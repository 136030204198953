import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import { FiArrowDownCircle, FiArrowUpCircle } from 'react-icons/fi';

export const ProtocolStatusConfig = ({
  protocolStatus = [],
  selectedProtocolStatus = [],
  protocolStatusConfig,
  setProtocolStatusConfig
}) => {
  useEffect(() => {
    if (protocolStatus && protocolStatus.length > 0) {
      const config = [];
      for (const stgId of selectedProtocolStatus) {
        config.push(protocolStatus.find((stg) => stg.id === stgId));
      }
      setProtocolStatusConfig(config);
    }
  }, [selectedProtocolStatus, protocolStatus, setProtocolStatusConfig]);

  if (
    !protocolStatus ||
    !protocolStatus.length ||
    !selectedProtocolStatus ||
    !selectedProtocolStatus.length
  ) {
    return null;
  }

  const switchItems = (index1, index2) => {
    [protocolStatusConfig[index1], protocolStatusConfig[index2]] = [
      protocolStatusConfig[index2],
      protocolStatusConfig[index1]
    ];
    setProtocolStatusConfig([...protocolStatusConfig]);
  };

  const upItem = (i) => {
    const index = protocolStatusConfig.indexOf(i);
    if (index > 0) {
      switchItems(index, index - 1);
    }
  };

  const downItem = (i) => {
    const index = protocolStatusConfig.indexOf(i);
    if (index < protocolStatusConfig.length - 1) {
      switchItems(index, index + 1);
    }
  };

  return (
    <div>
      <Table bordered={true} striped={true} size="sm" className="text-center">
        <thead>
          <tr>
            <th>Status/Fase</th>
            <th>Ordenar</th>
          </tr>
        </thead>
        <tbody>
          {protocolStatusConfig &&
            protocolStatusConfig.map((stage) => (
              <tr key={stage.id}>
                <td className="text-left">{stage.name}</td>
                <td>
                  <div className="">
                    <FiArrowUpCircle
                      size={20}
                      cursor="pointer"
                      onClick={() => upItem(stage)}
                    />
                    <FiArrowDownCircle
                      style={{ marginLeft: '10px' }}
                      size={20}
                      cursor="pointer"
                      onClick={() => downItem(stage)}
                    />
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};
