import React, { useCallback, useEffect, useState } from 'react';
import LeadService from '../../services/leadService';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { FiEdit } from 'react-icons/fi';
import {
  Row,
  Form,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Container,
  Button,
  ButtonGroup,
  InputGroup,
  InputGroupAddon
} from 'reactstrap';

import TelephoneMask from 'components/Inputs/TelephoneMask';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import LeadStageService from '../../../LeadStages/service';
import Loader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import CustomFieldsInputsForm from '../../../shared/components/CustomFieldsInputsForm';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';

import {
  maskPhoneNumber,
  removeMaskSpecialCharacters
} from 'views/CRM/shared/utils/phoneNumberHelpers';

import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { toBrazilianFullDate } from 'views/CRM/shared/utils/date';
import ConvertLeadModal from './ConvertLeadModal';

import './styles.css';
import ChangeResponsibleModal from './ChangeResponsibleModal';
import ListComments from './Comments/CommentsList';
import CommentModal from './Comments/CommentsForm';
import ModalComment from './Comments/ModalUpdateComment';
import { FiPlus } from 'react-icons/fi';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { formatCustomFieldsToDisplay } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { useClickToCallOnPhoneField } from 'views/CRM/shared/hooks/useClickToCallOnPhoneField';
import { LeadAttachments } from './Attachments';
import TemplatesModal from 'views/ServicePanel/components/QueueContent/components/Contact/TemplatesModal';
import ContactAttemptTimelineModal from '../../../shared/ContactAttempt/components/ContactTimeLineModal';
import { getContactsAttempts } from '../../../shared/ContactAttempt/utils';
import LeadTimeModal from './LeadTime';

const LeadEdit = () => {
  const { control, reset, setValue, handleSubmit, errors, watch } = useForm();
  const { recordTypeId, id } = useParams();
  const history = useHistory();

  const [lead, setLead] = useState();
  const [activeStage, setActiveStage] = useState();
  const [leadStages, setLeadStages] = useState();
  const [changedStage, setChangedStage] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [isConvertLeadModalOpen, setIsConvertLeadModalOpen] = useState(false);
  const [isChangeReponsibleModalOpen, setIsChangeReponsibleModalOpen] =
    useState(false);
  const [
    isContactAttemptTimelineModalOpen,
    setIsContactAttemptTimelineModalOpen
  ] = useState(false);
  const [listComments, setListComments] = useState([]);
  const [openModalUpdateComment, setOpenModalUpdateComment] = useState(false);
  const [openModalCreateComment, setOpenModalCreateComment] = useState(false);
  const [openModalLeadTime, setOpenModalLeadTime] = useState(false);
  const [dataCommentUpdate, setDataCommentUpdate] = useState([]);
  const [isModalTemplateMessageLeadOpen, setIsisModalTemplateMessageLeadOpen] =
    useState(false);
  const [whatsAppMessageContactInfoLead, setWhatsAppMessageContactInfoLead] =
    useState({});

  const [chatCreatedOnIsolatedMessageId, setChatCreatedOnIsolatedMessageId] =
    useState(null);

  const [callUniqueId, setCallUniqueId] = useState(null);

  const { getCustomFields, customFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType(entitiesName.LEAD, recordTypeId);
  const { canMakeCall, makeCall, canSendMessage } =
    useClickToCallOnPhoneField();

  useEffect(() => {
    if (chatCreatedOnIsolatedMessageId) {
      getContactsAttempts(chatCreatedOnIsolatedMessageId, 'CHAT', 'LEAD', id);
      return;
    }

    if (callUniqueId) {
      getContactsAttempts(callUniqueId, 'TELEFONIA', 'LEAD', id);
      return;
    }
  }, [callUniqueId, chatCreatedOnIsolatedMessageId, id]);

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    if (!isLoadingCustomFields) {
      getLeadById();
    }
  }, [isLoadingCustomFields, getLeadById]);

  const getLeadById = useCallback(async () => {
    LeadService.getById(recordTypeId, id)
      .then(({ data }) => {
        const leadData = {
          ...data.data,
          phone: maskPhoneNumber(data.data.phone)
        };
        const formattedLead = formatCustomFieldsToDisplay(
          leadData,
          customFields
        );

        setLead(formattedLead);
        reset(formattedLead);
        setActiveStage(leadData.stageId);
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.message || 'Erro ao buscar dados do Lead'
        );
      });
  }, [id, recordTypeId, customFields, reset]);

  const getCommnents = useCallback(() => {
    if (id) {
      LeadService.getLeadComments(id)
        .then((res) => {
          setListComments(res.data.data);
        })
        .catch(() => {
          toast.error('Houve um erro ao buscar os comentários desse lead', {
            closeOnClick: true,
            autoClose: 3000
          });
        });
    }
  }, [id]);

  useEffect(getCommnents, [getCommnents]);

  const changeStage = (stage) => {
    setActiveStage(stage);
  };

  const convertLead = async () => {
    setIsConvertLeadModalOpen(true);
  };

  const openContactAttemptTimeline = async () => {
    setIsContactAttemptTimelineModalOpen(true);
  };

  const changeResponsible = async () => {
    setIsChangeReponsibleModalOpen(true);
  };

  const changeLeadStage = async (data) => {
    if (lead.isConverted) {
      toast.error('Não é possível alterar a fase de um lead convertido.', {
        closeOnClick: true,
        autoClose: 3000
      });
      return;
    }
    LeadService.updateStage(lead.id, data)
      .then(() => {
        setLead({ ...lead, stageId: data.stageId });
        setChangedStage(true);
        setActiveStage(data.stageId);
        toast.success('Fase atualizada com sucesso.', {
          closeOnClick: true,
          autoClose: 3000
        });
      })
      .catch((error) => {
        const errMsg =
          error.response?.data?.message || 'Erro ao atualizar a fase.';
        toast.error(errMsg, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  const updateStage = async () => {
    if (isSubmiting || activeStage === lead?.stageId) return;

    const newStage = leadStages.find((stage) => stage.id === activeStage);

    if (!newStage) return;

    const data = { stageId: activeStage };

    if (newStage.isConversion) {
      await convertLead(data);
    } else {
      await changeLeadStage(data);
    }
  };

  function handleWhatsAppMessage(phone) {
    if (!phone) return;
    const phoneNumberWithoutMask = removeMaskSpecialCharacters(phone);
    let data = {
      nome: lead?.name,
      telefone: phoneNumberWithoutMask !== '' ? phoneNumberWithoutMask : ''
    };

    setWhatsAppMessageContactInfoLead(data);
    setIsisModalTemplateMessageLeadOpen(!isModalTemplateMessageLeadOpen);
    return whatsAppMessageContactInfoLead;
  }

  useEffect(() => {
    LeadStageService.listOrdenade(recordTypeId)
      .then((res) => {
        setLeadStages(res.data.data);
      })
      .catch(() => {
        toast.error('Erro ao carregar as fases.', {
          closeOnClick: true,
          autoClose: 3000
        });
      });
  }, [recordTypeId]);

  if (isLoadingCustomFields) {
    return (
      <Row className="justify-content-md-center">
        <Loader />
      </Row>
    );
  }

  const onSubmit = async (data) => {
    data.phone = removeMaskSpecialCharacters(data.phone);

    const dataDTO = formatCustomFieldsToSubmit(data, customFields);

    LeadService.updateLead(recordTypeId, id, dataDTO)
      .then(() => {
        toast.success('Lead atualizado com sucesso.', {
          autoClose: 3000,
          closeOnClick: true
        });
        history.push('/admin/crm/lead', { recordTypeId });
      })
      .catch((error) => {
        const errMsg = error?.response?.data?.message;
        toast.error(errMsg || 'Não foi possível atualizar o Lead.', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const cancelAction = () => {
    history.push('/admin/crm/lead', { recordTypeId });
  };

  const handleUpdateComment = (dataComment) => {
    setOpenModalUpdateComment(true);
    setDataCommentUpdate(dataComment);
  };

  const handleCreateComment = () => {
    setOpenModalCreateComment(true);
  };

  const handleOpenLeadTime = () => {
    setOpenModalLeadTime(true);
  };

  const reloadComments = () => {
    LeadService.getLeadComments(id).then((res) => {
      setListComments(res.data.data);
    });
  };

  return (
    <>
      <SimpleHeader
        name="Lead edition"
        parentName="Lead"
        returnPath="/admin/crm/lead"
        navigatorState={{ recordTypeId }}
      />
      <Container className="configuration-container mt--6" fluid>
        <Card>
          <CardHeader
            style={{
              display: 'flex'
            }}
          >
            {leadStages &&
              leadStages.map((stage) => (
                <ButtonGroup key={stage.id} style={{ flexGrow: 1 }}>
                  <Button
                    color={
                      activeStage === stage.id
                        ? 'primary'
                        : activeStage > stage.id
                        ? 'success'
                        : 'dark'
                    }
                    outline
                    active={activeStage >= stage.id}
                    size="md"
                    type="button"
                    title="Fases do Lead"
                    onClick={() => {
                      changeStage(stage.id);
                    }}
                  >
                    {stage.name}
                    <li
                      className="fas fa-arrow-right ml-2"
                      style={{ float: 'right', marginTop: '3px' }}
                    ></li>
                  </Button>
                </ButtonGroup>
              ))}
            <Button
              style={{ marginLeft: '3px' }}
              color="primary"
              size="sm"
              disabled={isSubmiting || activeStage === lead?.stageId}
              onClick={updateStage}
            >
              <li className="fas fa-check"></li> Atualizar fase
            </Button>
          </CardHeader>
          <CardBody>
            <Row className="mb-3">
              <Col sm="2">
                <h3 className="font-weight-bold small mb-0">Responsável:</h3>
                <span className="d-flex align-items-center">
                  <span id="responsible-edit-label" onClick={changeResponsible}>
                    {lead?.responsibleName}{' '}
                  </span>
                  <FiEdit
                    onClick={changeResponsible}
                    className="ml-2"
                    cursor="pointer"
                    textDecoration="underline"
                  />
                </span>
              </Col>
              <Col sm="3">
                <h3 className="font-weight-bold small mb-0">Criado em:</h3>
                <span>{toBrazilianFullDate(lead?.createdAt)}</span>
              </Col>
              <Col sm="3">
                <h3 className="font-weight-bold small mb-0">
                  Última modificação por:
                </h3>
                <span>{lead?.lastUpdatedByName}</span>
              </Col>
              <Col sm="3">
                <h3 className="font-weight-bold small mb-0">
                  Última modificação em:
                </h3>
                <span>{toBrazilianFullDate(lead?.lastUpdatedAt)}</span>
              </Col>
              <Col
                sm="1"
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                <h3 className="font-weight-bold small mb-0">Prazos do Lead:</h3>
                <Button
                  color="info"
                  size="sm"
                  onClick={() => handleOpenLeadTime()}
                >
                  <li className="fas fa-clock"></li>
                </Button>
              </Col>
            </Row>

            <hr
              style={{ margin: '0', paddingTop: '0', marginBottom: '2rem' }}
            />

            <Form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row className="mb-3">
                <Col sm="6">
                  <label className="form-control-label text-capitalize">
                    Nome
                  </label>
                  <Controller
                    render={(props) => (
                      <Input
                        value={props.value}
                        onChange={props.onChange}
                        type="text"
                        placeholder="Nome"
                      />
                    )}
                    control={control}
                    defaultValue=""
                    name="name"
                  />
                </Col>
                <Col sm="6">
                  <label className="form-control-label text-capitalize">
                    Email
                  </label>
                  <Controller
                    render={(props) => (
                      <Input
                        value={props.value}
                        onChange={props.onChange}
                        type="email"
                        placeholder="Email"
                      />
                    )}
                    control={control}
                    defaultValue=""
                    name="email"
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col sm="6">
                  <label className="form-control-label text-capitalize">
                    Telefone
                  </label>
                  <Controller
                    render={(props) => (
                      <InputGroup>
                        <TelephoneMask
                          valueController={setValue}
                          fieldName="phone"
                          value={props.value}
                          placeholder="(99) 91234-5678"
                        />
                        <InputGroupAddon addonType="append">
                          <Button
                            type="button"
                            color="info"
                            title="Enviar mensagem"
                            disabled={!canSendMessage(props.value)}
                            onClick={() => handleWhatsAppMessage(props.value)}
                          >
                            <i className="fas fa-comment" />
                          </Button>
                        </InputGroupAddon>
                        <InputGroupAddon addonType="append">
                          <Button
                            type="button"
                            color="success"
                            title="Realizar chamada"
                            disabled={!canMakeCall(props.value)}
                            onClick={() =>
                              makeCall(props.value, setCallUniqueId, true)
                            }
                          >
                            <i className="fas fa-phone" />
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    )}
                    control={control}
                    defaultValue=""
                    rules={{
                      validate: (value) => {
                        if (!value) return true;
                        let phoneFormated = value.replace(/\D/g, '');
                        phoneFormated = phoneFormated.replace(/^0/, '');

                        if (
                          phoneFormated.length < 10 &&
                          phoneFormated.length > 0
                        ) {
                          return 'Telefone inválido';
                        }
                        return true;
                      }
                    }}
                    name="phone"
                  />
                </Col>

                <CustomFieldsInputsForm
                  fields={customFields}
                  control={control}
                  watch={watch}
                  errors={errors}
                  isOnEditPage={true}
                  setValue={setValue}
                  setChatCreatedOnIsolatedMessageId={
                    setChatCreatedOnIsolatedMessageId
                  }
                  setCallUniqueId={setCallUniqueId}
                  isTryingToCreateAContactAttempt={true}
                  Controller={Controller}
                  isUpdate={true}
                  options={{
                    showCallIconOnPhoneField: true,
                    showMessageIconOnPhoneField: true
                  }}
                  contactMessageClientName={lead?.name}
                />
              </Row>
              {listComments && (
                <ListComments
                  listComments={listComments}
                  reloadComments={reloadComments}
                  handleUpdateComment={handleUpdateComment}
                />
              )}

              <Row style={{ paddingTop: '20px' }}>
                <Col className="text-right">
                  <Button
                    title="Adicionar novo comentário"
                    color="success"
                    type="button"
                    onClick={() => handleCreateComment()}
                  >
                    <FiPlus size={16} /> Adiciona Comentário
                  </Button>
                </Col>
              </Row>

              <Row style={{ paddingTop: '20px', paddingBottom: '40px' }}>
                <Col>
                  <h3 className="form-control-label mt-2">Anexo(s)</h3>
                  <LeadAttachments
                    leadId={lead?.id}
                    attachments={lead?.attachments}
                    isButtonDisabled={lead?.isConverted}
                    onAdded={(addedAttachments) => {
                      setLead((prev) => ({
                        ...prev,
                        attachments: [...prev.attachments, ...addedAttachments]
                      }));
                    }}
                    onDeleted={(attachmentId) => {
                      const remainAttachments = lead.attachments.filter(
                        (att) => att.id !== attachmentId
                      );
                      setLead((prev) => ({
                        ...prev,
                        attachments: remainAttachments
                      }));
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <ReactButton
                    btnColor="confirmation"
                    type="submit"
                    disabled={lead?.isConverted}
                  >
                    Salvar
                  </ReactButton>

                  <ReactButton
                    btnColor="cancelation"
                    onClick={() => {
                      return cancelAction();
                    }}
                  >
                    Cancelar
                  </ReactButton>
                </Col>
                <Col className="text-right">
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => openContactAttemptTimeline()}
                  >
                    <i className="fas fa-history" /> Histórico de Tentativas de
                    Contato
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>

      <ConvertLeadModal
        isModalOpen={isConvertLeadModalOpen}
        onModalToggle={setIsConvertLeadModalOpen}
        lead={lead}
      />
      <ChangeResponsibleModal
        isModalOpen={isChangeReponsibleModalOpen}
        onModalToggle={setIsChangeReponsibleModalOpen}
        leadId={lead?.id}
        callback={getLeadById}
      />
      <CommentModal
        render={getCommnents}
        leadId={id}
        isModalOpen={openModalCreateComment}
        onModalToggle={setOpenModalCreateComment}
        unmountOnClose={true}
      />
      <ModalComment
        render={getCommnents}
        dataComment={dataCommentUpdate}
        isModalOpen={openModalUpdateComment}
        onModalToggle={setOpenModalUpdateComment}
        unmountOnClose={true}
      />
      <TemplatesModal
        isModalOpen={isModalTemplateMessageLeadOpen}
        onModalToggle={setIsisModalTemplateMessageLeadOpen}
        clientNumber={whatsAppMessageContactInfoLead.telefone}
        clientName={whatsAppMessageContactInfoLead.nome}
        clientId={whatsAppMessageContactInfoLead.clientId ?? ''}
        setChatCreatedOnIsolatedMessageId={setChatCreatedOnIsolatedMessageId}
        isTryingToCreateAContactAttempt={true}
        isIsolated={true}
      />
      {id && (
        <ContactAttemptTimelineModal
          entityId={id}
          entityType="LEAD"
          isModalOpen={isContactAttemptTimelineModalOpen}
          onModalToggle={setIsContactAttemptTimelineModalOpen}
        />
      )}
      <LeadTimeModal
        leadId={id}
        isModalOpen={openModalLeadTime}
        onModalToggle={setOpenModalLeadTime}
        unmountOnClose={true}
        changedStage={changedStage}
        setChangedStage={setChangedStage}
      />
    </>
  );
};

export default LeadEdit;
