import React, { useEffect, useState } from 'react';
import { spotStyle } from '../assets';
import { Row, Col, CardTitle, CardSubtitle } from 'reactstrap';
import { Vr, TimelineDot, TimelineContainer, TimelineRow } from '../styled';

const Timeline = ({ timelineData }) => {
  const [timeline, setTimeline] = useState();

  useEffect(() => {
    setTimeline([...timelineData]);
  }, [timelineData]);

  return timeline ? (
    <Row className="mb-3">
      <Col className="col-sm-12 col-md-10 offset-md-1">
        <Vr />
        {timeline.reverse().map((el, index) => {
          return (
            <TimelineContainer key={index}>
              <TimelineDot color={spotStyle(el.atividade).color}>
                <i
                  className={spotStyle(el.atividade).icon}
                  style={{
                    marginTop: '10px',
                    color: spotStyle(el.atividade).iconColor
                  }}
                />
              </TimelineDot>
              <TimelineRow>
                <CardTitle tag="h5">{spotStyle(el.atividade).name}</CardTitle>
                <CardSubtitle tag="h5" className="mt--3 text-muted">
                  {`${new Date(el.horaAtividade).toLocaleTimeString(
                    'pt-br'
                  )} - ${new Date(el.horaAtividade).toLocaleDateString(
                    'pt-br'
                  )}`}
                </CardSubtitle>
                <h5>{el.descricao}</h5>
              </TimelineRow>
            </TimelineContainer>
          );
        })}
      </Col>
    </Row>
  ) : (
    <></>
  );
};

export default Timeline;
