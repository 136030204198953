import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { TagCloud } from 'react-tagcloud';
import {
  individual_column_style,
  individual_header_style_color_dark
} from '../../../../../assets/styles/table_custom_style';
import { Row, Card, Col, CardBody, CardHeader } from 'reactstrap';
import { optionColors } from '../assets/types';

const Tags = ({ dataTagsCloud, arrayTags1, arrayTags2, count }) => {
  const random = () => {
    let seed = 1;
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };

  let totalCount = 0;

  arrayTags1.map((item) => {
    totalCount += item.count;
  });

  arrayTags2.map((item) => {
    totalCount += item.count;
  });

  const getPercentage = (value) => {
    return ((value * 100) / totalCount).toFixed(2);
  };

  arrayTags1.map((item) => {
    item.percentage = `${getPercentage(item.count)}%`;
  });

  arrayTags2.map((item) => {
    item.percentage = `${getPercentage(item.count)}%`;
  });

  return (
    <>
      <Card id="tagsContent">
        <CardHeader>
          <h6 className="surtitle">Visão Geral</h6>
          <h5 className="h3 mb-0">Tags</h5>
        </CardHeader>
        <CardBody>
          <Col md="12">
            <h6 className="surtitle">Nuvem de Tags</h6>
            <TagCloud
              minSize={18}
              maxSize={40}
              tags={dataTagsCloud}
              count={12}
              colorOptions={optionColors}
              className="simple-cloud"
              randomNumberGenerator={random}
              style={{
                textAlign: 'center'
              }}
            />
            <hr />
          </Col>
          <Row>
            <Col md="6">
              <h6 className="surtitle">Quantitativo de Tags</h6>
              <Card>
                <ToolkitProvider
                  data={arrayTags1}
                  keyField="value"
                  columns={[
                    {
                      dataField: 'value',
                      text: 'Nome da tag',
                      sort: false,
                      style: individual_column_style,
                      headerStyle: individual_header_style_color_dark
                    },
                    {
                      dataField: 'count',
                      text: 'Quantidade',
                      sort: false,
                      style: individual_column_style,
                      headerStyle: individual_header_style_color_dark
                    },
                    {
                      dataField: 'percentage',
                      text: 'Porcentagem',
                      sort: false,
                      style: individual_column_style,
                      headerStyle: individual_header_style_color_dark
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        classes="align-items-center table-striped table-dark"
                      />
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </Col>

            <Col md="6">
              <Card style={{ paddingTop: '0.9em' }}>
                {arrayTags2.length > 0 && (
                  <ToolkitProvider
                    data={arrayTags2}
                    keyField="value"
                    columns={[
                      {
                        dataField: 'value',
                        text: 'Nome da tag',
                        sort: false,
                        style: individual_column_style,
                        headerStyle: individual_header_style_color_dark
                      },
                      {
                        dataField: 'count',
                        text: 'Quantidade',
                        sort: false,
                        style: individual_column_style,
                        headerStyle: individual_header_style_color_dark
                      },
                      {
                        dataField: 'percentage',
                        text: 'Porcentagem',
                        sort: false,
                        style: individual_column_style,
                        headerStyle: individual_header_style_color_dark
                      }
                    ]}
                  >
                    {(props) => (
                      <div className="py-4 table-responsive">
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center table-striped table-dark"
                        />
                      </div>
                    )}
                  </ToolkitProvider>
                )}

                <small className="text-uppercase text-muted font-weight-bold col-md-4 offset-md-9">
                  <b> Total de Tags :{count}</b>
                </small>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default Tags;
