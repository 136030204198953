import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ChatDialogTreeService from '../../../service';
import { unset, set } from 'lodash';
import { findPath, formatPath, loopTreeData } from './helpers';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';

const context_format = {
  removeNode: () => {},
  treeData: {},
  isModalOpen: false,
  setIsModalOpen: () => {},
  openModal: () => {},
  selectedNode: {},
  createNewLevel: () => {},
  onSubmitModalData: () => {},
  setTypeNodeAction: () => {},
  typeNodeAction: () => {},
  submitDataToBackend: () => {},
  resetTree: () => {},
  availableActions: [],
  availableQueues: [],
  setAvailableActions: () => {},
  setAvailableQueues: () => {}
};

const defaultTreeData = {
  0: {
    id: uuid(),
    level: 1,
    mac: {
      level: null,
      idMac: null
    },
    action: {},
    nodes: {},
    isDefault: true
  }
};

const DialogTreeContext = createContext(context_format);

export function DialogTreeProvider({ children }) {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [availableActions, setAvailableActions] = useState([]);
  const [availableQueues, setAvailableQueues] = useState([]);
  const [treeData, setTreeData] = useState(defaultTreeData);
  const [selectedNode, setSelectedNode] = useState({});
  const [typeNodeAction, setTypeNodeAction] = useState('');
  // const [nodeActionTypeSelected, setNodeActionTypeSelected] = useState('');
  const actualLevelAvailableLevels = Object.keys(treeData);

  const defaultNode = {
    id: uuid(),
    level: selectedNode.level + 1,
    mac: {
      level: null,
      idMac: null
    },
    action: {},
    nodes: {},
    isDefault: true
  };

  const removeNode = (nodeData) => {
    const newTreeData = { ...treeData };
    loopTreeData(treeData, (node) => {
      if (nodeData?.id === node.id) {
        const objectPath = findPath(newTreeData, 'id', node.id);
        const pathArray = formatPath(objectPath, null);
        unset(newTreeData, pathArray);
      }
    });
    setTreeData({ ...newTreeData });
  };

  const onSubmitModalData = (data) => {
    // setNodeActionTypeSelected(data.action.type);
    switch (typeNodeAction) {
      case 'ADD_NEW_NODE':
        createNewNode(data);
        break;
      // case 'ADD_NEW_NODE_CHILD':
      //   createNewNode(data);
      //   break;
      case 'EDIT_NODE':
        editNode(data);
        break;
      default:
        break;
    }
    setIsModalOpen(false);
  };

  const createNewNode = (data) => {
    const level = selectedNode.level;
    const id = selectedNode.id;
    const newTreeData = { ...treeData };

    const newNode = {
      id: uuid(),
      level: typeNodeAction === 'ADD_NEW_NODE' ? level : level + 1,
      numericIdentifier: data.numericIdentifier,
      stringIdentifier: data.stringIdentifier,
      possibleAnswers: data.possibleAnswers,
      action: data.action,
      mac: {
        level: null,
        idMac: null
      },
      nodes: {}
    };

    if (selectedNode.level !== 1) {
      const objectPath = findPath(newTreeData, 'id', id);
      const pathArray = formatPath(objectPath, typeNodeAction);
      const actualNodeLevel = eval(`treeData${pathArray}`);

      if (typeNodeAction === 'ADD_NEW_NODE') {
        const actualNodeIndex = Object.keys(actualNodeLevel);
        const newId =
          actualNodeIndex.length > 0 ? Math.max(...actualNodeIndex) + 1 : 1;

        newNode.mac = actualNodeLevel[0].mac;

        if (
          data.action.type === 'SUBMENU' ||
          data.action.type === 'SUBMENU_BUTTONS'
        ) {
          defaultNode.mac = {
            level: newNode.level,
            idMac: newNode.id
          };
          defaultNode.level = newNode.level + 1;
          newNode.nodes = {
            0: defaultNode
          };
          const newNodesBrother = {
            ...actualNodeLevel,
            [newId]: newNode
          };
          set(treeData, pathArray, newNodesBrother);
        }
        const newNodesBrother = {
          ...actualNodeLevel,
          [newId]: newNode
        };
        set(treeData, pathArray, newNodesBrother);
      }
      // else if (typeNodeAction === 'ADD_NEW_NODE_CHILD') {
      //   console.log('actualNodeLevel?.nodes', actualNodeLevel);
      //   const actualNodeIndex = Object.keys(actualNodeLevel?.nodes);
      //   const newId =
      //     actualNodeIndex.length > 0 ? Math.max(...actualNodeIndex) + 1 : 1;

      //   newNode.mac = {
      //     level: selectedNode.level,
      //     id: selectedNode.id
      //   };

      //   if (
      //     data.action.type === 'SUBMENU' ||
      //     data.action.type === 'SUBMENU_BUTTONS'
      //   ) {
      //     console.log('não é 1 e ta adicionando um filho', defaultNode);
      //     defaultNode.level = newNode.level + 1;
      //     defaultNode.mac = {
      //       level: newNode.level,
      //       id: newNode.id
      //     };

      //     newNode.nodes = {
      //       0: defaultNode
      //     };
      //     const newNodesChild = {
      //       ...actualNodeLevel,
      //       nodes: {
      //         ...actualNodeLevel.nodes,
      //         [newId]: newNode
      //       }
      //     };

      //     console.log('newNodesChild', newNodesChild);

      //     set(treeData, pathArray, newNodesChild);
      //     return;
      //   }
      //   const newNodesChild = {
      //     ...actualNodeLevel,
      //     nodes: {
      //       ...actualNodeLevel.nodes,
      //       [newId]: newNode
      //     }
      //   };
      //   set(treeData, pathArray, newNodesChild);
      // }
      else {
        data.mac = {
          level: selectedNode.level,
          idMac: selectedNode.id
        };
        if (selectedNode.nodes && Object.keys(selectedNode.nodes).length >= 1) {
          selectedNode.nodes = {
            ...selectedNode.nodes
          };
        } else {
          selectedNode.nodes = {
            ...selectedNode.nodes,
            0: data
          };
        }
      }
    }

    if (selectedNode.level === 1) {
      if (typeNodeAction === 'ADD_NEW_NODE') {
        const actualNodeIndex = Object.keys(treeData);
        const newId =
          actualNodeIndex.length > 0 ? Math.max(...actualNodeIndex) + 1 : 1;

        newNode.level = 1;

        if (
          data.action.type === 'SUBMENU' ||
          data.action.type === 'SUBMENU_BUTTONS'
        ) {
          defaultNode.mac = {
            level: newNode.level,
            idMac: newNode.id
          };
          newNode.nodes = {
            0: defaultNode
          };
        }

        treeData[newId] = newNode;
        return;
      }
      // else if (typeNodeAction === 'ADD_NEW_NODE_CHILD') {
      //   const thisNodeKey = Object.keys(treeData).find(
      //     (key) => treeData[key].id === selectedNode.id
      //   );

      //   const actualNodeLevel = treeData[thisNodeKey];

      //   const actualNodeIndex = Object.keys(actualNodeLevel?.nodes);
      //   const newId =
      //     actualNodeIndex.length > 0 ? Math.max(...actualNodeIndex) + 1 : 1;

      //   newNode.mac = {
      //     level: selectedNode.level,
      //     id: selectedNode.id
      //   };

      //   if (
      //     data.action.type === 'SUBMENU' ||
      //     data.action.type === 'SUBMENU_BUTTONS'
      //   ) {
      //     defaultNode.level = newNode.level + 1;

      //     console.log('é 1 e ta adicionando um filho com submenu', newNode);

      //     defaultNode.mac = {
      //       level: newNode.level,
      //       id: newNode.id
      //     };

      //     const newNodesChild = {
      //       ...actualNodeLevel,
      //       nodes: {
      //         ...actualNodeLevel.nodes,
      //         [newId]: {
      //           ...newNode,
      //           nodes: {
      //             0: defaultNode
      //           }
      //         }
      //       }
      //     };

      //     set(treeData, thisNodeKey, newNodesChild);
      //     return;
      //   }

      //   const newNodesChild = {
      //     ...actualNodeLevel,
      //     nodes: {
      //       ...actualNodeLevel.nodes,
      //       [newId]: newNode
      //     }
      //   };

      //   set(treeData, thisNodeKey, newNodesChild);
      //   return;
      // }
      else {
        data.mac = {
          level: selectedNode.level,
          idMac: selectedNode.id
        };
        if (selectedNode.nodes && Object.keys(selectedNode.nodes).length >= 1) {
          selectedNode.nodes = {
            ...selectedNode.nodes
          };
        } else {
          selectedNode.nodes = {
            ...selectedNode.nodes,
            0: data
          };
        }
      }
    }

    setTreeData({ ...newTreeData });
  };

  const editNode = (data) => {
    if (
      selectedNode.nodes &&
      Object.keys(selectedNode.nodes).length === 1 &&
      Object.keys(selectedNode.nodes[0].action).length === 0
    ) {
      delete selectedNode.nodes[0];
    }
    if (selectedNode.isDefault) {
      loopTreeData(treeData, (node) => {
        if (node.id === selectedNode.id && node.level === selectedNode.level) {
          node.action = data.action;
        }
      });
      if (
        data.action.type === 'SUBMENU' ||
        data.action.type === 'SUBMENU_BUTTONS'
      ) {
        createNewNode(defaultNode);
      }
      setTreeData({ ...treeData });
    }

    loopTreeData(treeData, (node) => {
      if (node.id === selectedNode.id && node.level === selectedNode.level) {
        node.numericIdentifier = data.numericIdentifier;
        node.stringIdentifier = data.stringIdentifier;
        node.possibleAnswers = data.possibleAnswers;
        node.action = data.action;
      }
    });

    if (
      data.action.type === 'SUBMENU' ||
      data.action.type === 'SUBMENU_BUTTONS'
    ) {
      createNewNode(defaultNode);
    }

    setTreeData({ ...treeData });
  };

  const validateTreeData = () => {
    let isTreeValid = {
      isValid: true,
      message: ''
    };
    loopTreeData(treeData, (node) => {
      if (Object.keys(node.action).length === 0) {
        isTreeValid = {
          isValid: false,
          message: 'Ação padrão não configurada em todos os níveis'
        };
      } else if (
        node.action.type === 'SUBMENU_BUTTONS' &&
        Object.keys(node.nodes).length <= 4
      ) {
        Object.values(node.nodes).forEach((subNode) => {
          if (
            subNode?.stringIdentifier &&
            subNode?.stringIdentifier.length > 16
          ) {
            isTreeValid = {
              isValid: false,
              message:
                `${subNode.stringIdentifier} do submenu de botões da opção ${node.stringIdentifier} é maior que 16 caracteres` ||
                'Erro'
            };
          }
        });
      }
    });
    return isTreeValid;
  };

  const submitDataToBackend = async (data, isUpdate, id) => {
    const dataToSend = {
      name: data.treeName,
      dialog: treeData
    };

    if (validateTreeData().isValid) {
      if (isUpdate) {
        FlexChatIntegration.putDialogTree(id, dataToSend)
          .then(() => {
            toast.success('Árvore de diálogo atualizada com sucesso');
            history.push('/admin/ChatDialogTree');
          })
          .catch((err) => {
            toast.error(
              err.response.data.message || 'Erro ao atualizar árvore de diálogo'
            );
          });
      } else {
        FlexChatIntegration.postDialogTree(dataToSend)
          .then(() => {
            toast.success('Árvore de diálogo criada com sucesso');
            history.push('/admin/ChatDialogTree');
          })
          .catch((err) => {
            toast.error(
              err.response.data.message || 'Erro ao criar árvore de diálogo'
            );
          });
      }
    } else {
      toast.error(validateTreeData().message);
    }
  };

  const openModal = (nodeData, type) => {
    if (nodeData) {
      setSelectedNode(nodeData);
      setTypeNodeAction(type);
      setIsModalOpen(true);
    }
  };

  const resetTree = useCallback(() => {
    setTreeData({
      0: {
        id: uuid(),
        level: 1,
        mac: {
          level: null,
          idMac: null
        },
        action: {},
        nodes: {},
        isDefault: true
      }
    });

    setSelectedNode({});

    setIsModalOpen(false);

    setTypeNodeAction('');
  }, []);

  useEffect(() => {
    if (!isModalOpen) setSelectedNode({});
  }, [isModalOpen]);

  return (
    <DialogTreeContext.Provider
      value={{
        removeNode,
        selectedNode,
        treeData,
        setTreeData,
        isModalOpen,
        setIsModalOpen,
        openModal,
        onSubmitModalData,
        typeNodeAction,
        actualLevelAvailableLevels,
        submitDataToBackend,
        resetTree,
        setAvailableActions,
        setAvailableQueues,
        availableActions,
        availableQueues
      }}
    >
      {children}
    </DialogTreeContext.Provider>
  );
}

export function useDialogTree() {
  const dialogContext = useContext(DialogTreeContext);
  return dialogContext;
}
