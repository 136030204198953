/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from 'react';
// nodejs library to set properties for components
//import PropTypes from 'prop-types';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

const TimelineHeader = () => {
  const [state] = useState({
    path: ''
  });
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  if (state.path) return <Redirect to={state.path} />;

  return (
    <>
      <div
        className="header header-dark pb-6 content__title content__title--calendar"
        style={{ background: systemConfiguration.primary_color }}
      >
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7"></Col>
              {/*<Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-search" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Procurar um item do menu" type="text" />
                  </InputGroup>
    </Col>*/}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TimelineHeader;
