export function disabledDownloadButtonCsvAndPdf(
  media,
  quantity,
  limitGenerationPDF
) {
  if (media === 'pdf') {
    if (quantity > 0 && quantity <= limitGenerationPDF) {
      return false;
    } else if (quantity > 0 && quantity > limitGenerationPDF) {
      return true;
    } else {
      return true;
    }
  } else if (media === 'csv') {
    if (quantity > 0) {
      return false;
    } else {
      return true;
    }
  }
}
