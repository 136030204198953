const initial_state = {
  data: []
};

export default function createObject(state = initial_state, action) {
  switch (action.type) {
    case 'HANDLE_OBJECT':
      return { ...state, data: action.payload };
    case '@ACTION_CHAT_CONFIG_TIMEOUT': {
      return {
        data: {
          ...state.data,
          timeoutAtendimento: { timeoutConfigs: [...action.payload] }
        }
      };
    }
    case '@ACTION_CHAT_CONFIG_CHECK_MESSAGES':
      return {
        data: {
          ...state.data,
          mensagens: { ...action.payload }
        }
      };
    case '@ACTION_CHAT_CONFIG_PROS':
      return {
        data: {
          ...state.data,
          chat: { ...action.payload }
        }
      };
    case '@ACTION_CHAT_CONFIG_HORARIOS':
      return {
        data: {
          ...state.data,
          configuracao_horario_atendimento: [...action.payload]
        }
      };
    case '@ACTION_CHAT_CONFIG_EMAIL':
      return {
        data: {
          ...state.data,
          email: { ...action.payload }
        }
      };
    case '@ACTION_CHAT_CONFIG_FACEBOOK':
      return {
        data: {
          ...state.data,
          facebook: { ...action.payload }
        }
      };

      case '@ACTION_CHAT_CONFIG_INSTAGRAM':
        return {
          data: {
            ...state.data,
            instagram: { ...action.payload }
          }
        };
      
    case '@ACTION_CHAT_CONFIG_BOT':
      return {
        data: {
          ...state.data,
          watson: { ...action.payload }
        }
      };
    case '@ACTION_CHAT_CONFIG_WHATSAPP':
      return {
        data: {
          ...state.data,
          whatsapp: { ...action.payload }
        }
      };
    case '@ACTION_CHAT_CONFIG_TELEGRAM':
      return {
        data: {
          ...state.data,
          telegram: { ...action.payload }
        }
      };
    case '@ACTION_CONFIG_FIELDS':
      return {
        data: {
          ...state.data,
          camposEncerramento: { ...action.payload }
        }
      };
    default:
      return state;
  }
}
