import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ChatDialogTreeService from '../service';

import DialogTreeModal from './components/DialogTreeModal';
import { useDialogTree } from './tree/context';
import TreeList from './tree/treeList';
import { toast } from 'react-toastify';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
import { ScreenPrompt } from 'components/ScreenPrompt';

const ChatDialogTreeForm = () => {
  const history = useHistory();
  const { id } = useParams();

  const {
    isModalOpen,
    setIsModalOpen,
    onSubmitModalData,
    selectedNode,
    submitDataToBackend,
    resetTree,
    setTreeData
  } = useDialogTree();

  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDialogTree = useCallback(() => {
    setLoading(true);
    FlexChatIntegration.getTreeById(id)
      .then((response) => {
        setTreeData(response.data.dialog);
        reset({
          treeName: response.data.name
        });
      })
      .catch((err) => {
        toast.error(
          (err.response && err.response.data.message) ||
            'Erro ao listar Árvores de Diálogo!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, [id, reset, setTreeData]);

  useEffect(() => {
    if (id) {
      setIsUpdate(true);
      getDialogTree();
    }
  }, [id, getDialogTree]);

  const onSubmit = async (data) => {
    submitDataToBackend(data, isUpdate, id);
  };

  useEffect(() => {
    if (!id) {
      resetTree();
    }
  }, [id, resetTree]);

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/ChatDialogTree');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/ChatDialogTree"
        buttonTitle="Voltar para a pagina de Árvores de diálogo"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>
                    {isUpdate
                      ? 'Atualizar Árvore de diálogo'
                      : 'Criar Árvore de diálogo'}
                  </h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col sm="12">
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="treeName"
                            rules={{ required: 'Nome é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="treeName"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                      <hr />

                      <Row>
                        <TreeList />
                      </Row>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <DialogTreeModal
        isOpenModal={isModalOpen}
        onModalToggle={setIsModalOpen}
        onSubmitModalData={onSubmitModalData}
        selectedNode={selectedNode}
      />
    </>
  );
};

export default ChatDialogTreeForm;
