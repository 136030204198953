import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { CardBody as CardBodyReactstrap } from 'reactstrap';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ImageServiceUpload from '../service';

import ReactButton from 'components/Buttons/ReactButton';
import { Input } from 'reactstrap';
import { Controller } from 'react-hook-form';
import { styles } from './styles';
import { Form } from 'reactstrap';
import UserService from '../../../../../../../User/service';

export function CardBody({
  protocol,
  user,
  chat,
  calls,
  toggleModal,
  image,
  loadImage
}) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const { control, handleSubmit } = useForm();
  const [newName, setNewName] = useState(user.name);

  useEffect(() => {
    setNewName(user.name);
  }, [user?.name]);

  const userDTO = {
    ...user,
    extensionsid: user?.extensions.map((ext) => ext.extension_number) || [],
    profilesid: user?.profiles.map((profiles) => profiles.id) || [],
    costscenterid: user?.costscenter.map((costscenter) => costscenter.id) || [],
    teamsIds: user?.teams.map((teams) => teams.id) || [],
    queuesid: user?.queues.map((queue) => queue.id) || [],
    queuesidToSignIn: user?.queuesidToSignIn.map((qId) => qId.id) || [],
    recordtypesid: user.recordTypes.map((recType) => recType.id),
    supervisorids: user?.supervisors.map((supervisor) => supervisor.login) || []
  };

  const onSubmit = async (event) => {
    try {
      if (user.should_sign_in_asterisk === null) {
        const formatedData = {
          ...userDTO,
          penalty: 0,
          should_sign_in_asterisk: false,
          name: event.name
        };

        await UserService.update(user.login, formatedData).then(() => {
          toast.success('Usuário alterado com sucesso!', {
            autoClose: 3000,
            closeOnClick: true
          });
          setNewName(event.name);
          window.localStorage.setItem('name', event.name);
        });
      } else {
        const formatedData = {
          ...userDTO,
          penalty: 0,
          name: event.name
        };

        await UserService.update(user.login, formatedData).then(() => {
          toast.success('Usuário alterado com sucesso!', {
            autoClose: 3000,
            closeOnClick: true
          });
          setNewName(event.name);
          window.localStorage.setItem('name', event.name);
        });
      }

      var imageFormData = new FormData();
      imageFormData.append('foto_perfil', image);
      imageFormData.append('originalname', 'userImage');

      await ImageServiceUpload.post(imageFormData)
        .then(() => {
          toggleModal();
        })
        .catch(() => {})
        .finally(() => {
          loadImage();
        });
    } catch (err) {
      toast.error(err.response?.data?.message || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  return (
    <CardBodyReactstrap>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="col">
          <div className="card-profile-stats d-flex justify-content-center">
            <div style={styles.divCircle}>
              <span
                className="heading"
                style={{
                  backgroundColor: systemConfiguration.primary_color,
                  color: '#f5f5f5',
                  fontSize: '1.5rem',
                  borderRadius: '5px',
                  width: '5.0rem',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {calls.listCallDetailedSyntheticCallCenterDTO[0]?.answered || 0}
              </span>
              <span style={{ color: '#4E4E73' }} className="description">
                Chamadas Atendidas
              </span>
            </div>
            <div style={styles.divCircle}>
              <span
                className="heading"
                style={{
                  backgroundColor: systemConfiguration.primary_color,
                  color: '#f5f5f5',
                  fontSize: '1.5rem',
                  borderRadius: '5px',
                  width: '5.0rem',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {chat.conversasFinalizadas[0]?.count || 0}
              </span>
              <span style={{ color: '#4E4E73' }} className="description">
                Conversas Atendidas
              </span>
            </div>
            <div style={styles.divCircle}>
              <span
                className="heading"
                style={{
                  backgroundColor: systemConfiguration.primary_color,
                  color: '#f5f5f5',
                  fontSize: '1.5rem',
                  borderRadius: '5px',
                  width: '5.0rem',
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  alignItems: 'center'
                }}
              >
                {protocol?.content?.countProtocols?.quantity || 0}
              </span>
              <span style={{ color: '#4E4E73' }} className="description">
                Protocolos finalizados
              </span>
            </div>
          </div>
          <hr
            style={{
              marginTop: '0.2rem',
              backgroundColor: systemConfiguration.primary_color,
              width: '80%'
            }}
          />
        </div>

        <div style={styles.container}>
          <div style={styles.divs}>
            <h4 className="mb-0" style={{ fontSize: '1rem' }}>
              Nickname:
            </h4>
            <span className="description">{user?.nickname}</span>
          </div>

          <div style={styles.divs}>
            <h4 className="mb-0" style={{ fontSize: '1rem' }}>
              Perfil:
            </h4>
            <span className="description">{user?.profile?.name}</span>
          </div>
        </div>

        <div style={styles.divs}>
          {/* //talvez notificar quando o nome for alterado com sucesso */}

          <label className="form-control-label" style={{ fontSize: '1rem' }}>
            Nome:
          </label>
          <Controller
            as={<Input type="text" />}
            control={control}
            name="name"
            rules={{
              required: 'Por favor, digite seu novo Nome!'
            }}
            defaultValue={`${newName}`} //deixar o valor do nome do usuário aqui
          />
        </div>

        <div style={styles.divs}>
          {/* //fazer com que os botões realmente alterem o nome do usuário */}
          <ReactButton btnColor="confirmation" type="submit">
            Alterar
          </ReactButton>
          {/* //ao clicar qem 'Cancelar' o modal deve fechar */}
          <ReactButton btnColor="cancelation" onClick={toggleModal}>
            Cancelar
          </ReactButton>
        </div>
      </Form>
    </CardBodyReactstrap>
  );
}
