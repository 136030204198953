import React, { useEffect, useRef, useState } from 'react';
import ClientBalloon from '../../../ChatBallons/Client';
import UserBalloon from '../../../ChatBallons/User';
import SpyBallon from '../../../ChatBallons/Spy';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button
} from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style
} from 'assets/styles/table_custom_style';
import { Hr, Vr, TimelineDot, TimelineContainer, TimelineRow } from '../styled';
import Modal from 'components/Modal/NotificationModal';
import Timeline from '../../components/Timeline';
import { getStateConversation } from 'utils/fuctions/getStateConversation';
import GetConversationService from '../../../../../views/Monitoring/Chat/conversation/services/apiConversation';
import { useReactToPrint } from 'react-to-print';
import { PrintConversation } from './styled';
import IconG4 from 'assets/img/theme/iconG4.png';
import { LocationOn } from '@material-ui/icons';

export const ConversationCard = ({
  conversationData,
  getDataOnModalOpen = false
}) => {
  const [conversation, setConversation] = useState({});
  const [data, setData] = useState({});
  const state =
    (conversationData?.cliente?.telefone &&
      getStateConversation(conversationData?.cliente?.telefone)) ||
    'DDD não definido';
  const id = conversationData?._id;

  useEffect(() => {
    setConversation({ ...conversationData, ...data });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationData]);

  useEffect(() => {
    if (getDataOnModalOpen) {
      const interval = setInterval(() => {
        getData();
      }, 5000);

      getData();

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getData = () => {
    GetConversationService.get(id)
      .then((res) => {
        setData(res.data);
        setConversation({ ...conversation, ...res.data });
      })
      .catch((err) => {
        console.log('err', err);
      });
  };

  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const openGoogleMaps = () => {
    window.open(
      'https://maps.google.com?q=' +
        conversation.cliente.latitude +
        ',' +
        conversation.cliente.longitude
    );
  };

  return (
    <>
      {conversation._id ? (
        <Card>
          <CardBody className="d-flex flex-column">
            <CardTitle tag="h4">
              Cliente: {conversation.cliente.nome || 'Não informado'}
            </CardTitle>
            <CardSubtitle tag="h5" className="mb-3 text-muted">
              <Row>
                <Col md="6" title="CPF do cliente">
                  <i
                    className="fas fa-id-card"
                    style={{ color: '#5bc0de' }}
                  ></i>{' '}
                  - {conversation.cliente.cpf || 'Não informado'}
                </Col>
                <Col md="6" title="Canal de atendimento">
                  <i className="fas fa-users" style={{ color: '#0275d8' }}></i>{' '}
                  - {conversation.canal || 'Não reconhecido'}
                </Col>
              </Row>
              <Row>
                <Col md="6" title="Telefone do cliente">
                  <i className="fas fa-phone" style={{ color: '#5cb85c' }}></i>{' '}
                  - {conversation.cliente.telefone || 'Não informado'}
                </Col>
                <Col md="6" title="Estado">
                  <i className="fas fa-city" style={{ color: '#f0ad4e' }}></i>
                  {conversation.cliente.telefone && ` - Estado : ${state}`}
                </Col>
              </Row>
              <Row>
                <Col md="6" title="Email do cliente">
                  <i
                    className="fas fa-envelope-open-text"
                    style={{ color: '#f0ad4e' }}
                  ></i>{' '}
                  - {conversation.cliente.email || 'Não informado'}
                </Col>
                <Col md="6" title="Situação da conversa">
                  <i
                    className="far fa-chart-bar"
                    style={{ color: '#d9534f' }}
                  ></i>{' '}
                  - {conversation.situacao === 'abandonada' && 'Abandonada'}
                  {conversation.situacao === 'encerrada' && 'Encerrada'}
                  {conversation.situacao === 'em_atendimento' &&
                    'Em atendimento'}
                  {conversation.situacao === 'nao_atendida' && 'Não atendida'}
                  {conversation.situacao === 'transferida' && 'Transferida'}
                </Col>
              </Row>
            </CardSubtitle>

            <Row className="mb-3" style={{ alignItems: 'center' }}>
              <Col md={conversation.cliente.longitude ? '4' : '6'}>
                <Modal
                  name="Timeline do atendimento"
                  buttonName="Timeline"
                  useIconButton={false}
                  color="success"
                  tooltipTitle="Clique para ver a timeline do atendimento"
                >
                  {conversation.timeline && (
                    <Timeline timelineData={conversation.timeline} />
                  )}
                </Modal>
                {conversation.resumoBot && (
                  <>
                    {conversation.resumoBot.length > 0 && (
                      <Modal
                        name="Resumo Bot do Atendimento"
                        buttonName="Resumo Bot"
                        useIconButton={false}
                        color="info"
                        tooltipTitle="Clique para ver o resumo do bot no atendimento"
                      >
                        <Row className="mt-3">
                          <div className="col">
                            <div className="card-wrapper">
                              <Card>
                                <ToolkitProvider
                                  data={conversation.resumoBot}
                                  keyField="id"
                                  columns={[
                                    {
                                      dataField: 'id',
                                      text: 'Campo',
                                      sort: true,
                                      style: individual_column_style,
                                      headerStyle: individual_header_style,
                                      formatter: (row, _) =>
                                        row === 'CPF' || row === 'CPF/CNPJ'
                                          ? 'CPF/CNPJ'
                                          : row
                                    },
                                    {
                                      dataField: 'value',
                                      text: 'Descrição',
                                      sort: true,
                                      style: individual_column_style,
                                      headerStyle: individual_header_style,
                                      //tratativa adicionada, pois estava vindo a string "undefined"
                                      formatter: (row, _) =>
                                        row === 'undefined' ? '' : row
                                    }
                                  ]}
                                >
                                  {(props) => (
                                    <div className="py-4 table-responsive">
                                      <BootstrapTable
                                        {...props.baseProps}
                                        bootstrap4={true}
                                        bordered={false}
                                        classes="align-items-center"
                                      />
                                    </div>
                                  )}
                                </ToolkitProvider>
                              </Card>
                            </div>
                          </div>
                        </Row>
                      </Modal>
                    )}
                  </>
                )}
              </Col>
              <Col md="6">
                <Button
                  color="warning"
                  size="sm"
                  title={'Baixar Conversa'}
                  onClick={() => {
                    handlePrint();
                  }}
                >
                  Baixar a conversa <i className="fas fa-download"></i>
                </Button>
              </Col>
              {conversation.cliente.longitude &&
              conversation.cliente.latitude ? (
                <Col md="2">
                  <Button
                    color="primary"
                    outline
                    size="sm"
                    type="button"
                    title="Abrir endereço do cliente no Google Maps"
                    onClick={() => {
                      openGoogleMaps();
                    }}
                  >
                    <LocationOn />
                  </Button>
                </Col>
              ) : null}
            </Row>
            <Hr />
            <Row className="mb-3">
              <Col md="1" style={{ alignSelf: 'center' }}>
                <i
                  className="fas fa-info-circle"
                  style={{ color: '#5bc0de' }}
                ></i>
              </Col>
              <Col md="11">
                <p>
                  O atendimento foi iniciado através do canal{' '}
                  <strong>{conversation.canal || ''}</strong> e fila{' '}
                  <strong>{conversation.fila || ''}</strong>
                </p>
              </Col>
            </Row>
            {conversation.observacao && (
              <Row>
                <Col md="1" style={{ alignSelf: 'center' }}>
                  <i
                    className="fas fa-info-circle"
                    style={{ color: '#5bc0de' }}
                  ></i>
                </Col>
                <Col md="11">
                  <p>Observação: {conversation.observacao}</p>
                </Col>
              </Row>
            )}
            <Hr className="mb-3 mt--1" />
            <Row className="mb-3">
              <Col className="col-sm-12 col-md-10 offset-md-1">
                <Vr />
                <TimelineContainer>
                  <TimelineDot color="#5bc0de">
                    <i
                      className="fas fa-sign-in-alt"
                      style={{ marginTop: '10px' }}
                    />
                  </TimelineDot>
                  <TimelineRow>
                    <CardTitle tag="h5">ENTROU NA FILA</CardTitle>
                    {conversation.hora_criacao && (
                      <CardSubtitle tag="h5" className="mt--3 text-muted">
                        {`${new Date(
                          conversation.hora_criacao
                        ).toLocaleTimeString('pt-br')} - ${new Date(
                          conversation.hora_criacao
                        ).toLocaleDateString('pt-br')}`}
                      </CardSubtitle>
                    )}
                  </TimelineRow>
                </TimelineContainer>
                {conversation.situacao === 'abandonada' &&
                  conversation.atendida === false && (
                    <TimelineContainer>
                      <TimelineDot color="#d9534f">
                        <i
                          className="fas fa-times"
                          style={{ marginTop: '10px' }}
                        />
                      </TimelineDot>
                      <TimelineRow>
                        <CardTitle tag="h5">ABANDONADA</CardTitle>
                        {conversation.hora_fim_conversa && (
                          <CardSubtitle tag="h5" className="mt--3 text-muted">
                            {`${new Date(
                              conversation.hora_fim_conversa
                            ).toLocaleTimeString('pt-br')} - ${new Date(
                              conversation.hora_fim_conversa
                            ).toLocaleDateString('pt-br')}`}
                          </CardSubtitle>
                        )}
                        <h5 style={{ color: '#d9534f' }} className="mb--1 mt-1">
                          {conversation.encerrada_por || ''}
                        </h5>
                        <h5>{conversation.observacao || ''}</h5>
                      </TimelineRow>
                    </TimelineContainer>
                  )}

                {(conversation.atendida === true ||
                  conversation.atendimentoBot === true) && (
                  <TimelineContainer>
                    <TimelineDot color="#5cb85c">
                      <i
                        className="far fa-bell"
                        style={{ marginTop: '10px' }}
                      />
                    </TimelineDot>
                    <TimelineRow>
                      <CardTitle tag="h5">ATENDIDA</CardTitle>
                      {conversation.hora_do_atendimento && (
                        <CardSubtitle tag="h5" className="mt--3 text-muted">
                          {`${new Date(
                            conversation.hora_do_atendimento
                          ).toLocaleTimeString('pt-br')} - ${new Date(
                            conversation.hora_do_atendimento
                          ).toLocaleDateString('pt-br')}`}
                        </CardSubtitle>
                      )}
                      {conversation.atendente && (
                        <h5 style={{ color: '#5cb85c' }}>
                          {conversation.atendente.name || ''}
                        </h5>
                      )}
                    </TimelineRow>
                  </TimelineContainer>
                )}
                {conversation.situacao === 'encerrada' && (
                  <TimelineContainer>
                    <TimelineDot color="#d9534f">
                      <i
                        className="fas fa-check"
                        style={{ marginTop: '10px' }}
                      />
                    </TimelineDot>
                    <TimelineRow>
                      <CardTitle tag="h5">ENCERRADA</CardTitle>
                      {conversation.hora_fim_conversa && (
                        <CardSubtitle tag="h5" className="mt--3 text-muted">
                          {`${new Date(
                            conversation.hora_fim_conversa
                          ).toLocaleTimeString('pt-br')} - ${new Date(
                            conversation.hora_fim_conversa
                          ).toLocaleDateString('pt-br')}`}
                        </CardSubtitle>
                      )}
                      <h5 className="mb--1 mt-1 text-muted">
                        {conversation.encerrada_por || ''}
                      </h5>
                      {conversation.atendente && (
                        <h5 style={{ color: '#d9534f' }}>
                          {conversation.atendente.name || ''}
                        </h5>
                      )}
                    </TimelineRow>
                  </TimelineContainer>
                )}
              </Col>
            </Row>
            <Hr className="mb-3" />

            <PrintConversation ref={componentRef}>
              {conversation.mensagens && (
                <>
                  <img
                    src={IconG4}
                    alt="logo"
                    id="logoG4PDF"
                    style={{
                      position: 'absolute',
                      zIndex: '-1',
                      bottom: '7rem'
                    }}
                  />
                  {/* //alteração feita para o sussurro */}
                  {conversation.mensagens.map((el, index) => {
                    if (el) {
                      return el.cliente_ou_atendente === 'atendente' ||
                        el.cliente_ou_atendente === 'admin' ? (
                        el.cliente_ou_atendente === 'atendente' ? (
                          <UserBalloon
                            key={`conversationdetails+${conversation._id}+${index}`}
                            name={el.escrita_por}
                            type={el.response_type}
                            message={el.texto}
                            title={el.title}
                            time={el.hora_da_mensagem}
                            options={el.options}
                            source={el.source}
                          />
                        ) : (
                          <SpyBallon
                            key={`conversationdetails+${conversation._id}+${index}`}
                            name={el.escrita_por}
                            type={el.response_type}
                            message={el.texto}
                            title={el.title}
                            time={el.hora_da_mensagem}
                            options={el.options}
                            source={el.source}
                          />
                        )
                      ) : (
                        <ClientBalloon
                          key={`conversationdetails+${conversation._id}+${index}`}
                          name={el.escrita_por}
                          type={el.response_type}
                          message={el.texto}
                          title={el.title}
                          time={el.hora_da_mensagem}
                          options={el.options}
                          source={el.source}
                          contact={el.contact}
                        />
                      );
                    }
                  })}
                </>
              )}
            </PrintConversation>
          </CardBody>
        </Card>
      ) : null}
    </>
  );
};
