export const satisfaction = [
  { label: 'Todos', value: 'Todos' },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 }
];

export const channels = [
  { label: 'Todos', value: 'Todos' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'Telegram', value: 'telegram' },
  { label: 'Whatsapp', value: 'whatsapp' },
  { label: 'Chat', value: 'chat' },
  { label: 'Chat Flexia', value: 'chat Flexia' },
  { label: 'Instagram', value: 'instagram' }
];

export const serviceSucess = [
  { label: 'Todos', value: 'Todos' },
  { label: 'Sim', value: true },
  { label: 'Não', value: false }
];

export const situation = [
  { label: 'Todas', value: 'Todos' },
  { label: 'Em Atendimento', value: 'em_atendimento' },
  { label: 'Abandonada', value: 'abandonada' },
  { label: 'Transferida', value: 'transferida' },
  { label: 'Não Atendida', value: 'nao_transferida' },
  { label: 'Encerrada', value: 'encerrada' }
];

export const status = [
  { label: 'Todos', value: 'Todos' },
  { label: 'Aberto', value: 'Aberto' },
  { label: 'Encaminhado', value: 'Encaminhado' },
  { label: 'Encerrado', value: 'Encerrado' },
  { label: 'Pendência do cliente', value: 'Pendencia_do_cliente' },
  { label: 'Pendência da operadora', value: 'Pendecia_da_operadora' }
];

export const botCalls = [
  { label: 'Todos', value: 'Todos' },
  { label: 'Sim', value: true },
  { label: 'Não', value: false }
];

export const closure = [
  { label: 'Todos', value: 'Todos' },
  { label: 'Cliente', value: 'CLIENTE' },
  { label: 'Bot', value: 'BOT' },
  { label: 'Ociosidade', value: 'OCIOSIDADE' },
  { label: 'Administrador', value: 'ADMINISTRADOR' },
  { label: 'Atendente', value: 'ATENDENTE' }
];

export const active = [
  { label: 'Todos', value: 'Todos' },
  { label: 'Sim', value: true },
  { label: 'Não', value: false }
];
