import { useState, useCallback } from 'react';
import ProtocolFieldsService from '../../service';

function useHandleProtocolListingToContacts() {
  const [protocols, setProtocols] = useState(null);
  const [protocolsQuantity, setProtocolQuantity] = useState(0);
  const [isLoadingProtocols, setIsLoadingProtocols] = useState(false);

  const getProtocols = useCallback(async (params = {}) => {
    setIsLoadingProtocols(true);

    ProtocolFieldsService.getProtocols(params)
      .then((response) => {
        const protocolsData = response.data.content;
        const quantity = response.data.quantity;
        setProtocols(protocolsData);
        setProtocolQuantity(quantity);
      })
      .catch(() => {
        setProtocols(null);
      })
      .finally(() => {
        setIsLoadingProtocols(false);
      });
  }, []);

  return {
    protocols,
    protocolsQuantity,
    isLoadingProtocols,
    getProtocols
  };
}

export { useHandleProtocolListingToContacts };
