import { apiChat as http } from '../http';

class ServiceCRUD {
  constructor(path) {
    this.path = path;
  }

  getAll = () => {
    return http.get(`${this.path}`);
  };

  getAllPagination(page, showDisabled) {
    return http.get(`${this.path}?&showDisabled=${showDisabled}`, {
      params: {
        page: page
      }
    });
  }

  getAllFilter = (page = 1, search = '') => {
    return http.get(
      `${this.path}/busca/filtrados?filtro=${search}&pagina=${page}`
    );
  };

  get = (id) => {
    return http.get(`${this.path}/${id}`);
  };

  create = (data) => {
    return http.post(this.path, data);
  };

  update = (id, data) => {
    const list = [
      'facebook',
      'instagram',
      'sms',
      'telegram',
      'whatsapp',
      'chat'
    ];

    list.forEach((item) => {
      if (data[item]) {
        delete data[item];
      }
    });

    return http.put(`${this.path}/${id}`, data);
  };

  delete = (id) => {
    return http.delete(`${this.path}/${id}`);
  };

  getReport(type, page, queryParams = {}) {
    return http.get(`${this.path}/${type}`, {
      params: {
        pagina: page,
        ...queryParams
      }
    });
  }

  getReportPerformance(type, page, queryParams = {}) {
    return http.get(`${this.path}/${type}`, {
      params: {
        page: page,
        ...queryParams
      }
    });
  }

  getDownloads(type, queryParams = {}) {
    return http.get(`${this.path}/${type}/csv`, {
      params: {
        ...queryParams
      },
      responseType: 'blob'
    });
  }

  showTags = () => {
    return http.get(`${this.path}/list/enabled`);
  };
}

export default ServiceCRUD;
