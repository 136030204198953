import { apiUc as http } from '../../../../../http';

class DialerProcessService {
  call(data) {
    return http.post(`/api/processo-discador/call`, data);
  }

  next(data) {
    return http.post(`/api/processo-discador/next`, data);
  }
}

export default new DialerProcessService();
