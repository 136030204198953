const { useEffect, useState, useCallback } = require('react');
const { useSelector } = require('react-redux');
const { toast } = require('react-toastify');

const { apiChat } = require('http/index.js');
const { removeMaskSpecialCharacters } = require('../utils/phoneNumberHelpers');

export const useClickToCallOnPhoneField = () => {
  const [isUserLoggedOnSomeExtension, setIsUserLoggedOnSomeExtension] =
    useState(true);

  const telephonyServicePanel = useSelector(
    (state) => state.telephonyServicePanel
  );

  useEffect(() => {
    if (!telephonyServicePanel.extension) {
      setIsUserLoggedOnSomeExtension(false);
    } else {
      setIsUserLoggedOnSomeExtension(true);
    }
  }, [telephonyServicePanel]);

  const canMakeCall = useCallback(
    (fieldValue) => {
      if (!fieldValue) return false;

      const rawPhoneNumber = removeMaskSpecialCharacters(fieldValue);
      if (rawPhoneNumber.length < 10 || rawPhoneNumber.length > 11) {
        return false;
      }

      if (!isUserLoggedOnSomeExtension) {
        return false;
      }

      return true;
    },
    [isUserLoggedOnSomeExtension]
  );

  const canSendMessage = (fieldValue) => {
    if (!fieldValue) return false;

    const rawPhoneNumber = removeMaskSpecialCharacters(fieldValue);
    if (rawPhoneNumber.length < 10 || rawPhoneNumber.length > 11) {
      return false;
    }

    return true;
  };

  const makeCall = (
    fieldValue,
    setCallUniqueId = () => {},
    isTryingToCreateAContactAttempt = false
  ) => {
    if (
      !window.confirm(
        `Tem certeza que deseja realizar uma chamada para ${fieldValue}?`
      )
    )
      return;

    const rawPhoneNumber = removeMaskSpecialCharacters(fieldValue);

    apiChat
      .post(`/clicktocall`, {
        origem: telephonyServicePanel.extension,
        destino: rawPhoneNumber
      })
      .then((res) => {
        if (isTryingToCreateAContactAttempt) {
          setCallUniqueId(res.data.uniqueId);
        }
        toast.success('Realizando chamada!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .catch((err) => {
        console.log(err);
        toast.error('Erro ao realizar chamada!');
      });
  };

  return { canMakeCall, makeCall, isUserLoggedOnSomeExtension, canSendMessage };
};
