import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardTitle } from 'reactstrap';
import { HiPhone, HiClock } from 'react-icons/hi';
import { ContentContainer } from './styled';

const AsideCardItem = ({ title, children }) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const primary_color = systemConfiguration.primary_color;

  return (
    <Card
      style={{
        backgroundColor: primary_color,
        color: '#fff',
        textAlign: 'center',
        margin: '0 0 15px 0',
        minHeight: '7.2rem'
      }}
    >
      <CardBody style={{ padding: '10px' }}>
        <CardTitle
          title="card"
          tag="h3"
          className="text-uppercase font-weight-bold mb-0 pb-0  text-white"
        >
          {title}
          {title === 'T.M.A' && (
            <HiPhone size={22} color="white" style={{ paddingLeft: '5px' }} />
          )}
          {title === 'T.M.E' && (
            <HiClock size={22} color="white" style={{ paddingLeft: '5px' }} />
          )}
        </CardTitle>
        <ContentContainer>{children}</ContentContainer>
      </CardBody>
    </Card>
  );
};

export default AsideCardItem;
