import React from 'react';
import HeaderBar from '../HeaderBar';
import { Col, Row } from 'reactstrap';
import AgentCard from 'views/Dashboard/Agent/components/AgentIndicators/components/AgentCard';
import ReportCard from '../ReportCard';
import { AgentsCardsContainerWrapper } from './styled';

const AgentCardsContainer = ({ dialerName, agents = [], data }) => {
  return (
    <AgentsCardsContainerWrapper>
      <Row>
        <Col>
          <HeaderBar
            title={`Lista de Transmissão: ${dialerName}`}
            classname="mb-2"
          />
          <div
            id="agents"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill,minmax(11rem,2fr))',
              gap: '1rem'
            }}
          >
            {agents &&
              agents.map((agent, index) => (
                <AgentCard data={agent} key={index} />
              ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col style={{ display: 'flex', width: '25%' }}>
          <ReportCard
            data={{
              title: 'Total de mensagens',
              value: data && data[0] ? data[0].count : 0
            }}
          />
        </Col>
      </Row>
    </AgentsCardsContainerWrapper>

    // <div>
    //   <div id="header">
    //     <HeaderBar title={`Discador: ${dialerName}`} />
    //   </div>
    //   <div id="agents" style={{ display: 'flex' }}>
    //     {agents &&
    //       agents.map((agent, index) => <AgentCard data={agent} key={index} />)}
    //   </div>
    // </div>
  );
};

export default AgentCardsContainer;
