import React, { useEffect, useState } from 'react';

import {
  Container,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  InputGroupText
} from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from '../../../components/Pagination';
import SectorService from '../service';
import ModalConfirmation from 'components/Modal/ConfirmationModal';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const SectorList = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const [sector, setSector] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [seachSector, setSeachSector] = useState();
  const [idResource, setIdResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  useEffect(() => {
    getSector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSector = (selectedPage = 1, search = '') => {
    setLoading(true);
    SectorService.getAllSeach(selectedPage, 10, search, true)
      .then((response) => {
        const sectorList = response.data.data;
        // const newActivePage = selectedPage || activePage;

        const quantity = response.data.totalRegisters;

        setSector(sectorList);
        setQuantity(quantity);
        // setActivePage(newActivePage);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Não foi possivel listar os setores', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    getSector(page);
  };

  function getSectorSeach(event, selectedPage = 1) {
    setSeachSector(event.target.value);
    if (seachSector) {
      SectorService.getAllSeach(selectedPage, 10, event.target.value, false)
        .then((res) => {
          console.log(res.data.data);
          const { data, totalRegisters } = res.data;
          const newActivePage = selectedPage || activePage;
          setSector(data);
          setQuantity(totalRegisters);
          setActivePage(newActivePage);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Não foi possivel listar os setores', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }

  function deleteSector(sector_id) {
    SectorService.delete(sector_id)
      .then(() => {
        toast.success('Setor excluido com sucesso', {
          autoClose: 3000,
          closeOnClick: true
        });
        getSector();
      })
      .catch((err) => {
        console.log(err.response.data.message);
        toast.error('Não é possível excluir este Setor', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        getSector();
      });
  }

  function handleUpdate(id) {
    history.push(`sector/form/${id}`);
    console.log(id);
  }

  function controlDeleteResource(idResouce) {
    setIdResource(idResouce);
    setOpenModalConfirmation(true);
  }

  return (
    <>
      <SimpleHeader
        setCreate="/admin/sector/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Setores</h3>
                </CardHeader>
                <CardBody>
                  {/* <small className="text text-muted font-weight-bold">
                    Buscar setor
                  </small> */}
                  <Row>
                    <Col md="11">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Almoxarifado"
                          type="text"
                          onChange={(event) => getSectorSeach(event)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={sector}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'description',
                      text: 'Descrição',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },

                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            size="sm"
                            color="info"
                            onClick={() => handleUpdate(row.id)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    },

                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => controlDeleteResource(row.id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
        <ModalConfirmation
          isModalOpen={openModalConfirmation}
          onModalToggle={setOpenModalConfirmation}
          unmountOnClose={true}
          idResource={idResource}
          deleteResource={deleteSector}
          message={'Tem certeza que deseja excluir o Setor?'}
        />
      </Container>
    </>
  );
};

export default SectorList;