export const maskCpf = (valueCpf) => {
  if (!valueCpf) return '';

  let cpfFormated = valueCpf.replace(/\D/g, '');

  if (cpfFormated.length === 11) {
    cpfFormated = cpfFormated.replace(
      /^(\d\d\d)(\d{3})(\d{3})(\d{2}).*/,
      '$1.$2.$3-$4'
    );
  }

  return cpfFormated;
};

export const maskCnpj = (valueCnpj) => {
  if (!valueCnpj) return '';

  let cnpjFormated = valueCnpj.replace(/\D/g, '');

  if (cnpjFormated.length === 14) {
    cnpjFormated = cnpjFormated.replace(
      /^(\d\d)(\d{3})(\d{3})(\d{4})(\d{2}).*/,
      '$1.$2.$3/$4-$5'
    );
  }

  return cnpjFormated;
};
