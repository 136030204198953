import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  button {
    width: 100%;
  }
`;
