import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 5px;
`;

export const PowerButton = styled.i`
  cursor: ${({ isPaused }) => (isPaused ? 'default' : 'pointer')};
  transition: color 0.3s;
  &:hover {
    color: ${({ isPaused }) => (isPaused ? 'initial' : '#cc0000')};
  }
`;

export const QueueNameWrapper = styled.span`
  font-size: 0.9rem;
  margin-left: 8px;
`;
