import { sortData } from '../../../../../utils/fuctions/sortData';
import randomColor from 'randomcolor';

export const calculateData = () => {
  const calculateDataReceivedCall = (response) => {
    const queueData = response.data.data.listCallDetailedSyntheticCallCenterDTO;
    let arrayLabels = [];
    let arrayAtendidas = [];
    let arrayAbandonadas = [];

    if (queueData) {
      queueData.forEach((queue) => {
        arrayLabels.push(queue.nameQueue);
        arrayAtendidas.push(queue.answered);
        arrayAbandonadas.push(queue.abandoned);
      });
    }

    const returnData = {
      labels: [...arrayLabels],
      datasets: [
        {
          label: 'Atendidas ',
          data: [...arrayAtendidas],
          backgroundColor: '#00A07C'
        },
        {
          label: 'Abandonadas ',
          data: [...arrayAbandonadas],
          backgroundColor: 'red'
        }
      ]
    };

    return returnData;
  };
  const calculateDataIncomingCallService = (response) => {
    const queueData = response.data.data.listCallDetailedSyntheticCallCenterDTO;
    let arrayLabels = [];
    let arrayAtendidas = [];
    let arrayAbandonadas = [];

    if (queueData) {
      queueData.forEach((queue) => {
        arrayLabels.push(queue.nameQueue);
        arrayAtendidas.push(queue.answered);
        arrayAbandonadas.push(queue.abandoned);
      });
    }

    const returnData = {
      labels: [...arrayLabels],
      datasets: [
        {
          label: 'Atendidas ',
          data: [...arrayAtendidas],
          backgroundColor: '#00A07C'
        },
        {
          label: 'Abandonadas ',
          data: [...arrayAbandonadas],
          backgroundColor: 'red'
        }
      ]
    };

    return returnData;
  };
  const calculateChatDashboardService = (res, backgroundColor) => {
    let attendants = [];
    let quantity = [];

    const filterAttendants = res.data.filter(
      (attendant) => attendant.quantidade > 0
    );

    filterAttendants.forEach((el) => {
      attendants.push(el.atendente);
      quantity.push(el.quantidade);
    });

    const returnData = {
      data: {
        labels: [...attendants],
        datasets: [
          {
            label: 'Conversas',
            data: [...quantity],
            backgroundColor: backgroundColor,
            maxBarThickness: 10
          }
        ]
      }
    };

    return returnData;
  };
  const calculateProtocolByAttendants = (response, orderByGraphics) => {
    if (response.content) {
      let arrayLabels = [];
      let arrayData = [];
      let arrayColors = [];
      let totalCount;
      const { content } = response;

      const formattedQuantityToNumber = content.map((protocol) => {
        return {
          quantity: Number(protocol.quantity),
          name: protocol.name
        };
      });

      const isOrderedProtocols = sortData(
        formattedQuantityToNumber,
        'quantity',
        orderByGraphics
      );

      totalCount = isOrderedProtocols.reduce((acc, currentValue) => {
        return acc + Number(currentValue.quantity);
      }, 0);

      isOrderedProtocols.forEach((protocol) => {
        arrayLabels.push(protocol.name);
        arrayData.push(protocol.quantity);
        arrayColors.push(randomColor({ luminosity: 'random', hue: 'random' }));
      });

      const returnData = {
        arrayLabels,
        arrayData,
        arrayColors,
        totalCount
      };

      return returnData;
    } else {
      return null;
    }
  };
  const calculteConversationByQueue = (response, orderByGraphics) => {
    if (response.conversasFiltradas) {
      const isOrderedConversations = sortData(
        response.conversasFiltradas,
        'count',
        orderByGraphics
      );

      let arrayLabels = [];
      let arrayData = [];
      let arrayColors = [];
      let totalCount = 0;

      isOrderedConversations.forEach((el) => {
        if (typeof el._id === 'boolean') {
          if (el._id) {
            arrayLabels.push('Atendimento Bot');
          } else {
            arrayLabels.push('Atendimento Humano');
          }
        } else {
          arrayLabels.push(el._id);
        }
        arrayData.push(el.count);
        arrayColors.push(randomColor({ luminosity: 'random', hue: 'random' }));
        totalCount += el.count;
      });

      const returnData = {
        filteredLabels: [...arrayLabels],
        filteredData: [...arrayData],
        filteredColors: [...arrayColors],
        totalCount
      };

      return returnData;
    } else {
      return null;
    }
  };
  return {
    calculateDataReceivedCall,
    calculateDataIncomingCallService,
    calculateChatDashboardService,
    calculateProtocolByAttendants,
    calculteConversationByQueue
  };
};
