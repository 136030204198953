import React from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

export const ProtocolNumberWrapper = ({
  number,
  description,
  isDescriptionShown
}) => {
  if (!isDescriptionShown) return `${number}`;

  return (
    <>
      <span id={`popover-${number}`}>{number}</span>
      <UncontrolledPopover
        placement="bottom"
        target={`popover-${number}`}
        trigger="click hover focus"
      >
        <PopoverBody>
          {description || 'Protocolo não possui descrição'}
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};
