import { actionTypes } from '../action/telephonyServicePanel/actionTypes';

const initialState = {
  agentCode: '',
  extension: ''
};

export default function telephonyServicePanelReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case actionTypes.USER_LOGGED_IN:
      return { ...action.payload };
    case actionTypes.USER_LOGGED_OUT:
      return { ...initialState };
    default:
      return state;
  }
}
