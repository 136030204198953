import React from 'react';

import Form from './form';
import { TreeProvider } from './tree/context';

const Provider = () => (
  <TreeProvider>
    <Form />
  </TreeProvider>
);

export default Provider;
