import React from 'react';
import { EnterToPause } from './components/EnterToPause';
import { GetOutToPause } from './components/GetOutToPause';
import { ContainerItems } from './styled';

const PauseServicePainel = ({ isPaused = false }) => {
  return (
    <ContainerItems>
      {isPaused ? <GetOutToPause /> : <EnterToPause />}
    </ContainerItems>
  );
};

export default PauseServicePainel;
