const getCols = (data) => {
  const cols = new Set();

  data.map((element) =>
    Object.keys(element)
      .filter((key) => {
        const value = element[key];
        const isValidValue =
          typeof value === 'string' ||
          typeof value === 'number' ||
          typeof value === 'boolean';

        return isValidValue;
      })
      .map((key) => cols.add(key))
  );

  return [...cols];
};

const getRow = (element, cols) =>
  cols.map((col) => {
    const value = element[col];

    return value === undefined || value === null ? '' : `${value}`;
  });

export const generateTableFromListOfObjects = (data) => {
  const table = [];
  const cols = getCols(data);

  table.push(cols);

  const rows = data.map((element) => getRow(element, cols));

  table.push(...rows);

  return table;
};
