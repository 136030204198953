export const strategies = {
  ATIVO: 'ativo',
  RECEPTIVO: 'receptivo',
  MENSAGEM: 'mensagem',
  INTERATIVO: 'interativo'
};

export const strategyOptions = [
  { label: 'Ativo', value: strategies.ATIVO },
  { label: 'Receptivo', value: strategies.RECEPTIVO },
  { label: 'Receptivo com mensagem', value: strategies.MENSAGEM },
  { label: 'Interativo com integração', value: strategies.INTERATIVO }
];
