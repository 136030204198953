import { useState, useCallback } from 'react';

import OpportunityStageService from '../service';

function useListOpportunityStagesByRecordType() {
  const [opportunityStages, setOpportunityStages] = useState(null);
  const [isLoadingOpportunityStages, setIsLoadingOpportunityStages] = useState(
    false
  );

  const getOpportunityStagesByRecordType = useCallback(async (recordTypeId) => {
    setIsLoadingOpportunityStages(true);

    OpportunityStageService.listOrdenade(recordTypeId)
      .then((response) => {
        setOpportunityStages(response.data.data);
      })
      .catch(() => {
        setOpportunityStages(null);
      })
      .finally(() => {
        setIsLoadingOpportunityStages(false);
      });
  }, []);

  return {
    opportunityStages,
    isLoadingOpportunityStages,
    getOpportunityStagesByRecordType
  };
}

export { useListOpportunityStagesByRecordType };
