import React, { useState, useEffect, useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label
} from 'reactstrap';

import DefaultModal from 'components/Modal/DefaultModal';
import Pagination from 'components/Pagination';
import ReactButton from 'components/Buttons/ReactButton';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactLoader from 'components/Loader';
import BroadcastListService from '../../../service';

const PAGE_SIZE = 10;

const AddContactsModal = ({
  isModalOpen,
  onModalToggle,
  broadcastListId,
  reloadList,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);

  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [timeoutID, setTimeoutID] = useState(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isSeletingAll, setIsSeletingAll] = useState(false);

  const handleGetContactsToAdd = useCallback(
    (page, q = '') => {
      setLoading(true);

      const params = {
        page,
        size: PAGE_SIZE,
        ignoringFrom: broadcastListId,
        q
      };

      BroadcastListService.getContacts(params)
        .then((response) => {
          const { count, registers } = response.data;
          setQuantity(count);
          setContacts(registers);
        })
        .catch(() => {
          toast.error('Erro ao listar contatos');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [broadcastListId]
  );

  useEffect(() => {
    if (isModalOpen) {
      handleGetContactsToAdd(1);
    }

    return () => {
      setSelectedRows([]);
      setActivePage(1);
    };
  }, [isModalOpen, handleGetContactsToAdd]);

  useEffect(() => {
    if (selectedRows.length === quantity) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [quantity, selectedRows.length]);

  const handleAddContacts = () => {
    if (!selectedRows?.length) return;

    const clients = selectedRows.map((client) => ({
      id: client.id,
      name: client.nome
    }));
    setSelectedRows([]);

    BroadcastListService.addContactsToBroadcastList(broadcastListId, clients)
      .then(() => {
        toast.success('Contato(s) adicionado(s) com sucesso');
        reloadList(1);
        closeModal();
      })
      .catch((error) => {
        const msg = error?.response?.data?.message;
        toast.error(msg || 'Ocorreu um erro ao adicionar o(s) contato(s)');
      });
  };

  const handleOnSelect = (row, isNotSelectedYet) => {
    if (isNotSelectedYet) {
      const newSelected = {
        id: row.id,
        nome: row.nome,
        id_whatsapp: row.id_whatsapp
      };
      setSelectedRows([...selectedRows, newSelected]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x.id !== row.id));
    }
  };

  const handleOnSelectAll = (isSomeSelected, rows) => {
    if (isSomeSelected) {
      const allRows = [...selectedRows, ...rows].map((r) => {
        return { id: r.id, nome: r.nome, id_whatsapp: r.id_whatsapp };
      });
      setSelectedRows(allRows);
    } else {
      const idsToRemove = rows.map((row) => row.id);
      setSelectedRows((currentSelected) => {
        return currentSelected.filter(({ id }) => !idsToRemove.includes(id));
      });
    }
  };

  const handlePageChange = (activePage) => {
    setActivePage(activePage);
    handleGetContactsToAdd(activePage);
  };

  const handleOnSearch = (event) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const q = event.target.value;
    setTimeoutID(
      setTimeout(() => {
        handleGetContactsToAdd(1, q);
      }, 1000)
    );
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedRows([]);
      setIsAllSelected(false);
      return;
    }

    setIsSeletingAll(true);

    BroadcastListService.getContacts({ ignoringFrom: broadcastListId })
      .then((response) => {
        const { registers } = response.data;
        if (!registers) return;

        const registersSelectionDTO = registers.map((row) => ({
          id: row.id,
          nome: row.nome,
          id_whatsapp: row.id_whatsapp
        }));

        setIsAllSelected(true);
        setSelectedRows(registersSelectionDTO);
      })
      .catch(() => {
        toast.error('Erro ao selecionar todos os contatos');
      })
      .finally(() => {
        setIsSeletingAll(false);
      });
  };

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Adicionar contatos à Campanha Ativa SMS"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      <>
        <Row>
          <Col className="d-flex flex-row justify-content-between">
            <div>
              <strong>
                {selectedRows.length || '0'}
                {`/${quantity} `}
              </strong>
              {selectedRows.length === 1 ? 'novo' : 'novos'} contatos
              selecionados
            </div>
            <ReactButton
              btnColor="confirmation"
              type="submit"
              size="sm"
              disabled={!selectedRows || !selectedRows.length}
              onClick={handleAddContacts}
            >
              Salvar Selecionados
            </ReactButton>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="fas fa-search" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                size="md"
                placeholder="Buscar por nome ou telefone"
                type="text"
                defaultValue=""
                onChange={handleOnSearch}
              />
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-3 pl-3 small">
          <Col>
            <FormGroup check>
              <Label
                style={{
                  cursor: isSeletingAll || quantity < 1 ? 'auto' : 'pointer'
                }}
                check
              >
                <Input
                  type="checkbox"
                  checked={isAllSelected}
                  onChange={handleSelectAll}
                  disabled={isSeletingAll || quantity < 1}
                />
                <span
                  style={{ userSelect: 'none' }}
                >{`Selecionar todos (${quantity})`}</span>
              </Label>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <ToolkitProvider
              data={contacts || []}
              keyField="id"
              loading={loading}
              columns={[
                {
                  dataField: 'nome',
                  text: 'Nome',
                  sort: true,
                  style: individual_column_style,
                  headerStyle: individual_header_style
                },
                {
                  dataField: 'id_zenvia_sms',
                  text: 'Telefone',
                  sort: true,
                  style: individual_column_style,
                  headerStyle: individual_header_style
                }
              ]}
            >
              {(props) => (
                <div className="py-4 table-responsive">
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <BootstrapTable
                      selectRow={{
                        mode: 'checkbox',
                        selected: selectedRows.map((r) => r.id),
                        onSelect: handleOnSelect,
                        onSelectAll: handleOnSelectAll,
                        selectColumnStyle: individual_header_style
                      }}
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  )}
                </div>
              )}
            </ToolkitProvider>
          </Col>
        </Row>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={PAGE_SIZE}
          totalItemsCount={quantity}
          onChange={handlePageChange.bind(this)}
        />
      </>
    </DefaultModal>
  );
};

export default AddContactsModal;
