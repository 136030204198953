import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ReactButton from 'components/Buttons/ReactButton';

import SalesProcessService from '../service';
import { OpportunityStagesConfig } from './components/OpportunityStagesConfig';
import { useListOpportunityStages } from 'views/CRM/OpportunityStages/hooks/useListOpportunityStages';
import FlexSelect from 'components/Inputs/FlexSelect';
import { ScreenPrompt } from 'components/ScreenPrompt';

const SalesProcessForm = () => {
  const [loading, setLoading] = useState(false);
  const [opportunityStagesConfig, setOpportunityStagesConfig] = useState([]);
  const [processTime, setProcessTime] = useState(0);

  const history = useHistory();
  const { id } = useParams();
  const { control, handleSubmit, errors, setValue, reset, watch, formState } =
    useForm();
  const { isDirty } = formState;

  const {
    opportunityStages,
    getOpportunityStages,
    isLoadingOpportunityStages
  } = useListOpportunityStages();

  const selectedOpportunityStages = watch('opportunityStages');

  useEffect(() => {
    getOpportunityStages();
  }, [getOpportunityStages]);

  useEffect(() => {
    let total = 0;
    if (opportunityStagesConfig.length) {
      for (const stage of opportunityStagesConfig) {
        total += stage.time;
      }
    }
    setProcessTime(total);
  }, [opportunityStagesConfig]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      SalesProcessService.getById(id)
        .then((response) => {
          const data = response.data.data;
          reset({
            name: data.name,
            opportunityStages: data?.opportunityStagesConfig?.map(
              (config) => config.opportunityStageId
            )
          });
        })
        .catch((error) => {
          const msg =
            error.response?.data?.message ||
            'Erro ao buscar processo de vendas';
          toast.error(msg);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    const config = opportunityStagesConfig
      .map(({ id }) => id)
      .map((opportunityStageId) => ({
        opportunityStageId,
        type: opportunityStageId === data.type
      }));

    const requestDataDTO = {
      name: data.name,
      opportunityStagesConfig: config
    };

    const savePromise = id
      ? SalesProcessService.update(id, requestDataDTO)
      : SalesProcessService.create(requestDataDTO);

    setLoading(true);

    savePromise
      .then(() => {
        if (id) {
          toast.success('Sucesso ao atualizar o processo de vendas', {
            autoClose: 3000,
            closeOnClick: true
          });
        } else {
          toast.success('Sucesso ao criar o processo de vendas', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
        backToListing();
      })
      .catch((error) => {
        const msg =
          error.response?.data?.message || 'Erro ao criar processo de vendas';
        toast.error(msg);
        setLoading(false);
      });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const backToListing = () => {
    history.push('/admin/crm/salesProcess');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/crm/salesProcess"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row className="mb-3">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Processo de vendas</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">Nome*</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="name"
                          rules={{
                            required: 'Nome é obrigatório',
                            maxLength: {
                              value: 50,
                              message: 'Nome deve ter no máximo 50 caracteres'
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="name"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">Fases*</label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={opportunityStages || []}
                              isMulti={true}
                              value={props.value}
                              valueController={setValue}
                              fieldName="opportunityStages"
                              labelName="name"
                              valueName="id"
                              isClearable={true}
                              disabled={isLoadingOpportunityStages}
                              noOptionsMessage={() =>
                                'Nenhuma fase para ser exibida'
                              }
                            />
                          )}
                          control={control}
                          name="opportunityStages"
                          defaultValue=""
                          rules={{
                            required: 'Selecione pelo menos duas fases',
                            validate: (value) => {
                              if (!value || !value.length)
                                return 'Selecione pelo menos duas fases';
                              return true;
                            }
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="opportunityStages"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col sm="12" style={{ overflowX: 'auto' }}>
                        <OpportunityStagesConfig
                          opportunityStages={opportunityStages}
                          selectedOpportunityStages={selectedOpportunityStages}
                          opportunityStagesConfig={opportunityStagesConfig}
                          setOpportunityStagesConfig={
                            setOpportunityStagesConfig
                          }
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span style={{ float: 'right' }}>
                          Tempo total do processo: {processTime} dia(s)
                        </span>
                      </Col>
                    </Row>
                    <hr />
                    <ReactButton
                      btnColor="confirmation"
                      type="submit"
                      disabled={loading}
                    >
                      Salvar
                    </ReactButton>

                    <ReactButton
                      btnColor="cancelation"
                      onClick={backToListing}
                      disabled={loading}
                    >
                      Cancelar
                    </ReactButton>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SalesProcessForm;
