import React, { useCallback } from 'react';
import DefaultModal from '../../../../../components/Modal/DefaultModal';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Row, Form, Col, Button, Input } from 'reactstrap';

const ModalUpdatePenality = ({
  setSelectedQueues,
  isModalOpen,
  onModalToggle,
  queueUpdate,
  selectedQueues,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  const { control, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      const currentQueues = [...selectedQueues];

      currentQueues.map((queue) => {
        if (queue.value === queueUpdate.value) {
          return (queue.penality = Number(data.penality));
        }
        return null;
      });

      setSelectedQueues([...currentQueues]);
    } catch (err) {
      console.log('err', err);
    } finally {
      closeModal();
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Alterar penalidade nesta fila para esse usuário"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col md="12">
              <label className="form-control-label">Penalidade</label>
              <Controller
                as={Input}
                control={control}
                type="number"
                min={0}
                defaultValue={`${queueUpdate.penality}`}
                name={'penality'}
              />
              <ErrorMessage
                errors={errors}
                name={'penality'}
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>
          <hr />
          <Button color="primary" type="submit">
            Salvar
          </Button>
          <Button color="danger" onClick={closeModal}>
            Voltar
          </Button>
        </Form>
      </>
    </DefaultModal>
  );
};

export default ModalUpdatePenality;
