import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import Service from '../service';
import ExtensionService from '../service/extensionService';
import { toast } from 'react-toastify';
//import { ddrRegex } from './regexs';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const SectorForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [extensions, setExtensions] = useState([]);

  const { id } = props.match.params;

  //set to select Extensions
  useEffect(() => {
    ExtensionService.getAllSimple()
      .then((res) => {
        setExtensions(res.data.data);
      })
      .catch(() => {
        toast.error('Erro ao tentar carregar os ramais', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      Service.get(id)
        .then((res) => {
          const getSector = res.data.data;
          reset(getSector);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar Chefe Secretária', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      if (data.extension_boss === data.extension_secretary) {
        toast.error(' Os ramais devem ser diferentes !');
        return;
      }
      setLoading(true);
      isUpdate ? await Service.update(id, data) : await Service.create(data);
      history.push('/admin/ChiefSecretary');
      if (isUpdate) {
        // setLoading(false);
        toast.info('Chefe Secretária foi atualizado com sucesso!');
      } else {
        // setLoading(false);
        toast.success('Chefe Secretária foi cadastrado com sucesso!');
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/ChiefSecretary');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/ChiefSecretary');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/ChiefSecretary"
        buttonTitle="Voltar para a pagina de chefe secretária"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Chefe Secretária</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Ramal do chefe*
                          </label>
                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                {extensions.map((extension, index) => (
                                  <option
                                    key={index}
                                    value={extension.extension_number}
                                  >
                                    {extension.extension_number}
                                  </option>
                                ))}
                              </Input>
                            }
                            control={control}
                            name="extension_boss"
                            rules={{
                              required: 'Selecione um ramal'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="extension_boss"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Ramal Secretária*
                          </label>
                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                {extensions.map((extension, index) => (
                                  <option
                                    key={index}
                                    value={extension.extension_number}
                                  >
                                    {extension.extension_number}
                                  </option>
                                ))}
                              </Input>
                            }
                            control={control}
                            name="extension_secretary"
                            rules={{
                              required: 'Selecione um ramal'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="extension_secretary"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SectorForm;
