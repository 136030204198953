import { apiChat } from 'http/index';
import ServiceCRUD from '../../../../../services/ServiceCRUDCHANNEL';

class GetConversationService extends ServiceCRUD {
  constructor() {
    super('monitoramento');
  }

  async getMonitoringData({ queryParams = {} }) {
    return apiChat.get(`/monitoramento`, { params: queryParams });
  }
}

export default new GetConversationService();
