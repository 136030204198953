import React from 'react';
import TooltipTop from './style';
import { UncontrolledTooltip } from 'reactstrap';

export function OutlineTooltip({ placement, message, target }) {
  let CustomTooltip;
  switch (placement) {
    case 'bottom':
      CustomTooltip = TooltipTop;
      break;

    default:
      CustomTooltip = UncontrolledTooltip;
  }
  return (
    <CustomTooltip placement={placement} target={target}>
      {message}
    </CustomTooltip>
  );
}
