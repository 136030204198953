import { useState, useEffect } from 'react';
import ScaleService from '../views/Scales/service';

export function useGetScales() {
  const [scales, setScales] = useState([]);

  useEffect(() => {
    ScaleService.getAll()
      .then((response) => {
        setScales(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);

  return {
    scales,
    setScales
  };
}
