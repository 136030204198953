import { v4 as uuid } from 'uuid';
import { typesOfLogouts } from '../utils/types';
export function formattedValuesToList(data, reportType) {
  if (reportType === 'analytical') {
    const actions = new Map();
    actions.set(typesOfLogouts[0].value, typesOfLogouts[0].label);
    actions.set(typesOfLogouts[1].value, typesOfLogouts[1].label);
    actions.set(typesOfLogouts[2].value, typesOfLogouts[2].label);
    actions.set(typesOfLogouts[3].value, typesOfLogouts[3].label);
    actions.set(typesOfLogouts[4].value, typesOfLogouts[4].label);
    actions.set('login', 'Login');

    const formattedValues = data.map((row) => {
      let formattedDateIn = '';
      let formattedDateOut = '';

      if (row.date_in) {
        const date_in = new Date(row.date_in);
        formattedDateIn = `${date_in.toLocaleDateString(
          'pt-br'
        )} ${date_in.toLocaleTimeString('pt-br')}`;
      }

      if (row.date_out) {
        const date_out = new Date(row.date_out);
        formattedDateOut = `${date_out.toLocaleDateString(
          'pt-br'
        )} ${date_out.toLocaleTimeString('pt-br')}`;
      }

      return {
        uuid: uuid(),
        usrLogin: row.usr_login,
        action: actions.get(`${row.logout_type}`),
        formattedDateIn,
        formattedDateOut,
        elapsed_time: row.elapsed_time
      };
    });

    return formattedValues;
  } else if (reportType === 'synthetic') {
    const formattedValues = data.map((row) => {
      return {
        uuid: uuid(),
        usrLogin: row.usr_login,
        total_elapsed_time: row.total_elapsed_time,
        total_sessions: row.total_sessions
      };
    });

    return formattedValues;
  }
}
