/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import SimpleHeaderOffButton from 'components/Headers/SimpleHeaderOffButton';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'components/Pagination';
import OverTimesService from '../../ExtraHour/service/';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Card,
  Button,
  CardHeader,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
  Label
} from 'reactstrap';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';
import { useSelector } from 'react-redux';
import { IconLogoHeader } from '../../Scales/style/style';
import { IoIosHourglass } from 'react-icons/io';

export default function SupervisorExtraHour() {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const login = localStorage.getItem('login');
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showDisabled, setShowDisabled] = useState(false);

  async function loadApi(page = 1, querySearch = '') {
    setLoading(true);
    const size = 10;
    OverTimesService.getAllSearchSupervisor(
      showDisabled,
      size,
      page,
      querySearch
    ).then((response) => {
      setQuantity(response.data.totalRegisters);
      const data = response.data.data;
      setData(data);
      setLoading(false);
    });
  }
  useEffect(() => {
    loadApi();
  }, [showDisabled]);

  const toISOFormat = (dateNotFormmated) => {
    if (dateNotFormmated === undefined) {
      console.log('A data está vazia');
    } else {
      const [date] = dateNotFormmated.split('T');
      const [YYYY, MM, DD] = date.split('-');
      return `${DD}/${MM}/${YYYY}`;
    }
  };
  const ControlerStatus = (id, status) => {
    try {
      const data = { status: status };
      if (data.status === 'APPROVED') {
        OverTimesService.patch(id, data).finally(() => loadApi());
      }
      if (data.status === 'REJECTED') {
        OverTimesService.patch(id, data).finally(() => loadApi());
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    loadApi(page);
  };
  return (
    <>
      <SimpleHeaderOffButton name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    alignItems: 'center'
                  }}
                >
                  <IconLogoHeader
                    backgroundColor={systemConfiguration.primary_color}
                  >
                    <IoIosHourglass color="white" size={23} />
                  </IconLogoHeader>
                  <h3>Supervisor Hora Extra</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Buscar Hora Extra"
                          type="text"
                          onChange={'handleOnSearch'}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="4" className="text-right mt-2 ">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={showDisabled}
                            onChange={() => setShowDisabled(!showDisabled)}
                          />
                          <span>Mostrar desabilitados</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={data}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'attendantName',
                      text: 'solicitante',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'date',
                      text: 'data',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        const response = toISOFormat(row.date);
                        return response;
                      }
                    },
                    {
                      dataField: 'period',
                      text: 'Período',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'status',
                      text: 'status',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        const objectTest = {
                          PENDING: 'Pendente',
                          APPROVED: 'Aprovado',
                          REJECTED: 'Rejeitado',
                          FINISHED: 'Finalizado'
                        };
                        return <>{objectTest[row.status]}</>;
                      }
                    },
                    {
                      dataField: 'aprove',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Aprovar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        const isAprove = row.status !== 'PENDING';
                        return (
                          <Button
                            color="success"
                            size="sm"
                            title={'Confirmar hora extra'}
                            disabled={isAprove}
                            onClick={() => ControlerStatus(row.id, 'APPROVED')}
                          >
                            <li className="fas fa-check-circle"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Rejeitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        const isReject = row.status !== 'PENDING';

                        return (
                          <Button
                            color="danger"
                            size="sm"
                            title={'Rejeitar hora extra'}
                            disabled={isReject}
                            onClick={() => ControlerStatus(row.id, 'REJECTED')}
                          >
                            <li className="fas fa-times"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
