import ServiceCRUD from '../../../../services/ServiceCRUDNEWCHANNEL';
import { apiInstagramChat as http } from '../../../../http';

class ConversationReportService extends ServiceCRUD {
  constructor() {
    super('configurations/channels');
  }

  putCanal(path, canal) {
    return http.put(`configurations/${path}`, canal);
  }

  postCanal(path, canal) {
    return http.post(`configurations/${path}`, canal);
  }

  getWhatson() {
    return http.get(`configurations/watson`);
  }

  postWhatson(path, canal) {
    return http.post(`configurations/${path}`, canal);
  }

  getWhatsonById(id) {
    return http.get(`configurations/watson/` + id);
  }
  // updateConfigProtocolChat(data) {
  //   return http.put(
  //     `configuracao/api/protocolo/criarProtocoloParaConversa`,
  //     data
  //   );
  // }

  // getConfigProtocolChat() {
  //   return http.get(`configuracao/api/protocolo/criarProtocoloParaConversa`);
  // }

  // updateTimeForEndConversation(time) {
  //   return http.put(`configuracao/api/encerramento/tempo`, { time });
  // }

  // getWaitingTime() {
  //   return http.get(`configuracao/api/encerramento/tempo`);
  // }
}

export default new ConversationReportService();
