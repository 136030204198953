import React from 'react';
import { TotalProtocolsPerUser } from './components/TotalProtocolsPerUser/TotalProtocolsPerUser';
import { TotalProtocolsPerDay } from './components/TotalProtocolsPerMonth/TotalProtocolsPerMonth';
import { TotalProtocolsPerStatus } from './components/TotalProtocolsPerStatus/TotalProtocolsPerStatus';
import { TotalProtocolsPerReason } from './components/TotalProtocolsPerReason/TotalProtocolsPerReason';
import { TotalProtocolsPerSubreason } from './components/TotalProtocolsPerSubreason/TotalProtocolsPerSubreason';
import { TotalProtocolsPerCustomField } from './components/TotalProtocolsPerCustomField/TotalProtocolsPerCustomField';

export function Graphics({ protocolsData }) {
  return (
    <>
      <TotalProtocolsPerUser protocolsData={protocolsData} />
      <TotalProtocolsPerDay protocolsData={protocolsData} />
      {protocolsData?.totalProtocolsPerStatus && (
        <TotalProtocolsPerStatus protocolsData={protocolsData} />
      )}
      {protocolsData?.totalProtocolsPerReason && (
        <TotalProtocolsPerReason protocolsData={protocolsData} />
      )}
      {protocolsData?.totalProtocolsPerSubReason && (
        <TotalProtocolsPerSubreason protocolsData={protocolsData} />
      )}
      {protocolsData?.reportCustomFields &&
        protocolsData?.reportCustomFields.map((campo, index) => {
          return (
            <TotalProtocolsPerCustomField
              key={index}
              protocolsData={campo.data}
              title={campo.title}
            />
          );
        })}
    </>
  );
}
