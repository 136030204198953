import React, { useMemo } from 'react';
import { UncontrolledPopover, PopoverBody } from 'reactstrap';

export const ClientNameWrapper = ({ name, telephone, email }) => {
  const identifier = useMemo(() => {
    const randomValue = Math.random() + 1;
    return randomValue.toString(36).substring(3);
  }, []);

  return (
    <>
      <span id={`popover-${identifier}`}>{name}</span>
      <UncontrolledPopover
        placement="bottom"
        target={`popover-${identifier}`}
        trigger="click hover focus"
      >
        <PopoverBody>
          {!telephone && !email ? (
            'Nenhum dado encontrado'
          ) : (
            <>
              <div>{`Telefone: ${telephone || ''}`}</div>
              <div>{`Email: ${email || ''}`}</div>
            </>
          )}
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};
