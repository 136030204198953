import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, Row } from 'reactstrap';
import { useLocation } from 'react-router';

import Loader from 'components/Loader';
import { useRecordTypesByUser } from 'views/RecordType/hooks/useRecordTypesByUser';
import RecordTypeSelection from 'views/RecordType/components/RecordTypeSelection';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import HandleImport from './HandleImport';

function ImportAccountsModal(props) {
  const [selectedRecordTypeId, setSelectedRecordTypeId] = useState(null);
  const [shouldShowResource, setShouldShowResource] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const { recordTypes, isLoadingRecordTypes, getRecordTypesByUser } =
    useRecordTypesByUser(entitiesName.ACCOUNT);
  const { state } = useLocation();

  useEffect(() => {
    getRecordTypesByUser();
  }, [getRecordTypesByUser]);

  useEffect(() => {
    if (recordTypes && recordTypes.length === 1) {
      setSelectedRecordTypeId(recordTypes[0].id);
      setShouldShowResource(true);
    }
  }, [recordTypes]);

  /**
   * Quando a rota é chamada e deve-se abrir diretamente a listagem, sem
   * selecionar manualmente o tipo de registro.
   */
  useEffect(() => {
    if (state && state.recordTypeId) {
      setSelectedRecordTypeId(state.recordTypeId);
      setShouldShowResource(true);
    }
  }, [state]);

  useEffect(() => {
    if (!isOpen) {
      setSelectedRecordTypeId(null);
      setShouldShowResource(false);
    }
  }, [isOpen]);

  const shouldShowRecordTypeSelection = useCallback(() => {
    return isOpen && (isLoadingRecordTypes || !shouldShowResource);
  }, [isOpen, isLoadingRecordTypes, shouldShowResource]);

  const handleOnSelectedRecordType = useCallback((recordTypeId) => {
    setSelectedRecordTypeId(recordTypeId);
    setShouldShowResource(true);
  }, []);

  const renderWithBaseLayout = (children) => {
    return (
      <>
        {props.buttonOpenModal({
          onClick: () => setIsOpen(!isOpen)
        })}
        <Modal
          className="modal-dialog-centered"
          isOpen={isOpen}
          returnFocusAfterClose={false}
          unmountOnClose={true}
        >
          {children && (
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-default">
                Importar Contas
              </h6>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => {
                  setIsOpen(!isOpen);
                }}
              >
                <span aria-hidden={true}>&times;</span>
              </button>
            </div>
          )}
          {children}
        </Modal>
      </>
    );
  };

  const render = () => {
    if (!recordTypes || !recordTypes.length)
      return renderWithBaseLayout(
        <Row className="justify-content-md-center">
          <Loader />
        </Row>
      );

    if (shouldShowRecordTypeSelection()) {
      return renderWithBaseLayout(
        <ModalBody>
          <RecordTypeSelection
            recordTypes={recordTypes}
            handleOnSelectedRecordType={handleOnSelectedRecordType}
          />
        </ModalBody>
      );
    }

    if (!isOpen) return renderWithBaseLayout(null);

    return renderWithBaseLayout(
      <HandleImport
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        recordTypeId={selectedRecordTypeId}
      />
    );
  };

  return render();
}

export default ImportAccountsModal;
