export const formatCurrency = (value) => {
  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  const integer = Math.floor(value / 100);
  const decimals = value % 100;
  const fullValue = `${integer}.${decimals < 10 ? `0${decimals}` : decimals}`;

  return formatter.format(fullValue);
};
