import styled from 'styled-components';

export const ContainerWordCloud = styled.div`
  margin-top: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ImageWordCloud = styled.img`
  width: 60vw;
`;

export const CardCabecalho = styled.div`
  display: flex;
  justify-content: right;
`;
