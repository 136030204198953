import styled from 'styled-components';
import { shade } from 'polished';

export const Cantainer = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  border-collapse: collapse;

  /* @media (max-width: 800px) {
    flex-direction: row;
  } */

  overflow-x: scroll;
  overflow-y: hidden;

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border: 1px solid #ddd;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 10px;
  }

  .contentCheckBox {
    display: flex;
    margin-top: 30px;
    ${(props) => props.color && `border-left: 2px solid ${props.color};`}
    align-items: center;
  }

  #chipTime {
    //background: #4682b4;
    background: red;
    color: #fff;
    border-radius: 7px;
  }

  .cardAction {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    padding: 10px;

    input {
      max-width: 90px;
    }

    p {
      font-size: 12px;
      font-family: sans-serif;
    }
  }

  .cardTime {
    margin-top: 20px;
    display: flex;
    align-items: center;
    border: 1px solid red;

    input {
      max-width: 90px;
    }

    p {
      font-size: 12px;
      color: red;
      font-family: sans-serif;
    }
  }

  .contentTime {
    ${(props) => props.color && `border: 1px solid ${props.color};`}
    border-radius: 7px;
    margin-top: 20px;
    padding: 10px;
    font-size: 12px;
  }

  .contentCheckBox p {
    margin-bottom: 1px;
    font-family: sans-serif;
    font-size: 12px;
  }

  .iconTime {
    max-width: 100px;
  }

  .chipTrue {
    background: #00a17c;
    color: #fff;
    border-radius: 7px;
    font-size: 12px;
  }

  .chipFalse {
    background: #ee6363;
    color: #fff;
    border-radius: 7px;
    font-size: 12px;
  }

  .checkbox {
    height: 16px;
    width: 16px;
  }

  .contentMessages {
    font-size: 14px;

    .titleMsg {
      margin-top: 20px;
    }

    label {
      margin-top: 40px;
      margin-bottom: 1px;
    }
  }
`;

export const Head = styled.thead`
  tr th {
    font-size: 14px;
  }
`;

export const Body = styled.tbody`
  width: 100%;
  padding: 10px 0px;
  color: #000;
  margin-right: 0px;

  tr td {
    font-size: 14px;
  }

  .chipTrue {
    background: #00a17c;
    color: #fff;
  }

  /* button {
    background: #11cdef;
    transition: ease 0.3s;
    color: #fff;
    border-radius: 5px;
  } */

  svg {
    margin-right: 5px;
  }

  /* button:focus {
    outline-style: none;
    background: ${shade(0.3, '#11cdef')};
  }

  button:hover {
    background: ${shade(0.3, '#11cdef')};
    color: #fff;
  } */

  .chipFalse {
    background: #ee6363;
    color: #fff;
  }
`;

export const ChipTime = styled.div`
  //width: 18.75rem;
  display: flex;

  input {
    max-width: 90px;
  }

  p {
    font-size: 12px;
    font-family: sans-serif;
  }

  .chipTime {
    background: #4682b4;
    color: #fff;
    border-radius: 7px;
    margin-bottom: 10px;
  }
`;

export const ContentCheckbox = styled.div`
  margin-left: 20px;

  //border-left: 2px solid red;
  display: flex;
  margin-top: 30px;
  ${(props) => props.color && `border-left: 2px solid ${props.color};`}
  align-items: center;

  label {
    display: block;
    font-size: 0.875rem;
  }

  .chipFalse {
    background: #ee6363;
    color: #fff;
  }

  .chipTrue {
    background: #00a17c;
    color: #fff;
  }

  .checkbox {
    height: 16px;
    width: 16px;
  }
`;

export const TagFilas = styled.div`
  font-size: 13px;
  font-weight: bold;
  margin-right: 0.5rem;
  padding: 2px 6px;
  border-radius: 6px;
  color: #fff;
  background-color: #4682b4;
  max-width: fit-content;
  margin: 0.5rem 0.5rem;
`;
