import React, { useState, useEffect } from 'react';
import { Input, Button, Row, Col, Form, FormGroup, Label } from 'reactstrap';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import DialerService from '../service';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import ReactLoader from 'components/Loader';
import { sheetReader } from 'utils/fuctions/sheetReader.js';
import { generateSheetHeaders } from 'utils/fuctions/generateSheetHeaders.js';
import { defaultFields } from '../utils/defaultFields';
import { defaultOptionalFields } from '../utils/defaultOptionalFields';
import { generateTableFromListOfObjects } from '../utils/generateTableFromListOfObjects';
// import { allowedCSVMimeTypes } from './allowedCSVMimeTypes';

import { Item } from './components/styled';
import { ReactSortable } from 'react-sortablejs';

const MailingModal = ({
  dialerId,
  queueId,
  importType,
  integrationUrl,
  disabled
}) => {
  const { control, handleSubmit, setValue, watch } = useForm();
  const [CSVFile, setCSVFile] = useState(null);
  const [mallingData, setMallingData] = useState({});
  const [columns, setColumns] = useState([]);
  const [hasHeader, setHasHeader] = useState(true);
  const [fields, setFields] = useState(defaultFields);
  const [optionalFields, setOptionalFields] = useState(defaultOptionalFields);
  const [loading, setLoading] = useState(true);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [isOpen, setIsOpen] = useState(false);
  const [stateOrder, setStateOrder] = useState([]);

  const selectedFields = fields.map((field) => {
    return watch(field.name);
  });
  const ordenationField = watch('ordenationField');

  useEffect(() => {
    const csvData = mallingData.parsedCsv;
    const orderFields = [];
    const orderAux = [];

    if (csvData && ordenationField) {
      const fieldIndex = csvData[0].indexOf(ordenationField);

      for (let i = 1; i < csvData.length; i++) {
        const fieldValue = csvData[i][fieldIndex];
        const isNewGroup = !orderAux.includes(fieldValue);

        if (isNewGroup) {
          orderAux.push(fieldValue);
          orderFields.push({ id: i, name: fieldValue });
        }
      }

      setStateOrder(orderFields);
    }
  }, [ordenationField, mallingData]);

  useEffect(() => {
    if (importType === 'integration' && isOpen) {
      const fetchIntegrationUrlData = async () => {
        const { url, method, headers: headersRaw } = integrationUrl;
        const headers = headersRaw
          .split(';')
          .map((e) => e.split(': '))
          .reduce((prev, [k, v]) => {
            prev[k] = v;

            return prev;
          }, {});

        const res = await axios.request({ url, method, headers });

        return res;
      };

      fetchIntegrationUrlData()
        .then((res) => {
          const table = generateTableFromListOfObjects(
            res.data.data || res.data
          );

          setMallingData({
            parsedCsv: table,
            dialer_id: dialerId,
            queue_id: queueId
          });
          setColumns([...table[0].map((e) => ({ col: e }))]);
          setCSVFile(res);
        })
        .finally(() => setLoading(false));
    }
  }, [isOpen, dialerId, importType, queueId, integrationUrl]);

  const changeFile = async (event) => {
    setHasHeader(true);
    setFields(defaultFields);
    setOptionalFields(defaultOptionalFields);

    const file = event.target.files[0];

    if (!file) return;

    const data = await sheetReader(file);
    const parsedData = data
      .trim()
      .split(/\r?\n|\r/)
      .map((item) => item.split(/\t/))
      .filter((item) => item.length >= 3);

    setMallingData({
      parsedCsv: parsedData,
      dialer_id: dialerId,
      queue_id: queueId
    });
    setColumns([...parsedData[0].map((e) => ({ col: e }))]);
    setCSVFile(file);
  };

  const changeHasHeader = (e) => {
    let parsedData = [];

    if (!e.target.checked) {
      const headers = generateSheetHeaders(mallingData.parsedCsv[0].length);

      parsedData = [headers, ...mallingData.parsedCsv];
    } else {
      parsedData = mallingData.parsedCsv;

      parsedData.shift();
    }

    setMallingData({
      ...mallingData,
      parsedCsv: parsedData
    });
    setColumns([...parsedData[0].map((e) => ({ col: e }))]);
    setHasHeader(e.target.checked);
  };

  const isValidFile = () => {
    if (!CSVFile) return false;
    return true;
    // console.log('CSVFile.type', CSVFile.type);
    // return allowedCSVMimeTypes.includes(CSVFile.type) ? true : false;
  };

  const resetStateRelatedWithFile = () => {
    setCSVFile(null);
    setMallingData({});
    setColumns([]);
    setHasHeader(true);
    setFields(defaultFields);
    setOptionalFields(defaultOptionalFields);
    setLoading(true);
  };

  function getOrder(group, priorityArray) {
    priorityArray.forEach((priority) => {
      if (priority.name === group) {
        group = priority.priority;
      }
    });

    return group;
  }

  function formatPriority(serializedData) {
    if (ordenationField && stateOrder.length > 0) {
      const newSerializedData = [];
      const priorityArray = [];

      if (stateOrder.length > 0) {
        stateOrder.forEach((order, index) => {
          priorityArray.push({
            ...order,
            priority: stateOrder.length - (index + 1)
          });
        });
      }

      const indexPriority = serializedData[0].indexOf(ordenationField);

      serializedData.forEach((row, rowIndex) => {
        if (rowIndex === 0) {
          row[indexPriority] = 'prioridade';
        } else {
          row[indexPriority] = getOrder(row[indexPriority], priorityArray);
        }

        newSerializedData.push(row);
      });

      return newSerializedData;
    } else {
      return serializedData;
    }
  }

  const onSubmitCSV = (data) => {
    delete data.ordenationField;
    if (!isValidFile) return;

    const colsMap = [];
    const colsVisualizationMap = [];

    const serializedData = [];
    const serializedVisualizationData = [];

    mallingData.parsedCsv.forEach((row, rowIndex) => {
      const r = [];
      const rView = [];

      if (rowIndex === 0) {
        row.forEach((col, colIndex) => {
          for (const key in data) {
            if (data[key] === col) {
              colsMap.push(colIndex);
              r.push(key);
            }
          }

          for (const key in data.visualizationFields) {
            if (data.visualizationFields[key] === col) {
              colsVisualizationMap.push(colIndex);
              rView.push(col);
            }
          }
        });

        if (ordenationField) {
          colsMap.push(row.indexOf(ordenationField));
          r.push(ordenationField);
        }
      } else {
        colsMap.forEach((e) => {
          const col = row[e];

          r.push(
            col.match(/^[^A-z]+$/g) ? col.replaceAll(/[+()-.\s]/g, '') : col
          );
        });

        colsVisualizationMap.forEach((e) => {
          const col = row[e];

          rView.push(
            col.match(/^[^A-z]+$/g) ? col.replaceAll(/[+()-.\s]/g, '') : col
          );
        });
      }

      serializedData.push(r);
      serializedVisualizationData.push(rView);
    });

    mallingData.parsedCsv = formatPriority(serializedData);
    mallingData.parsedVizualizationCsv = serializedVisualizationData;

    DialerService.createMalling(mallingData)
      .then(() => {
        toast.success('Lista de contatos salva', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data) ||
          'Erro ao salvar lista de contatos';
        toast.success(message, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        toggleModal();
        resetStateRelatedWithFile();
      });
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const getEnabledFields = () => {
    let enabledFields = [];

    columns.forEach((column) => {
      let enabled = true;

      selectedFields.forEach((field) => {
        if (column.col === field) {
          enabled = false;
        }
      });

      if (enabled === true) {
        enabledFields.push(column);
      }
    });

    return enabledFields;
  };

  return (
    <>
      <Button
        size="sm"
        color="success"
        title="Importar CSV"
        onClick={toggleModal}
        disabled={disabled}
      >
        <i className="far fa-file"></i>
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={() => {
          toggleModal();
          resetStateRelatedWithFile();
        }}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Importar Lista de contatos
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => {
              toggleModal();
              resetStateRelatedWithFile();
            }}
          >
            <span aria-hidden={true}>&times;</span>
          </button>
        </div>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmitCSV)}>
            {(() => {
              switch (importType) {
                case 'file':
                  return (
                    <>
                      <label className="form-control-label d-block mb-3">
                        Insira um arquivo do tipo CSV, XLS ou XLSX
                      </label>
                      <label
                        htmlFor="import_mailing"
                        className="btn btn-primary btn-sm"
                        style={buttonStyle(systemConfiguration.primary_color)}
                      >
                        Selecionar um arquivo
                      </label>
                      {CSVFile && CSVFile.name}
                      <Input
                        style={{ display: 'none' }}
                        type="file"
                        id="import_mailing"
                        name="import_mailing"
                        onChange={changeFile}
                        accept=".csv, .xls, .xlsx"
                      />
                      {CSVFile && (
                        <>
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                name="hasHeader"
                                checked={hasHeader}
                                onChange={changeHasHeader}
                              />
                              Utilizar primeira linha como cabeçalho
                            </Label>
                          </FormGroup>
                          {fields.map((e) => (
                            <Controller
                              key={e.name}
                              control={control}
                              name={e.name}
                              defaultValue=""
                              render={(props) => (
                                <FormGroup>
                                  <Label className="form-control-label">
                                    {e.label}
                                  </Label>
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={columns}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName={e.name}
                                    labelName="col"
                                    valueName="col"
                                  />
                                </FormGroup>
                              )}
                            />
                          ))}
                          <div>
                            <span style={{ paddingRight: '5px' }}>
                              Associar mais um número
                            </span>
                            <Button
                              type="button"
                              size="sm"
                              color="default"
                              onClick={() => {
                                if (optionalFields.length > 0) {
                                  const optionalFieldsCopy = [
                                    ...optionalFields
                                  ];
                                  const field = optionalFieldsCopy.shift();

                                  setOptionalFields(optionalFieldsCopy);
                                  setFields([...fields, field]);
                                }
                              }}
                            >
                              <li className="fas fa-plus"></li>
                            </Button>
                          </div>
                          {columns && (
                            <Row className="mt-4">
                              <Col>
                                <Controller
                                  key="visualizationFields"
                                  control={control}
                                  name="visualizationFields"
                                  defaultValue=""
                                  render={(props) => (
                                    <FormGroup>
                                      <Label className="form-control-label">
                                        Campos para visualização
                                      </Label>
                                      <FlexSelect
                                        isClearable={true}
                                        dataOptions={getEnabledFields()}
                                        isMulti={true}
                                        closeMenuOnSelect={false}
                                        value={props.value}
                                        valueController={setValue}
                                        fieldName="visualizationFields"
                                        labelName="col"
                                        valueName="col"
                                      />
                                    </FormGroup>
                                  )}
                                />
                              </Col>
                            </Row>
                          )}

                          {columns && (
                            <>
                              <Row>
                                <Col>
                                  <Controller
                                    key="ordenationField"
                                    control={control}
                                    name="ordenationField"
                                    defaultValue=""
                                    render={(props) => (
                                      <FormGroup>
                                        <Label className="form-control-label">
                                          Campo de ordenação
                                        </Label>
                                        <FlexSelect
                                          isClearable={true}
                                          dataOptions={columns}
                                          isMulti={false}
                                          closeMenuOnSelect={true}
                                          value={props.value}
                                          valueController={setValue}
                                          fieldName="ordenationField"
                                          labelName="col"
                                          valueName="col"
                                        />
                                      </FormGroup>
                                    )}
                                  />
                                </Col>
                              </Row>

                              {ordenationField && stateOrder && (
                                <Row className="justify-content-md-center">
                                  <div style={{ width: '85%' }}>
                                    <ReactSortable
                                      list={stateOrder}
                                      setList={setStateOrder}
                                    >
                                      {stateOrder.map((item) => (
                                        <Item key={`${item.id}-${item.name}`}>
                                          <h5>{item.name.toUpperCase()}</h5>
                                        </Item>
                                      ))}
                                    </ReactSortable>
                                  </div>
                                </Row>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  );

                case 'integration':
                  return loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <>
                      {fields.map((e) => (
                        <Controller
                          key={e.name}
                          control={control}
                          name={e.name}
                          defaultValue=""
                          render={(props) => (
                            <FormGroup>
                              <Label className="form-control-label">
                                {e.label}
                              </Label>
                              <FlexSelect
                                isClearable={true}
                                dataOptions={columns}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName={e.name}
                                labelName="col"
                                valueName="col"
                              />
                            </FormGroup>
                          )}
                        />
                      ))}
                      <div>
                        <span style={{ paddingRight: '5px' }}>
                          Associar mais um número
                        </span>
                        <Button
                          type="button"
                          size="sm"
                          color="default"
                          onClick={() => {
                            if (optionalFields.length > 0) {
                              const optionalFieldsCopy = [...optionalFields];
                              const field = optionalFieldsCopy.shift();

                              setOptionalFields(optionalFieldsCopy);
                              setFields([...fields, field]);
                            }
                          }}
                        >
                          <li className="fas fa-plus"></li>
                        </Button>
                      </div>

                      {columns && (
                        <Row className="mt-4">
                          <Col>
                            <Controller
                              key="visualizationFields"
                              control={control}
                              name="visualizationFields"
                              defaultValue=""
                              render={(props) => (
                                <FormGroup>
                                  <Label className="form-control-label">
                                    Campos para visualização
                                  </Label>
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={getEnabledFields()}
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="visualizationFields"
                                    labelName="col"
                                    valueName="col"
                                  />
                                </FormGroup>
                              )}
                            />
                          </Col>
                        </Row>
                      )}

                      {columns && (
                        <>
                          <Row>
                            <Col>
                              <Controller
                                key="ordenationField"
                                control={control}
                                name="ordenationField"
                                defaultValue=""
                                render={(props) => (
                                  <FormGroup>
                                    <Label className="form-control-label">
                                      Campo de ordenação
                                    </Label>
                                    <FlexSelect
                                      isClearable={true}
                                      dataOptions={columns}
                                      isMulti={false}
                                      closeMenuOnSelect={true}
                                      value={props.value}
                                      valueController={setValue}
                                      fieldName="ordenationField"
                                      labelName="col"
                                      valueName="col"
                                    />
                                  </FormGroup>
                                )}
                              />
                            </Col>
                          </Row>

                          {ordenationField && stateOrder && (
                            <Row className="justify-content-md-center">
                              <div style={{ width: '85%' }}>
                                <ReactSortable
                                  list={stateOrder}
                                  setList={setStateOrder}
                                >
                                  {stateOrder.map((item) => (
                                    <Item key={`${item.id}-${item.name}`}>
                                      <h5>{item.name.toUpperCase()}</h5>
                                    </Item>
                                  ))}
                                </ReactSortable>
                              </div>
                            </Row>
                          )}
                        </>
                      )}
                    </>
                  );

                default:
                  return null;
              }
            })()}
            <Row className="mt-5">
              <Col>
                <ReactButton
                  btnColor="confirmation"
                  type="submit"
                  disabled={!isValidFile()}
                >
                  Importar
                </ReactButton>
                <ReactButton
                  btnColor="cancelation"
                  onClick={() => {
                    toggleModal();
                    resetStateRelatedWithFile();
                  }}
                >
                  Cancelar
                </ReactButton>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MailingModal;
