import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import Service from '../service';
import ContextService from '../service/contextService';
import { TypesTrunk } from './typesTrunk';
import { toast } from 'react-toastify';
import { ddrRegex } from './regex';
import { FiPlus, FiDelete } from 'react-icons/fi';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  Button,
  CardBody
} from 'reactstrap';

import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const DigitalForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const {
    reset,
    control,
    handleSubmit,
    errors,
    watch,
    register,
    unregister,
    formState
  } = useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [context, setContext] = useState([]);
  const [channels, setChannels] = useState([]);
  const technology = watch('technology');

  const { id } = props.match.params;

  //set to select context
  useEffect(() => {
    ContextService.getAll()
      .then((res) => {
        setContext(res.data.data);
      })
      .catch(() => {
        toast.error('Erro ao tentar carregar os contextos', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      Service.get(id)
        .then((res) => {
          const getTronco = res.data.data;
          reset(getTronco);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar Tronco', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const processChannels = (dataForm) => {
    let arr = [];
    let obj = {};
    let c = 0;
    const arrayOfKeys = Object.keys(dataForm);
    arrayOfKeys.forEach((attribute) => {
      if (
        attribute === 'name' ||
        attribute === 'technology' ||
        attribute === 'context' ||
        attribute === 'group_value' ||
        attribute === 'final_ddr' ||
        attribute === 'initial_ddr' ||
        attribute === 'key_number'
      )
        delete dataForm[attribute];
    });

    for (const [key, value] of Object.entries(dataForm)) {
      if (key.indexOf('device') > -1) {
        obj['device'] = value;
      }
      if (key.indexOf('channel') > -1) {
        obj['channel'] = value;
      }

      c++;
      if (c === 2) {
        arr.push(obj);
        obj = {};
        c = 0;
      }
    }
    console.log(arr);
    const groupValues = arr.reduce((prevValue, currentValues, index) => {
      const { device, channel } = currentValues;
      if (index === 0) {
        return `b${device}c${channel}`;
      }
      return `${prevValue}+b${device}c${channel}`;
    }, '');

    return groupValues;
  };

  const addChannel = () => {
    const length = channels.length;
    if (length >= 5) return;
    const device = `device${channels.length}`;
    const channel = `channel${channels.length}`;
    const teste = {
      device: device,
      channel: channel
    };

    setChannels([...channels, teste]);
  };

  const removeChannel = () => {
    const currentValues = [...channels];
    const deleted = currentValues.pop();
    unregister(deleted);
    setChannels(currentValues);
  };

  const defineSize = () => {
    if (technology === 'KHOMP') return 12;
    return 6;
  };

  const onSubmit = async (data) => {
    try {
      const dataFromForm = { ...data };
      let dataSubmit;
      if (data.technology === 'KHOMP') {
        let groupValues;
        if (!isUpdate) {
          groupValues = processChannels(dataFromForm);
        } else {
          groupValues = data.group_value;
        }
        dataSubmit = {
          name: data.name,
          context: data.context,
          technology: data.technology,
          type: data.technology,
          group_value: groupValues,
          key_number: data.key_number,
          initial_ddr: data.initial_ddr,
          final_ddr: data.final_ddr
        };
      } else {
        dataSubmit = {
          ...data,
          type: data.technology,
          key_number: data.key_number
        };
      }

      setLoading(true);
      isUpdate
        ? await Service.update(id, dataSubmit)
        : await Service.create(dataSubmit);
      history.push('/admin/trunks');
      if (isUpdate) {
        toast.info('Tronco foi atualizado com sucesso!');
      } else {
        toast.success('Tronco foi cadastrado com sucesso!');
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/trunks');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/trunks');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/trunks"
        buttonTitle="Voltar para a pagina de troncos"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Tronco Digital</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="12">
                          <label className="form-control-label">
                            Tecnologia*
                          </label>

                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                {TypesTrunk.map((trunk, index) => (
                                  <option key={index} value={trunk.id}>
                                    {trunk.name}
                                  </option>
                                ))}
                              </Input>
                            }
                            disabled={isUpdate}
                            control={control}
                            name="technology"
                            rules={{
                              required: 'Selecione uma tecnologia'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="technology"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md={defineSize()}>
                          <label className="form-control-label">Nome*</label>

                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{
                              required: 'Este campo é obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        {technology !== 'KHOMP' && (
                          <Col md="6">
                            <label className="form-control-label">Grupo*</label>
                            <Controller
                              as={Input}
                              control={control}
                              name="group_value"
                              rules={{
                                required: 'Este campo é obrigatório',
                                validate: (value) => {
                                  if (String(value).length > 30)
                                    return 'Grupo possuir no máximo 30 caracteres';
                                  return true;
                                }
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="group_value"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        )}
                        {isUpdate && technology === 'KHOMP' && (
                          <Col md="12">
                            <label className="form-control-label">Grupo*</label>
                            <Controller
                              as={Input}
                              control={control}
                              name="group_value"
                              rules={{
                                required: 'Este campo é obrigatório'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="group_value"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            DDR inicial*
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            name="initial_ddr"
                            rules={{
                              required: 'Este campo é obrigatório',
                              pattern: {
                                value: ddrRegex,
                                message: 'DDR deve possuir 4, 8 ou 10 números'
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="initial_ddr"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md="6">
                          <label className="form-control-label">
                            DDR final*
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            name="final_ddr"
                            rules={{
                              required: 'Este campo é obrigatório',
                              pattern: {
                                value: ddrRegex,
                                message: 'DDR deve possuir 4, 8 ou 10 números'
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="final_ddr"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row></Row>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Número da chave*
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            name="key_number"
                            rules={{
                              required: 'Este campo é obrigatório',
                              validate: (value) => {
                                if (String(value).length > 11)
                                  return 'Número da Chave deve possuir no máximo 11 caracteres';
                                return true;
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="key_number"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Contexto*
                          </label>

                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                {context.map((contextData, index) => (
                                  <option key={index} value={contextData.id}>
                                    {contextData.name}
                                  </option>
                                ))}
                              </Input>
                            }
                            control={control}
                            name="context"
                            rules={{
                              required: 'Selecione um contexto'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="context"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      {technology === 'KHOMP' && !isUpdate && (
                        <Row style={{ paddingTop: '20px' }}>
                          <Col className="text-right">
                            <Button
                              color="success"
                              type="button"
                              onClick={addChannel}
                              disabled={channels.length >= 5}
                            >
                              <FiPlus size={16} /> Adiciona Canal
                            </Button>
                            {channels.length > 0 && (
                              <Button
                                color="danger"
                                type="button"
                                onClick={removeChannel}
                              >
                                <FiDelete size={16} />
                              </Button>
                            )}
                          </Col>
                        </Row>
                      )}

                      {channels.map((network, index) => (
                        <Row key={index}>
                          <Col md="6" className="mb-3">
                            <label className="form-control-label">{`Dispositivo ${
                              index + 1
                            }`}</label>
                            <input
                              className="form-control"
                              type="text"
                              name={network.device}
                              ref={register({
                                required: 'Campo obrigatório'
                              })}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name={network.device}
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>

                          <Col md="6" className="mb-3">
                            <label className="form-control-label">{`Canal ${
                              index + 1
                            }`}</label>
                            <input
                              className="form-control"
                              type="number"
                              placeholder="Segundos"
                              name={network.channel}
                              ref={register({
                                required: 'Campo obrigatório'
                              })}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name={network.channel}
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                      ))}

                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DigitalForm;
