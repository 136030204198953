import { useState, useEffect } from 'react';
import QueueService from '../../Queue/service/index';

export function useGetQueues(showDisableQueue, setLoadingForm) {
  //queue to select
  useEffect(() => {
    if (showDisableQueue) {
      QueueService.getAllShowDisable(showDisableQueue)
        .then((response) => {
          setQueus(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    } else {
      QueueService.getAll()
        .then((response) => {
          setQueus(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
          setLoadingForm(false);
        });
    }
  }, [showDisableQueue, setLoadingForm]);

  const [queues, setQueus] = useState([]);

  return {
    queues,
    setQueus
  };
}
