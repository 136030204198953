import React from 'react';
import Loader from 'react-loader-spinner';
import { Row } from 'reactstrap';

export function Loading({ color }) {
  return (
    <Row className="justify-content-md-center">
      <Loader type="TailSpin" color={color} height={100} width={100} />
    </Row>
  );
}
