import produce from 'immer';

const INITIAL_STATE = {
  conversation: {}
};

export default function conversation(state = INITIAL_STATE, action) {
  //console.log('##### REDUCER CONVERSATION IN ATTENDANCE #####');
  switch (action.type) {
    case '@CONVERSATION_IN_ATTENDANCE/ADD_INIT_CONVERSATION':
      return produce(state, (draft) => {
        draft.conversation = action.payload.conversation;
      });
    case '@CONVERSATION_IN_ATTENDANCE/REMOVE_CONVERSATION_IN_ATTENDANCE':
      return produce(state, (draft) => {
        draft.conversation = {};
      });
    case '@CONVERSATION_IN_ATTENDANCE/CHECK_REMOVE_CONVERSATION_IN_ATTENDANCE': {
      const copy = { ...state };
      if (
        action.payload.id === state?.conversation?.id ||
        action.payload.id === state?.conversation?._id
      ) {
        copy.conversation = {};
        return copy;
      }
      return state;
    }

    case '@CONVERSATION_IN_ATTENDANCE/ADD_MESSAGE_CONVERSATION_IN_ATTENDANCE':
      return produce(state, (draft) => {
        if (draft.conversation.mensagens === null) return;
        const copyData = { ...draft };
        if (!copyData?.conversation?._id && copyData?.conversation?.id) {
          copyData.conversation._id = copyData.conversation.id;
        }
        if (action?.payload?.idDaConversa === copyData?.conversation?._id)
          if (draft?.conversation?.mensagens) {
            draft.conversation.mensagens.push(action?.payload?.message);
          }
      });
    case '@CONVERSATION_IN_ATTENDANCE/ADD_WHATSAPP_MESSAGE_ID_CONVERSATION_IN_ATTENDANCE':
      return produce(state, (draft) => {
        if (draft.conversation.mensagens === null) return;
        draft.conversation.mensagens = draft?.conversation?.mensagens.map(
          (mensagem) => {
            if (
              mensagem.texto === action.payload.message.texto &&
              mensagem.cliente_ou_atendente ===
                action.payload.message.cliente_ou_atendente &&
              mensagem.escrita_por === action.payload.message.escrita_por
            ) {
              mensagem.id_mensagem_whatsapp =
                action.payload.message.id_mensagem_whatsapp;
            }
            return mensagem;
          }
        );
      });
    case '@CONVERSATION_IN_ATTENDANCE/UPDATE_STATUS_MESSAGE_CONVERSATION_IN_ATTENDANCE':
      // eslint-disable-next-line no-case-declarations
      const newState = produce(state, (draft) => {
        if (!draft?.conversation?.mensagens) return;
        draft.conversation.mensagens = draft?.conversation?.mensagens?.map(
          (mensagem) => {
            if (
              mensagem.id_mensagem_whatsapp ===
              action.payload.eventMessage.idMensagemWhatsapp
            ) {
              mensagem.status = action.payload.eventMessage.status;
            }
            return mensagem;
          }
        );
      });
      return JSON.stringify(newState) === JSON.stringify(state)
        ? state
        : newState;

    case '@CONVERSATION_IN_ATTENDANCE/UPDATE_WHATSAPP_MESSAGE_ID_CONVERSATION_IN_ATTENDANCE':
      // eslint-disable-next-line no-case-declarations
      const stat = produce(state, (draft) => {
        if (!draft?.conversation?.mensagens) return;
        draft.conversation.mensagens = draft?.conversation?.mensagens?.map(
          (mensagem) => {
            if (mensagem.mid === action.payload.eventPayload.mid) {
              mensagem.id_mensagem_whatsapp =
                action.payload.eventPayload.id_mensagem_whatsapp;
            }
            return mensagem;
          }
        );
      });
      return JSON.stringify(stat) === JSON.stringify(state) ? state : stat;

    case '@CONVERSATION_IN_ATTENDANCE/DELETE_MESSAGE_CONVERSATION_IN_ATTENDANCE':
      // eslint-disable-next-line no-case-declarations
      const _newState = produce(state, (draft) => {
        if (!draft?.conversation?.mensagens) return;
        const copy = [...draft.conversation.mensagens];
        const index = copy.findIndex((mensagem) => {
          return mensagem.mid === action.payload.message.mid;
        });
        if (index === -1) {
          return;
        }
        copy.splice(index, 1);
        draft.conversation.mensagens = copy;
      });
      return JSON.stringify(_newState) === JSON.stringify(state)
        ? state
        : _newState;

    default:
      return state;
  }
}
