import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMemorizeFilters } from '../../../../../../../hooks/useMemorizeInputsFilters';
import { resetToFirstDayOfMonth } from '../../../../../shared/helpers/getFirstDayOfTheMonth';
import { resetFields } from '../../../../../shared/helpers/resetInputsFIeldsValues';
import { Button, Row, Col, Form } from 'reactstrap';
import DatePicker from 'components/Inputs/DatePicker';
import { dateRules } from 'views/Protocol/form/assets/functions';
import FlexSelect from 'components/Inputs/FlexSelect';
import {
  ImageButtonConteinerProtocol,
  ImageButton,
  ImageSize
} from '../../../../../../../assets/styles/image_style';
import { Tooltip } from '@material-ui/core';
import Loader from 'components/Loader';
import pdfImage from '../../../../../../../assets/img/pdf.png';
import xlsxImage from '../../../../../../../assets/img/xlsx.png';
import csvImage from '../../../../../../../assets/img/csv.png';
import { saveAs } from 'file-saver';
import OpportunityService from '../../../services/opportunityService';
import { toast } from 'react-toastify';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { AiOutlineClear } from 'react-icons/ai';
import { functionsUseFul } from 'views/Protocol/list/assets/functionsUseFul';
import { opportunityTypes } from 'views/CRM/shared/utils/opportunityTypes';

const FilterOpportunityRevenueForm = ({
  handleSearch,
  recordTypeId,
  analyticalData,
  setSelectedRecordType,
  recordTypes,
  setReportBy
}) => {
  const { control, handleSubmit, getValues, setValue, reset } = useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { memorizeFilters, getMemorizedFilters, clearMemorizedFilters } =
    useMemorizeFilters('CRM - Opportunity');
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingXLSX, setLoadingXLSX] = useState(false);

  const { getDateBegin } = functionsUseFul();

  useEffect(() => {
    const filters = getMemorizedFilters();
    if (filters) {
      reset(filters);
    }
  }, [getMemorizedFilters, reset, resetConfig, setValue]);

  const resetConfig = useMemo(
    () => ({
      dateBegin: resetToFirstDayOfMonth,
      dateEnd: () => new Date().toLocaleDateString('pt-br').toString(),
      type: () => '',
      reportBy: () => 'complete'
    }),
    []
  );

  const saveFiltersWithRecordType = (filters) => {
    const filtersWithRecordType = {
      ...filters,
      recordTypeId: recordTypeId
    };
    memorizeFilters(filtersWithRecordType);
  };

  const onSubmit = async (data) => {
    saveFiltersWithRecordType(data);
    const formattedData = { ...data };
    formattedData.filterDateBy = 'closure';
    await handleSearch({ ...formattedData });
    setReportBy(data.reportBy);
  };

  const toISOFormat = useCallback((dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${DD}/${MM}/${YYYY}`;
  }, []);

  const handleReset = () => {
    if (recordTypeId && recordTypes && recordTypes.length) {
      setSelectedRecordType(recordTypes[0]);
    }
    resetFields(resetConfig, [], control);
    clearMemorizedFilters();
  };

  const handleReportExport = (typeExport, e) => {
    e.preventDefault();

    const params = getValues(['dateBegin', 'dateEnd', 'type', 'reportBy']);

    const dto = { ...params };
    dto.filterDateBy = 'closure';

    if (typeExport === 'XLSX') {
      setLoadingXLSX(true);

      OpportunityService.getReportXlsxOpportunityRevenue(recordTypeId, dto)
        .then((res) => {
          saveAs(
            new Blob([res.data], {
              type: 'application/octet-stream'
            }),
            `relatorio-receita-oportunidade.xlsx`
          );
          setLoadingXLSX(false);
        })
        .catch(() => {
          setLoadingXLSX(false);
          toast.info('Nenhum registro foi encontrado!');
        });
    } else if (typeExport === 'PDF') {
      setLoadingPdf(true);
      OpportunityService.getReportPdfOpportunityRevenue(recordTypeId, dto)
        .then((res) => {
          saveAs(
            new Blob([res.data], { type: 'application/octet-stream' }),
            `relatorio-receita-oportunidade-${new Date()}.pdf`
          );
          setLoadingPdf(false);
        })
        .catch(() => {
          setLoadingPdf(false);
          toast.info('Nenhum registro foi encontrado!');
        });
    } else if (typeExport === 'CSV') {
      setLoadingCSV(true);

      OpportunityService.getReportCsvOpportunityRevenue(recordTypeId, dto)
        .then((res) => {
          saveAs(
            new Blob([res.data], {
              type: 'application/octet-stream'
            }),
            `relatorio-receita-oportunidade.csv`
          );
          setLoadingCSV(false);
        })
        .catch(() => {
          setLoadingCSV(false);
          toast.info('Houve um erro ao baixar relatório');
        });
    }
  };

  return (
    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          position: 'absolute',
          right: '1rem',
          top: '2rem'
        }}
      >
        <span
          onClick={() => {
            handleReset();
          }}
          className="small"
          style={{ cursor: 'pointer' }}
        >
          <AiOutlineClear /> Limpar filtros
        </span>
      </div>
      <Row className="mb-3">
        <Col sm="3">
          <label className="form-control-label">Data inicial</label>
          <Controller
            render={(props) => (
              <DatePicker
                fieldName="dateBegin"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateBegin"
            rules={dateRules}
            defaultValue={getDateBegin()}
          />
        </Col>
        <Col sm="3">
          <label className="form-control-label">Data final</label>
          <Controller
            render={(props) => (
              <DatePicker
                fieldName="dateEnd"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateEnd"
            defaultValue={new Date().toLocaleDateString('pt-br')}
            rules={{
              ...dateRules,
              validate: async (value) => {
                if (
                  new Date(toISOFormat(value)) <
                  new Date(toISOFormat(getValues()['dateBegin']))
                ) {
                  return 'A data final não pode ser anterior à inicial!';
                } else {
                  return true;
                }
              }
            }}
          />
        </Col>
        <Col sm="3">
          <label className="form-control-label">Estado</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={opportunityTypes || []}
                isMulti={false}
                value={props.value}
                valueController={setValue}
                fieldName="type"
                labelName="name"
                valueName="value"
                isClearable={true}
                closeMenuOnSelect={true}
              />
            )}
            control={control}
            name="type"
            defaultValue=""
          />
        </Col>
        <Col sm="3">
          <label className="form-control-label">Separar por</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={[
                  {
                    name: 'Período completo',
                    value: 'complete'
                  },
                  {
                    name: 'Por mês',
                    value: 'month'
                  }
                ]}
                isMulti={false}
                value={props.value}
                valueController={setValue}
                fieldName="reportBy"
                labelName="name"
                valueName="value"
                isClearable={false}
                closeMenuOnSelect={true}
              />
            )}
            control={control}
            name="reportBy"
            defaultValue="complete"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-3" style={ImageButtonConteinerProtocol}>
            <Button
              style={buttonStyle(systemConfiguration.primary_color)}
              type="submit"
              size="sm"
            >
              Buscar
            </Button>
          </div>
        </Col>
        <Col
          md="6"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <button
            id="button_csv"
            style={ImageButton}
            onClick={(e) => {
              handleReportExport('CSV', e);
            }}
            disabled={analyticalData.length && !loadingCSV ? false : true}
          >
            <Tooltip
              title={
                <p style={{ fontSize: '14px' }}>
                  {analyticalData.length
                    ? `Fazer download do relatório de receitas da Oportunidade.`
                    : 'Nenhum registro para realizar download'}
                </p>
              }
              arrow
            >
              {loadingCSV ? (
                <Loader
                  type="ThreeDots"
                  visible={true}
                  height="40"
                  width="40"
                />
              ) : (
                <img src={csvImage} alt="csv" style={ImageSize} />
              )}
            </Tooltip>
          </button>
          <button
            id="button_xlsx"
            style={ImageButton}
            onClick={(e) => {
              handleReportExport('XLSX', e);
            }}
            disabled={analyticalData.length && !loadingXLSX ? false : true}
          >
            <Tooltip
              title={
                <p style={{ fontSize: '14px' }}>
                  {analyticalData.length
                    ? `Fazer download do relatório de receitas da Oportunidade.`
                    : 'Nenhum registro para realizar download'}
                </p>
              }
              arrow
            >
              {loadingXLSX ? (
                <Loader
                  type="ThreeDots"
                  visible={true}
                  height="40"
                  width="40"
                />
              ) : (
                <img src={xlsxImage} alt="xlsx" style={ImageSize} />
              )}
            </Tooltip>
          </button>
          <button
            style={ImageButton}
            name="pdf"
            onClick={(e) => {
              handleReportExport('PDF', e);
            }}
            disabled={analyticalData.length && !loadingPdf ? false : true}
          >
            <Tooltip
              title={
                <p style={{ fontSize: '14px' }}>
                  {analyticalData.length
                    ? `Fazer download do relatório de receitas da Oportunidade.`
                    : 'Nenhum registro para realizar download'}
                </p>
              }
              arrow
            >
              {loadingPdf ? (
                <Loader
                  type="ThreeDots"
                  visible={true}
                  height="40"
                  width="40"
                />
              ) : (
                <img src={pdfImage} alt="pdf" style={ImageSize} />
              )}
            </Tooltip>
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default FilterOpportunityRevenueForm;
