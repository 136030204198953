import React, { useState, useEffect } from 'react';
import { Row } from 'reactstrap';

import PaperService from '../../../../Paper/service';

import DefaultModal from 'components/Modal/DefaultModal';
import ReactLoader from 'components/Loader';
import MonitoringForm from '../MonitoringForm';
// import { SingleTemplateModal } from '../SingleTemplateModal';
import { verifyRoles } from 'components/Authentication/VerifyRoles';

import { ColTemplate } from './style';
import { toast } from 'react-toastify';
import AnswersModal from '../AnswersModal';

export default function PapersListModal({
  isModalOpen,
  onModalToggle,
  cdrReport,
  papersAssessments,
  cdrUniqueId,
  dataReport,
  ReportService,
  ...props
}) {
  const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [monitoringFormModalOpen, setMonitoringFormModalOpen] = useState(false);
  const [currentPaper, setCurrentPaper] = useState(undefined);

  const filterListCall = dataReport.listCallInAnalyticalDTO.filter(function (
    value
  ) {
    return value.uniqueid == cdrUniqueId;
  })[0];


  async function getPapers() {
    try {
      const { data } = await PaperService.getAll();
      setPapers(data.data);
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao listar questionários!', {
        autoClose: 3000,
        closeOnClick: true
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    getPapers();
  }, []);

  const handleOnClick = (value) => {
    setCurrentPaper(value);
    setMonitoringFormModalOpen(true);
  };

  const hasPapers = !!papers.length;

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Questionários de monitoria"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      {loading ? (
        <Row className="justify-content-md-center py-3">
          <ReactLoader />
        </Row>
      ) : (
        <>
          <Row>
            {hasPapers &&
              papers.map((paper) => {
                const assessments = papersAssessments.filter((assessment) => {
                  return assessment.paperid === paper.id;
                });

                const hasAssessment = assessments.length != 0;
                const hasPermissionToViewReports = verifyRoles([
                  'VIEW_QUALITY_MONITORING_REPORTS'
                ]);

                return (
                  <ColTemplate
                    sm="12"
                    onClick={() => {
                      if (assessments.length == 0) {
                        handleOnClick(paper);
                      }
                    }}
                    style={{
                      cursor: 'pointer',
                      backgroundColor: hasAssessment ? '#f2f2f2' : null
                    }}
                    key={paper.id}
                    // className={`colTemplateMessageHover${templateIndex}`}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <h4>{`${paper.name}`}</h4>
                      {hasPermissionToViewReports && hasAssessment ? (
                        <h4>{`${assessments[0].average}   `}</h4>
                      ) : null}
                    </div>
                    {hasPermissionToViewReports && hasAssessment ? (
                      <AnswersModal
                        assessmentId={assessments[0].id}
                        cdrUniqueId={cdrUniqueId}
                        nameAgent= {filterListCall.nameAgent}
                        calldateStart = {filterListCall.callDateStartFormated} 
                        Origem= {filterListCall.clidNum}
                        Queue= {filterListCall.queue}
                         MonitoringForm={true}
                      />
                    ) : null}
                  </ColTemplate>
                );
              })}
          </Row>
        </>
      )}

      <MonitoringForm
        isModalOpen={monitoringFormModalOpen}
        onModalToggle={setMonitoringFormModalOpen}
        onSubmitForm={() => onModalToggle(false)}
        cdrReport={cdrReport}
        cdrUniqueId={cdrUniqueId}
        paper={currentPaper}
        dataReport = {dataReport}
        ReportService={ReportService}
      />
    </DefaultModal>
  );
}
