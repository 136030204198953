import React, { useState, useEffect } from 'react';
import { getLogoURL } from 'views/SystemParams/services/getLogoURL';
import {
  ForgotContainer,
  FormContainer,
  ContainerChips
} from './assets/styled';
import { useSelector } from 'react-redux';
import SurveyService from './service';
import { useHistory } from 'react-router-dom';
import Success from './components/Success';
import Fail from './components/Fail';
import Retry from './components/Retry';
import FlexRating from './components/FlexRating';
import ReactButton from '../../components/Buttons/ReactButton';
import { ChipComponent } from '../../components/Chip/index';

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label
} from 'reactstrap';

import { toast } from 'react-toastify';
import ReactLoader from 'components/Loader';

const Survey = () => {
  const [surveyConfig, setSurveyConfig] = useState(null);
  const [optionTags, setOptionTags] = useState(null);
  const [questionTag, setQuestionTag] = useState('');
  const [rate, setRate] = useState(0);
  const [logoURL, setLogoURL] = useState('');
  const [selectedTags, setSelectedTags] = useState([]);
  const [conversationId, setConversationId] = useState('');
  const [surveyId, setSurveyId] = useState('');
  const [screen, setScreen] = useState('');
  const [observation, setObservation] = useState('');
  const history = useHistory();

  const dataConfigurations = useSelector((state) => state.systemConfiguration);

  useEffect(() => {
    setScreen('LOADING');
    const stringParams = window.location.search.substring(1).split('&');

    if (stringParams.length === 2) {
      const conversationParam = stringParams[0].split('=')[1];
      const surveyParam = stringParams[1].split('=')[1];
      SurveyService.getSurveyConfig(surveyParam)
        .then((res) => {
          if (res.data) {
            setSurveyConfig({ ...res.data });
            setScreen('FORM');
          } else {
            setScreen('FAIL');
          }
        })
        .catch((err) => {
          console.log(err);
          setScreen('FAIL');
        });

      setConversationId(conversationParam);
      setSurveyId(surveyParam);
    } else {
      setScreen('FAIL');
    }
  }, [history]);

  useEffect(() => {
    getLogoURL().then((source) => {
      setLogoURL(source);
    });
  }, []);

  useEffect(() => {
    let aux = false;
    setSelectedTags([]);

    surveyConfig &&
      surveyConfig.answers.forEach((el) => {
        if (el['rate'] === rate) {
          let arrayTag = [];
          el['tags'] &&
            el['tags'].forEach((tag) => {
              arrayTag.push({ label: tag.name, value: tag });
            });
          setOptionTags(arrayTag);
          setQuestionTag(el.question_tag);
          aux = true;
        }
      });

    if (rate === null || aux === false) {
      setQuestionTag(null);
      setOptionTags(null);
    }
  }, [rate, surveyConfig]);

  const handleSubmit = () => {
    if (rate < 1) {
      toast.info(`Por favor, atribua uma pontuação!`);
    } else {
      const formatedData = {
        channel: 'chat',
        observation: observation,
        rate: rate,
        type_survey: surveyConfig.type_survey,
        tags: [...selectedTags],
        name: surveyConfig.name
      };

      SurveyService.sendInquiry(conversationId, surveyId, formatedData)
        .then((res) => {
          if (
            res.data.result === 'Pesquisa já respondida para esse atendimento'
          ) {
            setScreen('RETRY');
          } else {
            setScreen('SUCCESS');
          }
        })
        .catch((err) => {
          toast.error(
            err.response.data.message || `Falha ao enviar satisfação!`
          );
        });
    }
  };

  const handleMessageChange = (event) => {
    setObservation(event.target.value);
  };

  const varianteType = (param) => {
    let alreadExists = false;

    if (selectedTags.lenght === 0) {
      setSelectedTags([param]);
    } else {
      alreadExists = selectedTags.some((st) => {
        return st === param;
      });
    }

    if (alreadExists) {
      //tira do array
      const auxState = selectedTags;
      const itemExist = selectedTags.find((st) => st === param);
      const indexItemExist = selectedTags.findIndex((st) => st === itemExist);
      auxState.splice(indexItemExist, 1);
      setSelectedTags([...auxState]);
    } else {
      setSelectedTags([...selectedTags, param]);
    }
  };

  return (
    <ForgotContainer
      backgroundCardColor={
        dataConfigurations.background_color_screen_card_satisfaction_survey
      }
      backgroundColor={
        dataConfigurations.background_color_screen_satisfaction_survey
      }
    >
      <FormContainer fontColor={dataConfigurations.font_color_card_survey}>
        {logoURL && (
          <div className="img-logo-g4flex">
            <img src={logoURL} style={{ maxWidth: '200px' }} alt="logo_g4" />
          </div>
        )}

        <Card className="card-survey">
          <CardHeader className="bg-transparent">
            <div className="text-muted text-center">
              <h2
                style={{ color: dataConfigurations.font_color_card_survey }}
                className=""
              >
                Pesquisa de satisfação
              </h2>
            </div>
          </CardHeader>
          <CardBody>
            {screen === 'SUCCESS' && (
              <Success fontColor={dataConfigurations.font_color_card_survey} />
            )}

            {screen === 'FAIL' && (
              <Fail fontColor={dataConfigurations.font_color_card_survey} />
            )}

            {screen === 'RETRY' && (
              <Retry fontColor={dataConfigurations.font_color_card_survey} />
            )}

            {screen === 'LOADING' && (
              <div className="text-center">
                <ReactLoader />
              </div>
            )}

            {screen === 'FORM' && surveyConfig && (
              <Form>
                <div className="text-center ">
                  <h3
                    style={{ color: dataConfigurations.font_color_card_survey }}
                    className="display-6"
                  >
                    {surveyConfig.question}
                  </h3>
                </div>
                <FlexRating
                  setRate={setRate}
                  rate={rate}
                  color={dataConfigurations.font_color_card_survey}
                  type={surveyConfig.type_survey}
                />
                <div className="text-center mb-1">
                  <h5 className="text-muted">
                    {optionTags && optionTags.length > 0 && (
                      <h4
                        style={{
                          color: dataConfigurations.font_color_card_survey
                        }}
                      >
                        {questionTag}
                      </h4>
                    )}
                  </h5>
                </div>
                <ContainerChips>
                  {optionTags &&
                    optionTags.map((item, index) => {
                      return (
                        <ChipComponent
                          key={index}
                          id={item.value._id}
                          colorTagSelected={
                            dataConfigurations.font_color_card_survey_tags_selected
                          }
                          color={dataConfigurations.font_color_card_survey}
                          isActive={selectedTags.includes(item.value)}
                          name={item.value.name}
                          handleClickChip={() => varianteType(item.value)}
                        />
                      );
                    })}
                </ContainerChips>

                <Row className="justify-content-center">
                  <Col md="8">
                    <Label for="textId" className="mb--1">
                      <h4
                        style={{
                          color: dataConfigurations.font_color_card_survey
                        }}
                        className="display-6"
                      >
                        Deseja adicionar algum comentário?
                      </h4>
                    </Label>
                    <Input
                      type="textarea"
                      name="text"
                      rows="4"
                      id="textId"
                      value={observation}
                      onChange={handleMessageChange}
                      placeholder="Observação..."
                    />
                  </Col>
                </Row>
                <Row className="mt-6">
                  <Col md="12" className="send-btn-container">
                    <FormGroup className="text-center">
                      <ReactButton
                        btnColor="confirmation"
                        onClick={handleSubmit}
                        type="button"
                      >
                        Avaliar
                      </ReactButton>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            )}
          </CardBody>
        </Card>
      </FormContainer>
    </ForgotContainer>
  );
};

export default Survey;
