import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMemorizeFilters } from '../../../../../../../hooks/useMemorizeInputsFilters';
import { resetToFirstDayOfMonth } from '../../../../../shared/helpers/getFirstDayOfTheMonth';
import { resetFields } from '../../../../../shared/helpers/resetInputsFIeldsValues';
import { saveAs } from 'file-saver';
import LeadService from '../../../services/leadService.js';
import { toast } from 'react-toastify';
import { Button, Row, Col, Form, Input } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import DatePicker from 'components/Inputs/DatePicker';
import { dateRules } from 'views/Protocol/form/assets/functions';
import FlexSelect from 'components/Inputs/FlexSelect';
import {
  ImageButton,
  ImageButtonConteinerProtocol,
  ImageSize
} from '../../../../../../../assets/styles/image_style';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import xlsxImage from '../../../../../../../assets/img/xlsx.png';
import pdfImage from '../../../../../../../assets/img/pdf.png';
import { AiOutlineClear } from 'react-icons/ai';
import { useUsers } from 'views/Report/Protocol/hooks/useUsers';
import { functionsUseFul } from 'views/Protocol/list/assets/functionsUseFul';
import Loader from 'components/Loader';
import { useListLeadStagesByRecordType } from 'views/CRM/LeadStages/hooks/useListLeadStagesByRecordType';
import { timeDataNames } from 'views/CRM/shared/utils/leadOpportunityTimeName';

const FilterLeadTinmeForm = ({
  handleSearch,
  recordTypeId,
  leads,
  setSelectedRecordType,
  recordTypes,
  analyticalData,
  setAnalyticalData,
  quantity,
  setDataToSend,
  reportType
}) => {
  const { control, handleSubmit, getValues, setValue, reset } = useForm();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const limitRows = 4;
  const { memorizeFilters, getMemorizedFilters, clearMemorizedFilters } =
    useMemorizeFilters('CRM - Lead');

  const { userList } = useUsers();
  const { getDateBegin } = functionsUseFul();
  const { leadStages, getLeadStagesByRecordType, isLoadingLeadStages } =
    useListLeadStagesByRecordType();

  useEffect(() => {
    if (leads) {
      setAnalyticalData(leads);
    }
  }, [leads, setAnalyticalData]);

  useEffect(() => {
    if (recordTypeId) getLeadStagesByRecordType(recordTypeId);
  }, [recordTypeId, getLeadStagesByRecordType]);

  useEffect(() => {
    const filters = getMemorizedFilters();
    if (filters) {
      reset(filters);
    }
  }, [getMemorizedFilters, reset, resetConfig, setValue]);

  const resetConfig = useMemo(
    () => ({
      dateBegin: resetToFirstDayOfMonth,
      dateEnd: () => new Date().toLocaleDateString('pt-br').toString(),
      stageId: () => '',
      textQuery: () => '',
      responsibleLogin: () => '',
      leadTime: () => '',
      reportType: () => 1
    }),
    []
  );

  const saveFiltersWithRecordType = (filters) => {
    const filtersWithRecordType = {
      ...filters,
      recordTypeId: recordTypeId
    };
    memorizeFilters(filtersWithRecordType);
  };

  const onSubmit = async (data) => {
    const reportTypeSubmit = data.reportType;
    saveFiltersWithRecordType(data);
    const formattedData = { ...data };
    setDataToSend({ ...formattedData });
    await handleSearch({ ...formattedData }, reportTypeSubmit);
  };

  const toISOFormat = useCallback((dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${DD}/${MM}/${YYYY}`;
  }, []);

  const handleReportExport = (typeExport, e) => {
    e.preventDefault();

    const params = getValues([
      'textQuery',
      'typeExportReport',
      'stageId',
      'dateBegin',
      'dateEnd',
      'responsibleLogin',
      'leadTime'
    ]);

    const dto = {
      ...params,
      dateBegin: toISOFormat(params.dateBegin),
      dateEnd: toISOFormat(params.dateEnd),
      recordTypeId: recordTypeId
    };

    if (analyticalData.length < limitRows && typeExport == 'XLSX') {
      setLoadingCSV(true);

      LeadService.getXlsx(dto)
        .then((res) => {
          if (res.data.size === 0) {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          } else {
            saveAs(
              new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
              }),
              `relatorio-prazos-leads.xlsx`
            );

            setLoadingCSV(false);
          }
        })
        .catch(() => {
          setLoadingCSV(false);
          toast.info('Nenhum registro foi encontrado!');
        });
    } else if (typeExport == 'XLSX' && analyticalData.length > limitRows) {
      setLoadingCSV(true);

      LeadService.getCsv(dto)
        .then((res) => {
          if (res.data.size === 0) {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          } else {
            saveAs(
              new Blob([res.data], { type: res.headers['content-type'] }),
              `relatorio-prazos-leads-${new Date()}.csv`
            );

            setLoadingCSV(false);
          }
        })
        .catch(() => {
          setLoadingCSV(false);
          toast.info('Nenhum registro foi encontrado!');
        });
    } else if (typeExport == 'PDF') {
      setLoadingPdf(true);
      LeadService.getPdf(dto)
        .then((res) => {
          if (res.data.quantity === 0) {
            toast.info('Nenhum registro foi encontrado!');
            setLoadingPdf(false);
          } else {
            saveAs(
              new Blob([res.data], { type: 'application/pdf' }),
              `relatorio-prazos-leads-${new Date()}.pdf`
            );
          }
          setLoadingPdf(false);
        })
        .catch(() => {
          setLoadingPdf(false);
          toast.info('Nenhum registro foi encontrado!');
        });
    }
  };

  const handleReset = () => {
    if (recordTypeId && recordTypes && recordTypes.length) {
      setSelectedRecordType(recordTypes[0]);
    }
    resetFields(resetConfig, [], control);
    clearMemorizedFilters();
  };

  const showAllLeads = useMemo(() => {
    const flag = localStorage.getItem('show_all_leads');
    return flag === 'true';
  }, []);

  return (
    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          position: 'absolute',
          right: '1rem',
          top: '2rem'
        }}
      >
        <span
          onClick={() => {
            handleReset();
          }}
          className="small"
          style={{ cursor: 'pointer' }}
        >
          <AiOutlineClear /> Limpar filtros
        </span>
      </div>
      <Row className="mb-3">
        <Col sm="8">
          <label className="form-control-label">Buscar</label>
          <Controller
            as={
              <Input
                type="text"
                name="textQuery"
                placeholder="Buscar por nome, telefone ou email"
              />
            }
            control={control}
            name="textQuery"
            defaultValue=""
          />
        </Col>
        <Col sm="4">
          <label className="form-control-label"> Fase do Lead </label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={leadStages ? leadStages : []}
                isMulti={false}
                value={props.value}
                valueController={setValue}
                fieldName="stageId"
                labelName="name"
                valueName="id"
                isClearable={true}
                disabled={isLoadingLeadStages}
                closeMenuOnSelect={true}
              />
            )}
            control={control}
            defaultValue=""
            name="stageId"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={showAllLeads ? '3' : '6'}>
          <label className="form-control-label">Data inicial</label>
          <Controller
            render={(props) => (
              <DatePicker
                fieldName="dateBegin"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateBegin"
            rules={dateRules}
            defaultValue={getDateBegin()}
          />
        </Col>
        <Col sm={showAllLeads ? '3' : '6'}>
          <label className="form-control-label">Data final</label>
          <Controller
            render={(props) => (
              <DatePicker
                fieldName="dateEnd"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateEnd"
            defaultValue={new Date().toLocaleDateString('pt-br')}
            rules={{
              ...dateRules,
              validate: async (value) => {
                if (
                  new Date(toISOFormat(value)) <
                  new Date(toISOFormat(getValues()['dateBegin']))
                ) {
                  return 'A data final não pode ser anterior à inicial!';
                } else {
                  return true;
                }
              }
            }}
          />
        </Col>
        {showAllLeads && (
          <Col sm="3">
            <label className="form-control-label">Responsável</label>
            <Controller
              render={(props) => (
                <FlexSelect
                  dataOptions={userList || []}
                  isMulti={false}
                  value={props.value}
                  valueController={setValue}
                  fieldName="responsibleLogin"
                  labelName="name"
                  valueName="login"
                  isClearable={true}
                  closeMenuOnSelect={true}
                />
              )}
              control={control}
              name="responsibleLogin"
              defaultValue=""
            />
          </Col>
        )}
        <Col sm="3">
          <label className="form-control-label">Prazo do Lead</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={timeDataNames || []}
                isMulti={false}
                value={props.value}
                valueController={setValue}
                fieldName="leadTime"
                labelName="name"
                valueName="value"
                isClearable={true}
                closeMenuOnSelect={true}
              />
            )}
            control={control}
            name="leadTime"
            defaultValue=""
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="3">
          <label className="form-control-label">Tipo de relatório</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={[
                  { name: 'Analítico', value: 1 },
                  { name: 'Sintético', value: 2 }
                ]}
                isMulti={false}
                value={props.value}
                valueController={setValue}
                fieldName="reportType"
                labelName="name"
                valueName="value"
                closeMenuOnSelect={true}
              />
            )}
            control={control}
            defaultValue={1}
            name="reportType"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-3" style={ImageButtonConteinerProtocol}>
            <Button
              style={buttonStyle(systemConfiguration.primary_color)}
              type="submit"
              size="sm"
            >
              Buscar
            </Button>
          </div>
        </Col>
        {reportType === 1 && (
          <Col
            md="6"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <button
              id="button_xlsx"
              style={ImageButton}
              onClick={(e) => {
                handleReportExport('XLSX', e);
              }}
              disabled={analyticalData.length && !loadingCSV ? false : true}
            >
              <Tooltip
                title={
                  <p style={{ fontSize: '14px' }}>
                    {analyticalData.length
                      ? `Fazer download do relatório de Leads. Arquivos menores que ${limitRows} registros serão baixados em XLSX, caso contrário CSV`
                      : 'Nenhum registro para realizar download'}
                  </p>
                }
                arrow
              >
                {loadingCSV ? (
                  <Loader
                    type="ThreeDots"
                    visible={true}
                    height="40"
                    width="40"
                  />
                ) : (
                  <img src={xlsxImage} alt="xlsx" style={ImageSize} />
                )}
              </Tooltip>
            </button>
            <button
              style={ImageButton}
              name="pdf"
              onClick={(e) => {
                handleReportExport('PDF', e);
              }}
              disabled={analyticalData.length && !loadingPdf ? false : true}
            >
              <Tooltip
                title={
                  <p style={{ fontSize: '14px' }}>
                    {analyticalData.length
                      ? 'Fazer download do relatório de Leads.'
                      : 'Nenhum registro para realizar download, faça um filtra para realizar está ação'}
                  </p>
                }
                arrow
              >
                {loadingPdf ? (
                  <Loader
                    type="ThreeDots"
                    visible={true}
                    height="40"
                    width="40"
                  />
                ) : (
                  <img src={pdfImage} alt="pdf" style={ImageSize} />
                )}
              </Tooltip>
            </button>
          </Col>
        )}
      </Row>
      {reportType === 1 && (
        <div>
          <div style={{ fontSize: 'small', float: 'right' }}>
            {quantity} resultado(s) encontrado(s)
          </div>
        </div>
      )}
    </Form>
  );
};

export default FilterLeadTinmeForm;
