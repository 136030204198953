import { Button } from 'reactstrap';
import React, { useState } from 'react';
import { formatDateForAudioDownloads } from '../../../../../../utils/fuctions/formattedDateForAudioDownloads';
import { toast } from 'react-toastify';
import fileSaver from 'file-saver';
import ReportService from '../../service';
import ReactLoader from 'components/Loader';

export const DownloadButton = ({ row }) => {
  const [isLoading, setIsLoading] = useState(false);

  const downloadAudio = (uniqueid, nameAgent, date) => {
    setIsLoading(true);
    const formattedDate = formatDateForAudioDownloads(date);
    ReportService.downloadAudio(uniqueid)
      .then((res) => {
        if (res.data.size === 0);
        else {
          fileSaver.saveAs(
            new Blob([res.data], { type: res.headers['content-type'] }),
            `${formattedDate}-${nameAgent}.gsm`
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Erro ao fazer download de arquivo');
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div style={{ position: 'relative', width: 'min-content' }}>
      {isLoading && (
        <div
          style={{
            position: 'absolute',
            left: '6px',
            top: '3px',
            zIndex: '10'
          }}
        >
          <ReactLoader width={20} height={20}></ReactLoader>
        </div>
      )}
      <Button
        disabled={!(row.disposition === 'ANSWERED') || isLoading}
        onClick={() =>
          downloadAudio(row.uniqueid, row.nameAgent, row.calldateStart)
        }
        color="success"
        size="sm"
      >
        <i className="fas fa-cloud-download-alt"></i>
      </Button>
    </div>
  );
};
