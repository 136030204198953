import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { FaRegAddressBook } from 'react-icons/fa';
import { Option, StyleOption } from '../../styled';
import DefaultModal from '../../../../../../../components/Modal/DefaultModal';
import ContactInputs from 'views/CRM/Contact/form/FormInputs';
import ContactService from 'views/CRM/Contact/services/contactService';

const ContactDetails = ({ contactId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contact, setContact] = useState(null);

  useEffect(() => {
    if (isModalOpen) {
      ContactService.getContactById(contactId)
        .then((response) => {
          console.log(response.data.data);
          setContact(response.data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isModalOpen, contactId]);

  return (
    <>
      <Option title={'Detalhes do contato'}>
        <Button
          color="link"
          size="sm"
          style={StyleOption}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <FaRegAddressBook size={20} color="#32325d" />
        </Button>
      </Option>
      <DefaultModal
        show={isModalOpen}
        onModalToggle={setIsModalOpen}
        title="Detalhes do contato"
        showCloseButton={false}
        modalContainerClasses="modal-lg"
      >
        {contact ? (
          <ContactInputs
            contact={contact}
            recordTypeId={contact.recordTypeId}
            onCancelAction={() => setIsModalOpen(false)}
            onSaveAction={() => setIsModalOpen(false)}
          />
        ) : null}
      </DefaultModal>
    </>
  );
};

export default ContactDetails;
