import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { Input } from 'reactstrap';
import { BsCameraVideoFill, BsFillImageFill } from 'react-icons/bs';
import { PreviewImage } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/PreviewImage';
import { PreviewPdf } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/PreviewPdf';
import { Preview } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/styled';
import { validationFiles } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/assets/validationFiles';
import { toast } from 'react-toastify';
import { generatePreview } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/assets/generatePreview';
import { VideoPreview } from '../../../../ServicePanel/components/QueueContent/components/VideoPreview';
import { AiFillFilePdf } from 'react-icons/ai';

const TemplateMessageMidia = ({ templateType, files, setFiles, url }) => {
  const {
    CheckedRefusedExtensionsFiles,
    isAllFilesWithApprovedExtension,
    isFilesWithApprovedExtension,
    isImagesWithApprovedExtension,
    sizeInvalidFileValidation
  } = validationFiles();
  const [previewImages, setPreviewImages] = useState(undefined);
  const [pdfPreview, setPdfPreview] = useState(undefined);
  const [videoPreview, setVideoPreview] = useState(undefined);
  const {
    getImagePreviewSingleFile,
    getPDFPreviewSingleFile,
    getVideoPreview
  } = generatePreview();
  const [
    fileExtensionsWithPreviewAlreadyGenerated,
    setFileExtensionsWithPreviewAlreadyGenerated
  ] = useState([]);

  useEffect(() => {
    if (url && templateType === 'IMAGE')
      setPreviewImages({ url, nameFile: '' });
  }, []);
  const handleOnChangeFileToTarget = (e, type) => {
    const file = e.target.files;
    handleSelectFile(file, type);
  };
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const handleSelectFile = (files, type) => {
    if (!files) return;

    const filesToBeSent = Array.from(files);

    const isCheckedRefusedExtensionsFiles =
      CheckedRefusedExtensionsFiles(filesToBeSent);

    if (isCheckedRefusedExtensionsFiles) {
      toast.error('Erro ao enviar arquivo(s). Formato não permitido');
      return;
    }
    const isInvalidTypeFile = isAllFilesWithApprovedExtension(filesToBeSent);
    if (Object.keys(isInvalidTypeFile).length !== 0) {
      toast.error(
        `${isInvalidTypeFile.message}: ${isInvalidTypeFile.extension}`
      );
      return;
    }

    const isSizeFileValidation = sizeInvalidFileValidation(
      filesToBeSent,
      'WHATSAPP'
    );
    if (Object.keys(isSizeFileValidation).length !== 0) {
      toast.error(
        `${isSizeFileValidation.message} ${isSizeFileValidation.extension}`
      );
      return;
    }

    const imagesWithApprovedExtension =
      isImagesWithApprovedExtension(filesToBeSent);
    const selectedImagePreview = getImagePreviewSingleFile(
      imagesWithApprovedExtension[0]
    );

    const filesWithApprovedExtension =
      isFilesWithApprovedExtension(filesToBeSent);

    const selectedPdfPreview = getPDFPreviewSingleFile(
      filesWithApprovedExtension[0]
    );

    const getFileExtensionsWithPreviewAlreadyGenerated = [
      selectedImagePreview,
      selectedPdfPreview
    ].map((file) => {
      return file?.nameFile.split('.').pop();
    });
    setFileExtensionsWithPreviewAlreadyGenerated(
      getFileExtensionsWithPreviewAlreadyGenerated
    );

    setPreviewImages(undefined);
    setPdfPreview(undefined);
    setVideoPreview(undefined);
    setFiles(filesToBeSent);

    if (type === 'image') {
      setPreviewImages(selectedImagePreview);
    }

    if (type === 'document') {
      setPdfPreview(selectedPdfPreview);
    }

    if (type === 'video') {
      let newVideo = getVideoPreview(filesToBeSent[0]);
      setVideoPreview(newVideo);
    }
  };

  const onInputClick = (event) => {
    event.target.value = '';
  };

  return (
    <>
      {templateType === 'IMAGE' ? (
        <>
          <label
            htmlFor={`uploadFileConversationImage`}
            className="btn mt-2"
            style={{
              ...buttonStyle(systemConfiguration.confirmation_button_color)
            }}
          >
            <>
              <BsFillImageFill />
            </>
          </label>
          <Input
            style={{ display: 'none' }}
            type="file"
            name="uploadFile"
            id={`uploadFileConversationImage`}
            onClick={onInputClick}
            onChange={(e) => {
              handleOnChangeFileToTarget(e, 'image');
            }}
            accept="image/*"
          />
        </>
      ) : null}

      {templateType === 'DOCUMENT' ? (
        <>
          <label
            htmlFor={`uploadFileConversationDocument`}
            className="btn mt-2"
            style={{
              ...buttonStyle(systemConfiguration.confirmation_button_color)
            }}
          >
            <>
              <AiFillFilePdf />
            </>
          </label>
          <Input
            style={{ display: 'none' }}
            type="file"
            name="uploadFile"
            id={`uploadFileConversationDocument`}
            onClick={onInputClick}
            onChange={(e) => {
              handleOnChangeFileToTarget(e, 'document');
            }}
            accept=".pdf"
          />
        </>
      ) : null}

      {templateType === 'VIDEO' ? (
        <>
          <label
            htmlFor={`uploadFileConversationVideo`}
            className="btn mt-2"
            style={{
              ...buttonStyle(systemConfiguration.confirmation_button_color)
            }}
          >
            <>
              <BsCameraVideoFill />
            </>
          </label>
          <Input
            style={{ display: 'none' }}
            type="file"
            name="uploadFile"
            id={`uploadFileConversationVideo`}
            onClick={onInputClick}
            onChange={(e) => {
              handleOnChangeFileToTarget(e, 'video');
            }}
            accept="video/*"
          />
        </>
      ) : null}

      <>
        <Preview>
          {previewImages || (!!url && templateType === 'IMAGE') ? (
            <PreviewImage
              previewImage={
                !previewImages && !!url && templateType === 'VIDEO'
                  ? { url, nameFile: '' }
                  : previewImages
              }
            />
          ) : null}

          {pdfPreview || (!!url && templateType === 'DOCUMENT') ? (
            <PreviewPdf
              filePreview={
                !pdfPreview && !!url && templateType === 'DOCUMENT'
                  ? { url, nameFile: '' }
                  : pdfPreview
              }
            />
          ) : null}

          {videoPreview || (!!url && templateType === 'VIDEO') ? (
            <VideoPreview
              video={
                !videoPreview && !!url && templateType === 'VIDEO'
                  ? { url, nameFile: '' }
                  : videoPreview
              }
            />
          ) : null}
        </Preview>
      </>
    </>
  );
};

export default TemplateMessageMidia;
