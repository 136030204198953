export const strategyTitle =
  'FEWESTCALLS - Toca no ramal com o menor número de chamadas concluídas.\nLEASTRECENT - Toca no ramal que foi desligado a mais tempo.\nLINEAR - Toca na ordem que os ramais entraram na fila\nRANDOM - Toca aleatoriamente.\nRINGALL - Toca todos os ramais disponíveis até um atender.\nRRMEMORY - Memoriza de onde parou e passa para o seguinte.';

export const strategyOptions = [
  { label: 'FEWESTCALLS', value: 'FEWESTCALLS' },
  { label: 'LEASTRECENT', value: 'LEASTRECENT' },
  { label: 'LINEAR', value: 'LINEAR' },
  { label: 'RANDOM', value: 'RANDOM' },
  { label: 'RINGALL', value: 'RINGALL' },
  { label: 'RRMEMORY', value: 'RRMEMORY' }
];

export const booleanOptions = [
  { label: 'Não', value: 'false' },
  { label: 'Sim', value: 'true' }
];
