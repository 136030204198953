import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import TransshipmentService from '../service';
import ExtensionService from '../../Extension/service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import FlexSelect from 'components/Inputs/FlexSelect';
import FormTooltip from 'components/Inputs/FormTooltip';
import {
  sendCasts,
  castExtraTransshipments,
  receiveCasts
} from './assets/Casts.js';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import ReactLoader from 'components/Loader';
import { ScreenPrompt } from 'components/ScreenPrompt';

const TransshipmentForm = (props) => {
  const history = useHistory();
  const { id } = props.match.params;

  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [extensions, setExtensions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [extraTransshipments, setExtraTransshipments] = useState([]);

  useEffect(() => {
    setLoading(true);
    ExtensionService.getAllSimple()
      .then((response) => {
        setExtensions(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      TransshipmentService.get(id)
        .then((response) => {
          let getTransshipment = response.data.data;
          getTransshipment = receiveCasts(getTransshipment);

          const getExtraTransshipment =
            castExtraTransshipments(getTransshipment);
          setExtraTransshipments(getExtraTransshipment);

          reset(getTransshipment);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(
            err.response.data.message || 'Erro ao carregar transbordo!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
          setLoading(false);
        });
    } else {
      setExtraTransshipments([{ leakName: 'leak1', leakTime: 'leak1_time' }]);
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      let formatedData = {
        ...data
      };
      formatedData = sendCasts(formatedData);

      isUpdate
        ? await TransshipmentService.update(id, formatedData)
        : await TransshipmentService.create(formatedData);
      history.push('/admin/transshipment');

      toast.success(
        isUpdate
          ? 'Transbordo alterado com sucesso!'
          : 'Transbordo cadastrado com sucesso!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    } catch (err) {
      toast.error(err.response?.data?.message || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/transshipment');
  };

  const addTransshipment = (event) => {
    event.preventDefault();
    setExtraTransshipments([
      ...extraTransshipments,
      {
        leakName: `leak${extraTransshipments.length + 1}`,
        leakTime: `leak${extraTransshipments.length + 1}_time`
      }
    ]);
  };

  const removeTransshipment = (event) => {
    event.preventDefault();
    let array = [...extraTransshipments];
    const item = array.pop();
    setValue(item.leakName, null);
    setValue(item.leakTime, []);
    setExtraTransshipments([...array]);
  };

  const TransshipmentRow = (leakName, leakTime, index) => {
    return (
      <Row className="mb-4" key={index}>
        <Col md="6">
          <label className="form-control-label">Transbordo {index}*</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={extensions}
                isMulti={true}
                isClearable={true}
                value={props.value}
                valueController={setValue}
                fieldName={leakName}
                labelName="extension_number"
                valueName="extension_number"
              />
            )}
            control={control}
            name={leakName}
            rules={{
              required: 'Transbordo é obrigatório!'
            }}
            defaultValue=""
          />
          <ErrorMessage
            errors={errors}
            name={leakName}
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6">
          <label className="form-control-label">
            Tempo de Ring - Transbordo {index}*
          </label>
          <Controller
            placeholder="Segundos"
            as={Input}
            control={control}
            name={leakTime}
            rules={{
              required: 'Tempo de Ring é obrigatório!',
              pattern: {
                value: /^([0-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|300)$/,
                message:
                  'Tempo de Ring é um campo numérico com valores entre 0 e 300!'
              }
            }}
            defaultValue=""
          />
          <ErrorMessage
            errors={errors}
            name={leakTime}
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
    );
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/transshipment"
        buttonTitle="Voltar para a pagina de transbordo"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Transbordo</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="12">
                          <label className="form-control-label">
                            Ramal Origem*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={extensions}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="extension_id"
                                labelName="extension_number"
                                valueName="extension_number"
                              />
                            )}
                            control={control}
                            name="extension_id"
                            rules={{ required: 'Ramal Origem é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="extension_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Tempo de Ring - Transbordo*
                            <FormTooltip
                              text="Ao colocar 0 em qualquer tempo de ring,
                            o transbordo funcionará como um siga-me"
                            />
                          </label>
                          <Controller
                            placeholder="Segundos"
                            as={Input}
                            control={control}
                            name="extension_time"
                            rules={{
                              required: 'Tempo de Ring é obrigatório!',
                              pattern: {
                                value:
                                  /^([0-9]|[1-8][0-9]|9[0-9]|[12][0-9]{2}|300)$/,
                                message:
                                  'Tempo de Ring é um campo numérico com valores entre 0 e 300!'
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="extension_time"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      {extraTransshipments.map((el, index) => {
                        return TransshipmentRow(
                          el.leakName,
                          el.leakTime,
                          index + 1
                        );
                      })}

                      <Row className="mb-3">
                        <Col md="12" className="text-right">
                          {extraTransshipments.length > 1 && (
                            <ReactButton
                              btnColor="cancelation"
                              data-toggle="tooltip"
                              data-placement="left"
                              title="Remover Transbordo"
                              data-container="body"
                              data-animation="true"
                              onClick={(event) => removeTransshipment(event)}
                            >
                              x
                            </ReactButton>
                          )}
                          <ReactButton
                            btnColor="confirmation"
                            data-toggle="tooltip"
                            data-placement="left"
                            title="Adicionar Transbordo"
                            data-container="body"
                            data-animation="true"
                            onClick={(event) => addTransshipment(event)}
                            disabled={extraTransshipments.length === 10 && true}
                          >
                            <strong>+</strong>
                          </ReactButton>
                        </Col>
                      </Row>

                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TransshipmentForm;
