import React from 'react';
import { Col } from 'reactstrap';
import ReportCardsContainer from '../ReportCardsContainer';
import AgentsCardsContainer from '../AgentsCardsContainer';

import {
  MainContainerWrapper,
  MainContainerContent,
  MainContainerReportCards,
  MainContainerAgentsCards,
  MainContainerNoData
} from './styled';

const MainContainer = ({ data, name }) => {
  return (
    <MainContainerWrapper>
      <MainContainerContent>
        <MainContainerAgentsCards>
          <AgentsCardsContainer data={data} dialerName={name || ''} />
        </MainContainerAgentsCards>

        <MainContainerReportCards>
          {data && data.length > 0 ? (
            <Col style={{ width: '100%', maxWidth: 'none' }} md="4">
              <ReportCardsContainer data={data} />
            </Col>
          ) : (
            <MainContainerNoData>Sem dados disponíveis.</MainContainerNoData>
          )}
        </MainContainerReportCards>
      </MainContainerContent>
    </MainContainerWrapper>
  );
};

export default MainContainer;
