import { useCallback, useState } from 'react';

import { defaultLimit } from 'views/CRM/shared/utils/pagination';

import AccountService from '../services/accountService';

export const useListAccounts = ({ recordTypeId }) => {
  const [accounts, setAccounts] = useState(null);
  const [totalAccounts, setTotalAccounts] = useState(0);
  const [isLoadingAccounts, setIsLoadingAccounts] = useState(false);

  const getAccounts = useCallback(
    (page = 1, params = {}) => {
      setIsLoadingAccounts(true);

      AccountService.getAccounts(recordTypeId, params, {
        page,
        limit: defaultLimit
      })
        .then((response) => {
          setAccounts(response.data.data);
          setTotalAccounts(response.data.totalRegisters);
        })
        .finally(() => setIsLoadingAccounts(false));
    },
    [recordTypeId]
  );

  const clearAccounts = useCallback(() => {
    setAccounts(null);
    setTotalAccounts(0);
    setIsLoadingAccounts(false);
  }, []);

  return {
    accounts,
    totalAccounts,
    isLoadingAccounts,
    getAccounts,
    clearAccounts
  };
};
