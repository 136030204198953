import { actionTypes } from './actionTypes';

export function actionCreatorLogin(payload) {
  return {
    type: actionTypes.USER_LOGGED_IN,
    payload
  };
}

export function actionCreatorLogout() {
  return {
    type: actionTypes.USER_LOGGED_OUT
  };
}
