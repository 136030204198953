import styled from 'styled-components';

export const AgentsCardsContainerWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: transparent;
  /* display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr 1fr; */
  grid-gap: 1rem;
  padding: 1rem;
  overflow: hidden;
  overflow-y: auto;
  background: transparent;
`;
