import React from 'react';
import ReportCard from '../ReportCard';
import styled from 'styled-components';

// const Container = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
// `;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: space-between; */
  gap: 0.5rem;
`;

const ReportCardsContainer = ({ data = {} }) => {
  return (
    <Container>
      <ReportCard
        data={{
          title: 'Fila de Espera',
          value: data.watingCalls.length || '0'
        }}
      />
      <ReportCard
        data={{
          title: 'Quantidade de Callbacks',
          value: data.amount || '0'
        }}
      />
      <ReportCard
        data={{
          title: 'Quantidade de Retornos com Sucesso',
          value: data.returnSuccess || '0'
        }}
      />
      <ReportCard
        data={{
          title: 'Quantidade de Retornos Atendidos',
          value: data.numberAnswered || '0'
        }}
      />
      <ReportCard
        data={{
          title: 'Tempo Médio de Retorno',
          value: data.avgToReturnFormatted || '00:00:00'
        }}
      />
      <ReportCard
        data={{
          title: 'Tempo Máximo de retorno',
          value: data.maxReturnFormatted || '00:00:00'
        }}
      />
      <ReportCard
        data={{
          title: 'Tempo Médio de Duração',
          value: data.avgBillsecFormatted || '00:00:00'
        }}
      />
      <ReportCard
        data={{
          title: 'Tempo Máximo de Duração',
          value: data.maxReturnFormatted || '00:00:00'
        }}
      />
    </Container>
  );
};

export default ReportCardsContainer;
