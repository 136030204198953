import styled from 'styled-components';
import { Col } from 'reactstrap';

import ReactButton from 'components/Buttons/ReactButton';

export const StyledCol = styled(Col)`
  display: flex;
  gap: 30px;
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const SendRequestButton = styled(ReactButton)`
  align-self: end;
  height: calc(1.5em + 1.25rem + 5px);
`;

export const ResponseContainer = styled.div`
  border-radius: 4px;
  border: 1px solid hsl(0, 0%, 80%);
  padding: 0.625rem 0.75rem;
  width: 100%;
  height: 300px;
  overflow-x: hidden;
  overflow-y: auto;

  ${(props) =>
    props.center &&
    `
    display: flex;
    justify-content: center;
    align-items: center;
  `}

  > pre {
    margin-bottom: 0;

    > code {
      font-size: 0.875rem;
      font-family: 'Open Sans', sans-serif;
    }
  }
`;
