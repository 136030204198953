import ServiceCRUD from '../../../../../services/ServiceCRUDFLEXCHAT';
import { apiChat } from '../../../../../http';

class HeatmapService extends ServiceCRUD {
  constructor() {
    super('relatorio');
  }

  getHeatmap = (queryParams) => {
    console.log(`${this.path}`)
    return apiChat.get(`${this.path}/mapa-de-calor`,{
      params: {
        ...queryParams
      }
    })
  }

}

export default new HeatmapService();
