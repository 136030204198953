import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ReactButton from 'components/Buttons/ReactButton';

import LeadProcessService from '../service';
import { LeadStagesConfig } from './components/LeadStagesConfig';
import { useListLeadStages } from 'views/CRM/LeadStages/hooks/useListLeadStages';
import FlexSelect from 'components/Inputs/FlexSelect';
import { ScreenPrompt } from 'components/ScreenPrompt';

const LeadProcessForm = () => {
  const [loading, setLoading] = useState(false);
  const [leadProcess, setLeadProcess] = useState(null);
  const [leadStagesConfig, setLeadStagesConfig] = useState([]);
  const [processTime, setProcessTime] = useState(0);

  const history = useHistory();
  const { id } = useParams();
  const {
    control,
    handleSubmit,
    errors,
    setValue,
    watch,
    register,
    reset,
    formState
  } = useForm();
  const { isDirty } = formState;

  const { leadStages, getLeadStages, isLoadingLeadStages } =
    useListLeadStages();

  const selectedLeadStages = watch('leadStages');

  useEffect(() => {
    getLeadStages();
  }, [getLeadStages]);

  useEffect(() => {
    let total = 0;
    if (leadStagesConfig.length) {
      for (const stage of leadStagesConfig) {
        total += stage.time;
      }
    }
    setProcessTime(total);
  }, [leadStagesConfig]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      LeadProcessService.getById(id)
        .then((response) => {
          const data = response.data.data;
          reset({
            name: data.name,
            leadStages: data?.leadStagesConfig?.map(
              (config) => config.leadStageId
            )
          });
          setLeadProcess(data);
        })
        .catch((error) => {
          const msg =
            error.response?.data?.message || 'Erro ao buscar processo do lead';
          toast.error(msg);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, reset]);

  const showValidationError = (message = '') => {
    toast.error(message);
  };

  const onSubmit = async (data) => {
    const { isDefault, isConversion, isLost } = data;
    if (!isDefault) {
      return showValidationError('Selecione a fase padrão');
    }

    if (!isConversion) {
      return showValidationError('Selecione a fase de conversão');
    }

    if (!isLost) {
      return showValidationError('Selecione a fase de perdido');
    }

    if (isDefault === isConversion) {
      return showValidationError(
        'A fase de conversão não pode ser a fase padrão'
      );
    }

    if (isDefault === isLost) {
      return showValidationError(
        'A fase de padrão não pode ser a fase perdido'
      );
    }

    if (isLost === isConversion) {
      return showValidationError(
        'A fase de conversão não pode ser a fase perdido'
      );
    }

    const config = leadStagesConfig
      .map(({ id }) => id)
      .map((leadStageId) => ({
        leadStageId,
        isDefault: leadStageId === Number(data.isDefault),
        isConversion: leadStageId === Number(data.isConversion),
        isLost: leadStageId === Number(data.isLost),
        isActive: true
      }));

    const requestDataDTO = {
      name: data.name,
      leadStagesConfig: config
    };

    const savePromise = id
      ? LeadProcessService.update(id, requestDataDTO)
      : LeadProcessService.create(requestDataDTO);

    setLoading(true);

    savePromise
      .then(() => {
        backToListing();
      })
      .catch((error) => {
        const msg =
          error.response?.data?.message || 'Erro ao criar processo do lead';
        toast.error(msg);
        setLoading(false);
      });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const backToListing = () => {
    history.push('/admin/crm/leadProcess');
  };

  const getDefaultStageId = useCallback(() => {
    if (!id || !leadProcess) return null;
    return leadProcess.leadStagesConfig.find((config) => config.isDefault)
      ?.leadStageId;
  }, [id, leadProcess]);

  const getConversionStageId = useCallback(() => {
    if (!id || !leadProcess) return null;
    return leadProcess.leadStagesConfig.find((config) => config.isConversion)
      ?.leadStageId;
  }, [id, leadProcess]);

  const getLostStageId = useCallback(() => {
    if (!id || !leadProcess) return null;
    return leadProcess.leadStagesConfig.find((config) => config.isLost)
      ?.leadStageId;
  }, [id, leadProcess]);

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/crm/leadProcess"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row className="mb-3">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Processo do Lead</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">Nome*</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="name"
                          rules={{
                            required: 'Nome é obrigatório',
                            maxLength: {
                              value: 50,
                              message: 'Nome deve ter no máximo 50 caracteres'
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="name"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">Fases*</label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={leadStages || []}
                              isMulti={true}
                              value={props.value}
                              valueController={setValue}
                              fieldName="leadStages"
                              labelName="name"
                              valueName="id"
                              isClearable={true}
                              disabled={isLoadingLeadStages}
                              noOptionsMessage={() =>
                                'Nenhuma fase para ser exibida'
                              }
                            />
                          )}
                          control={control}
                          name="leadStages"
                          defaultValue=""
                          rules={{
                            required: 'Selecione pelo menos duas fases',
                            validate: (value) => {
                              if (!value || !value.length)
                                return 'Selecione pelo menos duas fases';
                              return true;
                            }
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="leadStages"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col sm="12" style={{ overflowX: 'auto' }}>
                        <LeadStagesConfig
                          register={register}
                          control={control}
                          setValue={setValue}
                          leadStages={leadStages}
                          selectedLeadStages={selectedLeadStages}
                          defaultStageId={getDefaultStageId()}
                          conversionStageId={getConversionStageId()}
                          lostStageId={getLostStageId()}
                          leadStagesConfig={leadStagesConfig}
                          setLeadStagesConfig={setLeadStagesConfig}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <span style={{ float: 'right' }}>
                          Tempo total do processo: {processTime} dia(s)
                        </span>
                      </Col>
                    </Row>
                    <hr />
                    <ReactButton
                      btnColor="confirmation"
                      type="submit"
                      disabled={loading}
                    >
                      Salvar
                    </ReactButton>

                    <ReactButton
                      btnColor="cancelation"
                      onClick={backToListing}
                      disabled={loading}
                    >
                      Cancelar
                    </ReactButton>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default LeadProcessForm;
