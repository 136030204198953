import React from 'react';
import { Content } from '../../Content';
import { ModalBody as ModalBodyReactStrap } from 'reactstrap';
export function ModalBody({
  login,
  protocol,
  user,
  chat,
  calls,
  toggleModal,
  loadImage
}) {
  return (
    <ModalBodyReactStrap style={{ padding: '1.5rem 1.5rem 0 1.5rem' }}>
      <hr style={{ marginTop: '-30px' }} />
      <Content
        login={login}
        protocol={protocol}
        user={user}
        calls={calls}
        chat={chat}
        toggleModal={toggleModal}
        loadImage={loadImage}
      />
    </ModalBodyReactStrap>
  );
}
