export const receiveCasts = (getTransshipment) => {
  if (getTransshipment.leak1) {
    getTransshipment = {
      ...getTransshipment,
      leak1: getTransshipment.leak1.split(',')
    };
  } else {
    getTransshipment = {
      ...getTransshipment,
      leak1: '',
      leak1_time: ''
    };
  }

  if (getTransshipment.leak2) {
    getTransshipment = {
      ...getTransshipment,
      leak2: getTransshipment.leak2.split(',')
    };
  } else {
    getTransshipment = {
      ...getTransshipment,
      leak2: '',
      leak2_time: ''
    };
  }
  if (getTransshipment.leak3) {
    getTransshipment = {
      ...getTransshipment,
      leak3: getTransshipment.leak3.split(',')
    };
  } else {
    getTransshipment = {
      ...getTransshipment,
      leak3: '',
      leak3_time: ''
    };
  }
  if (getTransshipment.leak4) {
    getTransshipment = {
      ...getTransshipment,
      leak4: getTransshipment.leak4.split(',')
    };
  } else {
    getTransshipment = {
      ...getTransshipment,
      leak4: '',
      leak4_time: ''
    };
  }
  if (getTransshipment.leak5) {
    getTransshipment = {
      ...getTransshipment,
      leak5: getTransshipment.leak5.split(',')
    };
  } else {
    getTransshipment = {
      ...getTransshipment,
      leak5: '',
      leak5_time: ''
    };
  }

  return getTransshipment;
};

export const sendCasts = (formatedData) => {
  if (formatedData.leak1) {
    formatedData = {
      ...formatedData,
      leak1: formatedData.leak1.join(',')
    };
  } else {
    formatedData = {
      ...formatedData,
      leak1: null,
      leak1_time: null
    };
  }

  if (formatedData.leak2) {
    formatedData = {
      ...formatedData,
      leak2: formatedData.leak2.join(',')
    };
  } else {
    formatedData = {
      ...formatedData,
      leak2: null,
      leak2_time: null
    };
  }
  if (formatedData.leak3) {
    formatedData = {
      ...formatedData,
      leak3: formatedData.leak3.join(',')
    };
  } else {
    formatedData = {
      ...formatedData,
      leak3: null,
      leak3_time: null
    };
  }
  if (formatedData.leak4) {
    formatedData = {
      ...formatedData,
      leak4: formatedData.leak4.join(',')
    };
  } else {
    formatedData = {
      ...formatedData,
      leak4: null,
      leak4_time: null
    };
  }
  if (formatedData.leak5) {
    formatedData = {
      ...formatedData,
      leak5: formatedData.leak5.join(',')
    };
  } else {
    formatedData = {
      ...formatedData,
      leak5: null,
      leak5_time: null
    };
  }

  return formatedData;
};

export const castExtraTransshipments = (getTransshipment) => {
  let getExtraTransshipment = [];

  if (getTransshipment.leak1)
    getExtraTransshipment = [
      ...getExtraTransshipment,
      { leakName: 'leak1', leakTime: 'leak1_time' }
    ];

  if (getTransshipment.leak2)
    getExtraTransshipment = [
      ...getExtraTransshipment,
      { leakName: 'leak2', leakTime: 'leak2_time' }
    ];

  if (getTransshipment.leak3)
    getExtraTransshipment = [
      ...getExtraTransshipment,
      { leakName: 'leak3', leakTime: 'leak3_time' }
    ];

  if (getTransshipment.leak4)
    getExtraTransshipment = [
      ...getExtraTransshipment,
      { leakName: 'leak4', leakTime: 'leak4_time' }
    ];

  if (getTransshipment.leak5)
    getExtraTransshipment = [
      ...getExtraTransshipment,
      { leakName: 'leak5', leakTime: 'leak5_time' }
    ];

  return getExtraTransshipment;
};
