import React from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Row, Col, Input } from 'reactstrap';

export const Flex4UForm = ({ canal, control, errors }) => {
  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <Row style={{ marginTop: '4px' }}>
        <Col md="6">
          <label className="form-control-label">Nome do canal</label>
          <Controller
            as={Input}
            control={control}
            name="nome_canal"
            defaultValue={canal?.nome_canal ?? ''}
            rules={{
              required: 'Nome é um campo obrigatório'
            }}
          />
          <ErrorMessage
            errors={errors}
            name="nome_canal"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6">
          <label className="form-control-label">Nome do atendente</label>
          <Controller
            as={Input}
            control={control}
            name="nome_atendente"
            rules={{
              required: 'Nome do atendente é um campo obrigatório'
            }}
            defaultValue={canal?.nome_atendente ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="nome_atendente"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
    </>
  );
};
