import React from 'react';

import { CompTime } from './style';


export const FormatTime = ({ date, paramAlign }) => {
  let dateAux = `${new Date(date).getHours()}:${
      new Date(date).getMinutes() < 10 ? '0' : ''
    }${new Date(date).getMinutes()}`;

  return (
    <CompTime paramAlign={paramAlign}>
     {dateAux}
    </CompTime>
  );
};
