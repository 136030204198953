import ServiceCRUD from '../../../services/ServiceCRUDFLEXUC';
import { apiUc as http } from '../../../http';

class Service extends ServiceCRUD {
  constructor() {
    super('/api/qa/paper');
  }

  updateCategories(paperId, data) {
    return http.put(`${this.path}/${paperId}/category`, data);
  }

  updateQuestions(paperId, data) {
    return http.put(`${this.path}/${paperId}/question`, data);
  }

  deleteQuestion(paperId, questionId) {
    return http.delete(`${this.path}/${paperId}/question/${questionId}`);
  }
}

export default new Service();
