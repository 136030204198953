import randomColor from 'randomcolor';
import { getLabel } from '../assets/consts';

export const calculateData = () => {
  const calculateProtocolByAttendants = (response) => {
    const { totalProtocolsPerUser } = response;

    let arrayLabels = [];
    let arrayData = [];
    let arrayColors = [];
    let totalCount = 0;

    if (totalProtocolsPerUser) {
      arrayLabels = totalProtocolsPerUser.map((protocol) => {
        return protocol.protocol_current_responsible.name;
      });
      arrayData = totalProtocolsPerUser.map((protocol) => {
        return parseInt(protocol.total);
      });

      totalCount = arrayData.reduce(function (sum, i) {
        return sum + i;
      }, 0);

      for (let index = 0; index < arrayData.length; index++) {
        arrayColors.push(randomColor({ luminosity: 'random', hue: 'random' }));
      }

      const returnData = {
        arrayLabels,
        arrayData,
        arrayColors,
        totalCount
      };

      return returnData;
    }
    return null;
  };

  const calculateTotalProtocolsPerMonth = (response) => {
    let days = [];
    let quantity = [];

    const filterMonth = response.filter((month) => month.month > 0);

    filterMonth.forEach((el) => {
      days.push(getLabel(el.day, el.month, el.year));
      quantity.push(el.total);
    });

    const returnData = {
      data: {
        labels: [...days],
        datasets: [
          {
            label: 'Protocolos',
            data: [...quantity],
            backgroundColor: '#00a17c',
            maxBarThickness: 10
          }
        ]
      }
    };

    return returnData;
  };

  const calculateTotalProtocolsPerStatus = (response) => {
    let labels = [];
    let datasets = [
      {
        label: 'Total de protocolos: ',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1
      }
    ];
    let protocolDataForSubtitles = [];
    let totalProtocolPerMonth = 0;

    if (response !== null) {
      response.forEach((res) => {
        const convertToTotalNumberOfProtocolsPerMonth = Number(res.total);
        totalProtocolPerMonth =
          convertToTotalNumberOfProtocolsPerMonth + totalProtocolPerMonth;
      });

      response.forEach((res) => {
        labels.push(res?.status?.name || 'Protocolo criado sem status/fase');
        let backgroundColor = randomColor({
          luminosity: 'random',
          hue: 'random',
          format: 'rgb'
        });
        datasets[0].data.push({
          y: res.total,
          percentage:
            Number(res.total * 100) / Number(totalProtocolPerMonth).toFixed(2)
        });
        datasets[0].backgroundColor.push(backgroundColor);
        datasets[0].borderColor.push(backgroundColor);
        protocolDataForSubtitles.push({
          name: res?.status?.name || 'Protocolo criado sem status/fase',
          backgroundColor
        });
      });
    }

    const data = {
      labels: [...labels],
      datasets: [...datasets],
      protocolDataForSubtitles
    };

    return data;
  };

  const calculateTotalProtocolsPerReason = (response) => {
    let labels = [];
    let datasets = [
      {
        label: 'Total de protocolos: ',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1
      }
    ];
    let protocolDataForSubtitles = [];
    let totalProtocolPerMonth = 0;

    if (response !== null) {
      response.forEach((res) => {
        const convertToTotalNumberOfProtocolsPerMonth = Number(res.total);
        totalProtocolPerMonth =
          convertToTotalNumberOfProtocolsPerMonth + totalProtocolPerMonth;
      });
      response.forEach((res) => {
        labels.push(res?.reason?.name || 'Protocolo criado sem motivo');
        let backgroundColor = randomColor({
          luminosity: 'random',
          hue: 'random',
          format: 'rgb'
        });
        datasets[0].data.push({
          y: res.total,
          percentage:
            Number(res.total * 100) / Number(totalProtocolPerMonth).toFixed(2)
        });
        datasets[0].backgroundColor.push(backgroundColor);
        datasets[0].borderColor.push(backgroundColor);
        protocolDataForSubtitles.push({
          name: res?.reason?.name || 'Protocolo criado sem motivo',
          backgroundColor
        });
      });
    }
    const data = {
      labels: [...labels],
      datasets: [...datasets],
      protocolDataForSubtitles
    };

    return data;
  };

  const calculateTotalProtocolsPerSubReason = (response) => {
    let labels = [];
    let datasets = [
      {
        label: 'Total de protocolos: ',
        data: [],
        backgroundColor: [],
        borderColor: [],
        borderWidth: 1
      }
    ];
    let protocolDataForSubtitles = [];
    let totalProtocolPerMonth = 0;

    if (response !== null) {
      response.forEach((res) => {
        const convertToTotalNumberOfProtocolsPerMonth = Number(res.total);
        totalProtocolPerMonth =
          convertToTotalNumberOfProtocolsPerMonth + totalProtocolPerMonth;
      });
      response.forEach((res) => {
        labels.push(res?.subreason?.name || 'Protocolo criado sem submotivo');
        let backgroundColor = randomColor({
          luminosity: 'random',
          hue: 'random',
          format: 'rgb'
        });
        datasets[0].data.push({
          y: res.total,
          percentage:
            Number(res.total * 100) / Number(totalProtocolPerMonth).toFixed(2)
        });
        datasets[0].backgroundColor.push(backgroundColor);
        datasets[0].borderColor.push(backgroundColor);
        protocolDataForSubtitles.push({
          name: res?.subreason?.name || 'Protocolo criado sem submotivo',
          backgroundColor
        });
      });
    }
    const data = {
      labels: [...labels],
      datasets: [...datasets],
      protocolDataForSubtitles
    };

    return data;
  };

  return {
    calculateProtocolByAttendants,
    calculateTotalProtocolsPerMonth,
    calculateTotalProtocolsPerStatus,
    calculateTotalProtocolsPerReason,
    calculateTotalProtocolsPerSubReason
  };
};
