import styled from 'styled-components';

export const UserInfoWrapper = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
  color: #5e6a88;
  padding-bottom: 0.5rem;
`;

export const Label = styled.label`
  font-size: 0.9rem;
  font-weight: bold;
  color: #5e72e4;
  font-weight: bold;
`;

export const Button = styled.button`
  border: 0;
  border-radius: 0.2rem;
  margin-top: 0.6rem;
  background-color: #5e72e4;
  width: 100%;
  height: 40px;
  font-weight: 550;
  color: white;
  transition: 0.5s;
  &:hover {
    background-color: #36327f;
  }
`;

export const ContentLoading = styled.div`
  margin: 0 auto;
`;
