import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { typesOfReports } from '../utils/types';
import { formattedValuesToList } from '../assets/formattedValuesToList';
import { formattedValuesToSubmit } from '../assets/formattedValuesToSubmit';
import { useForm, Controller } from 'react-hook-form';
import { Alerts } from './components/Alerts';
import { Loading } from './components/Loading';
import { SendingForm } from './components/SendingForm';
import FlexSelect from '../../../../components/Inputs/FlexSelect';
import Pagination from 'components/Pagination';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import TableAnalytical from './components/Tables/Analytical';
import TableSyntetical from './components/Tables/Syntetical';
import { Container, Row, Card, Col, CardHeader, CardBody } from 'reactstrap';
import { toast } from 'react-toastify';
import * as fileSaver from 'file-saver';
import AuthService from './service';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';

const AnalyticalAuthentication = () => {
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [dataToSubmit, setDataToSubmit] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [dataReport, setDataReport] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [dataField, setDataField] = useState();
  const { control, setValue, watch } = useForm();
  const reportType = watch('reportType') || 'analytical';
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  useEffect(() => {
    setDataReport(null);
  }, [reportType]);

  async function onSubmit(data) {
    const parameters = {
      dataToSend: data,
      page: 1
    };

    setDataToSubmit(parameters.dataToSend);
    getReports(parameters.dataToSend, parameters.page);
  }

  const getReports = useCallback(
    async (dataToSend, page) => {
      try {
        setIsLoading(true);
        const newActivePage = page || activePage;

        const dataToSubmit = formattedValuesToSubmit({
          ...dataToSend,
          page: newActivePage
        });
        const response = await AuthService.getAll(dataToSubmit, reportType);
        const responseData = response?.data?.data;
        const reportData = formattedValuesToList(
          responseData.content,
          reportType
        );
        setActivePage(page);
        setDataReport(reportData);
        setDataField(dataToSend);
        setQuantity(responseData.quantity);
        setIsLoading(false);
        if (responseData.quantity === 0) {
          Alerts('info', 'Nenhum registro foi encontrado');
        }
      } catch (erro) {
        Alerts('error', 'Ops. Não foi possivel formar relatório');
        console.error('error get reports =>', erro);
      }
    },
    [activePage, reportType]
  );

  const getMimeTypeFromType = (type) => {
    const mimeTypes = new Map();
    mimeTypes.set('csv', 'application/csv');
    mimeTypes.set('pdf', 'application/pdf');
    return mimeTypes.get(type);
  };

  const getFileNameFromType = (type) => {
    const fileNames = new Map();
    const date = new Date().toLocaleDateString('pt-br');
    fileNames.set('csv', `Relatório Analítico de Autenticação${date}.csv`);
    fileNames.set('pdf', `Relatório Analítico de Autenticação${date}.pdf`);
    return fileNames.get(type);
  };

  const downloadMedia = useCallback(
    async (media, reportType) => {
      try {
        setIsLoading(true);

        const response = await AuthService.getDownloadsPdfAndCsv(
          media,
          reportType,
          dataField
        );

        const mimeType = getMimeTypeFromType(media);
        const fileName = getFileNameFromType(media);
        fileSaver.saveAs(
          new Blob([response.data], { type: mimeType }),
          fileName
        );
      } catch (err) {
        toast.error('Ops, ocorreu um erro ao realizar o download do arquivo', {
          autoClose: 3000,
          closeOnClick: true
        });
      } finally {
        setIsLoading(false);
      }
    },
    [dataField]
  );

  const handlePageChange = (activePage) => {
    getReports(dataToSubmit, activePage);
  };

  const ReportTypeComponent = () => {
    return (
      <Row className="mb-12" style={{ marginBottom: '0.5rem' }}>
        <Col md="12">
          <label className="form-control-label">Tipo de Relatório</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={typesOfReports}
                isMulti={false}
                value={props.value}
                valueController={setValue}
                closeMenuOnSelect={true}
                fieldName="reportType"
                labelName="label"
                valueName="value"
              />
            )}
            control={control}
            name="reportType"
            defaultValue={'analytical'}
          />
          <div style={{ marginTop: '10px' }}>
            <UserFlexSelect
              usersShowDisable={usersShowDisable}
              setUsersShowDisable={setUsersShowDisable}
              name={'Usuário'}
            />
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Autenticação</h3>
                </CardHeader>
                <CardBody>
                  <SendingForm
                    downloadMedia={downloadMedia}
                    quantity={parseInt(quantity) || 0}
                    reportType={reportType}
                    filters={dataField}
                    onSubmit={onSubmit}
                    ReportTypeComponent={ReportTypeComponent}
                    usersShowDisable={usersShowDisable}
                  ></SendingForm>
                </CardBody>

                {isLoading ? (
                  <Loading color={systemConfiguration.primary_color} />
                ) : (
                  (dataToSubmit &&
                    quantity > 0 &&
                    reportType === 'analytical' &&
                    dataReport && (
                      <>
                        <TableAnalytical
                          quantity={quantity}
                          reports={dataReport}
                        />
                        <Pagination
                          activePage={activePage}
                          totalItemsCount={quantity}
                          onChange={handlePageChange.bind(this)}
                        />
                      </>
                    )) ||
                  (dataToSubmit &&
                    quantity > 0 &&
                    reportType === 'synthetic' &&
                    dataReport && (
                      <>
                        <TableSyntetical
                          quantity={quantity}
                          reports={dataReport}
                        />
                        <Pagination
                          activePage={activePage}
                          totalItemsCount={quantity}
                          onChange={handlePageChange.bind(this)}
                        />
                      </>
                    ))
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AnalyticalAuthentication;
