import React, { useState, useEffect } from 'react';
import DefaultModal from '../../../../../../components/Modal/DefaultModal';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import LeadTimeService from '../../../../Report/TimeReport/services/leadService';
import { toast } from 'react-toastify';

const LeadTimeModal = ({
  isModalOpen,
  onModalToggle,
  leadId,
  changedStage,
  setChangedStage,
  ...props
}) => {
  const [leadTime, setLeadTime] = useState();

  useEffect(() => {
    LeadTimeService.getLeadTimeByLeadId(leadId)
      .then(({ data }) => {
        setLeadTime(data.data);
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.message || 'Erro ao buscar dados do Lead'
        );
      });
  }, [leadId]);

  useEffect(() => {
    if (changedStage) {
      LeadTimeService.getLeadTimeByLeadId(leadId)
        .then(({ data }) => {
          setLeadTime(data.data);
        })
        .catch((err) => {
          toast.error(
            err.response?.data?.message || 'Erro ao buscar dados do Lead'
          );
        })
        .finally(() => {
          setChangedStage(false);
        });
    }
  }, [leadId, changedStage, setChangedStage]);

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Prazos do Lead"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      <>
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  {leadTime?.length >= 1 ? (
                    leadTime?.map((leadTime) => (
                      <div
                        key={leadTime.id}
                        className="timeline timeline-one-side mx-0"
                        data-timeline-axis-style="dashed"
                        data-timeline-content="axis"
                      >
                        <div className="timeline-block pb-5">
                          <span className="timeline-step badge-success">
                            <i className="ni ni-calendar-grid-58" />
                          </span>

                          <div
                            className="timeline-content"
                            style={{ maxWidth: '80rem' }}
                          >
                            <p className="text-sm mt-1 mb-0">
                              <strong>Fase do Lead</strong>:{' '}
                              {leadTime.stageName}
                            </p>

                            <p className="text-sm mt-1 mb-0">
                              <strong>Dias que passou na fase</strong>:{' '}
                              {leadTime.time}
                            </p>

                            <p className="text-sm mt-1 mb-0">
                              <strong>Lead foi movido dentro do prazo</strong>:{' '}
                              {leadTime.movedAtTime ? (
                                <span className="text-success">Sim</span>
                              ) : (
                                <span className="text-danger">Não</span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm mt-1 mb-0 text-center">
                      Não há nenhuma informação a ser mostrada.
                    </p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    </DefaultModal>
  );
};

export default LeadTimeModal;
