import { useState, useEffect } from 'react';
import ExtensionService from '../../../Extension/service';

export function useGetExtensions() {
  const [extensions, setExtensions] = useState([]);

  useEffect(() => {
    ExtensionService.getAllSimple()
      .then((response) => {
        setExtensions(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);

  return {
    extensions,
    setExtensions
  };
}
