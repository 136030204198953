/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useCallback } from 'react';
import SearchHeader from '../../components/Headers/SearchHeader';
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Row,
  Col,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Input,
  FormGroup,
  Label,
  Button
} from 'reactstrap';
import { formatDistance } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../assets/styles/table_custom_style';
import Pagination from 'components/Pagination';
import ReactLoader from 'components/Loader';
import AllNotificationService from './service/AllNotificationService';
import OverTimesService from '../ExtraHour/service';
import { toast } from 'react-toastify';

export default function AllNotification() {
  const shouldSign = JSON.parse(
    localStorage.getItem('should_sign_in_asterisk')
  );
  const [showDisabled, setShowDisable] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [timeoutID, setTimeoutID] = useState(null);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [querySearch, setQuerySearch] = useState('');

  const loadApiNotification = useCallback(
    (page = 1, querySearch = '') => {
      const size = 10;
      AllNotificationService.getAllSearchSupervisor(
        showDisabled,
        size,
        page,
        querySearch
      ).then((response) => {
        setQuantity(response.data.totalRegisters);
        const data = response.data.data;
        setNotifications(data);
        setLoading(false);
      });
    },
    [showDisabled]
  );

  const ControlerStatus = (id, status) => {
    try {
      if (status === 'APPROVED') {
        OverTimesService.patch(id, { status })
          .then(() => loadApiNotification())
          .catch((err) => toast.error(err));
      }
      if (status === 'REJECTED') {
        OverTimesService.patch(id, { status })
          .then(() => loadApiNotification())
          .catch((err) => toast.error(err));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  function calculateDateDiference(startDate) {
    const endDate = new Date();
    const diferenceUpdated = formatDistance(startDate, endDate, { locale: pt });
    return diferenceUpdated;
  }

  useEffect(() => {
    loadApiNotification();
  }, [loadApiNotification]);

  const handlePageChange = (page) => {
    setActivePage(page);
    loadApiNotification(page, querySearch);
  };

  const handleOnSearch = (event) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const searchValue = event.target.value;
    setQuerySearch(searchValue);
    setTimeoutID(
      setTimeout(() => {
        loadApiNotification(1, searchValue);
      }, 1000)
    );
  };

  return (
    <>
      <SearchHeader />
      <Container fluid>
        <Card>
          <CardHeader>
            <h2>Notificações</h2>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="8">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Buscar Notificação"
                    type="text"
                    onChange={handleOnSearch}
                  />
                </InputGroup>
              </Col>
            </Row>
          </CardBody>
          {shouldSign ? (
            <ToolkitProvider
              data={notifications}
              keyField="id"
              columns={[
                {
                  dataField: 'to',
                  text: 'Login do atendente',
                  sort: true,
                  style: individual_column_style
                },
                {
                  dataField: 'from',
                  text: 'Login do Emissor',
                  sort: true,
                  style: individual_column_style,
                  formatter: (_, row) => {
                    return `${row.from} ${
                      row.supervisor !== null ? '- (Supervisor)' : ''
                    }`;
                  }
                },
                {
                  dataField: 'content',
                  text: 'Conteúdo da notificação',
                  sort: true,
                  style: {
                    ...individual_column_style,
                    width: '100%',
                    whiteSpace: 'inherit',
                    textAlign: 'justify'
                  }
                },
                {
                  dataField: 'createdAt',
                  text: 'Tempo da notificação',
                  sort: true,
                  style: individual_column_style,
                  formatter: (_, row) => {
                    return calculateDateDiference(new Date(row.createdAt));
                  }
                }
              ]}
            >
              {(props) => (
                <div className="py-4 table-responsive">
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  )}
                </div>
              )}
            </ToolkitProvider>
          ) : (
            <ToolkitProvider
              data={notifications}
              keyField="id"
              columns={[
                {
                  dataField: 'to',
                  text: 'Login do atendente',
                  sort: true,
                  style: individual_column_style
                },
                {
                  dataField: 'from',
                  text: 'Login do Emissor',
                  sort: true,
                  style: individual_column_style,
                  formatter: (_, row) => {
                    return `${row.from} ${
                      row.supervisor !== null ? '- (Supervisor)' : ''
                    }`;
                  }
                },
                {
                  dataField: 'content',
                  text: 'Conteúdo da notificação',
                  sort: true,
                  style: {
                    ...individual_column_style,
                    width: '100%',
                    whiteSpace: 'inherit',
                    textAlign: 'justify'
                  }
                },
                {
                  dataField: 'createdAt',
                  text: 'Tempo da notificação',
                  sort: true,
                  style: individual_column_style,
                  formatter: (_, row) => {
                    return calculateDateDiference(new Date(row.createdAt));
                  }
                },
                {
                  dataField: 'aprove',
                  style: icon_column_style,
                  text: 'Aprovar',
                  // eslint-disable-next-line react/display-name
                  formatter: (_, row) => {
                    const isAprove = row.status !== 'PENDING';
                    return (
                      <Button
                        color="success"
                        size="sm"
                        title={'Confirmar hora extra'}
                        disabled={isAprove}
                        onClick={() => ControlerStatus(row.id, 'APPROVED')}
                      >
                        <li className="fas fa-check-circle"></li>
                      </Button>
                    );
                  }
                },
                {
                  dataField: 'delete',
                  style: icon_column_style,
                  text: 'Rejeitar',
                  // eslint-disable-next-line react/display-name
                  formatter: (_, row) => {
                    const isReject = row.status !== 'PENDING';

                    return (
                      <Button
                        color="danger"
                        size="sm"
                        title={'Rejeitar hora extra'}
                        disabled={isReject}
                        onClick={() => ControlerStatus(row.id, 'REJECTED')}
                      >
                        <li className="fas fa-times"></li>
                      </Button>
                    );
                  }
                }
              ]}
            >
              {(props) => (
                <div className="py-4 table-responsive">
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  )}
                </div>
              )}
            </ToolkitProvider>
          )}

          <Pagination
            activePage={activePage}
            totalItemsCount={quantity}
            onChange={handlePageChange.bind(this)}
          />
        </Card>
      </Container>
    </>
  );
}
