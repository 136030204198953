import styled from 'styled-components';

export const Form = styled.form`
  /* margin-left: auto;
  margin-right: auto;
  width: 98%; */

  .accodion {
    padding: 10px;
  }

  .accodionEnd {
    padding: 10px;
    background: #fff;
  }

  .contentButton {
    background: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
  }

  .btnCancel {
    border-radius: 5px 0 0 5px;
    color: #ee6363;
    border: 1px solid #ee6363;
    transition: ease 0.3s;
  }

  .btnCancel:hover {
    border-radius: 5px 0 0 5px;
    color: #fff;
    border: 1px solid #ee6363;
    background: #ee6363;
  }

  .btnSave {
    color: #00a17c;
    border: 1px solid #00a17c;
    transition: ease 0.3s;
    border-radius: 0 5px 5px 0;
    margin-right: 20px;
  }

  .btnSave:hover {
    color: #fff;
    border: 1px solid #00a17c;
    background: #00a17c;
  }
`;
