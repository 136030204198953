import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import SpyService from '../service';
import ExtensionService from '../service/trunkService';
import { toast } from 'react-toastify';
// import { regex } from './regexs';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const GroupSpyForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;
  const [isUpdate, setIsUpdate] = useState(false);
  const [tunks, setTrunks] = useState([]);

  const { id } = props.match.params;

  // Get to extensions
  useEffect(() => {
    ExtensionService.getAll()
      .then((response) => {
        setTrunks(response.data.data);
      })
      .catch(() => {
        console.log('Erro ao ler os ramais');
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      SpyService.get(id)
        .then((res) => {
          let getGroupSpy = res.data.data;
          setBlocked(getGroupSpy.customized_group);
          reset(getGroupSpy);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar Grupo de rota de saída', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (dataForm) => {
      try {
        const data = { ...dataForm };
        const arrayOfKeys = Object.keys(data);
        arrayOfKeys.forEach((key) => {
          if (String(data[key]).length === 0) delete data[key];
        });
        setLoading(true);
        isUpdate
          ? await SpyService.update(id, data)
          : await SpyService.create(data);
        history.push('/admin/GroupExitRoute');

        if (isUpdate) {
          toast.info('Grupo de Rota de saída foi atualizada com sucesso!');
        } else {
          toast.success('Grupo de Rota de saída cadastrada com sucesso!');
        }
      } catch (err) {
        if (err.response.data.message === 'Este nome já está sendo utilizado') {
          setLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 3000,
            closeOnClick: true
          });
          return;
        }
        toast.error(err.response.data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
        history.push('/admin/GroupExitRoute');
      }
    },
    [history, isUpdate, id]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/GroupExitRoute');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/GroupExitRoute"
        buttonTitle="Voltar para a pagina de grupo de rotas de saída"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Grupo de Rotas de Saída</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md={{ size: 12 }}>
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{
                              required: 'Este campo é obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      {!blocked && (
                        <Row>
                          <Col md="6">
                            <label className="form-control-label">
                              Saida principal*
                            </label>
                            <Controller
                              as={
                                <Input type="select">
                                  <option value="">Selecione um tronco</option>
                                  {tunks.map((tunk, index) => (
                                    <option key={index} value={tunk.id}>
                                      {tunk.name}
                                    </option>
                                  ))}
                                </Input>
                              }
                              control={control}
                              name="first_out"
                              rules={{
                                required: 'Este campo é obrigatório',
                                validate: (value) => {
                                  if (Number(value) < 1 && value)
                                    return 'Saída principal inválida';
                                }
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="first_out"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md={{ size: 6 }}>
                            <label className="form-control-label">
                              Tempo de Toque*
                            </label>
                            <Controller
                              as={Input}
                              type="number"
                              control={control}
                              name="first_time"
                              rules={{
                                required: 'Este campo é obrigatório',
                                validate: (value) => {
                                  if (Number(value) < 5)
                                    return 'Tempo de alerta deve ser no mínimo 5';
                                }
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="first_time"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Transbordo 2
                            </label>
                            <Controller
                              as={
                                <Input type="select">
                                  <option value="">Selecione um tronco</option>
                                  {tunks.map((tunk, index) => (
                                    <option key={index} value={tunk.id}>
                                      {tunk.name}
                                    </option>
                                  ))}
                                </Input>
                              }
                              control={control}
                              name="second_out"
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="second_out"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md={{ size: 6 }}>
                            <label className="form-control-label">
                              Tempo de Toque
                            </label>
                            <Controller
                              as={Input}
                              type="number"
                              control={control}
                              name="second_time"
                              rules={{
                                validate: (value) => {
                                  if (Number(value) < 5 && value)
                                    return 'Tempo de alerta deve ser no mínimo 5';
                                }
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="second_time"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Transbordo 3
                            </label>
                            <Controller
                              as={
                                <Input type="select">
                                  <option value="">Selecione um tronco</option>
                                  {tunks.map((tunk, index) => (
                                    <option key={index} value={tunk.id}>
                                      {tunk.name}
                                    </option>
                                  ))}
                                </Input>
                              }
                              control={control}
                              name="third_out"
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="third_out"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md={{ size: 6 }}>
                            <label className="form-control-label">
                              Tempo de Toque
                            </label>
                            <Controller
                              as={Input}
                              type="number"
                              control={control}
                              name="third_time"
                              rules={{
                                validate: (value) => {
                                  if (Number(value) < 5 && value)
                                    return 'Tempo de alerta deve ser no mínimo 5';
                                }
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="third_time"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                      )}

                      <Col sm="12">
                        <Controller
                          name="customized_group"
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <>
                              <label className="form-control-label">
                                <Input
                                  type="checkbox"
                                  onChange={(e) => {
                                    setBlocked(!blocked);
                                    return props.onChange(e.target.checked);
                                  }}
                                  checked={props.value}
                                />
                                Grupo Personalizado
                              </label>
                            </>
                          )}
                        />
                      </Col>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default GroupSpyForm;
