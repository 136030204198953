import React from 'react';

import CollectiveIndicatorChart from '../CollectiveIndicatorChart';

const GREEN_COLOR = 'rgb(19, 133, 77, 1)';
const RED_COLOR = 'rgb(224, 54, 35, 1)';

const ReceivedCallsChart = ({
  qtdAnswered,
  qtdAbandoned,
  answeredPercentage,
  abandonedPercentage
}) => {
  return (
    <CollectiveIndicatorChart
      title="Status de chamadas"
      labels={['Atendidas', 'Abandonadas']}
      dataset={{
        data: [qtdAnswered, qtdAbandoned],
        percentage: [answeredPercentage, abandonedPercentage],
        backgroundColor: [GREEN_COLOR, RED_COLOR]
      }}
    />
  );
};

export default React.memo(ReceivedCallsChart);
