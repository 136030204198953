import styled from 'styled-components';

export const ProfilePicSection = styled.section`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 1rem;
  flex-direction: row;
  width: 100%;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
export const ProfileArea = styled.div`
  width: 10rem;
  height: 10rem;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: right;
`;
export const ProfileImage = styled.div`
  border: 0.3rem solid rgba(0, 0, 0, 0.21);
  width: 10rem;
  height: 10rem;
  border-radius: 100%;

  &:after {
    content: '';
    width: 1.8rem;
    height: 1.8rem;
    border-radius: 100%;
    background: rgb(52, 254, 96);
    position: absolute;
    bottom: 0.8rem;
    left: 8.4rem;
    @media (max-width: 550px) {
      bottom: 7rem;
    }
  }

  img {
    width: 100%;
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 100%;
    object-fit: cover;
  }
`;
export const CoverSection = styled.section`
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 1rem;
  flex-direction: row;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;
export const CoverImage = styled.section`
  border: 0.3rem solid hsl(0, 0%, 80%);
  border-radius: 8px;
  height: 10rem;
  aspect-ratio: 16/9;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
export const UploadButton = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  border: 2px dashed hsl(0, 0%, 80%);
  border-radius: 16px;
  align-items: center;

  width: 180px;
  height: 80px;

  transition: all ease-in-out 0.3s;

  &:hover {
    background: hsl(0, 0%, 96%);
  }

  span {
    font-size: 16px;
  }

  svg {
    transition: all ease-in-out 0.2s;
    width: 1.5rem;
    height: 1.5rem;
  }
`;
export const ColorSection = styled.section`
  display: flex;
  flex-direction: column;
`;
export const ColorInput = styled.input`
  padding: 0;
  margin: 0;
  height: 0rem;
  width: 100%;
  max-width: 200px;
  border: none;
  background: transparent;
`;
