// /users/login do usuário/days-off/
import ServiceCRUD from '../../../../../services/ServiceCRUDNOTIFICATIONS';

class GeneralNotificationService extends ServiceCRUD {
  constructor() {
    super('/api/notifications');
  }
}

export default new GeneralNotificationService();
