import React, { useEffect, useState, useCallback } from 'react';
import { Col, Input } from 'reactstrap';
import Select from 'react-select';

import { fillTypes } from '../../../utils';
import { custom_select } from 'assets/styles/multiple_select';

const types = Object.freeze([
  { label: 'Texto Livre', value: fillTypes.FREE_TEXT },
  { label: 'Campo de cliente', value: fillTypes.CLIENT_FIELD }
]);

const TemplateMessageFillingItem = ({
  paramIdendifier,
  clientFields,
  templateParams,
  onChangeParam,
  isUpdate
}) => {
  const [type, setType] = useState(
    isUpdate && Object.keys(templateParams).length > 0
      ? templateParams[paramIdendifier]?.type ?? fillTypes.FREE_TEXT
      : fillTypes.FREE_TEXT
  );
  const [shouldReset, setShouldReset] = useState(!isUpdate);

  useEffect(() => {
    if (shouldReset) {
      changeParamValue('');
    } else {
      setShouldReset(true);
    }
  }, [type]);

  const changeType = useCallback((itemData) => {
    setType(itemData?.value || fillTypes.FREE_TEXT);
  }, []);

  const changeParamValue = useCallback(
    (value) => {
      onChangeParam(paramIdendifier, {
        type,
        value
      });
    },
    [onChangeParam, paramIdendifier, type]
  );

  const getDefaultType = () => {
    if (!isUpdate) return types[0];
    if (
      templateParams[paramIdendifier] &&
      templateParams[paramIdendifier].type === fillTypes.FREE_TEXT
    ) {
      return types[0];
    }
    if (
      templateParams[paramIdendifier] &&
      templateParams[paramIdendifier].type === fillTypes.CLIENT_FIELD
    ) {
      return types[1];
    }
    return types[0];
  };

  const getSelectDefaultValue = () => {
    if (!isUpdate) return '';
    const key = templateParams[paramIdendifier]?.value;
    return clientFields && clientFields.find((field) => field.value === key);
  };

  return (
    <>
      <>
        <div
          md={2}
          lg={1}
          className="d-flex flex-column justify-content-center text-center pl-3"
        >
          {paramIdendifier || ''}
        </div>
        <Col md={4} lg={3}>
          <Select
            styles={custom_select}
            placeholder="Selecione um tipo"
            options={types}
            isSearchable={false}
            isClearable={false}
            name="fillingType"
            defaultValue={getDefaultType()}
            onChange={changeType}
          />
        </Col>
        <Col md={3}>
          {type === fillTypes.FREE_TEXT ? (
            <Input
              name="freeText"
              value={templateParams[paramIdendifier]?.value || ''}
              defaultValue={templateParams[paramIdendifier]?.value || ''}
              onChange={(e) => changeParamValue(e.target.value)}
            />
          ) : (
            <Select
              styles={custom_select}
              placeholder="Selecione um campo"
              options={clientFields || []}
              isSearchable={false}
              isClearable={false}
              name="clientField"
              defaultValue={getSelectDefaultValue()}
              onChange={(item) => changeParamValue(item.value)}
            />
          )}
        </Col>
      </>
    </>
  );
};

export default TemplateMessageFillingItem;
