import styled from 'styled-components';

//${(props) => props.color && `background-color: ${props.color};`}

export const ForgotContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  align-items: center;
  min-height: 100vh;
  // width: 100vw; */
  justify-content: center;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#F8F9FE'};

  .card-survey {
    background: ${(props) =>
      props.backgroundCardColor ? props.backgroundCardColor : '#F7FAFC'};
    color: ${(props) => (props.fontColor ? props.fontColor : '#372F59')};
  }

  .img-logo-g4flex {
    text-align: center;
    margin-bottom: 25px;
  }

  .g4-button {
    background-color: #3f51b5;
    border-color: #3f51b5;
  }

  .g4-button:hover {
    background-color: #757de8;
    border-color: #757de8;
  }
`;

// .MuiChip-outlinedPrimary {
//   color: #878984;
//   background-color: #fff;
//   border: 1px solid #878984;
// }

// .MuiChip-clickableColorPrimary:hover {
//   color: #fff;
//   background-color: #878984;
// }

// .MuiChip-colorPrimary {
//   color: #878984;
//   border: 1px solid #878984;
// }

//#99b543

export const FormContainer = styled.div`
  flex-basis: 70%;
  display: flex;
  flex-direction: column;
  /* margin: 50px; */
`;

export const ContainerChips = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;
