export const styles = {
  container: {
    padding: '0.5rem',
    fontSize: '5px',
    margin: '0.5rem 0 0 0',
    color: '#FFF',
    width: 'auto'
  },
  items: {
    marginBottom: 0,
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '5px',
    display: 'inline-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative'
  },
  itemsPulse: {
    margin: '0.5rem 0 0 0',
    padding: '0.5rem 0',
    fontSize: '13px',
    fontWeight: '500',
    marginLeft: '5px',
    display: 'inline-box',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative'
  }
};
