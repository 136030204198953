import React, { useEffect, useState } from 'react';
import { Row, Input, Col, Form } from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import { ChipTime, Container } from './style';
import { useForm, Controller } from 'react-hook-form';
import Service from '../../service/index';
import { toast } from 'react-toastify';
import ReactLoader from 'components/Loader';
import { MdAccessAlarms } from 'react-icons/md';

import { Chip } from '@material-ui/core';

export const TimeEndConversation = () => {
  const [time, setTime] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    Service.getWaitingTime()
      .then((response) => {
        setTime(response?.data?.waitingTime);
        setLoading(false);
      })
      .then(() => {
        setLoading(false);
      });
  }, []);

  const { handleSubmit, control } = useForm();

  const onSubmit = (e) => {
    try {
      Service.updateTimeForEndConversation(e.time)
        .then(() => {
          toast.success('Alterações feitas com sucesso', {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .catch(() => {
          toast.error(
            'Ops, ocorreu um erro ao definir um tempo de encerramento!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
        });
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <Container>
      {loading ? (
        <Row className="justify-content-md-center">
          <ReactLoader />
        </Row>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col style={{ display: 'flex', alignItems: 'center' }} md="4">
              TEMPO PARA LIBERAR ENCERRAMENTO
            </Col>
            <Col md="4">
              <Controller
                as={
                  <ChipTime>
                    <Col>
                      <Chip
                        avatar={<MdAccessAlarms color="white" />}
                        size="small"
                        className="chipTime"
                        label="TEMPO EM MINUTOS"
                      />
                      <Input
                        defaultValue={time}
                        type="number"
                        name="time"
                        min={0}
                      />
                    </Col>
                  </ChipTime>
                }
                control={control}
                type="number"
                name="time"
                defaultValue={time}
              />
              {/* <Input
              className="text"
              type="number"
              placeholder="Tempo em minutos"
              onChange={(e) => setTime(e.target.value)}
            ></Input> */}
            </Col>
          </Row>
          <Row>
            <ReactButton btnColor="confirmation" type="submit">
              Salvar
            </ReactButton>
          </Row>
        </Form>
      )}
    </Container>
  );
};
