import { apiAuth as http } from '../http';

class ServiceCRUD {
  constructor(path) {
    this.path = path;
  }

  getAll(queryParams = {}, type = '') {
    return http.get(`${this.path}/${type}`, {
      params: {
        ...queryParams
      }
    });
  }

  create(data) {
    return http.post(this.path, data);
  }

  createKeepAlive(data) {
    const baseURL = process.env.REACT_APP_AUTH_URL;

    const body = new URLSearchParams();
    body.set('usrLogin', data.usrLogin);
    body.set('logoutType', data.logoutType);

    navigator.sendBeacon(
      `${baseURL}/authentication-report/register-logout`,
      body
    );
  }
}

export default ServiceCRUD;
