import React, { useCallback, useEffect, useState } from 'react';
import { Button, Row } from 'reactstrap';
import { Option, StyleOption } from '../../styled';
import DefaultModal from '../../../../../../../components/Modal/DefaultModal';
import { HiUserAdd } from 'react-icons/hi';
import LeadInputs from 'views/CRM/Lead/form/FormInputs';
import { useRecordTypesByUser } from 'views/RecordType/hooks/useRecordTypesByUser';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import ReactLoader from 'components/Loader';
import RecordTypeSelection from 'views/RecordType/components/RecordTypeSelection';

export const ModalLead = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [shouldShowLeadForm, setShouldShowLeadForm] = useState(false);
  const [selectedRecordTypeId, setSelectedRecordTypeId] = useState(null);
  const handleOnSelectedRecordType = useCallback((recordTypeId) => {
    setSelectedRecordTypeId(recordTypeId);
    setShouldShowLeadForm(true);
  }, []);
  const shouldShowRecordTypeSelection =
    isLoadingRecordTypes || !shouldShowLeadForm;

  const { recordTypes, isLoadingRecordTypes, getRecordTypesByUser } =
    useRecordTypesByUser(entitiesName.LEAD);

  const selectedRecordType = recordTypes?.[0] ?? null;

  useEffect(() => {
    if (isModalOpen) {
      getRecordTypesByUser();
      return;
    }
    setTimeout(() => setShouldShowLeadForm(false), 200);
  }, [isModalOpen]);

  return (
    <>
      <Option title={'Criar Lead'}>
        <Button
          color="link"
          size="sm"
          style={StyleOption}
          onClick={() => {
            setIsModalOpen(true);
          }}
        >
          <HiUserAdd size={20} color="#32325d" />
        </Button>
      </Option>
      <DefaultModal
        show={isModalOpen}
        onModalToggle={setIsModalOpen}
        title="Adicionar Lead"
        showCloseButton={false}
        modalContainerClasses="modal-lg"
      >
        {isLoadingRecordTypes || !selectedRecordType ? (
          <Row className="justify-content-md-center">
            <ReactLoader />
          </Row>
        ) : (
          <>
            {shouldShowRecordTypeSelection ? (
              <RecordTypeSelection
                recordTypes={recordTypes}
                handleOnSelectedRecordType={handleOnSelectedRecordType}
              />
            ) : (
              <LeadInputs
                recordTypeId={selectedRecordTypeId}
                isModal={true}
                callbackFunc={() => {
                  setIsModalOpen(false);
                }}
              />
            )}
          </>
        )}
      </DefaultModal>
    </>
  );
};
