import styled from 'styled-components';

export const ContainerForm = styled.div`
  margin-left: 0rem;
`;

export const ContentTitleSection = styled.div`
  margin-left: 1rem;
  font-weight: bold;
  font-size: 0.95rem;
`;

export const ContentItemFormTitle = styled.div`
  margin-left: 0.2rem;
  label {
    font-weight: bold;
    font-size: 0.9rem;
  }
`;
export const ContentItemFormSelect = styled.section`
  margin-left: 1.5rem;
  margin-right: 1.5rem;
`;

export const SpaceItens = styled.br``;

export const Hr = styled.hr`
  margin-top: -0.1px;
`;

export const ContainerProtocolGeneration = styled.div`
  display: flex;
  justify-content: space-between;
  flex: wrap;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const ItemProtocolGeneration = styled.div`
  width: 50%;
  &:last-child {
    padding-left: 1rem;
    border-left: solid 1px #e5e5e5;
  }
`;
