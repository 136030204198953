export const individual_column_style = {
  paddingTop: '8px',
  paddingBottom: '8px'
};

export const individual_header_style = {
  whiteSpace: 'break-spaces'
};

export const individual_header_style_color_dark = {
  whiteSpace: 'break-spaces',
  background: '#172B4D'
};

export const individual_header_curst_style = {
  whiteSpace: 'break-spaces',
  paddingLeft: '55px'
};

export const individual_icon_header_style = {
  whiteSpace: 'break-spaces',
  textAlign: 'center'
};

export const sortingHeaderStyle = {
  background: 'orange'
};

export const individual_icon_header_style_callback = {
  whiteSpace: 'break-spaces',
  textAlign: 'left',
  paddingLeft: '10px',
  paddingBottom: '5px',
  width: '40px',
  height: '20px'
};

export const individual_icon_header_style_callback_scrolling = {
  paddingRight: '25px'
};

export const icon_column_style = {
  paddingTop: '8px',
  paddingBottom: '8px',
  textAlign: 'center'
};

export const select_field_row_style = {
  padding: 0,
  textAlign: 'center'
};
