import React from 'react';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { BsCheck, BsCheckAll } from 'react-icons/bs';
import { MdError } from 'react-icons/md';

export function getStatusIcon(status) {
  switch (status?.situation) {
    case 'enqueued':
      return <BsCheck size="18" color="white" title="Enviando" />;
    case 'sent':
      return <BsCheck size="18" color="white" title="Enviado" />;
    case 'delivered':
      return <BsCheckAll size="18" color="white" title="Entregue" />;
    case 'read':
      return <BsCheckAll size="18" color="#39C0ED" title="Lido" />;
    case 'failed':
      return (
        <MdError
          size="18"
          color="#F93154"
          title={`${status?.code} ${status?.reason}`}
        />
      );
    default:
      return <></>;
  }
}
