import axios from 'axios';
import ReactButton from 'components/Buttons/ReactButton';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Service from '../../../views/ChatConfiguration/service';

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row
} from 'reactstrap';

const BotFlowForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  //audio_closing_message_id
  const [labelSelected, setLabelSelected] = useState();
  const [labelUpdated, setLabelUpdated] = useState();
  const [itemAssunto, setItemAssunto] = useState();
  const [bot, setBot] = useState({});
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  useEffect(() => {
    Service.getAll()
      .then((response) => {
        if (response.data.watson) {
          setBot(response.data.watson);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Erro ao carregar usuários!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadBuscarFluxo();
  }, [bot]);

  const { handleSubmit } = useForm();

  const { id } = props.match.params;

  function loadBuscarFluxo() {
    setLoading(true);
    axios
      .post(
        //'http://localhost:5002/nodes/list',
        'https://flexucteste.g4flex.com.br/watson-api/nodes/list',
        {
          assistant: {
            //apikey: 'gfgU9QtTK18Ma8WXFl1vmrbEAlaKorOvNUlZdEEfVTGc',
            //apikey: 'VpM1xnw5Xrj3dcgRkyB8M3buL0Zn7W0w7rLmkE4WiQR0',
            apikey: bot.apiKey,
            url: 'https://api.us-south.assistant.watson.cloud.ibm.com'
          },
          //workspaceId: 'c6fea0a9-aa33-44ba-837b-8ae16ea13c25'
          //workspaceId: 'e78aede8-e2b4-4b79-9faf-8cfdd40305b1'
          workspaceId: bot.workspaceId
        },
        {
          headers: {
            'Access-Control-Allow-Methods':
              'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers':
              'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      .then((response) => {
        const data = response.data;
        const itemUpdate = data.filter((selected) => selected.id === id);
        setLabelSelected(itemUpdate[0].label);
        setItemAssunto(itemUpdate[0].titleAssunto);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }

  async function onSubmit() {
    if (!labelUpdated) {
      toast.error('Ops, campo vazio!', {
        autoClose: 3000,
        closeOnClick: true
      });
      return;
    }
    const newNodeUpdated = {
      id,
      label: labelUpdated,
      titleAssunto: itemAssunto
    };
    //console.log(newNodeUpdated);
    const node = newNodeUpdated;

    await axios
      .put(`https://flexucteste.g4flex.com.br/watson-api/nodes/updateText`, {
        dialogNode: node.id,
        text: node.label,
        titleAssunto: 'whatever',

        assistant: {
          //apikey: 'gfgU9QtTK18Ma8WXFl1vmrbEAlaKorOvNUlZdEEfVTGc',
          //apikey: 'VpM1xnw5Xrj3dcgRkyB8M3buL0Zn7W0w7rLmkE4WiQR0',
          apikey: bot.apiKey,
          url: 'https://api.us-south.assistant.watson.cloud.ibm.com'
        },
        //workspaceId: 'c6fea0a9-aa33-44ba-837b-8ae16ea13c25'
        //workspaceId: 'e78aede8-e2b4-4b79-9faf-8cfdd40305b1'
        workspaceId: bot.workspaceId
      })
      .then((response) => {
        if (response.data) {
          toast.success('Alterações feitas com sucesso', {
            autoClose: 3000,
            closeOnClick: true
          });
          loadBuscarFluxo();
          //setNodeName('');
          //setIsSelected(false);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Ops, ocorreu um erro!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
    history.push(`/admin/botFlow`);
  }

  const cancelAction = () => {
    history.push('/admin/botFlow');
  };

  function setDataText(e, itemAssunto) {
    //console.log(itemAssunto);
    setLabelUpdated(e.target.value);
  }
  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/botFlow"
        buttonTitle="Voltar para a pagina de configuração de pesquisa"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Resposta do bot</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mt-2"></Row>
                      <Row className="mt-2">
                        <Col md="8">
                          <label className="titleMsg">
                            MENSAGEM DE RESPOSTA
                          </label>
                          <Input
                            rows={18}
                            type="textarea"
                            onChange={(e) => setDataText(e, itemAssunto)}
                            defaultValue={labelSelected}
                            name="msgEncerramento"
                          />
                        </Col>
                      </Row>

                      <hr />

                      <ReactButton btnColor="confirmation" type="submit">
                        Atualizar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BotFlowForm;
