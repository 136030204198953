export const supervisorAlertTypes = Object.freeze({
  FLEXUC_NOTIFICATION: 'FLEXUC_NOTIFICATION',
  EMAIL_NOTIFICATION: 'EMAIL_NOTIFICATION',
  PHONE_CALL_NOTIFICATION: 'PHONE_CALL_NOTIFICATION'
});

const supervisorAlertTypeLabels = Object.freeze({
  FLEXUC_NOTIFICATION: 'Notificação pelo FlexUC',
  EMAIL_NOTIFICATION: 'Notificação por email',
  PHONE_CALL_NOTIFICATION: 'Notificação por ligação'
});

export const getSupervisorAlertTypeLabel = (type) => {
  return supervisorAlertTypeLabels[type];
};
