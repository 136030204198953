import React, { useCallback, useEffect, useState } from 'react';
import { Input, Button, Row, Col, Table, Form } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import Pagination from 'components/Pagination';
import { TableContainer } from './styled';
import ReactLoader from 'components/Loader';
import { toast } from 'react-toastify';
import { useClienteSearch } from 'views/Clients/components/FilterClientScreen/useClientSearch';

const availableSearchTypes = {
  INPUT_TEXT: 'INPUT_TEXT',
  SELECT_BOX: 'SELECT_BOX',
  INPUT_NUMBER: 'INPUT_NUMBER'
};

const FilterClientScreen = ({ setSelectedClient }) => {
  const { control, getValues, setValue } = useForm();
  const { fieldsConfig, searchClient, loading } = useClienteSearch();
  const [clients, setClients] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);

  const [searchType, setSearchType] = useState(availableSearchTypes.INPUT_TEXT);
  const [currentFieldsToSelectBox, setCurrentFieldsToSelectBox] = useState([]);

  const lastAttributeAtClientSearch = localStorage.getItem(
    'flexuc:lastAttributeAtClientSearch'
  );

  const fieldNameIsNumericType = useCallback(
    (fieldName) => {
      return (
        fieldsConfig?.filter((field) => field.name === fieldName)?.type ===
        'NUMERIC'
      );
    },
    [fieldsConfig]
  );

  const handleInputKeyChange = useCallback(
    (fieldName) => {
      localStorage.setItem('flexuc:lastAttributeAtClientSearch', fieldName);

      const isNumberField = fieldNameIsNumericType(fieldName);
      const isSelectBoxField =
        fieldsConfig.find((field) => field.name === fieldName)?.type === 'LIST';

      if (isSelectBoxField && !isNumberField) {
        setSearchType(availableSearchTypes.SELECT_BOX);
        const options = fieldsConfig.filter(
          (field) => field.name === fieldName
        );

        const newOptions =
          (options && !!options.length && options.map((op) => op.value)) || [];
        return setCurrentFieldsToSelectBox([...newOptions]);
      } else if (!isSelectBoxField && !isNumberField) {
        setSearchType(availableSearchTypes.INPUT_TEXT);
        setValue('searchValue', '');
        return setCurrentFieldsToSelectBox([]);
      } else {
        setSearchType(availableSearchTypes.INPUT_NUMBER);
        setValue('searchValue', 0);
        return setCurrentFieldsToSelectBox([]);
      }
    },
    [fieldsConfig, setValue, fieldNameIsNumericType]
  );

  useEffect(() => {
    if (!fieldsConfig?.length || !lastAttributeAtClientSearch) return;

    setValue('searchKey', lastAttributeAtClientSearch);
    handleInputKeyChange(lastAttributeAtClientSearch);
  }, [
    fieldsConfig,
    handleInputKeyChange,
    lastAttributeAtClientSearch,
    setValue
  ]);

  const getCustomFieldsNameAndLabel = () => {
    if (!fieldsConfig?.length) return [];

    return fieldsConfig
      .filter((field) => ['TEXT', 'NUMERIC', 'LIST'].includes(field.type))
      .map((field) => ({ name: field.name, label: field.label }));
  };

  const onSearch = useCallback(
    async (page = 1) => {
      const { searchKey, searchValue } = getValues();

      if (!searchKey || !searchValue) {
        return setClients([]);
      }

      const resultSet = await searchClient({
        attribute: searchKey,
        value: searchValue,
        page
      });

      const total =
        (resultSet && resultSet.quantity && Number(resultSet.quantity)) || 0;
      setTotalItems(total);

      setClients(resultSet?.content || []);
    },
    [getValues, searchClient]
  );

  useEffect(() => {
    onSearch(activePage);
  }, [activePage, onSearch]);

  const handleSelectClient = (client) => {
    const phoneFormated = client.telefone.replace(/\D/g, '');

    if (phoneFormated.length < 10) {
      toast.error('Selecione um cliente com telefone válido');
      return;
    }

    setSelectedClient({
      clientId: client.id,
      clientName: client.nome,
      clientPhone: client.telefone,
      contactId: undefined
    });
  };

  const handlePageChange = (newPage) => {
    setActivePage(newPage);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    onSearch();
  };

  return (
    <>
      <div className="mb-2">
        <Form name="formSearchClient" onSubmit={onSubmit}>
          <Row>
            <Col sm="3" className="my-1">
              <Controller
                as={
                  <Input
                    type="select"
                    name="searchKey"
                    onChangeCapture={(event) =>
                      handleInputKeyChange(event.target.value)
                    }
                  >
                    <option value="">Buscar por ...</option>
                    <option value="nome">Nome</option>
                    <option value="email">E-mail</option>
                    <option value="telefone">Telefone</option>
                    {Object.keys(fieldsConfig).length &&
                      fieldsConfig
                        .filter((field) =>
                          ['TEXT', 'NUMERIC', 'LIST'].includes(field.type)
                        )
                        .map((field) => (
                          <option key={field.name} value={field.name}>
                            {field.label}
                          </option>
                        ))}
                  </Input>
                }
                control={control}
                name="searchKey"
                defaultValue=""
              />
            </Col>
            <Col sm="7" className="my-1">
              {searchType === availableSearchTypes.INPUT_TEXT && (
                <Controller
                  as={Input}
                  control={control}
                  defaultValue={''}
                  name="searchValue"
                />
              )}
              {searchType === availableSearchTypes.SELECT_BOX && (
                <Controller
                  as={
                    <Input type="select" name="searchValue">
                      <option value="">Selecione</option>
                      {currentFieldsToSelectBox.map((fieldName, index) => (
                        <option key={index} value={fieldName}>
                          {fieldName}
                        </option>
                      ))}
                    </Input>
                  }
                  control={control}
                  name="searchValue"
                  defaultValue=""
                />
              )}
              {searchType === availableSearchTypes.INPUT_NUMBER && (
                <Controller
                  as={Input}
                  type="number"
                  control={control}
                  defaultValue={0}
                  name="searchValue"
                />
              )}
            </Col>

            <Col sm="2" className="my-1">
              <Button color="info" type="submit">
                Buscar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <div id="listClientsTable">
        <TableContainer
          style={{
            overflowX: 'auto'
          }}
        >
          {loading ? (
            <Row className="justify-content-md-center py-3">
              <ReactLoader />
            </Row>
          ) : (
            <Table hover={true}>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Telefone</th>
                  {getCustomFieldsNameAndLabel().map((field) => (
                    <th key={field.name}>{field.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {clients.length
                  ? clients.map((client) => (
                      <tr
                        key={client.id}
                        onClick={() => handleSelectClient(client)}
                        style={{ cursor: 'pointer' }}
                      >
                        <td>{client.nome}</td>
                        <td>{client.email}</td>
                        <td>{client.telefone}</td>
                        {getCustomFieldsNameAndLabel().map((field) => (
                          <td key={field.name}>{client[field.name]}</td>
                        ))}
                      </tr>
                    ))
                  : null}
              </tbody>
            </Table>
          )}
        </TableContainer>
        {clients.length > 0 && totalItems > 10 && (
          <Pagination
            activePage={activePage}
            totalItemsCount={totalItems}
            onChange={handlePageChange.bind(this)}
          />
        )}
      </div>
    </>
  );
};

export default FilterClientScreen;
