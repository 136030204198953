import React, { useEffect, useState, useCallback } from 'react';
import { Input, UncontrolledTooltip } from 'reactstrap';
import { useSelector } from 'react-redux';

import { styles } from './styles';

import CircleNumber from '../CircleNumber';
import DropdownButton from '../DropdownButton';
import DropdownQueueList from '../DropdownQueueList';
import { queueAgentStatus as state } from '../../utils/states';
import { statusColors as color } from '../../utils/state_colors';

const HeaderBar = ({
  data = [],
  sortMembers,
  nameQueuesToMonit,
  setIsOneCardPerAgent,
  setShowAgentStatistics,
  setGroupByQueue,
  handleFilterInfo,
  ...props
}) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [isSearch, setIsSearch] = useState(true);
  const [filterValue, setFilterValue] = useState('');
  const [filteredHeaderInfo, setFilteredHeaderInfo] = useState([]);

  const handleFilterInfoHeader = useCallback(
    (valuesToFilter) => {
      {
        let filteredQueueMembersList = [];
        valuesToFilter.map((item) => {
          let agentQueuePausesNames = '';
          item.agent.queue.pauses.find((agentQueue) => {
            if (
              agentQueue.name.toLowerCase().includes(filterValue.toLowerCase())
            ) {
              agentQueuePausesNames = agentQueue.name;
            }
          });

          if (
            item.queueName
              ?.toLowerCase()
              .includes(filterValue.toLowerCase()) === true ||
            item.extension
              ?.toLowerCase()
              .includes(filterValue.toLowerCase()) === true ||
            item.agent?.usr?.name
              ?.toLowerCase()
              .includes(filterValue.toLowerCase()) === true ||
            item.caller?.toLowerCase().includes(filterValue.toLowerCase()) ===
              true ||
            agentQueuePausesNames
              ?.toLowerCase()
              .includes(filterValue.toLowerCase()) === true
          ) {
            filteredQueueMembersList.push(item);
          }
        });
        return filteredQueueMembersList;
      }
    },
    [filterValue]
  );

  useEffect(() => {
    if (filterValue.toLowerCase() === '') setFilteredHeaderInfo(data);
    setFilteredHeaderInfo(handleFilterInfoHeader(data));
  }, [data, filterValue, handleFilterInfoHeader]);

  const handleFilter = (value) => {
    setFilterValue(value);
    handleFilterInfo(value);
  };

  const getCountByState = (statusCode) => {
    const items = filteredHeaderInfo.filter(
      (item) => item.state === statusCode
    );
    return items.length;
  };

  const renderTooltip = (target, message) => {
    return (
      <UncontrolledTooltip placement="bottom" target={target}>
        <span style={{ fontSize: '12px' }}>{message}</span>
      </UncontrolledTooltip>
    );
  };

  useEffect(() => {
    const isSearch = localStorage.getItem('isSearch');
    if (isSearch) {
      setIsSearch(JSON.parse(isSearch));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('isSearch', JSON.stringify(isSearch));
  }, [isSearch]);

  return (
    <div style={styles(systemConfiguration.primary_color).container} {...props}>
      <>
        <CircleNumber
          value={getCountByState(state.AVAILABLE)}
          bgColor={color[state.AVAILABLE]}
          id="avaliable"
        />
        {renderTooltip('avaliable', 'Quantidade de Agentes Livres')}
      </>
      <>
        <CircleNumber
          value={getCountByState(state.RING)}
          bgColor={color[state.RING]}
          id="ring"
        />
        {renderTooltip('ring', 'Quantidade de Agentes Ocupados')}
      </>
      <>
        <CircleNumber
          value={getCountByState(state.PAUSE)}
          bgColor={color[state.PAUSE]}
          id="pause"
        />
        {renderTooltip('pause', 'Quantidade de Agentes em Pause')}
      </>
      <>
        <CircleNumber
          value={getCountByState(state.RINGING)}
          bgColor={color[state.RINGING]}
          id="ringing"
        />
        {renderTooltip('ringing', 'Quantidade de Agentes Chamando')}
      </>
      <>
        <CircleNumber
          value={getCountByState(state.UNPLACED)}
          bgColor={color[state.UNPLACED]}
          id="unplaced"
        />
        {renderTooltip('unplaced', 'Quantidade de Ramais Off-line')}
      </>
      <div className="ml-auto d-flex align-items-center mr-3">
        <div
          className="searchContainer"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            alignContent: 'center',
            transition: 'all ease-in-out 0.2s'
          }}
        >
          <div
            className="searchIconContainer"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              alignContent: 'center',
              cursor: 'pointer',
              marginRight: '0.5rem'
            }}
            title={`Clique para filtrar os cards por:
            - Usuário
            - Ramal
            - Fila
            - Número do Chamador (se aplicável)
            - Pausa
            `}
            onClick={() => {
              setIsSearch(!isSearch);
              localStorage.setItem('isSearch', !isSearch);
            }}
          >
            <i className="fa fa-search" />
          </div>
          <Input
            style={{
              height: '38px',
              width: isSearch ? '200px' : '0px',
              transition: 'all ease-in-out 0.3s',
              visibility: isSearch ? 'visible' : 'hidden',
              padding: isSearch ? '0.625rem 0.75rem' : '0'
            }}
            type="text"
            placeholder="Filtrar..."
            onChange={(e) => {
              handleFilter(e.target.value);
            }}
          />
        </div>

        <DropdownQueueList nameQueuesToMonit={nameQueuesToMonit} />
        <DropdownButton
          sortMembers={sortMembers}
          setIsOneCardPerAgent={setIsOneCardPerAgent}
          setShowAgentStatistics={setShowAgentStatistics}
          setGroupByQueue={setGroupByQueue}
        />
      </div>
    </div>
  );
};

export default HeaderBar;
