const getExternalSource = (logoURL) => {
  const baseURL = process.env.REACT_APP_CONFIGURATION_URL;

  return `${baseURL}${logoURL}`;
};

export const getLogoURL = () => {
  const externalSource = getExternalSource('src/public/img/logo.png');

  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      resolve(image.src);
    };

    image.onerror = () => {
      const localImage = require('../../../assets/img/logoUc.png');
      resolve(localImage.default);
    };

    image.src = externalSource;
  });
};

export const getLogoSocialURL = () => {
  const externalSource = getExternalSource('src/public/img/logo_social.png');

  return new Promise((resolve) => {
    const image = new Image();

    image.onload = () => {
      resolve(image.src);
    };

    image.onerror = () => {
      const localImage = require('../../../assets/img/logo_social.png');
      resolve(localImage.default);
    };

    image.src = externalSource;
  });
};
