export const protocolFieldTypes = Object.freeze({
  TEXT: 'TEXT',
  DATE: 'DATE',
  NUMERIC: 'NUMERIC',
  LIST: 'LIST',
  CURRENCY: 'CURRENCY',
  HOUR: 'HOUR',
  TELEPHONE: 'TELEPHONE',
  CPF: 'CPF',
  CNPJ: 'CNPJ',
  MULTIPLE: 'MULTIPLE',
  LINK: 'LINK'
});
