import React from 'react';
import { Card, Row } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import { TotalRegister } from 'components/TotalRegister';

import {
  individual_column_style,
  individual_header_style,
  icon_column_style
} from '../../../../assets/styles/table_custom_style';
import AnswersModal from '../components/AnswersModal';
import ReportService from '../../Callcenter/ReceivedCall/service';
import { AudioStreamReportTelephony } from 'components/AudioController/AudioStream';

const Table = ({ data, totalRegister }) => {
  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            <TotalRegister totalRegister={totalRegister} />
            <Card>
              <ToolkitProvider
                data={data}
                keyField="id"
                columns={[
                  {
                    dataField: 'agent',
                    text: 'Atendente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      ...individual_header_style,
                      cursor: 'pointer'
                    }
                  },
                  {
                    dataField: 'origin',
                    text: 'Origem',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      ...individual_header_style,
                      cursor: 'pointer'
                    }
                  },
                  {
                    dataField: 'queue',
                    text: 'Fila',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      ...individual_header_style,
                      cursor: 'pointer'
                    }
                  },
                  {
                    dataField: 'call_date',
                    text: 'Data da Ligação',
                    align: 'center',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      whiteSpace: 'break-spaces',
                      textAlign: 'center',
                      cursor: 'pointer'
                    },
                    formatter: (row) => {
                      const date = new Date(row);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'paper',
                    text: 'Questionário',
                    align: 'center',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      whiteSpace: 'break-spaces',
                      textAlign: 'center',
                      cursor: 'pointer'
                    }
                  },
                  {
                    dataField: 'average',
                    text: 'Nota média',
                    align: 'center',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      whiteSpace: 'break-spaces',
                      textAlign: 'center',
                      cursor: 'pointer'
                    }
                  },
                  {
                    dataField: 'category',
                    text: 'Categoria',
                    align: 'center',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      whiteSpace: 'break-spaces',
                      textAlign: 'center',
                      cursor: 'pointer'
                    }
                  },
                  {
                    dataField: 'createdby',
                    text: 'Autor',
                    align: 'center',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      whiteSpace: 'break-spaces',
                      textAlign: 'center',
                      cursor: 'pointer'
                    }
                  },
                  {
                    dataField: 'assessmentdate',
                    text: 'Data e Hora',
                    align: 'center',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      whiteSpace: 'break-spaces',
                      textAlign: 'center',
                      cursor: 'pointer'
                    },
                    formatter: (row) => {
                      const date = new Date(row);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'answers',
                    text: 'Respostas',
                    style: icon_column_style,
                    headerStyle: {
                      whiteSpace: 'break-spaces',
                      textAlign: 'center',
                      cursor: 'pointer'
                    },
                    formatter: (_, row) => {
                      return (
                        <AnswersModal
                          assessmentId={row.id}
                          cdrUniqueId={row.uniqueid}
                          nameAgent={row.agent}
                          calldateStartAnswers={row.call_date}
                          Origem={row.origin}
                          Queue={row.queue}
                        />
                      );
                    }
                  },

                  {
                    dataField: 'listen',
                    text: 'Escutar',
                    sort: true,
                    style: icon_column_style,
                    formatter: (rowContent, row) => {
                      return (
                        <AudioStreamReportTelephony
                          ReportService={ReportService}
                          uniqueid={row.uniqueid}
                          disabled={
                            row.disposition === 'ANSWERED' ? false : true
                          }
                          reasonDisabled={
                            row.disposition === 'ANSWERED' ? '' : 'Não atendida'
                          }
                        />
                      );
                    }
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Table;
