import React, { useState } from 'react';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown
} from 'reactstrap';
import { FaRegEnvelope } from 'react-icons/fa';

import { useProtocolAttachments } from '../../Attachments/useProtocolAttachments';

import CommentsForm from '../CommentsForm';

const CommentDiv = ({ comment }) => {
  const meyHtmlEmail = `<div>${comment}</div>`;

  return <div dangerouslySetInnerHTML={{ __html: meyHtmlEmail }} />;
};

export default function CommentContainer({ comment, onUpdate, onDelete ,email}) {
  const [editMode, setEditMode] = useState(false);
  const {
    getFileURL,
    getFileExtensionFromName,
    getIconFromFileExtension,
    getFileNameFormatted
  } = useProtocolAttachments();
  const isCommentFromEmail = comment.channel === 'email';
  const commentAuthor = isCommentFromEmail ? comment.from : comment.user_id;
  const commentedAt = format(
    new Date(comment.creation_date),
    `dd 'de' LLLL 'às' HH:mm`,
    {
      locale: ptBR
    }
  );

  const handleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const filterEmailCopy = comment?.destination && comment?.destination.length > 0 ?
  comment?.destination.map(function(value) {
    return value.from;
  }).join(',\n') : '';

  return editMode ? (
    <CommentsForm
      comment={comment}
      onSubmit={onUpdate}
      onCancel={handleEditMode}
    />
  ) : (
    <div
      className="border rounded"
      style={{
        fontSize: '14px'
      }}
    >
      <div
        className="border-bottom d-flex justify-content-between align-items-center py-2 px-3"
        style={{
          background: '#f6f9fc',
          color: '#8898aa'
        }}
      >
        <div>
          <strong
            style={{
              color: '#525f7f'
            }}
          >
            {commentAuthor}
          </strong>
          {' comentou em '}
          <time>{commentedAt}</time>
          {isCommentFromEmail ? (
            <>
              {' via email '}
              <FaRegEnvelope size={16} />
            </>
          ) : null}
          <span
                className="badge badge-pill "
                style={{
                  fontSize: '10px'
                }}
                title={commentAuthor ? `de: ${commentAuthor}\npara: ${email}\nemail copy:  ${filterEmailCopy}\ndata:  ${commentedAt}`   : ''}

              >
                <i className="fas fa-caret-down"></i>
              </span>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyItems: 'space-between'
          }}
        >
           <span>
          {!comment.is_private ? (
              <span
                className="badge badge-pill badge-primary"
                style={{
                  fontSize: '10px'
                }}
                title={email ? `${email}\n${filterEmailCopy}` : ''}

              >
                <i className="fas fa-users"></i>
              </span>
            ) : ([
              <span
                className="badge badge-pill badge-primary"
                style={{
                  fontSize: '10px'
                }}
                title={email ? `${email}\n${filterEmailCopy}` : ''}

              >
                <i className="fas fa-users"></i>
              </span>
            ]
            )}
          </span>
          <span>
            {comment.is_private ? (
              <span
                className="badge badge-pill badge-primary"
                style={{
                  fontSize: '10px'
                }}
                title="Um comentário privado só pode ser visualizado pela plataforma do FlexUC"
              >
                Comentário Privado
              </span>
            ) : (
              <span
                className="badge badge-pill badge-secondary"
                style={{
                  fontSize: '10px'
                }}
                title="Um comentário público pode ser visualizado pela plataforma do FlexUC e pelo cliente onde será enviado um email para o cliente com o comentário"
              >
                Comentário Público
              </span>
            )}
          </span>
          <UncontrolledDropdown>
            <DropdownToggle
              data-toggle="dropdown"
              tag="button"
              className="border-0 bg-transparent "
              style={{
                color: '#8898aa'
              }}
            >
              <i className="fas fa-ellipsis-h"></i>
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem
                disabled={isCommentFromEmail}
                onClick={handleEditMode}
              >
                Editar
              </DropdownItem>
              <DropdownItem disabled={isCommentFromEmail} onClick={onDelete}>
                Deletar
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      </div>
      <div className="p-3">
        <div
          style={{
            whiteSpace: 'pre-wrap'
          }}
        >
          <CommentDiv comment={comment.description} />
        </div>
        {comment.attachments.length ? (
          <>
            <br />
            <div className="border-top py-2">
              <strong>{`${comment.attachments.length} ${
                comment.attachments.length > 1 ? 'Anexos' : 'Anexo'
              }`}</strong>
            </div>
            <div className="d-flex flex-column align-items-start">
              {comment.attachments.map((attachment) => (
                <div
                  key={attachment.id}
                  title={attachment.name}
                  className="attachment-name-container p-2"
                >
                  <img
                    src={getIconFromFileExtension(
                      getFileExtensionFromName(attachment.name)
                    )}
                    width="24px"
                    height="24px"
                    alt={attachment.name}
                    className="d-inline-block mr-2"
                  />
                  <a
                    href={getFileURL(attachment.path)}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={true}
                  >
                    {getFileNameFormatted(attachment.name)}
                  </a>
                </div>
              ))}
            </div>
          </>
        ) : null}
      </div>
    </div>
  );
}
