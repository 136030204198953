import React, { useState, useEffect, useCallback } from 'react';
import Service from './service';
import QueueService from '../../../../views/Queue/service';
import UserService from '../../../../views/User/service';
import ServiceReport from './service/Export';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';

import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';

import { dateRules, hourRules } from '../Assets/rulesValidation';
import HourMaskMMHHSS from '../../../../components/Inputs/HourMaskMMHHSS.js';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ReportPerformance from './tablePerformance';
import pdfImage from '../../../../assets/img/pdf.png';
import csvImage from '../../../../assets/img/csv.png';
import * as fileSaver from 'file-saver';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { Tooltip } from '@material-ui/core';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import Pagination from 'components/Pagination';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';

const PerformanceReport = () => {
  const { control, handleSubmit, errors, setValue, watch, getValues } =
    useForm();
  const [ReportTypePerformance, setReportTypeAnalytical] = useState([]);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [dataToSubmitDownloads, setDataToSubmitDownloads] = useState();
  const [queues, setQueues] = useState([]);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [agents, setAgents] = useState([]);
  const [loadingForm] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const showDisableQueue = watch('disable');
  const showDisableAgent = watch('disableAgent');

  const [messagemGeneratePdf, setMessagemGeneratePdf] = useState();
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();

  const [activePage, setActivePage] = useState(1);

  const [quantity, setQuantity] = useState(0);
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );

  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantity,
      limitGenerationPDF
    );

    setMessagemGeneratePdf(message.pdfMessage);
    setMessagemGenerateCsv(message.csvMessage);
  }, [quantity, limitGenerationPDF]);

  useEffect(() => {
    QueueService.getAllShowDisable(showDisableQueue)
      .then((response) => {
        const data = response.data.data;
        const queues = data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });
        setQueues(queues);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, [showDisableQueue]);

  useEffect(() => {
    UserService.getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        const data = response.data.data;
        const users = data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });

        setAgents(users);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, [usersShowDisable]);

  const downloadMedia = useCallback(
    async (midia, type, fileName) => {
      try {
        const response = await ServiceReport.getDownloadsUnified(
          'performance',
          dataToSubmitDownloads,
          midia
        );
        fileSaver.saveAs(new Blob([response.data], { type }), fileName);
      } catch (err) {
        toast.error(err || 'Ops, ocorreu um erro!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [dataToSubmitDownloads]
  );

  const toISOFormat = useCallback(async (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${YYYY}-${MM}-${DD}`;
  }, []);

  const getDataDTO = useCallback(
    async (data) => {
      let dataToSend;
      const filterAgents = agents.filter((agent) => {
        if (data.agent.includes(agent.agent_code)) {
          return agent;
        } else {
          return null;
        }
      });

      const filterAgentsLogins = filterAgents.map((agent) => {
        return agent.login;
      });

      const dataQueue = queues.find((queue) => {
        if (queue.id === data.queue) {
          return queue.id;
        } else {
          return null;
        }
      });
      const queueToSubmit = dataQueue;

      dataToSend = {
        queue: queueToSubmit ? queueToSubmit.name : '',
        ['agent[]']:
          filterAgentsLogins.length === 0 ? 'Todos' : filterAgentsLogins,
        initialDate: `${await toISOFormat(data.dateBegin)}`,
        finalDate: `${await toISOFormat(data.dateEnd)}`,
        timeStart: data.timeStart,
        timeEnd: data.timeEnd,
        disableAgent: data.disableAgent || false
      };

      // const arrayOfKeys = Object.keys(dataToSend);
      // arrayOfKeys.forEach((key) => {
      //   if (String(dataToSend[key]).length === 0 && key !== 'disableAgent')
      //     dataToSend[key] = 'Todos';
      // });

      setDataToSubmitDownloads(dataToSend);
      return dataToSend;
    },
    [toISOFormat, agents, queues]
  );

  const getReports = useCallback((typeReport, page = 1, dataToSend) => {
    setLoadingTable(true);
    Service.getReportPerformance(typeReport, page, dataToSend)
      .then((res) => {
        const { performance } = res.data;
        setQuantity(parseInt(res.data.quantidadeDeRegistros));
        performance.forEach((el, index) => {
          performance[index].id = uuid();
        });
        setReportTypeAnalytical(performance);

        if (performance.length === 0) {
          toast.info('Nenhum registro foi encontrado!');
        }
        setLoadingTable(false);
      })
      .catch((err) => {
        setLoadingTable(false);
        console.log('erro aqui' + err);
      });
  }, []);

  const handlePageChange = (page) => {
    setActivePage(page);
    getReports('performance', page, dataToSubmitDownloads);
  };

  const onSubmit = useCallback(
    async (data) => {
      setActivePage(1);
      try {
        const dataToSend = await getDataDTO(data);
        getReports('performance', 1, dataToSend);
      } catch (err) {
        toast.error(err || 'Erro desconhecido por favor, informar o suporte!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [getDataDTO, getReports]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Performance Atendente</h3>
                </CardHeader>
                <CardBody>
                  {loadingForm ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Fila</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={queues}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="queue"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="queue"
                            defaultValue=""
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Agente</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={agents}
                                isMulti={true}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={false}
                                fieldName="agent"
                                labelName="name"
                                valueName="agent_code"
                              />
                            )}
                            control={control}
                            name="agent"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Data inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateBegin"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateBegin"
                            rules={dateRules}
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateBegin"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md="6">
                          <label className="form-control-label">
                            Hora inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="timeStart"
                                value={props.value}
                              />
                            )}
                            control={control}
                            name="timeStart"
                            rules={hourRules}
                            defaultValue="00:00:00"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="timeStart"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Data final*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateEnd"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateEnd"
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                            rules={{
                              ...dateRules,
                              validate: async (value) => {
                                if (
                                  new Date(await toISOFormat(value, true)) <
                                  new Date(
                                    await toISOFormat(
                                      getValues()['dateBegin'],
                                      true
                                    )
                                  )
                                ) {
                                  return 'A data final não pode ser anterior à inicial!';
                                } else {
                                  return true;
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md="6">
                          <label className="form-control-label">
                            Hora final*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="timeEnd"
                                value={props.value}
                              />
                            )}
                            control={control}
                            name="timeEnd"
                            rules={hourRules}
                            defaultValue="23:59:59"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="timeEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-12">
                        <Col className="md-5 mx-4">
                          <div style={{ display: 'flex', gap: '20px' }}>
                            <Controller
                              name="disable"
                              control={control}
                              render={(props) => (
                                <>
                                  <label className="form-control-label">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                    Mostrar desabilitados (Fila)
                                  </label>
                                </>
                              )}
                              defaultValue=""
                            />
                            <UserFlexSelect
                              usersShowDisable={usersShowDisable}
                              setUsersShowDisable={setUsersShowDisable}
                              name={'Agentes'}
                            />
                          </div>
                        </Col>
                        <Col className="md-3 mx-4">
                        </Col>
                      </Row>
                      <Col md="12" style={ImageButtonContainer}>
                        <button
                          type="button"
                          style={ImageButton}
                          name="pdf"
                          disabled={disabledDownloadButtonCsvAndPdf(
                            'pdf',
                            quantity,
                            limitGenerationPDF
                          )}
                          onClick={() =>
                            downloadMedia(
                              'pdf',
                              'application/pdf',
                              'Relatório unificado de Performance.pdf'
                            )
                          }
                        >
                          <Tooltip
                            title={
                              <p style={{ fontSize: '14px' }}>
                                {messagemGeneratePdf}
                              </p>
                            }
                            arrow
                          >
                            <img src={pdfImage} alt="pdf" style={ImageSize} />
                          </Tooltip>
                        </button>

                        <button
                          type="button"
                          style={ImageButton}
                          disabled={disabledDownloadButtonCsvAndPdf(
                            'csv',
                            quantity,
                            limitGenerationPDF
                          )}
                          onClick={() =>
                            downloadMedia(
                              'csv',
                              'application/csv',
                              'Relatório unificado de Performance.csv'
                            )
                          }
                        >
                          <Tooltip
                            title={
                              <p style={{ fontSize: '14px' }}>
                                {messagemGenerateCsv}
                              </p>
                            }
                            arrow
                          >
                            <img src={csvImage} alt="csv" style={ImageSize} />
                          </Tooltip>
                        </button>
                      </Col>

                      <Button
                        style={buttonStyle(systemConfiguration.primary_color)}
                        type="submit"
                      >
                        Buscar
                      </Button>
                    </Form>
                  )}
                </CardBody>
                {loadingTable ? (
                  <div className="py-4 table-responsive">
                    <Row className="justify-content-md-center">
                      {loadingTable && (
                        <Loader
                          type="TailSpin"
                          color={systemConfiguration.primary_color}
                          height={100}
                          width={100}
                        />
                      )}
                    </Row>
                  </div>
                ) : (
                  <>
                    {dataToSubmitDownloads && (
                      <>
                        <ReportPerformance
                          totalRegister={quantity}
                          showReports={ReportTypePerformance}
                        />
                        <Pagination
                          activePage={activePage}
                          totalItemsCount={quantity}
                          onChange={handlePageChange.bind(this)}
                        />
                      </>
                    )}
                  </>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default PerformanceReport;
