import { useState, useEffect } from 'react';

import {
  fetchSchedules,
  fetchAudios,
  fetchQueues,
  fetchExtensions
} from './asyncDataGetters';

export function useAsyncData() {
  const [timeSchedules, setTimeSchedules] = useState([]);
  const [audios, setAudios] = useState([]);
  const [queues, setQueues] = useState([]);
  const [extensions, setExtensions] = useState([]);

  useEffect(() => {
    Promise.all([
      fetchSchedules(),
      fetchAudios(),
      fetchQueues(),
      fetchExtensions()
    ])
      .then((results) => {
        setTimeSchedules(results[0]);
        setAudios(results[1]);
        setQueues(results[2]);
        setExtensions(results[3]);
      })
      .catch((err) => {
        console.log('Erro on IVR Promise.all', err);
      });
  }, []);

  return { timeSchedules, audios, queues, extensions };
}
