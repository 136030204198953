import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {
  individual_column_style,
  individual_header_style,
  icon_column_style,
  individual_icon_header_style
} from '../../../../../../../../assets/styles/table_custom_style';
import BootstrapTable from 'react-bootstrap-table-next';

import ModalConfirmation from 'components/Modal/ConfirmationModal';

import LeadService from '../../../../../services/leadService';
import { toast } from 'react-toastify';

import './styles.css';
import { toBrazilianFullDate } from 'views/CRM/shared/utils/date';
import LeadHistoryModal from '../../../LeadHistoryModal';
import { maskPhoneNumber } from 'views/CRM/shared/utils/phoneNumberHelpers';
import { maskCpf } from 'views/CRM/shared/utils/cpfCnpjHelpers';
import { maskCnpj } from 'views/CRM/shared/utils/cpfCnpjHelpers';
import { formatCurrency } from 'utils/fuctions/formatCurrency';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import LeadActionsModal from '../../../LeadActionsModal';

const ListLeadsTable = ({
  leads,
  reloadLeads,
  handleOnSelect,
  selectedRows,
  handleOnSelectAll,
  transferLeads,
  customFields
}) => {
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [leadId, setLeadId] = useState(null);

  const history = useHistory();

  const handleDeleteLead = useCallback(
    (id) => {
      LeadService.delete(id)
        .catch((err) => {
          const message =
            err?.response?.data?.message || 'Erro ao deletar o lead';
          toast.error(message);
        })
        .finally(() => {
          setOpenModalConfirmation(false);
          reloadLeads(1);
        });
    },
    [reloadLeads]
  );

  const editLead = (lead) => {
    history.push(`/admin/crm/leadEdit/${lead.recordTypeId}/${lead.id}`);
  };

  const controlDeleteLead = (id) => {
    setLeadId(id);
    setOpenModalConfirmation(true);
  };

  const createDynamicColumns = () => {
    const columns = [];

    columns.push(
      {
        dataField: 'name',
        text: 'Nome',
        sort: true,
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (_, row) => {
          return (
            <span
              className="lead-name"
              onClick={() => {
                editLead(row);
              }}
            >
              {row.name}
            </span>
          );
        }
      },
      {
        dataField: 'stageName',
        text: 'Fase',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'createdAt',
        text: 'Criado em',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (row) => {
          if (row) return toBrazilianFullDate(row);
        }
      },
      {
        dataField: 'createdByName',
        text: 'Criado por',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'responsibleName',
        text: 'Responsável',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'lastUpdatedAt',
        text: 'Última atualização',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (row) => {
          if (row) return toBrazilianFullDate(row);
        }
      }
    );

    (customFields || []).forEach((field) => {
      columns.push({
        dataField: field.name,
        text: field.label,
        style: individual_column_style,
        formatter: (_, row) => {
          if (field.type === 'DATE' && !!row[field.name]) {
            return new Date(row[field.name]).toLocaleDateString('pt-br');
          } else if (field.type === 'TELEPHONE' && row[field.name]) {
            return maskPhoneNumber(row[field.name]);
          } else if (field.type === 'CPF' && row[field.name]) {
            return maskCpf(row[field.name]);
          } else if (field.type === 'CNPJ' && row[field.name]) {
            return maskCnpj(row[field.name]);
          } else if (field.type === 'CURRENCY' && row[field.name]) {
            return formatCurrency(row[field.name]);
          }
          return row[`${field.name}`];
        }
      });
    });

    columns.push({
      dataField: 'edit',
      text: 'Editar',
      style: icon_column_style,
      headerStyle: individual_icon_header_style,
      formatter: (_, row) => {
        return (
          <>
            {!row.isConverted ? (
              <Button size="sm" color="info" onClick={() => editLead(row)}>
                <li className="fas fa-user-edit"></li>
              </Button>
            ) : null}
          </>
        );
      }
    });

    columns.push({
      dataField: 'history',
      text: 'Histórico',
      style: icon_column_style,
      headerStyle: individual_icon_header_style,
      formatter: (_, row) => {
        return <LeadHistoryModal leadId={row.id} />;
      }
    });

    columns.push({
      dataField: 'actions',
      text: 'Ações',
      style: icon_column_style,
      headerStyle: individual_icon_header_style,
      formatter: (_, row) => {
        return <LeadActionsModal leadData={row} />;
      }
    });

    columns.push({
      dataField: 'delete',
      text: 'Excluir',
      style: icon_column_style,
      headerStyle: individual_icon_header_style,
      formatter: (_, row) => {
        return (
          <>
            {!row.isConverted && verifyRoles(['DELETE_LEAD_CRM']) ? (
              <Button
                size="sm"
                color="danger"
                onClick={() => controlDeleteLead(row.id)}
              >
                <li className="fas fa-trash"></li>
              </Button>
            ) : null}
          </>
        );
      }
    });

    return [...columns];
  };

  const dynamicFunctionColumns = createDynamicColumns();

  return (
    <>
      <ToolkitProvider
        data={leads || []}
        keyField="id"
        columns={dynamicFunctionColumns}
      >
        {(props) => (
          <div className="py-4 table-responsive">
            {transferLeads ? (
              <BootstrapTable
                selectRow={{
                  mode: 'checkbox',
                  selected: selectedRows.map((r) => r.id),
                  onSelect: handleOnSelect,
                  onSelectAll: handleOnSelectAll
                }}
                bootstrap4={true}
                bordered={false}
                classes="align-items-center"
                {...props.baseProps}
              />
            ) : (
              <BootstrapTable
                bootstrap4={true}
                bordered={false}
                classes="align-items-center"
                {...props.baseProps}
              />
            )}
          </div>
        )}
      </ToolkitProvider>

      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={leadId}
        deleteResource={handleDeleteLead}
        message={'Tem certeza que deseja excluir o lead?'}
      />
    </>
  );
};

export default ListLeadsTable;
