import React from 'react';
import { Container, Row, Card, CardBody, CardHeader } from 'reactstrap';
import SimpleHeader from '../../../components/Headers/SimpleHeader';
import FormCreate from '../form/index';

const ExternalProtocolWindow = () => {
  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h2>Cadastro de protocolo</h2>
                </CardHeader>
                <CardBody>
                  <FormCreate />
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ExternalProtocolWindow;
