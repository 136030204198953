import { useState, useEffect, useCallback } from 'react';
import ProtocolFieldsService from '../service/protocolFields';

function useProtocolDefaultFieldsSettings(recordTypeId) {
  const [
    protocolDefaultFieldsDisplaySettings,
    setProtocolDefaultFieldsDisplaySettings
  ] = useState(null);
  const [isLoadingProtocolDefaultFields, setIsLoadingProtocolDefaultFields] =
    useState(false);

  const getProtocolDefaultFieldsFields = useCallback(async () => {
    setIsLoadingProtocolDefaultFields(true);

    ProtocolFieldsService.getDefaultFieldsDisplaySettings(recordTypeId)
      .then((response) => {
        const fieldsData = response.data?.content?.defaultFieldsConfig || null;
        setProtocolDefaultFieldsDisplaySettings(fieldsData);
      })
      .catch(() => {
        setProtocolDefaultFieldsDisplaySettings(null);
      })
      .finally(() => {
        setIsLoadingProtocolDefaultFields(false);
      });
  }, [recordTypeId]);

  useEffect(() => {
    getProtocolDefaultFieldsFields();
  }, [getProtocolDefaultFieldsFields]);

  return {
    protocolDefaultFieldsDisplaySettings,
    isLoadingProtocolDefaultFields,
    reloadProtocolDefaultFields: getProtocolDefaultFieldsFields
  };
}

export { useProtocolDefaultFieldsSettings };
