import ServiceCRUD from '../../../../services/ServiceNPS';
import { apiChat } from '../../../../http';

class ConfigSurveyService extends ServiceCRUD {
  constructor() {
    super('configuration/survey');
  }

  getSurveyGeneralConfigurations() {
    return apiChat.get('/configuracao/api/wayOfConductingSurvey/get');
  }

  updateSurveyGeneralConfigurations(data) {
    return apiChat.put('/configuracao/api/wayOfConductingSurvey/update', data);
  }
}

export default new ConfigSurveyService();
