import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'reactstrap';

import Pagination from 'components/Pagination';
import Loader from 'components/Loader';

import FilterAccountsForm from './components/FilterAccountsForm';
import ListAccountsTable from './components/ListAccountsTable';

import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { useListAccounts } from 'views/CRM/Account/hooks/useListAccounts';

const AccountsList = ({
  selectedRecordTypeId,
  setSelectedRecordType,
  recordTypes
}) => {
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({});

  const {
    getAccounts,
    accounts,
    totalAccounts,
    isLoadingAccounts,
    clearAccounts
  } = useListAccounts({
    recordTypeId: selectedRecordTypeId
  });

  const { customFields, getCustomFields } = useEntityCustomFieldsByRecordType(
    entitiesName.ACCOUNT,
    selectedRecordTypeId
  );

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    clearAccounts();
    setActivePage(1);
  }, [selectedRecordTypeId, clearAccounts]);

  const handleSearch = useCallback(
    (searchFilters) => {
      setActivePage(1);
      setFilters(searchFilters);
      getAccounts(1, searchFilters);
    },
    [getAccounts]
  );

  const handlePageChange = (newPage) => {
    setActivePage(newPage);
    getAccounts(newPage, filters);
  };

  const reloadAccounts = useCallback(() => {
    getAccounts(1, {});
  }, [getAccounts]);

  return (
    <>
      <FilterAccountsForm
        handleSearch={handleSearch}
        customFields={customFields}
        isLoading={isLoadingAccounts}
        selectedRecordTypeId={selectedRecordTypeId}
        setSelectedRecordType={setSelectedRecordType}
        recordTypes={recordTypes}
      />

      <div className="d-block small px-2 mt-4 text-right">
        {totalAccounts} resultado(s) encontrado(s)
      </div>
      <Row className="mt-2">
        {isLoadingAccounts ? (
          <div className="col-12">
            <Row className="justify-content-md-center">
              <Loader />
            </Row>
          </div>
        ) : (
          <ListAccountsTable
            accounts={accounts}
            reloadAccounts={reloadAccounts}
          />
        )}
      </Row>
      <Pagination
        activePage={activePage}
        totalItemsCount={totalAccounts}
        onChange={handlePageChange.bind(this)}
      />
    </>
  );
};

export default AccountsList;
