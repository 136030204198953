import React, { useCallback, useEffect } from 'react';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FaRegTrashAlt } from 'react-icons/fa';
import { Col, Form, Input, Label, Row } from 'reactstrap';

import ReactButton from '../../../../components/Buttons/ReactButton';
import FlexSelect from '../../../../components/Inputs/FlexSelect';
import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch';
import { useGetTeams } from '../../../User/form/hooks/useGetTeams';
import FormTooltip from 'components/Inputs/FormTooltip';

import { AddQuestionButton, RemoveQuestionButton } from './styled';

const QUESTION_WEIGHT = [
  {
    value: 1
  },
  {
    value: 2
  },
  {
    value: 3
  },
  {
    value: 4
  },
  {
    value: 5
  },
  {
    value: 'Crítico'
  }
];

export default function PaperForm({
  data,
  onSubmit,
  onCancel,
  onDeleteQuestion,
  editMode = false
}) {
  const { teams } = useGetTeams();
  const { reset, control, handleSubmit, errors, setValue, clearErrors, watch } =
    useForm();
  const {
    fields: questionFields,
    append: appendQuestion,
    remove: removeQuestion
  } = useFieldArray({
    control,
    name: 'questions'
  });

  const {
    fields: categoryFields,
    append: appendCategory,
    remove: removeCategory
  } = useFieldArray({
    control,
    name: 'categories'
  });

  const customSetValue = useCallback(
    (fieldName, value) => {
      setValue(fieldName, value);
      clearErrors(fieldName);
    },
    [setValue, clearErrors]
  );
  const type = watch('type');

  useEffect(() => {
    if (data) {
      reset({
        ...data,
        teams: data.teams.map((team) => team.id),
        categories: data.categories.map((category) => ({
          category_id: category.id,
          name: category.name,
          enabled: category.enabled
        })),
        questions: data.questions.map((question) => ({
          question_id: question.id,
          description: question.description,
          weightage:
            question.type == 'CRITICAL' ? 'Crítico' : question.weightage,
          enabled: question.enabled,
          required: question.required
        }))
      });
    }
  }, [data, reset]);

  const errorFormMessage = (message) => (
    <p className="text-danger">{message}</p>
  );

  return (
    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
      <h4 className="mb-0">Gerais</h4>
      <hr className="mt-2 mb-4" />
      <Row className="mb-3">
        <Col md="6">
          <label className="form-control-label">Name da monitoria*</label>
          <Controller
            as={Input}
            control={control}
            name="name"
            rules={{
              required: 'Nome da monitoria é um campo obrigatório'
            }}
            defaultValue=""
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6">
          <label className="form-control-label">Equipe*</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={teams}
                value={props.value}
                valueController={customSetValue}
                fieldName="teams"
                labelName="name"
                valueName="id"
                isMulti
              />
            )}
            control={control}
            name="teams"
            defaultValue=""
            rules={{
              required: 'Selecione uma equipe'
            }}
          />
          <ErrorMessage
            errors={errors}
            name="teams"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
      <Row className="my-4">
        <Col md="6">
          <div className="d-flex">
            <label className="form-control-label">Tipo de nota*:</label>
            <div className="ml-5">
              <Controller
                name="type"
                control={control}
                render={(props) => (
                  <Input
                    {...props}
                    id="type-basic"
                    disabled={editMode}
                    type="radio"
                    value="basic"
                    checked={type === 'basic'}
                  />
                )}
                rules={{
                  required: 'Selecione um tipo de nota'
                }}
              />
              <Label htmlFor="type-basic">de 1 a 5</Label>
            </div>
            <div className="ml-5">
              <Controller
                name="type"
                control={control}
                render={(props) => (
                  <Input
                    {...props}
                    id="type-advanced"
                    disabled={editMode}
                    type="radio"
                    value="advanced"
                    checked={type === 'advanced'}
                  />
                )}
                rules={{
                  required: 'Selecione um tipo de nota'
                }}
              />
              <Label htmlFor="type-advanced">de 1 a 10</Label>
            </div>
          </div>
          <ErrorMessage
            errors={errors}
            name="type"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>

      <Row
        style={{
          marginLeft: '0.1rem'
        }}
      >
        <h4> Questões</h4>
        <FormTooltip text='As questões de peso crítico serão respondidas com "Sim" ou "Não". Caso uma questão de peso crítico for marcada como "Sim", a nota do formulário será zerada.' />
      </Row>
      <hr className="mt-2 mb-4" />
      {questionFields.map((question, index) => (
        <Row key={question.id} className="mb-3">
          <Col className="col">
            <Controller
              as={Input}
              control={control}
              name={`questions.${index}.question_id`}
              defaultValue={question.question_id}
              hidden
              disabled
            />

            <Controller
              as={Input}
              control={control}
              name={`questions.${index}.description`}
              rules={{
                required: 'A descrição da questão é obrigatório'
              }}
              defaultValue={question.description}
              placeholder="Descrição"
            />
            <ErrorMessage
              errors={errors}
              name={`questions.${index}.description`}
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          <Col md="2">
            <Controller
              render={(props) => {
                return (
                  <FlexSelect
                    dataOptions={QUESTION_WEIGHT.filter((option) => {
                      if (question.new) return true;

                      if (
                        option.value == 'Crítico' &&
                        props.value == 'Crítico'
                      ) {
                        return true;
                      }

                      if (
                        option.value != 'Crítico' &&
                        props.value != 'Crítico'
                      ) {
                        return true;
                      }

                      return false;
                    })}
                    closeMenuOnSelect={true}
                    value={props.value}
                    valueController={customSetValue}
                    fieldName={props.name}
                    placeholder="Peso"
                    labelName="value"
                    valueName="value"
                  />
                );
              }}
              control={control}
              name={`questions.${index}.weightage`}
              rules={{
                required: 'Selecione um peso'
              }}
              defaultValue={question.weightage}
            />
            <ErrorMessage
              errors={errors}
              name={`questions.${index}.weightage`}
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          <div
            className="col-auto "
            style={{
              marginTop: '1.5 rem'
            }}
          >
            <Col md="0">
              <label className="form-control-label">Obrigatória</label>
              <Controller
                render={(props) => (
                  <ToggleSwitch
                    checked={props.value}
                    id={`questions.${index}.required`}
                    onChange={() => {
                      customSetValue(
                        `questions.${index}.required`,
                        !props.value
                      );
                    }}
                  />
                )}
                control={control}
                name={`questions.${index}.required`}
                defaultValue={question.required}
              />
            </Col>
          </div>
          {editMode ? (
            <Col md="0">
              <label className="form-control-label">Habilitada</label>
              <Controller
                render={(props) => (
                  <ToggleSwitch
                    checked={props.value}
                    id={`questions.${index}.enabled`}
                    onChange={() => {
                      customSetValue(
                        `questions.${index}.enabled`,
                        !props.value
                      );
                    }}
                  />
                )}
                control={control}
                name={`questions.${index}.enabled`}
                defaultValue={question.enabled}
              />
            </Col>
          ) : (
            <Col md="1">
              <RemoveQuestionButton
                type="button"
                onClick={async () => {
                  removeQuestion(index);

                  if (question.question_id) {
                    onDeleteQuestion(question.question_id);
                  }
                }}
              >
                <FaRegTrashAlt size={20} />
              </RemoveQuestionButton>
            </Col>
          )}
        </Row>
      ))}

      <Row>
        <Col md="12">
          <AddQuestionButton
            type="button"
            onClick={() =>
              appendQuestion({
                description: '',
                weightage: null,
                required: true,
                new: true
              })
            }
          >
            + Adicionar questão
          </AddQuestionButton>
        </Col>
      </Row>

      <h4
        className="mt-0 mb-0"
        style={{
          paddingTop: '2rem'
        }}
      >
        Categorias de resposta
      </h4>
      <hr className="mt-2 mb-4" />
      {categoryFields.map((category, index) => (
        <Row key={category.id} className="mb-3">
          <Col className="col">
            <Controller
              as={Input}
              control={control}
              name={`categories.${index}.category_id`}
              defaultValue={category.category_id}
              hidden
              disabled
            />

            <Controller
              as={Input}
              control={control}
              name={`categories.${index}.name`}
              rules={{
                required: 'O nome da categoria é obrigatório'
              }}
              defaultValue={category.description}
              placeholder="Nome"
            />
            <ErrorMessage
              errors={errors}
              name={`categories.${index}.name`}
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          {editMode && !category.new ? (
            <Col md="0">
              <label className="form-control-label">Habilitada</label>
              <Controller
                render={(props) => (
                  <ToggleSwitch
                    checked={props.value}
                    id={`categories.${index}.enabled`}
                    onChange={() => {
                      customSetValue(
                        `categories.${index}.enabled`,
                        !props.value
                      );
                    }}
                  />
                )}
                control={control}
                name={`categories.${index}.enabled`}
                defaultValue={category.enabled}
              />
            </Col>
          ) : (
            <Col className="col-auto d-flex">
              <RemoveQuestionButton
                type="button"
                onClick={async () => {
                  removeCategory(index);
                }}
              >
                <FaRegTrashAlt size={20} />
              </RemoveQuestionButton>
            </Col>
          )}
        </Row>
      ))}

      <Row>
        <Col md="12">
          <AddQuestionButton
            type="button"
            onClick={() =>
              appendCategory({
                name: '',
                new: true
              })
            }
          >
            + Adicionar categoria
          </AddQuestionButton>
        </Col>
      </Row>
      <hr />
      <ReactButton btnColor="confirmation" type="submit">
        Salvar
      </ReactButton>

      <ReactButton btnColor="cancelation" onClick={onCancel}>
        Cancelar
      </ReactButton>
    </Form>
  );
}
