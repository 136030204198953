import React, { useState } from 'react';
import { FaInfo } from 'react-icons/fa';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style
} from 'assets/styles/table_custom_style';

import { useModal } from '../../hooks/useModal';

import PauseReportModal from '../PauseReportModal';

const AgentPauseList = ({ data }) => {
  const { isModalOpen, toggleModal, handleModalToggle } = useModal();
  const [pauseId, setPauseId] = useState(undefined);

  return (
    <>
      <div>
        <h5 className="m-0">Pausas</h5>
        <ToolkitProvider
          style={{
            padding: '0',
            marginTop: '16px',
            marginBottom: '30px'
          }}
          columns={[
            {
              dataField: 'pauseName',
              text: 'Nome da pausa',
              style: individual_column_style
            },
            {
              dataField: 'timeInPause',
              text: 'Tempo total',
              style: individual_column_style
            },
            {
              dataField: 'total',
              text: 'Quantidade',
              style: individual_column_style
            },
            {
              dataField: 'detalhes',
              text: 'Detalhes',
              style: icon_column_style,
              headerStyle: individual_icon_header_style,
              // eslint-disable-next-line react/display-name
              formatter: (_, row) => (
                <button
                  style={{
                    borderRadius: '0.375rem',
                    border: 'none',
                    backgroundColor: '#ebebeb'
                  }}
                  onClick={() => {
                    toggleModal();
                    setPauseId(row.pauseId);
                  }}
                >
                  <FaInfo size={10} color="#66677f" />
                </button>
              )
            }
          ]}
          data={data}
          keyField="id"
        >
          {(props) => (
            <div className="py-4 table-responsive">
              <BootstrapTable
                {...props.baseProps}
                bootstrap4={true}
                bordered={false}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <PauseReportModal
        isModalOpen={isModalOpen}
        onModalToggle={handleModalToggle}
        pauseId={pauseId}
      />
    </>
  );
};

export default React.memo(AgentPauseList);
