import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import * as fileSaver from 'file-saver';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import QueueService from '../../../Queue/service';
import UserService from '../../../User/service';
import IVRService from '../../../IVR/service';
import ExtensionService from '../../../Extension/service';
import ReportService from './service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import HourMaskMMHHSS from '../../../../components/Inputs/HourMaskMMHHSS.js';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ReportAnalythicalTable from './tables/Analytical';
import ReportSyntheticTable from './tables/Synthetic';

import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import { Tooltip } from '@material-ui/core';

import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';

const hourRules = {
  required: 'Hora é obrigatório!',
  pattern: {
    value: /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/,
    message: 'Hora inválida! (00:00:00)'
  }
};

const dateRules = {
  required: 'Data é obrigatório!',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};

const reportOptions = [
  { label: 'Analítico', value: 1 },
  { label: 'Sintético', value: 2 }
];

const dispositionOptions = [
  { label: 'Atendida', value: 'ANSWERED' },
  { label: 'Não Atendida', value: 'NO ANSWER' }
];

const ReceivedCallReport = () => {
  const { control, handleSubmit, errors, setValue, watch, getValues } =
    useForm();
  const [IVRs, setIVRs] = useState([]);
  const [IVRsOptions, setIVRsOptions] = useState([]);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [users, setUsers] = useState([]);
  const [extensios, setExtensions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [queues, setQueues] = useState([]);
  const [loadingReport, setLoadingReport] = useState(false);
  const reportType = watch('reportType');
  const [ReportTypeAnalytical, setReportTypeAnalytical] = useState([]);
  const [ReportTypeSynthetic, setReportTypeSynthetic] = useState([]);
  const [media, setMedia] = useState('');
  const showDisables = watch('disable') || false;
  const [dataToSend, setDataToSend] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );

  const [messagemGeneratePdf, setMessagemGeneratePdf] = useState();
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();

  const [itemPerPage, setItemsPerPage] = useState(10);

  //messagemGeneratePdfCsv
  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantity,
      limitGenerationPDF
    );

    setMessagemGeneratePdf(message.pdfMessage);
    setMessagemGenerateCsv(message.csvMessage);
  }, [quantity, limitGenerationPDF]);

  // Get queues to select box
  useEffect(() => {
    setLoading(true);
    QueueService.getAllShowDisable(showDisables)
      .then((response) => {
        setQueues(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showDisables]);

  // Get Extensions to select box
  useEffect(() => {
    setLoading(true);
    ExtensionService.getAllShowDisableNew(showDisables)
      .then((response) => {
        setExtensions(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [showDisables]);

  // Get IVR's to select box
  useEffect(() => {
    setLoading(true);
    IVRService.getAll()
      .then((response) => {
        setIVRs(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (IVRs) {
      const ura = watch('ura');
      const ivrs = IVRs.filter((el) => el.name === ura);

      if (ura) {
        IVRService.getIVROptions(ivrs[0].id)
          .then((response) => {
            const options = response.data.data[0].ura_options;
            const optionsFormated = formatUraOptions(options);
            setIVRsOptions(optionsFormated);
          })
          .catch((err) => {
            console.log('Erro', err);
          });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('ura')]);

  // Get users to select box
  useEffect(() => {
    getUser(true);
  }, [usersShowDisable]);

  const getUser = (showDisable = false) => {
    if (!showDisable) {
      setLoading(true);
    }
    UserService.getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        response.data.data.forEach((el, index) => {
          if (el.pin_code === '') response.data.data[index].pin_code = 'null';
        });
        setUsers(response.data.data);
        if (!showDisable) {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('err: ' + err);
        if (!showDisable) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setValue('uraOption', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('ura')]);

  const formatUraOptions = (options) => {
    const optionsFormated = [];
    const optionsArray = options.split(', ');

    optionsArray.forEach((el) => {
      optionsFormated.push({ label: el, value: el });
    });

    return optionsFormated;
  };

  //  reset data
  const clearData = () => {
    setReportTypeAnalytical([]);
    setReportTypeSynthetic([]);
    setDataToSend({});
    setQuantity(0);
    setActivePage(1);
  };

  const toISOFormat = (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');

    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${MM}-${DD}-${YYYY}`;
  };

  const getFormatedType = (value) => {
    switch (value) {
      case 1:
        return 'analytical';
      case 2:
        return 'synthetic';
      case 3:
        return 'detailedsynthetic';
      default:
        break;
    }
  };

  const getDataDTO = (data) => {
    let formatedData;

    formatedData = {
      ...data,
      dateBegin: `${toISOFormat(data.dateBegin)} ${data.timeStart}`,
      dateEnd: `${toISOFormat(data.dateEnd)} ${data.timeEnd}`,
      timeStart: data.timeStart.replace(/:/g, ''),
      timeEnd: data.timeEnd.replace(/:/g, '')
    };

    if (!formatedData.ura) delete formatedData.ura;
    if (!formatedData.uraOption) delete formatedData.uraOption;
    if (!formatedData.agent) delete formatedData.agent;
    if (!formatedData.disposition) delete formatedData.disposition;
    if (!formatedData.dst) delete formatedData.dst;
    if (!formatedData.origin) delete formatedData.origin;
    if (!formatedData.queue) delete formatedData.queue;

    setDataToSend(formatedData);
    return formatedData;
  };

  const definedNameReportDownload = (name) => {
    let nameReportDownload;

    switch (name) {
      case 'pdf':
        nameReportDownload = 'Relatório_de_IVR.pdf';
        break;
      case 'csv':
        nameReportDownload = 'Relatório_de_IVR.csv';
        break;
      case 'excel':
        nameReportDownload = 'Relatório_de_IVR.xlsx';
        break;
      default:
        break;
    }

    return nameReportDownload;
  };

  const getReports = (typeReport, dataToSend, page, useLoader = true) => {
    setLoadingReport(useLoader);
    if (media === 'pdf' || media === 'csv') {
      ReportService.getDownloads(typeReport, 1, media, dataToSend)
        .then((res) => {
          if (res.data.size <= 11)
            toast.info('Nenhum registro foi encontrado!');
          else {
            fileSaver.saveAs(
              new Blob([res.data], { type: res.headers['content-type'] }),
              definedNameReportDownload(media)
            );
          }
          setLoadingReport(false);
        })
        .catch((err) => {
          toast.error(err || `Erro ao gerar documento ${media}`, {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoadingReport(false);
        });
      setMedia('');
    } else {
      ReportService.getAllReportsType(typeReport, page, dataToSend, itemPerPage)
        .then((res) => {
          switch (typeReport) {
            case 'analytical':
              if (res.data.data.listUraAnalyticalDTO.length === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listUraAnalyticalDTO.forEach((el, index) => {
                  res.data.data.listUraAnalyticalDTO[index].uuid = uuid();
                });
                setReportTypeAnalytical(res.data.data);
                setQuantity(res.data.data.quantity);
              }
              break;
            case 'synthetic':
              if (res.data.data.listUraSyntheticDTO.length === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listUraSyntheticDTO.forEach((el, index) => {
                  res.data.data.listUraSyntheticDTO[index].uuid = uuid();
                });
                setReportTypeSynthetic(res.data.data);
                setQuantity(res.data.data.quantity);
              }
              break;
            default:
              break;
          }
          setLoadingReport(false);
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoadingReport(false);
        });
    }
  };

  const onSubmit = (data) => {
    clearData();
    try {
      const formatedData = getDataDTO(data);
      const formatedType = getFormatedType(reportType);
      getReports(formatedType, formatedData, 1, true);
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    const ura = watch('ura');

    if (ura === '') {
      setValue('uraOption', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch]);

  useEffect(() => {
    clearData();
  }, [reportType]);

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de URA</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">URA</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={IVRs}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="ura"
                                labelName="name"
                                valueName="name"
                              />
                            )}
                            control={control}
                            name="ura"
                            defaultValue=""
                          />
                        </Col>
                        <Col sm="6">
                          <label className="form-control-label">
                            Opção da URA
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={IVRsOptions}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                fieldName="uraOption"
                                labelName="label"
                                valueName="value"
                                isDisabled={watch('ura') ? false : true}
                              />
                            )}
                            control={control}
                            name="uraOption"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Origem</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="origin"
                            defaultValue=""
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Destino</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={extensios}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="dst"
                                labelName="extension_number"
                                valueName="extension_number"
                              />
                            )}
                            control={control}
                            name="dst"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Data inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateBegin"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateBegin"
                            rules={dateRules}
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateBegin"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Hora inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="timeStart"
                                value={props.value}
                              />
                            )}
                            control={control}
                            name="timeStart"
                            rules={hourRules}
                            defaultValue="00:00:00"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="timeStart"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Data final*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateEnd"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateEnd"
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                            rules={{
                              ...dateRules,
                              validate: (value) => {
                                if (
                                  new Date(toISOFormat(value, true)) <
                                  new Date(
                                    toISOFormat(getValues()['dateBegin'], true)
                                  )
                                ) {
                                  return 'A data final não pode ser anterior à inicial!';
                                } else {
                                  return true;
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Hora final*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="timeEnd"
                                value={props.value}
                              />
                            )}
                            control={control}
                            name="timeEnd"
                            rules={hourRules}
                            defaultValue="23:59:59"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="timeEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Status da chamada
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={dispositionOptions}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                isClearable={true}
                                closeMenuOnSelect={true}
                                fieldName="disposition"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="disposition"
                            defaultValue=""
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Agente</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={users}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="agent"
                                labelName="name"
                                valueName="agent_code"
                              />
                            )}
                            control={control}
                            name="agent"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col sm="6">
                          <label className="form-control-label">
                            Tipo de Relatório
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={reportOptions}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="reportType"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="reportType"
                            defaultValue={1}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Fila</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={queues}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="queue"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="queue"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="md-6 mx-4">
                          <div style={{ display: 'flex', gap: '20px' }}>
                            <Controller
                              name="disable"
                              control={control}
                              render={(props) => (
                                <>
                                  <label className="form-control-label">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                    Mostrar desabilitados (Ura, Ramal, Fila)
                                  </label>
                                </>
                              )}
                              defaultValue={false}
                            />
                            <UserFlexSelect
                              usersShowDisable={usersShowDisable}
                              setUsersShowDisable={setUsersShowDisable}
                              name={'Agentes'}
                            />
                          </div>
                        </Col>
                        <Col md="4" style={ImageButtonContainer}>
                          {reportType !== 1 && (
                            <button
                              style={ImageButton}
                              name="pdf"
                              onClick={() => setMedia('pdf')}
                              disabled={disabledDownloadButtonCsvAndPdf(
                                'pdf',
                                quantity,
                                limitGenerationPDF
                              )}
                            >
                              <Tooltip
                                title={
                                  <p style={{ fontSize: '14px' }}>
                                    {messagemGeneratePdf}
                                  </p>
                                }
                                arrow
                              >
                                <img
                                  src={require('../../../../assets/img/pdf.png')}
                                  alt="pdf"
                                  style={ImageSize}
                                />
                              </Tooltip>
                            </button>
                          )}

                          <button
                            style={ImageButton}
                            onClick={() => setMedia('csv')}
                            disabled={disabledDownloadButtonCsvAndPdf(
                              'csv',
                              quantity,
                              limitGenerationPDF
                            )}
                          >
                            <Tooltip
                              title={
                                <p style={{ fontSize: '14px' }}>
                                  {messagemGenerateCsv}
                                </p>
                              }
                              arrow
                            >
                              <img
                                src={require('../../../../assets/img/csv.png')}
                                alt="csv"
                                style={ImageSize}
                              />
                            </Tooltip>
                          </button>
                        </Col>
                      </Row>

                      <Button
                        style={buttonStyle(systemConfiguration.primary_color)}
                        type="submit"
                      >
                        Buscar
                      </Button>
                    </Form>
                  )}
                  {loadingReport ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      {reportType === 1 &&
                        ReportTypeAnalytical.listUraAnalyticalDTO && (
                          <ReportAnalythicalTable
                            dataReport={ReportTypeAnalytical}
                            dataToSend={dataToSend}
                            totalRegister={quantity}
                            getReports={getReports}
                            quantity={quantity}
                            setActivePage={setActivePage}
                            activePage={activePage}
                            itemPerPage={itemPerPage}
                            setItemsPerPage={setItemsPerPage}
                          />
                        )}
                      {reportType === 2 &&
                        ReportTypeSynthetic.listUraSyntheticDTO && (
                          <ReportSyntheticTable
                            dataReport={ReportTypeSynthetic}
                            totalRegister={quantity}
                          />
                        )}
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ReceivedCallReport;
