import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Modal, ModalBody } from 'reactstrap';
import IssueService from '../../../../../../Issue/service';
import ProductService from '../../../../../../Product/service';
import SectorService from '../../../../../../Sector/service';
import StatusService from '../../../../../../status/service';
import ReasonService from '../../../../../../reason/service';
import ChatConfigService from '../../../../../../ChatConfiguration/service';
import SubReasonService from '../../../../../../subReason/service';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { AiOutlineIssuesClose } from 'react-icons/ai';
import ProtocolService from '../../../../../../Protocol/service/index';
import UpdateProtocolForModal from '../ProtocolConfig/formUpdateProtocol';
import { Option, StyleOption } from '../../styled';
import { useSocketCallAnswered } from 'components/Navbars/ServicePanel/ProtocolModal/hooks/useSocketCallAnswered';
import { removeConversationInAttendance } from 'store/action/conversation-in-attendance-action';
import ProtocolCreationHandler from 'views/Protocol/form';
import { useSocketConversationInstagram } from 'hooks/useSocketConversationInstagram';
import UserService from 'views/Report/Callcenter/Perfomance/services/UserService';
import { Loading } from './atonsComponents/Loading';
import { FormDefaultProtocol } from './moleculesComponents/FormDefaultProtocol';

const CloseConversation = ({
  name,
  tooltipTitle = '',
  id,
  reason = '',
  subReason = '',
  protolEndOfConversation,
  recordType,
  idProtocol,
  conversation
}) => {
  const [selectedRecordTypeByUser, setSelectedRecordTypeByUser] =
    useState(null);
  const { socketInstagramConversation } = useSocketConversationInstagram();

  const dispatch = useDispatch();
  const useFormProtocol = useForm();
  const { reset } = useFormProtocol;
  const [isOpen, setIsOpen] = useState(false);
  const [status, setStatus] = useState([]);
  const [issues, setIssues] = useState([]);
  const [products, setProducts] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [reasons, setReasons] = useState([]);
  const [errorInUploadedFiles, setErrorInUploadedFiles] = useState(false);
  const [subReasons, setSubReasons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [closingFields, setClosingFields] = useState();
  const [protocol, setProtocol] = useState([]);
  const [protocolNoComeco, setProtocolNoComeco] = useState([]);
  const [displayEndButton, setDisplayEndButton] = useState(true);
  const [displayDefaultClosure, setDisplayDefaultClosure] = useState(true);
  const { answeringCall } = useSocketCallAnswered();
  const [recordTypes, setRecordTypes] = useState([]);
  const [activeFormCreatedProtocol, setActiveFormCreatedProtocol] =
    useState(false);
  const [protocolCreated, setProtocolCreated] = useState(false);
  const [showSaveProtocol, setShowSaveProtocol] = useState(false);
  const [protocolSaveSucess, setProtocolSaveSucess] = useState();
  const isObjectFilled = (obj) => {
    return Object.keys(obj).length ? true : false;
  };

  const handlerToastError = (message) => {
    toast.error(message, {
      autoClose: 3000,
      closeOnClick: true
    });
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setShowSaveProtocol(false);
  };

  const handleGetSubReasons = useCallback(
    async (event) => {
      let value = event && event.target ? event.target.value : event;
      const reasonExists = reasons.find((e) => e.id === value);

      if (!reasonExists) setSubReasons([]);
      setLoading(false);
      try {
        const response = await SubReasonService.getByReasonId(value);
        setSubReasons(response.data.content);
      } catch (err) {
        const { data } = err.response;
        setLoading(false);
        handlerToastError(data);
      }
    },
    [reasons]
  );

  //get config chat

  const getRecordTypes = async () => {
    try {
      const response = await UserService.get(
        window.localStorage.getItem('login')
      );
      const recordTypesByRequest = response.data.data.recordTypes.map(
        (item) => item.id
      );

      setRecordTypes(recordTypesByRequest);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handlerToastError('Erro ao carregar tipos de registros!');
    }
  };

  const getChatConfig = async () => {
    try {
      const response = await ChatConfigService.getAll();
      setClosingFields(response.data.camposEncerramento);
    } catch (error) {
      handlerToastError('Erro ao carregar campos!');
    }
  };

  useEffect(() => {
    getChatConfig();
    if (isOpen) getRecordTypes();
  }, [isOpen]);

  useEffect(() => {
    handleGetSubReasons(reason);
    const resetValues = {
      motivo: reason,
      submotivo: subReason
    };

    reset(resetValues);
  }, [isOpen, reason, subReason, reset, handleGetSubReasons]);

  useEffect(() => {
    if (recordTypes && recordTypes.length > 0 && isOpen) {
      setLoading(true);
      ReasonService.getByRecordTypes({ recordTypes })
        .then((response) => {
          const dataShort = sortData(response.data.content);
          setReasons(dataShort);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          handlerToastError('Erro ao carregar motivos');
        });
    }
  }, [isOpen, recordTypes]);

  useEffect(() => {
    if (isOpen) {
      SectorService.getAll()
        .then((response) => {
          setSectors(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen === true) {
      IssueService.getAll()
        .then((response) => {
          setIssues(response.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      ProductService.getAll()
        .then((response) => {
          setProducts(response.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && recordTypes && recordTypes.length > 0) {
      setLoading(true);
      StatusService.getByRecordTypes({ recordTypes })
        .then((response) => {
          setStatus(response.data.content);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.log('err: ' + err);
        });
    }
  }, [isOpen, recordTypes]);

  useEffect(() => {
    if (idProtocol) {
      setLoading(true);
      setDisplayEndButton(true);
      ProtocolService.getByIdAndRecordType(idProtocol, recordType)
        .then((res) => {
          const protocolData = res.data.content;
          setProtocol(protocolData);
          protocolData
            ? setDisplayDefaultClosure(false)
            : setDisplayDefaultClosure(true);

          setLoading(false);
        })
        .catch(() => {
          setDisplayDefaultClosure(false);
          setLoading(false);
          handlerToastError('Erro ao carregar o protocolo');
        });
    } else {
      setDisplayDefaultClosure(true);
    }

    if (protolEndOfConversation) {
      setDisplayDefaultClosure(false);
      setDisplayEndButton(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protolEndOfConversation]);

  useEffect(() => {
    if (protocolCreated) {
      if (protocol.sucess === true) {
        const data = protocol.data;
        onSubmit(data, true);
      }
      if (protocol.sucess === false) {
        const data = protocol.data;
        onSubmit(data, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protocolCreated, protocol]);

  useEffect(() => {
    if (protocolSaveSucess === true) {
      const data = protocolNoComeco;
      onSubmit(data, true);
    }
    if (protocolSaveSucess === false) {
      const data = protocolNoComeco;
      onSubmit(data, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [protocolSaveSucess]);

  const onSubmit = async (data, sucess = true) => {
    if (
      displayEndButton &&
      protolEndOfConversation &&
      !idProtocol &&
      errorInUploadedFiles
    ) {
      return;
    }
    if (!data.observacao) {
      data.observacao = data.description;
    }
    try {
      if (sucess) {
        let formattedData = {};

        if (!protolEndOfConversation && !idProtocol) {
          const statusSelected = status.find((st) => st.id === data.status_id);

          const submotivoSelected = subReasons.find(
            (sub) => sub.id === data.subreason_id
          );

          const motivoSelected = reasons.find((re) => re.id === data.reason_id);

          formattedData = {
            ...data,
            motivo: motivoSelected?.name,
            submotivo: submotivoSelected?.name,
            status: statusSelected ? statusSelected?.name : undefined
          };
        }

        if (idProtocol) {
          let statusSelected = {};

          if (data.current_status) {
            statusSelected = status.find((st) => {
              return st.id === data.current_status;
            });
          }
          formattedData = {
            ...data,
            assunto: data?.reason?.name || '',
            reason_id: data?.reason_id,
            setor: conversation?.setor || '',
            status: statusSelected ? statusSelected?.name : undefined,
            status_id: statusSelected ? statusSelected?.id : undefined,
            motivo: data?.reason?.name || '',
            submotivo: data?.subreason?.name || '',
            subreason_id: data?.subreason_id
          };
        }

        if (protolEndOfConversation && !idProtocol) {
          let subR;
          if (data?.reason_id) {
            subR = await SubReasonService.getByReasonId(data.reason_id);
          }

          let statusSelected = {};

          if (data.current_status) {
            statusSelected = status.find((st) => {
              return st.id === data.current_status;
            });
          }

          let submotivoSelected = {};
          if (data.subreason_id !== '') {
            submotivoSelected = subR?.data?.content?.find((sub) => {
              return sub.id === data.subreason_id;
            });
          }

          let motivoSelected = {};
          if (data.reason_id) {
            motivoSelected = reasons.find((re) => {
              return re.id === data.reason_id;
            });
          }

          formattedData = {
            ...data,
            assunto: '',
            motivo: motivoSelected?.name || '',
            observacao: data?.observacao || '',
            produto: data?.produto || '',
            reason_id: data?.reason_id,
            setor: conversation?.setor || '',
            status: statusSelected ? statusSelected?.name : undefined,
            status_id: statusSelected ? statusSelected?.id : undefined,
            submotivo: submotivoSelected?.name || '',
            subreason_id: data?.subreason_id
          };
        }
        if (!formattedData.protocolAlreadyCreated) {
          formattedData.protocolAlreadyCreated = false;
        }

        toast.success('Conversa encerrada com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });

        {
          socketInstagramConversation.emit('encerra_conversa_atendente', {
            id,
            body: {
              ...formattedData,
              record_type_id: selectedRecordTypeByUser,
              token: localStorage.getItem('token')
            }
          });
        }

        setLoading(false);
        await dispatch(removeConversationInAttendance());
      }
    } catch (err) {
      console.error('err', err);
      handlerToastError('Erro ao encerrar conversa!');
    }
  };

  const sortData = (data) => {
    const dataSort = data.sort((a, b) => {
      if (a.name) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      } else {
        if (a.nome.toLowerCase() < b.nome.toLowerCase()) return -1;
        if (a.nome.toLowerCase() > b.nome.toLowerCase()) return 1;
        return 0;
      }
    });
    return dataSort;
  };

  const closeModal = () => {
    toggleModal();
  };

  const handleSelectedRecordType = (recordType) => {
    if (recordType) {
      setSelectedRecordTypeByUser(recordType);
    }
  };

  const createProtocolAtTheEndOfConversation =
    protolEndOfConversation && !idProtocol;

  return (
    <>
      <Option title={tooltipTitle}>
        <Button
          onClick={toggleModal}
          color="link"
          size="x-m"
          style={StyleOption}
        >
          <AiOutlineIssuesClose size={20} color="#32325d" />
        </Button>
      </Option>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
        size={idProtocol || protolEndOfConversation ? 'lg' : ''}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {name}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          {createProtocolAtTheEndOfConversation && (
            <ProtocolCreationHandler
              handleSelectedRecordType={handleSelectedRecordType}
              selectedRecordTypeId={recordType}
              modalActions={{ closeModal, isOpen }}
              defaultFormData={
                isObjectFilled(answeringCall) ? answeringCall : null
              }
              setDisplayEndButton={setDisplayEndButton}
              displayEndButton={displayEndButton}
              inAttendanceScreen={true}
              setProtocol={setProtocol}
              setErrorInUploadedFiles={setErrorInUploadedFiles}
              setProtocolCreated={setProtocolCreated}
              setActiveFormCreatedProtocol={setActiveFormCreatedProtocol}
              activeFormCreatedProtocol={activeFormCreatedProtocol}
              conversation={conversation}
            />
          )}

          {idProtocol && (
            <UpdateProtocolForModal
              id={idProtocol}
              recordType={recordType}
              showSaveProtocol={showSaveProtocol}
              setShowSaveProtocol={setShowSaveProtocol}
              setProtocolSaveSucess={setProtocolSaveSucess}
              setProtocolUpdate={setProtocolNoComeco}
              onTheServiceScreen={true}
            />
          )}
          {!protolEndOfConversation && <hr style={{ marginTop: '-40px' }} />}

          {loading ? (
            <Loading />
          ) : (
            <FormDefaultProtocol
              protocolEndOfConversation={protolEndOfConversation}
              handleGetSubReasons={handleGetSubReasons}
              reasons={reasons}
              displayDefaultClosure={displayDefaultClosure}
              useFormProtocol={useFormProtocol}
              onSubmit={onSubmit}
              closingFields={closingFields}
              subReasons={subReasons}
              products={products}
              issues={issues}
              displayEndButton={displayEndButton}
              protolEndOfConversation={protolEndOfConversation}
              idProtocol={idProtocol}
              setShowSaveProtocol={setShowSaveProtocol}
              setActiveFormCreatedProtocol={setActiveFormCreatedProtocol}
              toggleModal={toggleModal}
              status={status}
              sectors={sectors}
            />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default CloseConversation;
