import React from 'react';
import { useTree } from '../context';
import isNodeRoot from '../util/isNodeRoot';

import {
  button_style,
  delete_icon_style,
  add_icon_style,
  display_name_style,
  container_style
} from './styles';

const NodeTree = ({ nodeData }) => {
  const { addNode, removeNode, openModal, rootNodeData } = useTree();

  const shouldShowAddButton = () => {
    if (!nodeData.attributes) return true;
    if (!nodeData.attributes.content) return true;
    if (nodeData.attributes.content.substring(0, 5) === 'Áudio') return true;
    return false;
  };

  const renderName = () => {
    if (isNodeRoot(nodeData)) return rootNodeData.content;
    return nodeData.attributes && nodeData.attributes.content;
  };

  const renderIcon = () => {
    if (isNodeRoot(nodeData) && rootNodeData.content)
      return <i className="fas fa-edit"></i>;
    if (nodeData.attributes && nodeData.attributes.content)
      return <i className="fas fa-edit"></i>;

    return <i className="fas fa-plus-circle"></i>;
  };

  return (
    <>
      <div style={container_style}>
        <button
          type="button"
          style={button_style}
          onClick={() => openModal(nodeData)}
        >
          {renderIcon()}
        </button>

        {nodeData.depth !== 0 && !nodeData.children && (
          <i
            className="far fa-times-circle"
            style={{
              ...delete_icon_style,
              bottom: shouldShowAddButton() ? 40 : 12
            }}
            onClick={() => removeNode(nodeData)}
          ></i>
        )}

        {shouldShowAddButton() && (
          <i
            className="fas fa-plus-circle"
            style={add_icon_style}
            onClick={() => addNode(nodeData)}
          ></i>
        )}
        <span style={display_name_style}>{renderName()}</span>
      </div>
    </>
  );
};

export default NodeTree;
