import React, { useState } from 'react';
import styled from 'styled-components';
import { TbTag } from 'react-icons/tb';
import { OutlineTooltipTag } from 'components/OutlineTooltipTag';

export const TagChatClient = ({
  name,
  allTags,
  idNameTags,
  isMore,
  color = '#5086c1',
  disabled = false,
  index,
  sizeText = 'small',
  inPopover = false
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const handlerName = (name) => {
    if (!name) return;

    if (sizeText === 'auto') {
      if (name?.length > 15) {
        return name.substring(0, 10) + '...';
      }
      return name;
    }
    if (name?.length > 6) {
      return name.substring(0, 6) + '...';
    }
    return name;
  };

  return (
    <>
      {isMore ? (
        <div>
          <TagChatClientExternal
            disabled={disabled}
            color={color}
            isMore={isMore}
            id={`tags_chat_tooltip${index}`}
            inPopover={inPopover}
          >
            <TbTag
              size={10}
              onMouseDown={toggle}
              style={{
                marginRight: `0.125rem`
              }}
            />

            <FontTag>{handlerName(name)}</FontTag>
          </TagChatClientExternal>
          <OutlineTooltipTag
            placement="bottom"
            target={`tags_chat_tooltip${index}`}
          >
            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                maxWidth: '200px',
                width: 'auto',
                wordWrap: 'break-word',
                paddingTop: '0.6rem',
                backgroundColor: '#fff'
              }}
            >
              {allTags &&
                allTags.map((tag, index) => {
                  if (idNameTags[tag]?.name)
                    return (
                      <TagChatClient
                        key={index}
                        color={idNameTags[tag]?.color || '#5086c1'}
                        isMore={false}
                        name={idNameTags[tag]?.name}
                        idNameTags={idNameTags}
                        allTags={allTags}
                        sizeText="auto"
                        inPopover={true}
                      />
                    );
                })}
            </div>
          </OutlineTooltipTag>
        </div>
      ) : (
        <div>
          <TagChatClientExternal
            disabled={disabled}
            color={color}
            isMore={isMore}
            inPopover={inPopover}
            title={name}
          >
            <TbTag
              size={10}
              onMouseDown={toggle}
              style={{
                marginRight: `0.125rem`
              }}
            />

            <FontTag>{handlerName(name)}</FontTag>
          </TagChatClientExternal>
        </div>
      )}
    </>
  );
};

const TagChatClientExternal = styled.div`
  color: #fff;
  display: ${(props) => (props.disabled ? 'none' : 'flex')};
  flex-direction: row;
  justify-content: center;
  padding-top: 0.125rem;
  cursor: pointer;
  align-items: center;
  background-color: ${(props) => (props.isMore ? '#6b7280' : props.color)};
  height: ${(props) =>
    props.isMore ? '1.25rem' : props.inPopover ? 'auto' : 'auto'};
  width: ${(props) =>
    props.isMore ? '1.25rem' : props.inPopover ? 'auto' : 'auto'};
  border-radius: ${(props) =>
    props.isMore
      ? '50%'
      : `
      border-radius: 0.25rem;
      -webkit-border-radius:  0.25rem;
      -moz-border-radius:  0.25rem;`};
  padding: 0.125rem;
  ${(props) => !props.inPopover && ` max-width: 4.375rem;`}
  margin-right: 0.125rem;
  flex-wrap: nowrap;

  &:hover {
    opacity: 0.8;
  }

  &:hover > span {
    font-weight: 500;
  }

  ${(props) =>
    props.inPopover &&
    `
      margin-top: 0.25rem;
      margin-right: 0.25rem;
  `}
`;

const FontTag = styled.span`
  font-size: 0.625rem;
`;
