import React from 'react';
import { Card } from './Card';
import { Row, Col } from 'reactstrap';

export function Content({
  protocol,
  user,
  chat,
  calls,
  toggleModal,
  loadImage
}) {
  return (
    <>
      <Row>
        <Col className="order-xl-2" xl="12">
          <Card
            protocol={protocol}
            user={user}
            calls={calls}
            chat={chat}
            toggleModal={toggleModal}
            loadImage={loadImage}
          />
        </Col>
      </Row>
    </>
  );
}
