import FlexSelectCalenderCHA from 'views/CalendarScales/components/ChangeAvulsa/components/FlexSelectCalenderCHA';
import DatepickerIsValidCalenderBlockDayOff from 'components/Inputs/DatePickerIsValidCalenderBlockDayOff';
import FlexSelectCalender from 'components/Inputs/FlexSelectCalender';
import { Form, Row, Col, Input, Table } from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import { useForm, Controller } from 'react-hook-form';
import UserService from '../../../User/service/index';
import { toast } from 'react-toastify';
import { getDay } from 'date-fns';
import React from 'react';

export default function TieinComponent({
  closeModal,
  setScaleSelected,
  scalesTieinTable,
  controlTable,
  daysOffObject,
  user,
  listSelect,
  onSubmit,
  setControlTable,
  daysOfWeekObjectDateFilterTiein,
  setScalesTieinTable,
  listFlexSelect
}) {
  const { control, handleSubmit, setValue, getValues } = useForm();

  const requestTableTiein = async () => {
    const tableTiein = getValues(['atendentShift', 'dateTimeShift']);
    if (
      tableTiein.atendentShift === '' ||
      tableTiein.atendentShift === undefined
    ) {
      setControlTable(false);
    }
    if (
      tableTiein.atendentShift === '' ||
      tableTiein.atendentShift === undefined
    ) {
      setControlTable(false);
    }
    if (
      tableTiein.atendentShift !== 'undefined' &&
      tableTiein.atendentShift !== '' &&
      tableTiein.dateTimeShift !== 'undefined' &&
      tableTiein.dateTimeShift !== ''
    ) {
      const [DD, MM, YYYY] = tableTiein.dateTimeShift.split('/');
      const newMonth = MM - 1;
      const dateFormated = new Date(YYYY, newMonth, DD);
      const DateFilter = getDay(dateFormated);
      const DateFilterName = daysOfWeekObjectDateFilterTiein[DateFilter];
      let ArrayVazioUser = [];
      await UserService.get(tableTiein.atendentShift).then((res) => {
        const response = res.data.data.scale;
        if (!user.scale || !user.scale.id) {
          toast.error('Id de escala não encontrado');
          return;
        }
        if (response.id === user.scale.id) {
          ArrayVazioUser = [];
          toast.error(
            'A escala do atendente selecionado é a mesma escala configurada em seu sistema.'
          );
        } else {
          ArrayVazioUser = response;
          const tieinInitialHour = ArrayVazioUser[`beginTime${DateFilterName}`];
          const tieinFinalHour = ArrayVazioUser[`endTime${DateFilterName}`];
          const tieinName = ArrayVazioUser.name;
          const avulsaId = ArrayVazioUser.id;
          const objectTieinTable = {
            beginDate: tieinInitialHour,
            endDate: tieinFinalHour,
            id: avulsaId,
            name: tieinName
          };
          setScalesTieinTable([objectTieinTable]);
          setControlTable(true);
        }
      });
    }
  };
  return (
    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
      <Row className="mt-4 ml-1">
        <Col>
          <label className="form-control-label">Atendente</label>
          <Controller
            render={(props) => {
              return (
                <FlexSelectCalender
                  dataOptions={listSelect}
                  isClearable={true}
                  isMulti={false}
                  value={props.value}
                  valueController={setValue}
                  fieldName="atendentShift"
                  labelName="label"
                  valueName="value"
                  minWidth="192px"
                  requestTableTiein={requestTableTiein}
                  closeMenuOnSelect={true}
                />
              );
            }}
            control={control}
            name="atendentShift"
            defaultValue=""
          />
        </Col>
        <Col>
          <label className="form-control-label">Supervisor</label>
          <Controller
            render={(props) => {
              return (
                <FlexSelectCalenderCHA
                  dataOptions={listFlexSelect}
                  isClearable={true}
                  isMulti={false}
                  value={props.value}
                  closeMenuOnSelect={true}
                  valueController={setValue}
                  fieldName="supervisorShift"
                  labelName="label"
                  valueName="value"
                  minWidth="192px"
                />
              );
            }}
            control={control}
            name="supervisorShift"
            defaultValue=""
          />
        </Col>
        <Col>
          <label>Data de Troca</label>
          <Controller
            render={(props) => {
              return (
                <DatepickerIsValidCalenderBlockDayOff
                  fieldName="dateTimeShift"
                  valueController={setValue}
                  defaultValue={props.value}
                  requestTableTiein={requestTableTiein}
                  user={user}
                  daysOffObject={daysOffObject}
                />
              );
            }}
            control={control}
            name="dateTimeShift"
            defaultValue=""
          />
        </Col>
      </Row>
      {controlTable && (
        <Col className="mt-4 ">
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Nome da escala</th>
                <th>Hora inicial</th>
                <th>Hora Final</th>
              </tr>
            </thead>
            <tbody>
              {scalesTieinTable.map((item, key) => {
                return (
                  <tr key={key}>
                    <th scope="row">
                      <Input
                        type="checkbox"
                        style={{
                          marginTop: '-0.5rem',
                          marginLeft: '0.5rem'
                        }}
                        onClick={() => {
                          setScaleSelected(item.id);
                        }}
                      />
                    </th>
                    <td>{item.name || ''}</td>
                    <td>{item.beginDate}</td>
                    <td>{item.endDate}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      )}

      <Row style={{ marginTop: '3rem', marginLeft: '9rem' }}>
        <ReactButton btnColor="confirmation" type="submit">
          Salvar
        </ReactButton>
        <ReactButton btnColor="cancelation" onClick={() => closeModal()}>
          Cancelar
        </ReactButton>
      </Row>
    </Form>
  );
}
