import React, { useEffect, useState } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import Pagination from 'components/Pagination';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';

import IVRService from '../service';

import {
  Input,
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label
} from 'reactstrap';

import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const IVRList = () => {
  const history = useHistory();

  const [ivrs, setIVRs] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDisable, setShowDisable] = useState(false);
  const [timeoutID, setTimeoutID] = useState(null);

  const handleGetIVRs = (selectedPage = 1, search = '') => {
    setLoading(true);
    IVRService.getAllSeach(selectedPage, 10, search, showDisable)
      .then((response) => {
        setIVRs(response.data.data);
        setQuantity(response.data.totalRegisters);
      })
      .catch((err) => {
        toast.error(
          (err.response && err.response.data.message) ||
            'Erro ao listar pausas!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(handleGetIVRs, [showDisable]);

  const toggleDisable = (id, disable) => {
    if (!disable) {
      disableIVR(id);
    } else {
      recoveryIVR(id);
    }
  };

  const disableIVR = (id) => {
    if (!window.confirm('Tem certeza que deseja desabilitar essa URA?')) return;
    IVRService.delete(id)
      .then(() => {
        toast.info('URA desabilitada com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });

        handleGetIVRs();
      })
      .catch((err) => {
        toast.error(
          err.response.data.message || 'Ops, algo errado aconteceu!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      });
  };

  const recoveryIVR = (id) => {
    IVRService.recovery(id)
      .then(() => {
        toast.info('URA habilitada!', {
          autoClose: 3000,
          closeOnClick: true
        });
        handleGetIVRs(activePage);
      })
      .catch((err) => {
        toast.error(
          err.response.data.message || 'Ops, algo errado aconteceu!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      });
  };

  const getIVRSearch = (event, selectedPage = 1) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const query_text = event.target.value;
    setTimeoutID(
      setTimeout(() => {
        handleGetIVRs(selectedPage, query_text);
      }, 1000)
    );
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    handleGetIVRs(page);
  };

  const handleShowDisableds = (value) => {
    setShowDisable(value);
    if (value === false) setActivePage(1);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/ivr/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>URA</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Pesquisa por nome"
                          type="text"
                          onChange={(event) => getIVRSearch(event)}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="4" className="text-right mt-2">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={showDisable}
                            onChange={() => handleShowDisableds(!showDisable)}
                          />
                          <span>Mostrar desabilitadas</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={ivrs}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'customized',
                      text: 'Customizada?',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => (row.customized ? 'Sim' : 'Não')
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="info"
                            size="sm"
                            disabled={row.disable}
                            onClick={() => history.push(`ivr/form/${row.id}`)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'disable',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Habilitar / Desabilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            checked={!row.disable}
                            id={row.name}
                            onChange={() => toggleDisable(row.id, row.disable)}
                          />
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default IVRList;
