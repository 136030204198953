import React from 'react';
import { FaSignInAlt } from 'react-icons/fa';

import { useModal } from '../../hooks/useModal';

import AgentIndicatorCard from '../AgentIndicatorCard';
import AutenticationReportModal from '../AutenticationReportModal';

const TotalLoginCard = ({ data }) => {
  const { isModalOpen, toggleModal, handleModalToggle } = useModal();

  return (
    <>
      <AgentIndicatorCard icon={FaSignInAlt}>
        <AgentIndicatorCard.Header>
          <AgentIndicatorCard.Title>
            Quantidade de logins
          </AgentIndicatorCard.Title>
          <AgentIndicatorCard.DetailBtn onClick={toggleModal} />
        </AgentIndicatorCard.Header>
        <AgentIndicatorCard.Body value={data} />
      </AgentIndicatorCard>
      <AutenticationReportModal
        isModalOpen={isModalOpen}
        onModalToggle={handleModalToggle}
      />
    </>
  );
};

export default React.memo(TotalLoginCard);
