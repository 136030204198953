import React from 'react';
import { ModalHeader } from './Components/Modal/ModalHeader';
import { ModalBody } from './Components/Modal/ModalBody';
import { Modal } from 'reactstrap';
import { useGetUserById } from '../hooks/useGetUserById';
import { useGetProtocolById } from '../hooks/useGetProtocolById';
import { useGetCallById } from '../hooks/useGetCallsById';
import { useGetChatById } from '../hooks/useGetChatById';
import ReactLoader from 'components/Loader';
import { Row } from 'reactstrap';

const ProfileUpdateModal = ({
  toggleModal,
  isOpen,
  login,
  agent_code,
  loadImage
}) => {
  const { user } = useGetUserById(login, isOpen);
  const { protocol } = useGetProtocolById(login, isOpen);
  const { calls } = useGetCallById(agent_code, isOpen);
  const { chat } = useGetChatById(login, isOpen);

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <ModalHeader toggleModal={toggleModal} />
        {user && protocol && calls && chat ? (
          <ModalBody
            login={login}
            protocol={protocol}
            user={user}
            calls={calls}
            chat={chat}
            toggleModal={toggleModal}
            loadImage={loadImage}
          />
        ) : (
          <Row className="justify-content-md-center">
            <ReactLoader />
          </Row>
        )}
      </Modal>
    </>
  );
};

export default ProfileUpdateModal;
