import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import AudiosService from '../../Queue/service/Audios';
import QueueService from '../../Queue/service';
import GroupExitRouteService from '../../GroupExitRoute/service';
import IntegrationUrlService from '../../IntegrationUrl/service';
import BlackListService from '../../BlackList/service';
import DialerService from '../service';
import FlexSelect from 'components/Inputs/FlexSelect';
import HourMaskMMHHSS from '../../../components/Inputs/HourMaskMMHHSS.js';
import { sendCasts } from './assets/Casts.js';
import { strategies, strategyOptions } from '../utils/strategy';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const mailingImportTypeOptions = [
  { label: 'Arquivo', value: 'file' },
  { label: 'Integração', value: 'integration' }
];

const hourRules = {
  required: 'Campo obrigatório!',
  pattern: {
    value: /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/,
    message: 'Hora inválida!'
  }
};

const DialerForm = (props) => {
  const history = useHistory();
  const { id } = props.match.params;

  const { reset, control, handleSubmit, errors, setValue, watch, formState } =
    useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [sounds, setSounds] = useState([]);
  const [queues, setQueues] = useState([]);
  const [groupsExitRoute, setGroupsExitRoute] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultRoutes, setDefaultRoutes] = useState(true);
  const [defaultCheckAgent, setDefaultCheckAgent] = useState(true);
  const [mailingImportType, setMailingImportType] = useState('file');
  const [integrationUrls, setIntegrationUrls] = useState([]);
  const [blacklists, setBlacklists] = useState([]);
  const type = watch('type');
  const exitRoute = watch('route_default');

  useEffect(() => {
    setLoading(true);
    AudiosService.getWithType('sound')
      .then((response) => {
        setSounds(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    QueueService.getAll()
      .then((response) => {
        setQueues(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    GroupExitRouteService.getAll()
      .then((response) => {
        setGroupsExitRoute(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    IntegrationUrlService.getAll()
      .then((response) => {
        setIntegrationUrls(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    BlackListService.getAll()
      .then((response) => {
        setBlacklists(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      DialerService.get(id)
        .then((response) => {
          const getDialer = response.data.data;

          setDefaultRoutes(getDialer.route_default);
          setDefaultCheckAgent(getDialer.check_agent);
          setMailingImportType(getDialer.mailing_import_type);
          reset(getDialer);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(
            err.response.data.message || 'Erro ao carregar discador!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
          setLoading(false);
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      const formData = Object.keys(data).reduce((acc, key) => {
        if (data[key] === '') {
          return {
            ...acc,
            [key]: null
          };
        }

        return {
          ...acc,
          [key]: data[key]
        };
      }, {});
      const formatedData = sendCasts(formData, type);
      isUpdate
        ? await DialerService.update(id, formatedData)
        : await DialerService.create(formatedData);

      history.push('/admin/dialer');
      toast.success(
        isUpdate
          ? 'Discador alterado com sucesso!'
          : 'Discador cadastrado com sucesso!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    } catch (err) {
      toast.error(err.response?.data?.message || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/dialer');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/dialer"
        buttonTitle="Voltar para a pagina de discador"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Discador</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                      autoComplete="off"
                    >
                      <Row className="mb-3">
                        <Col md="8">
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{
                              required: 'Nome é obrigatório!'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Estratégia*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={strategyOptions}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="type"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="type"
                            rules={{ required: 'Estratégia é obrigatório!' }}
                            defaultValue="ativo"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="type"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        {(type === strategies.ATIVO ||
                          type === strategies.RECEPTIVO ||
                          type === strategies.INTERATIVO) && (
                          <Col md="6">
                            <label className="form-control-label">Fila*</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={true}
                                  dataOptions={queues}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="queue_id"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="queue_id"
                              rules={{ required: 'Fila é obrigatório!' }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="queue_id"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        )}

                        {(type === '' || type === strategies.MENSAGEM) && (
                          <Col md="6">
                            <label className="form-control-label">Audio*</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={true}
                                  dataOptions={sounds}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="audio_id"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="audio_id"
                              rules={{ required: 'Audio é obrigatório!' }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="audio_id"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        )}
                      </Row>

                      {(type === strategies.ATIVO ||
                        type === strategies.RECEPTIVO ||
                        type === strategies.MENSAGEM) && (
                        <>
                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Intervalo entre chamadas (Segundos)*
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                type="number"
                                min={1}
                                name="attempt_interval"
                                rules={{
                                  required:
                                    'Intervalo entre chamadas obrigatório!',
                                  pattern: {
                                    value: /^(|\d)+$/,
                                    message:
                                      'Intervalo entre chamadas é um campo numérico!'
                                  }
                                }}
                                defaultValue="5"
                              />
                              <ErrorMessage
                                errors={errors}
                                name="attempt_interval"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                            <Col md="6">
                              <label className="form-control-label">
                                Quantidade máxima de tentativas*
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                name="attempts"
                                type="number"
                                min={1}
                                rules={{
                                  required:
                                    'Quantidade máxima de tentativas é obrigatório!',
                                  pattern: {
                                    value: /^(|\d)+$/,
                                    message:
                                      'Quantidade máxima de tentativas é um campo numérico!'
                                  }
                                }}
                                defaultValue="5"
                              />
                              <ErrorMessage
                                errors={errors}
                                name="attempts"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Intervalo entre tentativas (minutos)*
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                type="number"
                                min={1}
                                name="repeat_interval"
                                rules={{
                                  required:
                                    'Intervalo entre tentativas é obrigatório!',
                                  pattern: {
                                    value: /^(|\d)+$/,
                                    message:
                                      'Intervalo entre tentativas é um campo numérico!'
                                  }
                                }}
                                defaultValue="10"
                              />
                              <ErrorMessage
                                errors={errors}
                                name="repeat_interval"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                            <Col md="6">
                              <label className="form-control-label">
                                Quantidade de chamadas enviadas por vez*
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                type="number"
                                min={1}
                                name="qtd_attempt"
                                rules={{
                                  required:
                                    'Quantidade de chamadas enviadas por vez é obrigatório!',
                                  pattern: {
                                    value: /^(|\d)+$/,
                                    message:
                                      'Quantidade de chamadas enviadas por vez é um campo numérico!'
                                  }
                                }}
                                defaultValue="1"
                              />
                              <ErrorMessage
                                errors={errors}
                                name="qtd_attempt"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Quantidade de agentes livres para enviar
                                chamadas*
                              </label>
                              <Controller
                                as={Input}
                                type="number"
                                min={1}
                                control={control}
                                name="qtd_agent_free"
                                rules={{
                                  required:
                                    'Quantidade de agentes livres para enviar chamadas é obrigatório!',
                                  pattern: {
                                    value: /^(|\d)+$/,
                                    message:
                                      'Quantidade de agentes livres para enviar chamadas é um campo numérico!'
                                  }
                                }}
                                defaultValue="1"
                              />
                              <ErrorMessage
                                errors={errors}
                                name="qtd_agent_free"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                            <Col md="6">
                              <label className="form-control-label">
                                Lista de bloqueio
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={blacklists}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="blacklist_id"
                                    labelName="name"
                                    valueName="id"
                                  />
                                )}
                                control={control}
                                name="blacklist_id"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="blacklist_id"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Tipo de importação de lista*
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={mailingImportTypeOptions}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={(name, value) => {
                                      setValue(name, value);
                                      setMailingImportType(value);
                                    }}
                                    fieldName="mailing_import_type"
                                    labelName="label"
                                    valueName="value"
                                  />
                                )}
                                control={control}
                                name="mailing_import_type"
                                rules={{
                                  required:
                                    'Tipo de importação de lista é obrigatório!'
                                }}
                                defaultValue={mailingImportType}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="mailing_import_type"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                            {mailingImportType === 'integration' && (
                              <Col md="6">
                                <label className="form-control-label">
                                  URL de integração*
                                </label>
                                <Controller
                                  render={(props) => (
                                    <FlexSelect
                                      isClearable={true}
                                      dataOptions={integrationUrls}
                                      isMulti={false}
                                      closeMenuOnSelect={true}
                                      value={props.value}
                                      valueController={setValue}
                                      fieldName="integration_url_id"
                                      labelName="name"
                                      valueName="id"
                                    />
                                  )}
                                  control={control}
                                  name="integration_url_id"
                                  rules={{
                                    required: 'URL de integração é obrigatório!'
                                  }}
                                  defaultValue=""
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="integration_url_id"
                                  render={({ message }) =>
                                    errorFormMessage(message)
                                  }
                                />
                              </Col>
                            )}
                          </Row>
                        </>
                      )}

                      {type === strategies.INTERATIVO && (
                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Intervalo entre chamadas (Segundos)*
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              type="number"
                              min={1}
                              name="attempt_interval"
                              rules={{
                                required:
                                  'Intervalo entre chamadas obrigatório!',
                                pattern: {
                                  value: /^(|\d)+$/,
                                  message:
                                    'Intervalo entre chamadas é um campo numérico!'
                                }
                              }}
                              defaultValue="5"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="attempt_interval"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              URL de integração*
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={true}
                                  dataOptions={integrationUrls}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="integration_url_id"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="integration_url_id"
                              rules={{
                                required: 'URL de integração é obrigatório!'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="integration_url_id"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                      )}

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Hora inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="start_hour"
                                value={props.value}
                                placeholder="08:00:00"
                              />
                            )}
                            control={control}
                            name="start_hour"
                            rules={hourRules}
                            defaultValue="08:00:00"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="start_hour"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Hora final*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="end_hour"
                                value={props.value}
                                placeholder="18:00:00"
                              />
                            )}
                            control={control}
                            name="end_hour"
                            rules={hourRules}
                            defaultValue="18:00:00"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="end_hour"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        {type !== strategies.INTERATIVO && (
                          <Col md="2" className="mx-4">
                            <Controller
                              name="route_default"
                              control={control}
                              render={(props) => (
                                <>
                                  <label className="form-control-label">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                    Utilizar rotas do sistema
                                  </label>
                                </>
                              )}
                              defaultValue={defaultRoutes}
                            />
                          </Col>
                        )}
                        <Col md="2" className="mx-4">
                          <Controller
                            name="check_agent"
                            control={control}
                            render={(props) => (
                              <>
                                <label className="form-control-label">
                                  <Input
                                    type="checkbox"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                  />
                                  Verificar agentes livres
                                </label>
                              </>
                            )}
                            defaultValue={defaultCheckAgent}
                          />
                        </Col>
                      </Row>

                      {exitRoute === false && (
                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Rota de saída*
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={true}
                                  dataOptions={groupsExitRoute}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="route_id"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="route_id"
                              rules={{
                                required: 'Rota de saída é obrigatório!'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="route_id"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                      )}

                      <hr />

                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DialerForm;
