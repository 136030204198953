const monthsOptions = [
  {
    label: 'Janeiro',
    value: 'jan'
  },
  {
    label: 'Fevereiro',
    value: 'feb'
  },
  {
    label: 'Março',
    value: 'mar'
  },
  {
    label: 'Abril',
    value: 'apr'
  },
  {
    label: 'Maio',
    value: 'may'
  },
  {
    label: 'Junho',
    value: 'jun'
  },
  {
    label: 'Julho',
    value: 'jul'
  },
  {
    label: 'Agosto',
    value: 'aug'
  },
  {
    label: 'Setembro',
    value: 'sep'
  },
  {
    label: 'Outubro',
    value: 'oct'
  },
  {
    label: 'Novembro',
    value: 'nov'
  },
  {
    label: 'Dezembro',
    value: 'dec'
  }
];

export default monthsOptions;
