import { calculateOffsetFromPageAndLimit } from 'views/CRM/shared/utils/pagination';
import { apiCrm } from '../../../../http';

class OpportunityService {
  getOpportunities(
    recordTypeId,
    params = {},
    headers = { page: 1, limit: 10 }
  ) {
    const offset = calculateOffsetFromPageAndLimit(headers.page, headers.limit);
    return apiCrm.get(`opportunity/${recordTypeId}`, {
      params,
      headers: { ...headers, offset }
    });
  }

  getAllOpportunities(recordTypeId, params = {}) {
    const headers = {
      limit: '15000'
    };
    return apiCrm.get(`opportunity/${recordTypeId}`, {
      params,
      headers
    });
  }

  getById(recordTypeId, id) {
    return apiCrm.get(`opportunity/${recordTypeId}/${id}`);
  }

  createOpportunity(recordTypeId, data) {
    return apiCrm.post(`opportunity/${recordTypeId}`, data);
  }

  getStages() {
    return apiCrm.get('opportunity-stage');
  }

  updateStage(opportunityId, data) {
    return apiCrm.put(`opportunity/change-stage/${opportunityId}`, data);
  }

  updateOpportunity(recordTypeId, opportunityId, data) {
    return apiCrm.put(`opportunity/${recordTypeId}/${opportunityId}`, data);
  }

  delete(opportunityId) {
    return apiCrm.delete(`opportunity/${opportunityId}`);
  }

  getAccounts(params = {}) {
    return apiCrm.get(`/account`, {
      params
    });
  }

  changeResponsible(opportunityId, responsibleLogin) {
    return apiCrm.put(`opportunity/change-responsible/${opportunityId}`, {
      responsibleLogin
    });
  }

  changeSeveralOpportunitiesResponsible(opportunityIds, responsibleLogin) {
    const data = { opportunityIds: opportunityIds, responsibleLogin };
    return apiCrm.put(`opportunity/change-responsible-several-opportunities`, {
      data
    });
  }

  getOpportunityComments(opportunityId) {
    return apiCrm.get(`opportunity-comment/${opportunityId}`);
  }

  createOpportunityComments(opportunityId, data) {
    const content = data.content;
    return apiCrm.post(`opportunity-comment/${opportunityId}`, { content });
  }

  updateOpportunityComments(commentId, data) {
    const content = data.content;
    return apiCrm.put(`opportunity-comment/${commentId}`, { content });
  }

  deleteOpportunityComments(commentId) {
    return apiCrm.delete(`opportunity-comment/${commentId}`);
  }

  getOpportunityHistory(opportunityId) {
    return apiCrm.get(`opportunity-history/${opportunityId}`);
  }

  getConvertOpportunityHistory(opportunityId) {
    return apiCrm.get(`convert-opportunity-history/${opportunityId}`);
  }

  async removeAttachment(opportunityId, attachmentId) {
    return apiCrm.delete(
      `/opportunity-attachment/${opportunityId}/${attachmentId}`
    );
  }

  async addAttachments(opportunityId, formData) {
    return apiCrm.post(`/opportunity-attachment/${opportunityId}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async getExcel(params) {
    return apiCrm.get('/report/opportunity/export/xlsx', {
      params,
      responseType: 'arraybuffer'
    });
  }

  async getCsv(params) {
    return apiCrm.get('/report/opportunity/export/csv', { params });
  }

  async getPdf(params) {
    return apiCrm.get('/report/opportunity/export/pdf', {
      params,
      responseType: 'blob'
    });
  }

  convertOpportunity(opportunityId, recordTypeId, data) {
    return apiCrm.post(
      `/convert-opportunity/${recordTypeId}/${opportunityId}`,
      data
    );
  }
}

export default new OpportunityService();
