import styled from 'styled-components';

export const ListFileAttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-wrap: wrap;
  width: 100%;
`;

export const AttachmentWrapItem = styled.div`
  position: relative;
  width: auto;
  padding: 0.5rem;
  line-height: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1.5rem;
  flex-direction: column;
  display: flex;
  align-items: center;

  transition: all ease-in-out 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  & .delete-icon-attachment {
    display: none;
    position: absolute;
    right: 32px;
    top: 5;
  }

  &:hover .delete-icon-attachment {
    display: block;
  }
`;
