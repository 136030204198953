import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';

import OpportunityStageService from '../service';
import { ScreenPrompt } from 'components/ScreenPrompt';
import FormTooltip from 'components/Inputs/FormTooltip';
import { opportunityTypes } from 'views/CRM/shared/utils/opportunityTypes';

const OpportunityStagesForm = () => {
  const history = useHistory();
  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;

  const [loading, setLoading] = useState(false);
  const [inputName, setInputName] = useState();
  const [inputTime, setInputTime] = useState();
  const [inputWeight, setInputWeight] = useState();
  const [typeName, setTypeName] = useState();
  const [isUpdate, setIsUpdate] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      OpportunityStageService.getById(id)
        .then(({ data }) => {
          const name = data.data.name;
          const time = data.data.time;
          const weight = data.data.weight;
          const type = data.data.type;
          setInputName(name);
          setInputTime(time);
          setInputWeight(weight);
          setTypeName(type);
          setIsUpdate(true);
        })
        .catch((error) => console.error('Erro no id', error));
    }
  }, [id]);

  useEffect(() => {
    if (inputName) {
      reset({
        name: inputName,
        type: typeName,
        time: inputTime,
        weight: inputWeight
      });
    }
  }, [inputName, typeName, reset, inputTime, inputWeight]);

  const onSubmit = async (data) => {
    data.time = Number(data.time);
    if (data.type === 'CLOSED_WON') {
      data.weight = 1;
    } else if (data.type === 'CLOSED_LOST') {
      data.weight = 0;
    } else {
      data.weight = Number(data.weight.split(',').join('.'));
    }
    setLoading(true);
    try {
      const stage = isUpdate
        ? await OpportunityStageService.update(id, data)
        : await OpportunityStageService.create(data);
      toast.success(stage.data.message || 'Fase salva com sucesso', {
        autoClose: 3000,
        closeOnClick: true
      });

      backToListing();
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        'Erro ao salvar fase';
      toast.error(message, {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const backToListing = () => {
    history.push('/admin/crm/opportunityStages');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/crm/opportunityStages"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row className="mb-3">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Fase da Oportunidade</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">Nome*</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="name"
                          rules={{
                            required: 'Nome é obrigatório',
                            maxLength: {
                              value: 50,
                              message: 'Nome deve ter no máximo 50 caracteres'
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="name"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col>
                        <label className="form-control-label text-capitalize">
                          Tipo de Oportunidade*
                        </label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={opportunityTypes || []}
                              isMulti={false}
                              value={props.value}
                              valueController={setValue}
                              fieldName="type"
                              labelName="name"
                              valueName="value"
                              isClearable={true}
                              closeMenuOnSelect={true}
                            />
                          )}
                          control={control}
                          defaultValue=""
                          rules={{
                            required: 'Campo obrigatório'
                          }}
                          name="type"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="type"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">
                          Tempo da fase
                          <FormTooltip text="O número colocado será representado por dias. Ex.: 1 é igual a 1 dia. Fases do tipo Fechado Ganho ou Fechado Perdido terão sempre o valor zerado." />
                        </label>
                        <Controller
                          render={(props) => (
                            <Input
                              value={props.value}
                              onChange={props.onChange}
                              type="number"
                            />
                          )}
                          control={control}
                          name="time"
                          defaultValue="0"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="time"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">
                          Peso da fase
                          <FormTooltip text="O número colocado deverá ser entre 0 e 1. Fase do tipo Fechado Ganho terá peso 1 por padrão e fase do tipo Fechado Perdido terá peso 0 por padrão." />
                        </label>
                        <Controller
                          as={Input}
                          control={control}
                          name="weight"
                          rules={{
                            validate: (value) => {
                              if (isNaN(Number(value)))
                                return 'O peso deverá ser um número';

                              if (Number(value) > 1 || Number(value) < 0)
                                return 'O peso da fase deverá ser um número com valor no mínimo 0 e no máximo 1. Ex.: 0.5';
                            }
                          }}
                          defaultValue={0}
                          disabled={
                            (typeName && typeName === 'CLOSED_WON') ||
                            typeName === 'CLOSED_LOST'
                          }
                        />
                        <ErrorMessage
                          errors={errors}
                          name="weight"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <ReactButton
                      btnColor="confirmation"
                      type="submit"
                      disabled={loading}
                    >
                      Salvar
                    </ReactButton>

                    <ReactButton
                      btnColor="cancelation"
                      onClick={backToListing}
                      disabled={loading}
                    >
                      Cancelar
                    </ReactButton>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OpportunityStagesForm;
