import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import PlayerModal from '../../Modal/PlayerModal';
import AudioPlayer from './components/AudioPlayer';
import ReactLoader from 'components/Loader';

export function AudioStreamReportTelephony({
  ReportService,
  uniqueid,
  nameAgent,
  calldateStart,
  Origem,
  Queue,
  disabled = false,
  reasonDisabled = ''
}) {
  const [playing, setPlaying] = useState(false);
  const [disable, setDisable] = useState(false);
  const [srcAudio, setSrcAudio] = useState(null);
  const [reasonDisable, setReasonDisable] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const executeDisabled = true;

  useEffect(() => {
    if (uniqueid) {
      setDisable(disabled);
      setReasonDisable(reasonDisabled);
    } else {
      setDisable(true);
      setReasonDisable('Não possui registro');
    }
  }, [uniqueid, disabled, ReportService, reasonDisabled]);

  const playAudio = () => {
    setIsLoading(true);
    const audioData = ReportService.streamAudio(uniqueid);
    const audio = new Audio(audioData);

    const onAudioReady = () => {
      setIsLoading(false);
      setPlaying(true);
      setSrcAudio(audio.src);
      setIsModalOpen(true);
    };

    if (audio.readyState >= 3) {
      onAudioReady();
    } else {
      audio.oncanplaythrough = onAudioReady;
      audio.onerror = () => {
        setIsLoading(false);
        toast.error('Erro ao carregar a gravação', {
          autoClose: 3000,
          closeOnClick: true
        });
      };
    }
  };

  const stopAudio = () => {
    setPlaying(false);
    setSrcAudio(() => null);
  };

  useEffect(() => {
    isModalOpen ? setPlaying(true) : setPlaying(false);
  }, [isModalOpen]);

  return (
    <>
      <div style={{ position: 'relative', width: 'min-content' }}>
        {isLoading && (
          <div
            style={{
              position: 'absolute',
              left: '4px',
              top: '3px',
              zIndex: '10'
            }}
          >
            <ReactLoader width={20} height={20}></ReactLoader>
          </div>
        )}
        <Button
          disabled={disable || isLoading}
          color={playing ? 'danger' : 'success'}
          size="sm"
          dataplacement="top"
          title={reasonDisable}
          datacontainer="body"
          dataanimation="true"
          onClick={() => {
            playing ? stopAudio(uniqueid) : playAudio(uniqueid);
          }}
        >
          <i
            className={playing ? 'fas fa-stop-circle' : 'fas fa-play-circle'}
          ></i>
        </Button>
      </div>

      <PlayerModal
        show={isModalOpen}
        onModalToggle={setIsModalOpen}
        title=""
        showCloseButton={false}
        modalContainerClasses=""
      >
        <AudioPlayer srcAudio={srcAudio} nameAgent = {nameAgent} calldateStart = {calldateStart} uniqueid = {uniqueid}  ReportService={ReportService} Origem= {Origem} Queue= {Queue} executeDisabled={executeDisabled}/>
      </PlayerModal>
    </>
  );
}
