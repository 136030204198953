import ReactButton from 'components/Buttons/ReactButton';
import ReactLoader from 'components/Loader';
import { OutlineTooltip } from 'components/OutlineTooltip';
import React, { useCallback, useEffect, useState } from 'react';
import { FaRegLightbulb } from 'react-icons/fa';
import { IoMdChatboxes, IoMdClose } from 'react-icons/io';
import { useSelector } from 'react-redux';
import {
  Col,
  Form,
  Input,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover
} from 'reactstrap';
import DialerProcessService from '../service';
import TemplatesModal from 'views/ServicePanel/components/QueueContent/components/Contact/TemplatesModal';
import { useForm } from 'react-hook-form';

const VisualizationForm = (props) => {
  const { closeDrawer, data } = props;
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [loadingForm, setLoadingForm] = useState(false);
  const [configFields, setConfigFields] = useState([]);
  const [numberFields, setNumberFields] = useState([]);
  const [modalConversationVisbility, setModalConversationVisbility] =
    useState(false);

  const { register, setValue } = useForm();

  useEffect(() => {
    setLoadingForm(true);
    if (data) {
      const fields = Object.keys(data.params || {});

      if (fields.includes('externalLink') && data?.params['externalLink'])
        window.open(data?.params['externalLink'], '_blank');

      const newConfigFields = fields.filter(
        (field) =>
          !field.match('numero') &&
          !field.match('whatsappNumber') &&
          !field.match('externalLink')
      );
      const newNumberFields = fields.filter((field) => !!field.match('numero'));

      setConfigFields(newConfigFields);
      setNumberFields(newNumberFields);

      fields.forEach((field) => {
        setValue(field, data.params[field]);
      });
    }
    setLoadingForm(false);
  }, [data, setValue]);

  const getFieldLabel = useCallback((fieldName) => {
    const knowFieldNames = new Map([
      ['nome_do_cliente', 'NOME DO CLIENTE'],
      ['identificacao', 'IDENTIFICAÇÃO'],
      ['whatsappNumber', 'WHATSAPP'],
      ['numero', 'NÚMERO'],
      ['numero_2', 'NÚMERO 2'],
      ['numero_3', 'NÚMERO 3'],
      ['numero_4', 'NÚMERO 4'],
      ['numero_5', 'NÚMERO 5'],
      ['numero_6', 'NÚMERO 6'],
      ['numero_7', 'NÚMERO 7'],
      ['numero_8', 'NÚMERO 8'],
      ['numero_9', 'NÚMERO 9']
    ]);

    if (knowFieldNames.has(fieldName)) {
      return knowFieldNames.get(fieldName);
    }

    return fieldName.toUpperCase().replaceAll('_', ' ').replaceAll('-', ' ');
  }, []);

  const isWhatsappNumberValid = useCallback(() => {
    const hasWhatsappNumber = data?.params?.whatsappNumber;
    if (!hasWhatsappNumber) return false;

    const number = data.params.whatsappNumber;
    const numberLength = number.length;

    const hasCorrectLength = numberLength === 10 || numberLength === 11;
    const hasOnlyNumericCharacters = /\d{11}/g.test(number);
    return hasCorrectLength && hasOnlyNumericCharacters;
  }, [data]);

  if (loadingForm) {
    return (
      <Row className="justify-content-md-center">
        <ReactLoader />
      </Row>
    );
  }

  return (
    <>
      <Form className="needs-validation">
        {data?.params ? (
          <>
            <div className="d-flex flex-row justify-content-between align-items-center">
              <h1 className="mb-0 h2">Dados do atendimento</h1>
              <IoMdClose
                size={25}
                cursor="pointer"
                className="close"
                id="PopoverClick"
              />
              <UncontrolledPopover
                trigger="legacy"
                placement="bottom"
                target="PopoverClick"
              >
                <PopoverHeader>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>Tem certeza?</span>
                    <div>
                      <FaRegLightbulb
                        cursor="help"
                        href="#"
                        id="UncontrolledTooltipExample"
                      />
                      <OutlineTooltip
                        placement="right"
                        target="UncontrolledTooltipExample"
                      >
                        <span className="small">
                          Dica: se precisar de mais espaço na tela você pode
                          minimizar esta janela clicando na aba lateral "Dados
                          do Atendimento".
                        </span>
                      </OutlineTooltip>
                    </div>
                  </div>
                </PopoverHeader>
                <PopoverBody>
                  Ao fechar a janela os dados atuais não poderão mais ser
                  visualizados.
                  <div className="mt-2 d-flex">
                    <ReactButton
                      type="button"
                      btnColor="confirmation"
                      onClick={closeDrawer}
                      size="sm"
                      block
                    >
                      Fechar mesmo assim
                    </ReactButton>
                  </div>
                </PopoverBody>
              </UncontrolledPopover>
            </div>
            <hr className="mt-4" />
            <Row className="mb-3 mt-0">
              {configFields.map((field) => {
                return (
                  <Col key={field} sm="6" className="mb-3">
                    <label className="form-control-label">
                      {getFieldLabel(field)}
                    </label>
                    <Input
                      name={field}
                      defaultValue={data?.params[field]}
                      readOnly={true}
                      innerRef={register()}
                    />
                  </Col>
                );
              })}
            </Row>
            <Row className="mb-3">
              {numberFields.map((field) => {
                return (
                  <Col key={field} sm="6" className="mb-3">
                    <label className="form-control-label">
                      {getFieldLabel(field)}
                    </label>

                    <div style={{ display: 'flex', gap: '12px' }}>
                      <Input
                        name={field}
                        defaultValue={data.params[field]}
                        readOnly={true}
                        innerRef={register()}
                      />

                      <ReactButton
                        btnColor="confirmation"
                        onClick={() => {
                          DialerProcessService.call({
                            cdr_dialer_id: data.cdrDialerId,
                            extension: data.extension,
                            number: data.params[field]
                          });
                        }}
                      >
                        Ligar
                      </ReactButton>
                    </div>
                  </Col>
                );
              })}
              <Col sm="6" className="mb-3">
                <label className="form-control-label">
                  {getFieldLabel('whatsappNumber')}
                </label>
                <div
                  style={{
                    display: 'flex',
                    gap: '12px',
                    alignItems: 'center'
                  }}
                >
                  <Input
                    name="whatsappNumber"
                    defaultValue={data?.params?.whatsappNumber}
                    readOnly={true}
                    innerRef={register()}
                  />
                  <IoMdChatboxes
                    size={35}
                    type="button"
                    color={
                      isWhatsappNumberValid()
                        ? systemConfiguration.primary_color
                        : 'grey'
                    }
                    style={{
                      cursor: isWhatsappNumberValid() ? 'pointer' : 'default'
                    }}
                    onClick={() => {
                      if (!isWhatsappNumberValid()) return;
                      setModalConversationVisbility(
                        !modalConversationVisbility
                      );
                    }}
                  />
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <Row
            className="mb-3"
            style={{
              alignItems: 'center',
              justifyContent: 'center',
              textAlign: 'center'
            }}
          >
            <h3>Cliente sem dados para exibição</h3>
          </Row>
        )}
        <Row>
          <Col md="6">
            <ReactButton
              btnColor="confirmation"
              onClick={() => {
                DialerProcessService.next({
                  dialer_id: data.dialerId,
                  extension: data.extension
                });
              }}
            >
              Próximo
            </ReactButton>
          </Col>
        </Row>
      </Form>

      <TemplatesModal
        show={modalConversationVisbility}
        onModalToggle={setModalConversationVisbility}
        clientNumber={data?.params?.whatsappNumber}
        clientName={data?.params?.nome_do_cliente}
        isIsolated={true}
        showCloseButton={false}
      />
    </>
  );
};

export default VisualizationForm;
