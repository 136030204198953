import styled from 'styled-components';

export const ContainerItems = styled.div`
  display: block;
  flex-direction: column;
  padding: 1.5rem 1rem;
  max-height: 35rem;
  width: 30rem;
  overflow-y: scroll;

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const TittleModalNotification = styled.span`
  font-size: 1.3rem;
  font-weight: bold;
  color: #050505;
  padding-bottom: 0.5rem;
`;

export const ButtonsFunc = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0 2rem 0;
`;
export const TittleSeeAll = styled.span`
  font-size: 0.9rem;
  display: inline-block;
  margin-left: 14rem;
  text-decoration: underline;
`;
