import { format, startOfMonth } from 'date-fns';
export const getDateBegin = () => {
  const date = new Date();
  const firstDayMonth = new Date(
    date.getFullYear('pt-br'),
    date.getMonth('pt-br'),
    1
  );
  const dateBegin = format(startOfMonth(firstDayMonth), 'dd/LL/yyyy');
  return dateBegin;
};
