import React, { createContext, useState, useContext, useCallback } from 'react';

const useBlockAcessServicePainelContext = createContext();

export function BlockAcessServicePainelProvider({ children }) {
  const [isBlockServicePainel, setIsBlockServicePainel] = useState();
  const [typeOfPause, setTypeOfPause] = useState();

  const handleModifielTypeOfPause = (value) => {
    //aqui modificar o localStorage
    localStorage.setItem('type_of_pause', value);
    setTypeOfPause(() => localStorage.getItem('type_of_pause'));
  };

  const handleModifieldIsBlockServicePainel = (value) => {
    //aqui modificar o localStorage
    setIsBlockServicePainel(value);
    if (!value) {
      localStorage.removeItem('type_of_pause');
    }
    localStorage.setItem('is_paused', value);
  };

  const getInitialValuesBlockAcessServicePainel = useCallback(() => {
    const is_paused = JSON.parse(localStorage.getItem('is_paused'));
    const type_of_pause = localStorage.getItem('type_of_pause');
    setIsBlockServicePainel(is_paused);
    setTypeOfPause(type_of_pause);
  }, []);

  // const getInitialValuesBlockAcessServicePainel = () => {
  //   const is_paused = JSON.parse(localStorage.getItem('is_paused'));
  //   const type_of_pause = localStorage.getItem('type_of_pause');
  //   setIsBlockServicePainel(is_paused);
  //   setTypeOfPause(type_of_pause);
  // };

  return (
    <>
      <useBlockAcessServicePainelContext.Provider
        value={{
          isBlockServicePainel,
          typeOfPause,
          getInitialValuesBlockAcessServicePainel,
          handleModifieldIsBlockServicePainel,
          handleModifielTypeOfPause
        }}
      >
        {children}
      </useBlockAcessServicePainelContext.Provider>
    </>
  );
}

export function useServiceBlockServicePainelContext() {
  const context = useContext(useBlockAcessServicePainelContext);
  return context;
}
