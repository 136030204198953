import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

// Default values
const backgroundColor = '#FFFFFF';
const borderColor = '#9DAAB9';

const getBorderColorByPlacement = (placement, color) => {
  const map = {
    bottom: `${color} transparent transparent transparent`,
    left: `transparent ${color} transparent transparent`,
    top: `transparent transparent ${color} transparent`,
    right: `transparent transparent transparent ${color}`
  };

  return map[placement];
};

// pointer-events to fix the tooltip flickering when mouseover
const TooltipBottom = styled(UncontrolledTooltip)`
  opacity: 1 !important;
  pointer-events: none;

  .tooltip-inner {
    display: inline-block;
    position: relative;
    text-align: left;
    color: #566382;
    background-color: ${backgroundColor};
    border-width: 1px;
    border-style: solid;
    border-color: ${borderColor};
    padding: 10px;
    margin: 10px 0 0 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.4) 0 2px 3px);
  }

  .arrow {
    display: none;
  }

  .tooltip-inner::before,
  .tooltip-inner::after {
    content: '';
    display: block;
    position: absolute;
    left: ${({ placement }) => (placement === 'right' ? '100%' : 'auto')};
    right: ${({ placement }) => (placement === 'left' ? '100%' : 'auto')};
    top: ${({ placement }) => (placement === 'bottom' ? '100%' : 'auto')};
    bottom: ${({ placement }) => (placement === 'top' ? '100%' : 'auto')};
    width: 0;
    height: 0;
    border-style: solid;
  }

  .tooltip-inner::after {
    top: ${({ placement }) =>
      placement === 'left' || placement === 'right'
        ? 'calc(50% - 7px)'
        : 'auto'};
    right: ${({ placement }) =>
      placement === 'top' || placement === 'bottom'
        ? 'calc(50% - 7px)'
        : 'auto'};
    border-color: ${({ placement }) =>
      getBorderColorByPlacement(placement, backgroundColor)};
    border-width: 7px;
  }

  .tooltip-inner::before {
    top: ${({ placement }) =>
      placement === 'left' || placement === 'right'
        ? 'calc(50% - 10px)'
        : 'auto'};
    right: ${({ placement }) =>
      placement === 'top' || placement === 'bottom'
        ? 'calc(50% - 10px)'
        : 'auto'};
    border-color: ${({ placement }) =>
      getBorderColorByPlacement(placement, borderColor)};
    border-width: 10px;
  }
`;
export default TooltipBottom;
