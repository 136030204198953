import React from 'react';
import { useSelector } from 'react-redux';

const HeaderBar = ({ title = '' }) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  return (
    <div
      style={{
        backgroundColor: systemConfiguration.primary_color,
        color: '#FFF',
        padding: '0.5rem 1rem',
        margin: '0.5rem 0',
        fontSize: '1.5rem',
        borderRadius: '0.3rem'
      }}
    >
      <span>{title.replaceAll('%20', ' ')}</span>
    </div>
  );
};

export default HeaderBar;
