import ServiceCRUD from '../../../services/ServiceCRUDFLEXCHAT';
import { apiChatExport, apiChat } from '../../../http';

class ConversationService extends ServiceCRUD {
  constructor() {
    super('conversa');
  }

  uploadFile = (data) => {
    return apiChatExport.post(`${this.path}/upload/arquivo`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  };

  //https://flexia.g4flex.com.br/historico/cliente/5ff368f779f6120011ae589a
  getHistory = (id, atendente, hora_fim_conversa) => {
    return apiChat.get(`historico/cliente/${id}`, {
      params: {
        atendente,
        hora_fim_conversa
      }
    });
  };

  getConversation = (id) => {
    return apiChat.get(`conversa/encerrada/${id}`);
  };

  getConversationById = async (id) => {
    const result = await apiChat.get(`conversa/${id}`);
    return result.data;
  };

  sendTemplateMessage(conversationId, messageData) {
    return apiChat.post(`${this.path}/atendente-enviar-template`, {
      conversationId,
      messageData: {
        id: messageData.id,
        message: messageData.message,
        params: messageData.params
      }
    });
  }
}

export default new ConversationService();
