export const dateRules = {
  required: 'Data é obrigatório!',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};

// export const cpfRules = {
//   pattern: {
//     value: /^[0-9][0-9][0-9].[0-9][0-9][0-9].[0-9][0-9][0-9]-[0-9][0-9]$/,
//     message: 'CPF inválido! (000.000.000-00)'
//   }
// };

export const reportOptions = [
  { label: 'Analítico', value: 1 },
  { label: 'Sintético', value: 2 }
];

export const contactOrigin = [
  { label: 'Ativo', value: 1 },
  { label: 'Receptivo', value: 2 },
  { label: 'Campanha Ativa Whatsapp', value: 3 }
];

export const situationOptions = [
  { label: 'Abandonada', value: 'abandonada' },
  { label: 'Em atendimento', value: 'em_atendimento' },
  { label: 'Encerrada', value: 'encerrada' },
  { label: 'Não atendida', value: 'nao_atendida' },
  { label: 'Transferida', value: 'Transferida' }
];

export const closedByOptions = [
  { label: 'Administrador', value: 'ADMNISTRADOR' },
  { label: 'Atendente', value: 'ATENDENTE' },
  { label: 'Bot', value: 'BOT' },
  { label: 'Cliente', value: 'CLIENTE' },
  { label: 'Ociosidade', value: 'OCIOSIDADE' }
];

export const booleanOptions = [
  { label: 'Sim', value: 'true' },
  { label: 'Não', value: 'false' }
];

export const satisfactionOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 }
];

export const channelOptions = [
  { label: 'Chat', value: 'chat' },
  { label: 'SMS', value: 'sms' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'Telegram', value: 'telegram' },
  { label: 'Whatsapp', value: 'whatsapp' },
  { label: 'Flex4U', value: 'flex4u' }
];

export const groupByOptions = [
  { label: 'Assunto', value: 'assunto' },
  { label: 'Atendimento Bot', value: 'atendimentoBot' },
  { label: 'Atendente', value: 'atendente' },
  { label: 'Assunto Bot', value: 'assuntoBot' },
  { label: 'Fila', value: 'fila' },
  { label: 'Motivo', value: 'motivo' },
  { label: 'Submotivo', value: 'submotivo' },
  // {
  //   label: 'Status da Lista de Transmissão',
  //   value: 'statusListaDeTransmissao'
  // },
  { label: 'Campanha Ativa Whatsapp', value: 'nameBroadCastList' },
  { label: 'Produto', value: 'produto' },
  { label: 'Setor', value: 'setor' },
  { label: 'Sucesso Atendimento Bot', value: 'sucessoAtendimento' },
  { label: 'Situação', value: 'situacao' },
  { label: 'Tag', value: 'tag' },
  { label: 'Intervalo', value: 'intervalo' }
];
