import styled from 'styled-components';
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap';

export const StyledCard = styled(Card)`
  background-color: ${(props) => props.backgroundColor};
  color: #fff;
  padding: 8px;
`;

export const StyledCardHeader = styled(CardHeader)`
  padding: 0;
  background-color: ${(props) => props.backgroundColor};
  border: none;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledCardTitle = styled(CardTitle)`
  margin: 0px;
  color: #fff;
  font-size: 0.875rem;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const StyledCardBody = styled(CardBody)`
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
`;
