import ServiceCRUD from '../../../services/ServiceCRUDFLEXUC';
import { apiUc, apiAuth } from '../../../http';

class UserService extends ServiceCRUD {
  constructor() {
    super('/api/usuarios');
  }

  changeActive = (login, data) => {
    return apiUc.put(`${this.path}/${login}/status`, data);
  };

  changePassword = (login, data) => {
    return apiAuth.put(`/atualizar/${login}/senha`, data);
    // return apiAuth.put(`/atualizar/${login}/senha`, data);
  };

  search = (params) => {
    return apiUc.get(`${this.path}`, {
      params
    });
  }
}

export default new UserService();
