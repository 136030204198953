import React, { useEffect, useState } from 'react';
import { Card } from 'reactstrap';

import { styles } from './styles';
import { state_colors } from '../../../utils/state_colors';
import { differenceInSeconds } from 'date-fns';

const ExtensionCardItem = ({
  data: { extension, state, costCenter, phoneNumber, date, identifier = '' }
}) => {
  const [upTime, setUpTime] = useState('00:00:00');

  useEffect(() => {
    const intervalID = setInterval(() => {
      let differenceFromNow = differenceInSeconds(new Date(), new Date(date));

      const hours = Math.floor(differenceFromNow / (60 * 60));
      differenceFromNow -= hours * 60 * 60;

      const minutes = Math.floor(differenceFromNow / 60);
      differenceFromNow -= minutes * 60;

      const seconds = Math.floor(differenceFromNow);

      function getFomattedDigit(digit) {
        return `${String(digit).padStart(2, '0')}`;
      }

      setUpTime(
        `${getFomattedDigit(hours)}:${getFomattedDigit(
          minutes
        )}:${getFomattedDigit(seconds)}`
      );
    }, 1000);

    return () => clearInterval(intervalID);
  }, [date]);

  return (
    <Card
      style={{
        ...styles.container,
        backgroundColor: state_colors[state]
      }}
    >
      <strong>
        {extension} - {identifier}
      </strong>
      <strong>{costCenter}</strong>
      <div style={styles.itemRow}>Cliente: {phoneNumber}</div>
      <span>Tempo: {upTime}</span>
    </Card>
  );
};

export default ExtensionCardItem;
