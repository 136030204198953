import { apiUc } from 'http/index';

export const getBFFAgentDashboard = async ({ agent, queues }) => {
  const {
    data: { data }
  } = await apiUc.get('/api/bff-agent-dashboard', {
    params: {
      agentCode: agent,
      queues
    }
  });

  return data;
};
