import React, { useCallback } from 'react';

import DefaultModal from '../../../../components/Modal/DefaultModal';
import ProtocolForm from '../../../../views/Protocol/form';

const ProtocolModal = ({
  isModalOpen,
  onModalToggle,
  defaultFormData,
  createdFrom = '',
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  return (
    <DefaultModal
      backdrop={'static'}
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Protocolo de atendimento"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      <ProtocolForm
        modalActions={{ closeModal, defaultFormData }}
        selectedRecordTypeId={defaultFormData?.recordTypeId}
        createdFrom={createdFrom}
      />
    </DefaultModal>
  );
};

export default ProtocolModal;
