import { useState, useCallback } from 'react';

import {
  calculateOffsetFromPageAndLimit,
  defaultLimit
} from 'views/CRM/shared/utils/pagination';

import SalesProcessService from '../service';

function useListSalesProcesses() {
  const [salesProcesses, setSalesProcesses] = useState(null);
  const [totalSalesProcesses, setTotalSalesProcesses] = useState(null);
  const [isLoadingSalesProcesses, setIsLoadingSalesProcesses] = useState(false);

  const getSalesProcesses = useCallback(
    async (page = 1, params = {}, limit = defaultLimit) => {
      setIsLoadingSalesProcesses(true);

      const offset = calculateOffsetFromPageAndLimit(page, limit);

      SalesProcessService.list(params, { offset, limit })
        .then((response) => {
          setSalesProcesses(response.data.data);
          setTotalSalesProcesses(response.data.totalRegisters);
        })
        .catch(() => {
          setSalesProcesses(null);
        })
        .finally(() => {
          setIsLoadingSalesProcesses(false);
        });
    },
    []
  );

  return {
    salesProcesses,
    isLoadingSalesProcesses,
    totalSalesProcesses,
    getSalesProcesses
  };
}

export { useListSalesProcesses };
