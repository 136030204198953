/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Col,
  FormGroup,
  Label,
  Input
} from 'reactstrap';

import Loader from 'components/Loader';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import Pagination from 'components/Pagination';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

import { useListLeadStages } from '../hooks/useListLeadStages';

import LeadStageService from '../service';
import { toast } from 'react-toastify';

const LeadStages = () => {
  const [activePage, setActivePage] = useState(1);
  const [showDisabled, setShowDisable] = useState(false);

  const { getLeadStages, leadStages, totalLeadStages, isLoadingLeadStages } =
    useListLeadStages();

  useEffect(() => {
    getLeadStages(1);
  }, []);

  useEffect(() => {
    getLeadStages(1, { showDisabled: showDisabled ? 'true' : 'false' });
  }, [showDisabled]);

  const goToPage = (newPage) => {
    setActivePage(newPage);
    getLeadStages(newPage);
  };

  const handleEnableLeadStage = async (id) => {
    await LeadStageService.enable(id);
  };

  const handleDisableLeadStage = async (id) => {
    await LeadStageService.disable(id);
  };

  const toggleLeadStageStatus = ({ id, isActive }) => {
    try {
      if (isActive) {
        handleDisableLeadStage(id);
      } else {
        handleEnableLeadStage(id);
      }
      toast.success(
        `Fase do lead ${isActive ? 'desabilitada' : 'habilitada'} com sucesso`
      );
    } catch (error) {
      const msg = error?.response?.data?.message;
      toast.error(
        msg || `Erro ao ${isActive ? 'desabilitar' : 'habilitar'} fase do lead`
      );
    }
    setTimeout(() => {
      goToPage(1);
    }, 100);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/crm/leadStages/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Fases do Lead</h3>
                </CardHeader>

                <Row className="mt-3 mx-2">
                  <Col md="12" className="text-right mt-2 mt-md-1">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={showDisabled}
                          onChange={() => setShowDisable(!showDisabled)}
                        />
                        <span>Mostrar desabilitados</span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <CardBody>
                  {isLoadingLeadStages ? (
                    <div className="col-12">
                      <Row className="justify-content-md-center">
                        <Loader />
                      </Row>
                    </div>
                  ) : (
                    <Table hover={true}>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th className="text-center">Editar</th>
                          <th className="text-center">Habilitar/Desabilitar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leadStages &&
                          leadStages.map((leadStage) => {
                            return (
                              <tr key={leadStage.id}>
                                <td>{leadStage.name}</td>
                                <td className="text-center" width="10%">
                                  <Link
                                    to={
                                      '/admin/crm/leadStages/form/' +
                                      leadStage.id
                                    }
                                  >
                                    <Button color="info" size="sm">
                                      <li className="fas fa-user-edit"></li>
                                    </Button>
                                  </Link>
                                </td>
                                <td className="text-center" width="10%">
                                  <ToggleSwitch
                                    checked={leadStage.isActive}
                                    id={leadStage.id}
                                    onChange={() =>
                                      toggleLeadStageStatus({
                                        id: leadStage.id,
                                        isActive: leadStage.isActive
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={totalLeadStages}
                  onChange={goToPage.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default LeadStages;
