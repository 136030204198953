import { differenceInSeconds } from 'date-fns';
import React, { useEffect, useState } from 'react';

function UptimeCounter({ startDate }) {
  const [upTime, setUpTime] = useState('00:00:00');

  useEffect(() => {
    if (!startDate) return;

    const intervalID = setInterval(() => {
      let differenceFromNow = differenceInSeconds(
        new Date(),
        new Date(startDate)
      );

      const hours = Math.floor(differenceFromNow / (60 * 60));
      differenceFromNow -= hours * 60 * 60;

      const minutes = Math.floor(differenceFromNow / 60);
      differenceFromNow -= minutes * 60;

      const seconds = Math.floor(differenceFromNow);

      function getFomattedDigit(digit) {
        return `${String(digit).padStart(2, '0')}`;
      }

      setUpTime(
        `${getFomattedDigit(hours)}:${getFomattedDigit(
          minutes
        )}:${getFomattedDigit(seconds)}`
      );
    }, 1000);

    return () => clearInterval(intervalID);
  }, [startDate]);

  return (
    <span className="small d-inline-block ml-1 font-weight-bold text-white">
      {upTime}
    </span>
  );
}

export default UptimeCounter;
