import { useEffect, useState, useCallback } from 'react';
import ClientService from '../../service';
import FieldsConfigService from '../../../fieldConfigs/service';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';

const PAGE_SIZE = 10;

export const getClientFields = async () => {
  try {
    const response = await FieldsConfigService.getFields(entitiesName.CONTACT);
    return response.data.data;
  } catch (error) {
    console.error('Erro ao buscar campos do cliente: ', error);
    return null;
  }
};

const useClienteSearch = () => {
  const [fieldsConfig, setFieldsConfig] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function setClientFields() {
      const fields = await getClientFields();
      setFieldsConfig(fields);
    }

    setClientFields();
  }, []);

  const searchClient = useCallback(async ({ attribute, value, page = 1 }) => {
    try {
      setLoading(true);

      const resultSet = await ClientService.getAllFilter(
        'cliente',
        page,
        PAGE_SIZE,
        {
          [attribute]: value,
          orderBy: attribute
        }
      );

      return resultSet.data;
    } catch (error) {
      console.error('Erro ao filtrar cliente');
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    searchClient,
    fieldsConfig,
    loading
  };
};

export { useClienteSearch };
