import { ErrorMessage } from '@hookform/error-message';
import ReactButton from 'components/Buttons/ReactButton';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, Form, Input, Row } from 'reactstrap';
import Select from 'react-select/async';

import CustomFieldsInputsForm from 'views/CRM/shared/components/CustomFieldsInputsForm';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import FlexSelect from 'components/Inputs/FlexSelect';
import OpportunityStageService from '../../../../../OpportunityStages/service';

import OpportunityService from '../../../../../Opportunity/services/opportunityService';
import { custom_select } from 'assets/styles/multiple_select';
import { useSearchUsers } from 'views/User/hooks/useSearchUsers';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';

const AddOpportunityToAccountForm = ({
  isOpen,
  setIsOpen,
  recordTypeId,
  accountId,
  onAddedOpportunity
}) => {
  const { control, errors, setValue, handleSubmit, setError, watch } =
    useForm();
  const [stages, setStages] = useState();
  const [isLoadingStages, setIsLoadingStages] = useState(false);
  const [isSubmiting, setIsSubmiting] = useState(false);
  const [responsible, setResponsible] = useState({
    label: localStorage.getItem('name'),
    value: localStorage.getItem('login')
  });

  const { customFields, getCustomFields } = useEntityCustomFieldsByRecordType(
    entitiesName.OPPORTUNITY,
    recordTypeId
  );
  const { isSearchingUsers, searchUsers } = useSearchUsers();

  useEffect(() => {
    const getStages = () => {
      setIsLoadingStages(true);
      OpportunityStageService.listOrdenade(recordTypeId)
        .then((response) => {
          setStages(response.data.data);
        })
        .catch(() => {
          toast.error('Ocorreu um erro ao buscar as fases da oportunidade');
        })
        .finally(() => {
          setIsLoadingStages(false);
        });
    };
    getStages();
  }, [recordTypeId]);

  useEffect(() => {
    if (isOpen) getCustomFields();
  }, [isOpen, getCustomFields]);

  const onSubmit = (data) => {
    if (!responsible || !responsible.value) {
      setError('responsibleLogin', { message: 'Campo obrigatório' });
      return;
    }

    setIsSubmiting(true);

    data.accountId = accountId;
    data.responsibleLogin = responsible.value;

    const dataDTO = formatCustomFieldsToSubmit(data, customFields);

    OpportunityService.createOpportunity(recordTypeId, dataDTO)
      .then(() => {
        toast.success('Oportunidade criada com sucesso');
        onAddedOpportunity();
        setIsOpen(false);
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.message ||
            err.message ||
            'Erro ao criar a oportunidade',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      })
      .finally(() => {
        setIsSubmiting(false);
      });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <Col sm="6">
          <label className="form-control-label text-capitalize">Título*</label>
          <Controller
            render={(props) => (
              <Input
                value={props.value}
                onChange={props.onChange}
                type="text"
                placeholder="Título"
              />
            )}
            control={control}
            defaultValue=""
            rules={{ required: 'Campo obrigatório' }}
            name="title"
          />
          <ErrorMessage
            errors={errors}
            name="title"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col>
          <label className="form-control-label text-capitalize">
            Fase da oportunidade*
          </label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={stages || []}
                isMulti={false}
                value={props.value}
                valueController={setValue}
                fieldName="stageId"
                labelName="name"
                valueName="id"
                isClearable={true}
                disabled={isLoadingStages}
                closeMenuOnSelect={true}
              />
            )}
            control={control}
            defaultValue=""
            rules={{
              required: 'Campo obrigatório'
            }}
            name="stageId"
          />
          <ErrorMessage
            errors={errors}
            name="stageId"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>

      <Row className="mb-3">
        <Col md="6">
          <div className="d-flex">
            <div style={{ flexGrow: '10' }}>
              <label className="form-control-label">Responsável*</label>
              <Select
                styles={custom_select}
                placeholder="Busca rápida"
                isClearable={true}
                name="responsibleLogin"
                isLoading={isSearchingUsers}
                loadingMessage={() => 'Buscando...'}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue
                    ? 'Digite para pesquisar'
                    : 'Nenhum resultado encontrado'
                }
                loadOptions={async (inputValue) => {
                  const data = await searchUsers(inputValue);
                  return (
                    data &&
                    data.map((user) => ({
                      label: user.name,
                      value: user.login
                    }))
                  );
                }}
                onChange={(data) => {
                  if (data) {
                    const { value, label } = data;
                    setResponsible({
                      label,
                      value
                    });
                  } else {
                    setResponsible(null);
                  }
                }}
                value={
                  responsible
                    ? {
                        label: responsible.label,
                        value: responsible.value
                      }
                    : ''
                }
                defaultValue=""
              />
              <ErrorMessage
                errors={errors}
                name="responsibleLogin"
                render={({ message }) => errorFormMessage(message)}
              />
            </div>
          </div>
        </Col>

        <CustomFieldsInputsForm
          fields={customFields}
          control={control}
          errors={errors}
          setValue={setValue}
          Controller={Controller}
          watch={watch}
        />
      </Row>

      <hr />

      <ReactButton btnColor="confirmation" type="submit" disabled={isSubmiting}>
        Salvar
      </ReactButton>

      <ReactButton btnColor="cancelation" onClick={() => setIsOpen(false)}>
        Cancelar
      </ReactButton>
    </Form>
  );
};

export default AddOpportunityToAccountForm;
