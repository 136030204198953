import ServiceCRUD from '../../../services/ServiceCRUD';
import { apiCase } from '../../../http';

class RecordTypeService extends ServiceCRUD {
  constructor() {
    super('record-type');
  }

  list(params) {
    return apiCase.get(`/record-type`, { params });
  }

  listByUser(entity, params) {
    return apiCase.get(`/record-type/by-user/${entity}`, { params });
  }

  enableRecordType(recordTypeId) {
    return apiCase.put(`/record-type/change-status/${recordTypeId}`);
  }

  disableRecordType(recordTypeId) {
    return apiCase.put(`/record-type/change-status/${recordTypeId}`);
  }
}

export default new RecordTypeService();
