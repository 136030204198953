import ServiceCRUD from '../../../services/ServiceCRUD';
import { apiCase as http } from '../../../http';

class ReasonService extends ServiceCRUD {
  constructor() {
    super('subreasons');
  }

  getByReasonId(reason_id) {
    return http.get(`subreasons/by_reason/${reason_id}`);
  }

  getMultipleIds(ids) { 
    const params = ids.join(",")
    return http.get(`subreasons/by_multiple_reasons?ids=${params}`); 
  }


}

 

export default new ReasonService();
