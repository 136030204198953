import React, { useState, useEffect, useCallback } from 'react';
import Service from './service';
import QueueService from '../../../Queue/service';
import TagService from '../../../SatisfactionSurveyLevel/Tags/service';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from '../../../../components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import { dateRules } from './assets/rulesValidation';
import { typesConfig, channels } from './assets/types';
import { notes } from '../assets/notes';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import Table from './table';
import csvImage from '../../../../assets/img/csv.png';
import pdfImage from '../../../../assets/img/pdf.png';
import * as fileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import userService from './service/UserService';
import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';
import { Tooltip } from '@material-ui/core';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import Pagination from 'components/Pagination';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';

const Report = () => {
  const { control, handleSubmit, errors, setValue, watch, getValues } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [SatisfactionReport, setSatisfactionReport] = useState([]);
  const [dataToSubmitDownloads, setDataToSubmitDownloads] = useState();
  const [quantity, setQuantity] = useState();
  const [queues, setQueues] = useState([]);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [agent, setAgent] = useState([]);
  const [tags, setTags] = useState([]);
  const [loadingForm] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [ratesByTypeSurvey, setRatesByTypesSurvey] = useState([]);
  const showDisableQueue = watch('disable');
  const typeSurvey = watch('type_survey');

  const [messagemGeneratePdf, setMessagemGeneratePdf] = useState();
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();

  const [quantityInfo, setQuantityInfo] = useState(0);
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );

  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantityInfo,
      limitGenerationPDF
    );

    setMessagemGeneratePdf(message.pdfMessage);
    setMessagemGenerateCsv(message.csvMessage);
  }, [quantityInfo, limitGenerationPDF]);

  useEffect(() => {
    userService
      .getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        setAgent(response.data.data);
      })
      .catch((err) => {
        console.log('error:' + err);
      });
  }, [usersShowDisable]);

  useEffect(() => {
    QueueService.getAllShowDisable(showDisableQueue)
      .then((response) => {
        const data = response.data.data;
        const queues = data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });
        setQueues(queues);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, [showDisableQueue]);

  useEffect(() => {
    if (typeSurvey === 'nps') {
      const rates = notes.slice(0, 10);
      setRatesByTypesSurvey(rates);
    } else if (typeSurvey === 'satisfacao') {
      const rates = notes.slice(0, 5);
      setRatesByTypesSurvey(rates);
    } else {
      setRatesByTypesSurvey([]);
    }
  }, [typeSurvey]);

  useEffect(() => {
    TagService.showTags()
      .then((response) => {
        setTags(response.data.list);
      })
      .catch((err) => {
        console.log('erroListTag', err);
      });
  }, []);

  const downloadMedia = useCallback(
    async (media, type, fileName) => {
      try {
        const response = await Service.getDownloadsPdfAndCsv(
          dataToSubmitDownloads,
          media
        );
        fileSaver.saveAs(new Blob([response.data], { type }), fileName);
      } catch (err) {
        toast.error(
          err || 'Ops, ocorreu um erro ao realizar o download do arquivo!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      }
    },
    [dataToSubmitDownloads]
  );

  const toISOFormat = useCallback(async (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${YYYY}-${MM}-${DD}`;
  }, []);

  const getDataDTO = useCallback(
    async (data) => {
      let dataToSend;

      const dataQueues = queues.filter((queue) => data.queue.includes(queue.id));

      const objTagsArray = tags.filter((tag) => {
        if (data.tags.includes(tag._id)) {
          return tag;
        }
        return null;
      });

      dataToSend = {
        queue: dataQueues || '',
        initialDate: `${await toISOFormat(data.dateBegin)}`,
        finalDate: `${await toISOFormat(data.dateEnd)}`,
        type_survey: data.type_survey,
        channel: data.channel,
        tag: objTagsArray[0] || '',
        rates: data.rates || [],
        user: data.atendente || '',
        page: 1
      };

      setDataToSubmitDownloads(dataToSend);
      return dataToSend;
    },
    [toISOFormat, queues, tags]
  );

  const getReports = useCallback(
    (dataToSend, selectedPage = 1) => {
      setLoadingTable(true);
      const newActivePage = selectedPage || activePage;
      Service.fetchReports(dataToSend)
        .then((res) => {
          // console.log(res);
          const { list } = res.data;
          const { total } = res.data;
          setQuantityInfo(total);
          // console.log('list', list);
          // console.log('total', total);
          setSatisfactionReport(list || []);
          setQuantity(total);
          setActivePage(newActivePage);
          if (list.length === 0) {
            toast.info('Nenhum registro foi encontrado!');
          }
          setLoadingTable(false);
        })
        .catch((err) => {
          setLoadingTable(false);
          console.log('erro na request de buscar dados relatorio=>' + err);
        });
    },
    [activePage]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        const dataToSend = await getDataDTO(data);

        getReports(dataToSend);
      } catch (err) {
        console.log(err);
        toast.error('Erro desconhecido por favor, informar o suporte!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [getDataDTO, getReports]
  );

  const handlePageChange = (activePage) => {
    const dataSubmit = { ...dataToSubmitDownloads, page: activePage };
    getReports(dataSubmit, activePage);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatorio analítico de satisfação</h3>
                </CardHeader>
                <CardBody>
                  {loadingForm ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Fila</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={queues}
                                isMulti={true}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="queue"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="queue"
                            defaultValue=""
                          />
                        </Col>
                        <Col md="3">
                          <label className="form-control-label">Agente</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={agent}
                                isMulti={true}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="atendente"
                                labelName="name"
                                valueName="name"
                              />
                            )}
                            control={control}
                            name="atendente"
                            defaultValue=""
                          />
                        </Col>

                        <Col md="3">
                          <label className="form-control-label">Canal</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={channels}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="channel"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="channel"
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Data inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateBegin"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateBegin"
                            rules={dateRules}
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateBegin"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Data final*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateEnd"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateEnd"
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                            rules={{
                              ...dateRules,
                              validate: async (value) => {
                                if (
                                  new Date(await toISOFormat(value, true)) <
                                  new Date(
                                    await toISOFormat(
                                      getValues()['dateBegin'],
                                      true
                                    )
                                  )
                                ) {
                                  return 'A data final não pode ser anterior à inicial!';
                                } else {
                                  return true;
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Tipo de pesquisa*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={typesConfig}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                defaultValue="satisfacao"
                                fieldName="type_survey"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            rules={{
                              required: 'Campo obrigatório!'
                            }}
                            control={control}
                            name="type_survey"
                            defaultValue="satisfacao"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="type_survey"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Tag</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={tags}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="tags"
                                labelName="name"
                                valueName="_id"
                              />
                            )}
                            control={control}
                            name="tags"
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="12">
                          <label className="form-control-label">
                            Nota da pesquisa
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={ratesByTypeSurvey}
                                isMulti={true}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={false}
                                valueController={setValue}
                                fieldName="rates"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="rates"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="rates"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-12">
                        <Col className="md-5 mx-4">
                          <div style={{ display: 'flex', gap: '20px' }}>
                            <Controller
                              name="disable"
                              control={control}
                              render={(props) => (
                                <>
                                  <label className="form-control-label">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                    Mostrar desabilitados (Fila)
                                  </label>
                                </>
                              )}
                              defaultValue=""
                            />
                            <UserFlexSelect
                              usersShowDisable={usersShowDisable}
                              setUsersShowDisable={setUsersShowDisable}
                              name={'Agentes'}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Col md="12" style={ImageButtonContainer}>
                        <button
                          // disabled={!SatisfactionReport.length > 0}
                          disabled={disabledDownloadButtonCsvAndPdf(
                            'pdf',
                            quantityInfo,
                            limitGenerationPDF
                          )}
                          style={ImageButton}
                          onClick={() =>
                            downloadMedia(
                              'pdf',
                              'application/pdf',
                              'Relatório analítico de satisfação.pdf'
                            )
                          }
                        >
                          <Tooltip
                            title={
                              <p style={{ fontSize: '14px' }}>
                                {messagemGeneratePdf}
                              </p>
                            }
                            arrow
                          >
                            <img src={pdfImage} alt="pdf" style={ImageSize} />
                          </Tooltip>
                        </button>
                        <button
                          // disabled={!SatisfactionReport.length > 0}
                          disabled={disabledDownloadButtonCsvAndPdf(
                            'csv',
                            quantityInfo,
                            limitGenerationPDF
                          )}
                          style={ImageButton}
                          onClick={() =>
                            downloadMedia(
                              'csv',
                              'application/csv',
                              'Relatório analítico de satisfação.csv'
                            )
                          }
                        >
                          <Tooltip
                            title={
                              <p style={{ fontSize: '14px' }}>
                                {messagemGenerateCsv}
                              </p>
                            }
                            arrow
                          >
                            <img src={csvImage} alt="csv" style={ImageSize} />
                          </Tooltip>
                        </button>
                      </Col>

                      <Button
                        style={buttonStyle(systemConfiguration.primary_color)}
                        type="submit"
                      >
                        Buscar
                      </Button>
                    </Form>
                  )}
                </CardBody>
                {loadingTable ? (
                  <div className="py-4 table-responsive">
                    <Row className="justify-content-md-center">
                      {loadingTable && (
                        <Loader
                          type="TailSpin"
                          color={systemConfiguration.primary_color}
                          height={100}
                          width={100}
                        />
                      )}
                    </Row>
                  </div>
                ) : (
                  <>
                    {SatisfactionReport.length > 0 && (
                      <Table
                        totalRegister={quantityInfo}
                        showReports={SatisfactionReport}
                      />
                    )}
                    {SatisfactionReport.length > 0 && (
                      <Pagination
                        activePage={activePage}
                        totalItemsCount={quantity}
                        onChange={handlePageChange.bind(this)}
                      />
                    )}
                  </>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Report;
