import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleInfosUser, getListuser } from '../../store/action/internalChat';
import { watchNewMessages } from '../../store/action/internalChat';

import api from './config/configAPI';

import { useConectionSocketAdmConversation } from 'hooks/useSocketAdmConversations';
import { useConectionSocketChatInternal } from 'hooks/useInternalChatSocket';

import { Container, BodyChat, Header } from './style';

import { UserListContainer } from './components/userList';
// import { useRequestUsers } from 'hooks/useUserContext';
import UserService from 'views/User/service/index';

export const InternalChat = ({ sizeChat }) => {
  const [users, setUsers] = useState([]);

  // const { users } = useRequestUsers();
  const [usersInternalChat, setUsersInternalChat] = useState([]);
  const { socketInternalChat } = useConectionSocketChatInternal();
  const { socketAdm } = useConectionSocketAdmConversation();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const watchStateNewMessages = useSelector(
    (state) => state.activeChatInternal.newMessages
  );

  const dispatch = useDispatch();
  const dataChat = useSelector((state) => state.orderListUser);

  const login = localStorage.getItem('login');
  const [unreadMessages, setUnreadMessages] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [listMessages, setListMessages] = useState([]);
  const [userLogeds, setUsersLogeds] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userConversation, setUserConversation] = useState([]);
  const [senders, setSenders] = useState([]);

  function handleInfosUserFunction(infos) {
    api
      .post('conversations/', {
        senderId: localStorage.getItem('login'),
        receiverId: infos.login
      })
      .then((response) => {
        const data = {
          id: response.data.id
        };
        dispatch(handleInfosUser(Object.assign(infos, data)));
      })
      .catch((err) => {
        console.log('error', err);
      });
  }
  function updateConversation(infos) {
    socketInternalChat.emit('chat:opened', {
      self: login,
      partnerId: infos.login,
      groupId: ''
    });
    const usersFormated = usersInternalChat.map((item) => {
      if (item.login === infos.login) {
        return { ...item, haveMessages: false };
      } else {
        return item;
      }
    });
    setUsersInternalChat(usersFormated);
  }

  function getConversations() {
    api
      .get('conversations/')
      .then((response) => response)
      .catch((error) => console.log(error));
  }

  function toggleNewMessage(param) {
    dispatch(watchNewMessages(param));
  }

  const getUsers = () => {
    const Params = {
      page: 0,
      size: 0,
      showDisable: false,
      query: ''
    };

    UserService.getAllSeach(
      Params.page,
      Params.size,
      Params.query,
      Params.showDisable
    ).then((response) => {
      setUsers(response.data.data);
    });
  };

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    socketInternalChat.on('unread_messages', (response) => {
      setUnreadMessages(response.unread);
    });
  }, [socketInternalChat]);
  useEffect(() => {
    socketInternalChat.on('message', (response) => {
      listMessages.push(response);
    });
  }, [socketInternalChat, listMessages]);

  useEffect(() => {
    setUsersLogeds(dataChat.chats);
    getConversationsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataChat]);

  useEffect(() => {
    socketAdm.emit('lista_atendentes_logados');
    socketAdm.on('atendentes_logados', (data) => {
      dispatch(getListuser(data.atendentes));
    });
    getConversations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socketAdm]);

  const loadApiUsers = async () => {
    formatUsers(users);
  };

  async function getConversationsList() {
    await api
      .get(`conversations?memberId=${login}`)
      .then(({ data }) => {
        let conversations = data.conversations;
        let senders = [];
        conversations.forEach((item) => {
          let lastUpdate = item.lastMessage;
          item.members[0] === login
            ? (item.sender = item.members[1])
            : (item.sender = item.members[0]);
          if (item.lastMessage !== null) {
            item.updatedAt = lastUpdate.createdAt;
          }
          senders.push(item.sender);
          return item;
        });
        conversations.sort(function (a, b) {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        });
        setUserConversation(conversations);
        setSenders(senders);
      })
      .catch((error) => console.error('Erro no getConversation =>', error));
  }

  useEffect(() => {
    loadApiUsers(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userLogeds, unreadMessages, listMessages]);

  const formatUsers = (users) => {
    let ArrayVazio = [];

    users.forEach((item) => {
      let isLogged = false;
      let haveMessages = false;
      userLogeds.forEach((res) => {
        if (item.login == res.login) {
          isLogged = true;
        }
      });
      unreadMessages.forEach((res) => {
        if (item.login == res.login) {
          haveMessages = true;
        }
      });
      listMessages.forEach((res) => {
        if (item.login == res.from) {
          haveMessages = true;
        }
      });

      if (isLogged && haveMessages) {
        ArrayVazio.push({
          ...item,
          isLogged: true,
          haveMessages: true
        });
      } else if (isLogged && !haveMessages) {
        ArrayVazio.push({ ...item, isLogged: true, haveMessages: false });
      } else if (!isLogged && haveMessages) {
        ArrayVazio.push({
          ...item,
          isLogged: false,
          haveMessages: true
        });
      } else {
        ArrayVazio.push({ ...item, isLogged: false, haveMessages: false });
      }
    });

    const usersLoggedMessage = ArrayVazio.filter((item) => {
      return item.isLogged && item.haveMessages;
    });

    const usersNotLoggedMessage = ArrayVazio.filter((item) => {
      return !item.isLogged && item.haveMessages;
    });
    const usersLoggedNotMessage = ArrayVazio.filter((item) => {
      return item.isLogged && !item.haveMessages;
    });
    const usersNotLoggedNotMessage = ArrayVazio.filter((item) => {
      return !item.isLogged && !item.haveMessages;
    });

    setUsersInternalChat([
      ...usersLoggedMessage,
      ...usersNotLoggedMessage,
      ...usersLoggedNotMessage,
      ...usersNotLoggedNotMessage
    ]);
  };

  return (
    <Container>
      <BodyChat sizeChat={sizeChat}>
        <Header
          color={systemConfiguration.primary_color}
          fontColor={systemConfiguration.internal_chat_font_color}
        >
          {watchStateNewMessages ? (
            <button
              className="header"
              onClick={() => {
                toggleNewMessage(false);
              }}
            >
              Chat interno
            </button>
          ) : (
            <button
              className="header"
              onClick={() => {
                toggleNewMessage(true);
              }}
            >
              Chat interno
            </button>
          )}
        </Header>

        <UserListContainer
          sizeChat={watchStateNewMessages}
          handleInfosUserFunction={handleInfosUserFunction}
          updateConversation={updateConversation}
          users={usersInternalChat}
          color={systemConfiguration.primary_color}
          loading={loading}
          setLoading={setLoading}
          userConversation={userConversation}
          senders={senders}
        />
      </BodyChat>
    </Container>
  );
};
