import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import IssueService from '../service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const IssueForm = (props) => {
  const history = useHistory();
  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      setLoading(true);
      IssueService.get(id)
        .then((res) => {
          const getIssue = res.data;
          reset(getIssue);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch((err) => {
          //const { data } = err.response;
          toast.error(
            err.response.data.message || 'Erro ao carregar assunto!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
          setLoading(false);
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      const formatedData = { ...data };
      isUpdate
        ? await IssueService.update(id, formatedData)
        : await IssueService.create(formatedData);
      history.push('/admin/issue');
      toast.success(
        isUpdate
          ? 'Assunto alterado com sucesso!'
          : 'Assunto cadastrado com sucesso!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    } catch (err) {
      toast.error(err.response?.data?.message || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/issue');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/issue"
        buttonTitle="Voltar para a pagina de assunto"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Assunto</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="nome"
                            rules={{ required: 'Nome é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="nome"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Descrição*
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            rules={{ required: 'Descrição é obrigatório!' }}
                            name="descricao"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="descricao"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default IssueForm;
