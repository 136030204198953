import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import React from 'react';
import {
  individual_column_style,
  individual_header_style
} from 'assets/styles/table_custom_style';
import { toTrueOrFalse } from 'views/CRM/shared/utils/trueOrFalse';

export const AnalyticalOpportunityTime = ({ data }) => {
  const createDynamicColumns = () => {
    const columns = [];

    columns.push(
      {
        dataField: 'opportunityTitle',
        text: 'Título da Oportunidade',
        sort: true,
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'stageName',
        text: 'Fase da Oportunidade',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'time',
        text: 'Dias na fase',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'movedAtTime',
        text: 'Movimentado dentro do prazo',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (row) => {
          return toTrueOrFalse(row);
        }
      }
    );

    return [...columns];
  };

  const dynamicFunctionColumns = createDynamicColumns();

  return (
    <ToolkitProvider
      data={data || []}
      keyField="id"
      columns={dynamicFunctionColumns}
    >
      {(props) => (
        <div className="py-4 table-responsive">
          <BootstrapTable
            bootstrap4={true}
            bordered={false}
            classes="align-items-center"
            {...props.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};
