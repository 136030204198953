import styled from 'styled-components';

export const InputGroupWrapper = styled.div`
  @media (max-width: 765px) {
    width: 19.875rem;
  }
`;

export const UserNotLoggedIn = styled.div`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;

  p {
    display: block;
    padding: 0.5rem;
    font-weight: bold;
    font-size: 1.2rem;
  }
`;
