import { useState } from 'react';
import SubReasonService from '../../../subReason/service';
import { toast } from 'react-toastify';

export function useMultipleSubReason() {
  const [subreasons, setSubreasons] = useState([]);
  const [loadingSubreasons, setLoadingSubreasons] = useState(false);

  const handleGetSubReasons = (values) => {
    if (values.length!= 0) {
      setLoadingSubreasons(true);
      SubReasonService.getMultipleIds(values)
        .then((response) => {
          setSubreasons(response.data.content);
        })
        .catch((err) => {
          const { data } = err.response;
          toast.error(data.message, {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .finally(() => {
          setLoadingSubreasons(false);
        });
        
    } else {
      setSubreasons([]);
    }
    
  };

  return {
    subreasons,
    loadingSubreasons,
    handleGetSubReasons
  };
}
