import React from 'react';
import { generateFormattedDate } from './assets/generateFormattedDate';
import { toast } from 'react-toastify';
import { FaFileAlt } from 'react-icons/fa';
import {
  ClientTimestamp,
  ClientContent,
  ClientName,
  MessageContent,
  MessageText,
  Image,
  ImageContent,
  ContextImageContent,
  ContextMessageContent,
  Bar,
  ContextImage
} from './components/styled';
import './style.css';

const ClientMessage = ({
  message = '',
  title = '',
  time = '',
  name = '',
  type = 'text',
  source = '',
  context
}) => {
  const copyToClipBoard = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      toast.info(`Mensagem copiada!`, { autoClose: 1500 });
    } catch (err) {
      console.log('error copy => ', err);
      toast.error('Erro ao copiar mensagem!', { autoClose: 1500 });
    }
  };
  if (context) {
    console.log(context);
  }

  return (
    <ClientContent>
      {context ? (
        <ContextMessageContent>
          <Bar></Bar>
          <div>
            <ClientName>{context.escrita_por}:</ClientName>
            {context.response_type === 'text' ? (
              <div>{context.texto}</div>
            ) : null}
            {context.response_type === 'file' ? (
              <div
                className="messagePdf"
                onClick={() => window.open(context.source, '_blank')}
              >
                <FaFileAlt size="24" color="#6A5ACD" />
                <label style={{ cursor: 'pointer' }}>clique aqui</label>
              </div>
            ) : null}
            {context.response_type === 'image' && (
              <ContextImageContent style={{ textAling: 'start' }}>
                <ContextImage
                  style={{ maxWidht: '10%', maxHeight: '10%' }}
                  src={context.source}
                  alt={`imagem de ${name}`}
                  onClick={() => window.open(context.source, '_blank')}
                />
              </ContextImageContent>
            )}
            {context.response_type === 'audio' && (
              <ContextImageContent>
                <audio src={context.source} controls={true} autoPlay={false} />
              </ContextImageContent>
            )}
            {context.response_type === 'video' && (
              <ContextImageContent>
                <video
                  src={context.source}
                  autoPlay={false}
                  controls={true}
                  width="10%"
                  height="10%"
                />
              </ContextImageContent>
            )}
          </div>
        </ContextMessageContent>
      ) : null}
      <ClientName>{name}:</ClientName>
      <MessageContent>
        <MessageText onClick={() => copyToClipBoard(title || message)}>
          {title ||
            (message &&
              (type === 'link' ? (
                <u onClick={() => window.open(message, '_blank')}>{message}</u>
              ) : (
                message
              )))}
          {type === 'file' && (
            <div
              className="messagePdf"
              onClick={() => window.open(source, '_blank')}
            >
              <FaFileAlt size="24" color="#6A5ACD" />
              <label style={{ cursor: 'pointer' }}>clique aqui</label>
            </div>
            // {/* <embed className="fileBalon" src={source+'#page=1&zoom=20&toolbar=0'}></embed> */}
          )}
        </MessageText>
        {type === 'image' && (
          <ImageContent>
            <Image
              src={source}
              alt={`imagem de ${name}`}
              onClick={() => window.open(source, '_blank')}
            />
          </ImageContent>
        )}
        {type === 'audio' && (
          <ImageContent>
            <audio src={source} controls={true} autoPlay={false} />
          </ImageContent>
        )}
        {type === 'video' && (
          <ImageContent>
            <video
              src={source}
              autoPlay={false}
              controls={true}
              width="100%"
              height="300"
            />
          </ImageContent>
        )}
      </MessageContent>
      <ClientTimestamp>{time && generateFormattedDate(time)}</ClientTimestamp>
    </ClientContent>
  );
};

export default ClientMessage;
