export const spotStyle = (type) => {
  switch (type) {
    case 'aguardando_resposta':
      return {
        name: 'AGUARDANDO RESPOSTA',
        color: '#5cb85c',
        icon: 'far fa-bell',
        iconColor: 'white'
      };
    case 'em_atendimento':
      return {
        name: 'EM ATENDIMENTO',
        color: '#5cb85c',
        icon: 'far fa-bell',
        iconColor: 'white'
      };
    case 'transferida':
      return {
        name: 'TRANSFERIDA',
        color: '#f7f7f7',
        icon: 'fas fa-external-link-alt',
        iconColor: '#999999'
      };
    case 'atender':
      return {
        name: 'ATENDER',
        color: '#5bc0de',
        icon: 'fas fa-sign-in-alt',
        iconColor: 'white'
      };
    case 'encerrada':
      return {
        name: 'ENCERRADA',
        color: '#d9534f',
        icon: 'fas fa-check',
        iconColor: 'white'
      };
    case 'nao_atendida':
      return {
        name: 'NÃO ATENDIDA',
        color: '#d9534f',
        icon: 'fas fa-times',
        iconColor: 'white'
      };
    case 'abandonada':
      return {
        name: 'ABANDONADA',
        color: '#d9534f',
        icon: 'fas fa-times',
        iconColor: 'white'
      };
    case 'chamada_telefonica':
      return {
        name: 'CHAMADA TELEFÔNICA',
        color: '#5bc0de',
        icon: 'fas fa-phone',
        iconColor: 'white'
      };

    default:
      return {
        name: 'INDEFINIDO',
        color: '#f7f7f7',
        icon: 'fas fa-question',
        iconColor: '#999999'
      };
  }
};
