import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { VoiceProtocolModal } from './components/VoiceProtocolModal';

export const VoiceProtocol = ({ telefone, recordTypes, disabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        disabled={disabled}
        onClick={() => {
          toggleModal();
        }}
        style={{ width: '33px', height: '28px' }}
        color="success"
        size="sm"
      >
        <li className="fas fa-microphone"></li>
      </Button>
      {isModalOpen ? (
        <VoiceProtocolModal
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          telefone={telefone}
          recordTypes={recordTypes}
        />
      ) : null}
    </>
  );
};
