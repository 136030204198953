/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Service from '../service';
import { toast } from 'react-toastify';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'components/Pagination';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  Input,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

import SimpleHeader from '../../../components/Headers/SimpleHeader';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const FacilityRouteList = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const [facilityRoute, setFacilityRoute] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    handleGetExtensions();
  }, []);

  const handleGetExtensions = (selectedPage = 1, querySearch = '') => {
    Service.getAll(false, selectedPage, 10, false, querySearch)
      .then((res) => {
        const { data, totalRegisters } = res.data;
        const newActivePage = selectedPage || activePage;

        setFacilityRoute(data);
        setQuantity(totalRegisters);
        setActivePage(newActivePage);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Não foi possível listar as rotas de facilidade', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const handlePageChange = (activePage) => handleGetExtensions(activePage);

  const handleUpdate = ({ id }) => {
    history.push(`facilityRoute/form/${id}`);
  };

  const handleOnSearch = (event) => {
    const q = event.target.value;
    setTimeout(() => {
      handleGetExtensions(1, q);
    }, 500);
  };

  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Rotas de Facilidade</h3>
                </CardHeader>

                <Row className="mt-3 mx-2">
                  <Col md="11">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Buscar rota de facilidade por nome"
                        type="text"
                        defaultValue=""
                        onChange={handleOnSearch}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <ToolkitProvider
                  data={facilityRoute}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'route',
                      text: 'Rota',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },

                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="info"
                            size="sm"
                            disabled={!row.change}
                            onClick={() => handleUpdate(row)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default FacilityRouteList;
