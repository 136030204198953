import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import ConversationDetails from '../ConversationDetails';

import {
  individual_column_style,
  individual_header_style
} from '../../../../../../assets/styles/table_custom_style';
import { Card, Row } from 'reactstrap';
import { TotalRegister } from 'components/TotalRegister';

const Table = ({ data, totalRegister }) => {
  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            <TotalRegister totalRegister={totalRegister} />
            <Card>
              <ToolkitProvider
                data={data}
                keyField="mensagem_id"
                columns={[
                  {
                    dataField: 'mensagem',
                    text: 'Mensagem',
                    sort: true,
                    style: {
                      whiteSpace: 'break-spaces',
                      wordBreak: 'break-all',
                      width: '90%'
                    },
                    headerStyle: {
                      ...individual_header_style,
                      cursor: 'pointer'
                    }
                  },
                  {
                    dataField: 'score',
                    text: 'Score',
                    align: 'center',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      ...individual_header_style,
                      cursor: 'pointer'
                    }
                  },
                  {
                    dataField: 'intencao',
                    text: 'Intenção',
                    align: 'center',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      whiteSpace: 'break-spaces',
                      cursor: 'pointer',
                      textAlign: 'center'
                    }
                  },
                  {
                    dataField: 'hora',
                    text: 'Data e Hora',
                    align: 'center',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      whiteSpace: 'break-spaces',
                      textAlign: 'center',
                      cursor: 'pointer'
                    },
                    formatter: (row) => {
                      const date = new Date(row);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'nameCostCenter',
                    text: 'Conversa',
                    align: 'center',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: {
                      whiteSpace: 'break-spaces',
                      cursor: 'pointer'
                    },
                    // eslint-disable-next-line react/display-name
                    formatter: (_, row) => (
                      <>
                        <ConversationDetails conversationData={row} />
                      </>
                    )
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Table;
