import ServiceCRUD from '../../../../services/ServiceCRUDFLEXUC';
import { apiUc } from '../../../../http';

class Service extends ServiceCRUD {
  constructor() {
    super('/api/qa/assessment');
  }

  getAssessmentAnalytics({ dateBegin, dateEnd, size }, pagina) {
    return apiUc.get(this.path, {
      params: {
        dateBegin,
        dateEnd,
        limit: size,
        offset: size * (pagina - 1)
      }
    });
  }

  getAssessmentAnswers(id) {
    return apiUc.get(`${this.path}/${id}`);
  }

  getAssessmentsByCdrReport(id) {
    return apiUc.get(`${this.path}s/by_cdr_report/${id}`);
  }
}

export default new Service();
