import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FiClock } from 'react-icons/fi';

import TimelineOpportunityHistory from '../TimelineOpportunityHistory';

const OpportunityHistoryModal = (props) => {
  const { opportunityId, convertOpportunityId, className = '' } = props;
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Button style={props.style} color="success" onClick={toggle} size="sm">
        <FiClock size={17} />
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={`modal-lg ${className}`}>
        <ModalHeader toggle={toggle}>Histórico da oportunidade</ModalHeader>
        <ModalBody>
          <TimelineOpportunityHistory
            opportunityId={opportunityId}
            convertOpportunityId={convertOpportunityId}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OpportunityHistoryModal;
