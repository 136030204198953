import React from 'react';

import { Row, Card, Col, ListGroupItem, ListGroup, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { formatDateForAudioDownloads } from '../../../../../utils/fuctions/formattedDateForAudioDownloads';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import ReportService from '../service';
import { TotalRegister } from '../../../../../components/TotalRegister';
import * as fileSaver from 'file-saver';
import { AudioStreamReportTelephony as AudioStream } from '../../../../../components/AudioController/AudioStream';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';
import { DownloadButton } from '../components/DownloadButton';

const itemStyle = { padding: '5px 0 0 20px' };

const analitical = (props) => {
  let isDownloadAndListen = true;
  if (props.isDownloadAndListen === false) {
    isDownloadAndListen = props.isDownloadAndListen;
  }
  const isDownload = () => {
    if (isDownloadAndListen) {
      return props.needPermission
        ? [
            verifyRoles(['DOWNLOAD_CALLS']) && {
              dataField: 'download',
              text: 'Download',
              sort: true,
              style: individual_column_style,
              headerStyle: individual_header_style,
              // eslint-disable-next-line react/display-name
              formatter: (_, row) => {
                return <DownloadButton row={row}></DownloadButton>;
              }
            },
            verifyRoles(['LISTEN_CALLS']) && {
              dataField: 'listen',
              text: 'Escutar',
              sort: true,
              style: individual_column_style,
              headerStyle: individual_header_style,

              // eslint-disable-next-line react/display-name
              formatter: (_, row) => {
                return (
                  <AudioStream
                    ReportService={ReportService}
                    uniqueid={row.uniqueid}
                    disabled={row.disposition === 'ANSWERED' ? false : true}
                  />
                );
              }
            }
          ]
        : [
            {
              dataField: 'download',
              text: 'Download',
              sort: true,
              style: individual_column_style,
              headerStyle: individual_header_style,
              // eslint-disable-next-line react/display-name
              formatter: (_, row) => {
                return <DownloadButton row={row}></DownloadButton>;
              }
            },
            {
              dataField: 'listen',
              text: 'Escutar',
              sort: true,
              style: individual_column_style,
              headerStyle: individual_header_style,

              // eslint-disable-next-line react/display-name
              formatter: (_, row) => {
                return (
                  <AudioStream
                    ReportService={ReportService}
                    uniqueid={row.uniqueid}
                    disabled={row.disposition === 'ANSWERED' ? false : true}
                  />
                );
              }
            }
          ];
    }
    return [{}];
  };

  return (
    <>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            {props.quantity >= 0 && (
              <TotalRegister totalRegister={props.quantity} />
            )}
            <Card>
              <ToolkitProvider
                data={props.showReports}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'calldateStart',
                    text: 'Data início',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      const date = new Date(Row);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'clidNum',
                    text: 'origem',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'dst',
                    text: 'Destino',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'nameAgent',
                    text: 'Nome do agente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'ddr',
                    text: 'DDR',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'queue',
                    text: 'Fila',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'centroDeCusto',
                    text: 'Setor',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'carrier',
                    text: 'Operadora',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'dispositionFormated',
                    text: 'Status da chamada',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'transferIn',
                    text: 'Recebeu transferencia de',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'transferOut',
                    text: 'Transferiu para',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeCallFormated',
                    text: 'Tempo em ligação',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeWaitingCallFormated',
                    text: 'Tempo em espera',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'amdStatus',
                    text: 'AMD status',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'hangupCauseDescription',
                    text: 'Causa Desligamentos',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },

                  //trabalhar nesses dois pois ambos são audios
                  ...isDownload()
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
              {props.quantity > 0 && (
                <Row className="mb-3 mt-3">
                  <Col md="3">
                    <ListGroup flush>
                      <ListGroupItem disabled style={itemStyle}>
                        <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          Chamadas atendidas:
                        </span>{' '}
                        {props.extraDataFromAnalyticalReport.quantityCallAnswer}
                      </ListGroupItem>
                      <ListGroupItem disabled style={itemStyle}>
                        <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          Chamadas não atendidas:
                        </span>{' '}
                        {
                          props.extraDataFromAnalyticalReport
                            .quantityCallNoAnswer
                        }
                      </ListGroupItem>
                      <ListGroupItem disabled style={itemStyle}>
                        <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          Tempo médio de Serviço:
                        </span>{' '}
                        {
                          props.extraDataFromAnalyticalReport
                            .averageServiceTimeFormated
                        }
                      </ListGroupItem>
                      <ListGroupItem disabled style={itemStyle}>
                        <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                          Tempo médio de Espera:
                        </span>{' '}
                        {
                          props.extraDataFromAnalyticalReport
                            .waitingServiceTimeFormated
                        }
                      </ListGroupItem>
                    </ListGroup>
                  </Col>
                </Row>
              )}
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default analitical;
