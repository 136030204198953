import { apiCrm } from '../../../../http';

class MapCustomFieldService {
  getMappedCustomFields() {
    return apiCrm.get('map-custom-field');
  }

  createMapCustomFields(data) {
    return apiCrm.post('map-custom-field', data);
  }
}

export default new MapCustomFieldService();
