import ServiceCRUD from '../../../services/ServiceCRUDFLEXUC';
import { apiUc as http } from '../../../http/index';

class ExtensionService extends ServiceCRUD {
  constructor() {
    super('/api/ramais');
  }

  createExtensionRange(data) {
    return http.post(`/api/ramais-range`, data);
  }

  updateExtensionSet(data) {
    return http.put(`/api/ramais-range`, data);
  }

  getAllShowDisableNew = (showDisable) => {
    return http.get(`${this.path}/all?showDisable=${showDisable}`);
  };
}

export default new ExtensionService();
