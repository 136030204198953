import styled from 'styled-components';

export const Preview = styled.div`
  display: block;
  max-width: 380px;
  max-height: auto;
  justify-content: center;
  text-align: center;
  border-radius: 7px;
  box-shadow: -6px 6px 18px #ebebeb, 6px -6px 18px #ffffff;
  padding-top: 5px;

  .fileBalon {
    border-radius: 10px;
    height: 287px;
    width: 380px;
  }

  object {
    height: 230px;
  }

  img {
    width: 95%;
  }
`;

export const TitlePreview = styled.div`
  display: block;
  max-height: auto;
  text-align: center;
  margin-bottom: 1rem;
  max-width: 9%%;
  h4 {
    display: block;
    padding-top: 0.8rem;
    color: ${(props) => props.colorText};
  }
`;
