import styled from 'styled-components';

export const AsideCardListWrapper = styled.div`
  background-color: ${(props) => (props.isDragging ? '#c1cbdb' : '#fff')};
  transition: all 0.5s ease-in-out;
`;

export const AsideCardContent = styled.div`
  transform: ${(props) => (props.isDragging ? 'rotate(6deg)' : 'rotate(0deg)')};
  animation: ${(props) => (props.isDragging ? 'rotate infinite' : 'none')} ease
    1s;
  transition: all 0.3s ease-in-out;

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(6deg);
    }
    75% {
      transform: rotate(-6deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
`;
