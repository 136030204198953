import React, { useMemo, createContext, useContext, useEffect } from 'react';
import io from 'socket.io-client';
import store from '../store';
import {
  addInitUnAnsweredConversation,
  addMessageUnansweredConversation,
  addUnAnsweredConversation,
  removeUnAnsweredConversation
} from '../store/action/unanswered-convertations-action';
import {
  addInitAnsweredConversation,
  removeAnsweredConversation,
  addMessageAnsweredConversation,
  addAnsweredConversation
} from '../store/action/answered-conversations-action';
import {
  addMessageConversationInAttendance,
  checkRemoveConversationInAttendance,
  deleteMessageConversationInAttendance,
  updateStatusMessageConversationInAttendance,
  updateWhatsappMessageIdConversationInAttendance
} from '../store/action/conversation-in-attendance-action';
import { toast } from 'react-toastify';

const useSocketConversationInstagramContext = createContext();

export function ConversationInstagramConnectionSocketForMonitoringProvider({
  children
}) {
  const socketInstagramConversation = useMemo(
    () =>
      io(process.env.REACT_APP_PROXY_URL, {
        path: '/flex-chat-integration-socket',
        transports: ['websocket'],
        upgrade: false,
        query: { token: localStorage.getItem('token') }
      }),
    []
  );

  useEffect(() => {
    return () => {
      socketInstagramConversation.disconnect();
    };
  }, [socketInstagramConversation]);

  useEffect(() => {
    socketInstagramConversation.on(
      'conversa_transferida_pelo_bot_para_atendente',
      async (data) => {
        await store.dispatch(addAnsweredConversation(data.conversa));
      }
    );
  }, [socketInstagramConversation]);

  useEffect(() => {
    socketInstagramConversation.on('cliente_enviou_mensagem', async (data) => {
      data.mensagem = { hora_da_mensagem: new Date(), ...data.mensagem };

      await store.dispatch(
        addMessageConversationInAttendance(data.mensagem, data.idDaConversa)
      );
      await store.dispatch(
        addMessageAnsweredConversation(
          data.mensagem,
          data.idDaConversa,
          data.novas_mensagens
        )
      );

      await store.dispatch(
        addMessageUnansweredConversation(
          data.mensagem,
          data.idDaConversa,
          data.novas_mensagens
        )
      );
    });
  }, [socketInstagramConversation]);

  useEffect(() => {
    socketInstagramConversation.on('change_message_whatsapp_id', (payload) => {
      store.dispatch(updateWhatsappMessageIdConversationInAttendance(payload));
    });
  }, [socketInstagramConversation]);

  useEffect(() => {
    socketInstagramConversation.on(
      'evento_de_mensagem',
      async (eventMessage) => {
        await store.dispatch(
          updateStatusMessageConversationInAttendance(eventMessage)
        );
      }
    );
  }, [socketInstagramConversation]);

  useEffect(() => {
    socketInstagramConversation.on('delete_message_from_chat', async (data) => {
      await store.dispatch(deleteMessageConversationInAttendance(data));
    });
  }, [socketInstagramConversation]);

  useEffect(() => {
    socketInstagramConversation.on('entrou_na_fila', (data) => {
      const attendantQueues = JSON.parse(localStorage.getItem('queues'));
      //console.log('########## entrou_na_fila ########## ', data.conversa);
      attendantQueues.forEach(async (attendantQueue) => {
        if (data.conversa?.fila && data.conversa.fila === attendantQueue.name) {
          await store.dispatch(addUnAnsweredConversation(data.conversa));
        }
      });
    });
  }, [socketInstagramConversation]);

  useEffect(() => {
    //Alteração feita para o sussurro
    socketInstagramConversation.on('adm_enviou_sussurro', async (data) => {
      data.mensagem = { hora_da_mensagem: new Date(), ...data.mensagem };
      await store.dispatch(
        addMessageConversationInAttendance(data.mensagem, data.idDaConversa)
      );
      //updating list of answeredConversations in store
      await store.dispatch(
        addMessageAnsweredConversation(
          data.mensagem,
          data.idDaConversa,
          data.novas_mensagens
        )
      );
    });
  }, [socketInstagramConversation]);

  useEffect(() => {
    socketInstagramConversation.on('conversa_transferida', (data) => {
      let idDaConversa = data.id ?? data._id;
      store.dispatch(removeAnsweredConversation(data));
      store.dispatch(checkRemoveConversationInAttendance(idDaConversa));
    });
  }, [socketInstagramConversation]);

  useEffect(() => {
    socketInstagramConversation.on('conversa_encerrada', async (data) => {
      let idDaConversa = data.id ?? data._id;
      await store.dispatch(removeAnsweredConversation(idDaConversa));
      await store.dispatch(checkRemoveConversationInAttendance(idDaConversa));
    });
  }, [socketInstagramConversation]);

  useEffect(() => {
    socketInstagramConversation.on('remove_conversa_fila', async (data) => {
      await store.dispatch(removeUnAnsweredConversation(data));
    });
  }, [socketInstagramConversation]);

  useEffect(() => {
    socketInstagramConversation.on('show_popup_to_agent', async (data) => {
      if (data.message) toast.error(data.message);
    });
  }, [socketInstagramConversation]);

  return (
    <>
      <useSocketConversationInstagramContext.Provider
        value={{
          socketInstagramConversation
        }}
      >
        {children}
      </useSocketConversationInstagramContext.Provider>
    </>
  );
}

export function useSocketConversationInstagram() {
  const context = useContext(useSocketConversationInstagramContext);
  return context;
}
