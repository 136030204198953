import { inboundRouteTypes } from './inboundRouteTypes';

export default [
  { value: inboundRouteTypes.IVR, displayName: 'URA' },
  { value: inboundRouteTypes.AUDIO, displayName: 'Áudio' },
  { value: inboundRouteTypes.CALLBACK, displayName: 'Callback' },
  { value: inboundRouteTypes.QUEUE, displayName: 'Fila' },
  { value: inboundRouteTypes.EXTENSION, displayName: 'Ramal' },
  { value: inboundRouteTypes.GENERIC_EXTENSION, displayName: 'Ramal Genérico' },
  { value: inboundRouteTypes.CUSTOMIZED, displayName: 'Customizado' }
];
