/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import QueueMessageService from '../service';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Pagination from 'components/Pagination';
import ModalConfirmation from '../../../components/Modal/ConfirmationModal';

import {
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  CardBody,
  Badge
  // Col,
  // InputGroup,
  // InputGroupAddon,
  // Input,
  // InputGroupText
} from 'reactstrap';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const QueueMessageList = () => {
  const [queueMessages, setQueueMessages] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [idResource, setidResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  // const [searchQueueMessages, setSearchQueueMessages] = useState();

  const getQueueMessages = (selectedPage = 1, search = '') => {
    setLoading(true);
    //QueueMessageService.getAllFilter(selectedPage, search)
    QueueMessageService.getAll(selectedPage, search)
      .then((res) => {
        const queueMessages = res.data;
        const quantity = res.length;
        const newActivePage = selectedPage || activePage;
        setQueueMessages(queueMessages);
        setQuantity(quantity);
        setActivePage(newActivePage);
        setLoading(false);
      })
      .catch((err) => {
        //const { data } = error.response;
        toast.error(err.response.data.message || 'Erro ao listar mensagens!', {
          autoClose: 3000,
          closeOnClick: true
        });
        setLoading(false);
      });
  };

  useEffect(getQueueMessages, []);

  const handlePageChange = (activePage) => getQueueMessages(activePage);

  const deleteQueueMessage = (id) => {
    QueueMessageService.delete(id)
      .then(() => {
        getQueueMessages();
        toast.success('Mensagem de fila deletada!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .catch((err) => {
        //const { data } = error.response;
        toast.error(
          err.response.data.message || 'Erro ao deletar mensagem de fila!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        getQueueMessages();
      });
  };

  // const getQueueMessagesSearch = (event, selectedPage = 1) => {
  //   setSearchQueueMessages(event.target.value);
  //   if (searchQueueMessages) {
  //     getQueueMessages(selectedPage, event.target.value);
  //   }
  // };

  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };
  return (
    <>
      <SimpleHeader
        setCreate="/admin/queuemessage/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Mensagem de fila</h3>
                </CardHeader>
                <CardBody>
                  {/* <small className="text text-muted font-weight-bold">
                    Buscar setor
                  </small> */}
                  {/* <Row>
                    <Col md="11">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Mensagem"
                          type="text"
                          onChange={(event) => getQueueMessagesSearch(event)}
                        />
                      </InputGroup>
                    </Col>
                  </Row> */}
                </CardBody>
                <ToolkitProvider
                  data={queueMessages}
                  keyField="_id"
                  columns={[
                    {
                      dataField: 'title',
                      text: 'Título',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'texto',
                      text: 'Texto',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'response_type',
                      text: 'Response Type',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'filas',
                      style: icon_column_style,
                      headerStyle: individual_header_style,
                      text: 'Filas',
                      sort: true,
                      formatter: (rowContent, row) => {
                        return rowContent.map((queue, index) => {
                          return (
                            <Badge key={index} color="info">
                              {queue}
                            </Badge>
                          );
                        });
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link to={'/admin/queuemessage/form/' + row._id}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            onClick={() => controlDelete(row._id)}
                            size="sm"
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={deleteQueueMessage}
        message={'Tem certeza que deseja excluir a Mensagem de fila?'}
      />
    </>
  );
};

export default QueueMessageList;
