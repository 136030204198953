import React from 'react';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Row,
  Card,
  Col,
  Input,
  CardHeader,
  Button,
  CardBody,
  Media
} from 'reactstrap';

const ProfileForm = () => {
  const history = useHistory();

  const cancelAction = () => {
    history.push('/admin');
  };

  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Perfil2</h3>
                </CardHeader>
                <CardBody>
                  <Row style={{ padding: '40px 0px 80px 2%' }}>
                    <Media>
                      <span
                        className="avatar rounded-circle"
                        style={{ width: '150px' }}
                      >
                        <img
                          alt="..."
                          src={require('assets/img/theme/team-4.jpg')}
                        />
                      </span>
                    </Media>
                  </Row>
                  <Row>
                    <Col md="5">
                      <label className="form-control-label">Nome</label>
                      <Input
                        disabled={true}
                        defaultValue={localStorage.getItem('name')}
                      />
                    </Col>
                    <Col md={{ size: 6, offset: 1 }}>
                      <label className="form-control-label">Email</label>
                      <Input
                        disabled={true}
                        defaultValue={localStorage.getItem('email')}
                      />
                    </Col>
                  </Row>
                  <hr />
                  <Button color="danger" onClick={() => cancelAction()}>
                    Cancelar
                  </Button>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ProfileForm;
