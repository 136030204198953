export const useCalculateStatisticalData = () => {
  const getTME = (reportData) => {
    const calls =
      reportData && reportData.listCallDetailedSyntheticCallCenterDTO;

    if (!calls || calls.length === 0) return '00:00:00';
    if (calls.length === 1) return calls[0].tme || '00:00:00';

    const filteredCalls = calls.filter((call) => !!call.tme);

    const phoneCallsWaitTime = [];

    filteredCalls.forEach((call) => {
      const [hours, minutes, seconds] = call.tme.split(':');
      const hourInSeconds = Number.parseInt(hours, 10) * 3600;
      const minuteInSeconds = Number.parseInt(minutes, 10) * 60;
      const secondAsInteger = Number.parseInt(seconds, 10);
      phoneCallsWaitTime.push(
        (hourInSeconds + minuteInSeconds + secondAsInteger) * call.answered
      );
    });

    const sum = phoneCallsWaitTime.reduce((prev, curr) => prev + curr, 0);
    const totalAnsweredCalls = filteredCalls.reduce(
      (prev, curr) => prev + curr.answered,
      0
    );
    const average = Math.round(sum / totalAnsweredCalls);

    return millisecondsToHHmmss(average);
  };

  const getTMA = (reportData) => {
    const calls =
      reportData && reportData.listCallDetailedSyntheticCallCenterDTO;

    if (!calls || calls.length === 0) return '00:00:00';
    if (calls.length === 1) return calls[0].tma || '00:00:00';

    const filteredCalls = calls.filter((call) => !!call.tma);

    const phoneCallsDuration = [];

    filteredCalls.forEach((call) => {
      const [hours, minutes, seconds] = call.tma.split(':');
      const hourInSeconds = Number.parseInt(hours, 10) * 3600;
      const minuteInSeconds = Number.parseInt(minutes, 10) * 60;
      const secondAsInteger = Number.parseInt(seconds, 10);
      phoneCallsDuration.push(
        (hourInSeconds + minuteInSeconds + secondAsInteger) * call.answered
      );
    });

    const sum = phoneCallsDuration.reduce((prev, curr) => prev + curr, 0);
    const totalAnsweredCalls = filteredCalls.reduce(
      (prev, curr) => prev + curr.answered,
      0
    );
    const average = Math.round(sum / totalAnsweredCalls);

    return millisecondsToHHmmss(average);
  };

  const getLongestWaitTime = (reportData) => {
    const calls = reportData && reportData.listCallInAnalyticalDTO;

    if (!calls || calls.length === 0) return '00:00:00';
    if (calls.length === 1)
      return calls[0].timeWaitingCallFormated || '00:00:00';

    const callsWatingTime = calls.map((call) => ({
      timeWaitingCall: call.timeWaitingCall,
      timeWaitingCallFormated: call.timeWaitingCallFormated
    }));

    const longestWaitTime = callsWatingTime.reduce(
      (acc, curr) => {
        return acc.timeWaitingCall > curr.timeWaitingCall ? acc : curr;
      },
      { timeWaitingCall: 0, timeWaitingCallFormated: '00:00:00' }
    );

    return longestWaitTime.timeWaitingCallFormated;
  };

  const getAnsweredCalls = (reportData) => {
    const calls =
      reportData && reportData.listCallDetailedSyntheticCallCenterDTO;

    if (!calls || calls.length === 0) return 0;
    if (calls.length === 1) return calls[0].answered || 0;

    return calls
      .map((call) => call.answered)
      .reduce((acc, current) => {
        return Number(acc) + Number(current);
      }, 0);
  };

  const getAbandonedCalls = (reportData) => {
    const calls =
      reportData && reportData.listCallDetailedSyntheticCallCenterDTO;

    if (!calls || calls.length === 0) return 0;
    if (calls.length === 1) return calls[0].abandoned || 0;

    return calls
      .map((call) => call.abandoned)
      .reduce((acc, current) => {
        return Number(acc) + Number(current);
      }, 0);
  };

  const getPercentageOfAnsweredAndAbandonedCalls = (reportData) => {
    const totalAnswered = getAnsweredCalls(reportData);
    const totalAbandoned = getAbandonedCalls(reportData);

    let answeredPercentage = 0;
    let abandonedPercentage = 0;

    const totalCalls = totalAnswered + totalAbandoned;
    if (totalCalls > 0) {
      abandonedPercentage = Math.ceil((totalAbandoned / totalCalls) * 100);
      answeredPercentage = 100 - abandonedPercentage;
    }

    return {
      answeredPercentage,
      abandonedPercentage
    };
  };

  const millisecondsToHHmmss = (milliseconds) => {
    function getFomattedDigit(digit) {
      return `${String(digit).padStart(2, '0')}`;
    }

    const hours = Math.floor(milliseconds / (60 * 60));
    milliseconds -= hours * 60 * 60;

    const minutes = Math.floor(milliseconds / 60);
    milliseconds -= minutes * 60;

    const seconds = Math.floor(milliseconds);

    return `${getFomattedDigit(hours)}:${getFomattedDigit(
      minutes
    )}:${getFomattedDigit(seconds)}`;
  };

  return {
    getTME,
    getTMA,
    getLongestWaitTime,
    getAnsweredCalls,
    getAbandonedCalls,
    getPercentageOfAnsweredAndAbandonedCalls
  };
};
