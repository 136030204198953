import ServiceChat from '../../../../../../../services/ServiceCRUDFLEXCHAT';
import { apiChat } from '../../../../../../../http/index';
import FlexChatIntegration from './FlexChatIntegration';
class ContactService extends ServiceChat {
  constructor() {
    super('contatos');
  }

  getContacts(searchParams = {}) {
    return apiChat.get('/contatos', {
      params: searchParams
    });
  }

  getContactByClientId(clientId) {
    return apiChat.get(`/contatos/${clientId}`);
  }

  wideSearch(params) {
    const usrLogin = localStorage.getItem('login');
    return apiChat.get('/contatos/wideSearch', {
      params,
      usrLogin
    });
  }

  getTemplates(login) {
    return apiChat.get('/templates', { params: { login } });
  }

  getTemplateById(id) {
    return apiChat.get(`/template/${id}`);
  }

  startActiveContactConversation(
    contactId,
    usrSocketId,
    messageData,
    queue,
    clientId,
    idSourcePhone = ''
  ) {
    return apiChat.post('/startActiveContact', {
      contactId,
      usrSocketId,
      messageData: {
        id: messageData.id,
        message: messageData.message,
        params: messageData.params
      },
      queue: queue,
      clientId,
      idSourcePhone,
      userLogin: localStorage.getItem('login')
    });
  }

  startActiveContactConversationMedia(formatedImage) {
    return apiChat.post('/startActiveContactMidia', formatedImage, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  sendIsolatedMessage(data) {
    return apiChat.post('/sendIsolatedMessage', data);
  }

  async getStatusClientSession(clientNumber) {
    const uri = `/clientStatus/${clientNumber}`;
    const standardSizePhoneWithNine = 13;

    let response = await apiChat.get(uri);

    if (!response.data && clientNumber.length === standardSizePhoneWithNine) {
      const clientNumberWithoutNine =
        clientNumber.substr(0, 4) + clientNumber.substr(5, 13);
      response = await FlexChatIntegration.getStatusClientSession(
        clientNumberWithoutNine
      );
    }

    return response.data ? true : false;
  }

  deleteContact(contactId) {
    return apiChat.delete(`/contatos/${contactId}`);
  }

  // get Synchronized templates
  getSynchronizedTemplates() {
    return apiChat.get(`/templates/synchronize`);
  }
}

export default new ContactService();
