export function generateFormattedDate(date) {
  const newDate = new Date(date);
  const formattedDate = `
    ${newDate.toLocaleDateString('pt-br')} - 
    ${newDate.getHours()}:${
    newDate.getMinutes() < 10 ? '0' : ''
  }${newDate.getMinutes()}`;

  return formattedDate;
}
