export const fileToBase64 = (file) => {
  return new Promise((resolve) => {
    var reader = new FileReader();
    // Ler o conteudo do arquivo
    reader.onload = function (event) {
      resolve(event.target.result);
    };

    // Converte dado para base64
    reader.readAsDataURL(file);
  });
};
