import { useCallback } from 'react';
import ProtocolService from '../../../service';
import pngIcon from 'assets/img/icons/fileExtensions/png.png';
import pdfIcon from 'assets/img/icons/fileExtensions/pdf.png';
import docIcon from 'assets/img/icons/fileExtensions/doc.png';
import docxIcon from 'assets/img/icons/fileExtensions/docx.png';
import jpgIcon from 'assets/img/icons/fileExtensions/jpg.png';
import jpegIcon from 'assets/img/icons/fileExtensions/jpeg.png';
import genericIcon from 'assets/img/icons/fileExtensions/generic.png';

const fileExtensions = new Map([
  ['png', pngIcon],
  ['pdf', pdfIcon],
  ['doc', docIcon],
  ['docx', docxIcon],
  ['jpg', jpgIcon],
  ['jpeg', jpegIcon]
]);

const getIconFromExtension = (extension) => {
  return fileExtensions.get(extension) || genericIcon;
};

export const useProtocolAttachments = (protocolId) => {
  const getFileURL = useCallback((path) => {
    return `${process.env.REACT_APP_PROXY_URL}${path}`;
  }, []);

  const removeAttachmentFromProtocol = useCallback(
    async (attachmentId) => {
      try {
        await ProtocolService.removeAttachment(protocolId, attachmentId);
        return true;
      } catch (error) {
        return false;
      }
    },
    [protocolId]
  );

  const addAttachmentsToProtocol = useCallback(
    async (formData) => {
      const data = await ProtocolService.addAttachments(protocolId, formData);
      return data.data.content;
    },
    [protocolId]
  );

  const getFileExtensionFromName = useCallback((fileName) => {
    return fileName.split('.').pop();
  }, []);

  const getFileNameFormatted = useCallback((fileName = '') => {
    return fileName.substring(13);
  }, []);

  const getIconFromFileExtension = useCallback(
    (fileName) => {
      const extension = getFileExtensionFromName(fileName);
      return getIconFromExtension(extension);
    },
    [getFileExtensionFromName]
  );

  return {
    getFileURL,
    removeAttachmentFromProtocol,
    addAttachmentsToProtocol,
    getFileExtensionFromName,
    getIconFromFileExtension,
    getFileNameFormatted
  };
};
