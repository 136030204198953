import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Col, Form, FormGroup, Input, Label, Row } from 'reactstrap';

import ReactButton from 'components/Buttons/ReactButton';

function RecordTypeSelection({ recordTypes = [], handleOnSelectedRecordType }) {
  const { handleSubmit } = useForm();
  const [selectedRecordType, setSelectedRecordType] = useState(null);

  useEffect(() => {
    if (recordTypes && recordTypes.length) {
      setSelectedRecordType(recordTypes[0].id);
    }
  }, [recordTypes]);

  const onSelectionChange = (event) => {
    const value = event.target.value;
    setSelectedRecordType(value);
  };

  const onSubmit = () => {
    if (!selectedRecordType) return;
    handleOnSelectedRecordType(selectedRecordType);
  };

  return (
    <>
      <h1 className="mb-0 pb-0">Tipos de registro</h1>
      <h2 style={{ fontSize: '0.8rem', marginBottom: '0.6rem' }}>
        Selecione algum dos tipos de registro abaixo
      </h2>
      <Form
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
      >
        <Row>
          <Col sm="12">
            <FormGroup tag="fieldset">
              {recordTypes &&
                recordTypes.map((recordType, index) => (
                  <FormGroup check key={recordType.id}>
                    <Label check>
                      <Input
                        type="radio"
                        name="selectedRecordType"
                        value={recordType.id}
                        defaultChecked={index === 0}
                        onChange={onSelectionChange}
                      />{' '}
                      {recordType.name}
                    </Label>
                  </FormGroup>
                ))}
            </FormGroup>
          </Col>
          <Col sm="12">
            {recordTypes && (
              <>
                <ReactButton
                  btnColor="confirmation"
                  type="submit"
                  disabled={!selectedRecordType}
                >
                  Selecionar
                </ReactButton>
              </>
            )}
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default RecordTypeSelection;
