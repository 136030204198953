import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import ExtensionService from '../service';
import ContextService from '../../Context/service';
import CostCenterService from '../service/centr_de_custo';
import PJSIPConfiguration from '../service/pjsip_configuration';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory, useLocation } from 'react-router-dom';
import ReactButton from 'components/Buttons/ReactButton';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody,
  FormGroup,
  Label
} from 'reactstrap';
import { ScreenPrompt } from 'components/ScreenPrompt';

const FormPjsip = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { type, actionType } = location.state;
  const { id } = props.match.params;

  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;
  const [isUpdate, setIsUpdate] = useState(false);
  const [isExtensionRange, setIsExtensionRange] = useState(false);

  const [contexts, setContexts] = useState([]);
  const [costsCenter, setCostsCenter] = useState([]);
  const [pjsipConfiguration, setPjsipConfiguration] = useState([]);

  // Get Contexts to select
  useEffect(() => {
    ContextService.getAll()
      .then((response) => {
        setContexts(response.data.data);
      })
      .catch(() => {
        console.log('Erro ao ler os contextos');
      });
  }, []);

  // Get costs center to select
  useEffect(() => {
    CostCenterService.getAll()
      .then((response) => {
        setCostsCenter(response.data.data);
      })
      .catch(() => {
        console.log('Erro ao ler os centros de custo');
      });
  }, []);

  // Get PJSIP configurations to select
  useEffect(() => {
    if (type === 'PJSIP') {
      PJSIPConfiguration.getAll()
        .then((response) => {
          setPjsipConfiguration(response.data.data);
        })
        .catch(() => {
          console.log('Erro ao ler configurações do PJSIP');
        });
    }
  }, [type]);

  // Load extension if is update
  useEffect(() => {
    if (id) {
      ExtensionService.get(id)
        .then((response) => {
          // limpa os campos caso sej aum clone
          if (actionType === 'CLONE') {
            reset({
              ...response.data.data,
              extension_number: '',
              identifier: ''
            });
          } else {
            reset(response.data.data);
          }
          setIsUpdate(true);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar ramal', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset, actionType]);

  const onSubmit = async (data) => {
    const extensionData = { ...data, type };
    if (!data.identifier || String(data.identifier).length < 1)
      delete extensionData.identifier;

    try {
      const isClone = actionType === 'CLONE';

      if (!isUpdate || isClone) {
        isExtensionRange
          ? // Na criação de Range a senha deve ser mandada como { password }
            await ExtensionService.createExtensionRange({
              ...extensionData,
              password: extensionData.secret
            })
          : await ExtensionService.create(extensionData);
      } else {
        await ExtensionService.update(id, extensionData);
      }

      toast.success(
        isUpdate && !isClone ? 'Ramal atualizado' : 'Ramal cadastrado!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
      history.push('/admin/extensions');
    } catch (err) {
      console.log(err.response);
      toast.error('Erro ao salvar ramal', {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/extensions');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/extensions');
  };

  const handleExtensionCreationType = () => {
    setIsExtensionRange(!isExtensionRange);
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/extensions"
        buttonTitle="Voltar para a pagina de ramais"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Ramal {type}</h3>
                </CardHeader>
                <CardBody>
                  {actionType === 'ADD' && (
                    <Row className="mb-3">
                      <Col sm="12">
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="isExtensionRange"
                              value="unique"
                              checked={!isExtensionRange}
                              onChange={handleExtensionCreationType}
                            />
                            Criar um ramal
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              type="radio"
                              name="isExtensionRange"
                              value="multi"
                              checked={isExtensionRange}
                              onChange={handleExtensionCreationType}
                            />
                            Criar intervalo de ramais
                          </Label>
                        </FormGroup>
                      </Col>
                    </Row>
                  )}

                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {!isExtensionRange && (
                      <Row>
                        <Col md={type === 'ANALOGICO' ? '12' : '6'}>
                          <label className="form-control-label">
                            Número do ramal*
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="extension_number"
                            rules={{
                              required: 'Campo obrigatório',
                              validate: (value) => {
                                if (String(value).length < 3)
                                  return 'No mínino 3 caracteres';
                                if (String(value).length > 10)
                                  return 'No máximo 10 caracteres';
                                return true;
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="extension_number"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        {type !== 'ANALOGICO' && (
                          <Col md={{ size: 6 }}>
                            <label className="form-control-label">
                              Nome do ramal
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="identifier"
                              rules={{
                                validate: (value) => {
                                  if (String(value).length === 0) return true;
                                  // if (/^([a-zA-Z0-9-_]+)$/.test(value))
                                  //   return true;
                                  // return 'Não use espaços ou caracteres especiais';
                                }
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="identifier"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        )}
                      </Row>
                    )}

                    {isExtensionRange && (
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Ramal Inicial*
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="initialExtension"
                            rules={{ required: 'Campo obrigatório' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="initialExtension"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Ramal final*
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="finalExtension"
                            defaultValue=""
                            rules={{ required: 'Campo obrigatório' }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="finalExtension"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                    )}

                    <Row style={{ paddingTop: '15px' }}>
                      {type !== 'ANALOGICO' && (
                        <Col md="6">
                          <label className="form-control-label">Senha*</label>
                          <Controller
                            as={Input}
                            type="password"
                            control={control}
                            name="secret"
                            defaultValue=""
                            rules={{
                              required: 'Defina uma senha',
                              validate: (value) => {
                                if (/^\S*$/.test(value)) return true;
                                return 'Não utilize espaços em branco';
                              }
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="secret"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      )}
                      {type === 'PJSIP' && (
                        <Col md="6">
                          <label className="form-control-label">
                            Configuração*
                          </label>
                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                {pjsipConfiguration.map((config, index) => (
                                  <option key={index} value={config.id}>
                                    {config.name}
                                  </option>
                                ))}
                              </Input>
                            }
                            control={control}
                            name="pjsip_configuration_id"
                            rules={{ required: 'Selecione uma configuração' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="pjsip_configuration_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      )}
                      {(type === 'SIP' || type === 'IAX2') && (
                        <Col md="6">
                          <label className="form-control-label">
                            Transporte*
                          </label>
                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                <option value="UDP">UDP</option>
                                <option value="TCP">TCP</option>
                              </Input>
                            }
                            control={control}
                            name="transport"
                            rules={{ required: 'Selecione o transporte' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="transport"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      )}
                    </Row>

                    <Row style={{ paddingTop: '15px' }}>
                      <Col md="6">
                        <label className="form-control-label">Setor*</label>
                        <Controller
                          as={
                            <Input type="select">
                              <option value="">Selecione</option>
                              {costsCenter.map((cost, index) => (
                                <option key={index} value={cost.id}>
                                  {cost.name}
                                </option>
                              ))}
                            </Input>
                          }
                          control={control}
                          name="cost_center"
                          rules={{ required: 'Campo obrigatório' }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="cost_center"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">Contexto*</label>
                        <Controller
                          as={
                            <Input type="select">
                              <option value="">Selecione</option>
                              {contexts.map((context, index) => (
                                <option key={index} value={context.id}>
                                  {context.name}
                                </option>
                              ))}
                            </Input>
                          }
                          control={control}
                          name="context"
                          rules={{ required: 'Campo obrigatório' }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="context"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>

                    <hr />
                    <h3>Configurações</h3>
                    {type !== 'ANALOGICO' && (
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Call Group
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="callgroup"
                            rules={{
                              validate: (value) => {
                                if (
                                  /^([1-9]|[1-5][0-9]|6[0-3])?(,[1-9]|,[1-5][0-9]|,6[0-3]){0,4}$/.test(
                                    value
                                  )
                                )
                                  return true;
                                return 'Call Group inválido';
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="callgroup"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Pickup Group
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="pickupgroup"
                            rules={{
                              validate: (value) => {
                                if (
                                  /^([1-9]|[1-5][0-9]|6[0-3])?(,[1-9]|,[1-5][0-9]|,6[0-3]){0,4}$/.test(
                                    value
                                  )
                                )
                                  return true;
                                return 'Pickup Group inválido';
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="pickupgroup"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                    )}

                    {type !== 'ANALOGICO' && (
                      <Row className="mb-2" style={{ paddingTop: '15px' }}>
                        <Col md="6">
                          <label className="form-control-label">
                            Limite de contatos*
                          </label>
                          <Controller
                            as={Input}
                            type="number"
                            control={control}
                            name="call_limit"
                            rules={{
                              required: 'Campo obrigatório',
                              validate: (value) => {
                                return value > 0 && value < 100
                                  ? true
                                  : 'Limite entre 1 e 99';
                              }
                            }}
                            defaultValue={1}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="call_limit"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col
                          className="mt-3 mt-md-4"
                          style={{ marginLeft: '1.5rem' }}
                        >
                          <Row>
                            <Col sm="12">
                              <Controller
                                name="allow_external_call_recording"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <>
                                    <label className="form-control-label">
                                      <Input
                                        type="checkbox"
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                        checked={props.value}
                                      />
                                      Gravar chamadas externas
                                    </label>
                                  </>
                                )}
                              />
                            </Col>
                            <Col sm="12">
                              <Controller
                                name="allow_internal_call_recording"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <>
                                    <label className="form-control-label">
                                      <Input
                                        type="checkbox"
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                        checked={props.value}
                                      />
                                      Gravar chamadas internas
                                    </label>
                                  </>
                                )}
                              />
                            </Col>
                            <Col sm="12">
                              <Controller
                                name="allow_monitor_extension"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <>
                                    <label className="form-control-label">
                                      <Input
                                        type="checkbox"
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                        checked={props.value}
                                      />
                                      Monitorar
                                    </label>
                                  </>
                                )}
                              />
                            </Col>
                            {type === 'PJSIP' && (
                              <Col sm="12">
                                <Controller
                                  name="nat"
                                  control={control}
                                  defaultValue={false}
                                  render={(props) => (
                                    <>
                                      <label className="form-control-label">
                                        <Input
                                          type="checkbox"
                                          onChange={(e) =>
                                            props.onChange(e.target.checked)
                                          }
                                          checked={props.value}
                                        />
                                        NAT
                                      </label>
                                    </>
                                  )}
                                />
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                    )}

                    <ReactButton btnColor="confirmation" type="submit">
                      Salvar
                    </ReactButton>
                    <ReactButton
                      btnColor="cancelation"
                      onClick={() => cancelAction()}
                    >
                      Cancelar
                    </ReactButton>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default FormPjsip;
