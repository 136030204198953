export const ContainerTitle = {
  display: 'flex',
  flexDirection: 'row',
  width: '50%'
};

export const ColorTitleAnswered = {
  width: '104px',
  height: '22px',
  borderRadius: '5%',
  backgroundColor: `#00A07C`,
  color: 'white',
  textAlign: 'center',
  fontWeight: 'bold'
};

export const ColorTitleAbandoned = {
  width: '104px',
  height: '22px',
  marginLeft: '2%',
  borderRadius: '5%',
  backgroundColor: `red`,
  color: 'white',
  textAlign: 'center',
  fontWeight: 'bold',
  marginBottom: '2%'
};
