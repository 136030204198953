import React, { useEffect, useState } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import LoaderReact from 'components/Loader';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Pagination from 'components/Pagination';
import Modal from '../../../components/Modal/NotificationModal';
import ModalConfirmation from '../../../components/Modal/ConfirmationModal';

import TransshipmentService from '../service';

import {
  Input,
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';

const QueueList = () => {
  const [transshipments, setTransshipments] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTransshipments, setSearchTransshipments] = useState();
  const [idResource, setidResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const getTransshipments = (selectedPage = 1, search = '') => {
    setLoading(true);
    TransshipmentService.getAllSeach(selectedPage, 10, search, true)
      .then((response) => {
        const transshipmentList = response.data.data;
        const quantity = response.data.totalRegisters;

        setTransshipments(transshipmentList);
        setQuantity(quantity);
        setLoading(false);
      })
      .catch((err) => {
        //const { data } = error.response;
        toast.error(
          err.response.data.message || 'Erro ao listar transbordos!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
        setLoading(false);
      });
  };

  useEffect(getTransshipments, []);

  const deleteTransshipment = (id) => {
    TransshipmentService.delete(id)
      .then(() => {
        toast.info('Transbordo deletado com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
        getTransshipments();
        setActivePage(1);
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Ops algo errado aconteceu!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        getTransshipments();
      });
  };

  const getTransshipmentsSearch = (event, selectedPage = 1) => {
    setSearchTransshipments(event.target.value);
    if (searchTransshipments) {
      getTransshipments(selectedPage, event.target.value);
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    getTransshipments(page);
  };

  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/transshipment/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Transbordo</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Transbordo"
                          type="text"
                          onChange={(event) => getTransshipmentsSearch(event)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>

                <ToolkitProvider
                  data={transshipments}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'extension.extension_number',
                      text: 'Ramal Origem',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'extension_time',
                      text: 'Tempo Original',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'leak1',
                      text: 'Transbordo 1',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'leak1_time',
                      text: 'Tempo de Ring - Transbordo 1',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'configs',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Configurações',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Modal name={row.name}>
                            {row.leak2 && (
                              <p>
                                <strong>Transbordo 2: </strong>
                                {row.leak2}
                              </p>
                            )}
                            {row.leak2_time && (
                              <p>
                                <strong>Tempo de Ring - Transbordo 2: </strong>
                                {row.leak2_time}
                              </p>
                            )}
                            {row.leak3 && (
                              <p>
                                <strong>Transbordo 3: </strong>
                                {row.leak3}
                              </p>
                            )}
                            {row.leak3_time && (
                              <p>
                                <strong>Tempo de Ring - Transbordo 3: </strong>
                                {row.leak3_time}
                              </p>
                            )}
                            {row.leak4 && (
                              <p>
                                <strong>Transbordo 4: </strong>
                                {row.leak4}
                              </p>
                            )}
                            {row.leak4_time && (
                              <p>
                                <strong>Tempo de Ring - Transbordo 4: </strong>
                                {row.leak4_time}
                              </p>
                            )}
                            {row.leak5 && (
                              <p>
                                <strong>Transbordo 5: </strong>
                                {row.leak5}
                              </p>
                            )}
                            {row.leak5_time && (
                              <p>
                                <strong>Tempo de Ring - Transbordo 5: </strong>
                                {row.leak5_time}
                              </p>
                            )}
                          </Modal>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            onClick={() => controlDelete(row.id)}
                            size="sm"
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (rowContent, row) => {
                        return (
                          <Link to={'/admin/transshipment/form/' + row.id}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <LoaderReact />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={deleteTransshipment}
        message={'Tem certeza que deseja excluir o Transbordo?'}
      />
    </>
  );
};

export default QueueList;
