import { useState, useCallback } from 'react';

export const useFileUploadModalHandler = () => {
  const [isOpen, setIsOpen] = useState(false);

  const changeStatus = useCallback((newStatus) => {
    setIsOpen(newStatus);
  }, []);

  return {
    isOpen,
    changeStatus
  };
};
