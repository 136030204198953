import React, { useCallback, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { Row, Form, Col, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select/async';

import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import CustomFieldsInputsForm from '../../../shared/components/CustomFieldsInputsForm';

import OpportunityService from '../../services/opportunityService';
import { useListOpportunityStagesByRecordType } from '../../../OpportunityStages/hooks/useListOpportunityStagesByRecordType';
import { custom_select } from '../../../../../assets/styles/multiple_select';
import { useSearchAccounts } from '../../hooks/useSearchAccounts';
import AddAccountModal from '../AddAccountModal';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { useSearchUsers } from 'views/User/hooks/useSearchUsers';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { AiOutlineClear } from 'react-icons/ai';
import CurrencyMask from 'components/Inputs/CurrencyMask';
import { ScreenPrompt } from 'components/ScreenPrompt';
import ReactLoader from 'components/Loader';
import { AddNewContactButton } from 'components/Buttons/AddNewContactButton';
import DatePicker from 'components/Inputs/DatePicker.js';

const OpportunityInputs = ({ recordTypeId }) => {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    errors,
    setValue,
    register,
    setError,
    formState,
    reset,
    watch
  } = useForm();
  const { isDirty } = formState;

  const [isSubmiting, setIsSubmiting] = useState(false);
  const { isSearching, filterAccounts } = useSearchAccounts();
  const [account, setAccount] = useState();
  const [responsible, setResponsible] = useState({
    label: localStorage.getItem('name'),
    value: localStorage.getItem('login')
  });

  useEffect(() => {
    const opportunity = localStorage.getItem('opportunityValue');
    if (opportunity) {
      reset(JSON.parse(opportunity));
    }
  }, []);

  const { getCustomFields, customFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType(entitiesName.OPPORTUNITY, recordTypeId);

  const { isSearchingUsers, searchUsers } = useSearchUsers();

  const {
    getOpportunityStagesByRecordType,
    isLoadingOpportunityStages,
    opportunityStages
  } = useListOpportunityStagesByRecordType();

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    register('responsibleLogin');
  }, [register]);

  useEffect(() => {
    getOpportunityStagesByRecordType(recordTypeId);
  }, [recordTypeId, getOpportunityStagesByRecordType]);

  const onAddedAccount = useCallback((data) => {
    setAccount({
      label: data.name,
      value: data.id
    });
  }, []);

  const onSubmit = async (data) => {
    
    if (data.amount) {
      data.amount = data.amount
        .replace('R$', '')
        .replace(/\./g, '')
        .replace(',', '.')
        .trim();
    }
    const originalData = { ...data };
    if (!responsible || !responsible.value) {
      setError('responsibleLogin', { message: 'Campo obrigatório' });
      return;
    }

    setIsSubmiting(true);

    data.accountId = account && account.value ? Number(account.value) : null;
    data.responsibleLogin = responsible.value;

    const dataDTO = formatCustomFieldsToSubmit(data, customFields);
    OpportunityService.createOpportunity(recordTypeId, dataDTO)
      .then((response) => {
        toast.success('Oportunidade criada com sucesso');
        history.push(
          `/admin/crm/opportunityEdit/${recordTypeId}/${response?.data?.data?.id}`
        );
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.message ||
            err.message ||
            'Erro ao criar a oportunidade',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      })
      .finally(() => {
        setIsSubmiting(false);
        localStorage.setItem('opportunityValue', JSON.stringify(originalData));
      });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/crm/opportunity', { recordTypeId });
  };

  const defaultStage = opportunityStages?.filter(
    (opportunityStage) => opportunityStage.orderNumber
  )[0];
  console.log('Default Stage => ', defaultStage);

  if (isLoadingOpportunityStages) {
    return (
      <Row className="justify-content-md-center">
        <ReactLoader />
      </Row>
    );
  }

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        <button
          type="button"
          style={{
            display: 'none',
            border: 'none',
            background: 'none'
          }}
          onClick={() => {
            localStorage.removeItem('opportunityValue');
            reset({ stageId: null });
            setValue('responsibleLogin', null);
            setResponsible(null);
          }}
          id="buttonInChild"
        >
          <span
            onClick={() => {
              document.getElementById('buttonInChild').click();
            }}
            className="small"
            style={{ cursor: 'pointer' }}
          >
            <AiOutlineClear /> Limpar campos
          </span>
        </button>
        <Row className="mb-3">
          <Col sm="6">
            <label className="form-control-label">Título*</label>
            <Controller
              render={(props) => (
                <Input
                  value={props.value}
                  onChange={props.onChange}
                  type="text"
                  placeholder="Título"
                />
              )}
              control={control}
              defaultValue=""
              rules={{ required: 'Campo obrigatório' }}
              name="title"
            />
            <ErrorMessage
              errors={errors}
              name="title"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          <Col>
            <label className="form-control-label">Fase da oportunidade*</label>
            <Controller
              render={(props) => (
                <FlexSelect
                  dataOptions={opportunityStages || []}
                  isMulti={false}
                  value={props.value}
                  valueController={setValue}
                  fieldName="stageId"
                  labelName="name"
                  valueName="id"
                  isClearable={true}
                  disabled={isLoadingOpportunityStages}
                  closeMenuOnSelect={true}
                />
              )}
              control={control}
              defaultValue={defaultStage?.id || ''}
              rules={{
                required: 'Campo obrigatório'
              }}
              name="stageId"
            />
            <ErrorMessage
              errors={errors}
              name="stageId"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm="6">
            <div className="d-flex">
              <div style={{ flexGrow: '10' }} className="tour3">
                <label className="form-control-label">Conta</label>
                <Select
                  styles={custom_select}
                  placeholder="Busca rápida"
                  isClearable={true}
                  name="accountId"
                  isLoading={isSearching}
                  loadingMessage={() => 'Buscando...'}
                  noOptionsMessage={({ inputValue }) =>
                    !inputValue
                      ? 'Digite para pesquisar'
                      : 'Nenhum resultado encontrado'
                  }
                  loadOptions={async (inputValue) => {
                    const res = await filterAccounts(inputValue);
                    return (
                      res &&
                      res.map((account) => ({
                        label: account.name,
                        value: account.id
                      }))
                    );
                  }}
                  onChange={(data) => {
                    if (data) {
                      const { value, label } = data;
                      setAccount({
                        label,
                        value
                      });
                    } else {
                      setAccount(null);
                    }
                  }}
                  value={
                    account
                      ? {
                          label: account.label,
                          value: account.value
                        }
                      : ''
                  }
                  defaultValue={
                    account
                      ? {
                          label: account.label,
                          value: account.value
                        }
                      : ''
                  }
                />
              </div>

              <div
                style={{
                  alignSelf: 'flex-end',
                  justifyContent: 'space-between',
                  padding: '0.6rem 0.6rem'
                }}
              >
                <AddAccountModal
                  onAddedAccount={onAddedAccount}
                  buttonOpenModal={({ onClick }) => (
                    <AddNewContactButton onClick={onClick} />
                  )}
                />
              </div>
            </div>
          </Col>

          <Col md="6">
            <div className="d-flex">
              <div style={{ flexGrow: '10' }}>
                <label className="form-control-label">Responsável*</label>
                <Select
                  styles={custom_select}
                  placeholder="Busca rápida"
                  isClearable={true}
                  name="responsibleLogin"
                  isLoading={isSearchingUsers}
                  loadingMessage={() => 'Buscando...'}
                  noOptionsMessage={({ inputValue }) =>
                    !inputValue
                      ? 'Digite para pesquisar'
                      : 'Nenhum resultado encontrado'
                  }
                  loadOptions={async (inputValue) => {
                    const data = await searchUsers(inputValue);
                    return (
                      data &&
                      data.map((user) => ({
                        label: user.name,
                        value: user.login
                      }))
                    );
                  }}
                  onChange={(data) => {
                    if (data) {
                      const { value, label } = data;
                      setResponsible({
                        label,
                        value
                      });
                    } else {
                      setResponsible(null);
                    }
                  }}
                  value={
                    responsible
                      ? {
                          label: responsible.label,
                          value: responsible.value
                        }
                      : ''
                  }
                  defaultValue={
                    responsible
                      ? {
                          label: responsible.label,
                          value: responsible.value
                        }
                      : ''
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="responsibleLogin"
                  render={({ message }) => errorFormMessage(message)}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col sm="6">
            <label className="form-control-label">Valor</label>
            <Controller
              render={(props) => (
                <CurrencyMask
                  valueController={setValue}
                  value={props.value}
                  fieldName="amount"
                  placeholder="Valor"
                />
              )}
              control={control}
              defaultValue={0}
              rules={{ required: 'Campo obrigatório' }}
              name="amount"
            />

            <ErrorMessage
              errors={errors}
              name="amount"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          <Col sm="6">
            <label className="form-control-label">
              Previsão de encerramento
            </label>
            <Controller
              render={(props) => (
                <div>
                  <DatePicker
                    fieldName="expectedClosure"
                    valueController={setValue}
                    defaultValue={props.value}
                  />
                </div>
              )}
              control={control}
              defaultValue=""
              name="expectedClosure"
            />
            <ErrorMessage
              errors={errors}
              name="expectedClosure"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        </Row>

        <Row className="mb-3">
          <CustomFieldsInputsForm
            fields={customFields}
            control={control}
            errors={errors}
            setValue={setValue}
            Controller={Controller}
            watch={watch}
          />
        </Row>

        <ReactButton
          btnColor="confirmation"
          type="submit"
          disabled={isSubmiting}
        >
          Salvar
        </ReactButton>

        <ReactButton
          btnColor="cancelation"
          onClick={() => {
            return cancelAction();
          }}
        >
          Cancelar
        </ReactButton>
      </Form>
    </>
  );
};

export default OpportunityInputs;
