export function maskPhone(valuePhone) {
  if (!valuePhone) {
    return '';
  }
  let phoneFormated = valuePhone.replace(/\D/g, '');
  phoneFormated = phoneFormated.replace(/^0/, '');
  if (phoneFormated.length > 10) {
    phoneFormated = phoneFormated.replace(
      /^(\d\d)(\d{1})(\d{4})(\d{4}).*/,
      '($1)$2.$3-$4'
    );
  } else if (phoneFormated.length > 5) {
    phoneFormated = phoneFormated.replace(
      /^(\d\d)(\d{4})(\d{0,4}).*/,
      '($1)$2-$3'
    );
  } else if (phoneFormated.length > 2) {
    phoneFormated = phoneFormated.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else {
    phoneFormated = phoneFormated.replace(/^(\d*)/, '($1');
  }
  return phoneFormated;
}

export function validateFields(rule) {
  if (rule) {
    return {
      required: 'Campo obrigatório'
    };
  }
}

export function definedShowName(name, rule) {
  if (rule) {
    return `${name}*`;
  }
  return name;
}

export function dateRules(validations) {
  let rules = {
    required: validations ? 'Campo obrigatório' : false,
    pattern: {
      value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
      message: 'Data inválida! (dd/MM/aaaa)'
    }
  };
  return rules;
}

export function sortData(data) {
  const dataSort = data.sort((a, b) => {
    if (a.name) {
      if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
      if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
      return 0;
    } else {
      if (a.nome.toLowerCase() < b.nome.toLowerCase()) return -1;
      if (a.nome.toLowerCase() > b.nome.toLowerCase()) return 1;
      return 0;
    }
  });
  return dataSort;
}
