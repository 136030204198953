import React from 'react';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';

export default function ReactLoader({ height = 100, width = 100 }) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  return (
    <Loader
      type="TailSpin"
      color={systemConfiguration.primary_color || "#26A269"}
      height={height}
      width={width}
    />
  );
}
