import React, { useState, useEffect } from 'react';
import { useForm, useWatch, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import FlexSelect from '../../../components/Inputs/FlexSelect';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import Service from '../service';
import { toast } from 'react-toastify';
//import { ddrRegex } from './regexs';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { WhatsappForm } from './components/WhatssappForm';
import { InstagramForm } from './components/InstagramForm';
import { TelegramForm } from './components/TelegramForm';
import { FacebookForm } from './components/FacebookForm';
import { Flex4UForm } from './components/Flex4UForm';
import TimeScheduleService from '../../../views/TimeSchedule/service';
import WhatsonService from '../service/watson/index';
import { useGetQueues } from 'views/User/form/hooks/useGetQueues';
import { SmsForm } from './components/SmsForm';
import { WebChatForm } from './components/WebChatForm';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
import { ScreenPrompt } from 'components/ScreenPrompt';

const ChatForm = (props) => {
  const { id } = props.match.params ?? '';

  const [isUpdate, setIsUpdate] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [canal, setCanal] = useState(null);
  const [selectedChannel, setSelectedChannel] = useState('whatsapp');
  const isDisabledChannel =
    selectedChannel === 'telegram' || selectedChannel === 'facebook';

  const [horariosList, setHorariosLista] = useState([]);
  const horariosOptions = horariosList.map((item) => {
    return { value: item.id, label: item.name };
  });

  const [watsonList, setWatsonList] = useState([]);
  const watsonOptions = watsonList.map((item) => {
    return { value: item.id, label: item.nome_canal };
  });

  const [dialogTreeList, setDialogTreeList] = useState([]);
  const dialogTreeOptions = dialogTreeList.map((item) => {
    return { value: item.id, label: item.name };
  });

  const queues = useGetQueues()?.queues ?? [];
  const queuesOptionList = queues.map((queue) => {
    return { value: queue.id, label: queue.name };
  });

  const { reset, control, handleSubmit, errors, setValue, formState, trigger } =
    useForm();

  const hasProgramacaoHorario = useWatch({
    name: 'openingHours.idHour',
    control
  });

  const tipoAtendimento = useWatch({
    name: 'tipoAtendimento',
    control
  });

  const enumChannels = {
    whatsapp: (
      <WhatsappForm
        canal={canal}
        control={control}
        errors={errors}
        setValue={setValue}
      ></WhatsappForm>
    ),
    telegram: (
      <TelegramForm
        canal={canal}
        control={control}
        errors={errors}
      ></TelegramForm>
    ),
    facebook: (
      <FacebookForm
        canal={canal}
        control={control}
        errors={errors}
      ></FacebookForm>
    ),
    chat: (
      <WebChatForm
        canal={canal}
        control={control}
        errors={errors}
        setValue={setValue}
      ></WebChatForm>
    ),
    instagram: (
      <InstagramForm
        canal={canal}
        control={control}
        errors={errors}
      ></InstagramForm>
    ),
    sms: <SmsForm canal={canal} control={control} errors={errors}></SmsForm>,
    flex4u: (
      <Flex4UForm
        canal={canal}
        control={control}
        errors={errors}
        setValue={setValue}
      ></Flex4UForm>
    )
  };

  const options = [
    { label: 'whatsapp', value: 'whatsapp' },
    { label: 'telegram', value: 'telegram' },
    { label: 'instagram', value: 'instagram' },
    { label: 'facebook', value: 'facebook' },
    { label: 'sms', value: 'sms' },
    { label: 'webchat', value: 'chat' },
    { label: 'flex4u', value: 'flex4u' }
  ];

  const optionsPerChannel = {
    whatsapp: ['Watson', 'Árvore de Díalogo', 'Fila'],
    telegram: ['Fila', 'Watson'],
    instagram: ['Fila', 'Árvore de Díalogo'],
    facebook: ['Fila', 'Watson'],
    chat: ['Fila', 'Watson', 'Árvore de Díalogo'],
    sms: ['Fila'],
    flex4u: ['Fila', 'Watson', 'Árvore de Díalogo']
  };

  const optionsTipoAtendimento = [
    { label: 'Watson', value: 'Watson' },
    { label: 'Árvore de Díalogo', value: 'Árvore de Díalogo' },
    { label: 'Fila', value: 'Fila' }
  ].filter((option) => {
    return optionsPerChannel[selectedChannel].includes(option.label);
  });

  const optionsAtendimentoHorario = {
    Watson: watsonOptions,
    'Árvore de Díalogo': dialogTreeOptions,
    Fila: queuesOptionList
  };

  const typeList = {
    Watson: 'WATSON',
    'Árvore de Díalogo': 'CHATBOT',
    Fila: 'QUEUE'
  };

  const _typeList = {
    WATSON: 'Watson',
    CHATBOT: 'Árvore de Díalogo',
    QUEUE: 'Fila'
  };

  const getDialogTree = () => {
    FlexChatIntegration.getAllDialogTree()
      .then((res) => {
        setDialogTreeList(res?.data ?? []);
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Erro ao carregar Árvore de diálogo!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  };

  const getWhatson = () => {
    WhatsonService.getWhatson()
      .then((response) => {
        setLoading(false);
        setWatsonList(response?.data.data ?? []);
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Erro ao carregar Watson!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  };

  const getHorarios = () => {
    TimeScheduleService.getAll()
      .then((res) => {
        setHorariosLista(res?.data?.data ?? []);
      })
      .catch(() => toast.error('Erro ao buscas os horários'));
  };

  const formatDataForReset = (data) => {
    const formatData = { ...data };

    if (formatData.cotacaoDolar) {
      const formatterBR = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      });

      formatData.cotacaoDolar = formatterBR.format(formatData.cotacaoDolar);
    }
    if (formatData.custoPorConversa) {
      const formatterUS = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD'
      });

      formatData.custoPorConversa = formatterUS.format(
        formatData.custoPorConversa
      );
    }

    formatData.tipoAtendimento = _typeList[data?.openingHours?.ontime?.type];
    if (data.openingHours) {
      formatData.openingHours.ontime = data?.openingHours?.ontime?.id ?? '';

      formatData.openingHours.offtime = data?.openingHours?.offtime?.id ?? '';

      formatData.openingHours.holiday = data?.openingHours?.holiday?.id ?? '';
    }

    return formatData;
  };

  useEffect(() => {
    getDialogTree();
    getHorarios();
    getWhatson();
    if (id) {
      setLoading(true);
      Service.get(id)
        .then((res) => {
          const data = res.data.data;
          const formatData = formatDataForReset(data);

          setCanal(res.data.data);
          reset(formatData);
          setSelectedChannel(res.data.data.channel);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch((err) => {
          toast.error('Erro ao tentar carregar canal', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const formatDTO = (info) => {
    const data = { ...info };

    if (data.cotacaoDolar) {
      const cotacaoDolar = data.cotacaoDolar;
      const value = parseFloat(
        cotacaoDolar.replace(/[^\d,.-]/g, '').replace(',', '.')
      );
      data.cotacaoDolar = value;
    }

    if (data.custoPorConversa) {
      const custoPorConversa = data.custoPorConversa;
      const value = parseFloat(custoPorConversa.replace(/[^\d,.-]/g, ''));
      data.custoPorConversa = value;
    }
    data.openingHours = {
      ...data.openingHours,
      ontime: data?.openingHours?.ontime
        ? {
            id: data.openingHours.ontime,
            type: typeList[data.tipoAtendimento]
          }
        : '',
      offtime: data?.openingHours?.offtime
        ? {
            id: data.openingHours.offtime,
            type: 'CHATBOT'
          }
        : '',
      holiday: data?.openingHours?.holiday
        ? {
            id: data.openingHours.holiday,
            type: 'CHATBOT'
          }
        : ''
    };

    return data;
  };

  const onSubmit = async (info) => {
    const data = formatDTO(info);

    if (data.channel === 'chat') {
      let response = undefined;
      isUpdate
        ? (response = await Service.putCanal(data.channel, { ...data, id: id }))
        : (response = await Service.postCanal(data.channel, data));

      if (response) {
        const logoChat = new FormData();
        const profilePic = new FormData();
        let imageOpenChat = new FormData();
        let iconOpenChat = new FormData();

        logoChat.append('file', data.logoChat);
        profilePic.append('file', data.profilePic);

        if (data.typeOfOpeningChat === 'IMAGE') {
          imageOpenChat.append('file', data.imageOfOpeningChat);
          imageOpenChat.append('type', 'IMAGE');
        } else if (data.typeOfOpeningChat === 'ICON') {
          iconOpenChat.append('file', data.iconOfOpeningChat);
          iconOpenChat.append('type', 'ICON');
        }

        if (typeof data?.logoChat !== 'string') {
          const responseImage = await Service.postFotoWebChat(
            'logochat',
            logoChat
          );

          if (!responseImage) {
            toast.error('Erro ao atualizar logo');
          }
        }
        if (typeof data?.profilePic !== 'string') {
          const responseProfilePic = await Service.postFotoWebChat(
            'profilepic',
            profilePic
          );

          if (!responseProfilePic) {
            toast.error('Erro ao atualizar Imagem de perfil');
          }
        }

        if (
          typeof data?.imageOfOpeningChat !== 'string' &&
          data?.imageOfOpeningChat
        ) {
          const responseImage = await Service.postImageOpenWebChat(
            'imageOpen',
            imageOpenChat
          );
          if (!responseImage) {
            toast.error('Erro ao atualizar logo');
          }
        }

        if (
          typeof data?.iconOfOpeningChat !== 'string' &&
          data?.iconOfOpeningChat
        ) {
          const responseImage = await Service.postImageOpenWebChat(
            'iconOpen',
            iconOpenChat
          );
          if (!responseImage) {
            toast.error('Erro ao atualizar logo');
          }
        }

        if (isUpdate) {
          toast.info('Canal foi atualizado com sucesso!');
        } else {
          toast.success('Canal foi cadastrado com sucesso!');
        }
        history.push('/admin/chat');

        return;
      }

      return;
    }

    try {
      setLoading(true);
      isUpdate
        ? await Service.putCanal(data.channel, { ...data, id: id })
        : await Service.postCanal(data.channel, data);
      history.push('/admin/chat');
      if (isUpdate) {
        toast.info('Canal foi atualizada com sucesso!');
      } else {
        toast.success('Canal foi cadastrada com sucesso!');
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/chat');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/chat');
  };

  const { isDirty } = formState;

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/chat"
        buttonTitle="Voltar para a pagina de canais"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Canais</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Tipo de canal
                          </label>

                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isDisabled={!!id}
                                isClearable={false}
                                dataOptions={options}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={selectedChannel}
                                valueController={(name, value) => {
                                  reset({
                                    channel: value,
                                    openingHours: {
                                      idHour: ''
                                    }
                                  });

                                  setSelectedChannel(value);
                                }}
                                fieldName="channel"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="channel"
                            defaultValue="whatsapp"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="channel"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Status do canal
                          </label>

                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={false}
                                dataOptions={[
                                  {
                                    label: 'Habilitado',
                                    value: true
                                  },
                                  {
                                    label: 'Desabilitado',
                                    value: false
                                  }
                                ]}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="ativado"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="ativado"
                            defaultValue={true}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="ativado"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      {enumChannels[selectedChannel]}
                      <hr />

                      <h3>Rotas</h3>

                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Tipo de atendimento*
                          </label>

                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={false}
                                dataOptions={optionsTipoAtendimento}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={(fieldName, value) => {
                                  setValue(fieldName, value);
                                  setValue('openingHours.ontime', '');
                                }}
                                fieldName="tipoAtendimento"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            rules={{
                              required:
                                'Tipo de atendimento é um campo obrigatório'
                            }}
                            control={control}
                            name="tipoAtendimento"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="tipoAtendimento"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md="6">
                          <label className="form-control-label">
                            Atendimento no Hórario*
                          </label>

                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={false}
                                dataOptions={
                                  optionsAtendimentoHorario[tipoAtendimento] ??
                                  []
                                }
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="openingHours.ontime"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            rules={{
                              required:
                                'Atendimento no Hórario é um campo obrigatório'
                            }}
                            control={control}
                            name="openingHours.ontime"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="openingHours.ontime"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Programação de Hórario
                          </label>

                          <Controller
                            render={(props) => (
                              <FlexSelect
                                // isDisabled={
                                //   !(
                                //     selectedChannel === 'whatsapp' ||
                                //     selectedChannel === 'chat'
                                //   )
                                // }
                                isClearable={true}
                                dataOptions={horariosOptions}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="openingHours.idHour"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="openingHours.idHour"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="OpeningHours.idHour"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="3">
                          <label className="form-control-label">
                            Atendimento Fora do Hórario
                          </label>

                          <Controller
                            render={(props) => {
                              return (
                                <FlexSelect
                                  isDisabled={
                                    !(
                                      hasProgramacaoHorario &&
                                      !isDisabledChannel
                                    )
                                  }
                                  isClearable={true}
                                  dataOptions={dialogTreeOptions}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={(name, value) => {
                                    setValue(name, value);
                                    trigger('openingHours.offtime');
                                  }}
                                  fieldName="openingHours.offtime"
                                  labelName="label"
                                  valueName="value"
                                />
                              );
                            }}
                            rules={{
                              required:
                                hasProgramacaoHorario && !isDisabledChannel
                                  ? 'Atendimento Fora do Hórario é um campo obrigatório'
                                  : false
                            }}
                            shouldUnregister={!!hasProgramacaoHorario} // Desregistrar o campo quando 'hasProgramacaoHorario' for falso
                            control={control}
                            name="openingHours.offtime"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="openingHours.offtime"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="3">
                          <label className="form-control-label">
                            Atendimento Feriado
                          </label>

                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isDisabled={
                                  !(hasProgramacaoHorario && !isDisabledChannel)
                                }
                                isClearable={false}
                                dataOptions={dialogTreeOptions}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={(name, value) => {
                                  setValue(name, value);
                                  trigger('openingHours.holiday');
                                }}
                                fieldName="openingHours.holiday"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            rules={{
                              required:
                                hasProgramacaoHorario && !isDisabledChannel
                                  ? 'Atendimento Feriado é um campo obrigatório'
                                  : false
                            }}
                            control={control}
                            name="openingHours.holiday"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="openingHours.holiday"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <hr />

                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ChatForm;
