import DefaultModal from 'components/Modal/DefaultModal';
import React from 'react';

import FilterClientScreen from '../FilterClientScreen';

const ModalFilterClients = ({
  isModalOpen,
  onModalToggle,
  setSelectedClient,
  ...props
}) => {
  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Buscar contatos por campo específico"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      <FilterClientScreen setSelectedClient={setSelectedClient} />
    </DefaultModal>
  );
};

export default ModalFilterClients;
