import React, { useEffect } from 'react';
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Table,
  Form
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import ReactButton from 'components/Buttons/ReactButton';
import { useHistory } from 'react-router-dom';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useLeadCustomFields } from '../hooks/useLeadCustomFields';
import { useOpportunityCustomFields } from '../hooks/useOpportunityCustomFields';
import FlexSelect from 'components/Inputs/FlexSelect';
import MapCustomFieldsService from '../services/mapCustomFieldService';
import { toast } from 'react-toastify';

const MapFieldsList = () => {
  const { control, setValue, handleSubmit, reset } = useForm();
  const history = useHistory();
  const { leadCustomFields, reloadLeadCustomFields } = useLeadCustomFields();
  const {
    opportunityCustomFields,
    reloadOpportunityCustomFields,
    isLoadingOpportunityCustomFields
  } = useOpportunityCustomFields();

  useEffect(() => {
    MapCustomFieldsService.getMappedCustomFields()
      .then(({ data }) => {
        const formattedData = leadCustomFields.map((leadField) => {
          let labels = {};
          data.data.forEach((field) => {
            if (
              field.leadFieldId === 'email' ||
              field.leadFieldId === 'telephone'
            ) {
              const fieldName =
                field.leadFieldId === 'email' ? 'email' : 'telephone';
              labels[`${fieldName}`] = field.opportunityFieldId;
            }
            if (field.leadFieldId === leadField.id) {
              const fieldName = leadField.label;
              labels[`${fieldName}`] = field.opportunityFieldId;
            }
          });
          return labels;
        });

        const objectValues = Object.assign({}, ...formattedData);
        reset(objectValues);
      })
      .catch((err) => console.error(err));
  }, [reset, leadCustomFields]);

  useEffect(() => {
    reloadLeadCustomFields();
    reloadOpportunityCustomFields();
  }, [reloadLeadCustomFields, reloadOpportunityCustomFields]);

  const onSubmit = (data) => {
    const opportunities = Object.keys(data);
    const repeatedFields = Object.values(data);
    const hasRepeatedFields = repeatedFields.some((item, index) => {
      if (item.length) {
        return index !== repeatedFields.indexOf(item);
      }
      return;
    });

    const formattedData = leadCustomFields.map((lead) => {
      const dataFields = [];
      opportunities.forEach((field) => {
        if (field === lead.label) {
          dataFields.push({
            leadFieldId: lead.id,
            opportunityFieldId: data[`${field}`]
          });
        }
      });
      return Object.assign({}, ...dataFields);
    });

    formattedData.push({
      leadFieldId: 'email',
      opportunityFieldId: data.email
    });

    formattedData.push({
      leadFieldId: 'telephone',
      opportunityFieldId: data.telephone
    });

    if (!hasRepeatedFields) {
      MapCustomFieldsService.createMapCustomFields(formattedData)
        .then(() => {
          toast.success('Campos mapeados com successo', {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .catch(() => {
          toast.error('Houve um erro ao mapear os campos', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    } else {
      toast.warn(
        'Não pode haver campos customizados da oportunidade repetidos',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    }
  };

  const backToConfig = () => {
    history.push('/admin/configuration');
  };

  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Mapeamento de campos</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Table hover={true} striped>
                      <thead>
                        <tr>
                          <th className="text-center">Campo Lead</th>
                          <th className="text-center">Campo Oportunidade</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Telefone</td>
                          <td>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={
                                    (opportunityCustomFields &&
                                      opportunityCustomFields.filter(
                                        (field) => field.type === 'TELEPHONE'
                                      )) ||
                                    []
                                  }
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="telephone"
                                  labelName="label"
                                  valueName="id"
                                  closeMenuOnSelect={true}
                                  isClearable={true}
                                  disabled={isLoadingOpportunityCustomFields}
                                  noOptionsMessage={() =>
                                    'Não há campos customizados na oportunidade'
                                  }
                                />
                              )}
                              control={control}
                              name="telephone"
                              defaultValue=""
                            />
                          </td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={
                                    (opportunityCustomFields &&
                                      opportunityCustomFields.filter(
                                        (field) => field.type === 'TEXT'
                                      )) ||
                                    []
                                  }
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="email"
                                  labelName="label"
                                  valueName="id"
                                  closeMenuOnSelect={true}
                                  isClearable={true}
                                  disabled={isLoadingOpportunityCustomFields}
                                  noOptionsMessage={() =>
                                    'Não há campos customizados na oportunidade'
                                  }
                                />
                              )}
                              control={control}
                              name="email"
                              defaultValue=""
                            />
                          </td>
                        </tr>
                        {leadCustomFields &&
                          leadCustomFields.map((leadCustomField, i) => {
                            return (
                              <>
                                <tr key={i}>
                                  <td>{leadCustomField.label}</td>
                                  <td>
                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={
                                            (opportunityCustomFields &&
                                              opportunityCustomFields.filter(
                                                (field) =>
                                                  field.type ===
                                                  leadCustomField.type
                                              )) ||
                                            []
                                          }
                                          isMulti={false}
                                          value={props.value}
                                          valueController={setValue}
                                          fieldName={leadCustomField.label}
                                          labelName="label"
                                          valueName="id"
                                          closeMenuOnSelect={true}
                                          isClearable={true}
                                          disabled={
                                            isLoadingOpportunityCustomFields
                                          }
                                          noOptionsMessage={() =>
                                            'Não há campos customizados na oportunidade'
                                          }
                                        />
                                      )}
                                      control={control}
                                      name={leadCustomField.label}
                                      defaultValue=""
                                    />
                                  </td>
                                </tr>
                              </>
                            );
                          })}
                        <hr />
                      </tbody>
                    </Table>
                    <ReactButton btnColor="confirmation" type="submit">
                      Salvar
                    </ReactButton>

                    <ReactButton btnColor="cancelation" onClick={backToConfig}>
                      Cancelar
                    </ReactButton>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default MapFieldsList;
