import React, { useCallback, useState } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useProtocolAttachments } from './useProtocolAttachments';
import { DivContainer } from './styled';
import './styles.css';
import { FileSelectorWrapper } from './styled';
import { Button, Col, Form, Input, Row } from 'reactstrap';
import { ErrorFormMessage } from 'components/ErrorFormMessage';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import iconepast from 'assets/img/icons/fileExtensions/file.png';
import iconedownloand from 'assets/img/icons/fileExtensions/download.png';

import { notAllowedExtensions } from 'views/shared/helpers/notAllowedExtensions';

export const ProtocolAttachments = ({
  protocolId,
  attachments,
  onAdded,
  onDeleted
}) => {
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);

  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { errors } = useForm();

  const isImageFile = (file) => {
    const acceptedImageTypes = ['.jpg', '.png', '.jpeg'];
    for (let extersion of acceptedImageTypes) {
      if (file.path.endsWith(extersion)) {
        return true;
      }
    }
    return false;
  };

  const {
    getFileURL,
    removeAttachmentFromProtocol,
    addAttachmentsToProtocol,
    getFileExtensionFromName,
    getIconFromFileExtension,
    getFileNameFormatted
  } = useProtocolAttachments(protocolId);

  const handleDeleteAttachment = useCallback(
    async (attachmentId) => {
      if (window.confirm('Deseja realmente remover este anexo?') === false)
        return;
      const isDeleted = await removeAttachmentFromProtocol(attachmentId);
      if (isDeleted) {
        toast.success('Anexo removido com sucesso');
        onDeleted(attachmentId);
        return;
      }
      return toast.error('Erro ao remover anexo');
    },
    [onDeleted, removeAttachmentFromProtocol]
  );

  const changeFile = (event) => {
    const files = event?.target?.files;
    if (!files || !files.length) return;
    setFiles(files);
  };

  const handleClearFiles = (event) => {
    event.target.form.reset();
    setFiles(null);
  };

  const getFileNamesToShow = () => {
    if (!files || !files.length) return null;
    if (files.length > 1) return `${files.length} arquivos selecionados`;
    return files[0].name;
  };

  const handleSubmitedForm = useCallback(
    async (event) => {
      try {
        const regex = /(?:\.([^.]+))?$/;

        setLoading(true);
        event.persist();

        if (!files || !files.length) {
          setLoading(false);
          return toast.error('Selecione um arquivo');
        }

        const formData = new FormData();
        let stopProcess = false;
        for (const file of files) {
          const ext = regex.exec(file.name)[1];

          if (notAllowedExtensions.includes(ext)) {
            stopProcess = true;
            return toast.error('Extensão ' + ext + ' não permitida');
          }
          formData.append('attachments', file, file.name);
        }

        if (stopProcess) {
          setLoading(false);
          return;
        }

        const addedAttachments = await addAttachmentsToProtocol(formData);
        if (addedAttachments) {
          onAdded(addedAttachments);
          event.target.form.reset();
          setFiles(null);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error(error.response?.data?.message || 'Erro ao adicionar anexo');
      }
    },
    [addAttachmentsToProtocol, onAdded, files]
  );

  return (
    <>
      <FileSelectorWrapper>
        <Form className="needs-validation" encType="multipart/form-data">
          <Row className="mb-3">
            <Col sm="12">
              <label className="form-control-label d-block">
                Arquivos ( pdf, doc, docx, png, jpg, jpeg )*
              </label>
              <label
                htmlFor="attachments"
                className="audio-button-select-style"
                style={buttonStyle(systemConfiguration.primary_color)}
              >
                Selecionar o(s) anexo(s)
              </label>
              <Input
                style={{ display: 'none' }}
                type="file"
                id="attachments"
                name="attachments"
                onChange={changeFile}
                multiple
                // accept=".png, .jpeg, .jpg, .pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
              <ErrorMessage
                errors={errors}
                name="attachments"
                render={({ message }) => ErrorFormMessage(message)}
              />
            </Col>
            {files && files.length ? (
              <div
                style={{
                  marginLeft: '1rem',
                  display: 'flex',
                  flexWrap: 'wrap',
                  alignItems: 'flex-start',
                  alignContent: 'flex-start'
                }}
              >
                <p>{getFileNamesToShow()}</p>
                <Button
                  size="sm"
                  type="button"
                  color="danger"
                  title="Remover arquivo(s) selecionado(s)"
                  onClick={handleClearFiles}
                  style={{
                    marginLeft: '0.5rem'
                  }}
                >
                  Limpar
                </Button>
                <Button
                  size="sm"
                  type="button"
                  color="success"
                  title="Adicionar arquivo(s) selecionado(s)"
                  onClick={handleSubmitedForm}
                  disabled={loading}
                >
                  {loading ? 'Aguarde...' : 'Fazer Upload'}
                </Button>
              </div>
            ) : null}
          </Row>
        </Form>
      </FileSelectorWrapper>

      {attachments && !!attachments.length && (
        <DivContainer>
          {attachments.map((att) => (
            <div
              key={att.id}
              title={att.name}
              style={{
                position: 'relative',
                width: 'auto',
                padding: '0.5rem',
                lineHeight: '1rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingRight: '1.5rem',
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center'
              }}
              className="attachment-name-container"
            >
              {isImageFile(att) ? (
                <img
                  src={getFileURL(att.path)}
                  width="auto"
                  height="200px"
                  alt={att.name}
                  style={{
                    borderRadius: '.3rem',
                    marginRight: '0.5rem',
                    marginBottom: '0.5rem'
                  }}
                />
              ) : (
                <img src={iconepast} width="auto" height="205px" />
              )}

              <div>
                <img
                  src={getIconFromFileExtension(
                    getFileExtensionFromName(att.name)
                  )}
                  width="24px"
                  height="24px"
                  alt={att.name}
                  style={{ marginRight: '0.5rem' }}
                />
                <a
                  href={getFileURL(att.path)}
                  target="_blank"
                  rel="noopener noreferrer"
                  download={true}
                  className="small line-height-0"
                >
                  {getFileNameFormatted(att.name)}
                  <img
                    src={iconedownloand}
                    width="24px"
                    height="24px"
                    alt={att.name}
                    style={{ marginLeft: '0.5rem' }}
                  />
                </a>
              </div>
              <IoMdCloseCircle
                type="button"
                onClick={() => handleDeleteAttachment(att.id)}
                className="delete-icon"
              />
            </div>
          ))}
        </DivContainer>
      )}
    </>
  );
};
