import React, { useEffect, useState } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Pagination from 'components/Pagination';
import Modal from '../../../components/Modal/NotificationModal';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';

import QueueService from '../service';

import {
  Input,
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label
} from 'reactstrap';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const QueueList = () => {
  const [queues, setQueues] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [showDisable, setShowDisable] = useState(false);
  const [searchQueues, setSearchQueues] = useState();

  const getQueues = (selectedPage = 1, search = '') => {
    setLoading(true);
    QueueService.getAllSeach(selectedPage, 10, search, showDisable)
      .then((response) => {
        const queueList = response.data.data;
        const quantity = response.data.totalRegisters;

        setQueues(queueList);
        setQuantity(quantity);
        setLoading(false);
      })
      .catch((err) => {
        //const { data } = error.response;
        toast.error(err.response.data.message || 'Erro ao listar filas!', {
          autoClose: 3000,
          closeOnClick: true
        });
        setLoading(false);
      });
  };

  useEffect(getQueues, [showDisable]);

  const toggleDisable = (id, disable) => {
    if (!disable) {
      disableQueue(id);
    } else {
      recoveryQueue(id);
    }
  };

  const disableQueue = (id) => {
    if (!window.confirm('Tem certeza que deseja desabilitar a fila?')) return;
    QueueService.delete(id)
      .then(() => {
        toast.info('Fila desabilitada com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
        getQueues();
        setActivePage(1);
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Ops algo errado aconteceu!', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const recoveryQueue = (id) => {
    QueueService.recovery(id)
      .then(() => {
        toast.info('Fila recuperada com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
        getQueues(activePage);
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Ops algo errado aconteceu!', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const getQueuesSearch = (event, selectedPage = 1) => {
    setSearchQueues(event.target.value);
    if (searchQueues) {
      getQueues(selectedPage, event.target.value);
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    getQueues(page);
  };

  const handleShowDisableds = (value) => {
    setShowDisable(value);
    if (value === false) setActivePage(1);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/queue/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Fila</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Fila"
                          type="text"
                          onChange={(event) => getQueuesSearch(event)}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="4" className="text-right mt-2">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={showDisable}
                            onChange={() => handleShowDisableds(!showDisable)}
                          />
                          <span>Mostrar desabilitadas</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={queues}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'strategy',
                      text: 'Estratégia',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'configs',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Configurações',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Modal name={row.name}>
                            <p>
                              <strong>Tempo de Toque: </strong>
                              {row.timeout ? 'sim' : 'Não'}
                            </p>
                            <p>
                              <strong>Anúncio Periódico: </strong>
                              {row.periodic_announce}
                            </p>
                            <p>
                              <strong>Freq. Anúncio Periódico: </strong>
                              {row.periodic_announce_frequency}
                            </p>
                            <p>
                              <strong>Pausa Automática: </strong>
                              {row.autopause === true ? 'Sim' : 'Não'}
                            </p>
                            <p>
                              <strong>Tocar quando ocupado: </strong>
                              {row.ringinuse === true ? 'Sim' : 'Não'}
                            </p>
                            <p>
                              <strong>Música de Espera: </strong>
                              {row.musiconhold}
                            </p>
                            <p>
                              <strong>Tempo entre Chamadas: </strong>
                              {row.wrapuptime}
                            </p>
                            <p>
                              <strong>Setor: </strong>
                              {row.centrodecusto.name}
                            </p>
                          </Modal>
                        );
                      }
                    },
                    {
                      dataField: 'color',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Cor',
                      // eslint-disable-next-line react/display-name
                      formatter: (rowContent, row) => {
                        return (
                          <div
                            style={{
                              marginLeft: '45%',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: `${row.color}`
                            }}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'disable',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Habilitar / Desabilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            checked={!row.disable}
                            id={row.name}
                            onChange={() => toggleDisable(row.id, row.disable)}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (rowContent, row) => {
                        return (
                          <Link to={'/admin/queue/form/' + row.id}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default QueueList;
