import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ReactButton from 'components/Buttons/ReactButton';

import LeadStageService from '../service';
import { ScreenPrompt } from 'components/ScreenPrompt';
import FormTooltip from 'components/Inputs/FormTooltip';

const LeadStagesForm = () => {
  const history = useHistory();
  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;

  const [loading, setLoading] = useState(false);
  const [inputName, setInputName] = useState();
  const [inputTime, setInputTime] = useState();
  const [isUpdate, setIsUpdate] = useState(false);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      LeadStageService.getById(id)
        .then(({ data }) => {
          const name = data.data.name;
          const time = data.data.time;
          setInputName(name);
          setInputTime(time);
          setIsUpdate(true);
        })
        .catch((error) => console.error('Erro no id', error));
    }
  }, [id]);

  useEffect(() => {
    if (inputName) {
      reset({ name: inputName, time: inputTime });
    }
  }, [inputName, reset, inputTime]);

  const onSubmit = async (data) => {
    data.time = Number(data.time);
    setLoading(true);
    try {
      const stage = isUpdate
        ? await LeadStageService.update(id, data)
        : await LeadStageService.create(data);
      toast.success(stage.data.message || 'Fase salva com sucesso', {
        autoClose: 3000,
        closeOnClick: true
      });

      backToListing();
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        'Erro ao salvar fase';
      toast.error(message, {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const backToListing = () => {
    history.push('/admin/crm/leadStages');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/crm/leadStages"
      />
      <Container className="mt--6" fluid>
        <Row className="mb-3">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Fase do lead</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">Nome*</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="name"
                          rules={{
                            required: 'Nome é obrigatório',
                            maxLength: {
                              value: 50,
                              message: 'Nome deve ter no máximo 50 caracteres'
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="name"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">
                          Tempo da fase
                          <FormTooltip text="O número colocado será representado por dias. Ex.: 1 é igual a 1 dia." />
                        </label>
                        <Controller
                          render={(props) => (
                            <Input
                              value={props.value}
                              onChange={props.onChange}
                              type="number"
                            />
                          )}
                          control={control}
                          name="time"
                          defaultValue="0"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="time"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <ReactButton
                      btnColor="confirmation"
                      type="submit"
                      disabled={loading}
                    >
                      Salvar
                    </ReactButton>

                    <ReactButton
                      btnColor="cancelation"
                      onClick={backToListing}
                      disabled={loading}
                    >
                      Cancelar
                    </ReactButton>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default LeadStagesForm;
