import styled from 'styled-components';

export const ButtonControlled = styled.div`
  position: sticky;
  margin-top: 2rem;

  .icon-image-style {
    display: inline-block;
    width: 2.5rem;
    height: 2.5rem;
    padding: 5px;
    margin-left: 0.5rem;
    border-radius: 100%;
  }

  .icon-Fi-Delete {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
`;
export const NotificationItemWrapper = styled.div`
  display: flex;
  position: relative;

  &:hover {
    background: #e0e0e0;
  }
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 2rem;
  border-radius: 0.25rem;
  cursor: pointer;
`;

export const ImageLogo = styled.img`
  width: 4rem;
  height: 4rem;
  min-width: 4rem;
  min-height: 4rem;
  border: 1px solid #ddd;
  border-radius: 100%;
  margin-top: -1rem;
  margin-right: 0.5rem;
`;

export const ToViewButton = styled.button`
  min-width: 0.7rem;
  height: 0.7rem;
  background: var(--primary);
  border: 1px var(--primary);
  border-radius: 100%;
  position: absolute;
  top: 2.5rem;
  right: 95%;

  padding-left: 0;
  &:hover {
    filter: brightness(0.8);
  }
`;
export const IconLogoWrapper = styled.div`
  background-color: ${(props) => props.color};
  position: absolute;
  margin: 1.5rem 1.5rem 0 3rem;
  height: 1.5rem;
  padding: 0.3rem;
  width: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
`;

export const IconLogo = styled.i`
  color: #ffffff;
  font-size: 0.8rem;
`;

export const SourceRequest = styled.h4`
  font-size: 0.8rem;
  margin-top: -0.8rem;
  text-align: justify;
`;
export const ContentGridDisplay = styled.div`
  display: flex;
  position: relative;
  margin-left: 0.4rem;
  flex-direction: column;
`;
export const ContentButtonDisplay = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 0.5rem;
`;
export const TimeCount = styled.span`
  font-size: 0.9rem;
  height: 20px;
  color: var(--primary);
`;
