import React, { useState } from 'react';
import AudioService from '../../service';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

export const AudioPlayer = ({ id }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [audio, setAudio] = useState(undefined);

  return (
    <Button
      onClick={() => {
        if (isPlaying && audio) {
          audio.pause();
          setIsPlaying(false);
        } else if (audio) {
          audio.play();
          setIsPlaying(true);
        } else {
          if (id) {
            AudioService.get(id)
              .then((res) => {
                if (!res.data.data.sound) {
                  toast.error('Áudio inválido');
                  return;
                }

                let newAudio = new Audio(
                  'data:audio/wav;base64,' + res.data.data.sound
                );
                setAudio(newAudio);
                newAudio.play();
                setIsPlaying(true);
              })
              .catch((err) => {
                console.log(err);
                toast.error(err, {
                  autoClose: 3000,
                  closeOnClick: true
                });
              });
          }
        }
      }}
      color={isPlaying ? 'danger' : 'success'}
      size="sm"
    >
      <i
        className={isPlaying ? 'fas fa-stop-circle' : 'fas fa-play-circle'}
      ></i>
    </Button>
  );
};
