import styled from 'styled-components';

export const CompDate = styled.div`
  display: flex;
  justify-content: center;

  .content-date-message-internal-chat {
    color: ${(props) => (props.fontColor ? props.fontColor : 'gray')};
    font-size: 0.8rem;
    border-radius: 7px;
    padding: 5px;
    background: ${(props) => (props.color ? props.color : 'gray')};
  }

  .content-date-message-internal-chat:first-child {
    margin-top: 12px;
  }
`;
