import { useState, useEffect } from 'react';
import { DEFAULT_PENALTY } from '../assets/constants';
export function useGetCurrentSelectedQueues({
  login,
  isUpdate,
  queues,
  controlSelectQueues
}) {
  const [selectedQueues, setSelectedQueues] = useState(null);

  useEffect(() => {
    const currentSelectedQueues = [];

    if (!login && !isUpdate) {
      if (queues !== []) {
        queues.forEach((queue) => {
          if (Array.isArray(controlSelectQueues)) {
            controlSelectQueues.forEach((currentQueue) => {
              if (currentQueue === queue.id) {
                currentSelectedQueues.push({
                  label: queue?.name,
                  value: queue?.id,
                  //add penalidade padrão por fila selecionada
                  penality: DEFAULT_PENALTY
                });
              }
            });
          }
        });
      }
      //pegar as que já estão setadas
      setSelectedQueues(currentSelectedQueues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlSelectQueues]);

  return {
    selectedQueues,
    setSelectedQueues
  };
}
