import { useCallback, useState } from 'react';

import { defaultLimit } from 'views/CRM/shared/utils/pagination';

import LeadTimeService from '../services/leadService';

export const useListLeads = ({ recordTypeId }) => {
  const [leads, setLeads] = useState(null);
  const [syntheticData, setSyntheticData] = useState(null);
  const [isLoadingsyntheticLeads, setIsLoadingsyntheticLeads] = useState(false);
  const [totalLeads, setTotalLeads] = useState(0);
  const [isLoadingLeads, setIsLoadingLeads] = useState(false);

  const getReportLeadTime = useCallback(
    (page = 1, params = {}) => {
      setIsLoadingLeads(true);

      LeadTimeService.getReportLeadTime(recordTypeId, params, {
        page,
        limit: defaultLimit
      })
        .then((response) => {
          setLeads(response.data.data);
          setTotalLeads(response.data.totalRegisters);
        })
        .finally(() => setIsLoadingLeads(false));
    },
    [recordTypeId]
  );

  const getReportSyntheticLeadTime = useCallback(
    (page = 1, params = {}) => {
      setIsLoadingsyntheticLeads(true);

      LeadTimeService.getReportSyntheticLeadTime(recordTypeId, params, {
        page,
        limit: defaultLimit
      })
        .then((response) => {
          setSyntheticData(response.data.data);
        })
        .finally(() => setIsLoadingsyntheticLeads(false));
    },
    [recordTypeId]
  );

  const clearLeads = useCallback(() => {
    setLeads(null);
    setTotalLeads(0);
    setIsLoadingLeads(false);
  }, []);

  return {
    leads,
    totalLeads,
    isLoadingLeads,
    clearLeads,
    getReportLeadTime,
    getReportSyntheticLeadTime,
    syntheticData,
    isLoadingsyntheticLeads
  };
};
