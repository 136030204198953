import React, { useState, useCallback, useEffect } from 'react';
import { Row } from 'reactstrap';

import Loader from 'components/Loader';

import { SyntheticOpportunityRevenue } from '../../../../components/Tables/SyntheticOpportunityRevenue';
import { useListOpportunities } from '../../../hooks/useListOpportunityRevenue';
import FilterOpportunityRevenueForm from '../FilterOpportunityTime';

const ReportOpportunityTime = ({
  selectedRecordTypeId,
  setSelectedRecordType,
  recordTypes
}) => {
  const [syntheticalData, setSyntheticalData] = useState([]);
  const [reportBy, setReportBy] = useState('complete');

  const {
    getReportSyntheticOpportunityRevenue,
    syntheticData,
    isLoadingSyntheticOpportunity
  } = useListOpportunities({
    recordTypeId: selectedRecordTypeId
  });

  const handleSearch = useCallback(
    (searchFilters) => {
      getReportSyntheticOpportunityRevenue(searchFilters);
    },
    [getReportSyntheticOpportunityRevenue]
  );

  useEffect(() => {
    if (syntheticData) {
      setSyntheticalData(syntheticData);
    }
  }, [syntheticData]);

  return (
    <>
      <FilterOpportunityRevenueForm
        handleSearch={handleSearch}
        recordTypeId={selectedRecordTypeId}
        setSelectedRecordType={setSelectedRecordType}
        recordTypes={recordTypes}
        setReportBy={setReportBy}
        analyticalData={syntheticData.opportunityCountByStageRevenue ?? []}
      />

      <hr />
      {syntheticalData ? (
        isLoadingSyntheticOpportunity ? (
          <div className="col-12">
            <Row className="justify-content-md-center">
              <Loader />
            </Row>
          </div>
        ) : (
          <div style={{ marginTop: '1rem' }}>
            {reportBy === 'month' ? (
              <>
                {syntheticData.opportunityCountByStageRevenue?.map((data) => {
                  return (
                    <SyntheticOpportunityRevenue
                      data={data.resultData}
                      month={data.month}
                      key={data.month}
                    />
                  );
                })}
              </>
            ) : (
              <SyntheticOpportunityRevenue
                data={syntheticData.opportunityCountByStageRevenue}
              />
            )}
          </div>
        )
      ) : null}
    </>
  );
};

export default ReportOpportunityTime;
