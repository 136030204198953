import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import ModalConfirmation from 'components/Modal/ConfirmationModal';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {
  individual_column_style,
  individual_header_style,
  icon_column_style,
  individual_icon_header_style
} from '../../../../../../../../assets/styles/table_custom_style';
import BootstrapTable from 'react-bootstrap-table-next';

import contactService from '../../../../../services/contactService';
import { toast } from 'react-toastify';

import './styles.css';
import { toBrazilianFullDate } from 'views/CRM/shared/utils/date';
import { VoiceProtocol } from 'views/Clients/list/components/VoiceProtocol';
import HistoryConversation from 'views/ServicePanel/components/AttendanceContent/components/Modal/HistoryConversation';
import { ClientProtocol } from 'views/Clients/list/components/ClientProtocol';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { useRecordTypesByUser } from 'views/RecordType/hooks/useRecordTypesByUser';

const ListContactsTable = ({ contacts, reloadContacts }) => {
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [contactId, setContactId] = useState(null);
  const history = useHistory();
  const { recordTypes, getRecordTypesByUser } = useRecordTypesByUser(
    entitiesName.PROTOCOL
  );

  useEffect(() => {
    getRecordTypesByUser();
  }, [getRecordTypesByUser]);

  const handleDeleteLead = useCallback(
    (id) => {
      contactService
        .deleteContact(id)
        .catch((err) => {
          const message =
            err?.response?.data?.message || 'Contato deletado com sucesso';
          toast.error(message);
        })
        .finally(() => {
          setOpenModalConfirmation(false);
          reloadContacts(1);
        });
    },
    [reloadContacts]
  );

  const editContact = (contact) => {
    history.push(
      `/admin/crm/contactForm/${contact.recordTypeId}/${contact.id}`
    );
  };

  const controlDeleteContact = (id) => {
    setContactId(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <ToolkitProvider
        data={contacts || []}
        keyField="id"
        columns={[
          {
            dataField: 'name',
            text: 'Nome',
            sort: true,
            style: individual_column_style,
            headerStyle: { ...individual_header_style, cursor: 'pointer' },
            formatter: (_, row) => {
              return (
                <span
                  className="contact-name"
                  onClick={() => {
                    editContact(row);
                  }}
                >
                  {row.name}
                </span>
              );
            }
          },
          {
            dataField: 'phone',
            text: 'Telefone',
            style: individual_column_style
          },
          {
            dataField: 'createdAt',
            text: 'Criado em',
            style: individual_column_style,
            headerStyle: { ...individual_header_style, cursor: 'pointer' },
            formatter: (row) => {
              if (row) return toBrazilianFullDate(row);
            }
          },
          {
            dataField: 'createdByName',
            text: 'Criado por',
            style: individual_column_style,
            headerStyle: { ...individual_header_style, cursor: 'pointer' }
          },
          {
            dataField: 'lastUpdatedAt',
            text: 'Última atualização',
            style: individual_column_style,
            headerStyle: { ...individual_header_style, cursor: 'pointer' },
            formatter: (row) => {
              if (row) return toBrazilianFullDate(row);
            }
          },
          {
            dataField: 'edit',
            text: 'Editar',
            style: icon_column_style,
            headerStyle: individual_icon_header_style,
            formatter: (_, row) => {
              return (
                <Button size="sm" color="info" onClick={() => editContact(row)}>
                  <li className="fas fa-user-edit"></li>
                </Button>
              );
            }
          },
          {
            dataField: 'voice',
            style: icon_column_style,
            headerStyle: individual_icon_header_style,
            text: 'Voz',
            formatter: (_, row) => {
              return (
                <VoiceProtocol
                  disabled={row.phone ? false : true}
                  telefone={row.phone}
                />
              );
            }
          },
          {
            dataField: 'chat',
            style: {
              ...icon_column_style,
              display: 'flex',
              justifyContent: 'center'
            },
            headerStyle: individual_icon_header_style,
            text: 'Chat',
            formatter: (_, row) => {
              return (
                <HistoryConversation
                  name="Histórico de atendimentos"
                  tooltipTitle="Histórico"
                  id={row.id}
                  isButton={true}
                  colorButton="success"
                  newIcon={true}
                />
              );
            }
          },
          {
            dataField: 'protocol',
            style: icon_column_style,
            headerStyle: individual_icon_header_style,
            text: 'Protocolos',
            formatter: (_, row) => {
              return (
                <ClientProtocol
                  recordTypes={recordTypes}
                  clientInfo={row.nome}
                  clientId={row.id}
                />
              );
            }
          },
          {
            dataField: 'delete',
            text: 'Excluir',
            style: icon_column_style,
            headerStyle: individual_icon_header_style,
            formatter: (_, row) => {
              return (
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => controlDeleteContact(row.id)}
                >
                  <li className="fas fa-trash"></li>
                </Button>
              );
            }
          }
        ]}
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
              classes="align-items-center"
            />
          </div>
        )}
      </ToolkitProvider>

      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={contactId}
        deleteResource={handleDeleteLead}
        message={'Tem certeza que deseja excluir o contato?'}
      />
    </>
  );
};

export default ListContactsTable;
