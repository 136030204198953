import styled from 'styled-components';

export const CommentsFormWrapper = styled.div`
  & > div > div.ql-toolbar {
    border: none;
    box-shadow: none;
  }
  & > div > div.ql-container > div {
    border: none;
    box-shadow: none;
  }
  & > div > div.ql-container {
    border: none;
    box-shadow: none;
  }
`;

export const SpanSignature = styled.span`
  font-size: 12px;
  font-style: italic;
  color: red;
`;

export const CheckboxSignatureWrapper = styled.div`
  height: auto;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const OptionSendSignatureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
`;

export const OptionSendSignatureDescription = styled.div`
  width: 80%;
`;

export const ButtonCCWrapper = styled.div`
  & > button {
    color: ${(el) => (el.color ? '#fff' : '#000000')};
    background-color: ${(el) => (el.color ? '#005ECD' : '#FFF')};
    border: none;
    border-color: '#ced4da';
    border-radius: 0;
  }
`;

export const InputGroupTextWrapper = styled.div`
  & > span {
    width: 8%;
    border-right: none;
    border-radius: 0px;
    border-left: none;
    box-shadow: none;
  }
`;
