import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import MainContainer from './MainContainer';
import broadcastListServiceSMS from '../../../../Report/broadcastListSMS/service';
import { toast } from 'react-toastify';

export const SMSBroadcastListDashboard = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [name, setName] = useState('');

  const today = new Date();

  // Primeiro dia do mês atual
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  // Formatar as datas como YYYY-MM-DD
  // const todayFormatted = today.toISOString().slice(0, 10);
  // const firstDayOfMonthFormatted = firstDayOfMonth.toISOString().slice(0, 10);

  const dataToSend = {
    idBroadCastList: id,
    // dateStart: firstDayOfMonthFormatted,
    // dateEnd: todayFormatted,
    reportType: 2
  };

  const getData = () => {
    broadcastListServiceSMS
      .getAllParams(1, 10, dataToSend, 2)
      .then((res) => {
        if (
          res.data.result.GroupByStatusMessage.length === 0 &&
          res.data.result.groupByMessage.length === 0
        ) {
          toast.info('Nenhum registro foi encontrado');
        }
        setData([res.data.result]);
        setName(res.data.result.name);
      })
      .catch((err) => {
        console.log('err', err);
        toast.error(
          err || 'Ocorreu um erro porfavor entre em contato com o suporte'
        );
      });
  };

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
    }, 1000 * 20);

    return () => clearInterval(interval);
  }, [id]);

  return <MainContainer name={name} data={data} />;
};
