import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'reactstrap';

import Pagination from 'components/Pagination';
import Loader from 'components/Loader';
import FilterLeadsForm from './components/FilterLeadsForm';
import ListLeadsTable from './components/ListLeadsTable';

import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { useListLeads } from '../../../hooks/useListLeads';

import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { useListLeadStagesByRecordType } from 'views/CRM/LeadStages/hooks/useListLeadStagesByRecordType';
import { Kanban } from 'views/CRM/Opportunity/list/components/OpportunitiesList/components/Kanban';
import { toast } from 'react-toastify';
import leadService from 'views/CRM/Lead/services/leadService';
import LeadService from '../../../services/leadService';

import ConvertLeadModal from 'views/CRM/Lead/form/LeadDetails/ConvertLeadModal';
import { formatCustomFieldsToDisplay } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { InfoCardLead } from './components/InfoCardLead';
import ChangeResponsibleModal from './components/ChangeResponsibleModal';

const LeadCRM = ({
  selectedRecordTypeId,
  setSelectedRecordType,
  recordTypes
}) => {
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({});
  const [showTable, setShowTable] = useState(true);
  const [controlFunction, setControlFunction] = useState(() => {});

  const { leadStages, getLeadStagesByRecordType } =
    useListLeadStagesByRecordType();

  const [isConvertLeadModalOpen, setIsConvertLeadModalOpen] = useState(false);
  const [lead, setLead] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [transferLeads, setTransferLeads] = useState(false);
  const [isChangeResponsibleModalOpen, setIsChangeResponsibleModalOpen] =
    useState(false);
  const [transferColumnsSearch, setTransferColumnsSearch] = useState({});

  useEffect(() => {
    if (!showTable) {
      getLeadStagesByRecordType(selectedRecordTypeId);
    }
  }, [getLeadStagesByRecordType, selectedRecordTypeId, showTable]);

  const {
    getLeads,
    getAllLeads,
    leads,
    totalLeads,
    isLoadingLeads,
    clearLeads
  } = useListLeads({
    recordTypeId: selectedRecordTypeId
  });

  const { customFields, getCustomFields } = useEntityCustomFieldsByRecordType(
    entitiesName.LEAD,
    selectedRecordTypeId
  );

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    clearLeads(clearLeads);
    setActivePage(1);
  }, [selectedRecordTypeId, clearLeads]);

  const handleSearch = useCallback(
    (searchFilters, getAll = false) => {
      setActivePage(1);
      setFilters(searchFilters);
      if (getAll) {
        getAllLeads(searchFilters);
        return;
      }
      getLeads(1, searchFilters);
    },
    [getLeads, getAllLeads]
  );

  const handleTransferSearch = () => {
    handleSearch(transferColumnsSearch);
  };

  const handlePageChange = (newPage) => {
    setActivePage(newPage);
    getLeads(newPage, filters);
  };

  const reloadLeads = useCallback(() => {
    getLeads(1, {});
  }, [getLeads]);

  const onTableChange = async (leadId, faseId, isConversion) => {
    if (isConversion) {
      const leadData = (await LeadService.getById(selectedRecordTypeId, leadId))
        .data.data;

      const formattedLead = formatCustomFieldsToDisplay(leadData, customFields);
      setLead(formattedLead);
      setIsConvertLeadModalOpen(true);

      return new Promise((resolve, reject) => {
        resolve(true);
        reject(false);
      });
    }
    return leadService
      .updateStage(leadId, { stageId: faseId })

      .then(() => {
        // TODO: Melhorar a performance da busca,
        // acho melhor atualizar apenas o lead que mudou de fase ao invés de refazer a busca pra atualizar o Kanban
        handleSearch({ ...filters }, false);
        return true;
      })
      .catch((err) => {
        console.log('!!!err', err);
        toast.error('Ocorreu um erro ao atualizar a fase');
        return false;
      });
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x.id !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const allRows = rows.map((r) => {
      return r;
    });
    if (isSelect) {
      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <>
      <ConvertLeadModal
        isModalOpen={isConvertLeadModalOpen}
        onModalToggle={setIsConvertLeadModalOpen}
        lead={lead}
        isKanban={true}
        callBackFunc={controlFunction}
      />
      <FilterLeadsForm
        handleSearch={handleSearch}
        customFields={customFields}
        isLoadingLeads={isLoadingLeads}
        recordTypeId={selectedRecordTypeId}
        showTable={showTable}
        setShowTable={setShowTable}
        setTransferLeads={setTransferLeads}
        transferLeads={transferLeads}
        setIsChangeResponsibleModalOpen={setIsChangeResponsibleModalOpen}
        leads={leads}
        selectedRows={selectedRows}
        totalLeads={totalLeads}
        setSelectedRecordType={setSelectedRecordType}
        recordTypes={recordTypes}
        setTransferColumnsSearch={setTransferColumnsSearch}
      />

      <Row style={{ height: '33.75rem' }} className="mt-2">
        {isLoadingLeads ? (
          <div className="col-12">
            <Row className="justify-content-md-center">
              <Loader />
            </Row>
          </div>
        ) : showTable ? (
          <ListLeadsTable
            leads={leads}
            reloadLeads={reloadLeads}
            selectedRows={selectedRows}
            handleOnSelect={handleOnSelect}
            handleOnSelectAll={handleOnSelectAll}
            transferLeads={transferLeads}
            customFields={customFields}
          />
        ) : (
          <Kanban
            onTableChange={onTableChange}
            data={leads}
            opportunityStages={leadStages ?? []}
            setControlFunction={setControlFunction}
            InfoCard={InfoCardLead}
          ></Kanban>
        )}
      </Row>
      {showTable && (
        <div style={{ marginTop: transferLeads ? '8rem' : '3rem' }}>
          <Pagination
            activePage={activePage}
            totalItemsCount={totalLeads}
            onChange={handlePageChange.bind(this)}
          />
        </div>
      )}
      <ChangeResponsibleModal
        isModalOpen={isChangeResponsibleModalOpen}
        onModalToggle={setIsChangeResponsibleModalOpen}
        leadIds={selectedRows}
        callback={handleTransferSearch}
      />
    </>
  );
};

export default LeadCRM;
