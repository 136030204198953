import { useEffect } from 'react';
import store from 'store';
import { useSelector } from 'react-redux';

import { loadConfiguration } from '../../../store/action/systemConfiguration';

export function ConfigurationContainer(props) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  useEffect(() => {
    store.dispatch(loadConfiguration());
  }, []);

  return systemConfiguration.loading ? null : props.children;
}
