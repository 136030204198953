import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Card, CardHeader, CardBody } from 'reactstrap';
import { getMonthAndYearName } from '../../../shared/utils/monthNameHelper';

export const SyntheticOpportunityRevenue = ({ data, month }) => {
  const groupByAverageTime = data;

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });
  const getGraphicResponsibleLabels = () => {
    if (!groupByAverageTime) return [];
    return groupByAverageTime?.map(
      (item) => `${item.name} (${formatter.format(item.totalAmount)})`
    );
  };

  const getGraphicResponsibleData = () => {
    if (!groupByAverageTime) return [];
    const items = groupByAverageTime?.map((item) =>
      parseFloat(item.totalAmount).toFixed(2)
    );

    const colors = groupByAverageTime?.map((item) =>
      item.stageType === 'CLOSED_LOST'
        ? '#F5365C'
        : item.stageType === 'CLOSED_WON'
        ? '#00A17C'
        : item.stageType === 'TOTAL'
        ? '#5172CB'
        : '#11CDEF'
    );

    return {
      items,
      colors
    };
  };

  return (
    <>
      {groupByAverageTime && groupByAverageTime.length > 0 ? (
        <Card>
          <CardHeader>
            <h6 className="surtitle">Visão Geral</h6>
            <h5 className="h3 mb-0">
              {month
                ? `Previsão de Receita referente à ${getMonthAndYearName(
                    month
                  )}`
                : 'Previsão total de receita por período completo'}
            </h5>
          </CardHeader>
          <CardBody>
            <Bar
              data={{
                labels: getGraphicResponsibleLabels(),
                datasets: [
                  {
                    data: getGraphicResponsibleData().items,
                    backgroundColor: getGraphicResponsibleData().colors,
                    maxBarThickness: 10
                  }
                ]
              }}
              options={{
                showLines: true,
                scales: {
                  yAxes: [
                    {
                      gridLines: {
                        color: '#e9ecef',
                        zeroLineColor: '#e9ecef'
                      },
                      ticks: {
                        callback: function (value) {
                          if (!(value % 10)) {
                            return value;
                          }
                        }
                      }
                    }
                  ],
                  xAxes: [
                    {
                      maxBarThickness: 100
                    }
                  ]
                },
                tooltips: {
                  callbacks: {
                    label: function (item, data) {
                      var label = data.datasets[item.datasetIndex].label || '';
                      var yLabel = item.yLabel;
                      var content = '';
                      if (data.datasets.length > 1) {
                        content += label;
                      }
                      content += yLabel;
                      return content;
                    }
                  }
                },
                legend: {
                  display: false
                }
              }}
              className="chart-canvas"
              id="chart-bars"
            />
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardHeader>
            <h6 className="surtitle">Visão Geral</h6>
            <h5 className="h3 mb-0">Total de receita por período completo</h5>
          </CardHeader>
          <CardBody>
            <h2>Não há dados disponíveis</h2>
          </CardBody>
        </Card>
      )}
    </>
  );
};
