import styled from 'styled-components';

export const CompTime = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.paramAlign === 'left' ? 'flex-start' : 'flex-end'};
  font-size: 0.8rem;
  margin-bottom: -25px;
  color: #000;
`;
