import { useEffect, useState } from 'react';
import { differenceInSeconds } from 'date-fns';

import {
  getHoursFromSeconds,
  getMinutesFromSeconds,
  formatDigit
} from 'views/Dashboard/Agent/utils/helps';

const Timer = ({ date, render }) => {
  const [upTime, setUpTime] = useState('00:00:00');

  useEffect(() => {
    const intervalID = setInterval(() => {
      const differenceFromNow = differenceInSeconds(
        new Date(),
        new Date(date || Date.now())
      );
      const [hours, secondsLeftFromHours] = getHoursFromSeconds(
        differenceFromNow
      );
      const [minutes, secondsLeftFromMinutes] = getMinutesFromSeconds(
        secondsLeftFromHours
      );
      const seconds = Math.floor(secondsLeftFromMinutes);

      setUpTime(
        `${formatDigit(hours)}:${formatDigit(minutes)}:${formatDigit(seconds)}`
      );
    }, 1000);

    return () => clearInterval(intervalID);
  }, [date]);

  return render(upTime);
};

export default Timer;
