import React, { useEffect, useState } from 'react';
import InboundRouteService from '../service';
import ContextService from '../../Context/service';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Pagination from 'components/Pagination';
import ModalConfirmation from '../../../components/Modal/ConfirmationModal';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import ReactLoader from 'components/Loader';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';

const InboundRouteList = () => {
  const [inboundRoutes, setInboundRoutes] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const [timeoutID, setTimeoutID] = useState(null); // Timeout da busca por texto
  const [idResource, setidResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [contexts, setContexts] = useState([]);

  useEffect(() => {
    handleGetInboundRoutes();
  }, []);

  useEffect(() => {
    handleGetContexts();
  }, []);

  const handleGetInboundRoutes = (
    selectedPage = 1,
    querySearch = '',
    params = {}
  ) => {
    setLoading(true);
    // console.log('param', params);
    InboundRouteService.getAll(
      false,
      selectedPage,
      10,
      false,
      querySearch,
      params
    )
      .then((res) => {
        const configs = res.data.data;

        const quantity = res.data.totalRegisters;
        const newActivePage = selectedPage;
        setInboundRoutes(configs);
        // console.log('configs', configs);
        setQuantity(quantity);
        setActivePage(newActivePage);
      })
      .catch(() => {
        toast.error('Erro ao carregar rotas', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (activePage) => handleGetInboundRoutes(activePage);

  const handleOnSearch = (event) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const q = event.target.value;
    setTimeoutID(
      setTimeout(() => {
        handleGetInboundRoutes(1, q);
      }, 1000)
    );
  };

  const handleGetContexts = () => {
    ContextService.getAll(null, null, false, '', {
      type: 'ENTRADA'
    })
      .then((response) => {
        setContexts(response.data.data);
      })
      .catch(() => {
        console.log('Error getting contexts');
      });
  };

  const handleFilterByContext = (event) => {
    const value = event.target.value;
    if (value) {
      return handleGetInboundRoutes(1, '', { context: value });
    }
    return handleGetInboundRoutes();
  };

  const handleDeleteInboundRoute = (id) => {
    InboundRouteService.delete(id)
      .then(() => {
        handleGetInboundRoutes();
        toast.success('Rota excluída!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .catch(() => {
        toast.error('Erro ao deletar rota', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        handleGetInboundRoutes();
      });
  };

  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/inboundRoute/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Rotas de entrada</h3>
                </CardHeader>

                <Row className="mt-3 mx-2">
                  <Col md="6">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Pesquisar por rota"
                        type="text"
                        defaultValue=""
                        onChange={handleOnSearch}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={{ size: 4, offset: 2 }}>
                    <InputGroup>
                      <Input
                        type="select"
                        defaultValue=""
                        onChange={handleFilterByContext}
                      >
                        <option value="">Filtrar por Contexto</option>
                        {contexts.map((context) => (
                          <option key={context.id} value={context.id}>
                            {context.name}
                          </option>
                        ))}
                      </Input>
                    </InputGroup>
                  </Col>
                </Row>

                <ToolkitProvider
                  data={inboundRoutes}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'route',
                      text: 'Rota',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'context.name',
                      text: 'Contexto',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'input_type',
                      text: 'Tipo de Entrada',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        const [inbound_route] = row.inputs.filter(
                          (item) => !item.schedule
                        );
                        switch (inbound_route.type) {
                          case 'IVR':
                            return 'URA';
                          case 'AUDIO':
                            return 'Áudio';
                          case 'CALLBACK':
                            return 'Callback';
                          case 'QUEUE':
                            return 'Fila';
                          case 'EXTENSION':
                            return 'Ramal';
                          case 'GENERIC_EXTENSION':
                            return 'Ramal generico';
                          case 'CUSTOMIZED':
                            return 'Customizado';
                          default:
                            return 'indefinido';
                        }
                      }
                    },
                    {
                      dataField: 'input',
                      text: 'Entrada',
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        const [inbound_route] = row.inputs.filter((item) => {
                          return !item.schedule;
                        });
                        const key = inbound_route.type.toLowerCase();
                        if (typeof inbound_route[key] === 'undefined') {
                          return;
                        } else {
                          return (
                            inbound_route[key].name ||
                            inbound_route[key].extension_number
                          );
                        }
                      }
                    },
                    {
                      dataField: 'input_outside',
                      text: 'Tipo entrada fora do horário',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        const [outside_route] = row.inputs.filter(
                          (item) => item.schedule
                        );
                        return outside_route ? outside_route.type : '';
                      }
                    },
                    {
                      dataField: 'outside_input',
                      text: 'Entrada fora de horário',
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        const [inbound_route] = row.inputs.filter(
                          (item) => item.schedule
                        );
                        if (!inbound_route) return '';
                        const key = inbound_route.type.toLowerCase();
                        return (
                          inbound_route[key].name ||
                          inbound_route[key].extension_number
                        );
                      }
                    },
                    {
                      dataField: 'schedule_name',
                      text: 'Programação de horário',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        const [outside_route] = row.inputs.filter(
                          (item) => item.schedule
                        );
                        return outside_route ? outside_route.schedule.name : '';
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link to={'/admin/inboundRoute/form/' + row.id}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },

                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            onClick={() => controlDelete(row.id)}
                            size="sm"
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={handleDeleteInboundRoute}
        message={'Tem certeza que deseja excluir a rota de entrada?'}
      />
    </>
  );
};

export default InboundRouteList;
