import { useState, useEffect } from 'react';
import ContextService from '../../../Context/service';

export function useGetContexts() {
  const [contexts, setContexts] = useState([]);

  useEffect(() => {
    ContextService.getAll()
      .then((response) => {
        setContexts(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);

  return {
    contexts,
    setContexts
  };
}
