import React from 'react';
import { ContactAttemptTimeline } from '../ContactAttemptTimeLine';
import DefaultModal from 'components/Modal/DefaultModal';

const ContactAttemptTimelineModal = ({
  isModalOpen,
  onModalToggle,
  entityId,
  entityType
}) => {
  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Histórico de tentativas de contato"
      showCloseButton={false}
      modalContainerClasses="modal-md"
    >
      <ContactAttemptTimeline entityId={entityId} entityType={entityType} />
    </DefaultModal>
  );
};

export default ContactAttemptTimelineModal;
