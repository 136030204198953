import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';

import useAgentEvents from 'views/Monitoring/Queue/MonitoringScreen/hooks/useAgentEvents';

import { queueAgentStatus } from 'views/Monitoring/Queue/MonitoringScreen/utils/states';
import { statusColors } from 'views/Monitoring/Queue/MonitoringScreen/utils/state_colors';

import ChartLabel from '../ChartLabel';

const LABELS = {
  [queueAgentStatus.AVAILABLE]: 'Agentes livres',
  [queueAgentStatus.RING]: 'Agentes ocupados',
  [queueAgentStatus.PAUSE]: 'Agentes em pausa',
  [queueAgentStatus.RINGING]: 'Agentes chamando',
  [queueAgentStatus.UNPLACED]: 'Ramais offline'
};

const AgentsStatusChart = ({ title, queuesToMonit }) => {
  const [data, setData] = useState([]);
  const { agents } = useAgentEvents();
  const labels = useRef(Object.values(LABELS));
  const backgroundColors = useRef(
    Object.keys(LABELS).map((key) => statusColors[key])
  );

  const groupByAgent = useCallback((allCardsData) => {
    if (!allCardsData) return [];

    const groupedAgents = {};
    allCardsData.forEach((item) => {
      if (!groupedAgents[item.agent.usr.login]) {
        groupedAgents[item.agent.usr.login] = item;
        groupedAgents[item.agent.usr.login].sessions = [];
      }

      groupedAgents[item.agent.usr.login].sessions.push(item.agent.queue);
    });

    const cardDataByAgentDTO = [];
    for (const key in groupedAgents) {
      cardDataByAgentDTO.push(groupedAgents[key]);
    }

    return cardDataByAgentDTO;
  }, []);

  const queueMembers = useMemo(() => {
    return groupByAgent(agents || []).filter((item) =>
      queuesToMonit.includes(Number(item.agent.queue_id))
    );
  }, [groupByAgent, agents, queuesToMonit]);

  useEffect(() => {
    const getCountByState = (statusCode) => {
      const items = queueMembers.filter((item) => item.state === statusCode);

      return items.length;
    };

    setData(Object.keys(LABELS).map(getCountByState));
  }, [queueMembers]);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4" className="mb-0">
          {title}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col xl="12">
            <div
              style={{
                height: '30px',
                display: 'flex'
              }}
            >
              {data.map((item, index) => (
                <div
                  key={index}
                  style={{
                    width: `${Math.round((item / queueMembers.length) * 100)}%`,
                    height: '100%',
                    backgroundColor: backgroundColors.current[index]
                  }}
                />
              ))}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <div
              style={{
                display: 'flex',
                columnGap: '8px'
              }}
            >
              {labels.current.map((label, index) => (
                <ChartLabel
                  key={index}
                  label={label}
                  value={data[index]}
                  color={backgroundColors.current[index]}
                />
              ))}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default AgentsStatusChart;
