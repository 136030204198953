import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import React from 'react';
import { individual_column_style } from 'assets/styles/table_custom_style';
import { broadcastTypeOfClosingConversationOptions } from 'views/ChatBroadcastList/utils';

export const Analytical = ({ data }) => {
  const status = {
    enqueued: 'Na fila',
    sent: 'Enviado',
    delivered: 'Entregue',
    read: 'Lida',
    failed: 'Falhou'
  };

  const getTipo = (id) => {
    const lista = broadcastTypeOfClosingConversationOptions.find((lista) => {
      return lista.value === id;
    });

    return lista?.label || '';
  };

  return (
    <ToolkitProvider
      data={data}
      keyField="id"
      columns={[
        {
          dataField: 'nome_lista_de_transmissao',
          text: 'Lista de transmissão',
          sort: true,
          style: individual_column_style
        },
        {
          dataField: 'tipo_de_lista',
          text: 'Tipo de Lista',
          sort: true,
          style: individual_column_style,
          formatter: (_, row) => {
            return getTipo(row.tipo_de_lista);
          }
        },
        {
          dataField: 'nome_atendente',
          text: 'Usuário',
          sort: true,
          style: individual_column_style
        },
        {
          dataField: 'cliente_nome',
          text: 'Cliente',
          sort: true,
          style: individual_column_style
        },

        {
          dataField: 'cliente_telefone',
          text: 'Telefone',
          sort: true,
          style: individual_column_style
        },
        {
          dataField: 'ultima_msg_cliente',
          text: 'Resposta do Cliente',
          sort: true,
          style: individual_column_style
        },
        {
          dataField: 'observacao',
          text: 'Observação',
          sort: true,
          style: individual_column_style
        },
        {
          dataField: 'hora_criacao',
          text: 'Hora da criação',
          sort: true,
          style: individual_column_style,
          formatter: (_, row) => {
            if (row.hora_criacao) {
              const date = new Date(row.hora_criacao);
              return `${date.toLocaleDateString(
                'pt-br'
              )} ${date.toLocaleTimeString('pt-br')}`;
            }
            return '-';
          }
        },
        {
          dataField: 'hora_do_atendimento',
          text: 'Início da conversa',
          sort: true,
          style: individual_column_style,
          formatter: (_, row) => {
            if (row.hora_do_atendimento) {
              const date = new Date(row.hora_do_atendimento);
              return `${date.toLocaleDateString(
                'pt-br'
              )} ${date.toLocaleTimeString('pt-br')}`;
            }
            return '-';
          }
        },

        {
          dataField: 'status_da_mensagem',
          text: 'Status Da mensagem',
          sort: true,
          style: individual_column_style,
          formatter: (_, row) => {
            return status[row.status_da_mensagem] ?? '-';
          }
        },
        {
          dataField: 'situacao',
          text: 'Situação da conversa',
          sort: true,
          style: individual_column_style
        }
      ]}
    >
      {(props) =>
        props ? (
          <div style={{ overflow: 'auto' }}>
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
            />
          </div>
        ) : (
          ''
        )
      }
    </ToolkitProvider>
  );
};
