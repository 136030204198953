import DefaultModal from '../../../../../components/Modal/DefaultModal';
import React, { useCallback, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Service from '../../service';
import { toast } from 'react-toastify';
import { Row, Form, Col, Input } from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';

const UpdateTag = ({ isModalOpen, onModalToggle, idTag, render, ...props }) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  const { control, handleSubmit, errors, reset } = useForm();

  useEffect(() => {
    if (idTag) {
      Service.get(idTag)
        .then((res) => {
          const getTag = res.data;
          const dataUpdateTag = {
            name: getTag.name
          };
          reset(dataUpdateTag);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar Tag', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [idTag, reset]);

  const onSubmit = async (data) => {
    try {
      let message;
      const dataToForm = { ...data };
      const status = await Service.update(idTag, dataToForm);

      if (status.data.toUpperCase() === 'success'.toUpperCase()) {
        message = 'Tag atualizada com sucesso';
        toast.success(message, {
          autoClose: 2000,
          closeOnClick: true
        });
        closeModal();
      }
    } catch (err) {
      toast.error(' Erro ao atualizar tag', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      render();
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Atualizar Tag"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col className="px-0">
              <Col sm="12" className="mb-3">
                <label className="form-control-label">Nome*</label>
                <Controller
                  as={Input}
                  control={control}
                  name="name"
                  rules={{
                    required: 'Campo obrigatório'
                  }}
                  defaultValue=""
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Col>
          </Row>
          <hr />

          <ReactButton btnColor="confirmation" type="submit">
            Salvar
          </ReactButton>
          <ReactButton btnColor="cancelation" onClick={closeModal}>
            Voltar
          </ReactButton>
        </Form>
      </>
    </DefaultModal>
  );
};

export default UpdateTag;
