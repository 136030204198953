import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

const GraphicOpportunitiesByResponsible = ({
  opportunitiesCountByResponsible
}) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const getGraphicResponsibleLabels = () => {
    if (!opportunitiesCountByResponsible) return [];
    return opportunitiesCountByResponsible?.map(
      (item) => `${item.responsibleName} (${item.quantity})`
    );
  };

  const getGraphicResponsibleData = () => {
    if (!opportunitiesCountByResponsible) return [];
    return opportunitiesCountByResponsible?.map((item) => item.quantity);
  };

  return (
    <div className="chart mt-5">
      <Bar
        data={{
          labels: getGraphicResponsibleLabels(),
          datasets: [
            {
              data: getGraphicResponsibleData(),
              backgroundColor: systemConfiguration?.primary_color,
              maxBarThickness: 10
            }
          ]
        }}
        options={{
          showLines: true,
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: '#e9ecef',
                  zeroLineColor: '#e9ecef'
                },
                ticks: {
                  callback: function (value) {
                    if (!(value % 10)) {
                      return value;
                    }
                  }
                }
              }
            ],
            xAxes: [
              {
                maxBarThickness: 100
              }
            ]
          },
          tooltips: {
            callbacks: {
              label: function (item, data) {
                var label = data.datasets[item.datasetIndex].label || '';
                var yLabel = item.yLabel;
                var content = '';
                if (data.datasets.length > 1) {
                  content += label;
                }
                content += yLabel;
                return content;
              }
            }
          },
          legend: {
            display: false
          }
        }}
        className="chart-canvas"
        id="chart-bars"
      />
    </div>
  );
};

export default GraphicOpportunitiesByResponsible;
