import styled from 'styled-components';

export const ChatMessageAudioSelectorWrapper = styled.div`
  width: 100%;
  margin-top: 10px;

  .audio-button-select-style {
    height: 30px;
    width: 100%;
    max-width: 300px;
    border-radius: 15px;
    ${(props) => props.color && `background: ${props.color};`}
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: Roboto, sans-serif;
    margin-top: 10px;
    border: 0;
    cursor: pointer;

    &&:focus {
      border: 0;
    }
  }
`;
