import { format } from 'date-fns';

export const now = new Date();
export const firstDayMonth = new Date(now.getFullYear(), now.getMonth(), 1);

export const range = {
  dateBegin: format(firstDayMonth, 'LL-dd-yyyy HH:mm:ss'),
  dateEnd: format(now, 'LL-dd-yyyy HH:mm:ss')
};

export const rangeProtocol = {
  dateBegin: format(firstDayMonth, 'yyyy-LL-dd'),
  dateEnd: format(now, 'yyyy-LL-dd')
};

export const rangeChat = {
  dataInicial: format(firstDayMonth, 'yyyy-LL-dd'),
  dataFinal: format(now, 'yyyy-LL-dd'),
  group: 'fila'
};

export const synthetic = {
  type: 'detailedsynthetic',
  query: {
    groupby: 'fila',
    disable: false,
    ...range
  }
};

export const rangeCalls = {
  groupby: 'fila',
  disable: false,
  ...range
};

export const barChartConfig = (legend = true) => {
  return {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: '#e9ecef',
              zeroLineColor: '#e9ecef'
            },
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              }
            }
          }
        ],
        xAxes: [
          {
            maxBarThickness: 100
          }
        ]
      },

      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || '';
            var yLabel = item.yLabel;
            var content = '';
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          }
        }
      },
      legend: {
        display: legend,
        position: 'top'
      }
    }
  };
};
