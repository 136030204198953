module.exports = function getChannelsFromSelect(channels) {
  const channelsFromSelect = [];
  Object.keys(channels).forEach((key) => {
    const channel = channels[key];
    if (key === 'instagram' && channel?.id) {
      channelsFromSelect.push({
        value: channel.id,
        label: channel.nome_canal
      });
    }

    if (key === 'facebook' && channel?.id) {
      channelsFromSelect.push({
        value: channel.id,
        label: channel.nome_canal
      });
    }

    if (key === 'whatsapp') {
      channels[key].forEach((whatsappChannel) => {
        channelsFromSelect.push({
          value: whatsappChannel.id,
          label: whatsappChannel.nome_canal
        });
      });
    }

    if (key === 'telegram' && channel?.id) {
      channelsFromSelect.push({
        value: channel.id,
        label: channel.nome_canal
      });
    }

    if (key === 'webchat' && channel?.id) {
      channelsFromSelect.push({
        value: channel.id,
        label: channel.nome_canal
      });
    }

    if (key === 'sms' && channel?.id) {
      channelsFromSelect.push({
        value: channel.id,
        label: channel.nome_canal
      });
    }
  });

  return channelsFromSelect;
};
