import { useCallback, useState } from 'react';

import { defaultLimit } from 'views/CRM/shared/utils/pagination';

import OpportunityService from '../services/opportunityService';

export const useListOpportunities = ({ recordTypeId }) => {
  const [opportunities, setOpportunities] = useState(null);
  const [totalOpportunities, setTotalOpportunities] = useState(0);
  const [isLoadingOpportunities, setIsLoadingOpportunities] = useState(false);

  const getOpportunities = useCallback(
    (page = 1, params = {}) => {
      setIsLoadingOpportunities(true);

      OpportunityService.getOpportunities(recordTypeId, params, {
        page,
        limit: defaultLimit
      })
        .then((response) => {
          setOpportunities(response.data.data);
          setTotalOpportunities(response.data.totalRegisters);
        })
        .finally(() => setIsLoadingOpportunities(false));
    },
    [recordTypeId]
  );

  const getAllOpportunities = useCallback(
    (params = {}) => {
      setIsLoadingOpportunities(true);

      OpportunityService.getAllOpportunities(recordTypeId, params)
        .then((response) => {
          setOpportunities(response.data.data);
          setTotalOpportunities(response.data.totalRegisters);
        })
        .finally(() => setIsLoadingOpportunities(false));
    },
    [recordTypeId]
  );

  const clearOpportunities = useCallback(() => {
    setOpportunities(null);
    setTotalOpportunities(0);
    setIsLoadingOpportunities(false);
  }, []);

  return {
    opportunities,
    totalOpportunities,
    isLoadingOpportunities,
    getOpportunities,
    clearOpportunities,
    getAllOpportunities
  };
};
