import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';

import { useConectionSocketAdmConversation } from '../../../../../hooks/useSocketAdmConversations';
import { Card, CardBody, CardTitle, Row, Col } from 'reactstrap';

const ShowUsers = ({ title, icon }) => {
  const { socketAdm } = useConectionSocketAdmConversation();
  const { socketConversation } = useConversationConnectionSocketForMonitoring();
  const [loggedAttendants, setLoggedAttendants] = useState([]);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const primary_color = systemConfiguration.primary_color;

  useEffect(() => {
    socketConversation.emit('lista_atendentes_logados');
    socketAdm.on('atendentes_logados', (data) => {
      setLoggedAttendants(data.atendentes);
    });
  }, [socketConversation, socketAdm]);

  return (
    <Card
      className="card-stats"
      style={{
        backgroundColor: primary_color
      }}
    >
      <CardBody>
        <Row>
          <div className="col mt-3 mb-3">
            <CardTitle tag="h5" className="text-uppercase text-white mb-0">
              {title}
            </CardTitle>
            <span className="h2 font-weight-bold text-white mb-0">
              {loggedAttendants.length}
            </span>
          </div>
          <Col className="col-auto mt-3">
            <div className="icon icon-shape bg-white rounded-circle shadow">
              <i className={icon} style={{ color: primary_color }} />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ShowUsers;
