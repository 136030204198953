export const formatDate = (rangeDate) => {
  return rangeDate.toLocaleDateString('pt-br').split('/').reverse().join('-');
};

export const barChartConfig = (legend = true) => {
  return {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: '#e9ecef',
              zeroLineColor: '#e9ecef'
            },
            ticks: {
              callback: function (value) {
                if (!(value % 10)) {
                  //return '$' + value + 'k'
                  return value;
                }
              }
            }
          }
        ],
        xAxes: [
          {
            maxBarThickness: 100
          }
        ]
      },

      tooltips: {
        callbacks: {
          label: function (item, data) {
            var label = data.datasets[item.datasetIndex].label || '';
            var yLabel = item.yLabel;
            var content = '';
            if (data.datasets.length > 1) {
              content += label;
            }
            content += yLabel;
            return content;
          }
        }
      },
      legend: {
        display: legend,
        position: 'top'
      }
    }
  };
};

export const barChart = {
  options: {
    scales: {
      yAxes: [
        {
          gridLines: {
            color: '#e9ecef',
            zeroLineColor: '#e9ecef'
          },
          ticks: {
            callback: function (value) {
              if (!(value % 10)) {
                //return '$' + value + 'k'
                return value;
              }
            }
          }
        }
      ]
    },
    tooltips: {
      callbacks: {
        label: function (item, data) {
          var label = data.datasets[item.datasetIndex].label || '';
          var yLabel = item.yLabel;
          var content = '';
          if (data.datasets.length > 1) {
            content += label;
          }
          content += yLabel;
          return content;
        }
      }
    }
  }
};

export const ringChart = {
  options: {
    responsive: true,
    legend: {
      display: true,
      position: 'bottom'
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  }
};

export const weekDays = [
  'domingo',
  'segunda-feira',
  'terça-feira',
  'quarta-feia',
  'quinta-feira',
  'sexta-feira',
  'sábado'
];
