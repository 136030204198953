import styled from 'styled-components';

//any-message
//any-message:after
export const ClientContent = styled.div`
  position: relative;
  margin-left: 20px;
  margin-bottom: 20px;
  padding: 10px 10px 30px 10px;
  background-color: #dbdbdb;

  width: fit-content;
  min-width: 200px;

  max-width: 60%;
  min-height: 100px;
  font-weight: 400;
  text-align: left;
  border-radius: 10px;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #dbdbdb;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    left: -15px;
  }

  .g4-link {
    word-wrap: break-word;
  }
`;

//.any-timestamp
export const ClientTimestamp = styled.div`
  position: absolute;
  font-size: 0.85em;
  font-weight: 400;
  bottom: 5px;
  right: 10px;
`;

//.any-name
export const ClientName = styled.div`
  margin-bottom: 5px;
  font-weight: 600;
`;

//.user-timestamp
export const UserTimestamp = styled.div`
  position: absolute;
  font-size: 0.85em;
  font-weight: 400;
  bottom: 5px;
  right: 10px;
`;

//.user-name
export const UserName = styled.div`
  margin-bottom: 5px;
  font-weight: 600;
`;

//.image-content
export const ImageContent = styled.div`
  width: 100%;
  text-align: center;
  justify-content: center;
`;

//.user-message
//.user-message:after
export const UserContent = styled.div`
  ${(props) => props.color && `background-color: ${props.color};`}

  position: relative;
  margin-bottom: 20px;
  margin-left: calc(100% - 62%);

  padding: 10px 10px 30px 10px;
  color: white;

  width: fit-content;
  min-width: 200px;

  font-weight: 400;
  min-height: 100px;
  text-align: left;
  border-radius: 10px;

  ${ImageContent} {
    max-width: 60%;
    margin-left: auto;
    margin-right: 20px;
  }

  &:after {
    ${(props) => props.color && `border-bottom: 15px solid ${props.color};`}
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    color: white;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    bottom: 0;
    right: -15px;
  }

  .g4-link {
    word-wrap: break-word;
  }
`;

//  ${(props) => (props.resizable ? console.log('true') : console.log('false'))}

//.message-content
export const MessageContent = styled.div`
  width: 100%;
  word-break: break-word;
`;
export const ContextMessageContent = styled.div`
  width: 100%;
  background-color: #d1d1d1;
  /* background-color: red; */
  word-break: break-word;
  margin-bottom: 4px;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  display: flex;
  height: 100%;

  /* &:before {
    content: '';
    position: absolute;
    background-color: green;
    border-radius: 50%;
    width: 2px;
    height: 100%;
    padding: 1rem 0;
    bottom: 0;
    left: 0;
  } */
`;

export const ContextImageContent = styled.div`
  width: 100%;
  text-align: start;
  justify-content: center;
`;
export const Bar = styled.div`
  margin: -10px 10px -10px -10px;
  border-radius: 5px;
  min-height: 100%;
  width: 0.3rem;
  background-color: #9747ff;
`;

//.image
export const Image = styled.img`
  text-align: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 400px;
  cursor: pointer;
`;
export const ContextImage = styled.img`
  text-align: center;
  justify-content: center;
  max-width: 10%;
  max-height: 10%;
  cursor: pointer;
`;

//.message-text
export const MessageText = styled.div`
  cursor: pointer;

  .messagePdf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
