import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap';
import { useLocation } from 'react-router';

import Loader from 'components/Loader';
import SimpleHeader from 'components/Headers/SimpleHeader';
import { useRecordTypesByUser } from 'views/RecordType/hooks/useRecordTypesByUser';
import RecordTypeSelection from 'views/RecordType/components/RecordTypeSelection';

import FormInputs from './FormInputs';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { AiOutlineClear } from 'react-icons/ai';

function CreateOpportunityForm() {
  const [selectedRecordTypeId, setSelectedRecordTypeId] = useState(null);
  const [shouldShowOpportunityForm, setShouldShowOpportunityForm] =
    useState(false);
  const { recordTypes, isLoadingRecordTypes, getRecordTypesByUser } =
    useRecordTypesByUser(entitiesName.OPPORTUNITY);
  const { state } = useLocation();

  useEffect(() => {
    getRecordTypesByUser();
  }, [getRecordTypesByUser]);

  useEffect(() => {
    if (recordTypes && recordTypes.length === 1) {
      setSelectedRecordTypeId(recordTypes[0].id);
      setShouldShowOpportunityForm(true);
    }
  }, [recordTypes]);

  /**
   * Quando a rota é chamada e deve-se abrir diretamente a listagem, sem
   * selecionar manualmente o tipo de registro.
   */
  useEffect(() => {
    if (state && state.recordTypeId) {
      setSelectedRecordTypeId(state.recordTypeId);
      setShouldShowOpportunityForm(true);
    }
  }, [state]);

  const shouldShowRecordTypeSelection = useCallback(() => {
    return isLoadingRecordTypes || !shouldShowOpportunityForm;
  }, [isLoadingRecordTypes, shouldShowOpportunityForm]);

  const handleOnSelectedRecordType = useCallback((recordTypeId) => {
    setSelectedRecordTypeId(recordTypeId);
    setShouldShowOpportunityForm(true);
  }, []);

  const renderWithBaseLayout = (children) => {
    return (
      <>
        <SimpleHeader showBackArrow="not" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <div className="d-flex flex-row justify-content-between">
                      <h3 className="mb-0">Adicionar Oportunidade</h3>
                      <span
                        onClick={() => {
                          document.getElementById('buttonInChild').click();
                        }}
                        className="small"
                        style={{ cursor: 'pointer' }}
                      >
                        <AiOutlineClear /> Limpar campos
                      </span>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-2">{children}</CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  };

  const render = () => {
    if (!recordTypes || !recordTypes.length)
      return renderWithBaseLayout(
        <Row className="justify-content-md-center">
          <Loader />
        </Row>
      );

    return shouldShowRecordTypeSelection()
      ? renderWithBaseLayout(
          <RecordTypeSelection
            recordTypes={recordTypes}
            handleOnSelectedRecordType={handleOnSelectedRecordType}
          />
        )
      : renderWithBaseLayout(
          <FormInputs recordTypeId={selectedRecordTypeId} />
        );
  };

  return render();
}

export default CreateOpportunityForm;
