import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  .checkbox {
    height: 16px;
    width: 16px;
  }

  .contentCheckbox {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
`;

export const ContentFields = styled.div`
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 5px;

    .chipTrue {
        background: #00a17c;
        color: #fff;
        border-radius: 7px;
        font-size: 12px;
     }

    .chipFalse {
        background: #ee6363;
        color: #fff;
        border-radius: 7px;
        font-size: 12px;
    }
`;