export const protocolNoEditFields = [
  'nome',
  'telefone',
  'id_facebook',
  'id_telegram',
  'id_whatsapp',
  'was_created_automatically',
  'number',
  'title',
  'description',
  'telephone',
  'current_status',
  'current_responsible',
  'reason_id',
  'subreason_id',
  'service_channel',
  'email_thread_id',
  'creation_date',
  'closure_date',
  'user_id',
  'client_id'
];
