import { apiUc as http } from '../http';
//
class ServiceCRUD {
  constructor(path) {
    this.path = path;
  }

  getAll(
    showAllQueues = false,
    page,
    size,
    showDisable = false,
    querySearch = '',
    queryParams = {}
  ) {
    if (!page || !size)
      return http.get(`${this.path}`, {
        params:
          Object.keys(queryParams).length > 0
            ? { ...queryParams, showAllQueues, showDisable }
            : { showAllQueues, showDisable }
      });

    return http.get(`${this.path}`, {
      headers: {
        limit: size,
        offset: size * (page - 1)
      },
      params: {
        showDisable: showDisable,
        q: querySearch,
        ...queryParams
      }
    });
  }

  getAllFilterTeam = (
    showAllQueues = false,
    page,
    size,
    showDisabled = false,
    querySearch = '',
    queryParams = {}
  ) => {
    const login = localStorage.getItem('login');

    if (!page || !size)
      return http.get(`${this.path}/user/${login}`, {
        params:
          Object.keys(queryParams).length > 0
            ? { ...queryParams, showAllQueues }
            : { showAllQueues }
      });

    return http.get(`${this.path}/user/${login}`, {
      headers: {
        limit: size,
        offset: size * (page - 1)
      },
      params: {
        showDisable: showDisabled,
        q: querySearch,
        ...queryParams
      }
    });
  };
  getAllSimple = () => {
    return http.get(`${this.path}/all`);
  };

  getAllShowDisable = (showDisable) => {
    return http.get(`${this.path}?showDisable=${showDisable}`);
  };

  getAllSeach = (page, size, query, showDisable, login) => {
    return http.get(`${this.path}?q=${query}&showDisable=${showDisable}`, {
      headers: {
        limit: size,
        offset: size * (page - 1),
        login
      }
    });
  };
  getAllShowSupervisor = (page, size, query, showDisable, isSupervisor) => {
    return http.get(
      `${this.path}?&q=${query}&showDisable=${showDisable}&isSupervisor=${isSupervisor}`,
      {
        headers: {
          limit: size,
          offset: size * (page - 1)
        }
      }
    );
  };

  get = (id) => {
    return http.get(`${this.path}/${id}`);
  };
  post = (data) => {
    return http.post(`${this.path}`, data);
  };
  put = (id, data) => {
    return http.put(`${this.path}/${id}`, data);
  };

  create = (data) => {
    return http.post(this.path, data);
  };

  update = (id, data) => {
    return http.put(`${this.path}/${id}`, data);
  };

  delete = (id) => {
    return http.delete(`${this.path}/${id}`);
  };

  recovery = (id) => {
    return http.put(`${this.path}/${id}/enable`);
  };

  enableExtension(entension_number) {
    return http.put(`${this.path}/${entension_number}/enable`);
  }

  getConfig = (data) => {
    return http.get(`${this.path}`);
  };

  updateConfig = (data) => {
    return http.put(`${this.path}`, data);
  };
}

export default ServiceCRUD;
