import React from 'react';
import { Row, Card, Button } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import ReportService from '../service';
import { AudioStreamReportTelephony as AudioStream } from '../../../../../components/AudioController/AudioStream';
import { toast } from 'react-toastify';
import * as fileSaver from 'file-saver';
import {
  individual_column_style,
  individual_header_style,
  individual_icon_header_style,
  icon_column_style
} from '../../../../../assets/styles/table_custom_style';
import { TotalRegister } from '../../../../../components/TotalRegister';

const Analytical = ({ dataReport, totalRegister }) => {
  const downloadAudio = (uniqueid) => {
    ReportService.downloadAudio(uniqueid)
      .then((res) => {
        res.data.size <= 11
          ? toast.info('Nenhum registro foi encontrado!')
          : fileSaver.saveAs(res.data, `${uniqueid}.gsm`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatColumn = () => {
    const phoneNumbersDataFields = [];

    dataReport.listDiscadorDTO.forEach((e) => {
      const fields = Object.keys(e).filter((e) => e.match(/phoneNumber/));

      fields.forEach(
        (e) =>
          !phoneNumbersDataFields.includes(e) && phoneNumbersDataFields.push(e)
      );
    });

    const listPhoneNumbers = phoneNumbersDataFields.map((dataField, i) => ({
      dataField,
      text: i === 0 ? 'Telefone' : `Telefone ${i + 1}`,
      sort: true,
      style: individual_column_style,
      headerStyle: individual_header_style
    }));

    return [
      {
        dataField: 'dialerName',
        text: 'Nome do Discador',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'clientName',
        text: 'Nome do Cliente',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'identification',
        text: 'Id do Cliente',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      ...listPhoneNumbers,
      {
        dataField: 'status',
        text: 'Status',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'queueName',
        text: 'Fila',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'agent',
        text: 'Agente',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'startDay',
        text: 'Data do Disparo',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'startHour',
        text: 'Hora do Disparo',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'dateAnswer',
        text: 'Data do Atendimento',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'hourAnswer',
        text: 'Hora do Atendimento',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'attempts',
        text: 'Tentativa',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'timeReturnFormatted',
        text: 'Tempo de retorno',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'callDuration',
        text: 'Duração da Chamada',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style
      },
      {
        dataField: 'hangupCauseDescription',
        text: 'Causa de desligamento',
        sort: true,
        style: individual_column_style,
        headerStyle: individual_header_style,
        formatter: (_, row) => {
          return row?.hangupCauseDescription || 'Não definido';
        }
      },
      {
        dataField: 'download',
        text: 'Download',
        sort: true,
        style: icon_column_style,
        headerStyle: individual_icon_header_style,
        // eslint-disable-next-line react/display-name
        formatter: (rowContent, row) => {
          return (
            <Button
              color="success"
              size="sm"
              disabled={row.status !== 'CLIENTE ATENDEU'}
              dataplacement="top"
              title={row.uniqueId ? '' : 'Não possui registro'}
              datacontainer="body"
              dataanimation="true"
              onClick={() => downloadAudio(row.uniqueId)}
            >
              <i className="fas fa-cloud-download-alt"></i>
            </Button>
          );
        }
      },
      {
        dataField: 'listen',
        text: 'Escutar',
        sort: true,
        style: icon_column_style,
        headerStyle: individual_icon_header_style,
        // eslint-disable-next-line react/display-name
        formatter: (rowContent, row) => {
          return (
            <AudioStream
              ReportService={ReportService}
              uniqueid={row.uniqueId}
              disabled={row.status !== 'CLIENTE ATENDEU'}
            />
          );
        }
      }
    ];
  };

  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            <Card>
              {totalRegister >= 0 && (
                <TotalRegister totalRegister={totalRegister} />
              )}
              <ToolkitProvider
                data={dataReport.listDiscadorDTO || []}
                keyField="uuid"
                columns={formatColumn()}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Analytical;
