import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import Service from '../service';
import contextService from '../service/contextService';
import groupService from '../service/exitGroupService';
import { toast } from 'react-toastify';
import { Regex } from './Regex';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';

import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const CallbackForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [contexts, setContexts] = useState([]);
  const [groupExit, setGroupExit] = useState([]);

  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      setLoading(true);
      Service.get(id)
        .then((res) => {
          const getCallback = res.data.data;
          reset(getCallback);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar Callback', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  //  Get contexts to select
  useEffect(() => {
    contextService
      .getAll()
      .then((response) => {
        setContexts(response.data.data);
      })
      .catch(() => {
        console.log('Erro ao ler os contextos');
      });
  }, []);

  //  Get group to select
  useEffect(() => {
    groupService
      .getAll()
      .then((response) => {
        setGroupExit(response.data.data);
      })
      .catch(() => {
        console.log('Erro ao ler os grupos');
      });
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      let context_id = parseInt(data.context_id);
      let group_out = parseInt(data.group_out);
      const dataStore = {
        context_id: context_id,
        group_out: group_out,
        route: data.route,
        change_route: data.change_route
      };
      isUpdate
        ? await Service.update(id, dataStore)
        : await Service.create(dataStore);
      history.push('/admin/exitRoute');
      if (isUpdate) {
        toast.info('Rota de saida foi atualizada com sucesso!');
      } else {
        toast.success('Rota de saida foi cadastrada com sucesso!');
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/exitRoute');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/exitRoute');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/exitRoute"
        buttonTitle="Voltar para a pagina de rotas de saída"
      />
      <ScreenPrompt
        when={isDirty}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar` && !formState.isSubmitting}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Rota de saída</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <h3>Principal</h3>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Contexto*
                          </label>
                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                {contexts.map((context, index) => (
                                  <option key={index} value={context.id}>
                                    {context.name}
                                  </option>
                                ))}
                              </Input>
                            }
                            control={control}
                            name="context_id"
                            rules={{
                              required: 'Selecione um contexto'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="context_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Grupo*</label>
                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                {groupExit.map((group, index) => (
                                  <option key={index} value={group.id}>
                                    {group.name}
                                  </option>
                                ))}
                              </Input>
                            }
                            control={control}
                            name="group_out"
                            rules={{
                              required: 'Selecione um grupo'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="group_out"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Rota*</label>

                          <Controller
                            as={Input}
                            control={control}
                            name="route"
                            rules={{
                              required: 'Rota é um campo obrigatório',
                              pattern: {
                                value: Regex,
                                message: 'Rota no formato inválido'
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="route"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Ajuste da Rota*
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            name="change_route"
                            rules={{
                              required: 'Ajuste de rota é um campo obrigatório',
                              validate: (value) => {
                                if (/^-?(\d)*:-?(\d)*$/.test(value))
                                  return true;
                                return 'Ajuste de Rota inválido';
                              }
                            }}
                            defaultValue=":"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="change_route"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CallbackForm;
