import { differenceInSeconds } from 'date-fns';

export const getProps = (situation) => {
  switch (situation) {
    case 'atendida':
      return {
        color: '#5cb85c',
        status: 'EM ATENDIMENTO',
        badge: 'success'
      };
    case 'em_atendimento':
      return {
        color: '#5cb85c',
        status: 'RESPONDER',
        badge: 'success'
      };
    case 'nao_atendida':
      return {
        color: '#d9534f ',
        status: 'NÃO ATENDIDA',
        badge: 'danger'
      };
    case 'transferida':
      return {
        color: '#292b2c',
        status: 'TRANSFERIDA',
        badge: 'dark'
      };
    default:
      break;
  }
};

export const getTime = (startTime) => {
  let differenceFromNow = differenceInSeconds(new Date(), new Date(startTime));

  const hours = Math.floor(differenceFromNow / (60 * 60));
  differenceFromNow -= hours * 60 * 60;

  const minutes = Math.floor(differenceFromNow / 60);
  differenceFromNow -= minutes * 60;

  const seconds = Math.floor(differenceFromNow);

  function getFomattedDigit(digit) {
    return `${String(digit).padStart(2, '0')}`;
  }

  return `${getFomattedDigit(hours)}:${getFomattedDigit(
    minutes
  )}:${getFomattedDigit(seconds)}`;
};
