import React from 'react';
import { FiInfo } from 'react-icons/fi';
import { UncontrolledTooltip } from 'reactstrap';

const QueueInfo = ({ agent }) => {
  return (
    <>
      <FiInfo size="1.5rem" id={`tooltip-${agent.extension}`} />
      <UncontrolledTooltip
        placement="top"
        target={`tooltip-${agent.extension}`}
      >
        {agent &&
          agent.queues.map((q) => (
            <span key={q.id} className="d-block">
              {q.name}
            </span>
          ))}
      </UncontrolledTooltip>
    </>
  );
};

export default QueueInfo;
