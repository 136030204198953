import React from 'react';
import AudioLink from '../../../../assets/audios/newMessage.mp3';
import ImageIcon from '../../../../assets/img/message.png';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

const ClickToastArea = ({ name, idChat }) => {
  const history = useHistory();

  const handlerClick = async (idChat) => {
    history.push(`/admin/service_panel`, { idChat });
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        cursor: 'pointer',
        padding: '10px'
      }}
      onClick={() => {
        handlerClick(idChat)
          .then((data) => {})
          .catch((error) => {
            console.log('error ==>', error);
          });
      }}
    >
      {name}
    </div>
  );
};

export const PlayNewAlert = (data, permission, type, audioNotification) => {
  const idDaConversa = data.idDaConversa || data.id || data._id;
  let audio = new Audio(AudioLink);
  if (audioNotification !== null && audioNotification !== undefined) {
    audio = new Audio(audioNotification);
  }

  const title = () => {
    if (type === 'client in queue') {
      return 'Cliente em fila de espera';
    } else if (type === 'new menssage') {
      return 'Nova mensagem';
    } else {
      return 'Atendimento exclusivo';
    }
  };
  const audioNotificationVolume =
    localStorage.getItem('@flexuc/audioNotificationVolume') || 1;

  audio.volume = audioNotificationVolume;

  const menssageBody = data?.mensagem?.escrita_por
    ? `No atendimento do cliente ${data?.mensagem?.escrita_por}`
    : `O cliente ${data?.cliente?.nome} entrou na fila ${data?.fila}`;

  const audioEnabled = JSON.parse(localStorage.getItem('@flexuc/audioEnabled'));

  return permission === 'granted'
    ? new Notification(title(), {
        body: menssageBody,
        icon: ImageIcon
      }) &&
        audioEnabled &&
        audio.play()
    : toast.info(
        <ClickToastArea name={menssageBody} idChat={idDaConversa} />
      ) &&
        audioEnabled &&
        audio.play();
};
