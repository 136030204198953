import PauseReportService from 'views/Report/Callcenter/Pause/service';

import { REPORT_TYPE, QUERY_PARAMS_ANALYTICAL } from '../utils/consts';

export const getAnalyticalPauseReport = async (params, page) => {
  const {
    data: { data }
  } = await PauseReportService.getAllReportsType(REPORT_TYPE.ANALYTICAL, page, {
    ...QUERY_PARAMS_ANALYTICAL,
    ...params
  });

  return data;
};
