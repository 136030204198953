/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from 'react';
import makeAnimated from 'react-select/animated';
import NewService from '../../../service/newService';
import { Chip } from '@material-ui/core';
import FlexChatIntegrationService from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration/index';
import Select from 'react-select';
import { MdAccessAlarms } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Input, Row } from 'reactstrap';
import { ChipTime, ContentCheckbox } from '../style';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import QueueService from '../../../../Queue/service';
import { Form } from '../../channels/modals/facebook/style';
import { ButtonChat } from 'views/ChatConfiguration/components/buttons';

import { toast } from 'react-toastify';

const ModalUpdate = ({
  info,
  modifyStateModal,
  posicao,
  setTimeoutConfigs
}) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const dados = useSelector((state) => state.createObject.data);
  const [initialState, setInitialState] = useState(null);
  const [Config, setConfig] = useState([]);
  const [queues, setQueues] = useState([]);
  const [channels, setChannels] = useState([]);

  const animatedComponents = makeAnimated();
  useEffect(() => {
    QueueService.getAll()
      .then((response) => {
        const newQueues = response.data.data.map((queue) => {
          return {
            label: queue.name,
            value: queue.id
          };
        });
        setQueues(newQueues);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
    FlexChatIntegrationService.getAllChannels()
      .then((result) => {
        const dataChannels = [];
        const dataResponse = result.data.data;
        Object.keys(dataResponse).map((channelName) => {
          if (channelName === 'facebook' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].nome_canal,
              value: result.data.data[channelName].nome_canal
            });
          }
          if (channelName === 'sms' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].nome_canal,
              value: result.data.data[channelName].nome_canal
            });
          }

          if (channelName === 'instagram' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].nome_canal,
              value: result.data.data[channelName].nome_canal
            });
          }

          if (channelName === 'whatsapp') {
            result.data.data[channelName].map((channelWpp) => {
              if (channelWpp.id)
                dataChannels.push({
                  label: channelWpp.nome_canal,
                  value: channelWpp.nome_canal
                });
            });
          }

          if (channelName === 'telegram' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].nome_canal,
              value: result.data.data[channelName].nome_canal
            });
          }

          if (channelName === 'webchat' && dataResponse[channelName].id) {
            dataChannels.push({
              label: result.data.data[channelName].nome_canal,
              value: result.data.data[channelName].nome_canal
            });
          }
        });
        setChannels(dataChannels);
      })
      .catch(() => {});
  }, []);
  useEffect(() => {
    setInitialState(info);
    setConfig([dados]);
  }, [info, dados]);

  function setStateModal(event) {
    event.preventDefault();
    modifyStateModal(false);
  }

  function controlToggleActive(control) {
    setInitialState({ ...initialState, ignoreWeekend: control });
  }

  function sendObject(event) {
    event.preventDefault();
    NewService.updateTimeout(initialState)
      .then((response) => {
        setStateModal(event);
        if (response.data) {
          setTimeoutConfigs(response.data.data);
          toast.success('Alterações feitas com sucesso', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data || 'Ops, ocorreu um erro', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  }

  function setDataModal(event, option, name) {
    if (name === 'inTheQueues') {
      const labels = event ? event.map((option) => option.label) : [];
      setInitialState({
        ...initialState,
        inTheQueues: labels
      });
    } else if (name === 'channels') {
      const labels = event ? event.map((option) => option.value) : [];
      setInitialState({
        ...initialState,
        channels: labels
      });
    } else if (event.target.name === 'ignoreWeekend') {
      setInitialState({
        ...initialState,
        [event.target.name]: event.target.checked
      });
    } else {
      setInitialState({
        ...initialState,
        [event.target.name]: event.target.value
      });
    }
  }
  const customStyles = {
    control: (base) => ({
      ...base,
      minHeight: 46,
      color: '#8898aa',
      backgroundColor: '#fff',
      border: '1px solid #dee2e6'
    }),
    placeholder: (defaultStyles) => {
      return {
        ...defaultStyles,
        color: '#8898aa',
        fontSize: '0.875rem'
      };
    }
  };
  return (
    <>
      {initialState && channels.length && (
        <Form>
          <Row className="row">
            <Col>
              <label>Tipo:</label>
              <Input
                type="select"
                onChange={setDataModal}
                name="typeTimeout"
                //defaultValue={initialState.tipoTimeout}
                value={initialState.typeTimeout}
              >
                <option value="SucessoAtendimento">
                  Sucesso de Atendimento
                </option>
                <option value="Ociosidade">Ociosidade</option>
              </Input>
            </Col>
            <Col md="4">
              <label>Regra:</label>
              <Input
                onChange={setDataModal}
                type="select"
                name="typeOfAttendant"
                //defaultValue={initialState.tipoAtendente}
                value={initialState.typeOfAttendant}
              >
                <option value="Atendente">Atendente</option>
                <option value="Chatbot">Chatbot</option>
              </Input>
            </Col>
            <Col md="4">
              <label>Canais: </label>
              <Select
                placeholder="Selecione..."
                components={animatedComponents}
                styles={customStyles}
                options={channels?.map((option) => {
                  return {
                    label: option?.label,
                    value: option?.value
                  };
                })}
                isMulti
                name="channels"
                onChange={(event, option) =>
                  setDataModal(event, option, 'channels')
                }
                defaultValue={
                  initialState?.channels?.map((value) => {
                    return { label: value, value };
                  }) || []
                }
                isClearable={true}
                isSearchable={true}
                isLoading={false}
                isRtl={false}
                closeMenuOnSelect={false}
              ></Select>
            </Col>
          </Row>

          <Row>
            <Col md="8">
              <label>Fila(s) de origem:</label>
              <Select
                placeholder="Selecione..."
                components={animatedComponents}
                styles={customStyles}
                //className="select"
                options={queues.map((option) => {
                  return {
                    label: option.label,
                    value: option.label
                  };
                })}
                isMulti
                name="inTheQueues"
                onChange={(event, option) =>
                  setDataModal(event, option, 'inTheQueues')
                }
                defaultValue={initialState?.inTheQueues?.map((value) => {
                  return { label: value, value };
                })}
                isClearable={true}
                isSearchable={true}
                isLoading={false}
                isRtl={false}
                closeMenuOnSelect={false}
              ></Select>
            </Col>
            <Col md={`${initialState.action !== 'encerrar' ? '2' : '4'}`}>
              <label>Ação:</label>
              <Input
                type="select"
                onChange={setDataModal}
                name="action"
                value={initialState.action}
              >
                <option value="encerrar">Encerrar</option>
                <option value="transferir">Transferir</option>
              </Input>
            </Col>
            {initialState.action !== 'encerrar' && (
              <Col md="2">
                <label>Transferir para:</label>
                <Input
                  type="select"
                  onChange={setDataModal}
                  name="transferQueue"
                  value={initialState.transferQueue}
                >
                  {queues &&
                    queues.map((queue) => (
                      <option key={queue.value} value={queue.label}>
                        {queue.label}
                      </option>
                    ))}
                </Input>
              </Col>
            )}
          </Row>
          <Row
            style={{
              marginTop: '20px'
            }}
          >
            <Col>
              <label>{`Lembrete de ${
                initialState.action === 'encerrar'
                  ? 'Encerramento'
                  : 'Transferência'
              }:`}</label>
              <Input
                type="textarea"
                onChange={setDataModal}
                defaultValue={initialState.reminderMessage}
                name="reminderMessage"
              />
            </Col>
            {initialState.tipoAtendente === 'Chatbot' ? (
              <ChipTime>
                <Col md="9">
                  {/* <label>Tempo:</label>{' '} */}
                  <Chip
                    avatar={<MdAccessAlarms color="white" />}
                    size="small"
                    className="chipTime"
                    label="TEMPO EM MINUTOS"
                  />
                  <Input
                    defaultValue={initialState.limite}
                    type="number"
                    name="limite"
                    min={1}
                    max={1440}
                    onChange={setDataModal}
                  />
                </Col>
              </ChipTime>
            ) : (
              <ChipTime>
                <Col md="9">
                  {/* <label>Tempo:</label>{' '} */}
                  <Chip
                    avatar={<MdAccessAlarms color="white" />}
                    size="small"
                    className="chipTime"
                    label="TEMPO EM MINUTOS"
                  />
                  <Input
                    defaultValue={initialState.limit}
                    type="number"
                    name="limit"
                    min={1}
                    onChange={setDataModal}
                  />
                </Col>
              </ChipTime>
            )}
          </Row>
          <Row
            style={{
              marginTop: '20px'
            }}
          >
            <Col>
              <label>{`Mensagem de ${
                initialState.action === 'encerrar'
                  ? 'Encerramento'
                  : 'Transferência'
              }:`}</label>
              <Input
                type="textarea"
                onChange={setDataModal}
                defaultValue={initialState.closingMessage}
                name="closingMessage"
              />
            </Col>
            {initialState.typeOfAttendant === 'Chatbot' ? (
              <ChipTime>
                <Col>
                  <Chip
                    avatar={<MdAccessAlarms color="white" />}
                    size="small"
                    className="chipTime"
                    label="TEMPO EM MINUTOS"
                  />
                  <Input
                    defaultValue={initialState.timeout}
                    type="number"
                    name="timeout"
                    min={1}
                    max={1440}
                    onChange={setDataModal}
                  />
                </Col>
              </ChipTime>
            ) : (
              <ChipTime>
                <Col md="9">
                  {/* <label>Tempo:</label>{' '} */}
                  <Chip
                    avatar={<MdAccessAlarms color="white" />}
                    size="small"
                    className="chipTime"
                    label="TEMPO EM MINUTOS"
                  />
                  <Input
                    defaultValue={initialState.timeout}
                    type="number"
                    name="timeout"
                    min={1}
                    onChange={setDataModal}
                  />
                </Col>
              </ChipTime>
            )}
          </Row>

          <Row>
            <ContentCheckbox color={systemConfiguration.primary_color}>
              <Col>
                <label className="titleMsg">IGNORAR FINAL DE SEMANA</label>
                {initialState.ignoreWeekend === true ? (
                  <Chip size="small" className="chipTrue" label="HABILITADO" />
                ) : (
                  <Chip
                    size="small"
                    className="chipFalse"
                    label="DESABILITADO"
                  />
                )}
              </Col>
              <ToggleSwitch
                checked={initialState.ignoreWeekend}
                id={'ativado'}
                onChange={() =>
                  controlToggleActive(!initialState.ignoreWeekend)
                }
              />
            </ContentCheckbox>
          </Row>
          <Row className="row">
            <Col md="12" className="button">
              <ButtonChat
                action={(event) => sendObject(event)}
                name={'Salvar'}
                color={systemConfiguration.confirmation_button_color}
              />
              <ButtonChat
                action={(event) => setStateModal(event)}
                name={'Fechar'}
                color={systemConfiguration.cancelation_button_color}
              />
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};

export { ModalUpdate };
