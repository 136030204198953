import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { Row } from 'reactstrap';
import * as S from './styles';

const ServicePanelItem = ({ agentSession, handleLogoff, isPaused }) => {
  const [shouldSign, setshouldSign] = useState(false);

  useEffect(() => {
    const shouldsign = localStorage.getItem('should_sign_in_asterisk');
    const shouldsignFormated = JSON.parse(shouldsign);
    setshouldSign(shouldsignFormated);
  }, []);
  const handleIndividualLogoff = () => {
    if (isPaused) {
      toast.info('Você não pode deslogar enquanto estiver em pausa');
      return;
    }

    const queuesToRemove = agentSession.queue;
    handleLogoff({ queuesToRemove: [queuesToRemove] });
  };

  return (
    <Row className="align-items-center px-3">
      <div className="col">
        <div className="d-flex justify-content-between align-items-center">
          <S.Wrapper>
            <S.QueueNameWrapper>
              {(agentSession.queue && agentSession.queue.name) || ''}
            </S.QueueNameWrapper>

            {shouldSign ? (
              <></>
            ) : (
              <S.PowerButton
                isPaused={isPaused}
                className="ni ni-button-power"
                onClick={handleIndividualLogoff}
              />
            )}
          </S.Wrapper>
        </div>
      </div>
    </Row>
  );
};

export default ServicePanelItem;
