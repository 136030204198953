import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { connect, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from 'reactstrap';
import { signIn } from '../../store/action/index';
import {
  getLogoURL,
  getLogoSocialURL
} from '../../views/SystemParams/services/getLogoURL';
import './Login.css';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import ReactLoader from 'components/Loader';
import { FcGoogle } from 'react-icons/fc';
import { apiAuth } from '../../http';

const Login = (props) => {
  const [dataLogin, SetDataLogin] = useState({
    login: {
      elementType: 'input',
      type: 'text',
      placeholder: 'Login de acesso',
      name: 'login',
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: 'false'
    },
    password: {
      elementType: 'input',
      type: 'password',
      placeholder: 'Senha',
      name: 'password',
      value: '',
      validation: {
        required: true
      },
      valid: false,
      touched: 'false'
    }
  });
  const [logoURL, setLogoURL] = useState();
  const [logoSocialURL, setLogoSocialURL] = useState();
  const [googleCode, setGoogleCode] = useState('');

  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  useEffect(() => {
    if (googleCode.length > 10) {
      const decodedUri = decodeURIComponent(googleCode);
      const baseUrl = window.location.origin;
      apiAuth
        .post('/oauth/google', {
          code: decodedUri,
          redirectUri: `${baseUrl}/`
        })
        .then((res) => {
          const { data } = res;
          localStorage.setItem(
            'should_sign_in_asterisk',
            data.should_sign_in_asterisk
          );
          localStorage.setItem(
            'queuesToSignId',
            JSON.stringify(data.queuesToSignId)
          );

          localStorage.setItem('use_scale', data.use_scale);
          localStorage.setItem('is_using_scale', !!data.scale_id);
          localStorage.setItem('agent_code', data.agent_code);
          localStorage.setItem('token', data.token);
          localStorage.setItem('name', data.name);
          localStorage.setItem('queues', JSON.stringify(data.queues));
          localStorage.setItem('extensions', JSON.stringify(data.extensions));
          localStorage.setItem('teams', JSON.stringify(data.teams));
          localStorage.setItem('login', data.login);
          localStorage.setItem('nickname', data.nickname);
          localStorage.setItem('type_of_pause', data.type_of_pause);
          localStorage.setItem('is_paused', data.is_paused);
          localStorage.setItem('isLoginExtension', false);
          localStorage.setItem(
            'show_all_conversations',
            data.show_all_conversations
          );
          localStorage.setItem('show_all_protocols', data.show_all_protocols);
          localStorage.setItem('show_all_leads', data.show_all_leads);
          localStorage.setItem(
            'show_all_opportunities',
            data.show_all_opportunities
          );
          localStorage.setItem('pin_code', data.pin_code);

          localStorage.setItem('roles', JSON.stringify(data.roles));

          window.location.reload();
        })
        .catch(() => {
          toast.error('E-mail não cadastrado no FlexUC');
        });
    }
  }, [googleCode]);

  useEffect(() => {
    //   getDefaultTheme();
    getLogoURL()
      .then((source) => {
        setLogoURL(source);
      })
      .catch(() => console.error('[LOGIN FORM] Error loading logo'));

    getLogoSocialURL()
      .then((source) => {
        setLogoSocialURL(source);
      })
      .catch(() => console.error('[LOGIN FORM] Error loading social logo'));
  }, []);

  const checkValidity = (value, rules) => {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== '' && isValid;
    }

    if (rules.minLength) {
      isValid = value.length >= rules.minLength && isValid;
    }

    if (rules.maxLength) {
      isValid = value.length <= rules.maxLength && isValid;
    }

    if (rules.isNumeric) {
      const pattern = /^\d+$/;
      isValid = pattern.test(value) && isValid;
    }

    return isValid;
  };

  const inputChangedHandler = (event, controlName) => {
    const updatedState = {
      ...dataLogin,
      [controlName]: {
        ...dataLogin[controlName],
        value: event.target.value,
        valid: checkValidity(
          event.target.value,
          dataLogin[controlName].validation
        ),
        touched: 'true'
      }
    };
    SetDataLogin(updatedState);
  };

  const submitAuth = (event) => {
    event.preventDefault();
    const login = dataLogin.login.value;
    const password = dataLogin.password.value;
    props.signIn(login, password);
  };

  const notifySucessLogin = (nameUser) =>
    toast.info('Seja bem-vindo(a) ' + nameUser);
  let buttonSingIn = null;
  if (!props.loading) {
    buttonSingIn = (
      <Button
        onClick={submitAuth}
        className="my-4 g4-button"
        type="submit"
        style={buttonStyle(systemConfiguration.primary_color)}
      >
        Entrar
      </Button>
    );
  } else {
    buttonSingIn = (
      <div>
        <ReactLoader />
      </div>
    );
  }
  if (props.should_sign_in_asterisk) return <Redirect to="/LoginAutoAuth" />;

  if (props.token) {
    notifySucessLogin(props.name);
    return <Redirect to="/admin" />;
  }

  if (localStorage.getItem('token')) {
    const roleNames = JSON.parse(localStorage.getItem('roles')).map(
      ({ role }) => role
    );

    if (
      roleNames.includes('AGENT_DASHBOARD') &&
      !roleNames.includes('DASHBOARD')
    ) {
      return <Redirect from="*" to="/admin/dashboard/agent" />;
    }

    return <Redirect from="*" to="/admin/dashboard" />;
  }

  const getAuthUrl = async () => {
    const baseUrl = window.location.origin;
    const response = apiAuth
      .get(`/oauth/get-auth?redirectUri=${baseUrl}/`)
      .then((res) => {
        return res.data.authorizationUrl;
      })
      .catch((error) => {
        console.log('Erro no auth', error);
        toast.info(
          'Não foi possível conectar ao Google. Tente novamente mais tarde.'
        );
        return undefined;
      });
    return response;
  };

  const handleGoogleAuth = async () => {
    const url = await getAuthUrl();
    const authPopup = window.open(
      url,
      'Pop up window',
      'popup=true, width=500, height=600, top=100,left=100'
    );

    if (window.focus) {
      authPopup.focus();
    }
    const intervalId = setInterval(() => {
      if (!authPopup.closed) {
        setGoogleCode(
          authPopup.location.href.split('?')[1].split('&')[0].split('=')[1]
        );
        authPopup.close();
      }
      if (authPopup?.closed) {
        clearInterval(intervalId);
      }
    }, 1000);
  };

  return (
    <div className="login-container fadeIn">
      <div className="logo">
        <img src={logoSocialURL} alt="logo" />
      </div>
      <div className="form-container">
        <div className="img-logo-g4flex">
          <img src={logoURL} style={{ maxWidth: '200px' }} alt="logo_g4" />
        </div>
        <Card className="bg-secondary border-0 mb-0">
          <CardHeader className="bg-transparent pb-2">
            <div className="text-muted text-center mt-2 mb-3">
              <h3>{systemConfiguration.welcome_msg}</h3>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form onSubmit={submitAuth} role="form">
              <FormGroup
                className={classnames('mb-3', {
                  focused: 'login'
                })}
              >
                <InputGroup className="input-group-merge input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-email-83" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type={dataLogin.login.type}
                    placeholder={dataLogin.login.placeholder}
                    name={dataLogin.login.name}
                    value={dataLogin.login.value}
                    invalid={!dataLogin.login.valid}
                    touched={dataLogin.login.touched}
                    onChange={(event) =>
                      inputChangedHandler(event, dataLogin.login.name)
                    }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup
                className={classnames({
                  focused: 'password'
                })}
              >
                <InputGroup className="input-group-merge input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type={dataLogin.password.type}
                    placeholder={dataLogin.password.placeholder}
                    name={dataLogin.password.name}
                    value={dataLogin.password.value}
                    invalid={!dataLogin.password.valid}
                    touched={dataLogin.password.touched}
                    onChange={(event) =>
                      inputChangedHandler(event, dataLogin.password.name)
                    }
                  />
                </InputGroup>
              </FormGroup>
              <div className="text-center">{buttonSingIn}</div>
            </Form>
            <hr />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <Button
                name="GoogleSingIn"
                onClick={() => {
                  handleGoogleAuth();
                }}
              >
                <FcGoogle />
              </Button>
              <p>Entrar com o Google</p>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    token: state.auth.token,
    name: state.auth.name,
    visible: state.auth.visible,
    should_sign_in_asterisk: state.auth.should_sign_in_asterisk,
    queuesidToSignIn: state.auth.queuesidToSignIn
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    signIn: (login, password) => dispatch(signIn(login, password))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
