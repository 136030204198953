import React, { useEffect, useState } from 'react';
// import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Pagination from 'components/Pagination';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import MultiButtonsHeader from '../../../components/Headers/MultiButtons';
import UserService from '../service';

import {
  Input,
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label
} from 'reactstrap';

import {
  select_field_row_style,
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const UserList = () => {
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchUsers, setSearchUsers] = useState();
  const [showDisabled, setShowDisable] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const getUsers = (selectedPage = 1, search = '') => {
    const login = localStorage.getItem('login');
    setLoading(true);
    UserService.getAllSeach(selectedPage, 10, search, showDisabled, login)
      .then((response) => {
        const userList = response.data.data;
        const quantity = response.data.totalRegisters;
        setUsers(userList);
        setQuantity(quantity);
        setLoading(false);
        setActivePage(selectedPage);
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Erro ao listar usuários!', {
          autoClose: 3000,
          closeOnClick: true
        });
        setLoading(false);
      });
  };

  useEffect(getUsers, [showDisabled]);

  const navigateToEditMultiple = () => {
    // O botão 'Editar todos' foi clicado
    if (selectedRows.length < 2) {
      return history.push(`user/form/edit_multiple`, {
        actionType: 'EDIT_ALL'
      });
    }
    const verifier = selectedRows[0];
    const isValid = selectedRows.every((id) => id.type === verifier.type);
    if (isValid) {
      return history.push('/admin/user/form/edit_multiple', {
        actionType: 'EDIT_MULTIPLE',
        users: selectedRows
      });
    } else {
      toast.error('Os ramais devem ser do mesmo tipo', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };
  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      const newSelected = { id: row.login };
      setSelectedRows([...selectedRows, newSelected]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x.id !== row.login));
    }
  };
  const handleOnSelectAll = (isSelect, rows) => {
    const allRows = rows.map((r) => {
      return { id: r.login };
    });
    if (isSelect) {
      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }
  };

  const changeActive = (login, active) => {
    if (active)
      if (!window.confirm('Tem certeza que deseja desabilitar o usuário?'))
        return;
    UserService.changeActive(login, {
      active: !active
    })
      .then(() => {
        toast.info(
          !active
            ? 'Usuário habilitado com sucesso!'
            : 'Usuário desabilitado com sucesso!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
        getUsers(1);
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Ops algo errado aconteceu!', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const getUsersSearch = (event, selectedPage = 1) => {
    setSearchUsers(event.target.value);
    if (searchUsers) {
      getUsers(selectedPage, event.target.value);
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    getUsers(page);
  };

  const renderButtonsOnHeader = () => {
    return [
      {
        label: 'Editar vários',
        customNavigator: navigateToEditMultiple,
        disabled: selectedRows.length < 2
      },
      {
        label: 'Editar todos',
        customNavigator: navigateToEditMultiple
      }
    ];
  };

  return (
    <>
      <MultiButtonsHeader
        setCreate="/admin/user/form"
        name="Form validation"
        parentName="Forms"
        buttons={renderButtonsOnHeader()}
        showArrowReturn={true}
      />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Usuários</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Insira seu nome de usuário ou login"
                          type="text"
                          onChange={(event) => getUsersSearch(event)}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="4" className="text-right mt-2 ">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={showDisabled}
                            onChange={() => setShowDisable(!showDisabled)}
                          />
                          <span>Mostrar desabilitados</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={users}
                  keyField="login"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'login',
                      text: 'Login',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'contexto.name',
                      text: 'Contexto',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'agent_code',
                      text: 'Código do Agente',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'profile.name',
                      text: 'Perfil de Acesso',
                      sort: true,
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      // eslint-disable-next-line react/display-name
                      formatter: (rowContent) => {
                        return (
                          <i
                            className="fas fa-info-circle"
                            size="sm"
                            datatoggle="tooltip"
                            dataplacement="top"
                            title={rowContent}
                            datacontainer="body"
                            dataanimation="true"
                            style={{ fontSize: '25px', color: '#2dce89' }}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'disable',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Habilitar / Desabilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            disabled={!row.hasPermission}
                            checked={row.active}
                            name={row.name}
                            id={row.login}
                            onChange={() => changeActive(row.login, row.active)}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (rowContent, row) => {
                        return (
                          <Link
                            style={
                              row.hasPermission
                                ? null
                                : { pointerEvents: 'none' }
                            }
                            to={'/admin/user/form/' + row.login}
                          >
                            <Button
                              disabled={!row.hasPermission}
                              color="info"
                              size="sm"
                            >
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          selectRow={{
                            mode: 'checkbox',
                            selected: selectedRows.map((r) => r.id),
                            onSelect: handleOnSelect,
                            onSelectAll: handleOnSelectAll,
                            selectColumnStyle: select_field_row_style
                          }}
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default UserList;
