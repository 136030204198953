/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardHeader,
  CardBody
} from 'reactstrap';

import { TableProtocolFields } from './components/TableProtocolFields';
import FieldsConfigService from '../service/index';

const FieldsConfig = () => {
  const [validationEntities] = useState(() => {
    let arrayEntities = [];
    arrayEntities.push({
      displayName: 'Protocolo',
      pluralDisplayName: 'Protocolos',
      entityName: 'protocol',
      entityNameUpper: 'PROTOCOL'
    });

    arrayEntities.push({
      displayName: 'Oportunidade',
      pluralDisplayName: 'Oportunidades',
      entityName: 'opportunity',
      entityNameUpper: 'OPPORTUNITY'
    });

    arrayEntities.push({
      displayName: 'Conta',
      pluralDisplayName: 'Contas',
      entityName: 'account',
      entityNameUpper: 'ACCOUNT'
    });

    arrayEntities.push({
      displayName: 'Contato',
      pluralDisplayName: 'Contatos',
      entityName: 'contact',
      entityNameUpper: 'CONTACT'
    });

    arrayEntities.push({
      displayName: 'Lead',
      pluralDisplayName: 'Leads',
      entityName: 'lead',
      entityNameUpper: 'LEAD'
    });

    return arrayEntities;
  });

  const [selectedEntity, setSelectedEntity] = useState(() => {
    const entity = validationEntities;
    return entity[0];
  });

  useEffect(() => {
    getCustomFields(selectedEntity.entityName);
  }, []);

  const [fields, setFields] = useState();

  const showCustomFields = (entity) => {
    setSelectedEntity({ ...entity });
    getCustomFields(entity.entityName);
  };

  const getCustomFields = (entity) => {
    FieldsConfigService.getFields(entity)
      .then((response) => {
        setFields(response.data.data);
      })
      .catch(setFields(null));
  };

  return (
    <>
      {validationEntities[0]?.entityName ? (
        <>
          <SimpleHeader
            setCreate="/admin/fieldsConfig/form"
            name="Form validation"
            parentName="Forms"
            entityName={selectedEntity.displayName}
          />
          <Container className="mt--6" fluid>
            <Row>
              <div className="col">
                <div className="card-wrapper">
                  <Card>
                    <CardHeader>
                      <Row>
                        <Col>
                          <h2>Campos customizados</h2>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          style={{
                            display: 'grid',
                            gridTemplateColumns:
                              'repeat(auto-fit, minmax(min(7rem, 100%), 1fr))',
                            gap: '1rem',
                            width: '100%',
                            margin: '1rem'
                          }}
                        >
                          {' '}
                          {validationEntities.map((entity) => (
                            <Button
                              key={entity.displayName}
                              color="primary"
                              outline
                              className={
                                selectedEntity.displayName ===
                                entity.displayName
                                  ? 'active'
                                  : null
                              }
                              size="sm"
                              type="button"
                              title="Visualizar todos os tipos de registro desssa entidade"
                              onClick={() => showCustomFields(entity)}
                              style={{
                                margin: '0rem'
                              }}
                            >
                              {entity.displayName}
                            </Button>
                          ))}
                        </Col>
                      </Row>
                    </CardHeader>
                    <TableProtocolFields fields={fields} />
                  </Card>
                </div>
              </div>
            </Row>
          </Container>
        </>
      ) : (
        <Container>
          <Card>
            <CardBody>
              <h1 style={{ textAlign: 'center' }}>
                Desculpe, mas você não tem permissão para visualizar os campos
                customizados de cliente e protocolo
              </h1>
            </CardBody>
          </Card>
        </Container>
      )}
    </>
  );
};

export default FieldsConfig;
