import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';

const PausaAutomaticModal = ({
  show,
  children,
  onModalToggle,
  title = '',
  modalContainerClasses = '',
  ...props
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    onModalToggle(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const toggleModal = () => setOpen(!open);

  return (
    <>
      <Modal
        className={`modal-dialog-centered ${modalContainerClasses}`}
        isOpen={open}
        toggle={() => toggleModal()}
        {...props}
      >
        <div className="modal-header">
          <h6
            className="modal-title"
            id="modal-title-default"
            style={{ marginLeft: '1rem' }}
          >
            {title}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => setOpen(false)}
          >
            <span aria-hidden={true}></span>
          </button>
        </div>
        <div className="modal-body">{children}</div>
      </Modal>
    </>
  );
};

export default PausaAutomaticModal;
