import React, { useMemo, useEffect, createContext, useContext } from 'react';
import { newMessage } from 'views/internalChat/socketInternalChat/message-socket';
import notification from 'views/internalChat/components/sound/notificationInternalChat.wav';
import io from 'socket.io-client';

const music = new Audio(notification);

const useInternalChatSocket = createContext();

export function SocketInternalChatProvider({ children }) {
  const socketInternalChat = useMemo(() => {
    return io.connect(process.env.REACT_APP_PROXY_URL, {
      path: process.env.REACT_APP_INTERNAL_CHAT_SOCKET_PATH,
      transports: ['websocket'],
      upgrade: false
    });
  }, []);

  useEffect(() => {
    return () => {
      socketInternalChat.close();
    };
  }, [socketInternalChat]);

  useEffect(() => {
    socketInternalChat.emit('introduction', {
      identity: localStorage.getItem('login')
    });
  }, [socketInternalChat]);

  useEffect(() => {
    socketInternalChat.on('message', (response) => {
      newMessage(response);
      music.play();
    });
  }, [socketInternalChat]);

  return (
    <>
      <useInternalChatSocket.Provider
        value={{
          socketInternalChat: socketInternalChat
        }}
      >
        {children}
      </useInternalChatSocket.Provider>
    </>
  );
}

export function useConectionSocketChatInternal() {
  const context = useContext(useInternalChatSocket);
  return context;
}
