import React, { useState, useEffect, useCallback } from 'react';
import { parseISO, format } from 'date-fns';
import { TotalRegister } from '../../../../../components/TotalRegister';

import {
  Button,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label
} from 'reactstrap';
import { toast } from 'react-toastify';

import DefaultModal from 'components/Modal/DefaultModal';
import ModalConfirmation from 'components/Modal/ConfirmationModal';
import Pagination from 'components/Pagination';
import ReactButton from 'components/Buttons/ReactButton';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../../../assets/styles/table_custom_style';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactLoader from 'components/Loader';
import BroadcastListService from '../../../service';

import AddContactsModal from '../AddContactsModal';
// import ImportClientListModal from '../../../../Clients/list/components/ImportClientListModal';
import ImportContactsToBroadcastListModal from 'views/Clients/list/components/ImportContactsToBroadcastListModal';
import HandleContactsModal from 'views/ChatBroadcastListSMS/list/components/HandleContactsModal';

const DEFAULT_PAGE_SIZE = 10;

const HandleAllContactsModal = ({
  isModalOpen,
  onModalToggle,
  broadcastListId,
  ...props
}) => {
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [filtro, setFitro] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [contacts, setContacts] = useState(null);
  const [isAddContactsModalOpen, setIsAddContactsModalOpen] = useState(false);
  const [idResource, setidResource] = useState();
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [timeoutID, setTimeoutID] = useState(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isSeletingAll, setIsSeletingAll] = useState(false);

  const handleGetContactsFromBroadcastList = useCallback(
    (page, q = '',contactactive=true,) => {
      if (isModalOpen) {
        setLoading(true);
        
        const params = { page, q ,contactactive};

        if(q.length > 0){
          setFitro(true)
        }else if (q.length  <1){
          setFitro(false)
        }
        
        console.log('pesquisa',params)
        BroadcastListService.getAllContactsFromBroadcastList(
          broadcastListId,
          params
        )
          .then((response) => {
            const registers = response.data.registers;
            const count = response.data.count;
            setContacts(registers);
            setQuantity(count);
          })
          .catch(() => {
            toast.error('Erro ao listar contatos da Campanha Ativa Whatsapp');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [isModalOpen, broadcastListId]
  );

  const handleDeleteContactsFromBroadcastList = useCallback(
    (ids = []) => {
      BroadcastListService.removeContactsFromBroadcastList(broadcastListId, ids)
        .then(() => {
          const singular = 'Contato removido com sucesso';
          const plural = 'Contatos removidos com sucesso';
          toast.success(ids.length === 1 ? singular : plural);
          handleGetContactsFromBroadcastList(1);
        })
        .catch(() => {
          toast.error(
            'Ocorreu um erro ao remover os contatos da Campanha Ativa Whatsapp'
          );
        })
        .finally(() => {
          setOpenModalConfirmation(false);
          setSelectedRows([]);
        });
    },
    [handleGetContactsFromBroadcastList, broadcastListId]
  );

  useEffect(() => {
    handleGetContactsFromBroadcastList(1);
  }, [handleGetContactsFromBroadcastList]);

  useEffect(() => {
    if (selectedRows.length === quantity && quantity > 0) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [quantity, selectedRows.length]);

  const handleOnSelect = (row, isNotSelectedYet) => {
    if (isNotSelectedYet) {
      const newSelected = {
        id: row.id,
        nome: row.nome,
        id_whatsapp: row.id_whatsapp
      };
      setSelectedRows([...selectedRows, newSelected]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x.id !== row.id));
    }
  };

  const handleOnSelectAll = (isSomeSelected, rows) => {
    if (isSomeSelected) {
      const allRows = [...selectedRows, ...rows].map((r) => {
        return { id: r.id, nome: r.nome, id_whatsapp: r.id_whatsapp };
      });
      setSelectedRows(allRows);
    } else {
      const idsToRemove = rows.map((row) => row.id);
      setSelectedRows((currentSelected) => {
        return currentSelected.filter(({ id }) => !idsToRemove.includes(id));
      });
    }
  };

  const handlePageChange = (activePage) => {
    setActivePage(activePage);
    handleGetContactsFromBroadcastList(activePage);
  };

  const undoSelection = () => {
    setSelectedRows([]);
  };

  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedRows([]);
      setIsAllSelected(false);
      return;
    }

    setIsSeletingAll(true);

    BroadcastListService.getAllContactsFromBroadcastList(broadcastListId)
      .then((response) => {
        const { registers } = response.data;
        if (!registers) return;

        const registersSelectionDTO = registers.map((row) => ({
          id: row.id,
          nome: row.nome,
          id_whatsapp: row.id_whatsapp
        }));

        setIsAllSelected(true);
        setSelectedRows(registersSelectionDTO);
      })
      .catch(() => {
        toast.error('Erro ao selecionar todos os contatos');
      })
      .finally(() => {
        setIsSeletingAll(false);
      });
  };

  const handleOnSearch = (event) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const q = event.target.value;
    setTimeoutID(
      setTimeout(() => {
        handleGetContactsFromBroadcastList(1, q);
      }, 1000)
    );
  };



  return (
    <>
      <DefaultModal
        show={isModalOpen}
        onModalToggle={onModalToggle}
        title="Buscar contatos que estão na lista de transmissão WhatsApp"
        showCloseButton={false}
        modalContainerClasses="modal-xl"
        {...props}
      >
        <>
        
          <Row>
            <Col className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column justify-content-between">
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  size="md"
                  placeholder="Buscar por nome ou telefone"
                  type="text"
                  defaultValue=""
                  onChange={handleOnSearch}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="mt-3 pl-3 small">
            <Col>
            </Col>
          </Row>
          <>
          {quantity >= 0 && <TotalRegister totalRegister={quantity} />}
          </>
          <Row>
            <Col>
              <ToolkitProvider
                data={contacts || []}
                keyField="id"
                loading={loading}
                columns={[
                  {
                    dataField: 'bl_title',
                    text: 'LISTA DE TRANSMISSÃO',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'nome',
                    text: 'Nome',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'id_whatsapp',
                    text: 'Telefone',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'created_at',
                    text: 'Data de Criação',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (_, row) => {
                      const formattedDate = format(parseISO(row.created_at), 'dd/MM/yyyy');
                      return <span>{formattedDate}</span>;
                   },
                    
                    
                  },
                  ,
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    {loading ? (
                      <Row className="justify-content-md-center">
                        <ReactLoader />
                      </Row>
                    ) : (
                      <BootstrapTable
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        classes="align-items-center"
                      />
                    )}
                  </div>
                )}
              </ToolkitProvider>
              {!!quantity && quantity > DEFAULT_PAGE_SIZE && filtro === false && (
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col style={{ maxWidth: '8rem' }}>
              <ReactButton
                btnColor="confirmation"
                type="submit"
                size="sm"
                onClick={() => onModalToggle(false)}
                block
              >
                OK
              </ReactButton>
            </Col>
          </Row>
        </>
      </DefaultModal>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={handleDeleteContactsFromBroadcastList}
        message={'Tem certeza que deseja excluir este(s) contato(s)?'}
      />
      <AddContactsModal
        isModalOpen={isAddContactsModalOpen}
        onModalToggle={setIsAddContactsModalOpen}
        unmountOnClose={true}
        broadcastListId={broadcastListId}
        reloadList={handleGetContactsFromBroadcastList}
      />
      <HandleContactsModal
        isModalOpen={isContactModalOpen}
        onModalToggle={setIsContactModalOpen}
        unmountOnClose={true}
        broadcastListId={idResource}
      />
    </>
  );
};

export default HandleAllContactsModal;
