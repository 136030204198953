export const timeDataNames = [
  {
    name: 'Em atraso',
    value: 'overdue'
  },
  {
    name: 'Em dia',
    value: 'current'
  }
];
