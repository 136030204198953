import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Label,
  Input,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import Pagination from 'components/Pagination';

import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import CreateAndEditTagClient from '../components/ModalCreateTag';
import { HeaderTagClient } from '../components/HeaderTagClient';
import TagClientService from '../services/TagClientService';
import { toast } from 'react-toastify';
import { ListColorDisplay } from 'components/Color/ListColorDisplay/ListColorDisplay';

const TagsClientList = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [tagFromUpdate, setTagFromUpdate] = useState({});
  const [tagsList, setTagsList] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [totalTags, setTotalTags] = useState(0);
  const [showDisabled, setShowDisable] = useState(false);
  const [nameSearch, setNameSearch] = useState('');

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const disabledTag = (id, name, disabled) => {
    TagClientService.changeStatus(id)
      .then(() => {
        toast.success(
          `Tag ${name} ${disabled ? 'habilitada' : 'desabilitada'}`
        );
        getTags();
      })
      .catch(() => {
        toast.error(
          `Erro ao ${disabled ? 'Desabilitar' : 'Habilitar'} tag ${name}`
        );
      });
  };

  useEffect(() => {
    getTags(1, nameSearch);
  }, [nameSearch]);

  const getTags = (newPage, name) => {
    TagClientService.getWithPagination({
      page: newPage || activePage,
      showDisabled,
      name
    }).then((response) => {
      setTagsList(response.data.data.result);
      setTotalTags(response.data.data.count);
    });
  };

  useEffect(() => {
    getTags(1, nameSearch);
  }, []);

  const goToPage = (newPage) => {
    setActivePage(newPage);
    getTags(newPage, nameSearch);
  };
  useEffect(() => {
    getTags(1, nameSearch);
  }, [showDisabled]);

  return (
    <>
      <CreateAndEditTagClient
        isModalOpen={modalOpen}
        onModalToggle={setModalOpen}
        tagFromUpdate={tagFromUpdate}
        getTagsList={getTags}
      />

      <HeaderTagClient
        onClickCreateTag={() => {
          setTagFromUpdate(null);
          toggleModal();
        }}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Tags Do CRM</h3>
                </CardHeader>
                <Row className="mt-3 mx-2">
                  <Col md="8">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Busca tag por nome"
                        value={nameSearch}
                        type="text"
                        defaultValue=""
                        onChange={(e) => {
                          setNameSearch(e.target.value);
                        }}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="12" className="text-right mt-2 mt-md-1">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={showDisabled}
                          onChange={() => setShowDisable(!showDisabled)}
                        />
                        <span>Mostrar desabilitados</span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <CardBody>
                  <Table hover={true} striped>
                    <thead>
                      <tr>
                        <th className="text-center">Nome</th>
                        <th className="text-center">Cor</th>
                        <th className="text-center">Desabilitar/Habilitar</th>
                        <th className="text-center">Editar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tagsList.length &&
                        tagsList.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center">{item.name}</td>
                            <td className="text-center">
                              <ListColorDisplay color={item.color || '#fff'} />
                            </td>
                            <td className="text-center">
                              <ToggleSwitch
                                checked={!item.disabled}
                                id={item.id}
                                onChange={() =>
                                  disabledTag(item.id, item.name, item.disabled)
                                }
                              />
                            </td>
                            <td className="text-center">
                              <ReactButton
                                color="info"
                                onClick={() => {
                                  setTagFromUpdate(item);
                                  toggleModal();
                                }}
                                size="sm"
                                text="Editar"
                              >
                                Editar
                              </ReactButton>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </CardBody>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={totalTags}
                  onChange={goToPage.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TagsClientList;
