import { useState, useCallback } from 'react';
import StatusService from '../../service';

function useListStatusByRecordType(recordTypeId) {
  const [status, setStatus] = useState(null);
  const [statusQuantity, setStatusQuantity] = useState(0);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);

  const getStatusByRecordType = useCallback(
    async (params = {}) => {
      setIsLoadingStatus(true);

      StatusService.getByRecordType(recordTypeId, params)
        .then((response) => {
          const statusData = response.data.content;
          const quantity = response.data.quantity;
          setStatus(statusData);
          setStatusQuantity(quantity);
        })
        .catch(() => {
          setStatus(null);
        })
        .finally(() => {
          setIsLoadingStatus(false);
        });
    },
    [recordTypeId]
  );

  return {
    status,
    statusQuantity,
    isLoadingStatus,
    getStatusByRecordType
  };
}

export { useListStatusByRecordType };
