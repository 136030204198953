import { useState, useCallback } from 'react';
import opportunityService from '../services/opportunityService';

export const useSearchAccounts = () => {
  const [timeoutID, setTimeoutID] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  const findAccounts = useCallback(async (q) => {
    const params = {
      textQuery: q
    };
    try {
      const response = opportunityService.getAccounts(params);
      return response;
    } catch (error) {
      return null;
    }
  }, []);

  const handleGetAccounts = useCallback(
    async (q) => {
      try {
        if (!q) return [];
        setIsSearching(true);
        const accounts = await findAccounts(q);
        return accounts.data.data;
      } catch (error) {
        return [];
      } finally {
        setIsSearching(false);
      }
    },
    [findAccounts]
  );

  const filterAccounts = useCallback(
    async (q) => {
      return new Promise((resolve, reject) => {
        try {
          if (typeof timeoutID === 'number') {
            clearTimeout(timeoutID);
          }

          setTimeoutID(
            setTimeout(async () => {
              const data = await handleGetAccounts(q);
              resolve(data);
            }, 1000)
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    [handleGetAccounts, timeoutID]
  );

  return {
    isSearching,
    filterAccounts
  };
};
