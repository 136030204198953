import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BsFillPeopleFill } from 'react-icons/bs';
import { FaList } from 'react-icons/fa';
import { MdSettings } from 'react-icons/md';
import { BiSolidFilterAlt, BiSort } from 'react-icons/bi';
import classnames from 'classnames';
import store from 'store';
import { addInitConversationInAttendance } from 'store/action/conversation-in-attendance-action';
import { apiChat } from '../../../../http';
import {
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  TabContent,
  TabPane,
  Input
} from 'reactstrap';
import {
  IconCount,
  TopHeader,
  SubHeader,
  Hr,
  Content,
  Header
} from './components/styled';
import QueueTag from './components/QueueTag/index';
import QueueService from 'views/Queue/service';
import { toast } from 'react-toastify';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';
import ContactActiveModal from './components/Contact/ContactActiveModal/index';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import {
  organizeConversationsOptions,
  whichConversationTypeToShowOptions
} from './components/types';
import { useSocketConversationInstagram } from 'hooks/useSocketConversationInstagram';

//react virtualized
import { List, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css';
import TransferManyButton from 'views/Monitoring/Chat/conversation/components/TransferManyButton';
import TagClientService from 'views/CRM/Tags/services/TagClientService';

const QueueContent = () => {
  const ALL_TAGS = 'TODAS AS TAGS';
  const ALL_QUEUES = 'TODAS AS FILAS';
  const { socketConversation } = useConversationConnectionSocketForMonitoring();
  const { socketInstagramConversation: socketInstagram } =
    useSocketConversationInstagram();
  const [modalContactAtiveVisibility, setModalContactAtiveVisibility] =
    useState(false);
  const newContactInfo = useSelector((state) => state.contactEvents);

  const [queues, setQueues] = useState([]);
  const [idNameTags, setIdNameTags] = useState({});
  const [tags, setTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([ALL_TAGS]);
  const [selectedQueue, setSelectedQueue] = useState(['TODAS AS FILAS']);
  const [totalCount, setTotalCount] = useState(0);
  const [answeredCount, setAnsweredCount] = useState(0);
  const [unAnsweredCount, setUnAnsweredCount] = useState(0);
  const [answereds, setAnswereds] = useState([]);
  const [unAnswereds, setUnAnswereds] = useState([]);
  const [allConversation, setAllConversation] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [
    dropdownOrganizeConversationsOptions,
    setDropdownOrganizeConversationsOptions
  ] = useState(false);
  const [activeTab, setActiveTab] = useState(ALL_QUEUES);
  const [
    dropdownWhichConversationTypeToShowOptions,
    setDropdownWhichConversationTypeToShowOptions
  ] = useState(false);

  const [dropDownTagsClientOptions, setDropDownTagsClientOptions] =
    useState(false);

  const [dropdownGroup, setDropdownGroup] = useState(false);

  const [answeredsFilter, setAnsweredsFilter] = useState([]);
  const [unAnsweredsFilter, setUnAnsweredsFilter] = useState([]);
  const [allConversationFilter, setAllConversationFilter] = useState([]);

  const [organizeConversationsType, setOrganizeConversationsType] = useState(1);
  const [whichConversationTypeToShow, setWhichConversationTypeToShow] =
    useState(1);
  const [counter, setCounter] = useState(0);
  const [isActiveContact, setIsActiveContact] = useState(null);
  const [dropDownActiveContact, setDropDownActiveContact] = useState(false);
  const [selectedConversations, setSelectedConversations] = useState([]);

  const [newContactInfoController, setNewContactInfoController] =
    useState(null);

  const selectedDropdownItemColor = '#DEEBFE';

  const unAnsweredConversations = useSelector(
    (state) => state.unAnsweredConversation
  );
  const answeredConversations = useSelector(
    (state) => state.answeredConversation
  );

  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const telephonyServicePanel = useSelector(
    (state) => state.telephonyServicePanel
  );

  const handleCheck = (event, id) => {
    if (event.target.checked) {
      setSelectedConversations((prev) => [...prev, id]);
      return;
    }
    const copy = [...selectedConversations];
    const index = copy.findIndex((item) => item === id);
    copy.splice(index, 1);
    setSelectedConversations(copy);
  };

  const handlerSelectTag = (option) => {
    let tagsSelected = [...selectedTags];
    if (option === ALL_TAGS) {
      toggleTab('EM_ATENDIMENTO');
      return setSelectedTags([ALL_TAGS]);
    }
    if (option !== ALL_TAGS) {
      tagsSelected = tagsSelected.filter((item) => item !== ALL_TAGS);

      if (tagsSelected.includes(option)) {
        toggleTab('EM_ATENDIMENTO');
        return setSelectedTags(tagsSelected.filter((item) => item !== option));
      }
      toggleTab('EM_ATENDIMENTO');
      setSelectedTags([...tagsSelected, option]);
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCounter(counter + 1);
    }, 8000);

    return () => {
      clearTimeout(timeout);
    };
  }, [counter]);

  useEffect(() => {
    const localQueue = JSON.parse(localStorage.getItem('selectedQueue'));
    if (localQueue && localQueue.length === 0) {
      setSelectedQueue([...selectedQueue, ALL_QUEUES]);
    } else {
      setSelectedQueue(localQueue);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('selectedQueue', JSON.stringify(selectedQueue));
  }, [selectedQueue]);

  useEffect(() => {
    localStorage.setItem('selectedTags', JSON.stringify(selectedTags));
  }, [selectedTags]);

  useEffect(() => {
    if (selectedQueue.length > 1 && selectedQueue.includes(ALL_QUEUES)) {
      setSelectedQueue(selectedQueue.filter((item) => item !== ALL_QUEUES));
    }
  }, [selectedQueue]);
  useEffect(() => {
    let previousOrganizationType = localStorage.getItem(
      'organizeConversationsType'
    );
    setOrganizeConversationsType(previousOrganizationType || 1);
  }, []);

  useEffect(() => {
    if (Object.keys(newContactInfo.contactInfo).length > 0) {
      setNewContactInfoController(newContactInfo.contactInfo);
    }
  }, [newContactInfo]);

  useEffect(() => {
    let previousWhichConversationTypeToShow = localStorage.getItem(
      'whichConversationTypeToShow'
    );
    setWhichConversationTypeToShow(previousWhichConversationTypeToShow || 1);
  }, []);

  function organizedConversations(type) {
    localStorage.setItem(
      'organizeConversationsType',
      parseInt(organizeConversationsType)
    );
    return type.sort((a, b) => {
      if (a.mensagens?.length > 0 && b.mensagens?.length > 0) {
        if (organizeConversationsType == 1) {
          const dateA = new Date(a.hora_criacao).getTime();
          const dateB = new Date(b.hora_criacao).getTime();
          if (dateA > dateB) {
            return 1;
          }
          if (dateA < dateB) {
            return -1;
          }
          return 0;
        } else if (organizeConversationsType == 2) {
          let maxA = a.mensagens.reduce(function (a, b) {
            return a.hora_da_mensagem > b.hora_da_mensagem ? a : b;
          });

          let maxB = b.mensagens.reduce(function (a, b) {
            return a.hora_da_mensagem < b.hora_da_mensagem ? b : a;
          });

          const dateA = new Date(maxA.hora_da_mensagem).getTime();
          const dateB = new Date(maxB.hora_da_mensagem).getTime();
          return dateB - dateA;
        } else if (organizeConversationsType == 3) {
          let differenceAllTimeActivityTransferA = 0;
          let differenceAllTimeActivityTransferB = 0;
          const timelineArrayA = [];
          const timelineArrayB = [];

          a.timeline.map((item) => {
            if (item) {
              timelineArrayA.push(item);
            }
          });

          b.timeline.map((item) => {
            if (item) {
              timelineArrayB.push(item);
            }
          });

          if (timelineArrayA && timelineArrayA.length > 0) {
            if (
              timelineArrayA.length === 1 &&
              timelineArrayA[0].horaAtividade
            ) {
              const dateNowA = new Date().getTime();
              const dateA = new Date(timelineArrayA[0].horaAtividade);
              differenceAllTimeActivityTransferA += dateNowA - dateA.getTime();
            } else {
              for (let i = 0; i < timelineArrayA.length; i++) {
                if (
                  timelineArrayA[i] ===
                    timelineArrayA[timelineArrayA.length - 1] &&
                  (timelineArrayA[i].atividade === 'transferida' ||
                    timelineArrayA[i].atividade === 'nao_atendida')
                ) {
                  const dateNowA = new Date().getTime();
                  const dateA = new Date(timelineArrayA[i].horaAtividade);
                  differenceAllTimeActivityTransferA +=
                    dateNowA - dateA.getTime();
                } else {
                  if (timelineArrayA[i + 1]) {
                    switch (timelineArrayA[i].atividade) {
                      case 'transferida':
                        if (
                          timelineArrayA[i + 1].atividade === 'atender' ||
                          timelineArrayA[i + 1].atividade === 'em_atendimento'
                        ) {
                          const dateA = new Date(
                            timelineArrayA[i].horaAtividade
                          );
                          const dateB = new Date(
                            timelineArrayA[i + 1].horaAtividade
                          );
                          differenceAllTimeActivityTransferA +=
                            dateB.getTime() - dateA.getTime();
                        }
                        break;
                      case 'nao_atendida':
                        if (
                          timelineArrayA[i + 1].atividade === 'atender' ||
                          timelineArrayA[i + 1].atividade === 'em_atendimento'
                        ) {
                          const dateA = new Date(
                            timelineArrayA[i].horaAtividade
                          );
                          const dateB = new Date(
                            timelineArrayA[i + 1].horaAtividade
                          );
                          differenceAllTimeActivityTransferA +=
                            dateB.getTime() - dateA.getTime();
                        }
                        break;
                      default:
                        break;
                    }
                  }
                }
              }
            }
          } else {
            differenceAllTimeActivityTransferA = 0;
          }

          if (timelineArrayB && timelineArrayB.length > 0) {
            if (
              timelineArrayB.length === 1 &&
              timelineArrayB[0].horaAtividade
            ) {
              const dateNowB = new Date().getTime();
              const dateB = new Date(timelineArrayB[0].horaAtividade);
              differenceAllTimeActivityTransferB += dateNowB - dateB.getTime();
            } else {
              for (let i = 0; i < timelineArrayB.length; i++) {
                if (
                  timelineArrayB[i] ===
                    timelineArrayB[timelineArrayB.length - 1] &&
                  (timelineArrayB[i].atividade === 'transferida' ||
                    timelineArrayB[i].atividade === 'nao_atendida')
                ) {
                  const dateNowB = new Date().getTime();
                  const dateB = new Date(timelineArrayB[i].horaAtividade);
                  differenceAllTimeActivityTransferB +=
                    dateNowB - dateB.getTime();
                } else {
                  if (timelineArrayB[i + 1]) {
                    switch (timelineArrayB[i].atividade) {
                      case 'transferida':
                        if (
                          timelineArrayB[i + 1].atividade === 'atender' ||
                          timelineArrayB[i + 1].atividade === 'em_atendimento'
                        ) {
                          const dateB = new Date(
                            timelineArrayB[i].horaAtividade
                          );
                          const dateC = new Date(
                            timelineArrayB[i + 1].horaAtividade
                          );
                          differenceAllTimeActivityTransferB +=
                            dateC.getTime() - dateB.getTime();
                        }
                        break;
                      case 'nao_atendida':
                        if (
                          timelineArrayB[i + 1].atividade === 'atender' ||
                          timelineArrayB[i + 1].atividade === 'em_atendimento'
                        ) {
                          const dateB = new Date(
                            timelineArrayB[i].horaAtividade
                          );
                          const dateC = new Date(
                            timelineArrayB[i + 1].horaAtividade
                          );
                          differenceAllTimeActivityTransferB +=
                            dateC.getTime() - dateB.getTime();
                        }
                        break;
                      default:
                        break;
                    }
                  }
                }
              }
            }
          } else {
            differenceAllTimeActivityTransferB = 0;
          }

          if (
            differenceAllTimeActivityTransferA >
            differenceAllTimeActivityTransferB
          ) {
            return -1;
          }
          if (
            differenceAllTimeActivityTransferA <
            differenceAllTimeActivityTransferB
          ) {
            return 1;
          }
          return 0;
        }
      }
    });
  }

  function filterConversationsByType(type) {
    localStorage.setItem(
      'whichConversationTypeToShow',
      parseInt(whichConversationTypeToShow)
    );
    let conversationToFilter = type;
    let conversationAfterFilter = [];

    switch (parseInt(whichConversationTypeToShow)) {
      case 1: // Todos
        conversationAfterFilter = conversationToFilter;
        break;
      case 2: // Respondidas
        conversationAfterFilter = conversationToFilter.filter(
          (conversation) => {
            if (conversation.mensagens.length > 0) {
              const lastMessage =
                conversation.mensagens[conversation.mensagens.length - 1];

              if (
                lastMessage.cliente_ou_atendente === 'atendente' &&
                lastMessage?.escrita_por === conversation?.atendente?.name
              ) {
                return true;
              }

              return false;
            }

            return false;
          }
        );
        break;
      case 3: // Não respondidas
        conversationAfterFilter = conversationToFilter.filter(
          (conversation) => {
            if (conversation.mensagens.length > 0) {
              const lastMessage =
                conversation.mensagens[conversation.mensagens.length - 1];
              if (
                lastMessage?.cliente_ou_atendente === 'cliente' ||
                lastMessage?.escrita_por !== conversation?.atendente?.name
              ) {
                return true;
              }

              return false;
            }
            return false;
          }
        );
        break;
      default:
        console.log('Tipo de conversa não reconhecido');
    }

    return conversationAfterFilter;
  }

  function filterConversationsByTags(allConversation) {
    if (!selectedTags.includes(ALL_TAGS)) {
      return allConversation?.filter((conversation) => {
        return conversation?.tags && conversation?.tags.length > 0
          ? conversation?.tags?.some((tag) => selectedTags?.includes(tag))
          : false;
      });
    }
    return allConversation;
  }

  //useffect to answereds and unAnswereds conversations
  useEffect(() => {
    setAnswereds([...answeredConversations.conversations]);
    const unAnsweredConversationsFiltered = [
      ...unAnsweredConversations.conversations
    ].filter((conversation) => {
      if (
        conversation.atendente === null &&
        conversation.broadcastTypeOfClosingConversation === 4 &&
        conversation.isBroadCastList &&
        conversation.situacao !== 'transferida'
      ) {
        return false;
      }
      return true;
    });
    setUnAnswereds([...unAnsweredConversationsFiltered]);
    setAllConversation([
      ...answeredConversations.conversations,
      ...unAnsweredConversationsFiltered
    ]);
  }, [
    unAnsweredConversations,
    answeredConversations,
    organizeConversationsType
  ]);

  useEffect(() => {
    if (answereds) {
      let answeredsFilters = organizedConversations(answereds);
      if (!selectedQueue.includes(ALL_QUEUES)) {
        answeredsFilters = answeredsFilters.filter((conversation) => {
          return selectedQueue.includes(conversation.fila);
        });
      }

      if (isActiveContact !== null) {
        answeredsFilters = answeredsFilters.filter((conversation) => {
          return isActiveContact
            ? conversation.isContatoAtivo
            : !conversation.isContatoAtivo;
        });
      }
      if (newContactInfoController) {
        answeredsFilters = answeredsFilters.map((conversation) => {
          if (
            conversation.cliente &&
            conversation.cliente.id === newContactInfoController.conversationId
          ) {
            return {
              ...conversation,
              cliente: {
                ...conversation.cliente,
                nome: newContactInfoController.contactName
              }
            };
          }
          return conversation;
        });
      }
      answeredsFilters = filterConversationsByType(answereds);
      answeredsFilters = filterConversationsByTags(answeredsFilters);

      setAnsweredsFilter(answeredsFilters);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isActiveContact,
    selectedQueue,
    selectedTags,
    answeredConversations,
    answereds,
    organizeConversationsType,
    newContactInfoController,
    counter,
    whichConversationTypeToShow
  ]);

  useEffect(() => {
    if (unAnswereds) {
      let unAnsweredFilter = organizedConversations(unAnswereds);
      if (!selectedQueue.includes(ALL_QUEUES)) {
        unAnsweredFilter = unAnsweredFilter.filter((conversation) => {
          return selectedQueue.includes(conversation.fila);
        });
      }

      if (isActiveContact !== null) {
        unAnsweredFilter = unAnsweredFilter.filter((conversation) => {
          return isActiveContact
            ? conversation.isContatoAtivo
            : !conversation.isContatoAtivo;
        });
      }

      if (newContactInfoController) {
        unAnsweredFilter = unAnsweredFilter.map((conversation) => {
          if (
            conversation.cliente &&
            conversation.cliente.id === newContactInfoController.conversationId
          ) {
            return {
              ...conversation,
              cliente: {
                ...conversation.cliente,
                nome: newContactInfoController.contactName
              }
            };
          }
          return conversation;
        });
      }

      unAnsweredFilter = filterConversationsByType(unAnswereds);
      unAnsweredFilter = filterConversationsByTags(unAnsweredFilter);

      setUnAnsweredsFilter(unAnsweredFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedQueue,
    selectedTags,
    isActiveContact,
    unAnsweredConversations,
    unAnswereds,
    organizeConversationsType,
    newContactInfoController,
    counter,
    whichConversationTypeToShow
  ]);

  useEffect(() => {
    if (allConversation) {
      let allConversation = organizedConversations([
        ...answeredsFilter,
        ...unAnsweredsFilter
      ]);
      if (!selectedQueue.includes(ALL_QUEUES)) {
        allConversation = allConversation.filter((conversation) => {
          return selectedQueue.includes(conversation.fila);
        });
      }

      allConversation = filterConversationsByTags(allConversation);
      if (isActiveContact !== null) {
        allConversation = allConversation.filter((conversation) => {
          return isActiveContact
            ? conversation.isContatoAtivo
            : !conversation.isContatoAtivo;
        });
      }
      if (newContactInfoController) {
        allConversation = allConversation.map((conversation) => {
          if (
            conversation.cliente &&
            conversation.cliente.id === newContactInfoController.conversationId
          ) {
            return {
              ...conversation,
              cliente: {
                ...conversation.cliente,
                nome: newContactInfoController.contactName
              }
            };
          }
          return conversation;
        });
      }
      allConversation = filterConversationsByType(allConversation);
      setAllConversationFilter(allConversation);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isActiveContact,
    selectedQueue,
    selectedTags,
    answeredsFilter,
    unAnsweredsFilter,
    organizeConversationsType,
    newContactInfoController,
    counter,
    whichConversationTypeToShow
  ]);

  useEffect(() => {
    QueueService.getAll()
      .then((response) => {
        let arrayQueues = [];
        const attendantQueues = JSON.parse(localStorage.getItem('queues'));
        response.data.data.forEach((queue) => {
          attendantQueues.forEach((attendantQueue) => {
            if (queue.id === attendantQueue.id) arrayQueues.push(queue);
          });
        });
        let filteredQueues = arrayQueues.filter((fila) => {
          return fila['is_chat'];
        });
        setQueues([...filteredQueues]);
      })
      .catch((err) => {});

    TagClientService.getAll({ showDisabled: true }).then((response) => {
      const tagsResponse = response.data.data;

      if (tagsResponse && tagsResponse?.length) {
        const idToTagName = {};
        tagsResponse.forEach((tag) => {
          idToTagName[tag.id] = {
            name: tag.name,
            disabled: tag?.disabled,
            color: tag?.color
          };
        });

        setIdNameTags(idToTagName);
        const localTags = JSON.parse(localStorage.getItem('selectedTags'));
        setTags(tagsResponse);
        if (localTags && localTags.length) {
          if (!localTags.includes(ALL_TAGS)) {
            setSelectedTags(
              tagsResponse.filter((tag) => tagsResponse.includes(tag.name))
            );
            return;
          }
          setSelectedTags([
            ...tagsResponse.filter((tag) => tagsResponse.includes(tag.name)),
            ALL_TAGS
          ]);
        } else {
          setSelectedTags([ALL_TAGS]);
        }
      }
    });
  }, []);

  const getConversations = useCallback(() => {
    socketConversation.emit('conversas_do_atendente', {
      atendente_login: localStorage.getItem('login')
    });
  }, [socketConversation]);

  useEffect(() => {
    getConversations();
  }, [getConversations]);

  useEffect(() => {
    socketConversation.on('conversa_atendida', async (data) => {
      if (data.ja_atendida === undefined || data.ja_atendida === false) {
        await store.dispatch(addInitConversationInAttendance(data.conversa));
      } else {
        getConversations();
      }
    });
  }, [socketConversation, getConversations]);

  useEffect(() => {
    socketInstagram.on('conversa_atendida', async (data) => {
      if (data.ja_atendida === undefined || data.ja_atendida === false) {
        await store.dispatch(addInitConversationInAttendance(data.conversa));
        getConversations();
      } else {
        getConversations();
      }
    });
  }, [socketInstagram, getConversations]);

  const handleItemClick = (option) => {
    if (option === ALL_QUEUES) {
      setSelectedQueue([ALL_QUEUES]);
    }
    if (option !== ALL_QUEUES) {
      if (selectedQueue.includes(option)) {
        setSelectedQueue(selectedQueue.filter((item) => item !== option));
        setActiveTab(ALL_QUEUES);
      } else {
        setSelectedQueue([...selectedQueue, option]);
        setActiveTab(ALL_QUEUES);
      }
    }
  };

  const answerConversation = async (
    id,
    transferMode,
    clientPhone,
    canal = ''
  ) => {
    if (transferMode === 'call') {
      if (telephonyServicePanel.extension === '') {
        toast.error(
          `Você precisa estar logado em um ramal para continuar este atendimento!`,
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
        return;
      }

      if (clientPhone === '') {
        toast.error(
          `Não é possível realizar a chamada pois não há telefone no cadastro do cliente!`,
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
        return;
      }

      if (
        !window.confirm(
          'Este cliente deseja um atendimento por ligação, tem certeza que deseja realizar a chamada agora?'
        )
      )
        return;

      const data = {
        origem: telephonyServicePanel.extension,
        destino: clientPhone
      };

      await apiChat.post(`/clicktocall`, data);
    }

    setActiveTab('EM_ATENDIMENTO');
    await socketInstagram.emit('atender_conversa', {
      atendente: {
        nome: localStorage.getItem('name'),
        login: localStorage.getItem('login'),
        id_socket:
          canal === 'facebook' ? socketConversation.id : socketInstagram.id
      },
      id
    });

    getConversations();
    answereds.forEach((el) => {
      if (el._id === id) {
        socketConversation.emit('buscar_conversa_id', id);
      }
    });
  };

  //counters
  useEffect(() => {
    let answered = 0;
    let unAnswered = 0;

    if (isActiveContact !== null) {
      if (isActiveContact) {
        answeredsFilter.forEach((conversation) => {
          if (
            (selectedQueue.includes(conversation.fila) ||
              selectedQueue.includes(ALL_QUEUES)) &&
            isActiveContact === conversation.isContatoAtivo
          ) {
            answered += 1;
          }
        });
        unAnsweredsFilter.forEach((conversation) => {
          if (
            (selectedQueue.includes(conversation.fila) ||
              selectedQueue.includes(ALL_QUEUES)) &&
            isActiveContact === conversation.isContatoAtivo
          ) {
            unAnswered += 1;
          }
        });
      } else if (!isActiveContact) {
        answeredsFilter.forEach((conversation) => {
          if (
            (selectedQueue.includes(conversation.fila) ||
              selectedQueue.includes(ALL_QUEUES)) &&
            isActiveContact === conversation.isContatoAtivo
          ) {
            answered += 1;
          }
        });
        unAnsweredsFilter.forEach((conversation) => {
          if (
            (selectedQueue.includes(conversation.fila) ||
              selectedQueue.includes(ALL_QUEUES)) &&
            isActiveContact === conversation.isContatoAtivo
          ) {
            unAnswered += 1;
          }
        });
      }
    } else {
      answeredsFilter.forEach((conversation) => {
        if (
          selectedQueue.includes(conversation.fila) ||
          selectedQueue.includes(ALL_QUEUES)
        )
          answered += 1;
      });

      unAnsweredsFilter.forEach((conversation) => {
        if (
          selectedQueue.includes(conversation.fila) ||
          selectedQueue.includes(ALL_QUEUES)
        )
          unAnswered += 1;
      });
    }

    setTotalCount(answered + unAnswered);
    setAnsweredCount(answered);
    setUnAnsweredCount(unAnswered);
  }, [
    selectedQueue,
    selectedTags,
    isActiveContact,
    answeredsFilter,
    unAnsweredsFilter
  ]);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      clearSelectedConversations();
      setActiveTab(tab);
    }
  };

  const clearSelectedConversations = () => {
    setSelectedConversations([]);
  };

  const showQueue = (queue) => {
    if (queue?.length > 1) {
      return queue.map((item) => {
        return (
          <div
            key={item}
            style={{
              borderBottom: 'solid 1px',
              borderColor: '#d3d3d3'
            }}
          >
            {item}
          </div>
        );
      });
    }
    if (queue?.length === 0) {
      return 'Selecione uma fila';
    } else {
      return queue;
    }
  };

  return (
    <Content primaryColor={systemConfiguration.primary_color}>
      <Header>
        <TopHeader style={{ maxWidth: '100%' }}>
          <IconCount
            primaryColor={systemConfiguration.primary_color}
            count={totalCount.toString().length > 4 ? '0.7rem' : '1rem'}
            id="IconCount"
          >
            {totalCount}
          </IconCount>
          <Nav tabs className="MainLink mt-1">
            <NavItem>
              <NavLink
                id="AllQueues"
                className={classnames({
                  active: activeTab === ALL_QUEUES
                })}
                onClick={() => {
                  toggleTab(ALL_QUEUES);
                }}
              >
                <h4
                  className="display-6 text-uppercase"
                  style={{
                    display: 'flex',
                    width: '180px',
                    height: '40px',
                    flexDirection: 'column',
                    marginRight: '-25px',
                    overflowX: 'hidden',
                    overflowY: 'auto',
                    scrollbarWidth: 'thin'
                  }}
                >
                  {showQueue(selectedQueue)}
                </h4>
              </NavLink>
            </NavItem>
          </Nav>
          <div style={{ position: 'relative', zIndex: 1001, left: '285px' }}>
            <Dropdown
              isOpen={dropdownOpen}
              toggle={() => {
                setDropdownOpen(!dropdownOpen);
              }}
            >
              <DropdownToggle
                color="link"
                style={{
                  color: systemConfiguration.primary_color,
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  position: 'absolute',
                  opacity: 0,
                  pointerEvents: 'none'
                }}
              >
                <FaList size="20" title="Filas Disponíveis" />
              </DropdownToggle>
              <DropdownMenu className="DropdownMenu">
                <DropdownItem value="ALL_QUEUES" toggle={false}>
                  <input
                    type="checkbox"
                    checked={selectedQueue.includes(ALL_QUEUES)}
                    onChange={() => handleItemClick(ALL_QUEUES)}
                    onClick={() => {
                      toggleTab(selectedQueue);
                      setActiveTab(ALL_QUEUES);
                    }}
                    style={{ marginRight: '5px' }}
                  />
                  Todas
                </DropdownItem>
                <DropdownItem divider />
                {queues.map((queue, index) => {
                  return (
                    <DropdownItem
                      key={`dropdownitem+${queue.id}+${index}`}
                      value={queue.name}
                      toggle={false}
                    >
                      <input
                        type="checkbox"
                        value={queue.name}
                        checked={selectedQueue.includes(queue.name)}
                        onChange={() => {
                          handleItemClick(queue.name);
                        }}
                        onClick={(e) => {
                          toggleTab(e.target.value);
                        }}
                        style={{ marginRight: '5px' }}
                      />
                      {queue.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              isOpen={dropdownOrganizeConversationsOptions}
              toggle={() => {
                setDropdownOrganizeConversationsOptions(
                  !dropdownOrganizeConversationsOptions
                );
              }}
            >
              <DropdownToggle
                color="link"
                style={{
                  color: systemConfiguration.primary_color,
                  paddingLeft: '0',
                  paddingRight: '0',
                  position: 'absolute',
                  opacity: 0,
                  pointerEvents: 'none'
                }}
              >
                <BiSort size="20" title="Organização das chamadas" />
              </DropdownToggle>
              <DropdownMenu className="DropdownMenu">
                {organizeConversationsOptions.map((filter, index) => (
                  <DropdownItem
                    toggle={false}
                    key={`${filter.value}+${index}}`}
                    value={filter.value}
                    style={{
                      backgroundColor:
                        filter.value === parseInt(organizeConversationsType)
                          ? selectedDropdownItemColor
                          : ''
                    }}
                    onClick={(e) => {
                      setOrganizeConversationsType(e.target.value);
                    }}
                  >
                    {filter.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              isOpen={dropdownWhichConversationTypeToShowOptions}
              toggle={() => {
                setDropdownWhichConversationTypeToShowOptions(
                  !dropdownWhichConversationTypeToShowOptions
                );
              }}
            >
              <DropdownToggle
                color="link"
                style={{
                  color: systemConfiguration.primary_color,
                  paddingLeft: '0',
                  paddingRight: '0',
                  position: 'absolute',
                  opacity: 0,
                  pointerEvents: 'none'
                }}
              >
                <BiSolidFilterAlt size="20" title="Mostrar conversas" />
              </DropdownToggle>
              <DropdownMenu className="DropdownMenu">
                {whichConversationTypeToShowOptions.map((filter, index) => (
                  <DropdownItem
                    toggle={false}
                    key={`${filter.value}+${index}}`}
                    value={filter.value}
                    style={{
                      backgroundColor:
                        filter.value === parseInt(whichConversationTypeToShow)
                          ? selectedDropdownItemColor
                          : ''
                    }}
                    onClick={(e) => {
                      setWhichConversationTypeToShow(e.target.value);
                    }}
                  >
                    {filter.label}
                  </DropdownItem>
                ))}
              </DropdownMenu>
            </Dropdown>

            <Dropdown
              isOpen={dropDownTagsClientOptions}
              toggle={() => {
                setDropDownTagsClientOptions(!dropDownTagsClientOptions);
              }}
            >
              <DropdownToggle
                color="link"
                style={{
                  color: systemConfiguration.primary_color,
                  paddingLeft: '10px',
                  paddingRight: '10px',
                  position: 'absolute',
                  opacity: 0,
                  pointerEvents: 'none'
                }}
              >
                <FaList size="20" title="Tags Disponíveis" />
              </DropdownToggle>
              <DropdownMenu className="DropdownMenu">
                <DropdownItem value={ALL_TAGS} toggle={false}>
                  <input
                    type="checkbox"
                    checked={selectedTags.includes(ALL_TAGS)}
                    onChange={() => {
                      handlerSelectTag(ALL_TAGS);
                      toggleTab('EM_ATENDIMENTO');
                    }}
                    onClick={() => {
                      handlerSelectTag(ALL_TAGS);
                      toggleTab('EM_ATENDIMENTO');
                    }}
                    style={{ marginRight: '5px' }}
                  />
                  Todas
                </DropdownItem>
                <DropdownItem divider />
                {tags?.map((tag, index) => {
                  return (
                    <DropdownItem
                      key={`dropdownitem+${tag.id}+${index}`}
                      value={tag.id}
                      toggle={false}
                    >
                      <input
                        type="checkbox"
                        value={tag.id}
                        checked={selectedTags.includes(tag.id)}
                        onChange={() => {
                          handlerSelectTag(tag.id);
                          toggleTab('EM_ATENDIMENTO');
                        }}
                        onClick={() => {
                          toggleTab('EM_ATENDIMENTO');
                        }}
                        style={{ marginRight: '5px' }}
                      />
                      {tag.name}
                    </DropdownItem>
                  );
                })}
              </DropdownMenu>
            </Dropdown>
          </div>
          {/* Primeiras opções */}
          <Dropdown
            isOpen={dropdownGroup}
            toggle={() => {}}
            style={{ display: 'flex', marginLeft: '8px' }}
            autoClose={false}
          >
            <DropdownToggle
              onClick={() => setDropdownGroup(!dropdownGroup)}
              color="link"
              style={{
                color: systemConfiguration.primary_color,
                paddingLeft: '0',
                paddingRight: '0'
              }}
            >
              <FaList size="20" title="Filtros" />
            </DropdownToggle>

            <DropdownMenu autoClose={false} className="DropdownMenu">
              <DropdownItem
                onClick={() => setDropdownOpen(true)}
                toggle={false}
              >
                Filas
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  setDropdownWhichConversationTypeToShowOptions(true)
                }
                toggle={false}
              >
                Status da conversa
              </DropdownItem>
              <DropdownItem
                onClick={() => setDropdownOrganizeConversationsOptions(true)}
                toggle={false}
              >
                Tempo de interação
              </DropdownItem>
              <DropdownItem
                onClick={() => setDropDownTagsClientOptions(true)}
                toggle={false}
              >
                Tags do cliente
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>

          <TransferManyButton
            id={selectedConversations}
            isStringArray={true}
            altIcon
          ></TransferManyButton>
        </TopHeader>
        <SubHeader className="mt-2">
          <Nav
            tabs
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
              boxSizing: 'border-box',
              flexWrap: 'nowrap',
              width: '100%'
            }}
          >
            <NavItem>
              <NavLink
                className={classnames(
                  { active: activeTab === 'EM_ESPERA' },
                  'px-2'
                )}
                id="AllAwaiting"
                onClick={() => {
                  toggleTab('EM_ESPERA');
                }}
              >
                <h5
                  className="display-6"
                  style={{
                    fontSize: '0.7rem'
                  }}
                >
                  EM ESPERA: {unAnsweredCount}
                </h5>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames(
                  {
                    active: activeTab === 'EM_ATENDIMENTO'
                  },
                  'px-2'
                )}
                id="AllAttendance"
                onClick={() => {
                  toggleTab('EM_ATENDIMENTO');
                }}
              >
                <h5
                  className="display-6"
                  style={{
                    fontSize: '0.7rem'
                  }}
                >
                  EM ATENDIMENTO: {answeredCount}
                </h5>
              </NavLink>
            </NavItem>
            {verifyRoles(['ACTIVE_CONTACT']) && (
              <NavItem>
                <NavLink
                  onClick={() =>
                    setModalContactAtiveVisibility(!modalContactAtiveVisibility)
                  }
                  className="px-2"
                >
                  <BsFillPeopleFill
                    size={'20'}
                    type="button"
                    color={systemConfiguration.primary_color}
                    style={{
                      marginBotton: '0.25rem'
                    }}
                  />
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <Dropdown
                isOpen={dropDownActiveContact}
                toggle={() => {
                  setDropDownActiveContact(!dropDownActiveContact);
                }}
                style={{ margin: '0', padding: '0' }}
              >
                <DropdownToggle
                  color="link"
                  style={{
                    color: systemConfiguration.primary_color,
                    margin: '0',
                    padding: '0'
                  }}
                >
                  <MdSettings
                    size="20"
                    title={`Visibilidade das conversas com contato ativo\nVocê pode escolher se deseja ver as conversas com contato ativo ou receptivo\nVocê escolheu ver ${
                      isActiveContact === null
                        ? 'Todas as conversas'
                        : isActiveContact
                        ? 'Apenas conversas com contato ativo'
                        : 'Apenas conversas com contato receptivo'
                    }
	                    `}
                  />
                </DropdownToggle>
                <DropdownMenu className="DropdownMenu">
                  <DropdownItem
                    value={ALL_QUEUES}
                    onClick={() => {
                      setIsActiveContact(null);
                    }}
                    style={{
                      backgroundColor:
                        isActiveContact === null
                          ? selectedDropdownItemColor
                          : ''
                    }}
                  >
                    Todas as Conversas
                  </DropdownItem>
                  <DropdownItem
                    value={ALL_QUEUES}
                    onClick={() => {
                      setIsActiveContact(true);
                    }}
                    style={{
                      backgroundColor:
                        isActiveContact === true
                          ? selectedDropdownItemColor
                          : ''
                    }}
                  >
                    Contato Ativo
                  </DropdownItem>
                  <DropdownItem
                    value={ALL_QUEUES}
                    onClick={() => {
                      setIsActiveContact(false);
                    }}
                    style={{
                      backgroundColor:
                        isActiveContact === false
                          ? selectedDropdownItemColor
                          : ''
                    }}
                  >
                    Contato Receptivo
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </NavItem>
          </Nav>
        </SubHeader>
        <Hr />
      </Header>
      <TabContent activeTab={activeTab}>
        <TabPane
          tabId={ALL_QUEUES}
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={110}
                rowCount={allConversationFilter.length || 0}
                rowGetter={({ index }) => allConversationFilter[index] || {}}
                rowRenderer={({ key, index, style }) => {
                  return (
                    <>
                      <div key={key} style={style}>
                        <QueueTag
                          key={key}
                          style={style}
                          conversation={allConversationFilter[index]}
                          allQueues={queues}
                          idNameTags={idNameTags}
                          functionAnswerConversation={answerConversation}
                        />
                      </div>
                    </>
                  );
                }}
              />
            )}
          </AutoSizer>
        </TabPane>
        <TabPane
          tabId="EM_ESPERA"
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={110}
                rowCount={unAnsweredsFilter.length || 0}
                rowGetter={({ index }) => unAnsweredsFilter[index] || {}}
                rowRenderer={({ key, index, style }) => {
                  {
                    if (isActiveContact !== null) {
                      if (
                        (selectedQueue.includes(
                          unAnsweredsFilter[index].fila
                        ) ||
                          selectedQueue.includes(ALL_QUEUES)) &&
                        isActiveContact ===
                          unAnsweredsFilter[index].isContatoAtivo
                      ) {
                        return (
                          <div key={key} style={style}>
                            <QueueTag
                              key={key}
                              style={style}
                              conversation={unAnsweredsFilter[index]}
                              allQueues={queues}
                              idNameTags={idNameTags}
                              functionAnswerConversation={answerConversation}
                            />
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    } else {
                      if (
                        selectedQueue.includes(unAnsweredsFilter[index].fila) ||
                        selectedQueue.includes(ALL_QUEUES)
                      ) {
                        return (
                          <div key={key} style={style}>
                            <QueueTag
                              key={key}
                              style={style}
                              conversation={unAnsweredsFilter[index]}
                              allQueues={queues}
                              idNameTags={idNameTags}
                              functionAnswerConversation={answerConversation}
                            />
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    }
                  }
                }}
              />
            )}
          </AutoSizer>
        </TabPane>
        <TabPane
          tabId="EM_ATENDIMENTO"
          style={{
            width: '100%',
            height: '100%'
          }}
        >
          <AutoSizer>
            {({ width, height }) => (
              <List
                width={width}
                height={height}
                rowHeight={110}
                rowCount={answeredsFilter.length || 0}
                rowGetter={({ index }) => answeredsFilter[index] || {}}
                rowRenderer={({ key, index, style }) => {
                  if (
                    selectedQueue.includes(answeredsFilter[index].fila) ||
                    selectedQueue.includes(ALL_QUEUES)
                  ) {
                    if (
                      isActiveContact === null ||
                      isActiveContact === answeredsFilter[index].isContatoAtivo
                    ) {
                      const id =
                        answeredsFilter[index].id ?? answeredsFilter[index]._id;

                      return (
                        <div key={key} style={style}>
                          {verifyRoles(['CHAT_MULTIPLE_TRANSFER']) && (
                            <Input
                              style={{
                                position: 'absolute',
                                zIndex: '100000',
                                top: '14px',
                                left: '32px'
                              }}
                              checked={selectedConversations.includes(id)}
                              onClick={(event) => {
                                handleCheck(event, id);
                              }}
                              type="checkbox"
                            />
                          )}

                          <QueueTag
                            key={key}
                            style={style}
                            conversation={answeredsFilter[index]}
                            idNameTags={idNameTags}
                            allQueues={queues}
                            functionAnswerConversation={answerConversation}
                          />
                        </div>
                      );
                    }
                  }
                }}
              />
            )}
          </AutoSizer>
        </TabPane>
      </TabContent>

      <ContactActiveModal
        className="modal-lg"
        show={modalContactAtiveVisibility}
        onModalToggle={setModalContactAtiveVisibility}
      />
    </Content>
  );
};

export default QueueContent;
