import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Container, Row, Card, Col, CardHeader, CardBody } from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useRecordTypes } from '../RecordType/hooks/useRecordTypes';
import { ConfigurationArea } from './components/ConfigurationArea';
import { custom_select } from 'assets/styles/multiple_select';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';

const ProtocolConfiguration = () => {
  const { recordTypes } = useRecordTypes(entitiesName.PROTOCOL);
  const [selectedRecordType, setSelectedRecordType] = useState(null);

  useEffect(() => {
    if (recordTypes && !!recordTypes.length) {
      setSelectedRecordType({
        label: recordTypes[0].name,
        value: recordTypes[0].id
      });
    }
  }, [recordTypes]);

  useEffect(() => {
    if (!selectedRecordType) {
      return setSelectedRecordType(null);
    }
  }, [selectedRecordType]);

  const handleSelectedRecordTypeChange = (data) => {
    if (!data) {
      setSelectedRecordType(null);
    }
    setSelectedRecordType(data);
  };

  const getSelectOptionsDTO = () => {
    if (!recordTypes) return [];
    return recordTypes.map((group) => ({ label: group.name, value: group.id }));
  };

  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Configuração de Protocolo(s)</h3>
                </CardHeader>
                <CardBody>
                  <Row className="mb-4">
                    <Col>
                      <label className="form-control-label">
                        Tipo de registro
                      </label>
                      <Select
                        styles={custom_select}
                        placeholder="Selecione..."
                        options={getSelectOptionsDTO() || []}
                        isSearchable={false}
                        isClearable={true}
                        name="recordType"
                        defaultValue=""
                        value={selectedRecordType}
                        onChange={handleSelectedRecordTypeChange}
                      />
                    </Col>
                  </Row>
                  {selectedRecordType && (
                    <ConfigurationArea
                      recordTypeId={selectedRecordType.value}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ProtocolConfiguration;
