import styled from 'styled-components';

export const ContentLink = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 1180px;
  @media (max-width: 800px) {
    flex-direction: column;
  }

  input[type='text'] {
    display: inline-block;
    border: solid 0.1rem #b3b3b3;
    border-radius: 5px;
    width: 90%;
    height: 35px;
    color: #525f7f;
    transition: 0.2s;
    &:hover {
      border-color: #5e72e4;
    }
    @media (max-width: 800px) {
      margin: 10px 0 0 0;
      width: 100%;
    }
  }

  button {
    border: 0;
    margin-left: 2px;
    border-radius: 5px;
    width: calc(100% - 90%);
    transition: 0.2s;
    background-color: #5e72e4;
    svg {
      transition: filter 0.2s;
      color: #ffffff;
    }

    &:hover {
      svg {
        color: #32325d;
      }
    }

    @media (max-width: 800px) {
      margin: 10px 0 0 0;
      width: 100%;
    }
  }
`;

//Olá seja bem vindo
