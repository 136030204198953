import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { BlockAcessServicePainelProvider } from './hooks/useBlockAcessServicePainel';

import { ConfigurationContainer } from './components/Containers/ConfigurationContainer';
import 'assets/vendor/bootstrap-rtl/bootstrap-rtl.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
// Novas fontes
import 'assets/vendor/newFonts/openSans/css/openSans.css';
import 'assets/vendor/newFonts/roboto/css/roboto.css';

import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AdminLayout from 'layouts/Admin/Admin';
import 'assets/css/g4flex.css';

import MonitoringLayout from './layouts/Monitoring';
import DialerDashboardLayout from './layouts/Dialer';
import ProtocolCreateLayout from './layouts/Protocol';
import LoginLayout from './layouts/Login/Login';
import LogoutHandler from './layouts/Login/logout/logout';
import UnauthorizedHandler from './layouts/Login/Unauthorized';
import ActiveLogoutHandler from './layouts/Login/logout/Active';
import Survey from 'layouts/Survey';
import LoginAutoAuth from './layouts/LoginAutoAuth';

import store from './store';

import CacheBuster from 'react-cache-buster';
import { version } from '../package.json';
import ReactLoader from 'components/Loader';

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// const rootReducer = combineReducers({
//   auth: authReduce,
//   answeredConversation: answeredConversationReduce,
//   unAnsweredConversation: unAnsweredConversationReduce
// });

// const store = createStore(
//   rootReducer,
//   composeEnhancers(applyMiddleware(thunk))
// );

const isProduction = process.env.NODE_ENV === 'production';

const PrivateRoute = ({ component: AdminLayout, ...rest }) => {
  const isLoginExtension = JSON.parse(localStorage.getItem('isLoginExtension'));
  return (
    <Route
      {...rest}
      render={(props) =>
        isLoginExtension ? (
          <Redirect
            to={{ pathname: '/LoginAutoAuth', state: { from: props.location } }}
          />
        ) : (
          <AdminLayout {...props} />
        )
      }
    />
  );
};
ReactDOM.render(
  <Provider store={store}>
    <CacheBuster
      currentVersion={version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<ReactLoader />}
    >
      <ConfigurationContainer>
        <BlockAcessServicePainelProvider>
          <BrowserRouter>
            <ToastContainer />
            <Switch>
              <PrivateRoute path="/admin" component={AdminLayout} />

              <Route
                path="/monitoring"
                render={(props) => <MonitoringLayout {...props} />}
              />
              <Route
                path="/dialer"
                render={(props) => <DialerDashboardLayout {...props} />}
              />
              <Route
                path="/callback"
                render={(props) => <DialerDashboardLayout {...props} />}
              />
              <Route
                path="/protocol"
                render={(props) => <ProtocolCreateLayout {...props} />}
              />
              {/* <Route path="/users/reset/forgot" exact component={ForgotPassword} />
        <Route path="/users/reset/password" exact component={ResetPassword} /> */}
              <Route
                path="/unauthorized"
                exact
                component={UnauthorizedHandler}
              />
              <Route path="/attendance/survey" exact component={Survey} />
              <Route path="/user/logout" exact component={LogoutHandler} />
              <Route
                path="/activelogout"
                exact
                component={ActiveLogoutHandler}
              />
              <Route path="/" exact component={LoginLayout} />
              <Route path="/LoginAutoAuth" component={LoginAutoAuth} />
              <Redirect from="*" to="/" />
            </Switch>
          </BrowserRouter>
        </BlockAcessServicePainelProvider>
      </ConfigurationContainer>
    </CacheBuster>
  </Provider>,
  document.getElementById('root')
);
