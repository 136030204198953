import React, { useState, useEffect } from 'react';
import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';

import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { sheetReader } from 'utils/fuctions/sheetReader.js';
import { generateSheetHeaders } from 'utils/fuctions/generateSheetHeaders.js';
import BlacklistService from '../../../service';

const UploadModal = ({
  isModalOpen,
  onModalToggle,
  onImport,
  blacklistId,
  ...props
}) => {
  const initialState = {
    file: '',
    data: [],
    hasHeader: true,
    columns: []
  };
  const [state, setState] = useState(initialState);
  const { control, handleSubmit, setValue, errors } = useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const changeFile = async (event) => {
    const file = event.target.files[0];

    if (!file) return;

    const result = await sheetReader(file);

    const data = result
      .split(/\r?\n|\r/)
      .map((item) => item.split(/,|\t/))
      .filter((item) => item.length > 1);

    setState((s) => ({ ...s, data, file }));
  };

  const changeHasHeader = (e) => {
    const hasHeader = e.target.checked;

    if (!hasHeader) {
      const headers = generateSheetHeaders(state.columns.length);

      setState((s) => ({ ...s, hasHeader, data: [headers, ...s.data] }));
    } else {
      setState((s) => {
        s.data.shift();

        return { ...s, hasHeader, data: [...s.data] };
      });
    }
  };

  const onSubmit = async (data) => {
    const cols = data.origin.map((h) => state.data[0].indexOf(h));

    try {
      await BlacklistService.importBlacklistNumberList(blacklistId, {
        list: state.data,
        header: state.hasHeader,
        cols
      });

      toast.success('Lista de números salva!', {
        autoClose: 3000,
        closeOnClick: true
      });
      onImport();
    } catch (err) {
      toast.error('Não foi possível salvar lista de números!', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setState(initialState);
      onModalToggle(!isModalOpen);
    }
  };

  useEffect(() => {
    const [headers] = state.data;

    if (!headers) return;

    const columns = headers.map((e) => ({ col: e }));

    setState((s) => ({ ...s, columns }));
  }, [state.data]);

  return (
    <Modal
      className="modal-dialog-centered"
      isOpen={isModalOpen}
      toggle={() => onModalToggle(!isModalOpen)}
      returnFocusAfterClose={false}
      {...props}
    >
      <div className="modal-header">
        <h6 className="modal-title" id="modal-title-default">
          Importar Lista de Números
        </h6>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => {
            setState(initialState);
            onModalToggle(!isModalOpen);
          }}
        >
          <span aria-hidden={true}>&times;</span>
        </button>
      </div>
      {!state.file ? (
        <ModalBody>
          <Form>
            <FormGroup>
              <label className="form-control-label d-block mb-3">
                Insira um arquivo do tipo CSV, XLS ou XLSX
              </label>
              <label
                htmlFor="import_client_list"
                className="btn btn-primary btn-sm"
                style={buttonStyle(systemConfiguration.primary_color)}
              >
                Selecionar um arquivo
              </label>
              <Input
                style={{ display: 'none' }}
                type="file"
                id="import_client_list"
                name="import_client_list"
                accept=".csv, .xls, .xlsx"
                value={state.file}
                onChange={changeFile}
              />
            </FormGroup>
          </Form>
        </ModalBody>
      ) : (
        <>
          <ModalBody>
            <div style={{ marginBottom: '24px' }}>
              <div style={{ marginBottom: '8px', fontSize: '0.875rem' }}>
                Arquivo
              </div>
              <div
                style={{
                  color: '#525f7f',
                  fontSize: '1rem',
                  fontWeight: '600'
                }}
              >
                {state.file.name}
              </div>
            </div>
            <Form
              id="import-client-list-form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <FormGroup check style={{ marginBottom: '24px' }}>
                <Label check>
                  <Input
                    type="checkbox"
                    name="hasHeader"
                    checked={state.hasHeader}
                    onChange={changeHasHeader}
                  />
                  Utilizar primeira linha como cabeçalho
                </Label>
              </FormGroup>
              <FormGroup>
                <Controller
                  control={control}
                  name="origin"
                  defaultValue=""
                  render={(props) => (
                    <FormGroup>
                      <Label className="form-control-label text-capitalize">
                        Colunas de importação
                      </Label>
                      <FlexSelect
                        isClearable={true}
                        dataOptions={state.columns}
                        isMulti={true}
                        closeMenuOnSelect={true}
                        value={props.value}
                        valueController={setValue}
                        fieldName="origin"
                        labelName="col"
                        valueName="col"
                      />
                    </FormGroup>
                  )}
                  rules={{
                    required: 'Origem é obrigatório!'
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="origin"
                  render={({ message }) => (
                    <p style={{ color: 'red' }}>{message}</p>
                  )}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <ReactButton
              btnColor="confirmation"
              type="submit"
              form="import-client-list-form"
              disabled={state.file ? false : true}
            >
              Importar
            </ReactButton>
            <ReactButton
              btnColor="cancelation"
              onClick={() => {
                setState(initialState);
                onModalToggle(!isModalOpen);
              }}
            >
              Cancelar
            </ReactButton>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default UploadModal;
