import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import DatePicker from '../../../../../../components/Inputs/DatePicker';
import FlexSelect from '../../../../../../components/Inputs/FlexSelect';
import ReactButton from '../../../../../../components/Buttons/ReactButton';
import { ButtonWrapper } from '../styles';
import { useHandleProtocolListingToContacts } from 'views/Protocol/list/hooks/useHandleProtocolListingToContacts';
import { ClientProtocolTable } from './ClientProtocolTable';
import Pagination from 'components/Pagination';
import ReactLoader from 'components/Loader';

export const ClientProtocolModal = ({
  isModalOpen,
  toggleModal,
  clientInfo,
  recordTypes,
  isProtocolScreen = false,
  clientId
}) => {
  const [activePage, setActivePage] = useState(1);
  const [selectedType, setSelectedType] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).toLocaleDateString('pt-br')
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString('pt-br')
  );

  const { protocols, getProtocols, protocolsQuantity, isLoadingProtocols } =
    useHandleProtocolListingToContacts();

  useEffect(() => {
    getProtocols({
      dateBegin: startDate,
      dateEnd: endDate,
      clientTextQuery: clientInfo ? clientInfo : null,
      client_id: clientId,
      page: 1,
      size: 10
    });
  }, [clientInfo, clientId]);

  const getSelectOptionsDTO = () => {
    if (!recordTypes) return [];
    return recordTypes.map((group) => ({ label: group.name, value: group.id }));
  };

  const handlePageChange = (page) => {
    getProtocols({
      dateBegin: startDate,
      dateEnd: endDate,
      clientTextQuery: clientInfo ? clientInfo : null,
      client_id: clientId,
      page: page,
      size: 10
    });
    setActivePage(page);
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={isModalOpen}
        toggle={toggleModal}
        style={{ width: '80vw', maxWidth: ' 40rem' }}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Protocolos do contato
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>x</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />

          {recordTypes && recordTypes.length > 1 ? (
            <Row style={{ justifyContent: 'center', marginBottom: '0.5rem' }}>
              <Col md={12}>
                <label className="form-control-label">Tipo de registro</label>
                <FlexSelect
                  value={selectedType}
                  fieldName={'selectedType'}
                  valueController={(fieldName, value) => {
                    setSelectedType(value);
                  }}
                  dataOptions={getSelectOptionsDTO()}
                  labelName="label"
                  valueName="value"
                  closeMenuOnSelect={true}
                />
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md={6}>
              <label className="form-control-label">Data inicial</label>
              <DatePicker
                flexWrap={false}
                fieldName="startDate"
                value={startDate}
                valueController={(fieldname, value) => {
                  setStartDate(value);
                }}
                defaultValue={startDate}
              />
            </Col>
            <Col md={6}>
              <label className="form-control-label">Data final</label>
              <DatePicker
                flexWrap={false}
                fieldName="endDate"
                value={endDate}
                valueController={(fieldname, value) => {
                  setEndDate(value);
                }}
                defaultValue={endDate}
              />
            </Col>
          </Row>

          <ButtonWrapper>
            <ReactButton
              onClick={() => {
                getProtocols({
                  dateBegin: startDate,
                  dateEnd: endDate,
                  clientTextQuery: clientInfo ? clientInfo : null,
                  client_id: clientId
                });
              }}
              btnColor="confirmation"
            >
              Filtrar protocolo(s)
            </ReactButton>
          </ButtonWrapper>

          {protocols && !isLoadingProtocols ? (
            protocols.length > 0 ? (
              <>
                <ClientProtocolTable
                  data={protocols}
                  selectedType={selectedType}
                  isProtocolScreen={isProtocolScreen}
                />
                <Pagination
                  activePage={activePage}
                  totalItemsCount={protocolsQuantity}
                  onChange={handlePageChange.bind(this)}
                />
              </>
            ) : (
              <p className="mb-4 mt-4 text-center">
                Nenhum protocolo foi encontrado
              </p>
            )
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ReactLoader />
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
