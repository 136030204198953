import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Form, FormGroup, CustomInput } from 'reactstrap';
import PauseUnpauseModal from '../PauseUnpauseModal';
import Modal from '../../../../../components/Modal/DefaultModal';

const FormPauseUnpause = ({
  agent,
  pauses,
  handlePause,
  handleUnpause,
  loading
}) => {
  const { control, handleSubmit, register, watch, setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const isValid = watch('pause_id');
  let namePause = pauses.filter(
    (pause) => parseInt(isValid) == parseInt(pause.id)
  );

  const togglePause = async ({ agentCode, pause_id }) => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 2000);

    if (agent.paused) {
      await handleUnpause({ agentCode });
      setValue('pause_id', '');
    } else {
      await handlePause({ agentCode, pause_id });
    }
  };

  const getButtonLabel = () => {
    return agent.paused ? 'Sair de Pausa' : 'Pausar';
  };
  function modifyStateModal(boll) {
    setIsModalOpen(boll);
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <Form
      className="needs-validation"
      onSubmit={handleSubmit(togglePause)}
      inline
    >
      <FormGroup>
        <Controller
          as={
            <CustomInput
              id={agent.agentCode}
              bsSize="sm"
              type="select"
              disabled={agent.paused}
            >
              <option value="">Selecione uma pausa</option>
              {pauses?.map((pause) => (
                <option key={pause.id} value={pause.id}>
                  {pause.name}
                </option>
              ))}
            </CustomInput>
          }
          control={control}
          name="pause_id"
          defaultValue={agent.selectedPauseId || ''}
        />
      </FormGroup>

      <input
        type="text"
        name="agentCode"
        defaultValue={agent.agentCode}
        ref={register}
        hidden
      />
      <Modal
        show={isModalOpen}
        onModalToggle={(state) => setIsModalOpen(state)}
        showCloseButton={false}
      >
        <PauseUnpauseModal
          dataAgent={agent}
          selectedPauseId={isValid}
          modifyStateModal={modifyStateModal}
          namePause={namePause || ''}
          handlePause={handlePause}
          handleUnpause={handleUnpause}
        />
      </Modal>

      <Button
        name="create"
        title={'Pausar/Despausar'}
        onClick={() => {
          toggleModal();
        }}
        type="button"
        size="sm"
        color="primary"
        disabled={(!agent.paused && !isValid) || loading || isLoading}
      >
        {getButtonLabel()}
      </Button>
    </Form>
  );
};

export default FormPauseUnpause;
