import React from 'react';
import ReactDatetime from 'react-datetime';
import 'moment/locale/pt-br';

import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from 'reactstrap';
import { isSameDay } from 'date-fns';

const DatePickerIsValidCDOD = ({ showIconCalendar = true, ...props }) => {
  const controlChange = (values) => {
    let date = values;
    if (values._d) {
      date = values._d.toLocaleDateString('pt-br');

      return props.valueController(props.fieldName, date);
    }

    if (values) {
      return props.valueController(props.fieldName, date);
    }

    return props.valueController(props.fieldName, '');
  };

  const valid = (current) => {
    const datesDaysOff = props.daysOffObject.daysOff;
    const datesTimeShift = props.daysOffObject.timeShifts;

    let filteredDaysOff = datesDaysOff.filter((day) => {
      return !!!datesTimeShift.find((item) => {
        return item.dateTimeShift === day.date;
      });
    });

    const isValidDate = filteredDaysOff.find((item) => {
      return isSameDay(current._d, new Date(item.date));
    });

    return !!!isValidDate;
  };

  return (
    <>
      <FormGroup style={{ marginBottom: '0px', width: '240px' }}>
        <InputGroup className="input-group-alternative">
          {showIconCalendar && (
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ border: '1px solid #c4c4c4' }}>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
          )}
          <ReactDatetime
            isValidDate={valid}
            closeOnSelect={true}
            closeOnTab={true}
            name={props.fieldName}
            className="reactDate"
            inputProps={{
              style: {
                border: '1px solid #c4c4c4'
              }
            }}
            onChange={controlChange}
            timeFormat={false}
            locale="pt-br"
            value={props.defaultValue}
          />
        </InputGroup>
      </FormGroup>
    </>
  );
};

export default DatePickerIsValidCDOD;
