import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Pagination from 'components/Pagination';
import { toast } from 'react-toastify';
import UserService from '../../../User/service';
import CallbackService from '../../../Callback/service';
import QueueService from '../../../Queue/service';
import ReportService from './service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import HourMaskMMHHSS from '../../../../components/Inputs/HourMaskMMHHSS.js';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ReportAnalythical from './tables/Analytical';
import ReportSynthetic from './tables/Synthetic';
import ReportDetailedSynthetic from './tables/DetailedSynthetic';
import * as fileSaver from 'file-saver';
import { v4 as uuid } from 'uuid';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';

import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';
import { ItemPerPageSelect } from 'components/Inputs/ItemPerPageSelect';

const hourRules = {
  required: 'Hora é obrigatório!',
  pattern: {
    value: /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/,
    message: 'Hora inválida! (00:00:00)'
  }
};

const dateRules = {
  required: 'Data é obrigatório!',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};

const reportOptions = [
  { label: 'Analítico', value: 1 },
  { label: 'Sintético', value: 2 },
  { label: 'Sintético detalhado', value: 3 }
];

const statusOptions = [
  { label: 'Na fila', value: 1 },
  { label: 'Cliente atendeu', value: 2 },
  { label: 'Cliente não atendeu', value: 3 },
  { label: 'Perdido na fila', value: 4 },
  { label: 'Cliente já retornou', value: 5 }
];

const DialerReport = () => {
  const { control, handleSubmit, errors, setValue, watch, getValues } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [users, setUsers] = useState([]);
  const [queues, setQueues] = useState([]);
  const [callbacks, setCallbacks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const reportType = watch('reportType');
  const [ReportTypeAnalytical, setReportTypeAnalytical] = useState([]);
  const [ReportTypeSynthetic, setReportTypeSynthetic] = useState([]);
  const [ReportTypeDetailedSynthetic, setReportTypeDetailedSynthetic] =
    useState([]);
  const [media, setMedia] = useState('');
  const showDisables = watch('disable');
  const [dataToSend, setDataToSend] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [activePage, setActivePage] = useState(1);

  const [quantityInfo, setQuantityInfo] = useState(0);
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );
  const [messagemGeneratePdf, setMessagemGeneratePdf] = useState();
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //messagemGeneratePdfCsv
  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantityInfo,
      limitGenerationPDF
    );

    setMessagemGeneratePdf(message.pdfMessage);
    setMessagemGenerateCsv(message.csvMessage);
  }, [quantityInfo, limitGenerationPDF]);

  useEffect(() => {
    if (showDisables === true) {
      CallbackService.getAllShowDisable(showDisables)
        .then((response) => {
          setCallbacks(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    } else {
      CallbackService.getAll()
        .then((response) => {
          setCallbacks(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [showDisables]);

  useEffect(() => {
    if (showDisables === true) {
      QueueService.getAllShowDisable(showDisables)
        .then((response) => {
          setQueues(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    } else {
      QueueService.getAll()
        .then((response) => {
          setQueues(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [showDisables]);

  useEffect(() => {
    getUser(true)
  }, [usersShowDisable]);
  
  const getUser = (showDisable = false) => {
    if(!showDisable){
      setLoading(true);
    }
    UserService.getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        setUsers(response.data.data);
        if(!showDisable){
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('err: ' + err);
        if(!showDisable){
          setLoading(false);
        }
      });

  }

  const clearData = () => {
    setReportTypeAnalytical([]);
    setReportTypeSynthetic([]);
    setReportTypeDetailedSynthetic([]);
    setDataToSend({});
    setActivePage(1);
    setQuantity(0);
  };

  const toISOFormat = (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');

    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${MM}-${DD}-${YYYY}`;
  };

  const getFormatedType = (value) => {
    switch (value) {
      case 1:
        return 'analytical';
      case 2:
        return 'synthetic';
      case 3:
        return 'detailedsynthetic';
      default:
        break;
    }
  };

  const getDataDTO = (data) => {
    let formatedData;

    formatedData = {
      ...data,
      dateBegin: `${toISOFormat(data.dateBegin)} ${data.timeStart}`,
      dateEnd: `${toISOFormat(data.dateEnd)} ${data.timeEnd}`,
      timeStart: data.timeStart.replace(/:/g, ''),
      timeEnd: data.timeEnd.replace(/:/g, '')
    };

    if (!formatedData.agent) delete formatedData.agent;
    if (!formatedData.callback) delete formatedData.callback;
    if (!formatedData.queue_input) delete formatedData.queue_input;
    if (!formatedData.queue_output) delete formatedData.queue_output;
    if (!formatedData.status) delete formatedData.status;

    setDataToSend(formatedData);
    return formatedData;
  };

  const definedNameReportDownload = (name) => {
    let nameReportDownload;

    switch (name) {
      case 'pdf':
        nameReportDownload = 'Relatório_de_Callback.pdf';
        break;
      case 'csv':
        nameReportDownload = 'Relatório_de_Callback.csv';
        break;
      case 'excel':
        nameReportDownload = 'Relatório_de_Callback.xlsx';
        break;
      default:
        break;
    }

    return nameReportDownload;
  };

  const getReports = (typeReport, dataToSend, page, useLoader = true) => {
    setLoadingReport(useLoader);

    if (media === 'pdf' || media === 'excel' || media === 'csv') {
      ReportService.getDownloads(typeReport, 1, media, dataToSend)
        .then((res) => {
          if (res.data.size === 0)
            toast.info('Nenhum registro foi encontrado!');
          else {
            fileSaver.saveAs(
              new Blob([res.data], { type: res.headers['content-type'] }),
              definedNameReportDownload(media)
            );
          }
          setLoadingReport(false);
        })
        .catch((err) => {
          toast.error(err || `Erro ao gerar documento ${media}`, {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoadingReport(false);
        });
      setMedia('');
    } else {
      ReportService.getAllReportsType(
        typeReport,
        page,
        dataToSend,
        itemsPerPage
      )
        .then((res) => {
          switch (typeReport) {
            case 'analytical':
              if (res.data.data.quantity === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listCallbackDTO.forEach((el, index) => {
                  res.data.data.listCallbackDTO[index].uuid = uuid();
                });
                setReportTypeAnalytical(res.data.data);
                setQuantity(res.data.data.quantity);
                setQuantityInfo(res.data.data.quantity);
              }
              break;
            case 'synthetic':
              if (res.data.data.listCallbackSyntheticDTO.length === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listCallbackSyntheticDTO.forEach((el, index) => {
                  res.data.data.listCallbackSyntheticDTO[index].uuid = uuid();
                });
                setReportTypeSynthetic(res.data.data);
                setQuantity(res.data.data.quantity);
                setQuantityInfo(res.data.data.quantity);
              }
              break;
            case 'detailedsynthetic':
              if (res.data.data.quantity === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listCallbackDetailedSyntheticDTO.forEach(
                  (el, index) => {
                    res.data.data.listCallbackDetailedSyntheticDTO[index].uuid =
                      uuid();
                  }
                );
                setReportTypeDetailedSynthetic(res.data.data);
                setQuantity(res.data.data.quantity);
                setQuantityInfo(res.data.data.quantity);
              }
              break;
            default:
              break;
          }
          setLoadingReport(false);
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoadingReport(false);
        });
    }
  };

  const onSubmit = (data) => {
    clearData();
    try {
      const formatedData = getDataDTO(data);
      const formatedType = getFormatedType(reportType);
      getReports(formatedType, formatedData, 1, true);
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    clearData();
  }, [reportType]);

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const handlePageChange = (page) => {
    setActivePage(page);
    getReports(getFormatedType(reportType), dataToSend, page, false);
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de Callback</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Callback</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={callbacks}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="callback"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="callback"
                            defaultValue=""
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Agente</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={users}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="agent"
                                labelName="name"
                                valueName="agent_code"
                              />
                            )}
                            control={control}
                            name="agent"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Fila de entrada
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={queues}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="queue_input"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="queue_input"
                            defaultValue=""
                          />
                        </Col>

                        <Col md="6">
                          <label className="form-control-label">
                            Fila de saída
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={queues}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="queue_output"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="queue_output"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Data inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateBegin"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateBegin"
                            rules={dateRules}
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateBegin"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Hora inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="timeStart"
                                value={props.value}
                              />
                            )}
                            control={control}
                            name="timeStart"
                            rules={hourRules}
                            defaultValue="00:00:00"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="timeStart"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Data final*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateEnd"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateEnd"
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                            rules={{
                              ...dateRules,
                              validate: (value) => {
                                if (
                                  new Date(toISOFormat(value, true)) <
                                  new Date(
                                    toISOFormat(getValues()['dateBegin'], true)
                                  )
                                ) {
                                  return 'A data final não pode ser anterior à inicial!';
                                } else {
                                  return true;
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Hora final*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="timeEnd"
                                value={props.value}
                              />
                            )}
                            control={control}
                            name="timeEnd"
                            rules={hourRules}
                            defaultValue="23:59:59"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="timeEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Status</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={statusOptions}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="status"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="status"
                            defaultValue=""
                          />
                        </Col>

                        <Col md="6">
                          <label className="form-control-label">
                            Tipo de Relatório
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={reportOptions}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="reportType"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="reportType"
                            defaultValue={1}
                          />
                        </Col>
                      </Row>
                      {quantity > 0 &&(
                      <Row className="mb-3">
                        <Col md="6" className='bottom-2 position-absolute'>
                          <ItemPerPageSelect
                            valueController={setItemsPerPage}
                          />
                        </Col>
                      </Row>)}

                      <Row className="mb-3">
                        <Col className="md-6 mx-4">
                          <div style={{ display: 'flex', gap: '20px' }}>
                            <Controller
                              name="disable"
                              control={control}
                              render={(props) => (
                                <>
                                  <label className="form-control-label">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                    Mostrar desabilitados (Callbacks, Filas)
                                  </label>
                                </>
                              )}
                              defaultValue={false}
                            />
                            <UserFlexSelect
                              usersShowDisable={usersShowDisable}
                              setUsersShowDisable={setUsersShowDisable}
                              name={'Agentes'}
                            />
                          </div>
                        </Col>
                        <Col md="4" style={ImageButtonContainer}>
                          {reportType !== 1 && (
                            <button
                              style={ImageButton}
                              name="pdf"
                              disabled={disabledDownloadButtonCsvAndPdf(
                                'pdf',
                                quantityInfo,
                                limitGenerationPDF
                              )}
                              onClick={() => setMedia('pdf')}
                            >
                              <Tooltip
                                title={
                                  <p style={{ fontSize: '14px' }}>
                                    {messagemGeneratePdf}
                                  </p>
                                }
                                arrow
                              >
                                <img
                                  src={require('../../../../assets/img/pdf.png')}
                                  alt="pdf"
                                  style={ImageSize}
                                />
                              </Tooltip>
                            </button>
                          )}

                          <button
                            style={ImageButton}
                            disabled={disabledDownloadButtonCsvAndPdf(
                              'csv',
                              quantity,
                              limitGenerationPDF
                            )}
                            onClick={() =>
                              reportType === 1
                                ? setMedia('csv')
                                : setMedia('excel')
                            }
                          >
                            <Tooltip
                              title={
                                <p style={{ fontSize: '14px' }}>
                                  {messagemGenerateCsv}
                                </p>
                              }
                              arrow
                            >
                              <img
                                src={require('../../../../assets/img/csv.png')}
                                alt="csv"
                                style={ImageSize}
                              />
                            </Tooltip>
                          </button>
                        </Col>
                      </Row>

                      <Button
                        style={buttonStyle(systemConfiguration.primary_color)}
                        type="submit"
                      >
                        Buscar
                      </Button>
                    </Form>
                  )}
                  {loadingReport ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      {reportType === 1 &&
                        ReportTypeAnalytical.listCallbackDTO && (
                          <ReportAnalythical
                            totalRegister={quantityInfo}
                            dataReport={ReportTypeAnalytical}
                          />
                        )}
                      {reportType === 2 &&
                        ReportTypeSynthetic.listCallbackSyntheticDTO && (
                          <ReportSynthetic
                            totalRegister={quantityInfo}
                            dataReport={ReportTypeSynthetic}
                          />
                        )}
                      {reportType === 3 &&
                        ReportTypeDetailedSynthetic.listCallbackDetailedSyntheticDTO && (
                          <ReportDetailedSynthetic
                            totalRegister={quantityInfo}
                            dataReport={ReportTypeDetailedSynthetic}
                          />
                        )}
                    </>
                  )}
                  {quantity > 0 && (
                    <Pagination
                      itemsCountPerPage={itemsPerPage}
                      activePage={activePage}
                      totalItemsCount={quantity}
                      onChange={handlePageChange.bind(this)}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DialerReport;
