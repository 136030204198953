export const optionsToCreateNewNode = (
  hasNodeChild,
  isNodeDefault,
  isLevelEmpty,
  limitNodesCreation
) => {
  let options = [];
  if (!hasNodeChild && hasNodeChild === undefined) {
    options.push(
      {
        label: 'Adicionar um novo nó ao lado',
        value: 'ADD_NEW_NODE'
      },
      {
        label: 'Excluir nó',
        value: 'DELETE_NODE'
      }
    );
  }

  if (!hasNodeChild && !isNodeDefault) {
    options.push(
      {
        label: 'Adicionar um novo nó ao lado',
        value: 'ADD_NEW_NODE'
      },
      {
        label: 'Excluir nó',
        value: 'DELETE_NODE'
      }
    );
  }
  if (hasNodeChild) {
    options.push(
      {
        label: 'Adicionar um novo nó ao lado',
        value: 'ADD_NEW_NODE'
      }
      // {
      //   label: 'Adicionar um novo nó filho',
      //   value: 'ADD_NEW_NODE_CHILD'
      // }
    );
  }

  if (!hasNodeChild && isNodeDefault) {
    options = [];
    options.push({
      label: 'Adicionar um novo nó ao lado',
      value: 'ADD_NEW_NODE'
    });
  }

  if (hasNodeChild && isLevelEmpty && !isNodeDefault) {
    options = [];
    options.push(
      {
        label: 'Adicionar um novo nó ao lado',
        value: 'ADD_NEW_NODE'
      },
      // {
      //   label: 'Adicionar um novo nó filho',
      //   value: 'ADD_NEW_NODE_CHILD'
      // },
      {
        label: 'Excluir nó',
        value: 'DELETE_NODE'
      }
    );
  }

  if (limitNodesCreation) {
    options = [];
    options.push({
      label: 'Excluir nó',
      value: 'DELETE_NODE'
    });
  }
  return options;
};
