import React from 'react';
import { FormGroup, Label, Input } from 'reactstrap';

export const UserFlexSelect = ({
  usersShowDisable,
  setUsersShowDisable,
  name
}) => {
  return (
    <FormGroup check>
      <Label check>
        <Input
          type="checkbox"
          id="usersShowDisable"
          checked={usersShowDisable}
          onChange={() => setUsersShowDisable(!usersShowDisable)}
        />
        <span
          style={{
            fontSize: '0.875rem'
          }}
        >
          Mostrar desabilitados ({name})
        </span>
      </Label>
    </FormGroup>
  );
};
