import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  ${(props) => props.color && `background-color: ${props.color};`}
  align-items: center;
  justify-content: center;
  border-radius: 0.2rem;

  span {
    font-size: 0.9rem;
    display: block;
    color: white;
    font-weight: bold;
  }
`;
