const INITIAL_STATE = false;

export default function sidebarReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@SIDEBAR_OPEN':
      return action.payload;
    default:
      return state;
  }
}
