import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useMemorizeFilters } from '../../../../../../hooks/useMemorizeInputsFilters';
import { resetToFirstDayOfMonth } from '../../../../shared/helpers/getFirstDayOfTheMonth';
import { resetFields } from '../../../../shared/helpers/resetInputsFIeldsValues';
import { saveAs } from 'file-saver';
import LeadService from '../../services/leadService.js';
import { toast } from 'react-toastify';
import { Button, Row, Col, Form, Input } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import DatePicker from 'components/Inputs/DatePicker';
import { dateRules } from 'views/Protocol/form/assets/functions';
import FlexSelect from 'components/Inputs/FlexSelect';
import {
  ImageButton,
  ImageButtonConteinerProtocol,
  ImageSize
} from '../../../../../../assets/styles/image_style';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import xlsxImage from '../../../../../../assets/img/xlsx.png';
import pdfImage from '../../../../../../assets/img/pdf.png';
import { AiOutlineClear } from 'react-icons/ai';
import { useUsers } from 'views/Report/Protocol/hooks/useUsers';
import { functionsUseFul } from 'views/Protocol/list/assets/functionsUseFul';
import Loader from 'components/Loader';
import FormTooltip from 'components/Inputs/FormTooltip';

const FilterLeadsClosedWonForm = ({
  handleSearch,
  recordTypeId,
  leads,
  setSelectedRecordType,
  recordTypes,
  analyticalData,
  setAnalyticalData,
  quantity,
  setDataToSend
}) => {
  const { control, handleSubmit, getValues, setValue, register, reset } =
    useForm();
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const limitRows = 40000;
  const { memorizeFilters, getMemorizedFilters, clearMemorizedFilters } =
    useMemorizeFilters('CRM - Lead');

  const { userList } = useUsers();
  const { getDateBegin } = functionsUseFul();

  const reportTypeExport = [
    {
      id: '1',
      name: 'Convertidos'
    },
    {
      id: '2',
      name: 'Fechado Ganho'
    },
    {
      id: '3',
      name: 'Fechado Perdido'
    }
  ];

  useEffect(() => {
    if (leads) {
      setAnalyticalData(leads);
    }
  }, [leads, setAnalyticalData]);

  useEffect(() => {
    const filters = getMemorizedFilters();
    if (filters) {
      reset(filters);
    }
  }, [getMemorizedFilters, reset, resetConfig, setValue]);

  const resetConfig = useMemo(
    () => ({
      dateBegin: resetToFirstDayOfMonth,
      dateEnd: () => new Date().toLocaleDateString('pt-br').toString(),
      filterDateBy: () => 'creation',
      stageId: () => '',
      textQuery: () => '',
      responsibleLogin: () => ''
    }),
    []
  );

  const saveFiltersWithRecordType = (filters) => {
    const filtersWithRecordType = {
      ...filters,
      recordTypeId: recordTypeId
    };
    memorizeFilters(filtersWithRecordType);
  };

  const onSubmit = async (data) => {
    saveFiltersWithRecordType(data);
    const formattedData = { ...data };
    formattedData.conversionReport = true;
    setDataToSend({ ...formattedData });
    await handleSearch({ ...formattedData });
  };

  const toISOFormat = useCallback((dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${DD}/${MM}/${YYYY}`;
  }, []);

  const handleReportExport = (typeExport, e) => {
    e.preventDefault();

    const params = getValues([
      'textQuery',
      'typeExportReport',
      'dateBegin',
      'dateEnd',
      'responsibleLogin',
      'filterDateBy'
    ]);

    const dto = {
      ...params,
      dateBegin: toISOFormat(params.dateBegin),
      dateEnd: toISOFormat(params.dateEnd),
      recordTypeId: recordTypeId
    };

    if (analyticalData.length < limitRows && typeExport == 'XLSX') {
      setLoadingCSV(true);

      if (Number(params.typeExportReport) === 1) {
        LeadService.getReportType3Xlsx(dto)
          .then((res) => {
            if (res.data.size === 0) {
              setLoadingCSV(false);
              toast.info('Nenhum registro foi encontrado!');
            } else {
              saveAs(
                new Blob([res.data], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
                }),
                `realatorio-leads.xlsx`
              );

              setLoadingCSV(false);
            }
          })
          .catch(() => {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          });
      } else if (Number(params.typeExportReport) === 2) {
        LeadService.getReportType1Xlsx(dto)
          .then((res) => {
            if (res.data.size === 0) {
              setLoadingCSV(false);
              toast.info('Nenhum registro foi encontrado!');
            } else {
              saveAs(
                new Blob([res.data], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
                }),
                `realatorio-leads.xlsx`
              );

              setLoadingCSV(false);
            }
          })
          .catch(() => {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          });
      } else if (Number(params.typeExportReport) === 2) {
        LeadService.getReportType2Xlsx(dto)
          .then((res) => {
            if (res.data.size === 0) {
              setLoadingCSV(false);
              toast.info('Nenhum registro foi encontrado!');
            } else {
              saveAs(
                new Blob([res.data], {
                  type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
                }),
                `realatorio-leads.xlsx`
              );

              setLoadingCSV(false);
            }
          })
          .catch(() => {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          });
      }
    } else if (typeExport == 'XLSX' && analyticalData.length > limitRows) {
      setLoadingCSV(true);

      if (Number(params.typeExportReport) === 1) {
        LeadService.getReportType3Csv(dto)
          .then((res) => {
            if (res.data.size === 0) {
              setLoadingCSV(false);
              toast.info('Nenhum registro foi encontrado!');
            } else {
              saveAs(
                new Blob([res.data], { type: res.headers['content-type'] }),
                `realatorio-leads-${new Date()}.csv`
              );

              setLoadingCSV(false);
            }
          })
          .catch(() => {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          });
      } else if (Number(params.typeExportReport) === 2) {
        LeadService.getReportType1Csv(dto)
          .then((res) => {
            if (res.data.size === 0) {
              setLoadingCSV(false);
              toast.info('Nenhum registro foi encontrado!');
            } else {
              saveAs(
                new Blob([res.data], { type: res.headers['content-type'] }),
                `realatorio-leads-${new Date()}.csv`
              );

              setLoadingCSV(false);
            }
          })
          .catch(() => {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          });
      } else if (Number(params.typeExportReport) === 2) {
        LeadService.getReportType2Csv(dto)
          .then((res) => {
            if (res.data.size === 0) {
              setLoadingCSV(false);
              toast.info('Nenhum registro foi encontrado!');
            } else {
              saveAs(
                new Blob([res.data], { type: res.headers['content-type'] }),
                `realatorio-leads-${new Date()}.csv`
              );

              setLoadingCSV(false);
            }
          })
          .catch(() => {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          });
      }
    } else if (typeExport == 'PDF') {
      setLoadingPdf(true);
      if (Number(params.typeExportReport) === 1) {
        LeadService.getReportType3Pdf(dto)
          .then((res) => {
            if (res.data.quantity === 0) {
              toast.info('Não há Leads convertidos neste mês!');
              setLoadingPdf(false);
            } else {
              saveAs(
                new Blob([res.data], { type: 'application/pdf' }),
                `realatorio-leads-${new Date()}.pdf`
              );
            }
            setLoadingPdf(false);
          })
          .catch(() => {
            setLoadingPdf(false);
            toast.info('Não há Leads convertidos neste mês!');
          });
      } else if (Number(params.typeExportReport) === 2) {
        LeadService.getReportType1Pdf(dto)
          .then((res) => {
            if (res.data.quantity === 0) {
              toast.info('Não há Leads convertidos neste mês!');
              setLoadingPdf(false);
            } else {
              saveAs(
                new Blob([res.data], { type: 'application/pdf' }),
                `realatorio-leads-${new Date()}.pdf`
              );
            }
            setLoadingPdf(false);
          })
          .catch(() => {
            setLoadingPdf(false);
            toast.info('Não há Leads convertidos neste mês!');
          });
      } else if (Number(params.typeExportReport) === 3) {
        LeadService.getReportType2Pdf(dto)
          .then((res) => {
            if (res.data.quantity === 0) {
              toast.info('Não há Leads convertidos neste mês!');
              setLoadingPdf(false);
            } else {
              saveAs(
                new Blob([res.data], { type: 'application/pdf' }),
                `realatorio-leads-${new Date()}.pdf`
              );
            }
            setLoadingPdf(false);
          })
          .catch(() => {
            setLoadingPdf(false);
            toast.info('Não há Leads convertidos neste mês!');
          });
      }
    }
  };

  const handleReset = () => {
    if (recordTypeId && recordTypes && recordTypes.length) {
      setSelectedRecordType(recordTypes[0]);
    }
    resetFields(resetConfig, [], control);
    clearMemorizedFilters();
  };

  const showAllLeads = useMemo(() => {
    const flag = localStorage.getItem('show_all_leads');
    return flag === 'true';
  }, []);

  return (
    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          position: 'absolute',
          right: '1rem',
          top: '2rem'
        }}
      >
        <span
          onClick={() => {
            handleReset();
          }}
          className="small"
          style={{ cursor: 'pointer' }}
        >
          <AiOutlineClear /> Limpar filtros
        </span>
      </div>
      <Row className="mb-3">
        <Col sm="8">
          <label className="form-control-label">Buscar</label>
          <Controller
            as={
              <Input
                type="text"
                name="textQuery"
                placeholder="Buscar por nome, telefone ou email"
              />
            }
            control={control}
            name="textQuery"
            defaultValue=""
          />
        </Col>
        <Col sm="4">
          <label className="form-control-label">
            Fase da oportunidade
            <FormTooltip text="As buscas 'Fechado Ganho' e 'Fechado Perdido' só servirão para leads que foram convertidos em oportunidade. Casos de oportunidades criadas sem serem convertidas não aparecerão no relatório." />
          </label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={reportTypeExport || []}
                isMulti={false}
                value={props.value}
                valueController={setValue}
                fieldName="typeExportReport"
                labelName="name"
                valueName="id"
                isClearable={false}
                closeMenuOnSelect={true}
              />
            )}
            control={control}
            defaultValue="1"
            name="typeExportReport"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={showAllLeads ? '4' : '6'}>
          <label className="form-control-label">Data inicial</label>
          <Controller
            render={(props) => (
              <DatePicker
                fieldName="dateBegin"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateBegin"
            rules={dateRules}
            defaultValue={getDateBegin()}
          />
        </Col>
        <Col sm={showAllLeads ? '4' : '6'}>
          <label className="form-control-label">Data final</label>
          <Controller
            render={(props) => (
              <DatePicker
                fieldName="dateEnd"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateEnd"
            defaultValue={new Date().toLocaleDateString('pt-br')}
            rules={{
              ...dateRules,
              validate: async (value) => {
                if (
                  new Date(toISOFormat(value)) <
                  new Date(toISOFormat(getValues()['dateBegin']))
                ) {
                  return 'A data final não pode ser anterior à inicial!';
                } else {
                  return true;
                }
              }
            }}
          />
        </Col>
        {showAllLeads && (
          <Col sm="4">
            <label className="form-control-label">Responsável</label>
            <Controller
              render={(props) => (
                <FlexSelect
                  dataOptions={userList || []}
                  isMulti={false}
                  value={props.value}
                  valueController={setValue}
                  fieldName="responsibleLogin"
                  labelName="name"
                  valueName="login"
                  isClearable={true}
                  closeMenuOnSelect={true}
                />
              )}
              control={control}
              name="responsibleLogin"
              defaultValue=""
            />
          </Col>
        )}
      </Row>
      <Row className="mb-3">
        <Col sm="6">
          <label
            className="form-control-label"
            style={{ marginRight: '20px', fontWeight: '400' }}
          >
            <input
              type="radio"
              name="filterDateBy"
              ref={register}
              value="creation"
              defaultChecked={true}
            />{' '}
            Data de criação
          </label>
          <label className="form-control-label" style={{ fontWeight: '400' }}>
            <input
              type="radio"
              name="filterDateBy"
              ref={register}
              value="last_update"
              defaultChecked={false}
            />{' '}
            Data de última alteração
          </label>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="mt-3" style={ImageButtonConteinerProtocol}>
            <Button
              style={buttonStyle(systemConfiguration.primary_color)}
              type="submit"
              size="sm"
            >
              Buscar
            </Button>
          </div>
        </Col>
        <Col
          md="6"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end'
          }}
        >
          <button
            id="button_xlsx"
            style={ImageButton}
            onClick={(e) => {
              handleReportExport('XLSX', e);
            }}
            disabled={analyticalData.length && !loadingCSV ? false : true}
          >
            <Tooltip
              title={
                <p style={{ fontSize: '14px' }}>
                  {analyticalData.length
                    ? `Fazer download do relatório de Leads. Arquivos menores que ${limitRows} registros serão baixados em XLSX, caso contrário CSV`
                    : 'Nenhum registro para realizar download'}
                </p>
              }
              arrow
            >
              {loadingCSV ? (
                <Loader
                  type="ThreeDots"
                  visible={true}
                  height="40"
                  width="40"
                />
              ) : (
                <img src={xlsxImage} alt="xlsx" style={ImageSize} />
              )}
            </Tooltip>
          </button>
          <button
            style={ImageButton}
            name="pdf"
            onClick={(e) => {
              handleReportExport('PDF', e);
            }}
            disabled={analyticalData.length && !loadingPdf ? false : true}
          >
            <Tooltip
              title={
                <p style={{ fontSize: '14px' }}>
                  {analyticalData.length
                    ? 'Fazer download do relatório de Leads.'
                    : 'Nenhum registro para realizar download, faça um filtra para realizar está ação'}
                </p>
              }
              arrow
            >
              {loadingPdf ? (
                <Loader
                  type="ThreeDots"
                  visible={true}
                  height="40"
                  width="40"
                />
              ) : (
                <img src={pdfImage} alt="pdf" style={ImageSize} />
              )}
            </Tooltip>
          </button>
        </Col>
      </Row>
      <div>
        <div style={{ fontSize: 'small', float: 'right' }}>
          {quantity} resultado(s) encontrado(s)
        </div>
      </div>
    </Form>
  );
};

export default FilterLeadsClosedWonForm;
