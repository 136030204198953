import DefaultModal from '../../../../components/Modal/DefaultModal';
import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ProtocolService from '../../service';
import { toast } from 'react-toastify';
import { Row, Form, Col, Button, Input } from 'reactstrap';
import ReasonService from '../../../reason/service';
import SubReasonService from '../../../subReason/service';
import FlexSelect from 'components/Inputs/FlexSelect';
import { validateFields } from '../../form/assets/functions';

const ModalReason = ({
  isModalOpen,
  onModalToggle,
  idProtocol,
  isRequired,
  reasonIsRequiredSubReason,
  showSubReason,
  recordTypeId,
  render,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  const [reasons, setReasons] = useState([]);
  const { control, handleSubmit, setValue, errors } = useForm();
  const [loadingSubreasons, setLoadingSubreasons] = useState(false);
  const [subreasons, setSubreasons] = useState([]);

  useEffect(() => {
    ReasonService.getByRecordType(recordTypeId)
      .then((response) => {
        setReasons(response.data.content);
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data) || 'Erro ao carregar motivos';
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
        closeModal();
      });
  }, [closeModal]);

  const onSubmit = async (data) => {
    try {
      let message;
      await ProtocolService.protocolUpdateData(
        idProtocol,
        data,
        'change_reason_subreason'
      );

      message = 'Motivo do protocolo atualizado com sucesso';
      toast.success(message, {
        autoClose: 3000,
        closeOnClick: true
      });
      render({
        reason: reasons.find((item) => item.id === data.reason_id),
        subreason: subreasons.find((item) => item.id === data.subreason_id)
      });
      closeModal();
    } catch (err) {
      console.log(err);
      toast.error(' Erro ao atualizar o protocolo', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );
  const handleGetSubReasons = (event) => {
    const value = event;
    if (value) {
      setLoadingSubreasons(true);
      SubReasonService.getByReasonId(value)
        .then((response) => {
          setSubreasons(response.data.content);
        })
        .catch((err) => {
          const { data } = err.response;
          toast.error(data.message, {
            autoClose: 3000,
            closeOnClick: true
          });
          closeModal();
        })
        .finally(() => {
          setLoadingSubreasons(false);
        });
    } else {
      setSubreasons([]);
    }
  };
  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Alterar motivo do Protocolo"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col className="px-0">
              <Col sm="12" className="mb-3">
                <label className="form-control-label">Motivo*</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={
                        reasons
                          ? reasons.map(({ id, name }) => ({
                              label: name,
                              value: id
                            }))
                          : []
                      }
                      value={props.value}
                      valueController={setValue}
                      fieldName="reason_id"
                      labelName="label"
                      valueName="value"
                      isClearable={true}
                      closeMenuOnSelect={true}
                      portalTarget={true}
                      onChange={handleGetSubReasons}
                    />
                  )}
                  control={control}
                  name="reason_id"
                  rules={validateFields(isRequired)}
                  defaultValue=""
                />

                <ErrorMessage
                  errors={errors}
                  name="reason_id"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
              {showSubReason && (
                <Col md={{ size: 12 }} className="mb-3">
                  <label className="form-control-label">Submotivo*</label>
                  <Controller
                    render={(props) => (
                      <FlexSelect
                        dataOptions={
                          subreasons
                            ? subreasons.map(({ id, name }) => ({
                                label: name,
                                value: id
                              }))
                            : []
                        }
                        value={props.value}
                        valueController={setValue}
                        fieldName="subreason_id"
                        labelName="label"
                        valueName="value"
                        isClearable={true}
                        closeMenuOnSelect={true}
                        portalTarget={true}
                      />
                    )}
                    control={control}
                    name="subreason_id"
                    rules={{ required: 'Campo obrigatório' }}
                    defaultValue=""
                  />
                  <ErrorMessage
                    errors={errors}
                    name="subreason_id"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
              )}
            </Col>
          </Row>
          <hr />
          <Button color="primary" type="submit">
            Salvar
          </Button>
          <Button color="danger" onClick={closeModal}>
            Voltar
          </Button>
        </Form>
      </>
    </DefaultModal>
  );
};

export default ModalReason;
