import React, { useState } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';
import { MdOutlineContactPhone } from 'react-icons/md';
import SendMessageContactsModal from './components/SendMessageContactsModal';
import CallContactsModal from './components/CallContactsModal';

const OpportunityActionsModal = ({ opportunityData }) => {
  const [dropdown, setDropdown] = useState(false);

  const [isSendMessageModalContactsOpen, setIsSendMessageModalContactsOpen] =
    useState(false);
  const [isCallModalContactsOpen, setIsCallModalContactsOpen] = useState(false);

  const [isToGetContacts, setIsToGetContacts] = useState(false);

  const toggle = () => setDropdown(!dropdown);

  const openSendMessageModal = () => {
    setIsSendMessageModalContactsOpen(!isSendMessageModalContactsOpen);
    setIsToGetContacts(!isToGetContacts);
  };

  const openCallContactModal = () => {
    setIsCallModalContactsOpen(!isCallModalContactsOpen);
    setIsToGetContacts(!isToGetContacts);
  };

  return (
    <>
      <Button color="success" onClick={toggle} size="sm">
        <MdOutlineContactPhone size={17} />
      </Button>
      <Dropdown
        isOpen={dropdown}
        toggle={toggle}
        style={{ top: '-100px', left: '0' }}
      >
        <DropdownToggle
          color="link"
          style={{
            margin: '0',
            padding: '0'
          }}
        ></DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            value="Enviar mensagem whatsapp"
            onClick={() => openSendMessageModal()}
            disabled={!opportunityData.accountId}
          >
            <i className="fas fa-comment" />
            Enviar mensagem whatsapp
          </DropdownItem>
          <DropdownItem
            value="Realizar chamada"
            onClick={() => openCallContactModal()}
            disabled={!opportunityData.accountId}
          >
            <i className="fas fa-phone" />
            Realizar chamada
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
      <SendMessageContactsModal
        accountId={opportunityData.accountId}
        opportunityData={opportunityData}
        isToGetContacts={isToGetContacts}
        recordTypeId={opportunityData.accountRecordTypeId}
        isModalOpen={isSendMessageModalContactsOpen}
        onModalToggle={setIsSendMessageModalContactsOpen}
      />
      <CallContactsModal
        accountId={opportunityData.accountId}
        opportunityData={opportunityData}
        isToGetContacts={isToGetContacts}
        recordTypeId={opportunityData.accountRecordTypeId}
        isModalOpen={isCallModalContactsOpen}
        onModalToggle={setIsCallModalContactsOpen}
      />
    </>
  );
};

export default OpportunityActionsModal;
