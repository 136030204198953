import React, { Children } from 'react';
import { FaInfo } from 'react-icons/fa';
import { useSelector } from 'react-redux';

import {
  AgentIndicatorCardContainer,
  AgentIndicatorCardIconWrapper,
  AgentIndicatorCardDetailBtn
} from './styled';

const Header = ({ children }) => {
  return (
    <div className="d-flex">
      {Children.map(children, (child) => {
        return child;
      })}
    </div>
  );
};

const Title = ({ children }) => (
  <h2 className="text-sm font-weight-400">{children}</h2>
);

const DetailBtn = ({ onClick }) => (
  <AgentIndicatorCardDetailBtn onClick={onClick}>
    <FaInfo size={10} color="#66677f" />
  </AgentIndicatorCardDetailBtn>
);

const Body = ({ children, value }) => (
  <div className="d-flex align-items-center">
    <div className="d-flex align-items-end">
      <span className="text-lg font-weight-600">{value}</span>
    </div>
    {children ? children : null}
  </div>
);

const AgentIndicatorCard = ({ children, icon }) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const primary_color = systemConfiguration.primary_color;

  return (
    <AgentIndicatorCardContainer>
      <AgentIndicatorCardIconWrapper bgColor={primary_color}>
        {React.createElement(icon, {
          size: '20',
          color: '#fff'
        })}
      </AgentIndicatorCardIconWrapper>
      <div className="d-flex flex-column ml-3">
        {Children.map(children, (child) => {
          return child;
        })}
      </div>
    </AgentIndicatorCardContainer>
  );
};

AgentIndicatorCard.Header = Header;
AgentIndicatorCard.Title = Title;
AgentIndicatorCard.DetailBtn = DetailBtn;
AgentIndicatorCard.Body = Body;

export default AgentIndicatorCard;
