import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import { Redirect, useHistory } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { useSelector } from 'react-redux';

const TimelineFiltroHeaderSms = (props) => {
  const history = useHistory();

  const [state, setState] = useState({
    path: '',
    return: props.returnPath || '/admin/configuration',
    controlReturn: false,
    navigatorState: props.navigatorState || null
  });
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  function handleReturn() {
    history.push(state.return, state.navigatorState);
  }

  const buttonCreate = props.setCreate ? (
    <>
      <Button onClick={() => setState({ ...state, path: props.setCreate })}>
        Criar
      </Button>
      
    </>
  ) : null;



  if (state.path && props.entityName) {
    return (
      <Redirect
        to={{
          pathname: state.path,
          entityName: { entityName: props.entityName },
          state: state.navigatorState
        }}
      />
    );
  } else if (state.path) {
    return (
      <Redirect
        to={{
          pathname: state.path,
          state: state.navigatorState
        }}
      />
    );
  }

  return (
    <>
      <div
        className="header header-dark pb-6 content__title content__title--calendar"
        style={{ background: systemConfiguration.primary_color }}
      >
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {props.showBackArrow !== 'not' && (
                  <Button
                    size="sm"
                    color={systemConfiguration.primary_color}
                    title={
                      props.buttonTitle ||
                      'Volta para a pagina de configurações'
                    }
                    onClick={handleReturn}
                  >
                    <BiArrowBack size="35px" color="white" />
                  </Button>
                )}
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
              <Button
                    type="submit"
                    onClick={() => {
                        props.setAllIsContactModalOpen(true);
                        }}
                        >
                          Filtro
                        </Button>
                {props.children}
                {buttonCreate}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default TimelineFiltroHeaderSms;
