import React, { useEffect, useRef, useState } from 'react';
import { Container, CardContainer, CardHeader } from './styles';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { toast } from 'react-toastify';
import { FaArrowUp } from 'react-icons/fa';
import { FaArrowDown } from 'react-icons/fa';
export const Kanban = ({
  data,
  onTableChange,
  opportunityStages,
  InfoCard,
  setControlFunction = () => {},
  isProtocol = false,
  handleCall = () => {},
  disableClickToCall = true
}) => {
  const dragItem = useRef();
  const dragOverItem = useRef();

  const [currentList, setCurrentList] = useState('');

  const [oportunidadesList, setOportunidadesList] = useState({});
  const [valuesList, setValuesList] = useState({});
  const [isCrescente, setIsCrescente] = useState(false);
  const noStatusProtocol = {
    color: '#A9A9A9',
    name: 'Sem Status',
    description: 'Sem Status',
    id: 'j7h878c1-ffd4-44a9-a5e0-3389f83afjo25'
  };
  const hasSemStatus = opportunityStages.some(
    (status) => status.name === 'Sem Status'
  );
  if (!hasSemStatus) {
    opportunityStages.push(noStatusProtocol);
  }

  useEffect(() => {
    if (!isProtocol) return;
    const novoObjeto = {};

    for (const key in valuesList) {
      if (valuesList.hasOwnProperty(key)) {
        const listaAtual = valuesList[key];

        if (isCrescente) {
          const listaCrescente = listaAtual.slice().sort((a, b) => {
            return new Date(a.creation_date) - new Date(b.creation_date);
          });
          novoObjeto[key] = listaCrescente;
        } else {
          const listaDecrescente = listaAtual.slice().sort((a, b) => {
            return new Date(b.creation_date) - new Date(a.creation_date);
          });

          novoObjeto[key] = listaDecrescente;
        }
      }
    }

    setValuesList(novoObjeto);
  }, [isCrescente]);

  useEffect(() => {
    let item = {};
    if (opportunityStages?.length > 0) {
      opportunityStages.forEach((oportunidade) => {
        item[oportunidade.name] = { ...oportunidade };
      });
    }

    setOportunidadesList(item);
  }, [opportunityStages]);

  useEffect(() => {
    if (data?.length > 0) {
      if (isProtocol) {
        const copyValueList = { ...valuesList };
        const names = Object.keys(oportunidadesList);

        names.forEach((oportunidade) => {
          copyValueList[oportunidade] =
            data.filter((item) => {
              return (
                item?.current_status === oportunidadesList[oportunidade]?.id
              );
            }) ?? [];
        });

        setValuesList({ ...copyValueList });
        return;
      }
      const copyValueList = { ...valuesList };
      const names = Object.keys(oportunidadesList);

      names.forEach((oportunidade) => {
        copyValueList[oportunidade] =
          data.filter((item) => {
            return item.stageName === oportunidade;
          }) ?? [];
      });
      setValuesList({ ...copyValueList });
      return;
    }
    setValuesList([]);
  }, [data, oportunidadesList]);

  const dragStart = (e, position, startingList) => {
    e.dataTransfer.setData(
      'text/html',
      JSON.stringify({
        id: e.target.id,
        parent: startingList
      })
    );
    dragItem.current = position;
    // setStartList(startingList);
  };

  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };

  const getCollor = (opportunity, opportunityStages) => {
    if (isProtocol)
      return {
        borderTop: '3px solid',
        borderRadius: '10px',
        borderColor: opportunityStages
          .filter((filter) => filter.name === opportunity)
          .map((color) => color.color)
      };
  };
  const contStatus = {};
  if (isProtocol) {
    const noStatus = [
      {
        status: {
          color: '#FAEBD7',
          name: 'Sem Status',
          description: 'Sem Status'
        },
        status_id: 'j7h878c1-ffd4-44a9-a5e0-3389f83afjo25'
      }
    ];
    const stages = opportunityStages.map((stages) => stages.name);

    const noStatusArray = data.map((status) => {
      if (
        status.protocol_statuses.length === 0 ||
        !stages.includes(status.protocol_statuses[0]?.status?.name)
      ) {
        return {
          ...status,
          protocol_statuses: noStatus,
          current_status: 'j7h878c1-ffd4-44a9-a5e0-3389f83afjo25'
        };
      } else {
        return status;
      }
    });
    data = [...noStatusArray];
    const status = noStatusArray
      .map((status) => status.protocol_statuses)
      .map((status) => status[0]?.status?.name);

    status.forEach((elemento) => {
      if (contStatus[elemento] !== undefined) {
        contStatus[elemento] += 1;
      } else {
        contStatus[elemento] = 1;
      }
    });
  }

  if (!isProtocol) {
    const status = data.map((status) => status.stageName);

    status.forEach((elemento) => {
      if (contStatus[elemento] !== undefined) {
        contStatus[elemento] += 1;
      } else {
        contStatus[elemento] = 1;
      }
    });
  }

  const getCountedStatus = (oportunidade) => {
    if (!contStatus[oportunidade]) {
      return 0;
    }

    return contStatus[oportunidade];
  };
  const drop = async (e) => {
    if (!e.dataTransfer.getData('text/html').includes('draggable')) {
      dragItem.current = null;
      dragOverItem.current = null;
      setCurrentList('');
      return;
    }
    const startList = JSON.parse(e.dataTransfer.getData('text/html')).parent;
    if (startList === currentList) {
      const copyListItems = [...valuesList[currentList]];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      const copyValueList = { ...valuesList };
      copyValueList[currentList] = [...copyListItems];
      setValuesList(copyValueList);
      setCurrentList('');
      return;
    }
    const copyOriginal = [...valuesList[startList]];
    const copyCurrent = [...valuesList[currentList]];
    const dragItemContent = copyOriginal[dragItem.current];
    copyOriginal.splice(dragItem.current, 1);
    copyCurrent.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;

    if (dragItemContent?.isConverted) {
      toast.error('Não é possivel alterar a fase de um lead convertido', {
        autoClose: 3000,
        draggable: false
      });
      return;
    }

    onTableChange(
      dragItemContent.id,
      oportunidadesList[currentList].id,
      oportunidadesList[currentList]?.isConversion ?? false
    ).then((res) => {
      if (!res) return;
      const changeRow = () => {
        const copyValueList = { ...valuesList };
        copyValueList[startList] = [...copyOriginal];
        copyValueList[currentList] = [...copyCurrent];
        setValuesList(copyValueList);
        setCurrentList('');
      };

      if (oportunidadesList[currentList]?.isConversion) {
        setControlFunction(() => changeRow);
        return;
      }
      changeRow();
    });
    setCurrentList('');
  };

  const card = (props) => {
    if (props.data.length === 0) {
      return <div></div>;
    }

    return (
      <InfoCard
        dragStart={dragStart}
        dragEnter={dragEnter}
        data={props}
        handleCall={handleCall}
        disableClickToCall={disableClickToCall}
        isProtocol={isProtocol}
      ></InfoCard>
    );
  };

  const returnList = (arr, name) => {
    if (!arr) {
      return [];
    }

    return (
      <AutoSizer>
        {({ height, width }) => {
          return (
            <List
              className="List ListTable"
              height={height}
              itemCount={arr.length}
              itemSize={isProtocol ? 130 : 120}
              width={width}
              itemData={{
                array: arr,
                list: name,
                setValuesList: setValuesList
              }}
              // style={{ overflow: 'hidden' }}
            >
              {card}
            </List>
          );
        }}
      </AutoSizer>
    );
  };

  return (
    <>
      <Container isProtocol={isProtocol} draggable={false}>
        {Object.keys(oportunidadesList).map((oportunidade, index) => {
          return (
            <div
              key={oportunidade}
              className="cardCollumnWrapper"
              style={{ ...getCollor(oportunidade, opportunityStages) }}
            >
              {index === 0 && isProtocol && (
                <button
                  style={{
                    color: 'inherit',
                    border: 'none',
                    padding: 0,
                    font: 'inherit',
                    cursor: 'pointer',
                    outline: 'inherit',
                    position: 'absolute',
                    top: '8px',
                    left: '10px'
                  }}
                  onClick={() => setIsCrescente((prev) => !prev)}
                >
                  {isCrescente ? (
                    <>
                      <FaArrowUp style={{ height: '15px', width: '15px' }} />
                      <FaArrowDown
                        style={{
                          color: '#c9cdd1',
                          height: '15px',
                          width: '15px'
                        }}
                      />
                    </>
                  ) : (
                    <div>
                      <>
                        <FaArrowUp
                          style={{
                            color: '#c9cdd1',
                            height: '15px',
                            width: '15px'
                          }}
                        />
                        <FaArrowDown
                          style={{ height: '15px', width: '15px' }}
                        />
                      </>
                    </div>
                  )}
                </button>
              )}
              <CardHeader draggable={false}>
                <h3
                  style={{
                    color: '#424269',
                    marginTop: '3px',
                    fontSize: '0.875rem',
                    fontWeight: '600'
                  }}
                >
                  {oportunidade}
                </h3>
                <div
                  style={{
                    minWidth: '20px',
                    maxWidth: '100%',
                    border: '1px solid lightgray',
                    borderRadius: '40%',
                    padding: '2px',
                    marginLeft: '5px'
                  }}
                >
                  {getCountedStatus(oportunidade)}
                </div>
              </CardHeader>
              <CardContainer
                isProtocol={isProtocol}
                draggable={false}
                key={oportunidade}
                onDragOver={(event) => {
                  event.preventDefault();
                  setCurrentList(oportunidade);
                }}
                onDrop={(event) => {
                  drop(event);
                }}
                id={oportunidade}
              >
                {returnList(valuesList[oportunidade], oportunidade)}
              </CardContainer>
            </div>
          );
        })}
      </Container>
    </>
  );
};
