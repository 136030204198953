import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Row, Col } from 'reactstrap';

import { ColorArea } from './assets/styleds';
import { chartExample6 } from 'variables/charts.js';
import CountUp from 'react-countup';

const GraphicLeadsByStage = ({
  filteredLabels,
  filteredData,
  filteredColors,
  count
}) => {
  return (
    <Row>
      <Col md="4" style={{ alignSelf: 'center' }}>
        <div className="chart">
          <Pie
            data={{
              labels: [...filteredLabels],
              datasets: [
                {
                  data: [...filteredData],
                  backgroundColor: [...filteredColors]
                }
              ]
            }}
            options={chartExample6.options}
            className="chart-canvas"
            id="chart-doughnut"
          />
        </div>
      </Col>

      <Col md="8">
        <Row>
          <Col md="12">
            <h1 className="display-1">
              <CountUp end={count} />
            </h1>
            <h2 className="text-muted font-weight-light mt--3">
              Total de Leads
            </h2>
          </Col>
        </Row>

        <hr />

        <Row className="mr-0">
          {filteredData.map((data, index) => {
            return (
              <Col
                md="2"
                key={index}
                className="mt--3 mb--3"
                style={{
                  minWidth: '177px',
                  minHeight: '130px'
                }}
              >
                <Row
                  className="row flex-nowrap"
                  style={{
                    minHeight: '100px'
                  }}
                >
                  <Col md="1">
                    <ColorArea color={filteredColors[index]} />
                  </Col>
                  <Col md="11">
                    <h2>{filteredData[index]}</h2>
                    <h3 className="text-muted font-weight-light mt--3">
                      {filteredLabels[index] === ''
                        ? '-'
                        : filteredLabels[index]}
                    </h3>
                    <h2
                      className="mt--1 font-weight-light"
                      style={{
                        paddingBottom: '1.5625rem'
                      }}
                    >
                      {count
                        ? `${parseFloat(
                            (100 * filteredData[index]) / count
                          ).toFixed(2)}%`
                        : '0%'}
                    </h2>
                  </Col>
                </Row>
              </Col>
            );
          })}
        </Row>
      </Col>
    </Row>
  );
};

export default GraphicLeadsByStage;
