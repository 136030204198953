/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { FaPaperclip } from 'react-icons/fa';
import { IoMdCloseCircle, IoIosOptions } from 'react-icons/io';
import ReactQuill from 'react-quill';

import {
  Button,
  DropdownItem,
  DropdownMenu,
  ButtonGroup,
  DropdownToggle,
  Form,
  Input,
  Label,
  UncontrolledDropdown,
  InputGroup,
  InputGroupText
} from 'reactstrap';
import EmailSignatureService from '../../../../service/emailService';
import { useProtocolAttachments } from '../../Attachments/useProtocolAttachments';

import { CreatableFlexSelect } from 'components/Inputs/CreatableSelect';
import { PredefinedMessageModal } from '../../modal/PredefinedMessageModal';
import PredefinedMessageService from '../../../../../PredefinedMessage/service/index';
import ProtocolConfigurationsService from '../../../../../ProtocolConfiguration/service/index';
import {
  ButtonCCWrapper,
  CheckboxSignatureWrapper,
  CommentsFormWrapper,
  OptionSendSignatureDescription,
  OptionSendSignatureWrapper,
  SpanSignature
} from './styled';

var modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    [{ font: [] }],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' }
    ],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    ['link', 'image', 'code-block'],
    ['clean']
  ]
};

const formatSubjectOfTheProtocolEmail = (
  title,
  protocolNumber,
  protocolConfig
) => {
  let { subjectOfTheProtocolEmail } = protocolConfig;
  subjectOfTheProtocolEmail = subjectOfTheProtocolEmail.replace(
    '#TITULO_PROTOCOLO#',
    title
  );
  subjectOfTheProtocolEmail = subjectOfTheProtocolEmail.replace(
    '#NUMERO_PROTOCOLO#',
    protocolNumber
  );
  return subjectOfTheProtocolEmail;
};

const CommentsForm = ({ comment, onSubmit, onCancel, protocol = {} }) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  const [description, setDescription] = useState('');
  const [openPredefinedMessages, setOpenPredefinedMessages] = useState(false);
  const [predefinedMessages, setPredefinedMessages] = useState([]);
  const [predefinedMessageSelected, setPredefinedMessageSelected] = useState(1);
  const [subject, setSubject] = useState('');
  const [isPrivate, setIsPrivate] = useState(true);
  const [emailsInCopy, setEmailsInCopy] = useState([]);
  const [files, setFiles] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [isCC, setIsCC] = useState(false);
  const [attachmentsToRemove, setAttachmentsToRemove] = useState([]);
  const [sendSignature, setSendSignature] = useState(false);
  const [hasSignature, setHasSignature] = useState(false);
  const {
    getFileURL,
    getFileExtensionFromName,
    getIconFromFileExtension,
    getFileNameFormatted
  } = useProtocolAttachments();

  useEffect(() => {
    PredefinedMessageService.getByChannel('email').then((res) => {
      setPredefinedMessages(res?.data);
    });
  }, []);

  useEffect(() => {
    if (protocol?.record_type_id) {
      ProtocolConfigurationsService.getConfigs(protocol.record_type_id).then(
        (res) => {
          setSubject(
            formatSubjectOfTheProtocolEmail(
              protocol.title,
              protocol.number,
              res.data.content
            )
          );
        }
      );
    }
  }, [protocol]);

  const handleDescriptionChange = (event) => {
    setDescription(event);
  };

  const handleIsPrivateChange = () => {
    setIsPrivate((prevState) => !prevState);
  };

  const handleAddNewFile = (event) => {
    const uploadedFiles = event?.target?.files;

    if (!uploadedFiles || !uploadedFiles.length) return;

    const fileAlreadyUploaded = [...uploadedFiles].some((uploadedFile) =>
      files.some((file) => file.name === uploadedFile.name)
    );

    if (fileAlreadyUploaded) {
      event.target.value = '';
      return;
    }

    setFiles([...files, ...uploadedFiles]);
    event.target.value = '';
  };

  const handleRemoveFile = (fileName) => {
    return () => {
      setFiles((prevState) =>
        prevState.filter((file) => file.name !== fileName)
      );
    };
  };

  const handleDeleteAttachment = (attachmentId) => {
    return () => {
      setAttachmentsToRemove((prevState) => [...prevState, attachmentId]);
      setAttachments((prevState) =>
        prevState.filter((attachment) => attachment.id !== attachmentId)
      );
    };
  };

  const showCC = (e) => {
    e.preventDefault();
    setIsCC(!isCC);
  };

  function handleSubmit(callback) {
    const formData = new FormData();

    formData.append('description', description);
    formData.append('is_private', isPrivate);
    formData.append('sendSignatureEmail', sendSignature);
    formData.append('emailsInCopy', emailsInCopy);

    for (const file of files) {
      formData.append('attachments', file, file.name);
    }

    for (const attachmentToRemove of attachmentsToRemove) {
      formData.append('attachmentsToRemove', attachmentToRemove);
    }

    return async (event) => {
      event.preventDefault();

      await callback(formData);

      setDescription('');
      setIsPrivate(true);
      setFiles([]);
      comment && onCancel();
    };
  }

  async function mustSendSignature(userLogin) {
    await EmailSignatureService.mustSendSignature(userLogin);
  }

  useEffect(() => {
    EmailSignatureService.getSignature(localStorage.getItem('login'))
      .then((response) => {
        if (response) {
          setHasSignature(true);
          setSendSignature(response.must_send);
        }
      })
      .then(() => {});
  }, []);

  const handleAddPredefinedMessage = (message) => {
    const descriptionWithPredefined = `
      ${description}
      ${message}
    `;
    setDescription(descriptionWithPredefined);
  };

  useEffect(() => {
    if (!comment) return;
    setDescription(comment.description);
    setIsPrivate(comment.is_private);
    setAttachments(comment.attachments);
  }, [comment]);

  const toggleMessagePredefinedModal = () => {
    setOpenPredefinedMessages(!openPredefinedMessages);
  };

  useEffect(() => {
    if (isCC) setEmailsInCopy(protocol?.emailsInCopy || []);
    else setEmailsInCopy([]);
  }, [isCC]);

  return (
    <>
      <PredefinedMessageModal
        handle={handleAddPredefinedMessage}
        predefinedMessages={predefinedMessages}
        open={openPredefinedMessages}
        toggleModal={toggleMessagePredefinedModal}
        setPredefinedMessage={setPredefinedMessageSelected}
      />

      <Form
        className="needs-validation rounded p-2"
        style={{
          background: '#fff',
          boxShadow: '0px 0px 3px 1px #00000050'
        }}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="off"
      >
        <div
          className="rounded overflow-hidden"
          style={{
            marginBottom: '4px'
          }}
        >
          {!comment && (
            <>
              <InputGroup>
                <InputGroupText
                  className="form-control-label"
                  style={{
                    width: '8%',
                    borderRight: 'none',
                    borderTop: 'none',
                    borderBottom: 'none',
                    borderRadius: '0px',
                    borderLeft: 'none'
                  }}
                >
                  Para
                </InputGroupText>
                <Input
                  style={{
                    border: 'none',
                    boxShadow: 'none'
                  }}
                  type="text"
                  rows="5"
                  name="destiny"
                  value={protocol?.email || ''}
                  //disabled={!!protocol?.email}
                  placeholder=""
                  onChange={() => {}}
                  className="rounded-0  p-2"
                />
                <ButtonGroup>
                  <ButtonCCWrapper color={isCC}>
                    <Button className="form-control-label" onClick={showCC}>
                      CC
                    </Button>
                  </ButtonCCWrapper>
                </ButtonGroup>
              </InputGroup>
              {isCC && (
                <InputGroup
                  style={{
                    width: '100%'
                  }}
                >
                  <InputGroupText
                    className="form-control-label"
                    style={{
                      width: '8%',
                      borderRadius: '0px',
                      borderWidth: '1px 0 0px 0'
                    }}
                  >
                    CC
                  </InputGroupText>
                  <div style={{ width: '92%', display: 'flex' }}>
                    <CreatableFlexSelect
                      validation={(value) => {
                        if (!emailRegex.test(value)) return true;
                      }}
                      itemStyle={{
                        color: '#FFF',
                        borderWidth: '1px',
                        borderColor: '#ced4da'
                      }}
                      controlStyle={{
                        height: '95%',
                        borderRadius: '0px',
                        borderTopWidth: '1px',
                        borderLeft: 'none',
                        borderBottom: 'none',
                        BorderRight: 'none',
                        borderColor: '#ced4da',
                        borderRight: 'none'
                      }}
                      setValue={setEmailsInCopy}
                      defaultValue={protocol?.emailsInCopy || []}
                    />
                  </div>
                </InputGroup>
              )}

              <InputGroup>
                <InputGroupText
                  className="form-control-label"
                  style={{
                    width: '8%',
                    borderRight: 'none',
                    borderRadius: '0px',
                    borderLeft: 'none',
                    boxShadow: 'none'
                  }}
                >
                  Assunto
                </InputGroupText>

                <Input
                  style={{
                    boxShadow: 'none',
                    borderRight: 'none',
                    borderLeft: 'none'
                  }}
                  type="text"
                  rows="5"
                  name="assunto"
                  placeholder=""
                  value={protocol ? subject : ''}
                  onChange={() => {}}
                  className="rounded-0  p-2"
                />
              </InputGroup>
            </>
          )}
          <CommentsFormWrapper>
            <ReactQuill
              modules={modules}
              name="description"
              theme="snow"
              placeholder="Escreva um comentário"
              defaultValue={description}
              onChange={handleDescriptionChange}
              value={description}
              style={{
                border: 'none',
                boxShadow: 'none'
              }}
            />
          </CommentsFormWrapper>

          {files.length || attachments.length ? (
            <div className=" bg-white p-2 d-flex flex-column align-items-start">
              {attachments.map((attachment) => (
                <div
                  key={attachment.id}
                  title={attachment.name}
                  className="attachment-name-container p-2 pr-5 br-2 position-relative"
                >
                  <img
                    src={getIconFromFileExtension(
                      getFileExtensionFromName(attachment.name)
                    )}
                    width="24px"
                    height="24px"
                    alt={attachment.name}
                    className="d-inline-block mr-2"
                  />
                  <a
                    href={getFileURL(attachment.path)}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={true}
                    style={{
                      fontSize: '14px'
                    }}
                  >
                    {getFileNameFormatted(attachment.name)}
                  </a>
                  <IoMdCloseCircle
                    type="button"
                    onClick={handleDeleteAttachment(attachment.id)}
                    className="delete-icon"
                  />
                </div>
              ))}
              {files.map((attachment) => (
                <div
                  key={attachment.id}
                  title={attachment.name}
                  className="attachment-name-container p-2 pr-5 br-2 position-relative"
                >
                  <img
                    src={getIconFromFileExtension(
                      getFileExtensionFromName(attachment.name)
                    )}
                    width="24px"
                    height="24px"
                    alt={attachment.name}
                    className="d-inline-block mr-2"
                  />
                  <a
                    href={getFileURL(attachment.path)}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={true}
                    style={{
                      fontSize: '14px'
                    }}
                  >
                    {getFileNameFormatted(attachment.name)}
                  </a>
                  <IoMdCloseCircle
                    type="button"
                    onClick={handleDeleteAttachment(attachment.id)}
                    className="delete-icon"
                  />
                </div>
              ))}
            </div>
          ) : null}
        </div>

        <div className="pt-3 pb-2 d-flex justify-content-between align-items-center">
          <div>
            <div>
              <Label
                htmlFor="file"
                style={{
                  cursor: 'pointer',
                  margin: 0,
                  padding: '10px',
                  display: 'inline-flex'
                }}
              >
                <FaPaperclip size={16} />
              </Label>
              <Input
                id="file"
                type="file"
                multiple
                onChange={handleAddNewFile}
                hidden
                accept=".png, .jpeg, .jpg, .pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
            </div>
          </div>
          {comment ? (
            <div>
              <Button
                type="button"
                color="danger"
                title="Cancelar atualização"
                onClick={onCancel}
                Cancelar
              >
                Cancelar
              </Button>

              <Button
                color="primary"
                title="Atualizar comentário"
                disabled={description === ''}
              >
                Atualizar comentário
              </Button>
            </div>
          ) : (
            <div>
              <UncontrolledDropdown group>
                <Button
                  color="success"
                  title="Adicionar novo comentário"
                  disabled={description === ''}
                >
                  {isPrivate ? 'Comentar privado' : 'Comentar e notificar'}
                </Button>
                <DropdownToggle data-toggle="dropdown" caret color="success" />
                <DropdownMenu
                  right
                  style={{
                    width: '352px'
                  }}
                >
                  <DropdownItem
                    onClick={handleIsPrivateChange}
                    style={{
                      whiteSpace: 'break-spaces'
                    }}
                  >
                    <span>Comentário privado</span>
                    <p
                      className="m-0"
                      style={{
                        fontSize: '12px'
                      }}
                    >
                      Um comentário privado só pode ser visualizado pela
                      plataforma do FlexUC
                    </p>
                  </DropdownItem>
                  <DropdownItem
                    onClick={handleIsPrivateChange}
                    style={{
                      whiteSpace: 'break-spaces'
                    }}
                  >
                    <span>Comentário notificar</span>
                    <p
                      className="m-0"
                      style={{
                        fontSize: '12px'
                      }}
                    >
                      Será enviado um email para o cliente com o comentário
                    </p>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown group>
                <DropdownToggle data-toggle="dropdown" caret color="success">
                  <IoIosOptions size={20} />
                </DropdownToggle>
                <DropdownMenu
                  right
                  style={{
                    width: '352px'
                  }}
                >
                  <DropdownItem onClick={toggleMessagePredefinedModal}>
                    <span>Mensagens pré-definidas</span>
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      mustSendSignature(localStorage.getItem('login'));
                      setSendSignature(!sendSignature);
                    }}
                    disabled={!hasSignature}
                  >
                    <OptionSendSignatureWrapper>
                      <OptionSendSignatureDescription>
                        <span>Enviar assinatura</span>
                        <p
                          className="m-0"
                          style={{
                            fontSize: '12px'
                          }}
                        >
                          Enviar assinatura para o cliente
                          <br />
                          {!hasSignature && (
                            <SpanSignature>
                              (assinatura não cadastrada)
                            </SpanSignature>
                          )}
                        </p>
                      </OptionSendSignatureDescription>
                      <CheckboxSignatureWrapper>
                        <Input
                          type="checkbox"
                          name="signature"
                          id="signature"
                          className="ml-2"
                          checked={sendSignature}
                        />
                      </CheckboxSignatureWrapper>
                    </OptionSendSignatureWrapper>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )}
        </div>
      </Form>
    </>
  );
};

export default CommentsForm;
