import React from 'react';
import { FaClock } from 'react-icons/fa';

import AgentIndicatorCard from '../AgentIndicatorCard';

const TimeLoginCard = ({ data }) => {
  return (
    <AgentIndicatorCard icon={FaClock}>
      <AgentIndicatorCard.Header>
        <AgentIndicatorCard.Title>Tempo logado</AgentIndicatorCard.Title>
      </AgentIndicatorCard.Header>
      <AgentIndicatorCard.Body value={data} />
    </AgentIndicatorCard>
  );
};

export default React.memo(TimeLoginCard);
