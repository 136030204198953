import { useState, useRef } from 'react';

export const useSearch = () => {
  const [search, setSearch] = useState('');
  const timer = useRef(null);

  const debounce = (fn) => {
    return (args) => {
      const timerId = timer.current;

      if (timerId) {
        clearTimeout(timerId);
      }

      timer.current = setTimeout(() => {
        fn(args);
      }, 300);
    };
  };

  const debouncedSearch = debounce((value) => setSearch(value));

  return { search, debouncedSearch };
};
