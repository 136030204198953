import { apiCase as http } from '../../../http';

class EmailSignatureService {
  async mustSendSignature(userLogin) {
    return http.put(`/emails/signature/must-send/${userLogin}`);
  }

  async getSendSignature(userLogin) {
    const result = await http.get(`/emails/signature/must-send/${userLogin}`);
    return result.data.content.must_send;
  }

  async getSignature(userLogin) {
    const result = await http.get(`/emails/signature/${userLogin}`);
    return Object.keys(result.data.content).length ? result.data.content : null;
  }
}

export default new EmailSignatureService();
