import React, { useState, useEffect, useCallback } from 'react';
import Service from './service';
import PauseService from '../../../../views/Pause/service';
import UserService from '../../../../views/User/service';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Pagination from 'components/Pagination';
import Loader from 'react-loader-spinner';
import { Closed } from './Assets/types';
import { dateRules } from './Assets/rulesValidation';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';
import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ReportPauseTable from './table';
import csvImage from '../../../../assets/img/csv.png';
import * as fileSaver from 'file-saver';
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody
} from 'reactstrap';

import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';

const PauseReport = () => {
  const { control, handleSubmit, errors, setValue, getValues } = useForm();
  const [ReportTypePause, setReportTypePause] = useState([]);
  const [dataToSubmitDownloads, setDataToSubmitDownloads] = useState();
  const [pauses, setPauses] = useState([]);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [agents, setAgents] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  //pause to select
  useEffect(() => {
    PauseService.getAll()
      .then((response) => {
        setPauses([{ name: 'Todos', id: 'Todos' }, ...response.data.data]);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);

  //agent||users to select
  useEffect(() => {
    getUser(true);
  }, [usersShowDisable]);
  
  const getUser = (showDisable = false) => {
    if(!showDisable){
      setLoadingForm(true);
    }
    UserService.getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        setAgents([
          { agent_code: 'Todos', name: 'Todos' },
          ...response.data.data
        ]);
        if(!showDisable){
          setLoadingForm(false);
        }
      })
      .catch((err) => {
        console.log('err: ' + err);
        if(!showDisable){
          setLoadingForm(false);
        }
      });
  }

  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantity,
      limitGenerationPDF
    );

    setMessagemGenerateCsv(message.csvMessage);
  }, [quantity, limitGenerationPDF]);

  //não existe relatorio de pausa por enquanto, no service do backEnd
  const downloadMedia = useCallback(
    async (typeReport, type, fileName) => {
      try {
        const response = await Service.getDownloads(
          typeReport,
          dataToSubmitDownloads
        );
        fileSaver.saveAs(new Blob([response.data], { type }), fileName);
      } catch (err) {
        toast.error(err || 'Ops, ocorreu um erro!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [dataToSubmitDownloads]
  );

  const toISOFormat = useCallback(async (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${YYYY}-${MM}-${DD}`;
  }, []);

  const getDataDTO = useCallback(
    async (data) => {
      let dataToSend;
      dataToSend = {
        ...data,
        //esse campo esta setado como todos, mas futuramente deve ser alterado
        usuario: 'Todos',
        tipoPausa: 'Todos',
        dataInicial: `${await toISOFormat(data.dataInicial)}`,
        dataFinal: `${await toISOFormat(data.dataFinal)}`
      };
      setDataToSubmitDownloads(dataToSend);
      return dataToSend;
    },
    [toISOFormat]
  );

  const getReports = useCallback(
    (typeReport, selectedPage = 1, dataToSend) => {
      const newActivePage = selectedPage || activePage;
      setLoadingTable(true);
      Service.getReport(typeReport, selectedPage, dataToSend)
        .then((res) => {
          const { pausas, quantidadeDePausas } = res.data;
          setReportTypePause(pausas);
          setQuantity(quantidadeDePausas);
          setActivePage(newActivePage);
          if (pausas.length === 0) {
            toast.info('Nenhum registro foi encontrado!');
          }
          setLoadingTable(false);
        })
        .catch((err) => {
          setLoadingTable(false);
          console.log('erro' + err);
        });
    },
    [activePage]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        const dataToSend = await getDataDTO(data);
        getReports('pausa', 1, dataToSend);
      } catch (err) {
        toast.error(err || 'Erro desconhecido por favor, informar o suporte!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [getDataDTO, getReports]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const handlePageChange = (activePage) => {
    getReports('pausa', activePage, dataToSubmitDownloads);
  };

  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de Pausas</h3>
                </CardHeader>
                <CardBody>
                  {loadingForm ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Data inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dataInicial"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dataInicial"
                            rules={dateRules}
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dataInicial"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Data final*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dataFinal"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dataFinal"
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                            rules={{
                              ...dateRules,
                              validate: async (value) => {
                                if (
                                  new Date(await toISOFormat(value, true)) <
                                  new Date(
                                    await toISOFormat(
                                      getValues()['dataInicial'],
                                      true
                                    )
                                  )
                                ) {
                                  return 'A data final não pode ser anterior à inicial!';
                                } else {
                                  return true;
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dataFinal"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-12">
                        <Col md="6">
                              <label className="form-control-label">
                                Usuário
                              </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={agents}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                fieldName="usuario"
                                labelName="name"
                                valueName="agent_code"
                              />
                            )}
                            control={control}
                            name="usuario"
                            defaultValue="Todos"
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Tipo de Pausa
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={pauses}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                fieldName="tipoPausa"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="tipoPausa"
                            defaultValue="Todos"
                          />
                        </Col>
                      </Row>

                      <Row className="mb-12">
                        <Col md="12">
                          <label className="form-control-label">
                            Encerramento
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={Closed}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                isClearable={true}
                                fieldName="isClosed"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="isClosed"
                            defaultValue="Todos"
                          />
                        </Col>
                      </Row>
                      <div
                            style={{
                              marginTop: '10px'
                            }}
                          >
                            <UserFlexSelect
                              usersShowDisable={usersShowDisable}
                              setUsersShowDisable={setUsersShowDisable}
                              name={'Usuário'}
                            />
                          </div>

                      <Row className="mb-4">
                        <Col md="12" style={ImageButtonContainer}>
                          <button
                            style={ImageButton}
                            onClick={() =>
                              downloadMedia(
                                'pausa',
                                'application/csv',
                                'Relatório_Pausas.csv'
                              )
                            }
                            disabled={disabledDownloadButtonCsvAndPdf(
                              'csv',
                              quantity,
                              limitGenerationPDF
                            )}
                          >
                            <Tooltip
                              title={
                                <p style={{ fontSize: '14px' }}>
                                  {messagemGenerateCsv}
                                </p>
                              }
                              arrow
                            >
                              <img src={csvImage} alt="csv" style={ImageSize} />
                            </Tooltip>
                          </button>
                        </Col>
                      </Row>

                      <Button
                        color="primary"
                        type="submit"
                        style={{
                          ...buttonStyle(systemConfiguration.primary_color)
                        }}
                      >
                        Buscar
                      </Button>
                    </Form>
                  )}
                </CardBody>
                {loadingTable ? (
                  <div className="py-4 table-responsive">
                    <Row className="justify-content-md-center">
                      {loadingTable && (
                        <Loader
                          type="TailSpin"
                          color={systemConfiguration.primary_color}
                          height={100}
                          width={100}
                        />
                      )}
                    </Row>
                  </div>
                ) : (
                  <>
                    {dataToSubmitDownloads && ReportTypePause.length !== 0 && (
                      <ReportPauseTable showReports={ReportTypePause} />
                    )}
                    {dataToSubmitDownloads && ReportTypePause.length !== 0 && (
                      <Pagination
                        activePage={activePage}
                        totalItemsCount={quantity}
                        onChange={handlePageChange.bind(this)}
                      />
                    )}
                  </>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default PauseReport;
