import styled from 'styled-components';

export const UserInfoTitle = styled.span`
  font-size: 1rem;
  font-weight: bold;
  color: #5e6a88;
  font-weight: bold;
  padding-bottom: 0.5rem;
`;
export const ContentUserInfo = styled.div`
  display: flex;
  flex: wrap;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

export const UserInfoPause = styled.span`
  font-size: 0.9rem;
  font-weight: bold;
  color: #5e72e4;
  font-weight: bold;
  padding-bottom: 0.5rem;

  span {
    color: #5e6a88;
  }
`;

export const Button = styled.button`
  border: 0;
  border-radius: 0.2rem;
  margin-top: 0.6rem;
  background-color: #5e72e4;
  width: 100%;
  height: 40px;
  font-weight: 550;
  color: white;
  transition: 0.5s;
  font-family: Open Sans, sans-serif;
  &:hover {
    background-color: #36327f;
  }
`;

export const ContentLoading = styled.div`
  margin: 0 auto;
`;
