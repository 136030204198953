import { useState, useEffect, useCallback } from 'react';
import FieldsConfigService from '../service/index';

function useProtocolFields() {
  const [protocolFields, setProtocolFields] = useState(null);
  const [isLoadingProtocolFields, setIsLoadingProtocolFields] = useState(false);

  const getProtocolFields = useCallback(async (params = {}) => {
    setIsLoadingProtocolFields(true);

    FieldsConfigService.getFields('protocol', params)
      .then((response) => {
        const fieldsData = response.data.data;
        setProtocolFields(fieldsData);
      })
      .catch(() => {
        setProtocolFields(null);
      })
      .finally(() => {
        setIsLoadingProtocolFields(false);
      });
  }, []);

  useEffect(() => {
    getProtocolFields();
  }, [getProtocolFields]);

  return {
    protocolFields,
    isLoadingProtocolFields,
    reloadProtocolFields: getProtocolFields
  };
}

export { useProtocolFields };
