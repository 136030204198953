import React from 'react';
import child from '../../../../../assets/img/icons/generic/child.png';
import parent from '../../../../../assets/img/icons/generic/parent.png';
import { SubProtocolContainer } from './styles';

export const SubProtocol = ({ protocol, isParent = false, handleRedirect }) => {
  const date = new Date(protocol.creation_date);
  const createdAt = `${date.toLocaleDateString(
    'pt-BR'
  )} às ${date.toLocaleTimeString('pt-BR', {
    timeZone: 'America/Fortaleza'
  })}`;

  return (
    <SubProtocolContainer>
      <img
        style={{ position: 'absolute', right: '8px' }}
        src={isParent ? parent : child}
        height={20}
        width={20}
      ></img>
      <span
        onClick={() => handleRedirect(protocol.id, protocol.record_type_id)}
        className="protocol-number"
      >
        {protocol?.number}
      </span>
      <br></br>
      <span className="fw-600">
        Criado em: <span className="fw-400">{createdAt}</span>
      </span>
      <br></br>

      <div
        title={protocol?.protocol_current_responsible?.name}
        style={{
          maxWidth: '250px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
      >
        <span className="fw-600">
          Responsável:{' '}
          <span className="fw-400">
            {protocol?.protocol_current_responsible?.name}
          </span>
        </span>
      </div>

      <div
        title={protocol?.status?.name}
        style={{
          maxWidth: '250px',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
      >
        <span className="fw-600">
          Status: <span className="fw-400">{protocol?.status?.name}</span>{' '}
        </span>
      </div>
    </SubProtocolContainer>
  );
};
