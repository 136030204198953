import React, { useMemo } from 'react';
import HeaderBar from '../HeaderBar';
import { Col, Row } from 'reactstrap';
import AgentCard from 'views/Dashboard/Agent/components/AgentIndicators/components/AgentCard';
import ReportCard from '../ReportCard';
import { AgentsCardsContainerWrapper } from './styled';

const AgentCardsContainer = ({ dialerName, agents = [], data }) => {
  const total = useMemo(() => {
    if (data && data[0]) {
      const initialValue = 0;
      const sumWithInitial = data[0].groupByMessage.reduce(
        (accumulator, currentValue) => {
          return accumulator + parseInt(currentValue?.count ?? 0);
        },
        initialValue
      );
      return sumWithInitial;
    }
    return 0;
  }, [data]);

  return (
    <AgentsCardsContainerWrapper>
      <Row>
        <Col>
          <HeaderBar
            title={`Lista de Transmissão: ${dialerName}`}
            classname="mb-2"
          />
          <div
            id="agents"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill,minmax(11rem,2fr))',
              gap: '1rem'
            }}
          >
            {agents &&
              agents.map((agent, index) => (
                <AgentCard data={agent} key={index} />
              ))}
          </div>
        </Col>
      </Row>
      <Row>
        <Col style={{ display: 'flex', width: '25%' }}>
          <ReportCard
            data={{
              title: 'Total de mensagens',
              value: total
            }}
          />
        </Col>
      </Row>
    </AgentsCardsContainerWrapper>

    // <div>
    //   <div id="header">
    //     <HeaderBar title={`Discador: ${dialerName}`} />
    //   </div>
    //   <div id="agents" style={{ display: 'flex' }}>
    //     {agents &&
    //       agents.map((agent, index) => <AgentCard data={agent} key={index} />)}
    //   </div>
    // </div>
  );
};

export default AgentCardsContainer;
