import React, { useState, useMemo, useRef, useCallback } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  FormGroup,
  ModalBody
} from 'reactstrap';
import SupervisorPanelService from '../../service';

const ModalExample = (props) => {
  const { buttonLabel, className, extensionToSpy } = props;
  const agentCode = useMemo(() => localStorage.getItem('agent_code') || '', []);
  const listeningExtensionRef = useRef(null);

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => setModal(!modal);

  const startSpyOn = useCallback(
    async ({ isInteractive }) => {
      setIsLoading(true);

      try {
        if (
          !listeningExtensionRef.current ||
          !listeningExtensionRef.current.value
        )
          throw new Error('Informe o ramal de escuta');

        const listeningExtension = listeningExtensionRef.current.value;

        const requestData = {
          agentCode,
          extensionToSpy,
          listeningExtension,
          isInteractive
        };

        const response = await SupervisorPanelService.handleStartSpyOn(
          requestData
        );

        const { message } = response.data;

        setModal(false);

        return toast.success(message, {
          autoClose: 3000,
          closeOnClick: true
        });
      } catch (error) {
        const msg =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          'Erro ao ativar o espião';

        return toast.error(msg, {
          autoClose: 3000,
          closeOnClick: true
        });
      } finally {
        setIsLoading(false);
      }
    },
    [agentCode, extensionToSpy]
  );

  return (
    <div>
      <Button
        type="button"
        color="primary"
        size="sm"
        onClick={toggle}
        disabled={props.loading}
      >
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody>
          <h3>Como você deseja Espionar?</h3>
          <hr />
          <Row>
            <Col>
              <Form>
                <Row form>
                  <Col>
                    <FormGroup>
                      <input
                        ref={listeningExtensionRef}
                        type="number"
                        name="listeningExtension"
                        placeholder="Escolha qual ramal deseja ouvir"
                        className="text-center form-control"
                        defaultValue=""
                        disabled={isLoading}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col md={6}>
                    <FormGroup>
                      <Button
                        type="button"
                        color="primary"
                        block
                        onClick={() => startSpyOn({ isInteractive: false })}
                        disabled={isLoading}
                      >
                        Não Interativo
                      </Button>
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Button
                        type="button"
                        color="primary"
                        block
                        onClick={() => startSpyOn({ isInteractive: true })}
                      >
                        Interativo
                      </Button>
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ModalExample;
