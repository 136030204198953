import { apiNpsMidia } from 'http/index.js';

class SurveyService {
  getTags = () => {
    return apiNpsMidia.get(`tags`);
  };

  getSurveyConfig = (surveyId) => {
    return apiNpsMidia.get(`/configuration/survey/${surveyId}`);
  };

  sendInquiry = (conversationId, surveyId, data) => {
    return apiNpsMidia.post(
      `/satisfaction/survey/answer/${conversationId}/${surveyId}`,
      data
    );
  };
}

export default new SurveyService();
