import React from 'react';

import {
  ChartLabelContainer,
  ChartLabelIcon,
  ChartLabelLabel,
  ChartLabelValue
} from './styled';

const ChartLabel = ({ label, value, alternativeValue, color }) => {
  return (
    <ChartLabelContainer>
      <ChartLabelIcon backgroundColor={color} />
      <div className="ml-2 d-flex flex-column">
        <div>
          <ChartLabelValue>{value}</ChartLabelValue>
          {alternativeValue && <span> ({alternativeValue})</span>}
        </div>
        <ChartLabelLabel>{label}</ChartLabelLabel>
      </div>
    </ChartLabelContainer>
  );
};

export default ChartLabel;
