export const defaultOptionalFields = [
  {
    name: 'numero2',
    label: 'Número 2'
  },
  {
    name: 'numero3',
    label: 'Número 3'
  },
  {
    name: 'numero4',
    label: 'Número 4'
  },
  {
    name: 'numero5',
    label: 'Número 5'
  },
  {
    name: 'numero6',
    label: 'Número 6'
  },
  {
    name: 'numero7',
    label: 'Número 7'
  },
  {
    name: 'numero8',
    label: 'Número 8'
  },
  {
    name: 'numero9',
    label: 'Número 9'
  },
  {
    name: 'numero10',
    label: 'Número 10'
  }
];
