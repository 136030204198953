import { ErrorMessage } from '@hookform/error-message';
import ReactButton from 'components/Buttons/ReactButton';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import FlexSelect from '../../../../components/Inputs/FlexSelect';
import AudioService from '../../../Audio/service';
import QueueService from '../../../Queue/service';
import TagService from '../../Tags/service';
import Service from '../service';
import {
  NPSDefaultLabelByScore,
  rates,
  satisfactionDefaultLabelByScore,
  typesConfig
} from './utils';
import { ScreenPrompt } from 'components/ScreenPrompt';

const ConfigSurveyForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [audioQuestion, setAudioQuestion] = useState([]);
  //audio_closing_message_id
  const [audioClosingMessage, setAudioClosingMessage] = useState([]);
  const [queues, setQueues] = useState([]);
  const [tags, setTags] = useState([]);
  const [askForObservation, setAskForObservation] = useState(true);
  const [sendToContatoAtivo, setSendToContatoAtivo] = useState(false);

  const { control, handleSubmit, errors, setValue, watch, reset, formState } =
    useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const typeControl = watch('type_survey');

  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      setLoading(true);
      Service.get(id)
        .then((res) => {
          setIsUpdate(true);
          const getConfig = res.data;
          //cria funcção pra formatar, depois passa ela pra a função que trata o objeto
          const dataFormattedForDisplay = formatDataToUpdateForm(getConfig);

          reset(dataFormattedForDisplay);
          setAskForObservation(getConfig.askForObservation);
          setSendToContatoAtivo(getConfig.sendToContatoAtivo);
        })
        .catch((err) => {
          console.log('erro ao exibir pesquisa', err);
          toast.error(
            'Erro ao exibir configuração, por favor entrar em contato com o suporte',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
        });
    }
  }, [id, reset]);

  useEffect(() => {
    setLoading(true);
    QueueService.getAll()
      .then((response) => {
        setQueues(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    AudioService.getAll()
      .then((response) => {
        const { data } = response.data;

        const audiosTypeUra = data.filter((audio) => audio.type === 'ura');
        const audiosTypeSound = data.filter((audio) => audio.type === 'sound');
        setAudioClosingMessage(audiosTypeSound);
        setAudioQuestion(audiosTypeUra);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    TagService.showTags()
      .then((response) => {
        setTags(response.data.list);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  const formatData = useCallback(
    async (data) => {
      let dataToSubmit = {
        question: data.question,
        audio_question_id: data.audio_question_id,
        audio_closing_message_id: data.audio_closing_message_id,
        queues: data.queues,
        type_survey: data.type_survey,
        chat_message: data.chat_message,
        closing_message: data.closing_message,
        name: data.name,
        sendToContatoAtivo: data.sendToContatoAtivo,
        incorrectOptionMessage: data.incorrectOptionMessage,
        quantityOfInvalidAttempts: data.quantityOfInvalidAttempts
      };
      const quantity = data.rate9 ? 10 : 5;

      let answer = [];

      for (let i = 0; i < quantity; i++) {
        let tagsArray = [];

        for (let k = 0; k < data[`tags${i}`].length; k++) {
          tagsArray[k] = { order: k, _id: data[`tags${i}`][k] };
        }

        answer.push({
          rate: data[`rate${i}`],
          rate_label: data[`rate_label${i}`],
          audio_rate_id: data[`audio_rate_id${i}`],
          question_tag: data[`question_tag${i}`],
          tags: data[`tags${i}`],
          tags_order: tagsArray
        });
      }

      dataToSubmit['answers'] = answer;

      const objQueues = queues.filter((queue) => {
        if (dataToSubmit.queues.includes(queue.id)) {
          return queue;
        }
        return null;
      });

      dataToSubmit['queues'] = objQueues;

      for (let j = 0; j < dataToSubmit.answers.length; j++) {
        const objTags = tags.filter((tag) => {
          if (dataToSubmit.answers[j].tags.includes(tag._id)) {
            return tag;
          }
          return null;
        });

        dataToSubmit.answers[j].tags = objTags;
      }

      return dataToSubmit;
    },
    [queues, tags]
  );

  const formatDataToUpdateForm = (data) => {
    const lengthAnswer = data.answers.length;
    let setDataUpdate = {};
    for (let i = 0; i < lengthAnswer; i++) {
      setDataUpdate[`question_tag${i}`] = data.answers[i].question_tag;
      setDataUpdate[`rate${i}`] = parseInt(data.answers[i].rate);
      setDataUpdate[`rate_label${i}`] = data.answers[i].rate_label || '';
      setDataUpdate[`audio_rate_id${i}`] = parseInt(
        data.answers[i].audio_rate_id
      );

      let tagsOrder = [];
      const lgth = data.answers[i].tags.length;
      for (let j = 0; j < lgth; j++) {
        tagsOrder[data.answers[i].tags[j].order] = data.answers[i].tags[j]._id;
      }

      setDataUpdate[`tags${i}`] = tagsOrder;
    }

    setDataUpdate[`question`] = data.question;
    setDataUpdate[`audio_question_id`] = parseInt(data.audio_question_id);
    setDataUpdate[`audio_closing_message_id`] = parseInt(
      data.audio_closing_message_id
    );
    setDataUpdate[`type_survey`] = data.type_survey;
    setDataUpdate['sendToContatoAtivo'] = data.sendToContatoAtivo;
    setDataUpdate['chat_message'] = data.chat_message;
    setDataUpdate['closing_message'] = data.closing_message;
    setDataUpdate['name'] = data.name;
    setDataUpdate['incorrectOptionMessage'] = data.incorrectOptionMessage;
    setDataUpdate['quantityOfInvalidAttempts'] = data.quantityOfInvalidAttempts;
    setDataUpdate['queues'] = data.queues.map((queue) => {
      return parseInt(queue.id);
    });
    return setDataUpdate;
  };

  const onSubmit = useCallback(
    async (data) => {
      try {
        const dataToForm = await formatData(data);
        dataToForm.askForObservation = askForObservation;
        dataToForm.sendToContatoAtivo = sendToContatoAtivo;
        setLoading(true);
        if (isUpdate) {
          const responseUpdate = await Service.update(id, dataToForm);
          toast.info(`Configuração de pesquisa: ${responseUpdate.data.msg}`);
        } else {
          const responseCreate = await Service.create(dataToForm);
          toast.success(`Configuração de pesquisa: ${responseCreate.data.msg}`);
        }
        history.push('/admin/configSurvey/list');
      } catch (err) {
        const { msg } = err.response.data;
        const msgReturn = isUpdate
          ? `Não é possivel atualizar essa configuração. ${msg}`
          : `Não é possivel cadastrar essa configuração. ${msg}`;
        if (msg) {
          toast.error(msgReturn, {
            autoClose: 3000,
            closeOnClick: true
          });
        } else {
          toast.error(
            `Não é possível realizar essa ação, por favor informa ao suporte`,
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
        }

        history.push('/admin/configSurvey/list');
      }
    },
    [history, formatData, id, isUpdate, askForObservation, sendToContatoAtivo]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );
  const cancelAction = () => {
    history.push('/admin/configSurvey/list');
  };
  const renderAnswer = (answersQuantity) => {
    let answers = [];
    const defaultLabelsMap =
      answersQuantity === 5
        ? satisfactionDefaultLabelByScore
        : NPSDefaultLabelByScore;

    for (let index = 0; index < answersQuantity; index++) {
      answers.push(
        <div key={index}>
          <Row>
            <Col>
              <h3 className="mb-1">{`Resposta: 0${index + 1}`}</h3>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <label className="form-control-label">Nota</label>
              <Controller
                render={(props) => (
                  <FlexSelect
                    dataOptions={rates}
                    isMulti={false}
                    value={props.value}
                    isClearable={true}
                    valueController={setValue}
                    closeMenuOnSelect={true}
                    fieldName={`rate${index}`}
                    labelName="label"
                    valueName="value"
                    defaultValue={index + 1}
                  />
                )}
                defaultValue={index + 1}
                control={control}
                name={`rate${index}`}
              />
            </Col>
            <Col md="4">
              <label className="form-control-label">Rótulo da Nota</label>
              <Controller
                as={Input}
                control={control}
                name={`rate_label${index}`}
                defaultValue={defaultLabelsMap.get(index + 1)}
              />
              <ErrorMessage
                errors={errors}
                name={`rate_label${index}`}
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
            <Col md="4">
              <label className="form-control-label">Áudio</label>
              <Controller
                render={(props) => (
                  <FlexSelect
                    dataOptions={audioQuestion}
                    isMulti={false}
                    value={props.value}
                    isClearable={true}
                    valueController={setValue}
                    closeMenuOnSelect={true}
                    fieldName={`audio_rate_id${index}`}
                    labelName="name"
                    valueName="id"
                  />
                )}
                defaultValue=""
                control={control}
                name={`audio_rate_id${index}`}
              />
            </Col>
          </Row>

          <Row>
            <Col md="12">
              <label className="form-control-label">Questão Chat</label>

              <Controller
                as={Input}
                control={control}
                name={`question_tag${index}`}
                defaultValue="Selecione quais das opções abaixo corresponderam ao seu atendimento?"
              />
              <ErrorMessage
                errors={errors}
                name={`question_tag${index}`}
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <label className="form-control-label">Tags</label>

              <Controller
                render={(props) => (
                  <FlexSelect
                    dataOptions={tags}
                    isMulti={true}
                    isClearable={true}
                    value={props.value}
                    valueController={setValue}
                    fieldName={`tags${index}`}
                    labelName="name"
                    valueName="_id"
                  />
                )}
                control={control}
                name={`tags${index}`}
                defaultValue=""
              />
            </Col>
          </Row>
          <hr />
        </div>
      );
    }
    return answers;
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/configSurvey/list"
        buttonTitle="Voltar para a pagina de configuração de pesquisa"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Configuração de pesquisa</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color="#35ac50"
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mt-2">
                        <Col>
                          <h3 className="mb-1">Questão</h3>
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md="8">
                          <label className="form-control-label">Questão*</label>

                          <Controller
                            as={Input}
                            control={control}
                            defaultValue=""
                            name="question"
                            rules={{
                              required: 'Campo obrigatório'
                            }}
                            // defaultValue="Como você avalia este atendimento, sendo que 1 é muito insatisfeito, e 5 muito satisfeito?"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="question"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md="4">
                          <label className="form-control-label">
                            Áudio da questão*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={audioQuestion}
                                isMulti={false}
                                value={props.value}
                                isClearable={true}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="audio_question_id"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="audio_question_id"
                            rules={{ required: 'Campo obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="audio_question_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md="8">
                          <label className="form-control-label">Fila(s)*</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={queues}
                                isMulti={true}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="queues"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="queues"
                            defaultValue=""
                            rules={{ required: 'Campo obrigatório!' }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="queues"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">
                            Tipo de Pesquisa*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={typesConfig}
                                isMulti={false}
                                value={props.value}
                                isClearable={true}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="type_survey"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="type_survey"
                            rules={{ required: 'Campo obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="type_survey"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mt-2">
                        <Col md="4">
                          <label className="form-control-label">
                            Mensagem inicial*
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            name="chat_message"
                            rules={{
                              required: 'Campo obrigatório'
                            }}
                            defaultValue="Poderia nos ajudar a melhorar o nosso atendimento? São apenas dois cliques."
                          />
                          <ErrorMessage
                            errors={errors}
                            name="chat_message"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">
                            Mensagem de encerramento*
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            name="closing_message"
                            rules={{ required: 'Campo obrigatório' }}
                            defaultValue="Obrigado! Sua opinião é muito importante para nós."
                          />
                          <ErrorMessage
                            errors={errors}
                            name="closing_message"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">
                            Áudio da mensagem de encerramento
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={audioClosingMessage}
                                isMulti={false}
                                value={props.value}
                                isClearable={true}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="audio_closing_message_id"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="audio_closing_message_id"
                            defaultValue=""
                            // rules={{ required: 'Campo obrigatório!' }}
                          />
                          {/* <ErrorMessage
                            errors={errors}
                            name="audio_closing_message_id"
                            render={({ message }) => errorFormMessage(message)}
                          /> */}
                        </Col>
                      </Row>
                      <Row>
                        <Col md="4" className="mt-2">
                          <label className="form-control-label">
                            Mensagem de resposta inválida
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            defaultValue="*Resposta inválida. Estamos encerrando esta pesquisa, obrigado!*"
                            name="incorrectOptionMessage"
                            rules={{
                              required: 'Campo obrigatório'
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="incorrectOptionMessage"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="4" className="mt-2">
                          <label className="form-control-label">
                            Quantidade máxima de tentativas
                          </label>

                          <Controller
                            as={<Input type="number" min={1} />}
                            control={control}
                            defaultValue={1}
                            name="quantityOfInvalidAttempts"
                            rules={{
                              required: 'Campo obrigatório'
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="quantityOfInvalidAttempts"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6" className="mt-2">
                          <label className="form-control-label">
                            Nome da pesquisa*
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            defaultValue=""
                            name="name"
                            rules={{
                              required: 'Campo obrigatório'
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md="3" className="mt-2">
                          <label className="form-control-label">
                            Enviar pesquisa para contato ativo
                          </label>
                          <ToggleSwitch
                            checked={sendToContatoAtivo}
                            id={'ativado'}
                            onChange={() =>
                              setSendToContatoAtivo(!sendToContatoAtivo)
                            }
                          />
                        </Col>
                        <Col sm="12" className="mt-2">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="checkbox"
                                checked={askForObservation}
                                onChange={() =>
                                  setAskForObservation(!askForObservation)
                                }
                              />
                              <span style={{ userSelect: 'none' }}>
                                Pedir avaliação descritiva (campo de texto
                                livre)
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr />

                      {typeControl === 'nps' && renderAnswer(10)}
                      {typeControl === 'satisfacao' && renderAnswer(5)}

                      <ReactButton btnColor="confirmation" type="submit">
                        {id ? 'Atualizar' : 'Criar'}
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConfigSurveyForm;
