import { individual_column_style } from 'assets/styles/table_custom_style';
import { individual_header_style } from 'assets/styles/table_custom_style';
import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

import ChangePenaltyModal from '../ChangePenaltyModal';
import FormHandleLogout from '../FormHandleLogout';
import FormPauseUnpause from '../FormPauseUnpause';
import QueueInfo from '../QueueInfo';
import SpyModal from '../SpyModal';
import { verifyRoles } from '../../../../../components/Authentication/VerifyRoles';

const LoggedInAgentsTable = ({
  loggedInAgents = [],
  pauses = [],
  handlePause,
  handleUnpause,
  handleLogout,
  handleChangePenalty
}) => {
  const [loading, setLoading] = useState(false);

  const handleQueueLogout = async (data) => {
    setLoading(true);
    await handleLogout(data);
    setLoading(false);
  };

  return (
    <>
      <ToolkitProvider
        data={loggedInAgents || []}
        keyField="login"
        columns={[
          {
            dataField: 'logout',
            text: 'Deslogar',
            key: 'logout',
            sort: true,
            style: { ...individual_column_style, minWidth: '20rem' },
            headerStyle: individual_header_style,
            // eslint-disable-next-line react/display-name
            formatter: (_, Row) => {
              return (
                <FormHandleLogout
                  agent={Row}
                  handleLogout={handleQueueLogout}
                  loading={loading}
                />
              );
            }
          },
          {
            dataField: 'user',
            text: 'Agente',
            key: 'user',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          {
            dataField: 'extension',
            text: 'Ramal',
            key: 'extension',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          verifyRoles(['MONITORING_CALLCENTER_PAUSE']) && {
            dataField: 'pause/unpause',
            text: 'Pausar/Despausar',
            sort: true,
            style: { ...individual_column_style, minWidth: '20rem' },
            headerStyle: individual_header_style,
            // eslint-disable-next-line react/display-name
            formatter: (_, Row) => {
              return (
                <FormPauseUnpause
                  agent={Row}
                  pauses={pauses}
                  handlePause={handlePause}
                  handleUnpause={handleUnpause}
                  loading={loading}
                />
              );
            }
          },
          {
            dataField: 'queueInfo',
            text: 'Filas',
            key: 'queueInfo',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style,
            // eslint-disable-next-line react/display-name
            formatter: (_, Row) => {
              return <QueueInfo agent={Row} />;
            }
          },
          verifyRoles(['MONITORING_CALLCENTER_PENALTY']) && {
            dataField: 'penalty',
            text: 'Altera Penalidade',
            key: 'penalty',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style,
            // eslint-disable-next-line react/display-name
            formatter: (_, Row) => {
              return (
                <ChangePenaltyModal
                  buttonLabel="Alterar"
                  agent={Row}
                  handleChangePenalty={handleChangePenalty}
                />
              );
            }
          },
          verifyRoles(['MONITORING_CALLCENTER']) && {
            dataField: 'spy',
            text: 'Espionar',
            key: 'spy',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style,
            // eslint-disable-next-line react/display-name
            formatter: (_, Row) => {
              return (
                <SpyModal
                  buttonLabel="Espionar"
                  loading={loading}
                  extensionToSpy={Row.extension}
                />
              );
            }
          }
          // },
          // {
          //   dataField: 'desconect',
          //   text: 'Desconectar',
          //   key: 'desconect',
          //   sort: true,
          //   style: individual_column_style,
          //   headerStyle: individual_header_style,
          //   // eslint-disable-next-line react/display-name
          //   formatter: (_, Row) => {
          //     return (
          //       <Button
          //         size="sm"
          //         color="warning"
          //         disabled={
          //           userLogin !== 'adm' || Row.login === 'adm' ? true : false
          //         }
          //         title="Desconectar usuário do sistema"
          //         onClick={() => disconnectUser(Row.login)}
          //       >
          //         <li className="fas fa-user-times"></li>
          //       </Button>
          //     );
          //   }
          // }
        ]}
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
              classes="align-items-center"
              style={{ paddingBottom: '3rem' }}
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};

export default LoggedInAgentsTable;
