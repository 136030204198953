import React from 'react';
import { CardHeader } from 'reactstrap';

import { StyledCard, List } from './style';

const QueueList = ({ data }) => {
  return (
    <StyledCard>
      <CardHeader className="p-0 mb-2">
        <h4 className="mb-2">Filas logadas</h4>
      </CardHeader>
      <List>
        {data.map(({ id, name }) => (
          <li key={id}>{name}</li>
        ))}
      </List>
    </StyledCard>
  );
};

export default QueueList;
