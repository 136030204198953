import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label
} from 'reactstrap';
import { toast } from 'react-toastify';

import DefaultModal from 'components/Modal/DefaultModal';
import ModalConfirmation from 'components/Modal/ConfirmationModal';
import Pagination from 'components/Pagination';
import ReactButton from 'components/Buttons/ReactButton';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../../../assets/styles/table_custom_style';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import ReactLoader from 'components/Loader';
import BroadcastListService from '../../../service';
import AddContactsModal from '../AddContactsModal';
// import ImportClientListModal from '../../../../Clients/list/components/ImportClientListModal';
import ImportContactsToBroadcastListModal from 'views/Clients/list/components/ImportContactsToBroadcastListModal';

const DEFAULT_PAGE_SIZE = 10;

const HandleContactsModal = ({
  isModalOpen,
  onModalToggle,
  broadcastListId,
  ...props
}) => {
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState([]);
  const [contacts, setContacts] = useState(null);
  const [isAddContactsModalOpen, setIsAddContactsModalOpen] = useState(false);
  const [idResource, setidResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [timeoutID, setTimeoutID] = useState(null);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isSeletingAll, setIsSeletingAll] = useState(false);

  const handleGetContactsFromBroadcastList = useCallback(
    (page, q = '') => {
      if (isModalOpen) {
        setLoading(true);

        const params = { page, q };

        BroadcastListService.getContactsFromBroadcastList(
          broadcastListId,
          params
        )
          .then((response) => {
            const registers = response.data.registers;
            const count = response.data.count;
            setContacts(registers);
            setQuantity(count);
          })
          .catch(() => {
            toast.error('Erro ao listar contatos da Campanha Ativa Whatsapp');
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
    [isModalOpen, broadcastListId]
  );

  const handleDeleteContactsFromBroadcastList = useCallback(
    (ids = []) => {
      BroadcastListService.removeContactsFromBroadcastList(broadcastListId, ids)
        .then(() => {
          const singular = 'Contato removido com sucesso';
          const plural = 'Contatos removidos com sucesso';
          toast.success(ids.length === 1 ? singular : plural);
          handleGetContactsFromBroadcastList(1);
        })
        .catch(() => {
          toast.error(
            'Ocorreu um erro ao remover os contatos da Campanha Ativa Whatsapp'
          );
        })
        .finally(() => {
          setOpenModalConfirmation(false);
          setSelectedRows([]);
        });
    },
    [handleGetContactsFromBroadcastList, broadcastListId]
  );

  useEffect(() => {
    handleGetContactsFromBroadcastList(1);
  }, [handleGetContactsFromBroadcastList]);

  useEffect(() => {
    if (selectedRows.length === quantity && quantity > 0) {
      setIsAllSelected(true);
    } else {
      setIsAllSelected(false);
    }
  }, [quantity, selectedRows.length]);

  const handleOnSelect = (row, isNotSelectedYet) => {
    if (isNotSelectedYet) {
      const newSelected = {
        id: row.id,
        nome: row.nome,
        id_whatsapp: row.id_whatsapp
      };
      setSelectedRows([...selectedRows, newSelected]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x.id !== row.id));
    }
  };

  const handleOnSelectAll = (isSomeSelected, rows) => {
    if (isSomeSelected) {
      const allRows = [...selectedRows, ...rows].map((r) => {
        return { id: r.id, nome: r.nome, id_whatsapp: r.id_whatsapp };
      });
      setSelectedRows(allRows);
    } else {
      const idsToRemove = rows.map((row) => row.id);
      setSelectedRows((currentSelected) => {
        return currentSelected.filter(({ id }) => !idsToRemove.includes(id));
      });
    }
  };

  const handlePageChange = (activePage) => {
    setActivePage(activePage);
    handleGetContactsFromBroadcastList(activePage);
  };

  const undoSelection = () => {
    setSelectedRows([]);
  };

  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setSelectedRows([]);
      setIsAllSelected(false);
      return;
    }

    setIsSeletingAll(true);

    BroadcastListService.getContactsFromBroadcastList(broadcastListId)
      .then((response) => {
        const { registers } = response.data;
        if (!registers) return;

        const registersSelectionDTO = registers.map((row) => ({
          id: row.id,
          nome: row.nome,
          id_whatsapp: row.id_whatsapp
        }));

        setIsAllSelected(true);
        setSelectedRows(registersSelectionDTO);
      })
      .catch(() => {
        toast.error('Erro ao selecionar todos os contatos');
      })
      .finally(() => {
        setIsSeletingAll(false);
      });
  };

  const handleOnSearch = (event) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const q = event.target.value;
    setTimeoutID(
      setTimeout(() => {
        handleGetContactsFromBroadcastList(1, q);
      }, 1000)
    );
  };

  return (
    <>
      <DefaultModal
        show={isModalOpen}
        onModalToggle={onModalToggle}
        title="Gerenciar contatos da campanha"
        showCloseButton={false}
        modalContainerClasses="modal-xl"
        {...props}
      >
        <>
          <Row>
            <Col className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column justify-content-between">
                <span>
                  <strong>
                    {selectedRows.length || '0'}
                    {`/${quantity} `}
                  </strong>
                  {selectedRows.length.length === 1 ? 'contato ' : 'contatos '}
                  selecionados
                </span>
                <div className="small">
                  <Button
                    outline={true}
                    size="sm"
                    color=""
                    disabled={selectedRows.length < 1}
                    onClick={() => {
                      if (!selectedRows || !selectedRows.length) return;
                      const ids = selectedRows.map((row) => row.id);
                      controlDelete(ids);
                    }}
                  >
                    <li className="fas fa-trash"></li> Excluir Selecionados
                  </Button>
                </div>
                <div className="small">
                  <Button
                    outline={true}
                    size="sm"
                    color=""
                    disabled={selectedRows.length < 1}
                    onClick={undoSelection}
                  >
                    <li className="fas fa-undo"></li> Desfazer seleção
                  </Button>
                </div>
              </div>
              <div>
                <ImportContactsToBroadcastListModal
                  buttonOpenModal={({ onClick }) => (
                    <ReactButton
                      btnColor="confirmation"
                      type="submit"
                      size="sm"
                      onClick={onClick}
                    >
                      Importar Lista
                    </ReactButton>
                  )}
                  importToBroadcastListService={(data) =>
                    BroadcastListService.importList(
                      'cliente',
                      data,
                      broadcastListId
                    )
                  }
                  callback={() => handleGetContactsFromBroadcastList(1)}
                />
                <ReactButton
                  btnColor="confirmation"
                  type="submit"
                  size="sm"
                  onClick={() => setIsAddContactsModalOpen(true)}
                >
                  + Adicionar Contato(s)
                </ReactButton>
              </div>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  size="md"
                  placeholder="Buscar por nome ou telefone"
                  type="text"
                  defaultValue=""
                  onChange={handleOnSearch}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row className="mt-3 pl-3 small">
            <Col>
              <FormGroup check>
                <Label
                  style={{
                    cursor: isSeletingAll || quantity < 1 ? 'auto' : 'pointer'
                  }}
                  check
                >
                  <Input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={handleSelectAll}
                    disabled={isSeletingAll || quantity < 1}
                  />
                  <span
                    style={{ userSelect: 'none' }}
                  >{`Selecionar todos (${quantity})`}</span>
                </Label>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <ToolkitProvider
                data={contacts || []}
                keyField="id"
                loading={loading}
                columns={[
                  {
                    dataField: 'nome',
                    text: 'Nome',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'id_zenvia_sms',
                    text: 'Telefone',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'delete',
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    text: 'deletar',
                    formatter: (_, row) => {
                      return (
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => controlDelete([row.id])}
                        >
                          <li className="fas fa-trash"></li>
                        </Button>
                      );
                    }
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    {loading ? (
                      <Row className="justify-content-md-center">
                        <ReactLoader />
                      </Row>
                    ) : (
                      <BootstrapTable
                        selectRow={{
                          mode: 'checkbox',
                          selected: selectedRows.map((r) => r.id),
                          onSelect: handleOnSelect,
                          onSelectAll: handleOnSelectAll,
                          selectColumnStyle: individual_header_style
                        }}
                        {...props.baseProps}
                        bootstrap4={true}
                        bordered={false}
                        classes="align-items-center"
                      />
                    )}
                  </div>
                )}
              </ToolkitProvider>
              {!!quantity && quantity > DEFAULT_PAGE_SIZE && (
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col style={{ maxWidth: '8rem' }}>
              <ReactButton
                btnColor="confirmation"
                type="submit"
                size="sm"
                onClick={() => onModalToggle(false)}
                block
              >
                OK
              </ReactButton>
            </Col>
          </Row>
        </>
      </DefaultModal>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={handleDeleteContactsFromBroadcastList}
        message={'Tem certeza que deseja excluir este(s) contato(s)?'}
      />
      <AddContactsModal
        isModalOpen={isAddContactsModalOpen}
        onModalToggle={setIsAddContactsModalOpen}
        unmountOnClose={true}
        broadcastListId={broadcastListId}
        reloadList={handleGetContactsFromBroadcastList}
      />
    </>
  );
};

export default HandleContactsModal;
