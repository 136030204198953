import { ErrorMessage } from '@hookform/error-message';
import ReactButton from 'components/Buttons/ReactButton';
import TelephoneMask from 'components/Inputs/TelephoneMask';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, Form, Input, Row } from 'reactstrap';
import CustomFieldsInputsForm from 'views/CRM/shared/components/CustomFieldsInputsForm';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { removeMaskSpecialCharacters } from 'views/CRM/shared/utils/phoneNumberHelpers';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { useHistory } from 'react-router-dom';

import AccountService from '../../../../Account/services/accountService';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';

const AddAccountToContactForm = ({
  isOpen,
  setIsOpen,
  recordTypeId,
  onAddedAccount
}) => {
  const { control, errors, setValue, handleSubmit, watch } = useForm();
  const history = useHistory();

  const { customFields, getCustomFields } = useEntityCustomFieldsByRecordType(
    entitiesName.ACCOUNT,
    recordTypeId
  );

  useEffect(() => {
    if (isOpen) getCustomFields();
  }, [isOpen, getCustomFields]);

  const cancelAction = () => {
    history.push('/admin/crm/contact', { recordTypeId });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const onSubmit = (data) => {
    data.phone = removeMaskSpecialCharacters(data.phone);

    const dataDTO = formatCustomFieldsToSubmit(data, customFields);

    AccountService.create(recordTypeId, dataDTO)
      .then((response) => {
        toast.success('Conta criada com successo');
        onAddedAccount(response.data.data);
        setIsOpen(false);
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message || 'Erro ao criar conta');
      });
  };

  return (
    <Form
      className="needs-validation"
      onSubmit={(event) => {
        event.stopPropagation();
        handleSubmit(onSubmit)(event);
      }}
      id="modalForm"
    >
      <Row>
        <Col sm="6" className="mb-3">
          <label className="form-control-label text-capitalize">Nome*</label>
          <Controller
            render={(props) => (
              <Input
                value={props.value}
                onChange={props.onChange}
                type="text"
                placeholder="Nome"
              />
            )}
            control={control}
            defaultValue=""
            rules={{ required: 'Campo obrigatório' }}
            name="name"
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col sm="6" className="mb-3">
          <label className="form-control-label text-capitalize">Telefone</label>
          <Controller
            render={(props) => (
              <TelephoneMask
                valueController={setValue}
                fieldName="phone"
                value={props.value}
                mask={[
                  '(',
                  /[\d]/,
                  /[\d]/,
                  ')',
                  ' ',
                  /[\d]/,
                  /[\d]/,
                  /[\d]/,
                  /[\d]/,
                  /[\d]/,
                  '-',
                  /[\d]/,
                  /[\d]/,
                  /[\d]/,
                  /[\d]/
                ]}
                placeholder="(99) 91234-5678"
              />
            )}
            control={control}
            defaultValue=""
            rules={{
              validate: (value) => {
                if (!value) return true;
                const telephoneWithoutSpecialCharacters =
                  removeMaskSpecialCharacters(value);
                const validTelephoneRegExp = new RegExp('^[0-9]{11}$');
                return (
                  validTelephoneRegExp.test(
                    telephoneWithoutSpecialCharacters
                  ) || 'Número de telefone inválido'
                );
              }
            }}
            name="phone"
          />
          <ErrorMessage
            errors={errors}
            name="phone"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
      <Row className="mb-1">
        <CustomFieldsInputsForm
          fields={customFields}
          control={control}
          errors={errors}
          setValue={setValue}
          Controller={Controller}
          watch={watch}
        />
      </Row>

      <ReactButton btnColor="confirmation" type="submit" form="modalForm">
        Salvar
      </ReactButton>

      <ReactButton
        btnColor="cancelation"
        onClick={() => {
          return cancelAction();
        }}
      >
        Cancelar
      </ReactButton>
    </Form>
  );
};

export default AddAccountToContactForm;
