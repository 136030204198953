import React from 'react';
import { Col, Container, Row, Card, CardBody } from 'reactstrap';
import ReportCardsContainer from '../ReportCardsContainer';
import AgentsCardsContainer from '../AgentsCardsContainer';

const MainContainer = ({ data }) => {
  return (
    <div style={{ position: 'relative', overflowX: 'hidden' }}>
      <Container fluid style={{ padding: '0', marginTop: '-1rem' }}>
        <Row style={{ padding: '0' }}>
          <div className="col" style={{ padding: '0' }}>
            <Card style={{ padding: '0' }}>
              <CardBody>
                <Row>
                  <Col md="4">
                    <ReportCardsContainer data={data} />
                  </Col>
                  <Col md="8">
                    <AgentsCardsContainer
                      dialerName={data.NewdialerName}
                      agents={data.agents}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default MainContainer;
