import ServiceCRUD from '../../../services/ServiceCRUDSCALE';
import { apiScale as http } from '../../../http/';

class CalendarService extends ServiceCRUD {
  constructor() {
    super('/api/users');
  }
  getCalendarDayOff = (login, startDate, finalDate) => {
    return http.get(`${this.path}/${login}/days-off`, {
      params: { startDate: startDate, finalDate: finalDate }
    });
  };
  getCalendarDayOffWithoutParams = (login) => {
    return http.get(`${this.path}/${login}/days-off`);
  };
}

export default new CalendarService();
