import styled from 'styled-components';

export const Print = styled.div`
  @media print {
    .g4Img {
      position: absolute;
      top: 0;
      right: 0;
      height: 8rem;
      margin-right: 2.5rem;
    }

    .noShow {
      display: none;
    }
    .printEmail {
      max-height: none;
      height: auto;
    }
  }
`;

export const Ul = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0.5rem;
  column-gap: 2rem;
  list-style-type: none;
`;

export const Container = styled.div`
  p {
    font-family: Open Sans, sans-serif;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #525f7f;
  }
`;
