import { useCallback, useState } from 'react';

import QueueMonitoringService from '../services/queueMonitoringService';

export const useBFFQueueMonitoring = (queuesToMonit) => {
  const [queueMonitoringData, setQueueMonitoringData] = useState(null);
  const [queueMonitoringDataByQueue, setQueueMonitoringDataByQueue] =
    useState(null);

  const getQueueMonitoringData = useCallback(async () => {
    try {
      const response = await QueueMonitoringService.getQueueMonitoringData({
        queues: queuesToMonit
      });
      setQueueMonitoringData(response.data.data);
    } catch (error) {
      setQueueMonitoringData(null);
    }
  }, [queuesToMonit]);

  const getQueueMonitoringDataByQueue = useCallback(async () => {
    try {
      const response =
        await QueueMonitoringService.getQueueMonitoringDataByQueue({
          queues: queuesToMonit
        });
      setQueueMonitoringDataByQueue(response.data.data);
    } catch (error) {
      setQueueMonitoringDataByQueue(null);
    }
  }, [queuesToMonit]);

  return {
    queueMonitoringData,
    getQueueMonitoringData,
    getQueueMonitoringDataByQueue,
    queueMonitoringDataByQueue
  };
};
