import React, { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'reactstrap';
import { FiPower } from 'react-icons/fi';
import FlexSelect from '../../../../../components/Inputs/FlexSelect';
import { toast } from 'react-toastify';

const FormHandleLogout = ({ agent, handleLogout, loading = false }) => {
  const { control, handleSubmit, setValue } = useForm();
  const formRef = useRef(null);

  const handleQueueLogout = async (data) => {
    const { queueIds } = data;

    if (!queueIds || !queueIds.length) {
      return toast.error('Selecione pelo menos uma fila');
    }

    const { extension, agentCode } = agent;

    await handleLogout({
      extension,
      agentCode,
      queueIds
    });

    setValue('queueIds', '');
  };

  return (
    <Form
      onSubmit={handleSubmit(handleQueueLogout)}
      className="d-flex justify-content-around"
      inline
    >
      <Controller
        render={(props) => (
          <div style={{ flex: '0.9' }}>
            <FlexSelect
              isClearable={true}
              dataOptions={agent.queues}
              isMulti={true}
              closeMenuOnSelect={false}
              value={props.value}
              valueController={setValue}
              fieldName="queueIds"
              labelName="name"
              valueName="id"
              minHeight="1rem"
              isDisabled={loading || agent.paused}
            />
          </div>
        )}
        control={control}
        name="queueIds"
        defaultValue=""
        className="mt-2"
        style={{ flex: '0.8' }}
      />

      <button type="submit" hidden ref={formRef}></button>
      <FiPower
        size="1.2rem"
        color="#CC0000"
        opacity={agent.paused ? 0.5 : 1}
        cursor={agent.paused ? 'auto' : 'pointer'}
        onClick={() => {
          if (!agent.paused) formRef.current.click();
        }}
      />
    </Form>
  );
};

export default FormHandleLogout;
