import ServiceCRUD from '../../../services/ServiceCRUD';
import { apiCase as http } from '../../../http';
class ProtocolSchedulingService extends ServiceCRUD {
  constructor() {
    super('protocol-schedules');
  }

  async createProtocolSchedule(data) {
    return http.post(`/protocol-schedules/create`, data);
  }

  async cancelProtocolSchedule(schedulingId) {
    return http.delete(`/protocol-schedules/${schedulingId}`);
  }

  async getProtocolScheduleById(schedulingId) {
    return http.get(`/protocol-schedules/${schedulingId}`);
  }

  async rescheduleProtocolScheduling(schedulingId, data) {
    return http.put(`/protocol-schedules/${schedulingId}`, data);
  }
}

export default new ProtocolSchedulingService();
