import React, { useState, useEffect } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Timeline from 'components/Conversation/ConversationDetails/components/Timeline';
import {
  individual_column_style,
  individual_header_style
} from 'assets/styles/table_custom_style';
import {
  Hr,
  Vr,
  TimelineDot,
  TimelineContainer,
  TimelineRow
} from 'components/Conversation/ConversationDetails/components/styled';
import ClientBalloon from 'components/Conversation/ChatBallons/Client';
import UserBalloon from 'components/Conversation/ChatBallons/User';
import { SituationLine, SituationDiv } from './components/styleds';
import {
  Container,
  Row,
  Card,
  Col,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button
} from 'reactstrap';
import Modal from 'components/Modal/NotificationModal';
import ConversaService from '../service';
import ReactLoader from 'components/Loader';

const Conversation = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [conversation, setConversation] = useState({});
  const { id } = props.match.params;

  const cancelAction = () => {
    history.push('/admin');
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      ConversaService.getClosed(id)
        .then((res) => {
          const getConversation = res.data.conversa;
          setConversation({ ...getConversation });
          setLoading(false);
        })
        .catch((err) => {
          //const { data } = err.response;
          toast.error(
            err.response.data.message || 'Erro ao carregar Conversa!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
          setLoading(false);
        });
    }
  }, [id]);

  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Conversa</h3>
                </CardHeader>
                <CardBody>
                  <Row className="justify-content-center">
                    <Col md="7">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : conversation._id ? (
                        <Card>
                          <CardBody>
                            <CardTitle tag="h3">
                              Cliente:{' '}
                              {conversation.cliente.nome || 'Não informado'}
                            </CardTitle>
                            <CardSubtitle
                              tag="h4"
                              className="mb-3 mt-2 text-muted"
                            >
                              <Row>
                                <Col md="6" title="CPF do cliente">
                                  <i
                                    className="fas fa-id-card"
                                    style={{ color: '#5bc0de' }}
                                  ></i>{' '}
                                  -{' '}
                                  {conversation.cliente.cpf || 'Não informado'}
                                </Col>
                                <Col md="6" title="Canal de atendimento">
                                  <i
                                    className="fas fa-users"
                                    style={{ color: '#0275d8' }}
                                  ></i>{' '}
                                  - {conversation.canal || 'Não reconhecido'}
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6" title="Telefone do cliente">
                                  <i
                                    className="fas fa-phone"
                                    style={{ color: '#5cb85c' }}
                                  ></i>{' '}
                                  -{' '}
                                  {conversation.cliente.telefone ||
                                    'Não informado'}
                                </Col>
                                <Col md="6" title="Situação da conversa">
                                  <i
                                    className="far fa-chart-bar"
                                    style={{ color: '#d9534f' }}
                                  ></i>{' '}
                                  -{' '}
                                  {conversation.situacao === 'abandonada' &&
                                    'Abandonada'}
                                  {conversation.situacao === 'encerrada' &&
                                    'Encerrada'}
                                  {conversation.situacao === 'em_atendimento' &&
                                    'Em atendimento'}
                                  {conversation.situacao === 'nao_atendida' &&
                                    'Não atendida'}
                                  {conversation.situacao === 'transferida' &&
                                    'Transferida'}
                                </Col>
                              </Row>
                              <Row>
                                <Col md="12" title="Email do cliente">
                                  <i
                                    className="fas fa-envelope-open-text"
                                    style={{ color: '#f0ad4e' }}
                                  ></i>{' '}
                                  -{' '}
                                  {conversation.cliente.email ||
                                    'Não informado'}
                                </Col>
                              </Row>
                            </CardSubtitle>

                            <Row className="mb-3 mt-3">
                              <Col md="12">
                                <Modal
                                  name="Timeline do atendimento"
                                  buttonName="Timeline"
                                  useIconButton={false}
                                  color="success"
                                  tooltipTitle="Clique para ver a timeline do atendimento"
                                >
                                  {conversation.timeline && (
                                    <Timeline
                                      timelineData={conversation.timeline}
                                    />
                                  )}
                                </Modal>
                                {conversation.resumoBot && (
                                  <>
                                    {conversation.resumoBot.length > 0 && (
                                      <Modal
                                        name="Resumo Bot do Atendimento"
                                        buttonName="Resumo Bot"
                                        useIconButton={false}
                                        color="info"
                                        tooltipTitle="Clique para ver o resumo do bot no atendimento"
                                      >
                                        <Row className="mt-3">
                                          <div className="col">
                                            <div className="card-wrapper">
                                              <Card>
                                                <ToolkitProvider
                                                  data={conversation.resumoBot}
                                                  keyField="id"
                                                  columns={[
                                                    {
                                                      dataField: 'id',
                                                      text: 'Campo',
                                                      sort: true,
                                                      style: individual_column_style,
                                                      headerStyle: individual_header_style
                                                    },
                                                    {
                                                      dataField: 'value',
                                                      text: 'Descrição',
                                                      sort: true,
                                                      style: individual_column_style,
                                                      headerStyle: individual_header_style
                                                    }
                                                  ]}
                                                >
                                                  {(props) => (
                                                    <div className="py-4 table-responsive">
                                                      <BootstrapTable
                                                        {...props.baseProps}
                                                        bootstrap4={true}
                                                        bordered={false}
                                                        classes="align-items-center"
                                                      />
                                                    </div>
                                                  )}
                                                </ToolkitProvider>
                                              </Card>
                                            </div>
                                          </div>
                                        </Row>
                                      </Modal>
                                    )}
                                  </>
                                )}
                              </Col>
                            </Row>
                            <Hr />

                            <Row className="mb-3">
                              <Col md="1" style={{ alignSelf: 'center' }}>
                                <i
                                  className="fas fa-info-circle"
                                  style={{ color: '#5bc0de' }}
                                ></i>
                              </Col>
                              <Col md="11" className="mt-3">
                                <p>
                                  O atendimento foi iniciado através do canal{' '}
                                  <strong>{conversation.canal || ''}</strong> e
                                  fila{' '}
                                  <strong>{conversation.fila || ''}</strong>
                                </p>
                              </Col>
                            </Row>
                            {conversation.observacao && (
                              <Row className="mt--4">
                                <Col md="1" style={{ alignSelf: 'center' }}>
                                  <i
                                    className="fas fa-info-circle"
                                    style={{ color: '#5bc0de' }}
                                  ></i>
                                </Col>
                                <Col md="11" className="mt-3">
                                  <p>Observação: {conversation.observacao}</p>
                                </Col>
                              </Row>
                            )}
                            <Hr className="mb-3 mt--1" />

                            <SituationDiv>
                              <SituationLine>
                                <Row className="mb-3">
                                  <Col>
                                    <Vr />
                                    <TimelineContainer>
                                      <TimelineDot color="#5bc0de">
                                        <i
                                          className="fas fa-sign-in-alt"
                                          style={{ marginTop: '10px' }}
                                        />
                                      </TimelineDot>
                                      <TimelineRow>
                                        <CardTitle tag="h5">
                                          ENTROU NA FILA
                                        </CardTitle>
                                        {conversation.hora_criacao && (
                                          <CardSubtitle
                                            tag="h5"
                                            className="mt--3 text-muted"
                                          >
                                            {`${new Date(
                                              conversation.hora_criacao
                                            ).toLocaleTimeString(
                                              'pt-br'
                                            )} - ${new Date(
                                              conversation.hora_criacao
                                            ).toLocaleDateString('pt-br')}`}
                                          </CardSubtitle>
                                        )}
                                      </TimelineRow>
                                    </TimelineContainer>
                                    {conversation.situacao === 'abandonada' &&
                                      conversation.atendida === false && (
                                        <TimelineContainer>
                                          <TimelineDot color="#d9534f">
                                            <i
                                              className="fas fa-times"
                                              style={{ marginTop: '10px' }}
                                            />
                                          </TimelineDot>
                                          <TimelineRow>
                                            <CardTitle tag="h5">
                                              ABANDONADA
                                            </CardTitle>
                                            {conversation.hora_fim_conversa && (
                                              <CardSubtitle
                                                tag="h5"
                                                className="mt--3 text-muted"
                                              >
                                                {`${new Date(
                                                  conversation.hora_fim_conversa
                                                ).toLocaleTimeString(
                                                  'pt-br'
                                                )} - ${new Date(
                                                  conversation.hora_fim_conversa
                                                ).toLocaleDateString('pt-br')}`}
                                              </CardSubtitle>
                                            )}
                                            <h5
                                              style={{ color: '#d9534f' }}
                                              className="mb--1 mt-1"
                                            >
                                              {conversation.encerrada_por || ''}
                                            </h5>
                                            <h5>
                                              {conversation.observacao || ''}
                                            </h5>
                                          </TimelineRow>
                                        </TimelineContainer>
                                      )}

                                    {(conversation.atendida === true ||
                                      conversation.atendimentoBot === true) && (
                                      <TimelineContainer>
                                        <TimelineDot color="#5cb85c">
                                          <i
                                            className="far fa-bell"
                                            style={{ marginTop: '10px' }}
                                          />
                                        </TimelineDot>
                                        <TimelineRow>
                                          <CardTitle tag="h5">
                                            ATENDIDA
                                          </CardTitle>
                                          {conversation.hora_do_atendimento && (
                                            <CardSubtitle
                                              tag="h5"
                                              className="mt--3 text-muted"
                                            >
                                              {`${new Date(
                                                conversation.hora_do_atendimento
                                              ).toLocaleTimeString(
                                                'pt-br'
                                              )} - ${new Date(
                                                conversation.hora_do_atendimento
                                              ).toLocaleDateString('pt-br')}`}
                                            </CardSubtitle>
                                          )}
                                          {conversation.atendente && (
                                            <h5 style={{ color: '#5cb85c' }}>
                                              {conversation.atendente.name ||
                                                ''}
                                            </h5>
                                          )}
                                        </TimelineRow>
                                      </TimelineContainer>
                                    )}
                                    {conversation.situacao === 'encerrada' && (
                                      <TimelineContainer>
                                        <TimelineDot color="#d9534f">
                                          <i
                                            className="fas fa-check"
                                            style={{ marginTop: '10px' }}
                                          />
                                        </TimelineDot>
                                        <TimelineRow>
                                          <CardTitle tag="h5">
                                            ENCERRADA
                                          </CardTitle>
                                          {conversation.hora_fim_conversa && (
                                            <CardSubtitle
                                              tag="h5"
                                              className="mt--3 text-muted"
                                            >
                                              {`${new Date(
                                                conversation.hora_fim_conversa
                                              ).toLocaleTimeString(
                                                'pt-br'
                                              )} - ${new Date(
                                                conversation.hora_fim_conversa
                                              ).toLocaleDateString('pt-br')}`}
                                            </CardSubtitle>
                                          )}
                                          <h5 className="mb--1 mt-1 text-muted">
                                            {conversation.encerrada_por || ''}
                                          </h5>
                                          {conversation.atendente && (
                                            <h5 style={{ color: '#d9534f' }}>
                                              {conversation.atendente.name ||
                                                ''}
                                            </h5>
                                          )}
                                        </TimelineRow>
                                      </TimelineContainer>
                                    )}
                                  </Col>
                                </Row>
                              </SituationLine>
                            </SituationDiv>

                            <Hr className="mb-3" />

                            {conversation.mensagens && (
                              <>
                                {conversation.mensagens.map((el, index) => {
                                  return el.cliente_ou_atendente ===
                                    'atendente' ? (
                                    <UserBalloon
                                      key={index}
                                      name={el.escrita_por}
                                      type={el.response_type}
                                      message={el.texto}
                                      title={el.title}
                                      time={el.hora_da_mensagem}
                                      options={el.options}
                                      source={el.source}
                                      description={el.description}
                                    />
                                  ) : (
                                    <ClientBalloon
                                      key={index}
                                      name={el.escrita_por}
                                      type={el.response_type}
                                      message={el.texto}
                                      title={el.title}
                                      time={el.hora_da_mensagem}
                                      options={el.options}
                                      source={el.source}
                                      description={el.description}
                                    />
                                  );
                                })}
                              </>
                            )}
                          </CardBody>
                        </Card>
                      ) : (
                        <> Conversa não encontrada... </>
                      )}
                    </Col>
                  </Row>
                  <hr />
                  <Button color="danger" onClick={() => cancelAction()}>
                    Cancelar
                  </Button>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Conversation;
