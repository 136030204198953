import { useEffect, useState } from 'react';
import SectorService from '../../../Sector/service';

export function useGetSectors() {
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    SectorService.getAll()
      .then((response) => {
        setSectors(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);

  return {
    sectors,
    setSectors
  };
}
