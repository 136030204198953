import DefaultModal from 'components/Modal/DefaultModal';
import React, { useEffect, useState } from 'react';
import ProtocolService from '../../../../views/Protocol/service';
import CommentsList from 'views/Protocol/updateForm/components/Comments/CommentsList';
import CommentsForm from 'views/Protocol/updateForm/components/Comments/CommentsForm';
import { toast } from 'react-toastify';

const ListModalProtocols = ({
  show,
  setIsModalComents,
  id,
  isProtocolScreen = false
}) => {
  const [listComments, setListComments] = useState([]);

  const getCommnents = () => {
    if (id) {
      ProtocolService.getAllComments(id)
        .then((res) => {
          setListComments(res.data.content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const deleteComment = (idComment) => {
    if (!window.confirm('Apagar comentário?')) {
      return;
    } else {
      ProtocolService.deleteComment(id, idComment)
        .then((res) => {
          if (res.data.status === 'deleted'.toUpperCase()) {
            const message = 'Comentário excluido com sucesso';
            toast.success(message, {
              autoClose: 3000,
              closeOnClick: true
            });
            getCommnents();
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );
  useEffect(getCommnents, [id]);

  const onSubmit = async (data) => {
    try {
      const dataFormaSubmit = {
        description: data.description
      };
      await ProtocolService.createCommentProtocol(id, dataFormaSubmit);
      const message = 'Comentário adicionado com sucesso';
      toast.success(message, {
        autoClose: 3000,
        closeOnClick: true
      });
      getCommnents();
    } catch (err) {
      toast.error('Erro ao adicionar comentário');
    }
  };

  return (
    <DefaultModal
      show={show}
      onModalToggle={setIsModalComents}
      showCloseButton={false}
      modalContainerClasses="modal-xl"
    >
      <CommentsList
        listComments={listComments}
        isProtocolScreen={isProtocolScreen}
        deleteComent={deleteComment}
      ></CommentsList>
      <CommentsForm
        comments={[1]}
        onSubmit={onSubmit}
        errorFormMessage={errorFormMessage}
        IAlreadySendPublicEmail={listComments.find((comment) => comment.public)}
      ></CommentsForm>
    </DefaultModal>
  );
};

export default ListModalProtocols;
