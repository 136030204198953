import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import { useForm, Controller } from 'react-hook-form';
import { Row, Form, Col, Input, Button } from 'reactstrap';
import SubReasonService from '../../../subReason/service';
import Select from 'react-select/async';
import ProtocolFieldsService from '../../../fieldConfigs/service/protocolFields';
import Buttons from '../../components/Buttons';
import { functions } from '../../assets/functions';
import ReactLoader from 'components/Loader';
import {
  ContainerForm,
  ContentTitleSection,
  ContentItemFormSelect,
  ContentItemFormTitle,
  SpaceItens,
  Hr,
  ContainerProtocolGeneration,
  ItemProtocolGeneration
} from '../../styled';
import ConfigChatService from '../../../ChatConfiguration/service';
import ProtocolConfigService from '../../service';
import { ProtocolNumberConfig } from '../ProtocolNumberConfig';
import { AllowsRearrangeInputsModal } from './components/AllowsRearrangeInputsModal';
import { ErrorMessage } from '@hookform/error-message';
import TelephoneMask from 'components/Inputs/TelephoneMask';
import { useListReasonByRecordType } from 'views/reason/list/hooks/useListReasonByRecordType';
import { useListStatusByRecordType } from 'views/status/list/hooks/useListStatusByRecordType';
import { useUsers } from '../../../Report/Protocol/hooks/useUsers';
import { custom_select } from 'assets/styles/multiple_select';
import { useSearchAccounts } from 'views/CRM/Account/hooks/useSearchAccounts';
import contactService from 'views/CRM/Contact/services/contactService';
import FormTooltip from 'components/Inputs/FormTooltip';

const { formatDataForDisplay, formatDataForSubmit } = functions();

const ConfigurationArea = ({ recordTypeId }) => {
  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [protocolNumber, setProtocolNumber] = useState(['INC']);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const [protocolNumberInfo, setProtocolNumberUInfo] = useState(undefined);

  const [rearrangeInputsProtocolFormOpen, setRearrangeInputsProtocolFormOpen] =
    useState(false);
  const [subreasons, setSubreasons] = useState([]);
  const { userList } = useUsers(false);

  const [controlValidSelectAccount, setControlValidSelectAccount] =
    useState(false);

  const { filterAccounts, isSearching: isSearchingAccounts } =
    useSearchAccounts();

  const [loadingSubreasons, setLoadingSubreasons] = useState(false);

  const { status, getStatusByRecordType } =
    useListStatusByRecordType(recordTypeId);

  const { reasons, getReasonsByRecordType } =
    useListReasonByRecordType(recordTypeId);

  useEffect(() => {
    getReasonsByRecordType();
  }, [getReasonsByRecordType]);

  useEffect(() => {
    getStatusByRecordType();
  }, [getStatusByRecordType]);

  const { reset, control, handleSubmit, watch, setValue, errors } = useForm();

  const getSubreasonsByReasonId = (id) => {
    setLoadingSubreasons(true);

    if (id) {
      SubReasonService.getByReasonId(id)
        .then((response) => {
          const subReasons = response.data.content;
          const sortedSubReasons = subReasons.sort(function (a, b) {
            let nameA = a.name.toUpperCase();
            let nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          setSubreasons(sortedSubReasons);
        })
        .catch((err) => {
          const { data } = err.response;
          toast.error(data.message, {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .finally(() => {
          setLoadingSubreasons(false);
        });
    } else {
      setSubreasons([]);
    }
  };

  const handleGetSubReasons = useCallback((event) => {
    let value = '';
    if (event && event.target) {
      value = event.target.value;
    } else {
      value = event;
    }

    getSubreasonsByReasonId(value);
  }, []);

  const controlTitle = watch('showTitle');
  const controlRequiredTitle = watch('requiredTitle');
  const controlOnlyToShowOnEditTitle = watch('isOnlyToShowOnEditTitle');

  const controlClient = watch('showClient');
  const controlRequiredClient = watch('requiredClient');
  const controlOnlyToShowOnEditClient = watch('isOnlyToShowOnEditClient');

  const controlTelephone = watch('showTelephone');
  const controlRequiredTelephone = watch('requiredTelephone');
  const controlOnlyToShowOnEditTelephone = watch('isOnlyToShowOnEditTelephone');

  const controlDescription = watch('showDescription');
  const controlRequiredDescription = watch('requiredDescription');
  const controlOnlyToShowOnEditDescription = watch(
    'isOnlyToShowOnEditDescription'
  );

  const controlReason = watch('showReason');
  const controlRequiredReason = watch('requiredReason');
  const controlOnlyToShowOnEditReason = watch('isOnlyToShowOnEditReason');

  const controlSubReason = watch('showSubReason');
  const controlRequiredSubReason = watch('requiredSubReason');
  const controlOnlyToShowOnEditSubReason = watch('isOnlyToShowOnEditSubReason');

  const controlServiceChannel = watch('showServiceChannel');
  const controlRequiredServiceChannel = watch('requiredShowServiceChannel');
  const controlOnlyToShowOnEditServiceChannel = watch(
    'isOnlyToShowOnEditServiceChannel'
  );

  const controlStatus = watch('showCurrentStatus');
  const controlRequiredStatus = watch('requiredShowCurrentStatus');
  const controlOnlyToShowOnEditStatus = watch(
    'isOnlyToShowOnEditCurrentStatus'
  );

  const controlResponsible = watch('showCurrentResponsible');
  const controlRequiredResponsible = watch('requiredShowCurrentResponsible');
  const controlOnlyToShowOnEditResponsible = watch(
    'isOnlyToShowOnEditCurrentResponsible'
  );

  const controlEmail = watch('showEmail');
  const controlRequiredEmail = watch('requiredEmail');
  const controlOnlyToShowOnEditEmail = watch('isOnlyToShowOnEditEmail');

  const controlAccount = watch('showAccount');
  const controlRequiredAccount = watch('requiredAccount');
  const controlOnlyToShowOnEditAccount = watch('isOnlyToShowOnEditAccount');

  const controlFiles = watch('showFiles');
  const controlRequiredFiles = watch('requiredFiles');

  const createProtocolOnChatEnd = watch('shouldCreateProtocolWhenReceiveEmail');

  const hasTabulacaoEnabled = watch('timeToFillProtocolForm.enabled');

  const valueMotive = watch('defaultValueMotive');

  useEffect(() => {
    if (!valueMotive || !controlRequiredReason) {
      setSubreasons([]);
      setValue('defaultValueSubmotive', '');
      return;
    }

    getSubreasonsByReasonId(valueMotive);
  }, [valueMotive, controlRequiredReason]);

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const allControls = formatDataForSubmit({
    showTitle: controlTitle,
    isOnlyToShowOnEditTitle: controlOnlyToShowOnEditTitle,
    showClient: controlClient,
    isOnlyToShowOnEditClient: controlOnlyToShowOnEditClient,
    showTelephone: controlTelephone,
    isOnlyToShowOnEditTelephone: controlOnlyToShowOnEditTelephone,
    showDescription: controlDescription,
    isOnlyToShowOnEditDescription: controlOnlyToShowOnEditDescription,
    showReason: controlReason,
    isOnlyToShowOnEditReason: controlOnlyToShowOnEditReason,
    showSubReason: controlSubReason,
    isOnlyToShowOnEditSubReason: controlOnlyToShowOnEditSubReason,
    showServiceChannel: controlServiceChannel,
    isOnlyToShowOnEditServiceChannel: controlOnlyToShowOnEditServiceChannel,
    showCurrentStatus: controlStatus,
    isOnlyToShowOnEditCurrentStatus: controlOnlyToShowOnEditStatus,
    showCurrentResponsible: controlResponsible,
    isOnlyToShowOnEditCurrentResponsible: controlOnlyToShowOnEditResponsible,
    showEmail: controlEmail,
    isOnlyToShowOnEditEmail: controlOnlyToShowOnEditEmail,
    showAccount: controlAccount,
    isOnlyToShowOnEditAccount: controlOnlyToShowOnEditAccount,
    showFiles: controlFiles,
    requiredFiles: controlRequiredFiles
  });

  const getConfig = useCallback(async () => {
    setLoading(true);
    try {
      const responseDefaultFieldsConfig =
        await ProtocolFieldsService.getDefaultFieldsDisplaySettings(
          recordTypeId
        );
      const responseConfigProtocolChat =
        await ConfigChatService.getConfigProtocolChat();
      const responseConfigProtocol = await ProtocolConfigService.getConfigs(
        recordTypeId
      );

      const { defaultFieldsConfig } = responseDefaultFieldsConfig.data.content;

      const defaultRecordType =
        defaultFieldsConfig?.account_id?.defaultRecordType;
      const accountId = defaultFieldsConfig?.account_id?.defaultValue;

      if (defaultRecordType && accountId) {
        const res = await contactService.getAccountById(
          defaultRecordType,
          accountId
        );
        const contact = res?.data?.data;

        const formatContact = {
          defaultRecordType: contact.recordTypeId,
          id: contact.id,
          name: contact.name
        };
        setSelectedAccount(formatContact);
      }

      const data = formatDataForDisplay(defaultFieldsConfig);

      const criarProtocoloAoEncerrarConversa =
        responseConfigProtocolChat.data.criarProtocoloParaConversa;

      const {
        timeToFillProtocolForm,
        shouldCreateProtocolWhenReceiveEmail,
        shouldOpenProtocolWindowOnCallAnswered,
        shouldCreateProtocolWhenReceiveEmailInCC,
        mustSendTheFirstEmailToCCEmails,
        subjectOfTheProtocolEmail,
        sendEmailWhenCreatingTheProtocol
      } = responseConfigProtocol.data.content;

      const dataReset = {
        ...data,
        shouldCreateProtocolWhenReceiveEmail,
        shouldOpenProtocolWindowOnCallAnswered,
        criarProtocoloAoEncerrarConversa,
        mustSendTheFirstEmailToCCEmails,
        shouldCreateProtocolWhenReceiveEmailInCC,
        timeToFillProtocolForm,
        subjectOfTheProtocolEmail,
        sendEmailWhenCreatingTheProtocol
      };

      if (responseConfigProtocol.data.content.protocolNumberSetup) {
        setProtocolNumberUInfo(
          responseConfigProtocol.data.content.protocolNumberSetup
        );
      }

      dataReset.shouldCreateProtocolWhenReceiveEmail =
        shouldCreateProtocolWhenReceiveEmail;
      dataReset.shouldOpenProtocolWindowOnCallAnswered =
        shouldOpenProtocolWindowOnCallAnswered;

      dataReset.mustSendTheFirstEmailToCCEmails =
        mustSendTheFirstEmailToCCEmails;

      reset(dataReset);
    } catch (error) {
      console.log('error: ', error);
      const msg = error.response?.data?.message;
      toast.error(msg || 'Erro ao tentar buscar a configuração', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
    setLoading(false);
  }, [reset, recordTypeId]);

  const toggleRearrangeInputsProtocolForm = () => {
    setRearrangeInputsProtocolFormOpen(!rearrangeInputsProtocolFormOpen);
  };

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  const handleSetValueToSelects = (controlItem, valueItem) => {
    if (typeof controlItem !== 'undefined' && controlItem) {
      return valueItem;
    } else {
      return false;
    }
  };

  const makeDTOProtocolChatConfig = (data) => {
    const { criarProtocoloAoEncerrarConversa } = data;
    return { criarProtocoloAoEncerrarConversa };
  };

  const makeDTOProtocolDefaultFieldsConfig = (data) => {
    return formatDataForSubmit(data);
  };

  const makeDTOProtocolConfig = async (data) => {
    return {
      recordTypeId,
      shouldCreateProtocolWhenReceiveEmail:
        data.shouldCreateProtocolWhenReceiveEmail,
      shouldOpenProtocolWindowOnCallAnswered:
        data.shouldOpenProtocolWindowOnCallAnswered,
      shouldCreateProtocolWhenReceiveEmailInCC:
        data.shouldCreateProtocolWhenReceiveEmailInCC,
      mustSendTheFirstEmailToCCEmails: data.mustSendTheFirstEmailToCCEmails,
      timeToFillProtocolForm: data.timeToFillProtocolForm,
      sendEmailWhenCreatingTheProtocol: data.sendEmailWhenCreatingTheProtocol,
      subjectOfTheProtocolEmail: data.subjectOfTheProtocolEmail
    };
  };

  const onSubmit = async (data) => {
    data.defaultValueAccount = selectedAccount;
    setIsSaving(true);
    try {
      const chatConfigData = makeDTOProtocolChatConfig(data);
      const protocolConfigData = await makeDTOProtocolConfig(data);
      const defaultFieldsConfigData = makeDTOProtocolDefaultFieldsConfig(data);

      protocolConfigData.numberConfiguration = protocolNumber;

      await Promise.all([
        ConfigChatService.updateConfigProtocolChat(chatConfigData),
        ProtocolConfigService.setConfigs(protocolConfigData),
        ProtocolFieldsService.setDefaultFieldsDisplaySettings(
          recordTypeId,
          defaultFieldsConfigData
        )
      ]);

      toast.success('Configurações realizadas com sucesso', {
        autoClose: 3000,
        closeOnClick: true
      });
    } catch (error) {
      const msg = error.response?.data?.message;

      toast.error(msg || 'Erro ao tentar modificar configuração de protocolo', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      getConfig();
      setIsSaving(false);
    }
  };

  return (
    <>
      {loading ? (
        <Row className="justify-content-md-center">
          <ReactLoader />
        </Row>
      ) : (
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <ContainerForm>
            <ContentTitleSection>
              <Row>
                <label>Configuração de geração de protocolo via email</label>
              </Row>
            </ContentTitleSection>

            <ContentItemFormSelect>
              <Row>
                <Col md="12">
                  <Controller
                    name="shouldCreateProtocolWhenReceiveEmail"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <>
                        <Input
                          type="checkbox"
                          onChange={(e) => {
                            props.onChange(e.target.checked);
                            setValue(
                              'shouldCreateProtocolWhenReceiveEmailInCC',
                              e.target.checked
                            );
                          }}
                          checked={props.value}
                        />

                        <label className="form-control-label">
                          Permitir criação de protocolo(s) via recebimento de
                          email de cliente(s)
                        </label>
                      </>
                    )}
                  />
                </Col>
                <Col md="12">
                  <Controller
                    name="shouldCreateProtocolWhenReceiveEmailInCC"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <>
                        <Input
                          disabled={!createProtocolOnChatEnd}
                          type="checkbox"
                          onChange={(e) => props.onChange(e.target.checked)}
                          checked={props.value}
                        />
                        <label className="form-control-label">
                          Abrir protocolo de e-mail quando a caixa de correio
                          estiver em cópia
                        </label>
                      </>
                    )}
                  />
                </Col>
                <Col md="12">
                  <Controller
                    name="mustSendTheFirstEmailToCCEmails"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <>
                        <Input
                          disabled={!createProtocolOnChatEnd}
                          type="checkbox"
                          onChange={(e) => props.onChange(e.target.checked)}
                          checked={props.value}
                        />
                        <label className="form-control-label">
                          Enviar primeira resposta para e-mails que estão em
                          cópia
                        </label>
                      </>
                    )}
                  />
                </Col>
                <Col md="12">
                  <Controller
                    name="sendEmailWhenCreatingTheProtocol"
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <>
                        <Input
                          type="checkbox"
                          onChange={(e) => props.onChange(e.target.checked)}
                          checked={props.value}
                        />
                        <label className="form-control-label">
                          Enviar email ao criar protocolo
                        </label>
                      </>
                    )}
                  />
                </Col>
                <Col md="12">
                  <Controller
                    name="subjectOfTheProtocolEmail"
                    control={control}
                    render={(props) => (
                      <>
                        <label className="form-control-label">
                          Assunto para envio de email de protocolo
                          <FormTooltip text="Ao incluir #TITULO_PROTOCOLO# ou #NUMERO_PROTOCOLO# no assunto, esses marcadores serão substituídos automaticamente pelo título ou número do protocolo correspondente." />
                        </label>
                        <Input
                          type="text"
                          placeholder="Digite aqui o assunto de seus emails"
                          onChange={(e) => props.onChange(e.target.value)}
                          value={props.value}
                          defaultValue="Solicitação #TITULO_PROTOCOLO# (#NUMERO_PROTOCOLO#):"
                        />
                      </>
                    )}
                    rules={{
                      required: 'Assunto do email de protocolo é obrigatório'
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="subjectOfTheProtocolEmail"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
              </Row>
            </ContentItemFormSelect>

            <SpaceItens />
            <Hr />
            <ContainerProtocolGeneration>
              <ItemProtocolGeneration>
                <ContentTitleSection>
                  <Row>
                    <label>
                      Configuração de geração de protocolo via telefonia
                    </label>
                  </Row>
                </ContentTitleSection>
                <ContentItemFormSelect>
                  <Row>
                    <Col md="12">
                      <Controller
                        name="shouldOpenProtocolWindowOnCallAnswered"
                        defaultValue={false}
                        control={control}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Abrir modal de criação de protocolo ao receber
                              ligação de cliente
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </Row>

                  <Row>
                    {' '}
                    <Col md="12">
                      <Controller
                        name="timeToFillProtocolForm.enabled"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Tabulação
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                  {hasTabulacaoEnabled && (
                    <Row>
                      <Col md="6">
                        <label className="form-control-label">
                          Tempo em segundos para a tabulação
                        </label>
                        <Controller
                          as={Input}
                          control={control}
                          name="timeToFillProtocolForm.value"
                          rules={{
                            required:
                              'Tempo em segundos para a tabulação obrigatório'
                          }}
                          defaultValue={60}
                          type="number"
                        />
                      </Col>
                    </Row>
                  )}
                </ContentItemFormSelect>
              </ItemProtocolGeneration>

              <ItemProtocolGeneration>
                <ContentTitleSection>
                  <Row>
                    <label>Configuração de geração de protocolo via chat</label>
                  </Row>
                </ContentTitleSection>
                <ContentItemFormSelect>
                  <Row>
                    <Col md="12">
                      <Controller
                        name="criarProtocoloAoEncerrarConversa"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => {
                                props.onChange(e.target.checked);
                              }}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Permitir criar protocolo ao encerrar conversa
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </Row>
                </ContentItemFormSelect>
              </ItemProtocolGeneration>
            </ContainerProtocolGeneration>

            <SpaceItens />
            <Hr />

            <ContentTitleSection>
              <Row>
                <label>Configuração de campos padrões</label>
              </Row>
            </ContentTitleSection>
            <Row>
              <Col md="3">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="6">
                      <label>Titulo</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showTitle"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredTitle"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={handleSetValueToSelects(
                                controlTitle,
                                props.value
                              )}
                              disabled={
                                !controlTitle || controlOnlyToShowOnEditTitle
                              }
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                {controlRequiredTitle && (
                  <Row className="mb-2">
                    <Col md="9">
                      <label className="form-control-label">
                        Valor Padrão Titulo
                      </label>
                      <Controller
                        as={Input}
                        control={control}
                        name="defaultValueTitle"
                        rules={{
                          required: 'valor padrão titulo é obrigatório'
                        }}
                        defaultValue=""
                      />
                      <ErrorMessage
                        errors={errors}
                        name="defaultValueTitle"
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="isOnlyToShowOnEditTitle"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={!controlTitle || controlRequiredTitle}
                              checked={handleSetValueToSelects(
                                controlTitle,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Mostrar campo apenas na edição
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>

              <Col md="3">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="6">
                      <label>Cliente</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showClient"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredClient"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlClient || controlOnlyToShowOnEditClient
                              }
                              checked={handleSetValueToSelects(
                                controlClient,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="isOnlyToShowOnEditClient"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={!controlClient || controlRequiredClient}
                              checked={handleSetValueToSelects(
                                controlClient,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Mostrar campo apenas na edição
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>

              <Col md="3">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="6">
                      <label>Telefone</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showTelephone"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredTelephone"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlTelephone ||
                                controlOnlyToShowOnEditTelephone
                              }
                              checked={handleSetValueToSelects(
                                controlTelephone,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                {controlRequiredTelephone && (
                  <Row className="mb-2">
                    <Col md="9">
                      <label className="form-control-label">
                        Valor Padrão Telefone
                      </label>
                      <Controller
                        render={(props) => (
                          <TelephoneMask
                            valueController={setValue}
                            fieldName="defaultValueTelephone"
                            value={props.value}
                            placeholder="(88)8.9999-9999"
                          />
                        )}
                        control={control}
                        name="defaultValueTelephone"
                        defaultValue={''}
                        rules={{
                          required: 'valor padrão telefone é obrigatório'
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="defaultValueTelephone"
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="isOnlyToShowOnEditTelephone"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlTelephone || controlRequiredTelephone
                              }
                              checked={handleSetValueToSelects(
                                controlTelephone,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Mostrar campo apenas na edição
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>
              <Col md="3">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="6">
                      <label>Descrição</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showDescription"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredDescription"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={handleSetValueToSelects(
                                controlDescription,
                                props.value
                              )}
                              disabled={
                                !controlDescription ||
                                controlOnlyToShowOnEditDescription
                              }
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                {controlRequiredDescription && (
                  <Row className="mb-2">
                    <Col md="9">
                      <label className="form-control-label">
                        Valor Padrão Descrição
                      </label>
                      <Controller
                        as={Input}
                        control={control}
                        name="defaultValueDescription"
                        rules={{
                          required: 'valor padrão descrição é obrigatório'
                        }}
                        defaultValue=""
                      />
                      <ErrorMessage
                        errors={errors}
                        name="defaultValueDescription"
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="isOnlyToShowOnEditDescription"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlDescription ||
                                controlRequiredDescription
                              }
                              checked={handleSetValueToSelects(
                                controlDescription,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Mostrar campo apenas na edição
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>
            </Row>
            <SpaceItens />
            <Row>
              <Col md="3">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="6">
                      <label>Motivo</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showReason"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredReason"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlReason || controlOnlyToShowOnEditReason
                              }
                              checked={handleSetValueToSelects(
                                controlReason,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                {controlRequiredReason && (
                  <Row className="mb-2">
                    <Col md="9">
                      <label className="form-control-label">
                        Valor Padrão Motivo
                      </label>
                      <Controller
                        as={
                          <Input type="select" name="select">
                            <option value="">Selecione um motivo</option>
                            {reasons &&
                              reasons.map(({ id, name }, index) => (
                                <option key={index} value={id}>
                                  {name}
                                </option>
                              ))}
                          </Input>
                        }
                        control={control}
                        name="defaultValueMotive"
                        rules={{
                          required: 'valor padrão motivo é obrigatório'
                        }}
                        defaultValue=""
                      />
                      <ErrorMessage
                        errors={errors}
                        name="defaultValueMotive"
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="isOnlyToShowOnEditReason"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={!controlReason || controlRequiredReason}
                              checked={handleSetValueToSelects(
                                controlReason,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Mostrar campo apenas na edição
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>
              <Col md="3">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="6">
                      <label>Submotivo</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showSubReason"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={!controlReason}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredSubReason"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlSubReason ||
                                controlOnlyToShowOnEditSubReason
                              }
                              checked={handleSetValueToSelects(
                                controlSubReason,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                {controlRequiredSubReason && (
                  <Row className="mb-2">
                    <Col md="9">
                      <label className="form-control-label">
                        Valor Padrão SubMotivo
                      </label>
                      <Controller
                        as={
                          <Input type="select" name="select">
                            <option value="">Selecione um sub motivo</option>
                            {subreasons.map(({ id, name }, index) => (
                              <option key={index} value={id}>
                                {name}
                              </option>
                            ))}
                          </Input>
                        }
                        disabled={loadingSubreasons}
                        control={control}
                        name="defaultValueSubmotive"
                        rules={{
                          required: 'valor padrão submotivo é obrigatório'
                        }}
                        defaultValue=""
                      />
                      <ErrorMessage
                        errors={errors}
                        name="defaultValueSubmotive"
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="isOnlyToShowOnEditSubReason"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlSubReason || controlRequiredSubReason
                              }
                              checked={handleSetValueToSelects(
                                controlSubReason,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Mostrar campo apenas na edição
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>
              <Col md="3">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="12">
                      <label>Canal de atendimento</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showServiceChannel"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredShowServiceChannel"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlServiceChannel ||
                                controlOnlyToShowOnEditServiceChannel
                              }
                              checked={handleSetValueToSelects(
                                controlServiceChannel,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                {controlRequiredServiceChannel && (
                  <Row className="mb-2">
                    <Col md="9">
                      <label className="form-control-label">
                        Valor Padrão Canal de Atendimento
                      </label>
                      <Controller
                        as={
                          <Input type="select" name="select">
                            <option value="">Selecione o canal</option>
                            <option value="EMAIL">Email</option>
                            <option value="FACEBOOK">Facebook</option>
                            <option value="TELEGRAM">Telegram</option>
                            <option value="TELEFONIA">Telefonia</option>
                            <option value="WHATSAPP">Whatsapp</option>
                            <option value="CHAT">Chat</option>
                            <option value="FLEX4U">Flex4U</option>
                            <option value="INSTAGRAM">Instagram</option>
                            <option value="SMS">SMS</option>
                          </Input>
                        }
                        control={control}
                        name="defaultValueServiceChannel"
                        defaultValue={''}
                        rules={{
                          required:
                            'valor padrão canal de atendimento é obrigatório'
                        }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="defaultValueServiceChannel"
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="isOnlyToShowOnEditServiceChannel"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlServiceChannel ||
                                controlRequiredServiceChannel
                              }
                              checked={handleSetValueToSelects(
                                controlServiceChannel,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Mostrar campo apenas na edição
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>
              <Col md="3">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="6">
                      <label>Status/Fase</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showCurrentStatus"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredShowCurrentStatus"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlStatus || controlOnlyToShowOnEditStatus
                              }
                              checked={handleSetValueToSelects(
                                controlStatus,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                {controlRequiredStatus && (
                  <Row className="mb-2">
                    <Col md="9">
                      <label className="form-control-label">
                        Valor Padrão Status
                      </label>
                      <Controller
                        as={
                          <Input type="select" name="select">
                            <option value="">Selecione o status/fase</option>
                            {status &&
                              status.map(({ id, name }, index) => (
                                <option key={index} value={id}>
                                  {name}
                                </option>
                              ))}
                          </Input>
                        }
                        control={control}
                        name="defaultValueStatus"
                        rules={{
                          required: 'valor padrão status é obrigatório'
                        }}
                        defaultValue=""
                      />
                      <ErrorMessage
                        errors={errors}
                        name="defaultValueStatus"
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="isOnlyToShowOnEditCurrentStatus"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={!controlStatus || controlRequiredStatus}
                              checked={handleSetValueToSelects(
                                controlStatus,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Mostrar campo apenas na edição
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>
              <Col md="3" className="mt-4">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="6">
                      <label>Responsável</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showCurrentResponsible"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredShowCurrentResponsible"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlResponsible ||
                                controlOnlyToShowOnEditResponsible
                              }
                              checked={handleSetValueToSelects(
                                controlResponsible,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                {controlRequiredResponsible && (
                  <Row className="mb-2">
                    <Col md="9">
                      <label className="form-control-label">
                        Valor Padrão Responsável
                      </label>
                      <Controller
                        as={
                          <Input type="select" name="select">
                            <option value="">Selecione o responsável</option>
                            {userList.map(({ login, name }, index) => (
                              <option key={index} value={login}>
                                {name}
                              </option>
                            ))}
                          </Input>
                        }
                        control={control}
                        name="defaultValueResponsible"
                        rules={{
                          required: 'valor padrão responsável é obrigatório'
                        }}
                        defaultValue={''}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="defaultValueResponsible"
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="isOnlyToShowOnEditCurrentResponsible"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlResponsible ||
                                controlRequiredResponsible
                              }
                              checked={handleSetValueToSelects(
                                controlResponsible,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Mostrar campo apenas na edição
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>
              <Col md="3" className="mt-4">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="6">
                      <label>Email</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showEmail"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredEmail"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlEmail || controlOnlyToShowOnEditEmail
                              }
                              checked={handleSetValueToSelects(
                                controlEmail,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                {controlRequiredEmail && (
                  <Row className="mb-2">
                    <Col md="9">
                      <label className="form-control-label">
                        Valor Padrão Email
                      </label>
                      <Controller
                        as={Input}
                        type="email"
                        control={control}
                        name="defaultValueEmail"
                        rules={{
                          validate: (value) => {
                            const isRequired = true;
                            if (isRequired && !value)
                              return 'Campo obrigatório';
                            if (!isRequired && !value) return true;

                            const emailRegex =
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

                            return (
                              emailRegex.test(value) ||
                              'Email com formato incorreto'
                            );
                          }
                        }}
                        defaultValue=""
                      />
                      <ErrorMessage
                        errors={errors}
                        name="defaultValueEmail"
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  </Row>
                )}
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="isOnlyToShowOnEditEmail"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={!controlEmail || controlRequiredEmail}
                              checked={handleSetValueToSelects(
                                controlEmail,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Mostrar campo apenas na edição
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>
              <Col md="3" className="mt-4">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="6">
                      <label>Conta</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showAccount"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredAccount"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlAccount ||
                                controlOnlyToShowOnEditAccount
                              }
                              checked={handleSetValueToSelects(
                                controlAccount,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                {controlRequiredAccount && (
                  <Row className="mb-2">
                    <Col md="9">
                      <label className="form-control-label">
                        Valor Padrão Conta
                      </label>

                      <Select
                        styles={custom_select}
                        placeholder="Buscar conta"
                        required={true}
                        isClearable={true}
                        name="account_id"
                        loadOptions={async (inputValue) => {
                          const res = await filterAccounts(inputValue);

                          return res.map((item) => ({
                            ...item,
                            label: item.name,
                            value: item.id,
                            record_type_id: item.record_type_id
                          }));
                        }}
                        isLoading={isSearchingAccounts}
                        loadingMessage={() => 'Buscando...'}
                        noOptionsMessage={({ inputValue }) =>
                          !inputValue
                            ? 'Digite para pesquisar'
                            : 'Nenhum resultado encontrado'
                        }
                        onChange={(data) => {
                          if (data) {
                            setSelectedAccount({
                              id: data.id,
                              name: data.name,
                              defaultRecordType: data.record_type_id
                            });
                          } else {
                            setSelectedAccount(null);
                          }
                        }}
                        value={
                          selectedAccount
                            ? {
                                label: selectedAccount.name,
                                value: selectedAccount.id
                              }
                            : ''
                        }
                        defaultValue=""
                      />
                      {controlValidSelectAccount &&
                        errorFormMessage('Campo obrigatório')}
                    </Col>
                  </Row>
                )}
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="isOnlyToShowOnEditAccount"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={
                                !controlAccount || controlRequiredAccount
                              }
                              checked={handleSetValueToSelects(
                                controlAccount,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Mostrar campo apenas na edição
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>

              <Col md="3" className="mt-4">
                <Row>
                  <ContentItemFormTitle>
                    <Col md="6">
                      <label>Arquivos</label>
                    </Col>
                  </ContentItemFormTitle>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="showFiles"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            <label className="form-control-label">
                              Mostrar campo no cadastro
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
                <Row>
                  <ContentItemFormSelect>
                    <Col md="12">
                      <Controller
                        name="requiredFiles"
                        control={control}
                        defaultValue={false}
                        render={(props) => (
                          <>
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={!controlFiles}
                              checked={handleSetValueToSelects(
                                controlFiles,
                                props.value
                              )}
                            />
                            <label className="form-control-label">
                              Campo obrigatório
                            </label>
                          </>
                        )}
                      />
                    </Col>
                  </ContentItemFormSelect>
                </Row>
              </Col>
            </Row>

            <SpaceItens />
            <Hr />

            <ContentTitleSection>
              <Row>
                <label>
                  Configuração das posições dos campos do protocolo:
                </label>
              </Row>
            </ContentTitleSection>
            <Row>
              <Col md="3">
                <Button
                  color="primary"
                  type="button"
                  onClick={() => toggleRearrangeInputsProtocolForm()}
                >
                  Ajustar posições
                </Button>
              </Col>
            </Row>

            <SpaceItens />

            <ContentTitleSection>
              <Hr />
              <Row style={{ flexDirection: 'column' }}>
                <label>Configuração de número de protocolo</label>
                <ProtocolNumberConfig
                  data={protocolNumberInfo}
                  setProtocolNumber={setProtocolNumber}
                />
              </Row>
            </ContentTitleSection>
          </ContainerForm>
          <Buttons isSaving={isSaving} />
        </Form>
      )}
      {rearrangeInputsProtocolFormOpen && (
        <AllowsRearrangeInputsModal
          isModalOpen={rearrangeInputsProtocolFormOpen}
          toggleModal={toggleRearrangeInputsProtocolForm}
          allControls={allControls.fieldDisplaySettings}
          recordTypeId={recordTypeId}
        />
      )}
    </>
  );
};

export { ConfigurationArea };
