import React, { useEffect } from 'react';
import { Input, Table } from 'reactstrap';
import { FiArrowDownCircle, FiArrowUpCircle } from 'react-icons/fi';
import { Controller } from 'react-hook-form';

export const LeadStagesConfig = ({
  control,
  register,
  setValue,
  leadStages = [],
  selectedLeadStages = [],
  defaultStageId,
  lostStageId,
  conversionStageId,
  leadStagesConfig,
  setLeadStagesConfig
}) => {
  useEffect(() => {
    register('isDefault');
    register('isConversion');
    register('isLost');

    if (defaultStageId) setValue('isDefault', `${defaultStageId}`);
    if (conversionStageId) setValue('isConversion', `${conversionStageId}`);
    if (lostStageId) setValue('isLost', `${lostStageId}`);
  }, [register, defaultStageId, conversionStageId, lostStageId, setValue]);

  useEffect(() => {
    if (leadStages && leadStages.length > 0) {
      const config = [];
      for (const stgId of selectedLeadStages) {
        config.push(leadStages.find((stg) => stg.id === stgId));
      }
      setLeadStagesConfig(config);
    }
  }, [selectedLeadStages, leadStages, setLeadStagesConfig]);

  if (
    !leadStages ||
    !leadStages.length ||
    !selectedLeadStages ||
    !selectedLeadStages.length
  ) {
    return null;
  }

  const switchItems = (index1, index2) => {
    [leadStagesConfig[index1], leadStagesConfig[index2]] = [
      leadStagesConfig[index2],
      leadStagesConfig[index1]
    ];
    setLeadStagesConfig([...leadStagesConfig]);
  };

  const upItem = (i) => {
    const index = leadStagesConfig.indexOf(i);
    if (index > 0) {
      switchItems(index, index - 1);
    }
  };

  const downItem = (i) => {
    const index = leadStagesConfig.indexOf(i);
    if (index < leadStagesConfig.length - 1) {
      switchItems(index, index + 1);
    }
  };

  return (
    <div>
      <Table bordered={true} striped={true} size="sm" className="text-center">
        <thead>
          <tr>
            <th>Fase</th>
            <th>Padrão</th>
            <th>Conversão</th>
            <th>Perdido</th>
            <th>Ordenar</th>
            <th>Ativar</th>
          </tr>
        </thead>
        <tbody>
          {leadStagesConfig &&
            leadStagesConfig.map((stage) => (
              <tr key={stage.id}>
                <td className="text-left">{stage.name}</td>
                <td>
                  <label htmlFor={stage.id}>
                    <input
                      name="isDefault"
                      type="radio"
                      value={stage.id}
                      id={stage.id}
                      defaultChecked={defaultStageId === stage.id}
                      onChange={(event) =>
                        setValue('isDefault', event.target.value)
                      }
                    />
                  </label>
                </td>
                <td>
                  <label htmlFor={stage.id}>
                    <input
                      name="isConversion"
                      type="radio"
                      value={stage.id}
                      id={stage.id}
                      defaultChecked={conversionStageId === stage.id}
                      onChange={(event) =>
                        setValue('isConversion', event.target.value)
                      }
                    />
                  </label>
                </td>
                <td>
                  <label htmlFor={stage.id}>
                    <input
                      name="isLost"
                      type="radio"
                      value={stage.id}
                      id={stage.id}
                      defaultChecked={lostStageId === stage.id}
                      onChange={(event) =>
                        setValue('isLost', event.target.value)
                      }
                    />
                  </label>
                </td>
                <td>
                  <div className="">
                    <FiArrowUpCircle
                      size={20}
                      cursor="pointer"
                      onClick={() => upItem(stage)}
                    />
                    <FiArrowDownCircle
                      style={{ marginLeft: '10px' }}
                      size={20}
                      cursor="pointer"
                      onClick={() => downItem(stage)}
                    />
                  </div>
                </td>
                <td>
                  <Controller
                    name={`${stage.id}-isActive`}
                    control={control}
                    render={(props) => (
                      <>
                        <label className="form-control-label">
                          <Input
                            type="checkbox"
                            onChange={(e) => props.onChange(e.target.checked)}
                            checked={props.value}
                            disabled={true}
                          />
                        </label>
                      </>
                    )}
                    defaultValue={true}
                  />
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};
