import CallMadeReportService from 'views/Report/Callcenter/CallMade/service';

import { REPORT_TYPE, QUERY_PARAMS_ANALYTICAL } from '../utils/consts';

export const getAnalyticalCallMadeReport = async (params, page) => {
  const {
    data: { data }
  } = await CallMadeReportService.getAllReportsType(
    REPORT_TYPE.ANALYTICAL,
    page,
    { ...QUERY_PARAMS_ANALYTICAL, ...params }
  );

  return data;
};
