import React from 'react';

import { SubProtocol } from './Subprotocol';

export const SubProtocolList = ({ parentProtocol, list, handleRedirect }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        gap: '5px',
        flexWrap: 'wrap',
        backgroundColor: '#e9ecef',
        padding: '10px',
        borderRadius: '0.25rem'
      }}
    >
      {parentProtocol && (
        <SubProtocol
          handleRedirect={handleRedirect}
          isParent={true}
          protocol={parentProtocol}
        ></SubProtocol>
      )}
      {list.map((item) => {
        return (
          <SubProtocol
            handleRedirect={handleRedirect}
            protocol={item}
          ></SubProtocol>
        );
      })}
    </div>
  );
};
