import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { format, startOfMonth } from 'date-fns';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import Loader from 'react-loader-spinner';
import DatePicker from 'components/Inputs/DatePicker.js';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody
} from 'reactstrap';

import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import Pagination from 'components/Pagination';
import Table from './tables/Analytical';
import AssessmentService from './service';
import { dateRules, hourRules } from '../Callcenter/utils/rulesValidation';
import HourMaskMMHHSS from 'components/Inputs/HourMaskMMHHSS.js';

const ConversationReport = () => {
  const { control, handleSubmit, errors, setValue, getValues } = useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [dataToSend, setDataToSend] = useState({});
  const [assessments, setAssessments] = useState([]);

  async function getAssessment(data, page) {
    setIsTableLoading(true);

    try {
      const res = await AssessmentService.getAssessmentAnalytics(
        {
          ...data,
          size: 10
        },
        page
      );

      if (res.data.totalRegisters === 0) {
        toast.info('Nenhum registro foi encontrado!');
      }

      setAssessments(res.data.data);
      setQuantity(res.data.totalRegisters);
      setIsTableLoading(false);
    } catch (error) {
      toast.error('Ocorreu um erro buscar relatório', {
        autoClose: 3000,
        closeOnClick: true
      });
      setIsTableLoading(false);
    }
  }

  const handlePageChange = (page) => {
    setActivePage(page);
    getAssessment(dataToSend, page);
  };

  const clearData = () => {
    setActivePage(1);
    setDataToSend({});
    setQuantity(0);
  };

  const getDateBegin = () => {
    const date = new Date();
    const firstDayMonth = new Date(
      date.getFullYear('pt-br'),
      date.getMonth('pt-br'),
      1
    );
    const dateBegin = format(startOfMonth(firstDayMonth), 'dd/LL/yyyy');
    return dateBegin;
  };

  const toISOFormat = (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');

    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${YYYY}-${MM}-${DD}`;
  };

  const getDataDTO = (data) => {
    const formatedData = {
      dateBegin: `${toISOFormat(data.dateBegin, true)} ${data.timeStart}`,
      dateEnd: `${toISOFormat(data.dateEnd, true)} ${data.timeEnd}`
    };

    setDataToSend(formatedData);

    return formatedData;
  };

  const onSubmit = async (data) => {
    clearData();

    const formatedData = getDataDTO(data);
    setDataToSend(formatedData);
    getAssessment(formatedData, activePage);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de Monitoria de Qualidade</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">
                          Data inicial*
                        </label>
                        <Controller
                          render={(props) => (
                            <DatePicker
                              fieldName="dateBegin"
                              valueController={setValue}
                              defaultValue={props.value}
                            />
                          )}
                          control={control}
                          name="dateBegin"
                          rules={dateRules}
                          defaultValue={getDateBegin()}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="dateBegin"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">
                          Hora inicial*
                        </label>
                        <Controller
                          render={(props) => (
                            <HourMaskMMHHSS
                              valueController={setValue}
                              fieldName="timeStart"
                              value={props.value}
                            />
                          )}
                          control={control}
                          name="timeStart"
                          rules={hourRules}
                          defaultValue="00:00:00"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="timeStart"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">
                          Data final*
                        </label>
                        <Controller
                          render={(props) => (
                            <DatePicker
                              fieldName="dateEnd"
                              valueController={setValue}
                              defaultValue={props.value}
                            />
                          )}
                          control={control}
                          name="dateEnd"
                          defaultValue={new Date().toLocaleDateString('pt-br')}
                          rules={{
                            ...dateRules,
                            validate: (value) => {
                              if (
                                new Date(toISOFormat(value, true)) <
                                new Date(
                                  toISOFormat(getValues()['dateBegin'], true)
                                )
                              ) {
                                return 'A data final não pode ser anterior à inicial!';
                              } else {
                                return true;
                              }
                            }
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="dateEnd"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">
                          Hora final*
                        </label>
                        <Controller
                          render={(props) => (
                            <HourMaskMMHHSS
                              valueController={setValue}
                              fieldName="timeEnd"
                              value={props.value}
                            />
                          )}
                          control={control}
                          name="timeEnd"
                          rules={hourRules}
                          defaultValue="23:59:59"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="timeEnd"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>

                    <br />
                    <Button
                      style={buttonStyle(systemConfiguration.primary_color)}
                      type="submit"
                      disabled={isTableLoading}
                    >
                      {isTableLoading ? (
                        <Row className="justify-content-md-center">
                          <Loader
                            type="TailSpin"
                            color={'white'}
                            height={18}
                            width={78}
                          />
                        </Row>
                      ) : (
                        'Buscar'
                      )}
                    </Button>
                  </Form>
                  {isTableLoading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    quantity !== 0 && (
                      <>
                        <Table
                          data={assessments}
                          quantity={quantity}
                          totalRegister={quantity}
                        />
                        <Pagination
                          activePage={activePage}
                          totalItemsCount={quantity}
                          itemsCountPerPage={10}
                          onChange={handlePageChange.bind(this)}
                        />
                      </>
                    )
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConversationReport;
