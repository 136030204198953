export const refusedExtensions = [
  'bat',
  'js',
  'jar',
  'vb',
  'html',
  'exe',
  'msi',
  'sql',
  'gif'
];

export const imageExtensions = [
  'jpeg',
  'JPEG',
  'jpg',
  'JPG',
  'png',
  'PNG',
  'bmp'
];

export const audioExtensions = ['aac', 'mp4', 'amr', 'mpeg', 'mp3', 'wav'];

export const videoExtensions = ['mp4', '3gpp'];

export const fileExtensions = [
  'pdf',
  'PDF',
  'xls',
  'XLS',
  'doc',
  'DOC',
  'docx',
  'DOCX',
  'xlsx',
  'XLSX',
  'txt',
  'TXT',
  'csv',
  'CSV',
  'zip',
  'xml',
  'epub',
  'rtf'
];
