import {
  AUTH_SUCCESS,
  AUTH_START,
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_ACTIVE_LOGOUT
} from '../action/actionTypes';
import { updateObject } from '../utility';
import ServiceAuth from '../../services/ServiceAUTH';

const initialState = {
  token: null,
  error: null,
  loading: false,
  // remover depois o visible
  visible: false,
  isAutenticated: false,
  name: '',
  should_sign_in_asterisk: false,
  use_scale: false,
  queuesidToSignIn: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_START:
      return updateObject(state, { loading: true, visible: false });
    case AUTH_SUCCESS:
      return updateObject(state, {
        token: action.token,
        loading: false,
        visible: false,
        isAutenticated: true,
        name: action.name,
        should_sign_in_asterisk: action.should_sign_in_asterisk,
        use_scale: action.use_scale,
        queuesidToSignIn: action.queuesidToSignIn
      });
    case AUTH_FAIL:
      return updateObject(state, {
        error: action.error,
        loading: false,
        visible: true
      });
    case AUTH_LOGOUT:
      return updateObject(state, {
        isAutenticated: false,
        token: null,
        name: '',
        should_sign_in_asterisk: false,
        use_scale: false
      });
    case AUTH_ACTIVE_LOGOUT: {
      const logoutService = new ServiceAuth('logout');
      logoutService
        .create({
          login: localStorage.getItem('login'),
          logoutType: 'logout_involuntario'
        })
        .then(() => {
          window.location.href = '/activelogout';
        })
        .catch(() => {});
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
