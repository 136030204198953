import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import { Modal } from 'reactstrap';
import { getCroppedImg } from './utils';
import { ExternalContainer, Input, CropperContainer } from './styles';
import ReactButton from 'components/Buttons/ReactButton';

export const ImageCropper = ({
  image,
  setImage,
  setPreviewImage,
  isOpen,
  setIsOpen
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [croppedArea, setCroppedArea] = React.useState(null);
  const [zoom, setZoom] = useState(1);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  }, []);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onCrop = async () => {
    const canvas = await getCroppedImg(image, croppedArea);
    canvas.toBlob((blob) => {
      setPreviewImage(URL.createObjectURL(blob));
    });

    const teste = canvas.toDataURL('image/png');

    function dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }

    setImage(dataURLtoFile(teste, 'user_image'));
    toggleModal();
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <ExternalContainer>
          <h1>Editar imagem</h1>
          <CropperContainer>
            <Cropper
              className="Cropper"
              classes={Cropper}
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={1}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              cropShape="round"
              showGrid={false}
            ></Cropper>
          </CropperContainer>
          <Input
            type="range"
            min="1"
            value={zoom}
            max="3"
            step="0.05"
            onInput={(e) => {
              setZoom(e.target.value);
            }}
          />
          <div>
            <ReactButton
              btnColor="confirmation"
              onClick={() => {
                onCrop();
              }}
            >
              Confirmar
            </ReactButton>
            <ReactButton btnColor="cancelation" onClick={toggleModal}>
              Cancelar
            </ReactButton>
          </div>
        </ExternalContainer>
      </Modal>
    </>
  );
};
