import React from 'react';
//import './ToggleSwitch.scss';
//import './ToggleSwitch.css';
import { ToggleDiv } from './style';
import { useSelector } from 'react-redux';

const ToggleSwitch = (props) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const getOnChange = () => {
    props.onChange();
  };

  return (
    <ToggleDiv color={systemConfiguration.primary_color}>
      <input
        disabled={props.disabled ? props.disabled : false}
        checked={props.checked}
        type="checkbox"
        className="toggle-switch-checkbox"
        id={props.id}
        onChange={getOnChange}
      />
      <label className="toggle-switch-label" htmlFor={props.id}>
        <span className="toggle-switch-inner" />
        <span className="toggle-switch-switch" />
      </label>
    </ToggleDiv>
  );
};

export default ToggleSwitch;
