export const toISOFormat = (dateTimeString, bar = false) => {
  const [date] = dateTimeString.split(' ');
  const [DD, MM, YYYY] = date.split('/');

  if (bar) return `${MM}/${DD}/${YYYY}`;
  return `${YYYY}-${MM}-${DD}`;
};

export const getDataDTO = (data) => {
  let formatedData;

  formatedData = {
    dateBegin: `${toISOFormat(data.dataInicial)}`,
    dateEnd: `${toISOFormat(data.dataFinal)}`,
    filterDateBy: data.filterDateBy,
    recordType: data.selectedRecordType
  };
  return formatedData;
};

function getFirstDayString(year, month) {
  return new Date(year, month, 1).toLocaleDateString('pt-br');
}

export const firstDayOfMonth = getFirstDayString(
  new Date().getFullYear(),
  new Date().getMonth()
);

export function firstDay() {
  function getFirstDay(year, month) {
    return new Date(year, month, 1);
  }

  const today = new Date();

  return getFirstDay(today.getFullYear(), today.getMonth());
}
