import ServiceCRUD from '../../../services/ServiceCRUDFLEXCHAT';
import { apiChat as http } from '../../../http';

class ChatDialogTreeService extends ServiceCRUD {
  constructor() {
    super('/dialog/chat');
  }

  getActionDialogTree = () => {
    return http.get(`${this.path}/action`);
  };

  getAllSearch = (page, size, query) => {
    if (query !== '') {
      return http.get(`${this.path}?name=${query}`, {
        headers: {
          limit: size,
          offset: size * (page - 1)
        }
      });
    } else {
      return http.get(`${this.path}`, {
        headers: {
          limit: size,
          offset: size * (page - 1)
        }
      });
    }
  };

  putDialogTree = (id, data) => {
    return http.put(`${this.path}/${id}`, data);
  };

  postDialogTree = (data) => {
    return http.post(`${this.path}`, data);
  };

  deleteDialogTree = (id) => {
    return http.delete(`${this.path}/${id}`);
  };

  getTreeById = (id) => {
    return http.get(`${this.path}/${id}`);
  };

  getDialogTreeResume = () => {
    return http.get(`${this.path}/resume/all`);
  };
}

export default new ChatDialogTreeService();
