import React from 'react';

const Success = ({ fontColor }) => {
  return (
    <div className="text-center mb-8 mt-6">
      <i
        style={{ fontSize: '80px', color: fontColor }} //99b543
        className="fas fa-exclamation mb-4"
      ></i>
      <h3 style={{ paddingBottom: '30px', color: fontColor }}>
        Pesquisa já respondida para esse atendimento!
      </h3>
    </div>
  );
};

export default Success;
