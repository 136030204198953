import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import QueueService from 'views/Queue/service';
import {
  templateParamsRegex,
  broadcastTypeOfClosingConversationOptions
} from '../utils';
import TemplateMessageFilling from './components/TemplateMessageFillingList';

import BroadcastListSMSService from '../service';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { customFieldTypes } from 'views/fieldConfigs/utils/customFieldTypes';
import { ScreenPrompt } from 'components/ScreenPrompt';

export const ChatBroadcastListSMSForm = (props) => {
  const { id } = props.match.params;
  const history = useHistory();
  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;

  const [loading, setLoading] = useState(false);
  const [queues, setQueues] = useState(null);
  const [templateMessageText, setTemplateMessageText] = useState('');
  const [templateParams, setTemplateParams] = useState({});
  const [isUpdate, setIsUpdate] = useState(false);
  const [clientFields, setClientFields] = useState([]);

  const defaultContactRecordType = 301;
  const { customFields, getCustomFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType(
      entitiesName.CONTACT,
      defaultContactRecordType
    );

  const getQueues = useCallback(async () => {
    QueueService.getAll()
      .then((response) => {
        const queuesData = response.data.data;
        if (queuesData) {
          const queuesDTO = queuesData.map(({ id, name }) => ({ id, name }));
          setQueues(queuesDTO);
        }
      })
      .catch(() => {
        toast('Ocorreu um erro ao carregar as filas');
      });
  }, []);

  const getClientFields = useCallback(() => {
    if (isLoadingCustomFields) return;
    const defaultFields = [
      { label: 'Nome', value: 'nome' },
      { label: 'Telefone', value: 'phone' }
    ];

    const customizedFields = customFields
      .filter((field) => field.type === customFieldTypes.TEXT)
      .map((field) => ({
        label: field.label,
        value: field.name
      }));

    setClientFields(defaultFields.concat(customizedFields || []));
  }, [customFields, isLoadingCustomFields]);

  const getBroadcastListToEdit = useCallback(
    (broadcastListId) => {
      setLoading(true);

      BroadcastListSMSService.get(broadcastListId)
        .then((response) => {
          const broadcastListData = response.data;

          reset({
            title: broadcastListData.title,
            queueId: broadcastListData.queue_id,
            broadcastTypeOfClosingConversation:
              broadcastListData.broadcastTypeOfClosingConversation,
            closingMessage: broadcastListData.closingMessage ?? ''
          });
          setTemplateMessageText(broadcastListData.message);

          const params = broadcastListData.message.match(templateParamsRegex);
          const currentParams = {};
          if (params) {
            params.forEach((value, index) => {
              currentParams[`${value}`] = broadcastListData.params[index];
            });
          }
          setTemplateParams(currentParams);
          setIsUpdate(true);
        })
        .catch((err) => {
          const msg =
            err.response && err.response.data && err.response.data.message;
          toast.error(msg || 'Erro ao buscar campanha', {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [reset]
  );

  useEffect(() => {
    getQueues();
  }, [getQueues]);

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    getClientFields();
  }, [getClientFields]);

  useEffect(() => {
    if (id) getBroadcastListToEdit(id);
  }, [id, getBroadcastListToEdit]);

  const onSubmit = async (data) => {
    const extractParams = (message = '') => {
      return message.match(templateParamsRegex);
    };

    const params = extractParams(templateMessageText);

    const keys =
      isUpdate && params ? params.sort() : Object.keys(templateParams).sort();
    const newTemplateParams = keys.map((number) => templateParams[number]);

    data.message = templateMessageText;
    data.templateParams = newTemplateParams;
    data.templateType = 'TEXT';
    const requestBodyDTO = { ...data };
    try {
      isUpdate
        ? await BroadcastListSMSService.update(id, requestBodyDTO)
        : await BroadcastListSMSService.create(requestBodyDTO);
      toast.success(
        `Campanha Ativa SMS ${isUpdate ? 'atualizada' : 'criada'} com sucesso`
      );
      history.push('/admin/chat/broadcast-list-sms');
    } catch (error) {
      const errorMessage =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message;

      toast.error(errorMessage || 'Erro ao criar campanha');
    }

    return;
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/chat/broadcast-list-sms');
  };

  const changeText = (event) => {
    setTemplateMessageText(event.target.value);
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/chat/broadcast-list-sms"
        buttonTitle="Voltar para a pagina de campanhas"
      />
      <Container className="mt--6" fluid>
        <Row className="mb-3">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Campanha Ativa SMS</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Título*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="title"
                            rules={{ required: 'O título é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="title"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Fila*</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={queues || []}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="queueId"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="queueId"
                            rules={{
                              required: 'Selecione uma fila'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="queueId"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Texto*</label>
                          <Controller
                            render={() => (
                              <Input
                                type="textarea"
                                rows="4"
                                value={templateMessageText}
                                onChange={changeText}
                                name="message"
                                id="message"
                              />
                            )}
                            control={control}
                            name="message"
                            defaultValue={templateMessageText}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="message"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Tipo</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={
                                  broadcastTypeOfClosingConversationOptions
                                }
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="broadcastTypeOfClosingConversation"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="broadcastTypeOfClosingConversation"
                            defaultValue={
                              broadcastTypeOfClosingConversationOptions[0]
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12}>
                          {!!clientFields &&
                          !!clientFields.length &&
                          !loading ? (
                            <TemplateMessageFilling
                              templateMessage={templateMessageText}
                              templateParams={
                                templateParams !== {} ? templateParams : {}
                              }
                              setTemplateParams={setTemplateParams}
                              clientFields={clientFields}
                              isUpdate={isUpdate}
                              templateType="TEXT"
                            />
                          ) : loading ? (
                            <Col style={{ marginTop: '2rem' }}>
                              <ReactLoader height={60} />
                            </Col>
                          ) : null}
                        </Col>
                        <Col>
                          <ErrorMessage
                            errors={errors}
                            name="templateParams"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
