import React from 'react';

import { Form, Row, Col } from 'reactstrap';

import ReactButton from 'components/Buttons/ReactButton';

import FlexSelectDayOff from 'views/CalendarScales/components/ChangeDayOff/components/FlexSelectDayOff';
import DatePickerIsValidCDO from 'views/CalendarScales/components/ChangeDayOff/components/DatePickerIsValidCDO';
import DatePickerIsValidCDOD from 'views/CalendarScales/components/ChangeDayOff/components/DatePickerIsValidCDOD';

import { useForm, Controller } from 'react-hook-form';

export default function DayOffComponent({
  closeModal,
  onSubmit,
  listFlexSelect,
  user,
  daysOffObject
}) {
  const { control, handleSubmit, setValue } = useForm();

  return (
    <Form
      className="needs-validation"
      onSubmit={handleSubmit(onSubmit)}
      style={{ width: '100%' }}
    >
      <Row className="mt-4 ml-1">
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <label className="form-control-label">Supervisor</label>
          <Controller
            render={(props) => {
              return (
                <FlexSelectDayOff
                  dataOptions={listFlexSelect}
                  isClearable={true}
                  isMulti={false}
                  value={props.value}
                  valueController={setValue}
                  fieldName="supervisorShift"
                  labelName="label"
                  valueName="value"
                  minWidth="192px"
                  width="240px"
                />
              );
            }}
            control={control}
            name="supervisorShift"
            defaultValue=""
          />
        </Col>
      </Row>
      <Row className="mt-4 ml-1">
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <label>Data de Origem da Troca</label>
          <Controller
            render={(props) => {
              return (
                <DatePickerIsValidCDO
                  fieldName="beginDateDayOff"
                  valueController={setValue}
                  defaultValue={props.value}
                  user={user}
                  daysOffObject={daysOffObject}
                />
              );
            }}
            control={control}
            name="beginDateDayOff"
            defaultValue=""
          />
        </Col>
      </Row>
      <Row className="mt-4 ml-1">
        <Col
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <label>Data de Destino da Troca</label>
          <Controller
            render={(props) => {
              return (
                <DatePickerIsValidCDOD
                  fieldName="endDateDayOff"
                  valueController={setValue}
                  defaultValue={props.value}
                  daysOffObject={daysOffObject}
                />
              );
            }}
            control={control}
            name="endDateDayOff"
            defaultValue=""
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '3rem', marginLeft: '9rem' }}>
        <ReactButton btnColor="confirmation" type="submit">
          Salvar
        </ReactButton>
        <ReactButton btnColor="cancelation" onClick={() => closeModal()}>
          Cancelar
        </ReactButton>
      </Row>
    </Form>
  );
}
