import React, { useState, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Row, Form, Col, Input, Button } from 'reactstrap';

import forwarding_select_options from './forwardingSelectOptions';
import { forwarding_types } from './forwardingTypes';
import { ErrorMessage } from '@hookform/error-message';
import Modal from '../../../../components/Modal/DefaultModal';
import { useTree } from '../tree/context';
import isNodeRoot from '../tree/util/isNodeRoot';

import { useAsyncData } from '../asyncData';

const ModalForm = ({
  isOpenModal,
  onModalToggle,
  setterFunction,
  selectedNode
}) => {
  const { rootNodeData } = useTree();
  const { reset, control, handleSubmit, errors, watch } = useForm();
  const currentType = watch('type') || 'AUDIO';

  const { audios, extensions, queues, timeSchedules } = useAsyncData();

  const [hasChildren, setHasChildren] = useState(false); // flag para detectar se o nó tem filhos (se sim, o tipo do nó deve ser áudio)

  useEffect(() => {
    if (Object.keys(selectedNode).length > 0) {
      if (selectedNode.children) {
        setHasChildren(true);
      }
    } else {
      setHasChildren(false);
    }
  }, [selectedNode]);

  useEffect(() => {
    if (isNodeRoot(selectedNode)) {
      reset(rootNodeData);
    } else if (selectedNode.attributes) {
      reset(selectedNode.attributes);
    }
  }, [selectedNode, reset, rootNodeData]);

  const onSubmit = (data) => {
    setterFunction({ ...data });
    onModalToggle(false);
    reset();
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <Modal
      show={isOpenModal}
      onModalToggle={(state) => {
        onModalToggle(state);
      }}
      title="Encaminhamento"
      showCloseButton={false}
    >
      <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        {isNodeRoot(selectedNode) && (
          <Row className="mb-3">
            <Col sm="12">
              <label className="form-control-label">
                Programação de Horário*
              </label>
              <Controller
                defaultValue=""
                control={control}
                name="schedule_time_id"
                rules={{ required: 'Selecione uma programação' }}
                as={
                  <Input type="select">
                    <option value="">Selecione uma programação</option>
                    {timeSchedules.map((schedule) => (
                      <option key={schedule.id} value={schedule.id}>
                        {schedule.name}
                      </option>
                    ))}
                  </Input>
                }
              />
              <ErrorMessage
                errors={errors}
                name="schedule_time_id"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>
        )}

        {selectedNode.depth !== 0 && (
          <>
            <Row className="mb-3">
              <Col sm="12">
                <label className="form-control-label">Opção da URA*</label>
                <Controller
                  as={Input}
                  control={control}
                  name="uraOption"
                  rules={{ required: 'Campo obrigatório!' }}
                  defaultValue=""
                />
                <ErrorMessage
                  errors={errors}
                  name="uraOption"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col sm="12">
                <label className="form-control-label">Descrição</label>
                <Controller
                  as={Input}
                  control={control}
                  name="description"
                  defaultValue=""
                />
              </Col>
            </Row>
          </>
        )}

        <Row>
          <Col sm="12" className="mb-3">
            <label className="form-control-label">Tipo*</label>
            <Controller
              control={control}
              defaultValue="AUDIO"
              name="type"
              disabled={isNodeRoot(selectedNode) || hasChildren}
              as={
                <Input type="select">
                  {forwarding_select_options.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.displayName}
                    </option>
                  ))}
                </Input>
              }
            />
          </Col>

          {currentType === forwarding_types.AUDIO && (
            <Col sm="12" className="mb-3">
              <label className="form-control-label">Áudio*</label>
              <Controller
                defaultValue=""
                control={control}
                name="contentId"
                rules={{ required: 'Campo obrigatório' }}
                as={
                  <Input type="select">
                    <option value="">Selecione um áudio</option>
                    {audios &&
                      audios.map((audio) => (
                        <option key={audio.id} value={audio.id}>
                          {audio.name}
                        </option>
                      ))}
                  </Input>
                }
              />
              <ErrorMessage
                errors={errors}
                name="contentId"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          )}

          {currentType === forwarding_types.QUEUE && (
            <Col sm="12" className="mb-3">
              <label className="form-control-label">Fila*</label>
              <Controller
                defaultValue=""
                control={control}
                name="contentId"
                rules={{ required: 'Selecione uma fila' }}
                as={
                  <Input type="select">
                    <option value="">Selecione uma fila</option>
                    {queues &&
                      queues.map((queue) => (
                        <option key={queue.id} value={queue.id}>
                          {queue.name}
                        </option>
                      ))}
                  </Input>
                }
              />
              <ErrorMessage
                errors={errors}
                name="contentId"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          )}

          {currentType === forwarding_types.EXTENSION && (
            <Col sm="12" className="mb-3">
              <label className="form-control-label">Ramal*</label>
              <Controller
                defaultValue=""
                control={control}
                name="contentId"
                rules={{ required: 'Selecione um Ramal' }}
                as={
                  <Input type="select">
                    <option value="">Selecione um Ramal</option>
                    {extensions &&
                      extensions.map((extension) => (
                        <option
                          key={extension.extension_number}
                          value={extension.extension_number}
                        >
                          {extension.extension_number}
                        </option>
                      ))}
                  </Input>
                }
              />
              <ErrorMessage
                errors={errors}
                name="contentId"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          )}
        </Row>

        <Button color="primary" type="submit">
          Salvar
        </Button>
        <Button color="danger" onClick={() => onModalToggle(false)}>
          Cancelar
        </Button>
      </Form>
    </Modal>
  );
};

export default ModalForm;
