import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
  Label
} from 'reactstrap';
import { toast } from 'react-toastify';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ReactLoader from 'components/Loader';
import Pagination from 'components/Pagination';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import IntegrationUrlService from '../service';

export const IntegrationUrlList = () => {
  const [loading, setLoading] = useState(true);
  const [integrationUrls, setIntegrationUrls] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [showDisable, setShowDisable] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [
    controlIntegrationUrlsUpdate,
    setControlIntegrationUrlsUpdate
  ] = useState(0);
  const history = useHistory();

  const getIntegrationUrls = async (selectedPage = 1, search = '') => {
    try {
      const res = await IntegrationUrlService.getAllSeach(
        selectedPage,
        10,
        search,
        showDisable
      );

      setIntegrationUrls(res.data.data);
      setQuantity(res.data.totalRegisters);
    } catch (error) {
      toast.error('Não foi possivel listar as URLs de integração', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setLoading(false);
    }
  };

  const getIntegrationUrlsSearch = async (event, selectedPage = 1) => {
    getIntegrationUrls(selectedPage, event.target.value);
  };

  const toggleDisable = async (id, disable) => {
    if (disable) {
      try {
        await IntegrationUrlService.recovery(id);

        toast.success('URL de integração habilitada com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
        setControlIntegrationUrlsUpdate(Date.now());
      } catch (error) {
        toast.error('Não é possivel habilitar esta URL de integração', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    } else {
      try {
        await IntegrationUrlService.delete(id);

        toast.success('URL de integração desabilitada com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
        setControlIntegrationUrlsUpdate(Date.now());
      } catch (error) {
        toast.error('Não é possivel desabilitar esta URL de integração', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    }
  };

  const handlePageChange = (activePage) => {
    setActivePage(activePage);
    getIntegrationUrls(activePage);
  };

  useEffect(() => {
    getIntegrationUrls();
  }, [showDisable, controlIntegrationUrlsUpdate]);

  return (
    <>
      <SimpleHeader setCreate="/admin/integrationUrl/form" />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>URLs de integração</h3>
                </CardHeader>
                <CardBody>
                  <Row className="mb-3">
                    <Col md="12">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Integração FlexUC"
                          type="text"
                          onChange={(e) => getIntegrationUrlsSearch(e)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={showDisable}
                            onChange={() => setShowDisable(!showDisable)}
                          />
                          <span>Mostrar desabilitados</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={integrationUrls}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'url',
                      text: 'URL',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'edit',
                      text: 'Editar',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      formatter: function EditButton(_, { id, disable }) {
                        return (
                          <Button
                            size="sm"
                            color="info"
                            disabled={disable}
                            onClick={() => {
                              history.push(`integrationUrl/form/${id}`);
                            }}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'disable',
                      text: 'Desabilitar / Habilitar',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      formatter: function DisableButton(_, { id, disable }) {
                        return (
                          <ToggleSwitch
                            id={id}
                            checked={!disable}
                            onChange={() => toggleDisable(id, disable)}
                          />
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
