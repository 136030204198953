import React, { createContext, useState, useContext } from 'react';

export const CustomFieldsContext = createContext({});

export function CustomFieldsContextProvider({ children }) {
  const [valueFieldsReference, setValueFieldsReference] = useState({});

  const changeFieldsReference = (field) => {
    setValueFieldsReference(field);
  };

  const getValueFieldsReference = () => {
    return valueFieldsReference;
  };

  return (
    <CustomFieldsContext.Provider
      value={{ changeFieldsReference, getValueFieldsReference }}
    >
      {children}
    </CustomFieldsContext.Provider>
  );
}
export function useCustomFields() {
  const context = useContext(CustomFieldsContext);
  return context;
}
