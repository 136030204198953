import React, { useEffect } from 'react';
import { Row } from 'reactstrap';
import { toast } from 'react-toastify';

import DefaultModal from 'components/Modal/DefaultModal';
import ReactLoader from 'components/Loader';
import Pagination from 'components/Pagination';
import CallMadeAnalyticalTable from 'views/Report/Callcenter/CallMade/tables/Analytical';

import { getAnalyticalCallMadeReport } from 'views/Dashboard/Agent/services/getAnalyticalCallMadeReport';
import { DEFAULT_PAGE_SIZE } from 'views/Dashboard/Agent/utils/consts';

import { useAnalyticalReportService } from '../../hooks/useAnalyticalReportService';

const CallMadeReportModal = ({ isModalOpen, onModalToggle }) => {
  const {
    data,
    quantity,
    loading,
    activePage,
    error,
    handlePageChange,
    fetchData
  } = useAnalyticalReportService(getAnalyticalCallMadeReport);

  useEffect(() => {
    if (!isModalOpen) return;

    fetchData();
  }, [isModalOpen, activePage]);

  useEffect(() => {
    if (!error) return;

    onModalToggle(false);
    toast.error('Erro ao carregar relatório de chamadas realizadas.');
  }, [error]);

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Relatório de Chamadas Realizadas"
      modalContainerClasses="modal-xl"
      showCloseButton={false}
      centered={false}
    >
      {loading ? (
        <Row className="justify-content-md-center">
          <ReactLoader />
        </Row>
      ) : (
        <CallMadeAnalyticalTable
          quantity={quantity}
          showReports={data.listCallAnalytical || []}
          extraDataFromAnalyticalReport={data}
          needPermision={true}
        />
      )}

      {quantity > DEFAULT_PAGE_SIZE && (
        <Pagination
          activePage={activePage}
          totalItemsCount={quantity}
          onChange={handlePageChange}
        />
      )}
    </DefaultModal>
  );
};

export default CallMadeReportModal;
