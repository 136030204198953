import ServiceCRUD from '../../../services/ServiceCRUD';
import { apiCase as http } from '../../../http';

class ReasonService extends ServiceCRUD {
  constructor() {
    super('reasons');
  }

  getWithoutPagination() {
    return http.get('reasons/all');
  }

  getByRecordTypes(params) {
    return http.get('reasons/recordTypes', { params });
  }

  getByRecordType(recordTypeId, params) {
    return http.get(`/reasons/by-record-type/${recordTypeId}`, { params });
  }
}

export default new ReasonService();
