/* eslint-disable react/react-in-jsx-scope */
import React, { useEffect, useState, useCallback } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import DatePicker from '../../../components/Inputs/DatePicker';
import DatePickerIsValid from '../../../components/Inputs/DatePickerIsValid';
import FlexSelect from 'components/Inputs/FlexSelect';
import { useRequestUsers } from 'hooks/useUserContext';
import ReactButton from 'components/Buttons/ReactButton';
import DayOffService from '../service/index';
import { InputCheckbox } from '../form/style';
import { toast } from 'react-toastify';
import ReactLoader from 'components/Loader';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Col,
  Form,
  Input,
  FormGroup
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorFormMessage } from '../components/ErrorFormMessage';
import { IconLogoHeader } from '../../Scales/style/style';
import { useSelector } from 'react-redux';
import { BiAlarmOff } from 'react-icons/bi';
import { ScreenPrompt } from 'components/ScreenPrompt';

export default function DayOffForm(props) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const history = useHistory();

  const { users } = useRequestUsers();
  const [usersDayOff, setUsersDayOff] = useState([]);
  const [showDisabledsun, setShowDisablesun] = useState(false);
  const [showDisabledmon, setShowDisablemon] = useState(false);
  const [showDisabledtue, setShowDisabletue] = useState(false);
  const [showDisabledwed, setShowDisablewed] = useState(false);
  const [showDisabledthu, setShowDisablethu] = useState(false);
  const [showDisabledfri, setShowDisablefri] = useState(false);
  const [showDisabledsat, setShowDisablesat] = useState(false);
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isUpdate, setIsUpdate] = useState(false);
  const { control, errors, setValue, watch, handleSubmit, reset, formState } =
    useForm();
  const { isDirty } = formState;

  const { id } = props.match.params;

  const cancelAction = () => {
    history.push('/admin/DayOff');
  };

  const loadAPI = useCallback(async () => {
    DayOffService.get(id).then((response) => {
      const getFolga = response.data.data;
      const arraytest = [];
      // eslint-disable-next-line no-unused-vars
      const formatgetDayOff = response.data.data.users.forEach((response) => {
        arraytest.push(response.login);
      });
      const formatgetDayOffReset = { ...getFolga, userLogins: arraytest };
      setIsUpdate(true);
      setLoading(false);
      setShowDisablesun(getFolga.sunday);
      setShowDisablemon(getFolga.monday);
      setShowDisabletue(getFolga.tuesday);
      setShowDisablewed(getFolga.wednesday);
      setShowDisablethu(getFolga.thursday);
      setShowDisablefri(getFolga.friday);
      setShowDisablesat(getFolga.saturday);
      reset({
        ...formatgetDayOffReset,
        beginDate: new Intl.DateTimeFormat('pt-BR').format(
          new Date(getFolga.beginDate)
        ),
        endDate: new Intl.DateTimeFormat('pt-BR').format(
          new Date(getFolga.endDate)
        )
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, reset]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      loadAPI();
    }
  }, [id, loadAPI]);

  const watchFields = watch('endCriteria');

  const watchField = watch('repeatPattern');

  const dateRules = {
    required: 'Data é obrigatório!',
    pattern: {
      value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
      message: 'Data inválida! (dd/MM/aaaa)'
    }
  };

  useEffect(() => {
    const listUser = users.map((user) => {
      return {
        label: user.name,
        value: user.login
      };
    });

    setUsersDayOff(listUser);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = async (data) => {
    const FormatDateBegin = toISOFormat(data.beginDate);
    const FormatDateEnd = toISOFormat(data.endDate);
    const FormatData = {
      ...data,
      beginDate: FormatDateBegin,
      endDate: FormatDateEnd,
      sunday: showDisabledsun,
      monday: showDisabledmon,
      tuesday: showDisabledtue,
      wednesday: showDisabledwed,
      thursday: showDisabledthu,
      friday: showDisabledfri,
      saturday: showDisabledsat
    };

    if (id) {
      await DayOffService.put(id, FormatData)
        .then(() => {
          toast.info('Folga foi atualizada com sucesso!');
          history.push('/admin/DayOff');
        })
        .catch((err) => toast.error(err.response.data.errors[0]));
    } else {
      await DayOffService.post(FormatData)
        .then(() => {
          toast.success('Folga foi cadastrada com sucesso!');
          history.push('/admin/DayOff');
        })
        .catch((err) => toast.error(err.response.data.errors[0]));
    }
  };

  const toISOFormat = (dateNotFormmated) => {
    if (dateNotFormmated === undefined) {
      console.log('A data está vazia');
    } else {
      const [date] = dateNotFormmated.split(' ');
      const [DD, MM, YYYY] = date.split('/');
      return `${YYYY}/${MM}/${DD}`;
    }
  };
  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    alignItems: 'center'
                  }}
                >
                  <IconLogoHeader
                    backgroundColor={systemConfiguration.primary_color}
                  >
                    <BiAlarmOff color="white" size={25} />
                  </IconLogoHeader>
                  <h3>Folgas</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Nome</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{ required: false }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => ErrorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Tolerância para troca(Dias)
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="toleranceToChangeDayOff"
                            rules={{ required: false }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="toleranceToChangeDayOff"
                            render={({ message }) => ErrorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <label className="form-control-label">
                            Começar em:
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="beginDate"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="beginDate"
                            rules={dateRules}
                            // defaultValue={new Date().toLocaleDateString(
                            //   'pt-br'
                            // )}
                            defaultValue={''}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="beginDate"
                            render={({ message }) => ErrorFormMessage(message)}
                          />
                        </Col>
                        <Col>
                          <label className="form-control-label">
                            Padrão de repetição
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={[
                                  { value: 'Semanal', label: 'Semanal' },
                                  { value: 'Mensal', label: 'Mensal' },
                                  { value: 'Anual', label: 'Anual' }
                                ]}
                                isClearable={true}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                fieldName="repeatPattern"
                                labelName="label"
                                valueName="value"
                                defaultValue="Semanal"
                              />
                            )}
                            control={control}
                            name="repeatPattern"
                            defaultValue="Semanal"
                          />
                        </Col>
                        <Col>
                          <label className="form-control-label">
                            Intervalo de Repetições
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="repeatInterval"
                            rules={{ required: false }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="repeatInterval"
                            render={({ message }) => ErrorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      {watchField === 'Semanal' && (
                        <>
                          <Row>
                            <Col>
                              <label className="form-control-label">
                                Repetir:
                              </label>
                              <FormGroup
                                style={{
                                  marginLeft: '4rem',
                                  marginTop: '-2rem'
                                }}
                              >
                                <InputCheckbox>
                                  <input
                                    type="checkbox"
                                    checked={showDisabledsun}
                                    className="checkbox-round-sun"
                                    onChange={() =>
                                      setShowDisablesun(!showDisabledsun)
                                    }
                                  />
                                  <h5 id="input-sun">D</h5>
                                  <input
                                    type="checkbox"
                                    checked={showDisabledmon}
                                    className="checkbox-round-mon"
                                    onChange={() =>
                                      setShowDisablemon(!showDisabledmon)
                                    }
                                  />
                                  <h5 id="input-mon">S</h5>
                                  <input
                                    type="checkbox"
                                    checked={showDisabledtue}
                                    className="checkbox-round-tue"
                                    onChange={() =>
                                      setShowDisabletue(!showDisabledtue)
                                    }
                                  />
                                  <h5 id="input-tue">T</h5>
                                  <input
                                    type="checkbox"
                                    checked={showDisabledwed}
                                    className="checkbox-round-wed"
                                    onChange={() =>
                                      setShowDisablewed(!showDisabledwed)
                                    }
                                  />
                                  <h5 id="input-wed">Q</h5>
                                  <input
                                    type="checkbox"
                                    checked={showDisabledthu}
                                    className="checkbox-round-thu"
                                    onChange={() =>
                                      setShowDisablethu(!showDisabledthu)
                                    }
                                  />
                                  <h5 id="input-thu">Q</h5>
                                  <input
                                    type="checkbox"
                                    checked={showDisabledfri}
                                    className="checkbox-round-fri"
                                    onChange={() =>
                                      setShowDisablefri(!showDisabledfri)
                                    }
                                  />
                                  <h5 id="input-fri">S</h5>
                                  <input
                                    type="checkbox"
                                    checked={showDisabledsat}
                                    className="checkbox-round-sat"
                                    onChange={() =>
                                      setShowDisablesat(!showDisabledsat)
                                    }
                                  />
                                  <h5 id="input-sat">S</h5>
                                </InputCheckbox>
                              </FormGroup>
                            </Col>
                          </Row>
                        </>
                      )}

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Critério de termino
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={[
                                  { value: 'Nunca', label: 'Nunca' },
                                  {
                                    value: 'Especifico',
                                    label: 'Dia Específico'
                                  },
                                  {
                                    value: 'Ocorrencias',
                                    label: 'Após N° Ocorrências'
                                  }
                                ]}
                                placeholder="Nunca"
                                isClearable={true}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                fieldName="endCriteria"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="endCriteria"
                            defaultValue="Nunca"
                          />
                        </Col>
                        {watchFields === 'Especifico' && (
                          <Col>
                            <label className="form-control-label">
                              Dia de Término
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePickerIsValid
                                  fieldName="endDate"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="endDate"
                              rules={dateRules}
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                              //defaultValue={''}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="endDate"
                              render={({ message }) =>
                                ErrorFormMessage(message)
                              }
                            />
                          </Col>
                        )}
                        {watchFields === 'Ocorrencias' && (
                          <Col>
                            <label className="form-control-label">
                              Número de Ocorrências
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="numberOfOccurrences"
                              rules={{ required: false }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="numberOfOccurrences"
                              render={({ message }) =>
                                ErrorFormMessage(message)
                              }
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Usuários</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={usersDayOff}
                                placeholder="Selecione os usuários"
                                isClearable={true}
                                isMulti={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="userLogins"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="userLogins"
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginLeft: 'auto' }}>
                        <ReactButton btnColor="confirmation" type="submit">
                          Salvar
                        </ReactButton>
                        <ReactButton
                          btnColor="cancelation"
                          onClick={() => cancelAction()}
                        >
                          Cancelar
                        </ReactButton>
                      </Row>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
