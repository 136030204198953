import ServiceCRUD from '../../../../../services/ServiceCRUDFLEXCHAT';
import { apiChat } from '../../../../../http';

class ConversationReportService extends ServiceCRUD {
  constructor() {
    super('relatorio');
  }

  updateConversation = (id, data) => {
    return apiChat.put(`conversa/atualiza/encerramento/${id}`, data);
  };

  getSynteticReport(group, queryParams = {}) {
    return apiChat.get(`${this.path}/sintetico/${group}`, {
      params: {
        ...queryParams
      }
    });
  }

  getSynteticReportInterval(queryParams = {}){
    return apiChat.get(`${this.path}/message-interval`, {
      params: {
        ...queryParams
      }
    });
  }
}

export default new ConversationReportService();
