import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import FlexSelect from '../../../components/Inputs/FlexSelect';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import Service from '../service';
import { toast } from 'react-toastify';
//import { ddrRegex } from './regexs';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { useGetQueues } from 'views/User/form/hooks/useGetQueues';
import { useGetUsers } from 'views/Report/Audit/hooks/useGetUsers';
import { ScreenPrompt } from 'components/ScreenPrompt';

const WatsonForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { id } = props.match.params;

  const queues = useGetQueues()?.queues ?? [];
  const queuesOptionList = queues.map((queue) => {
    return { value: queue.id, label: queue.name };
  });
  const users = useGetUsers()?.users ?? [];
  const userOptionList = users.map((user) => {
    return { value: user.login, label: user.name };
  });

  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();

  const { isDirty } = formState;

  useEffect(() => {
    if (id) {
      setLoading(true);
      Service.getWhatsonById(id)
        .then((res) => {
          const data = {
            ...res.data.data,
            filaBotId: +res?.data?.data?.filaBotId
          };

          reset(data);

          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar canal', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    const user = users.find((item) => {
      return item.login === data.atendenteBotId;
    });
    data.nameAttendant = user?.name ?? '';
    try {
      setLoading(true);
      isUpdate
        ? await Service.putCanal('watson', { ...data, id: id })
        : await Service.postWhatson('watson', data);

      history.push('/admin/watson');
      if (isUpdate) {
        toast.info('Canal foi atualizada com sucesso!');
      } else {
        toast.success('Canal foi cadastrada com sucesso!');
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/watson');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/watson');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting} 
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/watson"
        buttonTitle="Voltar para a listagem de Watson"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Watson</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <>
                        <Row>
                          <Col md="6">
                            <label className="form-control-label">
                              Nome do Watson
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="nome_canal"
                              defaultValue={''}
                              rules={{
                                required: 'nome é um campo obrigatório'
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="nome_canal"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Descrição
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="descricao"
                              rules={{
                                required: 'descrição é um campo obrigatório'
                              }}
                              defaultValue={''}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="descricao"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <label className="form-control-label">
                              Assistant ID
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="assistantId"
                              rules={{
                                required: 'Assistant ID é um campo obrigatório'
                              }}
                              defaultValue={''}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="assistantId"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Api Key
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="apiKey"
                              rules={{
                                required: 'Api Key é um campo obrigatório'
                              }}
                              defaultValue={''}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="apiKey"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <label className="form-control-label">
                              WorkSpace id
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="workspaceId"
                              defaultValue={''}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="workspaceId"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Atendente Bot
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={false}
                                  dataOptions={userOptionList ?? []}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="atendenteBotId"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              rules={{
                                required:
                                  'Atendente Bot  é um campo obrigatório'
                              }}
                              control={control}
                              name="atendenteBotId"
                              defaultValue=""
                            />

                            <ErrorMessage
                              errors={errors}
                              name="atendenteBotId"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <label className="form-control-label">Fila</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={false}
                                  dataOptions={queuesOptionList ?? []}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="filaBotId"
                                  labelName="label"
                                  rules={{
                                    required: 'Fila é um campo obrigatório'
                                  }}
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="filaBotId"
                              defaultValue=""
                            />

                            <ErrorMessage
                              errors={errors}
                              name="filaBotId"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Status do Canal
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={false}
                                  dataOptions={[
                                    { value: true, label: 'Habilitado' },
                                    { value: false, label: 'Desabilitado' }
                                  ]}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="ativado"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="ativado"
                              defaultValue={true}
                            />

                            <ErrorMessage
                              errors={errors}
                              name="ativado"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col md="6">
                            <label className="form-control-label">
                              Fila em caso de falha
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={false}
                                  dataOptions={queuesOptionList ?? []}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="queueOnError"
                                  labelName="label"
                                  rules={{
                                    required: 'Fila é um campo obrigatório'
                                  }}
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="queueOnError"
                              defaultValue=""
                            />

                            <ErrorMessage
                              errors={errors}
                              name="queueOnError"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                      </>

                      <hr />

                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default WatsonForm;
