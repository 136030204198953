import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, CardHeader as CardHeaderReactStrap } from 'reactstrap';
import { Img, Input } from './styles';
import { ImageCropper } from './Cropper';
import ReactLoader from 'components/Loader';
export function CardHeader({
  image,
  setImage,
  setPreviewImage,
  previewImage,
  isLoading
}) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const [imageToCrop, setImageToCrop] = useState([]);
  const [isCropperOpen, setIsCropperOpen] = useState(false);

  const receiveImage = (event) => {
    if (event.target.files[0]) {
      setImageToCrop(URL.createObjectURL(event.target.files[0]));
      setIsCropperOpen(true);
      event.target = '';
    }
  };

  const onInputClick = (event) => {
    event.target.value = '';
  };

  return (
    <>
      <Row className="justify-content-center">
        <Col className="order-lg-2" lg="3">
          <div className="card-profile-image">
            {isLoading ? (
              <ReactLoader
                type="TailSpin"
                color={systemConfiguration.primary_color}
                height={100}
                width={100}
              />
            ) : (
              <label htmlFor="user_image">
                <Img
                  alt="..."
                  className="rounded-circle"
                  style={{ borderColor: systemConfiguration.primary_color }}
                  src={previewImage}
                />
              </label>
            )}
            <ImageCropper
              image={imageToCrop}
              setImage={setImage}
              setPreviewImage={setPreviewImage}
              isOpen={isCropperOpen}
              setIsOpen={setIsCropperOpen}
            ></ImageCropper>

            <Input
              id="user_image"
              onChange={receiveImage}
              onClick={onInputClick}
              type="file"
              accept="image/png,image/jpg,image/jpeg"
            ></Input>
          </div>
        </Col>
      </Row>
      <CardHeaderReactStrap className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4"></CardHeaderReactStrap>
    </>
  );
}
