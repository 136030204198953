import styled, { keyframes } from 'styled-components';

import { statusColors } from '../../../utils/state_colors';

const blinkAnimation = keyframes`
  0% {
    opacity: 0.1;
  }
`;

export const BadgeStatus = styled.span`
  background-color: ${({ status, showPauseAlert }) =>
    showPauseAlert ? '#9333ea' : statusColors[status]};
  height: 18px;
  width: 48px;
  border-radius: 10px;
  display: block;

  animation: ${({ showPauseAlert }) =>
      showPauseAlert ? blinkAnimation : 'none'}
    1.5s infinite;
`;
