import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';

const ModalHourMask = (props) => {
  const [mask, setMask] = useState([/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]);

  const onChange = (event) => {
    const time = event.target.value;
    if (time[0] === '2') {
      setMask([/[0-2]/, /[0-3]/, ':', /[0-5]/, /[0-9]/]);
    } else {
      setMask([/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]);
    }
    props.valueController(time);
  };

  const getInputValue = (value) => {
    return value;
  };

  return (
    <MaskedInput
      mask={mask}
      value={getInputValue(props.stateName)}
      onChange={(event) => onChange(event)}
      placeholder={props.placeholder}
      style={{
        width: '200px',
        height: '40px',
        fontWeight: '500',
        color: 'gray'
      }}
    />
  );
};

export default ModalHourMask;
