import { apiCrm } from 'http/index';

class LeadStageService {
  async create(data) {
    return apiCrm.post('/lead-stage', data);
  }

  async list(params, headers) {
    return apiCrm.get('/lead-stage', { params, headers });
  }

  async listOrdenade(recordTypeId) {
    return apiCrm.get(`/lead-stage/by-record-type/${recordTypeId}`);
  }

  async getById(id) {
    return apiCrm.get(`/lead-stage/get-by-id/${id}`);
  }

  async update(id, data) {
    return apiCrm.put(`/lead-stage/${id}`, data);
  }

  enable(id) {
    return apiCrm.put(`/lead-stage/${id}/enable`);
  }

  disable(id) {
    return apiCrm.put(`/lead-stage/${id}/disable`);
  }
}

export default new LeadStageService();
