import DefaultModal from '../../../../components/Modal/DefaultModal';
import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ProtocolService from '../../service';
import { toast } from 'react-toastify';
import { Row, Form, Col, Button } from 'reactstrap';
import SubReasonService from '../../../subReason/service';
import FlexSelect from '../../../../components/Inputs/FlexSelect';
import { validateFields } from '../../form/assets/functions';

const ModalStatus = ({
  isModalOpen,
  onModalToggle,
  idProtocol,
  idReasonProtocol,

  isRequired,
  render,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  const [subReason, setSubReason] = useState([]);
  const { control, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    SubReasonService.getByReasonId(idReasonProtocol)
      .then((response) => {
        const res = response.data.content;
        setSubReason(res);
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data) || 'Erro ao carregar status/fase';
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
        closeModal();
      });
  }, [closeModal, idReasonProtocol]);

  const onSubmit = async (data) => {
    try {
      let message;
      const dataToSubmit = { ...data, reason_id: idReasonProtocol };
      await ProtocolService.protocolUpdateData(
        idProtocol,
        dataToSubmit,
        'change_reason_subreason'
      );

      message = 'Submotivo do protocolo atualizado com sucesso';
      toast.success(message, {
        autoClose: 3000,
        closeOnClick: true
      });
      render(subReason.find((item) => item.id === data.subreason_id));
      closeModal();
    } catch (err) {
      toast.error(' Erro ao atualizar o protocolo', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Alterar Submotivo do Protocolo"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col md="12">
              <label className="form-control-label">Submotivo*</label>
              <Controller
                render={(props) => (
                  <FlexSelect
                    isClearable={true}
                    dataOptions={subReason}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    value={props.value}
                    valueController={setValue}
                    fieldName="subreason_id"
                    labelName="name"
                    valueName="id"
                  />
                )}
                control={control}
                name="subreason_id"
                rules={validateFields(isRequired)}
                defaultValue=""
              />
              <ErrorMessage
                errors={errors}
                name="subreason_id"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>
          <hr />
          <Button color="primary" type="submit">
            Salvar
          </Button>
          <Button color="danger" onClick={closeModal}>
            Voltar
          </Button>
        </Form>
      </>
    </DefaultModal>
  );
};

export default ModalStatus;
