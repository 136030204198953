import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { Comment, ReadMore } from './styled';

import ProtocolService from '../../../service';

const loaderContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const TimelineProtocolHistory = ({ protocolId }) => {
  const [protocolData, setProtocolData] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!protocolId) return;

    setLoading(true);

    ProtocolService.getProtocolHistory(protocolId)
      .then((response) => {
        setProtocolData(response.data.content);
      })
      .catch((error) => {
        const msg =
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
          'Erro ao recuperar histórico do protocolo';

        toast.error(msg, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, [protocolId]);

  const CommentDiv = ({ comment }) => {
    let myHtml = `<div>${comment} </div>`;
    if (comment.length === 500) myHtml = `<div>${comment}... </div>`;

    return <div dangerouslySetInnerHTML={{ __html: myHtml }} />;
  };

  const getFormattedDateAndHour = (stringDate) => {
    const date = new Date(stringDate);
    const formattedDate = date.toLocaleDateString('pt-br');
    const formattedHour = date.toLocaleTimeString('pt-br');

    return `${formattedDate} às ${formattedHour}`;
  };

  return (
    <>
      {loading ? (
        <div style={loaderContainerStyle}>
          <Loader type="TailSpin" color="#9ab744" height={50} width={50} />
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card>
                <CardHeader className="bg-transparent">
                  <p>
                    Protocolo N°{' '}
                    <span className="font-weight-bold">
                      {protocolData.number}
                    </span>
                  </p>
                  <p>{protocolData.title}</p>
                  <p className="small">{protocolData.description}</p>
                </CardHeader>
                <CardBody>
                  {protocolData &&
                    (protocolData.history || []).map((historyItem, index) => (
                      <div
                        key={index}
                        className="timeline timeline-one-side mx-0"
                        data-timeline-axis-style="dashed"
                        data-timeline-content="axis"
                      >
                        <div className="timeline-block pb-5">
                          <span className="timeline-step badge-success">
                            <i className="ni ni-calendar-grid-58" />
                          </span>

                          <div
                            className="timeline-content"
                            style={{ maxWidth: '50rem' }}
                          >
                            <small className="text-muted font-weight-bold">
                              {getFormattedDateAndHour(historyItem.date)}
                            </small>

                            <h5 className="mt-3 mb-0">
                              {historyItem.actionType}
                            </h5>

                            {historyItem.current_responsible && (
                              <p className="text-sm mt-1 mb-0">
                                Responsável: {historyItem.current_responsible}
                              </p>
                            )}

                            {historyItem.current_status && (
                              <p className="text-sm mt-1 mb-0">
                                Status/Fase: {historyItem.current_status}
                              </p>
                            )}

                            {historyItem.comment && (
                              <div
                                className="text-sm mt-1 mb-0"
                                style={{
                                  whiteSpace: 'pre-wrap'
                                }}
                              >
                                {historyItem.channel === 'flexuc'
                                  ? historyItem.user
                                  : historyItem.from}
                                :
                                {historyItem.comment.length > 500 ? (
                                  <>
                                    <Comment>
                                      <CommentDiv
                                        comment={historyItem.comment.slice(
                                          0,
                                          500
                                        )}
                                      />
                                    </Comment>
                                    <ReadMore
                                      onClick={(e) => {
                                        const commentDiv =
                                          document.createElement('div');
                                        ReactDOM.render(
                                          <CommentDiv
                                            comment={historyItem.comment}
                                          />,
                                          commentDiv
                                        );
                                        e.currentTarget.parentNode.innerHTML =
                                          commentDiv.innerHTML;
                                      }}
                                    >
                                      <span>Mostrar mais</span>
                                    </ReadMore>
                                  </>
                                ) : (
                                  <Comment>
                                    <CommentDiv comment={historyItem.comment} />
                                  </Comment>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default TimelineProtocolHistory;
