import { reports as http, apiDownloadAudio } from '../http';

class serviceReports {
  constructor(path) {
    this.path = path;
  }
  //types == analytical, synthetic, pdf, excel, detailedsynthetic
  getAllReportsType(type, page, queryParams = {}, quantity = 10) {
    return http.get(`${this.path}/${type}`, {
      params: {
        quantity: quantity,
        page: page,
        ...queryParams
      }
    });
  }
  getAllReportsTypeMonitorScreen(type, page, queryParams = {}) {
    return http.get(`${this.path}/${type}`, {
      params: {
        quantity: 1_000_000,
        page: page || 1,
        ...queryParams
      }
    });
  }

  getDownloads(type, page, format, queryParams = {}) {
    return http.get(`${this.path}/${type}/${format}`, {
      params: {
        quantity: 10,
        page: page,
        ...queryParams
      },
      responseType: 'blob'
    });
  }

  downloadAudio = (uniqueId, date = null) => {
    return apiDownloadAudio.get(`audio/${uniqueId}/${date}`, {
      responseType: 'blob'
    });
  };

  streamAudio = (uniqueId, date = null) => {
    return `${
      process.env.REACT_APP_AUDIO_URL
    }stream/${uniqueId}/${date}?authorization=Bearer ${localStorage.getItem(
      'token'
    )}`;
  };
}

export default serviceReports;
