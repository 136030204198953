import styled from 'styled-components';

export const Container = styled.div`
  width: max-content;
  height: 100%;
  padding: 0 5px;
  overflow: auto;

  width: 100%;
  display: flex;
  gap: 0.5rem;
  overflow-x: auto;

  .cardCollumnWrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: ${(props) => (props.isProtocol ? '370px' : '300px')};
  }
`;

export const CardHeader = styled.div`
  border-radius: 0.3rem 0.3rem 0 0;
  background-color: #e9ecef;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  text-align: center;
  height: 43px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
  position: 'relative';
`;

export const CardContainer = styled.div`
  overflow: hidden;
  max-height: 100%;
  height: ${(props) => (props.isProtocol ? '600px' : '450px')};
  min-width: 100%;
  border-radius: 0 0 0.3rem 0.3rem;
  /* background-color: #fafbfc; */
  background-color: white;
  /* border: 1px solid #ebecf0; */
  /* padding: 0 10px; */
  padding-top: 10px;

  & > div > div.List.ListTable {
    overflow-x: hidden !important;
    overflow-y: hidden !important;

    :hover {
      overflow-x: hidden !important;
      overflow-y: auto !important;
    }
  }
`;

export const Card = styled.div`
  :root {
    --background-lista: #fafbfc;
  }

  /* :hover {
    scale: 1.03;
  } */
  transition: all ease-in-out 0.1s;

  .card-padding-before {
    width: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
  --background-lista: #fafbfc;

  .card-padding-before:before {
    content: '';
    /* background-color: var(--background-lista); */
    background-color: white;
    height: 5px;
    width: calc(100% + 50px);
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
  }

  .card-padding-before:after {
    content: '';
    background-color: var(--background-lista);
    height: 5px;
    width: calc(100% + 50px);
    z-index: 10;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  overflow: hidden;
  cursor: pointer;
  /* background-color: #ffffff; */
  background-color: #fafbfc;
  min-height: 25px;
  padding: ${(props) => (props.isProtocol ? '0.25rem' : '0.5rem')};
  border-radius: 0.3rem;
  width: 95%;

  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  align-items: start;
  box-shadow: 0 1px 2px 1px #0000000f;

  p,
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
  }
  p {
    font-weight: 500;
  }
  span {
    position: absolute;
    top: 20px;
    font-weight: 300;
    max-width: 100%;
    font-size: 0.9rem;
  }

  padding: 20px;

  .CardContent {
    position: relative;
    width: 175px;

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

  .button-container {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    justify-content: end;

    button {
      margin-right: 2px;
      scale: 0.9;
      opacity: 0.6;
    }
  }
`;

export const CardProtocol = styled.div`
  :root {
    --background-lista: #fafbfc;
  }

  transition: all ease-in-out 0.1s;

  .card-padding-before {
    width: 100%;
    display: grid;
    grid-template-columns: 3fr 1fr;
    height: 100%;
  }
  --background-lista: #fafbfc;

  .card-padding-before:before {
    content: '';
    /* background-color: var(--background-lista); */
    background-color: white;
    height: 5px;
    width: calc(100% + 50px);
    z-index: 10;
    position: absolute;
    left: 0;
    top: 0;
  }

  .card-padding-before:after {
    content: '';
    background-color: var(--background-lista);
    height: 5px;
    width: calc(100% + 50px);
    z-index: 10;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  overflow: hidden;
  cursor: pointer;
  /* background-color: #ffffff; */
  background-color: #fafbfc;
  height: 160px;
  padding: ${(props) => (props.isProtocol ? '0.25rem' : '0.5rem')};
  border-radius: 0.3rem;
  width: 95%;

  margin-bottom: 5px;
  display: flex;
  align-items: start;
  box-shadow: 0 1px 2px 1px #0000000f;

  p,
  span {
    cursor: default;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1rem;
  }
  p {
    font-weight: 500;
  }
  span {
    position: absolute;
    top: 20px;
    font-weight: 300;
    max-width: 100%;
    font-size: 0.9rem;
  }

  padding: 20px;

  .CardContent {
    position: relative;
    width: 275px;

    .grid-container {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      width: 100%;
      align-content: center;
      justify-content: center;
      align-items: center;
      gap: 0.1rem;
    }

    label {
      margin: 0;
      font-size: 0.85rem;
      color: black;
    }

    p {
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      margin-bottom: 0;
      font-size: 0.85rem;
      color: #424269;
    }
  }

  .button-container {
    display: flex;
    margin-left: auto;
    justify-content: space-between;
    flex-direction: column;

    button {
      margin-right: 2px;
      scale: 0.9;
      opacity: 0.6;
    }
  }
`;
