export const typesOfReports = [
  { value: 'analytical', label: 'Analítico' },
  { value: 'synthetic', label: 'Sintético' }
];

export const typesOfLogouts = [
  {
    label: 'Logout voluntário',
    value: 'logout_voluntario'
  },
  {
    label: 'Logout involuntário',
    value: 'logout_involuntario'
  },
  {
    label: 'Logout voluntário por fechamento de aba',
    value: 'logout_voluntario_por_fechamento_de_aba'
  },
  {
    label: 'Logout por sessão expirada',
    value: 'logout_por_token_expirado'
  },
  {
    label: 'Logout por novo login',
    value: 'logout_por_novo_login'
  }
];
