import React, { useContext } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { AgentCtx } from '../../contexts/AgentCtx';

import AgentCard from './components/AgentCard';
import QueueList from './components/QueueList';
import TotalLoginCard from './components/TotalLoginCard';
import TimeLoginCard from './components/TimeLoginCard';
import TotalCallsAnsweredCard from './components/TotalCallsAnsweredCard';
import TotalCallsMadeCard from './components/TotalCallsMadeCard';
import TMACard from './components/TMACard';
import AgentPauseList from './components/AgentPauseList';

const AgentIndicators = ({ agent, extension }) => {
  const { agentIndicators, queues } = useContext(AgentCtx);

  return (
    <>
      <Container className="p-0">
        <Row>
          <Col xl="8">
            <AgentCard agent={agent} extension={extension} />
          </Col>
          <Col xl="4">
            <QueueList
              data={queues.map(({ id, name }) => ({
                id,
                name
              }))}
            />
          </Col>
        </Row>
        <Row>
          <Col xl="6">
            <TotalLoginCard data={agentIndicators.qtdLogin} />
          </Col>
          <Col xl="6">
            <TimeLoginCard data={agentIndicators.timeLogin} />
          </Col>
          <Col xl="6">
            <TotalCallsAnsweredCard data={agentIndicators.qtdCallsAnswered} />
          </Col>
          <Col xl="6">
            <TotalCallsMadeCard data={agentIndicators.qtdCallsMade} />
          </Col>
          <Col xl="6">
            <TMACard data={agentIndicators.agentTMA} />
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <AgentPauseList data={agentIndicators.agentPauses} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AgentIndicators;
