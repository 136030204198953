import React, { useState, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import UserService from '../../views/User/service';
// import { useGetExtensions } from '../../views/User/form/hooks/useGetExtensions';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { apiUc } from '../../http';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Row,
  Col
} from 'reactstrap';
import {
  getLogoURL,
  getLogoSocialURL
} from '../../views/SystemParams/services/getLogoURL';
import './index.css';
// import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { buttonStyle } from './components/utils/index';
import FlexSelectLogin from './components/FlexSelectLogin/FlexSelectLogin';

export default function LoginAutoAuth() {
  const [logoURL, setLogoURL] = useState();
  const [logoSocialURL, setLogoSocialURL] = useState();
  const [dataqueue, setdataqueue] = useState();
  const [dataagent, setdataagent] = useState();
  const [login, setLogin] = useState();
  const [agentSessions, setAgentSessions] = useState([]);
  const [extensions, setExtensions] = useState([]);
  // const { extensions } = useGetExtensions();
  const { control, setValue, handleSubmit } = useForm();

  const history = useHistory();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  useEffect(() => {
    if (login) {
      UserService.get(login)
        .then((response) => {
          setExtensions(response.data.data.extensions);
        })
        .catch((err) => {
          console.log(err);
          toast.error('Erro ao carregar usuário!', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [login]);
  useEffect(() => {
    //   getDefaultTheme();
    getLogoURL()
      .then((source) => {
        setLogoURL(source);
      })
      .catch(() => console.error('[LOGIN AUTO AUTH FORM] Error loading logo'));

    getLogoSocialURL()
      .then((source) => {
        setLogoSocialURL(source);
      })
      .catch(() =>
        console.error('[LOGIN AUTO AUTH FORM] Error loading social logo')
      );
  }, []);
  useEffect(() => {
    localStorage.setItem('isLoginExtension', true);
  }, []);
  useEffect(() => {
    const datafila = localStorage.getItem('queuesToSignId');
    const datafilaFormated = JSON.parse(datafila);
    let arrayDataFila = [];
    datafilaFormated.forEach((res) => {
      arrayDataFila.push(res.id);
    });
    setdataqueue(arrayDataFila);
  }, []);
  useEffect(() => {
    const dataagent = localStorage.getItem('agent_code');
    setdataagent(dataagent);
  }, []);
  useEffect(() => {
    const login = localStorage.getItem('login');
    setLogin(login);
  }, []);

  useEffect(() => {
    const agentCode = localStorage.getItem('agent_code');
    apiUc
      .get(`/api/agent-status/${agentCode}`)
      .then((response) => {
        const sessionData = response.data.data;
        setAgentSessions(sessionData);
      })
      .catch(() => {
        setAgentSessions([]);
      });
  }, []);
  const onSubmit = useCallback(
    async (data) => {
      if (agentSessions.length === 0) {
        localStorage.setItem('isLoginExtension', false);
        await apiUc
          .post(`/api/agent-session`, {
            extension: data.extension,
            queueIds: dataqueue,
            agent_code: dataagent
          })
          .then(() => {
            history.push('/admin/dashboard/');
          })
          .catch((err) => {
            toast.error(err.response?.data?.message ?? 'Ocorreu um erro');
          });
      }
      agentSessions.map((res) => {
        if (res.extension === data.extension) {
          localStorage.setItem('isLoginExtension', false);
          history.push('/admin/dashboard/');
        } else {
          toast.error(
            'O ramal escolhido não é o mesmo logado na fila, Por favor mudar para o ramal:' +
              res.extension
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataagent, dataqueue, agentSessions]
  );
  const cancelAction = () => {
    localStorage.removeItem('token');
    history.push('/activeLogout');
  };

  return (
    <div className="login-container fadeIn">
      <div className="logo">
        <img src={logoSocialURL} alt="logo" />
      </div>
      <div className="form-container">
        <div className="img-logo-g4flex">
          <img src={logoURL} style={{ maxWidth: '200px' }} alt="logo_g4" />
        </div>
        <Card className="bg-secondary border-0 mb-0">
          <CardHeader className="bg-transparent pb-2">
            <div className="text-muted text-center mt-2 mb-3">
              <h3>{systemConfiguration.welcome_msg}</h3>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            <Form role="form" onSubmit={handleSubmit(onSubmit)}>
              <FormGroup
                className={classnames('mb-3', {
                  focused: 'login'
                })}
              >
                <Row
                  className="mb-3"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <label>Ramal para Login</label>
                  </Col>
                </Row>
                <Row
                  className="mb-3"
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Col md="6">
                    <Controller
                      render={(props) => (
                        <FlexSelectLogin
                          dataOptions={extensions}
                          placeholder="Selecione o ramal"
                          isClearable={true}
                          closeMenuOnSelect={true}
                          isMulti={false}
                          value={props.value}
                          valueController={setValue}
                          fieldName="extension"
                          labelName="extension_number"
                          valueName="extension_number"
                        />
                      )}
                      control={control}
                      name="extension"
                      defaultValue=""
                    />
                  </Col>
                </Row>
              </FormGroup>
              <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  className="my-4 g4-button"
                  type="submit"
                  style={buttonStyle(systemConfiguration.primary_color)}
                >
                  Entrar
                </Button>
                <Button
                  color="danger"
                  className="my-4 g4-button"
                  style={{ width: '7rem' }}
                  onClick={cancelAction}
                >
                  Cancelar
                </Button>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
}
