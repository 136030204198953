import React, { useEffect } from 'react';
import { AddNewContactButton } from 'components/Buttons/AddNewContactButton';
import CreateAndEditTagClient from 'views/CRM/Tags/components/ModalCreateTag';
import TagClientService from 'views/CRM/Tags/services/TagClientService';
import { getFlexSelectCustomStyle } from '../../assets/styles/multiple_select';
import Select from 'react-select';

export const SearchTagClientInput = ({
  setValueSelected = () => {},
  defaultValue = null,
  style = {},
  minHeight = '',
  noOptionsMessage = () => 'Nenhuma Opção'
}) => {
  const [isModalTagsOpen, setIsModalTagsOpen] = React.useState(false);
  const [tagsSelected, setTagsSelected] = React.useState(defaultValue);
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    findTags();
  }, []);

  const findTags = async () => {
    const result = await TagClientService.getAll({ showDisabled: true });
    const resultDTO = result.data?.data?.map((tag) => ({
      label: tag.name,
      value: tag.id
    }));

    setOptions(resultDTO);
    return resultDTO;
  };

  const controlChange = (value) => {
    setValueSelected(value);
    setTagsSelected(value);
  };

  return (
    <div className="d-flex">
      <div style={{ flexGrow: '10' }} className="tour3">
        <label className="form-control-label text-capitalize">Tags</label>
        <Select
          isSearchable={false}
          isClearable={true}
          isMulti={true}
          name="tags"
          placeholder={'Selecione uma tag'}
          styles={{ ...getFlexSelectCustomStyle({ minHeight }), ...style }}
          value={tagsSelected}
          onChange={controlChange}
          closeMenuOnSelect={false}
          classNamePrefix="react-select"
          noOptionsMessage={noOptionsMessage}
          options={options}
        />
      </div>

      <div
        style={{
          alignSelf: 'flex-end',
          justifyContent: 'space-between',
          padding: '0.6rem 0.6rem'
        }}
      >
        <AddNewContactButton onClick={setIsModalTagsOpen} />
        <CreateAndEditTagClient
          isModalOpen={isModalTagsOpen}
          onModalToggle={setIsModalTagsOpen}
        />
      </div>
    </div>
  );
};
