import React, { useState } from 'react';
import { Input, Button, Row, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Modal, ModalBody } from 'reactstrap';
import { FiLink } from 'react-icons/fi';
import { addMessageConversationInAttendance } from '../../../../../../../store/action/conversation-in-attendance-action';
import { useSelector } from 'react-redux';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { addMessageAnsweredConversation } from 'store/action/answered-conversations-action';
import {
  apiChat,
  apiInstagramChat,
  apiSMSChat
} from '../../../../../../../http';

const UploadFile = ({ id: conversationId, conversation }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [link, setLink] = useState('');
  const [invalid, setInvalid] = useState('');
  const dispatch = useDispatch();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const onSubmit = () => {
    var expression =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);

    if (link.match(regex)) {
      sendLink();
    } else {
      setInvalid('Link inválido. Ex: http://g4flex.com.br/');
    }
  };

  const sendLink = async () => {
    try {
      const messageData = {
        cliente_ou_atendente: 'atendente',
        escrita_por: localStorage.getItem('name'),
        texto: link,
        response_type: 'link'
      };

      let response;
      let responsePayload;

      if (conversation.canal === 'instagram') {
        response = await apiInstagramChat.post(
          `/instagram/send-message/${conversation.id}`,
          {
            idDaConversa: conversation.id,
            conversa: {
              mensagem: {
                cliente_ou_atendente: 'atendente',
                escrita_por: localStorage.getItem('name'),
                texto: messageData.texto,
                response_type: 'text'
              }
            }
          }
        );
        responsePayload = response.data.data;
      } else if (conversation.canal === 'whatsapp') {
        response = await apiInstagramChat.post(
          `/whatsapp/send-message/text/${conversation.id}`,
          {
            idDaConversa: conversation._id,
            conversa: {
              mensagem: { ...messageData }
            }
          }
        );

        responsePayload = response.data.data;
      } else if (conversation.canal === 'sms') {
        response = await apiSMSChat.post(
          `/sms/send-message/${conversation._id}`,
          {
            idDaConversa: conversation._id,
            conversa: {
              mensagem: {
                cliente_ou_atendente: conversation.cliente.id_zenvia_sms,
                escrita_por: localStorage.getItem('name'),
                texto: messageData.texto,
                response_type: 'text'
              }
            }
          }
        );
        responsePayload = response.data.data;
      } else if (conversation.canal === 'chat') {
        response = await apiSMSChat.post(`/webchat/send-message/text`, {
          chatId: conversation._id || conversation.id,

          message: { ...messageData }
        });

        responsePayload = response.data.data;
      } else if (conversation.canal === 'flex4u') {
        response = await apiSMSChat.post(`/flex4u/send-message/text`, {
          chatId: conversation._id,

          message: { ...messageData }
        });

        responsePayload = response.data.data;
      } else {
        response = await apiChat.post(`/conversa/atendente-enviar-mensagem`, {
          chatId: conversation._id ?? conversation.id,
          message: {
            mensagem: { ...messageData }
          }
        });
        responsePayload = response.data.message;
      }

      const conversationId = conversation._id
        ? conversation._id
        : conversation.id;

      await dispatch(
        addMessageConversationInAttendance(
          { ...responsePayload },
          conversationId
        )
      );
      //updating list of answeredConversations in store
      await dispatch(
        addMessageAnsweredConversation({ ...responsePayload }, conversationId)
      );
      toggleModal();
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
    setLink('');
    setInvalid('');
  };

  const handleLinkChange = (event) => {
    const text = event.target.value;
    setLink(text);
    setInvalid('');
  };

  const handlePressEnter = (event) => {
    if (link !== '' && event.key === 'Enter') {
      event.preventDefault();
      onSubmit();
    }
    if (link === '' && event.key === 'Enter') event.preventDefault();
  };

  return (
    <>
      <Button
        className="shadow-none bottomBtn"
        color="link"
        size="sm"
        title="Enviar link"
        onClick={toggleModal}
      >
        <FiLink />
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Enviar link
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>&times;</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />
          <Input
            name="link"
            placeholder="Insira o link..."
            onChange={handleLinkChange}
            onKeyPress={handlePressEnter}
          />
          <span style={{ color: 'red' }}>{invalid}</span>
          <Row className="mt-5">
            <Col>
              <Button
                style={buttonStyle(
                  systemConfiguration.confirmation_button_color
                )}
                onClick={onSubmit}
              >
                Enviar
              </Button>
              <Button
                style={buttonStyle(
                  systemConfiguration.cancelation_button_color
                )}
                color="danger"
                onClick={toggleModal}
              >
                Cancelar
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default UploadFile;
