import ReactButton from 'components/Buttons/ReactButton';
import React from 'react';
import { useHistory } from 'react-router-dom';

const Buttons = ({ isSaving }) => {
  const history = useHistory();

  const cancelAction = () => {
    history.push('/admin/configuration');
  };

  return (
    <>
      <hr />
      <ReactButton btnColor="confirmation" type="submit" disabled={isSaving}>
        Salvar
      </ReactButton>

      <ReactButton
        btnColor="cancelation"
        onClick={() => cancelAction()}
        disabled={isSaving}
      >
        Cancelar
      </ReactButton>
    </>
  );
};

export default Buttons;
