import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import SpyService from '../service';
import UserService from '../service/userService';
import grouSpyService from '../service/groupService';
import { toast } from 'react-toastify';
// import { regex } from './regexs';
import FlexSelect from 'components/Inputs/FlexSelect';
import { ScreenPrompt } from 'components/ScreenPrompt';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';

const SectorForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);

  const [users, setUsers] = useState([]);
  const [groupSpy, setGroupSpy] = useState([]);

  const { id } = props.match.params;

  // Get users to select
  useEffect(() => {
    UserService.getAll()
      .then((response) => {
        setUsers(response.data.data);
      })
      .catch(() => {
        console.log('Erro ao ler os ramais');
      });
  }, []);
  // Get group to select
  useEffect(() => {
    grouSpyService
      .getAll()
      .then((response) => {
        setGroupSpy(response.data.data);
      })
      .catch(() => {
        console.log('Erro ao ler os ramais');
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      SpyService.get(id)
        .then((res) => {
          let getSector = res.data.data;

          let arrayGroupId = [];
          getSector.spyGroups.forEach((group) => {
            arrayGroupId.push(group.id);
          });

          getSector = { ...getSector, spyGroupIds: arrayGroupId };
          reset(getSector);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar espião', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        isUpdate
          ? await SpyService.update(id, data)
          : await SpyService.create(data);
        history.push('/admin/spy');

        if (isUpdate) {
          // setLoading(false);
          toast.info('Espião foi atualizada com sucesso!');
        } else {
          //setLoading(false);
          toast.success('Espião foi cadastrada com sucesso!');
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
        history.push('/admin/spy');
      }
    },
    [history, isUpdate, id]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/spy');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/spy"
        buttonTitle="Voltar para a pagina de espião"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Espião</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md={{ size: 6 }}>
                          <label className="form-control-label">Usuário*</label>
                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                {users.map((user, index) => (
                                  <option key={index} value={user.agent_code}>
                                    {`${user.name} - ${user.agent_code}`}
                                  </option>
                                ))}
                              </Input>
                            }
                            control={control}
                            name="user_id"
                            rules={{
                              required: 'Usuario é um campo obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="user_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Grupo(s)*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={groupSpy}
                                isMulti={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="spyGroupIds"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="spyGroupIds"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="spyGroupIds"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SectorForm;
