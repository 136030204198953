import React, { useState, useCallback, useEffect } from 'react';
import { Row } from 'reactstrap';

import Loader from 'components/Loader';
import { toast } from 'react-toastify';

import Pagination from 'components/Pagination';
import { AnalyticalOpportunityTime } from '../../../../components/Tables/AnalyticalOpportunityTime';
import { SyntheticOpportunityTime } from '../../../../components/Tables/SyntheticOpportunityTime';
import { useListOpportunities } from '../../../hooks/useListOpportunityTime';
import FilterOpportunityTimeForm from '../FilterOpportunityTime';

const ReportOpportunityTime = ({
  selectedRecordTypeId,
  setSelectedRecordType,
  recordTypes
}) => {
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [analyticalData, setAnalyticalData] = useState([]);
  const [reportType, setReportType] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [syntheticalData, setSyntheticalData] = useState([]);
  const [dataToSend, setDataToSend] = useState({});

  const {
    getReportOpportunityTime,
    opportunities,
    totalOpportunities,
    getReportSyntheticOpportunityTime,
    syntheticData
  } = useListOpportunities({
    recordTypeId: selectedRecordTypeId
  });

  const handleSearch = useCallback(
    (searchFilters, reportTypeSubmit, page = 1) => {
      setActivePage(1);
      setReportType(reportTypeSubmit);
      if (reportTypeSubmit === 1) {
        getReportOpportunityTime(page, searchFilters);
      }

      if (reportTypeSubmit === 2) {
        getReportSyntheticOpportunityTime(page, searchFilters);
      }
      setIsLoading(false);
    },
    [getReportOpportunityTime, getReportSyntheticOpportunityTime]
  );

  useEffect(() => {
    if (reportType === 1) {
      if (opportunities && opportunities.length) {
        setAnalyticalData(opportunities);
        setQuantity(totalOpportunities);
      }

      if (opportunities && !opportunities.length) {
        setAnalyticalData(opportunities);
        setQuantity(totalOpportunities);
        toast.info('Nenhum registro foi encontrado');
      }
    }
    if (reportType === 1) {
      if (syntheticData) {
        setSyntheticalData(syntheticData);
      }
    }
  }, [opportunities, totalOpportunities, reportType, syntheticData]);

  const handlePageChange = (page) => {
    setActivePage(page);
    getReportOpportunityTime(page, dataToSend);
  };

  return (
    <>
      <FilterOpportunityTimeForm
        handleSearch={handleSearch}
        recordTypeId={selectedRecordTypeId}
        opportunities={opportunities}
        setSelectedRecordType={setSelectedRecordType}
        recordTypes={recordTypes}
        analyticalData={analyticalData}
        setAnalyticalData={setAnalyticalData}
        quantity={quantity}
        setDataToSend={setDataToSend}
        reportType={reportType}
      />

      {reportType === 2 ? <hr /> : null}
      {analyticalData && analyticalData.length > 0 && reportType === 1 ? (
        isLoading ? (
          <div className="col-12">
            <Row className="justify-content-md-center">
              <Loader />
            </Row>
          </div>
        ) : (
          <div style={{ marginTop: '1rem' }}>
            <AnalyticalOpportunityTime data={analyticalData} />
            <Pagination
              activePage={activePage}
              totalItemsCount={quantity}
              onChange={handlePageChange.bind(this)}
            />
          </div>
        )
      ) : null}
      {syntheticalData && reportType === 2 ? (
        isLoading ? (
          <div className="col-12">
            <Row className="justify-content-md-center">
              <Loader />
            </Row>
          </div>
        ) : (
          <div style={{ marginTop: '1rem' }}>
            <SyntheticOpportunityTime data={syntheticData} />
          </div>
        )
      ) : null}
    </>
  );
};

export default ReportOpportunityTime;
