export const sortData = (data, orderingParameter, ordenationType = 'DESC') => {
  const dataSort = data.sort((a, b) => {
    if (ordenationType === 'ASC') {
      if (a[orderingParameter]) {
        if (a[orderingParameter] < b[orderingParameter]) return -1;
        if (a[orderingParameter] > b[orderingParameter]) return 1;
        return 0;
      } else {
        if (a[orderingParameter] < b[orderingParameter]) return -1;
        if (a[orderingParameter] > b[orderingParameter]) return 1;
        return 0;
      }
    } else if (ordenationType === 'DESC') {
      if (b[orderingParameter]) {
        if (b[orderingParameter] < a[orderingParameter]) return -1;
        if (b[orderingParameter] > a[orderingParameter]) return 1;
        return 0;
      } else {
        if (b[orderingParameter] < a[orderingParameter]) return -1;
        if (b[orderingParameter] > a[orderingParameter]) return 1;
        return 0;
      }
    }

    return null;
  });

  return dataSort;
};

// Para classificar um array de números em ordem numérica decrescente, basta inverter a lógica na função de comparação,
// crescente => a > b || decrescente => b > a
