export const queueAgentEvents = Object.freeze({
  QUEUE_MEMBER_RING: 'queue_member_ring',
  QUEUE_MEMBER_RINGING: 'queue_member_ringing',
  QUEUE_MEMBER_PAUSE: 'queue_member_pause',
  QUEUE_MEMBER_AVAILABLE: 'queue_member_available',
  QUEUE_MEMBER_UNREGISTERED: 'queue_member_unregistered',
  QUEUE_MEMBER_REMOVED: 'queue_member_removed',
  QUEUE_MEMBER_ADDED: 'queue_member_added',
  GET_QUEUE_MEMBERS_COMPLETE: 'get_queue_members_complete',
  GET_QUEUE_MEMBERS: 'get_queue_members',
  QUEUE_MEMBER: 'queue_member',
  QUEUE_MEMBER_PAUSE_ALERT: 'queue_member_pause_alert',
  QUEUE_MEMBER_TABULATION_PAUSE: 'queue_member_pause_tabulation'
});
