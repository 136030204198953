import ServiceCRUD from '../../../../../services/ServiceCRUDFLEXCHAT';
import { apiChat } from '../../../../../http';

// class WordCloudService extends ServiceCRUD {
//   constructor() {
//     super('relatorio');
//   }

//   getWordCloud(queryParams) {
//     return apiChat.get(`${this.path}/nuvem-de-palavras`, {
//       params: {
//         ...queryParams
//       }
//     });
//   }
// }

// export default new WordCloudService();

class ScoreboardService extends ServiceCRUD {
  constructor() {
    super('relatorio');
  }

  getScoreboard(queryParams) {
    return apiChat.get(`${this.path}/scoreboard`, {
      params: {
        ...queryParams
      }
    });
  }

  getScoreboardFastApi(queryParams) {
    return apiChat.get(`${this.path}/scoreboard-fast-api`, {
      params: {
        ...queryParams
      }
    });
  }
}

export default new ScoreboardService();
