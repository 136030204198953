/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReactLoader from 'components/Loader';
import ExtensionService from '../service';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'components/Pagination';
import { useHistory } from 'react-router-dom';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';

import {
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  Input,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

import MultiButtonsHeader from '../../../components/Headers/MultiButtons';
import {
  select_field_row_style,
  icon_column_style,
  individual_column_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import { toast } from 'react-toastify';

const ExtensionList = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [extensions, setExtensions] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [showDisabled, setShowDisable] = useState(false);
  const [timeoutID, setTimeoutID] = useState(null); // Timeout da busco por texto

  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    handleGetExtensions();
  }, [showDisabled]);

  const handleGetExtensions = (selectedPage = 1, querySearch = '') => {
    setLoading(true);

    ExtensionService.getAll(false, selectedPage, 10, showDisabled, querySearch)
      .then((res) => {
        const { data, totalRegisters } = res.data;
        const newActivePage = selectedPage || activePage;

        setExtensions(data);
        setQuantity(totalRegisters);
        setActivePage(newActivePage);
      })
      .catch(() => {
        toast.error('Não foi possível listar as ramais', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (activePage) => handleGetExtensions(activePage);

  const toggleExtensionStatus = (isDisabled, extension_number) => {
    if (isDisabled) {
      handleEnableExtension(extension_number);
    } else {
      handleDeleteExtension(extension_number);
    }
  };

  const handleDeleteExtension = (extension_number) => {
    if (!window.confirm('Desabilitar ramal?')) return;
    ExtensionService.delete(extension_number)
      .then(() => {
        toast.success('Ramal desabilitado com sucesso', {
          autoClose: 3000,
          closeOnClick: true
        });
        handleGetExtensions();
      })
      .catch(() => {
        toast.error('Ocoreu um erro!', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const handleEnableExtension = (extension_number) => {
    ExtensionService.enableExtension(extension_number)
      .then(() => {
        toast.success('Ramal habilitado', {
          autoClose: 3000,
          closeOnClick: true
        });
        handleGetExtensions();
      })
      .catch(() => {
        toast.error('Erro ao habilitar ramal', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const handleClickButtonCreate = (type, actionType) => {
    history.push(`extensions/form/${String(type).toLowerCase()}`, {
      type,
      actionType
    });
  };

  const handleUpdate = ({ extension_number, type }) => {
    history.push(
      `extensions/form/${String(
        type
      ).toLowerCase()}/${extension_number}/update`,
      {
        actionType: 'UPDATE',
        type
      }
    );
  };

  const handleClone = ({ extension_number, type }) => {
    history.push(
      `extensions/form/${String(type).toLowerCase()}/${extension_number}/clone`,
      {
        actionType: 'CLONE',
        type
      }
    );
  };

  const handleOnSearch = (event) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const q = event.target.value;
    setTimeoutID(
      setTimeout(() => {
        handleGetExtensions(1, q);
      }, 1000)
    );
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      const newSelected = { id: row.extension_number, type: row.type };
      setSelectedRows([...selectedRows, newSelected]);
    } else {
      setSelectedRows(
        selectedRows.filter((x) => x.id !== row.extension_number)
      );
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const allRows = rows.map((r) => {
      return { id: r.extension_number, type: r.type };
    });
    if (isSelect) {
      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }
  };

  const navigateToEditMultiple = () => {
    // O botão 'Editar todos' foi clicado
    if (selectedRows.length < 2) {
      return history.push(`extensions/form/edit_multiple`, {
        actionType: 'EDIT_ALL'
      });
    }

    const verifier = selectedRows[0];
    const isValid = selectedRows.every((id) => id.type === verifier.type);
    if (isValid) {
      return history.push('/admin/extensions/form/edit_multiple', {
        actionType: 'EDIT_MULTIPLE',
        type: verifier.type,
        extensions: selectedRows
      });
    } else {
      toast.error('Os ramais devem ser do mesmo tipo', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const renderButtonsOnHeader = () => {
    return [
      {
        label: 'Adicionar SIP',
        customNavigator: () => handleClickButtonCreate('SIP', 'ADD', null)
      },
      {
        label: 'Adicionar PJSIP',
        customNavigator: () => handleClickButtonCreate('PJSIP', 'ADD', null)
      },
      {
        label: 'Adicionar IAX2',
        customNavigator: () => handleClickButtonCreate('IAX2', 'ADD', null)
      },
      {
        label: 'Adicionar Analógico',
        customNavigator: () => handleClickButtonCreate('ANALOGICO', 'ADD', null)
      },
      {
        label: 'Editar vários',
        customNavigator: navigateToEditMultiple,
        disabled: selectedRows.length < 2
      },
      {
        label: 'Editar todos',
        customNavigator: navigateToEditMultiple
      }
    ];
  };

  return (
    <>
      <MultiButtonsHeader
        buttons={renderButtonsOnHeader()}
        showArrowReturn={true}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Ramais</h3>
                </CardHeader>

                <Row className="mt-3 mx-2">
                  <Col md="8">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Busca por número do ramal"
                        type="text"
                        defaultValue=""
                        onChange={handleOnSearch}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="4" className="text-right mt-2 mt-md-1">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={showDisabled}
                          onChange={() => setShowDisable(!showDisabled)}
                        />
                        <span>Mostrar desabilitados</span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <ToolkitProvider
                  data={extensions}
                  keyField="extension_number"
                  loading={loading}
                  columns={[
                    {
                      dataField: 'extension_number',
                      text: 'Ramal',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'cost_center',
                      text: 'Setor',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'contexto.name',
                      text: 'Contexto',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'type',
                      text: 'Tipo',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'call_limit',
                      text: 'Limite de Chamadas',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'callgroup',
                      text: 'Call Group',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'pickupgroup',
                      text: 'Pickup Group',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'transport',
                      text: 'Transporte',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'clone',
                      style: icon_column_style,
                      headerStyle: individual_header_style,
                      text: 'Clonar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            size="sm"
                            disabled={row.type === 'ANALOGICO' || row.disable}
                            color="success"
                            onClick={() => handleClone(row)}
                          >
                            <li className="fas fa-clone"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            size="sm"
                            disabled={row.disable}
                            color="info"
                            onClick={() => handleUpdate(row)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_header_style,
                      text: 'Habilitar / Desabilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            checked={!row.disable}
                            id={row.extension_number}
                            onChange={() => {
                              toggleExtensionStatus(
                                row.disable,
                                row.extension_number
                              );
                            }}
                          />
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          selectRow={{
                            mode: 'checkbox',
                            selected: selectedRows.map((r) => r.id),
                            onSelect: handleOnSelect,
                            onSelectAll: handleOnSelectAll,
                            selectColumnStyle: select_field_row_style
                          }}
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ExtensionList;
