import React from 'react';
import { HiPhone } from 'react-icons/hi';

import AgentIndicatorCard from '../AgentIndicatorCard';

const TMACard = ({ data }) => {
  return (
    <AgentIndicatorCard icon={HiPhone}>
      <AgentIndicatorCard.Header>
        <AgentIndicatorCard.Title>T.M.A</AgentIndicatorCard.Title>
      </AgentIndicatorCard.Header>
      <AgentIndicatorCard.Body value={data} />
    </AgentIndicatorCard>
  );
};

export default React.memo(TMACard);
