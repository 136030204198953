import React, { useState, useEffect } from 'react';
import { Form, Button } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';

import * as S from './style';
import FlexSelect from '../../../Inputs/FlexSelect';
import { toast } from 'react-toastify';

const LogoutForm = ({ agentQueues = [], isPaused, handleLogoff }) => {
  const [shouldSign, setshouldSign] = useState(null);
  const { control, handleSubmit, setValue } = useForm();

  const getQueuesFromAgentSessions = () => {
    return agentQueues.map((session) => session.queue);
  };

  const handleLogout = (data) => {
    const hasSelectedQueues = !!data.queueIds && !!data.queueIds.length;
    if (!hasSelectedQueues) toast.error('Selecione pelo menos uma fila');

    const queuesToRemove = getQueuesFromAgentSessions().filter((f) =>
      data.queueIds.includes(f.id)
    );

    handleLogoff({ queuesToRemove });
  };
  useEffect(() => {
    const shouldsign = localStorage.getItem('should_sign_in_asterisk');
    const shouldsignFormated = JSON.parse(shouldsign);
    setshouldSign(shouldsignFormated);
  }, []);

  return (
    <S.LogoutContainer>
      <Form onSubmit={handleSubmit(handleLogout)}>
        <div className="d-flex justify-content-between align-items-top">
          {shouldSign ? (
            <></>
          ) : (
            <Controller
              render={(props) => (
                <div style={{ flex: 0.7 }}>
                  <FlexSelect
                    placeholder="Selecione a(s) fila(s)"
                    isClearable={true}
                    dataOptions={getQueuesFromAgentSessions()}
                    isMulti={true}
                    closeMenuOnSelect={false}
                    value={props.value}
                    valueController={setValue}
                    fieldName="queueIds"
                    labelName="name"
                    valueName="id"
                    isDisabled={isPaused}
                    minHeight="1rem"
                  />
                </div>
              )}
              control={control}
              name="queueIds"
              defaultValue=""
            />
          )}

          {shouldSign ? (
            <></>
          ) : (
            <Button
              type="submit"
              disabled={isPaused}
              color="danger"
              size="sm"
              style={{ flex: 0.3, marginLeft: '10px' }}
            >
              Fazer Logout
            </Button>
          )}
        </div>
      </Form>
    </S.LogoutContainer>
  );
};

export default LogoutForm;
