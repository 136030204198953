import React from 'react';
import { Link } from 'react-router-dom';
import { BiCollapse } from 'react-icons/bi';
import {
  MdPermIdentity,
  MdFlashOn,
  MdHeadsetMic,
  MdPhone,
  MdSwapCalls,
  MdBuild,
  MdSettingsApplications,
  MdAssignment,
  MdReceipt,
  MdAssessment
} from 'react-icons/md';
import { BiNetworkChart } from 'react-icons/bi';
import { Card, CardBody, CardHeader, Container, Row, Col } from 'reactstrap';
import SearchHeader from 'components/Headers/SearchHeader.js';
import './styles.css';
import { verifyRoles } from 'components/Authentication/VerifyRoles';

const Configuration = () => {
  return (
    <>
      <SearchHeader
        setCreate="/admin/protocol/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="configuration-container mt--6" fluid>
        <Card>
          <CardHeader>
            <h2>Configurações</h2>
          </CardHeader>
          <CardBody>
            <Row>
              {verifyRoles(['USERS_LIST', 'USERS_PROFILE']) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <MdPermIdentity size={20} />
                    Conta
                  </div>
                  <ul>
                    {verifyRoles(['USERS_LIST']) && (
                      <Link to="/admin/user">
                        <li>Usuário</li>
                      </Link>
                    )}
                    {verifyRoles(['USERS_PROFILE']) && (
                      <Link to="/admin/profile">
                        <li>Perfil de acesso</li>
                      </Link>
                    )}
                    {verifyRoles(['TEAM_ACCESS']) && (
                      <Link to="/admin/Team/list">
                        <li>Equipe</li>
                      </Link>
                    )}
                  </ul>
                </Col>
              )}
              {verifyRoles(['PAUSE', 'SPY', 'SPY_GROUP']) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <MdHeadsetMic size={20} />
                    CallCenter
                  </div>
                  <ul>
                    {verifyRoles(['PAUSE']) && (
                      <Link to={`/admin/pause`}>
                        <li>Pausa</li>
                      </Link>
                    )}
                    {verifyRoles(['SPY']) && (
                      <Link to={`/admin/spy`}>
                        <li>Espião</li>
                      </Link>
                    )}
                    {verifyRoles(['SPY_GROUP']) && (
                      <Link to={`/admin/SpyGroup`}>
                        <li>Grupo de espião </li>
                      </Link>
                    )}
                  </ul>
                </Col>
              )}
              {verifyRoles(['AUDIO', 'URA', 'CALLBACK', 'DIALER']) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <MdBuild size={20} />
                    Avançados
                  </div>
                  <ul>
                    {verifyRoles(['AUDIO']) && (
                      <Link to={`/admin/audio`}>
                        <li>Áudio</li>
                      </Link>
                    )}
                    {verifyRoles(['URA']) && (
                      <Link to={`/admin/ivr`}>
                        <li>URA </li>
                      </Link>
                    )}
                    {verifyRoles(['CALLBACK']) && (
                      <Link to={`/admin/callback`}>
                        <li>Callback</li>
                      </Link>
                    )}
                    {verifyRoles(['HANGUP_CAUSE']) && (
                      <Link to={`/admin/shutdown/cause`}>
                        <li>Causa de desligamento</li>
                      </Link>
                    )}
                  </ul>
                </Col>
              )}
              {verifyRoles([
                'QUEUE',
                'CLIENT',
                'COST_CENTER',
                'REASON',
                'SUBREASON',
                'STATUS'
              ]) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <BiCollapse size={20} />
                    Geral
                  </div>

                  <ul>
                    {verifyRoles(['QUEUE']) && (
                      <Link to={`/admin/queue`}>
                        <li>Fila</li>
                      </Link>
                    )}

                    {verifyRoles(['SECTOR']) && (
                      <Link to={`/admin/sector`}>
                        <li>Setor</li>
                      </Link>
                    )}

                    {verifyRoles(['REASON']) && (
                      <Link to="/admin/reason">
                        <li>Motivo</li>
                      </Link>
                    )}

                    {verifyRoles(['SUBREASON']) && (
                      <Link to="/admin/subreason">
                        <li>Submotivo </li>
                      </Link>
                    )}

                    {verifyRoles(['STATUS']) && (
                      <Link to="/admin/status">
                        <li>Status/Fase</li>
                      </Link>
                    )}
                  </ul>
                </Col>
              )}
              {verifyRoles([
                'EXTENSION',
                'PJSIP_CONFIGURATION',
                'TRUNKS',
                'CONTEXT',
                'DDR'
              ]) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <MdPhone size={20} />
                    PBXIP
                  </div>
                  <ul>
                    {verifyRoles(['EXTENSION']) && (
                      <Link to={`/admin/extensions`}>
                        <li>Ramais</li>
                      </Link>
                    )}
                    {verifyRoles(['PJSIP_CONFIGURATION']) && (
                      <Link to={`/admin/pjsip_configuration`}>
                        <li>Configuração PJSIP</li>
                      </Link>
                    )}
                    {verifyRoles(['TRUNKS']) && (
                      <Link to={`/admin/trunks`}>
                        <li>Tronco</li>
                      </Link>
                    )}
                    {verifyRoles(['CONTEXT']) && (
                      <Link to={`/admin/context`}>
                        <li>Contexto</li>
                      </Link>
                    )}
                    {verifyRoles(['DDR']) && (
                      <Link to={`/admin/ddr`}>
                        <li>DDR</li>
                      </Link>
                    )}
                  </ul>
                </Col>
              )}
              {verifyRoles(['BLACK_LIST', 'BOSS_SECRETARY', 'VOICEMAIL']) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <MdFlashOn size={20} />
                    Facilidades
                  </div>
                  <ul>
                    {verifyRoles(['BLACK_LIST']) && (
                      <Link to={`/admin/blackList`}>
                        <li>Lista de bloqueio</li>
                      </Link>
                    )}
                    {verifyRoles(['BOSS_SECRETARY']) && (
                      <Link to={`/admin/ChiefSecretary`}>
                        <li>Secretário chefe</li>
                      </Link>
                    )}
                    {verifyRoles(['VOICEMAIL']) && (
                      <Link to={`/admin/voicemail`}>
                        <li>Voicemail</li>
                      </Link>
                    )}
                  </ul>
                </Col>
              )}
              {verifyRoles([
                'CHAT_CONFIGURATION',
                'ISSUE',
                'PREDEFINEDMESSAGE',
                'QUEUEMESSAGE',
                'PRODUCT',
                'TAG_CHAT',
                'LINK_CHAT',
                'BROADCAST_LIST',
                'BROADCAST_LIST_SMS'
              ]) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <BiNetworkChart size={20} />
                    Chat
                  </div>
                  <ul>
                    {verifyRoles(['CHAT_CONFIGURATION']) && (
                      <Link to={`/admin/chat`}>
                        <li>Canais</li>
                      </Link>
                    )}
                    {verifyRoles(['CHAT_CONFIGURATION']) && (
                      <Link to={`/admin/configChat`}>
                        <li>Configuração</li>
                      </Link>
                    )}
                    {verifyRoles(['CHAT_CONFIGURATION']) && (
                      <Link to={`/admin/botFlow`}>
                        <li>Respostas do chatbot</li>
                      </Link>
                    )}

                    {verifyRoles(['ISSUE']) && (
                      <Link to={`/admin/issue`}>
                        <li>Assunto</li>
                      </Link>
                    )}

                    {verifyRoles(['PREDEFINEDMESSAGE']) && (
                      <Link to={`/admin/predefinedMessage`}>
                        <li>Mensagem predefinida</li>
                      </Link>
                    )}

                    {verifyRoles(['QUEUEMESSAGE']) && (
                      <Link to={`/admin/queuemessage`}>
                        <li>Mensagem de fila</li>
                      </Link>
                    )}

                    {verifyRoles(['PRODUCT']) && (
                      <Link to={`/admin/product`}>
                        <li>Produto</li>
                      </Link>
                    )}

                    {verifyRoles(['TAG_CHAT']) && (
                      <Link to={`/admin/chat/tags`}>
                        <li>Tags</li>
                      </Link>
                    )}

                    {verifyRoles(['LINK_CHAT']) && (
                      <Link to={`/admin/chat/generation/link`}>
                        <li>Geração de Link</li>
                      </Link>
                    )}
                    {verifyRoles(['DIALOG_TREE']) && (
                      <Link to={`/admin/chatDialogTree`}>
                        <li>Árvore de Diálogo</li>
                      </Link>
                    )}
                    {verifyRoles(['CHAT_CONFIGURATION']) && (
                      <Link to={`/admin/watson`}>
                        <li>Watson</li>
                      </Link>
                    )}
                  </ul>
                </Col>
              )}
              {verifyRoles([
                'OUTBOUND_ROUTE',
                'INBOUND_ROUTE',
                'INTERNAL_ROUTE',
                'EASE_ROUTE',
                'OUTBOUND_ROUTE_GROUP',
                'TRANSSHIPMENT',
                'PROGRAMACAO_HORARIO'
              ]) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <MdSwapCalls size={20} />
                    Rotas
                  </div>
                  <ul>
                    {verifyRoles(['OUTBOUND_ROUTE']) && (
                      <Link to={`/admin/exitRoute`}>
                        <li>Rota de saída</li>
                      </Link>
                    )}
                    {verifyRoles(['INBOUND_ROUTE']) && (
                      <Link to={`/admin/inboundRoute`}>
                        <li>Rota de entrada</li>
                      </Link>
                    )}
                    {verifyRoles(['INTERNAL_ROUTE']) && (
                      <Link to={`/admin/internalRoute`}>
                        <li>Rota interna</li>
                      </Link>
                    )}
                    {verifyRoles(['EASE_ROUTE']) && (
                      <Link to={`/admin/facilityRoute`}>
                        <li>Rota de facilidade</li>
                      </Link>
                    )}
                    {verifyRoles(['OUTBOUND_ROUTE_GROUP']) && (
                      <Link to={`/admin/GroupExitRoute`}>
                        <li>Grupo de Rota de saída</li>
                      </Link>
                    )}
                    {verifyRoles(['TRANSSHIPMENT']) && (
                      <Link to={`/admin/transshipment`}>
                        <li>Transbordo</li>
                      </Link>
                    )}
                    {verifyRoles(['PROGRAMACAO_HORARIO']) && (
                      <Link to={`/admin/timeschedule`}>
                        <li>Programação de horário</li>
                      </Link>
                    )}
                  </ul>
                </Col>
              )}
              {verifyRoles(['AGENT_CRM']) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <MdAssessment size={20} />
                    CRM
                  </div>
                  <ul>
                    <Link to={`/admin/crm/leadStages`}>
                      <li>Fases do Lead</li>
                    </Link>
                    <Link to={`/admin/crm/leadProcess`}>
                      <li>Processos - Lead</li>
                    </Link>
                    <Link to={`/admin/crm/opportunityStages`}>
                      <li>Fases da Oportunidade</li>
                    </Link>
                    <Link to={`/admin/crm/salesProcess`}>
                      <li>Processos - Vendas</li>
                    </Link>
                    <Link to={`/admin/crm/protocolProcess`}>
                      <li>Processos de Atendimento</li>
                    </Link>
                    <Link to={`/admin/crm/mapFields`}>
                      <li>Mapeamento de campos</li>
                    </Link>
                    <Link to={`/admin/crm/tagsClient`}>
                      <li>Tags</li>
                    </Link>
                  </ul>
                </Col>
              )}

              {verifyRoles(['SURVEY_CONFIGURATION', 'SURVEY_TAGS']) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <MdAssignment size={20} />
                    Pesquisa
                  </div>
                  <ul>
                    {verifyRoles(['SURVEY_CONFIGURATION']) && (
                      <Link to="/admin/configSurvey/list">
                        <li>Configuração</li>
                      </Link>
                    )}
                    {verifyRoles(['SURVEY_TAGS']) && (
                      <Link to="/admin/tags">
                        <li>Tags</li>
                      </Link>
                    )}
                  </ul>
                </Col>
              )}
              {verifyRoles(['CONFIG_PROTOCOL']) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <MdReceipt size={20} />
                    Protocolo
                  </div>
                  <ul>
                    {verifyRoles(['CONFIG_PROTOCOL']) && (
                      <Link to="/admin/protocol/config">
                        <li>Configuração</li>
                      </Link>
                    )}
                  </ul>
                </Col>
              )}
              {verifyRoles([
                'CUSTOM_FIELDS_CONFIG',
                'SYSTEM_CONFIGURATION',
                'EMAIL_CONFIGURATION',
                'CONFIGURE_QUALITY_MONITORING_PAPERS'
              ]) && (
                <Col sm={4}>
                  <div className="configuration-title">
                    <MdSettingsApplications size={20} />
                    Sistema
                  </div>
                  <ul>
                    {verifyRoles(['CUSTOM_FIELDS_CONFIG']) && (
                      <Link to={`/admin/fieldsConfig`}>
                        <li>Campos customizados do sistema</li>
                      </Link>
                    )}
                    {verifyRoles(['SYSTEM_PARAMS']) && (
                      <Link to={`/admin/systemConfig`}>
                        <li>Parâmetros do sistema</li>
                      </Link>
                    )}
                    {verifyRoles(['SERVICE_LEVEL']) && (
                      <Link to={`/admin/systemConfig/levelService`}>
                        <li>Configuração do tempo de nível de serviço geral</li>
                      </Link>
                    )}
                    {verifyRoles(['EMAIL_CONFIGURATION']) && (
                      <Link to={`/admin/email`}>
                        <li>Configuração de email</li>
                      </Link>
                    )}
                    {verifyRoles(['INTEGRATION_URL']) && (
                      <Link to={'/admin/integrationUrl'}>
                        <li>URL de integração</li>
                      </Link>
                    )}
                    {verifyRoles(['RECORD_TYPES']) && (
                      <Link to="/admin/recordType">
                        <li>Tipos de Registro</li>
                      </Link>
                    )}
                    {verifyRoles(['CONFIGURE_QUALITY_MONITORING_PAPERS']) && (
                      <Link to="/admin/paper">
                        <li>Monitoria de qualidade</li>
                      </Link>
                    )}
                  </ul>
                </Col>
              )}
              {/*<Col>
                <div className="configuration-title">
                  <BiBot size={20} />
                  ChatBot
                </div>
                {showConfigs('undefined') && (
                  <ul>
                    <Link to="/admin/subreason">
                      <li>Parâmetros</li>
                    </Link>
                    <Link to="/admin/subreason">
                      <li>Watson</li>
                    </Link>
                    <Link to="/admin/subreason">
                      <li>DialogFlow</li>
                    </Link>
                  </ul>
                )}
              </Col>
              <Col>
                <div className="configuration-title">
                  <BiBot size={20} />
                  VoiceBot
                </div>
                {showConfigs('undefined') && (
                  <ul>
                    <Link to="/admin/subreason">
                      <li>Parâmetros</li>
                    </Link>
                    <Link to="/admin/subreason">
                      <li>Watson</li>
                    </Link>
                    <Link to="/admin/subreason">
                      <li>DialogFlow</li>
                    </Link>
                    <Link to="/admin/subreason">
                      <li>Amazon Polly</li>
                    </Link>
                    <Link to="/admin/subreason">
                      <li>Google STT</li>
                    </Link>
                  </ul>
                )}
              </Col>
              <Col>
                <div className="configuration-title">
                  <MdSettingsApplications size={20} />
                  Integrações
                </div>
                {showConfigs('undefined') && (
                  <ul>
                    <Link to="/admin/subreason">
                      <li>Movidesk</li>
                    </Link>
                    <Link to="/admin/subreason">
                      <li>Salesforce</li>
                    </Link>
                    <Link to="/admin/subreason">
                      <li>XVision</li>
                    </Link>
                  </ul>
                )}
              </Col> */}
            </Row>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default Configuration;
