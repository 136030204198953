import { useCallback, useState } from 'react';
import UserService from '../../service';

export function useGetUserSectors() {
  const login = localStorage.getItem('login');
  const [userCostCenter, setuserCostCenter] = useState();

  const getUserSectors = useCallback(() => {
    UserService.get(login)
      .then((response) => {
        let getUser = response.data.data;

        let arrayCostsCenterNames = [];
        getUser.costscenter.forEach((cc) => {
          arrayCostsCenterNames.push(cc.name);
        });
        setuserCostCenter(arrayCostsCenterNames);
      })
      .catch((err) => {
        console.log('erro no hookr', err);
      });
  }, [login]);

  return {
    getUserSectors,
    userCostCenter
  };
}
