import React, { useEffect } from 'react';
import { Row } from 'reactstrap';
import { toast } from 'react-toastify';

import DefaultModal from 'components/Modal/DefaultModal';
import ReactLoader from 'components/Loader';
import Pagination from 'components/Pagination';
import PauseAnalyticalTable from 'views/Report/Callcenter/Pause/tables/Analytical';

import { getAnalyticalPauseReport } from '../../../../services/getAnalyticalPauseReport';
import { DEFAULT_PAGE_SIZE } from '../../../../utils/consts';

import { useAnalyticalReportService } from '../../hooks/useAnalyticalReportService';

const PauseReportModal = ({ isModalOpen, onModalToggle, pauseId }) => {
  const {
    data,
    quantity,
    loading,
    activePage,
    error,
    handlePageChange,
    fetchData
  } = useAnalyticalReportService(getAnalyticalPauseReport);

  useEffect(() => {
    if (!isModalOpen) return;

    fetchData({ pause: pauseId });
  }, [isModalOpen, activePage]);

  useEffect(() => {
    if (!error) return;

    onModalToggle(false);
    toast.error('Erro ao carregar relatório de pausas.');
  }, [error]);

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Relatório de Pausas"
      modalContainerClasses="modal-xl"
      showCloseButton={false}
      centered={false}
    >
      {loading ? (
        <Row className="justify-content-md-center">
          <ReactLoader />
        </Row>
      ) : (
        <PauseAnalyticalTable
          showReports={data.listAgentActionDTO}
          totalRegister={quantity}
        />
      )}

      {quantity > DEFAULT_PAGE_SIZE && (
        <Pagination
          activePage={activePage}
          totalItemsCount={quantity}
          onChange={handlePageChange}
        />
      )}
    </DefaultModal>
  );
};

export default PauseReportModal;
