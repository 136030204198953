import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/action/index';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';

const Unauthorized = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  });

  toast.info('Realize seu login para continuar!');
  return <Redirect to="/dashboard" />;
};

export default Unauthorized;
