import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
`;

export const CardWrapper = styled.div`
  margin-bottom: -30px;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1.5rem;
`;

export const Label = styled.span`
  font-weight: bold;
  color: #5e72e4;
`;

export const UserInfoWrapper = styled.span`
  font-size: 0.8rem;
  padding: 0 1rem;
`;

export const Info = styled(UserInfoWrapper)`
  font-weight: bold;
`;
