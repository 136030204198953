import React from 'react';
import Pagination from 'components/Pagination';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';
import { TotalRegister } from '../../../../../components/TotalRegister';
import { ItemPerPageSelect } from 'components/Inputs/ItemPerPageSelect';


const Analytical = ({
  dataToSend,
  getReports,
  dataReport,
  quantity,
  setActivePage,
  activePage,
  totalRegister,
  itemPerPage = 10,
  setItemsPerPage
}) => {
  const handlePageChange = (page) => {
    setActivePage(page);
    getReports('analytical', dataToSend, page, false);
  };

  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            {totalRegister >= 0 && (
              <TotalRegister totalRegister={totalRegister} />
            )}
            <Card>
              <ToolkitProvider
                data={dataReport.listUraAnalyticalDTO || []}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'uraName',
                    text: 'Nome',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'date',
                    text: 'Data',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'origin',
                    text: 'Origem',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'destination',
                    text: 'Destino',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'queueName',
                    text: 'Fila',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'agentName',
                    text: 'Agente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'statusCall',
                    text: 'Status',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeWait',
                    text: 'Tempo de Espera',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeCall',
                    text: 'Tempo da chamada',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (rowContent, _) => {
                      let time = '';
                      if (rowContent) {
                        const array = rowContent.split(':');
                        time = `${array[0]}:${array[1]}:${array[2][0]}${array[2][1]}`;
                      }
                      return time;
                    }
                  },
                  {
                    dataField: 'uraTimeWait',
                    text: 'Tempo de espera na URA',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (rowContent, _) => {
                      let time = '';
                      if (rowContent) {
                        const array = rowContent.split(':');
                        time = `${array[0]}:${array[1]}:${array[2][0]}${array[2][1]}`;
                      }
                      return time;
                    }
                  },
                  {
                    dataField: 'lastUraOption',
                    text: 'Opção da URA',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'uraOptions',
                    text: 'Opções da URA',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'relative'
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    left: '0'
                  }}
                >
                  <ItemPerPageSelect valueController={setItemsPerPage} />
                </div>    
                <Pagination
                  itemsCountPerPage={itemPerPage}
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </div>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Analytical;
