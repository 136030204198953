import React, { useState, useEffect } from 'react';
import UserService from '../service';
import { useGetExtensions } from './hooks/useGetExtensions';
import { useGetContexts } from './hooks/useGetContexts';
import { useGetQueues } from './hooks/useGetQueues';
import { useGetProfiles } from './hooks/useGetProfiles';
import ReactQuill from 'react-quill';
import { useGetSectors } from './hooks/useGetSectors';
import { useGetCurrentSelectedQueues } from './hooks/useGetCurrentSelectedQueues';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { booleanToString } from './assets/convertDataTypes';
import { DEFAULT_PENALTY, THEMEOPTIONS } from './assets/constants';
import { toFormatValuesForSending } from './assets/toFormatValuesForSending';
import { QueuePenaltyTable } from './components/QueuePenaltyTable';
import { UpdatePasswordButton } from './components/UpdatePasswordButton';
import { ErrorFormMessage } from './components/ErrorFormMessage';
import { agentCodeRegex } from './assets/regex';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import FormTooltip from 'components/Inputs/FormTooltip';
import EditPassword from './components/EditPassword';
import ModalUpdatePenality from './components/modalEditPenality';
import FlexSelect from 'components/Inputs/FlexSelect';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ScaleService from '../../Scales/service';
import { useRequestUsers } from 'hooks/useUserContext';
import { useRecordTypes } from '../../RecordType/hooks/useRecordTypes';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody,
  FormGroup
} from 'reactstrap';
import { useGetTeams } from './hooks/useGetTeams';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import TelephoneMask from 'components/Inputs/TelephoneMask';
import { maskPhoneNumber } from 'views/CRM/shared/utils/phoneNumberHelpers';
import { useUsers } from 'views/Report/Protocol/hooks/useUsers';

//import { Loading } from 'views/Report/Audit/Authentication/components/Loading';
import { ScreenPrompt } from 'components/ScreenPrompt';
import { active } from 'sortablejs';

const UserForm = (props) => {
  const { loadApiUsers } = useRequestUsers();
  const { login } = props.match.params;
  const { recordTypes } = useRecordTypes();
  const {
    reset,
    control,
    handleSubmit,
    errors,
    setValue,
    getValues,
    watch,
    formState
  } = useForm();
  const { isDirty } = formState;

  const controlSelectQueues = watch('queuesid');
  const history = useHistory();
  const [isUpdate, setIsUpdate] = useState(false);
  const [isDisabledInput, setisDisabledInput] = useState(false);
  const [isDisabledInputLogin, setisDisabledInputLogin] = useState(false);
  const [isSupervisor, setisSupervisor] = useState(false);
  const [workScales, setworkScales] = useState([]);

  const [loading, setLoading] = useState(false);
  const { extensions } = useGetExtensions();
  const { contexts } = useGetContexts();
  const { queues } = useGetQueues();
  const { profiles } = useGetProfiles();
  const { sectors } = useGetSectors();
  const { teams } = useGetTeams();
  const { selectedQueues, setSelectedQueues } = useGetCurrentSelectedQueues({
    login,
    isUpdate,
    queues,
    controlSelectQueues
  });
  const { userList } = useUsers();

  const [openModal, setOpenModal] = useState(false);
  const [showAllConversations, setShowAllConversations] = useState(false);
  const [showAllProtocols, setShowAllProtocols] = useState(false);
  const [showAllLeads, setShowAllLeads] = useState(false);
  const [showAllOpportunities, setShowAllOpportunities] = useState(false);
  const [user, setUser] = useState(null);
  const [openModalUpdatePenality, setOpenModalUpdatePenality] = useState(false);
  const [queueUpdatePenality, setQueueUpdatePenality] = useState({});
  const [initialConfigSignatureEmail, setInitialConfigSignatureEmail] =
    useState({
      agent_login: login,
      signature: '',
      active: false
    });

  function setDataEmailSignature(event) {
    console.log(event);
    setInitialConfigSignatureEmail({
      ...initialConfigSignatureEmail,
      signature: event
    });
  }

  var modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      ['link', 'image', 'code-block'],
      ['clean']
    ]
  };
  useEffect(() => {
    if (login) {
      setLoading(true);
      UserService.get(login)
        .then((response) => {
          let getUser = response.data.data;

          let arrayQueuesId = [];
          getUser.queues.forEach((queue) => {
            arrayQueuesId.push(queue.id);
          });

          let arrayCostsCenterId = [];
          getUser.costscenter.forEach((cc) => {
            arrayCostsCenterId.push(cc.id);
          });

          let arrayRecordTypesId = [];
          getUser.recordTypes.forEach((rt) => {
            arrayRecordTypesId.push(rt.id);
          });

          let arrayExtensionsNumber = [];
          getUser.extensions.forEach((extension) => {
            arrayExtensionsNumber.push(extension.extension_number);
          });

          let arrayProfilesId = [];
          getUser.profiles.forEach((profile) => {
            arrayProfilesId.push(profile.id);
          });

          let arrayTeamsId = [];
          getUser.teams.forEach((team) => {
            arrayTeamsId.push(team.id);
          });

          let arrayqueuesidToSignIn = [];
          if (getUser.queuesidToSignIn) {
            getUser.queuesidToSignIn.forEach((response) => {
              arrayqueuesidToSignIn.push(response.id);
            });
          }

          const supervisorids = getUser?.supervisors?.map(
            (supervisor) => supervisor.login
          );

          if (getUser.show_all_conversations === true)
            setShowAllConversations(true);

          if (getUser.scale?.id > 0) {
            setisDisabledInput(true);
          } else {
            setisDisabledInput(false);
          }

          if (getUser.show_all_protocols === true) setShowAllProtocols(true);

          getUser = {
            ...getUser,
            costscenterid: arrayCostsCenterId,
            recordtypesid: arrayRecordTypesId,
            extensionsid: arrayExtensionsNumber,
            queuesid: arrayQueuesId,
            profilesid: arrayProfilesId,
            supervisorids,
            dark_mode: booleanToString(getUser.dark_mode),
            scaleId: getUser?.scale?.id ?? '',
            queuesidToSignIn: arrayqueuesidToSignIn,
            teamsIds: arrayTeamsId,
            telephone: maskPhoneNumber(getUser.telephone)
          };
          setisDisabledInputLogin(getUser.should_sign_in_asterisk);
          reset(getUser);
          setUser({ ...getUser });
          setisSupervisor(getUser.isSupervisor);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao carregar usuário!', {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoading(false);
        });
    }
  }, [login, reset]);

  useEffect(() => {
    const currentSelectedQueues = [];

    if (isUpdate) {
      const { penalties } = user; //caso seja usuario cadastrado no novo formato
      const queuesOldUser = user?.queues; //caso seja usuario cadastrado no formando antigo
      const penaltyDefaultOld = user?.penalty;
      const queuesToBeRemoved = [];

      if (user?.signatureEmail) {
        setInitialConfigSignatureEmail({
          signature: user?.signatureEmail.signature || '',
          agent_login: login,
          active: user?.signatureEmail.active || false
        });
      }

      if (penalties.length > 0) {
        // 'entrou usuario novo';
        queues.forEach((queue) => {
          penalties.forEach((penalty) => {
            if (queue?.id === penalty?.queue_id) {
              currentSelectedQueues.push({
                label: queue?.name,
                value: queue?.id,
                penality: penalty?.penalty
              });
            }
          });
        });
      } else {
        // 'chegou usuario antigo'
        queues.forEach((queue) => {
          queuesOldUser.forEach((queueOld) => {
            if (queue.id === queueOld.id) {
              currentSelectedQueues.push({
                label: queue?.name,
                value: queue?.id,
                penality: penaltyDefaultOld || DEFAULT_PENALTY
              });
            }
          });
        });
      }

      controlSelectQueues.forEach((selectQueue) => {
        let found = currentSelectedQueues.findIndex(
          (currentQueue) => currentQueue?.value === selectQueue
        );

        if (found === -1) {
          const newQueueInsert = queues.find(
            (queue) => queue?.id === selectQueue
          );

          currentSelectedQueues.push({
            label: newQueueInsert?.name,
            value: newQueueInsert?.id,
            penality: DEFAULT_PENALTY
          });
        }
      });

      currentSelectedQueues.forEach((currentQueue, index) => {
        const found = controlSelectQueues.find(
          (selectQueue) => currentQueue?.value === selectQueue
        );
        if (found === undefined) {
          queuesToBeRemoved.push(currentQueue);
        }
      });

      queuesToBeRemoved.forEach((queueRemoved) => {
        const itemsRemove = currentSelectedQueues.findIndex(
          (currentQueue) => currentQueue?.value === queueRemoved?.value
        );
        currentSelectedQueues.splice(itemsRemove, 1);
      });

      setSelectedQueues(currentSelectedQueues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlSelectQueues, isUpdate, queues, user]);

  async function loadApiScale() {
    const Params = {
      page: 0,
      size: 0,
      showDisable: false,
      querySearch: ''
    };
    ScaleService.getAllSearch(
      Params.showDisabled,
      Params.size,
      Params.page,
      Params.querySearch
    ).then((response) => {
      const getScale = response.data.data.map((scale) => {
        return {
          name: scale.name,
          id: scale.id
        };
      });
      setworkScales(getScale);
    });
  }

  useEffect(() => {
    loadApiScale();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    data = { scaleId: null, ...data };
    try {
      let formatedData = toFormatValuesForSending(
        data,
        user,
        selectedQueues,
        showAllConversations,
        isUpdate,
        isDisabledInputLogin,
        isDisabledInput,
        isSupervisor,
        showAllProtocols,
        initialConfigSignatureEmail,
        isUpdate
      );

      if (login === localStorage.getItem('login')) {
        localStorage.setItem('show_all_conversations', showAllConversations);
        localStorage.setItem('show_all_protocols', showAllProtocols);
      }

      isUpdate
        ? await UserService.update(login, formatedData)
        : await UserService.create(formatedData);

      const uniqueTeams = [...new Set([data.teamId, ...data.teamsIds])];
      const teams = JSON.stringify(uniqueTeams);

      localStorage.setItem('teams', teams);

      loadApiUsers();

      history.push('/admin/user');

      toast.success(
        isUpdate
          ? 'Usuário alterado com sucesso!'
          : 'Usuário cadastrado com sucesso!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error(err.response?.data?.message || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => <ErrorFormMessage message={message} />;

  const cancelAction = () => {
    history.push('/admin/user');
  };

  const toggleModal = () => {
    setOpenModal(!openModal);
  };

  const handleOpenModalUpdatePenality = (queueUpdate) => {
    setQueueUpdatePenality(queueUpdate);
    setOpenModalUpdatePenality(true);
  };
  const handleWithQueueLogin = () => {
    if (isDisabledInput) {
      setisDisabledInputLogin(true);
    } else {
      setisDisabledInputLogin(!isDisabledInputLogin);
    }
  };

  const handleWithSupervisor = () => {
    setisSupervisor(!isSupervisor);
    setisDisabledInputLogin(false);
    setisDisabledInput(false);
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/user"
        buttonTitle="Voltar para a pagina de usuários"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Usuário</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <>
                      {login && (
                        <UpdatePasswordButton toggleModal={toggleModal} />
                      )}
                      <Form
                        className="needs-validation"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Row
                          className="mb-1"
                          style={{ display: 'block', padding: '1rem' }}
                        >
                          <Row className="mb-3">
                            <Col>
                              <h3 className="mb-1">Principal</h3>
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Nome*
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                name="name"
                                rules={{ required: 'Nome é obrigatório!' }}
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="name"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                            <Col md="6">
                              <label className="form-control-label">
                                Usuário*
                              </label>
                              <Controller
                                as={<Input disabled={isUpdate} />}
                                control={control}
                                name="login"
                                rules={{
                                  pattern: {
                                    value: /^[a-z0-9.@*_-]*$/,
                                    message:
                                      'O nome de usuário não deve conter espaços ou letras em caixa alta!'
                                  },
                                  required: 'Usuário é obrigatório!'
                                }}
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="login"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          </Row>

                          {!login && (
                            <Row className="mb-3">
                              <Col md="6">
                                <label className="form-control-label">
                                  Senha*
                                </label>
                                <Controller
                                  as={<Input type="password" />}
                                  control={control}
                                  name="password"
                                  rules={{
                                    required: 'Por favor, digite a senha!'
                                  }}
                                  defaultValue=""
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="password"
                                  render={({ message }) =>
                                    errorFormMessage(message)
                                  }
                                />
                              </Col>
                              <Col md="6">
                                <label className="form-control-label">
                                  Confirmar senha*
                                </label>
                                <Controller
                                  as={<Input type="password" />}
                                  control={control}
                                  name="confirm_password"
                                  rules={{
                                    required:
                                      'Por favor, digite a confirmação da senha!',
                                    validate: (value) => {
                                      if (value === getValues()['password']) {
                                        return true;
                                      } else {
                                        return 'As senhas não conferem!';
                                      }
                                    }
                                  }}
                                  defaultValue=""
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="confirm_password"
                                  render={({ message }) =>
                                    errorFormMessage(message)
                                  }
                                />
                              </Col>
                            </Row>
                          )}

                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Email
                              </label>
                              <Controller
                                as={<Input type="email" />}
                                control={control}
                                name="email"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                            <Col md="6">
                              <label className="form-control-label">
                                Telefone
                              </label>
                              <Controller
                                render={(props) => (
                                  <TelephoneMask
                                    valueController={setValue}
                                    fieldName="telephone"
                                    value={props.value}
                                    placeholder="(99) 91234-5678"
                                  />
                                )}
                                control={control}
                                defaultValue=""
                                rules={{
                                  validate: (value) => {
                                    if (!value) return true;
                                    let phoneFormated = value.replace(
                                      /\D/g,
                                      ''
                                    );
                                    phoneFormated = phoneFormated.replace(
                                      /^0/,
                                      ''
                                    );

                                    if (
                                      phoneFormated.length < 10 &&
                                      phoneFormated.length > 0
                                    ) {
                                      return 'Telefone inválido';
                                    }
                                    return true;
                                  }
                                }}
                                name="telephone"
                              />
                              <ErrorMessage
                                errors={errors}
                                name="telephone"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Código do agente*
                                <FormTooltip
                                  text="Este código é usado para realizar chamadas em ramais
                                bloqueados."
                                />
                              </label>
                              {isUpdate ? (
                                <Controller
                                  as={<Input disabled />}
                                  control={control}
                                  name="agent_code"
                                  value={props.value}
                                  defaultValue=""
                                />
                              ) : (
                                <Controller
                                  as={Input}
                                  type="number"
                                  control={control}
                                  name="agent_code"
                                  rules={{
                                    required:
                                      'Código do agente é um campo obrigatório!',
                                    pattern: {
                                      value: agentCodeRegex,
                                      message:
                                        'Código do agente deve possuir entre 4 a 12 dígitos'
                                    }
                                  }}
                                  defaultValue=""
                                />
                              )}
                              <ErrorMessage
                                errors={errors}
                                name="agent_code"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>

                            <Col md="6">
                              <label className="form-control-label">
                                Código PIN
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                name="pin_code"
                                defaultValue={''}
                              />
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Perfil de acesso*
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={profiles}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="profileId"
                                    labelName="name"
                                    valueName="id"
                                  />
                                )}
                                control={control}
                                name="profileId"
                                defaultValue=""
                                rules={{
                                  required: 'Perfil de acesso é obrigatório!'
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="profileId"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                            <Col md="6">
                              <label className="form-control-label">
                                Nickname
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                name="nickname"
                                maxLength="30"
                                rules={{
                                  pattern: {
                                    value: /^[a-z0-9.@*_-]*$/,
                                    message:
                                      'O Nickname não deve conter espaços ou letras em caixa alta!'
                                  }
                                }}
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="nickname"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Tema*
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={false}
                                    dataOptions={THEMEOPTIONS}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="dark_mode"
                                    labelName="label"
                                    valueName="value"
                                  />
                                )}
                                control={control}
                                name="dark_mode"
                                defaultValue="false"
                                rules={{ required: 'Tema é obrigatório!' }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="dark_mode"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                            <Col md="6">
                              <label className="form-control-label">
                                Contexto*
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={contexts}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="context"
                                    labelName="name"
                                    valueName="id"
                                  />
                                )}
                                control={control}
                                name="context"
                                defaultValue=""
                                rules={{
                                  required: 'Contexto é obrigatório!'
                                }}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="context"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Equipe do usuário
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={teams}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="teamId"
                                    labelName="name"
                                    valueName="id"
                                  />
                                )}
                                control={control}
                                name="teamId"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="teamId"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                            <Col md="6">
                              <label className="form-control-label">
                                Setor
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={sectors}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="costCenterId"
                                    labelName="name"
                                    valueName="id"
                                  />
                                )}
                                control={control}
                                name="costCenterId"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="costCenterId"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          </Row>
                        </Row>

                        <Row className="mb-3">
                          <Col>
                            <h3 className="mb-1">Integrações</h3>
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Usuário
                              <FormTooltip
                                text="Este nome de usuário será utilizado na configuração de URL
                                de integração para uso na Tela de Sistemas, que autenticará em
                                sistemas de terceiros."
                              />
                            </label>
                            <Controller
                              as={<Input />}
                              control={control}
                              name="integration_login"
                              rules={{
                                pattern: {
                                  value: /^[a-z0-9.@*_-]*$/,
                                  message:
                                    'O nome de usuário não deve conter espaços ou letras em caixa alta!'
                                }
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="integration_login"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Senha
                              <FormTooltip
                                text="Esta senha será utilizado na configuração de URL de
                                integração para uso na Tela de Sistemas, que autenticará em
                                sistemas de terceiros."
                              />
                            </label>
                            <Controller
                              as={<Input type="password" />}
                              control={control}
                              name="integration_password"
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="integration_password"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row
                          className="mb-1"
                          style={
                            verifyRoles(['USER_SCALE_PROFILE'])
                              ? { display: 'block', padding: '1rem' }
                              : { display: 'none' }
                          }
                        >
                          <Row>
                            <Col>
                              <Row>
                                <Col>
                                  <h3 className="mb-1">Escalas</h3>
                                </Col>
                              </Row>
                              <Row>
                                <Col md="6">
                                  <FormGroup className="mb-0">
                                    <label className="form-control-label">
                                      <input
                                        type="checkbox"
                                        checked={isDisabledInputLogin}
                                        onChange={() => handleWithQueueLogin()}
                                        disabled={
                                          isDisabledInput || isSupervisor
                                        }
                                        style={{ marginRight: '0.35rem' }}
                                      />
                                      Logar nas filas automaticamente ao logar
                                      no sistema
                                    </label>
                                  </FormGroup>
                                </Col>

                                <Col>
                                  <Row>
                                    <Col sm="12">
                                      <FormGroup className="mb-0">
                                        <label className="form-control-label">
                                          <input
                                            type="checkbox"
                                            checked={isDisabledInput}
                                            onChange={() => {
                                              setisDisabledInputLogin(true);
                                              setisDisabledInput(
                                                !isDisabledInput
                                              );
                                            }}
                                            disabled={isSupervisor}
                                            style={{ marginRight: '0.5rem' }}
                                          />
                                          Utilizar escala de trabalho ao logar
                                        </label>
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="6">
                              {isDisabledInputLogin ? (
                                <>
                                  <label className="form-control-label">
                                    Selecione a Fila(s) para login automático.
                                  </label>
                                  <Controller
                                    render={(props) => (
                                      <FlexSelect
                                        dataOptions={queues}
                                        placeholder="Selecione a(s) fila(s)"
                                        isClearable={true}
                                        isMulti={true}
                                        value={props.value}
                                        valueController={setValue}
                                        fieldName="queuesidToSignIn"
                                        labelName="name"
                                        valueName="id"
                                      />
                                    )}
                                    control={control}
                                    name="queuesidToSignIn"
                                    defaultValue=""
                                    rules={{
                                      required: 'Selecione pelo menos uma fila!'
                                    }}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name="queuesidToSignIn"
                                    render={({ message }) =>
                                      errorFormMessage(message)
                                    }
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                            <Col md="6">
                              {isDisabledInput ? (
                                <>
                                  <label className="form-control-label">
                                    Escalas
                                  </label>
                                  <Controller
                                    render={(props) => (
                                      <FlexSelect
                                        dataOptions={workScales}
                                        placeholder="Selecione a escala"
                                        isClearable={true}
                                        isMulti={false}
                                        value={props.value}
                                        valueController={setValue}
                                        fieldName="scaleId"
                                        labelName="name"
                                        valueName="id"
                                      />
                                    )}
                                    control={control}
                                    name="scaleId"
                                    defaultValue=""
                                  />
                                </>
                              ) : (
                                <></>
                              )}
                            </Col>
                          </Row>

                          <Row>
                            <Col sm="12">
                              <FormGroup className="mt-2">
                                <label className="form-control-label">
                                  <input
                                    type="checkbox"
                                    checked={isSupervisor}
                                    onChange={() => {
                                      handleWithSupervisor();
                                    }}
                                    style={{ marginRight: '0.5rem' }}
                                  />
                                  Supervisor
                                </label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </Row>

                        <Row
                          className="mb-1"
                          style={
                            verifyRoles(['USER_PERMISSIONS'])
                              ? { display: 'block', padding: '1rem' }
                              : { display: 'none' }
                          }
                        >
                          <Row>
                            <Col>
                              <Row>
                                <Col>
                                  <h3 className="mb-1">Permissões</h3>
                                </Col>
                              </Row>
                              <Row className="mb-3">
                                <Col sm="12" md="6" className="mx-4">
                                  <Controller
                                    name="show_all_conversations"
                                    control={control}
                                    render={(props) => (
                                      <>
                                        <label className="form-control-label">
                                          <Input
                                            type="checkbox"
                                            onChange={(e) => {
                                              props.onChange(e.target.checked);
                                              setShowAllConversations(
                                                !showAllConversations
                                              );
                                            }}
                                            checked={props.value}
                                          />
                                          Relatório de conversas de todos os
                                          usuários
                                          <FormTooltip text="Habilitando este atributo o usuário passará a visualizar todas as conversas de todos os atendentes nos relatórios, não apenas as suas próprias." />
                                        </label>
                                      </>
                                    )}
                                    defaultValue=""
                                  />
                                </Col>
                                <Col sm="12" md="6" className="mx-4">
                                  <Controller
                                    name="show_all_protocols"
                                    control={control}
                                    render={(props) => (
                                      <>
                                        <label className="form-control-label">
                                          <Input
                                            type="checkbox"
                                            onChange={(e) => {
                                              props.onChange(e.target.checked);
                                              setShowAllProtocols(
                                                !showAllProtocols
                                              );
                                            }}
                                            checked={props.value}
                                          />
                                          Visualizar protocolos de todos os
                                          usuários
                                          <FormTooltip text="Habilitando este atributo o usuário passará a visualizar todos os protocolos de todos os atendentes, caso o contrário, visualizará apenas seus próprios protocolos." />
                                        </label>
                                      </>
                                    )}
                                    defaultValue=""
                                  />
                                </Col>
                                <Col sm="12" md="6" className="mx-4">
                                  <Controller
                                    name="show_all_leads"
                                    control={control}
                                    render={(props) => (
                                      <>
                                        <label className="form-control-label">
                                          <Input
                                            type="checkbox"
                                            onChange={(e) => {
                                              props.onChange(e.target.checked);
                                              setShowAllLeads(!showAllLeads);
                                            }}
                                            checked={props.value}
                                          />
                                          Leads de todos os usuários
                                          <FormTooltip text="Habilitando este atributo o usuário passará a visualizar todos os Leads de todos os atendentes, caso contrário, visualizará apenas seus próprios Leads." />
                                        </label>
                                      </>
                                    )}
                                    defaultValue=""
                                  />
                                </Col>
                                <Col sm="12" md="6" className="mx-4">
                                  <Controller
                                    name="show_all_opportunities"
                                    control={control}
                                    render={(props) => (
                                      <>
                                        <label className="form-control-label">
                                          <Input
                                            type="checkbox"
                                            onChange={(e) => {
                                              props.onChange(e.target.checked);
                                              setShowAllOpportunities(
                                                !showAllOpportunities
                                              );
                                            }}
                                            checked={props.value}
                                          />
                                          Oportunidades de todos os usuários
                                          <FormTooltip text="Habilitando este atributo o usuário passará a visualizar todas as Oportunidades de todos os atendentes, caso contrário, visualizará apenas suas próprias Oportunidades." />
                                        </label>
                                      </>
                                    )}
                                    defaultValue=""
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Ramais
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={extensions}
                                    placeholder="Selecione os ramais"
                                    isClearable={true}
                                    isMulti={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="extensionsid"
                                    labelName="extension_number"
                                    valueName="extension_number"
                                  />
                                )}
                                control={control}
                                name="extensionsid"
                                defaultValue=""
                              />
                            </Col>
                            <Col md="6">
                              <label className="form-control-label">
                                Fila(s)
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={queues}
                                    placeholder="Selecione a(s) fila(s)"
                                    isClearable={true}
                                    isMulti={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="queuesid"
                                    labelName="name"
                                    valueName="id"
                                  />
                                )}
                                control={control}
                                name="queuesid"
                                defaultValue=""
                              />
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Criação de novos usuários
                                <FormTooltip text="Campo para adição de quais são os tipos de perfis permitidos que este usuário poderá criar e fazer alterações." />
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={profiles}
                                    placeholder="Selecione os perfis"
                                    isMulti={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="profilesid"
                                    labelName="name"
                                    valueName="id"
                                  />
                                )}
                                control={control}
                                name="profilesid"
                                defaultValue=""
                              />
                            </Col>
                            <Col md="6">
                              <label className="form-control-label">
                                Setor(es)
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={sectors}
                                    isMulti={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="costscenterid"
                                    labelName="name"
                                    valueName="id"
                                  />
                                )}
                                control={control}
                                name="costscenterid"
                                defaultValue=""
                              />
                            </Col>
                          </Row>

                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Tipos de registro*
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={recordTypes ?? []}
                                    isMulti={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="recordtypesid"
                                    labelName="name"
                                    valueName="id"
                                  />
                                )}
                                control={control}
                                rules={{
                                  validate: (value) => {
                                    if (!value || !value.length) {
                                      !verifyRoles(['USER_PERMISSIONS']) &&
                                        toast.error(
                                          'Tipo de registro é obrigatório!',
                                          {
                                            autoClose: 3000,
                                            closeOnClick: true
                                          }
                                        );

                                      return 'Tipo de registro é obrigatório!';
                                    }
                                    return true;
                                  }
                                }}
                                name="recordtypesid"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="recordtypesid"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                            <Col md="6">
                              <label className="form-control-label">
                                Permissões de equipe
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={teams}
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="teamsIds"
                                    labelName="name"
                                    valueName="id"
                                  />
                                )}
                                control={control}
                                name="teamsIds"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="teamsIds"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Col md="6">
                              <label className="form-control-label">
                                Supervisores
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={userList ?? []}
                                    isMulti={true}
                                    closeMenuOnSelect={false}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="supervisorids"
                                    labelName="name"
                                    valueName="login"
                                  />
                                )}
                                control={control}
                                name="supervisorids"
                                defaultValue=""
                              />
                            </Col>
                          </Row>
                        </Row>
                        <Row>
                          <Col>
                            <h3 className="mb-1">Configurações de email</h3>
                          </Col>
                        </Row>

                        <Row>
                          <Col md="12">
                            <FormGroup className="mb-0">
                              <label className="form-control-label">
                                <input
                                  type="checkbox"
                                  checked={initialConfigSignatureEmail.active}
                                  onChange={() => {
                                    setInitialConfigSignatureEmail({
                                      ...initialConfigSignatureEmail,
                                      active:
                                        !initialConfigSignatureEmail.active
                                    });
                                  }}
                                  style={{ marginRight: '0.35rem' }}
                                />
                                Vincular assinatura de email personalizada
                              </label>
                            </FormGroup>
                          </Col>
                        </Row>
                        {initialConfigSignatureEmail.active && (
                          <Row>
                            <Col>
                              <ReactQuill
                                modules={modules}
                                name="textEmailForaHorario"
                                className="editorText"
                                theme="snow"
                                defaultValue={
                                  initialConfigSignatureEmail.signature
                                }
                                onChange={setDataEmailSignature}
                              />
                            </Col>
                          </Row>
                        )}

                        <Row
                          className="mb-1"
                          style={
                            verifyRoles(['USER_PENALTY'])
                              ? { display: 'block', padding: '1rem' }
                              : { display: 'none' }
                          }
                        >
                          {selectedQueues && (
                            <QueuePenaltyTable
                              selectedQueues={selectedQueues}
                              handleOpenModalUpdatePenality={
                                handleOpenModalUpdatePenality
                              }
                            />
                          )}
                        </Row>

                        <hr />

                        <ReactButton btnColor="confirmation" type="submit">
                          Salvar
                        </ReactButton>
                        <ReactButton
                          btnColor="cancelation"
                          onClick={() => cancelAction()}
                        >
                          Cancelar
                        </ReactButton>
                      </Form>
                    </>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
        {login && (
          <EditPassword
            toggleModal={toggleModal}
            isOpen={openModal}
            login={login}
          />
        )}
      </Container>
      <ModalUpdatePenality
        setSelectedQueues={setSelectedQueues}
        selectedQueues={selectedQueues}
        queueUpdate={queueUpdatePenality}
        isModalOpen={openModalUpdatePenality}
        onModalToggle={setOpenModalUpdatePenality}
        unmountOnClose={true}
      />
    </>
  );
};

export default UserForm;
