import styled from 'styled-components';

export const ButtonChip = styled.button`
  min-height: 40px;
  padding: 5px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline-style: none;
  /* mix-blend-mode: difference; */
  border: 1px solid ${(props) => props.color};
  color: ${(props) =>
    props.isActive && props.colorTagSelected
      ? props.colorTagSelected
      : props.color || 'white'}; //colorTagSelected
  background: ${(props) => (props.isActive ? props.color : 'transparent')};
  margin-top: 0.4rem;
  & + & {
    margin-left: 0.4rem;
  }
`;
