import ContextService from '../../Context/service';
import AudioService from '../../Audio/service';
import CallbackService from '../../Callback/service';
import QueueService from '../../Queue/service';
import ExtensionService from '../../Extension/service';
import IVRService from '../service/ura';
import TimeScheduleService from '../../TimeSchedule/service';

export const fetchContexts = async () => {
  try {
    const response = await ContextService.getAll(null, null, false, '', {
      type: 'ENTRADA'
    });
    return response.data.data;
  } catch (error) {
    console.log('Error getting contexts', error);
    return null;
  }
};

export const fetchAudios = async () => {
  try {
    const response = await AudioService.getAll();
    return response.data.data;
  } catch (error) {
    console.log('Error getting audios', error);
    return null;
  }
};

export const fetchCallbacks = async () => {
  try {
    const response = await CallbackService.getAll();
    return response.data.data;
  } catch (error) {
    console.log('Error getting callbacks', error);
    return null;
  }
};

export const fetchQueues = async () => {
  try {
    const response = await QueueService.getAll();
    return response.data.data;
  } catch (error) {
    console.log('Error getting queues', error);
    return null;
  }
};

export const fetchExtensions = async () => {
  try {
    const response = await ExtensionService.getAllSimple();
    return response.data.data;
  } catch (error) {
    console.log('Error getting extensions', error);
    return null;
  }
};

export const fetchIVR = async () => {
  try {
    const response = await IVRService.getAll();
    return response.data.data;
  } catch (error) {
    console.log('Error getting IVRs', error);
    return null;
  }
};

export const fetchSchedules = async () => {
  try {
    const response = await TimeScheduleService.getAll();
    return response.data.data;
  } catch (error) {
    console.log('Error getting time schedules', error);
    return null;
  }
};
