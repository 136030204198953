import { useState, useEffect } from 'react';
import ChatBroadcastListService from '../../ChatBroadcastListSMS/service';

export function useGetBroadcastList() {
  //queue to select
  useEffect(() => {
    ChatBroadcastListService.getAllWithoutPagination()
      .then((response) => {
        const list = response.data.map((item) => {
          return { value: item.id, label: item.title };
        });
        setBroadcastList(list);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);

  const [broadcastList, setBroadcastList] = useState([]);

  return {
    broadcastList,
    setBroadcastList
  };
}
