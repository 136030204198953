import React, { useEffect, useState } from 'react';
import ConversationService from '../../../../../service';
import { validationFiles } from './assets/validationFiles';
import { generatePreview } from './assets/generatePreview';
import { addMessageConversationInAttendance } from '../../../../../../../store/action/conversation-in-attendance-action';
import { useSelector, useDispatch } from 'react-redux';
import { PreviewImage } from './components/PreviewImage';
import { PreviewPdf } from './components/PreviewPdf';
import { PreviewFile } from './components/PreviewFile';
import { imageExtensions, videoExtensions } from './components/assets';
import { Preview } from './components/styled';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { Input, Button, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';
import DefaultModal from 'components/Modal/DefaultModal';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';

const UploadFile = ({
  id,
  defaultFiles = [],
  isModalOpen,
  onModalToggle,
  disabledSubmit = false,
  conversation,
  ...props
}) => {
  const [files, setFiles] = useState([]);
  const [pdfPreview, setPdfPreview] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);
  const [
    fileExtensionsWithPreviewAlreadyGenerated,
    setFileExtensionsWithPreviewAlreadyGenerated
  ] = useState([]);
  const [conversationId, setConversationId] = useState(null);
  const {
    CheckedRefusedExtensionsFiles,
    isAllFilesWithApprovedExtension,
    isFilesWithApprovedExtension,
    isImagesWithApprovedExtension,
    sizeInvalidFileValidation
  } = validationFiles();
  const { getImagePreview, getPDFPreview } = generatePreview();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const dispatch = useDispatch();

  useEffect(() => {
    setConversationId(id);
  }, [id]);

  useEffect(() => {
    if (defaultFiles && defaultFiles.length) {
      onModalToggle(true);
      handleSelectFile(defaultFiles);
    }
  }, [defaultFiles]);

  useEffect(() => {
    if (!isModalOpen) {
      reset();
    }
  }, [isModalOpen]);

  const handleOnChangeFile = (event) => {
    const files = event.target.files;
    handleSelectFile(files);
  };

  const handleSelectFile = (files) => {
    reset();

    if (!files) return;

    const filesToBeSent = Array.from(files);

    const isCheckedRefusedExtensionsFiles =
      CheckedRefusedExtensionsFiles(filesToBeSent);

    if (isCheckedRefusedExtensionsFiles) {
      toast.error('Erro ao enviar arquivo(s). Formato não permitido');
      return;
    }

    const isInvalidTypeFile = isAllFilesWithApprovedExtension(filesToBeSent);
    if (Object.keys(isInvalidTypeFile).length !== 0) {
      toast.error(
        `${isInvalidTypeFile.message}: ${isInvalidTypeFile.extension}`
      );
      return;
    }

    const isSizeFileValidation = sizeInvalidFileValidation(
      filesToBeSent,
      'CHAT'
    );
    if (Object.keys(isSizeFileValidation).length !== 0) {
      toast.error(
        `${isSizeFileValidation.message} ${isSizeFileValidation.extension}`
      );
      return;
    }

    const imagesWithApprovedExtension =
      isImagesWithApprovedExtension(filesToBeSent);

    const selectedImagePreview = getImagePreview(imagesWithApprovedExtension);

    const filesWithApprovedExtension =
      isFilesWithApprovedExtension(filesToBeSent);

    const selectedPdfPreview = getPDFPreview(filesWithApprovedExtension);

    const getFileExtensionsWithPreviewAlreadyGenerated = [
      ...selectedImagePreview,
      ...selectedPdfPreview
    ].map((file) => {
      return file?.nameFile.split('.').pop();
    });

    setFileExtensionsWithPreviewAlreadyGenerated(
      getFileExtensionsWithPreviewAlreadyGenerated
    );
    setPreviewImages(selectedImagePreview);
    setPdfPreview(selectedPdfPreview);
    setFiles(filesToBeSent);
  };

  const onSubmit = (sendFile) => {
    if (!sendFile) {
      return;
    }
    try {
      if (
        conversation.canal === 'whatsapp' ||
        conversation.canal === 'chat' ||
        conversation.canal === 'flex4u'
      ) {
        const fileExtension = sendFile.name.split('.').pop();
        const dateNow = new Date();
        const formatedData = new FormData();

        formatedData.append('file', sendFile);
        formatedData.append('idDaConversa', conversationId); //body
        formatedData.append('cliente_ou_atendente', 'atendente'); //
        formatedData.append('escrita_por', localStorage.getItem('name'));
        formatedData.append(
          'legenda',
          `${sendFile.name}_upload_atendente_${dateNow.toLocaleDateString(
            'pt-br'
          )}-${dateNow.toLocaleTimeString('pt-br')}`
        );

        if (imageExtensions.includes(fileExtension)) {
          formatedData.append('response_type', 'image');
          formatedData.append('type', 'image');
        } else if (videoExtensions.includes(fileExtension)) {
          formatedData.append('response_type', 'video');
          formatedData.append('type', 'video');
        } else {
          formatedData.append('response_type', 'file');
          formatedData.append('type', 'file');
        }

        if (conversation.canal === 'whatsapp') {
          FlexChatIntegration.uploadFile(conversationId, formatedData)
            .then((res) => {
              dispatch(
                addMessageConversationInAttendance(
                  { ...res.data },
                  conversationId
                )
              );
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (conversation.canal === 'flex4u') {
          FlexChatIntegration.uploadFileFlex4U(formatedData)
            .then((res) => {
              dispatch(
                addMessageConversationInAttendance(
                  { ...res.data.data },
                  conversationId
                )
              );
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          FlexChatIntegration.uploadFileWebchat(formatedData)
            .then((res) => {
              dispatch(
                addMessageConversationInAttendance(
                  { ...res.data.data },
                  conversationId
                )
              );
            })
            .catch((err) => {
              console.log(err);
            });
        }

        reset();
        toggleModal();

        return;
      }

      const fileExtension = sendFile.name.split('.').pop();
      const dateNow = new Date();
      const formatedData = new FormData();

      formatedData.append('files', sendFile);
      formatedData.append('idDaConversa', conversationId); //body
      formatedData.append('cliente_ou_atendente', 'atendente'); //
      formatedData.append('escrita_por', localStorage.getItem('name'));
      formatedData.append(
        'legenda',
        `${sendFile.name}_upload_atendente_${dateNow.toLocaleDateString(
          'pt-br'
        )}-${dateNow.toLocaleTimeString('pt-br')}`
      );

      if (imageExtensions.includes(fileExtension)) {
        formatedData.append('response_type', 'image');
        formatedData.append('type', 'image');
      } else if (videoExtensions.includes(fileExtension)) {
        formatedData.append('response_type', 'video');
        formatedData.append('type', 'video');
      } else {
        formatedData.append('response_type', 'file');
        formatedData.append('type', 'file');
      }
      ConversationService.uploadFile(formatedData)
        .then((res) => {
          dispatch(
            addMessageConversationInAttendance({ ...res.data }, conversationId)
          );
        })
        .catch((err) => {
          console.log(err);
        });

      reset();
      toggleModal();
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const reset = () => {
    setFileExtensionsWithPreviewAlreadyGenerated([]);
    setPreviewImages([]);
    setPdfPreview([]);
    setFiles([]);
  };

  const toggleModal = () => {
    onModalToggle(false);
    reset();
  };

  const processSubmit = () => {
    for (const file of files) {
      onSubmit(file);
    }
  };

  return (
    <>
      <DefaultModal
        show={isModalOpen}
        onModalToggle={onModalToggle}
        title="Enviar Arquivo"
        showCloseButton={false}
        modalContainerClasses="modal-sm"
        {...props}
      >
        <hr style={{ marginTop: '-30px' }} />
        <label
          htmlFor="uploadFile"
          className="btn btn-primary btn-sm"
          style={buttonStyle(systemConfiguration.confirmation_button_color)}
        >
          {Object.keys(files).length
            ? 'Escolher outro(s)'
            : 'Selecionar arquivo(s)'}
        </label>
        <Input
          style={{ display: 'none' }}
          type="file"
          id="uploadFile"
          multiple
          name="uploadFile"
          onChange={handleOnChangeFile}
        />

        <Row className="mt-2 mb-2">
          <Col className="d-flex justify-content-center">
            <Preview>
              {!!Object.keys(previewImages).length &&
                previewImages.map((previewImage, index) => {
                  return (
                    <PreviewImage key={index} previewImage={previewImage} />
                  );
                })}

              {!!Object.keys(pdfPreview).length &&
                pdfPreview.map((filePreview, index) => {
                  return <PreviewPdf key={index} filePreview={filePreview} />;
                })}

              {!!Object.keys(files).length &&
                files.map(
                  (file, index) =>
                    fileExtensionsWithPreviewAlreadyGenerated.includes(
                      file.name.split('.').pop()
                    ) === false && <PreviewFile key={index} file={file} />
                )}
            </Preview>
          </Col>
        </Row>

        <Row className="mt-5">
          <Col>
            <Button
              disabled={disabledSubmit}
              style={buttonStyle(systemConfiguration.confirmation_button_color)}
              onClick={processSubmit}
            >
              Enviar
            </Button>
            <Button
              style={buttonStyle(systemConfiguration.cancelation_button_color)}
              onClick={toggleModal}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </DefaultModal>
    </>
  );
};

export default UploadFile;
