import React, { useState, useEffect } from 'react';
import { FiMeh } from 'react-icons/fi';
import {
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { useSelector } from 'react-redux';
import LoggedInAgentsTable from '../LoggedInAgentsTable';
import { UserNotLoggedIn, InputGroupWrapper } from './styled';
import { useCallback } from 'react';

const QueueTable = ({
  loggedInAgents,
  pauses,
  handlePause,
  handleUnpause,
  handleLogout,
  handleChangePenalty
}) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [filteredLoggedAgents, setFilteredLoggedAgents] = useState([]);
  const [filterValue, setFilterValue] = useState('');

  useEffect(() => {
    onSubmit(filterValue);
  }, [filterValue, loggedInAgents, onSubmit]);

  const handleFilterInformation = useCallback(
    (values) => {
      const agentsInfoValues = values.toLowerCase();
      let filteredAgents = [];

      loggedInAgents.forEach((agent) => {
        let agentQueueNames = '';
        agent.queues.find((agentQueue) => {
          if (agentQueue.name.toLowerCase().includes(agentsInfoValues)) {
            agentQueueNames = agentQueue.name;
          }
        });
        if (
          agent.user.toLowerCase().includes(agentsInfoValues) === true ||
          agentQueueNames.toLowerCase().includes(agentsInfoValues) === true ||
          agent.extension.toString().includes(agentsInfoValues) === true
        ) {
          filteredAgents.push(agent);
        }
      });
      setFilteredLoggedAgents(filteredAgents);
    },
    [loggedInAgents]
  );

  const onSubmit = useCallback(
    (values) => {
      handleFilterInformation(values);
    },
    [handleFilterInformation]
  );

  return (
    <>
      {loggedInAgents.length > 0 ? (
        <React.Fragment>
          <Row className="mb-3">
            <Col md="4">
              <InputGroupWrapper>
                <InputGroup
                  style={{
                    minWidth: '19.875rem',
                    width: '79%'
                  }}
                >
                  <InputGroupAddon
                    addonType="prepend"
                    style={{
                      height: '2.375rem'
                    }}
                  >
                    <InputGroupText
                      style={{
                        height: '2.375rem'
                      }}
                    >
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    style={{
                      height: '2.375rem'
                    }}
                    placeholder="Pesquisar por agente, fila ou ramal"
                    title="Pesquisar por agente, fila ou ramal"
                    type="text"
                    onChange={(e) => {
                      onSubmit(e.target.value);
                      setFilterValue(e.target.value);
                    }}
                  />
                </InputGroup>
              </InputGroupWrapper>
            </Col>
          </Row>
          <Row
            className="overflow-y-visible"
            style={{
              marginTop: '-1rem'
            }}
          >
            <Col md="12">
              <LoggedInAgentsTable
                loggedInAgents={filteredLoggedAgents}
                pauses={pauses}
                handlePause={handlePause}
                handleUnpause={handleUnpause}
                handleLogout={handleLogout}
                handleChangePenalty={handleChangePenalty}
              />
            </Col>
          </Row>
        </React.Fragment>
      ) : (
        <UserNotLoggedIn>
          <p className="mb-1 text-muted">
            Ops...Não há nenhum usuário logado no momento
          </p>
          <FiMeh size={25} color={systemConfiguration.primary_color} />
        </UserNotLoggedIn>
      )}
    </>
  );
};

export default QueueTable;
