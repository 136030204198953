import { apiChatExport as http } from '../http';
//
class ServiceExportsFLEXCHAT {
  constructor(path) {
    this.path = path;
  }

  getDownloads(type, queryParams = {}) {
    return http.get(`${this.path}/${type}/csv`, {
      params: {
        ...queryParams
      },
      responseType: 'blob'
    });
  }

  getDownloadsPdf(type, queryParams = {}) {
    return http.get(`${this.path}/${type}/pdf`, {
      params: {
        ...queryParams
      },
      responseType: 'blob'
    });
  }

  getDownloadsUnified(type, queryParams = {}, midia) {
    return http.get(`${this.path}/unificado/${type}/${midia}`, {
      params: {
        ...queryParams
      },
      responseType: 'blob'
    });
  }
}

export default ServiceExportsFLEXCHAT;
