export const dateRules = {
  required: 'Data é obrigatório!',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};

export const cpfRules = {
  pattern: {
    value: /^[0-9][0-9][0-9].[0-9][0-9][0-9].[0-9][0-9][0-9]-[0-9][0-9]$/,
    message: 'CPF inválido! (000.000.000-00)'
  }
};

export const reportOptions = [
  { label: 'Analítico', value: 1 },
  { label: 'Sintético', value: 2 }
];

export const situationOptions = [
  { label: 'Abandonada', value: 'abandonada' },
  // { label: 'Em atendimento', value: 'em_atendimento' },
  { label: 'Encerrada', value: 'encerrada' }
  // { label: 'Não atendida', value: 'nao_atendida' },
  // { label: 'Transferida', value: 'Transferida' }
];

export const closedByOptions = [
  { label: 'Administrador', value: 'ADMNISTRADOR' },
  { label: 'Atendente', value: 'ATENDENTE' },
  { label: 'Bot', value: 'BOT' },
  { label: 'Cliente', value: 'CLIENTE' },
  { label: 'Ociosidade', value: 'OCIOSIDADE' }
];

export const booleanOptions = [
  { label: 'Sim', value: 'true' },
  { label: 'Não', value: 'false' }
];

export const satisfactionOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 }
];

export const channelOptions = [
  { label: 'Chat', value: 'chat' },
  { label: 'Facebook', value: 'facebook' },
  { label: 'Telegram', value: 'telegram' },
  { label: 'Whatsapp', value: 'whatsapp' }
];

export const groupByOptions = [
  { label: 'Assunto', value: 'assunto' },
  { label: 'Atendimento Bot', value: 'atendimentoBot' },
  { label: 'Atendente', value: 'atendente' },
  { label: 'Assunto Bot', value: 'assuntoBot' },
  { label: 'Fila', value: 'fila' },
  { label: 'Motivo', value: 'motivo' },
  { label: 'Produto', value: 'produto' },
  { label: 'Setor', value: 'setor' },
  { label: 'Sucesso Atendimento Bot', value: 'sucessoAtendimento' },
  { label: 'Situação', value: 'situacao' },
  { label: 'Tag', value: 'tag' }
];

export const wordCloudScoreOptions = [
  { label: 'Péssimo', value: 'PESSIMO' },
  { label: 'Ruim', value: 'RUIM' },
  { label: 'Razoável', value: 'RAZOAVEL' },
  { label: 'Bom', value: 'BOM' },
  { label: 'Ótimo', value: 'OTIMO' }
];

//Registros para testes
export const exemple = {
  conversas: [
    {
      nova_mensagem: false,
      encerrada: true,
      atendida: false,
      atendimentoBot: true,
      sucessoAtendimento: false,
      situacao: 'abandonada',
      meioTransferencia: 'chat',
      encerrada_por: 'OCIOSIDADE',
      fechou_janela: false,
      novas_mensagens: 0,
      _id: '5f54f4727cb1320011477e29',
      idSessao: 'cfb4ded7-3698-41b6-a744-433b6cf69293',
      fila: 'Suporte',
      cliente: {
        _id: '5f54efc67cb1320011477e18',
        celular: '558581516447'
      },
      canal: 'whatsapp',
      hora_criacao: '2020-09-06T14:18:46.313Z',
      mensagens: [
        {
          hora_da_mensagem: '2020-09-06T14:18:46.371Z',
          _id: '5f54efc67cb1320011477e19',
          escrita_por: 'FlexIA',
          texto:
            'Oi Felipe! Me chamo FlexIA, sou a assistente virtual da G4 Flex. Antes de continuar seu atendimento, vou precisar de algumas informações.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-06T14:18:46.378Z',
          _id: '5f54efc67cb1320011477e1a',
          escrita_por: 'FlexIA',
          texto: 'Qual seu endereço de e-mail?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        }
      ],
      resumoBot: [],
      atendente: {
        _id: '5e974df08d8e9e00118cffca',
        name: 'FlexIA'
      },
      hora_do_atendimento: '2020-09-06T14:18:46.310Z',
      assunto: 'Dúvidas',
      status: 'Em andamento',
      produto: 'Produto de Testes',
      setor: 'Default',
      observacao: 'Conversa sem interação a mais de 600000ms',
      hora_fim_conversa: '2020-09-06T14:38:42.461Z',
      timeline: [
        {
          horaAtividade: '2020-09-06T14:18:46.312Z',
          atividade: 'em_atendimento',
          descricao:
            'undefined entrou em atendimento com FlexIA na fila Suporte'
        },
        {
          horaAtividade: '2020-09-06T14:38:42.461Z',
          atividade: 'encerrada',
          descricao: 'Conversa encerrada pelo atendente FlexIA'
        }
      ],
      __v: 0
    },
    {
      nova_mensagem: false,
      encerrada: true,
      atendida: true,
      atendimentoBot: false,
      sucessoAtendimento: false,
      situacao: 'encerrada',
      meioTransferencia: 'call',
      encerrada_por: 'ATENDENTE',
      fechou_janela: false,
      novas_mensagens: 0,
      _id: '5f57996af445330011129c5f',
      idSessao: '93c5de9d-1eaf-4faf-a136-4fdb444df76e',
      idSF: '00Q3k00001PcuIZEAZ',
      tipoCadastroSF: 'lead',
      fila: 'Comercial',
      cliente: {
        _id: '5f579570f445330011129b42',
        nome: 'Patrick Saldanha',
        email: 'patrickvoip@hotmail.com',
        celular: '85996131579',
        id_telegram: '322260947',
        id_facebook: ''
      },
      canal: 'telegram',
      hora_criacao: '2020-09-08T14:28:26.289Z',
      mensagens: [
        {
          hora_da_mensagem: '2020-09-08T14:28:26.400Z',
          _id: '5f57950af4453300111299ea',
          escrita_por: 'FlexIA',
          texto:
            'Oi Patrick Saldanha! Me chamo FlexIA, sou a assistente virtual da G4 Flex. Antes de continuar seu atendimento, vou precisar de algumas informações.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:28:26.400Z',
          _id: '5f57950af4453300111299eb',
          escrita_por: 'FlexIA',
          texto: 'Qual seu endereço de e-mail?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:28:56.032Z',
          _id: '5f579528f4453300111299fa',
          escrita_por: 'Patrick Saldanha',
          texto: 'patrickvoip@hotmail.com',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:28:56.032Z',
          _id: '5f579528f4453300111299fb',
          escrita_por: 'FlexIA',
          texto:
            'Qual o seu número de celular? Exemplo: DDD + Número => 11988776655',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:08.057Z',
          _id: '5f579534f445330011129a0a',
          escrita_por: 'Patrick Saldanha',
          texto: '85996131579',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:08.058Z',
          _id: '5f579534f445330011129a0b',
          escrita_por: 'FlexIA',
          texto:
            'O email "patrickvoip@hotmail.com" e o telefone "85996131579" estão corretos?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:12.316Z',
          _id: '5f579538f445330011129a1c',
          escrita_por: 'Patrick Saldanha',
          texto: 'Sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:12.316Z',
          _id: '5f579538f445330011129a1d',
          escrita_por: 'FlexIA',
          texto: 'Ok',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:14.779Z',
          _id: '5f57953af445330011129a3b',
          escrita_por: 'FlexIA',
          texto:
            'Sou nova por aqui, mas já consigo te ajudar com alguns assuntos que já aprendi!',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:14.780Z',
          _id: '5f57953af445330011129a3c',
          escrita_por: 'FlexIA',
          title: 'Você pode escolher uma das opções:',
          description: '',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:14.780Z',
          _id: '5f57953af445330011129a3d',
          escrita_por: 'FlexIA',
          options: '1 - Atendimento Comercial',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:14.781Z',
          _id: '5f57953af445330011129a3e',
          escrita_por: 'FlexIA',
          options: '2 - Atendimento Suporte',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:14.781Z',
          _id: '5f57953af445330011129a3f',
          escrita_por: 'FlexIA',
          options: '3 - Ambiente de Consultor',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:22.284Z',
          _id: '5f579542f445330011129a57',
          escrita_por: 'Patrick Saldanha',
          texto: '1 - Atendimento Comercial',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:22.285Z',
          _id: '5f579542f445330011129a58',
          escrita_por: 'FlexIA',
          texto:
            'Nosso propósito é otimizar o tempo nas relações de comunicação entre pessoas e empresas, afinal ele, o tempo, é o único bem que não podemos recuperar.  ',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:22.285Z',
          _id: '5f579542f445330011129a59',
          escrita_por: 'FlexIA',
          title: 'Para isso desenvolvemos as seguintes ferramentas:',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:22.285Z',
          _id: '5f579542f445330011129a5a',
          escrita_por: 'FlexIA',
          options: ' 1 - FlexUC',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:22.286Z',
          _id: '5f579542f445330011129a5b',
          escrita_por: 'FlexIA',
          options: '2 - FlexIA',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:22.287Z',
          _id: '5f579542f445330011129a5c',
          escrita_por: 'FlexIA',
          options: '3 - Salesforce',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:22.296Z',
          _id: '5f579542f445330011129a5d',
          escrita_por: 'FlexIA',
          options: '4 - Integrações',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:30.021Z',
          _id: '5f57954af445330011129a7c',
          escrita_por: 'Patrick Saldanha',
          texto: '1',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:30.027Z',
          _id: '5f57954af445330011129a7d',
          escrita_por: 'FlexIA',
          texto:
            'O FlexUC  é uma plataforma completa para gerenciamento de Call Centers com monitoramento de Filas, Ramais, Troncos, E1 e muito mais.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:30.028Z',
          _id: '5f57954af445330011129a7e',
          escrita_por: 'FlexIA',
          texto: 'Deseja saber mais sobre outro produto?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:35.512Z',
          _id: '5f57954ff445330011129aa0',
          escrita_por: 'Patrick Saldanha',
          texto: 'Não',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:35.512Z',
          _id: '5f57954ff445330011129aa1',
          escrita_por: 'FlexIA',
          texto: 'Deseja ser transferido para atendimento?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:40.601Z',
          _id: '5f579554f445330011129ac5',
          escrita_por: 'Patrick Saldanha',
          texto: 'Sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:40.601Z',
          _id: '5f579554f445330011129ac6',
          escrita_por: 'FlexIA',
          title: 'Tipos de Atendimento',
          description: 'Selecione como você deseja seu atendimento.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:40.602Z',
          _id: '5f579554f445330011129ac7',
          escrita_por: 'FlexIA',
          options: 'Por Chat',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-08T14:29:40.602Z',
          _id: '5f579554f445330011129ac8',
          escrita_por: 'FlexIA',
          options: 'Por Ligação',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-08T14:30:02.701Z',
          _id: '5f57956af445330011129af0',
          escrita_por: 'Patrick Saldanha',
          texto: 'Por Ligação',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:30:02.702Z',
          _id: '5f57956af445330011129af1',
          escrita_por: 'FlexIA',
          texto:
            'Certo! Vou retornar para você utilizando esse número 85996131579, tudo bem?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:30:08.325Z',
          _id: '5f579570f445330011129b1b',
          escrita_por: 'Patrick Saldanha',
          texto: 'Sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:30:08.325Z',
          _id: '5f579570f445330011129b1c',
          escrita_por: 'FlexIA',
          texto:
            'Aguarde ai! Nesse momento estou conectando um de nossos analistas a você.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:42:51.136Z',
          _id: '5f57986bf445330011129b7a',
          escrita_por: 'Patrick Saldanha',
          texto: 'Quero um desconto de 100% em todos os seus produtos',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:42:55.385Z',
          _id: '5f57986ff445330011129ba5',
          escrita_por: 'Patrick Saldanha',
          texto: 'por tempo indeterminado.',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:43:20.763Z',
          _id: '5f579888f445330011129bd3',
          escrita_por: 'Patrick Saldanha',
          texto:
            'Infelizmente neste momento não será possível aplicar o desconto solicitado.\n',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-08T14:44:32.215Z',
          _id: '5f5798d0f445330011129c00',
          escrita_por: 'Patrick Saldanha',
          source:
            'https://flexia.g4flex.com.br/uploads/3426e91f1d2ff739646bc4f6cf93f6031599576272205.jpg',
          description: '',
          cliente_ou_atendente: 'cliente',
          response_type: 'image'
        },
        {
          hora_da_mensagem: '2020-09-08T14:44:32.215Z',
          _id: '5f5798d0f445330011129c00',
          escrita_por: 'Patrick Saldanha',
          source:
            'https://static.wixstatic.com/media/71a6c2_bddf371813b546a7834c252183473600~mv2.png/v1/fill/w_500,h_281,al_c,q_85,usm_0.66_1.00_0.01/71a6c2_bddf371813b546a7834c252183473600~mv2.webp',
          description: '',
          cliente_ou_atendente: 'cliente',
          response_type: 'image'
        },
        {
          hora_da_mensagem: '2020-09-08T14:44:32.215Z',
          _id: '5f5798d0f445330011129c00',
          escrita_por: 'Patrick Saldanha',
          source:
            'https://quatrorodas.abril.com.br/wp-content/uploads/2019/12/mustang-2020-10-e1576088366956.jpg?quality=70&strip=info',
          description: '',
          cliente_ou_atendente: 'cliente',
          response_type: 'image'
        },
        {
          hora_da_mensagem: '2020-09-08T14:44:32.215Z',
          _id: '5f5798d0f445330011129c00',
          escrita_por: 'Patrick Saldanha',
          source: 'https://images4.alphacoders.com/936/936378.jpg',
          description: '',
          cliente_ou_atendente: 'cliente',
          response_type: 'image'
        },
        {
          hora_da_mensagem: '2020-09-08T14:44:32.215Z',
          _id: '5f5798d0f445330011129c00',
          escrita_por: 'Patrick Saldanha',
          source:
            'https://profile.freepik.com/accounts/avatar/default_07.png?1596039107',
          description: '',
          cliente_ou_atendente: 'cliente',
          response_type: 'image'
        },
        {
          hora_da_mensagem: '2020-09-08T14:47:05.650Z',
          _id: '5f579969f445330011129c30',
          escrita_por: 'Patrick Saldanha',
          texto: 'Foi um prazer te atender hoje!!! Estou me desconectando.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        }
      ],
      resumoBot: [
        {
          id: 'Assunto',
          value: 'Comercial'
        },
        {
          id: 'Descrição',
          value: 'Está interessados nos produtos FlexUC'
        }
      ],
      atendente: {
        _id: '5ed79db82d219500112b0054',
        name: 'Patrick Saldanha'
      },
      hora_do_atendimento: '2020-09-08T14:38:42.929Z',
      id_socket_atendente: 'oefQyDuVN3-Bv0_xAAAK',
      assunto: 'Atendimento Comercial',
      status: 'Encerrado',
      produto: 'FlexUC - Aplicação',
      setor: 'Comercial',
      observacao: 'Apenas mais um teste de demonstração.',
      hora_fim_conversa: '2020-09-08T14:47:06.006Z',
      timeline: [
        {
          horaAtividade: '2020-09-08T14:28:26.287Z',
          atividade: 'em_atendimento',
          descricao:
            'Patrick Saldanha entrou em atendimento com FlexIA na fila Suporte'
        },
        {
          horaAtividade: '2020-09-08T14:30:08.371Z',
          atividade: 'transferida',
          descricao:
            'Cliente transferido para fila Comercial pelo atendente FlexIA'
        },
        {
          horaAtividade: '2020-09-08T14:47:06.006Z',
          atividade: 'encerrada',
          descricao: 'Conversa encerrada pelo atendente Patrick Saldanha'
        }
      ],
      __v: 0
    },
    {
      nova_mensagem: false,
      encerrada: true,
      atendida: true,
      atendimentoBot: false,
      sucessoAtendimento: false,
      situacao: 'abandonada',
      meioTransferencia: 'call',
      encerrada_por: 'OCIOSIDADE',
      fechou_janela: false,
      novas_mensagens: 0,
      _id: '5f61385bf445330011129f9d',
      idSessao: 'b97d574d-a25f-46e8-abd6-afccda2bb3ce',
      idSF: '00Q3k00001RSvIeEAL',
      tipoCadastroSF: 'lead',
      fila: 'Comercial',
      cliente: {
        _id: '5f6113b3f445330011129e0b',
        nome: 'Raul Martins',
        email: 'raulmartins@betanialacteos.com.br',
        celular: '558892320405',
        id_telegram: '',
        id_facebook: ''
      },
      canal: 'whatsapp',
      hora_criacao: '2020-09-15T19:15:15.448Z',
      mensagens: [
        {
          hora_da_mensagem: '2020-09-15T19:15:15.459Z',
          _id: '5f6112c3f445330011129c8d',
          escrita_por: 'FlexIA',
          texto:
            'Olá Raul Martins! Meu nome é FlexIA, sou a assistente virtual da G4 Flex. Antes de continuar seu atendimento, vou precisar de algumas informações.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:15:15.461Z',
          _id: '5f6112c3f445330011129c8e',
          escrita_por: 'FlexIA',
          texto: 'Qual seu endereço de e-mail?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:15:19.151Z',
          _id: '5f6112c7f445330011129c9b',
          escrita_por: 'Raul Martins',
          texto: 'Boa tarde',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:15:19.151Z',
          _id: '5f6112c7f445330011129c9c',
          escrita_por: 'FlexIA',
          texto: 'Desculpa, não entendi. Poderia digitar seu email novamente.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:15:57.697Z',
          _id: '5f6112edf445330011129cad',
          escrita_por: 'Raul Martins',
          texto: 'raulmartins@betanialacteos.com.br',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:15:57.697Z',
          _id: '5f6112edf445330011129cae',
          escrita_por: 'FlexIA',
          texto:
            'Qual o seu número de celular? Exemplo: DDD + Número => 11988776655',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:13.718Z',
          _id: '5f6112fdf445330011129cbf',
          escrita_por: 'Raul Martins',
          texto: '88992320405',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:13.718Z',
          _id: '5f6112fdf445330011129cc0',
          escrita_por: 'FlexIA',
          texto:
            'O email "raulmartins@betanialacteos.com.br" e o telefone "88992320405" estão corretos?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:23.970Z',
          _id: '5f611307f445330011129cd3',
          escrita_por: 'Raul Martins',
          texto: 'sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:23.970Z',
          _id: '5f611307f445330011129cd4',
          escrita_por: 'FlexIA',
          texto: 'Ok',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:27.890Z',
          _id: '5f61130bf445330011129cf6',
          escrita_por: 'FlexIA',
          texto:
            'Sou nova por aqui, mas já consigo te ajudar com alguns assuntos que já aprendi!',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:27.890Z',
          _id: '5f61130bf445330011129cf7',
          escrita_por: 'FlexIA',
          title: 'Você pode escolher uma das opções:',
          description: '',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:27.890Z',
          _id: '5f61130bf445330011129cf8',
          escrita_por: 'FlexIA',
          options: '1 - Atendimento Comercial',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:27.891Z',
          _id: '5f61130bf445330011129cf9',
          escrita_por: 'FlexIA',
          options: '2 - Atendimento Suporte',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:27.891Z',
          _id: '5f61130bf445330011129cfa',
          escrita_por: 'FlexIA',
          options: '3 - Ambiente de Consultor',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:47.149Z',
          _id: '5f61131ff445330011129d14',
          escrita_por: 'Raul Martins',
          texto: '1',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:47.149Z',
          _id: '5f61131ff445330011129d15',
          escrita_por: 'FlexIA',
          texto:
            'Nosso propósito é otimizar o tempo nas relações de comunicação entre pessoas e empresas, afinal ele, o tempo, é o único bem que não podemos recuperar.  ',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:47.150Z',
          _id: '5f61131ff445330011129d16',
          escrita_por: 'FlexIA',
          title: 'Para isso desenvolvemos as seguintes ferramentas:',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:47.150Z',
          _id: '5f61131ff445330011129d17',
          escrita_por: 'FlexIA',
          options: ' 1 - FlexUC',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:47.150Z',
          _id: '5f61131ff445330011129d18',
          escrita_por: 'FlexIA',
          options: '2 - FlexIA',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:47.150Z',
          _id: '5f61131ff445330011129d19',
          escrita_por: 'FlexIA',
          options: '3 - Salesforce',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T19:16:47.150Z',
          _id: '5f61131ff445330011129d1a',
          escrita_por: 'FlexIA',
          options: '4 - Integrações',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T19:17:51.730Z',
          _id: '5f61135ff445330011129d3b',
          escrita_por: 'Raul Martins',
          texto: '1',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:17:51.731Z',
          _id: '5f61135ff445330011129d3c',
          escrita_por: 'FlexIA',
          texto:
            'O FlexUC  é uma plataforma completa para gerenciamento de Call Centers com monitoramento de Filas, Ramais, Troncos, E1 e muito mais.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:17:51.731Z',
          _id: '5f61135ff445330011129d3d',
          escrita_por: 'FlexIA',
          texto: 'Deseja saber mais sobre outro produto?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:18:49.182Z',
          _id: '5f611399f445330011129d61',
          escrita_por: 'Raul Martins',
          texto: 'não',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:18:49.183Z',
          _id: '5f611399f445330011129d62',
          escrita_por: 'FlexIA',
          texto: 'Deseja ser transferido para atendimento?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:18:55.155Z',
          _id: '5f61139ff445330011129d88',
          escrita_por: 'Raul Martins',
          texto: 'sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:18:55.156Z',
          _id: '5f61139ff445330011129d89',
          escrita_por: 'FlexIA',
          title: 'Tipos de Atendimento',
          description: 'Selecione como você deseja seu atendimento.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:18:55.161Z',
          _id: '5f61139ff445330011129d8a',
          escrita_por: 'FlexIA',
          options: 'Por Chat',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T19:18:55.161Z',
          _id: '5f61139ff445330011129d8b',
          escrita_por: 'FlexIA',
          options: 'Por Ligação',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T19:19:07.200Z',
          _id: '5f6113abf445330011129db5',
          escrita_por: 'Raul Martins',
          texto: 'por ligação',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:19:07.200Z',
          _id: '5f6113abf445330011129db6',
          escrita_por: 'FlexIA',
          texto:
            'Certo! Vou retornar para você utilizando esse número 88992320405, tudo bem?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:19:15.802Z',
          _id: '5f6113b3f445330011129de2',
          escrita_por: 'Raul Martins',
          texto: 'sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:19:15.802Z',
          _id: '5f6113b3f445330011129de3',
          escrita_por: 'FlexIA',
          texto:
            'Aguarde ai! Nesse momento estou conectando um de nossos analistas a você.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T19:19:25.956Z',
          _id: '5f6113bdf445330011129e15',
          escrita_por: 'Raul Martins',
          texto: 'ta certo',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        }
      ],
      resumoBot: [
        {
          id: 'Assunto',
          value: 'Comercial'
        },
        {
          id: 'Descrição',
          value: 'Está interessados nos produtos FlexUC'
        }
      ],
      atendente: {
        _id: '5ed78f167fcaf00011f09b60',
        name: 'Leopoldo'
      },
      hora_do_atendimento: '2020-09-15T21:54:53.265Z',
      id_socket_atendente: 'Pf_BmFnjhGOnFKmLAAAs',
      assunto: '',
      status: '',
      produto: '',
      setor: '',
      observacao: 'Conversa sem interação a mais de 600000ms',
      hora_fim_conversa: '2020-09-15T21:55:39.152Z',
      timeline: [
        {
          horaAtividade: '2020-09-15T19:15:15.448Z',
          atividade: 'em_atendimento',
          descricao:
            'Raul Martins entrou em atendimento com FlexIA na fila Suporte'
        },
        {
          horaAtividade: '2020-09-15T19:19:15.832Z',
          atividade: 'transferida',
          descricao:
            'Cliente transferido para fila Comercial pelo atendente FlexIA'
        },
        {
          horaAtividade: '2020-09-15T21:55:39.151Z',
          atividade: 'encerrada',
          descricao: 'Conversa encerrada pelo atendente Leopoldo'
        }
      ],
      __v: 0
    },
    {
      nova_mensagem: false,
      encerrada: true,
      atendida: true,
      atendimentoBot: false,
      sucessoAtendimento: false,
      situacao: 'abandonada',
      meioTransferencia: 'chat',
      encerrada_por: 'OCIOSIDADE',
      fechou_janela: false,
      novas_mensagens: 0,
      _id: '5f613d0bf44533001112a178',
      idSessao: '421037b5-f3ac-4f15-ac63-d03614e7b9df',
      idSF: '00Q3k00001RSvicEAD',
      tipoCadastroSF: 'lead',
      fila: 'Comercial',
      cliente: {
        _id: '5f613906f44533001112a036',
        nome: 'Leopoldo',
        email: 'sucesso@gmail.com',
        celular: '558596685222',
        id_telegram: '',
        id_facebook: ''
      },
      canal: 'whatsapp',
      hora_criacao: '2020-09-15T21:51:36.896Z',
      mensagens: [
        {
          hora_da_mensagem: '2020-09-15T21:51:36.910Z',
          _id: '5f613768f445330011129e43',
          escrita_por: 'FlexIA',
          texto:
            'Oi Leopoldo! Me chamo FlexIA, sou a assistente virtual da G4 Flex. Antes de continuar seu atendimento, vou precisar de algumas informações.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:51:36.911Z',
          _id: '5f613768f445330011129e44',
          escrita_por: 'FlexIA',
          texto: 'Qual seu endereço de e-mail?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:06.147Z',
          _id: '5f613786f445330011129e51',
          escrita_por: 'Leopoldo',
          texto: 'sucesso@gmail.com',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:06.147Z',
          _id: '5f613786f445330011129e52',
          escrita_por: 'FlexIA',
          texto:
            'Qual o seu número de celular? Exemplo: DDD + Número => 11988776655',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:17.557Z',
          _id: '5f613791f445330011129e61',
          escrita_por: 'Leopoldo',
          texto: '8598111-1111',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:17.558Z',
          _id: '5f613791f445330011129e62',
          escrita_por: 'FlexIA',
          texto:
            'Desculpa não consegui te entender. Digite seu número no formato indicado.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:17.558Z',
          _id: '5f613791f445330011129e63',
          escrita_por: 'FlexIA',
          texto:
            'Qual o seu número de celular? Exemplo: DDD + Número => 11988776655',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:38.418Z',
          _id: '5f6137a6f445330011129e75',
          escrita_por: 'Leopoldo',
          texto: '85981115511',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:38.418Z',
          _id: '5f6137a6f445330011129e76',
          escrita_por: 'FlexIA',
          texto:
            'O email "sucesso@gmail.com" e o telefone "85981115511" estão corretos?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:48.541Z',
          _id: '5f6137b0f445330011129e8a',
          escrita_por: 'Leopoldo',
          texto: 'sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:48.541Z',
          _id: '5f6137b0f445330011129e8b',
          escrita_por: 'FlexIA',
          texto: 'Ok',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:50.851Z',
          _id: '5f6137b2f445330011129eaf',
          escrita_por: 'FlexIA',
          texto:
            'Sou nova por aqui, mas já consigo te ajudar com alguns assuntos que já aprendi!',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:50.852Z',
          _id: '5f6137b2f445330011129eb0',
          escrita_por: 'FlexIA',
          title: 'Você pode escolher uma das opções:',
          description: '',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:50.852Z',
          _id: '5f6137b2f445330011129eb1',
          escrita_por: 'FlexIA',
          options: '1 - Atendimento Comercial',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:50.852Z',
          _id: '5f6137b2f445330011129eb2',
          escrita_por: 'FlexIA',
          options: '2 - Atendimento Suporte',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:52:50.852Z',
          _id: '5f6137b2f445330011129eb3',
          escrita_por: 'FlexIA',
          options: '3 - Ambiente de Consultor',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:00.246Z',
          _id: '5f6137bcf445330011129ece',
          escrita_por: 'Leopoldo',
          texto: '1',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:00.247Z',
          _id: '5f6137bcf445330011129ecf',
          escrita_por: 'FlexIA',
          texto:
            'Nosso propósito é otimizar o tempo nas relações de comunicação entre pessoas e empresas, afinal ele, o tempo, é o único bem que não podemos recuperar.  ',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:00.247Z',
          _id: '5f6137bcf445330011129ed0',
          escrita_por: 'FlexIA',
          title: 'Para isso desenvolvemos as seguintes ferramentas:',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:00.247Z',
          _id: '5f6137bcf445330011129ed1',
          escrita_por: 'FlexIA',
          options: ' 1 - FlexUC',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:00.247Z',
          _id: '5f6137bcf445330011129ed2',
          escrita_por: 'FlexIA',
          options: '2 - FlexIA',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:00.247Z',
          _id: '5f6137bcf445330011129ed3',
          escrita_por: 'FlexIA',
          options: '3 - Salesforce',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:00.248Z',
          _id: '5f6137bcf445330011129ed4',
          escrita_por: 'FlexIA',
          options: '4 - Integrações',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:32.591Z',
          _id: '5f6137dcf445330011129ef6',
          escrita_por: 'Leopoldo',
          texto: '1',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:32.591Z',
          _id: '5f6137dcf445330011129ef7',
          escrita_por: 'FlexIA',
          texto:
            'O FlexUC  é uma plataforma completa para gerenciamento de Call Centers com monitoramento de Filas, Ramais, Troncos, E1 e muito mais.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:32.591Z',
          _id: '5f6137dcf445330011129ef8',
          escrita_por: 'FlexIA',
          texto: 'Deseja saber mais sobre outro produto?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:44.357Z',
          _id: '5f6137e8f445330011129f1d',
          escrita_por: 'Leopoldo',
          texto: 'sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:44.358Z',
          _id: '5f6137e8f445330011129f1e',
          escrita_por: 'FlexIA',
          title: 'Qual outro produto você deseja saber mais?',
          description: '',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:44.358Z',
          _id: '5f6137e8f445330011129f1f',
          escrita_por: 'FlexIA',
          options: 'FlexUC',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:44.358Z',
          _id: '5f6137e8f445330011129f20',
          escrita_por: 'FlexIA',
          options: 'FlexIA',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:44.360Z',
          _id: '5f6137e8f445330011129f21',
          escrita_por: 'FlexIA',
          options: 'Salesforce',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:44.360Z',
          _id: '5f6137e8f445330011129f22',
          escrita_por: 'FlexIA',
          options: 'Integrações',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:56.683Z',
          _id: '5f6137f4f445330011129f4d',
          escrita_por: 'Leopoldo',
          texto: 'não',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:53:56.683Z',
          _id: '5f6137f4f445330011129f4e',
          escrita_por: 'FlexIA',
          texto:
            'Eu não entendi.  Posso te transferir para um atendimento humano?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:57:02.915Z',
          _id: '5f6138aef445330011129fc8',
          escrita_por: 'Leopoldo',
          texto: 'sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:57:02.916Z',
          _id: '5f6138aef445330011129fc9',
          escrita_por: 'FlexIA',
          title: 'Tipos de Atendimento',
          description: 'Selecione como você deseja seu atendimento.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:57:02.916Z',
          _id: '5f6138aef445330011129fca',
          escrita_por: 'FlexIA',
          options: '1 - Por Chat',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:57:02.916Z',
          _id: '5f6138aef445330011129fcb',
          escrita_por: 'FlexIA',
          options: '2 - Por Ligação',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:58:30.420Z',
          _id: '5f613906f445330011129ffe',
          escrita_por: 'Leopoldo',
          texto: '1',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:58:30.420Z',
          _id: '5f613906f445330011129fff',
          escrita_por: 'FlexIA',
          texto: 'Você está sendo transferido.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:58:30.421Z',
          _id: '5f613906f44533001112a000',
          escrita_por: 'FlexIA',
          title: 'Qual outro produto você deseja saber mais?',
          description: '',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:58:30.421Z',
          _id: '5f613906f44533001112a001',
          escrita_por: 'FlexIA',
          options: 'FlexUC',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:58:30.421Z',
          _id: '5f613906f44533001112a002',
          escrita_por: 'FlexIA',
          options: 'FlexIA',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:58:30.421Z',
          _id: '5f613906f44533001112a003',
          escrita_por: 'FlexIA',
          options: 'Salesforce',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:58:30.421Z',
          _id: '5f613906f44533001112a004',
          escrita_por: 'FlexIA',
          options: 'Integrações',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T21:58:41.065Z',
          _id: '5f613911f44533001112a040',
          escrita_por: 'Leopoldo',
          texto: 'nenhum',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:59:19.290Z',
          _id: '5f613937f44533001112a086',
          escrita_por: 'Leopoldo',
          texto: 'Olá, meu nome é Leopoldo',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:59:19.682Z',
          _id: '5f613937f44533001112a0bc',
          escrita_por: 'Leopoldo',
          texto: 'Olá, seja bem vindo ao nosso atendimento.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T21:59:41.281Z',
          _id: '5f61394df44533001112a0fd',
          escrita_por: 'Leopoldo',
          texto:
            'Olá, seja bem vindo a nossa plataforma, em que posso lhe ajudar hoje?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:00:00.937Z',
          _id: '5f613960f44533001112a137',
          escrita_por: 'Leopoldo',
          texto: 'Testando',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        }
      ],
      resumoBot: [
        {
          id: 'cpf',
          value: null
        },
        {
          id: 'nome',
          value: null
        },
        {
          id: 'email',
          value: 'sucesso@gmail.com'
        },
        {
          id: 'empresa',
          value: null
        },
        {
          id: 'telefone',
          value: '85981115511'
        }
      ],
      atendente: {
        _id: '5ed78f167fcaf00011f09b60',
        name: 'Leopoldo'
      },
      hora_do_atendimento: '2020-09-15T21:59:19.270Z',
      id_socket_atendente: 'Pf_BmFnjhGOnFKmLAAAs',
      assunto: '',
      status: '',
      produto: '',
      setor: '',
      observacao: 'Conversa sem interação a mais de 600000ms',
      hora_fim_conversa: '2020-09-15T22:15:39.150Z',
      timeline: [
        {
          horaAtividade: '2020-09-15T21:51:36.896Z',
          atividade: 'em_atendimento',
          descricao: 'Leopoldo entrou em atendimento com FlexIA na fila Suporte'
        },
        {
          horaAtividade: '2020-09-15T21:58:30.474Z',
          atividade: 'transferida',
          descricao:
            'Cliente transferido para fila Comercial pelo atendente FlexIA'
        },
        {
          horaAtividade: '2020-09-15T21:59:19.269Z',
          atividade: 'atender',
          descricao: 'Leopoldo atendeu a conversa'
        },
        {
          horaAtividade: '2020-09-15T22:15:39.150Z',
          atividade: 'encerrada',
          descricao: 'Conversa encerrada pelo atendente Leopoldo'
        }
      ],
      __v: 0
    },
    {
      nova_mensagem: false,
      encerrada: true,
      atendida: true,
      atendimentoBot: false,
      sucessoAtendimento: false,
      situacao: 'encerrada',
      meioTransferencia: 'chat',
      encerrada_por: 'ATENDENTE',
      fechou_janela: false,
      novas_mensagens: 0,
      _id: '5f613e1ff44533001112a410',
      idSessao: '6203e4c9-12cb-4e37-9181-4786137e0761',
      idSF: '00Q3k00001RSvmeEAD',
      tipoCadastroSF: 'lead',
      fila: 'Comercial',
      cliente: {
        _id: '5f613de9f44533001112a2ec',
        nome: 'G4flex Business and Servi',
        email: 'xpto@gmail.com',
        celular: '558530331786',
        id_telegram: '',
        id_facebook: ''
      },
      canal: 'whatsapp',
      hora_criacao: '2020-09-15T22:17:57.421Z',
      mensagens: [
        {
          hora_da_mensagem: '2020-09-15T22:17:57.428Z',
          _id: '5f613d95f44533001112a1b4',
          escrita_por: 'FlexIA',
          texto:
            'Oi G4flex Business and Servi! Me chamo FlexIA, sou a assistente virtual da G4 Flex. Antes de continuar seu atendimento, vou precisar de algumas informações.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:17:57.429Z',
          _id: '5f613d95f44533001112a1b5',
          escrita_por: 'FlexIA',
          texto: 'Qual seu endereço de e-mail?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:17.850Z',
          _id: '5f613da9f44533001112a1c2',
          escrita_por: 'G4flex Business and Servi',
          texto: 'xpto@gmail.com',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:17.850Z',
          _id: '5f613da9f44533001112a1c3',
          escrita_por: 'FlexIA',
          texto:
            'Qual o seu número de celular? Exemplo: DDD + Número => 11988776655',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:27.991Z',
          _id: '5f613db3f44533001112a1d2',
          escrita_por: 'G4flex Business and Servi',
          texto: '1132233322',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:27.992Z',
          _id: '5f613db3f44533001112a1d3',
          escrita_por: 'FlexIA',
          texto:
            'O email "xpto@gmail.com" e o telefone "1132233322" estão corretos?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:32.883Z',
          _id: '5f613db8f44533001112a1e4',
          escrita_por: 'G4flex Business and Servi',
          texto: 'Sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:32.883Z',
          _id: '5f613db8f44533001112a1e5',
          escrita_por: 'FlexIA',
          texto: 'Ok',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:35.103Z',
          _id: '5f613dbbf44533001112a203',
          escrita_por: 'FlexIA',
          texto:
            'Sou nova por aqui, mas já consigo te ajudar com alguns assuntos que já aprendi!',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:35.106Z',
          _id: '5f613dbbf44533001112a204',
          escrita_por: 'FlexIA',
          title: 'Você pode escolher uma das opções:',
          description: '',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:35.106Z',
          _id: '5f613dbbf44533001112a205',
          escrita_por: 'FlexIA',
          options: '1 - Atendimento Comercial',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:35.107Z',
          _id: '5f613dbbf44533001112a206',
          escrita_por: 'FlexIA',
          options: '2 - Atendimento Suporte',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:35.107Z',
          _id: '5f613dbbf44533001112a207',
          escrita_por: 'FlexIA',
          options: '3 - Ambiente de Consultor',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:41.419Z',
          _id: '5f613dc1f44533001112a21f',
          escrita_por: 'G4flex Business and Servi',
          texto: '1',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:41.419Z',
          _id: '5f613dc1f44533001112a220',
          escrita_por: 'FlexIA',
          texto:
            'Nosso propósito é otimizar o tempo nas relações de comunicação entre pessoas e empresas, afinal ele, o tempo, é o único bem que não podemos recuperar.  ',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:41.420Z',
          _id: '5f613dc1f44533001112a221',
          escrita_por: 'FlexIA',
          title: 'Para isso desenvolvemos as seguintes ferramentas:',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:41.420Z',
          _id: '5f613dc1f44533001112a222',
          escrita_por: 'FlexIA',
          options: ' 1 - FlexUC',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:41.420Z',
          _id: '5f613dc1f44533001112a223',
          escrita_por: 'FlexIA',
          options: '2 - FlexIA',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:41.420Z',
          _id: '5f613dc1f44533001112a224',
          escrita_por: 'FlexIA',
          options: '3 - Salesforce',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:41.421Z',
          _id: '5f613dc1f44533001112a225',
          escrita_por: 'FlexIA',
          options: '4 - Integrações',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:48.765Z',
          _id: '5f613dc8f44533001112a244',
          escrita_por: 'G4flex Business and Servi',
          texto: '4',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:48.765Z',
          _id: '5f613dc8f44533001112a245',
          escrita_por: 'FlexIA',
          texto:
            'Em um mundo conectado os sistemas precisam se comunicar compartilhando dados para acelerar os processos empresariais. ',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:48.765Z',
          _id: '5f613dc8f44533001112a246',
          escrita_por: 'FlexIA',
          texto:
            'Nesse cenário a G4flex possui inúmeros casos de sucesso em integrações com nossas plataformas (FlexUC, Flex Inquiry, Flex SMS...), CRMs(Salesforce, Dynamics, Nectar...), com ERPs e sistemas legados de clientes.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:18:48.765Z',
          _id: '5f613dc8f44533001112a247',
          escrita_por: 'FlexIA',
          texto: 'Deseja saber mais sobre outro produto?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:02.755Z',
          _id: '5f613dd6f44533001112a26a',
          escrita_por: 'G4flex Business and Servi',
          texto: 'Nao',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:02.755Z',
          _id: '5f613dd6f44533001112a26b',
          escrita_por: 'FlexIA',
          texto: 'Deseja ser transferido para atendimento?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:13.445Z',
          _id: '5f613de1f44533001112a29a',
          escrita_por: 'G4flex Business and Servi',
          texto: 'Sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:13.445Z',
          _id: '5f613de1f44533001112a29b',
          escrita_por: 'FlexIA',
          title: 'Tipos de Atendimento',
          description: 'Selecione como você deseja seu atendimento.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:13.446Z',
          _id: '5f613de1f44533001112a29c',
          escrita_por: 'FlexIA',
          options: 'Por Chat',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:13.446Z',
          _id: '5f613de1f44533001112a29d',
          escrita_por: 'FlexIA',
          options: 'Por Ligação',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:21.533Z',
          _id: '5f613de9f44533001112a2c6',
          escrita_por: 'G4flex Business and Servi',
          texto: 'Chat',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:21.533Z',
          _id: '5f613de9f44533001112a2c7',
          escrita_por: 'FlexIA',
          texto: 'Você está sendo transferido.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:28.311Z',
          _id: '5f613df0f44533001112a2fc',
          escrita_por: 'Leopoldo',
          texto: 'Olá, meu nome é Leopoldo',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:28.360Z',
          _id: '5f613df0f44533001112a324',
          escrita_por: 'Leopoldo',
          texto: 'Olá, meu nome é Leopoldo',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:28.717Z',
          _id: '5f613df0f44533001112a375',
          escrita_por: 'Leopoldo',
          texto: 'Olá, seja bem vindo ao nosso atendimento.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:19:42.567Z',
          _id: '5f613dfef44533001112a3b5',
          escrita_por: 'Leopoldo',
          texto:
            'Infelizmente neste momento não será possível aplicar o desconto solicitado.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-15T22:20:14.869Z',
          _id: '5f613e1ef44533001112a3e2',
          escrita_por: 'Leopoldo',
          texto: 'Foi um prazer te atender hoje!!! Estou me desconectando.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        }
      ],
      resumoBot: [
        {
          id: 'Assunto',
          value: 'Comercial'
        },
        {
          id: 'Descricao',
          value: 'Está interessados nos produtos Integrações'
        }
      ],
      atendente: {
        _id: '5ed78f167fcaf00011f09b60',
        name: 'Leopoldo'
      },
      hora_do_atendimento: '2020-09-15T22:19:28.285Z',
      id_socket_atendente: 'Pf_BmFnjhGOnFKmLAAAs',
      assunto: 'Atendimento Desenvolvimento',
      status: 'Pendência da operadora',
      produto: 'Salesforce',
      setor: 'Desenvolvimento',
      observacao: '',
      hora_fim_conversa: '2020-09-15T22:20:15.269Z',
      timeline: [
        {
          horaAtividade: '2020-09-15T22:17:57.421Z',
          atividade: 'em_atendimento',
          descricao:
            'G4flex Business and Servi entrou em atendimento com FlexIA na fila Suporte'
        },
        {
          horaAtividade: '2020-09-15T22:19:21.565Z',
          atividade: 'transferida',
          descricao:
            'Cliente transferido para fila Comercial pelo atendente FlexIA'
        },
        {
          horaAtividade: '2020-09-15T22:19:28.285Z',
          atividade: 'atender',
          descricao: 'Leopoldo atendeu a conversa'
        },
        {
          horaAtividade: '2020-09-15T22:20:15.268Z',
          atividade: 'encerrada',
          descricao: 'Conversa encerrada pelo atendente Leopoldo'
        }
      ],
      __v: 0
    },
    {
      nova_mensagem: false,
      encerrada: true,
      atendida: true,
      atendimentoBot: false,
      sucessoAtendimento: false,
      situacao: 'encerrada',
      meioTransferencia: 'chat',
      encerrada_por: 'ATENDENTE',
      fechou_janela: false,
      novas_mensagens: 0,
      _id: '5f620aeff44533001112a5ab',
      id_socket_cliente: 'SuT7BaclRvnhf-6uAAA1',
      fila: 'Desenvolvimento',
      cliente: {
        _id: '5efde566a209560011b6e3eb',
        nome: 'Felipe',
        email: 'felipe@felipe.com',
        celular: '85981516447',
        cpf: '',
        id_telegram: '',
        id_facebook: ''
      },
      canal: 'chat',
      hora_criacao: '2020-09-16T12:53:03.907Z',
      mensagens: [
        {
          hora_da_mensagem: '2020-09-16T12:53:44.101Z',
          _id: '5f620ad8f44533001112a476',
          escrita_por: 'ADMIN',
          texto: 'Olá, meu nome é ADMIN',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:44.119Z',
          _id: '5f620ad8f44533001112a47e',
          escrita_por: 'ADMIN',
          texto: 'Olá, seja bem vindo ao nosso atendimento.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:47.261Z',
          _id: '5f620adbf44533001112a491',
          texto: 'qwer',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:49.008Z',
          _id: '5f620addf44533001112a49b',
          texto: 'asdf',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:50.628Z',
          _id: '5f620adef44533001112a4a6',
          texto: 'asdfzxcv',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:53.992Z',
          _id: '5f620ae1f44533001112a4c0',
          escrita_por: 'ADMIN',
          texto: 'qwer\na',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:54.355Z',
          _id: '5f620ae2f44533001112a4d7',
          escrita_por: 'ADMIN',
          texto: 'sdf\nz',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:54.847Z',
          _id: '5f620ae2f44533001112a4ef',
          escrita_por: 'ADMIN',
          texto: 'xcv\n',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:55.274Z',
          _id: '5f620ae3f44533001112a504',
          escrita_por: 'ADMIN',
          texto: '1\n',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:55.561Z',
          _id: '5f620ae3f44533001112a51a',
          escrita_por: 'ADMIN',
          texto: '2\n',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:55.811Z',
          _id: '5f620ae3f44533001112a531',
          escrita_por: 'ADMIN',
          texto: '3\n',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:56.046Z',
          _id: '5f620ae4f44533001112a549',
          escrita_por: 'ADMIN',
          texto: '4\n',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:56.231Z',
          _id: '5f620ae4f44533001112a562',
          escrita_por: 'ADMIN',
          texto: '5\n',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T12:53:56.434Z',
          _id: '5f620ae4f44533001112a57c',
          escrita_por: 'ADMIN',
          texto: '6\n',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T12:54:07.352Z',
          _id: '5f620aeff44533001112a593',
          escrita_por: 'ADMIN',
          texto: 'Foi um prazer te atender hoje!!! Estou me desconectando.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        }
      ],
      resumoBot: [],
      atendente: {
        _id: '5e77d11bf89facdfcee53a95',
        name: 'ADMIN'
      },
      hora_do_atendimento: '2020-09-16T12:53:44.086Z',
      id_socket_atendente: '05QJl_4I9JBt1V5AAAA2',
      assunto: 'Atendimento Desenvolvimento',
      status: 'Aberto',
      produto: 'FlexIA',
      setor: 'Comercial',
      observacao: '',
      hora_fim_conversa: '2020-09-16T12:54:07.383Z',
      timeline: [
        {
          horaAtividade: '2020-09-16T12:53:03.906Z',
          atividade: 'nao_atendida',
          descricao: 'Felipe entrou na fila Desenvolvimento'
        },
        {
          horaAtividade: '2020-09-16T12:53:44.086Z',
          atividade: 'atender',
          descricao: 'ADMIN atendeu a conversa'
        },
        {
          horaAtividade: '2020-09-16T12:54:07.383Z',
          atividade: 'encerrada',
          descricao: 'Conversa encerrada pelo atendente ADMIN'
        }
      ],
      __v: 0,
      satisfacao_do_cliente: 5
    },
    {
      nova_mensagem: false,
      encerrada: true,
      atendida: true,
      atendimentoBot: true,
      sucessoAtendimento: false,
      situacao: 'encerrada',
      meioTransferencia: 'chat',
      encerrada_por: 'BOT',
      fechou_janela: false,
      novas_mensagens: 0,
      _id: '5f62128af44533001112a6df',
      idSessao: '5f069f2e-e1c1-4ddf-ac37-1e9d70e68e00',
      idSF: '0033k00003FxZ6bAAF',
      tipoCadastroSF: 'contato',
      fila: 'Suporte',
      cliente: {
        _id: '5f621246f44533001112a5c1',
        nome: 'Geneflides',
        celular: '558596685252'
      },
      canal: 'whatsapp',
      hora_criacao: '2020-09-16T13:25:26.619Z',
      mensagens: [
        {
          hora_da_mensagem: '2020-09-16T13:25:26.627Z',
          _id: '5f621246f44533001112a5c2',
          escrita_por: 'FlexIA',
          texto:
            'Oi Geneflides! Me chamo FlexIA, sou a assistente virtual da G4 Flex. Antes de continuar seu atendimento, vou precisar de algumas informações.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:25:26.627Z',
          _id: '5f621246f44533001112a5c3',
          escrita_por: 'FlexIA',
          texto: 'Qual seu endereço de e-mail?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:25:44.493Z',
          _id: '5f621258f44533001112a5d2',
          escrita_por: 'Geneflides',
          texto: 'greeneflides@gmail.com',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:25:44.493Z',
          _id: '5f621258f44533001112a5d3',
          escrita_por: 'FlexIA',
          texto:
            'Qual o seu número de celular? Exemplo: DDD + Número => 11988776655',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:25:53.731Z',
          _id: '5f621261f44533001112a5e2',
          escrita_por: 'Geneflides',
          texto: '85996685252',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:25:53.731Z',
          _id: '5f621261f44533001112a5e3',
          escrita_por: 'FlexIA',
          texto:
            'O email "greeneflides@gmail.com" e o telefone "85996685252" estão corretos?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:25:59.782Z',
          _id: '5f621267f44533001112a5f4',
          escrita_por: 'Geneflides',
          texto: 'Sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:25:59.783Z',
          _id: '5f621267f44533001112a5f5',
          escrita_por: 'FlexIA',
          texto: 'Ok',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:02.613Z',
          _id: '5f62126af44533001112a611',
          escrita_por: 'FlexIA',
          texto:
            'Sou nova por aqui, mas já consigo te ajudar com alguns assuntos que já aprendi!',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:02.613Z',
          _id: '5f62126af44533001112a612',
          escrita_por: 'FlexIA',
          title: 'Você pode escolher uma das opções:',
          description: '',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:02.613Z',
          _id: '5f62126af44533001112a613',
          escrita_por: 'FlexIA',
          options: '1 - Atendimento Comercial',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:02.613Z',
          _id: '5f62126af44533001112a614',
          escrita_por: 'FlexIA',
          options: '2 - Atendimento Suporte',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:02.614Z',
          _id: '5f62126af44533001112a615',
          escrita_por: 'FlexIA',
          options: '3 - Ambiente de Consultor',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:08.490Z',
          _id: '5f621270f44533001112a62d',
          escrita_por: 'Geneflides',
          texto: '1',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:08.492Z',
          _id: '5f621270f44533001112a62e',
          escrita_por: 'FlexIA',
          texto:
            'Nosso propósito é otimizar o tempo nas relações de comunicação entre pessoas e empresas, afinal ele, o tempo, é o único bem que não podemos recuperar.  ',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:08.492Z',
          _id: '5f621270f44533001112a62f',
          escrita_por: 'FlexIA',
          title: 'Para isso desenvolvemos as seguintes ferramentas:',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:08.493Z',
          _id: '5f621270f44533001112a630',
          escrita_por: 'FlexIA',
          options: ' 1 - FlexUC',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:08.493Z',
          _id: '5f621270f44533001112a631',
          escrita_por: 'FlexIA',
          options: '2 - FlexIA',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:08.493Z',
          _id: '5f621270f44533001112a632',
          escrita_por: 'FlexIA',
          options: '3 - Salesforce',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:08.494Z',
          _id: '5f621270f44533001112a633',
          escrita_por: 'FlexIA',
          options: '4 - Integrações',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:16.699Z',
          _id: '5f621278f44533001112a652',
          escrita_por: 'Geneflides',
          texto: '1',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:16.699Z',
          _id: '5f621278f44533001112a653',
          escrita_por: 'FlexIA',
          texto:
            'O FlexUC  é uma plataforma completa para gerenciamento de Call Centers com monitoramento de Filas, Ramais, Troncos, E1 e muito mais.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:16.700Z',
          _id: '5f621278f44533001112a654',
          escrita_por: 'FlexIA',
          texto: 'Deseja saber mais sobre outro produto?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:24.545Z',
          _id: '5f621280f44533001112a676',
          escrita_por: 'Geneflides',
          texto: 'Nao',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:25.496Z',
          _id: '5f621281f44533001112a693',
          escrita_por: 'FlexIA',
          texto:
            'O seu caso foi criado com sucesso, seu número de protocolo é 00002664',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:25.496Z',
          _id: '5f621281f44533001112a694',
          escrita_por: 'FlexIA',
          texto: 'Deseja ser transferido para atendimento?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:34.196Z',
          _id: '5f62128af44533001112a6b9',
          escrita_por: 'Geneflides',
          texto: 'Nao',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T13:26:34.196Z',
          _id: '5f62128af44533001112a6ba',
          escrita_por: 'FlexIA',
          texto: 'Até já, foi muito bom poder ajudá-lo(a)!',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        }
      ],
      resumoBot: [],
      atendente: {
        _id: '5e974df08d8e9e00118cffca',
        name: 'FlexIA'
      },
      hora_do_atendimento: '2020-09-16T13:25:26.618Z',
      assunto: '',
      status: '',
      produto: '',
      setor: '',
      observacao: '',
      hora_fim_conversa: '2020-09-16T13:26:34.247Z',
      timeline: [
        {
          horaAtividade: '2020-09-16T13:25:26.619Z',
          atividade: 'em_atendimento',
          descricao:
            'Geneflides entrou em atendimento com FlexIA na fila Suporte'
        },
        {
          horaAtividade: '2020-09-16T13:26:34.247Z',
          atividade: 'encerrada',
          descricao: 'Conversa encerrada pelo atendente FlexIA'
        }
      ],
      __v: 0
    },
    {
      nova_mensagem: false,
      encerrada: true,
      atendida: true,
      atendimentoBot: false,
      sucessoAtendimento: false,
      situacao: 'encerrada',
      meioTransferencia: 'chat',
      encerrada_por: 'ATENDENTE',
      fechou_janela: false,
      novas_mensagens: 0,
      _id: '5f621d7cf44533001112aa81',
      idSessao: '5603ea65-7732-4352-aa16-7bccfa772284',
      idSF: '00Q3k00001RSwiJEAT',
      tipoCadastroSF: 'lead',
      fila: 'Comercial',
      cliente: {
        _id: '5f621c36f44533001112a836',
        nome: 'Leopoldo',
        email: 'kelly@intitutovoltaavida.com.br',
        celular: '558596685222',
        id_telegram: '',
        id_facebook: ''
      },
      canal: 'whatsapp',
      hora_criacao: '2020-09-16T14:01:18.989Z',
      mensagens: [
        {
          hora_da_mensagem: '2020-09-16T14:01:18.996Z',
          _id: '5f621aaef44533001112a701',
          escrita_por: 'FlexIA',
          texto:
            'Oi Leopoldo! Me chamo FlexIA, sou a assistente virtual da G4 Flex. Antes de continuar seu atendimento, vou precisar de algumas informações.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:01:18.996Z',
          _id: '5f621aaef44533001112a702',
          escrita_por: 'FlexIA',
          texto: 'Qual seu endereço de e-mail?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:02:43.247Z',
          _id: '5f621b03f44533001112a70f',
          escrita_por: 'Leopoldo',
          texto: 'kelly@intitutovoltaavida.com.br',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:02:43.248Z',
          _id: '5f621b03f44533001112a710',
          escrita_por: 'FlexIA',
          texto:
            'Qual o seu número de celular? Exemplo: DDD + Número => 11988776655',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:01.939Z',
          _id: '5f621b15f44533001112a71f',
          escrita_por: 'Leopoldo',
          texto: '85988339685',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:01.939Z',
          _id: '5f621b15f44533001112a720',
          escrita_por: 'FlexIA',
          texto:
            'O email "kelly@intitutovoltaavida.com.br" e o telefone "85988339685" estão corretos?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:10.583Z',
          _id: '5f621b1ef44533001112a731',
          escrita_por: 'Leopoldo',
          texto: 'sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:10.584Z',
          _id: '5f621b1ef44533001112a732',
          escrita_por: 'FlexIA',
          texto: 'Ok',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:12.506Z',
          _id: '5f621b20f44533001112a750',
          escrita_por: 'FlexIA',
          texto:
            'Sou nova por aqui, mas já consigo te ajudar com alguns assuntos que já aprendi!',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:12.507Z',
          _id: '5f621b20f44533001112a751',
          escrita_por: 'FlexIA',
          title: 'Você pode escolher uma das opções:',
          description: '',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:12.507Z',
          _id: '5f621b20f44533001112a752',
          escrita_por: 'FlexIA',
          options: '1 - Atendimento Comercial',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:12.507Z',
          _id: '5f621b20f44533001112a753',
          escrita_por: 'FlexIA',
          options: '2 - Atendimento Suporte',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:12.508Z',
          _id: '5f621b20f44533001112a754',
          escrita_por: 'FlexIA',
          options: '3 - Ambiente de Consultor',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:37.642Z',
          _id: '5f621b39f44533001112a76c',
          escrita_por: 'Leopoldo',
          texto: '1',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:37.643Z',
          _id: '5f621b39f44533001112a76d',
          escrita_por: 'FlexIA',
          texto:
            'Nosso propósito é otimizar o tempo nas relações de comunicação entre pessoas e empresas, afinal ele, o tempo, é o único bem que não podemos recuperar.  ',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:37.643Z',
          _id: '5f621b39f44533001112a76e',
          escrita_por: 'FlexIA',
          title: 'Para isso desenvolvemos as seguintes ferramentas:',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:37.643Z',
          _id: '5f621b39f44533001112a76f',
          escrita_por: 'FlexIA',
          options: ' 1 - FlexUC',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:37.644Z',
          _id: '5f621b39f44533001112a770',
          escrita_por: 'FlexIA',
          options: '2 - FlexIA',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:37.644Z',
          _id: '5f621b39f44533001112a771',
          escrita_por: 'FlexIA',
          options: '3 - Salesforce',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T14:03:37.644Z',
          _id: '5f621b39f44533001112a772',
          escrita_por: 'FlexIA',
          options: '4 - Integrações',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T14:06:13.378Z',
          _id: '5f621bd5f44533001112a79d',
          escrita_por: 'Leopoldo',
          texto: '1',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:06:13.379Z',
          _id: '5f621bd5f44533001112a79e',
          escrita_por: 'FlexIA',
          texto:
            'O FlexUC  é uma plataforma completa para gerenciamento de Call Centers com monitoramento de Filas, Ramais, Troncos, E1 e muito mais.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:06:13.379Z',
          _id: '5f621bd5f44533001112a79f',
          escrita_por: 'FlexIA',
          texto: 'Deseja saber mais sobre outro produto?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:06:25.314Z',
          _id: '5f621be1f44533001112a7c1',
          escrita_por: 'Leopoldo',
          texto: 'nao',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:06:25.314Z',
          _id: '5f621be1f44533001112a7c2',
          escrita_por: 'FlexIA',
          texto: 'Deseja ser transferido para atendimento?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:06:55.707Z',
          _id: '5f621bfff44533001112a7e6',
          escrita_por: 'Leopoldo',
          texto: 'sim',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:06:55.707Z',
          _id: '5f621bfff44533001112a7e7',
          escrita_por: 'FlexIA',
          title: 'Tipos de Atendimento',
          description: 'Selecione como você deseja seu atendimento.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:06:55.708Z',
          _id: '5f621bfff44533001112a7e8',
          escrita_por: 'FlexIA',
          options: 'Por Chat',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T14:06:55.708Z',
          _id: '5f621bfff44533001112a7e9',
          escrita_por: 'FlexIA',
          options: 'Por Ligação',
          cliente_ou_atendente: 'atendente',
          response_type: 'option'
        },
        {
          hora_da_mensagem: '2020-09-16T14:07:50.298Z',
          _id: '5f621c36f44533001112a811',
          escrita_por: 'Leopoldo',
          texto: 'chat',
          cliente_ou_atendente: 'cliente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:07:50.298Z',
          _id: '5f621c36f44533001112a812',
          escrita_por: 'FlexIA',
          texto: 'Você está sendo transferido.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:08:38.572Z',
          _id: '5f621c66f44533001112a843',
          escrita_por: 'Leopoldo',
          texto: 'Olá, meu nome é Leopoldo',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:08:38.922Z',
          _id: '5f621c66f44533001112a86a',
          escrita_por: 'Leopoldo',
          texto: 'Olá, seja bem vindo ao nosso atendimento.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:11:03.744Z',
          _id: '5f621cf7f44533001112a8e5',
          escrita_por: 'Leopoldo',
          texto: 'Olá aqui é o Leo tudo bem?\n',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T14:13:16.093Z',
          _id: '5f621d7cf44533001112aa55',
          escrita_por: 'Leopoldo',
          texto: 'Foi um prazer te atender hoje!!! Estou me desconectando.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        }
      ],
      resumoBot: [
        {
          id: 'Assunto',
          value: 'Comercial'
        },
        {
          id: 'Descricao',
          value: 'Está interessados nos produtos FlexUC'
        }
      ],
      atendente: {
        _id: '5ed78f167fcaf00011f09b60',
        name: 'Leopoldo'
      },
      hora_do_atendimento: '2020-09-16T14:08:38.545Z',
      id_socket_atendente: 'vWd4A9NR31VuVZCpAAA6',
      assunto: 'Atendimento Comercial',
      status: 'Pendencia do cliente',
      produto: 'FlexUC - Aplicação',
      setor: 'Desenvolvimento',
      observacao: '',
      hora_fim_conversa: '2020-09-16T14:13:16.445Z',
      timeline: [
        {
          horaAtividade: '2020-09-16T14:01:18.989Z',
          atividade: 'em_atendimento',
          descricao: 'Leopoldo entrou em atendimento com FlexIA na fila Suporte'
        },
        {
          horaAtividade: '2020-09-16T14:07:50.332Z',
          atividade: 'transferida',
          descricao:
            'Cliente transferido para fila Comercial pelo atendente FlexIA'
        },
        {
          horaAtividade: '2020-09-16T14:08:38.545Z',
          atividade: 'atender',
          descricao: 'Leopoldo atendeu a conversa'
        },
        {
          horaAtividade: '2020-09-16T14:13:16.445Z',
          atividade: 'encerrada',
          descricao: 'Conversa encerrada pelo atendente Leopoldo'
        }
      ],
      __v: 0
    },
    {
      nova_mensagem: false,
      encerrada: true,
      atendida: false,
      atendimentoBot: true,
      sucessoAtendimento: false,
      situacao: 'abandonada',
      meioTransferencia: 'chat',
      encerrada_por: 'OCIOSIDADE',
      fechou_janela: false,
      novas_mensagens: 0,
      _id: '5f624a93f44533001112acd2',
      idSessao: 'e7368450-1e1c-46de-b56c-778f3c78e483',
      fila: 'Suporte',
      cliente: {
        _id: '5f624614f44533001112acb5',
        nome: 'Felipe Silva',
        email: '',
        celular: '',
        cpf: '',
        id_telegram: '400797223',
        id_facebook: ''
      },
      canal: 'telegram',
      hora_criacao: '2020-09-16T17:06:32.443Z',
      mensagens: [
        {
          hora_da_mensagem: '2020-09-16T17:06:32.460Z',
          _id: '5f624618f44533001112acbc',
          escrita_por: 'FlexIA',
          texto:
            'Olá Felipe Silva! Meu nome é FlexIA, sou a assistente virtual da G4 Flex. Antes de continuar seu atendimento, vou precisar de algumas informações.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T17:06:32.460Z',
          _id: '5f624618f44533001112acbd',
          escrita_por: 'FlexIA',
          texto: 'Qual seu endereço de e-mail?',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        }
      ],
      resumoBot: [],
      atendente: {
        _id: '5e974df08d8e9e00118cffca',
        name: 'FlexIA'
      },
      hora_do_atendimento: '2020-09-16T17:06:32.441Z',
      assunto: '',
      status: '',
      produto: '',
      setor: '',
      observacao: 'Conversa sem interação a mais de 600000ms',
      hora_fim_conversa: '2020-09-16T17:25:39.265Z',
      timeline: [
        {
          horaAtividade: '2020-09-16T17:06:32.443Z',
          atividade: 'em_atendimento',
          descricao:
            'Felipe Silva entrou em atendimento com FlexIA na fila Suporte'
        },
        {
          horaAtividade: '2020-09-16T17:25:39.265Z',
          atividade: 'encerrada',
          descricao: 'Conversa encerrada pelo atendente FlexIA'
        }
      ],
      __v: 0
    },
    {
      nova_mensagem: false,
      encerrada: true,
      atendida: true,
      atendimentoBot: false,
      sucessoAtendimento: false,
      situacao: 'encerrada',
      meioTransferencia: 'chat',
      encerrada_por: 'ATENDENTE',
      fechou_janela: false,
      novas_mensagens: 0,
      _id: '5f6256e4f44533001112adc9',
      id_socket_cliente: 'Pd3l0orhA39igiEkAABK',
      fila: 'Comercial',
      cliente: {
        _id: '5efde566a209560011b6e3eb',
        nome: 'Felipe',
        email: 'felipe@felipe.com',
        celular: '85981516447',
        cpf: '',
        id_telegram: '',
        id_facebook: ''
      },
      canal: 'chat',
      hora_criacao: '2020-09-16T18:17:13.483Z',
      mensagens: [
        {
          hora_da_mensagem: '2020-09-16T18:17:18.480Z',
          _id: '5f6256aef44533001112ad0b',
          escrita_por: 'ADMIN',
          texto: 'Olá, meu nome é ADMIN',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T18:17:18.490Z',
          _id: '5f6256aef44533001112ad13',
          escrita_por: 'ADMIN',
          texto: 'Olá, seja bem vindo ao nosso atendimento.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        },
        {
          hora_da_mensagem: '2020-09-16T18:17:21.024Z',
          _id: '5f6256b1f44533001112ad36',
          texto: 'qwer',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T18:17:21.592Z',
          _id: '5f6256b1f44533001112ad40',
          texto: 'asdf',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T18:17:22.083Z',
          _id: '5f6256b2f44533001112ad4b',
          texto: 'zxcv',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T18:17:22.653Z',
          _id: '5f6256b2f44533001112ad57',
          texto: 'qwer',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T18:17:23.312Z',
          _id: '5f6256b3f44533001112ad64',
          texto: 'asdf',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T18:17:25.180Z',
          _id: '5f6256b5f44533001112ad72',
          texto: 'asdf',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T18:17:25.814Z',
          _id: '5f6256b5f44533001112ad81',
          texto: 'zxcv',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T18:17:26.366Z',
          _id: '5f6256b6f44533001112ad91',
          texto: 'zxcv',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T18:17:26.910Z',
          _id: '5f6256b6f44533001112ada2',
          texto: 'zxcv',
          response_type: 'text',
          cliente_ou_atendente: 'cliente',
          escrita_por: 'Felipe'
        },
        {
          hora_da_mensagem: '2020-09-16T18:18:12.675Z',
          _id: '5f6256e4f44533001112adb4',
          escrita_por: 'ADMIN',
          texto: 'Foi um prazer te atender hoje!!! Estou me desconectando.',
          cliente_ou_atendente: 'atendente',
          response_type: 'text'
        }
      ],
      resumoBot: [],
      atendente: {
        _id: '5e77d11bf89facdfcee53a95',
        name: 'ADMIN'
      },
      hora_do_atendimento: '2020-09-16T18:17:18.459Z',
      id_socket_atendente: 'xwraR6tNoR8Ouq4OAABL',
      assunto: 'Atendimento Comercial',
      status: 'Aberto',
      produto: 'FlexIA',
      setor: 'Comercial',
      observacao: '',
      hora_fim_conversa: '2020-09-16T18:18:12.706Z',
      timeline: [
        {
          horaAtividade: '2020-09-16T18:17:13.482Z',
          atividade: 'nao_atendida',
          descricao: 'Felipe entrou na fila Comercial'
        },
        {
          horaAtividade: '2020-09-16T18:17:18.459Z',
          atividade: 'atender',
          descricao: 'ADMIN atendeu a conversa'
        },
        {
          horaAtividade: '2020-09-16T18:18:12.706Z',
          atividade: 'encerrada',
          descricao: 'Conversa encerrada pelo atendente ADMIN'
        }
      ],
      __v: 0,
      satisfacao_do_cliente: 3
    }
  ],
  quantidadeDeConversas: 26
};

//03/05/2020 - 10/12/2020

export const exempleSyntheticUser = {
  conversasFinalizadas: [
    {
      _id: 'Geneflides',
      count: 99
    },
    {
      _id: 'Breno Alves',
      count: 1
    },
    {
      _id: 'Mariana Cavalcante',
      count: 2
    },
    {
      _id: 'Bot',
      count: 49
    },
    {
      _id: 'Leopoldo',
      count: 6
    },
    {
      _id: 'Felipe Cerqueira',
      count: 1
    },
    {
      _id: 'Administrador G4Flex',
      count: 51
    },
    {
      _id: 'FlexIA',
      count: 95
    },
    {
      _id: 'Patrick Saldanha',
      count: 27
    },
    {
      _id: 'Carolina Costa',
      count: 2
    },
    {
      _id: 'Felipe',
      count: 79
    },
    {
      _id: 'ADMIN',
      count: 601
    },
    {
      _id: 'Mardson Oliveira',
      count: 5
    },
    {
      _id: 'Emanuelle Silvia',
      count: 8
    },
    {
      _id: 'Rodrigo Mesquita',
      count: 7
    }
  ],
  conversasAbandonadas: [
    {
      _id: 'Geneflides',
      count: 2
    },
    {
      _id: 'FlexIA',
      count: 563
    },
    {
      _id: 'Felipe Cerqueira',
      count: 1
    },
    {
      _id: 'Administrador G4Flex',
      count: 2
    },
    {
      _id: 'Rodrigo Mesquita',
      count: 4
    },
    {
      _id: 'ADMIN',
      count: 63
    },
    {
      _id: 'Leopoldo',
      count: 2
    },
    {
      _id: 'Emanuelle Silvia',
      count: 1
    },
    {
      _id: 'Bot',
      count: 18
    },
    {
      _id: 'Felipe',
      count: 6
    },
    {
      _id: 'Patrick Saldanha',
      count: 6
    }
  ],
  conversasNaoAtendidas: [
    {
      _id: 'FlexIA',
      count: 1
    }
  ]
};

export const exempleSyntheticChannel = {
  conversasFinalizadas: [
    {
      _id: 'ChatFlexIA',
      count: 138
    },
    {
      _id: 'chat',
      count: 663
    },
    {
      _id: 'telegram',
      count: 88
    },
    {
      _id: 'whatsapp',
      count: 133
    }
  ],
  conversasAbandonadas: [
    {
      _id: 'ChatFlexIA',
      count: 97
    },
    {
      _id: 'telegram',
      count: 16
    },
    {
      _id: 'whatsapp',
      count: 87
    },
    {
      _id: 'chat',
      count: 56
    }
  ],
  conversasNaoAtendidas: [
    {
      _id: 'telegram',
      count: 1
    }
  ]
};

export const exempleSyntheticQueue = {
  conversasFinalizadas: [
    {
      _id: 'Patrick Ad-oc',
      count: 3
    },
    {
      _id: 'Atendimento Any',
      count: 25
    },
    {
      _id: 'Suporte',
      count: 285
    },
    {
      _id: 'Whatsapp',
      count: 3
    },
    {
      _id: 'Comercial',
      count: 644
    },
    {
      _id: 'Desenvolvimento',
      count: 81
    },
    {
      _id: 'queue',
      count: 1
    },
    {
      _id: 'Admin G4Flex',
      count: 6
    }
  ],
  conversasAbandonadas: [
    {
      _id: 'Admin G4Flex',
      count: 1
    },
    {
      _id: 'Atendimento Any',
      count: 1
    },
    {
      _id: 'Suporte',
      count: 603
    },
    {
      _id: 'Comercial',
      count: 59
    },
    {
      _id: 'Desenvolvimento',
      count: 5
    }
  ],
  conversasNaoAtendidas: [
    {
      _id: 'Comercial',
      count: 1
    }
  ]
};

export const exempleSyntheticFilter = {
  conversasFiltradas: [
    {
      _id: 'Whatsapp',
      count: 3
    },
    {
      _id: 'Comercial',
      count: 704
    },
    {
      _id: 'Desenvolvimento',
      count: 86
    },
    {
      _id: 'queue',
      count: 1
    },
    {
      _id: 'Admin G4Flex',
      count: 7
    },
    {
      _id: 'Patrick Ad-oc',
      count: 3
    },
    {
      _id: 'Atendimento Any',
      count: 26
    },
    {
      _id: 'Suporte',
      count: 888
    }
  ]
};
