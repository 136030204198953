import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import NewService from '../../service/newService';
import { Col, Row, Button, Table } from 'reactstrap';
import { Body, Cantainer, Head, TagFilas } from './style';
import Modal from '../../../../components/Modal/DefaultModal';
import { ModalUpdate } from './Modal/ModalUpdate';
import { ModalCreate } from './Modal/ModalCreate';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

const Timeout = () => {
  const [controll, setControll] = useState();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [timeoutConfigs, setTimeoutConfigs] = useState({});
  const [modalUpdateExist, setModalUpdateExist] = useState(false);
  const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const timeoutPadrao = {
    typeOfAttendant: 'Atendente',
    typeTimeout: 'Ociosidade',
    timeoutMsgEncerramento: 1,
    timeout: 1,
    timeoutMsgLembrete: 1,
    active: false,
    action: 'encerrar',
    inTheQueues: [],
    transferQueue: '',
    ignoreWeekend: true,
    closingMessage: '',
    reminderMessage: '',
    channels: []
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    NewService.getTimeOut().then((response) => {
      setTimeoutConfigs(response.data.result);
    });
  }, []);

  function controlToggleActive(id) {
    const filteredTimeout = timeoutConfigs.filter(
      (timeout) => timeout.id === id
    )[0];

    setTimeoutConfigs(
      timeoutConfigs.map((timeout) => {
        if (timeout.id === id) {
          return {
            ...timeout,
            active: !timeout?.active
          };
        }
        return timeout;
      })
    );

    NewService.changeStatusTimeout(filteredTimeout.id)
      .then((response) => {
        if (response.data) {
          toast.success(
            filteredTimeout.active
              ? 'Configuração de inatividade Ativado'
              : 'Configuração de inatividade Desativado',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error(error.response.data || 'Ops, ocorreu um erro', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  }

  function handleModalCreate() {
    setIsModalCreateOpen(true);
    setIsModalOpen(true);
  }

  function modifyStateModal(boll) {
    setIsModalOpen(boll);
  }
  function handleGetTimeout(id) {
    setModalUpdateExist(timeoutConfigs[id]);
    setIsModalCreateOpen(false);
    setControll(id);
    setIsModalOpen(true);
  }

  function handleDeleteTimeout(id) {
    const filteredTimeout = timeoutConfigs.filter(
      (timeout, index) => index !== id
    );

    NewService.deleteTimeout(filteredTimeout[0].id)
      .then((response) => {
        if (response.data) {
          toast.success('Configuração excluída com sucesso', {
            autoClose: 3000,
            closeOnClick: true
          });
          setTimeoutConfigs(
            timeoutConfigs.filter((timeout, index) => index !== id)
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Ops, ocorreu um erro!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  }

  return (
    <>
      <Cantainer color={systemConfiguration.primary_color}>
        <Row className="mb-5 mt-2">
          <Col md="12">
            <Button
              name="create"
              color="primary"
              size="sm"
              title={'Adicionar timeout'}
              onClick={() => {
                handleModalCreate();
                toggleModal();
              }}
            >
              <li className="fas fa-plus"></li> Adicionar timeout
            </Button>
          </Col>
        </Row>
        {timeoutConfigs ? (
          <>
            <Row>
              <Table>
                <Head>
                  <tr>
                    <th>TIPO</th>
                    <th>REGRA</th>
                    <th>AÇÃO</th>
                    <th>FILAS</th>
                    <th>CANAL</th>
                    <th>TEMPO</th>
                    <th>ATIVO?</th>
                    <th>Editar/Excluir</th>
                  </tr>
                </Head>
                <Body>
                  <Modal
                    show={isModalOpen}
                    onModalToggle={(state) => setIsModalOpen(state)}
                    title="Regra de Inatividade"
                    showCloseButton={false}
                    size="xl"
                  >
                    {isModalCreateOpen ? (
                      <ModalCreate
                        info={timeoutPadrao}
                        modifyStateModal={modifyStateModal}
                        setTimeoutConfigs={setTimeoutConfigs}
                        timeoutConfigs={timeoutConfigs}
                      />
                    ) : (
                      <ModalUpdate
                        info={modalUpdateExist}
                        modifyStateModal={modifyStateModal}
                        posicao={controll}
                        setTimeoutConfigs={setTimeoutConfigs}
                        timeoutConfigs={timeoutConfigs}
                      />
                    )}
                  </Modal>

                  {timeoutConfigs.length &&
                    timeoutConfigs.map((item, index) => {
                      return (
                        <>
                          <tr key={index}>
                            <td>
                              {item.typeTimeout === 'SucessoAtendimento'
                                ? 'Sucesso de Atendimento'
                                : 'Ociosidade'}
                            </td>
                            <td>{item.typeOfAttendant}</td>
                            <td>{item.action}</td>
                            <td>
                              {item?.inTheQueues?.map((q, index) => (
                                <TagFilas key={index}>{q}</TagFilas>
                              ))}
                            </td>
                            <td>
                              {item?.channels?.map((q, index) => (
                                <TagFilas key={index}>{q}</TagFilas>
                              ))}
                            </td>
                            <td>{item.timeout} minuto(s)</td>
                            <td>
                              <ToggleSwitch
                                checked={item.active}
                                id={index}
                                onChange={() => controlToggleActive(item?.id)}
                              />
                            </td>

                            <td>
                              <div>
                                <Button
                                  color="info"
                                  size="sm"
                                  title={'Editar timeout'}
                                  onClick={() => {
                                    handleGetTimeout(index);
                                    toggleModal();
                                  }}
                                >
                                  <li className="fas fa-edit"></li>
                                </Button>

                                <Button
                                  color="danger"
                                  size="sm"
                                  title={'Excluir timeout'}
                                  onClick={() => {
                                    window.confirm(
                                      'Deseja realmente excluir essa configuração?'
                                    ) && handleDeleteTimeout(index);
                                  }}
                                >
                                  <li className="fas fa-trash"></li>
                                </Button>
                              </div>
                            </td>
                          </tr>
                        </>
                      );
                    })}
                </Body>
              </Table>
            </Row>

            <hr
              style={{
                color: '#eee',
                backgroundColor: '#eee',
                height: 0.5,
                borderColor: '#eee'
              }}
            />
          </>
        ) : (
          'Adicionar configuração de inatividade'
        )}
      </Cantainer>
    </>
  );
};

export { Timeout };
