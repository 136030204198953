import React from 'react';
import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import DatePicker from 'components/Inputs/DatePicker';
import { useForm, Controller } from 'react-hook-form';
import { InputTypeFilter } from './InputTypeFilter';
import { FormComponent, ButtonFormContainer } from '../../../styled';

export function Form({
  selected_search_type,
  attendants_with_conversations,
  set_selected_search_type,
  onSubmit,
  field_value_already_selected_date,
  field_value_already_selected_attendent
}) {
  const { handleSubmit, setValue, control } = useForm();

  return (
    <FormComponent
      onSubmit={handleSubmit(onSubmit)}
      isMultipleFilters={
        selected_search_type !== null && selected_search_type.length === 2
          ? true
          : false
      }
    >
      <section className="historyConversation__formContainer">
        <InputTypeFilter set_selected_search_type={set_selected_search_type} />
        <div>
          {
            //validações necessarias por conta dos diferentes comportamentos
            //do componente
          }
          {(selected_search_type == null ||
            selected_search_type?.value === 'attendant' ||
            selected_search_type.length === 0 ||
            (selected_search_type.length === 1 &&
              selected_search_type[0]?.value === 'attendant')) && (
            <>
              <label className="form-control-label">Atendente</label>
              <Controller
                render={(props) => (
                  <FlexSelect
                    dataOptions={attendants_with_conversations}
                    isMulti={false}
                    isClearable={true}
                    closeMenuOnSelect={true}
                    value={props.value}
                    valueController={setValue}
                    fieldName="atendente"
                    labelName="name"
                    valueName="login"
                  />
                )}
                control={control}
                name="atendente"
                defaultValue={field_value_already_selected_attendent || ''}
              />
            </>
          )}
          {selected_search_type != null &&
            (selected_search_type?.value === 'closing_date' ||
              (selected_search_type.length === 1 &&
                selected_search_type[0]?.value === 'closing_date')) && (
              <>
                <label className="form-control-label">
                  Data de encerramento
                </label>
                <Controller
                  render={(props) => (
                    <DatePicker
                      showIconCalendar={false}
                      fieldName="hora_fim_conversa"
                      valueController={setValue}
                      defaultValue={props.value}
                    />
                  )}
                  control={control}
                  name="hora_fim_conversa"
                  defaultValue={
                    field_value_already_selected_date
                      ? new Date(
                          field_value_already_selected_date
                        ).toLocaleDateString('pt-br')
                      : new Date().toLocaleDateString('pt-br')
                  }
                />
              </>
            )}
        </div>
      </section>
      {selected_search_type != null && selected_search_type.length === 2 && (
        <div className="historyConversation__formContainerOneRow">
          <section>
            <label className="form-control-label">Atendente</label>
            <Controller
              render={(props) => (
                <FlexSelect
                  dataOptions={attendants_with_conversations}
                  isMulti={false}
                  isClearable={true}
                  closeMenuOnSelect={true}
                  value={props.value}
                  valueController={setValue}
                  fieldName="atendente"
                  labelName="name"
                  valueName="login"
                />
              )}
              control={control}
              name="atendente"
              defaultValue={field_value_already_selected_attendent || ''}
            />
          </section>
          <section>
            <label className="form-control-label">Data de encerramento</label>
            <Controller
              render={(props) => (
                <DatePicker
                  showIconCalendar={false}
                  fieldName="hora_fim_conversa"
                  valueController={setValue}
                  defaultValue={props.value}
                />
              )}
              control={control}
              name="hora_fim_conversa"
              defaultValue={
                field_value_already_selected_date
                  ? new Date(
                      field_value_already_selected_date
                    ).toLocaleDateString('pt-br')
                  : new Date().toLocaleDateString('pt-br')
              }
            />
          </section>
        </div>
      )}
      <ButtonFormContainer>
        <ReactButton btnColor="confirmation" type="submit">
          Filtrar atendimento(s)
        </ReactButton>
      </ButtonFormContainer>
    </FormComponent>
  );
}
