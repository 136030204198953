const INITIAL_STATE = {
  chats: [],
  newMessages: false
};

export default function activeChatInternal(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@HANDLE_INFOS_USER': {
      let auxChat = state.chats;
      if (state.chats.length === 0) {
        auxChat.push(
          Object.assign(action.infos, {
            active: true
          })
        );
      } else {
        let isExist = state.chats.every(
          (item) => item.login !== action.infos.login
        );
        if (isExist && state.chats.length <= 2) {
          auxChat.push(
            Object.assign(action.infos, {
              active: true
            })
          );
        }
        if (isExist && auxChat.length === 3) {
          auxChat.pop();
          auxChat.push(
            Object.assign(action.infos, {
              active: true
            })
          );
        }
      }
      return {
        chats: [...auxChat],
        newMessages: state.newMessages
      };
    }
    case '@ACTIVE_CONVERSATION': {
      let auxChat = state.chats;

      let isExist = state.chats.findIndex(
        (item) => item.login === action.infos.login
      );
      if (isExist != -1) {
        auxChat[isExist].active = action.boll;
      }

      return {
        chats: [...auxChat],
        newMessages: state.newMessages
      };
    }
    case '@ADD_MESSAGE': {
      let stateAux = [...state.chats];

      let now = new Date();

      if (action.isSocketUpdate) {
        stateAux.map((item) => {
          if (item.login === action.id) {
            item.messages.push({
              content: action.messages,
              sender: action.sender,
              createdAt: now
            });
          }
        });
      } else {
        stateAux.map((item) => {
          if (item.id === action.id) {
            item.messages = action.messages;
          }
        });
      }
      // let isExist = messages.filter((item) => item.id == action.id);
      return {
        chats: [...stateAux],
        newMessages: state.newMessages
      };
    }
    case '@REMOVE_CHAT_SPECIFIC': {
      let stateAux = [...state.chats];
      let removedItem = stateAux.findIndex(
        (item) => item.login === action.data
      );

      stateAux.splice(removedItem, 1);

      return {
        chats: [...stateAux],
        newMessages: state.newMessages
      };
    }
    case '@WATCH_NEW_MESSAGE':
      return {
        chats: [...state.chats],
        newMessages: action.newMessages
      };
    default:
      return state;
  }
}
