export const receiveCasts = (getTransshipment) => {
  return '';
};

export const sendCasts = (data, type) => {
  let formatedData = { ...data };

  if (data.route_default === true)
    formatedData = { ...formatedData, route_id: null };

  if (data.queue_id === undefined || type === 'mensagem')
    formatedData = { ...formatedData, queue_id: null };

  if (data.audio_id === undefined || type === 'ativo' || type === 'receptivo')
    formatedData = { ...formatedData, audio_id: null };

  if (data.check_agent === '')
    formatedData = { ...formatedData, check_agent: false };

  if (data.mailing_import_type === 'file')
    formatedData = { ...formatedData, integration_url_id: null };

  if (type === 'interativo')
    formatedData = { ...formatedData, mailing_import_type: 'integration' };

  if (data['qtd_agent_free'])
    formatedData.qtd_agent_free = Number(formatedData.qtd_agent_free);

  if (data['qtd_attempt'])
    formatedData.qtd_attempt = Number(formatedData.qtd_attempt);

  return formatedData;
};
