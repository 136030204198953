import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import customParser from 'socket.io-msgpack-parser';
import {
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input
} from 'reactstrap';

import ReactLoader from 'components/Loader';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import Pagination from 'components/Pagination';
import ModalConfirmation from 'components/Modal/ConfirmationModal';
import HandleContactsModal from './components/HandleContactsModal';
import BroadcastListSMSService from '../service';
import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import io from 'socket.io-client';
import useDebounce from '../../ServicePanel/components/QueueContent/components/Contact/ContactActiveModal/hooks/useDebaunce';
import { format, parseISO } from 'date-fns';
import ModalText from './components/Modaltext/Index';
import HandleAllContactsModalSms from './components/HandleAllContactsModalSms';
import TimelineFiltroHeaderSms from './components/HeaderSms';

export const ChatBroadcastListListSMS = () => {
  const [chatBroadcastList, setChatBroadcastList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [idResource, setidResource] = useState();
  const [idExecute, setIdExecute] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [openModalExecuteConfirmation, setOpenModalExecuteConfirmation] =
    useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [isAllContactModalOpen, setAllIsContactModalOpen] = useState(false);
  const [executingBroadcastList, setExecutingBroadcastList] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  const socketConversation = useMemo(() => {
    return io.connect(process.env.REACT_APP_PROXY_URL, {
      path: process.env.REACT_APP_CHANNEL_SOCKET_PATH,
      parser: customParser,
      transports: ['websocket'],
      upgrade: false
    });
  }, []);

  useEffect(() => {
    return () => {
      socketConversation.close();
    };
  }, [socketConversation]);

  const handleGetChatBroadcastList = useCallback((selectedPage = 1) => {
    BroadcastListSMSService.getAllPagination(selectedPage)
      .then((res) => {
        const data = res.data.registers;
        const quantity = res.data.count;
        if (quantity) {
          setChatBroadcastList([...data]);
          setQuantity(quantity);
        }
      })
      .catch((error) => {
        const errorMessage =
          error.response && error.response.data && error.response.data.message;
        toast.error(errorMessage || 'Erro ao listar listas de transmissão');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const setListStatusSocket = useCallback(
    (id, status) => {
      if (chatBroadcastList.length === 0) {
        return;
      }
      let copyList = [...chatBroadcastList];

      let listaIndex = copyList.findIndex((lista) => {
        return lista.id === +id;
      });
      if (listaIndex >= 0) {
        if (copyList[listaIndex].status === status) {
          return;
        }
        copyList[listaIndex].status = status;

        setChatBroadcastList([]);
        setChatBroadcastList([...copyList]);
      }
    },
    [chatBroadcastList, setChatBroadcastList]
  );

  const executeBroadcastList = useCallback((broadcastListId) => {
    setExecutingBroadcastList(true);

    BroadcastListSMSService.executeBroadcastList({
      broadcastListId
    })
      .then(() => {
        toast.success('Lista de transmissão iniciada com sucesso');
      })
      .catch((err) => {
        toast.error(
          err.response.data.message ?? 'Erro ao inicar lista de transmissão'
        );
      })
      .finally(() => {
        setExecutingBroadcastList(false);
        setOpenModalExecuteConfirmation(false);
      });
  }, []);

  const pauseBroadcastList = useCallback((broadcastListId) => {
    setExecutingBroadcastList(true);

    BroadcastListSMSService.pauseBroadcastList({
      broadcastListId
    })
      .then(() => {
        toast.success('Lista de transmissão pausada com sucesso');
      })
      .catch((err) => {
        toast.error(
          err.response.data.message ?? 'Erro ao inicar lista de transmissão'
        );
      })
      .finally(() => {
        setExecutingBroadcastList(false);
      });
  }, []);

  const resumeBroadcastList = useCallback((broadcastListId) => {
    setExecutingBroadcastList(true);

    BroadcastListSMSService.resumeBroadcastList({
      broadcastListId
    })
      .then(() => {
        toast.success('Lista de transmissão despausada com sucesso');
      })
      .catch((err) => {
        toast.error(
          err.response.data.message || 'Erro ao despausar lista de transmissão'
        );
      });

    setExecutingBroadcastList(false);
  }, []);

  const syncBroadcastList = (id) => {
    BroadcastListSMSService.syncBroadcastList(id)
      .then(() => {
        toast.success('Lista de transmissão sincronizada com sucesso');
      })
      .catch((err) => {
        toast.error(
          err.response.data.message ||
            'Erro ao sincronizar lista de transmissão'
        );
      });
  };

  useEffect(() => {
    handleGetChatBroadcastList();
  }, [handleGetChatBroadcastList]);

  useEffect(() => {
    socketConversation.on('lista_de_transmissao', async (data) => {
      setListStatusSocket(data.id, data.status);
    });
  }, [socketConversation, chatBroadcastList, setListStatusSocket]);

  const handlePageChange = (Page) => {
    setActivePage(Page);
    handleGetChatBroadcastList(Page);
  };

  const handleDeleteChatBroadcastList = useCallback(
    (id) => {
      BroadcastListSMSService.delete(id)
        .then(() => {
          toast.success('Campanha Ativa SMS excluída');
        })
        .catch((error) => {
          const errorMessage =
            error.response &&
            error.response.data &&
            error.response.data.message;
          toast.error(errorMessage || 'Erro ao exluir campanha');
        })
        .finally(() => {
          setOpenModalConfirmation(false);
          handleGetChatBroadcastList();
        });
    },
    [handleGetChatBroadcastList]
  );

  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };

  const controlExecute = (id) => {
    setIdExecute(id);
    setOpenModalExecuteConfirmation(true);
  };

  const handleOpenDashboard = (id) => {
    window.open(
      `${window.location.origin}/callback/broadcast-list-sms/${id}/dashboard`,
      '_blank',
      'width=' +
        window.screen.availWidth +
        ',height=' +
        window.screen.availHeight
    );
  };

  const handleGetChatBroadcastListFiltered = useCallback(
    (selectedPage = 1) => {
      setLoading(true);
      if (debouncedSearchValue === '') {
        handleGetChatBroadcastList();
        return;
      }
      BroadcastListSMSService.getAllSearch(
        selectedPage,
        10,
        debouncedSearchValue
      )
        .then((res) => {
          const data = res.data.registers;
          const quantity = res.data.count;
          if (quantity) {
            setChatBroadcastList([...data]);
            setQuantity(quantity);
          }
        })
        .catch((error) => {
          const errorMessage =
            error.response &&
            error.response.data &&
            error.response.data.message;
          toast.error(errorMessage || 'Erro ao listar listas de transmissão');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [debouncedSearchValue, handleGetChatBroadcastList]
  );

  useEffect(() => {
    handleGetChatBroadcastListFiltered();
  }, [handleGetChatBroadcastListFiltered]);

  const isAutomatic = () => {
    if (chatBroadcastList.some((item) => item.isAutomatic)) {
      return {
        dataField: 'isAutomatic',
        style: icon_column_style,
        headerStyle: individual_icon_header_style,
        text: 'Automatica',
        formatExtraData: executingBroadcastList,
        formatter: (_, row) => {
          if (row.isAutomatic) {
            return 'Sim';
          }
          return 'Não';
        }
      };
    }
    return {};
  };

  return (
    <>
      <TimelineFiltroHeaderSms
        setCreate="/admin/chat/broadcast-list-sms/form"
        name="Form validation"
        parentName="Forms"
        setAllIsContactModalOpen = {setAllIsContactModalOpen}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Campanha Ativa SMS</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Lista de transmissão"
                          type="text"
                          onChange={(event) =>
                            setSearchValue(event.target.value)
                          }
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={chatBroadcastList || []}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'title',
                      text: 'Título',
                      sort: true,
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style
                    },
                    {
                      dataField: 'created_at',
                      text: 'Data de criação',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        if (!row.created_at) return '-';

                        const inputDateString = row.created_at;

                        const dateObject = parseISO(inputDateString);

                        return format(dateObject, 'dd/MM/yyyy');
                      }
                    },
                    {
                      ...isAutomatic()
                    },
                    // {
                    //   dataField: 'queueName',
                    //   text: 'Fila',
                    //   sort: true,
                    //   style: individual_column_style,
                    //   headerStyle: individual_header_style
                    // },
                    {
                      dataField: 'message',
                      text: 'Informações',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        return <ModalText row={row}></ModalText>;
                      }
                    },
                    {
                      dataField: 'execute',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Executar',
                      formatExtraData: executingBroadcastList,
                      formatter: (cell, row, rowIndex, formatExtraData) => {
                        if (row.status === 'livre') {
                          return (
                            <Button
                              color="success"
                              size="sm"
                              title="Executar"
                              disabled={formatExtraData}
                              onClick={() => controlExecute(row.id)}
                            >
                              <i className="fas fa-play-circle"></i>
                            </Button>
                          );
                        }
                        if (row.status === 'pausado') {
                          return (
                            <Button
                              color="primary"
                              size="sm"
                              title="Despausar"
                              disabled={formatExtraData}
                              onClick={() => {
                                resumeBroadcastList(row.id);
                              }}
                            >
                              <i className="fas fa-play-circle"></i>
                            </Button>
                          );
                        }
                        if (row.status === 'executando') {
                          return (
                            <Button
                              color="danger"
                              size="sm"
                              title="Pausar"
                              disabled={formatExtraData}
                              onClick={() => {
                                pauseBroadcastList(row.id);
                              }}
                            >
                              <i className="fas fas fa-stop-circle"></i>
                            </Button>
                          );
                        }
                      }
                    },
                    {
                      text: 'Dashboard',
                      sort: true,
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="success"
                            title="Dashboard"
                            size="sm"
                            onClick={() => handleOpenDashboard(row.id)}
                          >
                            <i className="fas fa-chart-pie"></i>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'broadcastTypeOfClosingConversation',
                      text: 'Sincronizar',
                      sort: true,
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      formatter: (_, row) => {
                        if (row.broadcastTypeOfClosingConversation === 2) {
                          return (
                            <Button
                              color="success"
                              size="sm"
                              title="Sincronizar"
                              onClick={() => {
                                syncBroadcastList(row.id);
                              }}
                            >
                              <i className="fas fa-sync"></i>
                            </Button>
                          );
                        }
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      formatter: (_, row) => {
                        return (
                          <Link
                            to={`/admin/chat/broadcast-list-sms/form/${row.id}`}
                          >
                            <Button title="Editar" color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },
                    {
                      dataField: 'contacts',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Contatos',
                      formatter: (_, row) => {
                        return (
                          <>
                            <Button
                              color="info"
                              size="sm"
                              title="Contatos"
                              onClick={() => {
                                setidResource(row.id);
                                setIsContactModalOpen(true);
                              }}
                            >
                              <li className="fas fa-address-card"></li>
                            </Button>
                          </>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            title="Deletar"
                            size="sm"
                            onClick={() => controlDelete(row.id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={handleDeleteChatBroadcastList}
        message={'Tem certeza que deseja excluir essa Campanha Ativa SMS?'}
      />
      <ModalConfirmation
        isModalOpen={openModalExecuteConfirmation}
        onModalToggle={setOpenModalExecuteConfirmation}
        unmountOnClose={true}
        idResource={idExecute}
        deleteResource={executeBroadcastList}
        message={'Executar campanha ativa SMS?'}
      />
      <HandleContactsModal
        isModalOpen={isContactModalOpen}
        onModalToggle={setIsContactModalOpen}
        unmountOnClose={true}
        broadcastListId={idResource}
      />
      <HandleAllContactsModalSms
        isModalOpen={isAllContactModalOpen}
        onModalToggle={setAllIsContactModalOpen}
        unmountOnClose={true}
        broadcastListId={idResource}
      />
    </>
  );
};
