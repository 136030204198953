import React from 'react';
import { formatDateForAudioDownloads } from '../../../../../utils/fuctions/formattedDateForAudioDownloads';
import * as fileSaver from 'file-saver';

import { Row, Card, Button } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import ReportService from '../service';
import AudioStream from '../../../../../components/Inputs/AudioStreamReportTelephony';
import { toast } from 'react-toastify';
import {
  individual_column_style,
  individual_header_style,
  individual_icon_header_style,
  icon_column_style
} from '../../../../../assets/styles/table_custom_style';
import { TotalRegister } from '../../../../../components/TotalRegister';

const Analytical = ({ dataReport, totalRegister }) => {
  const downloadAudio = (uniqueid, nameAgent, date) => {
    const formattedDate = formatDateForAudioDownloads(date) || '';

    ReportService.downloadAudio(uniqueid)
      .then((res) => {
        res.data.size <= 11
          ? toast.info('Nenhum registro foi encontrado!')
          : fileSaver.saveAs(res.data, `${formattedDate}-${nameAgent}.gsm`);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            {totalRegister >= 0 && (
              <TotalRegister totalRegister={totalRegister} />
            )}
            <Card>
              <ToolkitProvider
                data={dataReport.listCallbackDTO || []}
                keyField="uuid"
                columns={[
                  {
                    text: 'Nome do Callback',
                    dataField: 'callback',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Fila entrada',
                    dataField: 'queueNameIn',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Fila Saída ',
                    dataField: 'queueNameOut',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Agente',
                    dataField: 'agent',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Status',
                    dataField: 'status',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Telefone',
                    dataField: 'phoneNumber',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Quantidade de Tentativas',
                    dataField: 'attempts',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Data de Entrada',
                    dataField: 'startDay',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Hora de Entrada',
                    dataField: 'startHour',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Data Último Retorno',
                    dataField: 'endDay',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Hora Último Retorno',
                    dataField: 'endHour',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Tempo de Retorno',
                    dataField: 'timeReturnFormatted',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Duração da Chamada',
                    dataField: 'callDuration',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Download',
                    dataField: 'download',
                    sort: true,
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (rowContent, row) => {
                      return (
                        <Button
                          color="success"
                          size="sm"
                          disabled={row.uniqueId ? false : true}
                          dataplacement="top"
                          title={row.uniqueId ? '' : 'Não possui registro'}
                          datacontainer="body"
                          dataanimation="true"
                          onClick={() =>
                            downloadAudio(
                              row.uniqueId,
                              row.callback,
                              row.startDay
                            )
                          }
                        >
                          <i className="fas fa-cloud-download-alt"></i>
                        </Button>
                      );
                    }
                  },
                  {
                    text: 'Escutar',
                    dataField: 'listen',
                    sort: true,
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (rowContent, row) => {
                      return (
                        <AudioStream
                          ReportService={ReportService}
                          uniqueid={row.uniqueId}
                        />
                      );
                    }
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Analytical;
