import React from 'react';

import { Container } from './styled';

import ExtensionCardItem from '../ExtensionCarditem';

const ExtensionCardContainer = ({ extensions }) => {
  return (
    <Container>
      {extensions.map((extension) => (
        <ExtensionCardItem key={extension.extension} data={extension} />
      ))}
    </Container>
  );
};

export default ExtensionCardContainer;
