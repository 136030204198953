export const ImageButton = {
  background: 'transparent',
  border: 0,
  margin: '0.5rem'
};

export const ImageButtonContainer = {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
};

export const ButtonDownloadPdf = {
  // border: '1px solid black',
  // borderRadius: '5px',
  // color: 'black',
  // display: 'flex',
  // alignItems: 'center',
  // justifyContent: 'center',
  // gap: '0.5rem'
  padding: '0.5rem'
};

export const ImageButtonConteinerProtocol = {
  display: 'flex',
  maxHeight: '50px',
  height: '50px',
  justifyContent: 'space-between'
};

export const ImageSize = {
  width: '42px'
};


