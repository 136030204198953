import React, { useState, useEffect, useRef, forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import io from 'socket.io-client';
import {
  activeConversation,
  addMessage,
  removeChat
} from '../../../../store/action/internalChat';

// import socket from '../../socketInternalChat/index';
import { FormatDate } from '../formatDatePTBR';
import { FormatTime } from '../formatTIME';
import ReacScrollabelFeed from 'react-scrollable-feed';

import {
  MdClose,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown
} from 'react-icons/md';
import { RiSendPlaneFill } from 'react-icons/ri';

import {
  Container,
  Header,
  BodyChat,
  BalloonRight,
  BalloonLeft,
  InputArea,
  Arrow,
  ArrowLeft,
  ContentMessages
} from './style';

//Henrique
import api from '../../config/configAPI';

import { useConectionSocketAdmConversation } from 'hooks/useSocketAdmConversations';
import { useConectionSocketChatInternal } from 'hooks/useInternalChatSocket';

export const ChatIndice = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { data, scrollToBottom } = props;

  const stateData = useSelector((state) => state.activeChatInternal);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const { socketAdm } = useConectionSocketAdmConversation();
  const { socketInternalChat } = useConectionSocketChatInternal();

  const [chatToggle, setChatToggle] = useState(false);
  const [firstGetMessages, setFirstGetMessages] = useState(false);

  const [mensagem, setMensagem] = useState('');
  const [listaMensagem, setListaMensagem] = useState([]);

  function modifyAcive(boll) {
    dispatch(activeConversation(chatToggle, boll));
  }

  useEffect(() => {
    setChatToggle(data);
  }, [data]);

  useEffect(() => {
    socketAdm.on('recebe_mensagem', (dados) => {
      setListaMensagem([...listaMensagem, dados]);
    });
  }, []);

  function getMessages(sender, received) {
    api
      .get(`/conversations/${sender}/${received}/messages`)
      .then((response) => {
        dispatch(addMessage(response.data.messages, data.id, sender, false));
        setFirstGetMessages(true);
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response.data.mensagem);
        } else {
          console.log('Erro ao adicionar mensagem!');
        }
      });
  }

  let watchvalueInput = !mensagem.match(/^(\s)+$/);

  const enviarMensagem = (e) => {
    e.preventDefault();
    const dataSendMessage = {
      to: data.login,
      from: localStorage.getItem('login'),
      content: mensagem
    };

    setMensagem('');

    if (watchvalueInput && mensagem.length > 0) {
      socketInternalChat.emit('message:send', dataSendMessage);
      dispatch(
        addMessage(mensagem, data.login, localStorage.getItem('login'), true)
      );
    }
    setFirstGetMessages(false);
  };

  function closeChatSpecific(param) {
    dispatch(removeChat(param.login));
  }
  function updatedChatConversation() {
    socketInternalChat.emit('chat:opened', {
      self: ''
    });
  }

  useEffect(() => {
    getMessages(localStorage.getItem('login'), data.login);
  }, []);

  return (
    <Container>
      <Header
        color={systemConfiguration.primary_color}
        fontColor={systemConfiguration.internal_chat_font_color}
      >
        {chatToggle.active ? (
          <button className="header" onClick={() => modifyAcive(false)}>
            <p>{data.name}</p>
          </button>
        ) : (
          <button className="header" onClick={() => modifyAcive(true)}>
            <p>{data.name}</p>
          </button>
        )}
        <div className="actions-header-chat-internal">
          <MdClose
            size="21"
            type="button"
            onClick={() => {
              closeChatSpecific(data);
              updatedChatConversation();
            }}
          />
        </div>
      </Header>
      <BodyChat size={chatToggle.active}>
        <ReacScrollabelFeed forceScroll className="area-messages">
          {data &&
            data.messages?.map((message, index) => {
              return (
                <div key={index}>
                  {new Date(data.messages[index]?.createdAt).getFullYear() ===
                    new Date(
                      data.messages[index + 1]?.createdAt
                    ).getFullYear() &&
                    new Date(data.messages[index]?.createdAt).getMonth() ===
                      new Date(
                        data.messages[index + 1]?.createdAt
                      ).getMonth() &&
                    new Date(data.messages[index]?.createdAt).getDate() !==
                      new Date(
                        data.messages[index - 1]?.createdAt
                      ).getDate() && <FormatDate date={message.createdAt} />}
                  {localStorage.getItem('login') === message.sender ? (
                    <ContentMessages>
                      <BalloonRight>
                        {message.content}
                        <FormatTime
                          paramAlign={'right'}
                          date={message.createdAt}
                        />
                      </BalloonRight>
                      <Arrow />
                    </ContentMessages>
                  ) : (
                    <ContentMessages>
                      <ArrowLeft
                        color={systemConfiguration.internal_chat_balloon_color}
                      />
                      <BalloonLeft
                        fontColor={systemConfiguration.internal_chat_font_color}
                        color={systemConfiguration.internal_chat_balloon_color}
                      >
                        {message.content}
                        <FormatTime
                          paramAlign={'left'}
                          date={message.createdAt}
                        />
                      </BalloonLeft>
                    </ContentMessages>
                  )}
                </div>
              );
            })}
        </ReacScrollabelFeed>
      </BodyChat>
      <InputArea
        color={systemConfiguration.primary_color}
        size={chatToggle.active}
        onSubmit={enviarMensagem}
      >
        <input
          type="text"
          name="mensagem"
          value={mensagem}
          autoComplete="off"
          onChange={(texto) => {
            setMensagem(texto.target.value);
          }}
          className="input-chat-internal-write"
          placeholder="Digite uma mensagem"
        />
      </InputArea>
    </Container>
  );
});
