/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import StatusService from '../service';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Pagination from 'components/Pagination';
import ModalConfirmation from '../../../components/Modal/ConfirmationModal';
import ReactLoader from 'components/Loader';

import { Container, Row, Card, CardHeader, Button, Col, Input,  InputGroup,InputGroupAddon,InputGroupText, } from 'reactstrap';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';

const StatusList = () => {
  const [status, setStatus] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [idResource, setidResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [filterstatus, setfilterstatus] = useState('');
  const [searchUsers, setSearchUsers] = useState();


  const filterphase = status.filter((value) => {
    return value.name.toLowerCase().includes(filterstatus.toLowerCase());
  });

  useEffect(() => {
    getStatus();
  }, []);

  const getUsersSearch = (event, selectedPage = 1) => {
    setSearchUsers(event.target.value);
    if (searchUsers) {
      getStatus(selectedPage, event.target.value);
    }
  };

  const getStatus = (selectedPage = 1, search = '') => {
    StatusService.getAll(selectedPage, search)
      .then((res) => {
        const status = res.data.content;

        //Tratando exibição dos Enums na listagem
        status.forEach((el, index) => {
          if (status[index].action === 'SEND_EMAIL')
            status[index].action = 'Enviar Email';
          if (status[index].action === 'SEND_SMS')
            status[index].action = 'Enviar SMS';
        });

        const quantity = res.data.quantity;
        const newActivePage = selectedPage || activePage;
        setStatus(status);
        setQuantity(quantity);
        setActivePage(newActivePage);
        setLoading(false);
      })
      .catch((error) => {
        const { data } = error.response;
        toast.error(data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const handlePageChange = (activePage) => getStatus(activePage);

  const deleteStatus = (id) => {
    StatusService.delete(id)
      .then((res) => {
        const { data } = res;
        getStatus();
        toast.success(data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .catch((error) => {
        const { data } = error.response;
        toast.error(data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        getStatus();
      });
  };

  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/status/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Status/Fases</h3>
                </CardHeader>
                <Col md="8" mt='10'>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText style={{marginTop:'10px'}}>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Buscar Status/Fases..."
                          type="text"
                          onChange={(event) => getUsersSearch(event)}
                          style={{marginTop: '10px'}}
                        />
                      </InputGroup>
                    </Col>
                {filterphase && filterphase.length ? 
                <ToolkitProvider
                  data={filterphase}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'description',
                      text: 'Descrição',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },

                    {
                      dataField: 'should_send_notification',
                      text: 'Enviar notificação',
                      sort: true,
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      formatter: (shouldSendNotification) => {
                        return shouldSendNotification ? 'Sim' : 'Não';
                      }
                    },
                    {
                      dataField: 'color',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Cor',
                      // eslint-disable-next-line react/display-name
                      formatter: (rowContent, row) => {
                        return (
                          <div
                            style={{
                              marginLeft: '45%',
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: `${row.color}`
                            }}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link to={'/admin/status/form/' + row.id}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },

                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            onClick={() => controlDelete(row.id)}
                            size="sm"
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider> : []}
                
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={deleteStatus}
        message={'Tem certeza que deseja excluir o status/fase?'}
      />
    </>
  );
};

export default StatusList;
