import styled from 'styled-components';

export const RemoveQuestionButton = styled.button`
  border: 0;
  background: none;
  padding: 0;
  color: rgba(33, 37, 41, 0.5);

  &:hover {
    color: rgba(220, 53, 69, 1);
  }
`;

export const AddQuestionButton = styled.button`
  border: 0;
  padding: 0;
  font-size: 0.875rem;
  font-weight: bold;
  padding: 0.5rem;
  border-radius: 0.375rem;
  background: none;
  color: rgba(33, 37, 41, 0.5);

  &:hover {
    background-color: #e9ecef;
  }
`;
