import React from 'react';

import CollectiveIndicatorChart from '../CollectiveIndicatorChart';

const GREEN_COLOR = 'rgb(19, 133, 77, 1)';
const RED_COLOR = 'rgb(224, 54, 35, 1)';

const ServiceLevelChart = ({ data }) => {
  return (
    <CollectiveIndicatorChart
      title="Nível de serviço"
      labels={['Conforme', 'Não conforme']}
      dataset={{
        data: [data.according, data.notAccording],
        percentage: [data.accordingPercentage, data.notAccordingPercentage],
        backgroundColor: [GREEN_COLOR, RED_COLOR]
      }}
    />
  );
};

export default React.memo(ServiceLevelChart);
