import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import ModalChangeDayOff from '../../views/ChangeDayOff/components/ModalChangeDayOff';
const TimelineHeader = ({ loadApi, ...props }) => {
  const [state, setState] = useState({
    path: '',
    return: props.returnPath || '/admin/configuration',
    controlReturn: false
  });

  const [openModalUpdatePenality, setOpenModalUpdatePenality] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  useEffect(() => {
    setOpenModalUpdatePenality(props.isOpen);
  }, [props.isOpen]);
  function handleReturn() {
    setState({ ...state, controlReturn: true });
  }
  const handleOpenModalUpdatePenality = () => {
    setOpenModalUpdatePenality(true);
    setIsNew(true);
  };
  const buttonCreate = props.setCreate ? (
    <>
      <Button
        onClick={() => handleOpenModalUpdatePenality()}
        color="secondary"
        style={{
          backgroundColor: '#f7fafc',
          borderColor: '#f7fafc',
          color: '#212529',
          boxShadow: '4px 6px 1px 3px rgb(50,50,93/11%)'
        }}
      >
        Solicitar
      </Button>
    </>
  ) : null;

  if (state.path && props.entityName) {
    return (
      <Redirect
        to={{
          pathname: state.path,
          entityName: { entityName: props.entityName }
        }}
      />
    );
  } else if (state.path) {
    return (
      <Redirect
        to={{
          pathname: state.path
        }}
      />
    );
  }
  if (state.controlReturn) return <Redirect to={state.return} />;
  return (
    <>
      <div
        className="header header-dark pb-6 content__title content__title--calendar"
        style={{ background: systemConfiguration.primary_color }}
      >
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {props.showBackArrow !== 'not' && (
                  <Button
                    size="sm"
                    color={systemConfiguration.primary_color}
                    title={
                      props.buttonTitle ||
                      'Volta para a pagina de configurações'
                    }
                    onClick={handleReturn}
                  >
                    <BiArrowBack size="35px" color="white" />
                  </Button>
                )}
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                {buttonCreate}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <ModalChangeDayOff
        isModalOpen={openModalUpdatePenality}
        onModalToggle={setOpenModalUpdatePenality}
        reloadfunction={loadApi}
        id={null}
      />
    </>
  );
};

export default TimelineHeader;
