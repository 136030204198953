import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Row, Col } from 'reactstrap';
import DatePicker from '../../../../../../components/Inputs/DatePicker';
import FlexSelect from '../../../../../../components/Inputs/FlexSelect';
import ReactButton from '../../../../../../components/Buttons/ReactButton';
import { ButtonWrapper } from '../../ClientProtocol/styles';
import ReportService from '../../../../../Report/Callcenter/ReceivedCall/service';
import Pagination from 'components/Pagination';
import { toast } from 'react-toastify';
import { VoiceProtocolTable } from './VoiceProtocolTable';
import ReactLoader from 'components/Loader';

export const VoiceProtocolModal = ({ isModalOpen, toggleModal, telefone }) => {
  const [callStatus, setCallStatus] = useState(undefined);
  const [calls, setCalls] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [startDate, setStartDate] = useState(
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      1
    ).toLocaleDateString('pt-br')
  );
  const [endDate, setEndDate] = useState(
    new Date().toLocaleDateString('pt-br')
  );

  const formatDate = (date) => {
    let dateArray = date.split('/');
    let DD = dateArray[0];
    let MM = dateArray[1];
    let YY = dateArray[2];

    return `${MM}-${DD}-${YY}`;
  };

  const getReports = () => {
    setIsLoading(true);
    ReportService.getAllReportsType('analytical', activePage, {
      quantity: 10,
      page: activePage,
      dateBegin: `${formatDate(startDate)} 00:00:00`,
      timeStart: '000000',
      dateEnd: `${formatDate(endDate)} 23:59:59`,
      timeEnd: '235959',
      reportType: 1,
      disable: false,
      origin: telefone ? telefone : undefined,
      disposition: callStatus
    })
      .then((res) => {
        setCalls(res.data.data.listCallInAnalyticalDTO);
        setQuantity(res.data.data.quantityCall);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err, {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  useEffect(() => {
    getReports();
  }, []);

  const handlePageChange = (page) => {
    getReports();
    setActivePage(page);
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={isModalOpen}
        toggle={toggleModal}
        style={{ width: '80vw', maxWidth: ' 40rem' }}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Relatório de chamadas
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>x</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />
          <Row style={{ justifyContent: 'center', marginBottom: '0.5rem' }}>
            <Col md={12}>
              <label className="form-control-label">Status da chamada</label>
              <FlexSelect
                value={callStatus}
                fieldName="disposition"
                valueController={(fieldName, value) => {
                  setCallStatus(value);
                }}
                dataOptions={[
                  { label: 'Atendida', value: 'ANSWERED' },
                  { label: 'Não Atendida', value: 'NO ANSWER' }
                ]}
                labelName="label"
                valueName="value"
                closeMenuOnSelect={true}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <label className="form-control-label">Data inicial</label>
              <DatePicker
                flexWrap={false}
                fieldName="startDate"
                value={startDate}
                valueController={(fieldname, value) => {
                  setStartDate(value);
                }}
                defaultValue={startDate}
              />
            </Col>
            <Col md={6}>
              <label className="form-control-label">Data final</label>
              <DatePicker
                flexWrap={false}
                fieldName="endDate"
                value={endDate}
                valueController={(fieldname, value) => {
                  setEndDate(value);
                }}
                defaultValue={endDate}
              />
            </Col>
          </Row>

          <ButtonWrapper>
            <ReactButton
              onClick={() => {
                getReports();
              }}
              btnColor="confirmation"
            >
              Filtrar chamada(s)
            </ReactButton>
          </ButtonWrapper>
          {calls && !isLoading ? (
            calls.length > 0 ? (
              <>
                <VoiceProtocolTable
                  data={calls}
                  ReportService={ReportService}
                />
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </>
            ) : (
              <p className="mb-4 mt-4 text-center">
                Nenhum relatório foi encontrado
              </p>
            )
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <ReactLoader />
            </div>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};
