import styled from 'styled-components';

export const SelectTemplateWrapper = styled.div`
  & > div > div > div > div:first-child {
    width: 100%;
  }
`;

export const SelectTemplateOptions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    color: ${(props) => props.color};
    ${(props) =>
      props.isMulti === true
        ? { marginLeft: '0.5rem' }
        : { marginRight: '0.5rem' }}
  }
`;
