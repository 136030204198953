import { formatCurrency } from 'utils/fuctions/formatCurrency';
import { parseIntFromString } from 'utils/fuctions/parseIntFromString';
import { maskCnpj, maskCpf } from '../utils/cpfCnpjHelpers';
import { toBrazilianDate } from '../utils/date';
import {
  maskPhoneNumber,
  removeMaskSpecialCharacters
} from '../utils/phoneNumberHelpers';
import { keepOnlyNumericCaracters } from './stringHelpers';

const {
  customFieldTypes
} = require('views/fieldConfigs/utils/customFieldTypes');
const _ = require('lodash');

/**
 * @param {Object} data
 * @param {Array} customFieldsConfig
 * @returns {Object}
 */
export const formatCustomFieldsToSubmit = (data, customFieldsConfig) => {
  if (!data) throw new Error('data is required');
  if (!customFieldsConfig) throw new Error('customFieldsConfig is required');

  const dataDTO = _.cloneDeep(data);

  const multipleCustomFields =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.MULTIPLE)
      .map((field) => field.name);

  multipleCustomFields.forEach((fieldName) => {
    if (dataDTO[fieldName]) {
      dataDTO[fieldName] = dataDTO[fieldName].join(';');
    } else {
      dataDTO[fieldName] = null;
    }
  });

  const numericCustomFields =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.NUMERIC)
      .map((field) => field.name);

  Object.keys(dataDTO).forEach((fieldName) => {
    if (numericCustomFields.includes(fieldName)) {
      if (dataDTO[fieldName]) {
        dataDTO[fieldName] = Number(dataDTO[fieldName]);
      } else {
        dataDTO[fieldName] = null;
      }
    }
  });

  const currencyFieldsName =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.CURRENCY)
      .map((field) => field.name);

  currencyFieldsName.forEach((item) => {
    const fieldValue = dataDTO[item];
    if (fieldValue) {
      dataDTO[item] = parseIntFromString(fieldValue);
    }
  });

  const cpfFieldNames =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.CPF)
      .map((field) => field.name);

  cpfFieldNames.forEach((item) => {
    const fieldValue = dataDTO[item];
    if (fieldValue) {
      dataDTO[item] = keepOnlyNumericCaracters(fieldValue);
    }
  });

  const cnpjFieldNames =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.CNPJ)
      .map((field) => field.name);

  cnpjFieldNames.forEach((item) => {
    const fieldValue = dataDTO[item];
    if (fieldValue) {
      dataDTO[item] = keepOnlyNumericCaracters(fieldValue);
    }
  });

  const telephoneFieldNames =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.TELEPHONE)
      .map((field) => field.name);

  telephoneFieldNames.forEach((item) => {
    const fieldValue = dataDTO[item];
    if (fieldValue) {
      dataDTO[item] = removeMaskSpecialCharacters(fieldValue);
    }
  });

  return dataDTO;
};

export const formatCustomFieldsToDisplay = (data, customFieldsConfig) => {
  if (!data) throw new Error('data is required');
  if (!customFieldsConfig) throw new Error('customFieldsConfig is required');

  const dataDTO = _.cloneDeep(data);

  const multipleCustomFields =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.MULTIPLE)
      .map((field) => field.name);

  const currencyFieldsName =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.CURRENCY)
      .map((field) => field.name);
  const dateFieldsName =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.DATE)
      .map((field) => field.name);
  const telephoneFieldsName =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.TELEPHONE)
      .map((field) => field.name);
  const cpfFieldsName =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.CPF)
      .map((field) => field.name);
  const cnpjFieldsName =
    customFieldsConfig &&
    customFieldsConfig
      .filter((field) => field.type === customFieldTypes.CNPJ)
      .map((field) => field.name);

  Object.keys(dataDTO).forEach((key) => {
    if (currencyFieldsName.includes(key) && dataDTO[key]) {
      dataDTO[key] = formatCurrency(parseInt(dataDTO[key], 10));
    } else if (dateFieldsName.includes(key) && dataDTO[key]) {
      dataDTO[key] = dataDTO[key] ? toBrazilianDate(dataDTO[key]) : '';
    } else if (telephoneFieldsName.includes(key) && dataDTO[key]) {
      dataDTO[key] = maskPhoneNumber(dataDTO[key]);
    } else if (cpfFieldsName.includes(key) && dataDTO[key]) {
      dataDTO[key] = maskCpf(dataDTO[key]);
    } else if (cnpjFieldsName.includes(key) && dataDTO[key]) {
      dataDTO[key] = maskCnpj(dataDTO[key]);
    } else if (multipleCustomFields.includes(key) && dataDTO[key]) {
      dataDTO[key] = dataDTO[key].split(';');
    }
  });

  return dataDTO;
};
