import DefaultModal from 'components/Modal/DefaultModal';
import { useDialogTree } from '../../tree/context';
import React, { useCallback } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { useSelector } from 'react-redux';

function DeleteNodeConfirmationModal({
  isModalOpen,
  onModalToggle,
  nodeData,
  nodeHasChildren
}) {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { removeNode } = useDialogTree();

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Confirmação"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
    >
      <>
        <h3 className="mb-0 align-text-center" style={{ textAlign: 'center' }}>
          {nodeHasChildren
            ? 'Tem certeza que deseja excluir este nó e todos os seus filhos?'
            : 'Tem certeza que deseja excluir este nó?'}
        </h3>
        <hr />
        <Row>
          <Col md="8">
            <Button
              style={buttonStyle(systemConfiguration.confirmation_button_color)}
              onClick={() => {
                removeNode(nodeData);
                closeModal();
              }}
            >
              Sim
            </Button>
          </Col>
          <Col md="4">
            <Button
              style={buttonStyle(systemConfiguration.cancelation_button_color)}
              onClick={closeModal}
            >
              Voltar
            </Button>
          </Col>
        </Row>
      </>
    </DefaultModal>
  );
}

export default DeleteNodeConfirmationModal;
