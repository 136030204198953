import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  .ContentLogin {
    width: 220px;
  }

  .softphone-btn {
      border: 0;
      border-radius: 7px;
      background-color: #05765C;
      width: 100%;
      height: 35px;
      color: #fff;
      margin-top: 8px;
      transition: ease 0.3s;
    }

    .softphone-btn:hover {
      background-color: ${shade(0.2, '#05765C')};
      cursor: pointer;
    }

    .softphone-btn:focus {
      outline-style: none;
    }
  }
`;

export const AreaLogin = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 5px;
  border-radius: 7px;
  background-color: #d5d4d4;
  padding: 4px;
  transition: ease 0.3s;

  input {
    border: 0;
    padding: 2px;
    background-color: #d5d4d4;
    border-radius: 7px;
  }

  svg {
    margin: 0 3px 0 5px;
  }
`;
