import styled from 'styled-components';

export const Node = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  .node-content {
    display: grid;
    grid-template-columns: ${(props) =>
      !props.isNodeHasChildren ? '30px 1fr 30px;' : '1fr 30px'};
    grid-template-rows: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 0px;
    align-items: start;
    justify-items: start;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100px;
    min-width: 100px;
    max-width: 28rem;
    margin: 0.2rem 0;
    border-radius: 5px;
    color: #232323;
    padding: 10px 10px;
    background-color: ${(props) =>
      props.isNodeDefault
        ? 'rgb(119 119 119 / 47%);'
        : props.isNodeHasChildren
        ? '#e3e3e3'
        : '#e3e3e3'};
  }

  ${(props) =>
    !props.isNodeInTheFirstLevel &&
    `
    &::before {
      content: '';
      display: block;
      border-left: 2px solid #A2ADB5;
      height: ${
        props.verifyIfIsTheLastLevelAndNode ? '0px' : 'calc(105px + 0.2rem)'
      };
      width: 2rem;
    }

    &::after {
      content: '';
      display: block;
      border-bottom: 2px solid #A2ADB5;
      border-left: 2px solid #A2ADB5;
      height: 51px;
      width: 2rem;
      border-radius: 0 0 0 1.7rem;
      position: absolute;
      top: 0;
      left: 0;
    }
  `}

  .prev-line {
    display: ${(props) =>
      props.isNodeInTheFirstLevel //|| props.verifyIfIsTheLastLevelAndNode
        ? 'none'
        : 'block'};
    height: 100%;
    width: 2px;
    background-color: #a2adb5;
    position: absolute;
    top: 0;
  }

  .node-expand-wrapper {
    min-width: 30px;
  }

  .node-content-wrapper {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
  }

  .node-action-buttons button {
    padding-top: 0;
  }

  p {
    font-size: 1rem;
    text-align: center;
    padding: 0;
    margin: 0;
    max-width: 24rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
  }

  svg.expand {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    color: #232323;
  }

  .active {
    transform: rotate(90deg);
  }

  .node-action-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .node-action-buttons svg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    background-color: transparent;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    margin: 0 5px;
  }
`;
