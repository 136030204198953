import React from 'react';

import { Row, Card, Col, CardBody, CardHeader } from 'reactstrap';
import CountUp from 'react-countup';
import { ColorArea } from '../assets/styleds';
import { Pie } from 'react-chartjs-2';
import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { chartPieWithTheLabelsOptions } from '../../../../../assets/ChartsLabelsConfigurations/ChartPieDefaultOptions';
const Search = ({ filteredLabels, filteredData, filteredColors, count }) => {
  Chart.plugins.register(ChartDataLabels);
  Chart.defaults.global.plugins.datalabels = false;

  return (
    <Card id="filteredSearch">
      <CardHeader>
        <h6 className="surtitle">Visão Geral</h6>
        <h5 className="h3 mb-0">Pesquisas Filtradas</h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="4" style={{ alignSelf: 'center' }}>
            <div className="chart">
              <Pie
                data={{
                  labels: [...filteredLabels],
                  datasets: [
                    {
                      data: [...filteredData],
                      backgroundColor: [...filteredColors]
                    }
                  ]
                }}
                options={chartPieWithTheLabelsOptions}
                className="chart-canvas"
                id="chart-doughnut"
              />
            </div>
          </Col>
          <Col md="8" style={{ alignSelf: 'center' }}>
            <Row>
              <Col md="12">
                <h1 className="display-1">
                  <CountUp end={count} />
                </h1>
                <h2 className="text-muted font-weight-light mt--3">
                  Total de pesquisas filtradas
                </h2>
              </Col>
            </Row>
            <hr />
            <Row>
              {filteredData.map((data, index) => {
                return (
                  <Col
                    md="2"
                    key={index}
                    className="mt--3 mb--3"
                    style={{
                      minWidth: '177px',
                      minHeight: '130px'
                    }}
                  >
                    <Row
                      className="row flex-nowrap"
                      style={{
                        minHeight: '100px'
                      }}
                    >
                      <Col
                        md="1"
                        style={{
                          padding: 0
                        }}
                      >
                        <ColorArea color={filteredColors[index]} />
                      </Col>
                      <Col md="11">
                        <h2>{filteredData[index]}</h2>
                        <h3 className="text-muted font-weight-light mt--3">
                          {filteredLabels[index] === '' ||
                          typeof filteredLabels[index] === 'object'
                            ? '-'
                            : filteredLabels[index]}
                        </h3>
                        <h2 className="mt--1 font-weight-light">
                          <CountUp
                            end={parseInt((100 * filteredData[index]) / count)}
                          />
                          %
                        </h2>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default Search;
