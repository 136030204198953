import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Card, CardBody, Button } from 'reactstrap';
import Loader from 'react-loader-spinner';

import { loaderContainerStyle } from 'views/CRM/Lead/list/components/TimelineLeadHistory';
import ModalContactAttemptDetails from '../ContactAttemptDetails';
import ContactAttemptService from '../../services';

export const ContactAttemptTimeline = ({ entityId, entityType }) => {
  const [contactAttempt, setContactAttempt] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openModaContactAttemptInfo, setOpenModaContactAttemptInfo] =
    useState(false);
  const [selectedHistoryItem, setSelectedHistoryItem] = useState(null);

  useEffect(() => {
    if (!entityId) return;
    ContactAttemptService.list(entityId, entityType).then((res) => {
      if (res.data) {
        setContactAttempt(res.data.data.contactAttempts);
      }
    });
  }, [entityId, entityType]);

  return (
    <>
      {loading ? (
        <div style={loaderContainerStyle}>
          <Loader type="TailSpin" color="#9ab744" height={50} width={50} />
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  {contactAttempt && contactAttempt.length >= 1 ? (
                    contactAttempt.map((historyItem) => (
                      <div
                        key={historyItem.id}
                        className="timeline timeline-one-side mx-0"
                        data-timeline-axis-style="dashed"
                        data-timeline-content="axis"
                      >
                        <div className="timeline-block pb-5">
                          {historyItem.contactType === 'CHAT' ? (
                            <span className="timeline-step badge-info">
                              <i className="ni ni-chat-round" />
                            </span>
                          ) : (
                            <span className="timeline-step badge-success">
                              <i className="fas fa-phone" />
                            </span>
                          )}

                          <div
                            className="timeline-content"
                            style={{ maxWidth: '50rem' }}
                          >
                            {historyItem.leadId ? (
                              <p className="text-sm mt-1 mb-0">Lead</p>
                            ) : (
                              <p className="text-sm mt-1 mb-0">Oportunidade</p>
                            )}

                            <small className="text-muted font-weight-bold">
                              {`${new Date(
                                historyItem.contactDate
                              ).toLocaleTimeString('pt-br')} - ${new Date(
                                historyItem.contactDate
                              ).toLocaleDateString('pt-br')}`}
                            </small>

                            {historyItem.userLogin && (
                              <p className="text-sm mt-1 mb-0">
                                Realizado por: {historyItem.userLogin}
                              </p>
                            )}

                            {historyItem.contactType && (
                              <p className="text-sm mt-1 mb-0">
                                Tipo de Contato:{' '}
                                {historyItem.contactType === 'CHAT'
                                  ? 'Chat'
                                  : 'Ligação'}
                              </p>
                            )}

                            {(historyItem.chatId || historyItem.callId) && (
                              <>
                                <Button
                                  disabled={
                                    !historyItem?.callId && !historyItem?.chatId
                                  }
                                  title={
                                    historyItem?.callId === null &&
                                    historyItem?.chatId === null
                                      ? 'Esse protocolo não possui conversas/chamadas relacionadas'
                                      : 'Clique para visualizar as conversas/chamadas relacionadas a este protocolo'
                                  }
                                  color="success"
                                  className="btn-sm"
                                  onClick={() => {
                                    setSelectedHistoryItem(historyItem);
                                    setOpenModaContactAttemptInfo(true);
                                  }}
                                >
                                  Dados do Atendimento
                                </Button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm mt-1 mb-0 text-center">
                      Não há nenhuma informação no histórico a ser mostrada.
                    </p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
      {selectedHistoryItem && openModaContactAttemptInfo && (
        <ModalContactAttemptDetails
          isModalOpen={openModaContactAttemptInfo}
          onModalToggle={setOpenModaContactAttemptInfo}
          contactAttemptInfo={selectedHistoryItem}
          setLoading={setLoading}
        />
      )}
    </>
  );
};
