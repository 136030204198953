import React from 'react';

import { Row, Card } from 'reactstrap';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';

const PauseTable = (props) => {
  return (
    <>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <ToolkitProvider
                data={props.showReports}
                keyField="_id"
                columns={[
                  {
                    dataField: 'tipoPausa.nome',
                    text: 'Tipo',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'usuario.nome',
                    text: 'Usuário',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'isClosed',
                    text: 'Encerrado?',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      if (Row) {
                        return 'Encerrado';
                      } else {
                        return 'Não Encerrado';
                      }
                    }
                  },
                  {
                    dataField: 'inicio_pausa',
                    text: 'Início',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      const date = new Date(Row);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'encerramento_pausa',
                    text: 'Fim de Pausa',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      const date = new Date(Row);
                      const verifielDate = date.toString();

                      if (verifielDate !== 'Invalid Date') {
                        return `${date.toLocaleDateString(
                          'pt-br'
                        )} ${date.toLocaleTimeString('pt-br')}`;
                      } else {
                        return 'Atendente em pausa';
                      }
                    }
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default PauseTable;
