import React from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { barChartConfig } from '../assets';

const CloseGraphic = ({ closedData }) => {
  return (
    <Row>
      <Col xl="12">
        <Card>
          <CardHeader>
            <h6 className="surtitle">Visão geral</h6>
            <h5 className="h3 mb-0">
              Conversas encerradas por atendente no mês
            </h5>
          </CardHeader>
          <CardBody>
            <div className="chart">
              <Bar
                data={closedData.data}
                options={barChartConfig(false).options}
                className="chart-canvas"
                id="chart-bars"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default CloseGraphic;
