import React, { useState } from 'react';
import { Row, Col } from 'reactstrap';
import { useForm } from 'react-hook-form';

import SupervisorPanelService from '../../service/index';
import { ButtonChat } from 'views/ChatConfiguration/components/buttons';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useCallback } from 'react';

const PauseUnpauseModal = ({
  dataAgent,
  modifyStateModal,
  selectedPauseId,
  namePause
}) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { setValue } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  function setStateModal(event) {
    event.preventDefault();
    modifyStateModal(false);
  }

  const handleUnpause = useCallback(async ({ agentCode }) => {
    try {
      await SupervisorPanelService.handleUnpause({ agentCode });
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar tirar o agente de pausa.', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  }, []);

  const handlePause = useCallback(async ({ agentCode, pause_id }) => {
    try {
      await SupervisorPanelService.handlePause({
        agentCode,
        pause_id
      });
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar pausar o agente.', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  }, []);

  const togglePause = async (event, agentCode, selectedPauseId) => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 2000);

    if (dataAgent.paused) {
      await handleUnpause({ agentCode });
      setValue('pause_id', '');
      setStateModal(event);
    } else {
      await handlePause({ agentCode, pause_id: selectedPauseId });
      setStateModal(event);
    }
  };

  return (
    <div>
      <h2>{`Você deseja ${dataAgent.paused ? 'retirar' : 'colocar'} a pausa ${
        namePause[0]?.name || ''
      } ${dataAgent.paused ? 'do' : 'no'} ramal ${
        dataAgent.extension
      } para o usuário ${dataAgent.user}?`}</h2>
      <Row className="row">
        <Col md="12" className="button">
          <ButtonChat
            action={(event) => {
              togglePause(event, dataAgent.agentCode, selectedPauseId);
            }}
            name={'Confirmar'}
            color={systemConfiguration.confirmation_button_color}
          />
          <ButtonChat
            action={(event) => setStateModal(event)}
            name={'Voltar'}
            color={systemConfiguration.cancelation_button_color}
          />
        </Col>
      </Row>
    </div>
  );
};

export default PauseUnpauseModal;
