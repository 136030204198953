import React, { useState, useEffect } from 'react';
import { CardBody, Card } from 'reactstrap';
import { FiArrowLeft } from 'react-icons/fi';
import WaitingCallItem from './WaitingCallItem';

import {
  SideCardContainer,
  ButtonToggleContainer,
  ToggleButton,
  ToggleButtonText
} from './styled';

const styles = {
  cardContainer: {
    width: '16rem'
  },
  card: {
    height: '100vh',
    overflowY: 'scroll',
    borderLeft: '1px solid #797979'
  }
};

const ListWatingCalls = ({ watingCalls = [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (watingCalls.length === 0) {
      setIsOpen(false);
    }
  }, [watingCalls]);

  const groupByQueue = () => {
    const itemsDTO = {};
    watingCalls.forEach((item) => {
      if (!itemsDTO[item.queueName]) {
        itemsDTO[item.queueName] = [];
      }
      itemsDTO[item.queueName].push(item);
    });

    return itemsDTO;
  };

  const watingCallsGroupedByQueue = Object.entries(groupByQueue()).sort(
    (arrA, arrB) => {
      if (arrA[0].toLowerCase() > arrB[0].toLowerCase()) return 1;
      if (arrA[0].toLowerCase() < arrB[0].toLowerCase()) return -1;
      return 0;
    }
  );

  const toggle = () => setIsOpen((isCurrentOpen) => !isCurrentOpen);

  return (
    <SideCardContainer isOpen={isOpen}>
      <ButtonToggleContainer>
        {watingCalls.length > 0 && (
          <ToggleButton onClick={toggle}>
            <FiArrowLeft
              className="mb-2"
              size="12"
              style={{
                transform: `rotate(${isOpen ? '180deg' : '0deg'})`,
                transition: 'transform 0.6s'
              }}
            />
            <ToggleButtonText>Em Espera</ToggleButtonText>
          </ToggleButton>
        )}
      </ButtonToggleContainer>
      <div style={styles.cardContainer}>
        <Card style={styles.card}>
          <CardBody>
            <div>
              <p className="font-weight-bold">Chamadas em Espera</p>

              {watingCallsGroupedByQueue &&
                watingCallsGroupedByQueue.length > 0 &&
                watingCallsGroupedByQueue.map(([queue, calls]) => (
                  <div key={queue}>
                    <span
                      style={{
                        display: 'inline-block',
                        fontWeight: 'bold',
                        marginTop: '0.6rem'
                      }}
                    >
                      {queue}
                    </span>
                    {(calls || []).map(({ caller, duration }) => (
                      <WaitingCallItem
                        key={caller}
                        caller={caller}
                        duration={duration}
                      />
                    ))}
                  </div>
                ))}
            </div>
          </CardBody>
        </Card>
      </div>
    </SideCardContainer>
  );
};

export default ListWatingCalls;
