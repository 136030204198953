import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { dateRules, reportOptions, wordCloudScoreOptions } from './assets';
import { ImageWordCloud, ContainerWordCloud } from './styled';
import WordCloudScoreService from './service';
import UserService from '../../../User/service';
import StatusService from '../../../status/service';
import IssueService from '../../../Issue/service';
import ProductService from '../../../Product/service';
import SectorService from '../../../Sector/service';
import QueueService from '../../../Queue/service';
import TagService from '../../../TagsChat/service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';

const ConversationReport = () => {
  const { control, handleSubmit, errors, setValue, watch, reset, getValues } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [isLoadWordCloud, setIsLoadWordCloud] = useState(false);
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reportType, setReportType] = useState(reportOptions[0]);
  const [imageWordCloud, setImageWordCloud] = useState();

  async function loadWordCloud(data) {
    setIsLoadWordCloud(true);
    setFlag(false);
    await WordCloudScoreService.getWordCloudScore({
      dataFinal: data.dataFinal,
      dataInicial: data.dataInicial,
      scoreRange: data.scoreRange
    })
      .then((res) => {
        const img = res.data.nuvemDePalavras;
        if (res.data.nuvemDePalavras === '' || res.data.lengthMensagens === 1) {
          setFlag(true);
          setImageWordCloud(null);

          setIsLoadWordCloud(false);
        } else {
          setImageWordCloud('data:image/png;base64,' + img);
          setIsLoadWordCloud(false);
          setFlag(false);
        }

        console.log(res.data);
      })
      .catch((err) => {
        setFlag(true);
        setIsLoadWordCloud(false);
        console.log(err);
      });
  }

  const clearData = () => {
    setImageWordCloud('');
  };

  const toISOFormat = (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');

    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${YYYY}-${MM}-${DD}`;
  };

  const getDataDTO = (data) => {
    let formatedData;

    formatedData = {
      ...data,
      dataInicial: `${toISOFormat(data.dataInicial)}`,
      dataFinal: `${toISOFormat(data.dataFinal)}`
    };
    if (!formatedData.assunto) delete formatedData.assunto;
    if (!formatedData.setor) delete formatedData.setor;
    if (!formatedData.produto) delete formatedData.produto;
    if (!formatedData.atendente) delete formatedData.atendente;
    if (!formatedData.encerradaPor) delete formatedData.encerradaPor;
    if (!formatedData.fila) delete formatedData.fila;
    if (!formatedData.atendimentoBot) delete formatedData.atendimentoBot;
    if (!formatedData.canal) delete formatedData.canal;
    if (!formatedData.cpf) delete formatedData.cpf;
    if (!formatedData.email) delete formatedData.email;
    if (!formatedData.isMobile) delete formatedData.isMobile;
    if (!formatedData.satisfacao) delete formatedData.satisfacao;
    if (!formatedData.nome) delete formatedData.nome;
    if (!formatedData.situacao) delete formatedData.situacao;
    if (!formatedData.status) delete formatedData.status;
    if (!formatedData.sucessoAtendimento)
      delete formatedData.sucessoAtendimento;
    if (!formatedData.encerradaPor) delete formatedData.encerradaPor;
    if (!formatedData.tag) delete formatedData.tag;
    if (!formatedData.conteudoDaMensagem)
      delete formatedData.conteudoDaMensagem;
    if (!formatedData.telefone) delete formatedData.telefone;
    else {
      formatedData = {
        ...formatedData,
        telefone: formatedData.telefone.replace(/\D+/g, '')
      };
    }

    if (formatedData.group === '' && reportType.value === 2) {
      formatedData = {
        ...formatedData,
        group: 'fila'
      };
    } else {
      if (!formatedData.group) delete formatedData.group;
    }

    delete formatedData.reportType;
    delete formatedData.disable;
    return formatedData;
  };

  const onSubmit = (data) => {
    clearData();

    try {
      const formatedData = getDataDTO(data);
      loadWordCloud(formatedData);
      //const formatedType = getFormatedType(reportType.value);
      //getReports(formatedType, formatedData, 1, true);
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const cleanFilter = () => {
    reset({
      dataInicial: new Date().toLocaleDateString('pt-br'),
      dataFinal: new Date().toLocaleDateString('pt-br'),
      scoreRange: ''
    });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Nuvem de Palavras por Score</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      <Form
                        className="needs-validation"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Row className="mb-3"></Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dataInicial"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dataInicial"
                              rules={dateRules}
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dataInicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Data final*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dataFinal"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dataFinal"
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                              rules={{
                                ...dateRules,
                                validate: (value) => {
                                  if (
                                    new Date(toISOFormat(value, true)) <
                                    new Date(
                                      toISOFormat(
                                        getValues()['dataInicial'],
                                        true
                                      )
                                    )
                                  ) {
                                    return 'A data final não pode ser anterior à inicial!';
                                  } else {
                                    return true;
                                  }
                                }
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dataFinal"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="3">
                            <label className="form-control-label">
                              Filtrar por score:
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={wordCloudScoreOptions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="scoreRange"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="scoreRange"
                              defaultValue=""
                            />
                          </Col>
                        </Row>
                        <br />
                        <Button
                          style={buttonStyle(systemConfiguration.primary_color)}
                          type="submit"
                          disabled={isLoadWordCloud}
                          //onClick={() => loadWordCloud()}
                        >
                          {isLoadWordCloud ? (
                            <Row className="justify-content-md-center">
                              <Loader
                                type="TailSpin"
                                color={'white'}
                                height={18}
                                width={78}
                              />
                            </Row>
                          ) : (
                            'Buscar'
                          )}
                        </Button>
                        <Button
                          onClick={cleanFilter}
                          style={buttonStyle(
                            systemConfiguration.cancelation_button_color
                          )}
                        >
                          Limpar
                        </Button>
                      </Form>
                    </>
                  )}

                  {isLoadWordCloud ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    imageWordCloud && (
                      <Row>
                        <Col md="12">
                          <ContainerWordCloud>
                            <ImageWordCloud src={imageWordCloud} />
                          </ContainerWordCloud>
                        </Col>
                      </Row>
                    )
                  )}
                  {flag == true && (
                    <Row>
                      <Col md="12">
                        <ContainerWordCloud>
                          <p>Conversas não encontradas!</p>
                        </ContainerWordCloud>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConversationReport;
