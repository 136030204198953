import React from 'react';
import { useSelector } from 'react-redux';
import { TitlePreview } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/styled';
import { VideoDiv, Video } from './styles';
export function VideoPreview({ video, title = true }) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  return (
    <>
      {video && (
        <div>
          <Video
            autoPlay={false}
            autostart="0"
            src={video.url}
            alt={video.nameFile}
            title={video.nameFile}
            controls
          />
          {title && (
            <TitlePreview
              colorText={systemConfiguration.primary_color}
              haveBorderTop={true}
            >
              <h4 className="display-6">{video.nameFile}</h4>
            </TitlePreview>
          )}
        </div>
      )}
    </>
  );
}
