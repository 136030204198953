import styled from 'styled-components';

//.user-message
//.user-message:after
export const AdminContent = styled.div`
  position: relative;
  margin-bottom: 20px;
  margin-left: calc(100% - 62%);
  padding: 10px 10px 30px 10px;
  color: white;
  background-color: #ff0000;
  width: 60%;
  font-weight: 400;
  min-height: 100px;
  text-align: left;
  border-radius: 10px;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    color: white;
    border-bottom: 15px solid #ff0000;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    bottom: 0;
    right: -15px;
  }

  .g4-link {
    word-wrap: break-word;
  }
`;
