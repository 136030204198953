import React, { useState, useEffect, useCallback } from 'react';
import ProtocolService from '../../../../../../../views/Protocol/service';
import { useForm, Controller } from 'react-hook-form';

import { ErrorMessage } from '@hookform/error-message';
import { FiPlus, FiDelete } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { fuctions } from '../../../../../../../views/Protocol/updateForm/utils/functions';
import Loader from 'react-loader-spinner';

import ModalStatus from '../../../../../../../views/Protocol/updateForm/components/ModalStatus';
import ModalUser from '../../../../../../../views/Protocol/updateForm/components/ModalUser';
import ModalReason from '../../../../../../../views/Protocol/updateForm/components/ModalReason';
import ModalSubReason from '../../../../../../../views/Protocol/updateForm/components/ModalSubReason';
import ModalComment from '../../../../../../../views/Protocol/updateForm/components/ModalUpdateComment';
import ModalClient from '../../../../../../../views/Protocol/updateForm/components/ModalClient';

import ListComments from '../../../../../../../views/Protocol/updateForm/components/Comments/CommentsList';
import CommentsForm from '../../../../../../../views/Protocol/updateForm/components/Comments/CommentsForm';
import FlexSelect from 'components/Inputs/FlexSelect';

import DatePicker from '../../../../../../../components/Inputs/DatePicker';
import HourMaskMMHHSS from 'components/Inputs/HourMaskMMHHSS.js';
import CurrencyMask from 'components/Inputs/CurrencyMask';
import { formatCurrency } from 'utils/fuctions/formatCurrency';
import { parseIntFromString } from 'utils/fuctions/parseIntFromString';

import {
  Row,
  Card,
  Form,
  Col,
  Input,
  Button,
  CardHeader,
  CardBody
} from 'reactstrap';

import {
  validateFields,
  definedShowName
} from '../../../../../../../views/Protocol/form/assets/functions';
import { useProtocolDefaultFieldsSettings } from '../../../../../../../views/fieldConfigs/hooks/useProtocolDefaultFieldsSettings';
import { useProtocolFieldsByRecordType } from '../../../../../../../views/fieldConfigs/hooks/useProtocolFieldsByRecordType';
import { ProtocolAttachments } from 'views/Protocol/updateForm/components/Attachments';

const { formatOptionsToSelect, formatDataUpdateProtocol, dateRules } =
  fuctions();

const UpdateProtocolForModal = ({
  id,
  recordType,
  showSaveProtocol,
  setShowSaveProtocol,
  setProtocolSaveSucess,
  setProtocolUpdate,
  onTheServiceScreen = false
}) => {
  const [loading, setLoading] = useState(false);
  const [protocol, setProtocol] = useState();
  console.log('protocol: ', protocol);
  const [openModalStatus, setOpenModalStatus] = useState(false);
  const [openModalUser, setOpenModalUser] = useState(false);
  const [openModalReason, setOpenModalReason] = useState(false);
  const [openModalSubReason, setOpenModalSubReason] = useState(false);
  const [openModalUpdateComment, setOpenModalUpdateComment] = useState(false);
  const [openModalUpdateClient, setOpenModalUpdateClient] = useState(false);
  const [comments, setComments] = useState([]);
  const [listComments, setListComments] = useState([]);
  const [dataCommentUpdate, setDataCommentUpdate] = useState([]);
  const [verifyIsArrayOfErrosEmpty, setVerifyIsArrayOfErrosEmpty] =
    useState(true);
  const {
    control,
    unregister,
    errors,
    handleSubmit,
    setValue,
    formState,
    watch
  } = useForm();

  const { protocolDefaultFieldsDisplaySettings } =
    useProtocolDefaultFieldsSettings(recordType);

  const { protocolCustomFields, getCurrencyFields } =
    useProtocolFieldsByRecordType(recordType);

  const getProtocolById = useCallback(() => {
    if (id) {
      setLoading(true);

      ProtocolService.getByIdAndRecordType(id, recordType)
        .then((res) => {
          const protocolData = res.data.content;
          setProtocol(protocolData);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao carregar o protocolo', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getCommnents = useCallback(() => {
    if (id) {
      ProtocolService.getAllComments(id)
        .then((res) => {
          setListComments(res.data.content);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [id]);

  useEffect(getProtocolById, [getProtocolById]);
  useEffect(getCommnents, [getCommnents]);

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const addControlComment = () => {
    const length = comments.length;
    if (length >= 5) return;
    const commentsCont = `device${comments.length}`;
    const commentsControl = {
      comments: commentsCont
    };

    setComments([...comments, commentsControl]);
  };

  const removeControlComment = () => {
    const currentValues = [...comments];
    const deleted = currentValues.pop();
    unregister(deleted);
    setComments(currentValues);
  };

  const deleteComment = (idComment) => {
    if (!window.confirm('Apagar comentário?')) {
      return;
    } else {
      ProtocolService.deleteComment(id, idComment)
        .then((res) => {
          if (res.data.status === 'deleted'.toUpperCase()) {
            const message = 'Comentário excluido com sucesso';
            toast.success(message, {
              autoClose: 3000,
              closeOnClick: true
            });
            getCommnents();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const onSubmit = async (data) => {
    try {
      const dataFormaSubmit = {
        description: data.description
      };
      await ProtocolService.createCommentProtocol(id, dataFormaSubmit);
      const message = 'Comentário adicionado com sucesso';
      toast.success(message, {
        autoClose: 3000,
        closeOnClick: true
      });
      getCommnents();
      setComments([]);
    } catch (err) {
      toast.error('Erro ao adicionar comentário');
    }
  };

  useEffect(() => {
    const formFieldsWithErrors = Object.keys(formState.errors).map((key) => {
      return key;
    });
    const allFormFields = watch();
    const isAnErrorInAnyFieldsInTheForm = Object.keys(allFormFields).map(
      (key) => {
        if (formFieldsWithErrors.includes(key)) {
          return true;
        }
      }
    );
    const isAnErrorInAnyFieldsInTheFormBoolean =
      isAnErrorInAnyFieldsInTheForm.some((value) => value === true);

    setVerifyIsArrayOfErrosEmpty(isAnErrorInAnyFieldsInTheFormBoolean);
  }, [formState, setVerifyIsArrayOfErrosEmpty, watch]);

  const updateProtocol = async (data) => {
    try {
      const numericFieldNames = protocolCustomFields
        ?.filter((f) => f.type === 'NUMERIC')
        .map((f) => f.name);

      numericFieldNames &&
        numericFieldNames.forEach((name) => {
          if (data[name]) {
            data[name] = Number(data[name]);
          }
        });

      const currencyFieldsName = getCurrencyFields()?.map(
        (field) => field.name
      );

      currencyFieldsName &&
        currencyFieldsName.forEach((item) => {
          const fieldValue = data[item];

          if (fieldValue) {
            data[item] = parseIntFromString(fieldValue);
          }
        });

      const dataSubmit = formatDataUpdateProtocol(data);

      // create a object without null values
      const dataSubmitWithoutNull = Object.fromEntries(
        Object.entries(dataSubmit).filter(([_, v]) => v != null)
      );

      if (!verifyIsArrayOfErrosEmpty) {
        await ProtocolService.updateByRecordType(
          id,
          recordType,
          dataSubmitWithoutNull
        )
          .then(() => {
            toast.info('Protocolo Atualizado com sucesso');
            getProtocolById();
            setProtocolUpdate(protocol);
            setProtocolSaveSucess(true);
            setShowSaveProtocol(false);
          })
          .catch((err) => {
            setProtocolUpdate(err);
            setProtocolSaveSucess(false);
            setShowSaveProtocol(false);

            toast.error(
              `Erro ao atualizar protocolo, ${err.response.data.message}`,
              {
                autoClose: 3000,
                closeOnClick: true
              }
            );
          });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUpdateComment = (dataComment) => {
    setOpenModalUpdateComment(true);
    setDataCommentUpdate(dataComment);
  };

  const setDefaultValueDynamicField = (FieldName) => {
    for (let [key, value] of Object.entries(protocol)) {
      if (key === FieldName) return value || '';
    }
  };

  useEffect(() => {
    setShowSaveProtocol(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyIsArrayOfErrosEmpty]);

  useEffect(() => {
    let memoryLeakController = true;
    if (!showSaveProtocol) return;
    if (!memoryLeakController) return;
    handleSubmit(updateProtocol)();

    return () => {
      memoryLeakController = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSaveProtocol]);

  return (
    <>
      <Card
        style={{
          width: '100%'
        }}
      >
        <CardHeader>
          {protocol && (
            <h3 className="mb-0">Nº. do Protocolo {protocol.number}</h3>
          )}
        </CardHeader>

        <CardBody>
          {loading ? (
            <Row className="justify-content-md-center">
              <Loader
                type="TailSpin"
                color="#35ac50"
                height={100}
                width={100}
              />
            </Row>
          ) : (
            protocol && (
              <>
                <Form
                  className="needs-validation formUpdateProtocol"
                  onSubmit={handleSubmit(updateProtocol)}
                >
                  {protocolDefaultFieldsDisplaySettings &&
                    !!Object.keys(protocolDefaultFieldsDisplaySettings)
                      .length && (
                      <>
                        <Row>
                          {protocolDefaultFieldsDisplaySettings.title
                            .isActive && (
                            <Col
                              md={
                                protocolDefaultFieldsDisplaySettings.telephone
                                  .isActive
                                  ? { size: 8 }
                                  : { size: 12 }
                              }
                            >
                              <label className="form-control-label">
                                {definedShowName(
                                  'Titulo',
                                  protocolDefaultFieldsDisplaySettings.title
                                    .isRequired
                                )}
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                name="title"
                                defaultValue={protocol?.title}
                                disabled={false}
                                rules={
                                  protocolDefaultFieldsDisplaySettings.title
                                    .isRequired
                                }
                              />
                              <ErrorMessage
                                errors={errors}
                                name="title"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          )}
                          {protocolDefaultFieldsDisplaySettings.telephone
                            .isActive && (
                            <Col
                              md={
                                protocolDefaultFieldsDisplaySettings.title
                                  .isActive
                                  ? { size: 4 }
                                  : { size: 12 }
                              }
                            >
                              <label className="form-control-label">
                                Telefone
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                name="telephone"
                                defaultValue={protocol?.telephone}
                                disabled={true}
                              />
                            </Col>
                          )}
                        </Row>
                        <Row>
                          {protocolDefaultFieldsDisplaySettings.description
                            .isActive && (
                            <Col md={{ size: 12 }} className="mt-2">
                              <label className="form-control-label">
                                {definedShowName(
                                  'Descrição',
                                  protocolDefaultFieldsDisplaySettings
                                    .description.isRequired
                                )}
                              </label>
                              <Controller
                                as={<Input type="textarea" rows="6" />}
                                control={control}
                                name="description"
                                defaultValue={protocol?.description || ''}
                                disabled={false}
                                rules={validateFields(
                                  protocolDefaultFieldsDisplaySettings
                                    .description.isRequired
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="description"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </Col>
                          )}
                        </Row>
                        <Row>
                          {protocolCustomFields &&
                            protocolCustomFields.length > 0 &&
                            protocolCustomFields
                              .filter((f) => f.type === 'TEXT')
                              .map((protocolField, index) => {
                                const inputTextLabel = `
                                  ${protocolField.label}
                                  ${
                                    protocolField.recordTypesConfig.isRequired
                                      ? '*'
                                      : ''
                                  }
                                `;

                                return (
                                  <Col
                                    md={{ size: 6 }}
                                    key={index}
                                    className="mt-2"
                                  >
                                    <label className="form-control-label">
                                      {inputTextLabel}
                                    </label>
                                    <Controller
                                      as={Input}
                                      control={control}
                                      defaultValue={setDefaultValueDynamicField(
                                        protocolField.name
                                      )}
                                      rules={{
                                        required: protocolField
                                          .recordTypesConfig.isRequired
                                          ? 'Campo obrigatório'
                                          : false
                                      }}
                                      name={protocolField.name}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name={protocolField.name}
                                      render={({ message }) =>
                                        errorFormMessage(message)
                                      }
                                    />
                                  </Col>
                                );
                              })}
                          {protocolCustomFields &&
                            protocolCustomFields.length > 0 &&
                            protocolCustomFields
                              .filter((f) => f.type === 'NUMERIC')
                              .map((protocolFieldNumber, index) => {
                                const inputNumberLabel = `
                                      ${protocolFieldNumber.label}
                                      ${
                                        protocolFieldNumber.recordTypesConfig
                                          .isRequired
                                          ? '*'
                                          : ''
                                      }
                                      `;
                                return (
                                  <Col
                                    md={{ size: 6 }}
                                    key={index}
                                    className="mt-2"
                                  >
                                    <label className="form-control-label">
                                      {inputNumberLabel}
                                    </label>
                                    <Controller
                                      as={Input}
                                      step="false"
                                      type="number"
                                      control={control}
                                      defaultValue={setDefaultValueDynamicField(
                                        protocolFieldNumber.name
                                      )}
                                      rules={{
                                        required: protocolFieldNumber
                                          .recordTypesConfig.isRequired
                                          ? 'Campo obrigatório'
                                          : false
                                      }}
                                      name={protocolFieldNumber.name}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name={protocolFieldNumber.name}
                                      render={({ message }) =>
                                        errorFormMessage(message)
                                      }
                                    />
                                  </Col>
                                );
                              })}
                          {protocolCustomFields &&
                            protocolCustomFields.length > 0 &&
                            protocolCustomFields
                              .filter((f) => f.type === 'DATE')
                              .map((protocolField, index) => {
                                const selectBoxLabel = `
                                  ${protocolField.label}
                                  ${
                                    protocolField.recordTypesConfig.isRequired
                                      ? '*'
                                      : ''
                                  }
                                `;

                                return (
                                  <Col
                                    md={{ size: 6 }}
                                    key={index}
                                    className="mt-2"
                                  >
                                    <label className="form-control-label">
                                      {selectBoxLabel}
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <DatePicker
                                          fieldName={protocolField.name}
                                          valueController={setValue}
                                          defaultValue={props.value}
                                        />
                                      )}
                                      control={control}
                                      name={protocolField.name}
                                      rules={dateRules(
                                        protocolField.recordTypesConfig
                                          .isRequired
                                      )}
                                      defaultValue={
                                        new Date(
                                          setDefaultValueDynamicField(
                                            protocolField.name
                                          )
                                        ).toLocaleDateString('pt-br') ===
                                        'Invalid Date'
                                          ? null
                                          : new Date(
                                              setDefaultValueDynamicField(
                                                protocolField.name
                                              )
                                            ).toLocaleDateString('pt-br')
                                      }
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name={protocolField.name}
                                      render={({ message }) =>
                                        errorFormMessage(message)
                                      }
                                    />
                                  </Col>
                                );
                              })}
                          {protocolCustomFields &&
                            protocolCustomFields.length > 0 &&
                            protocolCustomFields
                              .filter((f) => f.type === 'LIST')
                              .map((boxField, index) => {
                                const dateFieldLabel = `
                                  ${boxField.label}
                                  ${
                                    boxField.recordTypesConfig.isRequired
                                      ? '*'
                                      : ''
                                  }
                                `;

                                return (
                                  <Col
                                    md={{ size: 6 }}
                                    key={index}
                                    className="mt-2"
                                  >
                                    <label className="form-control-label">
                                      {dateFieldLabel}
                                    </label>

                                    <Controller
                                      render={(props) => (
                                        <FlexSelect
                                          dataOptions={formatOptionsToSelect(
                                            boxField.options
                                          )}
                                          isMulti={false}
                                          value={props.value || ''}
                                          isClearable={true}
                                          closeMenuOnSelect={true}
                                          valueController={setValue}
                                          fieldName={boxField.name}
                                          labelName="value"
                                          valueName="value"
                                        />
                                      )}
                                      defaultValue={setDefaultValueDynamicField(
                                        boxField.name
                                      )}
                                      control={control}
                                      name={boxField.name}
                                      rules={{
                                        required: boxField.recordTypesConfig
                                          .isRequired
                                          ? 'Campo obrigatório'
                                          : false
                                      }}
                                    />

                                    <ErrorMessage
                                      errors={errors}
                                      name={boxField.name}
                                      render={({ message }) =>
                                        errorFormMessage(message)
                                      }
                                    />
                                  </Col>
                                );
                              })}
                          {protocolCustomFields &&
                            protocolCustomFields.length > 0 &&
                            protocolCustomFields
                              .filter((f) => f.type === 'CURRENCY')
                              .map((protocolCurrencyField, index) => {
                                const inputNumberLabel = `
                                      ${protocolCurrencyField.label}
                                      ${
                                        protocolCurrencyField.recordTypesConfig
                                          .isRequired
                                          ? '*'
                                          : ''
                                      }
                                      `;
                                const value = setDefaultValueDynamicField(
                                  protocolCurrencyField.name
                                );
                                const parsedValue = formatCurrency(value);

                                return (
                                  <Col
                                    md={{ size: 6 }}
                                    key={index}
                                    className="mt-2"
                                  >
                                    <label className="form-control-label">
                                      {inputNumberLabel}
                                    </label>
                                    <Controller
                                      render={(props) => {
                                        return (
                                          <CurrencyMask
                                            valueController={setValue}
                                            value={props.value}
                                            fieldName={
                                              protocolCurrencyField.name
                                            }
                                          />
                                        );
                                      }}
                                      control={control}
                                      name={protocolCurrencyField.name}
                                      rules={{
                                        required: protocolCurrencyField
                                          .recordTypesConfig.isRequired
                                          ? 'Campo obrigatório'
                                          : false,
                                        pattern: {
                                          value:
                                            /^(R\$\s)([0-9]{1,3}\.?)+(\,[0-9]{2})$/,
                                          message: 'Valor inválido!'
                                        }
                                      }}
                                      defaultValue={value ? parsedValue : ''}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name={protocolCurrencyField.name}
                                      render={({ message }) =>
                                        errorFormMessage(message)
                                      }
                                    />
                                  </Col>
                                );
                              })}
                          {protocolCustomFields &&
                            protocolCustomFields.length > 0 &&
                            protocolCustomFields
                              .filter((f) => f.type === 'HOUR')
                              .map((protocolHourField, index) => {
                                const inputNumberLabel = `
                                      ${protocolHourField.label}
                                      ${
                                        protocolHourField.recordTypesConfig
                                          .isRequired
                                          ? '*'
                                          : ''
                                      }
                                      `;
                                return (
                                  <Col
                                    md={{ size: 6 }}
                                    key={index}
                                    className="mt-2"
                                  >
                                    <label className="form-control-label">
                                      {inputNumberLabel}
                                    </label>
                                    <Controller
                                      render={(props) => (
                                        <HourMaskMMHHSS
                                          valueController={setValue}
                                          fieldName={protocolHourField.name}
                                          value={props.value}
                                          placeholder="08:00:00"
                                        />
                                      )}
                                      control={control}
                                      name={protocolHourField.name}
                                      rules={{
                                        required: protocolHourField
                                          .recordTypesConfig.isRequired
                                          ? 'Campo obrigatório'
                                          : false,
                                        pattern: {
                                          value:
                                            /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/,
                                          message: 'Hora inválida!'
                                        }
                                      }}
                                      defaultValue={setDefaultValueDynamicField(
                                        protocolHourField.name
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name={protocolHourField.name}
                                      render={({ message }) =>
                                        errorFormMessage(message)
                                      }
                                    />
                                  </Col>
                                );
                              })}
                        </Row>

                        {!onTheServiceScreen && (
                          <Row>
                            <Col md="4" style={{ paddingTop: '10px' }}>
                              <Button
                                color="primary"
                                type="submit"
                                title="Atualizar dados do protocolo"
                              >
                                Atualizar
                              </Button>
                            </Col>
                          </Row>
                        )}

                        <Row className="mt-4">
                          {protocolDefaultFieldsDisplaySettings.client_id
                            .isActive && (
                            <>
                              <Col
                                md={
                                  protocolDefaultFieldsDisplaySettings
                                    .service_channel.isActive
                                    ? { size: 5 }
                                    : { size: 11 }
                                }
                              >
                                <label className="form-control-label">
                                  {definedShowName(
                                    'Contato',
                                    protocolDefaultFieldsDisplaySettings
                                      .client_id.isRequired
                                  )}
                                </label>
                                <Controller
                                  as={Input}
                                  control={control}
                                  name="client_id"
                                  defaultValue={protocol?.client?.nome || ''}
                                  disabled={true}
                                />
                              </Col>
                              <Col md="1" xl="1" style={{ paddingTop: '41px' }}>
                                <Button
                                  title="Atualizar contato"
                                  size="sm"
                                  color="info"
                                  onClick={() => setOpenModalUpdateClient(true)}
                                >
                                  <li className="fas fa-user-edit"></li>
                                </Button>
                              </Col>
                            </>
                          )}
                          {protocolDefaultFieldsDisplaySettings.service_channel
                            .isActive && (
                            <Col
                              md={
                                protocolDefaultFieldsDisplaySettings.client_id
                                  .isActive
                                  ? { size: 5 }
                                  : { size: 12 }
                              }
                            >
                              <label className="form-control-label">
                                {definedShowName(
                                  'Canal de atendimento',
                                  protocolDefaultFieldsDisplaySettings.telephone
                                    .isRequired
                                )}
                              </label>
                              <Controller
                                as={Input}
                                control={control}
                                name="service_channel"
                                defaultValue={protocol?.service_channel || ''}
                                disabled={true}
                              />
                            </Col>
                          )}
                        </Row>
                        <Row>
                          {protocolDefaultFieldsDisplaySettings.reason_id
                            .isActive && (
                            <>
                              <Col
                                md={
                                  protocolDefaultFieldsDisplaySettings
                                    .subreason_id.isActive
                                    ? { size: 5 }
                                    : { size: 11 }
                                }
                              >
                                <label className="form-control-label">
                                  {definedShowName(
                                    'Motivo',
                                    protocolDefaultFieldsDisplaySettings
                                      .reason_id.isRequired
                                  )}
                                </label>
                                <Controller
                                  as={Input}
                                  control={control}
                                  name="reason_id"
                                  defaultValue={protocol?.reason?.name || ''}
                                  disabled={true}
                                />
                              </Col>
                              <Col md="1" style={{ paddingTop: '41px' }}>
                                <Button
                                  title="Atualizar Motivo"
                                  size="sm"
                                  color="info"
                                  onClick={() => setOpenModalReason(true)}
                                >
                                  <li className="fas fa-user-edit"></li>
                                </Button>
                              </Col>
                            </>
                          )}
                          {protocolDefaultFieldsDisplaySettings.subreason_id
                            .isActive && (
                            <>
                              <Col
                                md={
                                  protocolDefaultFieldsDisplaySettings.reason_id
                                    .isActive
                                    ? { size: 5 }
                                    : { size: 11 }
                                }
                              >
                                <label className="form-control-label">
                                  {definedShowName(
                                    'Submotivo',
                                    protocolDefaultFieldsDisplaySettings
                                      .subreason_id.isRequired
                                  )}
                                </label>
                                <Controller
                                  as={Input}
                                  control={control}
                                  name="subreason_id"
                                  defaultValue={protocol?.subreason?.name || ''}
                                  disabled={true}
                                />
                              </Col>
                              <Col md="1" style={{ paddingTop: '41px' }}>
                                <Button
                                  title="Atualizar Submotivo"
                                  size="sm"
                                  color="info"
                                  onClick={() => setOpenModalSubReason(true)}
                                >
                                  <li className="fas fa-user-edit"></li>
                                </Button>
                              </Col>
                            </>
                          )}
                        </Row>
                        <Row>
                          <Col
                            md={
                              protocolDefaultFieldsDisplaySettings
                                .current_status.isActive
                                ? { size: 5 }
                                : { size: 11 }
                            }
                          >
                            <label className="form-control-label">
                              Responsável*
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="title"
                              defaultValue={
                                protocol?.protocol_responsibles[0]?.usr?.name ||
                                ''
                              }
                              disabled={true}
                            />
                          </Col>
                          <Col md="1" style={{ paddingTop: '41px' }}>
                            <Button
                              title="Atualizar Responsável"
                              size="sm"
                              color="info"
                              onClick={() => setOpenModalUser(true)}
                            >
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Col>
                          {protocolDefaultFieldsDisplaySettings.current_status
                            .isActive && (
                            <>
                              <Col
                                md={
                                  protocolDefaultFieldsDisplaySettings
                                    .current_status.isActive
                                    ? { size: 5 }
                                    : { size: 11 }
                                }
                              >
                                <label className="form-control-label">
                                  {definedShowName(
                                    'Status/Fase',
                                    protocolDefaultFieldsDisplaySettings
                                      .current_status.isRequired
                                  )}
                                </label>
                                <Controller
                                  as={Input}
                                  control={control}
                                  name="current_status"
                                  defaultValue={
                                    protocol?.protocol_statuses[0]?.status
                                      ?.name || ''
                                  }
                                  disabled={true}
                                />
                              </Col>
                              <Col md="1" style={{ paddingTop: '41px' }}>
                                <Button
                                  title="Atualizar Status/Fase"
                                  size="sm"
                                  color="info"
                                  onClick={() => setOpenModalStatus(true)}
                                >
                                  <li className="fas fa-user-edit"></li>
                                </Button>
                              </Col>
                            </>
                          )}
                        </Row>
                      </>
                    )}
                </Form>

                <h3 className="form-control-label mt-2">Anexo(s)</h3>
                <ProtocolAttachments
                  protocolId={protocol?.id}
                  attachments={protocol?.attachments}
                  onAdded={(addedAttachments) => {
                    setProtocol((prev) => ({
                      ...prev,
                      attachments: [...prev.attachments, ...addedAttachments]
                    }));
                  }}
                  onDeleted={(attachmentId) => {
                    const remainAttachments = protocol.attachments.filter(
                      (att) => att.id !== attachmentId
                    );
                    setProtocol((prev) => ({
                      ...prev,
                      attachments: remainAttachments
                    }));
                  }}
                />

                {listComments ? (
                  <ListComments
                    listComments={listComments}
                    handleUpdateComment={handleUpdateComment}
                    deleteComent={deleteComment}
                  />
                ) : (
                  <Row className="justify-content-md-center">
                    <Loader
                      type="TailSpin"
                      color="#35ac50"
                      height={100}
                      width={100}
                    />
                  </Row>
                )}
                <CommentsForm
                  protocol={protocol}
                  onSubmit={onSubmit}
                  comments={comments}
                  errorFormMessage={errorFormMessage}
                />

                <Row style={{ paddingTop: '20px' }}>
                  <Col className="text-right">
                    <Button
                      title="Adicionar novo comentário"
                      color="success"
                      type="button"
                      onClick={addControlComment}
                      disabled={comments.length >= 1}
                    >
                      <FiPlus size={16} /> Adiciona Comentário
                    </Button>
                    {comments.length > 0 && (
                      <Button
                        title="Cancelar adição de novo comentário"
                        color="danger"
                        type="button"
                        onClick={removeControlComment}
                      >
                        <FiDelete size={16} />
                      </Button>
                    )}
                  </Col>
                </Row>
              </>
            )
          )}
        </CardBody>
      </Card>

      {protocol && (
        <>
          {protocolDefaultFieldsDisplaySettings &&
            !!Object.keys(protocolDefaultFieldsDisplaySettings).length && (
              <>
                <ModalStatus
                  recordTypeId={recordType}
                  render={(newStatus) => {
                    protocol.protocol_statuses[0] = newStatus;
                    protocol.current_status = newStatus.id;
                    setValue('current_status', newStatus.name);
                  }}
                  idProtocol={protocol.id}
                  idStatusProtocol={protocol?.protocol_statuses[0]?.status?.id}
                  isModalOpen={openModalStatus}
                  onModalToggle={setOpenModalStatus}
                  unmountOnClose={true}
                  isRequired={
                    protocolDefaultFieldsDisplaySettings?.current_status
                      ?.isRequired || true
                  }
                />
                <ModalUser
                  render={(newUser) => {
                    protocol.current_responsible = newUser.id;
                    setValue('current_responsible', newUser.name);
                  }}
                  idProtocol={protocol.id}
                  idUserProtocol={
                    protocol?.protocol_responsibles[0]?.responsible_id
                  }
                  isModalOpen={openModalUser}
                  onModalToggle={setOpenModalUser}
                  unmountOnClose={true}
                />

                <ModalReason
                  recordTypeId={recordType}
                  render={(newData) => {
                    if (newData.reason) {
                      protocol.reason = newData.reason;
                      protocol.reason_id = newData.reason.id;
                    }
                    if (newData.subreason) {
                      protocol.subreason = newData.subreason;
                      protocol.subreason_id = newData.subreason.id;
                    }
                    setValue('reason_id', newData?.reason?.name);
                    setValue('subreason_id', newData?.subreason?.name);
                  }}
                  idProtocol={protocol.id}
                  isModalOpen={openModalReason}
                  onModalToggle={setOpenModalReason}
                  unmountOnClose={true}
                  isRequired={
                    protocolDefaultFieldsDisplaySettings?.reason_id
                      ?.isRequired || true
                  }
                  reasonIsRequiredSubReason={
                    protocolDefaultFieldsDisplaySettings?.subreason_id
                      ?.isRequired
                  }
                  showSubReason={
                    protocolDefaultFieldsDisplaySettings?.subreason_id?.isActive
                  }
                />
                <ModalSubReason
                  render={(newSubreason) => {
                    protocol.subreason = newSubreason;
                    protocol.subreason_id = newSubreason.id;
                    setValue('subreason_id', newSubreason.name);
                  }}
                  idProtocol={protocol.id}
                  idReasonProtocol={protocol?.reason_id}
                  isModalOpen={openModalSubReason}
                  onModalToggle={setOpenModalSubReason}
                  unmountOnClose={true}
                  isRequired={
                    protocolDefaultFieldsDisplaySettings?.subreason_id
                      ?.isRequired || true
                  }
                />

                <ModalClient
                  render={(newClient) => {
                    protocol.client = newClient;
                    setValue('client_id', newClient.nome);
                  }}
                  emailProtocol={protocol?.email}
                  idProtocol={protocol.id}
                  isModalOpen={openModalUpdateClient}
                  onModalToggle={setOpenModalUpdateClient}
                  unmountOnClose={true}
                  isRequired={
                    protocolDefaultFieldsDisplaySettings?.client_id
                      ?.isRequired || true
                  }
                />
              </>
            )}

          {dataCommentUpdate && (
            <ModalComment
              render={getCommnents}
              idProtocol={protocol.id}
              dataComment={dataCommentUpdate}
              isModalOpen={openModalUpdateComment}
              onModalToggle={setOpenModalUpdateComment}
              unmountOnClose={true}
            />
          )}
        </>
      )}
    </>
  );
};

export default UpdateProtocolForModal;
