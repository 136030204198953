export const resetFields = (
  resetConfig = {},
  customFields = {},
  rhfFormObject
) => {
  const currentInputValues = rhfFormObject.getValues();
  if (Object.keys(currentInputValues).length > 0) {
    for (const key of Object.keys(currentInputValues)) {
      if (resetConfig[key]) {
        rhfFormObject.setValue(key, resetConfig[key]());
      } else {
        if (customFields) {
          const field = customFields.find((field) => field.name === key);
          if (field) {
            rhfFormObject.setValue(key, '');
          }
        }
      }
    }
  }
};
