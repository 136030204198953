export const styles = {
  container: {
    color: '#FFF',
    width: '3.2rem',
    height: '3.2rem',
    borderRadius: '25px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '10px',
    fontWeight: '600',
    fontSize: '20px'
  }
};
