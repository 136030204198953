import React from 'react';
import { Input } from 'reactstrap';

import { formatCurrency } from 'utils/fuctions/formatCurrency';
import { parseIntFromString } from 'utils/fuctions/parseIntFromString';

const CurrencyMask = ({
  valueController,
  fieldName,
  value,
  shouldDisable = false,
  tabIndex = 0
}) => {
  const handleCurrencyChange = (event) => {
    const rawValue = event.target.value;

    const isEmpty = rawValue === '';
    const lastChar = rawValue.slice(-1);
    const isLastCharNumber = !Number.isNaN(parseInt(lastChar, 10));
    const parsedValue = parseIntFromString(rawValue);

    if (isEmpty || parsedValue === 0) {
      return valueController(fieldName, '');
    }

    if (!isLastCharNumber) {
      return;
    }

    const formattedValue = formatCurrency(parsedValue);

    return valueController(fieldName, formattedValue);
  };

  return (
    <Input
      tabIndex={tabIndex}
      type="text"
      name={fieldName}
      value={value}
      placeholder="R$ 0,00"
      disabled={shouldDisable}
      onChange={handleCurrencyChange}
    />
  );
};

export default CurrencyMask;
