import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Row,
  Col
} from 'reactstrap';

import ReactButton from 'components/Buttons/ReactButton';
import { custom_select } from 'assets/styles/multiple_select';
import {
  supervisorAlertTypes,
  getSupervisorAlertTypeLabel
} from '../../../utils/supervisorAlertTypes';

const { FLEXUC_NOTIFICATION, EMAIL_NOTIFICATION, PHONE_CALL_NOTIFICATION } =
  supervisorAlertTypes;

export const AddSupervisorNotificationModal = (props) => {
  const { buttonLabel, onAdded, className } = props;

  const options = useMemo(
    () => [
      {
        value: FLEXUC_NOTIFICATION,
        label: getSupervisorAlertTypeLabel(FLEXUC_NOTIFICATION)
      },
      {
        value: EMAIL_NOTIFICATION,
        label: getSupervisorAlertTypeLabel(EMAIL_NOTIFICATION)
      },
      {
        value: PHONE_CALL_NOTIFICATION,
        label: getSupervisorAlertTypeLabel(PHONE_CALL_NOTIFICATION)
      }
    ],
    []
  );
  const [modal, setModal] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [selectedType, setSelectedType] = useState(null);

  const toggle = useCallback(() => setModal(!modal), [modal]);

  const handleChangeType = useCallback((e) => {
    setSelectedType(e?.value);
    setHasError(false);
  }, []);

  const handleAdd = useCallback(() => {
    if (hasError || !selectedType) {
      setHasError(true);
      return;
    }
    onAdded(selectedType);
    toggle();
  }, [selectedType, hasError, onAdded, toggle]);

  useEffect(() => {
    return () => {
      setHasError(false);
      setSelectedType(null);
    };
  }, [modal]);

  return (
    <div>
      <ReactButton size="sm" btnColor="confirmation" onClick={toggle}>
        {buttonLabel}
      </ReactButton>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        centered
        unmountOnClose
      >
        <ModalHeader toggle={toggle}>
          Adicionar alerta para supervisores
        </ModalHeader>
        <ModalBody className="py-0">
          <Form>
            <Row>
              <Col md="12">
                <label className="form-control-label">
                  Tipo de notificação*
                </label>
                <Select
                  name="type"
                  options={options}
                  isSearchable={false}
                  isClearable={false}
                  defaultValue=""
                  styles={custom_select}
                  placeholder="Selecione..."
                  onChange={(e) => handleChangeType(e)}
                />
                {hasError && (
                  <p className="small text-danger ma-0 pa-0">
                    Campo obrigatório
                  </p>
                )}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <ReactButton btnColor="confirmation" onClick={handleAdd}>
            Adicionar
          </ReactButton>
        </ModalFooter>
      </Modal>
    </div>
  );
};
