import React from 'react';
import { Container } from './styled';
import { useSelector } from 'react-redux';

export function TotalRegister({ totalRegister }) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  return (
    <Container color={systemConfiguration.primary_color}>
      <span>Quantidade de Registros : {totalRegister}</span>
    </Container>
  );
}
