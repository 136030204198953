import { isUndefined } from 'lodash';
import React, { useMemo } from 'react';
import ListTable from './components/ListTable';
import { Accordion, AccordionDetails, withStyles } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';

const AccordionSummary = withStyles({
  root: {
    minHeight: 56,
    '&$expanded': {
      minHeight: 56
    }
  },
  content: {
    '&$expanded': {
      margin: '0 0'
    }
  },
  expanded: {}
})(MuiAccordionSummary);

export const ListView = ({
  protocolList,
  customFields,
  handleUpdate,
  handleCall,
  disableClickToCall,
  protocolDefaultFieldsDisplaySettings,
  status
}) => {
  const protocolSortedByStatus = useMemo(() => {
    if (!protocolList) return {};
    let protocolObject = {};
    protocolList.forEach((item) => {
      let itemStatus = item?.protocol_statuses[0]?.status?.name;
      if (isUndefined(itemStatus)) {
        itemStatus = 'Sem Status';
      }

      if (!protocolObject[itemStatus]) {
        protocolObject[itemStatus] = [];
      }
      protocolObject[itemStatus].push(item);
    });

    return protocolObject;
  }, [protocolList]);

  const render = () => {
    const keys = Object.keys(protocolSortedByStatus);

    return (
      <div>
        {keys.map((key) => {
          const _status = status.find((item) => item.name === key);
          const color = _status?.color ?? '';
          return (
            <>
              <Accordion
                defaultExpanded
                style={{ padding: '5px', boxShadow: 'none' }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  style={{
                    background: 'rgb(233,236,239)',
                    borderLeft: `3px solid ${color ?? 'rgb(233, 236, 239)'}`,
                    borderRadius: '10px'
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center'
                    }}
                  >
                    <strong> {key}</strong>
                    <div
                      style={{
                        minWidth: '20px',
                        maxWidth: '100%',
                        border: `1px solid lightgray`,
                        borderRadius: '40%',
                        padding: '2px',
                        marginLeft: '5px',
                        textAlign: 'center'
                      }}
                    >
                      {protocolSortedByStatus[key].length}
                    </div>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  {' '}
                  <ListTable
                    customFields={customFields}
                    data={protocolSortedByStatus[key] ?? []}
                    handleUpdate={handleUpdate}
                    handleCall={handleCall}
                    disableClickToCall={disableClickToCall}
                    protocolDefaultFieldsDisplaySettings={
                      protocolDefaultFieldsDisplaySettings
                    }
                  ></ListTable>
                </AccordionDetails>
              </Accordion>
            </>
          );
        })}
      </div>
    );
  };

  return render();
};
