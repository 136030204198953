import React from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

const CardItem = ({ title, text, icon }) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const primary_color = systemConfiguration.primary_color;
  return (
    <Card
      className="card-stats"
      style={{
        backgroundColor: primary_color
      }}
    >
      <CardBody>
        <Row>
          <div className="col mt-3 mb-3">
            <CardTitle tag="h5" className="text-uppercase text-white mb-0">
              {title}
            </CardTitle>
            <span className="h2 font-weight-bold text-white mb-0">{text}</span>
          </div>
          <Col className="col-auto mt-3 mb-3">
            <div className="icon icon-shape bg-white rounded-circle shadow">
              <i className={icon} style={{ color: primary_color }} />
            </div>
          </Col>
        </Row>
        {/* <p className="mt-3 mb-0 text-sm">
        <span className="text-success mr-2">
          <i className="fa fa-arrow-up" /> 3.48%
        </span>
        <span className="text-nowrap">Since last month</span>
      </p> */}
      </CardBody>
    </Card>
  );
};

export default CardItem;
