const toISOFormatSubmit = (dateNotFormmated) => {
  if (dateNotFormmated === undefined) {
    console.log('A data está vazia');
  } else {
    const [DD, MM, YYYY] = dateNotFormmated.split('/');
    return `${YYYY}-${MM}-${DD}T00:00:00`;
  }
};

const formatSupervisors = (supervisors, setListFlexSelect, login) => {
  const arraySupervisors = supervisors.map((item) => {
    if (item.login !== login) {
      return { value: item.login, label: item.name };
    } else {
      return [];
    }
  });

  setListFlexSelect(arraySupervisors);
};

const formatCallers = (callers, setListSelect, login) => {
  const newCallers = callers.map((item) => {
    if (item.scaleId && item.login !== login) {
      return { value: item.login, label: item.name };
    }
  });
  const renewCallers = newCallers.filter(isUndefined);
  setListSelect(renewCallers);
};

const isUndefined = (value) => {
  if (value !== undefined) {
    return value;
  }
};
const toISOFormatAvulsa = (dateNotFormmated) => {
  if (dateNotFormmated === undefined) {
    console.log('A data está vazia');
  } else {
    const [DD, MM, YYYY] = dateNotFormmated.split('/');
    return `${YYYY}-${MM}-${DD}`;
  }
};
const toISORender = (dateNotFormmated) => {
  if (dateNotFormmated === undefined) {
    console.log('A data está vazia');
  } else {
    const [date] = dateNotFormmated.split('T');
    const [YYYY, MM, DD] = date.split('-');
    return `${DD}/${MM}/${YYYY}`;
  }
};
const arrayDays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];

const Options = [
  {
    label: 'Troca de Folga',
    value: 'folga'
  },
  {
    label: 'Troca de Horário Avulsa',
    value: 'avulsa'
  },
  {
    label: 'Troca de Horário Casada',
    value: 'tiein'
  }
];

const daysOfWeekObject = [
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday'
];
const daysOfWeekObjectDateFilterTiein = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const getShiftStatus = (row) => {
  if (row.supervisorStatusShift === 'PENDING') {
    return 'PENDING';
  }
  if (row.supervisorStatusShift === 'REJECTED') {
    return 'REJECT';
  }
  if (row.userStatusShift === 'PENDING') {
    return 'PENDING';
  }
  if (row.userStatusShift === 'REJECTED') {
    return 'REJECT';
  }
  return 'APPROVED';
};

export {
  toISOFormatSubmit,
  formatSupervisors,
  formatCallers,
  toISOFormatAvulsa,
  isUndefined,
  toISORender,
  arrayDays,
  daysOfWeekObject,
  Options,
  daysOfWeekObjectDateFilterTiein
};
