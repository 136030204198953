import { useEffect, useState } from 'react';
import UserService from '../../../User/service';
import { toast } from 'react-toastify';

export function useUsers(usersShowDisable) {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    UserService.getAllShowDisable(usersShowDisable)
      .then((response) => {
        setUserList(response.data.data);
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data) || 'Erro ao carregar usuários';
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  }, [usersShowDisable]);

  return { userList };
}
