export const protocolInputTypes = {
  TEXT: 'TEXT',
  LIST: 'LIST',
  DATE: 'DATE',
  NUMERIC: 'NUMERIC',
  CURRENCY: 'CURRENCY',
  HOUR: 'HOUR',
  TELEPHONE: 'TELEPHONE',
  CPF: 'CPF',
  CNPJ: 'CNPJ',
  MULTIPLE: 'MULTIPLE',
  LINK: 'LINK'
};

export const protocolFieldTypes = Object.freeze([
  { type: protocolInputTypes.TEXT, displayName: 'Campo de Texto' },
  { type: protocolInputTypes.NUMERIC, displayName: 'Campo Numérico' },
  { type: protocolInputTypes.CURRENCY, displayName: 'Campo Monetário' },
  { type: protocolInputTypes.DATE, displayName: 'Campo de data' },
  { type: protocolInputTypes.HOUR, displayName: 'Campo de Hora' },
  { type: protocolInputTypes.LIST, displayName: 'Lista de Opções' },
  { type: protocolInputTypes.TELEPHONE, displayName: 'Telefone' },
  { type: protocolInputTypes.CPF, displayName: 'CPF' },
  { type: protocolInputTypes.CNPJ, displayName: 'CNPJ' },
  { type: protocolInputTypes.MULTIPLE, displayName: 'Campo múltiplo' },
  { type: protocolInputTypes.LINK, displayName: 'Campo de Hiperlink' }
]);
