import { apiConfiguration as http } from '../http';
//
class ServiceCRUD {
  constructor(path) {
    this.path = path;
  }

  get = (id) => {
    return http.get(`${this.path}/${id}`, { responseType: 'blob' });
  };

  post = (data) => {
    return http.post(this.path, data);
  };
}

export default ServiceCRUD;
