import ServiceCRUD from '../../../../services/ServiceCRUDFLEXUC';
import { apiUc } from '../../../../http';

class AudiosService extends ServiceCRUD {
  constructor() {
    super(`/api/audios`);
  }

  getWithType = (type) => {
    return apiUc.get(`${this.path}?type=${type}`);
  };
}

export default new AudiosService();
