import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import ReportService from '../../Report/Advanced/Callback/service';
import { subMonths, format } from 'date-fns';

import MainContainer from './MainContainer';
import useAgentEvents from '../../Monitoring/Queue/MonitoringScreen/hooks/useAgentEvents';
import { useSocketWatingCalls } from '../../Monitoring/Queue/MonitoringScreen/hooks/useSocketWatingCalls';

const CallbackDashboard = () => {
  const dialerName = window.location.search.split('?')[1];
  const callBackName = dialerName.split('=')[1];
  const dialerQueueName = window.location.search.split('=')[2];
  const { id } = useParams();
  const { agents, socket } = useAgentEvents();
  const { watingCalls } = useSocketWatingCalls(socket, callBackName);
  const [data, setData] = useState({});
  const currentDate = new Date();
  const dateBegin = format(subMonths(currentDate, 1), 'LL-dd-yyyy HH:mm:ss');
  const dateEnd = format(currentDate, 'LL-dd-yyyy HH:mm:ss');

  const orderByExtension = (items) => {
    return [...items].sort((a, b) => {
      return a.extension < b.extension ? -1 : 1;
    });
  };

  const orderByQueue = (items) => {
    return [...items].sort((a, b) => {
      return a.queueName < b.queueName ? -1 : 1;
    });
  };
  const filterAgentsByQueue = (items) => {
    const listFilterAgents = items.filter(
      (item) => item.queueName === dialerQueueName
    );
    return listFilterAgents;
  };

  useEffect(() => {
    getCallBackData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      getCallBackData();
    }, 1000 * 20);

    return () => clearInterval(interval);
  }, []);

  const getCallBackData = async () => {
    await ReportService.getAllReportsType('detailedsynthetic', 1, {
      callback: id,
      dateBegin: dateBegin,
      dateEnd: dateEnd,
      disable: false,
      reportType: 3,
      timeEnd: '235959',
      timeStart: '000000'
    })
      .then((res) => {
        setData(res.data.data.listCallbackDetailedSyntheticDTO[0]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <MainContainer
      data={{
        callBackName,
        callback: { ...data },
        agents: orderByExtension(orderByQueue(filterAgentsByQueue(agents))),
        watingCalls: watingCalls.filter(
          (call) => call.queueName === callBackName
        )
      }}
    />
  );
};

export default CallbackDashboard;
