import { useState, useEffect, useCallback } from 'react';
import UserService from '../../../User/service';

export function useGetUserById(login, isOpen) {
  const [user, setUser] = useState();

  const getUser = useCallback(async () => {
    try {
      if (isOpen) {
        const response = await UserService.get(login);
        setUser(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [login, isOpen]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return { user, setUser };
}
