import { useState, useCallback } from 'react';
import UserService from '../service';

export const useSearchUsers = () => {
  const [timeoutID, setTimeoutID] = useState(null);
  const [isSearchingUsers, setIsSearchingUsers] = useState(false);

  const fetchUsers = useCallback(async (q) => {
    const params = { q };
    try {
      const response = UserService.search(params);
      return response;
    } catch (error) {
      return null;
    }
  }, []);

  const handleGetUsers = useCallback(
    async (q) => {
      try {
        if (!q) return [];
        setIsSearchingUsers(true);
        const users = await fetchUsers(q);
        return users.data.data;
      } catch (error) {
        return [];
      } finally {
        setIsSearchingUsers(false);
      }
    },
    [fetchUsers]
  );

  const searchUsers = useCallback(
    async (q) => {
      return new Promise((resolve, reject) => {
        try {
          if (typeof timeoutID === 'number') {
            clearTimeout(timeoutID);
          }

          setTimeoutID(
            setTimeout(async () => {
              const data = await handleGetUsers(q);
              resolve(data);
            }, 1000)
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    [handleGetUsers, timeoutID]
  );

  return {
    isSearchingUsers,
    searchUsers
  };
};
