import { apiChat } from '../../../../http/index';
import ServiceCRUD from '../../../../services/ServiceCRUDCHANNEL';

class broadcastListService extends ServiceCRUD {
  constructor() {
    super('/report/broadcastlist');
  }

  async getExcel(params, showDisabledTransmissionLists = false) {
    return apiChat.get(
      `/report/broadcastlist/export/xlsx?showDisabled=${showDisabledTransmissionLists}`,
      {
        params,
        responseType: 'arraybuffer'
      }
    );
  }

  async getCsv(params, showDisabledTransmissionLists = false) {
    return apiChat.get(
      `/report/broadcastlist/export/csv?showDisabled=${showDisabledTransmissionLists}`,
      { params }
    );
  }
  async getPdf(params, showDisabledTransmissionLists = false) {
    return apiChat.get(
      `/report/broadcastlist/export/pdf?showDisabled=${showDisabledTransmissionLists}`,
      {
        params,
        responseType: 'blob'
      }
    );
  }
}

export default new broadcastListService();
