import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import SubReasonService from '../service';
import ReasonService from '../../reason/service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import FlexSelect from 'components/Inputs/FlexSelect';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody,
  Button,
  Table
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { AddSupervisorNotificationModal } from 'views/status/form/components/AddSupervisorNotificationModal';
import { getSupervisorAlertTypeLabel } from 'views/status/utils/supervisorAlertTypes';
import { ScreenPrompt } from 'components/ScreenPrompt';

const SubReasons = (props) => {
  const history = useHistory();

  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;
  const [isUpdate, setIsUpdate] = useState(false);
  const [reasons, setReasons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [supervisorAlerts, setSupervisorAlerts] = useState([]);

  const { id } = props.match.params;

  useEffect(() => {
    setLoading(true);
    ReasonService.getWithoutPagination()
      .then((res) => {
        const getReason = res.data.content;
        setReasons(getReason);
        setLoading(false);
      })
      .catch((err) => {
        const { data } = err.response;
        toast.error(data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
        setLoading(false);
      });

    if (id) {
      setLoading(true);
      SubReasonService.get(id)
        .then((res) => {
          const getSubReason = res.data.content;
          reset(getSubReason);
          setSupervisorAlerts(getSubReason.supervisorAlerts || []);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch((err) => {
          const { data } = err.response;
          toast.error(data.message || 'Erro ao listar submotivo!', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      const formatedData = { ...data, supervisorAlerts };
      isUpdate
        ? await SubReasonService.update(id, formatedData)
        : await SubReasonService.create(formatedData);
      history.push('/admin/subReason');
      toast.success(
        isUpdate
          ? 'Submotivo atualizado com sucesso!'
          : 'Submotivo criado com sucesso!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    } catch (err) {
      const { data } = err.response;
      toast.error(data.message || 'Erro ao cadastrar submotivo!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/subReason');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/subReason"
        buttonTitle="Voltar para a pagina de submotivos"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Submotivo</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{ required: 'Nome é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Descrição
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="description"
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Motivo*</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={reasons}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="reason_id"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="reason_id"
                            rules={{ required: 'Motivo é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="reason_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />

                      <Row className="mb-1">
                        <Col md="12">
                          <h3 className="mb-1">Alertas de supervisão</h3>
                        </Col>
                        <Col md="12">
                          <AddSupervisorNotificationModal
                            buttonLabel="Adicionar"
                            onAdded={(addedAlert) => {
                              if (supervisorAlerts.includes(addedAlert)) {
                                toast.error('Já existe um alerta desse tipo');
                                return;
                              }
                              setSupervisorAlerts((prev) => [
                                ...prev,
                                addedAlert
                              ]);
                            }}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-5">
                        <Col>
                          {supervisorAlerts && !!supervisorAlerts.length && (
                            <Table size="sm">
                              <thead>
                                <tr>
                                  <th>Nome</th>
                                  <th>Remover</th>
                                </tr>
                              </thead>
                              <tbody>
                                {supervisorAlerts.map((item, index) => (
                                  <tr key={index}>
                                    <td>{getSupervisorAlertTypeLabel(item)}</td>
                                    <td>
                                      <Button
                                        color="danger"
                                        size="sm"
                                        onClick={() => {
                                          setSupervisorAlerts((prev) =>
                                            prev.filter(
                                              (alert) => alert !== item
                                            )
                                          );
                                        }}
                                      >
                                        <li className="fas fa-trash"></li>
                                      </Button>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          )}
                        </Col>
                      </Row>

                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SubReasons;
