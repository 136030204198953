import styled from 'styled-components';

export const InputCheckbox = styled.div`
  input[type='checkbox'] {
    width: 1.8rem;
    height: 1.8rem;
    background-color: white;
    border-radius: 1rem;
    vertical-align: middle;
    border: 1px solid #ddd;
    appearance: none;
    -webkit-appearance: none;
    cursor: pointer;
    margin-right: 0.3rem;
  }
  .checkbox-round-sun:checked {
    background-color: #1a73e8;
  }
  .checkbox-round-mon:checked {
    background-color: #1a73e8;
  }
  .checkbox-round-tue:checked {
    background-color: #1a73e8;
  }
  .checkbox-round-wed:checked {
    background-color: #1a73e8;
  }
  .checkbox-round-thu:checked {
    background-color: #1a73e8;
  }
  .checkbox-round-fri:checked {
    background-color: #1a73e8;
  }
  .checkbox-round-sat:checked {
    background-color: #1a73e8;
  }
  #input-sun {
    position: absolute;
    margin-left: 0.64rem;
    margin-top: -1.475rem;
    pointer-events: none;
  }
  #input-mon {
    position: absolute;
    margin-left: 2.75rem;
    margin-top: -1.475rem;
    pointer-events: none;
  }
  #input-tue {
    position: absolute;
    margin-left: 4.85rem;
    margin-top: -1.475rem;
    pointer-events: none;
  }
  #input-wed {
    position: absolute;
    margin-left: 6.87rem;
    margin-top: -1.475rem;
    pointer-events: none;
  }
  #input-thu {
    position: absolute;
    margin-left: 8.93rem;
    margin-top: -1.475rem;
    pointer-events: none;
  }
  #input-fri {
    position: absolute;
    margin-left: 11.15rem;
    margin-top: -1.475rem;
    pointer-events: none;
  }
  #input-sat {
    position: absolute;
    margin-left: 13.25rem;
    margin-top: -1.475rem;
    pointer-events: none;
  }
  .checkbox-round-sun:checked ~ #input-sun {
    color: white;
  }
  .checkbox-round-mon:checked ~ #input-mon {
    color: white;
  }
  .checkbox-round-tue:checked ~ #input-tue {
    color: white;
  }
  .checkbox-round-wed:checked ~ #input-wed {
    color: white;
  }
  .checkbox-round-thu:checked ~ #input-thu {
    color: white;
  }
  .checkbox-round-fri:checked ~ #input-fri {
    color: white;
  }
  .checkbox-round-sat:checked ~ #input-sat {
    color: white;
  }
`;
