export const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%'
  }, 
  divs: {
    padding: '0.6rem',
  }, 
  divCircle: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'space-between',
    justifyContent: 'center',
    alignItems: 'center'
  }
};