import React, { useState, useEffect, useCallback, useMemo } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ReactLoader from 'components/Loader';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import {
  BsFillImageFill,
  BsCameraVideoFill,
  BsChatDotsFill
} from 'react-icons/bs';
import { AiFillFilePdf } from 'react-icons/ai';
import {
  Container,
  Row,
  Card,
  Col,
  Input,
  CardHeader,
  CardBody,
  Form
} from 'reactstrap';

import { useForm, Controller, useWatch } from 'react-hook-form';
import ActiveContactService from 'views/ServicePanel/components/QueueContent/components/Contact/service';

import TeamService from '../service/index';
import { custom_select } from 'assets/styles/multiple_select';

import ReactButton from 'components/Buttons/ReactButton';
import { useUsers } from 'views/Report/Protocol/hooks/useUsers';
import { ErrorMessage } from '@hookform/error-message';
import { SelectTemplateOptions } from 'views/ChatBroadcastList/form/styled';
import Select from 'react-select';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
import FlexSelect from 'components/Inputs/FlexSelect';
import { ScreenPrompt } from 'components/ScreenPrompt';
import { SearchTemplateWhatsapp } from 'views/ServicePanel/components/QueueContent/components/Contact/Function';

export default function TeamForm(props) {
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    errors,
    watch,
    getValues,
    formState
  } = useForm();
  const { isDirty } = formState;
  const { userList } = useUsers();
  const [whatsappList, setWhatsappList] = useState([]);
  const whatsappOptions = whatsappList.map((item) => {
    return { value: item.source, label: item.nome_canal };
  });
  const whatsappsHasAccessValue =
    useWatch({ control, name: 'whatsappsHasAccess' }) ?? [];
  const [templateList, setTemplateList] = useState({});

  const templateOptions = useMemo(() => {
    const values = whatsappsHasAccessValue.map((element) => {
      return templateList[element] ?? [];
    });
    return [].concat(...values);
  }, [whatsappsHasAccessValue, templateList]);

  useEffect(() => {
    whatsappsHasAccessValue.forEach(async (element) => {
      if (Object.keys(templateList).includes(element)) return;
      const response = await FlexChatIntegration.getContatoAtivoByPhone(
        element,
        false
      );
      const copy = { ...templateList };
      copy[element] = response.data.data.map((item) => {
        return { ...item, label: item.description, value: item.id };
      });

      setTemplateList({ ...copy });
    });
    const templateValues = getValues('templateHasAccess');
    if (templateValues) {
      const arrayId = templateOptions.map((item) => item.id);
      const copy = [...templateValues];
      const filtered = copy.filter((item) => {
        return arrayId.includes(item.id);
      });
      setValue('templateHasAccess', filtered);
    }
  }, [whatsappsHasAccessValue, templateOptions]);

  const [loading, setLoading] = useState(false);

  const { id } = props.match.params;

  const Icons = {
    IMAGE: <BsFillImageFill />,
    VIDEO: <BsCameraVideoFill />,
    DOCUMENT: <AiFillFilePdf />,
    TEXT: <BsChatDotsFill />
  };

  const Colors = {
    DOCUMENT: '#d92222d1',
    IMAGE: '#2dce89',
    VIDEO: '#11cdef',
    TEXT: 'rgb(54 54 96)'
  };

  const getWhatsapp = () => {
    FlexChatIntegration.getWhatsapp()
      .then((res) => {
        setWhatsappList(res?.data?.data ?? []);
      })
      .catch(() => {
        toast.error('Erro ao buscar whatsapp');
      });
  };

  const loadAPI = useCallback(async () => {
    setLoading(true);
    await TeamService.get(id)
      .then(async (response) => {
        const data = response.data.data;
        const whatsapp = response?.data?.data?.whatsapps ?? [];

        let obj = {};

        for (const element of whatsapp) {
          if (Object.keys(templateList).includes(element.source)) continue;

          const response = await FlexChatIntegration.getContatoAtivoByPhone(
            element.source,
            false
          );

          obj[element.source] = response.data.data.map((item) => {
            return { ...item, label: item.description, value: item.id };
          });
        }

        setTemplateList({ ...obj });

        if (data) {
          const formatedInfo = {
            name: data?.name,
            templateHasAccess: data?.templates?.map((template) => ({
              ...template,
              value: template.id,
              label: template.description,
              templateType: template.type
            })),
            whatsappsHasAccess: data?.whatsapps?.map((canal) => {
              return canal.source;
            }),
            userLogins: data?.users?.map((user) => ({
              value: user.login,
              label: user.name
            }))
          };
          reset(formatedInfo);
        }

        setLoading(false);
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao carregar os dados');
      });
  }, [id, reset]);

  const getSynchronizedTemplates = useCallback(async () => {
    setLoading(true);
    await FlexChatIntegration.syncTemplates();

    setLoading(false);
  }, []);

  useEffect(() => {
    if (id) {
      loadAPI();
    }
  }, [id, loadAPI]);

  useEffect(() => {
    getSynchronizedTemplates();
  }, [getSynchronizedTemplates]);

  useEffect(() => {
    getWhatsapp();
  }, []);

  const history = useHistory();

  const cancelAction = () => {
    history.push('/admin/Team/list');
  };

  const onSubmit = async (data) => {
    // get only the value in the array
    if (data?.userLogins?.length === 0 || data?.userLogins === null) {
      toast.error('É necessário selecionar pelo menos um usuário');
      return;
    }
    const dataToSend = {
      ...data,
      templateHasAccess: data?.templateHasAccess
        ? data?.templateHasAccess.map((template) => template.value)
        : [],
      userLogins: data?.userLogins.map((user) => user.value) || []
    };
    try {
      if (id) {
        await TeamService.put(id, dataToSend).then(() => {
          toast.info('Equipe foi atualizada com sucesso!');
          history.push('/admin/Team/list');
        });
      } else {
        await TeamService.post(dataToSend).then(() => {
          toast.success('Equipe foi cadastrada com sucesso!');
          history.push('/admin/Team/list');
        });
      }
    } catch (err) {
      toast.error('Ocorreu um erro ao cadastrar o time');
    }
  };

  const generateUsers = useCallback(() => {
    if (!userList) {
      return [];
    }

    return userList.map((user) => {
      return { value: user.login, label: user.name };
    });
  }, [userList]);

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    alignItems: 'center'
                  }}
                >
                  <h3>Equipes</h3>
                </CardHeader>

                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="4">
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{ required: 'Nome é obrigatório!' }}
                            defaultValue={''}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">Membros*</label>
                          <Controller
                            render={() => (
                              <Select
                                styles={{ ...custom_select, width: '100%' }}
                                placeholder="Selecione os membros da Equipe"
                                options={generateUsers() || []}
                                isMulti={true}
                                isClearable={true}
                                closeMenuOnSelect={false}
                                valueController={setValue}
                                name="userLogins"
                                value={watch('userLogins') || null}
                                onChange={(value) => {
                                  setValue('userLogins', value);
                                }}
                                defaultValue=""
                                rules={{ required: 'Nome é obrigatório!' }}
                              />
                            )}
                            control={control}
                            name="userLogins"
                            defaultValue={''}
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">
                            Whatsapp
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={whatsappOptions || []}
                                isMulti={true}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="whatsappsHasAccess"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="whatsappsHasAccess"
                            defaultValue={[]}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="whatsappsHasAccess"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>
                          <label className="form-control-label">
                            Templates que essa equipe tem acesso
                          </label>
                          <Controller
                            render={(props) => {
                              return (
                                <Select
                                  styles={{ ...custom_select, width: '100%' }}
                                  placeholder="Selecione o(s) template(s)..."
                                  options={templateOptions || []}
                                  isSearchable={true}
                                  isClearable={false}
                                  isMulti={true}
                                  // getValue={(value) => {
                                  //
                                  //   return value.id;
                                  // }}
                                  name="templateHasAccess"
                                  value={props.value || null}
                                  filterOption={(option, inputValue) => SearchTemplateWhatsapp({inputValue, option})}
                                  onChange={(data) => {
                                    setValue('templateHasAccess', data);
                                  }}
                                  getOptionValue={(value) => {
                                    return value.value;
                                  }}
                                  getOptionLabel={(option) => {
                                    return (
                                      <SelectTemplateOptions
                                        isMulti={true}
                                        color={Colors[option.type]}
                                        title={option.label}
                                      >
                                        {option.name}
                                        {'  '}
                                        {Icons[option.type] ?? '!'}
                                      </SelectTemplateOptions>
                                    );
                                  }}
                                  defaultValue={''}
                                />
                              );
                            }}
                            control={control}
                            name="templateHasAccess"
                            // rules={{
                            //   required: 'Selecione pelo menos um template'
                            // }}
                            defaultValue={''}
                          />

                          {/* <ErrorMessage
                            errors={errors}
                            name="templateHasAccess"
                            render={({ message }) => errorFormMessage(message)}
                          /> */}
                        </Col>
                      </Row>
                      <Row className="mt-5 ml-1">
                        <ReactButton btnColor="confirmation">
                          Salvar
                        </ReactButton>

                        <ReactButton
                          type="button"
                          btnColor="cancelation"
                          onClick={cancelAction}
                        >
                          Cancelar
                        </ReactButton>
                      </Row>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
