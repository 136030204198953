import React from 'react';
import { Row, Card, Col, ListGroup, ListGroupItem, Button } from 'reactstrap';
import ReportService from '../service';
import * as fileSaver from 'file-saver';
import { formatDateForAudioDownloads } from '../../../../../utils/fuctions/formattedDateForAudioDownloads';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import {
  individual_column_style,
  individual_header_style,
  individual_icon_header_style,
  icon_column_style
} from '../../../../../assets/styles/table_custom_style';
import { TotalRegister } from '../../../../../components/TotalRegister';
import { AudioStreamReportTelephony } from 'components/AudioController/AudioStream';
import { DownloadButton } from 'views/Report/Callcenter/CallMade/components/DownloadButton';

const itemStyle = { padding: '5px 0 0 20px' };

const Analytical = ({ dataReport, totalRecords }) => {
  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            {totalRecords >= 0 && (
              <TotalRegister totalRegister={totalRecords} />
            )}
            <Card>
              <ToolkitProvider
                data={dataReport.listCallAnalytical || []}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'callDateStart',
                    text: 'Data início',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      const date = new Date(Row);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'clidNum',
                    text: 'Origem',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'dst',
                    text: 'Destino',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'ddr',
                    text: 'DDR',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'centroDeCusto',
                    text: 'Setor',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'carrier',
                    text: 'Operadora',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'dispositionFormated',
                    text: 'Status da Chamada',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'esperaNaUra',
                    text: 'Espera na URA',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (rowContent, row) => {
                      let time = '';
                      if (rowContent) {
                        const array = rowContent.split(':');
                        time = `${array[0]}:${array[1]}:${array[2][0]}${array[2][1]}`;
                      }
                      return time;
                    }
                  },
                  {
                    dataField: 'opcaoDaUra',
                    text: 'Opção da URA',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'transferIn',
                    text: 'Recebeu transferência de',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'transferOut',
                    text: 'Transferiu para',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'waitingTimeFormated',
                    text: 'Tempo em espera',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeInCallFormated',
                    text: 'Tempo em ligação',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'download',
                    text: 'Download',
                    sort: true,
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (_, row) => {
                      return <DownloadButton row={row}></DownloadButton>;
                    }
                  },
                  {
                    dataField: 'listen',
                    text: 'Escutar',
                    sort: true,
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (_, row) => {
                      return (
                        <AudioStreamReportTelephony
                          ReportService={ReportService}
                          uniqueid={row.uniqueid}
                          disabled={
                            row.disposition === 'ANSWERED' ? false : true
                          }
                          reasonDisabled={
                            row.disposition === 'ANSWERED' ? '' : 'Não atendida'
                          }
                        />
                      );
                    }
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
              <Row className="mb-3 mt-3">
                <Col md="3">
                  <ListGroup flush>
                    <ListGroupItem disabled style={itemStyle}>
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        Chamadas atendidas:
                      </span>{' '}
                      {dataReport.quantityCallAnswer}
                    </ListGroupItem>
                    <ListGroupItem disabled style={itemStyle}>
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        Chamadas não atendidas:
                      </span>{' '}
                      {dataReport.quantityCallNoAnswer}
                    </ListGroupItem>
                    <ListGroupItem disabled style={itemStyle}>
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        Tempo médio de Serviço:
                      </span>{' '}
                      {dataReport.averageServiceTimeFormated}
                    </ListGroupItem>
                    <ListGroupItem disabled style={itemStyle}>
                      <span style={{ fontSize: '13px', fontWeight: 'bold' }}>
                        Tempo médio de Espera:
                      </span>{' '}
                      {dataReport.averageWaintingTimeFormated}
                    </ListGroupItem>
                  </ListGroup>
                </Col>
              </Row>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Analytical;
