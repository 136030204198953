import React from 'react';

import ChatDialogTreeForm from './form';
import { DialogTreeProvider } from './tree/context';

const Provider = () => (
  <DialogTreeProvider>
    <ChatDialogTreeForm />
  </DialogTreeProvider>
);

export default Provider;
