import React, { useCallback, useState } from 'react';
import { IoMdCloseCircle } from 'react-icons/io';
import { toast } from 'react-toastify';
import { useLeadAttachments } from './useLeadAttachments';
import Loader from 'components/Loader';

import './styles.css';
import { FileSelectorWrapper } from './styled';
import { Button, Col, Form, Input, Row } from 'reactstrap';
import { ErrorFormMessage } from 'components/ErrorFormMessage';
import { ErrorMessage } from '@hookform/error-message';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { notAllowedExtensions } from 'views/shared/helpers/notAllowedExtensions';

export const LeadAttachments = ({
  leadId,
  isButtonDisabled,
  attachments,
  onAdded,
  onDeleted
}) => {
  const [files, setFiles] = useState(null);
  const [loading, setLoading] = useState(false);

  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { errors } = useForm();

  const {
    getFileURL,
    removeAttachmentFromLead,
    addAttachmentsToLead,
    getFileExtensionFromName,
    getIconFromFileExtension,
    getFileNameFormatted
  } = useLeadAttachments(leadId);

  const handleDeleteAttachment = useCallback(
    async (attachmentId) => {
      if (window.confirm('Deseja realmente remover este anexo?') === false)
        return;
      const isDeleted = await removeAttachmentFromLead(attachmentId);
      if (isDeleted) {
        toast.success('Anexo removido com sucesso');
        onDeleted(attachmentId);
        return;
      }
      return toast.error('Erro ao remover anexo');
    },
    [onDeleted, removeAttachmentFromLead]
  );

  const changeFile = (event) => {
    const files = event?.target?.files;
    if (!files || !files.length) return;
    setFiles(files);
  };

  const handleClearFiles = (event) => {
    event.target.form.reset();
    setFiles(null);
  };

  const getFileNamesToShow = () => {
    if (!files || !files.length) return null;
    if (files.length > 1) return `${files.length} arquivos selecionados`;
    return files[0].name;
  };

  const handleSubmitedForm = useCallback(
    async (event) => {
      try {
        const regex = /(?:\.([^.]+))?$/;

        setLoading(true);
        event.persist();

        if (!files || !files.length) {
          setLoading(false);

          return toast.error('Selecione um arquivo');
        }

        const formData = new FormData();
        let stopProcess = false;
        for (const file of files) {
          const ext = regex.exec(file.name)[1];

          if (notAllowedExtensions.includes(ext)) {
            stopProcess = true;
            return toast.error('Extensão ' + ext + ' não permitida');
          }
          formData.append('attachments', file, file.name);
        }

        if (stopProcess) {
          setLoading(false);
          return;
        }

        const addedAttachments = await addAttachmentsToLead(formData);
        if (addedAttachments) {
          onAdded(addedAttachments);
          event.target.form.reset();
          setFiles(null);
          toast.info('Arquivo salvo com sucesso', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
        setLoading(false);
      } catch (error) {
        toast.error(error.response?.data?.message || 'Erro ao adicionar anexo');
        setLoading(false);
      }
    },
    [addAttachmentsToLead, onAdded, files]
  );

  return (
    <>
      <FileSelectorWrapper>
        <Form className="needs-validation" encType="multipart/form-data">
          <Row className="mb-3">
            <Col sm="12">
              <label className="form-control-label d-block">
                Arquivos ( pdf, doc, docx, png, jpg, jpeg )*
              </label>
              <label
                htmlFor="attachments"
                className="audio-button-select-style"
                style={buttonStyle(systemConfiguration.primary_color)}
              >
                Selecionar o(s) anexo(s)
              </label>
              <Input
                style={{ display: 'none' }}
                type="file"
                id="attachments"
                name="attachments"
                onChange={changeFile}
                multiple
                disabled={isButtonDisabled}
                // accept=".png, .jpeg, .jpg, .pdf, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              />
              <ErrorMessage
                errors={errors}
                name="attachments"
                render={({ message }) => ErrorFormMessage(message)}
              />
            </Col>
            {files && files.length ? (
              <>
                <div
                  style={{
                    marginLeft: '1rem',
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'flex-start',
                    alignContent: 'flex-start'
                  }}
                >
                  <p>{getFileNamesToShow()}</p>
                  <Button
                    size="sm"
                    type="button"
                    color="danger"
                    title="Remover arquivo(s) selecionado(s)"
                    onClick={handleClearFiles}
                    style={{
                      marginLeft: '0.5rem'
                    }}
                  >
                    Limpar
                  </Button>
                  <Button
                    size="sm"
                    type="button"
                    color="success"
                    title="Adicionar arquivo(s) selecionado(s)"
                    onClick={handleSubmitedForm}
                    disabled={loading}
                  >
                    {loading ? 'Aguarde...' : 'Fazer Upload'}
                  </Button>
                </div>
              </>
            ) : null}
          </Row>
        </Form>
      </FileSelectorWrapper>

      {attachments && !!attachments.length && (
        <div
          className="mt-3"
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            boxSizing: 'border-box',
            flexWrap: 'wrap',
            width: '100%'
          }}
        >
          {attachments.map((att) => (
            <div
              key={att.id}
              title={att.name}
              style={{
                position: 'relative',
                width: '18rem',
                padding: '0.5rem',
                lineHeight: '1rem',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                paddingRight: '1.5rem'
              }}
              className="attachment-name-container"
            >
              <img
                src={getIconFromFileExtension(
                  getFileExtensionFromName(att.name)
                )}
                width="24px"
                height="24px"
                alt={att.name}
                style={{ display: 'inline-block', marginRight: '0.5rem' }}
              />
              <a
                href={getFileURL(att.path)}
                target="_blank"
                rel="noopener noreferrer"
                download={true}
                className="small line-height-0"
              >
                {getFileNameFormatted(att.name)}
              </a>
              <IoMdCloseCircle
                type="button"
                onClick={() => handleDeleteAttachment(att.id)}
                className="delete-icon"
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};
