import React from 'react';
import { Card, CardHeader, CardBody, Col } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { barChartConfig } from '../assets';

const OutgoingGraphic = ({ outgoingData }) => {
  return (
    <Col xl="6">
      <Card>
        <CardHeader>
          <h6 className="surtitle">Visão Geral</h6>
          <h5 className="h3 mb-0">Chamadas recebidas por fila</h5>
        </CardHeader>
        <CardBody>
          <div className="chart">
            <Bar
              data={outgoingData}
              options={barChartConfig().options}
              className="chart-canvas"
              id="chart-bars"
            />
          </div>
        </CardBody>
      </Card>
    </Col>
  );
};

export default OutgoingGraphic;
