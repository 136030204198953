import React, { useState, useEffect } from 'react';

import Chart from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { Row, Card, Col, CardBody, CardHeader } from 'reactstrap';

import {
  chartOptions,
  parseOptions,
  chartExample6
} from '../../../../../../variables/charts';

const CallCenterCallGraphics = ({ dataGraphics, month }) => {
  const [pieChartData, setPieChartData] = useState([]);

  useEffect(() => {
    if (window.Chart) parseOptions(Chart, chartOptions());

    if (dataGraphics) {
      if (dataGraphics.length > 0) {
        let arrayLabels = ['Atendidas', 'Abandonadas'];
        let arrayData = [];
        let arrayColorsData = [];
        let arrayMain = [];

        dataGraphics.forEach((data) => {
          arrayData.push(data.answered);
          arrayColorsData.push('#00A07C');

          arrayData.push(data.abandoned);
          arrayColorsData.push('red');

          arrayMain.push({
            name: data.nameCostCenter,
            labels: [...arrayLabels],
            datasets: [
              {
                data: [...arrayData],
                backgroundColor: [...arrayColorsData]
              }
            ]
          });

          arrayData = [];
          arrayColorsData = [];
        });

        setPieChartData(arrayMain);
      }
    }
  }, [dataGraphics]);
  return (
    <Card>
      <CardHeader className="introduct">
        <h5 className="h3 mb-0">
          Chamadas Recebidas por setor PBXIP de {month}
        </h5>
      </CardHeader>
      <CardBody>
        <Row>
          {pieChartData.map((pieData, index) => {
            return (
              <Col md="2" key={index}>
                <p style={{ textAlign: 'center', fontWeight: 500 }}>
                  {pieData.name}
                </p>
                {/*
                  diferentes contas de email
                  amarrar email a uma fila,
                  responder o email pelo painel de atendimento
                  */}
                <div className="chart" style={{ height: '5rem' }}>
                  <Pie
                    data={pieData}
                    options={chartExample6.oplotions}
                    className="chart-canvas"
                    id="chart-pie"
                  />
                </div>
              </Col>
            );
          })}
        </Row>
      </CardBody>
    </Card>
  );
};

export default CallCenterCallGraphics;
