import React, { useState, useEffect } from 'react';
import { Pie } from 'react-chartjs-2';
import { chartExample6 } from 'variables/charts.js';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import { ColorArea } from 'views/Dashboard/Unified/components/ProtocolsByAttendantsGraphic/assets/styleds';
import randomColor from 'randomcolor';
import { toast } from 'react-toastify';
// import { ColorArea } from './assets/styleds';

export const Syntethic = ({ data }) => {
  const [GroupByStatusMessageColors, setGroupByStatusMessageColors] = useState(
    []
  );
  const [GroupByMessageColors, setGroupByMessageColors] = useState([]);
  const GroupByStatusMessage = data[0].GroupByStatusMessage;
  const groupByMessage = data[0].groupByMessage;

  const labelList = {
    enqueued: 'Na fila',
    sent: 'Enviado',
    delivered: 'Entregue',
    read: 'Lido',
    failed: 'Falhou',
    null: 'Sem informação',
    not_sent: 'Não enviado'
  };

  let groupByMessageLabels = groupByMessage.map((item) => {
    if (item.ultima_msg_cliente === '') {
      return 'Não respondida';
    }
    return item.ultima_msg_cliente;
  });

  let GroupByStatusMessageLabels = GroupByStatusMessage.map((item) => {
    return labelList[item.status_da_mensagem];
  });

  let groupByMessageData = groupByMessage.map((item) => {
    return +item.count;
  });

  let GroupByStatusMessageData = GroupByStatusMessage.map((item) => {
    return +item.count;
  });

  useEffect(() => {
    let newGroupByStatusMessagColors = [];
    GroupByStatusMessageData.forEach(() => {
      newGroupByStatusMessagColors.push(
        randomColor({ luminosity: 'random', hue: 'random' })
      );
    });
    let newgroupByMessageColors = [];
    groupByMessage.forEach(() => {
      newgroupByMessageColors.push(
        randomColor({ luminosity: 'random', hue: 'random' })
      );
    });

    setGroupByMessageColors([...newgroupByMessageColors]);
    setGroupByStatusMessageColors([...newGroupByStatusMessagColors]);
  }, [GroupByMessageColors.length, GroupByStatusMessageColors.length]);

  const groupByMessageCount = groupByMessageData.reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  const groupByStatusMessageCount = GroupByStatusMessageData.reduce(
    (partialSum, a) => partialSum + a,
    0
  );

  return (
    <>
      {groupByMessage.length > 0 && (
        <Card>
          <CardHeader>
            <h6 className="surtitle">Visão Geral</h6>
            <h5 className="h3 mb-0">Primeira Resposta</h5>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4" style={{ alignSelf: 'center' }}>
                <div className="chart">
                  <Pie
                    data={{
                      labels: groupByMessageLabels,
                      datasets: [
                        {
                          data: groupByMessageData,
                          backgroundColor: ['#3a0201', '#60d1ad', '#e0a088']
                        }
                      ]
                    }}
                    options={chartExample6.options}
                    className="chart-canvas"
                    id="chart-doughnut"
                  />
                </div>
              </Col>
              <Col md="8" style={{ alignSelf: 'center' }}>
                <Row>
                  <Col md="12">
                    <h1 className="display-1">
                      <CountUp end={groupByMessageCount} />
                    </h1>
                    <h2 className="text-muted font-weight-light mt--3">
                      Total de respostas
                    </h2>
                  </Col>
                </Row>
                <hr />
                <Row>
                  {groupByMessageData.map((data, index) => {
                    return (
                      <Col
                        md="2"
                        key={index}
                        className="mt--3 mb--3"
                        style={{
                          minWidth: '177px',
                          minHeight: '130px'
                        }}
                      >
                        <Row
                          className="row flex-nowrap"
                          style={{
                            minHeight: '100px'
                          }}
                        >
                          <Col md="1">
                            <ColorArea color={GroupByMessageColors[index]} />
                          </Col>
                          <Col md="11">
                            <h2>{groupByMessageData[index]}</h2>
                            <h3 className="text-muted font-weight-light mt--3">
                              {groupByMessageLabels[index] === ''
                                ? '-'
                                : groupByMessageLabels[index]}
                            </h3>
                            <h2
                              className="mt--1 font-weight-light"
                              style={{
                                paddingBottom: '1.5625rem'
                              }}
                            >
                              {`${parseFloat(
                                (100 * groupByMessageData[index]) /
                                  groupByMessageCount
                              ).toFixed(2)}%`}
                            </h2>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      {GroupByStatusMessage.length > 0 && (
        <Card>
          <CardHeader>
            <h6 className="surtitle">Visão Geral</h6>
            <h5 className="h3 mb-0">Status do envio</h5>
          </CardHeader>
          <CardBody>
            <Row>
              <Col md="4" style={{ alignSelf: 'center' }}>
                <div className="chart">
                  <Pie
                    data={{
                      labels: GroupByStatusMessageLabels,
                      datasets: [
                        {
                          data: GroupByStatusMessageData,
                          backgroundColor: GroupByStatusMessageColors
                        }
                      ]
                    }}
                    options={chartExample6.options}
                    className="chart-canvas"
                    id="chart-doughnut"
                  />
                </div>
              </Col>
              <Col md="8" style={{ alignSelf: 'center' }}>
                <Row>
                  <Col md="12">
                    <h1 className="display-1">
                      <CountUp end={groupByStatusMessageCount} />
                    </h1>
                    <h2 className="text-muted font-weight-light mt--3">
                      Total de mensagens
                    </h2>
                  </Col>
                </Row>
                <hr />
                <Row>
                  {GroupByStatusMessageData.map((data, index) => {
                    return (
                      <Col
                        md="2"
                        key={index}
                        className="mt--3 mb--3"
                        style={{
                          minWidth: '177px',
                          minHeight: '130px'
                        }}
                      >
                        <Row
                          className="row flex-nowrap"
                          style={{
                            minHeight: '100px'
                          }}
                        >
                          <Col md="1">
                            <ColorArea
                              color={GroupByStatusMessageColors[index]}
                            />
                          </Col>
                          <Col md="11">
                            <h2>{GroupByStatusMessageData[index]}</h2>
                            <h3 className="text-muted font-weight-light mt--3">
                              {GroupByStatusMessageLabels[index] === ''
                                ? '-'
                                : GroupByStatusMessageLabels[index]}
                            </h3>
                            <h2
                              className="mt--1 font-weight-light"
                              style={{
                                paddingBottom: '1.5625rem'
                              }}
                            >
                              {`${parseFloat(
                                (100 * GroupByStatusMessageData[index]) /
                                  groupByStatusMessageCount
                              ).toFixed(2)}%`}
                            </h2>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
    </>
  );
};
