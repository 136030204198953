import React from 'react';
import { HiPhoneIncoming } from 'react-icons/hi';

import { useModal } from '../../hooks/useModal';

import AgentIndicatorCard from '../AgentIndicatorCard';
import ReceivedCallReportModal from '../ReceivedCallReportModal';

const TotalCallsAnsweredCard = ({ data }) => {
  const { isModalOpen, toggleModal, handleModalToggle } = useModal();

  return (
    <>
      <AgentIndicatorCard icon={HiPhoneIncoming}>
        <AgentIndicatorCard.Header>
          <AgentIndicatorCard.Title>
            Chamadas atendidas
          </AgentIndicatorCard.Title>
          <AgentIndicatorCard.DetailBtn onClick={toggleModal} />
        </AgentIndicatorCard.Header>
        <AgentIndicatorCard.Body value={data} />
      </AgentIndicatorCard>
      <ReceivedCallReportModal
        isModalOpen={isModalOpen}
        onModalToggle={handleModalToggle}
      />
    </>
  );
};

export default React.memo(TotalCallsAnsweredCard);
