import React, { useEffect, useState } from 'react';
import Chart from 'chart.js';
import { toast } from 'react-toastify';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Form,
  Button
} from 'reactstrap';
import { chartOptions, parseOptions } from 'variables/charts.js';
import ReactLoader from 'components/Loader';
import FlexSelect from 'components/Inputs/FlexSelect';
import { useSelector } from 'react-redux';

import { dateRules } from '../../../../Report/Chat/DashboardDataBot/assets';
import { useForm, Controller } from 'react-hook-form';
import CRMDashboardService from '../../service';
import randomColor from 'randomcolor';
import GraphicLeadsByResponsible from './GraphicLeadsByResponsible';
import GraphicLeadsByStage from './GraphicLeadsByStage';
import DatePicker from 'components/Inputs/DatePicker.js';
import { ErrorMessage } from '@hookform/error-message';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { useRecordTypesByUser } from 'views/RecordType/hooks/useRecordTypesByUser';
import { buttonStyle } from 'layouts/LoginAutoAuth/components/utils';
import {
  getDataDTO,
  toISOFormat,
  firstDayOfMonth,
  firstDay
} from '../../shared/dataFormater';
import GraphicLeadsByListCustomFields from './GraphicLeadsByListCustomFields';

if (window.Chart) {
  parseOptions(Chart, chartOptions());
}
const filterLeadsCountByListCustomField = (data) => {
  if (!data) return;
  const result = data.filter((item) => {
    const allQuantitiesZero = item.options.every(
      (option) => option.quantity === 0
    );

    return !allQuantitiesZero;
  });
  return result;
};

const CRMDashboard = () => {
  const [loading, setLoading] = useState(false);
  const [leadsCountByStage, setLeadsCountByStage] = useState(null);
  const [leadsCountByResponsible, setLeadsCountByResponsible] = useState(null);
  const [leadsCountByListCustomFields, setLeadsCountByListCustomFields] =
    useState(null);
  const [defaultRecordType, setDefaultRecordType] = useState(null);
  const { control, handleSubmit, errors, setValue, getValues, register } =
    useForm();
  const {
    recordTypes: leadRecordTypes,
    getRecordTypesByUser: getLeadRecordTypesByUser
  } = useRecordTypesByUser(entitiesName.LEAD);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  useEffect(() => {
    getLeadRecordTypesByUser();
  }, [getLeadRecordTypesByUser]);

  useEffect(() => {
    if (leadRecordTypes !== null) {
      const firstRecordType = leadRecordTypes[0] || [];
      setDefaultRecordType({
        label: firstRecordType.name,
        value: firstRecordType.id
      });
    }
  }, [leadRecordTypes]);

  useEffect(() => {
    setLoading(true);

    if (defaultRecordType) {
      const formatedParams = {
        dateBegin: `${toISOFormat(firstDayOfMonth)}`,
        dateEnd: `${toISOFormat(new Date().toLocaleDateString('pt-br'))}`,
        recordType: defaultRecordType.value
      };
      getDashboardLeads(formatedParams);
    }
  }, [defaultRecordType]);

  const getDashboardLeads = (params) => {
    CRMDashboardService.getLeadsCountByStageWithDate(params)
      .then((response) => {
        const listCustomFields = response?.data?.data;
        setLeadsCountByStage(listCustomFields.leadsCountByStage);
        setLeadsCountByResponsible(listCustomFields.leadsCountByResponsible);

        const filteredResults = filterLeadsCountByListCustomField(
          listCustomFields.leadsCountByListCustomField
        );
        setLeadsCountByListCustomFields(filteredResults);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getGraphicLabels = () => {
    if (!leadsCountByStage) return [];
    return leadsCountByStage?.map((item) => item.name);
  };

  const getGraphicData = () => {
    if (!leadsCountByStage) return [];
    return leadsCountByStage?.map((item) => item.quantity);
  };

  const getGraphicColors = () => {
    if (!leadsCountByStage) return [];
    return leadsCountByStage?.map(() =>
      randomColor({ luminosity: 'random', hue: 'random' })
    );
  };

  const getGraphicTotalData = () => {
    if (!leadsCountByStage) return 0;
    return leadsCountByStage?.reduce((acc, item) => acc + item.quantity, 0);
  };

  const getLeadRecordTypesOptionsDTO = () => {
    if (!leadRecordTypes) return [];
    return leadRecordTypes.map((rt) => ({ label: rt.name, value: rt.id }));
  };

  const onSubmit = (data) => {
    try {
      const formatedData = getDataDTO(data);
      getDashboardLeads(formatedData);
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  if (loading) {
    return (
      <Row className="justify-content-md-center mt-4">
        <ReactLoader />
      </Row>
    );
  }

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <Container fluid className="pt-4">
      <Card>
        <CardBody>
          {leadRecordTypes ? (
            <div>
              <Form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row className="mb-3">
                  <Col md="4">
                    <label className="form-control-label">Data inicial*</label>
                    <Controller
                      render={(props) => (
                        <DatePicker
                          fieldName="dataInicial"
                          valueController={setValue}
                          defaultValue={props.value}
                        />
                      )}
                      control={control}
                      name="dataInicial"
                      rules={dateRules}
                      defaultValue={firstDay().toLocaleDateString('pt-br')}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="dataInicial"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                  <Col md="4">
                    <label className="form-control-label">Data final*</label>
                    <Controller
                      render={(props) => (
                        <DatePicker
                          fieldName="dataFinal"
                          valueController={setValue}
                          defaultValue={props.value}
                        />
                      )}
                      control={control}
                      name="dataFinal"
                      defaultValue={new Date().toLocaleDateString('pt-br')}
                      rules={{
                        ...dateRules,
                        validate: (value) => {
                          if (
                            new Date(toISOFormat(value, true)) <
                            new Date(
                              toISOFormat(getValues()['dataInicial'], true)
                            )
                          ) {
                            return 'A data final não pode ser anterior à inicial!';
                          } else {
                            return true;
                          }
                        }
                      }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="dataFinal"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                  <Col md="4">
                    <label className="form-control-label">
                      Tipo de registro*
                    </label>
                    <Controller
                      render={(props) => (
                        <FlexSelect
                          dataOptions={getLeadRecordTypesOptionsDTO()}
                          isMulti={false}
                          value={props.value}
                          valueController={setValue}
                          fieldName="selectedRecordType"
                          labelName="label"
                          valueName="value"
                          closeMenuOnSelect={true}
                          placeholder="Tipo de registro do lead"
                        />
                      )}
                      control={control}
                      defaultValue={
                        defaultRecordType ? defaultRecordType.value : ''
                      }
                      rules={{
                        required: 'Campo obrigatório'
                      }}
                      name="selectedRecordType"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="selectedRecordType"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="6">
                    <label
                      className="form-control-label"
                      style={{ marginRight: '20px', fontWeight: '400' }}
                    >
                      <input
                        type="radio"
                        name="filterDateBy"
                        ref={register}
                        value="creation"
                        defaultChecked={true}
                      />{' '}
                      Data de criação
                    </label>
                    <label
                      className="form-control-label"
                      style={{ fontWeight: '400' }}
                    >
                      <input
                        type="radio"
                        name="filterDateBy"
                        ref={register}
                        value="last_update"
                        defaultChecked={false}
                      />{' '}
                      Data de última alteração
                    </label>
                  </Col>
                </Row>
                <Button
                  type="submit"
                  style={buttonStyle(systemConfiguration.primary_color)}
                  className="mb-2"
                >
                  Buscar
                </Button>
              </Form>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <h6 className="surtitle">Visão Geral</h6>
                      <h5 className="h3 mb-0">
                        Distribuição de Leads por Fase
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <GraphicLeadsByStage
                        filteredLabels={getGraphicLabels()}
                        filteredData={getGraphicData()}
                        filteredColors={getGraphicColors()}
                        count={getGraphicTotalData()}
                      />
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader>
                      <h6 className="surtitle">Visão Geral</h6>
                      <h5 className="h3 mb-0">
                        Distribuição de Leads por responsável
                      </h5>
                    </CardHeader>
                    <CardBody>
                      {leadsCountByResponsible &&
                        !!leadsCountByResponsible.length && (
                          <GraphicLeadsByResponsible
                            leadsCountByResponsible={leadsCountByResponsible}
                          />
                        )}
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader>
                      <h6 className="surtitle">Visão Geral</h6>
                      <h5 className="h3 mb-0">
                        Distribuição de Leads por campos personalizados do tipo
                        Lista
                      </h5>
                    </CardHeader>
                    <CardBody
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        justifyContent: 'center'
                      }}
                    >
                      {/* Dados dos gráficos */}
                      {leadsCountByListCustomFields &&
                        !!leadsCountByListCustomFields.length &&
                        leadsCountByListCustomFields.map((item) => (
                          <GraphicLeadsByListCustomFields
                            key={item.id}
                            leadsCountByListCustomFields={item}
                          />
                        ))}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <span className="small">
                Você não tem acesso a nenhum tipo de lead.
              </span>
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default CRMDashboard;
