/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import ServiceCall from '../../../Report/Callcenter/ReceivedCall/service';
import {
  PARAMS,
  REPORT_TYPE_DETAILEDSYNTHETIC,
  PAGE
} from '../assets/consts/call';

export function useGetCallById(agent_code, isOpen) {
  const [calls, setCalls] = useState();

  const getExtension = useCallback(async () => {
    try {
      if (isOpen) {
        const response = await ServiceCall.getAllReportsType(
          REPORT_TYPE_DETAILEDSYNTHETIC,
          PAGE,
          { ...PARAMS, agent: agent_code }
        );
        setCalls(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [agent_code, isOpen]);

  useEffect(() => {
    getExtension();
  }, [getExtension]);

  return { calls, setCalls };
}
