import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import TimeScheduleService from '../service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import monthsOptions from '../../../variables/monthOptions.js';
import { useHistory } from 'react-router-dom';
import ModalHourMask from './assets/ModalHourMask.js';
import HourMask from '../../../components/Inputs/HourMask.js';
import './assets/style.css';
import { receiveCasts, sendCasts } from './assets/Casts.js';
import { ExceptionSchema } from './assets/ExceptionSchema.js';
import * as Yup from 'yup';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  Button,
  CardBody,
  FormGroup,
  Table,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';
const columns = ['Descrição', 'Data', 'Hora Inicial', 'Hora Final', 'Remover'];
const hourRules = {
  pattern: {
    value: /^[0-2][0-9]:[0-5][0-9]$/,
    message: 'Hora inválida!'
  }
};

const TimeScheduleForm = (props) => {
  const history = useHistory();
  const { id } = props.match.params;

  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [exceptions, setExceptions] = useState([]);

  const [description, setDescription] = useState('');
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState('jan');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [exceptionErrors, setExceptionErrors] = useState([]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      TimeScheduleService.get(id)
        .then((response) => {
          let getTimeSchedule = response.data.data;
          setExceptions(getTimeSchedule.schedule_time_exceptions);
          const castedData = receiveCasts(getTimeSchedule);
          reset(castedData);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(
            err.response.data.message ||
              'Erro ao carregar programação de horário!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
          setLoading(false);
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      let formatedData = {
        ...data,
        schedule_time_exceptions: [...exceptions]
      };
      const castedData = sendCasts(formatedData);
      isUpdate
        ? await TimeScheduleService.update(id, castedData)
        : await TimeScheduleService.create(castedData);
      history.push('/admin/timeschedule');

      toast.success(
        isUpdate
          ? 'Programação de horário alterada com sucesso!'
          : 'Programação de horário cadastrada com sucesso!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    } catch (err) {
      toast.error(err.response?.data?.message || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/timeschedule');
  };

  const toggle = () => {
    setModal(!modal);
    setExceptionErrors([]);
  };

  const addHoliday = async (event) => {
    event.preventDefault();

    const data = {
      dia: day,
      hora_inicial: startTime,
      hora_final: endTime,
      mes: month,
      descricao: description
    };

    try {
      await ExceptionSchema.validate(data, {
        abortEarly: false
      });

      exceptions.push(data);

      toggle();
    } catch (err) {
      const validationErrors = {};
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        setExceptionErrors(validationErrors);
      }
    }
  };

  const removeHoliday = (event, index) => {
    event.preventDefault();
    let array = exceptions;
    array.splice(index, 1);
    setExceptions([...array]);
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/timeschedule"
        buttonTitle="Voltar para a pagina de rotas de programação de horário"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Programação de horário</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color="#35ac50"
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col>
                          <h3 className="mb-1">Principal</h3>
                        </Col>
                      </Row>

                      <Row className="mb-5">
                        <Col md="8">
                          <h5>Nome*</h5>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{ required: 'Nome é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>
                          <h3 className="mb-1">Horários</h3>
                        </Col>
                      </Row>

                      <Row
                        className="mb-5" //col-md-11 offset-md-1
                        style={{ textAlign: 'center' }}
                      >
                        <Col className="col-sm">
                          <h5 style={{ marginTop: '38px' }}>
                            Horário inicial:
                          </h5>
                          <h5 style={{ marginTop: '38px' }}>Horário final:</h5>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>Segunda</h5>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="segunda_inicial"
                                  value={props.value}
                                  placeholder="08:00"
                                />
                              )}
                              control={control}
                              name="segunda_inicial"
                              rules={hourRules}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="segunda_inicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="segunda_final"
                                  value={props.value}
                                  placeholder="23:00"
                                />
                              )}
                              control={control}
                              name="segunda_final"
                              rules={hourRules}
                              defaultValue="" //
                            />
                            <ErrorMessage
                              errors={errors}
                              name="segunda_final"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>Terça</h5>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="terca_inicial"
                                  value={props.value}
                                  placeholder="08:00"
                                />
                              )}
                              control={control}
                              name="terca_inicial"
                              rules={hourRules}
                              defaultValue="" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="terca_inicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="terca_final"
                                  value={props.value}
                                  placeholder="23:00"
                                />
                              )}
                              control={control}
                              name="terca_final"
                              rules={hourRules}
                              defaultValue="" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="terca_final"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>Quarta</h5>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="quarta_inicial"
                                  value={props.value}
                                  placeholder="08:00"
                                />
                              )}
                              control={control}
                              name="quarta_inicial"
                              rules={hourRules}
                              defaultValue="" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="quarta_inicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="quarta_final"
                                  value={props.value}
                                  placeholder="23:00"
                                />
                              )}
                              control={control}
                              name="quarta_final"
                              rules={hourRules}
                              defaultValue="" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="quarta_final"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>Quinta</h5>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="quinta_inicial"
                                  value={props.value}
                                  placeholder="08:00"
                                />
                              )}
                              control={control}
                              name="quinta_inicial"
                              rules={hourRules}
                              defaultValue="" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="quinta_inicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="quinta_final"
                                  value={props.value}
                                  placeholder="23:00"
                                />
                              )}
                              control={control}
                              name="quinta_final"
                              rules={hourRules}
                              defaultValue="" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="quinta_final"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>Sexta</h5>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="sexta_inicial"
                                  value={props.value}
                                  placeholder="08:00"
                                />
                              )}
                              control={control}
                              name="sexta_inicial"
                              rules={hourRules}
                              defaultValue="" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="sexta_inicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="sexta_final"
                                  value={props.value}
                                  placeholder="23:00"
                                />
                              )}
                              control={control}
                              name="sexta_final"
                              rules={hourRules}
                              defaultValue="" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="sexta_final"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>Sábado</h5>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="sabado_inicial"
                                  value={props.value}
                                  placeholder="08:00"
                                />
                              )}
                              control={control}
                              name="sabado_inicial"
                              rules={hourRules}
                              defaultValue="" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="sabado_inicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="sabado_final"
                                  value={props.value}
                                  placeholder="23:00"
                                />
                              )}
                              control={control}
                              name="sabado_final"
                              rules={hourRules}
                              defaultValue="" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="sabado_final"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-sm">
                          <FormGroup>
                            <h5>Domingo</h5>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="domingo_inicial"
                                  value={props.value}
                                  placeholder="08:00"
                                />
                              )}
                              control={control}
                              name="domingo_inicial"
                              rules={hourRules}
                              defaultValue="" //08:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="domingo_inicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                          <FormGroup>
                            <Controller
                              render={(props) => (
                                <HourMask
                                  valueController={setValue}
                                  fieldName="domingo_final"
                                  value={props.value}
                                  placeholder="23:00"
                                />
                              )}
                              control={control}
                              name="domingo_final"
                              rules={hourRules}
                              defaultValue="" //23:00
                            />
                            <ErrorMessage
                              errors={errors}
                              name="domingo_final"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </FormGroup>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col>
                          <h3 className="mb-1">Feriados</h3>
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="col-md-7">
                          <Table>
                            <thead>
                              <tr>
                                {columns.map((column, index) => (
                                  <th key={index}>{column}</th>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              {exceptions.map((exception, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{exception.descricao}</td>
                                    <td>
                                      {exception.dia} / {exception.mes}
                                    </td>
                                    <td>{exception.hora_inicial}</td>
                                    <td>{exception.hora_final}</td>

                                    <td>
                                      <Button
                                        size="sm"
                                        color="danger"
                                        onClick={(event) => {
                                          removeHoliday(event, index);
                                        }}
                                      >
                                        <li className="fas fa-trash"></li>
                                      </Button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                          <ReactButton
                            btnColor="confirmation"
                            size="sm"
                            onClick={toggle}
                          >
                            Adicionar
                          </ReactButton>
                        </Col>
                      </Row>

                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>

      <Modal isOpen={modal} toggle={toggle} centered={true}>
        <ModalHeader toggle={toggle}>Feriado</ModalHeader>
        <ModalBody>
          <Row>
            <Col md="12">
              <h5>Descrição</h5>
              <Input
                name="descricao"
                defaultValue={description}
                onChange={(e) => setDescription(e.target.value)}
              />
              <span className="error-container">
                {exceptionErrors.descricao}
              </span>
            </Col>
          </Row>
          <Row className="mb-4">
            <Col md="4">
              <h5>Dia</h5>
              <Input
                type="number"
                name="dia"
                defaultValue={day}
                onChange={(e) => setDay(e.target.value)}
              />
              <p className="error-container">{exceptionErrors.dia}</p>
            </Col>
            <Col md="8">
              <h5>Mês</h5>
              <Input
                type="select"
                name="mes"
                defaultValue={month}
                onChange={(e) => setMonth(e.target.value)}
              >
                {monthsOptions.map((month, index) => {
                  return (
                    <option key={index} value={month.value}>
                      {month.label}
                    </option>
                  );
                })}
              </Input>
            </Col>
          </Row>
          <Row className="mb-5">
            <Col md="6">
              <h5>Hora inicial</h5>
              <ModalHourMask
                stateName={startTime}
                valueController={setStartTime}
                placeholder="08:00"
              />
              <span className="error-container">
                {exceptionErrors.hora_inicial}
              </span>
            </Col>
            <Col md="6">
              <h5>Hora final</h5>
              <ModalHourMask
                stateName={endTime}
                valueController={setEndTime}
                placeholder="23:00"
              />
              <span className="error-container">
                {exceptionErrors.hora_final}
              </span>
            </Col>
          </Row>
          <ReactButton btnColor="confirmation" onClick={addHoliday}>
            Salvar
          </ReactButton>

          <ReactButton btnColor="cancelation" onClick={toggle}>
            Cancelar
          </ReactButton>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TimeScheduleForm;
