import React, { useEffect } from 'react';
import TagClientService from 'views/CRM/Tags/services/TagClientService';
import { getFlexSelectCustomStyle } from '../../assets/styles/multiple_select';
import Select from 'react-select';
import FormTooltip from './FormTooltip';

export const FlexSelectSearchTag = ({
  setValueSelected = () => {},
  style = {},
  minHeight = '',
  setAllTags = () => {},
  value,
  noOptionsMessage = () => 'Nenhuma Opção'
}) => {
  const [options, setOptions] = React.useState([]);

  useEffect(() => {
    findTags();
  }, []);

  const findTags = async () => {
    const result = await TagClientService.getAll({ showDisabled: true });
    const resultDTO = result.data?.data?.map((tag) => ({
      label: tag.name,
      value: tag.id
    }));
    setAllTags(result.data.data);

    setOptions(resultDTO);
    return resultDTO;
  };

  const controlChange = (values) => {
    if (!values) setValueSelected([]);
    setValueSelected(values);
  };

  return (
    <div className="d-flex">
      <div style={{ flexGrow: '10' }} className="tour3">
        <label className="form-control-label">
          Tag Contato
          <FormTooltip text="Este campo de busca deve ser utilizado para filtrar conversas que possuem clientes com uma ou mais tags específicas. em caso de edição do contato o tempo para as alterações refletirem do relatório podem variar" />
        </label>
        <Select
          isSearchable={false}
          isClearable={true}
          isMulti={true}
          name="tags"
          placeholder={'Selecione uma tag'}
          styles={{ ...getFlexSelectCustomStyle({ minHeight }), ...style }}
          value={value}
          onChange={controlChange}
          closeMenuOnSelect={false}
          classNamePrefix="react-select"
          noOptionsMessage={noOptionsMessage}
          options={options}
        />
      </div>
    </div>
  );
};
