import React from 'react';

const FormTooltip = ({ text, color = '#707a95' }) => {
  return (
    <i
      className="fas fa-info-circle"
      size="sm"
      datatoggle="tooltip"
      dataplacement="top"
      title={text}
      datacontainer="body"
      dataanimation="true"
      style={{
        marginLeft: '5px',
        fontSize: '16px',
        color: color
      }}
    />
  );
};

export default FormTooltip;
