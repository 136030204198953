/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
//import { useHistory } from 'react-router-dom';
//import { brazilian } from '../../../assets/translator/pt-br';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Modal from '../../../components/Modal/NotificationModal';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import Pagination from 'components/Pagination';
import ScaleService from '../service';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Card,
  Button,
  CardHeader,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
  Label
} from 'reactstrap';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../assets/styles/table_custom_style';
import { useHistory } from 'react-router-dom';
import ReactLoader from 'components/Loader';
import { useSelector } from 'react-redux';
import { brazilian } from '../../../assets/translator/pt-br/index';

import { IconLogoHeader } from '../style/style';
import { BsFillCalendarFill } from 'react-icons/bs';
const arrayDays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];
export default function ScaleList() {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const history = useHistory();
  const [scales, setScales] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [timeoutID, setTimeoutID] = useState(null); // Timeout da busco por texto
  const [loading, setLoading] = useState(false);
  const [showDisabled, setShowDisable] = useState(false);
  const [daysofweek, setdaysofweek] = useState([]);
  const [querry, setQuerry] = useState('');

  async function loadApi(page = 1, querySearch = '') {
    setLoading(true);
    ScaleService.getAllSearch(showDisabled, 10, page, querySearch).then(
      (response) => {
        setQuantity(response.data.totalRegisters);
        const FormatedData = response.data.data;
        setLoading(false);
        const { DAYS } = brazilian;
        response.data.data.forEach((scale, index) => {
          let listDays = [];
          arrayDays.forEach((day) => {
            if (scale[day] === true) {
              for (let [key, value] of Object.entries(DAYS)) {
                if (key === day) listDays.push(value);
              }
            }
          });
          const joinDays = listDays.join(' , ');
          FormatedData[index] = { ...scale, joinDays };
        });
        setScales(FormatedData);
      }
    );
  }
  useEffect(() => {
    loadApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDisabled]);

  function handleUpdate(id) {
    history.push(`Scales/form/${id}`);
  }

  const changeActive = async (row) => {
    const FormatData = {
      ...row,
      disabled: !row.disabled
    };
    ScaleService.put(row.id, FormatData)
      .then(() => {
        toast.info(
          row.disabled
            ? 'Escala habilitada com sucesso'
            : 'Escala desabilitada com sucesso'
        );
        loadApi(activePage, querry);
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Ops algo errado aconteceu!', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };
  const handlePageChange = (page) => {
    setActivePage(page);
    loadApi(page);
  };

  const handleOnSearch = (event) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const q = event.target.value;
    setQuerry(q);
    setTimeoutID(
      setTimeout(() => {
        loadApi(1, q);
      }, 1000)
    );
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/Scales/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    alignItems: 'center'
                  }}
                >
                  <IconLogoHeader
                    backgroundColor={systemConfiguration.primary_color}
                  >
                    <BsFillCalendarFill color="white" size={20} />
                  </IconLogoHeader>
                  <h3>Escalas</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Buscar escala"
                          type="text"
                          onChange={handleOnSearch}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="4" className="text-right mt-2 ">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={showDisabled}
                            onChange={() => setShowDisable(!showDisabled)}
                          />
                          <span>Mostrar desabilitados</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={scales}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'loginTolerance',
                      text: 'Tolerância de login(minutos)',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'joinDays',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Dias trabalhados',
                      // eslint-disable-next-line react/display-name
                      formatter: (rowContent) => {
                        return (
                          <i
                            className="fas fa-info-circle"
                            size="sm"
                            datatoggle="tooltip"
                            dataplacement="top"
                            title={rowContent}
                            datacontainer="body"
                            dataanimation="true"
                            style={{ fontSize: '25px', color: '#2dce89' }}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'users',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'usuários',
                      formatter: (_, row) => {
                        return (
                          <Modal>
                            <strong>Usuários</strong>
                            {row.users.map((res, key) => {
                              return <p key={key}>{res.name}</p>;
                            })}
                          </Modal>
                        );
                      }
                    },
                    {
                      dataField: 'disabled',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Habilitar / Desabilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            checked={!row.disabled}
                            id={row.id}
                            name={row.name}
                            onChange={() => changeActive(row)}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="info"
                            size="sm"
                            title={'Editar Perfil'}
                            onClick={() => handleUpdate(row.id)}
                          >
                            <li className="fas fa-clone"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
