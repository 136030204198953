import styled from 'styled-components';

export const Form = styled.form`
  .row {
    margin-top: 20px;
  }
  .button {
    display: flex;
    justify-content: flex-start;
  }
`;
