import React, { useCallback, useState } from 'react';
import { useDialogTree } from '../context';

import { Node } from './styled';
import { AiOutlineRight } from 'react-icons/ai';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle
} from 'reactstrap';

import DialogTreeComponent from '../index';
import { optionsToCreateNewNode } from '../utils/createNewNodeOptions';
import { useSelector } from 'react-redux';
import DeleteNodeConfirmationModal from '../../components/deleteNodeConfirmationModal';
import { loopTreeData } from '../context/helpers';
const NodeTree = ({ nodeData }) => {
  const { openModal, treeData, availableActions, availableQueues } =
    useDialogTree();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [dropdownCreateNewNodeOptions, setDropdownCreateNewNodeOptions] =
    useState(false);
  const [modalExcludeNode, setModalExcludeNode] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const verifyIfNodeHasChildren = useCallback(() => {
    return nodeData.nodes && Object.keys(nodeData.nodes).length > 0;
  }, [nodeData]);

  const verifyIfNodeIsInTheFirstLevel = useCallback(() => {
    return nodeData.level === 1;
  }, [nodeData]);

  const handleAnimation = () => {
    setIsExpanded(!isExpanded);
  };

  const chooseNodeName = () => {
    if (!isTheDefaultNode()) {
      return (
        <p
          title={`Nó - ${nodeData.numericIdentifier} - ${nodeData.stringIdentifier} - Clique para editar o nó`}
        >
          {nodeData?.numericIdentifier ? (
            <>
              {nodeData.numericIdentifier} - {nodeData.stringIdentifier}
            </>
          ) : (
            <>{nodeData.stringIdentifier}</>
          )}
        </p>
      );
    } else {
      return <p title={`Nó Padrão - Clique para editar o nó`}>Nó Padrão</p>;
    }
  };

  const descriptionNodeStyle = {
    fontStyle: 'italic',
    position: 'absolute',
    bottom: '8px',
    left: '8px',
    fontSize: '0.9rem',
    color: '#565656'
  };

  const isTheDefaultNode = () => {
    return nodeData.isDefault ? true : false;
  };

  const handleNodeActions = (action) => {
    switch (action) {
      case 'ADD_NEW_NODE':
        openModal(nodeData, 'ADD_NEW_NODE');
        break;
      // case 'ADD_NEW_NODE_CHILD':
      //   openModal(nodeData, 'ADD_NEW_NODE_CHILD');
      //   break;
      case 'EDIT_NODE':
        openModal(nodeData, 'EDIT_NODE');
        break;
      case 'DELETE_NODE':
        setModalExcludeNode(true);
        break;
      default:
        break;
    }
  };

  const isLevelEmpty = () => {
    return (
      nodeData.nodes &&
      Object.keys(nodeData.nodes).length === 1 &&
      Object.keys(nodeData.nodes[0].action).length === 0
    );
  };

  const verifyIfIsTheLastLevelAndNode = () => {
    if (nodeData.nodes && Object.keys(nodeData.nodes).length === 1) {
      return false;
    } else {
      return true;
    }
  };

  const chooseDescriptionNodeName = (action) => {
    let submenuSelected = '';
    switch (action.type) {
      case 'ET':
        return `${action.description} - ${
          (availableQueues &&
            availableQueues.find((queue) => queue.id === nodeData.action.queue)
              .name) ||
          'Fila não encontrada'
        }`;
      case 'LINKMENU':
        loopTreeData(treeData, (node) => {
          if (node.id === nodeData.action.to.level) {
            node.stringIdentifier
              ? (submenuSelected = node.stringIdentifier)
              : (submenuSelected = `Nó padrão - level ${node.level}`);
          }
        });
        return `${action.description} - ${submenuSelected}`;
      default:
        return `${action.description}`;
    }
  };

  const verifyWatsNodeFatherHave = () => {
    const nodeFatherId = nodeData?.mac?.idMac;
    let actualFatherNode = null;
    loopTreeData(treeData, (node) => {
      if (node.id === nodeFatherId) {
        actualFatherNode = node;
        return node;
      }
    });

    return actualFatherNode;
  };

  const limitNodesCreation = () => {
    const nodeFather = verifyWatsNodeFatherHave();
    if (nodeFather?.action?.type === 'SUBMENU_BUTTONS') {
      if (nodeFather) {
        if (nodeFather.nodes && Object.keys(nodeFather.nodes).length >= 21) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  return (
    <>
      <li>
        <Node
          isNodeHasChildren={!verifyIfNodeHasChildren()}
          isNodeInTheFirstLevel={verifyIfNodeIsInTheFirstLevel()}
          verifyIfIsTheLastLevelAndNode={verifyIfIsTheLastLevelAndNode()}
          isNodeDefault={isTheDefaultNode()}
        >
          <div className="prev-line"></div>
          <div className="node-content">
            {verifyIfNodeHasChildren() && (
              <div className="node-expand-wrapper">
                <AiOutlineRight
                  className={isExpanded ? 'expand active' : 'expand'}
                  size={24}
                  color="#232323"
                  onClick={() => {
                    handleAnimation();
                  }}
                />
              </div>
            )}
            <div
              className="node-content-wrapper"
              onClick={() => {
                openModal(nodeData, 'EDIT_NODE');
              }}
            >
              {chooseNodeName()}
              <div className="node-actions">
                {availableActions.map((action) => {
                  if (nodeData.action.type === action.type) {
                    return (
                      <p
                        style={descriptionNodeStyle}
                        title={chooseDescriptionNodeName(action)}
                      >
                        {chooseDescriptionNodeName(action)}
                      </p>
                    );
                  }
                })}
              </div>
            </div>
            <div className="node-action-buttons">
              <Dropdown
                isOpen={dropdownCreateNewNodeOptions}
                toggle={() => {
                  setDropdownCreateNewNodeOptions(
                    !dropdownCreateNewNodeOptions
                  );
                }}
              >
                <DropdownToggle
                  color="link"
                  style={{
                    color: systemConfiguration.primary_color,
                    paddingLeft: '0',
                    paddingRight: '0'
                  }}
                  disabled={isTheDefaultNode() && limitNodesCreation()}
                >
                  <BiDotsVerticalRounded
                    size="35"
                    title="Opções dos nós"
                    color={systemConfiguration.primary_color}
                  />
                </DropdownToggle>
                <DropdownMenu className="DropdownMenu">
                  {optionsToCreateNewNode(
                    verifyIfNodeHasChildren(),
                    isTheDefaultNode(),
                    isLevelEmpty(),
                    limitNodesCreation()
                  ).map((filter, index) => {
                    return (
                      <DropdownItem
                        key={`${filter.value}+${index}}`}
                        value={filter.value}
                        style={{
                          color:
                            filter.value === 'DELETE_NODE' &&
                            systemConfiguration.cancelation_button_color
                        }}
                        onClick={() => {
                          handleNodeActions(filter.value);
                        }}
                      >
                        {filter.label}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
        </Node>
        {verifyIfNodeHasChildren() && isExpanded && (
          <>
            {/* <div className="prev-line"></div> */}
            <ul>
              <DialogTreeComponent data={nodeData.nodes || {}} />
            </ul>
          </>
        )}
      </li>
      <DeleteNodeConfirmationModal
        isModalOpen={modalExcludeNode}
        onModalToggle={setModalExcludeNode}
        nodeData={nodeData}
        nodeHasChildren={verifyIfNodeHasChildren()}
      />
    </>
  );
};

export default NodeTree;
