export const formatCurrencyus = (value) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });
  
    const integer = Math.floor(value / 100);
    const decimals = value % 100;
    const fullValue = `${integer}.${decimals < 10 ? `0${decimals}` : decimals}`;
  
    return formatter.format(fullValue);
  };
  