import { useEffect, useState } from 'react';
import UserService from '../../../User/service';
import { toast } from 'react-toastify';

export function useGetUsers(usersShowDisable) {
  const [users, setUsers] = useState([]);

  
  async function getUsers() {
    try {
      const response = await UserService.getAll(false, undefined, undefined, usersShowDisable);
      const data = response.data.data;
      setUsers(data);
    } catch (err) {
      const message =
        (err.response && err.response.data) || 'Erro ao carregar usuários';
      toast.error(message, {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  }

  useEffect(() => {
    getUsers();
  }, [usersShowDisable]);

  return { users };
}
