const INITIAL_STATE = {
  isDrawerCollapsed: true,
  data: {
    tabId: undefined
  }
};

export default function sideDrawerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@SIDEDRAWER_OPEN':
      return {
        ...state,
        isDrawerCollapsed: false,
        data: action.payload
      };
    case '@SIDEDRAWER_CLOSE':
      return {
        ...state,
        isDrawerCollapsed: true,
        data: {
          tabId: undefined
        }
      };
    case '@SIDEDRAWER_TOGGLE':
      return {
        ...state,
        isDrawerCollapsed: !state.isDrawerCollapsed
      };
    default:
      return state;
  }
}
