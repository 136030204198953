/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button,
  Col,
  Input,
  FormGroup,
  Label
} from 'reactstrap';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';

import Loader from 'components/Loader';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import Pagination from 'components/Pagination';
import OpportunityStageService from '../service/index';
import { toast } from 'react-toastify';

import { useListOpportunityStages } from '../hooks/useListOpportunityStages';

const OpportunityStages = () => {
  const [activePage, setActivePage] = useState(1);
  const [showDisabled, setShowDisable] = useState(false);

  const {
    getOpportunityStages,
    opportunityStages,
    totalOpportunityStages,
    isLoadingOpportunityStages
  } = useListOpportunityStages();

  useEffect(() => {
    getOpportunityStages(1);
  }, []);

  useEffect(() => {
    getOpportunityStages(1, { showDisabled: showDisabled ? 'true' : 'false' });
  }, [showDisabled]);

  const goToPage = (newPage) => {
    setActivePage(newPage);
    getOpportunityStages(newPage);
  };

  const handleEnableOpportunityStage = async (id) => {
    await OpportunityStageService.enable(id);
  };

  const handleDisableOpportunityStage = async (id) => {
    await OpportunityStageService.disable(id);
  };

  const toggleOpportunityStageStatus = ({ id, isActive }) => {
    try {
      if (isActive) {
        handleDisableOpportunityStage(id);
      } else {
        handleEnableOpportunityStage(id);
      }
      toast.success(
        `Fase da oportunidade ${
          isActive ? 'desabilitada' : 'habilitada'
        } com sucesso`
      );
    } catch (error) {
      const msg = error?.response?.data?.message;
      toast.error(
        msg ||
          `Erro ao ${
            isActive ? 'desabilitar' : 'habilitar'
          } fase da oportunidade`
      );
    }
    setTimeout(() => {
      goToPage(1);
    }, 100);
  };

  const stages = [
    {
      displayName: 'Aberto',
      typeNameUpper: 'OPEN'
    },
    {
      displayName: 'Fechado - Perdido',
      typeNameUpper: 'CLOSED_LOST'
    },
    {
      displayName: 'Fechado - Ganho',
      typeNameUpper: 'CLOSED_WON'
    }
  ];

  const showTypeName = (typeName) => {
    const entries = Object.values(stages);
    const name = entries.map((name) => {
      if (name.typeNameUpper === typeName) {
        return name.displayName;
      } else {
        return '';
      }
    });
    return name;
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/crm/opportunityStages/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Fases da Oportunidade</h3>
                </CardHeader>

                <Row className="mt-3 mx-2">
                  <Col md="12" className="text-right mt-2 mt-md-1">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={showDisabled}
                          onChange={() => setShowDisable(!showDisabled)}
                        />
                        <span>Mostrar desabilitados</span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <CardBody>
                  {isLoadingOpportunityStages ? (
                    <div className="col-12">
                      <Row className="justify-content-md-center">
                        <Loader />
                      </Row>
                    </div>
                  ) : (
                    <Table hover={true}>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th className="text-center">Tipo</th>
                          <th className="text-center">Editar</th>
                          <th className="text-center">Habilitar/Desabilitar</th>
                        </tr>
                      </thead>
                      <tbody>
                        {opportunityStages &&
                          opportunityStages.map((opportunityStage) => {
                            return (
                              <tr key={opportunityStage.id}>
                                <td>{opportunityStage.name}</td>
                                <td className="text-center">
                                  {showTypeName(opportunityStage.type)}
                                </td>
                                <td className="text-center" width="10%">
                                  <Link
                                    to={
                                      '/admin/crm/opportunityStages/form/' +
                                      opportunityStage.id
                                    }
                                  >
                                    <Button color="info" size="sm">
                                      <li className="fas fa-user-edit"></li>
                                    </Button>
                                  </Link>
                                </td>
                                <td className="text-center" width="10%">
                                  <ToggleSwitch
                                    checked={opportunityStage.isActive}
                                    id={opportunityStage.id}
                                    onChange={() =>
                                      toggleOpportunityStageStatus({
                                        id: opportunityStage.id,
                                        isActive: opportunityStage.isActive
                                      })
                                    }
                                  />
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={totalOpportunityStages}
                  onChange={goToPage.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OpportunityStages;
