import styled from 'styled-components';

export const ChatDialogTreeContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  .prev-line {
    display: block;
    height: 100%;
    width: 2px;
    background-color: #a2adb5;
    position: absolute;
    top: 0;
  }

  ul {
    list-style: none;
    transition: all 0.5s ease-in-out;
    width: inherit;

    li {
      position: relative;
      list-style: none;
      transition: all 0.5s ease-in-out;
      flex-direction: row;
    }
  }
`;
