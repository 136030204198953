import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.isGrouped
      ? '  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr))'
      : 'repeat(auto-fill, minmax(11rem, 2fr))'};
  gap: 20px;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    2,
    1fr
  ); /* 2 colunas, cada uma ocupando 1fr (50%) da largura disponível */
  gap: 20px;
`;

export const GridItem = styled.div`
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
`;

export const SmallCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 6px;
`;

export const CardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 6px;
`;
