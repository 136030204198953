import React, { useCallback } from 'react';
import { Controller } from 'react-hook-form';
import { Col, Input } from 'reactstrap';

import Datepicker from 'components/Inputs/DatePicker';
import FlexSelect from 'components/Inputs/FlexSelect';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorFormMessage } from 'components/ErrorFormMessage';

const AdvancedSearch = ({
  customFields,
  control,
  setValue,
  error,
  getValues,
  watch,
  toISOFormat
}) => {
  const getTextFields = useCallback(() => {
    if (!customFields) return [];
    return customFields.filter(
      (f) =>
        f.type === 'TEXT' ||
        f.type === 'TELEPHONE' ||
        f.type === 'CPF' ||
        f.type === 'CNPJ'
    );
  }, [customFields]);

  const getListFields = useCallback(() => {
    if (!customFields) return [];
    return customFields.filter((f) => f.type === 'LIST');
  }, [customFields]);

  const getSelectMultipleFields = useCallback(() => {
    if (!customFields) return [];
    return customFields.filter((f) => f.type === 'MULTIPLE');
  }, [customFields]);

  const getNumericFields = useCallback(() => {
    if (!customFields) return [];
    return customFields.filter((f) => f.type === 'NUMERIC');
  }, [customFields]);

  const getDateFields = useCallback(() => {
    if (!customFields) return [];
    return customFields.filter((f) => f.type === 'DATE');
  }, [customFields]);

  const dateRules = {
    pattern: {
      value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
      message: 'Data inválida! (dd/MM/aaaa)'
    }
  };

  const formValue = watch();
  console.log('formValue', formValue);

  return (
    <>
      {customFields &&
        getTextFields().map((textField) => {
          return (
            <Col md={{ size: 6 }} key={textField.id} className="mb-3">
              <label className="form-control-label text-capitalize">
                {textField.label}
              </label>
              <Controller
                as={Input}
                control={control}
                defaultValue=""
                name={textField.name}
              />
            </Col>
          );
        })}

      {customFields &&
        getListFields().map((boxField, index) => {
          return (
            <Col md={{ size: 6 }} key={index} className="mb-3">
              <label className="form-control-label">{boxField.label}</label>
              <Controller
                as={
                  <Input type="select">
                    <option value="">
                      {`Selecione o(a) ${boxField.label}`}
                    </option>
                    {boxField.options.map((value, index) => (
                      <option key={index} value={value?.value ?? value}>
                        {value?.value ?? value}
                      </option>
                    ))}
                  </Input>
                }
                defaultValue=""
                control={control}
                name={boxField.name}
              />
            </Col>
          );
        })}

      {customFields &&
        getSelectMultipleFields().map((boxField, index) => {
          const options =
            boxField.options.map((item) => {
              return { label: item?.value ?? item, value: item?.value ?? item };
            }) ?? [];

          return (
            <Col md={{ size: 6 }} key={index} className="mb-3">
              <label className="form-control-label">{boxField.label}</label>
              <Controller
                render={(props) => {
                  return (
                    <>
                      <FlexSelect
                        isClearable={true}
                        dataOptions={options}
                        isMulti={true}
                        closeMenuOnSelect={false}
                        value={props.value}
                        valueController={setValue}
                        fieldName={boxField.name}
                        name={boxField.name}
                        labelName="label"
                        valueName="value"
                      />
                    </>
                  );
                }}
                defaultValue={''}
                control={control}
                name={boxField.name}
              />
            </Col>
          );
        })}

      {customFields &&
        getNumericFields().map((protocolNumberField, index) => {
          return (
            <Col md={{ size: 6 }} key={index} className="mb-3">
              <label className="form-control-label text-capitalize">
                {protocolNumberField.label}
              </label>
              <Controller
                as={Input}
                control={control}
                type="number"
                step="false"
                defaultValue=""
                name={protocolNumberField.name}
              />
            </Col>
          );
        })}

      {customFields &&
        getDateFields().map((field) => {
          return (
            <>
              <Col md={{ size: 6 }} key={field.id} className="mb-3">
                <>
                  <label htmlFor={field.name}>
                    {field.label} (Data Inicial)
                  </label>
                  <Controller
                    render={(props) => (
                      <Datepicker
                        fieldName={field.name + '.DateBegin'}
                        defaultValue={props.value}
                        valueController={setValue}
                      />
                    )}
                    control={control}
                    defaultValue=""
                    name={field.name + '.DateBegin'}
                    rules={{
                      ...dateRules,
                      required: getValues(field.name + '.DateEnd')
                        ? 'Data é obrigatória'
                        : false
                    }}
                    id={field.name}
                  />
                  <ErrorMessage
                    errors={error}
                    name={field.name + '.DateBegin'}
                    render={({ message }) => (
                      <ErrorFormMessage message={message} />
                    )}
                  />
                </>
              </Col>
              <Col md={{ size: 6 }} key={field.id} className="mb-3">
                <>
                  <label htmlFor={field.name}>{field.label} (Data Final)</label>
                  <Controller
                    render={(props) => (
                      <Datepicker
                        fieldName={field.name + '.DateEnd'}
                        defaultValue={props.value}
                        valueController={setValue}
                      />
                    )}
                    control={control}
                    defaultValue=""
                    name={field.name + '.DateEnd'}
                    id={field.name}
                    rules={{
                      ...dateRules,
                      required: getValues(field.name + '.DateBegin')
                        ? 'Data é obrigatória'
                        : false,
                      validate: async (value) => {
                        if (
                          new Date(await toISOFormat(value, true)) <
                          new Date(
                            await toISOFormat(
                              getValues(field.name + '.DateBegin'),
                              true
                            )
                          )
                        ) {
                          return 'A data final não pode ser anterior à inicial!';
                        } else {
                          return true;
                        }
                      }
                    }}
                  />
                  <ErrorMessage
                    errors={error}
                    name={field.name + '.DateEnd'}
                    render={({ message }) => (
                      <ErrorFormMessage message={message} />
                    )}
                  />
                </>
              </Col>
            </>
          );
        })}
    </>
  );
};

export default AdvancedSearch;
