import React, { useEffect, useState } from 'react';
import Chart from 'chart.js';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { Row, Card, Col, CardBody, CardHeader, Container } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import randomColor from 'randomcolor';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';
import {
  chartOptions,
  parseOptions,
  chartExample2,
  chartExample3,
  chartExample6
} from 'variables/charts.js';

const Synthetic = ({ dataReport }) => {
  const [lineChartData, setLineChartData] = useState(null);
  const [barChartData, setBarChartData] = useState(null);
  const [pieChartData, setPieChartData] = useState(null);

  useEffect(() => {
    if (window.Chart) parseOptions(Chart, chartOptions());

    if (dataReport.listCallInSyntheticDTO)
      if (dataReport.listCallInSyntheticDTO.length > 0) {
        let arrayLabels = [];
        let arrayData = [];
        let colorsData = [];

        dataReport.listCallInSyntheticDTO.forEach((data) => {
          arrayLabels.push(data.queue);
          arrayData.push(data.quantity);
          colorsData.push(randomColor({ hue: 'green' }));
        });

        setBarChartData({
          labels: [...arrayLabels],
          datasets: [
            {
              data: [...arrayData],
              maxBarThickness: 50,
              backgroundColor: [...colorsData]
            }
          ]
        });
        setPieChartData({
          labels: [...arrayLabels],
          datasets: [
            {
              data: [...arrayData],
              backgroundColor: [...colorsData]
            }
          ]
        });
      }

    if (dataReport.listCallForHourDto)
      if (dataReport.listCallForHourDto.length > 0) {
        let arrayLabels = [];
        let arrayAnswer = [];
        let arrayNoAnswer = [];

        dataReport.listCallForHourDto.forEach((data) => {
          arrayLabels.push(data.hour);
          arrayAnswer.push(data.quantityAnswer);
          arrayNoAnswer.push(data.quantityNoAnswer);
        });

        setLineChartData({
          labels: [...arrayLabels],
          datasets: [
            {
              label: 'Atendida',
              data: [...arrayAnswer],
              borderColor: '#5cb85c',
              pointBackgroundColor: '#5cb85c'
            },
            {
              label: 'Não atendida',
              data: [...arrayNoAnswer],
              borderColor: '#d9534f',
              pointBackgroundColor: '#d9534f'
            }
          ]
        });
      }
  }, [dataReport]);

  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <ToolkitProvider
                data={dataReport.listCallForQueueDto || []}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'status',
                    text: 'Status da Chamada',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'quantity',
                    text: 'Quantidade',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tma',
                    text: 'Tempo médio de Atendimento',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tta',
                    text: 'Tempo total de atendimento',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tme',
                    text: 'Tempo médio de espera',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tte',
                    text: 'Tempo total de espera',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>

              <Container fluid>
                {pieChartData && barChartData && (
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardHeader>
                          <h6 className="surtitle">Visão Geral</h6>
                          <h5 className="h3 mb-0">
                            Quantidade de Chamadas por Fila
                          </h5>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col xl="6">
                              <div className="chart">
                                <Pie
                                  data={pieChartData}
                                  options={chartExample6.options}
                                  className="chart-canvas"
                                  id="chart-pie"
                                />
                              </div>
                            </Col>
                            <Col xl="6">
                              <div className="chart">
                                <Bar
                                  data={barChartData}
                                  options={chartExample2.options}
                                  className="chart-canvas"
                                  id="chart-bars"
                                />
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {lineChartData && (
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardHeader>
                          <h6 className="surtitle">Visão Geral</h6>
                          <h5 className="h3 mb-0">Chamadas por Hora</h5>
                        </CardHeader>
                        <CardBody>
                          <div className="chart">
                            <Line
                              data={lineChartData}
                              options={chartExample3.options}
                              id="chart-sales"
                              className="chart-canvas"
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
              </Container>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Synthetic;
