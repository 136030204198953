import styled from 'styled-components';

export const ImageContainer = styled.div`
  .input-block label {
    display: flex;
    color: red;
    margin: 10px 100px;
    line-height: 24px;
  }

  .input-block label span {
    font-size: 14px;
    color: red;
    margin-left: 24px;
    line-height: 24px;
  }

  .input-block input {
    width: 100%;
    background: #f5f8fa;
    border: 1px solid #d3e2e5;
    border-radius: 20px;
    outline: none;
    color: #5c8599;
  }

  .input-block input {
    //height: 64px;
    padding: 0 16px;
  }

  .input-block .images-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    // grid-template-columns: repeat(5, 1fr);
    //grid-gap: 50px;
  }

  .input-block .images-container img {
    width: 300px;
    height: 200px;

    object-fit: contain;
    border-radius: 20px;
    cursor: pointer;
  }

  .input-block .new-image {
    width: 300px;
    height: 200px;
    background: #f5f8fa;
    ${(props) => props.mainColor && `border: 1px dashed ${props.mainColor};`}
    border-radius: 20px;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .input-block input[type='file'] {
    visibility: hidden;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  margin: 20px 0;
  flex-wrap: wrap;

  input[type='color'] {
    visibility: hidden;
  }
`;

export const PreviewButton = styled.label`
  ${(props) => props.color && `background-color: ${props.color};`}

  border: none;
  border-radius: 4px;
  color: white;
  padding: 13px 40px;
  width: 200px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
`;

export const PreviewColor = styled.label`
  ${(props) => props.color && `background-color: ${props.color};`}

  border: none;
  border-radius: 4px;
  color: white;
  width: 150px;
  height: 70px;
  text-align: center;
  text-decoration: none;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
`;
