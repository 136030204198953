import React, { useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FiUpload } from 'react-icons/fi';
import {
  ColorSection,
  ColorInput,
  ProfilePicSection,
  UploadButton,
  ProfileImage,
  ProfileArea,
  CoverSection,
  CoverImage
} from '../styles/WebChatForm.style';
import { Row, Col, Input, InputGroup, InputGroupText } from 'reactstrap';
import FlexSelect from 'components/Inputs/FlexSelect';
import { ButtonContainer } from 'views/SystemParams/form/components/styles';
import { PreviewColor } from 'views/SystemParams/form/components/styles';

export const WebChatForm = ({ canal, control, errors, setValue }) => {
  const [previewProfile, setPreviewProfile] = useState('');
  const [previewCover, setPreviewCover] = useState('');
  const [previewIconOfOpeningChat, setPreviewIconOfOpeningChat] = useState('');
  const [imageOfOpeningChat, setImageOfOpeningChat] = useState('');
  const [chatOpeningType, setChatOpeningType] = useState('icon');

  const hexFormat = (color) => {
    if (color.length > 7) {
      return color.substring(0, 7);
    }
    if (!color.startsWith('#')) {
      return '#' + color;
    }
    return color;
  };

  useEffect(() => {
    canal && canal.logoChat
      ? setPreviewCover(canal.logoChat)
      : setPreviewCover('');

    canal && canal.profilePic
      ? setPreviewProfile(canal.profilePic)
      : setPreviewProfile('');

    canal && canal.iconOfOpeningChat
      ? setPreviewIconOfOpeningChat(canal.iconOfOpeningChat)
      : setPreviewIconOfOpeningChat('');

    canal && canal.imageOfOpeningChat
      ? setImageOfOpeningChat(canal.imageOfOpeningChat)
      : setImageOfOpeningChat('');

    canal && canal.typeOfOpeningChat
      ? setChatOpeningType(canal.typeOfOpeningChat)
      : setChatOpeningType('ICON');
  }, [canal]);

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <hr />
      <h3>Personalização</h3>
      <Row style={{ marginTop: '4px' }}>
        <Col md="6">
          <label className="form-control-label">Nome do canal</label>
          <Controller
            as={Input}
            control={control}
            name="nome_canal"
            defaultValue={canal?.nome_canal ?? ''}
            rules={{
              required: 'Nome é um campo obrigatório'
            }}
          />
          <ErrorMessage
            errors={errors}
            name="nome_canal"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="3">
          <label className="form-control-label">Nome do atendente</label>
          <Controller
            as={Input}
            control={control}
            name="nome_atendente"
            rules={{
              required: 'Nome do atendente é um campo obrigatório'
            }}
            defaultValue={canal?.nome_atendente ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="nome_atendente"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="3">
          <label className="form-control-label">Delay entre mensagens</label>
          <Controller
            as={() => (
              <InputGroup>
                <Input
                  type="number"
                  max={4}
                  min={0}
                  defaultValue={canal?.messageDelay ?? 0}
                  onChange={(field) => {
                    setValue('messageDelay', Number(field?.target?.value));
                  }}
                />
                <InputGroupText>Segundos</InputGroupText>
              </InputGroup>
            )}
            control={control}
            name="messageDelay"
            defaultValue={Number(canal?.messageDelay ?? 0)}
            rules={{
              max: {
                message: 'Tempo entre mensagens deve ser menor que 4 segundos',
                value: 4
              }
            }}
          />
          <ErrorMessage
            errors={errors}
            name="messageDelay"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: '8px' }}>
        <Col style={{ margin: 0 }}>
          <ColorSection>
            <label className="form-control-label">Cor do Webchat</label>
            <Controller
              render={(props) => {
                return (
                  <>
                    <ButtonContainer style={{ margin: 0 }}>
                      <PreviewColor color={props.value} htmlFor="cor" />

                      <ColorInput
                        onChange={(event) => {
                          setValue('cor', event.target.value);
                        }}
                        value={props.value}
                        type="color"
                        name="cor"
                        id="cor"
                      />
                    </ButtonContainer>

                    <input
                      type="text"
                      onChange={(event) => {
                        setValue('cor', hexFormat(event.target.value));
                      }}
                      style={{ border: 'none' }}
                      value={props.value}
                    />
                  </>
                );
              }}
              control={control}
              name="cor"
              defaultValue="#0433ff"
            />
          </ColorSection>
        </Col>
        <Col>
          <ColorSection>
            <label className="form-control-label">Cor do hyperlink</label>
            <Controller
              render={(props) => {
                return (
                  <>
                    <ButtonContainer style={{ margin: 0 }}>
                      <PreviewColor
                        color={props.value}
                        htmlFor="colorHyperlink"
                      />

                      <ColorInput
                        onChange={(event) => {
                          setValue('colorHyperlink', event.target.value);
                        }}
                        id="colorHyperlink"
                        value={props.value}
                        type="color"
                        name="colorHyperlink"
                        defaultValue={canal?.colorHyperlink || '#021369'}
                      />
                    </ButtonContainer>

                    <input
                      type="text"
                      onChange={(event) => {
                        setValue(
                          'colorHyperlink',
                          hexFormat(event.target.value)
                        );
                      }}
                      style={{ border: 'none' }}
                      value={props.value}
                    />
                  </>
                );
              }}
              control={control}
              name="colorHyperlink"
              defaultValue={canal?.colorHyperlink || '#021369'}
            />
          </ColorSection>
        </Col>
        <Col>
          <ColorSection>
            <label className="form-control-label">Cor do texto</label>
            <Controller
              render={(props) => {
                return (
                  <>
                    <ButtonContainer style={{ margin: 0 }}>
                      <PreviewColor
                        color={props.value}
                        htmlFor="textContrastColor"
                      />

                      <ColorInput
                        onChange={(event) => {
                          setValue('textContrastColor', event.target.value);
                        }}
                        id="textContrastColor"
                        value={props.value}
                        defaultValue={canal?.textContrastColor || '#ffffff'}
                        type="color"
                        name="textContrastColor"
                      />
                    </ButtonContainer>

                    <input
                      type="text"
                      onChange={(event) => {
                        setValue(
                          'textContrastColor',
                          hexFormat(event.target.value)
                        );
                      }}
                      style={{ border: 'none' }}
                      value={props.value}
                    />
                  </>
                );
              }}
              control={control}
              name="textContrastColor"
              defaultValue={canal?.textContrastColor || '#ffffff'}
            />
          </ColorSection>
        </Col>
        <Col>
          <ColorSection>
            <label className="form-control-label">Cor do botão de login</label>
            <Controller
              render={(props) => {
                return (
                  <>
                    <ButtonContainer style={{ margin: 0 }}>
                      <PreviewColor
                        color={props.value}
                        htmlFor="backgroundButtonLogin"
                      />

                      <ColorInput
                        onChange={(event) => {
                          setValue('backgroundButtonLogin', event.target.value);
                        }}
                        id="backgroundButtonLogin"
                        value={props.value}
                        defaultValue={canal?.backgroundButtonLogin || '#ffffff'}
                        type="color"
                        name="backgroundButtonLogin"
                      />
                    </ButtonContainer>

                    <input
                      type="text"
                      onChange={(event) => {
                        setValue(
                          'backgroundButtonLogin',
                          hexFormat(event.target.value)
                        );
                      }}
                      style={{ border: 'none' }}
                      value={props.value}
                    />
                  </>
                );
              }}
              control={control}
              name="backgroundButtonLogin"
              defaultValue={canal?.backgroundButtonLogin || '#ffffff'}
            />
          </ColorSection>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginTop: '4px' }}>
          <CoverSection>
            <div>
              <label className="form-control-label">Logo do Webchat</label>
              {previewCover && (
                <CoverImage>
                  <img src={previewCover} alt="logo chat" />
                </CoverImage>
              )}
            </div>
            <label htmlFor="logoChat">
              <UploadButton>
                {canal?.logoChat ? (
                  <span>Enviar outra imagem</span>
                ) : (
                  <span>Enviar imagem</span>
                )}
                <FiUpload />
              </UploadButton>
              <Controller
                name="logoChat"
                control={control}
                rules={{
                  required: 'Logo do Webchat é um campo obrigatório'
                }}
                defaultValue={''}
                render={() => (
                  <Input
                    id="logoChat"
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/png"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.onload = () => {
                        setPreviewCover(reader.result);
                      };
                      reader.readAsDataURL(file);
                      setValue('logoChat', e.target.files[0]);
                    }}
                    onClick={() => console.log('clicou!')}
                    multiple={false}
                  />
                )}
              />
            </label>
            <ErrorMessage
              errors={errors}
              name="logoChat"
              render={({ message }) => errorFormMessage(message)}
            />
          </CoverSection>
        </Col>
        <Col style={{ marginTop: '4px' }}>
          <ProfilePicSection>
            <div>
              <label className="form-control-label">Imagem do Perfil</label>
              {previewProfile && (
                <ProfileArea>
                  <ProfileImage>
                    <img src={previewProfile} alt="profile pic" />
                  </ProfileImage>
                </ProfileArea>
              )}
            </div>
            <label htmlFor="profilePic">
              <UploadButton>
                {canal?.profilePic ? (
                  <span>Enviar outra imagem</span>
                ) : (
                  <span>Enviar imagem</span>
                )}
                <FiUpload />
              </UploadButton>
              <Controller
                rules={{
                  required: 'Imagem do Perfil é um campo obrigatório'
                }}
                name="profilePic"
                control={control}
                defaultValue={''}
                render={() => (
                  <Input
                    id="profilePic"
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/png"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      const reader = new FileReader();
                      reader.onload = () => {
                        setPreviewProfile(reader.result);
                      };
                      reader.readAsDataURL(file);
                      setValue('profilePic', e.target.files[0]);
                    }}
                    multiple={false}
                  />
                )}
              />
            </label>
            <ErrorMessage
              errors={errors}
              name="profilePic"
              render={({ message }) => errorFormMessage(message)}
            />
          </ProfilePicSection>
        </Col>
      </Row>
      <Row>
        <Col md="6" style={{ marginTop: '8px' }}>
          <label className="form-control-label">
            Tipo de visualização de abertura
          </label>
          <Controller
            control={control}
            name="typeOfOpeningChat"
            render={(props) => (
              <FlexSelect
                dataOptions={[
                  {
                    label: 'Texto',
                    value: 'TEXT'
                  },
                  {
                    label: 'Icone',
                    value: 'ICON'
                  },
                  {
                    label: 'Imagem',
                    value: 'IMAGE'
                  }
                ]}
                labelName="label"
                valueName="value"
                fieldName="typeOfOpeningChat"
                isClearable={true}
                closeMenuOnSelect={true}
                valueController={setValue}
                value={props.value}
                onChange={(value) => {
                  setChatOpeningType(value);
                }}
              />
            )}
          />
        </Col>
      </Row>
      <Row>
        {chatOpeningType === 'ICON' ? (
          <Col md="6" style={{ marginTop: '8px' }}>
            <CoverSection>
              <div>
                <label className="form-control-label">
                  Icone de abertura do chat
                </label>
                {previewIconOfOpeningChat && (
                  <ProfileImage>
                    <img
                      src={previewIconOfOpeningChat}
                      alt="icone de abertura do chat"
                    />
                  </ProfileImage>
                )}
              </div>
              <label htmlFor="iconOfOpeningChat">
                <UploadButton>
                  {canal?.iconOfOpeningChat ? (
                    <span>Enviar outra imagem</span>
                  ) : (
                    <span>Enviar imagem</span>
                  )}
                  <FiUpload />
                </UploadButton>
                <Controller
                  name="iconOfOpeningChat"
                  control={control}
                  rules={{
                    required: 'Logo do Webchat é um campo obrigatório'
                  }}
                  defaultValue={''}
                  render={() => (
                    <Input
                      id="iconOfOpeningChat"
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/png"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onload = () => {
                          setPreviewIconOfOpeningChat(reader.result);
                        };
                        reader.readAsDataURL(file);
                        setValue('iconOfOpeningChat', e.target.files[0]);
                      }}
                      multiple={false}
                    />
                  )}
                />
              </label>
              <ErrorMessage
                errors={errors}
                name="iconOfOpeningChat"
                render={({ message }) => errorFormMessage(message)}
              />
            </CoverSection>
          </Col>
        ) : chatOpeningType === 'TEXT' ? (
          <Col md="6" style={{ marginTop: '8px' }}>
            <label className="form-control-label">Nome de abertura</label>
            <Controller
              as={Input}
              control={control}
              name="nameOfOpeningChat"
              defaultValue={canal?.nameOfOpeningChat ?? ''}
              rules={{
                required: 'Nome de abertura é um campo obrigatório'
              }}
            />
            <ErrorMessage
              errors={errors}
              name="nameOfOpeningChat"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        ) : chatOpeningType === 'IMAGE' ? (
          <Col md="6" style={{ marginTop: '8px' }}>
            <CoverSection>
              <div>
                <label className="form-control-label">
                  Icone de abertura do chat
                </label>
                {imageOfOpeningChat && (
                  <ProfileImage>
                    <img
                      src={imageOfOpeningChat}
                      alt="icone de abertura do chat"
                    />
                  </ProfileImage>
                )}
              </div>
              <label htmlFor="imageOfOpeningChat">
                <UploadButton>
                  {canal?.imageOfOpeningChat ? (
                    <span>Enviar outra imagem</span>
                  ) : (
                    <span>Enviar imagem</span>
                  )}
                  <FiUpload />
                </UploadButton>
                <Controller
                  name="imageOfOpeningChat"
                  control={control}
                  rules={{
                    required: 'Logo do Webchat é um campo obrigatório'
                  }}
                  defaultValue={''}
                  render={() => (
                    <Input
                      id="imageOfOpeningChat"
                      style={{ display: 'none' }}
                      type="file"
                      accept="image/png"
                      onChange={(e) => {
                        const file = e.target.files[0];
                        const reader = new FileReader();
                        reader.onload = () => {
                          setImageOfOpeningChat(reader.result);
                        };
                        reader.readAsDataURL(file);
                        setValue('imageOfOpeningChat', e.target.files[0]);
                      }}
                      multiple={false}
                    />
                  )}
                />
              </label>
              <ErrorMessage
                errors={errors}
                name="imageOfOpeningChat"
                render={({ message }) => errorFormMessage(message)}
              />
            </CoverSection>
          </Col>
        ) : null}
      </Row>

      <Row>
        <Col md="6" style={{ marginTop: '8px' }}>
          <h4>Personalização do balão de mensagem do atendente</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <ColorSection>
            <label className="form-control-label">Cor de fundo do balão</label>
            <Controller
              render={(props) => {
                return (
                  <>
                    <ButtonContainer style={{ margin: 0 }}>
                      <PreviewColor
                        color={props.value}
                        htmlFor="backgroundBallonAttendant"
                      />

                      <ColorInput
                        onChange={(event) => {
                          setValue(
                            'backgroundBallonAttendant',
                            event.target.value
                          );
                        }}
                        value={props.value}
                        defaultValue={
                          canal?.backgroundBallonAttendant || '#0433ff'
                        }
                        type="color"
                        name="backgroundBallonAttendant"
                        id="backgroundBallonAttendant"
                      />
                    </ButtonContainer>

                    <input
                      type="text"
                      onChange={(event) => {
                        setValue(
                          'backgroundBallonAttendant',
                          hexFormat(event.target.value)
                        );
                      }}
                      style={{ border: 'none' }}
                      value={props.value}
                    />
                  </>
                );
              }}
              control={control}
              name="backgroundBallonAttendant"
              defaultValue={canal?.backgroundBallonAttendant || '#0433ff'}
            />
          </ColorSection>
        </Col>

        <Col>
          <ColorSection>
            <label className="form-control-label">Cor do texto do balão</label>
            <Controller
              render={(props) => {
                return (
                  <>
                    <ButtonContainer style={{ margin: 0 }}>
                      <PreviewColor
                        color={props.value}
                        htmlFor="textColorBallonAttendant"
                      />

                      <ColorInput
                        onChange={(event) => {
                          setValue(
                            'textColorBallonAttendant',
                            event.target.value
                          );
                        }}
                        value={props.value}
                        type="color"
                        name="textColorBallonAttendant"
                        id="textColorBallonAttendant"
                      />
                    </ButtonContainer>

                    <input
                      type="text"
                      onChange={(event) => {
                        setValue(
                          'textColorBallonAttendant',
                          hexFormat(event.target.value)
                        );
                      }}
                      style={{ border: 'none' }}
                      value={props.value}
                    />
                  </>
                );
              }}
              control={control}
              name="textColorBallonAttendant"
              defaultValue="#0433ff"
            />
          </ColorSection>
        </Col>
      </Row>

      <Row>
        <Col md="6" style={{ marginTop: '8px' }}>
          <h4>Personalização do balão de mensagem do cliente</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <ColorSection>
            <label className="form-control-label">Cor de fundo do balão</label>
            <Controller
              render={(props) => {
                return (
                  <>
                    <ButtonContainer style={{ margin: 0 }}>
                      <PreviewColor
                        color={props.value}
                        htmlFor="backgroundBallonClient"
                      />

                      <ColorInput
                        onChange={(event) => {
                          setValue(
                            'backgroundBallonClient',
                            event.target.value
                          );
                        }}
                        value={props.value}
                        type="color"
                        name="backgroundBallonClient"
                        id="backgroundBallonClient"
                      />
                    </ButtonContainer>

                    <input
                      type="text"
                      onChange={(event) => {
                        setValue(
                          'backgroundBallonClient',
                          hexFormat(event.target.value)
                        );
                      }}
                      style={{ border: 'none' }}
                      value={props.value}
                    />
                  </>
                );
              }}
              control={control}
              name="backgroundBallonClient"
              defaultValue="#e7e7e7"
            />
          </ColorSection>
        </Col>

        <Col>
          <ColorSection>
            <label className="form-control-label">Cor do texto do balão</label>
            <Controller
              render={(props) => {
                return (
                  <>
                    <ButtonContainer style={{ margin: 0 }}>
                      <PreviewColor
                        color={props.value}
                        htmlFor="textColorBallonClient"
                      />

                      <ColorInput
                        onChange={(event) => {
                          setValue('textColorBallonClient', event.target.value);
                        }}
                        value={props.value}
                        type="color"
                        name="textColorBallonClient"
                        id="textColorBallonClient"
                      />
                    </ButtonContainer>

                    <input
                      type="text"
                      onChange={(event) => {
                        setValue(
                          'textColorBallonClient',
                          hexFormat(event.target.value)
                        );
                      }}
                      style={{ border: 'none' }}
                      value={props.value}
                    />
                  </>
                );
              }}
              control={control}
              name="textColorBallonClient"
              defaultValue="#e7e7e7"
            />
          </ColorSection>
        </Col>
      </Row>
    </>
  );
};
