import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 30px;

  label {
    margin-top: 10px;
  }

  .propertEmail {
    label {
      font-size: 12px;
    }
  }
  .editorText {
    height: 140px;
    margin-bottom: 30px;
  }

  .checkbox {
    height: 16px;
    width: 16px;
  }

  .chipTrue {
    background: #00a17c;
    color: #fff;
    border-radius: 7px;
    font-size: 12px;
  }

  .chipFalse {
    background: #ee6363;
    color: #fff;
    border-radius: 7px;
    font-size: 12px;
  }

  .contentCheckBox {
    display: flex;
    margin-bottom: 40px;
    margin-top: 20px;
    border-left: 2px solid #00a17c;
    align-items: center;

    p {
      font-size: 12px;
      font-family: sans-serif;
      margin-bottom: 1px;
    }
  }

  .contentEmailEncerramento {
    margin-bottom: 100px;
    margin-top: 10px;
  }

  .checkboxContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ConfigEmails {
    margin-top: 50px;

    label {
      font-size: 12px;
    }
  }
`;
