import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardFooter } from 'reactstrap';
import { useHistory } from 'react-router-dom';

import NothingToShow from './components/NothingToShow';
import AsideCardsList from './components/AsideCards/AsideCardsList';
import HeaderBar from './components/HeaderBar';
import QueueMembersContainer from './components/QueueMembers/QueueMembersContainer';
import Graphic from './components/Graphic';
import ListWatingCalls from './components/ListWatingCalls';

import { useSocketWatingCalls } from './hooks/useSocketWatingCalls';
import useAgentEvents from './hooks/useAgentEvents';
import AnsweredCallsTable from './components/AnsweredCallsTable';
import { useBFFQueueMonitoring } from './hooks/useBFFQueueMonitoring';
import { TIME_INTERVAL } from './utils/timeIntervalOfRequests';

const MonitoringPanel = () => {
  const [queueIds, queueNames] = useMemo(
    () => window.location.search.split('&'),
    []
  );
  const queuesToMonit = useMemo(
    () => queueIds.split('=')[1].split(','),
    [queueIds]
  );
  const nameQueuesToMonit = useMemo(
    () => queueNames.split('=')[1].split(','),
    [queueNames]
  );

  const hasJustOneQueue = useMemo(
    () => !!queuesToMonit.join() && queuesToMonit.length === 1,
    [queuesToMonit]
  );

  const history = useHistory();
  const { agents, socket } = useAgentEvents();
  const { watingCalls } = useSocketWatingCalls(socket, nameQueuesToMonit);

  const {
    queueMonitoringData,
    getQueueMonitoringData,
    getQueueMonitoringDataByQueue,
    queueMonitoringDataByQueue
  } = useBFFQueueMonitoring(queuesToMonit);

  const [sortType, setSortType] = useState('extension');
  const [isOneCardPerAgent, setIsOneCardPerAgent] = useState(!hasJustOneQueue);
  const [filterValue, setFilterValue] = useState('');
  const [showAgentStatistics, setShowAgentStatistics] = useState(false);
  const [groupByQueue, setGroupByQueue] = useState(false);

  const handleFilter = (e) => {
    setFilterValue(e.toLowerCase());
  };

  const sorters = useMemo(
    () => ({
      queue: (items) => {
        return [...items].sort((a, b) => {
          return a.queueName < b.queueName ? -1 : 1;
        });
      },
      extension: (items) => {
        return [...items].sort((a, b) => {
          return a.extension < b.extension ? -1 : 1;
        });
      },
      agent: (items) => {
        return [...items].sort((a, b) => {
          return a.agent?.usr?.name?.toLowerCase() <
            b.agent?.usr?.name?.toLowerCase()
            ? -1
            : 1;
        });
      }
    }),
    []
  );

  const sortMembers = (type) => {
    if (!type) return;
    setSortType(type);
  };

  const groupByAgent = useCallback((allCardsData) => {
    if (!allCardsData) return [];

    const groupedAgents = {};
    allCardsData.forEach((item) => {
      if (!groupedAgents[item.agent.usr.login]) {
        groupedAgents[item.agent.usr.login] = item;
        groupedAgents[item.agent.usr.login].sessions = [];
      }

      groupedAgents[item.agent.usr.login].sessions.push(item.agent.queue);
    });

    const cardDataByAgentDTO = [];
    for (const key in groupedAgents) {
      cardDataByAgentDTO.push(groupedAgents[key]);
    }

    return cardDataByAgentDTO;
  }, []);

  const QueueMembers = useMemo(() => {
    if (isOneCardPerAgent) {
      const groupedCardData = groupByAgent(agents || []);
      return sorters[sortType](groupedCardData);
    }
    return sorters[sortType](agents);
  }, [groupByAgent, isOneCardPerAgent, agents, sorters, sortType]);

  const queueMemberInfo = useMemo(() => {
    return QueueMembers.filter((item) => {
      if (isOneCardPerAgent) {
        return item.sessions.some((session) => {
          return queuesToMonit.includes(String(session.id));
        });
      }

      return queuesToMonit.includes(String(item.agent.queue_id));
    });
  }, [isOneCardPerAgent, queuesToMonit, QueueMembers]);

  useEffect(() => {
    if (queuesToMonit.length === 0) return;

    getQueueMonitoringData();
    if (groupByQueue) {
      getQueueMonitoringDataByQueue();
    }
    const interval = setInterval(() => {
      getQueueMonitoringData();
      if (groupByQueue) {
        getQueueMonitoringDataByQueue();
      }
    }, TIME_INTERVAL);

    return () => clearInterval(interval);
  }, [queuesToMonit, getQueueMonitoringData, groupByQueue]);

  useEffect(() => {
    const login = window.localStorage.getItem('login');
    const token = window.localStorage.getItem('token');

    if (!login || !token) {
      return history.push('/admin');
    }

    const roles = JSON.parse(window.localStorage.getItem('roles'));
    const [roleMonitoringCallcenter] = roles.filter(
      ({ role }) => role === 'MONITORING_CALLCENTER'
    );

    if (!roleMonitoringCallcenter) {
      return history.push('/admin');
    }

    const allowedQueues = JSON.parse(window.localStorage.getItem('queues'));
    const allowedQueuesIds = (allowedQueues || []).map(({ id }) => `${id}`);

    if (
      queuesToMonit &&
      queuesToMonit.length &&
      queuesToMonit[0] !== '' &&
      allowedQueuesIds &&
      allowedQueuesIds.length
    ) {
      queuesToMonit.forEach((id) => {
        if (!allowedQueuesIds.includes(String(id))) {
          return history.push('/admin');
        }
      });
    }
  }, [history, queuesToMonit]);

  return (
    <div
      style={{
        position: 'relative',
        overflowX: 'hidden'
      }}
    >
      <Container fluid style={{ padding: '0', marginTop: '-1rem' }}>
        <Row style={{ padding: '0' }}>
          <div className="col" style={{ padding: '0' }}>
            <Card style={{ padding: 0 }}>
              <CardBody>
                <Row>
                  {!groupByQueue && (
                    <Col
                      md="4"
                      lg="3"
                      xl="2"
                      style={{ padding: '0 0.8rem 0 1rem' }}
                    >
                      <AsideCardsList
                        queueMonitoringData={queueMonitoringData}
                        watingCalls={watingCalls}
                      />
                    </Col>
                  )}

                  <Col>
                    <HeaderBar
                      data={queueMemberInfo}
                      nameQueuesToMonit={nameQueuesToMonit}
                      sortMembers={sortMembers}
                      setIsOneCardPerAgent={setIsOneCardPerAgent}
                      handleFilterInfo={handleFilter}
                      setShowAgentStatistics={setShowAgentStatistics}
                      setGroupByQueue={setGroupByQueue}
                      className="mb-2"
                    />

                    {!queuesToMonit.join() ? (
                      <NothingToShow />
                    ) : (
                      agents && (
                        <QueueMembersContainer
                          queueMonitoringDataByQueue={
                            queueMonitoringDataByQueue
                          }
                          watingCalls={watingCalls}
                          filterValue={filterValue}
                          allQueueMembers={queueMemberInfo}
                          isOneCardPerAgent={isOneCardPerAgent}
                          showAgentStatistics={showAgentStatistics}
                          nameQueuesToMonit={nameQueuesToMonit}
                          groupByQueue={groupByQueue}
                        />
                      )
                    )}
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <p>Ligações Recebidas por Hora:</p>
                <div className="overflow-auto">
                  <AnsweredCallsTable
                    data={queueMonitoringData?.statisticsByHour}
                  />
                </div>
                <p>Ligações Recebidas por Hora:</p>
                <div>
                  <Graphic data={queueMonitoringData?.statisticsByHour} />
                </div>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
      <ListWatingCalls watingCalls={watingCalls} />
    </div>
  );
};

export default MonitoringPanel;
