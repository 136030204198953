import React, { useEffect, useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';

const WaitingCallItem = ({ caller, duration }) => {
  const [waitingTime, setWaitingTime] = useState(Number(duration));

  useEffect(() => {
    const timer = setInterval(() => {
      setWaitingTime((currentTime) => currentTime + 1);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <span key={caller} className="d-block">
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span title="Número na fila de espera">{caller}</span>
        <span id="waitingTime">{`${waitingTime}s`}</span>

        <UncontrolledTooltip placement="rigth" target="waitingTime">
          Tempo na fila de espera
        </UncontrolledTooltip>
      </div>
    </span>
  );
};

export default WaitingCallItem;
