import React from 'react';
import MaskedInput from 'react-text-mask';

const CPFMask = (props) => {
  const onChange = (event) => {
    props.valueController(props.fieldName, event.target.value);
  };

  const getInputValue = (value) => {
    return value;
  };

  return (
    <MaskedInput
      mask={[
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '.',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/
      ]}
      value={getInputValue(props.value)}
      tabIndex={props.tabIndex ? props.tabIndex : 0}
      onChange={(event) => onChange(event)}
      onInput={(event) => onChange(event)}
      placeholder={props.placeholder}
      disabled={props.shouldDisable}
      className="form-control"
      style={{
        width: '100%',
        height: '46px',
        fontWeight: '500',
        color: 'gray'
      }}
    />
  );
};

export default CPFMask;
