import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { useSelector } from 'react-redux';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';

const NotificationModal = ({
  children,
  buttonName = '',
  useIconButton = true,
  name,
  icon = 'fas fa-info-circle',
  color = 'success',
  tooltipTitle = '',
  showCloseButton = true,
  showSubmitButton = false
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Button
        size="sm"
        color={color}
        title={tooltipTitle}
        onClick={toggleModal}
      >
        {useIconButton && <i className={icon}></i>}
        {buttonName}
      </Button>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {name}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />
          {children}
        </ModalBody>

        <ModalFooter>
          {showCloseButton && (
            <Button
              style={buttonStyle(systemConfiguration.cancelation_button_color)}
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
            >
              Fechar
            </Button>
          )}

          {showSubmitButton && (
            <Button
              style={buttonStyle(systemConfiguration.confirmation_button_color)}
              type="submit"
            >
              Salvar
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NotificationModal;
