import styled from 'styled-components';
import { UncontrolledTooltip } from 'reactstrap';

// Default values
const backgroundColor = '#FFFFFF';
const borderColor = '#9DAAB9';

// pointer-events to fix the tooltip flickering when mouseover
const TooltipBottom = styled(UncontrolledTooltip)`
  opacity: 1 !important;
  pointer-events: none;

  .tooltip-inner {
    display: inline-block;
    position: relative;
    text-align: left;
    color: #566382;
    background-color: ${backgroundColor};
    border-width: 1px;
    border-style: solid;
    border-color: ${borderColor};
    padding: 10px;
    margin: 10px 0 0 0;
    filter: drop-shadow(rgba(0, 0, 0, 0.4) 0 2px 3px);
  }

  .arrow {
    display: none;
  }
`;
export default TooltipBottom;
