import { toast } from 'react-toastify';
export function Alerts(type, message) {
  switch (type) {
    case 'error':
      return toast.error(message);
    case 'success':
      return toast.success(message);
    case 'info':
      return toast.info(message);
    default:
      console.log(`Sorry.`);
  }
}
