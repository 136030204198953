import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import Pagination from 'components/Pagination';
import UserService from '../../../User/service';
import DialerService from '../../../Dialer/service';
import QueueService from '../../../Queue/service';
import hangupCauseService from '../../../ShutdownCause/service';
import { sortData } from './utils/functions';
import { useSelector } from 'react-redux';
import {
  hourRules,
  dateRules,
  reportOptions,
  statusOptions,
  groupByOptions
} from './utils/variables';
import ReportService from './service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import HourMaskMMHHSS from '../../../../components/Inputs/HourMaskMMHHSS.js';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ReportAnalythical from './tables/Analytical';
import ReportSynthetic from './tables/Synthetic';
import ReportDetailedSynthetic from './tables/DetailedSynthetic';
import * as fileSaver from 'file-saver';
import { v4 as uuid } from 'uuid';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';
import { Tooltip } from '@material-ui/core';

import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';
import { ItemPerPageSelect } from 'components/Inputs/ItemPerPageSelect';

const DialerReport = () => {
  const { control, handleSubmit, errors, setValue, watch, getValues , register} =
    useForm();
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [users, setUsers] = useState([]);
  const [queues, setQueues] = useState([]);
  const [hagupCause, setHagupCause] = useState([]);
  const [dialers, setDialers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const reportType = watch('reportType');
  const [ReportTypeAnalytical, setReportTypeAnalytical] = useState([]);
  const [ReportTypeSynthetic, setReportTypeSynthetic] = useState([]);
  const [ReportTypeDetailedSynthetic, setReportTypeDetailedSynthetic] =
    useState([]);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [media, setMedia] = useState('');
  const showDisables = !!watch('disable');
  const [dataToSend, setDataToSend] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );
  const [messagemGeneratePdf, setMessagemGeneratePdf] = useState();
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //messagemGeneratePdfCsv
  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantity,
      limitGenerationPDF
    );

    setMessagemGeneratePdf(message.pdfMessage);
    setMessagemGenerateCsv(message.csvMessage);
  }, [quantity, limitGenerationPDF]);

  useEffect(() => {
    if (showDisables === true) {
      DialerService.getAllShowDisable(showDisables)
        .then((response) => {
          setDialers(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    } else {
      DialerService.getAll()
        .then((response) => {
          setDialers(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [showDisables]);

  useEffect(() => {
    if (showDisables === true) {
      QueueService.getAllShowDisable(showDisables)
        .then((response) => {
          setQueues(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    } else {
      QueueService.getAll()
        .then((response) => {
          setQueues(response.data.data);
        })
        .catch((err) => {
          console.log('err: ' + err);
        });
    }
  }, [showDisables]);

  useEffect(() => {
    getUser(true);
  }, [usersShowDisable]);

  const getUser = (showDisable = false) => {
    if (!showDisable) {
      setLoading(true);
    }
    UserService.getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        setUsers(response.data.data);
        if (!showDisable) {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('err: ' + err);
        if (!showDisable) {
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    setLoading(true);
    hangupCauseService
      .getAll()
      .then((response) => {
        const dataShort = sortData(response.data.data);
        const data = dataShort.map((cause) => {
          return {
            cause_title: `${cause.cause_title} - ${cause.cause_number}`,
            cause_number: cause.cause_number
          };
        });

        setHagupCause(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  const clearData = () => {
    setReportTypeAnalytical([]);
    setReportTypeSynthetic([]);
    setReportTypeDetailedSynthetic([]);
    setDataToSend({});
    setActivePage(1);
    setQuantity(0);
  };

  const toISOFormat = (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');

    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${MM}-${DD}-${YYYY}`;
  };

  const getFormatedType = (value = 1) => {
    switch (value) {
      case 1:
        return 'analytical';
      case 2:
        return 'synthetic';
      case 3:
        return 'detailedsynthetic';
      default:
        break;
    }
  };

  const getDataDTO = (data) => {
    if (data.hangup_cause.length === 0) {
      delete data.hangup_cause;
    }

    let formatedData;

    formatedData = {
      ...data,
      dateBegin: `${toISOFormat(data.dateBegin)} ${data.timeStart}`,
      dateEnd: `${toISOFormat(data.dateEnd)} ${data.timeEnd}`,
      timeStart: data.timeStart.replace(/:/g, ''),
      timeEnd: data.timeEnd.replace(/:/g, ''),
      hangup_cause: data.hangup_cause && data.hangup_cause.join(',')
    };

    if ('groupBy' in formatedData) {
      if (formatedData.groupBy.length > 0) {
        formatedData.groupBy = formatedData.groupBy.join(',');
      } else {
        delete formatedData.groupBy;
      }
    }

    if (!formatedData.agent_code) delete formatedData.agent_code;
    if (!formatedData.client_name) delete formatedData.client_name;
    if (!formatedData.dialer) delete formatedData.dialer;
    if (!formatedData.identification) delete formatedData.identification;
    if (!formatedData.queue) delete formatedData.queue;
    if (!formatedData.status) delete formatedData.status;

    if (!formatedData.hangup_cause) delete formatedData.hangup_cause;

    setDataToSend(formatedData);
    return formatedData;
  };

  const definedNameReportDownload = (name) => {
    let nameReportDownload;

    switch (name) {
      case 'pdf':
        nameReportDownload = 'Relatório_de_Discador.pdf';
        break;
      case 'csv':
        nameReportDownload = 'Relatório_de_Discador.csv';
        break;
      case 'excel':
        nameReportDownload = 'Relatório_de_Discador.xlsx';
        break;
      default:
        break;
    }

    return nameReportDownload;
  };

  const getReports = (typeReport, dataToSend, page, useLoader = true) => {
    setLoadingReport(useLoader);
    if (media === 'pdf' || media === 'csv' || media === 'excel') {
      ReportService.getDownloads(typeReport, 1, media, dataToSend)
        .then((res) => {
          if (res.data.size === 0)
            toast.info('Nenhum registro foi encontrado!');
          else {
            fileSaver.saveAs(
              new Blob([res.data], { type: res.headers['content-type'] }),
              definedNameReportDownload(media)
            );
          }
          setLoadingReport(false);
        })
        .catch((err) => {
          toast.error(err || `Erro ao gerar documento ${media}`, {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoadingReport(false);
        });
      setMedia('');
    } else {
      ReportService.getAllReportsType(
        typeReport,
        page,
        dataToSend,
        itemsPerPage
      )
        .then((res) => {
          switch (typeReport) {
            case 'analytical':
              if (res.data.data.quantity === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listDiscadorDTO.forEach((el, index) => {
                  const phoneNumberList = el.phoneNumber
                    .split(';')
                    .filter((e) => !!e);
                  const fields = {};

                  phoneNumberList.forEach((e, i) => {
                    const fieldName =
                      i === 0 ? 'phoneNumber' : `phoneNumber${i + 1}`;

                    fields[fieldName] = e;
                  });

                  res.data.data.listDiscadorDTO[index] = {
                    uuid: uuid(),
                    ...el,
                    ...fields
                  };
                });
                setReportTypeAnalytical(res.data.data);
                setQuantity(res.data.data.quantity);
              }
              break;
            case 'synthetic':
              if (res.data.data.listDiscadorSyntheticDTO.length === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listDiscadorSyntheticDTO.forEach((el, index) => {
                  res.data.data.listDiscadorSyntheticDTO[index].uuid = uuid();
                });
                setReportTypeSynthetic(res.data.data);
                setQuantity(res.data.data.quantity);
              }
              break;
            case 'detailedsynthetic':
              if (res.data.data.quantity === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listDiscadorDetailedSyntheticDTO.forEach(
                  (el, index) => {
                    res.data.data.listDiscadorDetailedSyntheticDTO[index].uuid =
                      uuid();
                  }
                );
                setReportTypeDetailedSynthetic(res.data.data);
                setQuantity(res.data.data.quantity);
              }
              break;
            default:
              break;
          }
          setLoadingReport(false);
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoadingReport(false);
        });
    }
  };

  const onSubmit = (data) => {
    clearData();
    try {
      const formatedData = getDataDTO(data);
      const formatedType = getFormatedType(reportType);
      getReports(formatedType, formatedData, 1, true);
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    clearData();
  }, [reportType]);

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const handlePageChange = (page) => {
    setActivePage(page);
    getReports(getFormatedType(reportType), dataToSend, page, false);
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de Discador</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Discador</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={dialers}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="dialer"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="dialer"
                            defaultValue=""
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Agente</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={users}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="agent_code"
                                labelName="name"
                                valueName="agent_code"
                              />
                            )}
                            control={control}
                            name="agent_code"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Fila</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={queues}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="queue"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="queue"
                            defaultValue=""
                          />
                        </Col>

                        <Col md="6">
                          <label className="form-control-label">
                            Nome do cliente
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="client_name"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Status</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={statusOptions}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="status"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="status"
                            defaultValue=""
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Identificador do Cliente
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="identification"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Data inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateBegin"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateBegin"
                            rules={dateRules}
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateBegin"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Data final*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateEnd"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateEnd"
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                            rules={{
                              ...dateRules,
                              validate: (value) => {
                                if (
                                  new Date(toISOFormat(value, true)) <
                                  new Date(
                                    toISOFormat(getValues()['dateBegin'], true)
                                  )
                                ) {
                                  return 'A data final não pode ser anterior à inicial!';
                                } else {
                                  return true;
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Col className="mt-2" md="4">
                          <label
                            className="form-control-label"
                            style={{ marginRight: '20px', fontWeight: '400' }}
                          >
                            <input
                              type="radio"
                              name="typeDateFilter"
                              ref={register}
                              value="creation"
                              defaultChecked={true}
                              //disabled={isUpdate}
                            />{' '}
                            Data de disparo
                          </label>
                          <label
                            className="form-control-label"
                            style={{ fontWeight: '400' }}
                          >
                            <input
                              type="radio"
                              name="typeDateFilter"
                              ref={register}
                              value="lastChange"
                              defaultChecked={false}
                              //disabled={isUpdate}
                            />{' '}
                            Data de atendimento
                          </label>
                        </Col>
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Hora inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="timeStart"
                                value={props.value}
                              />
                            )}
                            control={control}
                            name="timeStart"
                            rules={hourRules}
                            defaultValue="00:00:00"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="timeStart"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Hora final*
                          </label>
                          <Controller
                            render={(props) => (
                              <HourMaskMMHHSS
                                valueController={setValue}
                                fieldName="timeEnd"
                                value={props.value}
                              />
                            )}
                            control={control}
                            name="timeEnd"
                            rules={hourRules}
                            defaultValue="23:59:59"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="timeEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Tipo de Relatório
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={reportOptions}
                                isMulti={false}
                                value={props.value}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="reportType"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="reportType"
                            defaultValue={1}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Causa de desligamento
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={hagupCause}
                                isMulti={true}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={false}
                                valueController={setValue}
                                fieldName="hangup_cause"
                                labelName="cause_title"
                                valueName="cause_number"
                              />
                            )}
                            control={control}
                            name="hangup_cause"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="hangup_cause"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      {quantity > 0 && (
                        <Row className="mb-3">
                          <Col md="6" className="bottom-2 position-absolute">
                            <ItemPerPageSelect
                              valueController={setItemsPerPage}
                            />
                          </Col>
                        </Row>
                      )}

                      {reportType !== 1 && (
                        <Row className="mb-3">
                          <Col md="12">
                            <label className="form-control-label">
                              Agrupamentos
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={groupByOptions}
                                  isMulti={true}
                                  value={props.value}
                                  valueController={setValue}
                                  isClearable={true}
                                  closeMenuOnSelect={false}
                                  fieldName="groupBy"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="groupBy"
                              defaultValue=""
                            />
                          </Col>
                        </Row>
                      )}

                      <Row className="mb-3">
                        <Col className="md-6 mx-4">
                          <div style={{ display: 'flex', gap: '20px' }}>
                            <Controller
                              name="disable"
                              control={control}
                              render={(props) => (
                                <>
                                  <label className="form-control-label">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                    Mostrar desabilitados (Discadores, Filas)
                                  </label>
                                </>
                              )}
                              defaultValue={false}
                            />
                            <UserFlexSelect
                              usersShowDisable={usersShowDisable}
                              setUsersShowDisable={setUsersShowDisable}
                              name={'Agentes'}
                            />
                          </div>
                        </Col>
                        <Col md="4" style={ImageButtonContainer}>
                          {reportType !== 1 && (
                            <button
                              style={ImageButton}
                              name="pdf"
                              onClick={() => setMedia('pdf')}
                              disabled={disabledDownloadButtonCsvAndPdf(
                                'pdf',
                                quantity,
                                limitGenerationPDF
                              )}
                            >
                              <Tooltip
                                title={
                                  <p style={{ fontSize: '14px' }}>
                                    {messagemGeneratePdf}
                                  </p>
                                }
                                arrow
                              >
                                <img
                                  src={require('../../../../assets/img/pdf.png')}
                                  alt="pdf"
                                  style={ImageSize}
                                />
                              </Tooltip>
                            </button>
                          )}

                          <button
                            style={ImageButton}
                            onClick={() =>
                              reportType === 1
                                ? setMedia('csv')
                                : setMedia('excel')
                            }
                            disabled={disabledDownloadButtonCsvAndPdf(
                              'csv',
                              quantity,
                              limitGenerationPDF
                            )}
                          >
                            <Tooltip
                              title={
                                <p style={{ fontSize: '14px' }}>
                                  {messagemGenerateCsv}
                                </p>
                              }
                              arrow
                            >
                              <img
                                src={require('../../../../assets/img/csv.png')}
                                alt="csv"
                                style={ImageSize}
                              />
                            </Tooltip>
                          </button>
                        </Col>
                      </Row>

                      <Button
                        style={buttonStyle(systemConfiguration.primary_color)}
                        type="submit"
                        onClick={() => onSubmit()}
                      >
                        Buscar
                      </Button>
                    </Form>
                  )}
                  {loadingReport ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      {reportType === 1 &&
                        ReportTypeAnalytical.listDiscadorDTO && (
                          <ReportAnalythical
                            totalRegister={quantity}
                            dataReport={ReportTypeAnalytical}
                          />
                        )}
                      {reportType === 2 &&
                        ReportTypeSynthetic.listDiscadorSyntheticDTO && (
                          <ReportSynthetic
                            totalRegister={quantity}
                            dataReport={ReportTypeSynthetic}
                            groupBy={dataToSend?.groupBy?.split(',')}
                          />
                        )}
                      {reportType === 3 &&
                        ReportTypeDetailedSynthetic.listDiscadorDetailedSyntheticDTO && (
                          <ReportDetailedSynthetic
                            totalRegister={quantity}
                            dataReport={ReportTypeDetailedSynthetic}
                            groupBy={dataToSend?.groupBy?.split(',')}
                          />
                        )}
                    </>
                  )}
                  {quantity > 0 && (
                    <Pagination
                      itemsCountPerPage={itemsPerPage}
                      activePage={activePage}
                      totalItemsCount={quantity}
                      onChange={handlePageChange.bind(this)}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DialerReport;
