export const chartPieWithTheLabelsOptions = {
  plugins: {
    datalabels: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      anchor: 'center',
      align: 'center',
      padding: 3,
      borderColor: 'white',
      borderRadius: 25,
      borderWidth: 1,
      color: 'white',
      display: function (context) {
        const dataPoints = context.chart.data.datasets[0].data;
        function totalSum(total, dataPoints) {
          return total + dataPoints;
        }
        const totalValue = dataPoints.reduce(totalSum, 0);
        const percentageValue =
          (context.dataset.data[context.dataIndex] / totalValue) * 100;
        return percentageValue.toFixed(1) >= 5;
      },
      font: {
        size: 10
      },
      formatter: (value, context) => {
        const dataPoints = context.chart.data.datasets[0].data;
        function totalSum(total, dataPoints) {
          return total + dataPoints;
        }
        const totalValue = dataPoints.reduce(totalSum, 0);
        const percentageValue = (value / totalValue) * 100;
        return `${value} (${percentageValue.toFixed(1)}%)`;
      }
    }
  }
};
