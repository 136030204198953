import React, { useState } from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';

const DropdowButton = ({
  sortMembers,
  setIsOneCardPerAgent,
  setShowAgentStatistics,
  setGroupByQueue
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const sortQueueMembers = (orderBy) => {
    sortMembers(orderBy);
  };

  return (
    <Dropdown direction="left" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <span style={{ padding: '10px', cursor: 'pointer' }}>
          <i className="fas fa-ellipsis-v"></i>
        </span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={() => sortQueueMembers('queue')}>
          Ordenar Por Fila
        </DropdownItem>
        <DropdownItem onClick={() => sortQueueMembers('extension')}>
          Ordenar Por Ramal
        </DropdownItem>
        <DropdownItem onClick={() => sortQueueMembers('agent')}>
          Ordenar Por Nome do Agente
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem
          onClick={() => {
            setIsOneCardPerAgent(false);
            setShowAgentStatistics(false);
            setGroupByQueue(false);
          }}
        >
          Mostrar todos os cards
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setIsOneCardPerAgent(true);
            setShowAgentStatistics(false);
            setGroupByQueue(false);
          }}
        >
          Mostrar um card por agente
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setIsOneCardPerAgent(true);
            setShowAgentStatistics(true);
            setGroupByQueue(false);
          }}
        >
          Mostrar estatísticas do agente
        </DropdownItem>
        <DropdownItem
          onClick={() => {
            setIsOneCardPerAgent(false);
            setShowAgentStatistics(false);
            setGroupByQueue(true);
          }}
        >
          Mostrar agrupamento por fila
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdowButton;
