import React from 'react';
import { useServiceBlockServicePainelContext } from '../../../../hooks/useBlockAcessServicePainel';
import { FaRegPauseCircle } from 'react-icons/fa';
import { Container, ContentInfor } from './styled';
const PauseServicePainel = () => {
  const { typeOfPause } = useServiceBlockServicePainelContext();
  return (
    <Container>
      <ContentInfor>
        <span>Ops...Você está em Pausa: {typeOfPause}</span>
        <FaRegPauseCircle />
      </ContentInfor>
    </Container>
  );
};

export default PauseServicePainel;
