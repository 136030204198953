import React from 'react';
import { OptionButton } from '../../styled';

const ClickToCall = ({
  title = '',
  callBack,
  disableClickToCall,
  icon,
  color,
  isDefaultButtonStyle,
  key
}) => {
  return (
    <OptionButton
      key={key}
      isDefaultButtonStyle={isDefaultButtonStyle}
      title={title}
      disabled={disableClickToCall}
      onClick={callBack}
      color={color}
      size="sm"
    >
      {icon}
    </OptionButton>
  );
};

export default ClickToCall;
