import React from 'react';
import { Container, Row, Card, CardHeader, CardBody } from 'reactstrap';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import FilteringFieldHeader from './components/FilteringFieldHeader';
import ExtensionCardContainer from './components/ExtensionCards/ExtensionCardContainer';
import { useSocketEvents } from './hooks/useSocketEvents';
import { useFilteringHandlers } from './hooks/useFilteringHandlers';
// import CostCenterService from '../../Extension/service/centr_de_custo';

const ExtensionMonitoring = () => {
  const { extensions } = useSocketEvents();
  const {
    filteredExtensions,
    costsCenter,
    hideInactive,
    textQuery,
    setSelectedCostCenters,
    setHideInactive,
    setTextQuery
  } = useFilteringHandlers(extensions);

  return (
    <>
      <SimpleHeader showBackArrow={'not'} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Monitoramento de Ramais</h3>
                </CardHeader>
                <CardBody>
                  <FilteringFieldHeader
                    textQuery={textQuery}
                    setTextQuery={setTextQuery}
                    hideInactive={hideInactive}
                    setHideInactive={setHideInactive}
                    costsCenters={costsCenter}
                    setSelectedCostCenters={setSelectedCostCenters}
                  />
                  <ExtensionCardContainer extensions={filteredExtensions} />
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ExtensionMonitoring;
