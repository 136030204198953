import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Card, CardHeader, Button } from 'reactstrap';
import Service from '../service';
import { toast } from 'react-toastify';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'components/Pagination';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import { HeaderBody } from './components/HeaderBody';
import ModalCreateTag from './components/ModalCreateTag';
import ModalUpdateTag from './components/ModalUpdateTag';
import ModalConfirmation from './components/ModalConfirmation';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_icon_header_style_callback,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const TagsList = () => {
  const [openModalCreateTag, setOpenModalCreateTag] = useState(false);
  const [openModalUpdateTag, setOpenModalUpdateTag] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [loadingPage, setLoadingPage] = useState(false);
  const [tags, setTags] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [idTag, setIdTag] = useState();
  const handleGetTags = useCallback(
    (selectedPage = 1) => {
      setLoadingPage(true);
      Service.getAll(selectedPage)
        .then((res) => {
          const { list, count } = res.data;
          const newActivePage = selectedPage || activePage;
          setTags(list);
          setQuantity(count);
          setActivePage(newActivePage);
        })
        .catch(() => {
          toast.error('Não foi possível lista as tags', {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .finally(() => {
          setLoadingPage(false);
        });
    },
    [activePage]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(handleGetTags, []);

  const handlePageChange = (activePage) => handleGetTags(activePage);

  const toggleDisableAndEnable = (id, status) => {
    const newStatus = {
      active: !status
    };
    Service.enable(id, newStatus)
      .then((res) => {
        if (res.data === 'success') {
          handleGetTags();
          const message = newStatus.active ? 'Habilitada' : 'Desabilitada';
          toast.success(
            `Alteração realizada com sucesso, tag agora está ${message}`,
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Erro ao alterar status da tag ', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const handleupdateTag = (id) => {
    setIdTag(id);
    setOpenModalUpdateTag(true);
  };

  const handledeletedTag = (id) => {
    setIdTag(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <HeaderBody setOpenModalCreateTag={setOpenModalCreateTag} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Tags</h3>
                </CardHeader>
                <ToolkitProvider
                  data={tags}
                  keyField="_id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },

                    {
                      dataField: 'active',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style_callback,
                      text: 'Desabilitar / Habilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            checked={row.active}
                            id={row._id}
                            onChange={() => {
                              toggleDisableAndEnable(row._id, row.active);
                            }}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="info"
                            title="Editar Tag"
                            type="button"
                            size="sm"
                            onClick={() => handleupdateTag(row._id)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Excluir',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            title="Excluir Tag"
                            type="button"
                            size="sm"
                            onClick={() => handledeletedTag(row._id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loadingPage ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader height={80} width={80} />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalCreateTag
        render={handleGetTags}
        isModalOpen={openModalCreateTag}
        onModalToggle={setOpenModalCreateTag}
        unmountOnClose={true}
      />
      <ModalUpdateTag
        render={handleGetTags}
        idTag={idTag}
        isModalOpen={openModalUpdateTag}
        onModalToggle={setOpenModalUpdateTag}
        unmountOnClose={true}
      />
      <ModalConfirmation
        render={handleGetTags}
        idTag={idTag}
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
      />
    </>
  );
};

export default TagsList;
