import React, { useState, useEffect, useCallback } from 'react';
import Service from './Services/Index';
import UserService from './Services/UserService';
import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Pagination from 'components/Pagination';
import Loader from 'react-loader-spinner';
import HourMaskMMHHSS from '../../../../components/Inputs/HourMaskMMHHSS.js';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ReportAnalythical from './tables/analytical';
import ReportSynthetical from './tables/synthetic';
import csvImage from '../../../../assets/img/csv.png';
import { useSelector } from 'react-redux';
import * as fileSaver from 'file-saver';
import { v4 as uuid } from 'uuid';
import { format, startOfMonth } from 'date-fns';
import { useGetQueues } from 'views/Report/hooks/useGetQueues';
import { Tooltip } from '@material-ui/core';
import { useGetTeamsFiltered } from '../../../../hooks/useGetTeamsFiltered';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { ItemPerPageSelect } from 'components/Inputs/ItemPerPageSelect';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';

const hourRules = {
  // required: 'Campo obrigatório!',
  pattern: {
    value: /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/,
    message: 'Hora inválida!'
  }
};

const dateRules = {
  required: 'Data é obrigatório!',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};

const reportOptions = [
  { label: 'Analítico', value: 1 },
  { label: 'Sintético', value: 2 }
];

const AutenticationReport = () => {
  const { control, handleSubmit, errors, setValue, watch, getValues } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [ReportTypeAnalytical, setReportTypeAnalytical] = useState([]);
  const [ReportTypeSynthetical, setReportTypeSynthetical] = useState([]);
  const [dataToSubmitDownloads, setDataToSubmitDownloads] = useState();
  const [itemPerPage, setItemsPerPage] = useState(10);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [agents, setAgents] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const reportType = watch('reportType');
  const showDisableQueue = useWatch({ control, name: 'disable' });
  const { queues } = useGetQueues(showDisableQueue, setLoadingForm);
  const { teams } = useGetTeamsFiltered();

  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();
  useEffect(() => {
    getUsers(true);
  }, [usersShowDisable]);

  const getUsers = (showDisable = false) => {
    if(!showDisable){
      setLoadingForm(true);
    }
    UserService.getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        setAgents(response.data.data);
        if(!showDisable){
          setLoadingForm(false);
        }
      })
      .catch((err) => {
        console.log('err: ' + err);
        if(!showDisable){
          setLoadingForm(false);
        }
      })
    };
  

  useEffect(() => {
    if (reportType === 1) {
      setQuantity(0);
      setActivePage(1);
      setReportTypeSynthetical([]);
    } else {
      setQuantity(0);
      setActivePage(1);
      setReportTypeAnalytical([]);
    }
  }, [reportType]);

  //agent||users to select

  //messagemGeneratePdfCsv
  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantity,
      limitGenerationPDF
    );
    setMessagemGenerateCsv(message.csvMessage);
  }, [quantity, limitGenerationPDF]);

  const downloadMedia = useCallback(
    async (media, type, fileName) => {
      try {
        let typeReport;
        if (dataToSubmitDownloads.reportType === 1) {
          typeReport = 'analytical';
        } else {
          typeReport = 'synthetic';
        }
        //type, page, format, queryParams
        const response = await Service.getDownloads(
          typeReport,
          1,
          media,
          dataToSubmitDownloads
        );
        fileSaver.saveAs(new Blob([response.data], { type }), fileName);
      } catch (err) {
        toast.error(err || 'Ops, ocorreu um erro!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [dataToSubmitDownloads]
  );

  const toISOFormat = useCallback(async (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${MM}-${DD}-${YYYY}`;
  }, []);

  const getDataDTO = useCallback(
    async (data) => {
      let dataToSend;
      if (data.queue.length !== 0) {
        dataToSend = {
          ...data,
          queue: data.queue.join(',') || null,
          dateBegin: `${await toISOFormat(data.dateBegin)} ${data.timeStart}`,
          dateEnd: `${await toISOFormat(data.dateEnd)} ${data.timeEnd}`,
          timeStart: data.timeStart.replace(/:/g, ''),
          timeEnd: data.timeEnd.replace(/:/g, ''),
          disable: data.disable || null
        };
      } else {
        dataToSend = {
          ...data,
          dateBegin: `${await toISOFormat(data.dateBegin)} ${data.timeStart}`,
          dateEnd: `${await toISOFormat(data.dateEnd)} ${data.timeEnd}`,
          timeStart: data.timeStart.replace(/:/g, ''),
          timeEnd: data.timeEnd.replace(/:/g, ''),
          disable: data.disable || null
        };
        delete dataToSend.queue;
      }
      if (!dataToSend.agent) delete dataToSend.agent;

      setDataToSubmitDownloads(dataToSend);
      return dataToSend;
    },
    [toISOFormat]
  );

  const defineTypeReport = async (type) => {
    if (type === 1) {
      return 'analytical';
    } else {
      return 'synthetic';
    }
  };

  const getReports = useCallback(
    (typeReport, selectedPage = 1, dataToSend) => {
      //definir tipo = analytical, synthetic,pdf, excel, detailedsynthetic, csv
      const newActivePage = selectedPage || activePage;
      if (typeReport === 'analytical') {
        setLoadingTable(true);
        Service.getAllReportsType(
          typeReport,
          selectedPage,
          dataToSend,
          itemPerPage
        )
          .then((res) => {
            const { listAuthenticationDTO, quantity } = res.data.data;
            listAuthenticationDTO.forEach((el, index) => {
              listAuthenticationDTO[index].uuid = uuid();
            });
            setReportTypeAnalytical(listAuthenticationDTO);
            setQuantity(quantity);
            setActivePage(newActivePage);
            if (listAuthenticationDTO.length === 0) {
              toast.info('Nenhum registro foi encontrado!');
            }
            setLoadingTable(false);
          })
          .catch((err) => {
            setLoadingTable(false);
            console.log(err);
          });
      } else {
        setLoadingTable(true);
        Service.getAllReportsType(
          typeReport,
          selectedPage,
          dataToSend,
          itemPerPage
        )
          .then((res) => {
            const { listAuthenticationSyntheticDTO, quantity } = res.data.data;
            setReportTypeSynthetical(listAuthenticationSyntheticDTO);
            setQuantity(quantity);
            setActivePage(newActivePage);
            if (listAuthenticationSyntheticDTO.length === 0) {
              toast.info('Nenhum registro foi encontrado!');
            }
            setLoadingTable(false);
          })
          .catch((err) => {
            setLoadingTable(false);
            console.log(err);
          });
      }
    },
    [activePage, itemPerPage]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        const dataToSend = await getDataDTO(data);
        const typeReport = await defineTypeReport(reportType);
        getReports(typeReport, 1, dataToSend);
      } catch (err) {
        toast.error(err || 'Erro desconhecido por favor, informar o suporte!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [getDataDTO, reportType, getReports]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const handlePageChange = (activePage) => {
    let reportType =
      dataToSubmitDownloads.reportType === 1 ? 'analytical' : 'synthetic';
    getReports(reportType, activePage, dataToSubmitDownloads);
  };

  const getDateBegin = () => {
    const date = new Date();
    const firstDayMonth = new Date(
      date.getFullYear('pt-br'),
      date.getMonth('pt-br'),
      1
    );
    const dateBegin = format(startOfMonth(firstDayMonth), 'dd/LL/yyyy');
    return dateBegin;
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de Autenticação</h3>
                </CardHeader>
                <CardBody>
                  {loadingForm ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      <Form
                        className="needs-validation"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Fila(s)
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={queues}
                                  isMulti={true}
                                  isClearable={true}
                                  value={props.value}
                                  closeMenuOnSelect={false}
                                  valueController={setValue}
                                  fieldName="queue"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="queue"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="6">
                              <label className="form-control-label">Agente</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={agents}
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="agent"
                                  labelName="name"
                                  valueName="agent_code"
                                />
                              )}
                              control={control}
                              name="agent"
                              defaultValue=""
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dateBegin"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dateBegin"
                              rules={dateRules}
                              // defaultValue={new Date().toLocaleDateString(
                              //   'pt-br'
                              // )}
                              defaultValue={getDateBegin()}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dateBegin"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>

                          <Col md="6">
                            <label className="form-control-label">
                              Hora inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <HourMaskMMHHSS
                                  valueController={setValue}
                                  fieldName="timeStart"
                                  value={props.value}
                                />
                              )}
                              control={control}
                              name="timeStart"
                              rules={hourRules}
                              defaultValue="00:00:00"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="timeStart"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data final*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dateEnd"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dateEnd"
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                              rules={{
                                ...dateRules,
                                validate: async (value) => {
                                  if (
                                    new Date(await toISOFormat(value, true)) <
                                    new Date(
                                      await toISOFormat(
                                        getValues()['dateBegin'],
                                        true
                                      )
                                    )
                                  ) {
                                    return 'A data final não pode ser anterior à inicial!';
                                  } else {
                                    return true;
                                  }
                                }
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dateEnd"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>

                          <Col md="6">
                            <label className="form-control-label">
                              Hora final*
                            </label>
                            <Controller
                              render={(props) => (
                                <HourMaskMMHHSS
                                  valueController={setValue}
                                  fieldName="timeEnd"
                                  value={props.value}
                                />
                              )}
                              control={control}
                              name="timeEnd"
                              rules={hourRules}
                              defaultValue="23:59:59"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="timeEnd"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">Equipe</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={true}
                                  dataOptions={teams}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="teamId"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="teamId"
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="teamId"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Tipo de Relatório
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={reportOptions}
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="reportType"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="reportType"
                              defaultValue={1}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-12">
                          <Col className="md-6 mx-4">
                        <div style={{ display: 'flex', gap: '20px'}}>
                            <Controller
                              name="disable"
                              control={control}
                              render={(props) => (
                                <>
                                  <label className="form-control-label">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                    Mostrar desabilitados (Fila)
                                  </label>
                                </>
                              )}
                              defaultValue=""
                            />
                              <UserFlexSelect usersShowDisable={usersShowDisable} setUsersShowDisable={setUsersShowDisable} name={'Agentes'}/>
                            </div>
                          </Col>
                          <Col md="4" style={ImageButtonContainer}>
                            <button
                              style={ImageButton}
                              id="button_csv"
                              onClick={() =>
                                downloadMedia(
                                  'csv',
                                  'application/csv',
                                  'Relatório_de_Autenticacao.csv'
                                )
                              }
                              disabled={disabledDownloadButtonCsvAndPdf(
                                'csv',
                                quantity,
                                limitGenerationPDF
                              )}
                            >
                              <Tooltip
                                title={
                                  <p style={{ fontSize: '14px' }}>
                                    {messagemGenerateCsv}
                                  </p>
                                }
                                arrow
                              >
                                <img
                                  src={csvImage}
                                  alt="csv"
                                  style={ImageSize}
                                />
                              </Tooltip>
                            </button>
                          </Col>
                        </Row>

                        <Button
                          style={buttonStyle(systemConfiguration.primary_color)}
                          type="submit"
                        >
                          Buscar
                        </Button>
                        <Row className="mb-3">
                        <Col md="3" style={{ marginTop: '-45px' , marginLeft:'110px'}}>
                            <ItemPerPageSelect
                              valueController={setItemsPerPage}
                            ></ItemPerPageSelect>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </CardBody>
                {loadingTable ? (
                  <div className="py-4 table-responsive">
                    <Row className="justify-content-md-center">
                      {loadingTable && (
                        <Loader
                          type="TailSpin"
                          color="#35ac50"
                          height={100}
                          width={100}
                        />
                      )}
                    </Row>
                  </div>
                ) : (
                  <>
                    {reportType === 1 && dataToSubmitDownloads ? (
                      <ReportAnalythical
                        showReports={ReportTypeAnalytical}
                        totalRecords={quantity}
                      />
                    ) : (
                      dataToSubmitDownloads && (
                        <ReportSynthetical
                          showReports={ReportTypeSynthetical}
                          totalRecords={quantity}
                        />
                      )
                    )}
                    {dataToSubmitDownloads && (
                      <Pagination
                        itemsCountPerPage={itemPerPage}
                        activePage={activePage}
                        totalItemsCount={quantity}
                        onChange={handlePageChange.bind(this)}
                      />
                    )}
                  </>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AutenticationReport;
