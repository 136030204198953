import ServiceCRUD from '../../../services/ServiceCRUDFLEXUC';
import { apiUc } from '../../../http';

class PauseService extends ServiceCRUD {
  constructor() {
    super(`/api/pausas`);
  }

  getAllPauses() {
    return apiUc.get(`/api/pausas`);
  }

  getPausesByUserPermission() {
    return apiUc.get('/api/pausas/by-user');
  }
}

export default new PauseService();
