import styled from 'styled-components';
import { Badge, Card } from 'reactstrap';

export const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 16px;
  height: 185px;
`;

export const StyledBadge = styled(Badge)`
  background-color: ${(props) => props.backgroundColor};
  color: #fff;
`;

export const CardBody = styled.div`
  display: grid;
  grid-template-columns: calc(50% - 0.5rem) calc(50% - 0.5rem);
  grid-gap: 1rem;
`;

export const CardText = styled.span`
  height: 24px;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
