import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import StatusService from '../service';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody,
  FormGroup,
  Label,
  Table,
  Button
} from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import { AddSupervisorNotificationModal } from './components/AddSupervisorNotificationModal';
import { getSupervisorAlertTypeLabel } from '../utils/supervisorAlertTypes';
import { ScreenPrompt } from 'components/ScreenPrompt';

const Status = (props) => {
  const history = useHistory();

  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;
  const [isUpdate, setIsUpdate] = useState(false);
  const [supervisorAlerts, setSupervisorAlerts] = useState([]);

  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      StatusService.get(id)
        .then((res) => {
          const statusData = res.data.content;
          setSupervisorAlerts(statusData.supervisorAlerts || []);
          reset({
            ...statusData,
            shouldSendNotification: statusData.should_send_notification
          });
          setIsUpdate(true);
        })
        .catch((err) => {
          const { data } = err.response;
          toast.error(data.message, {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      const formatedData = { ...data, supervisorAlerts };
      const status = isUpdate
        ? await StatusService.update(id, formatedData)
        : await StatusService.create(formatedData);
      history.push('/admin/status/');
      toast.success(status.data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
    } catch (err) {
      const { data } = err.response;
      toast.error(data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/status');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/status"
        buttonTitle="Voltar para a pagina de rotas de status/fase"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Status/Fase</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">Nome*</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="name"
                          rules={{ required: 'Campo obrigatório' }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="name"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">Descrição</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="description"
                          defaultValue=""
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="1">
                        <label className="form-control-label">Cor</label>
                        <Controller
                          as={<Input type="color" name="color" id="color" />}
                          control={control}
                          name="color"
                          defaultValue="#58DE6E"
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">
                          Notificações
                        </label>
                        <Controller
                          name="shouldSendNotification"
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <FormGroup check>
                              <Label check>
                                <Input
                                  type="checkbox"
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                  checked={props.value}
                                />
                                Enviar notificação por email para o cliente
                              </Label>
                            </FormGroup>
                          )}
                        />
                      </Col>
                    </Row>
                    <hr />

                    <Row className="mb-1">
                      <Col md="12">
                        <h3 className="mb-1">Alertas de supervisão</h3>
                      </Col>
                      <Col md="12">
                        <AddSupervisorNotificationModal
                          buttonLabel="Adicionar"
                          onAdded={(addedAlert) => {
                            if (supervisorAlerts.includes(addedAlert)) {
                              toast.error('Já existe um alerta desse tipo');
                              return;
                            }
                            setSupervisorAlerts((prev) => [
                              ...prev,
                              addedAlert
                            ]);
                          }}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-5">
                      <Col>
                        {supervisorAlerts && !!supervisorAlerts.length && (
                          <Table size="sm">
                            <thead>
                              <tr>
                                <th>Nome</th>
                                <th>Remover</th>
                              </tr>
                            </thead>
                            <tbody>
                              {supervisorAlerts.map((item, index) => (
                                <tr key={index}>
                                  <td>{getSupervisorAlertTypeLabel(item)}</td>
                                  <td>
                                    <Button
                                      color="danger"
                                      size="sm"
                                      onClick={() => {
                                        setSupervisorAlerts((prev) =>
                                          prev.filter((alert) => alert !== item)
                                        );
                                      }}
                                    >
                                      <li className="fas fa-trash"></li>
                                    </Button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        )}
                      </Col>
                    </Row>

                    <ReactButton btnColor="confirmation" type="submit">
                      Salvar
                    </ReactButton>

                    <ReactButton
                      btnColor="cancelation"
                      onClick={() => cancelAction()}
                    >
                      Cancelar
                    </ReactButton>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Status;
