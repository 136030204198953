import { format } from 'date-fns';

/**
 *
 * @param {string} ISOStringDate - Data como uma string ISO
 * @return {string} Data formatada: dd/mm/yyyy às hh:mm:ss
 */
export const toBrazilianFullDate = (ISOStringDate) => {
  const date = new Date(ISOStringDate);
  return `${date.toLocaleDateString('pt-BR')} às ${date.toLocaleTimeString(
    'pt-BR',
    {
      timeZone: 'America/Fortaleza'
    }
  )}`;
};

export const toBrazilianDate = (ISOStringDate) => {
  const date = new Date(ISOStringDate);
  return format(date, 'dd/LL/yyyy');
};

export const monthDiff = (dateFrom, dateTo) => {
  return (
    new Date(dateTo).getMonth() -
    new Date(dateFrom).getMonth() +
    12 * (new Date(dateTo).getFullYear() - new Date(dateFrom).getFullYear())
  );
};

export const daysDiff = (dateFrom, dateTo) => {
  const diffInTime = new Date(dateTo).getTime() - new Date(dateFrom).getTime();
  return Math.floor(diffInTime / (1000 * 3600 * 24));
};
