import { format, startOfMonth } from 'date-fns';
export const functionsUseFul = () => {
  const buildProtocolTitleString = (title = '') => {
    if (title !== null) {
      const maxLength = 50;
      if (title.length <= maxLength) return title;
      return `${title.substr(0, maxLength)}...`;
    } else {
      return;
    }
  };

  const getDateBegin = (altFormat = false) => {
    const date = new Date();
    const firstDayMonth = new Date(
      date.getFullYear('pt-br'),
      date.getMonth('pt-br'),
      1
    );
    if (altFormat) {
      const dateBegin = format(startOfMonth(firstDayMonth), 'LL/dd/yyyy');
      return dateBegin;
    }
    const dateBegin = format(startOfMonth(firstDayMonth), 'dd/LL/yyyy');
    return dateBegin;
  };

  const disabledDownloadButtonCsvAndPdf = (
    media,
    quantity,
    limitGenerationPDF,
    filtersProtocol
  ) => {
    if (media === 'pdf') {
      if (
        quantity > 0 &&
        quantity <= limitGenerationPDF &&
        Object.keys(filtersProtocol).length > 0
      ) {
        return false;
      } else if (
        quantity > 0 &&
        quantity > limitGenerationPDF &&
        Object.keys(filtersProtocol).length > 0
      ) {
        return true;
      } else {
        return true;
      }
    } else if (media === 'csv') {
      if (quantity > 0 && Object.keys(filtersProtocol).length > 0) {
        return false;
      } else {
        return true;
      }
    }
  };

  const definedMessageGeneratedInPdfCsvDownloads = (
    quantity,
    limitGenerationPDF,
    limitGenerationXLSX,
    filtersProtocol
  ) => {
    const message = {
      xlsxMessage: '',
      pdfMessage: ''
    };

    if (
      quantity > 0 &&
      quantity <= limitGenerationPDF &&
      Object.keys(filtersProtocol).length > 0
    ) {
      message.pdfMessage =
        'Clique aqui para realizar o Download do relatório em PDF';
      message.xlsxMessage = `Clique aqui para realizar o download do relatório em XLSX. Arquivos menores que ${limitGenerationXLSX} registros serão baixados em XLSX, caso contrário serão em CSV`;
    } else if (quantity > 0 && quantity > limitGenerationPDF) {
      message.pdfMessage = `Infelizmente essa quantidade de ${quantity}, não é suportando para download em PDF`;
      message.xlsxMessage = `Clique aqui para realizar o download do relatório em XLSX. Arquivos menores que ${limitGenerationXLSX} registros serão baixados em XLSX, caso contrário serão em CSV`;
    } else if (quantity <= 0) {
      message.pdfMessage =
        'Por favor verifique a quantide de registro, ou realize uma filtragem';

      message.xlsxMessage =
        'Por favor verifique a quantide de registro, ou realize uma filtragem';
    }

    if (quantity > 0 && Object.keys(filtersProtocol).length === 0) {
      message.pdfMessage =
        'Por favor verifique a quantide de registro, ou realize uma filtragem';

      message.xlsxMessage =
        'Por favor verifique a quantide de registro, ou realize uma filtragem';
    }

    return message;
  };
  return {
    getDateBegin,
    buildProtocolTitleString,
    disabledDownloadButtonCsvAndPdf,
    definedMessageGeneratedInPdfCsvDownloads
  };
};
