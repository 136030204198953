import React from 'react';

export function ModalHeader({ toggleModal }) {
  return (
    <div className="modal-header">
      <h6 className="modal-title" id="modal-title-default">
        Perfil
      </h6>
      <button
        aria-label="Close"
        className="close"
        data-dismiss="modal"
        type="button"
        onClick={toggleModal}
      >
        <span aria-hidden={true}>×</span>
      </button>
    </div>
  );
}
