import ServiceCRUD from '../../../services/ServiceCRUDFLEXUC';
import { apiUc as http } from '../../../http';

class Service extends ServiceCRUD {
  constructor() {
    super('/api/listanegra');
  }

  getAllBlacklistNumbers(blacklistId) {
    return http.get(`${this.path}/${blacklistId}/numeros`);
  }

  getAllBlacklistNumbersSearch(blacklistId, { page, size, query }) {
    return http.get(`${this.path}/${blacklistId}/numeros?q=${query}`, {
      headers: {
        limit: size,
        offset: size * (page - 1)
      }
    });
  }

  createBlacklistNumber(blacklistId, data) {
    return http.post(`${this.path}/${blacklistId}/numeros`, data);
  }

  deleteBlacklistNumber(blacklistId, ids) {
    return http.delete(
      `${this.path}/${blacklistId}/numeros?ids=${ids.join(',')}`
    );
  }

  importBlacklistNumberList(blacklistId, data) {
    return http.post(`${this.path}/${blacklistId}/numeros/import`, data);
  }
}

export default new Service();
