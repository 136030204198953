/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback } from 'react';
import Service from '../service';
import { toast } from 'react-toastify';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'components/Pagination';
import { BiArrowBack } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { Container, Row, Card, CardHeader, Button, Col } from 'reactstrap';

import ToggleSwitch from '../../../../components/ToggleSwitch/ToggleSwitch';
import ModalCreateTag from './components/ModalCreateTag';
import ModalUpdateTag from './components/ModalUpdateTag';
import ModalConfirmation from './components/ModalConfirmation';
import { useHistory } from 'react-router-dom';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_icon_header_style_callback,
  individual_header_style
} from '../../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const TagsList = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [openModalCreateTag, setOpenModalCreateTag] = useState(false);
  const [openModalUpdateTag, setOpenModalUpdateTag] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [callbacks, setCallbacks] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [idTag, setIdTag] = useState();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const handleGetTags = useCallback((selectedPage = 1) => {
    Service.getAll(selectedPage)
      .then((res) => {
        const { list, count } = res.data;
        const newActivePage = selectedPage || activePage;
        setCallbacks(list);
        setQuantity(count);
        setActivePage(newActivePage);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Não foi possível lista as tags', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  }, []);

  useEffect(handleGetTags, []);

  const handlePageChange = (activePage) => handleGetTags(activePage);

  const toggleDisableAndEnable = (id, status) => {
    const newStatus = {
      active: !status
    };
    Service.enable(id, newStatus)
      .then((res) => {
        if (res.data === 'success') {
          handleGetTags();
          const message = newStatus.active ? 'Habilitada' : 'Desabilitada';
          toast.success(
            `Alteração realizada com sucesso, tag agora está ${message}`,
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Erro ao alterar status da tag ', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const updateTag = (id) => {
    setIdTag(id);
    setOpenModalUpdateTag(true);
  };

  const deletedTag = (id) => {
    setIdTag(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <div
        className="header header-dark pb-6 content__title content__title--calendar"
        style={{ background: systemConfiguration.primary_color }}
      >
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                <Button
                  size="sm"
                  color={systemConfiguration.primary_color}
                  title="Volta para a pagina de configurações"
                  onClick={() => history.push(`/admin/configuration`)}
                >
                  <BiArrowBack size="35px" color="white" />
                </Button>
              </Col>
              <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
                <Button onClick={() => setOpenModalCreateTag(true)}>
                  {' '}
                  Criar{' '}
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Tags</h3>
                </CardHeader>
                <ToolkitProvider
                  data={callbacks}
                  keyField="_id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },

                    {
                      dataField: 'active',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style_callback,
                      text: 'Desabilitar / Habilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            checked={row.active}
                            id={row._id}
                            onChange={() => {
                              toggleDisableAndEnable(row._id, row.active);
                            }}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="info"
                            title="Editar Tag"
                            type="button"
                            size="sm"
                            onClick={() => updateTag(row._id)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Excluir',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            title="Excluir Tag"
                            type="button"
                            size="sm"
                            onClick={() => deletedTag(row._id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalCreateTag
        render={handleGetTags}
        isModalOpen={openModalCreateTag}
        onModalToggle={setOpenModalCreateTag}
        unmountOnClose={true}
      />
      <ModalUpdateTag
        render={handleGetTags}
        idTag={idTag}
        isModalOpen={openModalUpdateTag}
        onModalToggle={setOpenModalUpdateTag}
        unmountOnClose={true}
      />
      <ModalConfirmation
        render={handleGetTags}
        idTag={idTag}
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
      />
    </>
  );
};

export default TagsList;
