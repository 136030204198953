import React, { useState, useEffect } from 'react';
import DefaultModal from '../../../../../../components/Modal/DefaultModal';
import OpportunityTimeService from '../../../../Report/TimeReport/services/opportunityService';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';

const OpportunityTimeModal = ({
  isModalOpen,
  onModalToggle,
  opportunityId,
  changedStage,
  setChangedStage,
  ...props
}) => {
  const [opportunityTime, setOpportunityTime] = useState();

  useEffect(() => {
    OpportunityTimeService.getOpportunityTimeByOpportunityId(opportunityId)
      .then(({ data }) => {
        setOpportunityTime(data.data);
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.message || 'Erro ao buscar dados do Lead'
        );
      });
  }, [opportunityId]);

  useEffect(() => {
    if (changedStage) {
      OpportunityTimeService.getOpportunityTimeByOpportunityId(opportunityId)
        .then(({ data }) => {
          console.log('Pegou os dados');
          setOpportunityTime(data.data);
        })
        .catch((err) => {
          toast.error(
            err.response?.data?.message || 'Erro ao buscar dados do Lead'
          );
        })
        .finally(() => {
          setChangedStage(false);
        });
    }
  }, [opportunityId, changedStage, setChangedStage]);

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Prazos da Oportunidade"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      <>
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  {opportunityTime?.length >= 1 ? (
                    opportunityTime?.map((opportunityTime) => (
                      <div
                        key={opportunityTime.id}
                        className="timeline timeline-one-side mx-0"
                        data-timeline-axis-style="dashed"
                        data-timeline-content="axis"
                      >
                        <div className="timeline-block pb-5">
                          <span className="timeline-step badge-success">
                            <i className="ni ni-calendar-grid-58" />
                          </span>

                          <div
                            className="timeline-content"
                            style={{ maxWidth: '80rem' }}
                          >
                            <p className="text-sm mt-1 mb-0">
                              <strong>Fase da Oportunidade</strong>:{' '}
                              {opportunityTime.stageName}
                            </p>

                            <p className="text-sm mt-1 mb-0">
                              <strong>Dias que passou na fase</strong>:{' '}
                              {opportunityTime.time}
                            </p>

                            <p className="text-sm mt-1 mb-0">
                              <strong>
                                Oportunidade foi movida dentro do prazo
                              </strong>
                              :{' '}
                              {opportunityTime.movedAtTime ? (
                                <span className="text-success">Sim</span>
                              ) : (
                                <span className="text-danger">Não</span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm mt-1 mb-0 text-center">
                      Não há nenhuma informação a ser mostrada.
                    </p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    </DefaultModal>
  );
};

export default OpportunityTimeModal;
