import styled from 'styled-components';

export const ButtonLogo = styled.button`
  width: 40px;
  height: 40px;
  border: 1px solid white;
  border-radius: 99999999999px;
  background-color: ${(props) => (props.active ? '#dadce0' : '#ffffff')};
  margin-top: 0.5rem;
  margin-left: 0.5rem;

  &:hover {
    border: 1px solid #dadce0;
    border-radius: 999999999px;
  }
`;

export const HeaderCalendar = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Lato&family=Source+Sans+Pro&display=swap');

  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: space-between;

  h2 {
    font-size: 40px;
    font-family: 'Lato', sans-serif;
    font-weight: 400;
    color: #646f85;
    margin-left: 3rem;
  }
`;
