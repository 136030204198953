import { useState, useEffect } from 'react';
import ProfileService from '../../../Profile/service';
import { brazilian } from 'assets/translator/pt-br';

export function useGetProfiles() {
  const [profiles, setProfiles] = useState([]);

  function toTranslatedProfiles(listProfiles) {
    const translatedBrazilianProfiles = brazilian.PROFILES;
    const profilesTranslated = listProfiles.map((profile) => {
      if (translatedBrazilianProfiles[profile.name]) {
        profile.name = translatedBrazilianProfiles[profile.name];
      }
      return profile;
    });

    return profilesTranslated;
  }

  useEffect(() => {
    ProfileService.getAll()
      .then((response) => {
        const profileList = response.data.data;
        const profilesTranslated = toTranslatedProfiles(profileList);
        setProfiles(profilesTranslated);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);

  return {
    profiles,
    setProfiles
  };
}
