import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { FiPlus } from 'react-icons/fi';
import { useForm } from 'react-hook-form';
import Loader from 'react-loader-spinner';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Form,
  Input,
  Button,
  Collapse
} from 'reactstrap';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import ConfigurationService from '../services';
import {
  ImageContainer,
  ButtonContainer,
  PreviewButton,
  PreviewColor
} from './components/styles';
import { useSelector, useDispatch } from 'react-redux';
import { getLogoSocialURL, getLogoURL } from '../services/getLogoURL';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { updateConfiguration } from '../../../store/action/systemConfiguration/index';

import { FlexCollapse } from '../../../components/FlexCollapse';
import ChatMessageAudioSelector from './components/ChatMessageAudioSelector/ChatMessageAudioSelector';
import { fileToBase64 } from 'utils/fuctions/fileTo64';

const allowedTypes = [
  'image/png'
  // 'image/jpg',
  // 'image/jpeg',
  // 'image/bmp',
  // 'image/tiff'
];

const SystemParamsForm = () => {
  const [logo, setLogo] = useState(null);
  const [logoSocial, setLogoSocial] = useState(null);
  const [logoURL, setLogoURL] = useState(null);
  const [logoSocialURL, setLogoSocialURL] = useState(null);
  const { handleSubmit } = useForm();
  const [configurations, setConfigurations] = useState(null);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [loading, setLoading] = useState(false);

  const [stateCollapse, setStateCollapse] = useState(false);
  const [stateCollapseNPS, setStateCollapseNPS] = useState(false);
  const [stateCollapseInternalChat, setstateCollapseInternalChat] =
    useState(false);
  const [stateCollapseAudioChat, setStateCollapseAudioChat] = useState(false);
  const [sound, setSound] = useState(null);
  const [base64Sound, setBase64Sound] = useState(null);
  const [isValidSound, setIsValidSound] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true);
    ConfigurationService.getAllConfigs()
      .then((res) => {
        setConfigurations(res.data);
        setBase64Sound(res.data.audio_chat);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getLogoURL()
      .then((source) => {
        setLogoURL(source);
      })
      .catch(() => console.error('[LOGIN FORM] Error loading logo'));

    getLogoSocialURL()
      .then((source) => {
        setLogoSocialURL(source);
      })
      .catch(() => console.error('[LOGIN FORM] Error loading social logo'));
  }, []);

  // const isImageValid = () => {
  //   if (!image) return false;
  //   if (!allowedTypes.includes(image.type)) return false;
  //   return true;
  // };

  function handleSelectImage(event, setState, setStateURL) {
    const file = event.target.files[0];

    if (!file) {
      return;
    }

    if (!allowedTypes.includes(file.type)) {
      //(png, jpg, bmp, tiff)'
      toast.error('Selecione uma imagem válida! (png)', {
        autoClose: 3000,
        closeOnClick: true
      });

      return;
    }

    setState(file);
    setStateURL(URL.createObjectURL(file));
  }

  function changeColor(target) {
    const type = target.id;
    const color = target.value;

    switch (type) {
      case 'confirmation_button_color':
        setConfigurations({
          ...configurations,
          confirmation_button_color: color
        });
        break;
      case 'cancelation_button_color':
        setConfigurations({
          ...configurations,
          cancelation_button_color: color
        });
        break;
      case 'primary_color':
        setConfigurations({
          ...configurations,
          primary_color: color
        });
        break;
      case 'secondary_color':
        setConfigurations({
          ...configurations,
          secondary_color: color
        });
        break;
      case 'background_color_screen_satisfaction_survey':
        setConfigurations({
          ...configurations,
          background_color_screen_satisfaction_survey: color
        });
        break;
      case 'background_color_screen_card_satisfaction_survey':
        setConfigurations({
          ...configurations,
          background_color_screen_card_satisfaction_survey: color
        });
        break;
      case 'font_color_card_survey':
        setConfigurations({
          ...configurations,
          font_color_card_survey: color
        });
        break;
      case 'font_color_card_survey_tags_selected':
        setConfigurations({
          ...configurations,
          font_color_card_survey_tags_selected: color
        });
        break;
      case 'internal_chat_primary_color':
        setConfigurations({
          ...configurations,
          internal_chat_primary_color: color
        });
        break;
      case 'internal_chat_balloon_color':
        setConfigurations({
          ...configurations,
          internal_chat_balloon_color: color
        });
        break;
      case 'internal_chat_font_color':
        setConfigurations({
          ...configurations,
          internal_chat_font_color: color
        });
        break;
      default:
        break;
    }
  }

  function handleWelcome(event) {
    setConfigurations({ ...configurations, welcome_msg: event.target.value });
  }

  const onSubmit = async () => {
    setLoading(true);

    let responses = [];
    const logoData = new FormData();
    logoData.append('logo', logo);

    const logoSocialData = new FormData();
    logoSocialData.append('logo_social', logoSocial);

    if (sound) {
      if (!isValidSound) {
        toast.error('Formato ou tamanho de arquivo inválido', {
          autoClose: 3000,
          closeOnClick: true
        });
        setLoading(false);
        return;
      }
    }
    if (base64Sound === null) {
      responses.push(
        await ConfigurationService.updateConfigs({
          ...configurations,
          audio_chat: await fileToBase64(sound)
            .then((audio64) => {
              return audio64;
            })
            .catch(() => {
              return null;
            })
        })
      );
    } else {
      responses.push(
        await ConfigurationService.updateConfigs({
          ...configurations,
          audio_chat: base64Sound
        })
      );
    }

    responses.push(await ConfigurationService.updateLogo(logoData));
    responses.push(await ConfigurationService.updateLogoSocial(logoSocialData));

    let error = false;

    responses.forEach((res) => {
      if (res.status !== 200) error = true;
    });

    if (error) {
      toast.error(
        'Erro ao atualizar configurações, tente novamente mais tarde!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );

      setLoading(false);
    } else {
      toast.success('Configurações atualizadas com sucesso!', {
        autoClose: 3000,
        closeOnClick: true
      });

      dispatch(updateConfiguration(configurations));
      document.location.reload();
    }
  };

  // const changeFile = (event) => {
  //   const file = event.target.files[0];
  //   setImage(file);
  // };

  // const uploadLogo = (data) => {
  //   axios
  //     .post(`${BASE_URL}/api/logos`, data, {
  //       headers: { 'Content-Type': 'multipart/form-data' }
  //     })
  //     .then(() => {
  //       toast.success('Upload realizado com sucesso', {
  //         autoClose: 3000,
  //         closeOnClick: true
  //       });
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       const errorMessage =
  //         (error.response.data && error.response.data.message) ||
  //         'Ocorreu um erro';
  //       toast.error(errorMessage, {
  //         autoClose: 3000,
  //         closeOnClick: true
  //       });
  //     });
  // };

  // const onSubmitImage = async () => {
  //   const formData = new FormData();
  //   formData.append('logo', image);
  //   uploadLogo(formData);
  // };

  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Card>
          <CardHeader>
            <h4 className="mb-0">Configurações do sistema</h4>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Row className="justify-content-md-center">
                <Loader
                  type="TailSpin"
                  color={systemConfiguration.primary_color}
                  height={100}
                  width={100}
                />
              </Row>
            ) : (
              <div>
                <FlexCollapse
                  title="Cores e imagens do sistema"
                  stateCollapse={stateCollapse}
                  setStateCollapse={setStateCollapse}
                />
                <Collapse isOpen={stateCollapse}>
                  <Form
                    className="needs-validation"
                    style={{ marginTop: '20px' }}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mb-3">
                      <Col>
                        <h4 className="mb-1">Mensagem de boas vindas</h4>
                      </Col>
                    </Row>

                    <Row className="mb-5">
                      <Col md="12">
                        {configurations && (
                          <Input
                            value={configurations.welcome_msg}
                            onChange={handleWelcome}
                          />
                        )}
                      </Col>
                    </Row>

                    {configurations && (
                      <>
                        <Row className="mb-4">
                          <Col md="3">
                            <Row className="mb-1">
                              <Col>
                                <h4>Cor principal</h4>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <ButtonContainer>
                                  <PreviewColor
                                    color={configurations.primary_color}
                                    htmlFor="primary_color"
                                  />

                                  <input
                                    type="color"
                                    id="primary_color"
                                    value={configurations.primary_color}
                                    onChange={(e) => changeColor(e.target)}
                                  />
                                </ButtonContainer>
                              </Col>
                            </Row>
                          </Col>

                          <Col md="6">
                            <Row>
                              <Col>
                                <h4 className="mb-1">Cores dos botões</h4>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <ButtonContainer>
                                  <PreviewButton
                                    color={
                                      configurations.confirmation_button_color
                                    }
                                    htmlFor="confirmation_button_color"
                                  >
                                    Confirmação
                                  </PreviewButton>
                                  <input
                                    type="color"
                                    id="confirmation_button_color"
                                    value={
                                      configurations.confirmation_button_color
                                    }
                                    onChange={(e) => changeColor(e.target)}
                                  />

                                  <PreviewButton
                                    color={
                                      configurations.cancelation_button_color
                                    }
                                    htmlFor="cancelation_button_color"
                                  >
                                    Cancelamento
                                  </PreviewButton>
                                  <input
                                    type="color"
                                    id="cancelation_button_color"
                                    value={
                                      configurations.cancelation_button_color
                                    }
                                    onChange={(e) => changeColor(e.target)}
                                  />
                                </ButtonContainer>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="md-6">
                            <Row>
                              <Col className="mb-1">
                                <h4>Logo</h4>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <ImageContainer
                                  mainColor={systemConfiguration.primary_color}
                                >
                                  <div className="input-block">
                                    <div className="images-container">
                                      {logoURL ? (
                                        <label htmlFor="logo">
                                          <img
                                            key={logoURL}
                                            src={logoURL}
                                            alt="..."
                                          />
                                        </label>
                                      ) : (
                                        <label
                                          htmlFor="logo"
                                          className="new-image"
                                        >
                                          <FiPlus
                                            size={24}
                                            color={
                                              systemConfiguration.primary_color
                                            }
                                          />
                                        </label>
                                      )}
                                    </div>

                                    <input
                                      onChange={(event) =>
                                        handleSelectImage(
                                          event,
                                          setLogo,
                                          setLogoURL
                                        )
                                      }
                                      type="file"
                                      id="logo"
                                      accept={allowedTypes.join(', ')}
                                    />
                                  </div>
                                </ImageContainer>
                              </Col>
                            </Row>
                          </Col>

                          <Col className="md-6">
                            <Row>
                              <Col className="mb-1">
                                <h4>Capa</h4>
                              </Col>
                            </Row>

                            <Row>
                              <Col>
                                <ImageContainer
                                  mainColor={systemConfiguration.primary_color}
                                >
                                  <div className="input-block">
                                    <div className="images-container">
                                      {logoSocialURL ? (
                                        <label htmlFor="logoSocial">
                                          <img
                                            key={logoSocialURL}
                                            src={logoSocialURL}
                                            alt="..."
                                          />
                                        </label>
                                      ) : (
                                        <label
                                          htmlFor="logoSocial"
                                          className="new-image"
                                        >
                                          <FiPlus
                                            size={24}
                                            color={
                                              systemConfiguration.primary_color
                                            }
                                          />
                                        </label>
                                      )}
                                    </div>

                                    <input
                                      onChange={(event) =>
                                        handleSelectImage(
                                          event,
                                          setLogoSocial,
                                          setLogoSocialURL
                                        )
                                      }
                                      type="file"
                                      id="logoSocial"
                                      accept={allowedTypes.join(', ')}
                                    />
                                  </div>
                                </ImageContainer>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    )}

                    <Row>
                      <Col>
                        <Button
                          style={buttonStyle(
                            systemConfiguration.confirmation_button_color
                          )}
                          color="primary"
                          type="submit"
                        >
                          Salvar
                        </Button>
                        <Button
                          style={buttonStyle(
                            systemConfiguration.cancelation_button_color
                          )}
                          color="danger"
                          onClick={() => setStateCollapse(false)}
                        >
                          Fechar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Collapse>

                <FlexCollapse
                  title="Configurações do NPS"
                  stateCollapse={stateCollapseNPS}
                  setStateCollapse={setStateCollapseNPS}
                />
                <Collapse isOpen={stateCollapseNPS}>
                  <Form
                    className="needs-validation"
                    style={{ marginTop: '20px' }}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {configurations && (
                      <Row className="mb-2">
                        <Col md="3">
                          <Row className="mb-1">
                            <Col>
                              <h4>Cor de fundo da pesquisa</h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonContainer>
                                <PreviewColor
                                  color={
                                    configurations.background_color_screen_satisfaction_survey
                                  }
                                  htmlFor="background_color_screen_satisfaction_survey"
                                />

                                <input
                                  type="color"
                                  id="background_color_screen_satisfaction_survey"
                                  value={configurations.primary_color}
                                  onChange={(e) => changeColor(e.target)}
                                />
                              </ButtonContainer>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="3">
                          <Row className="mb-1">
                            <Col>
                              <h4>Cor do card da pesquisa</h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonContainer>
                                <PreviewColor
                                  color={
                                    configurations.background_color_screen_card_satisfaction_survey
                                  }
                                  htmlFor="background_color_screen_card_satisfaction_survey"
                                />

                                <input
                                  type="color"
                                  id="background_color_screen_card_satisfaction_survey"
                                  value={configurations.primary_color}
                                  onChange={(e) => changeColor(e.target)}
                                />
                              </ButtonContainer>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="3">
                          <Row className="mb-1">
                            <Col>
                              <h4>Cor da fonte da Pesquisa</h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonContainer>
                                <PreviewColor
                                  color={configurations.font_color_card_survey}
                                  htmlFor="font_color_card_survey"
                                />

                                <input
                                  type="color"
                                  id="font_color_card_survey"
                                  value={configurations.font_color_card_survey}
                                  onChange={(e) => changeColor(e.target)}
                                />
                              </ButtonContainer>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="3">
                          <Row className="mb-1">
                            <Col>
                              <h4>
                                Cor da fonte na(s) tag(s) selecionada(s) na
                                pesquisa
                              </h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonContainer>
                                <PreviewColor
                                  color={
                                    configurations.font_color_card_survey_tags_selected
                                  }
                                  htmlFor="font_color_card_survey_tags_selected"
                                />

                                <input
                                  type="color"
                                  id="font_color_card_survey_tags_selected"
                                  value={
                                    configurations.font_color_card_survey_tags_selected
                                  }
                                  onChange={(e) => changeColor(e.target)}
                                />
                              </ButtonContainer>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                    <Button
                      style={buttonStyle(
                        systemConfiguration.confirmation_button_color
                      )}
                      color="primary"
                      type="submit"
                    >
                      Salvar
                    </Button>
                    <Button
                      style={buttonStyle(
                        systemConfiguration.cancelation_button_color
                      )}
                      color="danger"
                      onClick={() => setStateCollapseNPS(!stateCollapseNPS)}
                    >
                      Fechar
                    </Button>
                  </Form>
                </Collapse>

                <FlexCollapse
                  title="Configurações de Áudio do Chat"
                  stateCollapse={stateCollapseAudioChat}
                  setStateCollapse={setStateCollapseAudioChat}
                />
                <Collapse isOpen={stateCollapseAudioChat}>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <ChatMessageAudioSelector
                      sound={sound ? sound : null}
                      base64Sound={base64Sound ? base64Sound : null}
                      setBase64Sound={setBase64Sound}
                      setSound={setSound}
                      tooltipText={
                        'Selecione o áudio que será utilizado nas notificações do chat'
                      }
                      setIsValidSound={setIsValidSound}
                    />
                    <Row>
                      <Col>
                        <Button
                          style={buttonStyle(
                            systemConfiguration.confirmation_button_color
                          )}
                          color="primary"
                          type="submit"
                        >
                          Salvar
                        </Button>
                        <Button
                          style={buttonStyle(
                            systemConfiguration.cancelation_button_color
                          )}
                          color="danger"
                          onClick={() => setStateCollapseAudioChat(false)}
                        >
                          Fechar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Collapse>

                <FlexCollapse
                  title="Configurações do Flex Chat"
                  stateCollapse={stateCollapseInternalChat}
                  setStateCollapse={setstateCollapseInternalChat}
                />
                <Collapse isOpen={stateCollapseInternalChat}>
                  <Form
                    className="needs-validation"
                    style={{ marginTop: '20px' }}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    {configurations && (
                      <Row className="mb-2">
                        <Col md="3">
                          <Row className="mb-1">
                            <Col>
                              <h4>Cor principal</h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonContainer>
                                <PreviewColor
                                  color={
                                    configurations.internal_chat_primary_color
                                  }
                                  htmlFor="internal_chat_primary_color"
                                />

                                <input
                                  type="color"
                                  id="internal_chat_primary_color"
                                  value={
                                    configurations.internal_chat_primary_color
                                  }
                                  onChange={(e) => changeColor(e.target)}
                                />
                              </ButtonContainer>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="3">
                          <Row className="mb-1">
                            <Col>
                              <h4>Cor do balão de conversa</h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonContainer>
                                <PreviewColor
                                  color={
                                    configurations.internal_chat_balloon_color
                                  }
                                  htmlFor="internal_chat_balloon_color"
                                />

                                <input
                                  type="color"
                                  id="internal_chat_balloon_color"
                                  value={
                                    configurations.internal_chat_balloon_color
                                  }
                                  onChange={(e) => changeColor(e.target)}
                                />
                              </ButtonContainer>
                            </Col>
                          </Row>
                        </Col>
                        <Col md="3">
                          <Row className="mb-1">
                            <Col>
                              <h4>Cor da fonte do balão de conversa</h4>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <ButtonContainer>
                                <PreviewColor
                                  color={
                                    configurations.internal_chat_font_color
                                  }
                                  htmlFor="internal_chat_font_color"
                                />

                                <input
                                  type="color"
                                  id="internal_chat_font_color"
                                  value={
                                    configurations.internal_chat_font_color
                                  }
                                  onChange={(e) => changeColor(e.target)}
                                />
                              </ButtonContainer>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                    <Button
                      style={buttonStyle(
                        systemConfiguration.confirmation_button_color
                      )}
                      color="primary"
                      type="submit"
                    >
                      Salvar
                    </Button>
                    <Button
                      style={buttonStyle(
                        systemConfiguration.cancelation_button_color
                      )}
                      color="danger"
                      onClick={() =>
                        setstateCollapseInternalChat(!stateCollapseInternalChat)
                      }
                    >
                      Fechar
                    </Button>
                  </Form>
                </Collapse>
              </div>
            )}
          </CardBody>
          {/* <CardHeader>
            <h4 className="mb-0">Configurações do sistema</h4>
          </CardHeader>
          <CardBody>
            {loading ? (
              <Row className="justify-content-md-center">
                <Loader
                  type="TailSpin"
                  color={systemConfiguration.primary_color}
                  height={100}
                  width={100}
                />
              </Row>
            ) : (
              <Form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row className="mb-3">
                  <Col>
                    <h4 className="mb-1">Mensagem de boas vindas</h4>
                  </Col>
                </Row>

                <Row className="mb-5">
                  <Col md="12">
                    {configurations && (
                      <Input
                        value={configurations.welcome_msg}
                        onChange={handleWelcome}
                      />
                    )}
                  </Col>
                </Row>

                {configurations && (
                  <Row className="mb-4">
                    <Col md="3">
                      <Row className="mb-1">
                        <Col>
                          <h4>Cor principal</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ButtonContainer>
                            <PreviewColor
                              color={configurations.primary_color}
                              htmlFor="primary_color"
                            />

                            <input
                              type="color"
                              id="primary_color"
                              value={configurations.primary_color}
                              onChange={(e) => changeColor(e.target)}
                            />
                          </ButtonContainer>
                        </Col>
                      </Row>
                    </Col>
                   
                    <Col md="6">
                      <Row>
                        <Col>
                          <h4 className="mb-1">Cores dos botões</h4>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          <ButtonContainer>
                            <PreviewButton
                              color={configurations.confirmation_button_color}
                              htmlFor="confirmation_button_color"
                            >
                              Confirmação
                            </PreviewButton>
                            <input
                              type="color"
                              id="confirmation_button_color"
                              value={configurations.confirmation_button_color}
                              onChange={(e) => changeColor(e.target)}
                            />

                            <PreviewButton
                              color={configurations.cancelation_button_color}
                              htmlFor="cancelation_button_color"
                            >
                              Cancelamento
                            </PreviewButton>
                            <input
                              type="color"
                              id="cancelation_button_color"
                              value={configurations.cancelation_button_color}
                              onChange={(e) => changeColor(e.target)}
                            />
                          </ButtonContainer>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                {configurations && (
                  <Row className="mb-2">
                    <Col md="3">
                      <Row className="mb-1">
                        <Col>
                          <h4>Cor de fundo da pesquisa</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ButtonContainer>
                            <PreviewColor
                              color={
                                configurations.background_color_screen_satisfaction_survey
                              }
                              htmlFor="background_color_screen_satisfaction_survey"
                            />

                            <input
                              type="color"
                              id="background_color_screen_satisfaction_survey"
                              value={configurations.primary_color}
                              onChange={(e) => changeColor(e.target)}
                            />
                          </ButtonContainer>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="3">
                      <Row className="mb-1">
                        <Col>
                          <h4>Cor do card da pesquisa</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ButtonContainer>
                            <PreviewColor
                              color={
                                configurations.background_color_screen_card_satisfaction_survey
                              }
                              htmlFor="background_color_screen_card_satisfaction_survey"
                            />

                            <input
                              type="color"
                              id="background_color_screen_card_satisfaction_survey"
                              value={configurations.primary_color}
                              onChange={(e) => changeColor(e.target)}
                            />
                          </ButtonContainer>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="3">
                      <Row className="mb-1">
                        <Col>
                          <h4>Cor da fonte da Pesquisa</h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ButtonContainer>
                            <PreviewColor
                              color={configurations.font_color_card_survey}
                              htmlFor="font_color_card_survey"
                            />

                            <input
                              type="color"
                              id="font_color_card_survey"
                              value={configurations.font_color_card_survey}
                              onChange={(e) => changeColor(e.target)}
                            />
                          </ButtonContainer>
                        </Col>
                      </Row>
                    </Col>
                    <Col md="3">
                      <Row className="mb-1">
                        <Col>
                          <h4>
                            Cor da fonte na(s) tag(s) selecionada(s) na pesquisa
                          </h4>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <ButtonContainer>
                            <PreviewColor
                              color={
                                configurations.font_color_card_survey_tags_selected
                              }
                              htmlFor="font_color_card_survey_tags_selected"
                            />

                            <input
                              type="color"
                              id="font_color_card_survey_tags_selected"
                              value={
                                configurations.font_color_card_survey_tags_selected
                              }
                              onChange={(e) => changeColor(e.target)}
                            />
                          </ButtonContainer>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}

                <Row className="mb-3">
                  <Col className="md-6">
                    <Row>
                      <Col className="mb-1">
                        <h4>Logo</h4>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <ImageContainer
                          mainColor={systemConfiguration.primary_color}
                        >
                          <div className="input-block">
                            <div className="images-container">
                              {logoURL ? (
                                <label htmlFor="logo">
                                  <img key={logoURL} src={logoURL} alt="..." />
                                </label>
                              ) : (
                                <label htmlFor="logo" className="new-image">
                                  <FiPlus
                                    size={24}
                                    color={systemConfiguration.primary_color}
                                  />
                                </label>
                              )}
                            </div>

                            <input
                              onChange={(event) =>
                                handleSelectImage(event, setLogo, setLogoURL)
                              }
                              type="file"
                              id="logo"
                              accept={allowedTypes.join(', ')}
                            />
                          </div>
                        </ImageContainer>
                      </Col>
                    </Row>
                  </Col>

                  <Col className="md-6">
                    <Row>
                      <Col className="mb-1">
                        <h4>Capa</h4>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <ImageContainer
                          mainColor={systemConfiguration.primary_color}
                        >
                          <div className="input-block">
                            <div className="images-container">
                              {logoSocialURL ? (
                                <label htmlFor="logoSocial">
                                  <img
                                    key={logoSocialURL}
                                    src={logoSocialURL}
                                    alt="..."
                                  />
                                </label>
                              ) : (
                                <label
                                  htmlFor="logoSocial"
                                  className="new-image"
                                >
                                  <FiPlus
                                    size={24}
                                    color={systemConfiguration.primary_color}
                                  />
                                </label>
                              )}
                            </div>

                            <input
                              onChange={(event) =>
                                handleSelectImage(
                                  event,
                                  setLogoSocial,
                                  setLogoSocialURL
                                )
                              }
                              type="file"
                              id="logoSocial"
                              accept={allowedTypes.join(', ')}
                            />
                          </div>
                        </ImageContainer>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}

          {/* <label className="form-control-label d-block mb-3">
                      Logo (png, jpg, bmp, tiff) *
                    </label>
                    <label
                      htmlFor="systemLogo"
                      className="btn btn-primary btn-sm"
                    >
                      Selecionar uma imagem
                    </label>
                    {image && image.name}
                    <Input
                      style={{ display: 'none' }}
                      type="file"
                      id="systemLogo"
                      name="logo"
                      onChange={changeFile}
                      accept={allowedTypes.join(', ')}
                    />
                    <Row className="mt-4">
                      <Col>
                        <Button
                          color="primary"
                          disabled={!isImageValid()}
                          onClick={onSubmitImage}
                        >
                          Enviar
                        </Button>
                      </Col>
                    </Row> */}

          {/* <Button
                      color="primary"
                      disabled={!isImageValid()}
                      onClick={onSubmitImage}
                    >
                      Enviar
                    </Button> */}

          {/* <hr /> inicio aqui
                <Button
                  style={buttonStyle(
                    systemConfiguration.confirmation_button_color
                  )}
                  color="primary"
                  type="submit"
                >
                  Salvar
                </Button>
                <Button
                  style={buttonStyle(
                    systemConfiguration.cancelation_button_color
                  )}
                  color="danger"
                  onClick={() => history.push('/admin/configuration')}
                >
                  Cancelar
                </Button>
              </Form>
            )}
          </CardBody> */}
        </Card>
      </Container>
    </>
  );
};

export default SystemParamsForm;
