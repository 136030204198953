import styled from 'styled-components';

export const SubProtocolContainer = styled.div`
  position: relative;
  padding: 5px;
  span {
    margin: 0;
    font-size: 14px;
  }
  background-color: #ffffff;
  height: 115px;
  width: 250px;
  border: 2px solid #d3d3d3;
  border-top: 2px solid #7b7a7a;
  border-radius: 2px;
  .protocol-number {
    cursor: pointer;
    font-weight: 500;
    &:hover {
      font-weight: 600;
    }
  }
  .fw-400 {
    font-weight: 400;
  }
  .fw-500 {
    font-weight: 500;
  }
  .fw-600 {
    font-weight: 600;
  }
`;
