import ServiceCRUD from '../../../services/ServiceCRUDFLEXUC';

class IVRService extends ServiceCRUD {
  constructor() {
    super(`/api/uras`);
  }

  getIVROptions(id) {
    return this.get(`/${id}/getUraOptions`);
  }
}

export default new IVRService();
