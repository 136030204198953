import React, { useState, useRef } from 'react';

import AsideCardItem from '../AsideCardItem';
import { AsideCardListWrapper, AsideCardContent } from './styles';

const styleNumber = {
  color: '#fff',
  fontSize: '1.8rem',
  margin: '0',
  padding: '0'
};
const styleTime = {
  color: '#fff',
  fontSize: '1.8rem',
  margin: '0',
  padding: '0'
};

const AsideCardList = ({ queueMonitoringData, watingCalls = [] }) => {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [isDragging, setIsDragging] = useState();

  const [listAsideCards, setListAsideCards] = useState([
    'watingCalls',
    'TME',
    'TMA',
    'longestWaitTime',
    'serviceLevelAccording',
    'serviceLevelAccordingPercentage',
    'qtdAnswered',
    'answeredPercentage'
  ]);

  const dragStart = (e, position) => {
    setIsDragging(position);
    dragItem.current = position;
  };

  const dragEnter = (e, position) => {
    setIsDragging(position);
    dragOverItem.current = position;
  };

  const drop = (e) => {
    const copyListItems = [...listAsideCards];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setIsDragging(null);
    setListAsideCards(copyListItems);
  };

  

  function switchOptions(item) {
    switch (item) {
      case 'watingCalls':
        return (
          <AsideCardItem className="my-2" title="Fila de espera" key="0">
            <span style={styleNumber}>
              {Array.isArray(watingCalls) && watingCalls.length}
            </span>
          </AsideCardItem>
        );
      case 'TMA':
        return (
          <AsideCardItem className="my-2" title="T.M.A" key="2">
            <span style={styleTime}>
              {queueMonitoringData?.TMA || '00:00:00'}
            </span>
          </AsideCardItem>
        );
      case 'TME':
        return (
          <AsideCardItem className="my-2" title="T.M.E" key="1">
            <span style={styleTime}>
              {queueMonitoringData?.TME || '00:00:00'}
            </span>
          </AsideCardItem>
        );
      case 'qtdAnswered':
        return (
          <AsideCardItem className="my-2" title="Status de chamadas" key="7">
            <div className="d-flex flex-row justify-content-center">
              <div className="d-flex flex-column text-center mr-4">
                <span
                  className="my-0 font-weight-normal"
                  style={{ fontSize: '2.0rem' }}
                >
                  {queueMonitoringData?.qtdAnswered || '0'}
                </span>
                <span className="my-0" style={{ fontSize: '0.8rem' }}>
                  Atendidas
                </span>
              </div>
              <div className="d-flex flex-column text-center">
                <span className="my-0" style={{ fontSize: '2.0rem' }}>
                  {queueMonitoringData?.qtdAbandoned || '0'}
                </span>
                <span className="my-0" style={{ fontSize: '0.8rem' }}>
                  Abandonadas
                </span>
              </div>
            </div>
          </AsideCardItem>
        );
      case 'answeredPercentage':
        return (
          <AsideCardItem
            className="my-2"
            title="Status de chamadas (%)"
            key="8"
          >
            <div className="d-flex flex-row justify-content-center">
              <div className="d-flex flex-column text-center mr-4">
                <span
                  className="my-0 font-weight-normal"
                  style={{ fontSize: '1.6rem' }}
                >
                  {queueMonitoringData?.answeredPercentage || '0%'}
                </span>
                <span className="my-0" style={{ fontSize: '0.8rem' }}>
                  Atendidas
                </span>
              </div>
              <div className="d-flex flex-column text-center">
                <span className="my-0" style={{ fontSize: '1.6rem' }}>
                  {queueMonitoringData?.abandonedPercentage || '0%'}
                </span>
                <span className="my-0" style={{ fontSize: '0.8rem' }}>
                  Abandonadas
                </span>
              </div>
            </div>
          </AsideCardItem>
        );
      case 'longestWaitTime':
        return (
          <AsideCardItem className="my-2" title="Maior Tempo de Espera" key="4">
            <span style={styleTime}>
              {queueMonitoringData?.longestWaitTime || '00:00:00'}
            </span>
          </AsideCardItem>
        );
      case 'serviceLevelAccording':
        return (
          <AsideCardItem className="my-2" title="Nível de Serviço" key="5">
            <div className="d-flex flex-row justify-content-center align-items-start">
              <div className="d-flex flex-column text-center mr-4">
                <span
                  className="my-0 font-weight-normal"
                  style={{ fontSize: '2.0rem' }}
                >
                  {queueMonitoringData?.serviceLevel?.according || '0'}
                </span>
                <span
                  className="my-0 font-weight-normal"
                  style={{ fontSize: '0.8rem' }}
                >
                  Conforme
                </span>
              </div>
              <div className="d-flex flex-column text-center">
                <span
                  className="my-0 font-weight-normal"
                  style={{ fontSize: '2.0rem' }}
                >
                  {queueMonitoringData?.serviceLevel?.notAccording || '0'}
                </span>
                <span
                  className="my-0 font-weight-normal"
                  style={{ fontSize: '0.8rem' }}
                >
                  Não Conforme
                </span>
              </div>
            </div>
          </AsideCardItem>
        );
      case 'serviceLevelAccordingPercentage':
        return (
          <AsideCardItem className="my-2" title="Nível de Serviço (%)" key="6">
            <div className="d-flex flex-row justify-content-center align-items-start">
              <div className="d-flex flex-column text-center mr-4">
                <span
                  className="my-0 font-weight-normal"
                  style={{ fontSize: '1.6rem' }}
                >
                  {queueMonitoringData?.serviceLevel?.accordingPercentage ||
                    '0%'}
                </span>
                <span
                  className="my-0 font-weight-normal"
                  style={{ fontSize: '0.8rem' }}
                >
                  Conforme
                </span>
              </div>
              <div className="d-flex flex-column text-center">
                <span
                  className="my-0 font-weight-normal"
                  style={{ fontSize: '1.6rem' }}
                >
                  {queueMonitoringData?.serviceLevel?.notAccordingPercentage ||
                    '0%'}
                </span>
                <span
                  className="my-0 font-weight-normal"
                  style={{ fontSize: '0.8rem' }}
                >
                  Não Conforme
                </span>
              </div>
            </div>
          </AsideCardItem>
        );
      default:
        break;
    }
  }

  return (
    <AsideCardListWrapper isDragging={isDragging}>
      {listAsideCards.map((item, index) => {
        return (
          <AsideCardContent
            title="Você pode clicar e arrastar os cards para reordenar a lista"
            key={index}
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragOver={(e) => e.preventDefault()}
            onDragEnd={drop}
            draggable
            isDragging={index === isDragging ? true : false}
          >
            {switchOptions(item)}
          </AsideCardContent>
        );
      })}
    </AsideCardListWrapper>
  );
};

export default AsideCardList;
