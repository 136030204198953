export const getMonthAndYearName = (date) => {
  const month = date.split('-')[1];
  const year = date.split('-')[0];

  switch (month) {
    case '01':
      return `janeiro de ${year}`;
    case '02':
      return `fevereiro de ${year}`;
    case '03':
      return `março de ${year}`;
    case '04':
      return `abril de ${year}`;
    case '05':
      return `maio de ${year}`;
    case '06':
      return `junho de ${year}`;
    case '07':
      return `julho de ${year}`;
    case '08':
      return `agosto de ${year}`;
    case '09':
      return `setembro de ${year}`;
    case '10':
      return `outubro de ${year}`;
    case '11':
      return `novembro de ${year}`;
    case '12':
      return `dezembro de ${year}`;
  }
};

export const getMonthName = (month) => {
  switch (month) {
    case '01':
      return `janeiro`;
    case '02':
      return `fevereiro`;
    case '03':
      return `março`;
    case '04':
      return `abril`;
    case '05':
      return `maio`;
    case '06':
      return `junho`;
    case '07':
      return `julho`;
    case '08':
      return `agosto`;
    case '09':
      return `setembro`;
    case '10':
      return `outubro`;
    case '11':
      return `novembro`;
    case '12':
      return `dezembro`;
  }
};
