import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import Service from '../service';
import QueueService from '../service/queue';
import { toast } from 'react-toastify';
//import { ddrRegex } from './regexs';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const CallbackForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;
  const [isUpdate, setIsUpdate] = useState(false);
  const [queues, setQueues] = useState([]);

  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      setLoading(true);
      Service.get(id)
        .then((res) => {
          const getCallback = res.data.data;
          reset(getCallback);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar Callback', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  // Get queues to select
  useEffect(() => {
    QueueService.getAll()
      .then((response) => {
        setQueues(response.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      isUpdate ? await Service.update(id, data) : await Service.create(data);
      history.push('/admin/callback');
      if (isUpdate) {
        toast.info('Callback foi atualizada com sucesso!');
      } else {
        toast.success('Callback foi cadastrada com sucesso!');
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/callback');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/callback');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/callback"
        buttonTitle="Voltar para a pagina de callbacks"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Callback</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <h3>Principal</h3>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Nome*</label>

                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{
                              required: 'nome é um campo obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Fila*</label>
                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                {queues.map((queue, index) => (
                                  <option key={index} value={queue.id}>
                                    {queue.name}
                                  </option>
                                ))}
                              </Input>
                            }
                            control={control}
                            name="queueId"
                            rules={{
                              required: 'Selecione uma fila'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="queueId"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Tipo*</label>
                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                <option value="ativo">Ativo</option>
                                <option value="receptivo">Receptivo</option>
                              </Input>
                            }
                            control={control}
                            name="type"
                            rules={{
                              required: 'Selecione um tipo'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="type"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <h3>Configuração</h3>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Quantidade máxima de tentativas*
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            type="number"
                            name="attempts"
                            rules={{
                              required: 'Número de Tentativas é obrigatório',
                              validate: (value) => {
                                return value >= 0
                                  ? true
                                  : 'Número de Tentativas não pode ser negativo';
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="attempts"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Intervalo entre tentativas(segundos)*
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            type="number"
                            name="attempt_interval"
                            rules={{
                              required:
                                'Intervalo Entre Tentativas é obrigatório',
                              validate: (value) => {
                                return value >= 0
                                  ? true
                                  : 'Intervalo Entre Tentativas não pode ser negativo';
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="attempt_interval"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Col sm="12">
                        {isUpdate ? (
                          <Controller
                            name="check_agent"
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <>
                                <label className="form-control-label">
                                  <Input
                                    type="checkbox"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                  />
                                  Verificar agentes livres
                                </label>
                              </>
                            )}
                          />
                        ) : (
                          <Controller
                            name="check_agent"
                            control={control}
                            defaultValue={true}
                            render={(props) => (
                              <>
                                <label className="form-control-label">
                                  <Input
                                    type="checkbox"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                  />
                                  Verificar agentes livres
                                </label>
                              </>
                            )}
                          />
                        )}
                      </Col>
                      <Col sm="12">
                        <Controller
                          name="only_day"
                          control={control}
                          defaultValue={false}
                          render={(props) => (
                            <>
                              <label className="form-control-label">
                                <Input
                                  type="checkbox"
                                  onChange={(e) =>
                                    props.onChange(e.target.checked)
                                  }
                                  checked={props.value}
                                />
                                Retornar apenas chamadas perdidas do dia
                              </label>
                            </>
                          )}
                        />
                      </Col>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CallbackForm;
