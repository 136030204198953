import React, { useEffect } from 'react';
import { Row, Form, Col, Input, Modal, ModalBody } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import UserService from '../../../service';
import ReactButton from 'components/Buttons/ReactButton';

const CloseConversation = ({ toggleModal, isOpen, login }) => {
  const { control, handleSubmit, errors, getValues, setValue } = useForm();

  useEffect(() => {
    setValue('password', '');
    setValue('confirm_password', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const onSubmit = async (data) => {
    try {
      const formatedData = {
        newPassword: data.password,
        passwordConfirmation: data.confirm_password
      };

      UserService.changePassword(login, formatedData)
        .then((res) => {
          toast.success('Senha alterada com sucesso!', {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .catch((err) => {
          toast.error(err.response.data[0] || 'Erro ao atualizar senha!', {
            autoClose: 3000,
            closeOnClick: true
          });
        });

      toggleModal();
    } catch (err) {
      const { data } = err.response;
      toast.error(data.message || 'Erro ao alterar senha!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Alterar senha
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />
          <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Col md="12">
                <label className="form-control-label">Senha*</label>
                <Controller
                  as={<Input type="password" />}
                  control={control}
                  name="password"
                  rules={{
                    required: 'Por favor, digite a senha!'
                  }}
                  defaultValue=""
                />
                <ErrorMessage
                  errors={errors}
                  name="password"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md="12">
                <label className="form-control-label">Confirmar senha*</label>
                <Controller
                  as={<Input type="password" />}
                  control={control}
                  name="confirm_password"
                  rules={{
                    required: 'Por favor, digite a confirmação da senha!',
                    validate: (value) => {
                      if (value === getValues()['password']) {
                        return true;
                      } else {
                        return 'As senhas não conferem!';
                      }
                    }
                  }}
                  defaultValue=""
                />
                <ErrorMessage
                  errors={errors}
                  name="confirm_password"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>

            <ReactButton btnColor="confirmation" type="submit">
              Alterar
            </ReactButton>

            <ReactButton
              btnColor="cancelation"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
            >
              Cancelar
            </ReactButton>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CloseConversation;
