import React, { useState, useRef, useEffect } from 'react';
import {
  Modifier,
  Container,
  ModifierContainer,
  AdicionaveisContainer
} from './styles';

export const ProtocolNumberConfig = ({ setProtocolNumber, data }) => {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [modifiers, setModifiers] = useState(['INC']);
  const [adicionaveis, setAdicionaveis] = useState([
    'AA',
    'MM',
    'DD',
    'HR',
    'MIN',
    'SEG',
    'AAAA'
  ]);

  const titles = {
    AAAA: 'Ano',
    AA: 'Ano',
    MM: 'Mês',
    DD: 'Dia',
    HR: 'Hora',
    MIN: 'Minuto',
    SEG: 'Segundos',
    INC: 'Incremento'
  };

  useEffect(() => {
    if (data) {
      let intersection = adicionaveis.filter((x) => !data.includes(x));
      setModifiers(data);
      setAdicionaveis(intersection);
    }
  }, []);

  const dragStart = (position) => {
    dragItem.current = position;
    // console.log(e.target.innerHTML);
  };

  const dragEnter = (position) => {
    dragOverItem.current = position;
  };

  const drop = () => {
    const copyListItems = [...modifiers];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setModifiers(copyListItems);
  };

  const handleOnClickAdicionar = (item, index) => {
    let copyAdicionaveis = [...adicionaveis];
    copyAdicionaveis.splice(index, 1);
    setModifiers([item, ...modifiers]);
    setAdicionaveis(copyAdicionaveis);
  };

  const handleOnClickRemover = (item, index) => {
    let copyModifiers = [...modifiers];
    copyModifiers.splice(index, 1);
    setAdicionaveis([item, ...adicionaveis]);
    setModifiers(copyModifiers);
  };

  useEffect(() => {
    setProtocolNumber(modifiers);
  }, [modifiers]);

  return (
    <>
      <Container>
        {adicionaveis.length > 0 ? (
          <AdicionaveisContainer>
            {adicionaveis.map((adicionavel, index) => {
              return (
                <Modifier
                  title={titles[adicionavel]}
                  type="button"
                  style={{ display: 'inline-table' }}
                  onClick={() => {
                    handleOnClickAdicionar(adicionavel, index);
                  }}
                  key={index}
                >
                  {adicionavel}
                </Modifier>
              );
            })}
          </AdicionaveisContainer>
        ) : null}

        <label className="form-control-label">Selecionados</label>
        <ModifierContainer>
          {modifiers.map((modifier, index) => {
            return (
              <Modifier
                title={titles[modifier]}
                type="button"
                draggable
                onClick={() => {
                  if (modifier === 'INC') {
                    return;
                  }
                  handleOnClickRemover(modifier, index);
                }}
                onDragStart={(e) => {
                  dragStart(index);
                }}
                onDragEnter={(e) => {
                  dragEnter(index);
                }}
                onDragEnd={() => {
                  drop();
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
                key={index}
              >
                {modifier}
              </Modifier>
            );
          })}
        </ModifierContainer>
      </Container>
    </>
  );
};
