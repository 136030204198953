export const brazilian = {
  DOWNLOAD_CALLS: 'Download de chamadas dashboard',
  LISTEN_CALLS: 'Escutar chamadas dashboard',
  DELETE_LEAD_CRM: 'Deletar Leads',
  DELETE_OPPORTUNITY_CRM: 'Deletar Oportunidades',
  WHISPER_TOOL: 'Monitoramento Sussuros',
  CHAT_TRANSFERS: 'Monitoramento Transferências',
  ENDING_CONVERSATIONS: 'Monitoramento Encerramento de Conversas',
  CONFIGURE_QUALITY_MONITORING_PAPERS:
    'Configurar formulários de monitoria de qualidade',
  ANSWER_QUALITY_MONITORING_PAPERS:
    'Preencher formulários de monitoria de qualidade',
  UPDATE_QUALITY_MONITORING_PAPER_ANSWERS:
    'Atualizar respostas dos formulários de monitoria de qualidade',
  VIEW_QUALITY_MONITORING_REPORTS:
    'Visualizar relatórios de monitoria de qualidade',
  AGENT_CRM: 'Agente CRM',
  AGENT_CRM_DASHBOARD: 'Dashboard CRM',
  PJSIP_CONFIGURATION: 'Configuração PJSIP',
  DIALOG_TREE: 'Árvore de diálogo',
  INBOUND_ROUTE: 'Rota de entrada',
  INTERNAL_CHAT: 'Chat interno',
  OUTBOUND_ROUTE: 'Rota de saída',
  INTERNAL_ROUTE: 'Rota interna',
  EASE_ROUTE: 'Rota de facilidade',
  FEATURE_ROUTE: 'Rota de facilidade',
  INCOMING_CALLS_CALLCENTER: 'Chamadas recebidas do Callcenter',
  OUTGOING_CALLS_CALLCENTER: 'Chamadas realizadas do Callcenter',
  INCOMING_CALLS_PBXIP: 'Chamadas recebidas PBXIP',
  OUTGOING_CALLS_PBXIP: 'Chamadas realizadas PBXIP',
  OUTBOUND_ROUTE_GROUP: 'Grupo de rotas de saída',
  INCOMING_CALLS: 'Chamadas recebidas',
  FREE_AGENTS: 'Agentes Livres',
  UNANSWERED_CALLS: 'Abandonadas',
  ONGOING_CALLS: 'Chamadas Correntes',
  OCCUPATION_HOUR: 'Ocupação/Hora',
  NOTIFICATIONS: 'Notificações',
  SERVER_DETAILS: 'Informações do Servidor',
  STORAGE_STATUS: 'Status de Armazenamento',
  HI: 'Olá',
  WELCOME: 'Bem-Vindo',
  IVR: 'URA',
  CUSTOMIZED: 'Customizado',
  'CUSTOMIZED(A)': 'Customizada',
  TRUE: 'Sim',
  FALSE: 'Não',
  REPORT_CALLCENTER: 'Relatório de Callcenter',
  REPORT_DIALER: 'Relatório de discador',
  REPORT_PBXIP: 'Relatório de PBXIP',
  URA_REPORT: 'Relatório de URA',
  MONITORING_CALLCENTER: 'Monitorar Callcenter',
  MONITORING_PBXIP: 'Monitorar PBXIP',
  USERS_PROFILE: 'Perfis',
  USERS_LIST: 'Lista de usuários',
  USER_PERMISSIONS: 'Usuário - permissões',
  USER_PENALTY: 'Usuário - Penalidade',
  USER_SCALE_PROFILE: 'Usuário - Escala',
  SYSTEM_PROFILE: 'Sistema',
  LOGO: 'Logo',
  'NO ANSWER': 'Não atendida',
  'IN CALL': 'Em ligação',
  ANSWERED: 'Atendida',
  TO_COPY: 'clonar',
  TECHNOLOGY: 'tecnologia',
  EDIT_ALL: 'Editar todos',
  PROGRAMACAO_HORARIO: 'Programação horário',
  AUDIT: 'Auditoria',
  SECTOR: 'Setor',
  CHAT_MULTIPLE_TRANSFER: 'Transferir múltiplas conversas no atendimento',
  TIME_BEGIN: 'Hora Inicial',
  TIME_END: 'Hora Final',
  TEAM_ACCESS: 'Acesso as Equipes',
  CONFERENCE: 'Conferência',
  BOSS_SECRETARY: 'Secretário chefe',
  CONTEXT: 'Contexto',
  IN: 'Entrada',
  OUT: 'Saida',
  SPY: 'Espião',
  VOICEMAIL: 'Voicemail',
  SPIES: 'Espiões',
  QUEUE: 'Fila',
  MONITORING: 'Monitoramentos',
  SPY_GROUP: 'Grupo de Espião',
  SPY_GROUPS: 'Grupos de Espião',
  BLACK_LIST: 'Lista de bloqueio',
  CALLBACK: 'Callback',
  DIALER: 'Discador',
  PAUSE: 'Pausa',
  PAUSES: 'Pausas',
  EXTENSION: 'Ramal',
  GENERIC_EXTENSION: 'Ramal Genérico',
  MONIT_EXTENSION: 'Monitorar Ramais',
  EXTENSIONS: 'Ramais',
  ORIGINAL_EXTENSION: 'Ramal Origem',
  ORIGINAL_TIME: 'Tempo Original',
  RING_TIME: 'Tempo de Ring',
  SPECIFIC_EXTENSION: 'Ramal Específico',
  SERVER: 'Servidor',
  SERVERS: 'Servidores',
  TRANSSHIPMENT: 'Transbordo',
  TRANSSHIPMENT_1: 'Transbordo 1',
  TRANSSHIPMENT_2: 'Transbordo 2',
  TRANSSHIPMENT_3: 'Transbordo 3',
  TRANSSHIPMENT_4: 'Transbordo 4',
  TRANSSHIPMENT_5: 'Transbordo 5',
  RING_TIME_TRANSSHIPMENT: 'Tempo de Ring - Transbordo',
  RING_TIME_TRANSSHIPMENT_1: 'Tempo de Ring - Transbordo 1',
  RING_TIME_TRANSSHIPMENT_2: 'Tempo de Ring - Transbordo 2',
  RING_TIME_TRANSSHIPMENT_3: 'Tempo de Ring - Transbordo 3',
  RING_TIME_TRANSSHIPMENT_4: 'Tempo de Ring - Transbordo 4',
  RING_TIME_TRANSSHIPMENT_5: 'Tempo de Ring - Transbordo 5',
  TRANSSHIPMENTS: 'Transbordos',
  TRUNKS: 'Troncos',
  ANALOGIC: 'Analógico',
  AUTHENTICATION_REPORT: 'Relatório de Autenticação',
  CALLBACK_REPORT: 'Relatório de Callback',
  PERFORMANCE_REPORT: 'Relatório de Performance',
  PAUSE_REPORT: 'Relatório de Pausa',
  LEVEL_OF_SERVICE_REPORT: 'Relatório de Nível de Serviço',
  OUTGOING_CALLS: 'Chamadas Realizadas',
  CALLS_MADE: 'Ligações Realizadas',
  CALLS_RECEIVED: 'Ligações Recebidas',
  OCCUPANCY_RATE_REPORT: 'Relatório de Taxa de Ocupação',
  REPORT_BY_INSTITUTION: 'Relatório por Instituição',
  REPORT_BY_USER: 'Relatório por Usuário',
  ATTENDANCE_PANEL: 'Painel de Atendimento',
  SUPERVISOR_PANEL: 'Painel do Supervisor',
  IMPORT_SCHEDULED_CALLS: 'Importar Chamadas Programadas',
  MONITOR: 'Monitorar',
  BILL_BY_COST_CENTER: 'Conta por Centro de Custo',
  ISSUE_BILL_BY_AGENT: 'Emitir Conta por Agente',
  MONITOR_CONSUMPTION: 'Monitorar Consumo',
  AGENT_PREPAID_REPORT: 'Relatório Pré-Pago Agente',
  COST_CENTER_PREPAID_REPORT: 'Relatório Pré-Pago Centro de Custo',
  UPDATE_SERVER: 'Atualizar Servidor',
  BACKUP_SERVER: 'Backup Servidor',
  CONFIG_DASHBOARD: 'Configurar Dashboard',
  OPERATIONS_PERFORMED: 'Operações Realizadas',
  SERVER_OPERATIONS: 'Operações do Servidor',
  SERVER_PARAMETERS: 'Parâmetros do Servidor',
  LIST: 'Lista',
  URA: 'URA',
  DASHBOARD: 'Dashboard Inicial',
  CHAT_DASHBOARD: 'Dashboard do Chat',
  CALLCENTER_DASHBOARD: 'Dashboard do CallCenter',
  AGENT_DASHBOARD: 'Dashboard do Atendente',
  EMAIL: 'Email',
  PASSWORD: 'Senha',
  LOGIN: 'Entrar',
  FORGOT_PASS: 'Esqueci a senha',
  INQUIRIES: 'Questionários',
  INQUIRY: 'Questionário',
  QUESTION: 'Questão',
  QUESTIONS: 'Questões',
  ANSWER: 'Resposta',
  ANSWERS: 'Respostas',
  USER: 'Usuário',
  USERS: 'Usuários',
  PROFILE: 'Perfil',
  LOGOUT: 'Sair',
  UNAUTHORIZED: 'Você precisa logar para acessar esta página',
  ANALYTICAL_REPORT: 'Relatório Analítico',
  FROM: 'De',
  TO: 'Até',
  TYPE: 'Tipo',
  ACTION_TYPE: 'Tipo de Ação',
  START_DATE: 'Data Inicial',
  FINAL_DATE: 'Data Final',
  START_TIME: 'Hora Inicial',
  FINAL_TIME: 'Hora Final',
  START: 'Início',
  END: 'Final',
  MONTH: 'Mês',
  DAY: 'Dia',
  HOLIDAY: 'Feriado',
  SAVE: 'Salvar',
  CLOSE: 'Fechar',
  SEARCH: 'Buscar',
  ACTION: 'Ação',
  DETAIL: 'Detalhe',
  SELECT_ONE_USER: 'Selecione um usuário',
  SELECT_ONE_ACTION: 'Selecione uma ação',
  SELECT_ONE_FILE: 'Selecione um arquivo',
  SELECT_ONE_EXTENSION: 'Selecione um ramal',
  SELECT_AN_OPTION: 'Selecione uma opção',
  SELECT_AN_USER: 'Selecione um usuário',
  AUDIO: 'Áudio',
  Audio: 'Áudio',
  OVERTIME_SONG: 'Música Fora Horário',
  MUSIC_ON_HOLD: 'Música de espera',
  NO_RECORD_FOUND: 'Nenhum Registro Encontrado',
  CREATE: 'Criação',
  UPDATE: 'Atualizar',
  IMPORT_MAILING: 'CORREIO DE IMPORTAÇÃO',
  REMOVE: 'Remoção',
  SOUND: 'Áudio',
  DESTINY: 'Destino',
  SOURCE: 'Origem',
  VALUE: 'Valor',
  CALL_LIMIT: 'Limite de contatos',
  CODE: 'Código',
  GROUPS: 'Grupos',
  KEY_NUMBER: 'Número chave',
  INITIAL_CHANNEL: 'Canal inicial',
  FINAL_CHANNEL: 'Canal final',
  INITIAL_DDR: 'DDR Inicial',
  FINAL_DDR: 'DDR Final',
  DDR: 'DDR',
  AGENT_CODE: 'Código do agente',
  PENALTY: 'Penalidade',
  ENABLE_DISABLE: 'Ativar / Desativar',
  PROTOCOL: 'Protocolo',
  CHATMONITORING: 'Monitoramento do chat',
  BOT: 'Bot',
  ATTENDANT: 'Atendente',
  ADMINISTRATOR: 'Administrador',
  TRAINEE: 'Estagiário',
  UNIFIED_REPORT: 'Relatório unificado',
  CONVERSATION_REPORT: 'Relatório de conversas',
  PERFORMANCE_CHAT_REPORT: 'Relatório de performance do chat',
  PAUSE_CHAT_REPORT: 'Relatório de pausa do chat',
  CHAT_CONFIGURATION: 'Configurações do chat',
  ISSUE: 'Assunto',
  PREDEFINEDMESSAGE: 'Mensagens predefinidas',
  QUEUEMESSAGE: 'Mensagem de fila',
  PRODUCT: 'Produto',
  SURVEY_REPORT: 'Relatórios da pesquisa',
  SURVEY_CONFIGURATION: 'Configuração da pesquisa',
  SURVEY_TAGS: 'Tags da pesquisa',
  CLIENT: 'Cliente',
  REASON: 'Motivos',
  SUBREASON: 'Submotivos',
  STATUS: 'Status',
  CUSTOM_CLIENT_FIELDS: 'Campos customizados do cliente',
  LIST_PROTOCOL: 'Exibição de Protocolos',
  CREATE_PROTOCOL: 'Criação de protocolos',
  CUSTOM_PROTOCOL_FIELDS: 'Campos customizados do protocolo',
  SYSTEM_CONFIGURATION: 'Configurações do sistema',
  EMAIL_CONFIGURATION: 'Configuração de email',
  TAG_CHAT: 'Configuração para criação de tag para o chat',
  LINK_CHAT: 'Configuração para criação de link para o chat',
  TELEPHONY_SERVICE_PANEL: 'Permitir logar em filas para atendimento telefonia',
  HANGUP_CAUSE: 'Configuração de Causa de desligamento',
  SERVICE_LEVEL: 'Configuração do tempo de nível de serviço geral',
  CONFIG_PROTOCOL: 'Configuração de protocolo',
  CUSTOM_FIELDS_CONFIG: 'Configuração de campos customizados do sistema',
  SYSTEM_PARAMS: 'Configuração dos parâmentros do sistema',
  PAUSE_CHAT_SERVICE_PAINEL: 'Pausa no painel de atendimento do chat',
  PROTOCOL_REPORTS: 'Relatorios do protocolo',
  ACTIVE_CONTACT: 'Contato ativo pelo chat',
  BROADCAST_LIST: 'Campanha Ativa Whatsapp',
  BROADCAST_LIST_REPORT: 'Relatório de Campanha Ativa Whatsapp',
  BROADCAST_LIST_SMS: 'Campanha Ativa SMS',
  BROADCAST_LIST_SMS_CONFIG: 'Configuração de Campanha Ativa SMS',
  INTEGRATION_URL: 'URL de Integração',
  RECORD_TYPES: 'Tipos de registro',
  SCALE_REPORT_TEAM: 'Relatório de escalas por equipe',
  SCALE_REPORT_AGENT: 'Relatório de escalas por agente',
  THIRD_SYSTEM: 'Tela de Sistemas',
  MONITORING_CALLCENTER_PAUSE: 'Monitorar Callcenter - Pause',
  MONITORING_CALLCENTER_PENALTY: 'Monitorar Callcenter - Penalidade',
  // report: {
  //   DATE: 'Data',
  //   TIME: 'Hora',
  //   AGENT: 'Agente',
  //   OPTION_CHOSEN: 'Opção Escolhida',
  //   RAMAL: 'Ramal',
  //   QUEUE: 'Fila',
  //   RECORDING: 'Gravação'
  // },
  // model: {
  //   BLACK_LIST: 'Lista de bloqueio',
  //   inquiry: {
  //     DESCRIPTION: 'Descrição'
  //   },
  //   question: {
  //     STATEMENT: 'Enunciado',
  //     INQUIRY: 'Questionário'
  //   },
  //   answer: {
  //     INQUIRY: 'Questionário',
  //     QUESTION: 'Questão',
  //     DESCRIPTION: 'Descrição',
  //     OPTION: 'Opção',
  //     TYPE: 'Type'
  //   },
  //   user: {
  //     NAME: 'Nome',
  //     EMAIL: 'E-Mail',
  //     PASSWORD: 'Senha',
  //     PASSWORD_CONFIRMATION: 'Confirme a senha',
  //     CURRENT_PASSWORD: 'Senha atual',
  //     NEW_PASSWORD: 'Nova senha',
  //     LOGIN: 'Login',
  //     ROLE: 'Role',
  //     QUEUES: 'Filas'
  //   }
  // },
  LOWERCASE_NAME: 'nome',
  BOSS_EXTENSION: 'Ramal do Chefe',
  SECRETARY_EXTENSION: 'Ramal Secretária',
  ALERT_TIME: 'Tempo de Alerta',
  ACTIVE: 'Ativo',
  ENABLE: 'Ativo',
  ADD: 'Adicionar',
  CANCEL: 'Cancelar',
  AVAILABLE_QUEUES: 'Filas disponíveis',
  SELECTED_QUEUES: 'Filas selecionadas',
  SELECTED_GROUPS: 'Grupos Selecionados',
  SELECTED_EXTENSIONS: 'Ramais Selecionados',
  SELECT_ROLE: 'Selecione uma role',
  TO_UPDATE: 'Atualizar',
  PROFILES: {
    PROTOCOL_ATTENDANT: 'Atendente do protocolo',
    PROTOCOL_ADMINISTRATOR: 'Administrador do protocolo',
    TELEPHONY_ATTENDANT: 'Atendente da telefonia',
    TELEPHONY_ADMINISTRATOR: 'Administrador da telefonia',
    CHAT_ATTENDANT: 'Atendente do chat',
    CHAT_ADMINISTRATOR: 'Administrador do chat'
  },
  // input: {
  //   PLACEHOLDER: 'Pesquisar por'
  // },
  // combobox: {
  //   CHOOSE_INQUIRY: 'Escolha um questionário',
  //   CHOOSE_QUESTION: 'Escolha uma questão',
  //   CHOOSE_ANSWER: 'Escolha uma resposta'
  // },
  // actions: {
  //   TO_EDIT: 'Editar',
  //   TO_EDIT_MULTIPLE: 'Editar Múltiplos',
  //   TO_REMOVE: 'Remover',
  //   TO_ENABLE: 'Habilitar/Desabilitar'
  // },
  // alerts: {
  //   remove_confirmation: {
  //     MESSAGE: 'Você tem certeza que deseja realizar esta ação?',
  //     CONFIRM_BTN: 'Sim',
  //     CANCEL_BTN: 'Não'
  //   }
  // },
  // btns: {
  //   CHANGE_PASSWORD: 'Mudar a senha',
  //   SAVE_BTN: 'Cadastrar',
  //   UPDATE_BTN: 'Atualizar'
  // },
  // errors: {
  //   REQUIRED: 'Este campo é obrigatório',
  //   MINLENGHT: 'O número mínimo caracteres é {{value}}',
  //   MAXLENGHT: 'O número máximo caracteres permitido é {{value}}',
  //   PATTERN: 'Padrão inválido',
  //   PASSWORD_MISMATCH: 'Senhas não conferem',
  //   ONLY_NUMBER: 'Este campo só aceita apenas números'
  // },
  // inquiry: {
  //   NAME: 'Nome do Questionário',
  //   QUESTION: 'Título da Questão',
  //   ANSWER: 'Resposta'
  // },
  PERIODIC_ANNOUNCE: 'Anúncio Periódico',
  PERIODIC_ANNOUNCE_FREQUENCY: 'Frequência Anúncio Periódico',
  AUTO_PAUSE: 'Auto Pausa',
  PERSONALIZED_QUEUE: 'Fila Personalizada',
  // MONDAY: 'Segunda',
  // TUESDAY: 'Terça',
  // WEDNESDAY: 'Quarta',
  // THURSDAY: 'Quinta',
  // FRIDAY: 'Sexta',
  // SATURDAY: 'Sábado',
  // SUNDAY: 'Domingo',
  // JANUARY: 'Janeiro',
  // FEBRUARY: 'Fevereiro',
  // MARCH: 'Março',
  // APRIL: 'Abril',
  // MAY: 'Maio',
  // JUNE: 'Junho',
  // JULY: 'Julho',
  // AUGUST: 'Agosto',
  // SEPTEMBER: 'Setembro',
  // OCTOBER: 'Outubro',
  // NOVEMBER: 'Novembro',
  // DECEMBER: 'Dezembro',
  // INDEPENDENCY_DAY: 'Dia da Independência',
  SOURCE_EXTENSION: 'Ramal Origem',
  SECONDS: 'Segundos',
  FOLLOWME_PLACEHOLDER:
    'Ao colocar 0 em qualquer tempo de ring, o transbordo passará a funcionar como um siga-me',
  DAYS: {
    sunday: 'Domingo',
    monday: 'Segunda-feira',
    tuesday: 'Terça-feira',
    wednesday: 'Quarta-feira',
    thursday: 'Quinta-feira',
    friday: 'Sexta-feira',
    saturday: 'Sábado'
  },
  WORK_SCALES: 'Escala de trabalho',
  DAYS_OFF: 'Dia de Folga',
  OVERTIME: 'Hora Extra',
  SUPERVISOR_OVERTIME: 'Hora extra controladas pelo supervisor',
  CALENDAR: 'Calendário',
  OVERTIME_NOTIFICATIONS: 'Notificações de hora extra',
  AGENT_STATUS: {
    AVAILABLE: 'Livre',
    RING: 'Ocupado',
    RINGING: 'Chamando',
    PAUSE: 'Pausa',
    UNPLACED: 'Offline'
  }
};
