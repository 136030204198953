export const calculateServiceLevel = () => {
  const porcentFormat = (data) => {
    const porcentNumberFormat = data.serviceLevel.replace(/\D/g, '').trim();
    return porcentNumberFormat;
  };

  const calculeConform = (data) => {
    // const conforme = Math.floor(answered * (serviceLevel / 100))
    // const naoConforme = answered - conforme;
    const calls = data[0];
    if (!calls) return;
    const serviceLevel = porcentFormat(calls);
    const conform = Math.floor(calls.answered * (serviceLevel / 100));

    return conform;
  };

  const calculeNotConfor = (data) => {
    const calls = data[0];
    if (!calls) return;
    const serviceLevel = porcentFormat(calls);
    const conform = Math.floor(calls.answered * (serviceLevel / 100));
    const notConform = calls.answered - conform;

    return notConform;
  };

  return { calculeConform, calculeNotConfor };
};
