import { apiCase as http } from '../../../http';

class ProtocolConfigurationService {
  getConfigs(recordTypeId) {
    return http.get(`/protocol-configs/${recordTypeId}`);
  }

  setConfigs(configs) {
    return http.put('/protocol-configs', configs);
  }

  // position od the protocol field
  getProtocolPosition(recordTypeId, entity) {
    return http.get(`/fieldOrdering/${entity}/${recordTypeId}`);
  }

  setProtocolPosition(data) {
    return http.post('/fieldOrdering', {
      entity: data.entity,
      recordType: data.recordType,
      positions: data.positions
    });
  }

  updateProtocolPosition(data, id) {
    return http.put(`/fieldOrdering/${id}`, {
      entity: data.entity,
      recordType: data.recordType,
      positions: data.positions
    });
  }
}

export default new ProtocolConfigurationService();
