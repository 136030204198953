import { useAsyncData } from '../../asyncData';

export function useNameRederer() {
  const { audios, extensions, queues } = useAsyncData();

  const buildNodeContentAttribute = (type, id) => {
    const name_begin = keyName[type]();
    const name = getResourceName(type, id);

    return `${name_begin}${name}`;
  };

  const keyName = {
    AUDIO: () => 'Áudio: ',
    QUEUE: () => 'Fila: ',
    EXTENSION: () => 'Ramal: ',
    GENERIC_EXTENSION: () => 'Ramal Genérico: '
  };

  const getResourceName = (type, id) => {
    let resource_name = '';

    switch (type) {
      case 'AUDIO':
        resource_name =
          (audios && audios.find((el) => el.id === Number(id)).name) || '';
        break;
      case 'EXTENSION':
        resource_name =
          (extensions &&
            extensions.find((el) => el.extension_number === id)
              .extension_number) ||
          '';
        break;
      case 'QUEUE':
        resource_name =
          (queues && queues.find((el) => el.id === Number(id)).name) || '';
        break;
      default:
        resource_name = '';
        break;
    }

    return resource_name;
  };

  return { buildNodeContentAttribute };
}
