import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import PaperForm from '../components/PaperForm';
import PaperService from '../service/index';
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap';
import ReactLoader from '../../../components/Loader';

export default function EditPaper(props) {
  const { id } = props.match.params;
  const [paper, setPaper] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleEditPaper = async ({
    name,
    description,
    type,
    teams,
    questions,
    categories
  }) => {
    try {
      let hasEnabledQuestions = false;
      questions.forEach((q) => {
        if (q.enabled) hasEnabledQuestions = true;
      });

      if (hasEnabledQuestions) {
        await PaperService.update(id, {
          name,
          description,
          type,
          teams
        });
        await PaperService.updateQuestions(id, {
          questions: questions.map(
            ({
              question_id: id,
              description,
              weightage,
              enabled,
              required
            }) => ({
              id,
              description,
              weightage: weightage == 'Crítico' ? 1 : weightage,
              enabled,
              required,
              type: weightage == 'Crítico' ? 'CRITICAL' : 'WEIGHTAGE'
            })
          )
        });
        if (categories) {
          await PaperService.updateCategories(id, {
            categories: categories.map(
              ({ category_id: id, name, enabled }) => ({
                id,
                name,
                enabled
              })
            )
          });
        }

        toast.success('Questionário foi atualizado com sucesso!');
        history.push('/admin/paper');
      } else {
        toast.error(
          'Habilite pelo menos uma questão para salvar o formulário',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      }
    } catch (err) {
      toast.error('Ocorreu um erro ao atualizar questionário.', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const handleDeleteQuestion = async (questionId) => {
    await PaperService.deleteQuestion(id, questionId);
  };

  const handleCancel = () => {
    history.push('/admin/paper');
  };

  useEffect(() => {
    (async () => {
      try {
        const { data } = await PaperService.get(id);
        setPaper(data.data);
        setLoading(false);
      } catch (error) {
        toast.error('Erro ao carregar questionário!', {
          autoClose: 3000,
          closeOnClick: true
        });
        setLoading(false);
        history.push('/admin/paper');
      }
    })();
  }, [id, history]);

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/paper"
        buttonTitle="Voltar para a pagina de questionários"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Edição de Questionário de Monitoria</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <PaperForm
                      data={paper}
                      onSubmit={handleEditPaper}
                      onCancel={handleCancel}
                      onDeleteQuestion={handleDeleteQuestion}
                      editMode={true}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
