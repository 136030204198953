export const emailConfigTypes = {
  ENTRY: 'ENTRY',
  EXIT: 'EXIT'
};

export const emailConfigTypesDisplay = [
  { label: 'Entrada', value: emailConfigTypes.ENTRY },
  { label: 'Saída', value: emailConfigTypes.EXIT }
];

export const emailConfigProvider = {
  MICROSOFT: 'MICROSOFT',
  OTHER: 'OTHER'
};

export const emailConfigProviderDisplay = [
  { label: 'Microsoft', value: emailConfigProvider.MICROSOFT },
  { label: 'Outro', value: emailConfigProvider.OTHER }
];

export const getLabelFromEmailConfigType = (type) => {
  const typeDisplay = emailConfigTypesDisplay.find(
    (typeDisplay) => typeDisplay.value === type
  );
  return typeDisplay ? typeDisplay.label : '';
};
