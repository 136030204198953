import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style,
  individual_icon_header_style,
  icon_column_style
} from '../../../../../../assets/styles/table_custom_style';
import { toBrazilianFullDate } from 'views/CRM/shared/utils/date';
import { FaRegCalendarAlt, FaBan } from 'react-icons/fa';
const SchedulesList = ({
  listSchedules,
  handleUpdateSchedule,
  cancelSchedule
}) => {
  return (
    <>
      <label className="form-control-label" style={{ paddingTop: '25px' }}>
        Agendamento(s)
      </label>

      <ToolkitProvider
        data={listSchedules || []}
        keyField="id"
        columns={[
          {
            dataField: 'description',
            text: 'Descrição',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          {
            dataField: 'schedule_date',
            text: 'Data do agendamento',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style,
            formatter: (Row) => {
              const date = new Date(Row);
              return toBrazilianFullDate(date);
            }
          },
          {
            dataField: 'type',
            text: 'Tipo',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          {
            dataField: 'destination',
            text: 'Destino',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          {
            dataField: 'status',
            text: 'Status',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          {
            dataField: 'edit',
            style: icon_column_style,
            headerStyle: individual_icon_header_style,
            // eslint-disable-next-line react/display-name
            text: 'reagendar',
            formatter: (_, row) => {
              return (
                <FaRegCalendarAlt
                  style={{
                    fontSize: '1.4rem',
                    color: 'blue',
                    cursor: 'pointer'
                  }}
                  id="tour4"
                  onClick={() => handleUpdateSchedule(row)}
                />
              );
            }
          },
          {
            dataField: 'delete',
            style: icon_column_style,
            headerStyle: individual_icon_header_style,
            text: 'cancelar',
            // eslint-disable-next-line react/display-name
            formatter: (_, row) => {
              return (
                <FaBan
                  style={{
                    fontSize: '1.4rem',
                    color: 'red',
                    cursor: 'pointer'
                  }}
                  id="tour4"
                  onClick={() => cancelSchedule(row.id)}
                />
              );
            }
          }
        ]}
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
              classes="align-items-center"
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};

export default SchedulesList;
