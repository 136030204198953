const pauseOptions = [
  {
    label: 'Após do horário programado para a Entrada',
    value: 'ENTRY'
  },
  {
    label: 'Antes do horário programado para a Saída',
    value: 'EXIT'
  }
];

export default pauseOptions;
