import produce from 'immer';

const INITIAL_STATE = {
  conversations: []
};

export default function conversation(state = INITIAL_STATE, action) {
  // console.log('##### REDUCER Unanswered Conversation #####');
  switch (action.type) {
    case '@UNANSWERED_CONVERSATION/ADD_INIT_CONVERSATIONS':
      return produce(state, (draft) => {
        draft.conversations = action.payload.conversations;
      });
    case '@UNANSWERED_CONVERSATION/ADD_CONVERSATION':
      return produce(state, (draft) => {
        draft.conversations.push(action.payload.conversation);
      });
    case '@UNANSWERED_CONVERSATION/REMOVE_CONVERSATION':
      return produce(state, (draft) => {
        const conversationIndex = draft.conversations.findIndex((c) => {
          return c._id === action.payload.id || c.id === action.payload.id;
        });

        if (conversationIndex >= 0) {
          draft.conversations.splice(conversationIndex, 1);
        }
      });
    case '@UNANSWERED_CONVERSATION/ADD_MESSAGE_UNANSWERED_CONVERSATION':
      return produce(state, (draft) => {
        draft.conversations.forEach((el, index) => {
          if (el._id === action.payload.idDaConversa) {
            draft.conversations[index].mensagens.push(action.payload.message);
            draft.conversations[index].novas_mensagens =
              action.payload.novas_mensagens || 0;
          }
        });
      });
    default:
      return state;
  }
}
