import React from 'react';
import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import { ColorArea } from '../styled';
import { Pie } from 'react-chartjs-2';
import { chartExample6 } from 'variables/charts.js';
import randomColor from 'randomcolor';

export function TotalProtocolsPerCustomField({ protocolsData, title }) {
  const calculateProtocolByCustomField = (data) => {
    let arrayLabels = [];
    let arrayData = [];
    let arrayColors = [];
    let totalCount = 0;

    if (data) {
      arrayLabels = data.map((protocol) => {
        return protocol.name;
      });
      arrayData = data.map((protocol) => {
        return parseInt(protocol.total);
      });

      totalCount = arrayData.reduce(function (sum, i) {
        return sum + i;
      }, 0);

      for (let index = 0; index < arrayData.length; index++) {
        arrayColors.push(randomColor({ luminosity: 'random', hue: 'random' }));
      }

      const returnData = {
        arrayLabels,
        arrayData,
        arrayColors,
        totalCount
      };

      return returnData;
    }
    return null;
  };
  const formattedDataProtocols = calculateProtocolByCustomField(protocolsData);

  return (
    <Card>
      <CardHeader>
        <h6 className="surtitle">Visão Geral</h6>
        <h5 className="h3 mb-0">{`Protocolos por campo customizado - ${title}`}</h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col md="4" style={{ alignSelf: 'center' }}>
            <div className="chart">
              <Pie
                data={{
                  labels: [...formattedDataProtocols.arrayLabels],
                  datasets: [
                    {
                      data: [...formattedDataProtocols.arrayData],
                      backgroundColor: [...formattedDataProtocols.arrayColors]
                    }
                  ]
                }}
                options={chartExample6.options}
                className="chart-canvas"
                id="chart-doughnut"
              />
            </div>
          </Col>
          <Col md="8" style={{ alignSelf: 'center' }}>
            <Row>
              <Col md="12">
                <h2 className="text-muted font-weight-bold mt--3">
                  Total de protocolos
                </h2>
                <h1 className="display-1">
                  <CountUp end={formattedDataProtocols.totalCount} />
                </h1>
              </Col>
            </Row>
            <hr />
            <Row>
              {formattedDataProtocols.arrayData.map((data, index) => {
                return (
                  <Col
                    md="2"
                    key={index}
                    className="mt--3 mb--3"
                    style={{
                      minWidth: '177px',
                      minHeight: '130px'
                    }}
                  >
                    <Row
                      className="row flex-nowrap"
                      style={{
                        minHeight: '100px'
                      }}
                    >
                      <Col
                        md="1"
                        style={{
                          paddingBottom: 0
                        }}
                      >
                        <ColorArea
                          color={formattedDataProtocols.arrayColors[index]}
                        />
                      </Col>

                      <Col md="11">
                        <h2>{formattedDataProtocols.arrayData[index]}</h2>
                        <h3 className="text-muted font-weight-light mt--3">
                          {formattedDataProtocols.arrayLabels[index] === ''
                            ? '-'
                            : formattedDataProtocols.arrayLabels[index]}
                        </h3>
                        <h2
                          className="mt--1 font-weight-light"
                          style={{ paddingBottom: '1.5625rem' }}
                        >
                          {`${parseFloat(
                            (100 * formattedDataProtocols.arrayData[index]) /
                              formattedDataProtocols.totalCount
                          ).toFixed(2)}%`}
                        </h2>
                      </Col>
                    </Row>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
}
