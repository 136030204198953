import { toast } from 'react-toastify';
import ContactAttemptService from '../services';

export const getContactsAttempts = (
  contactFormId,
  contactType,
  isFrom,
  entityId
) => {
  const userLogin = localStorage.getItem('login');
  const contactDate = new Date().toISOString();

  if (!isFrom || !userLogin || !contactType || !contactFormId) return;

  let dataToSend = {
    contactDate,
    leadId: null,
    opportunityId: null,
    userLogin,
    contactType,
    chatId: null,
    callId: null
  };

  switch (isFrom) {
    case 'LEAD':
      dataToSend.leadId = entityId;
      if (contactType === 'CHAT') dataToSend.chatId = contactFormId;
      if (contactType === 'TELEFONIA') dataToSend.callId = contactFormId;
      break;
    case 'OPPORTUNITY':
      dataToSend.opportunityId = entityId;
      if (contactType === 'CHAT') dataToSend.chatId = contactFormId;
      if (contactType === 'TELEFONIA') dataToSend.callId = contactFormId;
      break;
    default:
      break;
  }

  ContactAttemptService.create(dataToSend)
    .then((res) => {
      toast.success('Tentativa de contato registrada com sucesso', {
        closeOnClick: true,
        autoClose: 3000
      });
    })
    .catch((err) => {
      toast.error(
        err.response?.data?.message || 'Erro ao registrar tentativa de contato',
        {
          closeOnClick: true,
          autoClose: 3000
        }
      );
    });
};
