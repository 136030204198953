import styled from 'styled-components';

export const Comment = styled.p`
  position: relative;
  line-height: 1.2em;
  text-align: justify;
  margin-right: -1em;
  padding-right: 1em;
  font-size: 0.9rem;

  :after {
    content: '';
    position: absolute;
    right: 0;
    width: 1em;
    height: 1em;
    margin-top: 0.2em;
    background: white;
  }
`;

export const ReadMore = styled.button`
  margin: 0;
  border: none;
  padding: 0;
  background: transparent;
  font-size: 0.9rem;

  > span {
    color: #5e72e4;
  }
`;
