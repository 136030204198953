import ServiceCRUD from '../../../services/ServiceCRUD';
import { apiCase as http } from '../../../http';
class Protocol extends ServiceCRUD {
  constructor() {
    super('protocols');
  }

  async createProtocolByRecordType(recordTypeId, data) {
    return http.post(`/protocols/create/${recordTypeId}`, data);
  }

  async getProtocolsByRecordType(recordTypeId, params) {
    return http.get(`/protocols/${recordTypeId}`, { params });
  }
  async getProtocols(params) {
    return http.get(`/protocols`, { params });
  }

  async getByIdAndRecordType(protocolId, recordTypeId) {
    return http.get(`/protocols/${protocolId}/${recordTypeId}`);
  }

  updateByRecordType(protocolId, recordTypeId, data) {
    return http.put(`/protocols/${protocolId}/${recordTypeId}`, data);
  }

  async updateNoStrictByRecordType(protocolId, recordTypeId, data) {
    return http.put(`/protocols/nostrict/${protocolId}/${recordTypeId}`, data);
  }

  async removeAttachment(protocolId, attachmentId) {
    return http.delete(`/protocols/${protocolId}/attachments/${attachmentId}`);
  }

  async updateMultProtocols(current_status, user_id, data) {
    return http.put(`/protocols/updateMults`, {
      current_status,
      user_id,
      data
    });
  }

  async addAttachments(protocolId, formData) {
    return http.post(`/protocols/${protocolId}/attachments`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }
}

export default new Protocol();
