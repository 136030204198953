import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { chatConfigEmail } from '../../../../store/action/configurationsChat/confgEmailTelephony';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Container } from './style';
import { Chip } from '@material-ui/core/';
import { ButtonChat } from '../../components/buttons/index';

import { toast } from 'react-toastify';
import Service from '../../service/index';

import { Row, Col, Input } from 'reactstrap';

const EmailTelephonyModule = (props) => {
  const dispatch = useDispatch();
  const dados = useSelector((state) => state.createObject.data);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const [initialConfigEmail, setInitialConfigEmail] = useState();
  const [Config, setConfig] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [valueEmailEncerrado, setValueEmailEncerrado] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [valueEmailhorario, setValueEmailhorario] = useState('');

  // const [asteriskData, setAsteriskData] = useState({});

  var modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      ['link', 'image', 'code-block'],
      ['clean']
    ]
  };

  useEffect(() => {
    setInitialConfigEmail(dados.email);
    setConfig([dados]);
    // setAsteriskData(response.data.asterisk);
  }, [dados]);

  function setDataEmailEncerrado(event) {
    setValueEmailEncerrado(event);
    setInitialConfigEmail({
      ...initialConfigEmail,
      textEmailEncerramento: event
    });
  }

  function setDataEmailhorario(event) {
    setValueEmailhorario(event);
    setInitialConfigEmail({
      ...initialConfigEmail,
      textEmailForaHorario: event
    });
  }

  function modifyAccordion(event) {
    event.preventDefault();
    props.controledPanel4(false);
  }

  // function setDataTelefonia(event) {
  //   setAsteriskData({
  //     ...asteriskData,
  //     [event.target.name]: event.target.value
  //   });
  // }

  function setDataEmails(event) {
    let base = event.target.name;
    if (
      base === 'enviarEmailForaHorario' ||
      base === 'habilitarEmailAoEncerrar' ||
      base === 'habilitado' ||
      base === 'enviarConversaPorEmail'
    ) {
      setInitialConfigEmail({
        ...initialConfigEmail,
        [event.target.name]: event.target.checked
      });
    } else {
      setInitialConfigEmail({
        ...initialConfigEmail,
        [event.target.name]: event.target.value
      });
    }
  }

  function sendData(event) {
    event.preventDefault();

    Config[0].email = initialConfigEmail;

    dispatch(chatConfigEmail(initialConfigEmail));

    Service.update(`${dados._id}`, Config[0])
      .then((response) => {
        if (response.data) {
          toast.success('Alterações feitas com sucesso', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Ops, ocorreu um erro!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  }

  return (
    <>
      {initialConfigEmail && (
        <Container>
          <Row className="propertEmail">
            <Col md="6">
              <strong>Propriedades de Email</strong>
              <Row>
                <Col md="8">
                  <label>NOME</label>
                  <Input
                    name="name"
                    onChange={setDataEmails}
                    defaultValue={initialConfigEmail.name}
                  />
                </Col>
                <Col md="4">
                  <div className="checkboxContent">
                    <label>HABILITADO</label>
                    <input
                      onChange={setDataEmails}
                      type="checkbox"
                      className="checkbox"
                      name="habilitado"
                      defaultChecked={initialConfigEmail.habilitado}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <label>ENDEREÇO</label>
                  <Input
                    onChange={setDataEmails}
                    defaultValue={initialConfigEmail.address}
                  />
                </Col>
                <Col md="6">
                  <label>SENHA</label>
                  <Input
                    name="password"
                    onChange={setDataEmails}
                    defaultValue={initialConfigEmail.password}
                    type="password"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <label>HOST SMTP</label>
                  <Input
                    name="hostSMTP"
                    onChange={setDataEmails}
                    defaultValue={initialConfigEmail.hostSMTP}
                  />
                </Col>
              </Row>
            </Col>
            {/* <Col md="6">
            <strong>Configurações de Telefonia</strong>
            <Row>
              <Col md="9">
                <label>HOST</label>
                <Input
                  defaultValue={asteriskData.host}
                  name="host"
                  onChange={setDataTelefonia}
                />
              </Col>
              <Col md="3">
                <label>PORTA</label>
                <Input
                  name="port"
                  defaultValue={asteriskData.port}
                  onChange={setDataTelefonia}
                />
              </Col>
            </Row>
            <Row>
              <Col md="6">
                <label>USUÁRIO</label>
                <Input
                  name="username"
                  defaultValue={asteriskData.username}
                  onChange={setDataTelefonia}
                />
              </Col>
              <Col md="6">
                <label>SENHA</label>
                <Input
                  name="password"
                  defaultValue={asteriskData.password}
                  onChange={setDataTelefonia}
                  type="password"
                />
              </Col>
              <Col>
                <label>CONTEXTO</label>
                <Input
                  name="context"
                  defaultValue={asteriskData.context}
                  onChange={setDataTelefonia}
                />
              </Col>
            </Row>
          </Col> */}
          </Row>
          <Row className="ConfigEmails">
            <Col md="4">
              <strong>Configurações de Emails</strong>
              <div className="contentCheckBox">
                <Col>
                  <p>HABILITAR ENVIO DE EMAIL AO ENCERRAR</p>
                  {initialConfigEmail.habilitarEmailAoEncerrar === true ? (
                    <Chip
                      size="small"
                      className="chipTrue"
                      label="HABILITADO"
                    />
                  ) : (
                    <Chip
                      size="small"
                      className="chipFalse"
                      label="DESABILITADO"
                    />
                  )}
                </Col>
                <input
                  onChange={setDataEmails}
                  className="checkbox"
                  type="checkbox"
                  name="habilitarEmailAoEncerrar"
                  defaultChecked={initialConfigEmail.habilitarEmailAoEncerrar}
                />
              </div>

              <div className="contentCheckBox">
                <Col>
                  <p>HABILITAR ENVIO DE CONVERSA EM EMAIL</p>
                  {initialConfigEmail.enviarConversaPorEmail === true ? (
                    <Chip
                      size="small"
                      className="chipTrue"
                      label="HABILITADO"
                    />
                  ) : (
                    <Chip
                      size="small"
                      className="chipFalse"
                      label="DESABILITADO"
                    />
                  )}
                </Col>
                <input
                  onChange={setDataEmails}
                  className="checkbox"
                  type="checkbox"
                  name="enviarConversaPorEmail"
                  defaultChecked={initialConfigEmail.enviarConversaPorEmail}
                />
              </div>

              <div className="contentCheckBox">
                <Col>
                  <p>
                    HABILITAR EMAIL AUTOMATICO FORA DE HORARIO DE EXPEDIENTE
                  </p>
                  {initialConfigEmail.enviarEmailForaHorario === true ? (
                    <Chip
                      size="small"
                      className="chipTrue"
                      label="HABILITADO"
                    />
                  ) : (
                    <Chip
                      size="small"
                      className="chipFalse"
                      label="DESABILITADO"
                    />
                  )}
                </Col>
                <input
                  onChange={setDataEmails}
                  className="checkbox"
                  type="checkbox"
                  name="enviarEmailForaHorario"
                  defaultChecked={initialConfigEmail.enviarEmailForaHorario}
                />
              </div>
            </Col>
            <Col md="8">
              <strong>Conteúdo de Emails</strong>
              <div className="contentEmailEncerramento">
                <label>EMAIL DE ENCERRAMENTO</label>
                <ReactQuill
                  modules={modules}
                  name="textEmailEncerramento"
                  className="editorText"
                  theme="snow"
                  defaultValue={initialConfigEmail.textEmailEncerramento}
                  onChange={setDataEmailEncerrado}
                />
              </div>
              <div>
                <label>EMAIL FORA DE HORÁRIO</label>
                <ReactQuill
                  modules={modules}
                  name="textEmailForaHorario"
                  className="editorText"
                  theme="snow"
                  defaultValue={initialConfigEmail.textEmailForaHorario}
                  onChange={setDataEmailhorario}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <ButtonChat
                action={sendData}
                name={'Salvar'}
                color={systemConfiguration.confirmation_button_color}
              />
              <ButtonChat
                action={(event) => modifyAccordion(event)}
                name={'Fechar'}
                color={systemConfiguration.cancelation_button_color}
              />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export { EmailTelephonyModule };
