import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { FaCommentSlash, FaComments } from 'react-icons/fa';
import { GiSpeaker, GiSpeakerOff } from 'react-icons/gi';
import { MdNotifications } from 'react-icons/md';
import {
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  Dropdown
} from 'reactstrap';
import { MdReceipt } from 'react-icons/md';
import ProtocolModal from './ProtocolModal';
import NotificationForm from './NotificationForm';
import io from 'socket.io-client';
import { TelephonyServicePanel } from './TelephonyServicePanel';

import { useSocketCallAnswered } from './ProtocolModal/hooks/useSocketCallAnswered';
import PauseServicePainel from './PauseServicePainel';
import { verifyRoles } from '../../../components/Authentication/VerifyRoles';
import { useServiceBlockServicePainelContext } from '../../../hooks/useBlockAcessServicePainel';
import ModalPauseConfirmation from './TelephonyServicePanel/components/ModalPauseConfirmation';
import ding from '../../../assets/audios/ding.mp3';
import ModalClosePauseConfirmation from './TelephonyServicePanel/components/ModalClosePauseConfirmation';

import GeneralNotificationService from './NotificationForm/service/GeneralNotificationService.js';

import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ModalSchedulingProtocol from 'views/Protocol/form/components/ModalSchedulingProtocol';

const speakerStyle = { cursor: 'pointer', color: 'white', marginTop: '3px' };

const ServicePanel = () => {
  const history = useHistory();
  const { isBlockServicePainel, getInitialValuesBlockAcessServicePainel } =
    useServiceBlockServicePainelContext();

  const socketNotification = useMemo(
    () =>
      io(process.env.REACT_APP_PROXY_URL, {
        path: process.env.REACT_APP_NOTIFICATION_PATH_SOCKET
      }),
    []
  );

  const socketScales = useMemo(
    () =>
      io(process.env.REACT_APP_PROXY_URL, {
        path: process.env.REACT_APP_SCALE_PATH_SOCKET
      }),
    []
  );

  const { answeringCall, resetAnsweringCall } = useSocketCallAnswered();
  const [isProtocolModalOpen, setIsProtocolModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [finishedPause, setFinishedPause] = useState(false);
  const [notificationQuantity, setNotificationsQuantity] = useState([]);
  const [audioNotification, setAudioNotification] = useState();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSchedulingModalOpen, setIsSchedulingModalOpen] = useState(false);
  const [schedulingId, setSchedulingId] = useState();
  const [showAudioVolume, setShowAudioVolume] = useState(false);
  const showDropdownAudioVolume = () => {
    setShowAudioVolume(true);
  };
  const hideDropdownAudioVolume = () => {
    setShowAudioVolume(false);
  };

  useEffect(() => {
    const audio = JSON.parse(localStorage.getItem('@flexuc/audioEnabled'));

    if (audio === true) setAudioNotification(true);

    if (audio === false) {
      setAudioNotification(false);
      localStorage.setItem('@flexuc/audioEnabled', 'false');
    }

    if (audio === null) {
      setAudioNotification(true);
      localStorage.setItem('@flexuc/audioEnabled', 'true');
    }
  }, []);

  const alertConfirmation = useCallback((eventData) => {
    const agentLocal = localStorage.getItem('agent_code');
    const agentSocket = eventData.agentCode;
    if (agentSocket === agentLocal) {
      playSound();
      setIsOpen(true);
    }
  }, []);

  const finishedConfirmation = useCallback((eventData) => {
    const agentLocal = localStorage.getItem('agent_code');
    const agentSocket = eventData.agentCode;
    if (agentSocket === agentLocal) {
      playSound();
      setFinishedPause(true);
    }
  }, []);

  useEffect(() => {
    socketScales.onAny((event, args) => {
      console.log('event', event);
      console.log('args', args);
    });
    socketScales.on('connect', () => {
      socketScales.emit('overtime_room:create', localStorage.getItem('login'));
    });
  }, [socketScales]);

  useEffect(() => {
    socketScales.on('automatic_logout', () => {
      toast.info(`Logout automatico ocorrerá em breve!`, { autoClose: 3000 });
      setTimeout(() => {
        history.push('/user/logout');
      }, 3000);
    });
  }, [socketScales, history]);

  useEffect(() => {
    socketScales.on('automatic_pause_confirmation', (eventData) => {
      alertConfirmation(eventData);
    });
  }, [socketScales, alertConfirmation]);
  useEffect(() => {
    socketScales.on('automatic_pause_finished_alert', (eventData) => {
      finishedConfirmation(eventData);
    });
  }, [socketScales, finishedConfirmation]);

  // Notificações gerais
  useEffect(() => {
    socketNotification.on('connect', () => {
      socketNotification.emit(
        'notification_room:create',
        localStorage.getItem('login')
      );
    });
  }, [socketNotification]);

  useEffect(() => {
    socketNotification.on('flex_notification_event', () => {
      loadApiNotificationsQuantity();
    });
  }, [socketNotification]);

  useEffect(() => {
    try {
      getInitialValuesBlockAcessServicePainel();
    } catch (err) {
      console.error(err);
    }
  }, [getInitialValuesBlockAcessServicePainel]);

  const isObjectFilled = (obj) => {
    return Object.keys(obj).length ? true : false;
  };

  useEffect(() => {
    if (isObjectFilled(answeringCall)) {
      setIsProtocolModalOpen(true);
      if (answeringCall?.externalScreen) {
        window.open(answeringCall.externalScreen, '_blank');
      }
    }
  }, [answeringCall, resetAnsweringCall]);

  useEffect(() => {
    if (!isProtocolModalOpen) resetAnsweringCall();
  }, [isProtocolModalOpen, resetAnsweringCall]);

  const loadApiNotificationsQuantity = async () => {
    let notifications = [];
    await GeneralNotificationService.getNotificationUNREAD().then((res) => {
      const response = res.data.data;

      response.map((item) => {
        if (item.isNew) {
          notifications.push(item);
        }
      });
    });

    setNotificationsQuantity(notifications);
  };

  const cleanGeneralNotifications = async () => {
    let generalNotificationsIds = [];

    notificationQuantity.map((item) => {
      if (item.isNew) {
        generalNotificationsIds.push(item.id);
      }
    });

    if (generalNotificationsIds.length > 0) {
      await GeneralNotificationService.markNotificationAsSeen(
        generalNotificationsIds
      )
        .then(() => {
          loadApiNotificationsQuantity();
        })
        .catch((err) => toast.error(err));
    }
  };

  useEffect(() => {
    loadApiNotificationsQuantity();
  }, []);

  const playSound = () => {
    const audio = new Audio(ding);
    audio.play();
  };

  const changeAudioNotification = () => {
    setAudioNotification(!audioNotification);
    localStorage.setItem('@flexuc/audioEnabled', String(!audioNotification));
  };

  const toggleDropdown = () => setIsDropdownOpen((prevState) => !prevState);

  const changeRangeAudioNotificationVolume = (e) => {
    localStorage.setItem(
      '@flexuc/audioNotificationVolume',
      String(e.target.value)
    );
  };

  return (
    <>
      {isBlockServicePainel}

      {verifyRoles(['ATTENDANCE_PANEL']) ? (
        audioNotification ? (
          <div
            onMouseEnter={showDropdownAudioVolume}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <GiSpeaker
              size={33}
              style={speakerStyle}
              onClick={changeAudioNotification}
            />
            {showAudioVolume && (
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bottom: '1px',
                  zIndex: 999
                }}
              >
                <input
                  type="range"
                  orient="horizontal"
                  className="volumeBar"
                  max="1"
                  onChange={changeRangeAudioNotificationVolume}
                  step="0.1"
                  onMouseLeave={hideDropdownAudioVolume}
                  defaultValue={
                    localStorage.getItem('@flexuc/audioNotificationVolume') ||
                    '0.5'
                  }
                />
              </div>
            )}
          </div>
        ) : (
          <GiSpeakerOff
            size={33}
            style={speakerStyle}
            onClick={changeAudioNotification}
          />
        )
      ) : (
        <></>
      )}

      <Dropdown nav isOpen={isDropdownOpen} toggle={toggleDropdown}>
        <DropdownToggle
          className="nav-link"
          color=""
          tag="a"
          style={{
            cursor: 'pointer'
          }}
          title="Notificações"
        >
          {notificationQuantity.length > 0 ? (
            <>
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  bottom: '20px',
                  left: '30px',
                  width: '1.1rem',
                  height: '1.1rem',
                  background: 'white',
                  color: 'black',
                  fontWeight: '600',
                  fontSize: '0.8rem',
                  borderRadius: '100%',
                  textAlign: 'center'
                }}
              >
                <span>
                  {notificationQuantity.length > 9
                    ? '9+'
                    : notificationQuantity.length}
                </span>
              </div>
              <i>
                <MdNotifications
                  size={25}
                  onClick={() => {
                    cleanGeneralNotifications();
                  }}
                  style={{ position: 'relative' }}
                />
              </i>
            </>
          ) : (
            <i>
              <MdNotifications size={25} />
            </i>
          )}
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-xl py-0 overflow-y-visible"
          right
        >
          <NotificationForm
            isOpen={isDropdownOpen}
            setSchedulingId={setSchedulingId}
            setIsSchedulingModalOpen={setIsSchedulingModalOpen}
          />
        </DropdownMenu>
      </Dropdown>
      {verifyRoles(['PAUSE_CHAT_SERVICE_PAINEL']) && (
        <UncontrolledDropdown nav>
          <DropdownToggle
            className="nav-link"
            color=""
            tag="a"
            style={{ cursor: 'pointer' }}
            title={isBlockServicePainel ? 'Sair da pausa' : 'Entrar em Pausa'}
          >
            {isBlockServicePainel ? (
              <i>
                {' '}
                <FaCommentSlash size={25} />
              </i>
            ) : (
              <i>
                {' '}
                <FaComments size={25} />
              </i>
            )}
          </DropdownToggle>
          <DropdownMenu
            className="dropdown-menu-xl py-0 overflow-y-visible"
            right
          >
            <PauseServicePainel isPaused={isBlockServicePainel} />
          </DropdownMenu>
        </UncontrolledDropdown>
      )}

      {verifyRoles(['CREATE_PROTOCOL']) && (
        <UncontrolledDropdown nav>
          <DropdownToggle
            className="nav-link"
            color=""
            tag="a"
            style={{ cursor: 'pointer' }}
            onClick={() => setIsProtocolModalOpen(true)}
          >
            <MdReceipt size={25} />
          </DropdownToggle>

          <ProtocolModal
            isModalOpen={isProtocolModalOpen}
            onModalToggle={setIsProtocolModalOpen}
            unmountOnClose={true}
            defaultFormData={
              isObjectFilled(answeringCall) ? answeringCall : null
            }
          />
        </UncontrolledDropdown>
      )}
      {verifyRoles(['TELEPHONY_SERVICE_PANEL']) && <TelephonyServicePanel />}
      <ModalPauseConfirmation isModalOpen={isOpen} onModalToggle={setIsOpen} />
      <ModalClosePauseConfirmation
        isModalOpen={finishedPause}
        onModalToggle={setFinishedPause}
      />
      <ModalSchedulingProtocol
        className="modal-lg"
        show={isSchedulingModalOpen}
        onModalToggle={setIsSchedulingModalOpen}
        schedulingId={schedulingId}
        setschedulingId={setSchedulingId}
        isToCreateScheduling={false}
        isToUpdateScheduling={true}
        isFromNotification={true}
      />
    </>
  );
};

export default ServicePanel;
