export const defaultFields = [
  {
    name: 'nome',
    label: 'Nome'
  },
  {
    name: 'identificador',
    label: 'Identificador'
  },
  {
    name: 'whatsappNumber',
    label: 'Número de Whatsapp'
  },
  {
    name: 'numero',
    label: 'Número'
  }
];
