export const custom_select = {
  control: (styles) => ({
    ...styles,
    minHeight: '46px',
    fontSize: '0.875rem',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '400',
    color: '#878fab'
  })
};

export const custom_select_40vw = {
  control: (styles) => ({
    ...styles,
    minHeight: '50px',
    fontSize: '0.875rem',
    fontFamily: 'Open Sans, sans-serif',
    fontWeight: '400',
    color: '#878fab',
    width: '100%'
  })
};

export const getFlexSelectCustomStyle = ({
  minHeight = '',
  minWidth = '',
  width
}) => {
  return {
    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
    control: (styles) => ({
      ...styles,
      minHeight: minHeight || '46px',
      minWidth: minWidth || '46px',
      width: width || 'auto',
      fontSize: '0.875rem',
      fontFamily: 'Open Sans, sans-serif',
      fontWeight: '400',
      color: '#878fab'
    })
  };
};
