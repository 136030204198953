import ServiceCRUD from '../../../services/ServiceCRUDFLEXCHAT';
import { apiChat as http } from '../../../http/index';
class ConversationReportService extends ServiceCRUD {
  constructor() {
    super('configuracao/api');
  }

  getwhatsappNumber() {
    return http.get(`configuracao/api/whatsapp/number`);
  }

  updateConfigProtocolChat(data) {
    return http.put(
      `configuracao/api/protocolo/criarProtocoloParaConversa`,
      data
    );
  }

  getConfigProtocolChat() {
    return http.get(`configuracao/api/protocolo/criarProtocoloParaConversa`);
  }

  updateTimeForEndConversation(time) {
    return http.put(`configuracao/api/encerramento/tempo`, { time });
  }

  getWaitingTime() {
    return http.get(`configuracao/api/encerramento/tempo`);
  }
}

export default new ConversationReportService();
