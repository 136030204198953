import React from 'react';
import Select from 'react-select';
import { TYPES_FILTERS } from '../utils/constants';
import { getFlexSelectCustomStyle } from '../../../../../../../../assets/styles/multiple_select';
export function InputTypeFilter({ set_selected_search_type }) {
  return (
    <div>
      <label className="form-control-label">Filtrar por</label>
      <Select
        placeholder="selecione..."
        styles={getFlexSelectCustomStyle({ minHeight: '46px' })}
        defaultValue={TYPES_FILTERS[0]}
        isClearable={true}
        isSearchable={true}
        isMulti={true}
        options={TYPES_FILTERS}
        onChange={(e) => set_selected_search_type(e)}
      />
    </div>
  );
}
