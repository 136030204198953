import React from 'react';

const QueueContainer = (props) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center'
      }}
    >
      <div
        style={{
          width: '350px',
          display: 'flex',
          textAlign: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap'
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default QueueContainer;
