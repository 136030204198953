import React, { createContext, useState, useEffect, useContext } from 'react';

import UserService from 'views/User/service/index';
export const UserContext = createContext([]);

export function RequestUserProvider({ children }) {
  const [users, setUsers] = useState([]);

  const loadApiUsers = () => {
    const Params = {
      page: 0,
      size: 0,
      showDisable: true,
      query: ''
    };

    UserService.getAllSeach(
      Params.page,
      Params.size,
      Params.query,
      Params.showDisable
    ).then((response) => {
      setUsers(response.data.data);
    });
  };

  useEffect(() => {
    loadApiUsers();
  }, []);

  return (
    <>
      <UserContext.Provider value={{ users, loadApiUsers }}>
        {children}
      </UserContext.Provider>
    </>
  );
}
export function useRequestUsers() {
  const context = useContext(UserContext);
  return context;
}
