import axios from 'axios';

//FlexAuth
export const apiAuth = axios.create({
  baseURL: process.env.REACT_APP_AUTH_URL,
  timeout: 40000
});

apiAuth.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

// apiAuth.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response && error.response.status === 401) {
//       window.location.href = '/user/logout';
//     }
//     return error;
//   }
// );

//FlexCase
export const apiCase = axios.create({
  baseURL: process.env.REACT_APP_CASE_URL,
  timeout: 40000
});
apiCase.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});
apiCase.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

//CRM_SERVICE
export const apiCrm = axios.create({
  baseURL: process.env.REACT_APP_CRM_URL,
  timeout: 40000
});
apiCrm.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

//FlexUc
export const apiUc = axios.create({
  baseURL: process.env.REACT_APP_FLEXUC_URL,
  timeout: 20000
});

apiUc.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

apiUc.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

//FLEX AMI
export const apiAmi = axios.create({
  baseURL: process.env.REACT_APP_AMI_URL,
  timeout: 40000
});
apiAmi.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

apiAmi.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

//FlexChat
export const apiChat = axios.create({
  baseURL: process.env.REACT_APP_CHANNEL_URL,
  timeout: 40000
});
apiChat.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

apiChat.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

//Reports
export const reports = axios.create({
  baseURL: process.env.REACT_APP_REPORT_URL,
  timeout: 40000
});
reports.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

reports.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

//Export FlexChatExport
export const apiChatExport = axios.create({
  baseURL: process.env.REACT_APP_CHANNEL_DOWNLOADS,
  timeout: 40000
});
apiChatExport.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

apiChatExport.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

//downloads PROTOCOL
export const apiProtocolDownloads = axios.create({
  baseURL: process.env.REACT_APP_CASE_URL,
  timeout: 40000
});

apiProtocolDownloads.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

apiProtocolDownloads.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

//NPS
export const apiNps = axios.create({
  baseURL: process.env.REACT_APP_SURVEY_URL,
  timeout: 40000
});

apiNps.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

apiNps.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

export const apiNpsMidia = axios.create({
  baseURL: process.env.REACT_APP_SURVEY_DOWNLOADS,
  timeout: 40000
});

apiNpsMidia.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

apiNpsMidia.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

//Configuration
export const apiConfiguration = axios.create({
  baseURL: process.env.REACT_APP_CONFIGURATION_URL,
  timeout: 40000
});

apiConfiguration.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

apiConfiguration.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

export const apiDownloadAudio = axios.create({
  baseURL: process.env.REACT_APP_AUDIO_URL,
  timeout: 40000
});

//Scales
export const apiScale = axios.create({
  baseURL: process.env.REACT_APP_SCALE_URL,
  timeout: 40000
});
apiScale.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

apiScale.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

//Notifications
export const apiNotifications = axios.create({
  baseURL: process.env.REACT_APP_NOTIFICATION_URL,
  timeout: 40000
});
apiNotifications.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});
apiNotifications.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

export const apiInstagramChat = axios.create({
  baseURL: process.env.REACT_APP_CHAT_INTEGRATION_URL,
  timeout: 40000
});
apiInstagramChat.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});
apiInstagramChat.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);

export const apiSMSChat = axios.create({
  baseURL: process.env.REACT_APP_CHAT_INTEGRATION_URL,
  timeout: 40000
});
apiSMSChat.interceptors.request.use((config) => {
  config.headers['Authorization'] = `Bearer ${localStorage.getItem('token')}`;
  return config;
});
apiSMSChat.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      window.location.href = '/user/logout';
    }
    return Promise.reject(error);
  }
);
