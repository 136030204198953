import DefaultModal from '../../../../components/Modal/DefaultModal';
import React, { useCallback, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { toast } from 'react-toastify';
import { Row, Form, Col, Button } from 'reactstrap';
import UserService from '../../../User/service';
import StatusService from '../../../status/service';
import FlexSelect from '../../../../components/Inputs/FlexSelect';
import ProtocolService from '../../service';
import Loader from 'react-loader-spinner';

const ModelUpdateMultProtocols = ({
  isModalOpen,
  onModalToggle,
  ids,
  getProtocols,
  selectedRecordTypeId,
  selectedRecordType,
  ...props
}) => {
  const [statusList, setStatusList] = useState([]);
  const { control, handleSubmit, errors, setValue } = useForm();
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const closeModal = useCallback(() => {
    onModalToggle(false);
    //setSelectedOption(true);
  }, [onModalToggle]);
  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  useEffect(() => {
    StatusService.getByRecordType(selectedRecordTypeId.value)
      .then((response) => {
        const res = response.data.content;

        setStatusList(res);
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data) || 'Erro ao carregar status/fase';
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
        closeModal();
      });
  }, [closeModal, selectedRecordType]);

  useEffect(() => {
    UserService.getAll()
      .then((response) => {
        const res = response.data.data;

        const usersSort = res.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });

        setUserList(usersSort);
      })
      .catch((err) => {
        console.log(err);
        const message = 'Erro ao carregar usuários';
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
        closeModal();
      });
  }, [closeModal]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      let message;
      await ProtocolService.updateMultProtocols(
        data.status_id,
        data.responsible_id,
        ids
      );

      message = 'protocolos atualizados com sucesso';
      toast.success(message, {
        autoClose: 3000,
        closeOnClick: true
      });
      closeModal();
      setLoading(false);
      getProtocols();
    } catch (err) {
      console.log(err);
      toast.error(' Erro ao atualizar o protocolo', {
        autoClose: 3000,
        closeOnClick: true
      });
      setLoading(false);
    }
  };

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Atualizar protocolos em lote"
      subtitle="Selecione o usuário ou status/fase para realizar a atualização."
      showCloseButton={false}
      modalContainerClasses="modal-md"
      closeModal={closeModal}
      {...props}
    >
      {loading ? (
        <Row className="justify-content-md-center">
          <Loader type="TailSpin" height={100} width={100} />
        </Row>
      ) : (
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col md="12">
              <label className="form-control-label">Responsável</label>
              <Controller
                render={(props) => (
                  <FlexSelect
                    isClearable={true}
                    dataOptions={userList}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    value={props.value}
                    valueController={setValue}
                    fieldName="responsible_id"
                    labelName="name"
                    valueName="login"
                  />
                )}
                control={control}
                name="responsible_id"
                defaultValue=""
              />
              <ErrorMessage
                errors={errors}
                name="responsible_id"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>
          <Row className="mb-3">
            <Col md="12">
              <label className="form-control-label">Status/Fase</label>
              <Controller
                render={(props) => (
                  <FlexSelect
                    isClearable={true}
                    dataOptions={statusList}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    value={props.value}
                    valueController={setValue}
                    fieldName="status_id"
                    labelName="name"
                    valueName="id"
                  />
                )}
                control={control}
                name="status_id"
                defaultValue=""
              />
              <ErrorMessage
                errors={errors}
                name="status_id"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>
          <hr />
          <Button color="primary" type="submit">
            Salvar
          </Button>
          <Button color="danger" onClick={closeModal}>
            Voltar
          </Button>
        </Form>
      )}
    </DefaultModal>
  );
};

export default ModelUpdateMultProtocols;
