import React, { useMemo, useState, useCallback } from 'react';
// import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import PauseService from '../../service/pause';
import { useSelector } from 'react-redux';
import { useServiceBlockServicePainelContext } from '../../../../../../hooks/useBlockAcessServicePainel';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import {
  UserInfoTitle,
  ContentUserInfo,
  UserInfoPause,
  Button,
  ContentLoading
} from './styled';
import ReactLoader from 'components/Loader';

export function GetOutToPause() {
  const {
    typeOfPause,
    handleModifieldIsBlockServicePainel
  } = useServiceBlockServicePainelContext();
  const [loading, setLoading] = useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const loggedInUser = useMemo(() => {
    const idUser = localStorage.getItem('login');
    const isName = localStorage.getItem('name');
    return { idUser, isName };
  }, []);

  const onGetOutToPause = useCallback(async () => {
    try {
      setLoading(true);
      await PauseService.exitToPause(loggedInUser.idUser);

      toast.success(
        `Saida da Pausa realizada com sucesso pelo usuario ${loggedInUser.isName}`
      );
      handleModifieldIsBlockServicePainel(false);
    } catch (err) {
      toast.error('Ocorreu um erro a tentar sair da pausa');
      console.log('err', err);
    }
  }, [handleModifieldIsBlockServicePainel, loggedInUser]);

  return (
    <>
      {loading ? (
        <ContentLoading>
          <ReactLoader height={50} width={50} />
        </ContentLoading>
      ) : (
        <>
          <UserInfoTitle title="Pausa do atendimento">
            Sair da pausa no painel de atendimento
          </UserInfoTitle>
          <ContentUserInfo>
            <UserInfoPause title="Pausa do atendimento">
              Nome do atentende: <span>{loggedInUser.isName}</span>
            </UserInfoPause>
            <UserInfoPause title="Pausa do atendimento">
              Pausa: <span>{typeOfPause}</span>
            </UserInfoPause>
          </ContentUserInfo>
          <Button
            onClick={onGetOutToPause}
            title="Sair da pausa"
            style={buttonStyle(systemConfiguration.cancelation_button_color)}
          >
            Sair da pausa
          </Button>
        </>
      )}
    </>
  );
}
