import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { parseISO, format } from 'date-fns';
import { ChatBroadcastListForm } from 'views/ChatBroadcastList/form';
import {
    Container,
    Row,
    Card,
    CardHeader,
    Button,
    CardBody,
    Col,
    Modal,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    ModalHeader,
    ModalBody,
    ModalFooter, 
  } from 'reactstrap';
  
  
  


const ModalTextWhats = ({row}) =>{
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };

    

    if(row.typeBroadcastList == 1){
      row.broadcastTypeOfClosingConversationOptionsModal = 'Transferir para fila';
    }else if(row.typeBroadcastList == 2) {
      row.broadcastTypeOfClosingConversationOptionsModal = 'Encerrar na primeira resposta';
    }else if(row.typeBroadcastList == 3) {
      row.broadcastTypeOfClosingConversationOptionsModal = 'Transferir para fila com primeira resposta';
    }else if(row.typeBroadcastList == 4) {
      row.broadcastTypeOfClosingConversationOptionsModal = 'Transferir para a Árvore de Diálogo';
    }

    if(row.isAutomatic == true){
      row.isAutomatic = 'Sim'
    }else {
      row.isAutomatic = 'Não'
    }

    const dateCreate = row.created_at ? format(parseISO(row.created_at), 'dd/MM/yyyy') : '';

    return (
        <>
        
        
          <Button
            color="success"
            size="sm"
            title="Open Modal"
            onClick={toggleModal}
            className="ml-3 "    
          >
            <i className="fas fa-info-circle"></i>

          </Button>
    
          <Modal isOpen={modalIsOpen} toggle={toggleModal}>

            <ModalHeader toggle={toggleModal}>Informações da Campanha Ativa Whatsapp</ModalHeader>
            <ModalBody>
            <p>
                              <strong>Título: </strong>
                              {
                                row.title
                              }
                            </p>
                            <p>
                              <strong>Data de criação: </strong>
                              {dateCreate}
                            </p>
                            <p>
                              <strong>Data de Encerramento:  </strong>
                              {row.expiresIn ? `${row.expiresIn.slice(8, 10)}/${row.expiresIn.slice(5, 7)}/${row.expiresIn.slice(0, 4)} ${row.expiresIn.slice(11, 19)}` : ''}
                            </p>
                            <p>
                              <strong>
                                Fila: 
                              </strong>
                                {row.queue.name}                              
                            </p>
                            <p>
                              <strong>Tipo: </strong>
                              {row.broadcastTypeOfClosingConversationOptionsModal}
                            </p>
                            <p>
                              <strong>Whatsapp: </strong>
                              {row.sourcePhone}
                            </p>
                            <p>
                              <strong>Status: </strong>
                              {row.status}
                            </p>
                            <p>
                              <strong>Automatica: </strong>
                              {row.isAutomatic}
                            </p>
                            <p>
                              <strong>Mensagem Predefinida: </strong>
                              <br></br>
                               {row.message}
                            </p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={toggleModal}>
                Fechar
              </Button>
            </ModalFooter>
          </Modal>
        </>
      );
} 

export default ModalTextWhats;