import { useCallback, useState } from 'react';
import CustomFieldsService from '../service';
import { toast } from 'react-toastify';

export function useEntityCustomFieldsByRecordType(entity, recordTypeId) {
  const [customFields, setCustomFields] = useState([]);
  const [isLoadingCustomFields, setIsLoadingCustomFields] = useState(false);

  const getCustomFields = useCallback(async () => {
    setIsLoadingCustomFields(true);
    if (recordTypeId) {
      try {
        const response = await CustomFieldsService.getFieldsByRecordType(
          entity,
          recordTypeId
        );
        setCustomFields(response.data.data);
        return response.data.data;
      } catch (error) {
        const message =
          error.response?.data?.message ||
          'Erro ao carregar campos customizados';
        toast.error(message);
      } finally {
        setIsLoadingCustomFields(false);
      }
    }
  }, [entity, recordTypeId]);

  return { customFields, getCustomFields, isLoadingCustomFields };
}
