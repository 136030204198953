/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import {
  Container,
  Row,
  Card,
  CardHeader,
  CardBody,
  Table,
  Button
} from 'reactstrap';

import ModalConfirmation from 'components/Modal/ConfirmationModal';
import Loader from 'components/Loader';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import Pagination from 'components/Pagination';

import LeadProcessService from '../service';

import { useListLeadProcesses } from '../hooks/useListLeadProcesses';
import { toast } from 'react-toastify';

const LeadProcessList = () => {
  const [activePage, setActivePage] = useState(1);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [idLeadProcess, setIdLeadProcess] = useState(null);

  const history = useHistory();

  const {
    getLeadProcesses,
    leadProcesses,
    totalLeadProcesses,
    isLoadingLeadProcesses
  } = useListLeadProcesses();

  useEffect(() => {
    getLeadProcesses();
  }, []);

  const handlePageChange = (newPage) => {
    setActivePage(newPage);
    getLeadProcesses(newPage);
  };

  const handleEditButtonClick = useCallback((leadProcessId) => {
    history.push(`/admin/crm/leadProcess/form/${leadProcessId}`);
  });

  const handleDeleteLeadProcess = useCallback((id) => {
    LeadProcessService.delete(id)
      .catch((err) => {
        const message =
          err?.response?.data?.message || 'Erro ao deletar o processo do lead';
        toast.error(message);
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        getLeadProcesses(1);
      });
  }, []);

  const controlDeleteAudio = (id) => {
    setIdLeadProcess(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/crm/leadProcess/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Processos - Leads</h3>
                </CardHeader>
                <CardBody>
                  {isLoadingLeadProcesses ? (
                    <div className="col-12">
                      <Row className="justify-content-md-center">
                        <Loader />
                      </Row>
                    </div>
                  ) : (
                    <Table hover={true} striped>
                      <thead>
                        <tr>
                          <th>Nome</th>
                          <th className="text-center">Editar</th>
                          <th className="text-center">Excluir</th>
                        </tr>
                      </thead>
                      <tbody>
                        {leadProcesses &&
                          leadProcesses.map((leadProcess) => {
                            return (
                              <tr key={leadProcess.id}>
                                <td>{leadProcess.name}</td>
                                <td width="10%" className="text-center">
                                  <Button
                                    size="sm"
                                    color="info"
                                    onClick={() =>
                                      handleEditButtonClick(leadProcess.id)
                                    }
                                  >
                                    <li className="fas fa-user-edit"></li>
                                  </Button>
                                </td>
                                <td width="10%" className="text-center">
                                  <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() =>
                                      controlDeleteAudio(leadProcess.id)
                                    }
                                  >
                                    <li className="fas fa-trash"></li>
                                  </Button>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </Table>
                  )}
                </CardBody>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={totalLeadProcesses}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>

      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idLeadProcess}
        deleteResource={handleDeleteLeadProcess}
        message={'Tem certeza que deseja excluir o processo?'}
      />
    </>
  );
};

export default LeadProcessList;
