import styled from 'styled-components';

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  .inputLoader {
    position: absolute;
    right: 0;
    top: calc(50% - 18px);
  }
`;
