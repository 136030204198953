import React, { useState, useEffect, useRef, useCallback } from 'react';
import { MdAttachFile, MdFileDownload, MdPhoneForwarded } from 'react-icons/md';
import { IoIosSend } from 'react-icons/io';
import { useSelector, useDispatch } from 'react-redux';
import { GrEmoji } from 'react-icons/gr';
import Picker from 'emoji-picker-react';
import PredefinedMessageService from '../../../PredefinedMessage/service';
import Select from 'react-select';
import { custom_select_40vw } from '../../../../assets/styles/multiple_select';
import { Button } from 'reactstrap';
import ClientBalloon from 'components/Conversation/ChatBallons/Client';
import UserBalloon from 'components/Conversation/ChatBallons/User';
import SpyBallon from 'components/Conversation/ChatBallons/Spy';
import { toast } from 'react-toastify';
import { apiChat, apiInstagramChat, apiSMSChat } from '../../../../http';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import ConversationReportService from '../../../ChatConfiguration/service/index';
import ConversationService from '../../service';
import {
  Hr,
  Header,
  Dialog,
  HeaderOptions,
  Search,
  EmptyAttendance,
  Bottom,
  LeftArea,
  RightArea,
  InactiveConversation,
  Option
} from './components/styled';
import CloseConversation from './components/Modal/CloseConversation';
import TransferConversation from './components/Modal/TransferConversation';
import HistoryConversation from './components/Modal/HistoryConversation';
import TimelineConversation from './components/Modal/TimelineConversation';
import ResumeConversation from './components/Modal/ResumeConversation';
import UploadFile from './components/Modal/UploadFile';
import UploadLink from './components/Modal/UploadLink';
import AudioSender from './components/AudioSender';
import { useConectionSocketAdmConversation } from 'hooks/useSocketAdmConversations';
import { addMessageConversationInAttendance } from 'store/action/conversation-in-attendance-action';
import { addMessageAnsweredConversation } from 'store/action/answered-conversations-action';
import ClickToCall from './components/Modal/ClickToCall';
import ContactDetails from './components/Modal/ContactDetails';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';
import { useFileUploadModalHandler } from './components/Modal/UploadFile/hooks/useFileUploadModalHandler';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import ReactLoader from 'components/Loader';
import { InputContainer } from './styles';
import AddActiveContact from './components/Modal/AddActiveContact';
import { dataURLtoFile } from './utils/transformImageData';
import TemplatesModal from './components/TemplatesModal';
import ReactButton from 'components/Buttons/ReactButton';
import Service from '../../../ChatConfiguration/service';
import { ModalLead } from './components/Modal/ModalLead';
import { getMatchTeams } from './utils/getMatchTeams';
import axios from 'axios';
import FlexChatIntegration from '../QueueContent/components/Contact/service/FlexChatIntegration';
import ContactService from '../QueueContent/components/Contact/service/index';
import TagClientService from 'views/CRM/Tags/services/TagClientService';

const { saveAs } = require('file-saver');

const MINUTE = 60 * 1000;

const AttendanceContent = () => {
  const newContactInfo = useSelector((state) => state.contactEvents);
  const { socketConversation } = useConversationConnectionSocketForMonitoring();
  const [configurationCreateProtocol, setConfigurationCreateProtocol] =
    useState(false);
  const { socketAdm } = useConectionSocketAdmConversation();
  const { isOpen, changeStatus } = useFileUploadModalHandler();
  const [clientActiveSession, setClientActiveSession] = useState(true);
  const [message, setMessage] = useState('');
  const [showEmojis, setShowEmojis] = useState(false);
  const [conversation, setConversation] = useState(null);

  const [predefinedMessageOptions, setPredefinedMessageOptions] = useState([]);
  const [
    selectedPredefinedMessageOrderToSend,
    setSelectedPredefinedMessageOrderToSend
  ] = useState(null);
  const [clientTyping, setClientTyping] = useState(false);
  const [idNameTags, setIdNameTags] = useState({});
  const [attendantTyping, setAttendantTyping] = useState(false);
  const [disableClickToCall, setDisableClickToCall] = useState(true);
  const [recording, setRecording] = useState(false);
  const [loggedAttendants, setLoggedAttendants] = useState([]);
  const [filesLoadedOnPaste, setFilesLoadedOnPaste] = useState(null);
  const [timeToCloseConversation, setTimeToCloseConversation] = useState(null);
  const [isTimeToCloseConversation, setIsTimeToCloseConversation] =
    useState(null);
  const messagesEndRef = useRef(null);
  const [isSending, setIsSending] = useState(false);
  const messageArea = useRef(null);
  const dispatch = useDispatch();
  const [modalConversationVisbility, setModalConversationVisbility] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [newContactInfoController, setNewContactInfoController] =
    useState(null);
  const [contactId, setContactId] = useState();

  const conversationInAttendance = useSelector(
    (state) => state.conversationInAttendance
  );

  const telephonyServicePanel = useSelector(
    (state) => state.telephonyServicePanel
  );
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const wrapperRef = useRef(null);

  useEffect(() => {
    if (conversation) {
      ContactService.getContactByClientId(conversation.cliente.id)
        .then((response) => {
          setContactId(response.data.id);
        })
        .catch((error) => {
          console.error('Erro ao tentar pegar o contactId =>', error);
        });
    }
  }, [conversation]);

  const isWhatsappChannel = conversation && conversation.canal === 'whatsapp';

  //socket listeners
  useEffect(() => {
    socketConversation.on('digitando_cliente', (data) => {
      setClientTyping(data.digitando);
    });

    socketConversation.on('parou_digitar_cliente', (data) => {
      setClientTyping(data.digitando);
    });

    socketConversation.on('mensagem_antendente_chegou', async (messageData) => {
      await dispatch(
        addMessageConversationInAttendance(
          { ...messageData.message },
          messageData.conversaId
        )
      );
      //updating list of answeredConversations in store
      await dispatch(
        addMessageAnsweredConversation(
          { ...messageData.message },
          messageData.conversaId
        )
      );
    });
  }, [socketConversation, dispatch]);

  useEffect(() => {
    ConversationReportService.getConfigProtocolChat()
      .then((response) => {
        const result = response.data;
        setConfigurationCreateProtocol(result.criarProtocoloParaConversa);
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Erro ao carregar configurações!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  }, []);

  useEffect(() => {
    if (Object.keys(newContactInfo.contactInfo).length > 0) {
      setNewContactInfoController(newContactInfo.contactInfo);
    }
  }, [newContactInfo]);

  useEffect(() => {
    TagClientService.getAll({
      showDisabled: true
    }).then((response) => {
      const tagsResponse = response.data.data;
      const idToTagName = {};
      tagsResponse.forEach((tag) => {
        idToTagName[tag.id] = {
          name: tag.name,
          disabled: tag.disabled,
          color: tag.color
        };
      });

      setIdNameTags(idToTagName);
    });
  }, [conversation]);

  const checkHaveClientMessage = useCallback(() => {
    const clientMessage = conversation.mensagens.filter((message) => {
      return message.cliente_ou_atendente === 'cliente';
    });

    if (!clientMessage || clientActiveSession.length === 0) return false;

    const [lastClientMessage] = clientMessage.slice(-1);

    if (!lastClientMessage) return false;

    const today = new Date();
    const yesterday = new Date();
    const lastMessageTimeStamp = new Date(lastClientMessage.hora_da_mensagem);

    yesterday.setDate(today.getDate() - 1);

    const userActive = !(lastMessageTimeStamp < yesterday);

    return userActive;
  }, [conversation]);

  const verifyClientStatus = useCallback(async () => {
    // if (isWhatsappChannel) {
    //   const clientNumber = conversation.cliente.id_whatsapp;

    //   const isClientSessionActive = await ContactService.getStatusClientSession(
    //     clientNumber
    //   );

    //   setClientActiveSession(isClientSessionActive);

    //   return;
    // }

    if (isWhatsappChannel) {
      const hasClientMessage = checkHaveClientMessage(conversation);
      setClientActiveSession(hasClientMessage);
    }
  }, [checkHaveClientMessage, conversation, isWhatsappChannel]);

  useEffect(() => {
    if (
      conversationInAttendance &&
      conversationInAttendance.conversation &&
      Object.keys(conversationInAttendance.conversation).length !== 0
    ) {
      setConversation({ ...conversationInAttendance.conversation });
    } else {
      setConversation(null);
    }
  }, [conversationInAttendance]);

  //Check active conversation and set sessions
  useEffect(() => {
    if (conversation) {
      verifyClientStatus();
    }
  }, [verifyClientStatus, conversation]);

  useEffect(() => {
    if (!isOpen) {
      setFilesLoadedOnPaste(null);
    }
  }, [isOpen]);

  useEffect(() => {
    if (telephonyServicePanel.extension === '') {
      setDisableClickToCall(true);
    } else {
      setDisableClickToCall(false);
    }
  }, [telephonyServicePanel]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShowEmojis(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [wrapperRef]);
  //*
  useEffect(() => {
    socketConversation.emit('lista_atendentes_logados');
    let userTeam = localStorage.getItem('teams');
    if (userTeam) {
      userTeam = JSON.parse(userTeam);
    }
    socketAdm.on('atendentes_logados', (data) => {
      setLoggedAttendants(data?.atendentes);
    });
  }, [socketAdm, socketConversation]);

  useEffect(() => {
    Service.getWaitingTime().then((response) => {
      if (response?.data?.waitingTime) {
        setTimeToCloseConversation(Number(response?.data?.waitingTime) * 60);
        return;
      }
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      Service.getWaitingTime().then((response) => {
        if (response?.data?.waitingTime) {
          setTimeToCloseConversation(Number(response?.data?.waitingTime) * 60);
          return;
        }
        setTimeToCloseConversation(0);
      });
    }, MINUTE);
    return () => clearInterval(interval);
  }, []);

  const handleMessageChange = (event) => {
    const text = event.target.value;

    if (
      text.length !== 0 &&
      attendantTyping === false &&
      conversation?.canal === 'chat'
    ) {
      setAttendantTyping(true);
      socketConversation.emit('digitando', conversation._id);
    } else if (text.length === 0 && conversation?.canal === 'chat') {
      setAttendantTyping(false);
      socketConversation.emit('parou_digitar', conversation._id);
    }

    setMessage(text);
  };

  const handlePressEnter = (event) => {
    if (message !== '' && event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      onSubmitMiddleware();
    }

    if (message === '' && event.key === 'Enter') event.preventDefault();
  };

  const onEmojiClick = (event, emojiObject) => {
    setMessage(message.concat(emojiObject.emoji));
    setShowEmojis(false);
  };

  const selectPredefinedMessage = (event) => {
    setSelectedPredefinedMessageOrderToSend(event);
    setMessage(message.concat(event.value));
    document.getElementById('text-area').focus();
  };

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
    });
  };

  const onSubmit = async () => {
    setIsSending(true);
    const containsLink =
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=]*)?/gi.test(
        message
      );

    try {
      const messageData = {
        cliente_ou_atendente: 'atendente',
        escrita_por: localStorage.getItem('name'),
        texto: message,
        response_type: containsLink ? 'link' : 'text'
      };

      let response;
      let responsePayload;

      if (conversation.canal === 'instagram') {
        response = await apiInstagramChat.post(
          `/instagram/send-message/${conversation.id}`,
          {
            idDaConversa: conversation.id,
            conversa: {
              mensagem: {
                cliente_ou_atendente: 'atendente',
                escrita_por: localStorage.getItem('name'),
                texto: messageData.texto,
                response_type: 'text'
              }
            }
          }
        );
        responsePayload = response.data.data;
      } else if (conversation.canal === 'whatsapp') {
        response = await apiInstagramChat.post(
          `/whatsapp/send-message/text/${conversation.id}`,
          {
            idDaConversa: conversation._id,
            conversa: {
              mensagem: { ...messageData }
            }
          }
        );

        responsePayload = response.data.data;
      } else if (conversation.canal === 'sms') {
        response = await apiSMSChat.post(
          `/sms/send-message/${conversation._id}`,
          {
            idDaConversa: conversation._id,
            conversa: {
              mensagem: {
                cliente_ou_atendente: conversation.cliente.id_zenvia_sms,
                escrita_por: localStorage.getItem('name'),
                texto: messageData.texto,
                response_type: 'text'
              }
            }
          }
        );
        responsePayload = response.data.data;
      } else if (conversation.canal === 'chat') {
        response = await apiSMSChat.post(`/webchat/send-message/text`, {
          chatId: conversation._id || conversation.id,

          message: { ...messageData }
        });

        responsePayload = response.data.data;
      } else if (conversation.canal === 'flex4u') {
        response = await apiSMSChat.post(`/flex4u/send-message/text`, {
          chatId: conversation._id,
          message: { ...messageData }
        });
        responsePayload = response.data.data;
      } else {
        response = await apiChat.post(`/conversa/atendente-enviar-mensagem`, {
          chatId: conversation._id ?? conversation.id,
          message: {
            mensagem: { ...messageData }
          }
        });
        responsePayload = response.data.message;
      }

      const conversationId = conversation._id ?? conversation.id;

      await dispatch(
        addMessageConversationInAttendance(
          { ...responsePayload },
          conversationId
        )
      );
      //updating list of answeredConversations in store
      await dispatch(
        addMessageAnsweredConversation({ ...responsePayload }, conversationId)
      );

      await socketConversation.emit('parou_digitar', conversationId);
      setAttendantTyping(false);
      setMessage('');
      setIsSending(false);
      scrollToBottom();
    } catch (err) {
      toast.warning(err?.response?.data?.message || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setIsSending(false);
      document.getElementById('text-area').focus();
      scrollToBottom();
    }
  };

  async function fetchMidiaAndAppendToFormData(midiaUrl) {
    const newFile = fetch(midiaUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'file', { type: blob.type });
        return file;
      });

    const file = await newFile;
    return file;
  }

  const sendMidiaWithUrl = async (midiaUrl) => {
    if (midiaUrl === '') {
      return;
    }
    try {
      const conversationId = conversation._id ?? conversation.id;
      const formatedData = new FormData();

      const updatedFormDataMidia = await fetchMidiaAndAppendToFormData(
        midiaUrl
      );

      const fileType = updatedFormDataMidia.type.split('/')[0];

      formatedData.append('file', updatedFormDataMidia);

      const dateNow = new Date();
      formatedData.append('idDaConversa', conversationId);
      formatedData.append('cliente_ou_atendente', 'atendente');
      formatedData.append('escrita_por', localStorage.getItem('name'));
      if (selectedPredefinedMessageOrderToSend.whenToSend === '3') {
        formatedData.append('message', message);
      }
      formatedData.append('response_type', fileType);
      formatedData.append('type', fileType);
      formatedData.append(
        'legenda',
        `upload_atendente_${dateNow.toLocaleDateString(
          'pt-br'
        )}-${dateNow.toLocaleTimeString('pt-br')}`
      );

      if (
        conversation.canal === 'whatsapp' ||
        conversation.canal === 'chat' ||
        conversation.canal === 'flex4u'
      ) {
        if (conversation.canal === 'whatsapp') {
          return FlexChatIntegration.uploadFile(
            conversation._id ?? conversation.id,
            formatedData
          )
            .then((res) => {
              dispatch(
                addMessageConversationInAttendance(
                  { ...res.data },
                  conversationId
                )
              );
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              scrollToBottom();
              setIsSending(false);
            });
        } else if (conversation.canal === 'flex4u') {
          FlexChatIntegration.uploadFileFlex4U(formatedData)
            .then((res) => {
              dispatch(
                addMessageConversationInAttendance(
                  { ...res.data.data },
                  conversationId
                )
              );
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              scrollToBottom();
              setIsSending(false);
            });
        } else {
          FlexChatIntegration.uploadFileWebchat(formatedData)
            .then((res) => {
              dispatch(
                addMessageConversationInAttendance(
                  { ...res.data.data },
                  conversationId
                )
              );
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              scrollToBottom();
              setIsSending(false);
            });
        }

        return;
      }

      ConversationService.uploadFile(formatedData)
        .then((res) => {
          dispatch(
            addMessageConversationInAttendance({ ...res.data }, conversationId)
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          scrollToBottom();
          setIsSending(false);
        });
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    if (conversation) {
      scrollToBottom();
      if (conversation?.idCanal) {
        PredefinedMessageService.getByChannel(conversation?.idCanal)
          .then((response) => {
            let options = [];
            Object.values(response.data).forEach((el) => {
              options.push({
                label: el.title,
                value: el.message,
                equipes: el.teams,
                preDefinedMessageMidiaUrl: el.preDefinedMessageMidiaUrl,
                whenToSend: el.whenToSend
              });
            });

            const filteredPredefinedMessageOptions = getMatchTeams([
              ...options
            ]);

            setPredefinedMessageOptions([...filteredPredefinedMessageOptions]);
          })
          .catch((err) => {
            console.log('err: ' + err);
          });
      }
    }
  }, [conversation]);

  const callBack = () => {
    const data = {
      origem: telephonyServicePanel.extension,
      destino: conversation.cliente.telefone,
      id_conversa: conversation._id ?? conversation.id
    };

    if (disableClickToCall) {
      toast.error(
        'É necessário estar logado em um ramal para realizar a chamada!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    } else {
      if (!window.confirm('Tem certeza que deseja realizar a chamada agora?'))
        return;

      apiChat
        .post(`/clicktocall`, data)
        .then(() => {
          toast.success('Realizando chamada!', {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .catch((err) => {
          console.error(err.response.data.msg);
          toast.error(err.response.data.msg || 'Erro ao realizar chamada!');
        });
    }
  };

  const handleImageFormatFromPaste = (file) => {
    const files = file.items;
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.indexOf('image') !== -1) {
        const fileReader = new FileReader();
        const blob = files[i].getAsFile();
        fileReader.onload = function (event) {
          const imageToPaste = dataURLtoFile(
            event.target.result,
            `${Date.now()}.png`
          );
          setFilesLoadedOnPaste([imageToPaste]);
        };
        fileReader.readAsDataURL(blob);
        break;
      }
    }
  };

  const handleOnInputPaste = (event) => {
    const clipboardData =
      event.clipboardData || event.originalEvent.clipboardData;
    handleImageFormatFromPaste(clipboardData);
    const text = clipboardData.getData('Text');
    if (text) return false;

    const files = clipboardData.files;
    if (!files) return false;

    setFilesLoadedOnPaste(files);
  };

  const onDropHandle = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { files } = event.dataTransfer;
    if (!files) return false;

    setFilesLoadedOnPaste(files);
  };

  const handleOnDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'copy';
  };

  const checkIfIsTimeToCloseConversation = useCallback(() => {
    if (!conversation) return;

    const attendanceTimeInSeconds =
      new Date(conversation?.hora_do_atendimento).getTime() / 1000;

    const currentTimeInSeconds = new Date().getTime() / 1000;

    let isTimeToCloseConversation =
      currentTimeInSeconds >= timeToCloseConversation + attendanceTimeInSeconds;

    setIsTimeToCloseConversation(isTimeToCloseConversation);
  }, [conversation, timeToCloseConversation]);

  useEffect(() => {
    checkIfIsTimeToCloseConversation();

    const interval = setInterval(() => {
      checkIfIsTimeToCloseConversation();
    }, MINUTE / 2);

    return () => clearInterval(interval);
  }, [checkIfIsTimeToCloseConversation]);

  const mediaTypes = ['image', 'audio', 'video', 'file'];

  const mediaList = conversation?.mensagens.filter((msg) => {
    return mediaTypes.includes(msg?.response_type);
  });

  const verifyContactInfoName = () =>
    newContactInfoController?.conversationId === conversation.cliente.id
      ? newContactInfoController?.contactName
      : conversation.cliente.nome;

  async function baixarMidia(mensagem) {
    try {
      const response = await axios.get(mensagem.source, {
        responseType: 'blob'
      });
      const fileType = response?.data?.type?.split('/');
      const extensao = fileType[1];
      const nomeArquivo = `${mensagem.mid}.${extensao}`;

      if (response.data instanceof Blob) {
        saveAs(response.data, nomeArquivo);
      }
    } catch (error) {
      toast.error('Erro ao baixar arquivos', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  }

  async function baixarTodasAsMidias() {
    setIsLoading(true);
    if (!mediaList.length) {
      toast.error('Não há arquivos nesta conversa');
      setIsLoading(false);
      return;
    }
    for (const mensagem of mediaList) {
      if (
        mensagem.response_type === 'image' ||
        mensagem.response_type === 'audio' ||
        mensagem.response_type === 'video' ||
        mensagem.response_type === 'file'
      ) {
        await baixarMidia(mensagem);
      }
    }
    setIsLoading(false);
  }
  const onSubmitMiddleware = async () => {
    setIsSending(true);
    if (selectedPredefinedMessageOrderToSend?.whenToSend === '1') {
      await sendMidiaWithUrl(
        selectedPredefinedMessageOrderToSend?.preDefinedMessageMidiaUrl
      );
      setTimeout(onSubmit, 1000);
      setSelectedPredefinedMessageOrderToSend(null);
      return;
    }
    if (selectedPredefinedMessageOrderToSend?.whenToSend === '2') {
      await onSubmit();
      await sendMidiaWithUrl(
        selectedPredefinedMessageOrderToSend?.preDefinedMessageMidiaUrl
      );
      setSelectedPredefinedMessageOrderToSend(null);
      return;
    }
    if (selectedPredefinedMessageOrderToSend?.whenToSend === '3') {
      await sendMidiaWithUrl(
        selectedPredefinedMessageOrderToSend?.preDefinedMessageMidiaUrl
      );
      setSelectedPredefinedMessageOrderToSend(null);
      return;
    }

    await onSubmit();
    setSelectedPredefinedMessageOrderToSend(null);
  };

  const formatPredefinedMessageOption = (option) => {
    return (
      <div
        style={{
          display: 'flex',
          flexDirection: `${
            option.preDefinedMessageMidiaUrl ? 'row' : 'column'
          }`,
          justifyContent: `${
            option.preDefinedMessageMidiaUrl ? 'space-around' : 'center'
          }`,
          width: '100%',
          padding: '5px'
        }}
      >
        <div
          style={{
            marginRight: '10px'
          }}
        >
          <strong>Título:</strong> {option.label} <br />
          <strong>Mensagem:</strong> {option.value}
        </div>
        <div>
          {option.preDefinedMessageMidiaUrl && <strong>Arquivo anexado</strong>}{' '}
          <br />
          {option.whenToSend === '1' && (
            <span>Enviar mídia antes da mensagem</span>
          )}
          {option.whenToSend === '2' && (
            <span>Enviar midia depois da mensagem</span>
          )}
          {option.whenToSend === '3' && (
            <span>Enviar mensagem e mídia juntas</span>
          )}
        </div>
      </div>
    );
  };
  const filterPredefinedMessageOptions = predefinedMessageOptions.map(
    (option, index) => {
      option.value = option.value.replace(
        /#cliente/g,
        conversation?.cliente?.nome
      );
      option.value = option.value.replace(
        /#atendente/g,
        conversation?.atendente?.name
      );
      option.value = option.value.replace(
        /#CLIENTE/g,
        conversation?.cliente?.nome
      );
      option.value = option.value.replace(
        /#ATENDENTE/g,
        conversation?.atendente?.name
      );
      return option;
    }
  );
  const customFilter = (option, inputValue) => {
    if (!inputValue) {
      return true;
    }
    const mensagemFiltrada =
      option?.data?.label?.toLowerCase().includes(inputValue.toLowerCase()) ||
      option?.data?.value?.toLowerCase().includes(inputValue.toLowerCase());

    return mensagemFiltrada;
  };
  return conversation ? (
    <div
      id="chat-drop-zone"
      onPaste={handleOnInputPaste}
      onDrop={onDropHandle}
      onDragOver={handleOnDragOver}
      style={{ all: 'unset' }}
    >
      <Header>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <h3 className="display-5 headerTitle">
            {verifyContactInfoName()}
            {clientTyping && <span className="typing">está digitando...</span>}
          </h3>
        </div>

        <HeaderOptions>
          <Option title={'Baixar todos os arquivos da conversa'}>
            {isLoading ? (
              <ReactLoader width="20px" height="20px" />
            ) : (
              <Button
                disabled={isLoading}
                color="link"
                size="sm"
                style={{
                  width: '100%',
                  height: '100%',
                  padding: 0
                }}
                onClick={() => baixarTodasAsMidias()}
              >
                <MdFileDownload size={20} color="#32325d" />
              </Button>
            )}
          </Option>
          {verifyRoles(['ACTIVE_CONTACT']) && (
            <AddActiveContact
              name="Adicionar Novo Contato"
              tooltipTitle="Adicionar usuário aos contatos ativos"
              conversation={conversation}
            ></AddActiveContact>
          )}
          <ClickToCall
            title={`Retornar ligação!\nÉ necessário estar logado em um ramal para realizar a chamada!`}
            callBack={callBack}
            color="link"
            icon={<MdPhoneForwarded size={20} color="#32325d" />}
            disableClickToCall={disableClickToCall}
          />
          <ResumeConversation
            name="Resumo do atendimento"
            tooltipTitle="Resumo"
            conversation={conversation}
          />
          <TimelineConversation
            name="Timeline do atendimento"
            tooltipTitle="Timeline"
            timeline={conversation.timeline}
          />
          <ContactDetails contactId={conversation.cliente.id_postgres} />
          <ModalLead contactId={conversation.cliente.id_postgres} />
          <HistoryConversation
            name="Histórico de atendimentos"
            tooltipTitle="Histórico"
            id={conversation.cliente.id_postgres}
          />

          <TransferConversation
            name="Transferir atendimento"
            tooltipTitle="Transferir"
            id={conversation._id ?? conversation.id}
            conversation={conversation}
            //*
            isCurrentAgentsLogged={loggedAttendants}
          />

          <CloseConversation
            isDisabled={false}
            name="Encerrar atendimento"
            tooltipTitle="Encerrar"
            conversation={conversation}
            id={conversation._id ? conversation._id : conversation.id}
            reason={conversation.motivo}
            subReason={conversation.submotivo}
            protolEndOfConversation={configurationCreateProtocol}
            idProtocol={conversation.idProtocolo}
            recordType={conversation.recordType}
          />
        </HeaderOptions>
      </Header>
      <Hr className="mb-1" />
      <Dialog>
        {/* //alteração feita para o sussurro */}
        {conversation.mensagens.map((el, index) => {
          let source = el.source ?? '';
          source = source.replace('download=false', 'download=true');
          return el.cliente_ou_atendente === 'atendente' ||
            el.cliente_ou_atendente === 'admin' ? (
            el.cliente_ou_atendente === 'atendente' ? (
              <UserBalloon
                key={`conversationinattendance+${conversation._id}+${index}`}
                name={el.escrita_por}
                type={el.response_type}
                message={el.texto}
                title={el.title}
                time={el.hora_da_mensagem}
                options={el.options}
                source={source}
                status={el.status}
                description={el.description}
                className={'userBalloon'}
              />
            ) : (
              <SpyBallon
                key={`conversationinattendance+${conversation._id}+${index}`}
                name={el.escrita_por}
                type={el.response_type}
                message={el.texto}
                title={el.title}
                status={el.status}
                time={el.hora_da_mensagem}
                options={el.options}
                source={source}
                description={el.description}
              />
            )
          ) : (
            <ClientBalloon
              context={
                el.context
                  ? conversation.mensagens.find(
                      (mensagen) =>
                        mensagen.id_mensagem_whatsapp === el.context.in_reply_to
                    )
                  : null
              }
              todasMensagens={conversation.mensagens}
              teste={el}
              key={`conversationinattendance+${conversation._id}+${index}`}
              name={el.escrita_por}
              contact={el.contact}
              type={el.response_type}
              message={el.texto}
              title={el.title}
              time={el.hora_da_mensagem}
              options={el.options}
              description={el.description}
              source={source}
            />
          );
        })}

        <div ref={messagesEndRef} />
      </Dialog>
      {!clientActiveSession && isWhatsappChannel ? (
        <InactiveConversation>
          <h3>Cliente não está ativo!</h3>
          <span>
            Você ainda pode enviar mensagem, o cliente será ativado quando
            responder a mensagem.
          </span>
          <ReactButton
            color="primary"
            onClick={() => {
              setModalConversationVisbility(!modalConversationVisbility);
            }}
            type="button"
          >
            Enviar mensagem
          </ReactButton>
        </InactiveConversation>
      ) : (
        <div>
          <Search>
            <Hr className="mt-1 mb-1" />
            <Select
              styles={custom_select_40vw}
              menuPlacement="auto"
              isClearable={false}
              placeholder="Buscar mensagens predefinidas..."
              isSearchable={true}
              name="color"
              menuPosition="top"
              options={filterPredefinedMessageOptions}
              filterOption={(option, inputValue) =>
                customFilter(option, inputValue)
              }
              onChange={(e) => {
                setMessage(null);
                selectPredefinedMessage(e);
              }}
              getOptionLabel={(option) => formatPredefinedMessageOption(option)}
            />
          </Search>
          <div
            onClick={async () => {
              await verifyClientStatus();

              return;
            }}
          >
            <Bottom clientActiveSession={clientActiveSession}>
              <LeftArea>
                <Button
                  className="shadow-none bottomBtn"
                  color="link"
                  size="sm"
                  title="Emoji"
                  onClick={() => {
                    setShowEmojis(!showEmojis);
                  }}
                >
                  <GrEmoji />
                </Button>
                {showEmojis && (
                  <div ref={wrapperRef} className="pickerZone">
                    <Picker
                      disableSearchBar={true}
                      onEmojiClick={onEmojiClick}
                      id="emojiZone"
                    />
                  </div>
                )}
                <Button
                  className="shadow-none bottomBtn"
                  color="link"
                  size="sm"
                  title="Enviar arquivo"
                  onClick={() => changeStatus(true)}
                >
                  <MdAttachFile />
                </Button>
                <UploadLink
                  conversation={conversation}
                  id={conversation._id ?? conversation.id}
                />
              </LeftArea>

              <InputContainer>
                <TextareaAutosize
                  id="text-area"
                  autoFocus={true}
                  style={{
                    borderColor: systemConfiguration.primary_color,
                    width: '100%'
                  }}
                  rowsMin={1}
                  rowsMax={3}
                  placeholder="Escreva a mensagem..."
                  onChange={handleMessageChange}
                  onKeyPress={handlePressEnter}
                  onClick={() => setShowEmojis(false)}
                  value={message}
                  ref={messageArea}
                  disabled={!clientActiveSession || isSending}
                />
                {isSending && (
                  <div className="inputLoader">
                    <ReactLoader height={35} />
                  </div>
                )}
              </InputContainer>

              <RightArea>
                {window.isSecureContext === true && (
                  <AudioSender
                    id={conversation._id ?? conversation.id}
                    setRecording={setRecording}
                    conversation={conversation}
                  />
                )}

                {recording === false && (
                  <Button
                    className="shadow-none bottomBtn"
                    color="link"
                    size="sm"
                    onClick={onSubmitMiddleware}
                    title="Enviar"
                    disabled={!message?.trim() || isSending}
                  >
                    <IoIosSend />
                  </Button>
                )}
              </RightArea>
            </Bottom>
          </div>
        </div>
      )}
      <UploadFile
        id={conversation._id ?? conversation.id}
        isModalOpen={isOpen}
        onModalToggle={changeStatus}
        unmountOnClose={true}
        disabledSubmit={conversation.canal === 'instagram'}
        defaultFiles={filesLoadedOnPaste}
        conversation={conversation}
      />
      <TemplatesModal
        id={conversation._id ?? conversation.id}
        show={modalConversationVisbility}
        onModalToggle={setModalConversationVisbility}
        showCloseButton={false}
        conversation={conversation}
        contactId={contactId}
        clientId={conversation.cliente.id}
        queue={conversation.fila}
      />
    </div>
  ) : (
    <EmptyAttendance>Nenhuma conversa em atendimento...</EmptyAttendance>
  );
};

export default AttendanceContent;
