import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { codeRegex } from '../assets/regex';
import Service from '../service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

export function FormShutdownCause(props) {
  const history = useHistory();
  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      setLoading(true);
      Service.get(id)
        .then((res) => {
          const getShutDownCause = res.data.data;
          reset(getShutDownCause);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch((err) => {
          const { data } = err.response;
          toast.error(data.message || 'Erro ao listar Causa de desligamento!', {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoading(false);
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      const formatedData = { ...data };
      if (isUpdate) {
        await Service.update(id, formatedData);
        toast.success('Causa de desligamento atualizada com sucesso', {
          autoClose: 3000,
          closeOnClick: true
        });
      } else {
        await Service.create(formatedData);
        toast.success('Causa de desligamento criado com sucesso', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
      history.push('/admin/shutdown/cause');
    } catch (err) {
      const { data } = err.response;

      toast.error(data.message || 'Erro ao cadastrar Causa de desligamento!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/shutdown/cause');
  };
  return (
    <>
      <>
        <SimpleHeader
          name="Form validation"
          parentName="Forms"
          returnPath="/admin/shutdown/cause"
          buttonTitle="Voltar para a pagina de causas de desligamento"
        />
        <ScreenPrompt
          when={isDirty && !formState.isSubmitting}
          message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
        />
        <Container className="mt--6" fluid>
          <Row className="mb-3">
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <h3 className="mb-0">Causa de desligamento</h3>
                  </CardHeader>
                  <CardBody>
                    {loading ? (
                      <Row className="justify-content-md-center">
                        <ReactLoader />
                      </Row>
                    ) : (
                      <Form
                        className="needs-validation"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Row className="mb-3">
                          <Col md="12">
                            <label className="form-control-label">Nome*</label>
                            <Controller
                              as={Input}
                              control={control}
                              name="cause_title"
                              rules={{
                                required: 'Nome é obrigatório!'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="cause_title"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Código*
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="cause_number"
                              rules={{
                                required: 'Código é obrigatório!',
                                pattern: {
                                  value: codeRegex,
                                  message: 'Código deve ser apenas números'
                                }
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="cause_number"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Descrição
                            </label>
                            <Controller
                              as={Input}
                              control={control}
                              name="description"
                              defaultValue=""
                            />
                          </Col>
                        </Row>
                        <hr />

                        <ReactButton btnColor="confirmation" type="submit">
                          {id ? 'Atualizar' : 'Salvar'}
                        </ReactButton>

                        <ReactButton
                          btnColor="cancelation"
                          onClick={() => cancelAction()}
                        >
                          Cancelar
                        </ReactButton>
                      </Form>
                    )}
                  </CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    </>
  );
}
