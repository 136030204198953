import ReceivedCallReportService from 'views/Report/Callcenter/ReceivedCall/service';

import { REPORT_TYPE, QUERY_PARAMS_ANALYTICAL } from '../utils/consts';

export const getAnalyticalReceivedCallReport = async (params, page) => {
  const {
    data: { data }
  } = await ReceivedCallReportService.getAllReportsType(
    REPORT_TYPE.ANALYTICAL,
    page,
    { ...QUERY_PARAMS_ANALYTICAL, ...params }
  );

  return data;
};
