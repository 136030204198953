import { useCallback } from 'react';

export function useToISOFormat() {
  const toISOFormat = useCallback(async (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${DD}-${MM}-${YYYY}`;
  }, []);

  return { toISOFormat };
}
