import React from 'react';
import CustomTooltip from './style';

export function OutlineTooltipTag({
  placement,
  target,
  autohide = true,
  children
}) {
  return (
    <CustomTooltip
      placement={placement}
      target={target}
      autohide={autohide}
      flip={true}
    >
      {children}
    </CustomTooltip>
  );
}
