import React from 'react';
import { Controller } from 'react-hook-form';
import { Col, Input, Row } from 'reactstrap';
import Datepicker from 'components/Inputs/DatePicker';
import FlexSelect from 'components/Inputs/FlexSelect';

import { customFieldTypes } from 'views/fieldConfigs/utils/customFieldTypes';
import CurrencyMask from 'components/Inputs/CurrencyMask';
const TextInput = ({ field, control }) => (
  <>
    <label htmlFor={field.name}>{field.label}</label>
    <Controller
      render={(props) => (
        <Input
          value={props.value}
          onChange={props.onChange}
          type="text"
          placeholder={field.label}
        />
      )}
      control={control}
      defaultValue=""
      name={field.name}
      id={field.name}
    />
  </>
);

const NumericInput = ({ field, control }) => (
  <>
    <label htmlFor={field.name}>{field.label}</label>
    <Controller
      render={(props) => (
        <Input
          value={props.value}
          onChange={props.onChange}
          type="number"
          placeholder={field.label}
        />
      )}
      control={control}
      defaultValue=""
      name={field.name}
      id={field.name}
    />
  </>
);

const dateRules = {
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};

const DateInputBegin = ({ field, control, setValue, getValues }) => (
  <>
    <div key={field.id}>
      <label htmlFor={field.name}>{field.label} (Data Inicial)</label>
      <Controller
        render={(props) => (
          <Datepicker
            fieldName={field.name + '.DateBegin'}
            defaultValue={props.value}
            valueController={setValue}
          />
        )}
        control={control}
        defaultValue=""
        name={field.name + '.DateBegin'}
        id={field.name}
        rules={{
          ...dateRules,
          required: getValues(field.name + '.DateEnd')
            ? 'Data é obrigatória'
            : false
        }}
      />
    </div>
  </>
);

const DateInputEnd = ({ field, control, setValue, getValues, toISOFormat }) => (
  <>
    <div key={field.id}>
      <label htmlFor={field.name}>{field.label} (Data Final)</label>
      <Controller
        render={(props) => (
          <Datepicker
            fieldName={field.name + '.DateEnd'}
            defaultValue={props.value}
            valueController={setValue}
          />
        )}
        control={control}
        defaultValue=""
        name={field.name + '.DateEnd'}
        id={field.name}
        rules={{
          ...dateRules,
          required: getValues(field.name + '.DateBegin')
            ? 'Data é obrigatória'
            : false,
          validate: async (value) => {
            if (
              new Date(await toISOFormat(value, true)) <
              new Date(
                await toISOFormat(getValues(field.name + '.DateBegin'), true)
              )
            ) {
              return 'A data final não pode ser anterior à inicial!';
            } else {
              return true;
            }
          }
        }}
      />
    </div>
  </>
);

const ListInput = ({ field, setValue, control }) => (
  <>
    <label htmlFor={field.name}>{field.label}</label>
    <Controller
      render={(props) => (
        <FlexSelect
          dataOptions={field.options.map((option) => ({
            label: option.value,
            value: option.value
          }))}
          isMulti={false}
          isClearable={true}
          value={props.value}
          valueController={setValue}
          closeMenuOnSelect={true}
          fieldName={field.name}
          labelName="label"
          valueName="value"
        />
      )}
      control={control}
      defaultValue=""
      name={field.name}
      id={field.name}
    />
  </>
);

const CurrencyInput = ({ field, control, setValue }) => (
  <>
    <label htmlFor={field.name}>{field.label}</label>
    <Controller
      render={(props) => (
        <CurrencyMask
          valueController={setValue}
          value={props.value}
          fieldName={field.name}
        />
      )}
      control={control}
      defaultValue=""
      name={field.name}
      id={field.name}
    />
  </>
);

const HourField = ({ field, control }) => (
  <>
    <label htmlFor={field.name}>{field.label}</label>
    <Controller
      render={(props) => (
        <Input
          value={props.value}
          onChange={props.onChange}
          type="time"
          step="2"
          placeholder={field.label}
        />
      )}
      control={control}
      defaultValue=""
      name={field.name}
      id={field.name}
    />
  </>
);

const CustomFieldsFilterForm = ({
  customFields,
  control,
  setValue,
  toISOFormat,
  getValues
}) => {
  if (!customFields || !customFields.length) return null;

  const getSearchInput = (field) => {
    if (
      field.type === customFieldTypes.TEXT ||
      field.type === customFieldTypes.TELEPHONE ||
      field.type === customFieldTypes.CPF ||
      field.type === customFieldTypes.CNPJ
    )
      return <TextInput field={field} control={control} />;

    if (field.type === customFieldTypes.NUMERIC)
      return <NumericInput field={field} control={control} />;

    if (field.type === customFieldTypes.LIST)
      return <ListInput field={field} setValue={setValue} control={control} />;

    if (field.type === customFieldTypes.CURRENCY)
      return (
        <CurrencyInput field={field} setValue={setValue} control={control} />
      );

    if (field.type === customFieldTypes.HOUR)
      return <HourField field={field} control={control} />;

    return null;
  };

  return (
    <Row>
      {customFields.map((customField) => {
        if (customField.type === 'DATE' && toISOFormat && getValues) {
          return (
            <>
              <Col xs="12" sm="6" md="6" lg="4" key={customField.id}>
                <DateInputBegin
                  field={customField}
                  control={control}
                  setValue={setValue}
                  toISOFormat={toISOFormat}
                  getValues={getValues}
                />
              </Col>

              <Col
                xs="12"
                sm="6"
                md="6"
                lg="4"
                className="mb-3"
                key={customField.id}
              >
                <DateInputEnd
                  field={customField}
                  control={control}
                  setValue={setValue}
                  toISOFormat={toISOFormat}
                  getValues={getValues}
                />
              </Col>
            </>
          );
        }
        return (
          <Col xs="12" sm="6" md="6" lg="4" key={customField.id}>
            <div className="form-group">{getSearchInput(customField)}</div>
          </Col>
        );
      })}
    </Row>
  );
};

export default CustomFieldsFilterForm;
