import React, { useCallback, useState, useEffect } from 'react';
import { Button, Row, Col, Form, Input } from 'reactstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select/async';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';

import { useSearchAccounts } from 'views/CRM/Account/hooks/useSearchAccounts';
import { useSearchContacts } from 'views/CRM/Contact/hooks/useSearchContacts';
import { useRecordTypesByUser } from 'views/RecordType/hooks/useRecordTypesByUser';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import DefaultModal from 'components/Modal/DefaultModal';
import FlexSelect from 'components/Inputs/FlexSelect';

import LeadService from 'views/CRM/Lead/services/leadService';

import { custom_select } from 'assets/styles/multiple_select';

const ConvertLeadModal = ({
  isModalOpen,
  onModalToggle,
  lead,
  isKanban = false,
  callBackFunc = () => {},
  ...props
}) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const { isSearching, filterAccounts } = useSearchAccounts();
  const { isSearchingContacts, filterContacts } = useSearchContacts();
  const [selectedAccountRecordType, setSelectedAccountRecordType] =
    useState(null);
  const [selectedContactRecordType, setSelectedContactRecordType] =
    useState(null);
  const [selectedOpportunityRecordType, setSelectedOpportunityRecordType] =
    useState(null);

  const history = useHistory();
  const { handleSubmit, control, setValue, register } = useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const {
    recordTypes: accountRecordTypes,
    getRecordTypesByUser: getAccountRecordTypesByUser
  } = useRecordTypesByUser(entitiesName.ACCOUNT);
  const {
    recordTypes: contactRecordTypes,
    getRecordTypesByUser: getContactRecordTypesByUser
  } = useRecordTypesByUser(entitiesName.CONTACT);
  const {
    recordTypes: opportunityRecordTypes,
    getRecordTypesByUser: getOpportunityRecordTypesByUser
  } = useRecordTypesByUser(entitiesName.OPPORTUNITY);

  useEffect(() => {
    register('isNewAccount');
    register('isNewContact');
    setValue('isNewAccount', 'true');
    setValue('isNewContact', 'true');
  }, [register, setValue]);

  useEffect(() => {
    if (isModalOpen) {
      getAccountRecordTypesByUser();
      getContactRecordTypesByUser();
      getOpportunityRecordTypesByUser();
    }
  }, [
    isModalOpen,
    getAccountRecordTypesByUser,
    getContactRecordTypesByUser,
    getOpportunityRecordTypesByUser
  ]);

  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);

  const getAccountRecordTypesOptionsDTO = () => {
    if (!accountRecordTypes) return [];
    return accountRecordTypes.map((rt) => ({ label: rt.name, value: rt.id }));
  };

  const getContactRecordTypesOptionsDTO = () => {
    if (!contactRecordTypes) return [];
    return contactRecordTypes.map((rt) => ({ label: rt.name, value: rt.id }));
  };

  const getOpportunityRecordTypesOptionsDTO = () => {
    if (!opportunityRecordTypes) return [];
    return opportunityRecordTypes.map((rt) => ({
      label: rt.name,
      value: rt.id
    }));
  };

  const onSubmit = async (data) => {
    if (!data.name) return toast.error('O nome da oportunidade é obrigatório');
    if (opportunityRecordTypes.length > 1 && !selectedOpportunityRecordType) {
      return toast.error('O tipo de registro da oportunidade é obrigatório');
    }

    const opportunityRT =
      selectedOpportunityRecordType || opportunityRecordTypes[0].id;

    const dataDTO = {
      opportunity: { title: data.name, recordTypeId: opportunityRT }
    };

    if (data.isNewAccount === 'false') {
      if (!selectedAccount) {
        return toast.error('Selecione uma conta');
      }

      dataDTO.accountId = selectedAccount?.value;
    } else {
      if (!data?.account?.name) {
        return toast.error('O nome da conta é obrigatório');
      }
      if (accountRecordTypes.length > 1 && !selectedAccountRecordType) {
        return toast.error('Selecione o tipo de registro da conta');
      }

      const accountRT = selectedAccountRecordType || accountRecordTypes[0].id;

      dataDTO.account = {
        name: data.account.name,
        recordTypeId: accountRT
      };
    }

    if (data.isNewContact === 'false') {
      if (!selectedContact) {
        return toast.error('Selecione um contato');
      }

      dataDTO.contactId = selectedContact?.value;
    } else {
      if (!data?.contact?.name) {
        return toast.error('O nome do contato é obrigatório');
      }
      if (contactRecordTypes.length > 1 && !selectedContactRecordType) {
        return toast.error('Selecione o tipo de registro do contato');
      }

      const contactRT = selectedContactRecordType || contactRecordTypes[0].id;

      dataDTO.contact = {
        name: data.contact.name,
        recordTypeId: contactRT
      };
    }

    dataDTO.leadRecordType = lead.recordTypeId;

    try {
      const response = await LeadService.convertLead(lead.id, dataDTO);
      const oppRecordTypeId =
        selectedOpportunityRecordType || opportunityRecordTypes[0].id;
      toast.success('Lead convertido com sucesso');
      if (!isKanban) {
        history.push(
          `/admin/crm/opportunityEdit/${oppRecordTypeId}/${response.data.data}`
        );
        return;
      }
      callBackFunc();
      onModalToggle(false);
    } catch (error) {
      const errorMsg =
        error?.response?.data?.message || 'Erro ao converter Lead';
      toast.error(errorMsg);
    }
  };

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Converter Lead"
      showCloseButton={false}
      modalContainerClasses="modal-xl"
      {...props}
    >
      <Form
        className="mt--3"
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
      >
        <h2 className="mb-0">Oportunidade</h2>
        <Row className="mb-3">
          <Col
            sm={
              opportunityRecordTypes && opportunityRecordTypes.length > 1
                ? '6'
                : '12'
            }
          >
            <Controller
              render={(props) => (
                <Input
                  value={props.value}
                  onChange={props.onChange}
                  type="text"
                  placeholder="Nome da oportunidade"
                />
              )}
              control={control}
              defaultValue=""
              name="name"
            />
          </Col>

          {opportunityRecordTypes && opportunityRecordTypes.length > 1 ? (
            <Col sm="6">
              <FlexSelect
                value={selectedOpportunityRecordType}
                fieldName="selectedOpportunityRecordType"
                valueController={(_, value) => {
                  setSelectedOpportunityRecordType(value);
                }}
                dataOptions={getOpportunityRecordTypesOptionsDTO()}
                labelName="label"
                valueName="value"
                closeMenuOnSelect={true}
                placeholder="Tipo de registro da oportunidade"
              />
            </Col>
          ) : null}
        </Row>

        <h2 className="mb-0">Conta</h2>
        <Row className="mb-3">
          <Col sm="6">
            <div className="ma-0 d-flex align-items-center mb-0">
              <input
                name="isNewAccount"
                type="radio"
                value="true"
                id="isNewAccount"
                defaultChecked={true}
                onChange={(event) =>
                  setValue('isNewAccount', event.target.value)
                }
              />
              <label
                htmlFor="isNewAccount"
                className="small inline-block ml-1 mb-0"
              >
                Criar nova conta
              </label>
            </div>
            <Controller
              render={(props) => (
                <Input
                  value={props.value}
                  onChange={props.onChange}
                  type="text"
                  placeholder="Nome da conta"
                />
              )}
              control={control}
              defaultValue={lead?.name}
              name="account.name"
            />
          </Col>

          <Col sm="6">
            <div className="ma-0 d-flex align-items-center mb-0">
              <input
                name="isNewAccount"
                type="radio"
                value="false"
                id="isExistentAccount"
                defaultChecked={false}
                onChange={(event) =>
                  setValue('isNewAccount', event.target.value)
                }
              />
              <label
                htmlFor="isExistentAccount"
                className="small inline-block ml-1 mb-0"
              >
                Selecionar conta existente
              </label>
            </div>
            <div>
              <Select
                styles={custom_select}
                placeholder="Busca conta"
                isClearable={true}
                name="accountId"
                isLoading={isSearching}
                loadingMessage={() => 'Buscando...'}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue
                    ? 'Digite para pesquisar'
                    : 'Nenhum resultado encontrado'
                }
                loadOptions={async (inputValue) => {
                  const res = await filterAccounts(inputValue);
                  return (
                    res &&
                    res.map((account) => ({
                      label: account.name,
                      value: account.id
                    }))
                  );
                }}
                onChange={(data) => {
                  if (data) {
                    const { value, label } = data;
                    setSelectedAccount({
                      label,
                      value
                    });
                  } else {
                    setSelectedAccount(null);
                  }
                }}
                value={
                  selectedAccount
                    ? {
                        label: selectedAccount.label,
                        value: selectedAccount.value
                      }
                    : ''
                }
                defaultValue=""
              />
            </div>
          </Col>
          {accountRecordTypes && accountRecordTypes.length > 1 ? (
            <Col sm="6" className="mt-1">
              <FlexSelect
                value={selectedAccountRecordType}
                fieldName="selectedAccountRecordType"
                valueController={(_, value) => {
                  setSelectedAccountRecordType(value);
                }}
                dataOptions={getAccountRecordTypesOptionsDTO()}
                labelName="label"
                valueName="value"
                closeMenuOnSelect={true}
                placeholder="Tipo de registro da conta"
              />
            </Col>
          ) : null}
        </Row>

        <h2 className="mb-0">Contato</h2>
        <Row className="mb-3">
          <Col sm="6">
            <div className="ma-0 d-flex align-items-center mb-0">
              <input
                name="isNewContact"
                type="radio"
                value="true"
                id="isNewContact"
                defaultChecked={true}
                onChange={(event) =>
                  setValue('isNewContact', event.target.value)
                }
              />
              <label
                htmlFor="isNewContact"
                className="small inline-block ml-1 mb-0"
              >
                Criar novo contato
              </label>
            </div>
            <Controller
              render={(props) => (
                <Input
                  value={props.value}
                  onChange={props.onChange}
                  type="text"
                  placeholder="Nome do contato"
                />
              )}
              control={control}
              defaultValue={lead?.name}
              name="contact.name"
            />
          </Col>

          <Col sm="6">
            <div className="ma-0 d-flex align-items-center mb-0">
              <input
                name="isNewContact"
                type="radio"
                value="false"
                id="isExistentContact"
                defaultChecked={false}
                onChange={(event) =>
                  setValue('isNewContact', event.target.value)
                }
              />
              <label
                htmlFor="isExistentContact"
                className="small inline-block ml-1 mb-0"
              >
                Selecionar contato existente
              </label>
            </div>
            <div>
              <Select
                styles={custom_select}
                placeholder="Buscar contato"
                isClearable={true}
                name="contactId"
                isLoading={isSearchingContacts}
                loadingMessage={() => 'Buscando...'}
                noOptionsMessage={({ inputValue }) =>
                  !inputValue
                    ? 'Digite para pesquisar'
                    : 'Nenhum resultado encontrado'
                }
                loadOptions={async (inputValue) => {
                  const res = await filterContacts(inputValue);
                  return (
                    res &&
                    res.map((contact) => ({
                      label: contact.name,
                      value: contact.id
                    }))
                  );
                }}
                onChange={(data) => {
                  if (data) {
                    const { value, label } = data;
                    setSelectedContact({ label, value });
                  } else {
                    setSelectedContact(null);
                  }
                }}
                value={
                  selectedContact
                    ? {
                        label: selectedContact.label,
                        value: selectedContact.value
                      }
                    : ''
                }
                defaultValue=""
              />
            </div>
          </Col>

          {contactRecordTypes && contactRecordTypes.length > 1 ? (
            <Col sm="6" className="mt-1">
              <FlexSelect
                value={selectedContactRecordType}
                fieldName="selectedContactRecordType"
                valueController={(_, value) => {
                  setSelectedContactRecordType(value);
                }}
                dataOptions={getContactRecordTypesOptionsDTO()}
                labelName="label"
                valueName="value"
                closeMenuOnSelect={true}
                placeholder="Tipo de registro do contato"
              />
            </Col>
          ) : null}
        </Row>

        <Row className="mt-1">
          <Col>
            <Button
              style={buttonStyle(systemConfiguration.confirmation_button_color)}
              type="submit"
            >
              Converter
            </Button>
            <Button
              style={buttonStyle(systemConfiguration.cancelation_button_color)}
              onClick={closeModal}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    </DefaultModal>
  );
};

export default ConvertLeadModal;
