import React from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { formatCurrency } from 'utils/fuctions/formatCurrency';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';
import { Container, Ul } from './styles';
export const PageToPrint = ({
  protocolNumber,
  comments,
  protocolData,
  customFields,
  defaultFields
}) => {
  Object.byString = function (object, string) {
    string = string?.replace(/\[(\w+)\]/g, '.$1');
    string = string?.replace(/^\./, '');
    let a = string?.split('.');
    for (let i = 0, n = a?.length; i < n; ++i) {
      let k = a[i];
      if (!object) {
        return;
      }
      if (k in object) {
        object = object[k];
      } else {
        return;
      }
    }
    return object;
  };

  const defaultFieldsPaths = {
    client_id: 'client.nome',
    current_responsible: 'current_responsible',
    current_status: 'protocol_statuses[0].status.name',
    description: 'description',
    reason_id: 'reason.name',
    service_channel: 'service_channel',
    subreason_id: 'subreason.name',
    telephone: 'telephone',
    title: 'title',
    email: 'email',
    account_id: 'account.name'
  };

  const defaultFieldsNames = {
    client_id: 'Contato',
    current_responsible: 'Responsável',
    current_status: 'Status/Fase',
    description: 'Descrição',
    reason_id: 'Motivo',
    service_channel: 'Canal de atendimento',
    subreason_id: 'Submotivo',
    telephone: 'Telefone',
    title: 'Titulo',
    email: 'Email',
    account_id: 'Conta'
  };
  let customFieldsArray = [];
  let defaultFieldsArray = [];
  let description = undefined;
  let allFields = [];

  const setValueDyncamicField = (FieldName) => {
    for (let [key, value] of Object.entries(protocolData)) {
      if (key === FieldName) return value || '';
    }
  };

  if (defaultFields) {
    let defaultFieldsKeys = Object.keys(defaultFields).filter((fieldKey) => {
      return defaultFields[fieldKey].isActive;
    });

    defaultFieldsArray = defaultFieldsKeys.map((field) => {
      if (field === 'files') return;
      if (field === 'description') {
        description = (
          <p key={field}>
            <strong style={{ marginRight: '0.3rem' }}>
              {' '}
              {defaultFieldsNames[field]}:
            </strong>
            {Object.byString(protocolData, defaultFieldsPaths[field])}
          </p>
        );
        return;
      }

      return (
        <li key={field}>
          <strong style={{ marginRight: '0.3rem' }}>
            {defaultFieldsNames[field]}:
          </strong>
          {Object.byString(protocolData, defaultFieldsPaths[field])}
        </li>
      );
    });
    allFields.push([...defaultFieldsArray.filter(Boolean)]);
  }

  if (customFields) {
    customFieldsArray = customFields.map((field, index) => {
      if (field.type === 'DATE') {
        let value = new Date(setValueDyncamicField(field.name));

        return (
          <li key={index}>
            <strong style={{ marginRight: '0.3rem' }}>{field.label}:</strong>
            {value.toLocaleDateString('pt-br')}
          </li>
        );
      }
      if (field.type === 'CURRENCY') {
        return (
          <li key={index}>
            <strong style={{ marginRight: '0.3rem' }}>{field.label}:</strong>
            {formatCurrency(setValueDyncamicField(field.name))}
          </li>
        );
      }
      return (
        <li key={index}>
          <strong style={{ marginRight: '0.3rem' }}>{field.label}:</strong>
          {setValueDyncamicField(field.name)}
        </li>
      );
    });
    allFields.push([...customFieldsArray]);
  }

  return (
    <Container>
      <Card>
        <CardHeader>
          <h3 className="mb-0">Nº. do Protocolo {protocolNumber}</h3>
        </CardHeader>
        <CardBody>
          <label className="form-control-label">Dados do contato(s)</label>
          <Row style={{ flexDirection: 'column' }}>
            <Col>
              <Ul>{allFields && allFields.length ? allFields : null}</Ul>
            </Col>
            {description ? (
              <Col style={{ paddingLeft: '3.4375rem' }}>{description}</Col>
            ) : null}
          </Row>

          {comments && comments.length ? (
            <>
              <label
                className="form-control-label"
                style={{ paddingTop: '25px' }}
              >
                Comentário(s)
              </label>
              <ToolkitProvider
                data={comments || []}
                keyField="id"
                columns={[
                  {
                    dataField: 'description',
                    text: 'Descrição',
                    sort: true,
                    style: {
                      ...individual_column_style,
                      whiteSpace: 'break-spaces'
                    },
                    headerStyle: individual_header_style,
                    formatter: (value) => {
                      const myHtml = `<div>${value}</div>`;
                      return (
                        <div dangerouslySetInnerHTML={{ __html: myHtml }} />
                      );
                    }
                  },
                  {
                    dataField: 'creation_date',
                    text: 'Data de criação',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      const date = new Date(Row);
                      return date.toLocaleDateString('pt-br');
                    }
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </>
          ) : null}
        </CardBody>
      </Card>
    </Container>
  );
};
