import produce from 'immer';

const INITIAL_STATE = {
  contactInfo: {}
};

export default function contactEventsReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@ANSWERED_CONVERSATION/UPDATE_CONTACT_NAME':
      return produce(state, (draft) => {
        const dataToDraft = {
          conversationId: action.payload.data.contactId,
          contactName: action.payload.data.newContactName
        };
        draft.contactInfo = dataToDraft;
      });
    default:
      return state;
  }
}
