import React, { useState } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import {
  Button,
  Container,
  Row,
  Col,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { BiArrowBack } from 'react-icons/bi';
import { useSelector } from 'react-redux';

const MultiButtonsHeader = ({ buttons, size = 'sm', ...props }) => {
  const history = useHistory();
  const [state, setState] = useState({
    path: '',
    return: props.returnPath || '/admin/configuration',
    controlReturn: false
  });
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const handleButtonClick = (button) => {
    const { path, customNavigator } = button;
    if (customNavigator) return customNavigator();
    history.push(path, props);
  };
  const buttonCreate = props.setCreate ? (
    <>
      <Button onClick={() => setState({ ...state, path: props.setCreate })}>
        Criar
      </Button>
    </>
  ) : null;
  if (state.path && props.entityName) {
    return (
      <Redirect
        to={{
          pathname: state.path,
          entityName: { entityName: props.entityName }
        }}
      />
    );
  } else if (state.path) {
    return (
      <Redirect
        to={{
          pathname: state.path
        }}
      />
    );
  }
  if (state.controlReturn) return <Redirect to={state.return} />;

  const renderRegularButton = (button, key) => {
    return (
      <Button
        size={size}
        disabled={button.disabled}
        key={key}
        onClick={() => handleButtonClick(button)}
      >
        {button.label}
      </Button>
    );
  };

  const renderDropdownButton = (button, key) => {
    return (
      <ButtonDropdown
        isOpen={button.dropdown.isDropdownOpen}
        toggle={() =>
          button.dropdown.setIsDropdownOpen(!button.dropdown.isDropdownOpen)
        }
        key={key}
        size={size}
        disabled={button.disabled}
      >
        <Button
          id="caret"
          title={button.title}
          onClick={() => handleButtonClick(button)}
        >
          {button.label}
        </Button>
        <DropdownToggle split />
        <DropdownMenu>
          {button.dropdown.items.map((item, index) => (
            <DropdownItem
              key={index}
              title={item.title}
              onClick={() => item.onClick()}
            >
              {item.label}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    );
  };

  return (
    <div
      className="header header-dark  pb-6 content__title content__title--calendar"
      style={{ background: systemConfiguration.primary_color }}
    >
      <Container fluid>
        <div className="header-body">
          <Row className="align-items-center py-4">
            <Col md="3">
              {props.showArrowReturn && (
                <Button
                  size="sm"
                  color={systemConfiguration.primary_color}
                  title="Volta para a pagina de configurações"
                  onClick={() => history.push(`/admin/configuration`)}
                >
                  <BiArrowBack size="35px" color="white" />
                </Button>
              )}
            </Col>

            <Col sm="9" className="mt-3 mt-md-0 text-md-right">
              {buttons.map((button, index) => {
                const buttonType = button.type || 'regular';
                if (buttonType === 'regular')
                  return renderRegularButton(button, index);
                if (buttonType === 'dropdown')
                  return renderDropdownButton(button, index);
              })}
              {buttonCreate}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default MultiButtonsHeader;
