import React from 'react';
import { Table, Button } from 'reactstrap';

const OutOfHoursScheduleTable = ({ item = {}, handleRemoveItem }) => {
  return (
    <Table>
      <thead>
        <tr>
          <th>Tipo de Entrada</th>
          <th>Entrada</th>
          <th>Programação de Horário</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{item.type}</td>
          <td>{item.input}</td>
          <td>{item.schedule}</td>
          <td>
            <Button onClick={() => handleRemoveItem()} color="danger" size="sm">
              Remover
            </Button>
          </td>
        </tr>
      </tbody>
    </Table>
  );
};

export default OutOfHoursScheduleTable;
