export const typesConfig = [
  { value: 'nps', label: 'NPS' },
  { value: 'satisfacao', label: 'SATISFAÇÃO' }
];

export const channels = [
  { value: 'chat', label: 'Chat' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'telegram', label: 'Telegram' },
  { value: 'voice', label: 'Voz' },
  { value: 'whatsapp', label: 'WhatsApp' }
];

export const notes = [
  {
    value: 1,
    label: '1 - ⭐'
  },
  {
    value: 2,
    label: '2 - ⭐⭐'
  },
  {
    value: 3,
    label: '3 - ⭐⭐⭐'
  },
  {
    value: 4,
    label: '4 - ⭐⭐⭐⭐'
  },
  {
    value: 5,
    label: '5 - ⭐⭐⭐⭐⭐'
  },
  {
    value: 6,
    label: '6 - ⭐⭐⭐⭐⭐⭐'
  },
  {
    value: 7,
    label: '7 - ⭐⭐⭐⭐⭐⭐⭐'
  },
  {
    value: 8,
    label: '8 - ⭐⭐⭐⭐⭐⭐⭐⭐'
  },
  {
    value: 9,
    label: '9 - ⭐⭐⭐⭐⭐⭐⭐⭐⭐'
  },
  {
    value: 10,
    label: '10 - ⭐⭐⭐⭐⭐⭐⭐⭐⭐⭐'
  }
];
