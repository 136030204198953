import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import CostCenterService from '../../../Sector/service';
import UserService from '../../../User/service';
import TrunkService from '../../../Trunk/service';
import ExtensionService from '../../../Extension/service';
import ReportService from './service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import HourMaskMMHHSS from '../../../../components/Inputs/HourMaskMMHHSS.js';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ReportAnalythical from './tables/Analytical';
import ReportSynthetic from './tables/Synthetic';
import ReportDetailedSynthetic from './tables/DetailedSynthetic';
import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';
import Pagination from 'components/Pagination';
import * as fileSaver from 'file-saver';
import { v4 as uuid } from 'uuid';
import { format, startOfMonth } from 'date-fns';
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ddrService from '../../../../views/Ddrs/service';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';
import { ItemPerPageSelect } from 'components/Inputs/ItemPerPageSelect';

const hourRules = {
  required: 'Hora é obrigatório!',
  pattern: {
    value: /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/,
    message: 'Hora inválida! (00:00:00)'
  }
};

const dateRules = {
  required: 'Data é obrigatório!',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};

const reportOptions = [
  { label: 'Analítico', value: 1 },
  { label: 'Sintético', value: 2 },
  { label: 'Sintético detalhado', value: 3 }
];

const dispositionOptions = [
  { label: 'Atendida', value: 'ANSWERED' },
  { label: 'Não Atendida', value: 'NO ANSWER' }
];

const ReceivedCallReport = () => {
  const { control, handleSubmit, errors, setValue, watch, getValues } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [trunks, setTrunks] = useState([]);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [users, setUsers] = useState([]);
  const [extensios, setExtensions] = useState([]);
  const [ddrs, setDdrs] = useState();
  const [loading, setLoading] = useState(false);
  const [costCenters, setCostCenters] = useState([]);
  const [loadingReport, setLoadingReport] = useState(false);
  const reportType = watch('reportType');
  const [ReportTypeAnalytical, setReportTypeAnalytical] = useState([]);
  const [ReportTypeSynthetic, setReportTypeSynthetic] = useState([]);
  const [ReportTypeDetailedSynthetic, setReportTypeDetailedSynthetic] =
    useState([]);
  const [media, setMedia] = useState('');
  const showDisables = !!watch('disable');
  const [dataToSend, setDataToSend] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );
  const [messagemGeneratePdf, setMessagemGeneratePdf] = useState();
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  useEffect(() => {
    ddrService
      .getAll()
      .then((response) => {
        const data = response.data.data;
        const ddrData = data.map((value) => ({
          label: value.ddr,
          id: value.id
        }));
        setDdrs(ddrData);
      })
      .catch((error) => {
        console.log('error: ', error);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    CostCenterService.getAll()
      .then((response) => {
        setCostCenters(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    ExtensionService.getAllShowDisableNew(showDisables)
      .then((response) => {
        setExtensions(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, [showDisables]);

  useEffect(() => {
    setLoading(true);
    TrunkService.getAll()
      .then((response) => {
        setTrunks(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    getUser(true);
  }, [usersShowDisable]);

  const getUser = (showDisable = false) => {
    if (!showDisable) {
      setLoading(true);
    }
    UserService.getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        response.data.data.forEach((el, index) => {
          if (el.pin_code === '') response.data.data[index].pin_code = 'null';
        });
        setUsers(response.data.data);
        if (!showDisable) {
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log('err: ' + err);
        if (!showDisable) {
          setLoading(false);
        }
      });
  };

  //messagemGeneratePdfCsv
  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantity,
      limitGenerationPDF
    );

    setMessagemGeneratePdf(message.pdfMessage);
    setMessagemGenerateCsv(message.csvMessage);
  }, [quantity, limitGenerationPDF]);

  const clearData = () => {
    setReportTypeAnalytical([]);
    setReportTypeSynthetic([]);
    setReportTypeDetailedSynthetic([]);
    setDataToSend({});
    setQuantity(0);
    setActivePage(1);
  };

  const toISOFormat = (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');

    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${MM}-${DD}-${YYYY}`;
  };

  const getFormatedType = (value) => {
    switch (value) {
      case 1:
        return 'analytical';
      case 2:
        return 'synthetic';
      case 3:
        return 'detailedsynthetic';
      default:
        break;
    }
  };

  const getDataDTO = (data) => {
    let formatedData;

    formatedData = {
      ...data,
      dateBegin: `${toISOFormat(data.dateBegin)} ${data.timeStart}`,
      dateEnd: `${toISOFormat(data.dateEnd)} ${data.timeEnd}`,
      timeStart: data.timeStart.replace(/:/g, ''),
      timeEnd: data.timeEnd.replace(/:/g, '')
    };

    if (!formatedData.pin_code) delete formatedData.pin_code;
    if (!formatedData.carrier) delete formatedData.carrier;
    if (!formatedData.disposition) delete formatedData.disposition;
    if (!formatedData.dst) delete formatedData.dst;
    if (!formatedData.origin) delete formatedData.origin;
    if (!formatedData.cost_center) delete formatedData.cost_center;
    if (!formatedData.ddr) delete formatedData.ddr;

    setDataToSend(formatedData);
    return formatedData;
  };

  const getReports = (typeReport, dataToSend, page, useLoader = true) => {
    setLoadingReport(useLoader);
    if (media === 'pdf' || media === 'csv') {
      ReportService.getDownloads(typeReport, 1, media, dataToSend)
        .then((res) => {
          if (res.data.size <= 11)
            toast.info('Nenhum registro foi encontrado!');
          else {
            fileSaver.saveAs(
              new Blob([res.data], { type: res.headers['content-type'] }),
              media === 'pdf'
                ? 'Relatório_de_Chamadas_Recebidas_PBXIP.pdf'
                : 'Relatório_de_Chamadas_Recebidas_PBXIP.csv'
            );
          }
          setLoadingReport(false);
        })
        .catch((err) => {
          toast.error(err || `Erro ao gerar documento ${media}`, {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoadingReport(false);
        });
      setMedia('');
    } else {
      ReportService.getAllReportsType(
        typeReport,
        page,
        dataToSend,
        itemsPerPage
      )
        .then((res) => {
          switch (typeReport) {
            case 'analytical':
              if (res.data.data.quantityCall === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listCallAnalytical.forEach((el, index) => {
                  res.data.data.listCallAnalytical[index].uuid = uuid();
                });
                setReportTypeAnalytical(res.data.data);
                setQuantity(res.data.data.quantityCall);
              }
              break;
            case 'synthetic':
              if (
                res.data.data.listCallSyntheticDTO.length === 0 &&
                res.data.data.listCallForNotQueuedDTO.length === 0
              )
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listCallForNotQueuedDTO.forEach((el, index) => {
                  res.data.data.listCallForNotQueuedDTO[index].uuid = uuid();
                });
                setReportTypeSynthetic(res.data.data);
                setQuantity(
                  res.data.data.listCallSyntheticDTO.length +
                    res.data.data.listCallForNotQueuedDTO.length
                );
              }
              break;
            case 'detailedsynthetic':
              if (res.data.data.quantity === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listCallDetailedSyntheticPbxipDTO.forEach(
                  (el, index) => {
                    res.data.data.listCallDetailedSyntheticPbxipDTO[
                      index
                    ].uuid = uuid();
                  }
                );
                setReportTypeDetailedSynthetic(res.data.data);
                setQuantity(res.data.data.quantity);
              }
              break;
            default:
              break;
          }
          setLoadingReport(false);
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoadingReport(false);
        });
    }
  };

  const onSubmit = (data) => {
    clearData();
    try {
      const formatedData = getDataDTO(data);
      const formatedType = getFormatedType(reportType);
      getReports(formatedType, formatedData, 1, true);
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    clearData();
  }, [reportType]);

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const handlePageChange = (page) => {
    setActivePage(page);
    getReports(getFormatedType(reportType), dataToSend, page, false);
  };

  const getDateBegin = () => {
    const date = new Date();
    const firstDayMonth = new Date(
      date.getFullYear('pt-br'),
      date.getMonth('pt-br'),
      1
    );
    const dateBegin = format(startOfMonth(firstDayMonth), 'dd/LL/yyyy');
    return dateBegin;
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de Chamadas Recebidas - PBXIP</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      <Form
                        className="needs-validation"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Row className="mb-3">
                          <Col md="12">
                            <label className="form-control-label">
                              Operadora
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={trunks}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  closeMenuOnSelect={true}
                                  valueController={setValue}
                                  fieldName="carrier"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="carrier"
                              defaultValue=""
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">Origem</label>
                            <Controller
                              as={Input}
                              control={control}
                              name="origin"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Destino
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={extensios}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="dst"
                                  labelName="extension_number"
                                  valueName="extension_number"
                                />
                              )}
                              control={control}
                              name="dst"
                              defaultValue=""
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dateBegin"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dateBegin"
                              rules={dateRules}
                              defaultValue={getDateBegin()}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dateBegin"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Hora inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <HourMaskMMHHSS
                                  valueController={setValue}
                                  fieldName="timeStart"
                                  value={props.value}
                                />
                              )}
                              control={control}
                              name="timeStart"
                              rules={hourRules}
                              defaultValue="00:00:00"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="timeStart"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data final*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dateEnd"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dateEnd"
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                              rules={{
                                ...dateRules,
                                validate: (value) => {
                                  if (
                                    new Date(toISOFormat(value, true)) <
                                    new Date(
                                      toISOFormat(
                                        getValues()['dateBegin'],
                                        true
                                      )
                                    )
                                  ) {
                                    return 'A data final não pode ser anterior à inicial!';
                                  } else {
                                    return true;
                                  }
                                }
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dateEnd"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Hora Final*
                            </label>
                            <Controller
                              render={(props) => (
                                <HourMaskMMHHSS
                                  valueController={setValue}
                                  fieldName="timeEnd"
                                  value={props.value}
                                />
                              )}
                              control={control}
                              name="timeEnd"
                              rules={hourRules}
                              defaultValue="23:59:59"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="timeEnd"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Usuário
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={users}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="pin_code"
                                  labelName="name"
                                  valueName="pin_code"
                                />
                              )}
                              control={control}
                              name="pin_code"
                              defaultValue=""
                            />
                          </Col>

                          <Col md="6">
                            <label className="form-control-label">Setor</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={costCenters}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  closeMenuOnSelect={true}
                                  valueController={setValue}
                                  fieldName="cost_center"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="cost_center"
                              defaultValue=""
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Status da chamada
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={dispositionOptions}
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  isClearable={true}
                                  closeMenuOnSelect={true}
                                  fieldName="disposition"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="disposition"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Tipo de Relatório
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={reportOptions}
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="reportType"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="reportType"
                              defaultValue={1}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          {ddrs && (
                            <Col>
                              <label className="form-control-label">DDR</label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={ddrs || []}
                                    isMulti={false}
                                    value={props.value}
                                    valueController={setValue}
                                    closeMenuOnSelect={true}
                                    isClearable={true}
                                    fieldName="ddr"
                                    labelName="label"
                                    valueName="label"
                                  />
                                )}
                                control={control}
                                name="ddr"
                                defaultValue=""
                              />
                            </Col>
                          )}
                        </Row>
                        <Row className="mb-3">
                          <Col className="md-6 mx-4">
                            <div style={{ display: 'flex', gap: '20px' }}>
                              <Controller
                                name="disable"
                                control={control}
                                render={(props) => (
                                  <>
                                    <label className="form-control-label">
                                      <Input
                                        type="checkbox"
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                        checked={props.value}
                                      />
                                      Mostrar desabilitados (Ramais)
                                    </label>
                                  </>
                                )}
                                defaultValue={false}
                              />
                              <UserFlexSelect
                                usersShowDisable={usersShowDisable}
                                setUsersShowDisable={setUsersShowDisable}
                                name={'Usuário'}
                              />
                            </div>
                          </Col>
                          <Col md="4" style={ImageButtonContainer}>
                            <div id="button_pdf">
                              {reportType !== 1 && (
                                <button
                                  style={ImageButton}
                                  name="pdf"
                                  onClick={() => setMedia('pdf')}
                                  disabled={disabledDownloadButtonCsvAndPdf(
                                    'pdf',
                                    quantity,
                                    limitGenerationPDF
                                  )}
                                >
                                  <Tooltip
                                    title={
                                      <p style={{ fontSize: '14px' }}>
                                        {messagemGeneratePdf}
                                      </p>
                                    }
                                    arrow
                                  >
                                    <img
                                      src={require('../../../../assets/img/pdf.png')}
                                      alt="pdf"
                                      style={ImageSize}
                                    />
                                  </Tooltip>
                                </button>
                              )}
                            </div>

                            <button
                              id="button_csv"
                              style={ImageButton}
                              onClick={() => setMedia('csv')}
                              disabled={disabledDownloadButtonCsvAndPdf(
                                'csv',
                                quantity,
                                limitGenerationPDF
                              )}
                            >
                              <Tooltip
                                title={
                                  <p style={{ fontSize: '14px' }}>
                                    {messagemGenerateCsv}
                                  </p>
                                }
                                arrow
                              >
                                <img
                                  src={require('../../../../assets/img/csv.png')}
                                  alt="csv"
                                  style={ImageSize}
                                />
                              </Tooltip>
                            </button>
                          </Col>
                        </Row>

                        <Button
                          style={buttonStyle(systemConfiguration.primary_color)}
                          type="submit"
                        >
                          Buscar
                        </Button>
                        <Row className="mb-3">
                          <Col
                            md="3"
                            style={{ marginTop: '-45px', marginLeft: '110px' }}
                          >
                            <ItemPerPageSelect
                              valueController={setItemsPerPage}
                            ></ItemPerPageSelect>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                  {loadingReport ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      {reportType === 1 &&
                        ReportTypeAnalytical.listCallAnalytical && (
                          <ReportAnalythical
                            dataReport={ReportTypeAnalytical}
                            totalRegister={quantity}
                          />
                        )}
                      {reportType === 2 &&
                        ReportTypeSynthetic.listCallForNotQueuedDTO && (
                          <ReportSynthetic
                            dataReport={ReportTypeSynthetic}
                            totalRegister={quantity}
                          />
                        )}
                      {reportType === 3 &&
                        ReportTypeDetailedSynthetic.listCallDetailedSyntheticPbxipDTO && (
                          <ReportDetailedSynthetic
                            dataReport={ReportTypeDetailedSynthetic}
                            totalRegister={quantity}
                          />
                        )}
                    </>
                  )}
                  {(ReportTypeAnalytical.listCallAnalytical ||
                    ReportTypeDetailedSynthetic.listCallDetailedSyntheticPbxipDTO) && (
                    <Pagination
                      itemsCountPerPage={itemsPerPage}
                      activePage={activePage}
                      totalItemsCount={quantity}
                      onChange={handlePageChange.bind(this)}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ReceivedCallReport;
