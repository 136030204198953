import styled from 'styled-components';

export const Title = styled.h3`
  font-size: 1.2em;
  color: white;
  padding-top: 1%;
`;

export const TotalAttendanceData = styled.span`
  display: flex;
  padding-top: 2%;
  font-weight: bold;
  color: black;
`;
