import React, { useEffect, useState } from 'react';
import { differenceInSeconds } from 'date-fns';
import {
  FiPhoneIncoming,
  FiPhoneOutgoing,
  FiPauseCircle
} from 'react-icons/fi';
import { Card, Tooltip } from 'reactstrap';

import { styles } from './styles';

import './styled.css';

import { statusColors } from '../../../utils/state_colors';
import { queueAgentStatus } from '../../../utils/states';

import './styled.css';

const OneCardPerAgent = ({ data, id }) => {
  const [upTime, setUpTime] = useState('00:00:00');
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  useEffect(() => {
    const intervalID = setInterval(() => {
      let differenceFromNow = differenceInSeconds(
        new Date(),
        new Date(data.date)
      );

      const hours = Math.floor(differenceFromNow / (60 * 60));
      differenceFromNow -= hours * 60 * 60;

      const minutes = Math.floor(differenceFromNow / 60);
      differenceFromNow -= minutes * 60;

      const seconds = Math.floor(differenceFromNow);

      function getFomattedDigit(digit) {
        return `${String(digit).padStart(2, '0')}`;
      }

      setUpTime(
        `${getFomattedDigit(hours)}:${getFomattedDigit(
          minutes
        )}:${getFomattedDigit(seconds)}`
      );
    }, 1000);

    return () => clearInterval(intervalID);
  }, [data.date]);

  const getIconByState = (state, isIncomingCall) => {
    if (
      state === queueAgentStatus.UNPLACED ||
      state === queueAgentStatus.AVAILABLE ||
      state === queueAgentStatus.TABULATION_PAUSE
    ) {
      return;
    }
    if (state === queueAgentStatus.PAUSE) return <FiPauseCircle />;
    if (isIncomingCall) return <FiPhoneIncoming />;
    return <FiPhoneOutgoing />;
  };

  const getContentByStatus = () => {
    if (data.state === queueAgentStatus.PAUSE) {
      return data.agent?.pause?.name || '';
    }
    const isInCall =
      data.state === queueAgentStatus.RING ||
      data.state === queueAgentStatus.RINGING;
    if (isInCall) {
      return data.caller;
    }
    return '';
  };

  return (
    <>
      {data.agent.pause?.show_alert && data.state === 'PAUSE' ? (
        <Card
          style={{
            ...styles.container,
            backgroundColor: `purple`
          }}
          id={'tooltip' + id}
        >
          <p style={styles.items}>
            <i className="fas fa-user"></i>
            <span className="mx-1">
              {(data.agent && data.agent.usr && data.agent.usr.name) ||
                data.agent.usr.login}
            </span>
          </p>
          <p style={styles.items}>
            <span>
              <i className="fas fa-phone"></i>
              <span className="mx-1 mr-4">{data.extension || ''}</span>
            </span>
            <span>
              <i className="fas fa-clock"></i>
              <span className="mx-1">{upTime || ''}</span>
            </span>
          </p>
          <p style={styles.items}>
            <i className="fas fa-users"></i>
            <span className="mx-1">
              {data.caller ? data.agent.queue.name : ''}
            </span>
          </p>
          <p style={styles.items}>
            {getIconByState(data.state, data.isIncomingCall)}
            <span className="mx-1">{getContentByStatus()}</span>
          </p>
          {data.agent.pause?.show_alert && (
            <div className="container-pulse" title="Tempo de pausa excedido!">
              <div className="pulse">
                <i className="fa fa-bell"></i>
              </div>
            </div>
          )}
        </Card>
      ) : (
        <Card
          style={{
            ...styles.container,
            backgroundColor: `${statusColors[data.state]}`
          }}
          id={'tooltip' + id}
        >
          <p style={styles.items}>
            <i className="fas fa-user"></i>
            <span className="mx-1">
              {(data.agent && data.agent.usr && data.agent.usr.name) ||
                data.agent.usr.login}
            </span>
          </p>
          <p style={styles.items}>
            <span>
              <i className="fas fa-phone"></i>
              <span className="mx-1 mr-4">{data.extension || ''}</span>
            </span>
            <span>
              <i className="fas fa-clock"></i>
              <span className="mx-1">{upTime || ''}</span>
            </span>
          </p>
          <p style={styles.items}>
            <i className="fas fa-users"></i>
            <span className="mx-1">
              {data.caller ? data.agent.queue.name : ''}
            </span>
          </p>
          <p style={styles.items}>
            {getIconByState(data.state, data.isIncomingCall)}
            <span className="mx-1">{getContentByStatus()}</span>
          </p>
        </Card>
      )}

      <Tooltip
        placement="bottom"
        isOpen={tooltipOpen}
        target={'tooltip' + id}
        toggle={toggle}
      >
        {data.sessions &&
          data.sessions.map((queue) => (
            <span key={queue.id} className="d-block">
              {queue.name || ''}
            </span>
          ))}
      </Tooltip>
    </>
  );
};

export default OneCardPerAgent;
