import { useState, useCallback } from 'react';

import LeadStageService from '../service';

function useListLeadStagesByRecordType() {
  const [leadStages, setLeadStages] = useState(null);
  const [isLoadingLeadStages, setIsLoadingLeadStages] = useState(false);

  const getLeadStagesByRecordType = useCallback((recordTypeId) => {
    setIsLoadingLeadStages(true);

    LeadStageService.listOrdenade(recordTypeId)
      .then((response) => {
        setLeadStages(response.data.data);
      })
      .catch(() => {
        setLeadStages(null);
      })
      .finally(() => {
        setIsLoadingLeadStages(false);
      });
  }, []);

  return {
    leadStages,
    isLoadingLeadStages,
    getLeadStagesByRecordType
  };
}

export { useListLeadStagesByRecordType };
