import AudioService from '../../../Audio/service';
import QueueService from '../../../Queue/service';
import ExtensionService from '../../../Extension/service';
import IVRService from '../../service';
import TimeScheduleService from '../../../TimeSchedule/service';

export const fetchAudios = async () => {
  try {
    const response = await AudioService.getAll(null, null, false, '', {
      type: 'ura'
    });
    return response.data.data;
  } catch (error) {
    console.log('Error getting audios', error);
    return null;
  }
};

export const fetchQueues = async () => {
  try {
    const response = await QueueService.getAll();
    return response.data.data;
  } catch (error) {
    console.log('Error getting queues', error);
    return null;
  }
};

export const fetchExtensions = async () => {
  try {
    const response = await ExtensionService.getAllSimple();
    return response.data.data;
  } catch (error) {
    console.log('Error getting extensions', error);
    return null;
  }
};

export const fetchIVR = async () => {
  try {
    const response = await IVRService.getAll();
    return response.data.data;
  } catch (error) {
    console.log('Error getting IVRs', error);
    return null;
  }
};

export const fetchSchedules = async () => {
  try {
    const response = await TimeScheduleService.getAll();
    return response.data.data;
  } catch (error) {
    console.log('Error getting time schedules', error);
    return null;
  }
};
