/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import SimpleHeaderOffButton from 'components/Headers/SimpleHeaderOffButton';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'components/Pagination';
import TimeShiftService from '../../CalendarScales/service/TimeShift';
import TimeShiftStatusService from '../../CalendarScales/service/TimeShiftStatus';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Card,
  Button,
  CardHeader,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
  Label
} from 'reactstrap';
import { HeaderCalendar, ButtonLogo } from 'views/CalendarScales/style';
import { BsFillCalendarFill, BsPersonLinesFill } from 'react-icons/bs';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';
import { format } from 'date-fns';
import { useHistory } from 'react-router';
import TimeShiftSupervisor from 'views/CalendarScales/service/TimeShiftSupervisor';
import { getShiftStatus } from 'views/ChangeDayOff/functions';

export default function ChangeDayOffSupervisorList() {
  const history = useHistory();
  const login = localStorage.getItem('login');
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [showDisabled, setShowDisabled] = useState(false);

  useEffect(() => {
    localStorage.setItem('viewChangeDayOff', true);
  }, []);

  async function loadApi(page = 1, querySearch = '') {
    setLoading(true);
    const size = 10;
    TimeShiftService.getAllSearchSupervisor(
      showDisabled,
      size,
      page,
      querySearch,
      login
    ).then((response) => {
      setQuantity(response.totalRegisters);
      const data = response.data.data;
      setData(data);
      setLoading(false);
    });
  }
  useEffect(() => {
    loadApi();
  }, [showDisabled]);

  const toISOFormat = (dateNotFormmated) => {
    const [date] = dateNotFormmated.split('T');
    const [YYYY, MM, DD] = date.split('-');
    return `${DD}/${MM}/${YYYY}`;
  };

  const ControlerStatus = (id, status) => {
    try {
      const data = { statusShift: status };

      if (data.statusShift === 'APPROVED') {
        TimeShiftSupervisor.patchShift(id, 'approve').finally(() => loadApi());
      }
      if (data.statusShift === 'REJECT') {
        TimeShiftSupervisor.patchShift(id, 'reject').finally(() => loadApi());
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    loadApi(page);
  };
  return (
    <>
      <SimpleHeaderOffButton name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <Container fluid style={{ backgroundColor: '#ffffff' }}>
                    <Row>
                      <HeaderCalendar>
                        <h2>Trocas</h2>
                        <ButtonLogo
                          onClick={() => {
                            localStorage.removeItem('viewChangeDayOff');
                            history.push('/admin/Calendar');
                          }}
                        >
                          <BsFillCalendarFill color="#646f85"></BsFillCalendarFill>
                        </ButtonLogo>
                        <ButtonLogo active="true">
                          <BsPersonLinesFill color="#646f85"></BsPersonLinesFill>
                        </ButtonLogo>
                      </HeaderCalendar>
                    </Row>
                  </Container>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Buscar Hora Extra"
                          type="text"
                          onChange={'handleOnSearch'}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={data}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'userRequestShift',
                      text: 'solicitante',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'userRequestedShift',
                      text: 'solicitado',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'typeShift',
                      text: 'Tipo de troca',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        if (row.typeShift === 'single' && row.isDayoffShift) {
                          return 'Troca de folga';
                        } else if (
                          row.typeShift === 'single' &&
                          !row.isDayoffShift
                        ) {
                          return 'Troca de horário Avulsa';
                        } else {
                          return 'Troca de horário Casada';
                        }
                      }
                    },
                    {
                      dataField: 'dateTimeShift',
                      text: 'Data de origem da troca',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        if (row.typeShift === 'single' && row.isDayoffShift) {
                          const response = toISOFormat(row.dateTimeShift);

                          const weekDay = format(
                            new Date(row.dateTimeShift),
                            'EEEE'
                          );

                          const scale = row.requestScale;

                          const beginTime = scale[`beginTime${weekDay}`];
                          const endTime = scale[`endTime${weekDay}`];

                          return response;
                        } else {
                          const response = toISOFormat(row.dateTimeShift);

                          const weekDay = format(
                            new Date(row.dateTimeShift),
                            'EEEE'
                          );

                          const scale = row.requestScale;

                          const beginTime = scale[`beginTime${weekDay}`];
                          const endTime = scale[`endTime${weekDay}`];

                          return response + '  -  ' + beginTime + '-' + endTime;
                        }
                      }
                    },
                    {
                      dataField: 'requestedDateShift',
                      text: 'Data de destino da troca',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        if (row.typeShift === 'single' && row.isDayoffShift) {
                          const response = toISOFormat(row.requestedDateShift);

                          const weekDay = format(
                            new Date(row.requestedDateShift),
                            'EEEE'
                          );

                          const scale = row.requestedScale;

                          const beginTime = scale[`beginTime${weekDay}`];
                          const endTime = scale[`endTime${weekDay}`];

                          return response;
                        } else {
                          const response = toISOFormat(row.requestedDateShift);

                          const weekDay = format(
                            new Date(row.requestedDateShift),
                            'EEEE'
                          );

                          const scale = row.requestedScale;

                          const beginTime = scale[`beginTime${weekDay}`];
                          const endTime = scale[`endTime${weekDay}`];

                          return response + '  -  ' + beginTime + '-' + endTime;
                        }
                      }
                    },
                    {
                      dataField: 'statusShift',
                      text: 'status',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        const objectTest = {
                          PENDING: 'Pendente',
                          APPROVED: 'Aprovado',
                          REJECT: 'Rejeitado'
                        };
                        return <>{objectTest[getShiftStatus(row)]}</>;
                      }
                    },
                    {
                      dataField: 'aprove',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Aprovar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        const isAprove =
                          row.supervisorStatusShift !== 'PENDING';
                        return (
                          <Button
                            color="success"
                            size="sm"
                            title={'Confirmar hora extra'}
                            disabled={isAprove}
                            onClick={() => ControlerStatus(row.id, 'APPROVED')}
                          >
                            <li className="fas fa-check-circle"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Rejeitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        const isReject =
                          row.supervisorStatusShift !== 'PENDING';

                        return (
                          <Button
                            color="danger"
                            size="sm"
                            title={'Rejeitar hora extra'}
                            disabled={isReject}
                            onClick={() => ControlerStatus(row.id, 'REJECT')}
                          >
                            <li className="fas fa-times"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
