import React, { useState } from 'react';
import { getStateConversation } from 'utils/fuctions/getStateConversation';
import {
  Modal,
  ModalBody,
  Row,
  Card,
  CardSubtitle,
  CardTitle,
  CardBody,
  Col,
  Button
} from 'reactstrap';
import { CgNotes } from 'react-icons/cg';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../../../assets/styles/table_custom_style';
import { Hr, Option, StyleOption } from '../../styled';
import { LocationOn } from '@material-ui/icons';

const ResumeConversation = ({ name, tooltipTitle = '', conversation = {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const state =
    (conversation.cliente.telefone &&
      getStateConversation(conversation.cliente.telefone)) ||
    'DDD não definido';

  const openGoogleMaps = () => {
    window.open(
      'https://maps.google.com?q=' +
        conversation.cliente.latitude +
        ',' +
        conversation.cliente.longitude
    );
  };

  return (
    <>
      <Option title={tooltipTitle}>
        <Button
          onClick={toggleModal}
          color="link"
          size="sm"
          style={StyleOption}
        >
          <CgNotes size={20} color="#32325d" />
        </Button>
      </Option>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {name}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />
          <CardBody className="mt--4">
            <CardTitle tag="h4">
              Cliente: {conversation.cliente.nome || 'Não informado'}
            </CardTitle>
            <CardSubtitle tag="h5" className="mb-3 text-muted">
              <Row>
                <Col md="6" title="CPF do cliente">
                  <i
                    className="fas fa-id-card"
                    style={{ color: '#5bc0de' }}
                  ></i>{' '}
                  - {conversation.cliente.cpf || 'Não informado'}
                </Col>
                <Col md="6" title="Canal de atendimento">
                  <i className="fas fa-users" style={{ color: '#0275d8' }}></i>{' '}
                  - {conversation.canal || 'Não reconhecido'}
                </Col>
              </Row>
              <Row>
                <Col md="6" title="Telefone do cliente">
                  <i className="fas fa-phone" style={{ color: '#5cb85c' }}></i>{' '}
                  - {conversation.cliente.telefone || 'Não informado'}
                </Col>
                <Col md="6" title="Estado">
                  <i className="fas fa-city" style={{ color: '#f0ad4e' }}></i>
                  {conversation.cliente.telefone && ` - Estado : ${state}`}
                </Col>
              </Row>
              <Row>
                <Col md="12" title="Email do cliente">
                  <i
                    className="fas fa-envelope-open-text"
                    style={{ color: '#f0ad4e' }}
                  ></i>{' '}
                  - {conversation.cliente.email || 'Não informado'}
                </Col>
              </Row>
            </CardSubtitle>
            {conversation.resumoBot && (
              <>
                <Hr />
                <CardTitle tag="h4" className="mt--3">
                  Resumo bot
                </CardTitle>
                <Row className="mt--3">
                  <div className="col">
                    <div className="card-wrapper">
                      <Card>
                        <ToolkitProvider
                          data={conversation.resumoBot}
                          keyField="id"
                          columns={[
                            {
                              dataField: 'id',
                              text: 'Campo',
                              sort: true,
                              style: individual_column_style,
                              headerStyle: individual_header_style
                            },
                            {
                              dataField: 'value',
                              text: 'Descrição',
                              sort: true,
                              style: individual_column_style,
                              headerStyle: individual_header_style
                            }
                          ]}
                        >
                          {(props) => (
                            <div className="py-4 table-responsive">
                              <BootstrapTable
                                {...props.baseProps}
                                bootstrap4={true}
                                bordered={false}
                                classes="align-items-center"
                              />
                            </div>
                          )}
                        </ToolkitProvider>
                      </Card>
                    </div>
                  </div>
                </Row>
              </>
            )}
            <Row>
              {conversation.cliente.longitude &&
              conversation.cliente.latitude ? (
                <Col>
                  <Button
                    color="primary"
                    outline
                    size="sm"
                    type="button"
                    title="Abrir endereço do cliente no Google Maps"
                    onClick={() => {
                      openGoogleMaps();
                    }}
                    style={{ float: 'right' }}
                  >
                    <LocationOn />
                  </Button>
                </Col>
              ) : null}
            </Row>
          </CardBody>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ResumeConversation;
