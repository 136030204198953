import React, { useCallback, useState } from 'react';
import { Card } from '../../styles';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import OpportunityHistoryModal from '../../../OpportunityHistoryModal';
import ModalConfirmation from 'components/Modal/ConfirmationModal';
import OpportunityService from '../../../../../../../services/opportunityService';
import { toast } from 'react-toastify';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import OpportunityActionsModal from 'views/CRM/Opportunity/list/components/OpportunityActionsModal';

export const InfoCard = ({ data: info, dragStart, dragEnter, isDragging }) => {
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const removeItem = () => {
    info.data.setValuesList((prev) => {
      const copyList = { ...prev };
      const index = copyList[info.data.list].findIndex((item) => {
        return item.id === cardProps.id;
      });
      copyList[info.data.list].splice(index, 1);
      return copyList;
    });
  };

  const handleDeleteOpportunity = useCallback((id) => {
    OpportunityService.delete(id)
      .then(() => {
        removeItem();
      })
      .catch((err) => {
        const message =
          err?.response?.data?.message || 'Erro ao deletar a oportunidade';
        toast.error(message);
      })
      .finally(() => {
        setOpenModalConfirmation(false);
      });
  }, []);

  const cardProps = info.data.array[info.index];
  const history = useHistory();

  return (
    <Card
      style={{
        ...info.style,

        color: 'black',
        justifyContent: 'center',
        border: cardProps.dated ? '2px solid red' : ''
      }}
      onDragStart={(e) => dragStart(e, info.index, info.data.list)}
      onDragEnter={(e) => dragEnter(e, info.index)}
      draggable={true}
      key={cardProps?.id}
      id={'draggable' + cardProps?.id}
    >
      <div className="card-padding-before">
        <div title={cardProps.title ?? cardProps.name} className="CardContent">
          <p>{cardProps.title ?? cardProps.name}</p>
          <span>{cardProps.responsibleName}</span>
          <div>
            {cardProps.dated ? (
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 'lighter',
                  color: 'red',
                  marginTop: '28px'
                }}
              >
                Vencido há {cardProps.daysDated} dia(s)
              </div>
            ) : (
              ''
            )}
            <div
              style={{
                fontSize: '12px',
                fontWeight: 'lighter',
                color: 'black'
              }}
            >
              Há {cardProps.daysInStage} dia(s) na fase
            </div>
          </div>
        </div>
        <div className="button-container">
          <Button
            title="Editar"
            onClick={() => {
              history.push(
                `/admin/crm/opportunityEdit/${cardProps.recordTypeId}/${cardProps.id}`
              );
            }}
            size="sm"
            color="info"
          >
            <i className="fas fa-user-edit"></i>
          </Button>
          <OpportunityHistoryModal
            style={{ scale: 0.9 }}
            opportunityId={cardProps.id}
          />

          <OpportunityActionsModal opportunityData={cardProps} />

          <ModalConfirmation
            isModalOpen={openModalConfirmation}
            onModalToggle={setOpenModalConfirmation}
            unmountOnClose={true}
            idResource={cardProps.id}
            deleteResource={handleDeleteOpportunity}
            message={'Tem certeza que deseja excluir a oportunidade?'}
          />

          {verifyRoles(['DELETE_OPPORTUNITY_CRM']) && (
            <Button
              size="sm"
              color="danger"
              onClick={() => setOpenModalConfirmation(true)}
              style={{ marginTop: '-25px' }}
            >
              <li className="fas fa-trash"></li>
            </Button>
          )}
        </div>
      </div>
    </Card>
  );
};
