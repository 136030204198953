import React, { useEffect, useState } from 'react';
import DashboardService from '../../../../../Chat/service';
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from 'reactstrap';
import CardItem from 'views/Dashboard/Chat/components/CardItem';

const AgentConversationInfo = () => {
  const [closed, setClosed] = useState([]);
  const [inAttendance, setInAttendance] = useState([]);
  const [rangeDate, setRangeDate] = useState(null);

  useEffect(() => {
    const today = new Date();
    const range = {
      start: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      end: new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1)
    };
    setRangeDate(range);
  }, []);

  useEffect(() => {
    const loginAtendente = localStorage.getItem('login');
    if (!rangeDate) return;
    DashboardService.getClosed(rangeDate, loginAtendente)
      .then((response) => {
        setClosed(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [rangeDate]);

  useEffect(() => {
    const loginAtendente = localStorage.getItem('login');
    DashboardService.getInAttendance(null, loginAtendente)
      .then((response) => {
        setInAttendance(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h3" className="mb-0">
          <span className="text-truncate h3 mb-0 font-weight-bold">
            Conversas do {localStorage.getItem('login') || 'Atendente'}
          </span>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row className="mb-4">
          {inAttendance && (
            <Col md="8" xl="6">
              <CardItem
                title="Em atendimento"
                text={inAttendance.quantidade || 0}
                icon="fas fa-comments"
              />
            </Col>
          )}
          {closed && (
            <Col md="8" xl="6">
              <CardItem
                title="Encerradas"
                text={closed.quantidade || 0}
                icon="fas fa-times-circle"
              />
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

export default AgentConversationInfo;
