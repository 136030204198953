import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';

import ExtensionService from '../../service';
import ContextService from '../../../Context/service';
import CostCenterService from '../../service/centr_de_custo';
import PJSIPConfiguration from '../../service/pjsip_configuration';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  Button,
  CardBody
} from 'reactstrap';

const EditMultipleForm = () => {
  const history = useHistory();
  const location = useLocation();
  const { actionType, type, extensions = [] } = location.state;

  const { control, handleSubmit, errors, clearErrors } = useForm();

  const [contexts, setContexts] = useState([]);
  const [costsCenter, setCostsCenter] = useState([]);
  const [pjsipConfiguration, setPjsipConfiguration] = useState([]);
  // controle de campos habilitados/desabilitados
  const [enableControl, setEnableControl] = useState({
    secret: false,
    pjsip_configuration_id: false,
    cost_center: false,
    context: false,
    callgroup: false,
    pickupgroup: false,
    call_limit: false,
    allow_external_call_recording: false,
    allow_internal_call_recording: false,
    allow_monitor_extension: false,
    nat: false
  });

  // Get Contexts to select
  useEffect(() => {
    ContextService.getAll()
      .then((response) => {
        setContexts(response.data.data);
      })
      .catch(() => {
        console.log('Erro ao ler os contextos');
      });
  }, []);

  // Get costs center to select
  useEffect(() => {
    CostCenterService.getAll()
      .then((response) => {
        setCostsCenter(response.data.data);
      })
      .catch(() => {
        console.log('Erro ao ler os centros de custo');
      });
  }, []);

  // Get PJSIP configurations to select
  useEffect(() => {
    if (actionType === 'EDIT_ALL' || type === 'PJSIP') {
      PJSIPConfiguration.getAll()
        .then((response) => {
          setPjsipConfiguration(response.data.data);
        })
        .catch(() => {
          console.log('Erro ao ler configurações do PJSIP');
        });
    }
  }, [actionType, type]);

  const onSubmit = async (data) => {
    for (const d in data) {
      if (!enableControl[d]) delete data[d];
    }

    const formattedExtensions =
      extensions.length > 0 && extensions.map((ext) => parseInt(ext.id));

    if (formattedExtensions) data.extensions = formattedExtensions;
    if (type) data.type = type;
    data.updateAll = actionType === 'EDIT_ALL' ? true : false;

    try {
      await ExtensionService.updateExtensionSet(data);
      toast.success('Ramais atualizados', {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/extensions');
    } catch (err) {
      console.log(err.response);
      toast.error('Erro ao salvar ramal', {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/extensions');
    }
  };

  const handleToggleField = (fieldName) => {
    clearErrors(fieldName);
    setEnableControl({
      ...enableControl,
      [fieldName]: !enableControl[fieldName]
    });
  };

  const isSomeChecked = () => {
    const values = Object.values(enableControl);
    return values.some((v) => v);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/extensions');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/protocol"
        buttonTitle="Voltar para a pagina de ramais"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    {actionType === 'EDIT_MULTIPLE'
                      ? 'Edição de Múltiplos Ramais'
                      : 'Edição de todos os Ramais'}
                  </h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <>
                      {(actionType === 'EDIT_ALL' || type !== 'ANALOGICO') && (
                        <Row style={{ paddingTop: '15px' }}>
                          <Col md="6">
                            <div className="d-flex flex-row pl-2">
                              <div className="mt-5">
                                <Input
                                  type="checkbox"
                                  checked={enableControl.secret}
                                  onChange={() => handleToggleField('secret')}
                                />
                              </div>
                              <div className="col col-12">
                                <label className="form-control-label">
                                  Senha*
                                </label>
                                <Controller
                                  as={Input}
                                  type="password"
                                  control={control}
                                  name="secret"
                                  defaultValue=""
                                  disabled={!enableControl.secret}
                                  rules={
                                    enableControl.secret && {
                                      required: 'Defina uma senha',
                                      validate: (value) => {
                                        if (/^\S*$/.test(value)) return true;
                                        return 'Não utilize espaços em branco';
                                      }
                                    }
                                  }
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="secret"
                                  render={({ message }) =>
                                    errorFormMessage(message)
                                  }
                                />
                              </div>
                            </div>
                          </Col>

                          <Col md="6">
                            <div className="d-flex flex-row pl-2">
                              <div className="mt-5">
                                <Input
                                  type="checkbox"
                                  checked={enableControl.pjsip_configuration_id}
                                  onChange={() =>
                                    handleToggleField('pjsip_configuration_id')
                                  }
                                />
                              </div>
                              <div className="col col-12">
                                <label className="form-control-label">
                                  Configuração*
                                </label>
                                <Controller
                                  as={
                                    <Input type="select">
                                      <option value="">Selecione</option>
                                      {pjsipConfiguration.map(
                                        (config, index) => (
                                          <option key={index} value={config.id}>
                                            {config.name}
                                          </option>
                                        )
                                      )}
                                    </Input>
                                  }
                                  disabled={
                                    !enableControl.pjsip_configuration_id
                                  }
                                  control={control}
                                  name="pjsip_configuration_id"
                                  rules={
                                    enableControl.pjsip_configuration_id && {
                                      required: 'Selecione uma configuração'
                                    }
                                  }
                                  defaultValue=""
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="pjsip_configuration_id"
                                  render={({ message }) =>
                                    errorFormMessage(message)
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                        </Row>
                      )}

                      <Row style={{ paddingTop: '15px' }}>
                        <Col md="6">
                          <div className="d-flex flex-row pl-2">
                            <div className="mt-5">
                              <Input
                                type="checkbox"
                                checked={enableControl.cost_center}
                                onChange={() =>
                                  handleToggleField('cost_center')
                                }
                              />
                            </div>
                            <div className="col col-12">
                              <label className="form-control-label">
                                Centro de custo*
                              </label>
                              <Controller
                                as={
                                  <Input type="select">
                                    <option value="">Selecione</option>
                                    {costsCenter.map((cost, index) => (
                                      <option key={index} value={cost.id}>
                                        {cost.name}
                                      </option>
                                    ))}
                                  </Input>
                                }
                                disabled={!enableControl.cost_center}
                                control={control}
                                name="cost_center"
                                rules={
                                  enableControl.cost_center && {
                                    required: 'Campo obrigatório'
                                  }
                                }
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="cost_center"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="6">
                          <div className="d-flex flex-row pl-2">
                            <div className="mt-5">
                              <Input
                                type="checkbox"
                                checked={enableControl.context}
                                onChange={() => handleToggleField('context')}
                              />
                            </div>
                            <div className="col col-12">
                              <label className="form-control-label">
                                Contexto*
                              </label>
                              <Controller
                                as={
                                  <Input type="select">
                                    <option value="">Selecione</option>
                                    {contexts.map((context, index) => (
                                      <option key={index} value={context.id}>
                                        {context.name}
                                      </option>
                                    ))}
                                  </Input>
                                }
                                disabled={!enableControl.context}
                                control={control}
                                name="context"
                                rules={
                                  enableControl.context && {
                                    required: 'Campo obrigatório'
                                  }
                                }
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="context"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      {(actionType === 'EDIT_ALL' || type !== 'ANALOGICO') && (
                        <>
                          <hr />
                          <h3>Configurações</h3>
                          <Row>
                            <Col md="6">
                              <div className="d-flex flex-row pl-2">
                                <div className="mt-5">
                                  <Input
                                    type="checkbox"
                                    checked={enableControl.callgroup}
                                    onChange={() =>
                                      handleToggleField('callgroup')
                                    }
                                  />
                                </div>
                                <div className="col col-12">
                                  <label className="form-control-label">
                                    Call Group
                                  </label>
                                  <Controller
                                    as={Input}
                                    control={control}
                                    name="callgroup"
                                    disabled={!enableControl.callgroup}
                                    rules={
                                      enableControl.callgroup && {
                                        validate: (value) => {
                                          if (
                                            /^([1-9]|[1-5][0-9]|6[0-3])?(,[1-9]|,[1-5][0-9]|,6[0-3]){0,4}$/.test(
                                              value
                                            )
                                          )
                                            return true;
                                          return 'Call Group inválido';
                                        }
                                      }
                                    }
                                    defaultValue=""
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name="callgroup"
                                    render={({ message }) =>
                                      errorFormMessage(message)
                                    }
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col md="6">
                              <div className="d-flex flex-row pl-2">
                                <div className="mt-5">
                                  <Input
                                    type="checkbox"
                                    checked={enableControl.pickupgroup}
                                    onChange={() =>
                                      handleToggleField('pickupgroup')
                                    }
                                  />
                                </div>
                                <div className="col col-12">
                                  <label className="form-control-label">
                                    Pickup Group
                                  </label>
                                  <Controller
                                    as={Input}
                                    control={control}
                                    disabled={!enableControl.pickupgroup}
                                    name="pickupgroup"
                                    rules={
                                      enableControl.pickupgroup && {
                                        validate: (value) => {
                                          if (
                                            /^([1-9]|[1-5][0-9]|6[0-3])?(,[1-9]|,[1-5][0-9]|,6[0-3]){0,4}$/.test(
                                              value
                                            )
                                          )
                                            return true;
                                          return 'Pickup Group inválido';
                                        }
                                      }
                                    }
                                    defaultValue=""
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name="pickupgroup"
                                    render={({ message }) =>
                                      errorFormMessage(message)
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row className="mb-2" style={{ paddingTop: '15px' }}>
                            <Col md="6">
                              <div className="d-flex flex-row pl-2">
                                <div className="mt-5">
                                  <Input
                                    type="checkbox"
                                    checked={enableControl.call_limit}
                                    onChange={() =>
                                      handleToggleField('call_limit')
                                    }
                                  />
                                </div>
                                <div className="col col-12">
                                  <label className="form-control-label">
                                    Limite de contatos*
                                  </label>
                                  <Controller
                                    as={Input}
                                    type="number"
                                    control={control}
                                    disabled={!enableControl.call_limit}
                                    name="call_limit"
                                    rules={
                                      enableControl.call_limit && {
                                        required: 'Campo obrigatório',
                                        validate: (value) => {
                                          return value > 0 && value < 100
                                            ? true
                                            : 'Limite entre 1 e 99';
                                        }
                                      }
                                    }
                                    defaultValue={1}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name="call_limit"
                                    render={({ message }) =>
                                      errorFormMessage(message)
                                    }
                                  />
                                </div>
                              </div>
                            </Col>

                            <Col className="mt-3 mt-md-4">
                              <Row>
                                <Col sm="12">
                                  <div className="row ma-0 pl-2">
                                    <div className="col col-1 align-middle">
                                      <Input
                                        type="checkbox"
                                        checked={
                                          enableControl.allow_external_call_recording
                                        }
                                        onChange={() =>
                                          handleToggleField(
                                            'allow_external_call_recording'
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col col-11">
                                      <Controller
                                        name="allow_external_call_recording"
                                        control={control}
                                        defaultValue={false}
                                        render={(props) => (
                                          <>
                                            <label className="form-control-labe">
                                              <Input
                                                disabled={
                                                  !enableControl.allow_external_call_recording
                                                }
                                                type="checkbox"
                                                onChange={(e) =>
                                                  props.onChange(
                                                    e.target.checked
                                                  )
                                                }
                                                checked={props.value}
                                              />
                                              Gravar chamadas externas
                                            </label>
                                          </>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="12">
                                  <div className="row ma-0 pl-2">
                                    <div className="col col-1 align-middle">
                                      <Input
                                        type="checkbox"
                                        checked={
                                          enableControl.allow_internal_call_recording
                                        }
                                        onChange={() =>
                                          handleToggleField(
                                            'allow_internal_call_recording'
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col col-11">
                                      <Controller
                                        name="allow_internal_call_recording"
                                        control={control}
                                        defaultValue={false}
                                        render={(props) => (
                                          <>
                                            <label className="form-control-label">
                                              <Input
                                                type="checkbox"
                                                disabled={
                                                  !enableControl.allow_internal_call_recording
                                                }
                                                onChange={(e) =>
                                                  props.onChange(
                                                    e.target.checked
                                                  )
                                                }
                                                checked={props.value}
                                              />
                                              Gravar chamadas internas
                                            </label>
                                          </>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Col>

                                <Col sm="12">
                                  <div className="row ma-0 pl-2">
                                    <div className="col col-1 align-middle">
                                      <Input
                                        type="checkbox"
                                        checked={
                                          enableControl.allow_monitor_extension
                                        }
                                        onChange={() =>
                                          handleToggleField(
                                            'allow_monitor_extension'
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="col col-11">
                                      <Controller
                                        name="allow_monitor_extension"
                                        control={control}
                                        defaultValue={false}
                                        render={(props) => (
                                          <>
                                            <label className="form-control-label">
                                              <Input
                                                type="checkbox"
                                                disabled={
                                                  !enableControl.allow_monitor_extension
                                                }
                                                onChange={(e) =>
                                                  props.onChange(
                                                    e.target.checked
                                                  )
                                                }
                                                checked={props.value}
                                              />
                                              Monitorar
                                            </label>
                                          </>
                                        )}
                                      />
                                    </div>
                                  </div>
                                </Col>

                                {(actionType === 'EDIT_ALL' ||
                                  type === 'PJSIP') && (
                                  <Col sm="12">
                                    <div className="row ma-0 pl-2">
                                      <div className="col col-1 align-middle">
                                        <Input
                                          type="checkbox"
                                          checked={enableControl.nat}
                                          onChange={() =>
                                            handleToggleField('nat')
                                          }
                                        />
                                      </div>
                                      <div className="col col-11">
                                        <Controller
                                          name="nat"
                                          control={control}
                                          defaultValue={false}
                                          render={(props) => (
                                            <>
                                              <label className="form-control-label">
                                                <Input
                                                  type="checkbox"
                                                  disabled={!enableControl.nat}
                                                  onChange={(e) =>
                                                    props.onChange(
                                                      e.target.checked
                                                    )
                                                  }
                                                  checked={props.value}
                                                />
                                                NAT
                                              </label>
                                            </>
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                )}
                              </Row>
                            </Col>
                          </Row>
                        </>
                      )}
                      <div className={type === 'ANALOGICO' ? 'mt-3' : ''}>
                        <Button
                          color="primary"
                          type="submit"
                          disabled={!isSomeChecked()}
                        >
                          Salvar
                        </Button>
                        <Button color="danger" onClick={() => cancelAction()}>
                          Cancelar
                        </Button>
                      </div>
                    </>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EditMultipleForm;
