/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Service from '../service';
import { toast } from 'react-toastify';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'components/Pagination';
import { useHistory } from 'react-router-dom';
import ModalConfirmation from '../../../components/Modal/ConfirmationModal';

import {
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  Input,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

import MultiButtonsHeader from '../../../components/Headers/MultiButtons';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const TrunkList = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [extensions, setExtensions] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [timeoutID, setTimeoutID] = useState(null); // Timeout da busco por texto
  const [idResource, setidResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  useEffect(() => {
    handleGetExtensions();
  }, []);

  const handleGetExtensions = (selectedPage = 1, querySearch = '') => {
    setLoading(true);
    Service.getAll(selectedPage, 10, false, querySearch)
      .then((res) => {
        const { data, totalRegisters } = res.data;
        const newActivePage = selectedPage || activePage;

        setExtensions(data);
        setQuantity(totalRegisters);
        setActivePage(newActivePage);
      })
      .catch(() => {
        toast.error('Não foi possível listar os troncos', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (activePage) => handleGetExtensions(activePage);

  const handleDeleteExtension = (id) => {
    Service.delete(id)
      .then(() => {
        toast.success('Trunco deletado com sucesso', {
          autoClose: 3000,
          closeOnClick: true
        });
        handleGetExtensions();
      })
      .catch((err) => {
        toast.error(err.response.data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        handleGetExtensions();
      });
  };

  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };

  const handleClickButtonCreate = (type, actionType) => {
    history.push(`trunk/form/${String(type).toLowerCase()}`, {
      type,
      actionType
    });
  };

  const handleUpdate = (data) => {
    const id = data.id;
    const types = data.type;
    let type;
    if (types !== 'IAX2' && types !== 'PJSIP' && data.final_ddr) {
      type = 'DIGITAL';
    } else {
      if (types !== 'IAX2' && types !== 'PJSIP' && !data.final_ddr) {
        type = 'ANALOGICO';
      } else {
        type = types;
      }
    }
    history.push(`trunk/form/${String(type).toLowerCase()}/${id}`, {
      actionType: 'UPDATE',
      type
    });
  };

  const handleOnSearch = (event) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const q = event.target.value;
    setTimeoutID(
      setTimeout(() => {
        handleGetExtensions(1, q);
      }, 1000)
    );
  };

  const renderButtonsOnHeader = () => {
    return [
      {
        label: 'Adicionar IAX2',
        customNavigator: () => handleClickButtonCreate('IAX2', 'ADD', null)
      },
      {
        label: 'Adicionar PJSIP',
        customNavigator: () => handleClickButtonCreate('PJSIP', 'ADD', null)
      },
      {
        label: 'Adicionar Tronco Digital',
        customNavigator: () => handleClickButtonCreate('DIGITAL', 'ADD', null)
      },
      {
        label: 'Adicionar Tronco Analógico',
        customNavigator: () => handleClickButtonCreate('ANALOGICO', 'ADD', null)
      }
    ];
  };

  return (
    <>
      <MultiButtonsHeader
        buttons={renderButtonsOnHeader()}
        showArrowReturn={true}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Troncos</h3>
                </CardHeader>
                <Row className="mt-3 mx-2">
                  <Col md="8">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Busca por operadora"
                        type="text"
                        defaultValue=""
                        onChange={handleOnSearch}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <ToolkitProvider
                  data={extensions}
                  keyField="id"
                  loading={loading}
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Operadora',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'key_number',
                      text: 'Número Chave',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },

                    {
                      dataField: 'type',
                      text: 'Tipo',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (type, Row) => {
                        if (
                          type !== 'IAX2' &&
                          type !== 'PJSIP' &&
                          Row.final_ddr
                        ) {
                          return 'DIGITAL';
                        } else {
                          if (
                            type !== 'IAX2' &&
                            type !== 'PJSIP' &&
                            !Row.final_ddr
                          ) {
                            return 'ANALOGICO';
                          } else {
                            return type;
                          }
                        }
                      }
                    },

                    {
                      dataField: 'initial_ddr',
                      text: 'DDR Inicial',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'final_ddr',
                      text: 'DDR Final',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'technology',
                      text: 'Tecnologia',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },

                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            size="sm"
                            color="info"
                            onClick={() => handleUpdate(row)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_header_style,
                      text: 'Deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => controlDelete(row.id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={handleDeleteExtension}
        message={'Tem certeza que deseja excluir o tronco?'}
      />
    </>
  );
};

export default TrunkList;
