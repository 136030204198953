import React, { useCallback, useEffect, useMemo, useState } from 'react';
import io from 'socket.io-client';
import { Container, Row, Card, CardHeader, CardBody } from 'reactstrap';
import { cloneDeep } from 'lodash';
import { toast } from 'react-toastify';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import QueueTable from './components/QueueTable';
import AddAgentModal from './components/AddAgentModal';
import SupervisorPanelService from './service';
import UserService from '../../User/service';
import QueueService from '../../Queue/service';
import PauseService from '../../Pause/service';
import { apiUc } from '../../../http/';
import { supervisorPanelEvents } from './utils/supervisorPanelEvents';

const SupervisorPanel = () => {
  const socket = useMemo(
    () =>
      io(process.env.REACT_APP_PROXY_URL, {
        path: process.env.REACT_APP_AMI_SOCKET_PATH,
        transports: ['websocket'],
        upgrade: false,
        query: { token: localStorage.getItem('token') }
      }),
    []
  );
  const [loggedInAgents, setLoggedInAgents] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [queues, setQueues] = useState([]);
  const [pauses, setPauses] = useState([]);

  const getSupervisorPanelData = useCallback(() => {
    SupervisorPanelService.getSupervisorPanelData()
      .then((response) => {
        setLoggedInAgents(response.data.data || []);
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  const removeQueueMember = useCallback((eventData) => {
    setLoggedInAgents((prev) => {
      const newSessions = cloneDeep(prev);

      newSessions.forEach((session) => {
        if (session.extension === eventData.extension) {
          const remainQueues = session.queues.filter((queue) => {
            return queue.name !== eventData.queueName;
          });

          session.queues = remainQueues;
        }
      });

      return newSessions.filter(
        (session) => session.queues && session.queues.length
      );
    });
  }, []);

  const addQueueMember = useCallback(
    (eventData) => {
      setLoggedInAgents((prev) => {
        const newSessions = cloneDeep(prev);

        const isAlreadyListedInTheTable = newSessions.some(
          (session) => session.extension === eventData.extension
        );

        if (!isAlreadyListedInTheTable) {
          getSupervisorPanelData();
          return prev;
        }

        newSessions.forEach((session) => {
          if (session.extension === eventData.extension) {
            const currenPauseIds = session.pauses.map((p) => p.id);
            const newPauses = eventData.agent.queue.pauses.filter(
              (p) => !currenPauseIds.includes(p.id)
            );

            session.queues.push({
              id: eventData.agent.queue.id,
              name: eventData.queueName,
              penalty: eventData.agent.queue.penalty || 0,
              pauses: [...session.pauses, ...newPauses]
            });
          }
        });

        return newSessions.filter(
          (session) => session.queues && session.queues.length
        );
      });
    },
    [getSupervisorPanelData]
  );

  const handleQueueMemberPause = useCallback((eventData) => {
    setLoggedInAgents((prev) => {
      const newQueueData = cloneDeep(prev);

      newQueueData.forEach((session) => {
        if (session.extension === eventData.extension) {
          session.paused = true;
          session.selectedPauseId =
            eventData.pause_id || eventData.agent.pause_id;
        }
      });

      return newQueueData;
    });
  }, []);

  const handleQueueMemberUnpause = useCallback((eventData) => {
    setLoggedInAgents((prev) => {
      const newQueueData = cloneDeep(prev);

      newQueueData.forEach((session) => {
        if (session.extension === eventData.extension) {
          session.paused = false;
          delete session.selectedPauseId;
        }
      });

      return newQueueData;
    });
  }, []);

  useEffect(() => {
    UserService.getAll()
      .then((response) => {
        setAllUsers(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    QueueService.getAll()
      .then((response) => {
        setQueues(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    PauseService.getPausesByUserPermission()
      .then((response) => {
        setPauses(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    getSupervisorPanelData();
  }, [getSupervisorPanelData]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket Supervisor Panel Connected');
    });

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, [socket]);

  useEffect(() => {
    socket.on(supervisorPanelEvents.QUEUE_MEMBER_REMOVED, (eventData) => {
      removeQueueMember(eventData);
    });
  }, [socket, removeQueueMember]);

  useEffect(() => {
    socket.on(supervisorPanelEvents.QUEUE_MEMBER_ADDED, (eventData) => {
      addQueueMember(eventData);
    });
  }, [socket, addQueueMember]);

  useEffect(() => {
    socket.on(supervisorPanelEvents.QUEUE_MEMBER_PAUSE, (eventData) => {
      handleQueueMemberPause(eventData);
    });
  }, [socket, handleQueueMemberPause]);

  useEffect(() => {
    socket.on(supervisorPanelEvents.QUEUE_MEMBER_AVAILABLE, (eventData) => {
      handleQueueMemberUnpause(eventData);
    });
  }, [socket, handleQueueMemberUnpause]);

  useEffect(() => {
    socket.on(supervisorPanelEvents.QUEUE_MEMBER_UNREGISTERED, (eventData) => {
      handleQueueMemberUnpause(eventData);
    });
  }, [socket, handleQueueMemberUnpause]);

  const changePanalty = useCallback(
    async ({ login, queueName, penalty, extension }) => {
      try {
        await SupervisorPanelService.changePanalty(login, {
          fila: queueName,
          ramal: extension,
          penalty
        });

        toast.success('Penalidade alterada com sucesso', {
          autoClose: 3000,
          closeOnClick: true
        });

        getSupervisorPanelData();
        return true;
      } catch (error) {
        const errorMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          'Ops, ocorreu um erro ao alterar a penalidade!';

        toast.error(errorMessage, {
          autoClose: 3000,
          closeOnClick: true
        });
        return false;
      }
    },
    [getSupervisorPanelData]
  );

  const queueLogOut = async ({ extension, queueIds, agentCode }) => {
    try {
      await SupervisorPanelService.queueLogOut({
        extension,
        queueIds,
        agentCode
      });
    } catch (error) {
      toast.error('Ocorreu um erro ao deslogar da fila.', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const handlePause = useCallback(async ({ agentCode, pause_id }) => {
    try {
      await SupervisorPanelService.handlePause({
        agentCode,
        pause_id
      });
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar pausar o agente.', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  }, []);

  const handleUnpause = useCallback(async ({ agentCode }) => {
    try {
      await SupervisorPanelService.handleUnpause({ agentCode });
    } catch (error) {
      toast.error('Ocorreu um erro ao tentar tirar o agente de pausa.', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  }, []);

  const handleAgentLogin = async (data) => {
    try {
      await apiUc.post('/api/agent-session', data);
    } catch (error) {
      const msg =
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message;

      toast.error(msg || 'Erro ao logar agente');
    }
  };

  return (
    <>
      <SimpleHeader showBackArrow={'not'} />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="d-flex justify-content-between">
                    <h3 className="mb-0">Painel do supervisor</h3>
                    <AddAgentModal
                      buttonLabel="Adicionar Agente"
                      allUsers={allUsers}
                      loggedInAgents={loggedInAgents}
                      queues={queues}
                      handleAgentLogin={handleAgentLogin}
                    />
                  </div>
                </CardHeader>
                <CardBody className="overflow-y-visible">
                  <QueueTable
                    loggedInAgents={loggedInAgents}
                    pauses={pauses}
                    queues={queues}
                    allUsers={allUsers}
                    handlePause={handlePause}
                    handleUnpause={handleUnpause}
                    handleLogout={queueLogOut}
                    handleChangePenalty={changePanalty}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SupervisorPanel;
