import styled from 'styled-components';

export const TableContainer = styled.div`
  display: block;
  overflow-x: scroll;
  padding: 2rem 0.5rem;
  border-bottom: 0;
  ::-webkit-scrollbar {
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #aaa;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
`;
