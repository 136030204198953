import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Col,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover
} from 'reactstrap';

import IntegrationUrlService from '../../views/IntegrationUrl/service';
import ReactLoader from 'components/Loader';
import { IoMdClose } from 'react-icons/io';
import { FaRegLightbulb } from 'react-icons/fa';
import { OutlineTooltip } from 'components/OutlineTooltip';
import ReactButton from 'components/Buttons/ReactButton';

const ThirdSystem = ({ closeDrawer }) => {
  const [integrationUrl, setIntegrationUrl] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const getIntegrationUrlThirdSystem = async () => {
    try {
      const res = await IntegrationUrlService.thirdSystem();

      setIntegrationUrl(res.data.data);
    } catch (error) {
      toast.error('Não foi possivel listar as URLs de integração', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getIntegrationUrlThirdSystem();
  }, []);

  return (
    <>
      {loading ? (
        <Row className="justify-content-md-center">
          <ReactLoader />
        </Row>
      ) : (
        <>
          <div className="d-flex flex-row justify-content-between align-items-center">
            <h1 className="mb-0 h2">Tela de Sistemas</h1>
            <IoMdClose
              size={25}
              cursor="pointer"
              className="close"
              id="PopoverClick"
            />
            <UncontrolledPopover
              trigger="legacy"
              placement="bottom"
              target="PopoverClick"
            >
              <PopoverHeader>
                <div className="d-flex justify-content-between align-items-center">
                  <span>Tem certeza?</span>
                  <div>
                    <FaRegLightbulb
                      cursor="help"
                      href="#"
                      id="UncontrolledTooltipExample"
                    />
                    <OutlineTooltip
                      placement="right"
                      target="UncontrolledTooltipExample"
                    >
                      <span className="small">
                        Dica: se precisar de mais espaço na tela você pode
                        minimizar esta janela clicando na aba lateral "Dados do
                        Atendimento".
                      </span>
                    </OutlineTooltip>
                  </div>
                </div>
              </PopoverHeader>
              <PopoverBody>
                Ao fechar a janela os dados atuais não poderão mais ser
                visualizados.
                <div className="mt-2 d-flex">
                  <ReactButton
                    type="button"
                    btnColor="confirmation"
                    onClick={closeDrawer}
                    size="sm"
                    block
                  >
                    Fechar mesmo assim
                  </ReactButton>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
          <Row
            className="mb-3 mt-0"
            style={{
              height: 'calc(100% - 30px - 16px)'
            }}
          >
            <Col
              sm="12"
              style={{
                height: '100%'
              }}
            >
              <iframe
                allow=""
                className="external-link"
                src={integrationUrl?.url}
                frameBorder="0"
                style={{
                  width: '100%',
                  height: '100%'
                }}
              />
            </Col>
            <Col
              sm="12"
              style={{
                height: '0%',
                padding: '0'
              }}
            >
              <iframe
                allow=""
                className="external-link"
                frameBorder="0"
                style={{
                  height: '0'
                }}
              />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default ThirdSystem;
