import React, { useCallback, useState } from 'react';
import { Card } from '../../../../../../Opportunity/list/components/OpportunitiesList/components/Kanban/styles';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import LeadHistoryModal from '../../../LeadHistoryModal';
import LeadActionsModal from '../../../LeadActionsModal';
import ModalConfirmation from 'components/Modal/ConfirmationModal';
import LeadService from '../../../../../services/leadService';
import { toast } from 'react-toastify';
import { verifyRoles } from 'components/Authentication/VerifyRoles';

export const InfoCardLead = ({ data: info, dragStart, dragEnter }) => {
  const cardProps = info.data.array[info.index];
  const history = useHistory();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const removeItem = () => {
    info.data.setValuesList((prev) => {
      const copyList = { ...prev };
      const index = copyList[info.data.list].findIndex((item) => {
        return item.id === cardProps.id;
      });
      copyList[info.data.list].splice(index, 1);
      return copyList;
    });
  };

  const handleDeleteLead = useCallback((id) => {
    LeadService.delete(id)
      .then(() => {
        removeItem();
      })
      .catch((err) => {
        const message =
          err?.response?.data?.message || 'Erro ao deletar o lead';
        toast.error(message);
      })
      .finally(() => {
        setOpenModalConfirmation(false);
      });
  }, []);

  return (
    <Card
      style={{
        ...info.style,
        color: 'black',
        justifyContent: 'center',
        border: cardProps.dated && !cardProps.isConverted ? '2px solid red' : ''
      }}
      onDragStart={(e) => dragStart(e, info.index, info.data.list)}
      onDragEnter={(e) => dragEnter(e, info.index)}
      draggable={true}
      key={cardProps?.id}
      id={'draggable' + cardProps?.id}
    >
      <div className="card-padding-before">
        <div title={cardProps.title ?? cardProps.name} className="CardContent">
          <p>{cardProps.title ?? cardProps.name}</p>
          <span>{cardProps.responsibleName}</span>
          <div>
            {cardProps.dated && !cardProps.isConverted ? (
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 'lighter',
                  color: 'red',
                  marginTop: '28px'
                }}
              >
                Vencido há {cardProps.daysDated} dia(s)
              </div>
            ) : (
              ''
            )}
            {!cardProps.isConverted ? (
              <div
                style={{
                  fontSize: '12px',
                  fontWeight: 'lighter',
                  color: 'black'
                }}
              >
                Há {cardProps.daysInStage} dia(s) na fase
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="button-container">
          {!cardProps?.isConverted ? (
            <>
              <Button
                title="Editar"
                onClick={() => {
                  history.push(
                    `/admin/crm/leadEdit/${cardProps.recordTypeId}/${cardProps.id}`
                  );
                }}
                size="sm"
                color="info"
              >
                <i className="fas fa-user-edit"></i>
              </Button>

              <LeadHistoryModal leadId={cardProps.id} style={{ scale: 0.9 }} />

              <ModalConfirmation
                isModalOpen={openModalConfirmation}
                onModalToggle={setOpenModalConfirmation}
                unmountOnClose={true}
                idResource={cardProps.id}
                deleteResource={handleDeleteLead}
                message={'Tem certeza que deseja excluir o lead?'}
              />

              <LeadActionsModal leadData={cardProps} />

              {verifyRoles(['DELETE_LEAD_CRM']) && (
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => setOpenModalConfirmation(true)}
                  style={{ marginTop: '-25px' }}
                >
                  <li className="fas fa-trash"></li>
                </Button>
              )}
            </>
          ) : (
            <>
              <LeadHistoryModal leadId={cardProps.id} />
              <LeadActionsModal leadData={cardProps} />
            </>
          )}
        </div>
      </div>
    </Card>
  );
};
