import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../../store/action/index';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';

const ActiveLogout = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
  });

  toast.info('Você foi desconectado!');
  return <Redirect to="/" />;
};

export default ActiveLogout;
