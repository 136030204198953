import React, { useState, useCallback, useEffect } from 'react';
import { Row } from 'reactstrap';

import Loader from 'components/Loader';
import { toast } from 'react-toastify';

import Pagination from 'components/Pagination';
import { Analytical } from '../../../components/Tables/Analytical';
import { useListLeads } from '../../../../Lead/hooks/useListLeads';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import FilterLeadsClosedWonForm from '../FilterLeadsClosedWonForm';

const ReportLeadDataClosedWon = ({
  selectedRecordTypeId,
  setSelectedRecordType,
  recordTypes
}) => {
  const [activePage, setActivePage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [analyticalData, setAnalyticalData] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [dataToSend, setDataToSend] = useState({});
  const [reportType, setReportType] = useState('');

  const { getReportLeads, leads, totalLeads } = useListLeads({
    recordTypeId: selectedRecordTypeId
  });

  const { customFields, getCustomFields } = useEntityCustomFieldsByRecordType(
    entitiesName.LEAD,
    selectedRecordTypeId
  );

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  const handleSearch = useCallback(
    (searchFilters, page = 1) => {
      setActivePage(1);
      getReportLeads(page, searchFilters);
      setIsLoading(false);
      setReportType(searchFilters.typeExportReport);
    },
    [getReportLeads]
  );

  useEffect(() => {
    if (leads && leads.length) {
      setAnalyticalData(leads);
      setQuantity(totalLeads);
    }

    if (leads && !leads.length) {
      setAnalyticalData(leads);
      setQuantity(totalLeads);
      toast.info('Nenhum registro foi encontrado');
    }
  }, [leads, totalLeads]);

  const handlePageChange = (page) => {
    setActivePage(page);
    getReportLeads(page, dataToSend);
  };

  return (
    <>
      <FilterLeadsClosedWonForm
        handleSearch={handleSearch}
        recordTypeId={selectedRecordTypeId}
        leads={leads}
        setSelectedRecordType={setSelectedRecordType}
        recordTypes={recordTypes}
        analyticalData={analyticalData}
        setAnalyticalData={setAnalyticalData}
        quantity={quantity}
        setDataToSend={setDataToSend}
      />

      {analyticalData && analyticalData.length > 0 ? (
        isLoading ? (
          <div className="col-12">
            <Row className="justify-content-md-center">
              <Loader />
            </Row>
          </div>
        ) : (
          <>
            <Analytical
              data={analyticalData}
              customFields={customFields}
              reportType={reportType}
            />
            <Pagination
              activePage={activePage}
              totalItemsCount={quantity}
              onChange={handlePageChange.bind(this)}
            />
          </>
        )
      ) : null}
    </>
  );
};

export default ReportLeadDataClosedWon;
