import React from 'react';
import { useSelector } from 'react-redux';
import { TitlePreview } from './styled';

export function PreviewFile({ file }) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  return (
    <TitlePreview
      haveBorderTop={true}
      colorText={systemConfiguration.primary_color}
    >
      <h4 className="display-6">{file.name}</h4>
    </TitlePreview>
  );
}
