import styled from 'styled-components';

export const AgentIndicatorCardContainer = styled.div`
  margin-bottom: 1.875rem;
  display: flex;
`;

export const AgentIndicatorCardIconWrapper = styled.div`
  border-radius: 0.375rem;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ bgColor }) => bgColor};
`;

export const AgentIndicatorCardDetailBtn = styled.button`
  margin-left: 0.5rem;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ebebeb;
`;
