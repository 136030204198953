import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import QueueMessageService from '../service';
import QueueService from '../../Queue/service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import FlexSelect from 'components/Inputs/FlexSelect';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const atendenteOptions = [{ label: 'Atendente', value: 'Atendente' }];

const responseTypeOptions = [
  { label: 'Imagem', value: 'imagem' },
  { label: 'Texto', value: 'texto' }
];

const QueueMessageForm = (props) => {
  const history = useHistory();
  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [queues, setQueues] = useState([]);
  const [loading, setLoading] = useState(false);

  const { id } = props.match.params;

  useEffect(() => {
    setLoading(true);
    QueueService.getAll()
      .then((response) => {
        setQueues(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      QueueMessageService.get(id)
        .then((res) => {
          const getQueueMessage = res.data;
          reset(getQueueMessage);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch((err) => {
          //const { data } = err.response;
          toast.error(
            err.response.data.message || 'Erro ao carregar mensagem!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
          setLoading(false);
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      const formatedData = { ...data };
      isUpdate
        ? await QueueMessageService.update(id, formatedData)
        : await QueueMessageService.create(formatedData);
      history.push('/admin/queuemessage');
      toast.success(
        isUpdate
          ? 'Mensagem de fila alterada com sucesso!'
          : 'Mensagem de fila cadastrada com sucesso!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    } catch (err) {
      toast.error(err.response?.data?.message || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/queuemessage');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/queuemessage"
        buttonTitle="Voltar para a pagina de mensagem da fila"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Mensagem de fila</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Escrita por*
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="escrita_por"
                            rules={{ required: 'Escrita por é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="escrita_por"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Cliente ou Atendente*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={atendenteOptions}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="cliente_ou_atendente"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="cliente_ou_atendente"
                            rules={{
                              required: 'Cliente ou Atendente é obrigatório!'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="cliente_ou_atendente"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Response Type*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={responseTypeOptions}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="response_type"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="response_type"
                            rules={{ required: 'Response Type é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="response_type"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Título*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="title"
                            rules={{ required: 'Título é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="title"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Descrição*
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="description"
                            rules={{ required: 'Descrição é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="description"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Source*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="source"
                            rules={{ required: 'Source é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="source"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Fila(s)</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={queues}
                                isMulti={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="filas"
                                labelName="name"
                                valueName="name"
                              />
                            )}
                            control={control}
                            name="filas"
                            defaultValue=""
                          />
                        </Col>

                        <Col md="6">
                          <label className="form-control-label">Texto</label>
                          <Controller
                            as={
                              <Input type="textarea" name="texto" id="texto" />
                            }
                            control={control}
                            name="texto"
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <hr />

                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default QueueMessageForm;
