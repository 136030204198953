import { apiCrm } from '../../../../http';

class LeadProcessService {
  async list(params, headers) {
    return apiCrm.get(`/lead-process`, {
      params,
      headers
    });
  }

  async create(data) {
    return apiCrm.post(`/lead-process`, data);
  }

  async getById(id) {
    return apiCrm.get(`/lead-process/${id}`);
  }

  async update(id, data) {
    return apiCrm.put(`/lead-process/${id}`, data);
  }

  async delete(id) {
    return apiCrm.delete(`/lead-process/${id}`);
  }
}

export default new LeadProcessService();
