import styled from 'styled-components';

export const HeatMapContainer = styled.div`
  width: 35em;
  height: 550px;

  position: relative;

  margin-bottom: 2rem;

  svg {
    scroll-behavior: inherit;

    transform: scaleX(1.15);
    /* Posição do mapa dentro do container */
    path,
    text {
      transform: translateX(460px) translateY(-160px);
    }
  }

  @media (max-width: 576px) {
    svg {
      scroll-behavior: inherit;

      transform: scale(0.8);
      /* Posição do mapa dentro do container */
      path,
      text {
        transform: translateX(420px) translateY(-160px);
      }
    }
  }

  @media only screen and (max-width: 420px) {
    svg {
      scroll-behavior: inherit;

      transform: scale(0.8);
      /* Posição do mapa dentro do container */
      path,
      text {
        transform: translateX(410px) translateY(-160px);
      }
    }
  }
`;

export const HeatmapTable = styled.table`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-collapse: collapse;

  /* @media (max-width: 800px) {
    flex-direction: row;
  } */

  overflow-x: scroll;
  overflow-y: hidden;

  /* width */
  ::-webkit-scrollbar {
    width: 8px;
    height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    border: 1px solid #ddd;
    border-radius: 10px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ddd;
    border-radius: 10px;
  }

  tr {
    th {
      background-color: rgba(52, 135, 197);
      color: white;
      width: min-content;
    }
  }

  td,
  th {
    border: 1px solid #ddd;
    min-width: 40px;
  }
  th {
    min-width: 64px;
  }
  td {
    width: 12px;
    padding: 2px 2px;
    text-align: center;

    font-size: 14px;
  }
`;

export const HeatmapLegend = styled.div`
  /* position: absolute; */
  /* transform: translateX(0) translateY(-400px); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  margin-top: 2rem;

  border: 1px solid #ddd;
  border-radius: 0.25rem;

  ul {
    list-style: none;
    padding: 0 1.5rem;
    margin: 0;
  }
`;

export const HeatmapLegendItem = styled.div`
  display: inline-block;
  width: 1rem;
  height: 1rem;
  margin-right: 1rem;
  border-radius: 50%;

  border: 1.5px solid #ddd;
`;
