import React from 'react';

import { Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import CountUp from 'react-countup';
import { ColorArea } from './assets/styleds';
import { Pie } from 'react-chartjs-2';
import { chartExample5 } from 'variables/charts.js';
const Search = ({ filteredLabels, filteredData, filteredColors, count }) => {
  return (
    <>
      {filteredData && (
        <Row>
          <Col xl="12">
            <Card>
              <CardHeader>
                <h6 className="surtitle">Visão Geral</h6>
                <h5 className="h3 mb-0">Conversas por fila</h5>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4" style={{ alignSelf: 'center' }}>
                    <div className="chart">
                      <Pie
                        data={{
                          labels: [...filteredLabels],
                          datasets: [
                            {
                              data: [...filteredData],
                              backgroundColor: [...filteredColors],
                              label: 'Total de conversas no mês'
                            }
                          ]
                        }}
                        options={chartExample5.options}
                        className="chart-canvas"
                        id="chart-doughnut"
                      />
                    </div>
                  </Col>
                  <Col md="8" style={{ alignSelf: 'center' }}>
                    <Row>
                      <Col md="12">
                        <h1 className="display-1">
                          <CountUp end={count} />
                        </h1>
                        <h2 className="text-muted font-weight-light mt--3">
                          Total de conversas no mês
                        </h2>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      {filteredData.map((data, index) => {
                        return (
                          <Col
                            md="2"
                            key={index}
                            className="mt--3 mb--3"
                            style={{
                              minWidth: '177px',
                              minHeight: '130px'
                            }}
                          >
                            <Row
                              className="row flex-nowrap"
                              style={{
                                minHeight: '100px'
                              }}
                            >
                              <Col
                                md="1"
                                style={{
                                  padding: 0
                                }}
                              >
                                <ColorArea color={filteredColors[index]} />
                              </Col>
                              <Col md="11">
                                <h2>{filteredData[index]}</h2>
                                <h3 className="text-muted font-weight-light mt--3">
                                  {filteredLabels[index] === ''
                                    ? '-'
                                    : filteredLabels[index]}
                                </h3>
                                <h2
                                  className="mt--1 font-weight-light"
                                  style={{
                                    paddingBottom: '1.5625rem'
                                  }}
                                >
                                  {`${parseFloat(
                                    (100 * filteredData[index]) / count
                                  ).toFixed(2)}%`}
                                </h2>
                              </Col>
                            </Row>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Search;
