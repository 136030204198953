import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import Service from '../service';
import { toast } from 'react-toastify';
// import { ddrRegex } from './regexs';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const SectorForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);

  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      setLoading(true);
      Service.get(id)
        .then((res) => {
          const getSector = res.data.data;
          reset(getSector);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar Contexto', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      isUpdate ? await Service.update(id, data) : await Service.create(data);
      history.push('/admin/context');
      if (isUpdate) {
        toast.info('Contexto foi atualizado com sucesso!');
      } else {
        toast.success('Contexto foi cadastrado com sucesso!');
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/context');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/context');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/context"
        buttonTitle="Voltar para a pagina de contextos"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      ;
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Contexto</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Name*</label>

                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{
                              required: 'nome é um campo obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md={{ size: 6 }}>
                          <label className="form-control-label">
                            Descrição*
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="description"
                            rules={{
                              required: 'Descrição é um campo obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="description"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Tipo*</label>
                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione</option>
                                <option value="ENTRADA">ENTRADA</option>
                                <option value="SAIDA">SAIDA</option>
                              </Input>
                            }
                            control={control}
                            name="type"
                            rules={{
                              required: 'Selecione um tipo'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="type"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SectorForm;
