import React from 'react';

import { ButtonChip } from './style';

export const ChipComponent = ({
  name,
  color,
  handleClickChip,
  isActive,
  colorTagSelected
}) => {
  return (
    <ButtonChip
      onClick={handleClickChip}
      type="button"
      color={color}
      colorTagSelected={colorTagSelected}
      isActive={isActive}
    >
      {name}
    </ButtonChip>
  );
};
