import React from 'react';
import { useGetUsers } from '../../../hooks/useGetUsers';
import { useToISOFormat } from '../../../hooks/useToISOFormat';
import { typesOfLogouts } from '../../../utils/types';
import { dateRules } from '../../../assets/rulesValidation';
import { getDateBegin } from '../../../assets/getDateBegin';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import FlexSelect from 'components/Inputs/FlexSelect';
import DatePicker from 'components/Inputs/DatePicker.js';
import { ErrorFormMessage } from '../ErrorFormMessage';
import { ErrorMessage } from '@hookform/error-message';
import { Row, Form, Col, Button } from 'reactstrap';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import csvImage from '../../../../../../assets/img/csv.png';
import pdfImage from '../../../../../../assets/img/pdf.png';
import { Tooltip } from '@material-ui/core';
import {
  ImageButton,
  ImageSize,
  ImageButtonConteinerProtocol
} from '../../../../../../assets/styles/image_style';

export function SendingForm({
  onSubmit,
  ReportTypeComponent,
  downloadMedia,
  reportType,
  quantity,
  filters = {},
  usersShowDisable,
}) {
  const { users } = useGetUsers(usersShowDisable);
  const { toISOFormat } = useToISOFormat();
  const { control, handleSubmit, setValue, getValues, errors } = useForm();

  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  return (
    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
      <Row className="mb-3">
        <Col md="12">
          <label className="form-control-label">Usuário</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={users}
                isMulti={false}
                isClearable={true}
                value={props.value}
                closeMenuOnSelect={true}
                valueController={setValue}
                fieldName="usrLogin"
                labelName="name"
                valueName="login"
              />
            )}
            control={control}
            name="usrLogin"
            defaultValue=""
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md="4">
          <label className="form-control-label">Data inicial*</label>
          <Controller
            render={(props) => (
              <DatePicker
                fieldName="dateBegin"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateBegin"
            rules={dateRules}
            defaultValue={getDateBegin()}
          />
          <ErrorMessage
            errors={errors}
            name="dateBegin"
            render={({ message }) => <ErrorFormMessage message={message} />}
          />
        </Col>
        <Col md="4">
          <label className="form-control-label">Data final*</label>
          <Controller
            render={(props) => (
              <DatePicker
                fieldName="dateEnd"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateEnd"
            defaultValue={new Date().toLocaleDateString('pt-br')}
            rules={{
              ...dateRules,
              validate: async (value) => {
                if (
                  new Date(await toISOFormat(value, true)) <
                  new Date(await toISOFormat(getValues()['dateBegin'], true))
                ) {
                  return 'A data final não pode ser anterior à inicial!';
                } else {
                  return true;
                }
              }
            }}
          />
          <ErrorMessage
            errors={errors}
            name="dateEnd"
            render={({ message }) => <ErrorFormMessage message={message} />}
          />
        </Col>

        <Col md="4">
          <label className="form-control-label">Tipo de Logout</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={typesOfLogouts}
                isMulti={false}
                isClearable={true}
                value={props.value}
                valueController={setValue}
                closeMenuOnSelect={true}
                fieldName="logoutType"
                labelName="label"
                valueName="value"
              />
            )}
            control={control}
            name="logoutType"
            defaultValue=""
          />
        </Col>
      </Row>
      {ReportTypeComponent()}
      <Row className="mb-3">
        <Col>
          <div className="mt-3" style={ImageButtonConteinerProtocol}>
            <Button style={buttonStyle(systemConfiguration.primary_color)}>
              Buscar
            </Button>
            <div>
              <button
                type="button"
                disabled={(quantity < 1) | !Object.keys(filters).length}
                style={ImageButton}
                onClick={() => downloadMedia('pdf', reportType)}
              >
                <Tooltip
                  title={
                    <p style={{ fontSize: '14px' }}>Clique para baixar o PDF</p>
                  }
                  arrow
                >
                  <img src={pdfImage} alt="pdf" style={ImageSize} />
                </Tooltip>
              </button>
              <button
                type="button"
                disabled={(quantity < 1) | !Object.keys(filters).length}
                style={ImageButton}
                onClick={() => downloadMedia('csv', reportType)}
              >
                <Tooltip
                  title={
                    <p style={{ fontSize: '14px' }}>Clique para baixar o CSV</p>
                  }
                  arrow
                >
                  <img src={csvImage} alt="csv" style={ImageSize} />
                </Tooltip>
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </Form>
  );
}
