import React, { useState } from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  FormGroup,
  ModalBody
} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';

import FlexSelect from '../../../../../components/Inputs/FlexSelect';
import { ErrorMessage } from '@hookform/error-message';

const ChangePenaltyModal = ({ agent, handleChangePenalty, ...props }) => {
  const { buttonLabel, className } = props;
  const { control, handleSubmit, setValue, register, errors } = useForm();

  const [modal, setModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggle = () => setModal(!modal);

  const onSubmit = async (data) => {
    setIsLoading(true);

    await handleChangePenalty({
      login: agent.login,
      queueName: data.queueName,
      penalty: data.penalty,
      extension: agent.extension
    });

    setIsLoading(false);
    setModal(false);
  };

  const setPenaltyFromCurrentQueue = (name) => {
    const currentPenalty = agent.queues.find((q) => q.name === name).penalty;
    setValue('penalty', currentPenalty);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <div>
      <Button
        type="button"
        color="primary"
        size="sm"
        onClick={toggle}
        disabled={props.loading}
      >
        {buttonLabel}
      </Button>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalBody>
          <h3>Atualizar Penalidade - {agent.user}</h3>
          <hr />
          <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Col>
                <label htmlFor="queueNameChangePenaltyForm">Fila</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      closeMenuOnSelect={true}
                      isClearable={false}
                      dataOptions={agent.queues}
                      placeholder="Selecione a fila"
                      isMulti={false}
                      value={props.value}
                      valueController={(key, value) => {
                        setValue(key, value);
                        setPenaltyFromCurrentQueue(value);
                      }}
                      fieldName="queueName"
                      labelName="name"
                      valueName="name"
                      isDisabled={isLoading}
                    />
                  )}
                  rules={{ required: 'Selecione a fila' }}
                  control={control}
                  name="queueName"
                  defaultValue=""
                  id="queueNameChangePenaltyForm"
                />
                <ErrorMessage
                  errors={errors}
                  name="queueName"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>
            <Row form>
              <Col>
                <label htmlFor="queueNameChangePenaltyForm">Penalidade</label>
                <FormGroup>
                  <input
                    ref={register({
                      required: 'Defina uma penalidade',
                      min: {
                        value: 0,
                        message: 'Penalidade deve ser maior do que 0'
                      },
                      max: {
                        value: 999,
                        message: 'Penalidade deve ser menor do que 999'
                      }
                    })}
                    name="penalty"
                    type="number"
                    className="form-control"
                    defaultValue={0}
                    disabled={isLoading}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="penalty"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row form>
              <Col md={12} className="text-right">
                <FormGroup>
                  <Button type="submit" color="primary" disabled={isLoading}>
                    Alterar
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangePenaltyModal;
