import React from 'react';
import EditModal from 'views/Report/Chat/Conversation/assets/components/EditModal';
import ConversationDetails from '../../../../../../../../components/Conversation/ConversationDetails';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style,
  icon_column_style,
  individual_icon_header_style
} from '../../../../../../../../assets/styles/table_custom_style';

export function Table({ history }) {
  return (
    <>
      <Row className="mt--3">
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <ToolkitProvider
                data={history}
                keyField="_id"
                columns={[
                  {
                    dataField: 'atendente.name',
                    text: 'Atendente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'hora_criacao',
                    text: 'Data de encerramento',
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (_, row) => {
                      return (
                        <span>
                          {new Date(row.hora_fim_conversa).toLocaleDateString(
                            'pt-br'
                          )}
                        </span>
                      );
                    }
                  },
                  {
                    dataField: 'actions',
                    text: 'Ações',
                    sort: true,
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (rowContent, row) => {
                      return (
                        <>
                          <ConversationDetails conversationData={row} />
                          <EditModal conversationData={row} />
                        </>
                      );
                    }
                  }
                ]}
                hora_criacao
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
}
