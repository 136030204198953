export const findPath = (obj, name, val, currentPath) => {
  currentPath = currentPath || '';

  let matchingPath;

  if (!obj || typeof obj !== 'object') return;

  if (obj[name] === val) return `${currentPath}['${name}']`;

  for (const key of Object.keys(obj)) {
    if (key === name && obj[key] === val) {
      matchingPath = currentPath;
    } else {
      matchingPath = findPath(obj[key], name, val, `${currentPath}['${key}']`);
    }

    if (matchingPath) break;
  }

  return matchingPath;
};

export const formatPath = (path, typeNodeAction) => {
  const pathArray = path.split('[');
  const pathArrayFormatted = pathArray.map((item) => {
    let itemFormatted = item.replace(/'/g, '').replace(']', '').trim();
    if (!isNaN(parseInt(itemFormatted))) {
      itemFormatted = parseInt(itemFormatted);
    } else if (!isNaN(+itemFormatted)) {
      itemFormatted = +itemFormatted;
    }
    return itemFormatted;
  });
  const pathArrayWithoutFirstElement = pathArrayFormatted.slice(1);
  let pathArrayWithoutLastElement = pathArrayWithoutFirstElement.slice(0, -1);
  if (typeNodeAction === 'ADD_NEW_NODE') {
    pathArrayWithoutLastElement = pathArrayWithoutLastElement.slice(0, -1);
  }
  const formattedArray = formatArray(pathArrayWithoutLastElement);
  formattedArray[formattedArray.length - 1].replace('.', '');
  if (typeNodeAction !== 'ADD_NEW_NODE') {
    formattedArray[formattedArray.length - 1] = formattedArray[
      formattedArray.length - 1
    ].slice(0, -1);
  }
  const pathString = formattedArray.join('');
  return pathString;
};

export const loopTreeData = (data, callback) => {
  Object.keys(data).map((key) => {
    if (data[key].nodes) {
      loopTreeData(data[key].nodes, callback);
    }
    callback(data[key]);
  });
};

const formatArray = (arr) => {
  return arr.reduce((acc, curr) => {
    if (typeof curr === 'number') {
      acc.push(`[${curr}].`);
    } else {
      acc[acc.length - 1] += `${curr}`;
    }
    return acc;
  }, []);
};
