import React, { useEffect, useState } from 'react';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { TotalRegister } from '../../../../../components/TotalRegister';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';

const queueColumn = {
  dataField: 'nameQueue',
  text: 'Fila',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

const dateColumn = {
  dataField: 'dateFormatted',
  text: 'Data',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

const intervalColumn = {
  dataField: 'interval',
  text: 'Intervalo ',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

const agentColumn = {
  dataField: 'nameAgent',
  text: 'Agente',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

const teamColumn = {
  dataField: 'nameTeam',
  text: 'Equipe',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

const weekColumn = {
  dataField: 'weekDay',
  text: 'Dia da semana',
  sort: true,
  style: individual_column_style,
  headerStyle: individual_header_style
};

const DetailedSynthetic = ({ dataToSend, dataReport, totalRecords }) => {
  const [variableColumns, setVariableColumns] = useState([
    queueColumn,
    agentColumn,
    dateColumn,
    intervalColumn,
    weekColumn
  ]);

  useEffect(() => {
    let arrayGroupColumns = [];
    if (dataToSend.groupby) {
      dataToSend.groupby.split(',').forEach((el) => {
        switch (el) {
          case 'nomeEquipe':
            arrayGroupColumns.push(teamColumn);
            break;
          case 'agente':
            arrayGroupColumns.push(agentColumn);
            break;
          case 'data':
            arrayGroupColumns.push(dateColumn);
            break;
          case 'fila':
            arrayGroupColumns.push(queueColumn);
            break;
          case 'intervalo':
            arrayGroupColumns.push(intervalColumn);
            break;
          case 'diaDaSemana':
            arrayGroupColumns.push(weekColumn);
            break;
          default:
            break;
        }
      });
      setVariableColumns([...arrayGroupColumns]);
    }
  }, [dataToSend]);

  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            {totalRecords >= 0 && (
              <TotalRegister totalRegister={totalRecords} />
            )}
            <Card>
              <ToolkitProvider
                data={dataReport.listCallDetailedSyntheticCallCenterDTO || []}
                keyField="uuid"
                columns={[
                  ...variableColumns,
                  {
                    dataField: 'answered',
                    text: 'Chamadas Atendidas',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'abandoned',
                    text: 'Chamadas Abandonadas',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'total',
                    text: 'Total',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tme',
                    text: 'Tempo médio de espera',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tma',
                    text: 'Tempo médio de atendimento',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tta',
                    text: 'Tempo total de atendimento',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'serviceLevel',
                    text: 'Nivel de Serviço (%)',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'percentageAnswered',
                    text: 'Chamadas Atendidas (%)',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'percentageAbandoned',
                    text: 'Chamadas Abandonadas (%) ',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default DetailedSynthetic;
