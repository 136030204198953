import { apiChat as http } from '../../../../../http';
class PauseService {
  enterToPause(data) {
    return http.post(`relatoriopausa/pausa/iniciar`, data);
  }

  exitToPause(idUser) {
    return http.put(`/relatoriopausa/pausa/encerrar/${idUser}`);
  }
}

export default new PauseService();
