import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import SectorService from '../service';
import { toast } from 'react-toastify';
import { ddrRegex } from './regexs';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const SectorForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);

  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      setLoading(true);
      SectorService.get(id)
        .then((res) => {
          const getSector = res.data.data;
          reset(getSector);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar Ddr', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      setLoading(true);
      isUpdate
        ? await SectorService.update(id, data)
        : await SectorService.create(data);
      history.push('/admin/ddr');
      if (isUpdate) {
        // setLoading(false);
        toast.info('Ddr foi atualizado com sucesso!');
      } else {
        // setLoading(false);
        toast.success('Ddr foi cadastrado com sucesso!');
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/ddr');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/ddr');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/ddr"
        buttonTitle="Voltar para a pagina de DDRs"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      ;
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">DDR</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">DDR*</label>

                          <Controller
                            as={Input}
                            control={control}
                            name="ddr"
                            rules={{
                              required: 'Ddr é um campo obrigatório',
                              pattern: {
                                value: ddrRegex,
                                message: 'DDR deve possuir 4, 8 ou 10 números'
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="ddr"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md={{ size: 6 }}>
                          <label className="form-control-label">
                            Descrição*
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="description"
                            rules={{
                              required: 'Descrição é um campo obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="description"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SectorForm;
