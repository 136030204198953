import React from 'react';
import { HiPhoneOutgoing } from 'react-icons/hi';

import { useModal } from '../../hooks/useModal';

import AgentIndicatorCard from '../AgentIndicatorCard';
import CallMadeReportModal from '../CallMadeReportModal';

const TotalCallsMadeCard = ({ data }) => {
  const { isModalOpen, toggleModal, handleModalToggle } = useModal();

  return (
    <>
      <AgentIndicatorCard icon={HiPhoneOutgoing}>
        <AgentIndicatorCard.Header>
          <AgentIndicatorCard.Title>
            Chamadas realizadas
          </AgentIndicatorCard.Title>
          <AgentIndicatorCard.DetailBtn onClick={toggleModal} />
        </AgentIndicatorCard.Header>
        <AgentIndicatorCard.Body value={data} />
      </AgentIndicatorCard>
      <CallMadeReportModal
        isModalOpen={isModalOpen}
        onModalToggle={handleModalToggle}
      />
    </>
  );
};

export default React.memo(TotalCallsMadeCard);
