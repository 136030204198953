import { useCallback } from 'react';
import LeadService from '../../../services/leadService';
import pngIcon from 'assets/img/icons/fileExtensions/png.png';
import pdfIcon from 'assets/img/icons/fileExtensions/pdf.png';
import docIcon from 'assets/img/icons/fileExtensions/doc.png';
import docxIcon from 'assets/img/icons/fileExtensions/docx.png';
import jpgIcon from 'assets/img/icons/fileExtensions/jpg.png';
import jpegIcon from 'assets/img/icons/fileExtensions/jpeg.png';
import genericIcon from 'assets/img/icons/fileExtensions/generic.png';

const fileExtensions = new Map([
  ['png', pngIcon],
  ['pdf', pdfIcon],
  ['doc', docIcon],
  ['docx', docxIcon],
  ['jpg', jpgIcon],
  ['jpeg', jpegIcon]
]);

const getIconFromExtension = (extension) => {
  return fileExtensions.get(extension) || genericIcon;
};

export const useLeadAttachments = (leadId) => {
  const getFileURL = useCallback((path) => {
    const url = `${process.env.REACT_APP_PROXY_URL}${path}`;
    return url;
  }, []);

  const removeAttachmentFromLead = useCallback(
    async (attachmentId) => {
      try {
        await LeadService.removeAttachment(leadId, attachmentId);
        return true;
      } catch (error) {
        return false;
      }
    },
    [leadId]
  );

  const addAttachmentsToLead = useCallback(
    async (formData) => {
      const data = await LeadService.addAttachments(leadId, formData);
      return data.data.data;
    },
    [leadId]
  );

  const getFileExtensionFromName = useCallback((fileName) => {
    return fileName.split('.').pop();
  }, []);

  const getFileNameFormatted = useCallback((fileName = '') => {
    return fileName.substring(13);
  }, []);

  const getIconFromFileExtension = useCallback(
    (fileName) => {
      const extension = getFileExtensionFromName(fileName);
      return getIconFromExtension(extension);
    },
    [getFileExtensionFromName]
  );

  return {
    getFileURL,
    removeAttachmentFromLead,
    addAttachmentsToLead,
    getFileExtensionFromName,
    getIconFromFileExtension,
    getFileNameFormatted
  };
};
