export const rates = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 }
];

export const typesConfig = [
  { value: 'nps', label: 'NPS' },
  { value: 'satisfacao', label: 'SATISFAÇÃO' }
];

export const satisfactionDefaultLabelByScore = new Map([
  [1, 'Atendimento Péssimo.😡'],
  [2, 'Atendimento Ruim.'],
  [3, 'Atendimento Mediano.'],
  [4, 'Atendimento Bom.'],
  [5, 'Atendimento Excelente. 😃']
]);

export const NPSDefaultLabelByScore = new Map([
  [1, 'Péssimo'],
  [2, 'Muito Ruim'],
  [3, 'Ruim'],
  [4, 'Insuficiente'],
  [5, 'Mediano'],
  [6, 'Suficiente'],
  [7, 'Satisfatório'],
  [8, 'Bom'],
  [9, 'Muito bom'],
  [10, 'Ótimo']
]);
