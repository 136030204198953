import * as Yup from 'yup';

export const ExceptionSchema = Yup.object().shape({
  descricao: Yup.string().required('Descrição é obrigatório.'),
  dia: Yup.number()
    .required('Dia é obrigatório!')
    .min(1, 'Dias do mês são entre 1 e 31!')
    .max(31, 'Dias do mês são entre 1 e 31!'),
  hora_inicial: Yup.string()
    .required('Hora inicial é obrigatório!')
    .matches(/^[0-2][0-9]:[0-5][0-9]$/, 'Hora inválida!'),
  hora_final: Yup.string()
    .required('Hora final é obrigatório!')
    .matches(/^[0-2][0-9]:[0-5][0-9]$/, 'Hora inválida!')
});
