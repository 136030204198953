export const hourRules = {
  required: 'Hora é obrigatório!',
  pattern: {
    value: /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/,
    message: 'Hora inválida! (00:00:00)'
  }
};

export const dateRules = {
  required: 'Data é obrigatório!',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};

export const reportOptions = [
  { label: 'Analítico', value: 1 },
  { label: 'Sintético', value: 2 },
  { label: 'Sintético detalhado', value: 3 }
];

export const statusOptions = [
  { label: 'Na fila', value: 1 },
  { label: 'Cliente atendeu', value: 2 },
  { label: 'Cliente não atendeu', value: 3 },
  { label: 'Perdido na fila', value: 4 },
  { label: 'Caixa postal', value: 5 },
  { label: 'Ouviu a mensagem', value: 6 },
  { label: 'Desligou no audio', value: 7 }
];

export const groupByColumnsOptions = {
  DIALER: 'dialer',
  STATUS: 'status',
  QUEUE: 'queue',
  CLIENT: 'client',
  AGENT: 'agent'
};

export const groupByOptions = [
  { label: 'Discador', value: groupByColumnsOptions.DIALER },
  { label: 'Status', value: groupByColumnsOptions.STATUS },
  { label: 'Fila', value: groupByColumnsOptions.QUEUE },
  { label: 'Cliente', value: groupByColumnsOptions.CLIENT },
  { label: 'Agente', value: groupByColumnsOptions.AGENT }
];
