import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { BsFillMicFill } from 'react-icons/bs';
import { AiFillCheckCircle } from 'react-icons/ai';
import { FaStopCircle } from 'react-icons/fa';
import { Button } from 'reactstrap';
import { AudioInput, AudioTime } from './components/styled';
import { differenceInSeconds } from 'date-fns';
import { toast } from 'react-toastify';
import ConversationService from '../../../../../ServicePanel/service/index';
import { addMessageConversationInAttendance } from 'store/action/conversation-in-attendance-action';
import MicRecorder from 'mic-recorder-to-mp3';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';

let intervalId = null;
const Mp3Recorder = new MicRecorder({ bitRate: 128 });

const useRecorder = ({ id, setRecording, conversation = null }) => {
  const [isRecording, setIsRecording] = useState(false);
  const [timeCount, setTimeCount] = useState('00:00');
  const dispatch = useDispatch();

  const getTime = (startTime) => {
    let differenceFromNow = differenceInSeconds(
      new Date(),
      new Date(startTime)
    );

    const minutes = Math.floor(differenceFromNow / 60);
    differenceFromNow -= minutes * 60;
    const seconds = Math.floor(differenceFromNow);

    function getFomattedDigit(digit) {
      return `${String(digit).padStart(2, '0')}`;
    }

    return `${getFomattedDigit(minutes)}:${getFomattedDigit(seconds)}`;
  };

  useEffect(() => {
    if (isRecording) {
      Mp3Recorder.start()
        .then()
        .catch((e) => console.error(e));
      const nowTime = new Date();
      intervalId = setInterval(() => {
        setTimeCount(getTime(nowTime));
      }, 1000);
    } else {
      clearInterval(intervalId);
      intervalId = null;
      setTimeCount('00:00');
    }
  }, [isRecording]);

  const startRecording = () => {
    setIsRecording(true);
    setRecording(true);
  };

  const stopRecording = () => {
    Mp3Recorder.stop();
    setIsRecording(false);
    setRecording(false);
  };

  const sendRecording = () => {
    try {
      Mp3Recorder.stop()
        .getMp3()
        .then(([buffer, blob]) => {
          const dateNow = new Date();
          const formatedData = new FormData();

          formatedData.append('idDaConversa', id); //body
          formatedData.append('cliente_ou_atendente', 'atendente'); //
          formatedData.append('escrita_por', localStorage.getItem('name'));
          formatedData.append(
            'legenda',
            `audio_atendente_${dateNow.toLocaleDateString(
              'pt-br'
            )}-${dateNow.toLocaleTimeString('pt-br')}`
          );
          formatedData.append('response_type', 'audio');
          formatedData.append('type', 'audio');

          if (conversation?.canal === 'whatsapp') {
            formatedData.append('file', blob);
            FlexChatIntegration.uploadFile(id, formatedData)
              .then((res) => {
                dispatch(
                  addMessageConversationInAttendance({ ...res.data }, id)
                );
              })
              .catch((err) => {
                console.log(err);
              });

            return;
          }
          formatedData.append('files', blob);
          ConversationService.uploadFile(formatedData)
            .then((res) => {
              dispatch(addMessageConversationInAttendance({ ...res.data }, id));
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((e) => console.log(e));
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }

    setIsRecording(false);
    setRecording(false);
  };

  return (
    <>
      {!isRecording && (
        <Button
          className="shadow-none bottomBtn"
          color="link"
          size="sm"
          title="Enviar audio"
          onClick={startRecording}
          disabled={isRecording}
        >
          <BsFillMicFill />
        </Button>
      )}

      {isRecording && (
        <AudioInput>
          <Button
            className="shadow-none controlBtn"
            color="link"
            size="sm"
            title="Enviar"
            onClick={
              conversation?.canal === 'instagram'
                ? () => {
                    toast.error(
                      'Envio de áudio ainda não está disponivel nesse canal'
                    );
                    stopRecording();
                  }
                : sendRecording
            }
            disabled={!isRecording}
          >
            <AiFillCheckCircle color="#5cb85c" />
          </Button>

          <div className="blinker" />

          <AudioTime>{timeCount}</AudioTime>

          <Button
            className="shadow-none controlBtn"
            color="link"
            size="sm"
            title="Cancelar"
            onClick={stopRecording}
            disabled={!isRecording}
          >
            <FaStopCircle color="#d9534f" />
          </Button>
        </AudioInput>
      )}
    </>
  );
};

export default useRecorder;
