import React, { useCallback, useEffect, useState } from 'react';

import { Container } from './styles';
import QueueMemberCard from '../QueueMemberCard';
import OneCardPerAgent from '../OneCardPerAgent';
import AgentStatistics from '../AgentStatistics';
import QueueList from './components/QueueList';

const QueueMembersContainer = ({
  allQueueMembers = [],
  isOneCardPerAgent,
  showAgentStatistics,
  filterValue,
  nameQueuesToMonit,
  queueMonitoringDataByQueue,
  groupByQueue,
  watingCalls
}) => {
  const [filteredQueueMembers, setFilteredQueueMembers] =
    useState(allQueueMembers);

  useEffect(() => {
    setFilteredQueueMembers(allQueueMembers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQueueMembers]);

  const handleFilterInfo = useCallback(
    (valuesToFilter) => {
      {
        let filteredQueueMembersList = [];
        valuesToFilter.map((item) => {
          let agentQueuePausesNames = '';
          item.agent.queue.pauses.find((agentQueue) => {
            if (agentQueue.name.toLowerCase().includes(filterValue)) {
              agentQueuePausesNames = agentQueue.name;
            }
          });
          if (
            item.queueName?.toLowerCase().includes(filterValue) === true ||
            item.extension?.toLowerCase().includes(filterValue) === true ||
            item.agent?.usr?.name?.toLowerCase().includes(filterValue) ===
              true ||
            item.caller?.toLowerCase().includes(filterValue) === true ||
            agentQueuePausesNames?.toLowerCase().includes(filterValue) === true
          ) {
            filteredQueueMembersList.push(item);
          }
        });
        return filteredQueueMembersList;
      }
    },
    [filterValue]
  );

  useEffect(() => {
    if (filterValue === '') setFilteredQueueMembers(allQueueMembers);
    setFilteredQueueMembers(handleFilterInfo(allQueueMembers));
  }, [allQueueMembers, filterValue, handleFilterInfo]);

  const getQueueMemberCardComponent = useCallback(
    (isOnePerAgent, key, data) => {
      if (isOnePerAgent) {
        return <OneCardPerAgent key={key} data={data} id={key} />;
      }

      return <QueueMemberCard key={key} data={data} />;
    },
    []
  );

  return (
    <>
      {showAgentStatistics ? (
        <AgentStatistics queueMembers={filteredQueueMembers} />
      ) : groupByQueue ? (
        <QueueList
          queueMonitoringDataByQueue={queueMonitoringDataByQueue}
          queueMembers={filteredQueueMembers || []}
          nameQueuesToMonit={nameQueuesToMonit}
          watingCalls={watingCalls}
        />
      ) : (
        <Container isGrouped={groupByQueue}>
          {filteredQueueMembers.map((item, index) => {
            return getQueueMemberCardComponent(isOneCardPerAgent, index, item);
          })}
        </Container>
      )}
    </>
  );
};

export default QueueMembersContainer;
