import ServiceCRUD from '../../../services/ServiceCRUDFLEXUC';
import { apiUc as http } from '../../../http';

class DialerService extends ServiceCRUD {
  constructor() {
    super(`/api/discadores`);
  }

  createMalling(data) {
    return http.post(`/api/malling-discador`, data);
  }

  startDialer(dialerId) {
    return http.post(`/api/processo-discador`, { dialer_id: dialerId });
  }

  stopDialer(dialerId) {
    return http.delete(`/api/processo-discador/${dialerId}`);
  }

  createIntegrationDialerSettings(data) {
    return http.post(`/api/interactive-dialer`, data);
  }

  getIntegrationDialerSettings(dialerId) {
    return http.get(`/api/interactive-dialer/${dialerId}`);
  }
}

export default new DialerService();
