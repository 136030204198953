import store from '../../../store';
import { addMessage } from '../../../store/action/internalChat';
import { orderChat } from '../../../store/action/internalChat';
import { watchNewMessages } from '../../../store/action/internalChat';

export function newMessage(param) {
  store.dispatch(addMessage(param.content, param.from, param.from, true));
  store.dispatch(orderChat(param.from));
  store.dispatch(watchNewMessages(true));
}
