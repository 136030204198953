import { apiUc } from 'http/index';

class BFFQueueMonitoringService {
  async getQueueMonitoringData(params) {
    return apiUc.get('/api/bff-queue-monitoring', { params });
  }

  async getQueueMonitoringDataByQueue(params) {
    return apiUc.get('/api/bff-queue-monitoring/group-by-queue', { params });
  }
}

export default new BFFQueueMonitoringService();
