export const typesConfig = [
  { value: 'nps', label: 'NPS' },
  { value: 'satisfacao', label: 'SATISFAÇÃO' }
];

export const channels = [
  { value: 'chat', label: 'Chat' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'telegram', label: 'Telegram' },
  { value: 'voice', label: 'Voz' },
  { value: 'whatsapp', label: 'WhatsApp' }
];

export const groups = [
  { value: 'channel', label: 'Canal' },
  { value: 'queue', label: 'Fila' },
  { value: 'rate', label: 'Nota' },
  { value: 'type_survey', label: 'Tipo Pesquisa' },
  { value: 'name_survey', label: 'Nome de pesquisa' },
  { value: 'user', label: 'Usuário' }
];

export const colorsChars = [
  '#FF0000',
  '#FF1B00',
  '#FF3600',
  '#FF5100',
  '#FF6B00',
  '#FF8600',
  '#FFA100',
  '#ffbc00',
  '#ffd700',
  '#fff200',
  '#f2ff00',
  '#d7ff00',
  '#bcff00',
  '#a1ff00',
  '#86ff00',
  '#6dff00',
  '#51ff00',
  '#36ff00',
  '#1bff00',
  '#00ff00'
];

export const optionColors = {
  luminosity: 'random',
  hue: 'random'
};

export const stepsNPS = [
  {
    selector: '.introduct',
    content: `Olá seja bem vindo ao nosso TourGuiado, na parte dos indicadores de NPS. O NPS é uma escala de 1 a 10, que indica quanto você recomendaria a EMPRESA X para outra pessoa. Para continuar basta clicar na setinha da direita logo abaixo`
  },
  {
    selector: '.tour',
    content:
      'A porcentagem dos promotores considera apenas as pesquisas com notas 9 e 10, logo isso é um indicador muito muito bom!'
  },
  {
    selector: '.tour2',
    content:
      'Aqui por sua vez é a porcentagem de neutros ela considera apenas as pesquisas com notas 7 e 8'
  },
  {
    selector: '.tour3',
    content:
      'Esta é a porcentagem de detratores, um indicador que requer bastante atenção pois ela considera apenas as pesquisas com notas de 1 a 6, indicando que algo está errado no seu atendimento'
  },
  {
    selector: '.tour4',
    content:
      'Chegamos agora na parte da nota do NPS em si, este é calculada da seguinte forma: % de promotores - % de detratores = NPS. Indicando assim a percentagem de cliente que recomendariam a sua empresa para outras pessoas'
  },
  {
    selector: '.tour5',
    content: `Por fim, mas não menos importante chegamos a zona de satisfação. Caso seu NPS esteja entre 75 e 100 estamos numa zona de EXCELÊNCIA, entre 50 e 75 zona de QUALIDADE, entre 0 e 50 zona de APERFEIÇOAMENTO e entre -100 e 0 zona CRÍTICA. Esse foi nosso tour guiado espero que ele tenha sido útil, até a próxima!`
  }
];

export const stepsSatisfaction = [
  {
    selector: '.introduct',
    content: `Olá seja bem vindo ao nosso TourGuiado, na parte dos indicadores de SATISFAÇÃO. O SATISFAÇÃO é uma escala de 1 a 5, que indica quanto você recomendaria a EMPRESA X para outra pessoa. Para continuar basta clicar na setinha da direita logo abaixo`
  },
  {
    selector: '.tour',
    content:
      'A porcentagem dos promotores considera apenas as pesquisas com notas 4 e 5, logo isso é um indicador muito muito bom!'
  },
  {
    selector: '.tour2',
    content:
      'Aqui por sua vez é a porcentagem de neutros ela considera apenas as pesquisas com notas 3'
  },
  {
    selector: '.tour3',
    content:
      'Esta é a porcentagem de detratores, um indicador que requer bastante atenção pois ela considera apenas as pesquisas com notas de 1 a 2, indicando que algo está errado no seu atendimento'
  },
  {
    selector: '.tour4',
    content:
      'Chegamos agora na parte da nota do SATISFAÇÃO em si, este é calculada da seguinte forma: % de promotores - % de detratores = SATISFAÇÃO. Indicando assim a percentagem de cliente que recomendariam a sua empresa para outras pessoas'
  },
  {
    selector: '.tour5',
    content: `Por fim, mas não menos importante chegamos a zona de satisfação. Caso seu SATISFAÇÃO esteja entre 75 e 100 estamos numa zona de EXCELÊNCIA, entre 50 e 75 zona de QUALIDADE, entre 0 e 50 zona de APERFEIÇOAMENTO e entre -100 e 0 zona CRÍTICA. Esse foi nosso tour guiado espero que ele tenha sido útil, até a próxima!`
  }
];
