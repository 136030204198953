const INITIAL_STATE = 'register';

export default function navigation(state = INITIAL_STATE, action) {

  switch (action.type) {
    case '@NAVIGATION_PAGE_NEXT_PAGE':
      return action.payload;
    default:
      return state;
  }
}
