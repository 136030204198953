import React from 'react';
import { calculateData } from '../../../../assets/CalculateAndFormatedData';
import { QuantitativeContainer, ColorAreaIcon } from '../../components/styled';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Bar } from 'react-chartjs-2';
import { chartBarWithTheLabelsOptions } from '../../../../../../../../assets/ChartsLabelsConfigurations/ChartBarDeafultOptions';

export function TotalProtocolsPerSubreason({ protocolsData }) {
  const { calculateTotalProtocolsPerSubReason } = calculateData();

  const formattedDataProtocols = calculateTotalProtocolsPerSubReason(
    protocolsData.totalProtocolsPerSubReason
  );

  const quantityOfOptions = formattedDataProtocols?.datasets[0]?.data.length;

  return (
    <Row>
      <Col xl="12">
        <Card>
          <CardHeader>
            <h6 className="surtitle">Visão geral</h6>
            <h5 className="h3 mb-0">Protocolos por submotivo</h5>
          </CardHeader>
          <CardBody>
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                flexDirection: quantityOfOptions > 20 ? 'column' : 'row'
              }}
            >
              <Col
                md="9"
                style={{
                  maxWidth: quantityOfOptions > 20 ? '100%' : '75%'
                }}
              >
                <div className="chart">
                  <Bar
                    data={formattedDataProtocols}
                    options={{
                      ...chartBarWithTheLabelsOptions,
                      datalabels: {
                        ...chartBarWithTheLabelsOptions.datalabels,
                        font: {
                          size: quantityOfOptions > 20 ? 8 : 10
                        }
                      }
                    }}
                  />
                </div>
              </Col>
              <Col
                md="3"
                style={{
                  maxWidth: quantityOfOptions > 20 ? '100%' : '25%'
                }}
              >
                <QuantitativeContainer className="chart">
                  {formattedDataProtocols.protocolDataForSubtitles.map(
                    (protocol, index) => (
                      <div
                        key={index}
                        style={{
                          display: 'flex'
                        }}
                      >
                        <ColorAreaIcon color={protocol.backgroundColor} />
                        <span>{protocol.name}</span>
                      </div>
                    )
                  )}
                </QuantitativeContainer>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}
