import React from 'react';

const FormToolView = ({ text }) => {
  return (
    <i
      className="fa fa-eye"
      size="sm"
      datatoggle="tooltip"
      dataplacement="top"
      title={text}
      datacontainer="body"
      dataanimation="true"
      style={{
        marginLeft: '5px',
        fontSize: '20px',
        color: '#707a95'
      }}
    />
  );
};

export default FormToolView;
