import ImagePDF from '../../../../../assets/img/pdf.png';
import ImageDOC from '../../../../../assets/img/icons/fileExtensions/doc.png';
import ImageXLSX from '../../../../../assets/img/xlsx.png';
import ImageCSV from '../../../../../assets/img/csv.png';
import ImageTXT from '../../../../../assets/img/icons/fileExtensions/txt.png';

import { v4 } from 'uuid';
import { toast } from 'react-toastify';

export class FileAttachments {
  constructor() {
    if (FileAttachments.instance) {
      return FileAttachments.instance;
    }

    this.isLoading = false;
    this.attachments = [];
    this.attachmentsSubmited = [];
    this.attachmentsName = [];
    this.previewExtensions = {
      doc: ImageDOC,
      docx: ImageDOC,
      xlsx: ImageXLSX,
      xls: ImageXLSX,
      csv: ImageCSV,
      pdf: ImagePDF,
      plain: ImageTXT
    };

    FileAttachments.instance = this;
  }

  addNames(name) {
    this.attachmentsName.push(name);
  }
  getPreviewFromFileExtension(file) {
    const extencion = file?.type?.split('/');

    if (!extencion) return ImagePDF;

    if (extencion[1] === 'pdf') return ImagePDF;
    if (extencion[1] === 'doc' || extencion[1] === 'docx') return ImageDOC;
    if (extencion[1] === 'xlsx' || extencion[1] === 'xls') return ImageXLSX;
    if (extencion[1] === 'csv') return ImageCSV;

    const extensionIcon = this.previewExtensions[extencion[1]];
    if (extensionIcon) return extensionIcon;
    return URL.createObjectURL(file);
  }

  changeLoading() {
    this.isLoading = !this.isLoading;
  }

  addAttachment({ name, data }) {
    if (this.verifyIfNameExists(name)) {
      toast.error('Arquivo com nome duplicado');
      return;
    }

    const allowedTypes = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/xlsx',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'text/plain'
    ];

    if (!allowedTypes.includes(data.type)) {
      toast.error('Extensão invalida');
      return;
    }

    this.addNames(name);
    this.attachments.push({
      data: this.getPreviewFromFileExtension(data),
      name: this.formatName(name),
      id: v4()
    });

    this.attachmentsSubmited.push(data);
  }

  removeAttachment(attachment, callback = () => {}) {
    this.attachments = this.attachments.filter((a) => a.id !== attachment.id);

    this.attachmentsSubmited = this.attachmentsSubmited.filter(
      (a) => this.formatName(a.name) !== attachment.name
    );

    this.attachmentsName = this.attachmentsName.filter(
      (a) => this.formatName(a) !== attachment.name
    );

    callback();
  }

  verifyIfNameExists(name) {
    return this.attachmentsName.some((attName) => attName === name);
  }

  getAttachments() {
    return this.attachments;
  }

  getAttachmentsName() {
    return this.attachmentsName;
  }

  formatName(name) {
    if (name?.length > 10) {
      return `${name.substring(0, 10)}...`;
    }
    return name;
  }

  getAttachmentsSubmited() {
    return this.attachmentsSubmited;
  }

  reset() {
    this.attachments = [];
    this.attachmentsSubmited = [];
    this.attachmentsName = [];
  }

  validateFiles() {
    const allowedTypes = [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/xlsx',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ];

    let messageErrors = [];
    let attachmentsWithoutType = [];

    for (const file of this.attachmentsSubmited) {
      if (file.type === '') {
        attachmentsWithoutType.push(file.name);
      }
      if (!allowedTypes.includes(file.type)) {
        messageErrors.push(file.type);
      }
    }

    return { messageErrors, attachmentsWithoutType };
  }
}
