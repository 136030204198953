import React, { useCallback, useEffect, useState } from 'react';
import DefaultModal from '../../../../components/Modal/DefaultModal';
import FlexSelect from 'components/Inputs/FlexSelect';
import UserService from '../../../User/service';
import OverTimesService from '../../service';
import { useForm, Controller } from 'react-hook-form';
import DatePickerIsValid from '../../../../components/Inputs/DatePickerIsValid';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { Row, Form, Col, Button, Input } from 'reactstrap';
import ReactLoader from 'components/Loader';
import FormTooltip from 'components/Inputs/FormTooltip';

const ModalExtraHour = ({
  isModalOpen,
  onModalToggle,
  reloadfunction,
  id,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  // eslint-disable-next-line no-unused-vars
  const { control, handleSubmit, errors, setValue, reset } = useForm();
  const [supervisors, setSupervisors] = useState([]);
  const [isBeforeStart, setIsBeforeStart] = useState(false);
  const [isSupervisor, setIsSupervisor] = useState(false);
  const [supervisorLogin, setSupervisorLogin] = useState();
  const [users, setUsers] = useState([]);
  const [showUsers, setShowUsers] = useState(false);
  const [loading, setLoading] = useState(false);

  const loadApi = useCallback(async () => {
    OverTimesService.get(id).then((response) => {
      const getOverTime = response.data.data;
      const getOverTimeFormated = {
        ...getOverTime,
        date: toISORender(getOverTime.date),
        supervisor: getOverTime.supervisor.login
      };
      setIsBeforeStart(getOverTimeFormated.isBeforeStart);
      reset(getOverTimeFormated);
      setLoading(false);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      loadApi();
    }
  }, [id, loadApi, isModalOpen]);

  useEffect(() => {
    const Params = {
      page: 0,
      size: 0,
      showDisable: true,
      query: '',
      isSupervisor: true
    };

    UserService.getAll(false, undefined, undefined, false).then((response) => {
      const listUsers = response.data.data.map((user) => {
        return {
          label: user.name,
          value: user.login
        };
      });
      setUsers(listUsers);
    });

    UserService.getAllShowSupervisor(
      Params.page,
      Params.size,
      Params.query,
      Params.showDisable,
      Params.isSupervisor
    )
      .then((response) => {
        const login = localStorage.getItem('login');
        const username = localStorage.getItem('name');
        const listSupervisors = response.data.data.map((user) => {
          if (login === user.login && username === user.name) {
            setSupervisorLogin(login);
            setIsSupervisor(true);
          }
          return {
            label: user.name,
            value: user.login
          };
        });
        setSupervisors(listSupervisors);
      })
      .catch((error) => {
        toast.error(error.response?.data.errors[0]);
      });
  }, [isModalOpen]);

  const dateRules = {
    required: 'Data é obrigatório!',
    pattern: {
      value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
      message: 'Data inválida! (dd/MM/aaaa)'
    }
  };
  const toISORender = (dateNotFormmated) => {
    if (dateNotFormmated === undefined) {
      console.log('A data está vazia');
    } else {
      const [date] = dateNotFormmated.split('T');
      const [YYYY, MM, DD] = date.split('-');
      return `${DD}/${MM}/${YYYY}`;
    }
  };
  const errorFormMessage = (message) => (
    console.log(message), (<p style={{ color: 'red' }}>{message}</p>)
  );
  const onSubmit = async (data) => {
    const FormatDate = toISOFormat(data.date);
    const FormatData = {
      ...data,
      date: FormatDate,
      isFromSupervisor: isSupervisor,
      supervisor: isSupervisor ? supervisorLogin : data.supervisor
    };
    if (id) {
      await OverTimesService.put(id, FormatData)
        .then(() => {
          toast.info('Hora extra atualizada com sucesso!');
        })
        // .catch((err) => toast.error(err.data.message.errors[0]))
        .catch((err) => toast.error(err.response?.data.errors[0]))
        .finally(() => {
          onModalToggle(false);
          reloadfunction();
        });
    } else {
      await OverTimesService.post(FormatData)
        .then(() => {
          toast.info('Hora extra cadastrada com sucesso!');
        })
        // .catch((err) => toast.error(err.data.message.errors[0]))
        .catch((err) => toast.error(err.response?.data.errors[0]))
        .finally(() => {
          onModalToggle(false);
          reloadfunction();
        });
    }
  };
  const toISOFormat = (dateNotFormmated) => {
    if (dateNotFormmated === undefined) {
      toast.error('Error: A data está vazia!');
    } else {
      const [date] = dateNotFormmated.split(' ');
      const [DD, MM, YYYY] = date.split('/');
      return `${YYYY}-${MM}-${DD}T03:00:00.000Z`;
    }
  };

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Solicitação de Hora Extra"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          {loading ? (
            <Row className="justify-content-md-center">
              <ReactLoader />
            </Row>
          ) : (
            <>
              <Row className="mb-3">
                <Col>
                  <label className="form-control-label">Data</label>
                  <Controller
                    render={(props) => (
                      <DatePickerIsValid
                        fieldName="date"
                        valueController={setValue}
                        defaultValue={props.value}
                      />
                    )}
                    control={control}
                    name="date"
                    rules={dateRules}
                    defaultValue={new Date().toLocaleDateString('pt-br')}
                    //defaultValue={''}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="date"
                    render={({ message }) => errorFormMessage(message)}
                  />
                  <label className="form-control-label mt-3">
                    Tempo(Minutos)
                  </label>
                  <Controller
                    as={Input}
                    control={control}
                    name="timeInMinutes"
                    rules={{ required: 'Campo obrigatório' }}
                    defaultValue=""
                  />
                  <ErrorMessage
                    errors={errors}
                    name="timeInMinutes"
                    render={({ message }) => {
                      console.log(message);
                      return errorFormMessage(message);
                    }}
                  />
                  <label className="form-control-label mt-3">
                    {showUsers ? 'Usuário' : 'Supervisor'}
                  </label>
                  <Controller
                    render={(props) => (
                      <FlexSelect
                        dataOptions={showUsers ? users : supervisors}
                        isClearable={true}
                        closeMenuOnSelect={true}
                        isMulti={false}
                        value={props.value}
                        valueController={setValue}
                        fieldName={showUsers ? 'user' : 'supervisor'}
                        labelName="label"
                        valueName="value"
                        defaultValue=""
                      />
                    )}
                    control={control}
                    name={showUsers ? 'user' : 'supervisor'}
                    rules={{ required: 'Campo obrigatório' }}
                    defaultValue=""
                  />
                  <ErrorMessage
                    errors={errors}
                    name="supervisor"
                    render={({ message }) => errorFormMessage(message)}
                  />
                  <label
                    className="form-control-label mt-3"
                    style={{
                      marginRight: '10px'
                    }}
                  >
                    Hora extra antes do expediente
                  </label>
                  <Controller
                    render={(props) => (
                      <input
                        onChange={(e) => {
                          props.onChange(e.target.checked);
                        }}
                        type="checkbox"
                        className="checkbox"
                        name="isBeforeStart"
                        defaultChecked={isBeforeStart}
                      />
                    )}
                    control={control}
                    name="isBeforeStart"
                    defaultValue={false}
                  />
                  <FormTooltip text="Habilitando este campo a hora extra do usuário será antes do expediente, caso o campo esteja desabilitado e hora extra será após seu expediente." />
                  {isSupervisor ? (
                    <>
                      <label
                        className="form-control-label mt-3"
                        style={{
                          marginRight: '10px'
                        }}
                      >
                        Adicionar hora extra para um atendente
                      </label>
                      <Controller
                        render={(props) => (
                          <input
                            onChange={(e) => {
                              props.onChange(e.target.checked);
                              setShowUsers(!showUsers);
                            }}
                            type="checkbox"
                            className="checkbox"
                            name="addUserHourExtra"
                            defaultChecked={showUsers}
                          />
                        )}
                        control={control}
                        name="addUserHourExtra"
                        defaultValue={false}
                      />
                    </>
                  ) : null}
                </Col>
              </Row>
              <hr />
              <Button color="primary" type="submit">
                Salvar
              </Button>
              <Button color="danger" onClick={closeModal}>
                Voltar
              </Button>
            </>
          )}
        </Form>
      </>
    </DefaultModal>
  );
};

export default ModalExtraHour;
