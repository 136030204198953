import React, { useState, useCallback } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Form,
  Input
} from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import { saveAs } from 'file-saver';

import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'components/Inputs/DatePicker';
import { ErrorMessage } from '@hookform/error-message';
import { functionsUseFul } from 'views/Protocol/list/assets/functionsUseFul';
import { ErrorFormMessage } from 'components/ErrorFormMessage';
import { dateRules } from 'views/Protocol/form/assets/functions';
import FlexSelect from 'components/Inputs/FlexSelect';
import {
  ImageButton,
  ImageButtonConteinerProtocol,
  ImageSize
} from '../../../assets/styles/image_style';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { useSelector } from 'react-redux';
import { useGetQueues } from '../hooks/useGetQueues';
import { useGetUsers } from '../Audit/hooks/useGetUsers';
import { useGetBroadcastList } from '../hooks/useGetBroadcastListSMS';
import broadcastListService from './service';
import Loader from 'react-loader-spinner';

import Pagination from 'components/Pagination';
import { toast } from 'react-toastify';
import { TotalRegister } from 'components/TotalRegister';
import { Analytical } from './Tables/Analytical';
import { Syntethic } from './Tables/Synthetic';
import { broadcastTypeOfClosingConversationOptions } from 'views/ChatBroadcastListSMS/utils';
import xlsxImage from '../../../assets/img/xlsx.png';
import pdfImage from '../../../assets/img/pdf.png';
import { UserFlexSelect } from '../../../components/UserFlexSelect';
import { ItemPerPageSelect } from 'components/Inputs/ItemPerPageSelect';

export const TransmissionListReportSMS = () => {
  const { getDateBegin } = functionsUseFul();
  const [analyticalData, setAnalyticalData] = useState([]);
  const [synteticalData, setSynteticalData] = useState([]);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const { users } = useGetUsers(usersShowDisable);
  const { broadcastList } = useGetBroadcastList();
  const { queues } = useGetQueues(showDisabled);
  const [showDisabled, setShowDisabled] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { control, handleSubmit, getValues, setValue, errors } = useForm();
  const [dataToSend, setDataToSend] = useState({});
  const [reportType, setReportType] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const limitRows = 40000;
  const handlePageChange = (page) => {
    setActivePage(page);
    getData(page);
  };

  const hadleReportexport = (typeExport, e) => {
    e.preventDefault();

    const params = getValues([
      'idBroadCastList',
      'queue',
      'dateStart',
      'dateEnd',
      'statusMessage',
      'agent',
      'client',
      'phoneClient',
      'reportType',
      'broadcastTypeOfClosingConversation'
    ]);

    const dto = {
      ...params,
      dateStart: toISOFormat(params.dateStart),
      dateEnd: toISOFormat(params.dateEnd)
    };

    if (dto.statusMessage.length > 0) {
      dto.statusMessage = JSON.stringify(dto.statusMessage);
    }

    if (analyticalData.length < limitRows && typeExport == 'XLSX') {
      setLoadingCSV(true);

      broadcastListService
        .getExcel(dto)
        .then((res) => {
          if (res.data.size === 0) {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          } else {
            saveAs(
              new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
              }),
              `relatorio-lista-transmissao.xlsx`
            );

            setLoadingCSV(false);
          }
        })
        .catch((err) => {
          setLoadingCSV(false);
          console.log('==>', err);
          toast.info('Nenhum registro foi encontrado!');
        });
    } else if (typeExport == 'XLSX' && analyticalData.length > limitRows) {
      setLoadingCSV(true);

      broadcastListService
        .getCsv(dto)
        .then((res) => {
          if (res.data.size === 0) {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          } else {
            saveAs(
              new Blob([res.data], { type: res.headers['content-type'] }),
              `realatorio-lista-transmissao${new Date()}.csv`
            );

            setLoadingCSV(false);
          }
        })
        .catch((err) => {
          setLoadingCSV(false);
          console.log('==>', err);
          toast.info('Nenhum registro foi encontrado!');
        });
    } else if (typeExport == 'PDF') {
      setLoadingPdf(true);
      broadcastListService
        .getPdf(dto)
        .then((res) => {
          if (res.data.size === 0) {
            toast.info('Nenhum registro foi encontrado!');
            setLoadingPdf(false);
          } else {
            saveAs(
              new Blob([res.data], { type: 'application/pdf' }),
              `realatorio-lista-transmissao${new Date()}.pdf`
            );
          }
          setLoadingPdf(false);
        })
        .catch((err) => {
          setLoadingPdf(false);
          console.log('==>', err);
          toast.info('Nenhum registro foi encontrado!');
        });
    }
  };

  const getData = (page) => {
    const data = dataToSend;
    broadcastListService
      .getAllParams(page, 10, data)
      .then((res) => {
        setQuantity(res.data.quantity);
        setAnalyticalData(res.data.result);
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formatData = (data) => {
    let formatedData = { ...data };
    formatedData.dateEnd = toISOFormat(formatedData.dateEnd);
    formatedData.dateStart = toISOFormat(formatedData.dateStart);
    if (formatedData.queue.length > 0) {
      formatedData.queue = JSON.stringify(formatedData.queue);
    }
    if (formatedData.statusMessage.length > 0) {
      formatedData.statusMessage = JSON.stringify(formatedData.statusMessage);
    }
    return formatedData;
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    if (reportType === 1) {
      setActivePage(1);
      const dataToSend = formatData(data);
      setDataToSend(dataToSend);
      broadcastListService
        .getAllParams(1, itemsPerPage, dataToSend, reportType)
        .then((res) => {
          if (res.data.quantity === 0) {
            toast.info('Nenhum registro foi encontrado');
          }
          setQuantity(res.data.quantity);
          setAnalyticalData(res.data.result);
        })
        .catch((err) => {
          console.log('err', err);
          toast.error(
            err || 'Ocorreu um erro porfavor entre em contato com o suporte'
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }
    if (reportType === 2) {
      const dataToSend = formatData(data);
      setDataToSend(dataToSend);
      broadcastListService
        .getAllParams(1, 10, dataToSend, reportType)
        .then((res) => {
          if (
            res.data.result.GroupByStatusMessage.length === 0 &&
            res.data.result.groupByMessage.length === 0
          ) {
            toast.info('Nenhum registro encontrado');
          }
          setSynteticalData([res.data.result]);
        })
        .catch((err) => {
          console.log('err', err);
          toast.error(
            err || 'Ocorreu um erro porfavor entre em contato com o suporte'
          );
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const toISOFormat = useCallback((dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${YYYY}-${MM}-${DD}`;
  }, []);

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="mb-0">Relatório da Campanha Ativa SMS</h3>
                  </div>
                </CardHeader>
                <CardBody className="pt-2">
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">
                          Lista de Transmissão{reportType === 1 ? '' : '*'}
                        </label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              isClearable={true}
                              dataOptions={broadcastList}
                              isMulti={false}
                              value={props.value}
                              valueController={setValue}
                              closeMenuOnSelect={true}
                              fieldName="idBroadCastList"
                              labelName="label"
                              valueName="value"
                            />
                          )}
                          rules={
                            reportType === 2
                              ? {
                                  required:
                                    'Lista de Transmissão é obrigatório!'
                                }
                              : {}
                          }
                          control={control}
                          name="idBroadCastList"
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="idBroadCastList"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">Fila(s)</label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              isClearable={true}
                              dataOptions={queues}
                              isMulti={true}
                              value={props.value}
                              closeMenuOnSelect={false}
                              valueController={setValue}
                              fieldName="queue"
                              labelName="name"
                              valueName="name"
                            />
                          )}
                          control={control}
                          name="queue"
                          defaultValue=""
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md={{ size: 6 }}>
                        <label className="form-control-label">
                          Data inicial
                        </label>
                        <Controller
                          render={(props) => (
                            <DatePicker
                              fieldName="dateStart"
                              valueController={(fieldName, value) => {
                                setValue(fieldName, value);
                              }}
                              defaultValue={props.value}
                            />
                          )}
                          control={control}
                          name="dateStart"
                          defaultValue={getDateBegin()}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="dateStart"
                          render={({ message }) => (
                            <ErrorFormMessage message={message} />
                          )}
                        />
                      </Col>
                      <Col md={{ size: 6 }}>
                        <label className="form-control-label">Data final</label>
                        <Controller
                          render={(props) => (
                            <DatePicker
                              fieldName="dateEnd"
                              valueController={(fieldName, value) => {
                                setValue(fieldName, value);
                              }}
                              defaultValue={props.value}
                            />
                          )}
                          control={control}
                          name="dateEnd"
                          defaultValue={new Date().toLocaleDateString('pt-br')}
                          rules={{
                            ...dateRules,
                            validate: async (value) => {
                              if (
                                new Date(toISOFormat(value, true)) <
                                new Date(
                                  toISOFormat(getValues()['dateStart'], true)
                                )
                              ) {
                                return 'A data final não pode ser anterior à inicial!';
                              } else {
                                return true;
                              }
                            }
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="dateEnd"
                          render={({ message }) => (
                            <ErrorFormMessage message={message} />
                          )}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">
                          Status do envio
                        </label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={[
                                { value: 'sent', label: 'Enviada' },
                                { value: 'failed', label: 'Falhou' },
                                { value: 'not_sent', label: 'Não enviada' }
                              ]}
                              isMulti={true}
                              isClearable={true}
                              value={props.value}
                              closeMenuOnSelect={false}
                              valueController={setValue}
                              fieldName="statusMessage"
                              labelName="label"
                              valueName="value"
                            />
                          )}
                          control={control}
                          name="statusMessage"
                          defaultValue=""
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">Agente</label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              isClearable={true}
                              dataOptions={users}
                              isMulti={false}
                              value={props.value}
                              valueController={setValue}
                              closeMenuOnSelect={true}
                              fieldName="agent"
                              labelName="name"
                              valueName="login"
                            />
                          )}
                          control={control}
                          name="agent"
                          defaultValue=""
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">
                          Nome do cliente
                        </label>
                        <Controller
                          render={(props) => (
                            <Input
                              {...props}
                              placeholder="Nome do cliente"
                              name="client"
                              value={props.value}
                              defaultValue=""
                              valueController={setValue}
                            />
                          )}
                          control={control}
                          name="client"
                          defaultValue=""
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">
                          Telefone do cliente
                        </label>
                        <Controller
                          render={(props) => (
                            <Input
                              {...props}
                              placeholder="Telefone do cliente"
                              name="phoneClient"
                              value={props.value}
                              defaultValue=""
                              valueController={setValue}
                            />
                          )}
                          control={control}
                          name="phoneClient"
                          defaultValue=""
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">
                          Tipo de Relatório
                        </label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={[
                                { label: 'Analítico', value: 1 },
                                { label: 'Sintético', value: 2 }
                              ]}
                              isMulti={false}
                              value={props.value}
                              valueController={(fieldName, value) => {
                                setValue(fieldName, value);
                                setReportType(value);
                              }}
                              closeMenuOnSelect={true}
                              fieldName="reportType"
                              labelName="label"
                              valueName="value"
                            />
                          )}
                          defaultValue={1}
                          control={control}
                          name="reportType"
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">
                          Tipo de Lista de Transmissão
                        </label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              isClearable={true}
                              dataOptions={[
                                ...broadcastTypeOfClosingConversationOptions
                              ]}
                              isMulti={false}
                              value={props.value}
                              valueController={setValue}
                              closeMenuOnSelect={true}
                              fieldName="broadcastTypeOfClosingConversation"
                              labelName="label"
                              valueName="value"
                            />
                          )}
                          control={control}
                          name="broadcastTypeOfClosingConversation"
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col className="md-6 mx-4">
                        <div
                          style={{
                            display: 'flex',
                            gap: '20px'
                          }}
                        >
                        <label className="form-control-label">
                          <Input
                            type="checkbox"
                            onChange={(e) => {
                              setShowDisabled(!showDisabled);
                            }}
                            checked={showDisabled}
                          />
                          Mostrar Filas desabilitadas
                        </label>

                          <UserFlexSelect
                            usersShowDisable={usersShowDisable}
                            setUsersShowDisable={setUsersShowDisable}
                            name={'Agente'}
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col>
                        <div
                          className="mt-3"
                          style={{
                            display: 'flex',
                            maxHeight: '50px',
                            height: '50px',
                            justifyContent: 'flex-start'}}
                        >
                          <Button
                            style={buttonStyle(
                              systemConfiguration.primary_color
                            )}
                            type="submit"
                          >
                            Buscar
                          </Button>
                     
                              <ItemPerPageSelect
                                valueController={setItemsPerPage}
                              ></ItemPerPageSelect>
               
                        </div>
                      </Col>
                      <Col
                        md="6"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-end'
                        }}
                      >
                        <button
                          id="button_xlsx"
                          style={ImageButton}
                          onClick={(e) => {
                            hadleReportexport('XLSX', e);
                          }}
                          disabled={
                            analyticalData.length && !loadingCSV ? false : true
                          }
                        >
                          <Tooltip
                            title={
                              <p style={{ fontSize: '14px' }}>
                                {analyticalData.length
                                  ? `Fazer download do relatório de Lista de transmissão. Arquivos menores que ${limitRows} registros serão baixados em XLSX, caso contrário CSV`
                                  : 'Nenhum registro para realizar download'}
                              </p>
                            }
                            arrow
                          >
                            {loadingCSV ? (
                              <Loader
                                type="ThreeDots"
                                visible={true}
                                height="40"
                                width="40"
                              />
                            ) : (
                              <img
                                src={xlsxImage}
                                alt="xlsx"
                                style={ImageSize}
                              />
                            )}
                          </Tooltip>
                        </button>
                        <button
                          style={ImageButton}
                          name="pdf"
                          onClick={(e) => {
                            hadleReportexport('PDF', e);
                          }}
                          disabled={
                            analyticalData.length && !loadingPdf ? false : true
                          }
                        >
                          <Tooltip
                            title={
                              <p style={{ fontSize: '14px' }}>
                                {analyticalData.length
                                  ? 'Fazer download do relatório de lista de transmissão.'
                                  : 'Nenhum registro para realizar download, faça um filtra para realizar está ação'}
                              </p>
                            }
                            arrow
                          >
                            {loadingPdf ? (
                              <Loader
                                type="ThreeDots"
                                visible={true}
                                height="40"
                                width="40"
                              />
                            ) : (
                              <img src={pdfImage} alt="pdf" style={ImageSize} />
                            )}
                          </Tooltip>
                        </button>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
                {analyticalData &&
                analyticalData.length > 0 &&
                reportType === 1 ? (
                  isLoading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      {reportType === 1 ? (
                        <>
                          <Col md="12">
                            <TotalRegister totalRegister={quantity} />
                          </Col>
                          <Analytical data={analyticalData} />
                          <Pagination
                            itemsCountPerPage={itemsPerPage}
                            activePage={activePage}
                            totalItemsCount={quantity}
                            onChange={handlePageChange.bind(this)}
                          />
                        </>
                      ) : null}
                    </>
                  )
                ) : null}
                {synteticalData &&
                synteticalData.length > 0 &&
                reportType === 2 ? (
                  isLoading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      <Syntethic data={synteticalData} />
                    </>
                  )
                ) : null}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
