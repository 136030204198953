import DefaultModal from '../../../../components/Modal/DefaultModal';
import React, { useState, useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';

import ProtocolService from '../../service';
import ClientService from '../../../Clients/service';
import { toast } from 'react-toastify';
import { Row, Form, Col, Button } from 'reactstrap';
import Select from 'react-select/creatable';
import { custom_select } from '../../../../assets/styles/multiple_select';
import { validateFields } from '../../form/assets/functions';
import AddContactModal from 'views/Protocol/form/components/AddContactModal';
import { AddNewContactButton } from 'components/Buttons/AddNewContactButton';
import ModalFilterClients from 'views/Clients/components/ModalFilterClients';
import { SearchContactButton } from 'components/Buttons/SearchContactButton';
const ModalClient = ({
  isModalOpen,
  onModalToggle,
  idProtocol,
  isRequired,
  emailProtocol = '',
  render,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  const [clientToSelect, setClientsToSelect] = useState([]);
  const [selectedClient, setSelectClient] = useState('');
  const [selectClientModal, setSelectClientModal] = useState('');
  const [controlValidSelectClient, setControlValidSelectClient] =
    useState(false);
  const [timeoutID, setTimeoutID] = useState(null);
  const [clientNewData, setClientNewData] = useState(null);
  const { handleSubmit } = useForm();
  const [isClientSearchModalOpen, setIsClientSearchModalOpen] = useState(false); // controle do modal de busca de clientes
  const onSubmit = async () => {
    try {
      if (!selectedClient) {
        setControlValidSelectClient(!controlValidSelectClient);
        return;
      }
      const dataToSubmit = { clientId: selectedClient?.value };

      await ProtocolService.protocolUpdateData(
        idProtocol,
        dataToSubmit,
        'change_client'
      );

      const message = 'Contato do protocolo atualizado com sucesso';
      toast.success(message, {
        autoClose: 3000,
        closeOnClick: true
      });

      setSelectClient('');
      closeModal();
      render({
        ...clientNewData,
        nome: clientNewData.label,
        id: clientNewData.value
      });
      setClientNewData(null);
    } catch (err) {
      const errMsg =
        err?.response?.data?.message || 'Erro ao alterar contato do protocolo';
      toast.error(errMsg, {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    if (selectClientModal) {
      const client = {
        email: selectClientModal?.clientEmail || '',
        telefone: selectClientModal?.clientPhone || '',
        label: selectClientModal?.clientName || '',
        value: selectClientModal?.clientId || ''
      };

      setSelectClient(client?.value);
      setClientsToSelect([client]);
      setClientNewData(client);

      setIsClientSearchModalOpen(false);
    }
  }, [selectClientModal]);

  const sortData = (data) => {
    const dataSort = data.sort((a, b) => {
      if (a.name) {
        if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
        if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
        return 0;
      } else {
        if (a.nome.toLowerCase() < b.nome.toLowerCase()) return -1;
        if (a.nome.toLowerCase() > b.nome.toLowerCase()) return 1;
        return 0;
      }
    });
    return dataSort;
  };

  const handleGetClient = (value) => {
    if (value) {
      ClientService.getAllFilter('cliente', '', 'ALL', {
        nome: value,
        email: value
      })
        .then((response) => {
          const { content } = response.data;
          const sortClientsArray = sortData(content);
          const setData = sortClientsArray.map((client) => {
            const setClient = {
              email: client.email,
              telefone: client.telefone,
              label: client.nome,
              value: client.id
            };
            return setClient;
          });

          setClientsToSelect(setData);
        })
        .catch((err) => {
          const message =
            (err.response && err.response.data) || 'Erro ao carregar contatos';
          toast.error(message, {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    } else {
      setClientsToSelect();
    }
  };

  const seachClientSelect = (value) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    setTimeoutID(
      setTimeout(() => {
        handleGetClient(value);
      }, 1200)
    );
  };

  const setClientSelected = (client) => {
    if (client) {
      setSelectClient({ value: client.value, label: client.label });
      setClientNewData(client);
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelUpdate = () => {
    closeModal();
    setSelectClient(null);
  };

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      size="md"
      title="Alterar Contato"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <ModalFilterClients
          className="modal-lg"
          show={isClientSearchModalOpen}
          onModalToggle={setIsClientSearchModalOpen}
          setSelectedClient={(a) => {
            setClientSelected({
              value: a?.clientId,
              label: a?.clientName
            });
            setIsClientSearchModalOpen(false);
          }}
        />
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col md="10">
              <label className="form-control-label">Contato*</label>
              <Select
                isClearable
                allowCreateWhileLoading={false}
                isValidNewOption={() => false}
                placeholder="Buscar contato por nome ou email..."
                styles={custom_select}
                value={selectedClient}
                options={clientToSelect}
                onInputChange={seachClientSelect}
                onChange={setClientSelected}
                rules={validateFields(isRequired)}
              />
              {controlValidSelectClient &&
                errorFormMessage('Campo obrigatório')}
            </Col>
            <Col md="2">
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '1.5rem',
                  justifyContent: 'flex-end'
                }}
              >
                <SearchContactButton
                  onClick={() => setIsClientSearchModalOpen(true)}
                />
                <AddContactModal
                  defaultFieldsAutoComplete={[
                    {
                      email: 'teste@email.com'
                    }
                  ]}
                  setSelectedContact={(result) => {
                    setSelectClientModal(result);
                  }}
                  buttonOpenModal={({ onClick }) => (
                    <AddNewContactButton onClick={onClick} />
                  )}
                />
              </div>
            </Col>
          </Row>

          <hr />
          <Button color="primary" type="submit">
            Salvar
          </Button>
          <Button color="danger" onClick={() => cancelUpdate()}>
            Voltar
          </Button>
        </Form>
      </>
    </DefaultModal>
  );
};

export default ModalClient;
