import React from 'react';
import { useSelector } from 'react-redux';
import { TitlePreview } from './styled';

export function PreviewPdf({ filePreview, title = true }) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  return (
    <>
      {filePreview ? (
        <div>
          <embed
            className="fileBalon"
            src={filePreview.url + '#page=1&zoom=20&toolbar=0'}
          ></embed>
          {title && (
            <TitlePreview colorText={systemConfiguration.primary_color}>
              <h4 className="display-6">{filePreview.nameFile}</h4>
            </TitlePreview>
          )}
        </div>
      ) : null}
    </>
  );
}
