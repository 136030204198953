import React from 'react';
import { useSelector } from 'react-redux';

import {
  StyledCard,
  StyledCardHeader,
  StyledCardTitle,
  StyledCardBody
} from './styled';

const CollectiveIndicatorCard = ({ title, body }) => {
  const primaryColor = useSelector(
    (state) => state.systemConfiguration.primary_color
  );

  return (
    <StyledCard backgroundColor={primaryColor}>
      <StyledCardHeader backgroundColor={primaryColor}>
        <StyledCardTitle title={title} tag="h5">
          {title}
        </StyledCardTitle>
      </StyledCardHeader>
      <StyledCardBody>
        <span className="text-xl">{body}</span>
      </StyledCardBody>
    </StyledCard>
  );
};

export default React.memo(CollectiveIndicatorCard);
