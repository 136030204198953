/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import IssueService from '../service';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Pagination from 'components/Pagination';
import ModalConfirmation from '../../../components/Modal/ConfirmationModal';

import {
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  Col,
  CardBody,
  InputGroup,
  InputGroupAddon,
  Input,
  InputGroupText
} from 'reactstrap';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const IssueList = () => {
  const [issues, setIssues] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchIssues, setSearchIssues] = useState();
  const [idResource, setidResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  const getIssues = (selectedPage = 1, search = '') => {
    setLoading(true);
    IssueService.getAllFilter(selectedPage, search)
      .then((res) => {
        const issues = res.data.assuntos;
        const quantity = res.data.quantidadeDeAssuntos;
        const newActivePage = selectedPage || activePage;
        setIssues(issues);
        setQuantity(quantity);
        setActivePage(newActivePage);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Erro ao listar assuntos!', {
          autoClose: 3000,
          closeOnClick: true
        });
        setLoading(false);
      });
  };

  useEffect(getIssues, []);

  const handlePageChange = (activePage) => getIssues(activePage);

  const deleteIssue = (id) => {
    IssueService.delete(id)
      .then(() => {
        getIssues();
        toast.success('Assunto deletado!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Erro ao deletar assunto!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        getIssues();
      });
  };

  const getIssueSearch = (event, selectedPage = 1) => {
    setSearchIssues(event.target.value);
    if (searchIssues) {
      getIssues(selectedPage, event.target.value);
    }
  };

  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/issue/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Assunto</h3>
                </CardHeader>
                <CardBody>
                  {/* <small className="text text-muted font-weight-bold">
                    Buscar setor
                  </small> */}
                  <Row>
                    <Col md="11">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Assuntos"
                          type="text"
                          onChange={(event) => getIssueSearch(event)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={issues}
                  keyField="_id"
                  columns={[
                    {
                      dataField: 'nome',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'descricao',
                      text: 'Descrição',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link to={'/admin/issue/form/' + row._id}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => controlDelete(row._id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={deleteIssue}
        message={'Tem certeza que deseja excluir o assunto?'}
      />
    </>
  );
};

export default IssueList;
