import React from 'react';
import { Prompt } from 'react-router-dom';

export const ScreenPrompt = ({ when, message }) => {
  return (
    <>
      <Prompt when={when} message={() => message} />
    </>
  );
};
