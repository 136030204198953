import { useState, useEffect } from 'react';
import QueueService from '../../../Queue/service';

export function useGetQueues() {
  const [queues, setQueues] = useState([]);

  useEffect(() => {
    QueueService.getAll()
      .then((response) => {
        setQueues(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);

  return {
    queues,
    setQueues
  };
}
