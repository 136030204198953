import { useMemo, useCallback, useState, useEffect } from 'react';
import ServiceAuth from '../../../../services/ServiceAUTH';

export const useRegisterLogout = (logoutType) => {
  const [queue, setqueue] = useState();
  const [signAsterisk, setsignasterisk] = useState();
  // const [UseScale, setUseScale] = useState();
  const service = useMemo(() => {
    return new ServiceAuth('logout');
  }, []);

  const serviceToRegisterLogOutOnClosingScreen = useMemo(() => {
    return new ServiceAuth('authentication-report/register-logout');
  }, []);

  const serviceToRegisterLoginWhenEnteringTheSystem = useMemo(() => {
    return new ServiceAuth('authentication-report/register-login');
  }, []);

  const login = useMemo(() => localStorage.getItem('login'), []);

  useEffect(() => {
    const SignAsterisk = localStorage.getItem('should_sign_in_asterisk');
    const SignAsteriskFormated = JSON.parse(SignAsterisk);
    setsignasterisk(SignAsteriskFormated);
  }, []);
  // useEffect(() => {
  //   const UseScale = localStorage.getItem('use_scale');
  //   const UseScaleFormated = JSON.parse(UseScale);
  //   setUseScale(UseScaleFormated);
  // }, []);
  useEffect(() => {
    const queueIds = localStorage.getItem('queuesToSignId');
    const queueIdsFormated = JSON.parse(queueIds);
    const queueTest = queueIdsFormated.map((q) => q.id);
    setqueue(queueTest);
  }, []);

  const RegisterLogout = async () => {
    //login: usrLogin, logoutType, should_sign_in_asterisk,use_scale, agent_code, queueIds, extension
    try {
      const data = {
        logoutType,
        login,
        should_sign_in_asterisk: signAsterisk,
        // use_scale: UseScale,
        agent_code: localStorage.getItem('agent_code'),
        queueIds: queue,
        extension: localStorage.getItem('currentLoggedInExtension')
      };
      await service.create({ ...data });
    } catch (err) {
      console.error('Erro register logout', err);
    }
  };

  const RegisterLoginWhenEnteringTheSystem = async () => {
    try {
      const data = {
        usrLogin: login
      };

      await serviceToRegisterLoginWhenEnteringTheSystem.create({ ...data });
    } catch (err) {
      console.error('Erro register login', err);
    }
  };

  const RegisterLogOutOnClosingScreen = useCallback(async () => {
    try {
      const data = {
        logoutType,
        usrLogin: login
      };

      serviceToRegisterLogOutOnClosingScreen.createKeepAlive({
        ...data
      });
    } catch (err) {
      console.error('Erro register logout', err);
    }
  }, [login, logoutType, serviceToRegisterLogOutOnClosingScreen]);

  return {
    RegisterLogout,
    RegisterLoginWhenEnteringTheSystem,
    RegisterLogOutOnClosingScreen
  };
};
