import React, { useEffect } from 'react';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
import {
  Content,
  ImageLogo,
  ToViewButton,
  IconLogo,
  SourceRequest,
  ContentGridDisplay,
  TimeCount,
  ContentButtonDisplay,
  NotificationItemWrapper,
  // ButtonControlled,
  IconLogoWrapper
} from './styled';
import {
  Button
  // UncontrolledDropdown,
  // DropdownToggle,
  // DropdownMenu,
  // Row
} from 'reactstrap';
import pt from 'date-fns/locale/pt-BR';
import { useHistory } from 'react-router-dom';

// import { BsThreeDots } from 'react-icons/bs';
// import { FiDelete } from 'react-icons/fi';
import OverTimesService from '../../../../../views/ExtraHour/service';
// import NotificationTimeShiftService from '../service/NotificationTimeShift';
import GeneralNotificationService from '../service/GeneralNotificationService';
import { toast } from 'react-toastify';
import { formatDistance } from 'date-fns';
import LogoG4 from '../../../../../assets/img/theme/iconG4.png';
import TimeShiftSupervisor from 'views/CalendarScales/service/TimeShiftSupervisor';
import TimeShiftAgent from 'views/CalendarScales/service/TimeShiftAgent';
import { arrayTypesOfNotifications } from '../utils/arrayTypesOfNotifications';
import ProtocolSchedulingService from 'views/Protocol/service/ProtocolSchedulingService';
import { useClickToCallOnPhoneField } from 'views/CRM/shared/hooks/useClickToCallOnPhoneField';

export default function NotifcationItem(props) {
  const history = useHistory();
  const isUpdate = true;
  const { canMakeCall, makeCall } = useClickToCallOnPhoneField();

  const reloadNotifications = (idNotification) => {
    if (props.page === 1) {
      props.typeOfNotifications === 'ALL'
        ? props.loadApi('', isUpdate, [])
        : props.loadApi(isUpdate, []);
    } else {
      const notificationToBeRead = props.data.find(
        (item) => item.id === idNotification
      );
      notificationToBeRead.isRead = true;
      props.typeOfNotifications === 'ALL'
        ? props.loadApi('', isUpdate, props.data)
        : props.loadApi(isUpdate, props.data);
    }
  };

  const controlerStatus = (id, status) => {
    try {
      if (status === 'APPROVED') {
        OverTimesService.patch(id, { status })
          .then(() => {
            toast.success('Solicitação aprovada com sucesso!');
            reloadNotifications(id);
            props.setLoading(false);
          })
          .catch((err) => toast.error(err.response?.data.errors[0]));
      }
      if (status === 'REJECTED') {
        OverTimesService.patch(id, { status })
          .then(() => {
            toast.success('Solicitação rejeitada com sucesso!');
            reloadNotifications(id);
          })
          .catch((err) => toast.error(err.response?.data.errors[0]));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const controlerStatusTimeShift = async (id, status) => {
    try {
      if (status === 'APPROVED') {
        await TimeShiftSupervisor.patchShift(id, 'approve')
          .then(() => {
            toast.success('Solicitação aprovada com sucesso!');
            reloadNotifications(id);
          })
          .catch((err) => toast.error(err.response?.data.errors[0]));
      }
      if (status === 'REJECTED') {
        await TimeShiftSupervisor.patchShift(id, 'reject')
          .then(() => {
            toast.success('Solicitação rejeitada com sucesso!');
            reloadNotifications(id);
          })
          .catch((err) => toast.error(err.response?.data.errors[0]));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const controlerStatusTimeShiftAgent = async (id, status) => {
    try {
      if (status === 'APPROVED') {
        await TimeShiftAgent.patchShift(id, 'approve')
          .then(() => {
            toast.success('Solicitação aprovada com sucesso!');
            reloadNotifications(id);
          })
          .catch((err) => toast.error(err.response?.data.errors[0]));
      }
      if (status === 'REJECTED') {
        await TimeShiftAgent.patchShift(id, 'reject')
          .then(() => {
            toast.success('Solicitação rejeitada com sucesso!');
            reloadNotifications(id);
          })
          .catch((err) => toast.error(err.response?.data.errors[0]));
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const controlerRead = (id) => {
    GeneralNotificationService.markNotificationAsRead([id])
      .then(() => {
        reloadNotifications(id);
      })
      .catch((err) => toast.error(err));
  };

  function calculateDateDiference(startDate, endDate) {
    // let diference = Math.abs(endDate.getTime() - startDate.getTime());
    const diferenceUpdated = formatDistance(startDate, endDate, { locale: pt });
    const diferenceUpdatedDetailed = format(
      new Date(startDate),
      `dd 'de' LLLL 'às' HH:mm`,
      {
        locale: ptBR
      }
    );
    return `${diferenceUpdatedDetailed} (${diferenceUpdated})`;
  }

  const loadDns = () => {
    const dns = window.location.origin;
    if (dns === 'http://localhost:3000') {
      return 'https://flexucteste.g4flex.com.br/';
    } else {
      return dns;
    }
  };

  useEffect(() => {
    if (props.data.length > 9 && props.data.length !== props.totalRegister) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          props.setPage((currentPage) => currentPage + 1);
        }
      });

      intersectionObserver.observe(document.querySelector('#lazy-loading'));
      return () => intersectionObserver.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  useEffect(() => {
    if (props.data.length > 9) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          props.setPage(1);
        }
      });

      intersectionObserver.observe(document.querySelector('#set_page_to_1'));
      return () => intersectionObserver.disconnect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  const handleNotificationClick = (notification) => {
    if (
      notification?.type === 'email_protocol' ||
      notification?.type === 'supervisor_alert'
    ) {
      if (notification?.parent?.id !== undefined) {
        history.push(`/admin/protocol/${notification.parent.id}/update`, {
          recordTypeId: notification.parent.recordTypeId,
          returnPath: '/admin/protocol'
        });
      }
    }
  };

  const handleCallProtocolScheduling = (notification) => {
    const telephoneSplited = notification.match(/\d{10,11}/g);
    const telephone = telephoneSplited[0];
    makeCall(telephone);
  };

  const handleCancelProtocolScheduling = (notification) => {
    if (!window.confirm('Cancelar agendamento?')) {
      return;
    } else {
      ProtocolSchedulingService.cancelProtocolSchedule(notification.parentId)
        .then(() => {
          toast.success('Agendamento cancelado com sucesso', {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .catch((error) => {
          toast.error(
            error || 'Houve um erro ao tentar cancelar o agendamento.',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
        });
    }
  };

  const handleRescheduleProtocolScheduling = (notification) => {
    props.setIsSchedulingModalOpen(true);
    props.setSchedulingId(notification.parentId);
  };

  return (
    <>
      {props.data.length > 9 && <div id="set_page_to_1"></div>}
      {props.data.map((res, key) => {
        if (res.createdAt !== undefined) {
          const startDate = new Date(res.createdAt);
          const endDate = new Date();
          const dataToView = calculateDateDiference(startDate, endDate);
          const notificationDescription = arrayTypesOfNotifications.find(
            (item) => {
              return item.type === res.type;
            }
          );
          return (
            <NotificationItemWrapper key={key}>
              <Content
                key={key}
                title={notificationDescription?.title}
                onClick={() => {
                  handleNotificationClick(res);
                  if (res.isRead === false) {
                    controlerRead(res.id);
                  }
                }}
                className="hoverContent"
              >
                {!res.isRead && (
                  <ToViewButton
                    type="button"
                    onClick={() => {
                      if (res.overtimeId) {
                        controlerRead(res.id);
                      }
                    }}
                  ></ToViewButton>
                )}

                {res.user?.profile_pic ? (
                  <ImageLogo
                    src={`${loadDns()}/${res.user.profile_pic}`}
                    alt="logo"
                  />
                ) : (
                  <ImageLogo src={LogoG4} alt="logo" />
                )}
                {arrayTypesOfNotifications.map((item, index) => {
                  if (item.type === res.type) {
                    return (
                      <IconLogoWrapper color={item.color} key={index}>
                        <IconLogo key={index} className={item.icon}></IconLogo>
                      </IconLogoWrapper>
                    );
                  }
                })}
                <ContentGridDisplay>
                  <SourceRequest>
                    {res.content} Aberto por {res.from} para {res.to}.
                  </SourceRequest>
                  <TimeCount>{dataToView}</TimeCount>
                  {res.type === 'overtime' &&
                    !res.isReadOnly &&
                    res?.parent?.userStatusShift === 'PENDING' && (
                      <ContentButtonDisplay>
                        <Button
                          color="success"
                          size="sm"
                          onClick={() =>
                            controlerStatus(res.parentId, 'APPROVED')
                          }
                        >
                          Aceitar
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() =>
                            controlerStatus(res.parentId, 'REJECTED')
                          }
                        >
                          Recusar
                        </Button>
                      </ContentButtonDisplay>
                    )}
                  {res.type === 'timeshift' &&
                    !res.isReadOnly &&
                    res.parent.supervisorStatusShift === 'PENDING' && (
                      <ContentButtonDisplay>
                        <Button
                          color="success"
                          size="sm"
                          onClick={() =>
                            controlerStatusTimeShift(res.parentId, 'APPROVED')
                          }
                        >
                          Aceitar
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() =>
                            controlerStatusTimeShift(res.parentId, 'REJECTED')
                          }
                        >
                          Recusar
                        </Button>
                      </ContentButtonDisplay>
                    )}
                  {res.type === 'timeshift' &&
                    !res.isReadOnly &&
                    res.parent.supervisorStatusShift === 'APPROVED' &&
                    res?.parent?.userStatusShift === 'PENDING' &&
                    res.parent.userRequestedShift ===
                      localStorage.getItem('login') && (
                      <ContentButtonDisplay>
                        <Button
                          color="success"
                          size="sm"
                          onClick={() =>
                            controlerStatusTimeShiftAgent(
                              res.parentId,
                              'APPROVED'
                            )
                          }
                        >
                          Aceitar
                        </Button>
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() =>
                            controlerStatusTimeShiftAgent(
                              res.parentId,
                              'REJECTED'
                            )
                          }
                        >
                          Recusar
                        </Button>
                      </ContentButtonDisplay>
                    )}

                  {res.type === 'scheduling_protocol' && (
                    <ContentButtonDisplay style={{ display: 'flex' }}>
                      {res.content && res.content.match(/\d{10,11}/g) && res.content.includes('para o número') ? (<Button
                        size="md"
                        color="success"
                        disabled={
                          !canMakeCall(res.content.match(/\d{10,11}/g)[0])
                        }
                        onClick={() =>
                          handleCallProtocolScheduling(res.content)
                        }
                      >
                        Ligar
                      </Button>):null}
                      
                      <Button
                        size="md"
                        color="warning"
                        onClick={() => handleRescheduleProtocolScheduling(res)}
                      >
                        Reagendar
                      </Button>

                      <Button
                        size="md"
                        color="danger"
                        onClick={() => handleCancelProtocolScheduling(res)}
                      >
                        Cancelar
                      </Button>
                    </ContentButtonDisplay>
                  )}
                </ContentGridDisplay>
              </Content>
            </NotificationItemWrapper>
          );
        }
      })}

      {props.data.length > 9 && props.data.length !== props.totalRegister && (
        <div id="lazy-loading"></div>
      )}
    </>
  );
}
