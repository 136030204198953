import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row
} from 'reactstrap';

import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import FlexSelect from 'components/Inputs/FlexSelect';
import QueueService from '../../../../../Queue/service';
import { removeAnsweredConversation } from 'store/action/answered-conversations-action';
import { removeConversationInAttendance } from 'store/action/conversation-in-attendance-action';
import { useSocketConversationInstagram } from 'hooks/useSocketConversationInstagram';
import { useSocketLoggedAttendants } from '../../hooks/useSocketLoggedAttendants';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import { GrTransaction } from 'react-icons/gr';
import { SvgContainer } from '../styles';

const TransferManyButton = ({
  tooltipTitle = '',
  id,
  isStringArray = false,
  altIcon = false
}) => {
  const { socketInstagramConversation: socketInstagram } =
    useSocketConversationInstagram();

  const { loggedAttendants } = useSocketLoggedAttendants();

  const { control, handleSubmit, errors, setValue } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [queues, setQueues] = useState([]);
  const dispatch = useDispatch();
  const [isTransferByAgentEspecific, setInsTransferByAgentEspecific] =
    useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  useEffect(() => {
    if (isOpen) {
      QueueService.getAll(true)
        .then((response) => {
          // let arrayQueues = [];
          // const attendantQueues = JSON.parse(localStorage.getItem('queues'));
          // response.data.data.forEach((queue) => {
          //   attendantQueues.forEach((attendantQueue) => {
          //     if (queue.id === attendantQueue.id) arrayQueues.push(queue);
          //   });
          // });
          let filteredQueues = response.data.data.filter((fila) => {
            return fila['is_chat'];
          });
          setQueues([...filteredQueues]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isOpen]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = async (data) => {
    const info = isStringArray ? id : id.map((item) => item['_id']);
    try {
      if (isTransferByAgentEspecific) {
        const agentFilter = loggedAttendants.find(
          (agent) => data.user === agent.login
        );
        const atendente = {
          name: agentFilter.name,
          login: agentFilter.login,
          id_socket_atendente: agentFilter.socket_id
        };
        socketInstagram.emit('transferir_multiplas_conversas_atendente', {
          ids: info,
          atendente,
          responsavelPelaTransferencia: {
            name: localStorage.getItem('name'),
            login: localStorage.getItem('login')
          }
        });
      } else {
        const formatedData = { ...data };

        socketInstagram.emit('transferir_multiplas_conversas', {
          ids: info,
          fila: formatedData.fila,
          formulario: {
            ...formatedData
          },
          responsavelPelaTransferencia: {
            name: localStorage.getItem('name'),
            login: localStorage.getItem('login')
          }
        });
      }
      dispatch(removeAnsweredConversation(id));
      dispatch(removeConversationInAttendance());
      toast.success('Conversa transferida com sucesso!', {
        autoClose: 3000,
        closeOnClick: true
      });
      toggleModal();
    } catch (err) {
      console.log('err: ', err);
      toast.error('Erro ao transferir conversa!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      {altIcon
        ? verifyRoles(['CHAT_MULTIPLE_TRANSFER']) && (
            <Button
              size="sm"
              color="link"
              title={tooltipTitle}
              onClick={toggleModal}
              disabled={!id.length}
              style={{
                minHeight: '46px',
                background: 'none',
                border: 'none',
                color: systemConfiguration.primary_color,
                paddingBottom: '6px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <SvgContainer color={systemConfiguration.primary_color}>
                <GrTransaction
                  style={{
                    stroke: 'blue'
                  }}
                  size="20"
                  title="Transferir múltiplas conversas"
                />
              </SvgContainer>
            </Button>
          )
        : verifyRoles(['CHAT_TRANSFERS']) && (
            <Button
              size="sm"
              color="primary"
              title={tooltipTitle}
              onClick={toggleModal}
              disabled={!id.length}
              style={{ minHeight: '46px' }}
            >
              Transferir várias
            </Button>
          )}
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {`Transferir conversas`}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-35px' }} />
          <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Col md="12">
                <FormGroup check>
                  <Input
                    type="checkbox"
                    checked={isTransferByAgentEspecific}
                    onChange={() =>
                      setInsTransferByAgentEspecific(
                        !isTransferByAgentEspecific
                      )
                    }
                  />
                  <label className="form-control-label">
                    Trocar forma de transferência para atendente específico
                  </label>
                </FormGroup>
              </Col>
            </Row>
            {!isTransferByAgentEspecific ? (
              <Row className="mb-3">
                <Col md="12">
                  <label className="form-control-label">Fila*</label>
                  <Controller
                    render={(props) => (
                      <FlexSelect
                        dataOptions={queues}
                        isMulti={false}
                        isClearable={false}
                        value={props.value}
                        valueController={setValue}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        fieldName="fila"
                        labelName="name"
                        valueName="name"
                      />
                    )}
                    control={control}
                    name="fila"
                    defaultValue=""
                    rules={{ required: 'A fila é obrigatória' }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="fila"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
              </Row>
            ) : (
              <Row className="mb-3">
                <Col md="12">
                  <label className="form-control-label">Usuário*</label>
                  <Controller
                    render={(props) => (
                      <FlexSelect
                        dataOptions={loggedAttendants}
                        isMulti={false}
                        isClearable={true}
                        value={props.value}
                        valueController={setValue}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        fieldName="user"
                        labelName="name"
                        valueName="login"
                      />
                    )}
                    control={control}
                    name="user"
                    defaultValue=""
                    rules={{ required: 'Usuário é obrigatório!' }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="user"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
              </Row>
            )}

            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
              style={buttonStyle(systemConfiguration.cancelation_button_color)}
            >
              Fechar
            </Button>

            <Button
              color="primary"
              type="submit"
              style={buttonStyle(systemConfiguration.confirmation_button_color)}
            >
              Transferir
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TransferManyButton;
