import React, { useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Form, Card, CardBody, CardHeader } from 'reactstrap';
import FlexSelect from '../../../Inputs/FlexSelect';
import { toast } from 'react-toastify';
import ReactButton from 'components/Buttons/ReactButton';

const LoginForm = ({
  queues,
  extensions,
  onSubmitHandler,
  setIsDropdownOpen
}) => {
  const { control, handleSubmit, setValue } = useForm();

  const handleSubmitLoginForm = (data) => {
    const hasNoSelectedQueues = !data.queueIds || !data.queueIds.length;
    const hasNoSelectedExtension = !data.extension;

    if (hasNoSelectedQueues) return toast.error('Selecione pelo menos 1 fila');
    if (hasNoSelectedExtension) return toast.error('Selecione um ramal');

    onSubmitHandler(data);
  };

  useEffect(() => {
    setTimeout(() => {
      setIsDropdownOpen(false);
    }, 30000);
  }, []);

  return (
    <Card>
      <CardHeader>
        Olá, {localStorage.getItem('name')} escolha a fila e o ramal desejado
        para começar o atendimento.
      </CardHeader>
      <CardBody>
        <Form
          className="needs-validation"
          onSubmit={handleSubmit(handleSubmitLoginForm)}
        >
          <div className="mb-2">
            <Controller
              render={(props) => (
                <FlexSelect
                  isClearable={true}
                  dataOptions={queues.filter((queue) => queue.is_telephony)}
                  isMulti={true}
                  closeMenuOnSelect={false}
                  value={props.value}
                  valueController={setValue}
                  fieldName="queueIds"
                  labelName="name"
                  valueName="id"
                  minHeight="1rem"
                />
              )}
              control={control}
              name="queueIds"
              defaultValue=""
            />
          </div>
          <div className="mb-2">
            <Controller
              render={(props) => (
                <FlexSelect
                  closeMenuOnSelect={true}
                  isClearable={false}
                  dataOptions={extensions}
                  placeholder="Selecione o ramal"
                  isMulti={false}
                  value={props.value}
                  valueController={setValue}
                  fieldName="extension"
                  labelName="extension_number"
                  valueName="extension_number"
                  minHeight="1rem"
                />
              )}
              control={control}
              name="extension"
              defaultValue=""
              className="mt-2"
            />
          </div>
          <div className="text-right">
            <ReactButton type="submit">Iniciar Atendimento</ReactButton>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default LoginForm;
