import React, { createContext, useContext, useEffect } from 'react';

const useFlexCaseBackEndConnectionSocketContext = createContext();

export function FlexCaseBackEndConnectionSocketProvider({
  children,
  socketFlexCaseBackEnd
}) {
  useEffect(() => {
    return () => {
      socketFlexCaseBackEnd.disconnect();
    };
  }, [socketFlexCaseBackEnd]);

  return (
    <>
      <useFlexCaseBackEndConnectionSocketContext.Provider
        value={{
          socketFlexCaseBackEnd
        }}
      >
        {children}
      </useFlexCaseBackEndConnectionSocketContext.Provider>
    </>
  );
}

export function useFlexCaseBackEndConnectionSocket() {
  const context = useContext(useFlexCaseBackEndConnectionSocketContext);
  return context;
}
