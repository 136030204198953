import React from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import PaperForm from '../components/PaperForm';
import PaperService from '../service/index';
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap';

export default function CreatePaper() {
  const history = useHistory();

  const handleCreatePaper = async (data) => {
    try {
      const questions = data.questions.map((question) => {
        return {
          question_id: question.id,
          description: question.description,
          weightage: question.weightage == 'Crítico' ? 1 : question.weightage,
          type: question.weightage == 'Crítico' ? 'CRITICAL' : 'WEIGHTAGE',
          enabled: question.enabled,
          required: question.required
        };
      });

      data.questions = questions;

      await PaperService.create(data);

      toast.success('Questionário cadastrado com sucesso!');
      history.push('/admin/paper');
    } catch (err) {
      toast.error('Ocorreu um erro ao cadastrar questionário.', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const handleCancel = () => {
    history.push('/admin/paper');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/paper"
        buttonTitle="Voltar para a pagina de questionários"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    Configuração Questionário de Monitoria
                  </h3>
                </CardHeader>
                <CardBody>
                  <PaperForm
                    onSubmit={handleCreatePaper}
                    onCancel={handleCancel}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}
