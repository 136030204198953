import styled from 'styled-components';

export const PrintConversation = styled.div`
  @media print {
    margin-top: 8rem;
    margin-left: 3rem;
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100%;

    img {
      width: 100%;
      height: 13rem;
    }

    &:first-child img#logoG4PDF {
      width: 15rem;
      position: absolute;
      top: 0;
      right: 0;
      height: 8rem;
      margin-right: 2.5rem;
      z-index: 1;
      object-fit: cover;
    }
  }
`;
