import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardBody, Container, Row } from 'reactstrap';

import Loader from 'components/Loader';
import SimpleHeader from 'components/Headers/SimpleHeader';
import { useRecordTypesByUser } from 'views/RecordType/hooks/useRecordTypesByUser';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import ReportOpportunityDataClosedWon from './OpportunityReportList';
import ListOpportunityHeader from '../../../../Opportunity/list/components/ListOpportunitiesHeader';
import { useMemorizeFilters } from '../../../../../../hooks/useMemorizeInputsFilters';

function ReportOpportunityRevenueIndex() {
  const [selectedRecordType, setSelectedRecordType] = useState(null);
  const { recordTypes, isLoadingRecordTypes, getRecordTypesByUser } =
    useRecordTypesByUser(entitiesName.OPPORTUNITY);
  const { getMemorizedFilters } = useMemorizeFilters('CRM - Opportunity');

  useEffect(() => {
    getRecordTypesByUser();
  }, [getRecordTypesByUser]);

  useEffect(() => {
    if (recordTypes && recordTypes.length) {
      const merorizedRecordType = getMemorizedFilters();
      if (!merorizedRecordType?.recordTypeId) {
        setSelectedRecordType(recordTypes[0]);
        return;
      }
      const recordType = recordTypes.find(
        (rt) => rt.id === merorizedRecordType.recordTypeId
      );
      if (!recordType) {
        setSelectedRecordType(recordTypes[0]);
        return;
      }

      setSelectedRecordType(recordType);
    }
  }, [getMemorizedFilters, recordTypes]);

  const handleSelectRecordType = useCallback(
    (recordTypeId) => {
      setSelectedRecordType(recordTypes.find((rt) => rt.id === recordTypeId));
    },
    [recordTypes]
  );

  const getChildToRender = () => {
    if (isLoadingRecordTypes) {
      return (
        <div className="col-12">
          <Row className="justify-content-md-center">
            <Loader />
          </Row>
        </div>
      );
    }

    if (!recordTypes && !isLoadingRecordTypes) {
      return (
        <div>
          <span className="small">
            Você não tem acesso a nenhum tipo de lead.
          </span>
        </div>
      );
    }

    return (
      <ReportOpportunityDataClosedWon
        selectedRecordTypeId={selectedRecordType?.id}
        setSelectedRecordType={setSelectedRecordType}
        recordTypes={recordTypes}
      />
    );
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      ></SimpleHeader>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <ListOpportunityHeader
                  title="Relatório de previsão de receitas da Oportunidade"
                  recordTypes={recordTypes}
                  selectedRecordType={selectedRecordType}
                  handleSelectRecordType={handleSelectRecordType}
                />
                <CardBody className="pt-2">{getChildToRender()}</CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default ReportOpportunityRevenueIndex;
