import React from 'react';
import ReportCard from '../ReportCard';
import styled from 'styled-components';

// const Container = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: space-between;
// `;
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* justify-content: space-between; */
  gap: 0.5rem;
`;

const ReportCardsContainer = ({ data }) => {
  return (
    <Container>
      <ReportCard
        data={{ title: 'Quantidade Total', value: data.amount || '0' }}
      />
      <ReportCard
        data={{ title: 'Tentativa Atual', value: data.maxAttempts || '0' }}
      />
      <ReportCard
        data={{
          title: 'Realizadas com Sucesso',
          value: data.returnSuccess || '0'
        }}
      />
      <ReportCard
        data={{
          title: 'Realizadas com Atendimento',
          value: data.numberAnswered || '0'
        }}
      />
      <ReportCard
        data={{
          title: 'Pendentes da Tentativa',
          value: data.pendingAttempts || '0'
        }}
      />
      <ReportCard
        data={{
          title: 'Tempo Total de Conclusão',
          value: data.completionTimeFormatted || '00:00:00'
        }}
      />
      <ReportCard
        data={{
          title: 'Tempo Médio de Duração',
          value: data.avgBillsec || '00:00:00'
        }}
      />
      <ReportCard
        data={{
          title: 'Tempo Máximo de Duração',
          value: data.maxBillsec || '00:00:00'
        }}
      />
    </Container>
  );
};

export default ReportCardsContainer;
