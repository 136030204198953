import { useCallback, useEffect, useState } from 'react';

import { queueAgentEvents } from 'components/Navbars/ServicePanel/utils/events';
import { queueAgentStates } from 'components/Navbars/ServicePanel/utils/states';

export const useAgentDashboardEvents = (agent, extension, socket) => {
  const [agentSessions, setAgentSessions] = useState([]);

  const handleAgentSessions = useCallback(
    (agentSessions) => {
      setAgentSessions(agentSessions);
    },
    [setAgentSessions]
  );

  const handleAddSession = useCallback((newSession) => {
    setAgentSessions((currentSessions) => {
      if (newSession.agent.agent_code !== agent) {
        return currentSessions;
      }

      const alreadyAdded = currentSessions.some((session) => {
        return (
          session.extension === newSession.extension &&
          session.queue.name === newSession.agent.queue.name &&
          session.state === newSession.state
        );
      });

      if (alreadyAdded) return currentSessions;

      const newSessionDTO = {
        state: newSession.state,
        extension: newSession.extension,
        caller: null,
        date: newSession.date,
        is_incoming_call: newSession.isIncomingCall,
        queue: newSession.agent.queue,
        currentQueue: null
      };

      return [...currentSessions, newSessionDTO];
    });
  }, []);

  const handleRemoveSession = useCallback((session) => {
    setAgentSessions((currentSessions) => {
      if (session.extension !== extension) return currentSessions;

      return currentSessions.filter(
        (item) =>
          item.queue.name !== session.queueName ||
          item.extension !== session.extension
      );
    });
  }, []);

  const changeToUnplaced = useCallback((data) => {
    setAgentSessions((currentSessions) => {
      if (data.extension !== extension) return currentSessions;

      return currentSessions.map((session) => ({
        ...session,
        pause: null,
        state: queueAgentStates.UNPLACED,
        caller: null,
        date: data.date,
        is_incoming_call: null,
        currentQueue: null
      }));
    });
  }, []);

  const changeToPause = useCallback(
    (data) => {
      setAgentSessions((currentSessions) => {
        if (data.extension !== extension) return currentSessions;

        return currentSessions.map((session) => ({
          ...session,
          pause: data.agent.pause,
          state: queueAgentStates.PAUSE,
          caller: null,
          date: data.date,
          is_incoming_call: null,
          currentQueue: null
        }));
      });
    },
    [extension]
  );

  const changeToAvailable = useCallback((data) => {
    setAgentSessions((currentSessions) => {
      if (data.extension !== extension) return currentSessions;

      return currentSessions.map((session) => ({
        ...session,
        pause: null,
        state: queueAgentStates.AVAILABLE,
        caller: null,
        date: data.date,
        is_incoming_call: null,
        currentQueue: null
      }));
    });
  }, []);

  const changeToRing = useCallback((data) => {
    if (data.isIncomingCall) return;

    setAgentSessions((currentSessions) => {
      if (data.extension !== extension) return currentSessions;

      return currentSessions.map((session) => ({
        ...session,
        pause: null,
        state: queueAgentStates.RING,
        caller: data.caller,
        date: data.date,
        is_incoming_call: data.isIncomingCall,
        currentQueue: data.agent.queue.name
      }));
    });
  }, []);

  const changeToRinging = useCallback((data) => {
    setAgentSessions((currentSessions) => {
      if (data.extension !== extension) return currentSessions;

      return currentSessions.map((session) => ({
        ...session,
        pause: null,
        state: queueAgentStates.RINGING,
        caller: data.caller,
        date: data.date,
        is_incoming_call: true,
        currentQueue: data.agent.queue.name
      }));
    });
  }, []);

  useEffect(() => {
    socket.on(queueAgentEvents.QUEUE_MEMBER_ADDED, handleAddSession);

    return () =>
      socket.off(queueAgentEvents.QUEUE_MEMBER_ADDED, handleAddSession);
  }, [socket, handleAddSession]);

  useEffect(() => {
    socket.on(queueAgentEvents.QUEUE_MEMBER_REMOVED, handleRemoveSession);

    return () =>
      socket.off(queueAgentEvents.QUEUE_MEMBER_REMOVED, handleRemoveSession);
  }, [socket, handleRemoveSession]);

  useEffect(() => {
    socket.on(queueAgentEvents.QUEUE_MEMBER_UNREGISTERED, changeToUnplaced);

    return () =>
      socket.off(queueAgentEvents.QUEUE_MEMBER_UNREGISTERED, changeToUnplaced);
  }, [socket, changeToUnplaced]);

  useEffect(() => {
    socket.on(queueAgentEvents.QUEUE_MEMBER_PAUSE, changeToPause);

    return () => socket.off(queueAgentEvents.QUEUE_MEMBER_PAUSE, changeToPause);
  }, [socket, changeToPause]);

  useEffect(() => {
    socket.on(queueAgentEvents.QUEUE_MEMBER_AVAILABLE, changeToAvailable);

    return () =>
      socket.off(queueAgentEvents.QUEUE_MEMBER_AVAILABLE, changeToAvailable);
  }, [socket, changeToAvailable]);

  useEffect(() => {
    socket.on(queueAgentEvents.QUEUE_MEMBER_RING, changeToRing);

    return () => socket.off(queueAgentEvents.QUEUE_MEMBER_RING, changeToRing);
  }, [socket, changeToRing]);

  useEffect(() => {
    socket.on(queueAgentEvents.QUEUE_MEMBER_RINGING, changeToRinging);

    return () =>
      socket.off(queueAgentEvents.QUEUE_MEMBER_RINGING, changeToRinging);
  }, [socket, changeToRinging]);

  return { agentSessions, handleAgentSessions };
};
