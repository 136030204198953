import React, { useState, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import { toast } from 'react-toastify';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import DefaultModal from 'components/Modal/DefaultModal';
import Pagination from 'components/Pagination';
import ReactButton from 'components/Buttons/ReactButton';
import ReactLoader from 'components/Loader';
import ModalConfirmation from 'components/Modal/ConfirmationModal';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from 'assets/styles/table_custom_style';

import FormModal from '../FormModal';
import UploadModal from '../UploadModal';
import { DEFAULT_PAGE_SIZE, useFetch } from './hooks/useFetch';
import { useSelect } from './hooks/useSelect';
import { useSearch } from './hooks/useSearch';
import BlacklistService from '../../../service';

// TODO: resetar os states activePage, search, selectedRows, isAllRowsSelected
// e isAllItemsSelected quando fechar o modal
const ListModal = ({ isModalOpen, onModalToggle, blacklistId, ...props }) => {
  const [activePage, setActivePage] = useState(1);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [resourceId, setResourceId] = useState([]);
  const { search, debouncedSearch } = useSearch();
  const { data, quantity, loading, error, errorMessage, refecth } = useFetch(
    blacklistId,
    search,
    activePage
  );
  const {
    state: { selectedRows, isAllRowsSelected, isAllItemsSelected },
    select,
    selectAllRows,
    selectAllItems,
    unselectAllItems
  } = useSelect();

  useEffect(() => {
    if (!error && !errorMessage) return;

    onModalToggle(false);
    toast.error(errorMessage, {
      autoClose: 3000,
      closeOnClick: true
    });
  }, [error, errorMessage]);

  useEffect(() => {
    const totalPages = Math.ceil(quantity / DEFAULT_PAGE_SIZE);

    if (activePage > totalPages) {
      setActivePage(totalPages || 1);
    }
  }, [quantity]);

  const handleSelectAllItems = async () => {
    try {
      const res = await BlacklistService.getAllBlacklistNumbers(blacklistId);

      selectAllItems(res.data.data);
    } catch (error) {
      toast.error('Erro ao selecionar todos os números');
    }
  };

  const handleDelete = async (ids) => {
    try {
      await BlacklistService.deleteBlacklistNumber(blacklistId, ids);

      unselectAllItems();
      refecth();
      toast.success('Número(s) removido(s) com sucesso!');
    } catch (error) {
      toast.error(
        'Ocorreu um erro ao remover o(s) número(s) da lista de bloqueio'
      );
    } finally {
      setIsConfirmationModalOpen(false);
    }
  };

  return (
    <>
      <DefaultModal
        show={isModalOpen}
        onModalToggle={onModalToggle}
        title="Gerenciar números da lista de bloqueio"
        showCloseButton={false}
        modalContainerClasses="modal-xl"
        centered={false}
        {...props}
      >
        <>
          <Row>
            <Col className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column justify-content-between">
                {isAllRowsSelected && quantity > selectedRows.length && (
                  <>
                    <span>
                      Todos <strong>{selectedRows.length}</strong> números nesta
                      página estão selecionados
                    </span>
                    <div className="small">
                      <Button
                        outline={true}
                        size="sm"
                        color=""
                        onClick={handleSelectAllItems}
                      >
                        <i className="fas fa-check"></i> Selecionar todos (
                        {quantity})
                      </Button>
                    </div>
                  </>
                )}
                {isAllItemsSelected && (
                  <>
                    <span>
                      Todos <strong>{selectedRows.length}</strong> números nesta
                      lista estão selecionados
                    </span>
                    <div className="small">
                      <Button
                        outline={true}
                        size="sm"
                        color=""
                        onClick={unselectAllItems}
                      >
                        <i className="fas fa-undo"></i> Desfazer seleção
                      </Button>
                    </div>
                  </>
                )}
                {selectedRows.length !== 0 && (
                  <div className="small">
                    <Button
                      outline={true}
                      size="sm"
                      color=""
                      onClick={() => {
                        setResourceId(selectedRows.map((row) => row.id));
                        setIsConfirmationModalOpen(true);
                      }}
                    >
                      <i className="fas fa-trash"></i> Excluir Selecionados (
                      {selectedRows.length})
                    </Button>
                  </div>
                )}
              </div>
              <div>
                <ReactButton
                  btnColor="confirmation"
                  type="submit"
                  size="sm"
                  onClick={() => setIsImportModalOpen(true)}
                >
                  Importar Lista
                </ReactButton>
                <ReactButton
                  btnColor="confirmation"
                  type="submit"
                  size="sm"
                  onClick={() => setIsFormModalOpen(true)}
                >
                  + Adicionar Contato
                </ReactButton>
              </div>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-search" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  size="md"
                  placeholder="Buscar por número"
                  type="text"
                  defaultValue=""
                  onChange={(e) => debouncedSearch(e.target.value)}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <ToolkitProvider
                data={data}
                keyField="id"
                loading={loading}
                columns={[
                  {
                    dataField: 'origin',
                    text: 'Origem',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (cell) =>
                      cell.replace(
                        /^(\d{2})?(\d{2})(\d{4,5})(\d{4})$/,
                        (match, s1, s2, s3, s4) => {
                          const prev = `(${s2}) ${s3}-${s4}`;

                          if (s1) return `+${s1} ${prev}`;

                          return prev;
                        }
                      )
                  },
                  {
                    dataField: 'delete',
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    text: 'deletar',
                    // eslint-disable-next-line react/display-name
                    formatter: (_, row) => {
                      return (
                        <Button
                          color="danger"
                          size="sm"
                          onClick={() => {
                            setResourceId([row.id]);
                            setIsConfirmationModalOpen(true);
                          }}
                        >
                          <li className="fas fa-trash"></li>
                        </Button>
                      );
                    }
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    {loading ? (
                      <Row className="justify-content-md-center">
                        <ReactLoader />
                      </Row>
                    ) : (
                      <BootstrapTable
                        {...props.baseProps}
                        selectRow={{
                          mode: 'checkbox',
                          selected: selectedRows.map((r) => r.id),
                          onSelect: (row, isSelected) =>
                            select(row, isSelected, data),
                          onSelectAll: selectAllRows,
                          selectColumnStyle: individual_header_style
                        }}
                        bootstrap4={true}
                        bordered={false}
                        classes="align-items-center"
                      />
                    )}
                  </div>
                )}
              </ToolkitProvider>
              {!!quantity && quantity > DEFAULT_PAGE_SIZE && (
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={(pageNumber) => {
                    setActivePage(pageNumber);
                    unselectAllItems();
                  }}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col style={{ maxWidth: '8rem' }}>
              <ReactButton
                btnColor="confirmation"
                type="submit"
                size="sm"
                onClick={() => onModalToggle(false)}
                block
              >
                OK
              </ReactButton>
            </Col>
          </Row>
        </>
      </DefaultModal>
      <ModalConfirmation
        isModalOpen={isConfirmationModalOpen}
        onModalToggle={setIsConfirmationModalOpen}
        unmountOnClose={true}
        idResource={resourceId}
        deleteResource={handleDelete}
        message={'Tem certeza que deseja excluir este(s) número(s)?'}
      />
      <FormModal
        isModalOpen={isFormModalOpen}
        onModalToggle={setIsFormModalOpen}
        unmountOnClose={true}
        onFormSubmit={() => {
          unselectAllItems();
          refecth();
        }}
        blacklistId={blacklistId}
      />
      <UploadModal
        isModalOpen={isImportModalOpen}
        onModalToggle={setIsImportModalOpen}
        unmountOnClose={true}
        onImport={() => {
          unselectAllItems();
          refecth();
        }}
        blacklistId={blacklistId}
      />
    </>
  );
};

export default ListModal;
