import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import EmailConfigService from '../service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { useRecordTypes } from '../../RecordType/hooks/useRecordTypes';
import FlexSelect from 'components/Inputs/FlexSelect';
import {
  emailConfigTypes,
  emailConfigTypesDisplay,
  emailConfigProvider,
  emailConfigProviderDisplay
} from '../utils/constants';
import EntryEmailForm from './components/EntryEmailForm';
import ExitEmailForm from './components/ExitEmailForm';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { ScreenPrompt } from 'components/ScreenPrompt';

const EmailSettingsForm = (props) => {
  const history = useHistory();
  const { reset, control, handleSubmit, errors, setValue, watch, formState } =
    useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const { recordTypes, isLoadingRecordTypes } = useRecordTypes(
    entitiesName.PROTOCOL
  );
  const [selectedRecordType, setSelectedRecordType] = useState(null);

  const { id } = props.match.params;
  const selectedType = watch('type' || emailConfigTypes.ENTRY);
  const selectedProvider = watch('provider' || emailConfigProvider.GOOGLE);
  const isEntryMicrosoft =
    selectedType === emailConfigTypes.ENTRY &&
    selectedProvider === emailConfigProvider.MICROSOFT;

  useEffect(() => {
    if (id) {
      setLoading(true);
      EmailConfigService.get(id)
        .then((res) => {
          const emailData = res.data.content;
          reset({
            ...emailData,
            smtpHost: emailData.smtp_host,
            smtpPort: emailData.smtp_port,
            imapHost: emailData.imap_host,
            imapPort: emailData.imap_port,
            recordTypeId: emailData.record_type_id,
            recordTypeIds:
              emailData.type === emailConfigTypes.EXIT
                ? emailData.recordTypes.map((rt) => rt.id)
                : undefined
          });
          setIsUpdate(true);
        })
        .catch((err) => {
          const { data } = err.response;
          toast.error(
            data.message || 'Erro ao listar a configuração de email!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      const formatedData = {
        ...data,
        imapPort: data.imapPort ? Number(data.imapPort) : undefined,
        smtpPort: data.smtpPort ? Number(data.smtpPort) : undefined
      };

      isUpdate
        ? await EmailConfigService.update(id, formatedData)
        : await EmailConfigService.create(formatedData);

      history.push('/admin/email');
      toast.success(
        `Configuração de email ${
          isUpdate ? 'atualizada' : 'criada'
        } com sucesso`,
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    } catch (err) {
      const { data } = err.response;
      toast.error(
        data.message || 'Erro ao cadastrar a configuração de email!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/email');
  };

  useEffect(() => {
    if (recordTypes) {
      setSelectedRecordType({ label: 'Padrão', value: 1 });
    }
  }, [recordTypes]);

  useEffect(() => {
    if (!selectedRecordType) {
      return setSelectedRecordType(null);
    }
  }, [selectedRecordType]);

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row className="mb-3">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Configurações de Email</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                      autoComplete="off"
                    >
                      <Row className="mb-3">
                        <Col md="6" className="mt-2">
                          <label className="form-control-label">
                            Tipos de configuração*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={emailConfigTypesDisplay}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="type"
                                labelName="label"
                                valueName="value"
                                isClearable={false}
                              />
                            )}
                            control={control}
                            name="type"
                            defaultValue={emailConfigTypes.ENTRY}
                            rules={{
                              required:
                                'Selecione o tipo de configuração de email'
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="type"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6" className="mt-2">
                          <label className="form-control-label">
                            Provedor*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={emailConfigProviderDisplay}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="provider"
                                labelName="label"
                                valueName="value"
                                isClearable={false}
                              />
                            )}
                            control={control}
                            name="provider"
                            rules={{
                              required: 'Selecione o provedor de email'
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="provider"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      {selectedType === emailConfigTypes.ENTRY && (
                        <EntryEmailForm
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          isUpdate={isUpdate}
                          recordTypes={recordTypes}
                          isLoadingRecordTypes={isLoadingRecordTypes}
                          isEntryMicrosoft={isEntryMicrosoft}
                        />
                      )}

                      {selectedType === emailConfigTypes.EXIT && (
                        <ExitEmailForm
                          control={control}
                          errors={errors}
                          setValue={setValue}
                          isUpdate={isUpdate}
                          recordTypes={recordTypes}
                          isLoadingRecordTypes={isLoadingRecordTypes}
                        />
                      )}

                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default EmailSettingsForm;
