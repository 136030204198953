import React from 'react';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { TotalRegister } from 'components/TotalRegister';

import {
  individual_column_style,
  individual_header_style
} from 'assets/styles/table_custom_style';

const Syntetical = ({ quantity, reports }) => {
  return (
    <>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            {quantity >= 0 && <TotalRegister totalRegister={quantity} />}
            <Card>
              <ToolkitProvider
                data={reports}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'usrLogin',
                    text: 'Atendente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'total_elapsed_time',
                    text: 'Tempo total logado',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'total_sessions',
                    text: 'Total de logins realizados',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Syntetical;
// sintetico lucas => 02:29:05
