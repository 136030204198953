import React from 'react';
import DefaultModal from '../../../../components/Modal/DefaultModal';
import { CopyButton } from './components/CopyButton';
import { toast } from 'react-toastify';
import { Card } from 'reactstrap';
import { ContentLink } from './styled';

const ModalCopyLink = ({ isModalOpen, onModalToggle, link, ...props }) => {
  const copyToClipBoard = async () => {
    try {
      await navigator.clipboard.writeText(link);
      toast.info(`Link copiado com sucesso!`, { autoClose: 1800 });
    } catch (err) {
      console.log('error copy => ', err);
      toast.error('Erro ao copiar Link!', { autoClose: 1800 });
    }
  };

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Novo Link gerado"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      <>
        <Card className="cardContentLink">
          <ContentLink>
            <input type="text" readOnly={true} defaultValue={link} />
            <CopyButton copyToClipBoard={copyToClipBoard} />
          </ContentLink>
        </Card>
      </>
    </DefaultModal>
  );
};

export default ModalCopyLink;
