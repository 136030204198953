import { apiInstagramChat as http } from '../http';

class ServiceCRUD {
  constructor(path) {
    this.path = path;
  }

  getAll(page = 1, size = 10, headers = {}) {
    return http.get(`${this.path}?page=${page}&size=${size}`, {
      headers
    });
  }

  getAllParams(page = 1, size = 10, headers = {}, reportType = 1) {
    return http.get(
      `${this.path}/${
        reportType === 1 ? 'analytical' : 'synthetic'
      }?page=${page}&size=${size}`,
      {
        params: {
          ...headers
        }
      }
    );
  }

  get(id, queryParams = {}) {
    return http.get(`${this.path}/${id}`, {
      params: {
        ...queryParams
      }
    });
  }

  create(data) {
    return http.post(this.path, data);
  }

  post(data) {
    return http.post(this.path, data);
  }

  update(id, data) {
    return http.put(`${this.path}/${id}`, data);
  }

  delete(id) {
    return http.delete(`${this.path}/${id}`);
  }

  getWithParameters(page, size, queryParams = {}, headers = {}) {
    return http.get(`${this.path}/?page=${page}&size=${size}`, {
      params: {
        ...queryParams
      },
      headers
    });
  }
}
export default ServiceCRUD;
