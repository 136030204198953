import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Row, Input, Col } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { AiFillFilePdf } from 'react-icons/ai';

import { addMessageConversationInAttendance } from 'store/action/conversation-in-attendance-action';
import { addMessageAnsweredConversation } from 'store/action/answered-conversations-action';

import DefaultModal from 'components/Modal/DefaultModal';
import ReactButton from 'components/Buttons/ReactButton';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';

import { BsFillImageFill, BsCameraVideoFill } from 'react-icons/bs';

import { VideoPreview } from '../../../QueueContent/components/VideoPreview';
import { PreviewPdf } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/PreviewPdf';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { useSelector } from 'react-redux';
import { PreviewImage } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/PreviewImage';
import { Preview } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/styled';
import {
  imageExtensions,
  videoExtensions
} from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/components/assets';
import { validationFiles } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/assets/validationFiles';
import { generatePreview } from 'views/ServicePanel/components/AttendanceContent/components/Modal/UploadFile/assets/generatePreview';
export const SingleTemplateModal = ({
  isModalOpen,
  setIsModalOpen,
  onSendMessage,
  template,
  contactId,
  clientId,
  channelId = '',
  queue,
  conversation
}) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [sendingTemplate, setSendingTemplate] = useState(false);
  const [pdfPreview, setPdfPreview] = useState([]);
  const [videoPreview, setVideoPreview] = useState(undefined);
  const [previewImages, setPreviewImages] = useState([]);
  const [files, setFiles] = useState([]);
  const templateParamRegExp = /{{\d+}}/g;
  const dispatch = useDispatch();

  const { getImagePreview, getPDFPreview, getVideoPreview } = generatePreview();

  const {
    CheckedRefusedExtensionsFiles,
    isAllFilesWithApprovedExtension,
    isFilesWithApprovedExtension,
    isImagesWithApprovedExtension,
    sizeInvalidFileValidation
  } = validationFiles();

  const templateIndex = 1;
  const inputParam = `<input
                      class=${templateIndex}
                      style="
                        font-size: 1rem;
                        color: rgb(94, 95, 98);
                        box-shadow: 0 0 0 0;
                        margin: 5;
                        border: 1px solid;
                        border-color: rgb(173, 176, 184);
                        border-radius: 5px;
                        width: 6.5rem;
                        height: 1.5rem;
                        outline: 0;
                        "
                    ></input>`;

  const onInputClick = (event) => {
    event.target.value = '';
  };

  async function handleWithSendTemplateButton(template, templateIndex) {
    setSendingTemplate(true);

    const inputs = Array.from(document.getElementsByClassName(templateIndex));
    const variables = await extractVariblesFromInputs(inputs);

    if (!variables) {
      setSendingTemplate(false);
      return;
    }

    const message = await completeMessageWithVariables(
      template.description,
      variables
    );

    if (template.type === 'IMAGE') {
      if (files.length === 0) {
        toast.error('Selecione uma imagem');
        setSendingTemplate(false);
        return;
      }

      const fileExtension = files[0].name.split('.').pop();
      const formatedImage = new FormData();
      formatedImage.append('file', files[0]);
      if (imageExtensions.includes(fileExtension)) {
        formatedImage.append('response_type', 'image');
      }
      formatedImage.append('contactId', contactId);
      formatedImage.append('clientId', clientId);
      formatedImage.append('queue', queue);
      formatedImage.append('idSourcePhone', channelId);
      formatedImage.append('message', message);
      formatedImage.append('type', template.type);
      formatedImage.append('templateId', template.id);
      formatedImage.append('params', JSON.stringify([...variables]));
      formatedImage.append('userLogin', localStorage.getItem('login'));
      await sendTemplateMessageFile(formatedImage);
    } else if (template.type === 'DOCUMENT') {
      if (files.length === 0) {
        toast.error('Selecione um arquivo');
        setSendingTemplate(false);
        return;
      }

      const fileExtension = files[0].name.split('.').pop();
      const formatedImage = new FormData();
      formatedImage.append('file', files[0]);

      if (imageExtensions.includes(fileExtension)) {
        formatedImage.append('response_type', 'document');
      }
      formatedImage.append('contactId', contactId);
      formatedImage.append('clientId', clientId);
      formatedImage.append('queue', queue);
      formatedImage.append('idSourcePhone', channelId);
      formatedImage.append('message', message);
      formatedImage.append('type', template.type);
      formatedImage.append('templateId', template.id);
      formatedImage.append('params', JSON.stringify([...variables]));
      formatedImage.append('userLogin', localStorage.getItem('login'));

      await sendTemplateMessageFile(formatedImage);
    }
    if (template.type === 'VIDEO') {
      if (files.length === 0) {
        toast.error('Selecione um video');
        setSendingTemplate(false);
        return;
      }

      const fileExtension = files[0].name.split('.').pop();
      const formatedImage = new FormData();
      formatedImage.append('file', files[0]);
      if (videoExtensions.includes(fileExtension)) {
        formatedImage.append('response_type', 'video');
      }
      formatedImage.append('contactId', contactId);
      formatedImage.append('clientId', clientId);
      formatedImage.append('queue', queue);
      formatedImage.append('idSourcePhone', channelId);
      formatedImage.append('message', message);
      formatedImage.append('type', template.type);
      formatedImage.append('templateId', template.id);
      formatedImage.append('params', JSON.stringify([...variables]));
      formatedImage.append('userLogin', localStorage.getItem('login'));

      await sendTemplateMessageFile(formatedImage);
    } else if (template.type === 'TEXT') {
      await sendTemplateMessage(variables, template.id, message);
    }

    setSendingTemplate(false);
  }

  async function sendTemplateMessageFile(formatedImage) {
    try {
      const response = await FlexChatIntegration.sendTemplateMediaMessage(
        formatedImage
      );

      await dispatch(
        addMessageConversationInAttendance(
          { ...response.data.data },
          conversation._id ?? conversation.id
        )
      );

      //updating list of answeredConversations in store
      await dispatch(
        addMessageAnsweredConversation(
          { ...response.data.message },
          conversation._id ?? conversation.id
        )
      );

      setIsModalOpen(false);
      onSendMessage();
      toast.success('Mensagem enviada');
    } catch (error) {
      const msg =
        error.response && error.response.data && error.response.data.message;
      toast.error(msg || 'Erro ao enviar mensagem');
    }
  }

  const handleOnChangeFileToTarget = (e, type = '') => {
    const file = e.target.files;
    handleSelectFile(file, type);
  };

  const handleSelectFile = (files, type) => {
    if (!files) return;

    const filesToBeSent = Array.from(files);

    const isCheckedRefusedExtensionsFiles =
      CheckedRefusedExtensionsFiles(filesToBeSent);

    if (isCheckedRefusedExtensionsFiles) {
      toast.error('Erro ao enviar arquivo(s). Formato não permitido');
      return;
    }

    const isInvalidTypeFile = isAllFilesWithApprovedExtension(filesToBeSent);
    if (Object.keys(isInvalidTypeFile).length !== 0) {
      toast.error(
        `${isInvalidTypeFile.message}: ${isInvalidTypeFile.extension}`
      );
      return;
    }

    const isSizeFileValidation = sizeInvalidFileValidation(
      filesToBeSent,
      'WHATSAPP'
    );
    if (Object.keys(isSizeFileValidation).length !== 0) {
      toast.error(`O arquivo é grande demais`);
      return;
    }

    const imagesWithApprovedExtension =
      isImagesWithApprovedExtension(filesToBeSent);

    const selectedImagePreview = getImagePreview(imagesWithApprovedExtension);

    const filesWithApprovedExtension =
      isFilesWithApprovedExtension(filesToBeSent);

    const selectedPdfPreview = getPDFPreview(filesWithApprovedExtension);

    setPreviewImages(selectedImagePreview);
    setPdfPreview([selectedPdfPreview[0]]);
    setFiles(filesToBeSent);
    setVideoPreview(undefined);

    if (type === 'video') {
      let newVideo = getVideoPreview(filesToBeSent[0]);

      setVideoPreview(newVideo);
    }
  };

  async function extractVariblesFromInputs(inputs) {
    const variables = [];
    for (let i = 0; i < inputs.length; i++) {
      const value = inputs[i].value;
      if (value.trim() === '') {
        toast.warning('Preencha todos os campos');
        return null;
      }
      variables.push(value);
    }
    return variables;
  }

  async function completeMessageWithVariables(message, variables) {
    function toReplace(match, offset, string) {
      const variableNumber = parseInt(string[offset + 2] - 1);
      return variables[variableNumber] ?? '';
    }
    return message.replace(templateParamRegExp, toReplace);
  }

  async function sendTemplateMessage(variables, templateId, message) {
    try {
      const messageData = {
        id: templateId,
        message: message,
        params: variables
      };

      const response = await FlexChatIntegration.sendTemplateMessage(
        conversation._id ?? conversation.id,
        messageData
      );

      await dispatch(
        addMessageConversationInAttendance(
          { ...response.data.data },
          conversation._id ?? conversation.id
        )
      );
      //updating list of answeredConversations in store
      await dispatch(
        addMessageAnsweredConversation(
          { ...response.data.message },
          conversation._id ?? conversation.id
        )
      );

      setIsModalOpen(false);
      onSendMessage();
      toast.success('Mensagem enviada');
    } catch (error) {
      const msg =
        error.response && error.response.data && error.response.data.message;
      toast.error(msg || 'Erro ao enviar mensagem');
    }
  }

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={setIsModalOpen}
      title={
        template && template.description ? template.description : 'Template'
      }
      showCloseButton={false}
      modalContainerClasses="modal-md"
    >
      {template ? (
        <>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              marginTop: '0.5rem'
            }}
          >
            <div
              style={{
                alignSelf: 'center',
                padding: '1rem'
              }}
              className="text-justify"
              dangerouslySetInnerHTML={{
                __html: template.description.replace(
                  templateParamRegExp,
                  inputParam
                )
              }}
            ></div>

            {template.type !== 'TEXT' ? (
              <>
                <Row style={{ justifyContent: 'center' }} className="mt-2 mb-2">
                  <Col className="d-flex justify-content-center">
                    <Preview>
                      {!!Object.keys(previewImages).length &&
                        previewImages.map((previewImage, index) => {
                          return (
                            <PreviewImage
                              key={index}
                              previewImage={previewImage}
                              title={false}
                            />
                          );
                        })}
                      {videoPreview && (
                        <VideoPreview title={false} video={videoPreview} />
                      )}

                      {!!Object.keys(pdfPreview).length &&
                        pdfPreview.map((filePreview, index) => {
                          if (filePreview) {
                            return (
                              <PreviewPdf
                                key={index}
                                title={false}
                                filePreview={filePreview}
                              />
                            );
                          }
                        })}
                    </Preview>
                  </Col>
                </Row>
              </>
            ) : null}
            <Row style={{ justifyContent: 'center', marginTop: '0.5rem' }}>
              {template.type === 'IMAGE' ? (
                <>
                  <label
                    htmlFor={`uploadFileConversationImage`}
                    className="btn mt-2"
                    style={{
                      ...buttonStyle(
                        systemConfiguration.confirmation_button_color
                      ),
                      marginLeft: '0.7rem'
                    }}
                  >
                    <BsFillImageFill />
                  </label>
                  <Input
                    style={{ display: 'none' }}
                    type="file"
                    className="inputFile"
                    name="uploadFile"
                    id={`uploadFileConversationImage`}
                    onClick={onInputClick}
                    onChange={(e) => {
                      handleOnChangeFileToTarget(e, 'image');
                    }}
                    accept="image/*"
                  />
                </>
              ) : null}
              {template.type === 'VIDEO' ? (
                <>
                  <label
                    htmlFor={`uploadFileConversationVideo`}
                    className="btn mt-2"
                    style={{
                      ...buttonStyle(
                        systemConfiguration.confirmation_button_color
                      ),
                      marginLeft: '0.7rem'
                    }}
                  >
                    <>
                      <BsCameraVideoFill />
                    </>
                  </label>
                  <Input
                    style={{ display: 'none' }}
                    type="file"
                    className="inputFile"
                    name="uploadFile"
                    id={`uploadFileConversationVideo`}
                    onClick={onInputClick}
                    onChange={(e) => {
                      handleOnChangeFileToTarget(e, 'video');
                    }}
                    accept="video/*"
                  />
                </>
              ) : null}
              {template.type === 'DOCUMENT' ? (
                <Row>
                  <label
                    htmlFor={`uploadFileConversationDocument`}
                    className="btn mt-2"
                    style={{
                      ...buttonStyle(
                        systemConfiguration.confirmation_button_color
                      ),
                      marginLeft: '0.7rem'
                    }}
                  >
                    <AiFillFilePdf />
                  </label>
                  <Input
                    style={{ display: 'none' }}
                    type="file"
                    className="inputFile"
                    name="uploadFile"
                    id={`uploadFileConversationDocument`}
                    onClick={onInputClick}
                    onChange={(e) => {
                      handleOnChangeFileToTarget(e, 'file');
                    }}
                    accept=".pdf"
                  />
                </Row>
              ) : null}

              <div className="text-right mt-2">
                <ReactButton
                  color="primary"
                  disabled={sendingTemplate}
                  style={{ marginLeft: '0.7rem' }}
                  onClick={() =>
                    handleWithSendTemplateButton(template, templateIndex)
                  }
                >
                  Enviar mensagem
                </ReactButton>
              </div>
            </Row>
          </Row>
        </>
      ) : (
        <p>template invalido</p>
      )}
    </DefaultModal>
  );
};
