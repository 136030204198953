import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import Service from '../service/index';
import ddrService from '../service/ddrService';
import { toast } from 'react-toastify';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody,
  FormGroup
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import { ScreenPrompt } from 'components/ScreenPrompt';

const CallbackForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [blocked, setBlocked] = useState(false);
  const [ddrs, setDdrs] = useState([]);

  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      setLoading(true);
      Service.get(id)
        .then((res) => {
          const getBlackList = res.data.data;
          setBlocked(getBlackList.blocked);
          reset({
            name: getBlackList.name,
            blocked: getBlackList.blocked,
            ddr_id: getBlackList.blocked ? undefined : getBlackList.ddr.id
          });
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar lista de bloqueio', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  //  Get ddrs to select
  useEffect(() => {
    ddrService
      .getAll()
      .then((response) => {
        setDdrs(response.data.data);
      })
      .catch(() => {
        console.log(`Erro ao ler as DDR´s`);
      });
  }, []);

  const onSubmit = async (data) => {
    try {
      let dataStore;
      if (data.blocked) {
        dataStore = {
          origin: data.origin,
          name: data.name,
          blocked: data.blocked
        };
      } else {
        dataStore = {
          origin: data.origin,
          name: data.name,
          blocked: data.blocked,
          ddr_id: data.ddr_id
        };
      }
      // return console.log(dataStore);
      setLoading(true);
      isUpdate
        ? await Service.update(id, dataStore)
        : await Service.create(dataStore);
      history.push('/admin/blackList');
      if (isUpdate) {
        toast.info('Lista de bloqueio foi atualizada com sucesso!');
      } else {
        toast.success('Lista de bloqueio foi cadastrada com sucesso!');
      }
    } catch (err) {
      toast.error(err.response.data.message, {
        autoClose: 3000,
        closeOnClick: true
      });
      history.push('/admin/blacklist');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/blackList');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/blacklist"
        buttonTitle="Voltar para a pagina de pagina de bloqueio"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Lista de bloqueio</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Name*</label>

                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{
                              required: 'Nome é um campo obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            DDR{!blocked && '*'}
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isDisabled={blocked}
                                isClearable={true}
                                dataOptions={ddrs}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="ddr_id"
                                labelName="ddr"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="ddr_id"
                            rules={
                              !blocked && {
                                required: 'Selecione uma DDR'
                              }
                            }
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="ddr_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="6">
                          <Controller
                            name="blocked"
                            control={control}
                            defaultValue={false}
                            render={(props) => (
                              <FormGroup check>
                                <label className="form-control-label">
                                  <Input
                                    type="checkbox"
                                    onChange={(e) => {
                                      setBlocked(!blocked);
                                      return props.onChange(e.target.checked);
                                    }}
                                    checked={props.value}
                                  />
                                  Bloquear para todas as DDR&apos;s
                                </label>
                              </FormGroup>
                            )}
                          />
                        </Col>
                      </Row>

                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CallbackForm;
