import { apiCrm, apiCase } from '../../../http';
import ServiceCRUD from '../../../services/ServiceCRUD';

class FieldsConfigService extends ServiceCRUD {
  constructor() {
    super('config');
  }

  getFieldsClient(entityName, params) {
    return apiCase.get(`/config/${entityName}`, {
      params
    });
  }

  getFields(entityName, params) {
    return apiCrm.get(`custom-field/${entityName}`, {
      params
    });
  }

  getFieldsByRecordType(entityName, recordTypeId) {
    return apiCrm.get(`custom-field/${entityName}/${recordTypeId}`);
  }

  getFieldById(fieldId) {
    return apiCrm.get(`custom-field/get-by-id/${fieldId}`);
  }

  createField(data) {
    return apiCrm.post('custom-field', data);
  }

  updateFieldCRM(fieldId, data) {
    return apiCrm.put(`custom-field/${fieldId}`, data);
  }
}

export default new FieldsConfigService();
