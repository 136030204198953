import React, { useEffect, useState } from 'react';
import Chart from 'chart.js';
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Button,
  Form
} from 'reactstrap';

import { buttonStyle } from 'layouts/LoginAutoAuth/components/utils';
import { useForm, Controller } from 'react-hook-form';
import ReactLoader from 'components/Loader';
import OpportunityFunnelGraphic from './OpportunityFunnelGraphic';
import { chartOptions, parseOptions } from 'variables/charts.js';

import { useSelector } from 'react-redux';
// import Loader from 'react-loader-spinner';
import FlexSelect from 'components/Inputs/FlexSelect';
import { ErrorMessage } from '@hookform/error-message';
import DatePicker from 'components/Inputs/DatePicker.js';
import { dateRules } from '../../../../Report/Chat/DashboardDataBot/assets';
import CRMDashboardService from '../../service';
import { toast } from 'react-toastify';
import GraphicOpportunitiesByResponsible from './GraphicOpportunitiesByResponsible';
import { useRecordTypesByUser } from 'views/RecordType/hooks/useRecordTypesByUser';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import {
  getDataDTO,
  toISOFormat,
  firstDayOfMonth,
  firstDay
} from '../../shared/dataFormater';
import GraphicOpportunitiesByListCustomFields from './GraphicOpportunitiesByListCustomFields';
import { GraphicOpportunityRevenue } from './GraphicOpportunityRevenue';

if (window.Chart) {
  parseOptions(Chart, chartOptions());
}

const filterOpportunitiesCountByListCustomField = (data) => {
  if (!data) return;
  const result = data.filter((item) => {
    const allQuantitiesZero = item.options.every(
      (option) => option.quantity === 0
    );

    return !allQuantitiesZero;
  });
  return result;
};

const OpportunityDashboard = () => {
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, errors, setValue, getValues, register } =
    useForm();
  const [opportunitiesCountByResponsible, setOpportunitiesCountByResponsible] =
    useState(null);
  const [defaultRecordType, setDefaultRecordType] = useState(null);
  const [opportunitiesByStage, setOpportunitiesByStage] = useState(null);
  const [
    opportunitiesCountByListCustomFields,
    setOpportunitiesCountByListCustomFields
  ] = useState(null);
  const {
    recordTypes: opportunityRecordTypes,
    getRecordTypesByUser: getOpportunityRecordTypesByUser
  } = useRecordTypesByUser(entitiesName.OPPORTUNITY);
  const [total, setTotal] = useState(0);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [opportunitiesRevenue, setOpportunitiesRevenue] = useState(null);

  useEffect(() => {
    setLoading(true);

    if (defaultRecordType) {
      const formatedData = {
        dateBegin: `${toISOFormat(firstDayOfMonth)}`,
        dateEnd: `${toISOFormat(new Date().toLocaleDateString('pt-br'))}`,
        recordType: defaultRecordType ? defaultRecordType.value : ''
      };

      CRMDashboardService.getOpportunitiesCountByStageWithDate(formatedData)
        .then((response) => {
          const listCustomFields = response?.data?.data;
          setTotal(listCustomFields.opportunitiesByStageAmountSum ?? 0);
          setOpportunitiesByStage(listCustomFields.opportunitiesByStage);
          setOpportunitiesCountByResponsible(
            listCustomFields.opportunitiesByResponsible
          );
          setOpportunitiesRevenue(listCustomFields.opportunityRevenue);

          setOpportunitiesCountByListCustomFields(
            response?.data?.data?.opportunitiesByListCustomField
          );
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [defaultRecordType]);

  useEffect(() => {
    getOpportunityRecordTypesByUser();
  }, [getOpportunityRecordTypesByUser]);

  useEffect(() => {
    if (opportunityRecordTypes !== null) {
      const firstRecordType = opportunityRecordTypes[0] || [];
      setDefaultRecordType({
        label: firstRecordType.name,
        value: firstRecordType.id
      });
    }
  }, [opportunityRecordTypes]);

  if (loading) {
    return (
      <Row className="justify-content-md-center mt-4">
        <ReactLoader />
      </Row>
    );
  }

  const getOpportunityRecordTypesOptionsDTO = () => {
    if (!opportunityRecordTypes || !opportunityRecordTypes.length) return [];
    return opportunityRecordTypes.map((rt) => ({
      label: rt.name,
      value: rt.id
    }));
  };

  const onSubmit = (data) => {
    try {
      const formatedData = getDataDTO(data);
      CRMDashboardService.getOpportunitiesCountByStageWithDate(formatedData)
        .then((response) => {
          setOpportunitiesByStage(response?.data?.data?.opportunitiesByStage);
          setOpportunitiesCountByResponsible(
            response?.data?.data?.opportunitiesByResponsible
          );
          setOpportunitiesCountByListCustomFields(
            response?.data?.data?.opportunitiesByListCustomField
          );
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <Container fluid className="pt-4">
      <Card>
        <CardBody>
          {opportunityRecordTypes ? (
            <div>
              <Form
                className="needs-validation"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row className="mb-3">
                  <Col md="4">
                    <label className="form-control-label">Data inicial*</label>
                    <Controller
                      render={(props) => (
                        <DatePicker
                          fieldName="dataInicial"
                          valueController={setValue}
                          defaultValue={props.value}
                        />
                      )}
                      control={control}
                      name="dataInicial"
                      rules={dateRules}
                      defaultValue={firstDay().toLocaleDateString('pt-br')}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="dataInicial"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                  <Col md="4">
                    <label className="form-control-label">Data final*</label>
                    <Controller
                      render={(props) => (
                        <DatePicker
                          fieldName="dataFinal"
                          valueController={setValue}
                          defaultValue={props.value}
                        />
                      )}
                      control={control}
                      name="dataFinal"
                      defaultValue={new Date().toLocaleDateString('pt-br')}
                      rules={{
                        ...dateRules,
                        validate: (value) => {
                          if (
                            new Date(toISOFormat(value, true)) <
                            new Date(
                              toISOFormat(getValues()['dataInicial'], true)
                            )
                          ) {
                            return 'A data final não pode ser anterior à inicial!';
                          } else {
                            return true;
                          }
                        }
                      }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="dataFinal"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                  <Col md="4">
                    <label className="form-control-label">
                      Tipo de registro*
                    </label>
                    <Controller
                      render={(props) => (
                        <FlexSelect
                          dataOptions={getOpportunityRecordTypesOptionsDTO()}
                          isMulti={false}
                          value={props.value}
                          valueController={setValue}
                          fieldName="selectedRecordType"
                          labelName="label"
                          valueName="value"
                          closeMenuOnSelect={true}
                          placeholder="Tipo de registro da oportunidade"
                        />
                      )}
                      control={control}
                      defaultValue={
                        defaultRecordType ? defaultRecordType.value : ''
                      }
                      rules={{
                        required: 'Campo obrigatório'
                      }}
                      name="selectedRecordType"
                    />
                    <ErrorMessage
                      errors={errors}
                      name="selectedRecordType"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col sm="6">
                    <label
                      className="form-control-label"
                      style={{ marginRight: '20px', fontWeight: '400' }}
                    >
                      <input
                        type="radio"
                        name="filterDateBy"
                        ref={register}
                        value="creation"
                        defaultChecked={true}
                      />{' '}
                      Data de criação
                    </label>
                    <label
                      className="form-control-label"
                      style={{ fontWeight: '400' }}
                    >
                      <input
                        type="radio"
                        name="filterDateBy"
                        ref={register}
                        value="last_update"
                        defaultChecked={false}
                      />{' '}
                      Data de última alteração
                    </label>
                  </Col>
                </Row>
                <Button
                  type="submit"
                  style={buttonStyle(systemConfiguration.primary_color)}
                  className="mb-2"
                >
                  Buscar
                </Button>
              </Form>
              <Row>
                <Col>
                  <Card>
                    <CardHeader>
                      <h6 className="surtitle">Visão Geral</h6>
                      <h5 className="h3 mb-0">
                        Distribuição de Oportunidades por Fase
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          {opportunitiesByStage && (
                            <OpportunityFunnelGraphic
                              total={total}
                              data={opportunitiesByStage}
                            />
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader>
                      <h6 className="surtitle">Visão Geral</h6>
                      <h5 className="h3 mb-0">
                        Distribuição de Oportunidades por responsável
                      </h5>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col>
                          {opportunitiesCountByResponsible &&
                            opportunitiesCountByResponsible.length > 0 && (
                              <GraphicOpportunitiesByResponsible
                                opportunitiesCountByResponsible={
                                  opportunitiesCountByResponsible
                                }
                              />
                            )}
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>

                  <Card>
                    <CardHeader>
                      <h6 className="surtitle">Visão Geral</h6>
                      <h5 className="h3 mb-0">
                        Distribuição de Oportunidades por campo personalizado
                      </h5>
                    </CardHeader>
                    <CardBody
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        justifyContent: 'center'
                      }}
                    >
                      {opportunitiesCountByListCustomFields &&
                        opportunitiesCountByListCustomFields.length &&
                        opportunitiesCountByListCustomFields.map((item) => (
                          <GraphicOpportunitiesByListCustomFields
                            key={item.id}
                            opportunitiesCountByListCustomFields={item}
                          />
                        ))}
                    </CardBody>
                  </Card>
                  <Card>
                    <CardHeader>
                      <h6 className="surtitle">Visão Geral</h6>
                      <h5 className="h3 mb-0">
                        Previsão de receita durante o ano
                      </h5>
                    </CardHeader>
                    <CardBody
                      style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(1, 1fr)',
                        justifyContent: 'center'
                      }}
                    >
                      {opportunitiesRevenue && opportunitiesRevenue.length && (
                        <GraphicOpportunityRevenue
                          data={opportunitiesRevenue}
                        />
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          ) : (
            <div>
              <span className="small">
                Você não tem acesso a nenhum tipo de oportunidade.
              </span>
            </div>
          )}
        </CardBody>
      </Card>
    </Container>
  );
};

export default OpportunityDashboard;
