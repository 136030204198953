import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import Service from '../service';
import { toast } from 'react-toastify';
import { Regex } from './Regex';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import ReactLoader from 'components/Loader';

const SectorForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { reset, control, handleSubmit, errors } = useForm();
  const [isUpdate, setIsUpdate] = useState(false);

  const { id } = props.match.params;

  useEffect(() => {
    if (id) {
      setLoading(true);
      Service.get(id)
        .then((res) => {
          const getSector = res.data.data;
          reset(getSector);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar setor', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        isUpdate && (await Service.update(id, data));

        history.push('/admin/facilityRoute');
        if (isUpdate) {
          toast.info('A rota de facilidades foi atualizada com sucesso!');
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
        console.log(err.response.data.message);
        if (err.response.data.message !== 'Esta rota já está sendo utilizada') {
          history.push('/admin/facilityRoute');
        }
      }
    },
    [history, isUpdate, id]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/facilityRoute');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/protocol"
        buttonTitle="Voltar para a pagina de rotas de facilidade"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Rota de Facilidade</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Nome*</label>

                          <Controller
                            as={Input}
                            disabled={true}
                            control={control}
                            name="name"
                            rules={{
                              required: 'Nome é um campo obrigatório'
                              // pattern: {
                              //   value: regex,
                              //   message:
                              //     'Nome não pode possuir espaços ou caracteres especiais'
                              // }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md={{ size: 6 }}>
                          <label className="form-control-label">Rota*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="route"
                            rules={{
                              required: 'Rota é um campo obrigatório',
                              validate: (value) => {
                                if (value.length >= 50)
                                  return 'Rota deve possuir no máximo 50 caracteres';
                                return true;
                              },
                              pattern: {
                                value: Regex,
                                message: 'Rota no formato inválido'
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="route"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SectorForm;
