import styled from 'styled-components';

export const SideCardContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  margin-right: ${({ isOpen }) => (isOpen ? '0' : '-16rem')};
  transition: margin 0.6s;
`;

export const ButtonToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`;

export const ToggleButton = styled.div`
  cursor: pointer;
  background-color: #00a17c;
  border: 1px solid #797979;
  border-right: 0;
  color: #fff;
  padding: 1rem 0.2rem;
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ToggleButtonText = styled.span`
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 12px;
`;
