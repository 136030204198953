import styled from 'styled-components';

export const MainContainerWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
`;

export const MainContainerContent = styled.div`
  width: 100%;
  height: fit-content;
  position: relative;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  background: transparent;
`;

export const MainContainerAgentsCards = styled.div`
  width: 100%;
  height: 100%;
`;

export const MainContainerReportCards = styled.div`
  width: 100%;
  height: 100%;
`;

export const MainContainerNoData = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
`;
