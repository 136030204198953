import { useState, useEffect } from 'react';
import ChatBroadcastListService from '../../ChatBroadcastList/service';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';

export function useGetBroadcastList(showDisabledTransmissionLists) {
  //queue to select
  useEffect(() => {
    FlexChatIntegration.getBroadcastList(showDisabledTransmissionLists)
      .then((response) => {
        const list = response.data.map((item) => {
          return { value: item.id, label: item.title };
        });
        setBroadcastList(list);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, [showDisabledTransmissionLists]);

  const [broadcastList, setBroadcastList] = useState([]);

  return {
    broadcastList,
    setBroadcastList
  };
}
