import { useState, useCallback } from 'react';

import {
  calculateOffsetFromPageAndLimit,
  defaultLimit
} from 'views/CRM/shared/utils/pagination';

import LeadProcessService from '../service';

function useListLeadProcesses() {
  const [leadProcesses, setLeadProcesses] = useState(null);
  const [totalLeadProcesses, setTotalLeadProcesses] = useState(null);
  const [isLoadingLeadProcesses, setIsLoadingLeadProcesses] = useState(false);

  const getLeadProcesses = useCallback(
    async (page = 1, params = {}, limit = defaultLimit) => {
      setIsLoadingLeadProcesses(true);

      const offset = calculateOffsetFromPageAndLimit(page, limit);

      LeadProcessService.list(params, { offset, limit })
        .then((response) => {
          setLeadProcesses(response.data.data);
          setTotalLeadProcesses(response.data.totalRegisters);
        })
        .catch(() => {
          setLeadProcesses(null);
        })
        .finally(() => {
          setIsLoadingLeadProcesses(false);
        });
    },
    []
  );

  return {
    leadProcesses,
    isLoadingLeadProcesses,
    totalLeadProcesses,
    getLeadProcesses
  };
}

export { useListLeadProcesses };
