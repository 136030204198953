import FlexSelect from 'components/Inputs/FlexSelect';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Button, Form } from 'reactstrap';
import * as S from './style';

const PauseUnpauseForm = ({
  allPauses = [],
  pauses = [],
  isPaused,
  currentPauseId,
  handlePause,
  handleUnpause
}) => {
  const { control, handleSubmit, setValue } = useForm();
  const togglePause = (data) => {
    const { pauseId } = data;
    if (!pauseId) return toast.info('Selecione uma pausa');

    if (isPaused) {
      handleUnpause();
    } else {
      handlePause({ pauseId });
    }
  };

  return (
    <>
      <S.PauseContainer>
        <Form onSubmit={handleSubmit(togglePause)}>
          <div className="d-flex justify-content-between align-items-top">
            <Controller
              render={(props) => (
                <div style={{ flex: 0.7 }}>
                  <FlexSelect
                    closeMenuOnSelect={true}
                    isClearable={false}
                    dataOptions={isPaused ? allPauses : pauses}
                    placeholder="Selecione uma pausa"
                    isMulti={false}
                    value={props.value}
                    valueController={setValue}
                    fieldName="pauseId"
                    labelName="name"
                    valueName="id"
                    minHeight="1rem"
                    isDisabled={isPaused}
                  />
                </div>
              )}
              disabled={true}
              control={control}
              name="pauseId"
              defaultValue={currentPauseId}
              className="mt-2"
            />

            <Button
              type="submit"
              color="primary"
              size="sm"
              style={{ flex: 0.3, marginLeft: '10px' }}
            >
              {isPaused ? 'Despausar' : 'Pausar'}
            </Button>
          </div>
        </Form>
      </S.PauseContainer>
    </>
  );
};

export default PauseUnpauseForm;
