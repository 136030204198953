import { apiConfiguration as http } from '../../../http';
//
class ConfigurationService {
  getAllConfigs() {
    return http.get('/configurations');
  }

  updateConfigs(configurations) {
    return http.put(`/configurations/${configurations._id}`, configurations);
  }

  updateLogo(data) {
    return http.post(`/img/upload/logo`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  updateLogoSocial(data) {
    return http.post(`/img/upload/logosocial`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  updateLogoChat(data) {
    return http.post(`/img/upload/logochat`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }

  updateLogoFlexia(data) {
    return http.post(`/img/upload/logoflexia`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }
}

export default new ConfigurationService();
