import React, { useCallback, useEffect, useState } from 'react';

import ProtocolForm from './components/ProtocolForm';
import RecordTypeSelection from '../components/RecordTypeSelection';
import { useRecordTypesByUser } from '../../RecordType/hooks/useRecordTypesByUser';
import Loader from 'components/Loader';
import { Row } from 'reactstrap';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { useSocketCallAnswered } from 'components/Navbars/ServicePanel/ProtocolModal/hooks/useSocketCallAnswered';

function ProtocolCreationHandler(props) {
  const [selectedRecordTypeId, setSelectedRecordTypeId] = useState(null);
  const [shouldShowProtocols, setShouldShowProtocols] = useState(false);
  const { forceSaveProtocol, startCounter } = useSocketCallAnswered();
  const { recordTypes, isLoadingRecordTypes, getRecordTypesByUser } =
    useRecordTypesByUser(entitiesName.PROTOCOL);

  useEffect(() => {
    getRecordTypesByUser();
  }, [getRecordTypesByUser]);

  useEffect(() => {
    const defaultData = props.modalActions?.defaultFormData;
    if (defaultData) {
      const recordTypeToOpenModal = defaultData.recordTypeId || 1;
      setSelectedRecordTypeId(recordTypeToOpenModal);
      setShouldShowProtocols(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.modalActions]);

  useEffect(() => {
    if (recordTypes && recordTypes.length === 1) {
      setSelectedRecordTypeId(recordTypes[0].id);
      setShouldShowProtocols(true);
    }
  }, [recordTypes]);

  const shouldShowRecordTypeSelection = useCallback(() => {
    if (isLoadingRecordTypes || !shouldShowProtocols) return true;
    return false;
  }, [isLoadingRecordTypes, shouldShowProtocols]);

  const handleOnSelectedRecordType = useCallback((recordTypeId) => {
    setSelectedRecordTypeId(recordTypeId);
    setShouldShowProtocols(true);
  }, []);

  useEffect(() => {
    if (selectedRecordTypeId && props?.setDisplayEndButton) {
      props.handleSelectedRecordType(selectedRecordTypeId);
    }
  }, [props, selectedRecordTypeId]);

  const render = () => {
    if (!recordTypes || !recordTypes.length)
      return (
        <Row className="justify-content-md-center">
          <Loader />
        </Row>
      );

    return shouldShowRecordTypeSelection() ? (
      <>
        {props?.setDisplayEndButton ? props.setDisplayEndButton(false) : ''}
        <RecordTypeSelection
          recordTypes={recordTypes}
          handleOnSelectedRecordType={handleOnSelectedRecordType}
        />
      </>
    ) : (
      <>
        {props?.setDisplayEndButton ? props.setDisplayEndButton(true) : ''}

        <ProtocolForm
          {...props}
          selectedRecordTypeId={selectedRecordTypeId}
          deafultDataAcctid={props?.modalActions?.defaultFormData?.acctid}
          forceSaveProtocol={forceSaveProtocol}
          startCounter={startCounter}
          createdFrom={props.createdFrom ?? ''}
        />
      </>
    );
  };

  return render();
}

export default ProtocolCreationHandler;
