import { apiChat } from 'http/index';

class GetReportMonitoringService {
  async getReportExelMonitoring({ queryParams = {} }) {
    return apiChat.get(`/report/analitic/monitoring/excel`, {
      responseType: 'arraybuffer',
      params: queryParams
    });
  }
}

export default new GetReportMonitoringService();
