import { useCallback, useState } from 'react';
import OpportunityRevenueService from '../services/opportunityService';

export const useListOpportunities = ({ recordTypeId }) => {
  const [syntheticData, setSyntheticData] = useState([]);
  const [isLoadingSyntheticOpportunity, setIsLoadingSyntheticOpportunity] =
    useState(false);

  const getReportSyntheticOpportunityRevenue = useCallback(
    (params = {}) => {
      setIsLoadingSyntheticOpportunity(true);

      OpportunityRevenueService.getReportSyntheticOpportunityRevenue(
        recordTypeId,
        params
      )
        .then((response) => {
          setSyntheticData(response.data.data);
        })
        .finally(() => setIsLoadingSyntheticOpportunity(false));
    },
    [recordTypeId]
  );

  return {
    getReportSyntheticOpportunityRevenue,
    syntheticData,
    isLoadingSyntheticOpportunity
  };
};
