import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';

import Service from '../service';
import uraService from '../service/uraService';
import extensionService from '../service/extensionService';
import queueService from '../service/queueService';

import { toast } from 'react-toastify';
import { RouteTypes } from './RouteTypes';
import { Regex } from './regex';
import LoaderReact from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import { ScreenPrompt } from 'components/ScreenPrompt';

const InternalRouteForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [loadingRamal, setLoadingRamal] = useState(false);

  const { reset, control, handleSubmit, errors, watch, formState } = useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [uras, setUras] = useState([]);
  const [extensions, setExtensions] = useState([]);
  const [queues, setQueus] = useState([]);
  const type = watch('type');
  const isConference = watch('check_conference_pass');
  const { id } = props.match.params;

  //set to ura select
  useEffect(() => {
    setLoading(true);
    uraService
      .getAll()
      .then((res) => {
        setLoading(false);
        setUras(res.data.data);
      })
      .catch((err) => {
        console.log('erro ao listar uras: ' + err.response.data.message);
      });
  }, []);

  //set to extension select
  useEffect(() => {
    if (!(type === 'SPECIFIC_EXTENSION') || extensions.length > 0) return;
    setLoadingRamal(true);
    extensionService
      .getAllSimple()
      .then((res) => {
        setLoadingRamal(false);
        setExtensions(res.data.data);
      })
      .catch((err) => {
        console.log('erro ao listar ramais: ' + err.response.data.message);
        toast.error('Erro ao listar ramais');
      });
  }, [type]);

  //set to queue select
  useEffect(() => {
    setLoading(true);
    queueService
      .getAll()
      .then((res) => {
        setLoading(false);
        setQueus(res.data.data);
      })
      .catch((err) => {
        console.log('erro ao listar filas: ' + err.response.data.message);
      });
  }, []);

  useEffect(() => {
    if (id) {
      Service.get(id)
        .then((res) => {
          reset(res.data.data);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar Rota Interna', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (dataForm) => {
      try {
        const data = { ...dataForm };
        const arrayOfKeys = Object.keys(data);
        arrayOfKeys.forEach((key) => {
          if (String(data[key]).length === 0) delete data[key];
        });
        //return console.log(data);
        setLoading(true);
        isUpdate ? await Service.update(id, data) : await Service.create(data);
        history.push('/admin/internalRoute');

        if (isUpdate) {
          // setLoading(false);
          toast.info('Rota Interna foi atualizada com sucesso!');
        } else {
          //setLoading(false);
          toast.success('Rota Interna cadastrada com sucesso!');
        }
      } catch (err) {
        if (err.response.data.message === 'Este nome já está sendo utilizado') {
          setLoading(false);
          toast.error(err.response.data.message, {
            autoClose: 3000,
            closeOnClick: true
          });
          return;
        }
        toast.error(err.response.data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
        history.push('/admin/internalRoute');
      }
    },
    [history, isUpdate, id]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/internalRoute');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/internalRoute"
        buttonTitle="Voltar para a pagina de rota interna"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Rota Interna</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <LoaderReact />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Rota*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="route"
                            rules={{
                              required: 'Este campo é obrigatório',
                              pattern: {
                                value: Regex,
                                message: 'Rota no formato inválido'
                              }
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="route"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md="6">
                          <label className="form-control-label">Tipo*</label>
                          <Controller
                            as={
                              <Input type="select">
                                <option value="">Selecione um tipo</option>
                                {RouteTypes.map((type, index) => (
                                  <option key={index} value={type.id}>
                                    {type.name}
                                  </option>
                                ))}
                              </Input>
                            }
                            control={control}
                            name="type"
                            rules={{
                              required: 'Este campo é obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="type"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      {type === 'SPECIFIC_EXTENSION' && (
                        <Row>
                          <Col md="12">
                            <label className="form-control-label">
                              Ramal Específico*
                            </label>
                            <Controller
                              as={
                                <Input disabled={loadingRamal} type="select">
                                  <option value="">
                                    {loadingRamal
                                      ? 'Carregando opções...'
                                      : 'Selecione um Ramal'}{' '}
                                  </option>
                                  {extensions.map((extension, index) => (
                                    <option
                                      key={index}
                                      value={extension.extension_number}
                                    >
                                      {extension.extension_number}
                                    </option>
                                  ))}
                                </Input>
                              }
                              control={control}
                              name="specific_extension_id"
                              rules={{
                                required: 'Este campo é obrigatório'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="specific_extension_id"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                      )}

                      {type === 'IVR' && (
                        <Row>
                          <Col md="12">
                            <label className="form-control-label">URA*</label>
                            <Controller
                              as={
                                <Input type="select">
                                  <option value="">Selecione uma ura</option>
                                  {uras.map((ura, index) => (
                                    <option key={index} value={ura.id}>
                                      {ura.name}
                                    </option>
                                  ))}
                                </Input>
                              }
                              control={control}
                              name="ivr_id"
                              rules={{
                                required: 'Este campo é obrigatório'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="ivr_id"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                      )}

                      {type === 'QUEUE' && (
                        <Row>
                          <Col md="12">
                            <label className="form-control-label">Fila*</label>
                            <Controller
                              as={
                                <Input type="select">
                                  <option value="">Selecione uma ura</option>
                                  {queues.map((queues, index) => (
                                    <option key={index} value={queues.id}>
                                      {queues.name}
                                    </option>
                                  ))}
                                </Input>
                              }
                              control={control}
                              name="queue_id"
                              rules={{
                                required: 'Este campo é obrigatório'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="queue_id"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                      )}

                      {type === 'CONFERENCE' && (
                        <>
                          {type === 'CONFERENCE' && isConference && (
                            <Row>
                              <Col sm="6">
                                <label className="form-control-label">
                                  Senha da Conferência*
                                </label>
                                <Controller
                                  as={Input}
                                  type="password"
                                  control={control}
                                  name="conference_pass"
                                  rules={{
                                    required: 'Este campo é obrigatório'
                                  }}
                                  defaultValue=""
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="conference_pass"
                                  render={({ message }) =>
                                    errorFormMessage(message)
                                  }
                                />
                              </Col>
                            </Row>
                          )}
                          <Col sm="12">
                            <Controller
                              name="check_conference_pass"
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <>
                                  <label className="form-control-label">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                    Conferência com senha
                                  </label>
                                </>
                              )}
                            />
                          </Col>

                          <Col>
                            <Controller
                              name="check_conference_monitor"
                              control={control}
                              defaultValue={false}
                              render={(props) => (
                                <>
                                  <label className="form-control-label">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                    Gravar Conferência
                                  </label>
                                </>
                              )}
                            />
                          </Col>
                        </>
                      )}

                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default InternalRouteForm;
