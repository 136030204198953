import styled from 'styled-components';

export const FileProtocolArea = styled.label`
  ${(props) => props.disabled && 'background-color: #e9ecef;'};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #e5e5e5;
  width: 100%;
  height: 2.785rem;
  border-radius: 4px;
  cursor: pointer;

  ${(props) =>
    !props?.disabled &&
    `&:hover {
    background: hsl(0, 0%, 96%);
  }`};
`;

export const UploadButtonProtocol = styled.div`
  padding: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  width: 180px;
  height: 80px;
  transition: all ease-in-out 0.3s;

  span {
    font-size: clamp(0.7rem, 1.5vw, 1rem);
    margin: 0;
    padding: 0;
  }

  svg {
    transition: all ease-in-out 0.2s;
    width: clamp(1.5rem, 1.2, 2rem);
    height: clamp(1.5rem, 1.2, 2rem);
    margin: 0;
  }

  @media screen and (max-width: 480px) {
    flex-direction: column;
  }
`;

export const AreaPreviewProtocol = styled.div`
  width: 100%;
  height: auto;
  flex-direction: row;
  background-color: #f7f7f7;
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1rem 0;
`;
export const ImagePreviewProtocol = styled.div`
  img {
    width: 80px;
    height: 80px;
  }
`;
