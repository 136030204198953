import ServiceCRUD from '../../../services/ServiceCRUDNEWCHANNEL';
import { apiInstagramChat as http } from '../../../http';

class ConversationReportService extends ServiceCRUD {
  constructor() {
    super('configurations/channels');
  }

  async postCanal(path, canal) {
    const response = await http.post(`configurations/${path}`, canal);
    //console.log('response: ', response);
    return response.status === 200;
  }

  async putCanal(path, canal) {
    const response = await http.put(`configurations/${path}`, canal);
    //console.log('response: ', response);
    return response.status === 200;
  }

  async postFotoWebChat(path, canal) {
    const response = await http.post(`configurations/webchat/${path}`, canal, {
      headers: { 'content-type': 'multipart/form-data', 'local-upload': path }
    });
    return response.status === 200;
  }

  async postImageOpenWebChat(path, canal) {
    const response = await http.post(
      `configurations/webchat/open-chat`,
      canal,
      {
        headers: { 'content-type': 'multipart/form-data', 'local-upload': path }
      }
    );
    return response.status === 200;
  }

  getWhatson() {
    return http.get(`configurations/watson`);
  }

  async getConfigTimeout() {
    return http.get(`configurations/timeout`);
  }

  // updateConfigProtocolChat(data) {
  //   return http.put(
  //     `configuracao/api/protocolo/criarProtocoloParaConversa`,
  //     data
  //   );
  // }

  // getConfigProtocolChat() {
  //   return http.get(`configuracao/api/protocolo/criarProtocoloParaConversa`);
  // }

  // updateTimeForEndConversation(time) {
  //   return http.put(`configuracao/api/encerramento/tempo`, { time });
  // }

  // getWaitingTime() {
  //   return http.get(`configuracao/api/encerramento/tempo`);
  // }
}

export default new ConversationReportService();
