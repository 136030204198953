import React from 'react';

import { styles } from './styles';

const CircleNumber = ({ title, value, bgColor, ...props }) => {
  return (
    <div
      title={title}
      style={{ ...styles.container, backgroundColor: bgColor || '#FFF' }}
      {...props}
    >
      {value}
    </div>
  );
};

export default CircleNumber;
