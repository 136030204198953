import { useState, useEffect, useCallback } from 'react';
import ProtocolFieldsService from '../service/protocolFields';
import { protocolFieldTypes } from '../utils/protocolFieldTypes';

function useProtocolFieldsByRecordType(recordTypeId) {
  const [protocolCustomFields, setProtocolCustomFields] = useState(null);
  const [isLoadingProtocolCustomFields, setIsLoadingProtocolCustomFields] =
    useState(false);

  const getProtocolCustomFields = useCallback(async () => {
    setIsLoadingProtocolCustomFields(true);

    ProtocolFieldsService.getFieldsByRecordType(recordTypeId)
      .then((response) => {
        const fieldsData = response.data.content;
        setProtocolCustomFields(fieldsData);
      })
      .catch(() => {
        setProtocolCustomFields(null);
      })
      .finally(() => {
        setIsLoadingProtocolCustomFields(false);
      });
  }, [recordTypeId]);

  const getFieldsByType = useCallback(
    (type) => {
      if (!protocolCustomFields) return [];
      return protocolCustomFields.filter((field) => field.type === type);
    },
    [protocolCustomFields]
  );

  useEffect(() => {
    getProtocolCustomFields();
  }, [getProtocolCustomFields]);

  return {
    protocolCustomFields,
    isLoadingProtocolCustomFields,
    reloadProtocolCustomFields: getProtocolCustomFields,
    getTextFields: () => getFieldsByType(protocolFieldTypes.TEXT),
    getNumericFields: () => getFieldsByType(protocolFieldTypes.NUMERIC),
    getDateFields: () => getFieldsByType(protocolFieldTypes.DATE),
    getListFields: () => getFieldsByType(protocolFieldTypes.LIST),
    getCurrencyFields: () => getFieldsByType(protocolFieldTypes.CURRENCY),
    getHourFields: () => getFieldsByType(protocolFieldTypes.HOUR)
  };
}

export { useProtocolFieldsByRecordType };
