import { useCallback, useState } from 'react';

import { defaultLimit } from 'views/CRM/shared/utils/pagination';

import OpportunityTimeService from '../services/opportunityService';

export const useListOpportunities = ({ recordTypeId }) => {
  const [opportunities, setOpportunities] = useState(null);
  const [totalOpportunities, setTotalOpportunities] = useState(0);
  const [isLoadingOpportunities, setIsLoadingOpportunities] = useState(false);
  const [syntheticData, setSyntheticData] = useState([]);
  const [isLoadingsyntheticOpportunity, setIsLoadingsyntheticOpportunity] =
    useState(false);

  const getReportOpportunityTime = useCallback(
    (page = 1, params = {}) => {
      setIsLoadingOpportunities(true);

      OpportunityTimeService.getReportOpportunityTime(recordTypeId, params, {
        page,
        limit: defaultLimit
      })
        .then((response) => {
          setOpportunities(response.data.data);
          setTotalOpportunities(response.data.totalRegisters);
        })
        .finally(() => setIsLoadingOpportunities(false));
    },
    [recordTypeId]
  );

  const getReportSyntheticOpportunityTime = useCallback(
    (page = 1, params = {}) => {
      setIsLoadingsyntheticOpportunity(true);

      OpportunityTimeService.getReportSyntheticOpportunityTime(
        recordTypeId,
        params,
        {
          page,
          limit: defaultLimit
        }
      )
        .then((response) => {
          setSyntheticData(response.data.data);
        })
        .finally(() => setIsLoadingsyntheticOpportunity(false));
    },
    [recordTypeId]
  );

  const clearOpportunities = useCallback(() => {
    setOpportunities(null);
    setTotalOpportunities(0);
    setIsLoadingOpportunities(false);
  }, []);

  return {
    opportunities,
    totalOpportunities: totalOpportunities,
    isLoadingOpportunities: isLoadingOpportunities,
    clearLeads: clearOpportunities,
    getReportOpportunityTime,
    getReportSyntheticOpportunityTime,
    syntheticData,
    isLoadingsyntheticOpportunity
  };
};
