export function addInitUnAnsweredConversation(conversations) {
  return {
    type: '@UNANSWERED_CONVERSATION/ADD_INIT_CONVERSATIONS',
    payload: {
      conversations
    }
  };
}

export function addMessageUnansweredConversation(
  message,
  idDaConversa,
  novas_mensagens
) {
  return {
    type: '@UNANSWERED_CONVERSATION/ADD_MESSAGE_UNANSWERED_CONVERSATION',
    payload: {
      message,
      idDaConversa,
      novas_mensagens
    }
  };
}

export function addUnAnsweredConversation(conversation) {
  return {
    type: '@UNANSWERED_CONVERSATION/ADD_CONVERSATION',
    payload: {
      conversation
    }
  };
}

export function removeUnAnsweredConversation(id) {
  return {
    type: '@UNANSWERED_CONVERSATION/REMOVE_CONVERSATION',
    payload: {
      id
    }
  };
}
