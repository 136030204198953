import { apiUc } from '../../../../http';

const PROXY_BASE_URL = `/api`;

class SupervisorPanelService {
  getSupervisorPanelData() {
    return apiUc.get(`${PROXY_BASE_URL}/supervisor-panel`);
  }

  changePanalty(agentCode, data) {
    return apiUc.put(`${PROXY_BASE_URL}/penalidades/${agentCode}`, data);
  }

  queueLogOut({ extension, queueIds, agentCode }) {
    return apiUc({
      method: 'delete',
      data: {
        extension,
        queueIds,
        agent_code: agentCode
      },
      url: `${PROXY_BASE_URL}/agent-session`
    });
  }

  handlePause({ agentCode, pause_id }) {
    return apiUc.post(`${PROXY_BASE_URL}/agent-pause/${agentCode}`, {
      pause_id
    });
  }

  handleUnpause({ agentCode }) {
    return apiUc.delete(`${PROXY_BASE_URL}/agent-pause/${agentCode}`);
  }

  handleStartSpyOn(data) {
    return apiUc.post(`${PROXY_BASE_URL}/spying`, data);
  }
}

export default new SupervisorPanelService();
