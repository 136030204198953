import { apiCrm } from '../../../../http';

class SalesProcessService {
  async list(params, headers) {
    return apiCrm.get(`/sales-process`, {
      params,
      headers
    });
  }

  async create(data) {
    return apiCrm.post(`/sales-process`, data);
  }

  async getById(id) {
    return apiCrm.get(`/sales-process/${id}`);
  }

  async update(id, data) {
    return apiCrm.put(`/sales-process/${id}`, data);
  }

  async delete(id) {
    return apiCrm.delete(`/sales-process/${id}`);
  }
}

export default new SalesProcessService();
