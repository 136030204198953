import { statesByDdd } from '../../views/ServicePanel/assets/states';

export const getStateConversation = (phone) => {
  let ddd = null;
  if (phone.length === 12) {
    ddd = phone.substr(2, 2);
  } else if (phone.length === 10 || phone.length === 11) {
    ddd = phone.substr(0, 2);
  } else {
    return ddd;
  }

  for (var [key, value] of Object.entries(statesByDdd)) {
    if (key === ddd) {
      return value;
    }
  }
};
