import {
  AUTH_SUCCESS,
  AUTH_START,
  AUTH_FAIL,
  AUTH_LOGOUT,
  AUTH_ACTIVE_LOGOUT
} from './actionTypes';
import { apiAuth } from '../../http';
import { possiblePageFilters } from '../../hooks/useMemorizeInputsFilters';
import { toast } from 'react-toastify';
import store from 'store';
import { removeConversationInAttendance } from 'store/action/conversation-in-attendance-action';

const authStart = () => {
  return {
    type: AUTH_START
  };
};

const authSuccess = (
  token,
  name,
  should_sign_in_asterisk,
  use_scale,
  queuesidToSignIn
) => {
  return {
    type: AUTH_SUCCESS,
    token,
    name,
    should_sign_in_asterisk,
    use_scale,
    queuesidToSignIn
  };
};

const authFail = (error) => {
  return {
    type: AUTH_FAIL,
    error
  };
};

export function authActiveLogout() {
  return {
    type: AUTH_ACTIVE_LOGOUT
  };
}

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('name');
  localStorage.removeItem('email');
  localStorage.removeItem('login');
  localStorage.removeItem('roles');
  localStorage.removeItem('agent_code');
  localStorage.removeItem('extensions');
  localStorage.removeItem('queues');
  localStorage.removeItem('teams');
  localStorage.removeItem('nickname');
  localStorage.removeItem('type_of_pause');
  localStorage.removeItem('is_paused');
  localStorage.removeItem('show_all_conversations');
  localStorage.removeItem('show_all_protocols');
  localStorage.removeItem('show_all_leads');
  localStorage.removeItem('show_all_opportunities');
  localStorage.removeItem('pin_code');
  localStorage.removeItem('use_scale');
  localStorage.removeItem('should_sign_in_asterisk');
  localStorage.removeItem('currentLoggedInExtension');
  localStorage.removeItem('queuesToSignId');
  localStorage.removeItem('isLoginExtension');
  localStorage.removeItem('leadValue');

  if (possiblePageFilters) {
    possiblePageFilters.forEach((filter) => {
      if (localStorage.getItem(filter)) {
        localStorage.removeItem(filter);
      }
    });
  }
  store.dispatch(removeConversationInAttendance());

  return {
    type: AUTH_LOGOUT
  };
};

export const signIn = (login, password) => {
  return (dispath) => {
    // const {
    //   socketConversation
    // } = useConversationConnectionSocketForMonitoring();

    dispath(authStart());
    const data = {
      login,
      password
    };
    apiAuth
      .post('login', data)
      .then((res) => {
        const { data } = res;
        localStorage.setItem(
          'should_sign_in_asterisk',
          data.should_sign_in_asterisk
        );
        localStorage.setItem(
          'queuesToSignId',
          JSON.stringify(data.queuesToSignId)
        );

        localStorage.setItem('use_scale', data.use_scale);
        localStorage.setItem('is_using_scale', !!data.scale_id);
        localStorage.setItem('agent_code', data.agent_code);
        localStorage.setItem('token', data.token);
        localStorage.setItem('name', data.name);
        localStorage.setItem('queues', JSON.stringify(data.queues));
        localStorage.setItem('extensions', JSON.stringify(data.extensions));
        localStorage.setItem('teams', JSON.stringify(data.teams));
        localStorage.setItem('login', data.login);
        localStorage.setItem('nickname', data.nickname);
        localStorage.setItem('type_of_pause', data.type_of_pause);
        localStorage.setItem('is_paused', data.is_paused);
        localStorage.setItem('isLoginExtension', false);
        localStorage.setItem(
          'show_all_conversations',
          data.show_all_conversations
        );
        localStorage.setItem('show_all_protocols', data.show_all_protocols);
        localStorage.setItem('show_all_leads', data.show_all_leads);
        localStorage.setItem(
          'show_all_opportunities',
          data.show_all_opportunities
        );
        localStorage.setItem('pin_code', data.pin_code);
        // Receber a string
        // let pessoaString = localStorage.getItem('pessoa');
        // transformar em objeto novamente
        // let pessoaObj = JSON.parse(pessoaString);
        localStorage.setItem('roles', JSON.stringify(data.roles));
        dispath(
          authSuccess(
            data.token,
            data.name,
            data.should_sign_in_asterisk,
            data.use_scale,
            data.queuesidToSignIn
          )
        );
      })
      .catch((err) => {
        console.log('err', err);
        const messagem = 'Login/Senha inválido(s)';
        //toast.error(messagem);
        toast.error(err.response?.data?.message);
        dispath(authFail(messagem));
      });
  };
};
