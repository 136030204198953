import React from 'react';
import { useSelector } from 'react-redux';
import { TitlePreview } from './styled';
export function PreviewImage({ previewImage, title = true }) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  return (
    <>
      {previewImage ? (
        <div>
          <img
            src={previewImage.url}
            alt={previewImage.nameFile}
            title={previewImage.nameFile}
          />
          {title && (
            <TitlePreview
              colorText={systemConfiguration.primary_color}
              haveBorderTop={true}
            >
              <h4 className="display-6">{previewImage.nameFile}</h4>
            </TitlePreview>
          )}
        </div>
      ) : null}
    </>
  );
}
