import React, { useState, useEffect } from 'react';
import DefaultModal from 'components/Modal/DefaultModal';
import { Row, Col, InputGroup, Input, InputGroupAddon } from 'reactstrap';

import ModalFilterClients from '../components/ModalFilterClients';
import ReactButton from 'components/Buttons/ReactButton';
import ReactLoader from 'components/Loader';
import { TableContainer } from './style';
import CreateContactModal from '../CreateContactModal/index';
import ContactService from '../service/index';
import Pagination from 'components/Pagination';
import TemplatesModal from '../TemplatesModal/index';
import { toast } from 'react-toastify';
import ModalConfirmation from '../../../../../../../components/Modal/ConfirmationModal';
import useDebounce from './hooks/useDebaunce';
import { isNaN } from 'lodash';
import { SearchContactButton } from 'components/Buttons/SearchContactButton';
import MultiContactsModal from './components/multiContactsTable';

const ContactActiveModal = ({ show, onModalToggle, ...props }) => {
  const [createContactModalVisbility, setCreateContactModalVisbility] =
    useState(false);
  const [initMultiContactModal, setInitMultiContactModal] = useState(false);
  const [modalConversationVisbility, setModalConversationVisbility] =
    useState(false);
  const [loading, setLoading] = useState(false);

  const [isClientSearchModalOpen, setIsClientSearchModalOpen] = useState(false); // controle do modal de busca de clientes

  const [page, setPage] = useState(1);
  const [count, setCount] = useState(0);

  const limitOfContactsPerPage = 10;

  const [filterByName, setFilterByName] = useState('');
  const [filterByTelephone, setFilterByTelephone] = useState('');

  const [contacts, setContacts] = useState([]);

  const [clientNumber, setClientNumber] = useState();
  const [clientId, setClientId] = useState();
  const [contactId, setContactId] = useState();
  const [clientName, setClientName] = useState();

  const [searchValue, setSearchValue] = useState('');

  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [idResource, setIdResource] = useState();
  const [selectedRows, setSelectedRows] = useState([]);

  const debouncedSearchValue = useDebounce(searchValue, 1000);

  useEffect(() => {
    if (searchValue.length === 0 || !searchValue.length) {
      setPage(1);
      setFilterByName('');
      setFilterByTelephone('');
      return;
    }

    const defaultFieldNames = ['nome', 'telefone'];
    const textFilter = [...defaultFieldNames].map((fieldName) => ({
      [fieldName]: searchValue
    }));
    const fieldsToFilter = [...textFilter];

    const filters = {};
    fieldsToFilter.forEach((filter) => {
      Object.assign(filters, filter);
    });

    const filtersDTO = {};
    const filtersDTOAllContacts = {};
    for (const key in filters) {
      const value = filters[key];
      if (value) {
        filtersDTO[key] = value;
        filtersDTOAllContacts[key] = value;
      }
    }
    const isNotANumber = isNaN(parseInt(filtersDTOAllContacts.telefone));
    if (isNotANumber) {
      filtersDTOAllContacts.telefone = '';
    } else {
      filtersDTOAllContacts.nome = '';
    }

    setFilterByName(filtersDTOAllContacts.nome);
    setFilterByTelephone(filtersDTOAllContacts.telefone);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  useEffect(() => {
    async function goToFistPage() {
      setPage(1);
    }
    goToFistPage();
  }, [filterByName, filterByTelephone, debouncedSearchValue]);

  useEffect(() => {
    setLoading(true);
    getAllContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    page,
    filterByName,
    filterByTelephone,
    isClientSearchModalOpen,
    show,
    createContactModalVisbility
  ]);

  useEffect(() => {
    setFilterByTelephone('');
    setFilterByName('');
    setSearchValue('');
  }, [show]);

  const getAllContacts = () => {
    ContactService.getContacts({
      page,
      name: filterByName,
      telephone: filterByTelephone
    })
      .then((response) => {
        if (response.data.registers === null) {
          setCount(response.data.count);
          setContacts([]);
        } else {
          setCount(response.data.count);
          setContacts(response.data.registers);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Erro na consulta =>', error);
        toast.error('Erro ao buscar contatos');
        setLoading(false);
      });
  };

  async function handleSelectcontact(contact) {
    setContactId(contact.id);
    setClientNumber(contact.client_phone);
    setModalConversationVisbility(!modalConversationVisbility);
  }

  async function handleSelectMultiContact() {
    setModalConversationVisbility(!modalConversationVisbility);
  }

  const controlDelete = (contactId) => {
    setOpenModalConfirmation(true);
    setIdResource(contactId);
  };

  const deleteContact = async (contactId) => {
    try {
      const deleted = await ContactService.deleteContact(contactId);
      toast.success(deleted.data.message || 'Contato deletado com sucesso', {
        autoClose: 3000,
        closeOnClick: true
      });
    } catch (err) {
      console.log(err);
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        'Erro ao deletar contato';
      toast.error(message, {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setOpenModalConfirmation(false);
      getAllContacts();
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const alterDefaultModalData = (data) => {
    setClientId(data.clientId);
    setClientNumber(data.clientPhone);
    setContactId(data.contactId);
    setClientName(data.clientName);
    setModalConversationVisbility(!modalConversationVisbility);
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      console.log(row);
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x.id !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const allRows = rows.map((r) => {
      return r;
    });
    if (isSelect) {
      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <DefaultModal
      show={show}
      onModalToggle={onModalToggle}
      title="Buscar Contato"
      subtitle={`Total de contatos ${count}`}
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      <Row>
        <Col>
          <InputGroup>
            <Input
              bsSize="md"
              placeholder="Pesquisar contatos"
              type="text"
              defaultValue=""
              onChange={(event) => setSearchValue(event.target.value)}
            />
            {!initMultiContactModal && (
              <InputGroupAddon addonType="append">
                <SearchContactButton
                  onClick={() => setIsClientSearchModalOpen(true)}
                  style={{
                    fontSize: '1.4rem',
                    display: 'inline',
                    color: 'rgb(0, 100, 140)',
                    cursor: 'pointer',
                    marginLeft: '0.4rem',
                    marginTop: '0.8rem'
                  }}
                />
              </InputGroupAddon>
            )}
          </InputGroup>
        </Col>
      </Row>
      <TableContainer
        style={{
          overflowX: 'auto'
        }}
      >
        {loading ? (
          <Row className="justify-content-md-center py-3">
            <ReactLoader />
          </Row>
        ) : (
          <MultiContactsModal
            contacts={contacts}
            handleOnSelect={handleOnSelect}
            handleOnSelectAll={handleOnSelectAll}
            selectedRows={selectedRows}
            initMultiContactModal={initMultiContactModal}
            page={page}
            limitOfContactsPerPage={limitOfContactsPerPage}
            handleSelectcontact={handleSelectcontact}
            controlDelete={controlDelete}
          />
        )}
        <Pagination
          activePage={page}
          totalItemsCount={count}
          onChange={handlePageChange.bind(this)}
        />
      </TableContainer>

      <div
        style={{
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <ReactButton
          onClick={() => {
            setCreateContactModalVisbility(!createContactModalVisbility);
          }}
          btnColor="primary"
        >
          + Adicionar novo contato
        </ReactButton>
        <ReactButton
          onClick={() => {
            setInitMultiContactModal(!initMultiContactModal);
            setSelectedRows([]);
          }}
          btnColor="secondary"
        >
          {initMultiContactModal
            ? 'Iniciar uma conversa'
            : 'Iniciar várias conversas'}
        </ReactButton>
        {initMultiContactModal && selectedRows.length > 0 ? (
          <ReactButton
            onClick={() => {
              handleSelectMultiContact();
            }}
            btnColor="secondary"
          >
            Escolher template
          </ReactButton>
        ) : null}
      </div>

      <TemplatesModal
        show={modalConversationVisbility}
        onModalToggle={setModalConversationVisbility}
        clientNumber={clientNumber}
        contactId={contactId}
        clientId={clientId}
        clientName={clientName}
        showCloseButton={false}
        isIsolated={contactId ? false : true}
        selectedContacts={selectedRows}
        setSelectedContacts={setSelectedRows}
        isMultiContact={initMultiContactModal}
      />
      <CreateContactModal
        className="modal-lg"
        show={createContactModalVisbility}
        onModalToggle={setCreateContactModalVisbility}
      ></CreateContactModal>

      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={deleteContact}
        message={'Tem certeza que deseja excluir este contato?'}
      />
      <ModalFilterClients
        className="modal-lg"
        show={isClientSearchModalOpen}
        onModalToggle={setIsClientSearchModalOpen}
        setSelectedClient={alterDefaultModalData}
      />
    </DefaultModal>
  );
};

export default ContactActiveModal;
