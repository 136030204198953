import styled from 'styled-components';

export const IconLogoHeader = styled.div`
  width: 3rem;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid ${(props) => props.backgroundColor};
  border-radius: 999999999px;
  background-color: ${(props) => props.backgroundColor};
`;
