import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { format, sub } from 'date-fns';
import { dateRules, wordCloudScoreOptions } from './assets';
import { ContainerMessage } from './styled';
import ConversationAnalyticsService from './service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody
} from 'reactstrap';

import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import Table from './components/table';
import imageScore from './assets/img/score.png';
import { Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from 'components/Pagination';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1)
  },
  customWidth: {
    maxWidth: 500
  },
  noMaxWidth: {
    maxWidth: 'none'
  }
}));

const ConversationReport = () => {
  const { control, handleSubmit, errors, setValue, reset, getValues } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [flag, setFlag] = useState(false);
  const [loading, setLoading] = useState(false);
  const [quantity, setQuantity] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const [dataToSend, setDataToSend] = useState({});
  const [mensagens, setMensagens] = useState([]);
  const [intentions, setIntentions] = useState([
    {
      label: '',
      value: ''
    }
  ]);

  const classes = useStyles();

  useEffect(() => {
    fillIntentionsSelect();
  }, []);

  async function fillIntentionsSelect() {
    await ConversationAnalyticsService.getIntentions({
      dataFinal: format(new Date(), 'yyyy-MM-dd'),
      dataInicial: format(sub(new Date(), { months: 1 }), 'yyyy-MM-dd')
    })
      .then((res) => {
        setIntentions(res.data.intencao);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function loadTableConversation(data, pagina) {
    setIsTableLoading(true);
    setFlag(false);
    await ConversationAnalyticsService.getConversationAnalytics({
      pagina: pagina || activePage,
      dataFinal: data.dataFinal,
      dataInicial: data.dataInicial,
      scoreRange: data.scoreRange,
      intencao: data.intentions
    })
      .then((res) => {
        setFlag(true);
        if (res.data.quantidadeDeMensagens === 0) {
          toast.info('Nenhum registro foi encontrado!');
        } else {
          setMensagens(res.data.mensagens);
          setQuantity(res.data.quantidadeDeMensagens);
        }
        setIsTableLoading(false);
      })
      .catch((err) => {
        setFlag(true);
        setIsTableLoading(false);
        console.log(err);
      });
  }
  const handlePageChange = (page) => {
    setActivePage(page);
    loadTableConversation(dataToSend, page);
  };

  const clearData = () => {
    setActivePage(1);
    setDataToSend({});
    setQuantity(0);
  };

  const toISOFormat = (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');

    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${YYYY}-${MM}-${DD}`;
  };

  const getDataDTO = (data) => {
    let formatedData;

    formatedData = {
      ...data,
      dataInicial: `${toISOFormat(data.dataInicial)}`,
      dataFinal: `${toISOFormat(data.dataFinal)}`
    };

    setDataToSend(formatedData);

    return formatedData;
  };

  const onSubmit = (data) => {
    clearData();

    try {
      const formatedData = getDataDTO(data);
      setDataToSend(formatedData);
      loadTableConversation(formatedData, activePage);
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const cleanFilter = () => {
    reset({
      dataInicial: new Date().toLocaleDateString('pt-br'),
      dataFinal: new Date().toLocaleDateString('pt-br'),
      scoreRange: ''
    });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório Analítico de Conversas</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      <Form
                        className="needs-validation"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Row className="mb-3"></Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dataInicial"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dataInicial"
                              rules={dateRules}
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dataInicial"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Data final*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dataFinal"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dataFinal"
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                              rules={{
                                ...dateRules,
                                validate: (value) => {
                                  if (
                                    new Date(toISOFormat(value, true)) <
                                    new Date(
                                      toISOFormat(
                                        getValues()['dataInicial'],
                                        true
                                      )
                                    )
                                  ) {
                                    return 'A data final não pode ser anterior à inicial!';
                                  } else {
                                    return true;
                                  }
                                }
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dataFinal"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="6">
                            <label className="form-control-label">
                              Filtrar por score:
                            </label>
                            <Tooltip
                              title={<img alt="imageScore" src={imageScore} />}
                              classes={{ tooltip: classes.noMaxWidth }}
                              placement="bottom-start"
                            >
                              <i
                                className="fas fa-info-circle"
                                datatoggle="tooltip"
                                dataplacement="right"
                                datacontainer="body"
                                dataanimation="true"
                                style={{
                                  marginLeft: '15px',
                                  fontSize: '20px',
                                  color: '#707a95'
                                }}
                              />
                            </Tooltip>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={wordCloudScoreOptions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="scoreRange"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="scoreRange"
                              defaultValue=""
                            />
                          </Col>

                          <Col md="6">
                            <label className="form-control-label">
                              Intenções:
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={intentions}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="intentions"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="intentions"
                              defaultValue=""
                            />
                          </Col>
                        </Row>
                        <br />
                        <Button
                          style={buttonStyle(systemConfiguration.primary_color)}
                          type="submit"
                          disabled={isTableLoading}
                        >
                          {isTableLoading ? (
                            <Row className="justify-content-md-center">
                              <Loader
                                type="TailSpin"
                                color={'white'}
                                height={18}
                                width={78}
                              />
                            </Row>
                          ) : (
                            'Buscar'
                          )}
                        </Button>
                        <Button
                          onClick={cleanFilter}
                          style={buttonStyle(
                            systemConfiguration.cancelation_button_color
                          )}
                        >
                          Limpar
                        </Button>
                      </Form>
                    </>
                  )}
                  {isTableLoading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    quantity !== 0 && (
                      <>
                        <Table
                          data={mensagens}
                          quantity={quantity}
                          totalRegister={quantity}
                        ></Table>
                        <Pagination
                          activePage={activePage}
                          totalItemsCount={quantity}
                          itemsCountPerPage={10}
                          onChange={handlePageChange.bind(this)}
                        />
                      </>
                    )
                  )}
                  {flag === true && quantity === 0 && (
                    <Row>
                      <Col md="12">
                        <ContainerMessage>
                          <p>Conversas não encontradas!</p>
                        </ContainerMessage>
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ConversationReport;
