import DefaultModal from './DefaultModal';
import React, { useCallback } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { useSelector } from 'react-redux';

const ConfirmModal = ({
  isModalOpen,
  onModalToggle,
  idResource,
  deleteResource,
  modalContainerClasses = 'modal-sm',
  messageContainsHtml = false,
  message,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Confirmação"
      showCloseButton={false}
      modalContainerClasses={modalContainerClasses}
      {...props}
    >
      <>
        {!messageContainsHtml ? (
          <h3
            className="mb-0 align-text-center"
            style={{ textAlign: 'center' }}
          >
            {message}
          </h3>
        ) : (
          <div dangerouslySetInnerHTML={{ __html: message }} />
        )}

        <hr />
        <Row>
          <Col md="8">
            <Button
              disabled={!!props?.executingBroadcastList}
              style={buttonStyle(systemConfiguration.confirmation_button_color)}
              onClick={() => deleteResource(idResource)}
            >
              Confirmar
            </Button>
          </Col>
          <Col md="4">
            <Button
              style={buttonStyle(systemConfiguration.cancelation_button_color)}
              onClick={closeModal}
            >
              Voltar
            </Button>
          </Col>
        </Row>
      </>
    </DefaultModal>
  );
};

export default ConfirmModal;
