import styled from 'styled-components';
import { shade } from 'polished';

export const Button = styled.button`
  margin-top: 40px;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  background: ${(props) => props.color};
  height: 35px;
  width: 90px;
  border: 0px;
  border-radius: 3px;
  transition: ease 0.5s;
  outline: 0px;
  margin-bottom: 0px;

  & + & {
    margin-left: 10px;
  }

  &:hover {
    outline-style: none;
    background: ${(props) => shade('0.2', props.color)};
  }

  &:focus {
    outline: none;
  }
`;
