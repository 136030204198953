import { ErrorMessage } from '@hookform/error-message';
import FlexSelect from 'components/Inputs/FlexSelect';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { GrTransaction } from 'react-icons/gr';
import Loader from 'react-loader-spinner';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row
} from 'reactstrap';
import { removeAnsweredConversation } from 'store/action/answered-conversations-action';
import { removeConversationInAttendance } from 'store/action/conversation-in-attendance-action';
import { getStateConversation } from 'utils/fuctions/getStateConversation';
import QueueService from '../../../../../../Queue/service';
import { Option, StyleOption } from '../../styled';
import { useSocketConversationInstagram } from 'hooks/useSocketConversationInstagram';

const TransferConversation = ({
  name,
  tooltipTitle = '',
  id,
  conversation,
  isCurrentAgentsLogged
}) => {
  const { socketInstagramConversation } = useSocketConversationInstagram();
  const dispatch = useDispatch();
  const { control, handleSubmit, errors, setValue } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [queues, setQueues] = useState([]);
  const [isTransferByAgentEspecific, setInsTransferByAgentEspecific] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const state =
    (conversation.cliente.telefone &&
      getStateConversation(conversation.cliente.telefone)) ||
    'DDD não definido';
  const [
    isCurrentAgentsLoggedWithoutTheCurrentAgent,
    setIsCurrentAgentsLoggedWithoutTheCurrentAgent
  ] = useState([]);

  useEffect(() => {
    if (isOpen === true) {
      const params = {
        showAllQueues: true,
        showDisable: false
      };
      QueueService.getAll(params.showAllQueues, params.showDisable)
        .then((response) => {
          let filteredQueues = response.data.data.filter((fila) => {
            return fila['is_chat'];
          });
          setQueues(filteredQueues);
        })
        .catch((err) => {});
    }
  }, [isOpen]);

  useEffect(() => {
    if (isTransferByAgentEspecific) {
      if (isCurrentAgentsLogged) {
        const agentsWithoutCurrentAgent = isCurrentAgentsLogged.filter(
          (agent) => agent.login !== localStorage.getItem('login')
        );
        setIsCurrentAgentsLoggedWithoutTheCurrentAgent(
          agentsWithoutCurrentAgent
        );
      }
    }
  }, [isCurrentAgentsLogged, isTransferByAgentEspecific]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = async (data) => {
    try {
      if (isTransferByAgentEspecific) {
        const agentFilter = isCurrentAgentsLogged.find(
          (agent) => data.user === agent.login
        );

        const atendente = {
          name: agentFilter.name,
          login: agentFilter.login,
          id_socket_atendente: agentFilter.socket_id
        };

        socketInstagramConversation.emit('transferir_conversa_atendente', {
          id,
          //codigo para transferencia agente para agente com escolha de fila
          // fila: data.fila,
          atendente,
          responsavelPelaTransferencia: {
            name: localStorage.getItem('name'),
            login: localStorage.getItem('login')
          }
        });
      } else {
        const formatedData = { ...data };

        socketInstagramConversation.emit('transferir_conversa', {
          id,
          fila: formatedData.fila,
          formulario: {
            ...formatedData
          },
          responsavelPelaTransferencia: {
            name: localStorage.getItem('name'),
            login: localStorage.getItem('login')
          }
        });
      }

      dispatch(removeAnsweredConversation(id));
      dispatch(removeConversationInAttendance());
      toast.success('Conversa transferida com sucesso!', {
        autoClose: 3000,
        closeOnClick: true
      });
      setLoading(true);
    } catch (err) {
      const { data } = err.response;
      toast.error(data.message || 'Erro ao transferir conversa!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <Option title={tooltipTitle}>
        <Button
          onClick={toggleModal}
          color="link"
          size="sm"
          style={StyleOption}
        >
          <GrTransaction size={20} color="#32325d" />
        </Button>
      </Option>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {name}
          </h6>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <Container>
          <Col>
            <Card style={{ backgroundColor: '#FFFFFF' }}>
              <CardBody>
                <CardTitle tag="h4">
                  Cliente: {conversation.cliente.nome || 'Não informado'}
                </CardTitle>
                <CardSubtitle tag="h5" className="mb-3 text-muted">
                  <Row>
                    <Col md="6" title="CPF do cliente">
                      <i
                        className="fas fa-id-card"
                        style={{ color: '#5bc0de' }}
                      ></i>{' '}
                      - {conversation.cliente.cpf || 'Não informado'}
                    </Col>

                    <Col md="6" title="Canal de atendimento">
                      <i
                        className="fas fa-users"
                        style={{ color: '#0275d8' }}
                      ></i>{' '}
                      - {conversation.canal || 'Não reconhecido'}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" title="Telefone do cliente">
                      <i
                        className="fas fa-phone"
                        style={{ color: '#5cb85c' }}
                      ></i>{' '}
                      - {conversation.cliente.telefone || 'Não informado'}
                    </Col>

                    <Col md="6" title="Estado">
                      <i
                        className="fas fa-city"
                        style={{ color: '#f0ad4e' }}
                      ></i>
                      {conversation.cliente.telefone && ` - Estado : ${state}`}
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6" title="Email do cliente">
                      <i
                        className="fas fa-envelope-open-text"
                        style={{ color: '#f0ad4e' }}
                      ></i>{' '}
                      - {conversation.cliente.email || 'Não informado'}
                    </Col>
                    <Col md="6" title="Atendente">
                      <i
                        className="fas fa-user"
                        style={{ color: '#f0ad4e' }}
                      ></i>
                      {conversation?.atendente?.name
                        ? ` - ${conversation?.atendente?.name}`
                        : ' - Não informado'}
                    </Col>
                  </Row>
                </CardSubtitle>
              </CardBody>
            </Card>
          </Col>
        </Container>
        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />

          {loading === true ? (
            <Row className="justify-content-md-center mt-6">
              <Loader
                type="TailSpin"
                color="#35ac50"
                height={100}
                width={100}
              />
            </Row>
          ) : (
            <Form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row className="mb-3">
                <Col md="12">
                  <FormGroup check>
                    <Input
                      type="checkbox"
                      checked={isTransferByAgentEspecific}
                      onChange={() =>
                        setInsTransferByAgentEspecific(
                          !isTransferByAgentEspecific
                        )
                      }
                    />
                    <label className="form-control-label">
                      Trocar forma de transferência para atendente específico
                    </label>
                  </FormGroup>
                </Col>
              </Row>
              {!isTransferByAgentEspecific ? (
                <Row className="mb-3">
                  <Col md="12">
                    <label className="form-control-label">Fila*</label>
                    <Controller
                      render={(props) => (
                        <FlexSelect
                          dataOptions={queues}
                          isMulti={false}
                          isClearable={true}
                          value={props.value}
                          valueController={setValue}
                          closeMenuOnSelect={true}
                          isSearchable={true}
                          fieldName="fila"
                          labelName="name"
                          valueName="name"
                        />
                      )}
                      control={control}
                      name="fila"
                      defaultValue=""
                      rules={{ required: 'A fila é obrigatória' }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="fila"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>
                </Row>
              ) : (
                <Row className="mb-3">
                  <Col md="12">
                    <label className="form-control-label">Usuário*</label>
                    <Controller
                      render={(props) => (
                        <FlexSelect
                          dataOptions={
                            isCurrentAgentsLoggedWithoutTheCurrentAgent
                          }
                          isMulti={false}
                          isClearable={true}
                          value={props.value}
                          valueController={setValue}
                          closeMenuOnSelect={true}
                          isSearchable={true}
                          fieldName="user"
                          labelName="name"
                          valueName="login"
                        />
                      )}
                      control={control}
                      name="user"
                      defaultValue=""
                      rules={{ required: 'Usuário é obrigatório!' }}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="user"
                      render={({ message }) => errorFormMessage(message)}
                    />
                  </Col>

                  {/*
                   //codigo para transferencia agente para agente com escolha de fila
                  {isCurrentSelectAgentQueues !== undefined &&
                    isCurrentSelectAgentQueues.length > 0 && (
                      <Col md="12">
                        <label className="form-control-label">
                          Filas do atendente selecionado
                        </label>

                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={isCurrentSelectAgentQueues}
                              isMulti={false}
                              isClearable={true}
                              value={props.value}
                              valueController={setValue}
                              closeMenuOnSelect={true}
                              isSearchable={false}
                              fieldName="fila"
                              labelName="name"
                              valueName="value"
                            />
                          )}
                          control={control}
                          name="fila"
                          defaultValue=""
                          rules={{
                            required: 'Filas do atendente é obrigatório!'
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="fila"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    )} */}
                </Row>
              )}

              {/* <Row className="mb-5">
                <Col md="12">
                  <label className="form-control-label">Observação</label>
                  <Controller
                    as={
                      <Input
                        type="textarea"
                        rows="3"
                        name="observacao"
                        id="observacao"
                      />
                    }
                    control={control}
                    name="observacao"
                    defaultValue=""
                  />
                </Col>
              </Row> */}

              <Button
                color="danger"
                data-dismiss="modal"
                type="button"
                onClick={toggleModal}
              >
                Fechar
              </Button>

              <Button color="primary" type="submit">
                Transferir
              </Button>
            </Form>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default TransferConversation;
