import styled from 'styled-components';

export const EditProtocolByNumber = styled.div`
  cursor: pointer;
  transition: ease-in-out 0.4s;

  &:hover {
    color: #4892f9;
    scale: 1.1;
  }
`;
