import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import ProfileService from '../service';
import RoleService from '../service/RoleService';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import FlexSelect from 'components/Inputs/FlexSelect';
import { brazilian } from '../../../assets/translator/pt-br';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const ProfileForm = (props) => {
  const history = useHistory();
  const { id } = props.match.params;

  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [roles, setRoles] = useState([]);

  useEffect(() => {
    setLoading(true);
    RoleService.getAll()
      .then((response) => {
        const traduct = response.data.data.map((item) => {
          return {
            ...item,
            role: brazilian[item.role]
          };
        });
        setRoles(sortData(traduct));
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      ProfileService.get(id)
        .then((response) => {
          let getProfile = response.data.data;

          let arrayQueuesId = [];
          getProfile.roles.forEach((queue) => {
            arrayQueuesId.push(queue.id);
          });
          getProfile = { ...getProfile, roleIds: arrayQueuesId };

          reset(getProfile);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(
            err.response.data.message || 'Erro ao carregar perfil de acesso!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
          setLoading(false);
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      const formatedData = {
        ...data
      };

      if (formatedData.roleIds.length === 0) {
        toast.error('Selecione pelo menos uma permissão!', {
          autoClose: 3000,
          closeOnClick: true
        });
        return;
      }

      isUpdate
        ? await ProfileService.update(id, formatedData)
        : await ProfileService.create(formatedData);
      history.push('/admin/profile');

      toast.success(
        isUpdate
          ? 'Perfil de acesso alterado com sucesso, por favor realize login novamente!'
          : 'Perfil de acesso cadastrado com sucesso!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    } catch (err) {
      toast.error(err.response?.data?.message || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/profile');
  };

  const sortData = (data) => {
    const dataSort = data.sort((a, b) => {
      if (a.role && b.role) {
        if (a.role.toLowerCase() < b.role.toLowerCase()) return -1;
        if (a.role.toLowerCase() > b.role.toLowerCase()) return 1;
        return 0;
      }
      return null;
    });
    return dataSort;
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/profile"
        buttonTitle="Voltar para a pagina de perfil de acesso"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Perfil de acesso</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{ required: 'Nome é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md="6">
                          <label className="form-control-label">
                            Descrição
                          </label>
                          <Controller
                            as={Input}
                            control={control}
                            name="description"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col md="12">
                          <label className="form-control-label">Papéis</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={roles}
                                isMulti={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="roleIds"
                                labelName="role"
                                valueName="id"
                                isClearable={true}
                              />
                            )}
                            control={control}
                            name="roleIds"
                            defaultValue=""
                          />
                        </Col>
                      </Row>

                      <hr />

                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ProfileForm;
