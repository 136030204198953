export function generatePreview() {
  function getImagePreview(imagesWithApprovedExtension) {
    const imagePreview = imagesWithApprovedExtension.map((file) => {
      return {
        nameFile: file.name,
        url: URL.createObjectURL(file)
      };
    });

    return imagePreview;
  }

  function getVideoPreview(video) {
    const videoPreview = {
      nameFile: video.name,
      url: URL.createObjectURL(video)
    };

    return videoPreview;
  }

  function getPDFPreview(filesWithApprovedExtension) {
    const TYPE = 'application/pdf';
    const filesPreview = filesWithApprovedExtension.map((file) => {
      return {
        nameFile: file.name,
        url: URL.createObjectURL(file, { type: TYPE })
      };
    });

    return filesPreview;
  }

  function getPDFPreviewSingleFile(file) {
    try {
      const TYPE = 'application/pdf';
      const pdfPreview = {
        nameFile: file.name,
        url: URL.createObjectURL(file, { type: TYPE })
      };

      return pdfPreview;
    } catch (e) {
      return undefined;
    }
  }

  function getImagePreviewSingleFile(file) {
    try {
      const imagePreview = {
        nameFile: file.name,
        url: URL.createObjectURL(file)
      };

      return imagePreview;
    } catch (e) {
      return undefined;
    }
  }

  return {
    getImagePreview,
    getPDFPreview,
    getVideoPreview,
    getPDFPreviewSingleFile,
    getImagePreviewSingleFile
  };
}
