/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from 'reactstrap';

class Calendar extends React.Component {
  render() {
    return (
      <>
        <Container fluid style={{ zIndex: -10 }}>
          {/* <footer className="footer pt-0"> */}
          <Row>
            <Col md="12">
              <div className="copyright text-lg-left text-muted">
                © {new Date().getFullYear()}{' '}
                <a
                  className="font-weight-bold ml-1"
                  href="https://www.g4flex.com.br/"
                  target="_blank"
                >
                  G4Flex
                </a>
              </div>
            </Col>
          </Row>
          {/* </footer> */}
        </Container>
      </>
    );
  }
}

export default Calendar;
