/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import ReasonService from '../service';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Pagination from 'components/Pagination';
import ModalConfirmation from '../../../components/Modal/ConfirmationModal';

import { Container, Row, Card, CardHeader, Button } from 'reactstrap';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const ReasonList = () => {
  const [reasons, setReasons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [idResource, setidResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  useEffect(() => {
    getReason();
  }, []);

  const getReason = (selectedPage = 1) => {
    ReasonService.getAll(selectedPage)
      .then((res) => {
        const reasons = res.data.content;
        const quantity = res.data.quantity;
        const newActivePage = selectedPage || activePage;
        setReasons(reasons);
        setQuantity(quantity);
        setActivePage(newActivePage);
        setLoading(false);
      })
      .catch((error) => {
        const { data } = error.response;
        toast.error(data.message || 'Erro ao listar motivos!', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const handlePageChange = (activePage) => getReason(activePage);

  const deleteReason = (id) => {
    ReasonService.delete(id)
      .then((res) => {
        const { data } = res;
        getReason();
        toast.success(data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .catch((error) => {
        const { data } = error.response;
        toast.error(data.message || 'Erro ao deletar motivo!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        getReason();
      });
  };

  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/reason/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Motivo</h3>
                </CardHeader>
                <ToolkitProvider
                  data={reasons}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'description',
                      text: 'Descrição',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link to={'/admin/reason/form/' + row.id}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => controlDelete(row.id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={deleteReason}
        message={'Tem certeza que deseja excluir o Motivo?'}
      />
    </>
  );
};

export default ReasonList;
