export const useMakeConversationCounters = () => {
  const makeConversationCountersDTO = (data) => {
    return {
      qtdTotalConversations: data?.conversasAbertas || 0,
      qtdTotalMissed: data?.conversasNaoAtendidas || 0,
      qtdTotalInAttendance: data?.conversasEmAtendimento || 0,
      qtdTotalBot: data?.atendimentoBot || 0,
      qtdTotalHuman: data?.atendimentoHumano || 0,
      qtdTotalSuccessAttendance: data?.conversasEmAtendimento || 0,
      qtdEnded: data?.conversasEncerradas || 0,
      qtdAbandonadas: data?.conversasAbandonadas || 0
    };
  };

  return {
    makeConversationCountersDTO
  };
};
