import React, { useEffect, useState } from 'react';
import PJSIPConfigService from '../service';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Pagination from 'components/Pagination';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';
import Loader from 'react-loader-spinner';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';

const PJSIPConfigurationList = () => {
  const [pjsipConfiguration, setPJSIPConfigurations] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const [timeoutID, setTimeoutID] = useState(null); // Timeout da busca por texto

  useEffect(() => {
    handleGetPJSIPConfigurations();
  }, []);

  const handleGetPJSIPConfigurations = (selectedPage = 1, querySearch = '') => {
    setLoading(true);
    PJSIPConfigService.getAll(selectedPage, 10, false, querySearch)
      .then((res) => {
        const configs = res.data.data;

        const quantity = res.data.totalRegisters;
        const newActivePage = selectedPage;
        setPJSIPConfigurations(configs);
        setQuantity(quantity);
        setActivePage(newActivePage);
      })
      .catch((error) => {
        const { message } = error.response.data;
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (activePage) =>
    handleGetPJSIPConfigurations(activePage);

  const handleOnSearch = (event) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const q = event.target.value;
    setTimeoutID(
      setTimeout(() => {
        handleGetPJSIPConfigurations(1, q);
      }, 1000)
    );
  };

  const handleDeletePJSIPConfiguration = (id) => {
    if (!window.confirm('Deseja exluir essa configuração?')) return;
    PJSIPConfigService.delete(id)
      .then(() => {
        handleGetPJSIPConfigurations();
        toast.success('Configuração excluída!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .catch((err) => {
        const { message } = err.response.data;
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/pjsip_configuration/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Configurações PJSIP</h3>
                </CardHeader>

                <Row className="mt-3 mx-2">
                  <Col md="8">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Pesquisar por nome"
                        type="text"
                        defaultValue=""
                        onChange={handleOnSearch}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <ToolkitProvider
                  data={pjsipConfiguration}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'transport',
                      text: 'Transporte',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'bind',
                      text: 'Bind',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'external_media_address',
                      text: 'External Media Address',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'external_signaling_address',
                      text: 'External Signaling Address',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'external_signaling_port',
                      text: 'External Signaling Port:',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'local_net',
                      text: 'Redes locais',
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        let nets = [];
                        for (let i = 1; i <= 10; i++) {
                          if (row[`local_net_${i}`])
                            nets.push(row[`local_net_${i}`]);
                        }
                        return nets.join(', ');
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link
                            to={'/admin/pjsip_configuration/form/' + row.id}
                          >
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },

                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            onClick={() =>
                              handleDeletePJSIPConfiguration(row.id)
                            }
                            size="sm"
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <Loader
                            type="TailSpin"
                            color="#35ac50"
                            height={100}
                            width={100}
                          />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PJSIPConfigurationList;
