export function openSideDrawer(data) {
  return {
    type: '@SIDEDRAWER_OPEN',
    payload: data
  };
}

export function closeSideDrawer() {
  return {
    type: '@SIDEDRAWER_CLOSE'
  };
}

export function toggleSideDrawer() {
  return {
    type: '@SIDEDRAWER_TOGGLE'
  };
}
