import React from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';

export default function ReactButton(props) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  function getStyle(type) {
    switch (type) {
      case 'confirmation':
        return buttonStyle(systemConfiguration.confirmation_button_color);
      case 'cancelation':
        return buttonStyle(systemConfiguration.cancelation_button_color);
      case 'primary':
        return buttonStyle(systemConfiguration.primary_color);
      case 'secondary':
        return buttonStyle(systemConfiguration.secondary_color);
      default:
        return buttonStyle(systemConfiguration.primary_color);
    }
  }

  return (
    <Button style={getStyle(props.btnColor)} {...props}>
      {props.children}
    </Button>
  );
}
