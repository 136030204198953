// Deve alterar tbm no backend
export const notAllowedExtensions = [
  'exe',
  'bat',
  'vbs',
  'js',
  'ps1',
  'scr',
  'jar',
  'com',
  'msi',
  'dll',
  'reg',
  'hta',
  'cmd',
  'pif',
  'app',
  'apk',
  'sh',
  'py',
  'php',
  'asp',
  'aspx',
  'conf'
];
