import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import QueryString from 'query-string';
import { toast } from 'react-toastify';
import QueueService from '../Queue/service';
import TagsService from '../TagsChat/service';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory } from 'react-router-dom';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ModalCopyLink from './components/ModalCopyLink';
import FlexSelect from '../../components/Inputs/FlexSelect';
import { maskPhone } from './assets/functions/index';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import flexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
const errorFormMessage = (message) => <p style={{ color: 'red' }}>{message}</p>;

const ChatGenerationLink = () => {
  const [loading, setLoading] = useState(false);
  const [queues, setQueues] = useState([]);
  const [tags, setTags] = useState([]);
  const [phones, setPhones] = useState([]);
  const [generationLink, setGenerationLink] = useState(null);
  const [openModalCopyLink, setOpenModalCopyLink] = useState(false);
  const { control, handleSubmit, errors, setValue, watch } = useForm();
  const history = useHistory();
  const BASEURLWATSAPP = 'https://api.whatsapp.com/send';

  async function getQueues() {
    try {
      const params = {
        showAllQueues: true,
        showDisable: false
      };
      const { data } = await QueueService.getAll(
        params.showAllQueues,
        params.showDisable
      );
      setQueues(data.data);
    } catch (err) {
      console.log('err', err);
      toast.error('Ops...Não foi possivel buscar filas');
    }
  }

  async function getPhoneNumber() {
    try {
      const { data } = await flexChatIntegration.getWhatsapp();

      setPhones(data?.data || []);
    } catch (err) {
      console.log('err', err);
      toast.error('Ops...Não foi possivel buscar os numeros do whatsapp');
    } finally {
      setLoading(false);
    }
  }

  async function getTagsChat() {
    try {
      setLoading(true);
      const data = await TagsService.showTags();
      const { list } = data?.data;
      setTags(list);
    } catch (err) {
      console.log('err', err);
      toast.error('Ops...Não foi possivel listar as tags!');
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    getQueues();
    getPhoneNumber();
    getTagsChat();
  }, []);

  async function onGenerationLink(data) {
    // const { message, tag, queue } = data;
    const { message } = data;
    // let formatTextParams = `${message} {${tag}} <${queue}>`;
    let formatTextParams = `${message}`;

    if (data?.tag && !data?.queue) {
      let { tag } = data;
      formatTextParams = `${message} {${tag}}`;
    }
    if (data?.queue && !data?.tag) {
      let { queue } = data;
      formatTextParams = `${message} <${queue}>`;
    }
    if (data?.queue && data?.tag) {
      let { tag, queue } = data;
      formatTextParams = `${message} {${tag}} <${queue}>`;
    }

    const generationNewUrl = QueryString.stringifyUrl({
      url: BASEURLWATSAPP,
      query: {
        phone: data.sourcePhone,
        text: formatTextParams
      }
    });

    setGenerationLink(generationNewUrl);
    setOpenModalCopyLink(true);
  }

  function handleCancelAction() {
    history.push('/admin/configuration');
  }

  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    {` Gerar link para o número ${maskPhone(
                      watch('sourcePhone')
                    )}`}
                  </h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onGenerationLink)}
                    >
                      <Row>
                        <Col md="12">
                          <label className="form-control-label">
                            Texto Inicial*
                          </label>

                          <Controller
                            as={Input}
                            placeholder="Escreva aqui o texto inicial"
                            control={control}
                            name="message"
                            rules={{
                              required: 'Campo obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="message"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={{ size: 4 }}>
                          <label className="form-control-label">Tag</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={tags}
                                isMulti={false}
                                value={props.value}
                                placeholder="Escolha uma Tag"
                                isClearable={true}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="tag"
                                labelName="name"
                                valueName="name"
                              />
                            )}
                            defaultValue=""
                            control={control}
                            // rules={{ required: 'Campo obrigatório!' }}
                            name="tag"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="tag"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md={{ size: 4 }}>
                          <label className="form-control-label">Fila</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={queues}
                                isMulti={false}
                                value={props.value}
                                placeholder="Escolha uma Fila"
                                isClearable={true}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="queue"
                                labelName="name"
                                valueName="name"
                              />
                            )}
                            defaultValue=""
                            control={control}
                            // rules={{ required: 'Campo obrigatório!' }}
                            name="queue"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="queue"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>

                        <Col md={{ size: 4 }}>
                          <label className="form-control-label">Número</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={phones}
                                isMulti={false}
                                value={props.value}
                                placeholder="Escolha um número"
                                isClearable={true}
                                valueController={setValue}
                                closeMenuOnSelect={true}
                                fieldName="sourcePhone"
                                labelName="source"
                                valueName="source"
                              />
                            )}
                            defaultValue=""
                            control={control}
                            // rules={{ required: 'Campo obrigatório!' }}
                            name="sourcePhone"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="sourcePhone"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Gerar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => handleCancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalCopyLink
        isModalOpen={openModalCopyLink}
        onModalToggle={setOpenModalCopyLink}
        link={generationLink}
      />
    </>
  );
};

export default ChatGenerationLink;
