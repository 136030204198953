import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import PredefinedMessageService from '../service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import Loader from 'react-loader-spinner';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody,
  Button
} from 'reactstrap';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';
import FlexSelect from 'components/Inputs/FlexSelect';
import { useGetTeams } from 'views/User/form/hooks/useGetTeams';
import channelsService from '../service/channelsService';
import getChannelsFromSelect from '../utils/getChannelsFromSelect';
import { CoverSection } from 'views/Chat/form/styles/WebChatForm.style';
import { UploadButton } from 'views/Chat/form/styles/WebChatForm.style';
import { FiUpload } from 'react-icons/fi';
import { CoverImage } from 'views/Chat/form/styles/WebChatForm.style';
import { whenToSendImageOptions } from '../utils/getImageSendOptions';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { useSelector } from 'react-redux';

const fileSizeAllowed = 16 * 1024 * 1024;
const imageTypeAllowed = ['image/png', 'image/jpeg', 'image/jpg'];
const videoTypeAllowed = ['video/mp4'];

const fileTypeAllowed = imageTypeAllowed.concat(videoTypeAllowed);

const PredefinedMessageForm = (props) => {
  var modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ color: [] }, { background: [] }],
      [
        { list: 'ordered' },
        { list: 'bullet' },
        { indent: '-1' },
        { indent: '+1' }
      ],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      ['link', 'image', 'code-block'],
      ['clean']
    ]
  };
  const history = useHistory();
  const { reset, control, handleSubmit, errors, setValue, formState, watch } =
    useForm();

  const channelController = watch('channel');
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const { isDirty } = formState;
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [channels, setChannels] = useState([]);
  const [previewCover, setPreviewCover] = useState(null);
  const [file, setFile] = useState(null);
  const [predefinedMessageData, setPredefinedMessageData] = useState({});
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [loadingMedia, setLoadingMedia] = useState(false);

  const { teams } = useGetTeams();

  const { id } = props.match.params;

  const transformUrlToBlobFile = async (url) => {
    setLoadingMedia(true);
    const newFile = fetch(url)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'file', { type: blob.type });
        setLoadingMedia(false);
        return file;
      });
    return newFile;
  };

  useEffect(() => {
    const fetchFile = async () => {
      if (
        predefinedMessageData &&
        predefinedMessageData.preDefinedMessageMidiaUrl &&
        isUpdate
      ) {
        const fileFromUrl = await transformUrlToBlobFile(
          predefinedMessageData.preDefinedMessageMidiaUrl
        );
        setFile(fileFromUrl);
        setPreviewCover({
          src: URL.createObjectURL(fileFromUrl),
          type: fileFromUrl.type
        });
      }
    };

    fetchFile();
  }, [isUpdate, predefinedMessageData]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      PredefinedMessageService.get(id)
        .then((res) => {
          const getPredefinedMessage = res.data.data;
          getPredefinedMessage.teams = getPredefinedMessage.teams.map((team) =>
            Number(team)
          );
          setPredefinedMessageData(getPredefinedMessage);
          reset(getPredefinedMessage);
          setValue('whenToSend', getPredefinedMessage.whenToSend);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(
            err?.response?.data?.message || 'Erro ao carregar mensagem!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
          setLoading(false);
        });
    }
  }, [id, reset]);

  useEffect(() => {
    if (isUpdate && !previewCover) {
      setValue('whenToSend', null);
    }
  }, [isUpdate, previewCover, setValue]);

  useEffect(() => {
    setLoading(true);
    channelsService.getAll().then((res) => {
      const channels = getChannelsFromSelect(res?.data?.data);
      channels.push({ label: 'email', value: 'email' });
      setChannels(channels);
      setLoading(false);
    });
  }, []);

  const onSubmit = async (data) => {
    setIsLoadingData(true);
    try {
      const formatedData = new FormData();
      let formatedDataToSend = { ...data };

      if (!formatedDataToSend?.messageHtml) {
        formatedDataToSend.messageHtml = '';
      }

      Object.keys(formatedDataToSend).forEach((key) => {
        if (key === 'teams' || key === 'channel') {
          data[key].forEach((value) => {
            formatedData.append(`${key}[]`, value);
          });
        } else if (key === 'file') {
          if (data[key]) {
            formatedData.append(key, data[key]);
          } else {
            formatedData.append(key, file);
          }
        } else {
          formatedData.append(key, data[key]);
        }
      });
      isUpdate
        ? await PredefinedMessageService.updateWithMidia(formatedData, id).then(
            () => {
              setIsLoadingData(false);
            }
          )
        : await PredefinedMessageService.createWithMidia(formatedData).then(
            () => {
              setIsLoadingData(false);
            }
          );

      history.push('/admin/predefinedMessage');
      toast.success(
        isUpdate
          ? 'Mensagem alterada com sucesso!'
          : 'Mensagem cadastrada com sucesso!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    } catch (err) {
      console.log(err);
      toast.error(err?.response?.data || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/predefinedMessage');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/predefinedMessage"
        buttonTitle="Voltar para a pagina de mensagens predefinidas"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Mensagem Predefinida</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-4">
                        <Col md="6">
                          <label className="form-control-label">Título*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="title"
                            rules={{ required: 'Título é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="title"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Canal</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={channels}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                value={props.value}
                                valueController={setValue}
                                fieldName="channel"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            rules={{
                              required: 'Selecione pelo menos um canal!'
                            }}
                            name="channel"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="channel"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Col style={{ marginTop: '4px' }}>
                          <CoverSection>
                            <div>
                              <label>
                                Imagem/Vídeo da mensagem predefinida
                              </label>
                              {loadingMedia ? (
                                <ReactLoader />
                              ) : previewCover && previewCover.type ? (
                                <CoverImage>
                                  {imageTypeAllowed.includes(
                                    previewCover.type
                                  ) ? (
                                    <img
                                      src={previewCover.src}
                                      alt="logo chat"
                                    />
                                  ) : videoTypeAllowed.includes(
                                      previewCover.type
                                    ) ? (
                                    <video
                                      src={previewCover.src}
                                      controls
                                      style={{ width: '100%' }}
                                    />
                                  ) : (
                                    <p>O tipo de arquivo é inválido.</p>
                                  )}
                                  <button
                                    onClick={() => {
                                      setPreviewCover(null);
                                      setValue('file', null);
                                      setFile(null);
                                    }}
                                    style={{
                                      position: 'absolute',
                                      top: '0',
                                      right: '0',
                                      border: 'none',
                                      borderRadius: '50%',
                                      width: '20px',
                                      height: '20px',
                                      textAlign: 'center',
                                      lineHeight: '20px',
                                      cursor: 'pointer'
                                    }}
                                  >
                                    x
                                  </button>
                                </CoverImage>
                              ) : null}
                            </div>
                            <label htmlFor="file">
                              <UploadButton>
                                {predefinedMessageData?.preDefinedMessageMidiaUrl ? (
                                  <span>Enviar outro arquivo</span>
                                ) : (
                                  <span>Enviar arquivo</span>
                                )}
                                <FiUpload />
                              </UploadButton>
                              <Controller
                                name="file"
                                control={control}
                                defaultValue={null}
                                render={() => (
                                  <Input
                                    id="file"
                                    style={{ display: 'none' }}
                                    type="file"
                                    accept={fileTypeAllowed.join(',')}
                                    onChange={(e) => {
                                      const file = e.target.files[0];
                                      if (file.size > fileSizeAllowed) {
                                        toast.error(
                                          'O arquivo não pode ser maior que 16MB!',
                                          {
                                            autoClose: 3000,
                                            closeOnClick: true
                                          }
                                        );
                                        e.target.value = null;
                                        return;
                                      }
                                      const reader = new FileReader();
                                      reader.onloadstart = () => {
                                        setLoadingMedia(true);
                                      };
                                      reader.onload = () => {
                                        setPreviewCover({
                                          src: reader.result,
                                          type: file.type
                                        });
                                        setLoadingMedia(false);
                                      };
                                      reader.readAsDataURL(file);
                                      setValue('file', file);
                                      e.target.value = null;
                                    }}
                                    multiple={false}
                                  />
                                )}
                              />
                            </label>
                            <ErrorMessage
                              errors={errors}
                              name="file"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </CoverSection>
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Quando enviar
                            {previewCover ? '*' : ''}
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={whenToSendImageOptions}
                                isMulti={false}
                                isDisabled={previewCover ? false : true}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="whenToSend"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            rules={{
                              required: previewCover
                                ? 'Selecione uma opção!'
                                : ''
                            }}
                            name="whenToSend"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="whenToSend"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-4">
                        <Col md="6">
                          <label className="form-control-label">
                            Equipe(s)*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={teams}
                                isMulti={true}
                                closeMenuOnSelect={false}
                                value={props.value}
                                valueController={setValue}
                                fieldName="teams"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            rules={{
                              required: 'Selecione pelo menos uma equipe!'
                            }}
                            name="teams"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="teams"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-4">
                        {channelController &&
                        channelController?.length &&
                        channelController?.filter((c) => c !== 'email')
                          .length ? (
                          <Col md="6">
                            <label className="form-control-label">
                              Mensagem*
                              <span
                                title={`#Máscaras:\n - #cliente/#CLIENTE = Busca o Cliente presente na conversa \n  \n - #atendente/#ATENDENTE = Busca o atendente presente na conversa  `}
                                style={{
                                  fontSize: '15px'
                                }}
                              >
                                <i className="fas fa-info-circle"></i>
                              </span>
                            </label>
                            <Controller
                              as={(field) => {
                                return (
                                  <Input
                                    rows="4"
                                    type="textarea"
                                    defaultValue={field.value}
                                    value={field.value}
                                    onChange={(e) =>
                                      setValue('message', e.target.value)
                                    }
                                  />
                                );
                              }}
                              control={control}
                              rules={{ required: 'Mensagem é obrigatório!' }}
                              name="message"
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="message"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        ) : null}

                        {channelController &&
                        channelController?.indexOf('email') > -1 ? (
                          <Col md="6">
                            <label className="form-control-label">
                              Mensagem de email*
                            </label>
                            <Controller
                              as={(field) => {
                                return (
                                  <ReactQuill
                                    modules={modules}
                                    name="description"
                                    theme="snow"
                                    placeholder="Escreva um comentário"
                                    defaultValue={field.value}
                                    onChange={(value) =>
                                      setValue('messageHtml', value)
                                    }
                                    value={field.value}
                                    style={{
                                      border: 'none',
                                      boxShadow: 'none'
                                    }}
                                  />
                                );
                              }}
                              control={control}
                              rules={{
                                required: 'Mensagem de email é obrigatório!'
                              }}
                              name="messageHtml"
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="messageHtml"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        ) : null}
                      </Row>
                      <hr />
                      <Button
                        style={buttonStyle(systemConfiguration.primary_color)}
                        type="submit"
                        disabled={isLoadingData}
                      >
                        {isLoadingData ? (
                          <Row className="justify-content-md-center">
                            <Loader
                              type="TailSpin"
                              color={'white'}
                              height={18}
                              width={78}
                            />
                          </Row>
                        ) : (
                          'Salvar'
                        )}
                      </Button>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PredefinedMessageForm;
