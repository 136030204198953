import React, { useEffect, useState } from 'react';
import Service from './service';
import { useControlAudioState } from '../../../views/Report/SatisfactionSurvey/Analytical/hooks/useControlAudioState';
import Modal from '../SurveyDetailsChat/components/Modal';
import {
  Vr,
  TimelineDot,
  TimelineContainer,
  TimelineRow
} from '../SurveyDetailsChat/components/styled';
import AudioStream from '../../../components/Inputs/AudioStream';
import ReportService from '../../../views/Report/Callcenter/ReceivedCall/service';
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button
} from 'reactstrap';
import { toast } from 'react-toastify';
import * as fileSaver from 'file-saver';
export function SurveyDetailsTelephony({ id_attendance }) {
  const [survey, setSurvey] = useState();
  const { stopPlayingAudio, stopPlay } = useControlAudioState();
  useEffect(() => {
    Service.get(id_attendance)
      .then((response) => {
        const { data } = response.data;

        setSurvey(data);
      })
      .catch((error) => {
        console.log('error' + error);
      });
  }, [id_attendance]);

  function formatedDate(date) {
    const newDate = new Date(date);
    const formatedDate = `${newDate.toLocaleDateString(
      'pt-BR'
    )} às ${newDate.toLocaleTimeString('pt-BR', {
      timeZone: 'America/Fortaleza'
    })}`;
    return formatedDate;
  }

  function maskPhone(v) {
    let r = v.replace(/\D/g, '');
    r = r.replace(/^0/, '');
    if (r.length > 10) {
      r = r.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
    } else if (r.length > 5) {
      r = r.replace(/^(\d\d)(\d{4})(\d{0,4}).*/, '($1) $2-$3');
    } else if (r.length > 2) {
      r = r.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
    } else {
      r = r.replace(/^(\d*)/, '($1');
    }
    return r;
  }

  function downloadAudio(uniqueid) {
    ReportService.downloadAudio(uniqueid)
      .then((res) => {
        res.data.size <= 11
          ? toast.info('Nenhum registro foi encontrado!')
          : fileSaver.saveAs(res.data, `${uniqueid}.gsm`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
      {survey && (
        <Modal
          name="Detalhes do atendimento"
          tooltipTitle="Detalhes"
          stopPlay={stopPlay}
        >
          <Card>
            <CardBody>
              <CardTitle tag="h4">
                Origem:{' '}
                <strong>
                  {maskPhone(survey?.clid_num) || 'Não informado'}
                </strong>
              </CardTitle>
              <CardSubtitle
                tag="h5"
                className="mb-3 text-muted"
                style={{ marginTop: '-0.5rem' }}
              >
                <Row>
                  <Col md="6" title="Início do atendimento">
                    <i
                      className="far fa-calendar-alt"
                      style={{ color: '#5bc0de' }}
                    ></i>{' '}
                    - {formatedDate(survey?.calldate_start) || 'Não disponível'}
                  </Col>
                  <Col md="6" title="Fim do atendimento">
                    <i
                      className="far fa-calendar-alt"
                      style={{ color: '#0275d8' }}
                    ></i>{' '}
                    - {formatedDate(survey?.calldate_end) || 'Não disponível'}
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Fila do atendimento">
                    <i
                      className="fas fa-id-card"
                      style={{ color: '#5bc0de' }}
                    ></i>{' '}
                    - {survey?.queue?.name || 'Não disponível'}
                  </Col>
                  <Col md="6" title="Atendente">
                    <i
                      className="fas fa-users"
                      style={{ color: '#0275d8' }}
                    ></i>{' '}
                    - {survey?.agent?.name || 'Não disponível'}
                  </Col>
                </Row>

                <Row
                  style={{
                    margin: '0 auto',
                    paddingTop: '0.5rem'
                  }}
                >
                  <Col
                    md="6"
                    title="Reprodução do audio do atendimento"
                    style={{ textAlign: 'center' }}
                  >
                    <AudioStream
                      ReportService={ReportService}
                      uniqueid={survey?.uniqueid}
                      disabled={
                        survey?.disposition === 'ANSWERED' ? false : true
                      }
                      reasonDisabled={
                        survey?.disposition === 'ANSWERED' ? '' : 'Não atendida'
                      }
                      origin="survey"
                      stopAudioModal={stopPlayingAudio}
                    />
                  </Col>
                  <Col
                    md="6"
                    title="Download do audio do atendimento"
                    style={{ textAlign: 'center' }}
                  >
                    <Button
                      disabled={
                        survey?.disposition === 'ANSWERED' ? false : true
                      }
                      datatoggle="tooltip"
                      dataplacement="top"
                      outline
                      title={
                        survey?.disposition === 'ANSWERED' ? '' : 'Não atendida'
                      }
                      datacontainer="body"
                      dataanimation="true"
                      color="success"
                      size="sm"
                      onClick={() => downloadAudio(survey?.uniqueid)}
                    >
                      <i className="fas fa-cloud-download-alt"></i> Download do
                      atendimento
                    </Button>
                  </Col>
                </Row>
              </CardSubtitle>
              <hr />
              <Row className="mb-3">
                <Col md="1" style={{ alignSelf: 'center' }}>
                  <i
                    className="fas fa-info-circle"
                    style={{ color: '#5bc0de' }}
                  ></i>
                </Col>
                <Col md="11">
                  <p>
                    A chamada foi iniciado através da fila{' '}
                    <strong>{survey?.queue?.name || 'Não informado'}</strong>
                  </p>
                </Col>
              </Row>
              <hr />
              <Row className="mb-3">
                <Col className="col-sm-12 col-md-10 offset-md-0">
                  <Vr />
                  <TimelineContainer>
                    <TimelineDot color="#5bc0de">
                      <i
                        className="fas fa-sign-in-alt"
                        style={{ marginTop: '10px' }}
                      />
                    </TimelineDot>
                    <TimelineRow>
                      <CardTitle tag="h5">INÍCIO DA CHAMADA</CardTitle>
                      {survey?.calldate_start && (
                        <CardSubtitle tag="h5" className="mt--3 text-muted">
                          {`${new Date(
                            survey?.calldate_start
                          ).toLocaleTimeString('pt-br')} - ${new Date(
                            survey?.calldate_start
                          ).toLocaleDateString('pt-br')}`}
                        </CardSubtitle>
                      )}
                    </TimelineRow>
                  </TimelineContainer>
                  <TimelineContainer>
                    <TimelineDot color="#5cb85c">
                      <i
                        className="far fa-bell"
                        style={{ marginTop: '10px' }}
                      />
                    </TimelineDot>
                    <TimelineRow>
                      <CardTitle tag="h5">ATENDIDA</CardTitle>

                      <CardSubtitle tag="h5" className="mt--3 text-muted">
                        {`${new Date(
                          survey?.calldate_answer
                        ).toLocaleTimeString('pt-br')} - ${new Date(
                          survey?.calldate_answer
                        ).toLocaleDateString('pt-br')}`}
                      </CardSubtitle>

                      <h5 style={{ color: '#5cb85c' }}>
                        {survey?.agent?.name || 'Não disponível'}
                      </h5>
                    </TimelineRow>
                  </TimelineContainer>
                  <TimelineContainer>
                    <TimelineDot color="#d9534f">
                      <i
                        className="fas fa-check"
                        style={{ marginTop: '10px' }}
                      />
                    </TimelineDot>
                    <TimelineRow>
                      <CardTitle tag="h5">ENCERRADA</CardTitle>

                      <CardSubtitle tag="h5" className="mt--3 text-muted">
                        {`${new Date(survey.calldate_end).toLocaleTimeString(
                          'pt-br'
                        )} - ${new Date(survey.calldate_end).toLocaleDateString(
                          'pt-br'
                        )}`}
                      </CardSubtitle>

                      <h5 style={{ color: '#d9534f' }}>
                        {survey?.agent?.name || ''}
                      </h5>
                    </TimelineRow>
                  </TimelineContainer>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Modal>
      )}
    </>
  );
}
