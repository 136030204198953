import { format } from 'date-fns';
const QUANTITY = 10;
const REPORTTYPE = 3;
const DISABLE = false;
const GROUPBY = 'agente';
const ALLCALLS = false;
const TIMEEND = 235959;
const TIMESTART = '000000';
const date = new Date();
const dateBeginNotFormmated = new Date('2010-08-10 00:00:00');
const dateBegin = format(dateBeginNotFormmated, 'LL-dd-yyyy HH:mm:ss');
const dateEnd = format(date, 'LL-dd-yyyy HH:mm:ss');

export const PAGE = 1;
export const REPORT_TYPE_DETAILEDSYNTHETIC = 'detailedsynthetic';
export const PARAMS = {
  page: PAGE,
  quantity: QUANTITY,
  reportType: REPORTTYPE,
  disable: DISABLE,
  groupby: GROUPBY,
  allCalls: ALLCALLS,
  timeEnd: TIMEEND,
  timeStart: TIMESTART,
  dateEnd,
  dateBegin
};
