export const styles = (color) => {
  return {
    container: {
      padding: '0.5rem',
      backgroundColor: color,
      color: '#FFF',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      borderRadius: '7px',
      width: 'auto'
    }
  };
};
