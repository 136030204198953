import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react';
import io from 'socket.io-client';

import { getBFFAgentDashboard } from '../services/getBFFAgentDashboard';
import { TIME_INTERVAL } from '../utils/consts';

export const AgentCtx = createContext();

export const AgentCtxProvider = ({ children, agent }) => {
  const [agentIndicators, setAgentIndicators] = useState({
    qtdCallsMade: 0,
    qtdCallsAnswered: 0,
    agentTMA: '00:00:00',
    qtdLogin: 0,
    timeLogin: '00:00:00',
    agentPauses: []
  });
  const [collectiveIndicators, setCollectiveIndicators] = useState({
    TMA: '00:00:00',
    TME: '00:00:00',
    qtdAnswered: 0,
    qtdAbandoned: 0,
    answeredPercentage: '0%',
    abandonedPercentage: '0%',
    longestWaitTime: '00:00:00',
    serviceLevel: {
      according: 0,
      notAccording: 0,
      accordingPercentage: '0%',
      notAccordingPercentage: '0%'
    }
  });
  const [queues, setQueues] = useState([]);

  const socket = useMemo(() => {
    return io(process.env.REACT_APP_PROXY_URL, {
      path: process.env.REACT_APP_AMI_SOCKET_PATH,
      transports: ['websocket'],
      upgrade: false,
      query: { token: localStorage.getItem('token') }
    });
  }, []);

  const handleQueues = useCallback((queues) => {
    setQueues(queues);
  }, []);

  useEffect(() => {
    if (queues.length === 0) return;

    const fetchData = async () => {
      const { agentIndicators, collectiveIndicators } =
        await getBFFAgentDashboard({
          agent,
          queues: queues.map((queue) => queue.id)
        });

      setAgentIndicators(agentIndicators);
      setCollectiveIndicators(collectiveIndicators);
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, TIME_INTERVAL);

    return () => clearInterval(interval);
  }, [agent, queues]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Dashboard Agent Socket Connected');
    });

    return () => socket.disconnect();
  }, [socket]);

  return (
    <AgentCtx.Provider
      value={{
        queues,
        agentIndicators,
        collectiveIndicators,
        socket,
        handleQueues
      }}
    >
      {children}
    </AgentCtx.Provider>
  );
};
