import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { parseISO, format } from 'date-fns';


import {
    Container,
    Row,
    Card,
    CardHeader,
    Button,
    CardBody,
    Col,
    Modal,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Input,
    ModalHeader,
    ModalBody,
    ModalFooter, 
  } from 'reactstrap';
  
  


const ModalText = ({row}) =>{
    const [modalIsOpen, setModalIsOpen] = useState(false);

    const toggleModal = () => {
        setModalIsOpen(!modalIsOpen);
    };

    if(row.broadcastTypeOfClosingConversation == 1){
      row.broadcastTypeOfClosingConversationModal = 'Transferir para fila';
    }else if(row.broadcastTypeOfClosingConversation == 2) {
      row.broadcastTypeOfClosingConversationModal = 'Enviar sem abrir conversa';
    }

    const dateCreate = row.created_at ? format(parseISO(row.created_at), 'dd/MM/yyyy') : '';

    
    return (
        <>
        
        
          <Button
            color="success"
            size="sm"
            title="Open Modal"
            onClick={toggleModal}
            className="ml-3 "    
          >
            <i className="fas fa-info-circle"></i>

          </Button>
    
          <Modal isOpen={modalIsOpen} toggle={toggleModal}>

            <ModalHeader toggle={toggleModal}>Informações da Campanha Ativa SMS</ModalHeader>
            <ModalBody>
            <p>
                              <strong>Título: </strong>
                              {
                                row.title
                              }
                            </p>
                            <p>
                              <strong>Data de criação: </strong>
                              {dateCreate}
                              
                            </p>
                            <p>
                              <strong>Data de Encerramento:  </strong>
                              {row.expiresIn ? `${row.expiresIn.slice(8, 10)}/${row.expiresIn.slice(5, 7)}/${row.expiresIn.slice(0, 4)} ${row.expiresIn.slice(11, 19)}` : ''}
                            </p>
                            <p>
                              <strong>
                                Fila:
                              </strong>
                                {row.queueName}                              
                            </p>
                            <p>
                              <strong>Tipo:</strong>
                              {row.broadcastTypeOfClosingConversationModal}
                            </p>
                            <p>
                              <strong>Status:</strong>
                              {row.status}
                            </p>
                            <p>
                              <strong>Mensagem Predefinida: </strong>
                              <br></br>
                               {row.message}
                            </p>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={toggleModal}>
                Fechar
              </Button>
            </ModalFooter>
          </Modal>
        </>
      );
} 

export default ModalText;