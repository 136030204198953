import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import UserService from '../../../User/service';
import PauseService from '../../../Pause/service';
import ReportService from './service';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import HourMaskMMHHSS from '../../../../components/Inputs/HourMaskMMHHSS.js';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ReportAnalythical from './tables/Analytical';
import ReportSynthetic from './tables/Synthetic';
import Pagination from 'components/Pagination';
import * as fileSaver from 'file-saver';
import { v4 as uuid } from 'uuid';
import { format, startOfMonth } from 'date-fns';
import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';
import { Tooltip } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { useGetTeamsFiltered } from 'hooks/useGetTeamsFiltered';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';
import { ItemPerPageSelect } from 'components/Inputs/ItemPerPageSelect';

const hourRules = {
  required: 'Hora é obrigatório!',
  pattern: {
    value: /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/,
    message: 'Hora inválida! (00:00:00)'
  }
};

const dateRules = {
  required: 'Data é obrigatório!',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};

const reportOptions = [
  { label: 'Analítico', value: 1 },
  { label: 'Sintético', value: 2 }
];

const PauseReport = () => {
  const { control, handleSubmit, errors, setValue, watch, getValues } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [pauses, setPauses] = useState([]);
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingReport, setLoadingReport] = useState(false);
  const reportType = watch('reportType');
  const [ReportTypeAnalytical, setReportTypeAnalytical] = useState([]);
  const [ReportTypeSynthetical, setReportTypeSynthetical] = useState([]);
  const [media, setMedia] = useState('');
  const [dataToSend, setDataToSend] = useState({});
  const [quantity, setQuantity] = useState(0);
  const [activePage, setActivePage] = useState(1);
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );
  const [messagemGeneratePdf, setMessagemGeneratePdf] = useState();
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();
  const { teams } = useGetTeamsFiltered();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const getPause = () => {
    setLoading(true);
    PauseService.getAll()
      .then((response) => {
        setPauses(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  };

  const getUser = (showDisable = false) => {
    if(!showDisable){
      setLoading(true);
    }
    UserService.getAll(false, undefined, undefined, usersShowDisable)
    .then((response) => {
      setUsers(response.data.data);
      if(!showDisable){
        setLoading(false);
      }
    })
    .catch((err) => {
      console.log('err: ' + err);
      if(!showDisable){
        setLoading(false);
      }      
      });
  };

  useEffect(() => {
    getPause();
    getUser();
  }, []);

  useEffect(() => {
    getUser(true)
  }, [usersShowDisable])

  //messagemGeneratePdfCsv
  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantity,
      limitGenerationPDF
    );

    setMessagemGeneratePdf(message.pdfMessage);
    setMessagemGenerateCsv(message.csvMessage);
  }, [quantity, limitGenerationPDF]);

  const clearData = () => {
    setReportTypeAnalytical([]);
    setReportTypeSynthetical([]);
    setDataToSend({});
    setQuantity(0);
    setActivePage(1);
  };

  const toISOFormat = (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');

    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${MM}-${DD}-${YYYY}`;
  };

  const getDataDTO = (data) => {
    let formatedData;

    formatedData = {
      ...data,
      dateBegin: `${toISOFormat(data.dateBegin)} ${data.timeStart}`,
      dateEnd: `${toISOFormat(data.dateEnd)} ${data.timeEnd}`,
      timeStart: data.timeStart.replace(/:/g, ''),
      timeEnd: data.timeEnd.replace(/:/g, '')
    };

    if (!formatedData.agent) delete formatedData.agent;
    if (!formatedData.pause) delete formatedData.pause;

    setDataToSend(formatedData);
    return formatedData;
  };

  const getReports = (typeReport, dataToSend, page, useLoader = true) => {
    setLoadingReport(useLoader);

    if (media === 'pdf' || media === 'csv') {
      ReportService.getDownloads(typeReport, 1, media, dataToSend)
        .then((res) => {
          if (res.data.size <= 11)
            toast.info('Nenhum registro foi encontrado!');
          else {
            fileSaver.saveAs(
              new Blob([res.data], { type: res.headers['content-type'] }),
              media === 'pdf'
                ? 'Relatório_de_Pausas_CallCenter.pdf'
                : 'Relatório_de_Pausas_CallCenter.csv'
            );
          }
          setLoadingReport(false);
        })
        .catch((err) => {
          toast.error(err || `Erro ao gerar ${media}`, {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoadingReport(false);
        });
      setMedia('');
    } else {
      ReportService.getAllReportsType(
        typeReport,
        page,
        dataToSend,
        itemsPerPage
      )
        .then((res) => {
          switch (typeReport) {
            case 'analytical':
              if (res.data.data.listAgentActionDTO.length === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listAgentActionDTO.forEach((el, index) => {
                  res.data.data.listAgentActionDTO[index].uuid = uuid();
                });
                setReportTypeAnalytical(res.data.data.listAgentActionDTO);
                setQuantity(res.data.data.quantity);
              }
              break;
            case 'synthetic':
              if (res.data.data.listAgentActionSyntheticDTO.length === 0)
                toast.info('Nenhum registro foi encontrado!');
              else {
                res.data.data.listAgentActionSyntheticDTO.forEach(
                  (el, index) => {
                    res.data.data.listAgentActionSyntheticDTO[index].uuid =
                      uuid();
                  }
                );
                setQuantity(res.data.data.listAgentActionSyntheticDTO.length);
                setReportTypeSynthetical(
                  res.data.data.listAgentActionSyntheticDTO
                );
              }
              break;
            default:
              return '';
          }
          setLoadingReport(false);
        })
        .catch((err) => {
          toast.error(err, {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoadingReport(false);
        });
    }
  };

  const getFormatedType = (value) => {
    switch (value) {
      case 1:
        return 'analytical';
      case 2:
        return 'synthetic';
      default:
        return '';
    }
  };

  const onSubmit = (data) => {
    clearData();
    try {
      const formatedData = getDataDTO(data);
      const formatedType = getFormatedType(reportType);
      getReports(formatedType, formatedData, 1, true);
    } catch (err) {
      toast.error(err || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    clearData();
  }, [reportType]);

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const handlePageChange = (page) => {
    setActivePage(page);
    getReports(getFormatedType(reportType), dataToSend, page, false);
  };

  const getDateBegin = () => {
    const date = new Date();
    const firstDayMonth = new Date(
      date.getFullYear('pt-br'),
      date.getMonth('pt-br'),
      1
    );
    const dateBegin = format(startOfMonth(firstDayMonth), 'dd/LL/yyyy');
    return dateBegin;
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de Pausas - CallCenter</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      <Form
                        className="needs-validation"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">Pausa</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={pauses}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  closeMenuOnSelect={true}
                                  valueController={setValue}
                                  fieldName="pause"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="pause"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">Agente</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={users}
                                  isMulti={false}
                                  isClearable={true}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="agent"
                                  labelName="name"
                                  valueName="agent_code"
                                />
                              )}
                              control={control}
                              name="agent"
                              defaultValue=""
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dateBegin"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dateBegin"
                              rules={dateRules}
                              defaultValue={getDateBegin()}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dateBegin"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Hora inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <HourMaskMMHHSS
                                  valueController={setValue}
                                  fieldName="timeStart"
                                  value={props.value}
                                />
                              )}
                              control={control}
                              name="timeStart"
                              rules={hourRules}
                              defaultValue="00:00:00"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="timeStart"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data final*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dateEnd"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dateEnd"
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                              rules={{
                                ...dateRules,
                                validate: (value) => {
                                  if (
                                    new Date(toISOFormat(value, true)) <
                                    new Date(
                                      toISOFormat(
                                        getValues()['dateBegin'],
                                        true
                                      )
                                    )
                                  ) {
                                    return 'A data final não pode ser anterior à inicial!';
                                  } else {
                                    return true;
                                  }
                                }
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dateEnd"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Hora Final*
                            </label>
                            <Controller
                              render={(props) => (
                                <HourMaskMMHHSS
                                  valueController={setValue}
                                  fieldName="timeEnd"
                                  value={props.value}
                                />
                              )}
                              control={control}
                              name="timeEnd"
                              rules={hourRules}
                              defaultValue="23:59:59"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="timeEnd"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Tipo de Relatório
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={reportOptions}
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="reportType"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="reportType"
                              defaultValue={1}
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">Equipe</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={true}
                                  dataOptions={teams}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="teamId"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="teamId"
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="teamId"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col className="md-6 mx-4">
                            <div style={{ display: 'flex', gap: '20px' }}>
                              <Controller
                                name="disable"
                                control={control}
                                render={(props) => (
                                  <>
                                    <label className="form-control-label">
                                      <Input
                                        type="checkbox"
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                        checked={props.value}
                                      />
                                      Mostrar desabilitadas (Filas)
                                    </label>
                                  </>
                                )}
                                defaultValue={false}
                              />
                              <UserFlexSelect
                                usersShowDisable={usersShowDisable}
                                setUsersShowDisable={setUsersShowDisable}
                                name={'Agentes'}
                              />
                            </div>
                          </Col>
                          <Col md="4" style={ImageButtonContainer}>
                            <div id="button_pdf">
                              {reportType !== 1 && reportType !== 2 && (
                                <button
                                  style={ImageButton}
                                  name="pdf"
                                  onClick={() => setMedia('pdf')}
                                  disabled={disabledDownloadButtonCsvAndPdf(
                                    'pdf',
                                    quantity,
                                    limitGenerationPDF
                                  )}
                                >
                                  <Tooltip
                                    title={
                                      <p style={{ fontSize: '14px' }}>
                                        {messagemGeneratePdf}
                                      </p>
                                    }
                                    arrow
                                  >
                                    <img
                                      src={require('../../../../assets/img/pdf.png')}
                                      alt="pdf"
                                      style={ImageSize}
                                    />
                                  </Tooltip>
                                </button>
                              )}
                            </div>

                            <button
                              style={ImageButton}
                              onClick={() => setMedia('csv')}
                              disabled={disabledDownloadButtonCsvAndPdf(
                                'csv',
                                quantity,
                                limitGenerationPDF
                              )}
                            >
                              <Tooltip
                                title={
                                  <p style={{ fontSize: '14px' }}>
                                    {messagemGenerateCsv}
                                  </p>
                                }
                                arrow
                              >
                                <img
                                  src={require('../../../../assets/img/csv.png')}
                                  alt="csv"
                                  style={ImageSize}
                                />
                              </Tooltip>
                            </button>
                          </Col>
                        </Row>

                        <Button
                          style={buttonStyle(systemConfiguration.primary_color)}
                          type="submit"
                        >
                          Buscar
                        </Button>
                        <Row className="mb-3">
                        <Col md="3" style={{ marginTop: '-45px' , marginLeft:'110px'}}>
                            <ItemPerPageSelect
                              valueController={setItemsPerPage}
                            ></ItemPerPageSelect>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                  {loadingReport ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      {reportType === 1 && ReportTypeAnalytical.length > 0 && (
                        <ReportAnalythical
                          showReports={ReportTypeAnalytical}
                          totalRegister={quantity}
                        />
                      )}
                      {reportType === 2 && ReportTypeSynthetical.length > 0 && (
                        <ReportSynthetic
                          showReports={ReportTypeSynthetical}
                          totalRegister={quantity}
                        />
                      )}
                    </>
                  )}
                  {ReportTypeAnalytical.length > 0 && (
                    <Pagination
                      itemsCountPerPage={itemsPerPage}
                      activePage={activePage}
                      totalItemsCount={quantity}
                      onChange={handlePageChange.bind(this)}
                    />
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PauseReport;
