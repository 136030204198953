import styled from 'styled-components';

export const TableContainer = styled.div`
  display: block;
  overflow-x: scroll;
  padding: 2rem 0.5rem;
  border-bottom: 0;
  ::-webkit-scrollbar {
    height: 5px;
  }
  ::-webkit-scrollbar-thumb {
    background: #aaa;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #888;
  }
`;

export const ColTemplate = styled.div`
  width: 100%;
  padding: 0.5rem;
  background-color: #fff;
  transition: all 0.25s ease;
  border-left: 2px solid #797995;
  border-radius: 3px;
  margin-top: 3px;
  &:hover {
    background-color: #f5f5f5f5;
  }

  svg {
    color: ${(props) => props.color};
  }
`;
