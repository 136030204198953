import React from 'react';
import { Bar } from 'react-chartjs-2';
import { getMonthName } from 'views/CRM/shared/utils/monthNameHelper';

export const GraphicOpportunityRevenue = ({ data }) => {
  const groupByAverageTime = data;

  const formatter = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL'
  });

  const getGraphicResponsibleLabels = () => {
    if (!groupByAverageTime) return [];
    return groupByAverageTime?.map((item) => `${getMonthName(item.month)}`);
  };

  const getGraphicResponsibleData = () => {
    if (!groupByAverageTime) return [];
    const totalWon = groupByAverageTime?.map((item) => item.totalAmountClosed);

    const totalOpen = groupByAverageTime?.map((item) => item.totalAmountOpen);

    return {
      totalWon,
      totalOpen
    };
  };

  return (
    <div className="chart mt-5">
      <Bar
        data={{
          labels: getGraphicResponsibleLabels(),
          datasets: [
            {
              label: 'Aberto',
              backgroundColor: '#11CDEF',
              stack: 1,
              data: getGraphicResponsibleData().totalOpen
            },
            {
              label: 'Ganho',
              backgroundColor: '#00A17C',
              stack: 1,
              data: getGraphicResponsibleData().totalWon
            }
          ]
        }}
        options={{
          showLines: true,
          scales: {
            yAxes: [
              {
                stacked: true,
                gridLines: {
                  color: '#e9ecef',
                  zeroLineColor: '#e9ecef'
                },
                ticks: {
                  callback: function (value) {
                    if (!(value % 10)) {
                      return value;
                    }
                  }
                }
              }
            ],
            xAxes: [
              {
                stacked: true,
                maxBarThickness: 100
              }
            ]
          },
          tooltips: {
            callbacks: {
              label: function (item, data) {
                var label = data.datasets[item.datasetIndex].label || '';
                var yLabel = formatter.format(item.yLabel);
                var content = '';
                if (data.datasets.length > 1) {
                  content += label;
                }
                content += ` ${yLabel}`;
                return content;
              }
            }
          },
          legend: {
            display: false
          }
        }}
        className="chart-canvas"
        id="chart-bars"
      />
    </div>
  );
};
