/* eslint-disable no-unused-expressions */

import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  Row,
  Form,
  Col,
  Input,
  Button,
  Table,
  Badge,
  InputGroup
} from 'reactstrap';
import { FiEdit } from 'react-icons/fi';
import ReactButton from 'components/Buttons/ReactButton';
import ModalAddListOption from './ModalAddListOption';
import { protocolInputTypes, protocolFieldTypes } from './utils';
import { useRecordTypes } from '../../../../RecordType/hooks/useRecordTypes';
import DatePicker from 'components/Inputs/DatePicker.js';
import TelephoneMask from 'components/Inputs/TelephoneMask';
import FlexSelect from 'components/Inputs/FlexSelect';
import CPFMask from 'components/Inputs/CPFMask';
import CNPJMask from 'components/Inputs/CNPJMask';
import FieldsConfigService from '../../../service/index';
import { ScreenPrompt } from 'components/ScreenPrompt';
import CurrencyMask from 'components/Inputs/CurrencyMask';
import { formatCurrency } from 'utils/fuctions/formatCurrency';
import { parseIntFromString } from 'utils/fuctions/parseIntFromString';

const ProtocolFieldsForm = (entity) => {
  const regexRemoveCharacters = /[^\w\s]/g;

  const history = useHistory();
  const { id } = useParams();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOptionEdition, setIsOptionEdition] = useState(false);
  const [dataOptionToEdit, setDataOptionToEdit] = useState(null);
  const [listItems, setListItems] = useState([]);
  const [allFields, setAllFields] = useState([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [fieldData, setFieldData] = useState(null);
  const [entityName, setEntityName] = useState('');
  const [fieldsListFromSelectParents, setFieldsListFromSelectParents] =
    useState([]);
  const [optionsFieldsParent, setOptionsFieldsParent] = useState([]);
  const [isSubfields, setIsSubfields] = useState(false);
  const [parentSelected, setParentSelected] = useState('');

  const [isLoadingSubfields, setIsLoadingSubfields] = useState(false);

  const entitiesEdit = ['Lead', 'lead', 'Oportunidade', 'opportunity'];

  const { recordTypes, isLoadingRecordTypes } = useRecordTypes(entityName);

  const {
    control,
    handleSubmit,
    errors,
    watch,
    setError,
    clearErrors,
    setValue,
    reset,
    formState
  } = useForm();
  const { isDirty } = formState;

  const currentTypeOnForm = watch('type') || protocolInputTypes.TEXT;

  const values = watch();
  console.log('values: ', values);

  const fieldType = watch('type');
  const isSubfieldsFieldValue = watch('isSubfields');
  const selectedRecordTypes = watch('recordTypeIds') ?? [];
  const parentSelectedFields = watch('parentId');

  const getFieldsByEntity = async (entity) => {
    if (entity !== '' && selectedRecordTypes) {
      if (!allFields?.length) {
        setIsLoadingSubfields(true);
        const result = await getAllFieldsByEntity(entity);
        setFieldsListFromSelectParents(
          result?.filter((item) => {
            let isSubfield = false;
            const isTypeCorrect = item.type === 'LIST';
            if (!isTypeCorrect) return false;
            selectedRecordTypes?.forEach((rt) => {
              item?.recordTypesConfig?.forEach((rctSubFields) => {
                if (rctSubFields.recordTypeId === rt) {
                  isSubfield = true;
                }
              });
            });

            return isSubfield && isTypeCorrect;
          })
        );
        setIsLoadingSubfields(false);
      } else {
        setFieldsListFromSelectParents(
          allFields?.filter((item) => {
            let isSubfield = false;
            const isTypeCorrect = item.type === 'LIST';
            if (!isTypeCorrect) return false;
            selectedRecordTypes?.map((rt) => {
              item?.recordTypesConfig?.map((rctSubFields) => {
                if (rctSubFields.recordTypeId === rt) {
                  isSubfield = true;
                }
              });
            });

            return isSubfield && isTypeCorrect;
          })
        );
      }
    }
  };

  const getAllFieldsByEntity = async (entity) => {
    return FieldsConfigService.getFields(entity.toLowerCase())
      .then((result) => {
        setAllFields(result.data.data);
        setIsLoadingSubfields(false);
        return result.data.data;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (isSubfieldsFieldValue !== undefined)
      setIsSubfields(isSubfieldsFieldValue);

    if (isSubfieldsFieldValue === false) {
      setParentSelected('');
      setOptionsFieldsParent([]);
      setValue('parentId', '');
    }
  }, [isSubfieldsFieldValue]);

  useEffect(() => {
    if (entityName) getFieldsByEntity(entityName);
  }, [selectedRecordTypes]);

  useEffect(() => {
    if (parentSelectedFields && fieldsListFromSelectParents?.length) {
      setParentSelected(parentSelectedFields);
      setOptionsFieldsParent(
        fieldsListFromSelectParents.filter(
          (item) => item.name === parentSelectedFields
        )[0]
      );
    }
  }, [parentSelectedFields]);

  useEffect(() => {
    if (entity.entity === 'Protocolo') {
      setEntityName('PROTOCOL');
      getAllFieldsByEntity('PROTOCOL');
    } else if (entity.entity === 'Contato') {
      setEntityName('CONTACT');
      getAllFieldsByEntity('CONTACT');
    } else if (entity.entity === 'Oportunidade') {
      setEntityName('OPPORTUNITY');
      getAllFieldsByEntity('OPPORTUNITY');
    } else if (entity.entity === 'Conta') {
      setEntityName('ACCOUNT');
      getAllFieldsByEntity('ACCOUNT');
    } else if (entity.entity === 'Lead') {
      setEntityName('LEAD');
      getAllFieldsByEntity('LEAD');
    }
  }, [entity.entity]);

  useEffect(() => {
    if (id && !isLoadingSubfields) {
      setIsUpdate(true);
      if (!entity.fieldData) {
        FieldsConfigService.getFieldById(id)
          .then((response) => {
            prepareDataToEdit(response.data.data);
          })
          .catch(() => {
            toast.error('Ocorreu um erro ao buscar os dados do campo');
          });
      } else {
        prepareDataToEdit(entity.fieldData);
      }
    }
  }, [id, isLoadingSubfields]);

  const prepareDataToEdit = async (field) => {
    if (field.parentName && !field.isReference) {
      setParentSelected(field.parentName);
      setOptionsFieldsParent(
        fieldsListFromSelectParents.filter(
          (item) => item.name === field.parentName
        )[0]
      );

      setIsSubfields(true);
    }

    const { label, description, type, options, entity } = field;
    setEntityName(entity);
    const recordTypeIds = field?.recordTypesConfig
      .filter((item) => item.isActive)
      .map((item) => item.recordTypeId);

    const displayConfig = field?.recordTypesConfig
      .filter((item) => item.isActive)
      .map((item) => {
        let defaultValue = item.defaultValue;
        if (type === protocolInputTypes.CURRENCY) {
          defaultValue = formatCurrency(defaultValue);
        }
        return {
          [`isRequired-${item.recordTypeId}`]: item.isRequired,
          [`isNotEditable-${item.recordTypeId}`]: item.isNotEditable,
          [`isOnlyToShowOnEdit-${item.recordTypeId}`]:
            !!item.isOnlyToShowOnEdit,
          [`isActive-${item.recordTypeId}`]: item.isActive,
          [`defaultValue-${item.recordTypeId}`]: defaultValue
        };
      });

    const displayConfigDTO = {};
    displayConfig.forEach((config) => {
      Object.assign(displayConfigDTO, config);
    });

    if (
      type === protocolInputTypes.LIST ||
      type === protocolInputTypes.MULTIPLE
    ) {
      const currentOptions = options.map(
        ({ value, recordTypeIds, parentsValue }) => ({
          name: value,
          recordTypeIds,
          parentsValue
        })
      );
      setListItems(currentOptions);
      const optionsUpdatedDTO = {
        label,
        description,
        isSubfields: field.parentName && !field.isReference,
        type,
        recordTypeIds,
        ...displayConfigDTO
      };
      const arr = field?.options ?? [];
      arr.map((item) => {
        optionsUpdatedDTO[
          `fieldsParent-${item.value.replace(regexRemoveCharacters, '')}`
        ] = item.parentsValue;
      });

      setFieldData(optionsUpdatedDTO);
    } else {
      setFieldData({
        label,
        isSubfields: false,
        description,
        type,
        recordTypeIds,
        ...displayConfigDTO
      });
    }
  };

  useEffect(() => {
    reset(fieldData);
  }, [fieldData, reset]);

  const onSubmit = async (data) => {
    try {
      const type = isUpdate ? fieldData.type : data.type;
      const dataToSend = {
        type,
        label: data.label,
        description: data.description,
        entity: entityName
      };

      const recordTypesConfig = !data.recordTypeIds
        ? []
        : data.recordTypeIds.map((id) => ({
            recordTypeId: id,
            isRequired: data[`isRequired-${id}`] || false,
            isNotEditable: data[`isNotEditable-${id}`] || false,
            isOnlyToShowOnEdit: data[`isOnlyToShowOnEdit-${id}`] || false,
            isActive: true,
            defaultValue: data[`defaultValue-${id}`] || ''
          }));
      dataToSend.recordTypesConfig = recordTypesConfig;

      if (
        type === protocolInputTypes.LIST ||
        type === protocolInputTypes.MULTIPLE
      ) {
        const areOptionsValid = areListOptionsValid();
        if (!areOptionsValid) return;

        const optionsDTO = listItems.map((item) => {
          let dataFromReturn = {
            value: item.name,
            recordTypeIds: item.recordTypeIds
          };

          if (isSubfields) {
            dataFromReturn.parentsValue =
              data[
                `fieldsParent-${item.name.replace(regexRemoveCharacters, '')}`
              ] || [];
          }
          return dataFromReturn;
        });
        dataToSend.options = optionsDTO;

        if (parentSelected && isSubfields) {
          dataToSend.parentName = parentSelected;
        }

        if (
          isUpdate &&
          entity.fieldData?.parentName &&
          entity.fieldData?.isReference
        ) {
          dataToSend.isReference = true;
          dataToSend.parentName = entity.fieldData?.parentName;
        }
      }

      if (type === protocolInputTypes.MULTIPLE) {
        dataToSend.recordTypesConfig.forEach((item) => {
          if (typeof item.defaultValue === 'string') return;
          item.defaultValue = item.defaultValue.join(';');
        });
      }

      if (type === protocolInputTypes.NUMERIC) {
        dataToSend.recordTypesConfig.forEach((item) => {
          if (typeof item.defaultValue === 'number') return;
          item.defaultValue = +item.defaultValue;
        });
      }

      if (type === protocolInputTypes.CURRENCY) {
        dataToSend.recordTypesConfig.forEach((item) => {
          item.defaultValue = parseIntFromString(item.defaultValue);
        });
      }

      isUpdate
        ? await FieldsConfigService.updateFieldCRM(id, dataToSend)
        : await FieldsConfigService.createField(dataToSend);

      toast.success(`Campo ${isUpdate ? 'atualizado' : 'criado'} com sucesso`);
      history.push('/admin/fieldsConfig');
    } catch (error) {
      console.log(error);
      const errorMessage = error.response?.data?.message;
      toast.error(errorMessage || 'Erro ao realizar operação');
    }
  };

  const itemAlreadyExists = (list, item) => {
    return list.map((option) => option.name).includes(item.name);
  };

  const addListItem = (new_item) => {
    if (itemAlreadyExists(listItems, new_item)) {
      return toast.warning('Uma opção como mesmo nome já foi criada!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
    setListItems([...listItems, new_item]);
    clearErrors('list_options');
  };

  const removelistItem = (item) => {
    const remain_items = listItems.filter((el) => el !== item);
    setListItems(remain_items);
  };

  const areListOptionsValid = () => {
    if (!listItems || !listItems.length) {
      setError('list_options', {
        type: 'required',
        message: 'Adicione pelo menos um item à lista'
      });

      return false;
    }
    return true;
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/fieldsConfig');
  };

  const getRecordTypeNameById = (id) => {
    if (!recordTypes) return [];
    return recordTypes.find((rt) => rt.id === id);
  };

  const getFieldParentByName = (name) => {
    if (allFields?.length) {
      const field = allFields.find((field) => field.name === name);
      return field;
    }
  };

  const handleOpenModalToEditoption = (optionData) => {
    setIsOptionEdition(true);
    setDataOptionToEdit(optionData);
    setIsModalOpen(true);
  };

  const handleUpdateItem = useCallback((name, { recordTypeIds }) => {
    setListItems((currentItems) => {
      currentItems.forEach((item) => {
        if (item.name === name) {
          item.recordTypeIds = recordTypeIds;
        }
      });

      return currentItems;
    });
  }, []);
  const handleToFieldParent = (fieldData) => {
    const { id, entity } = fieldData;
    fieldData.entityName = entity.toLowerCase();
    history.push(`/admin/fieldsConfig/form/${id}`, { ...fieldData });
  };
  return (
    <>
      <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        <ScreenPrompt
          when={isDirty && !formState.isSubmitting}
          message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
        />
        <Row className="mb-3">
          <Col md="6">
            <label className="form-control-label">Nome do Campo*</label>
            <Controller
              as={Input}
              control={control}
              name="label"
              rules={{ required: 'O nome do campo é obrigatório' }}
              defaultValue=""
            />
            <ErrorMessage
              errors={errors}
              name="label"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          <Col md="6">
            <label className="form-control-label">Descrição</label>
            <Controller
              as={Input}
              control={control}
              name="description"
              defaultValue=""
            />
          </Col>
        </Row>
        <Row className="mb-3">
          {!isUpdate && (
            <Col md={isSubfields ? '4' : '6'}>
              <label className="form-control-label">Tipo*</label>
              <Controller
                as={
                  <Input type="select" name="select">
                    {protocolFieldTypes.map((field) => (
                      <option key={field.type} value={field.type}>
                        {field.displayName}
                      </option>
                    ))}
                  </Input>
                }
                control={control}
                name="type"
                rules={{ required: 'Selecione o tipo do campo' }}
                defaultValue={protocolInputTypes.TEXT}
              />
              <ErrorMessage
                errors={errors}
                name="type"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          )}
          <Col md={isSubfields ? '4' : '6'}>
            <label className="form-control-label">Tipos de registro</label>
            <Controller
              render={(props) => (
                <FlexSelect
                  dataOptions={recordTypes || []}
                  isMulti={true}
                  value={props.value}
                  valueController={setValue}
                  fieldName="recordTypeIds"
                  labelName="name"
                  valueName="id"
                  isClearable={true}
                  disabled={isLoadingRecordTypes}
                />
              )}
              control={control}
              name="recordTypeIds"
              defaultValue=""
            />
            <ErrorMessage
              errors={errors}
              name="recordTypeIds"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          {isSubfields && (
            <Col md={isSubfields ? '4' : '6'}>
              <label className="form-control-label">Campo filho</label>
              <Controller
                render={(props) => (
                  <FlexSelect
                    dataOptions={fieldsListFromSelectParents || []}
                    isMulti={false}
                    value={props.value}
                    valueController={setValue}
                    fieldName="parentId"
                    labelName="name"
                    valueName="name"
                    isClearable={true}
                    isDisabled={
                      isLoadingRecordTypes ||
                      currentTypeOnForm !== 'LIST' ||
                      !currentTypeOnForm ||
                      !selectedRecordTypes
                    }
                  />
                )}
                control={control}
                name="parentId"
                defaultValue={isSubfields ? parentSelected : ''}
              />
              <ErrorMessage
                errors={errors}
                name="parentId"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          )}
        </Row>

        {(!isUpdate && fieldType === 'LIST') ||
        (isUpdate && !entity.fieldData.isReference) ? (
          <Row className="mb-3 ml-2">
            <Col md="8">
              <Controller
                name="isSubfields"
                defaultValue={isUpdate && isSubfields}
                control={control}
                render={(props) => (
                  <>
                    <label className="form-control-label">
                      <Input
                        type="checkbox"
                        onChange={(e) => props.onChange(e.target.checked)}
                        checked={(isUpdate && isSubfields) || props.value}
                      />
                      Associar subcampos
                    </label>
                  </>
                )}
              />
            </Col>
          </Row>
        ) : null}
        {(currentTypeOnForm === protocolInputTypes.LIST ||
          currentTypeOnForm === protocolInputTypes.MULTIPLE) && (
          <>
            <Row className="mt-1 mb-3">
              <Col md="5">
                <Button
                  color="success"
                  type="button"
                  onClick={() => {
                    setDataOptionToEdit(null);
                    setIsOptionEdition(false);
                    setIsModalOpen(true);
                  }}
                >
                  Adicionar opção
                </Button>
                <ErrorMessage
                  errors={errors}
                  name="list_options"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>

            {isUpdate && entity.fieldData.isReference && allFields.length && (
              <Row className="ml-2 mb-3">
                Este campo é usado como um subcampo de:
                {
                  <Button
                    outline={true}
                    size="sm"
                    color=""
                    onClick={() => {
                      handleToFieldParent({
                        ...getFieldParentByName(entity.fieldData.parentName)
                      });
                    }}
                  >
                    {/* <li className="fas fa-trash"></li> */}
                    {getFieldParentByName(entity.fieldData.parentName)?.label}
                  </Button>
                }
              </Row>
            )}
            {isUpdate &&
              !entity.fieldData.isReference &&
              allFields.length &&
              entity.fieldData.parentName && (
                <Row className="ml-2 mb-3">
                  Visualizar subcampo:
                  {
                    <Button
                      outline={true}
                      size="sm"
                      color=""
                      onClick={() => {
                        handleToFieldParent({
                          ...getFieldParentByName(entity.fieldData.parentName)
                        });
                      }}
                    >
                      {/* <li className="fas fa-trash"></li> */}
                      {getFieldParentByName(entity.fieldData.parentName)?.label}
                    </Button>
                  }
                </Row>
              )}

            {listItems.length > 0 && (
              <Row>
                <Table>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Tipos de Registro</th>
                      <th>Excluir</th>
                      {isSubfields && (
                        <th>Valores do campo filho ao selecionar</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {listItems.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>
                            {item.recordTypeIds.map((id) => {
                              const rt = (recordTypes || []).find(
                                (rt) => rt.id === id
                              );
                              return (
                                <h4 style={{ display: 'inline' }} key={id}>
                                  <Badge color="secondary">
                                    {rt?.name || ''}
                                  </Badge>
                                </h4>
                              );
                            })}
                          </td>
                          <td>
                            <Button
                              color="primary"
                              size="sm"
                              onClick={() => handleOpenModalToEditoption(item)}
                            >
                              <FiEdit size={14} />
                            </Button>
                            <Button
                              color="danger"
                              size="sm"
                              onClick={() => removelistItem(item)}
                            >
                              <li className="fas fa-trash"></li>
                            </Button>
                          </td>
                          {isSubfields && (
                            <td>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={
                                      optionsFieldsParent?.options?.map(
                                        (o) => ({
                                          value: o.value
                                        })
                                      ) || []
                                    }
                                    isMulti={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName={`fieldsParent-${item.name.replace(
                                      regexRemoveCharacters,
                                      ''
                                    )}`}
                                    labelName="value"
                                    valueName="value"
                                    isClearable={true}
                                    isDisabled={
                                      isLoadingRecordTypes ||
                                      currentTypeOnForm !== 'LIST' ||
                                      !currentTypeOnForm
                                    }
                                  />
                                )}
                                control={control}
                                defaultValue=""
                                name={`fieldsParent-${item.name.replace(
                                  regexRemoveCharacters,
                                  ''
                                )}`}
                              />
                            </td>
                          )}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Row>
            )}
          </>
        )}
        {!!selectedRecordTypes &&
          !!selectedRecordTypes.length &&
          selectedRecordTypes.map((recordTypeId) => (
            <React.Fragment key={recordTypeId}>
              <h3 className="mb-0 pb-0 mt-3" style={{ fontSize: '0.86rem' }}>
                {getRecordTypeNameById(recordTypeId)?.name}
              </h3>

              <Row className="pt-0 mt-0">
                <Col md="5" className="ml-4">
                  <Controller
                    name={`isRequired-${recordTypeId}`}
                    control={control}
                    defaultValue={false}
                    render={(props) => (
                      <>
                        <label className="form-control-label">
                          <Input
                            disabled={
                              optionsFieldsParent?.recordTypesConfig?.filter(
                                (e) =>
                                  e.recordTypeId === recordTypeId &&
                                  e.isRequired
                              ).length > 0 ||
                              watch(`isOnlyToShowOnEdit-${recordTypeId}`)
                            }
                            defaultValue={
                              optionsFieldsParent?.recordTypesConfig?.filter(
                                (e) =>
                                  e.recordTypeId === recordTypeId &&
                                  e.isRequired
                              ).length > 0
                            }
                            type="checkbox"
                            onChange={(e) => props.onChange(e.target.checked)}
                            checked={
                              optionsFieldsParent?.recordTypesConfig?.filter(
                                (e) =>
                                  e.recordTypeId === recordTypeId &&
                                  e.isRequired
                              ).length > 0 || props.value
                            }
                          />
                          {currentTypeOnForm === protocolInputTypes.LIST ||
                          currentTypeOnForm === protocolInputTypes.MULTIPLE
                            ? 'Seleção obrigatória'
                            : 'Campo obrigatório'}
                        </label>
                      </>
                    )}
                  />
                </Col>
              </Row>

              {!!values[`isRequired-${recordTypeId}`] && (
                <Row className="my-2">
                  {currentTypeOnForm === protocolInputTypes.TEXT && (
                    <Col sm="6">
                      <label className="form-control-label">Valor Padrão</label>
                      <Controller
                        as={Input}
                        control={control}
                        name={`defaultValue-${recordTypeId}`}
                        rules={{ required: 'Defina um valor padrão' }}
                        defaultValue=""
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`defaultValue-${recordTypeId}`}
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  )}
                  {currentTypeOnForm === protocolInputTypes.NUMERIC && (
                    <Col sm="6">
                      <label className="form-control-label">Valor Padrão</label>
                      <Controller
                        as={Input}
                        control={control}
                        name={`defaultValue-${recordTypeId}`}
                        rules={{ required: 'Defina um valor padrão' }}
                        defaultValue=""
                        type="number"
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`defaultValue-${recordTypeId}`}
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  )}
                  {currentTypeOnForm === protocolInputTypes.CURRENCY && (
                    <Col sm="6">
                      <label className="form-control-label">Valor Padrão</label>
                      <Controller
                        render={(props) => (
                          <CurrencyMask
                            valueController={setValue}
                            value={props.value}
                            fieldName={`defaultValue-${recordTypeId}`}
                          />
                        )}
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Defina um valor padrão' }}
                        name={`defaultValue-${recordTypeId}`}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`defaultValue-${recordTypeId}`}
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  )}
                  {currentTypeOnForm === protocolInputTypes.LIST && (
                    <Col sm="6">
                      <Controller
                        control={control}
                        defaultValue=""
                        name={`defaultValue-${recordTypeId}`}
                        rules={{ required: 'Selecione o valor padrão' }}
                        as={
                          <Input type="select">
                            <option value="">Selecione o valor padrão</option>

                            {listItems.map((option, index) => (
                              <option key={index} value={option.name}>
                                {option.name}
                              </option>
                            ))}
                          </Input>
                        }
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`defaultValue-${recordTypeId}`}
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  )}

                  {currentTypeOnForm === protocolInputTypes.MULTIPLE && (
                    <Col sm="6">
                      <Controller
                        render={(props) => {
                          return (
                            <FlexSelect
                              dataOptions={listItems}
                              isMulti={true}
                              isClearable={true}
                              value={props.value || ''}
                              valueController={setValue}
                              closeMenuOnSelect={false}
                              fieldName={`defaultValue-${recordTypeId}`}
                              labelName="name"
                              valueName="name"
                            />
                          );
                        }}
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Selecione o valor padrão' }}
                        name={`defaultValue-${recordTypeId}`}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`defaultValue-${recordTypeId}`}
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  )}

                  {currentTypeOnForm === protocolInputTypes.DATE && (
                    <Col sm="6">
                      <Controller
                        render={(props) => {
                          return (
                            <Controller
                              render={(props) => (
                                <div
                                  style={{ position: 'relative', zIndex: '1' }}
                                >
                                  <DatePicker
                                    fieldName={`defaultValue-${recordTypeId}`}
                                    valueController={setValue}
                                    defaultValue={props.value}
                                  />
                                </div>
                              )}
                              control={control}
                              defaultValue=""
                              rules={{ required: 'Selecione o valor padrão' }}
                              name={`defaultValue-${recordTypeId}`}
                            />
                          );
                        }}
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Selecione o valor padrão' }}
                        name={`defaultValue-${recordTypeId}`}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`defaultValue-${recordTypeId}`}
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  )}

                  {currentTypeOnForm === protocolInputTypes.HOUR && (
                    <Col sm="6">
                      <Controller
                        render={(props) => {
                          return (
                            <Controller
                              render={(props) => (
                                <Input
                                  value={props.value}
                                  onChange={props.onChange}
                                  type="time"
                                  step="2"
                                  placeholder={''}
                                />
                              )}
                              control={control}
                              defaultValue=""
                              rules={{ required: 'Selecione o valor padrão' }}
                              name={`defaultValue-${recordTypeId}`}
                            />
                          );
                        }}
                        control={control}
                        defaultValue=""
                        rules={{ required: 'Selecione o valor padrão' }}
                        name={`defaultValue-${recordTypeId}`}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`defaultValue-${recordTypeId}`}
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  )}

                  {currentTypeOnForm === protocolInputTypes.TELEPHONE && (
                    <Col sm="6">
                      <Controller
                        render={(props) => (
                          <InputGroup>
                            <TelephoneMask
                              valueController={setValue}
                              fieldName={`defaultValue-${recordTypeId}`}
                              value={props.value}
                              placeholder="(DDD) 0000-0000"
                            />
                          </InputGroup>
                        )}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true ? 'Campo obrigatório' : false,
                          validate: (value) => {
                            if (!value) return true;
                            let phoneFormated = value.replace(/\D/g, '');
                            phoneFormated = phoneFormated.replace(/^0/, '');

                            if (
                              phoneFormated.length < 10 &&
                              phoneFormated.length > 0
                            ) {
                              return 'Telefone inválido';
                            }
                            return true;
                          }
                        }}
                        name={`defaultValue-${recordTypeId}`}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`defaultValue-${recordTypeId}`}
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  )}

                  {currentTypeOnForm === protocolInputTypes.CPF && (
                    <Col sm="6">
                      <Controller
                        render={(props) => (
                          <CPFMask
                            valueController={setValue}
                            fieldName={`defaultValue-${recordTypeId}`}
                            value={props.value}
                            placeholder="000.000.000-00"
                          />
                        )}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true ? 'Campo obrigatório' : false,
                          validate: (value) => {
                            if (!value) return true;
                            let cpfFormated = value.replace(/\D/g, '');
                            if (!cpfFormated) return true;
                            if (cpfFormated.length !== 11)
                              return 'CPF inválido';
                            return true;
                          }
                        }}
                        name={`defaultValue-${recordTypeId}`}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`defaultValue-${recordTypeId}`}
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  )}

                  {currentTypeOnForm === protocolInputTypes.CNPJ && (
                    <Col sm="6">
                      <Controller
                        render={(props) => (
                          <CNPJMask
                            valueController={setValue}
                            fieldName={`defaultValue-${recordTypeId}`}
                            value={props.value}
                            placeholder="00.000.000/0001-00"
                          />
                        )}
                        control={control}
                        defaultValue=""
                        rules={{
                          required: true ? 'Campo obrigatório' : false,
                          validate: (value) => {
                            if (!value) return true;
                            let cnpjFormated = value.replace(/\D/g, '');
                            if (!cnpjFormated) return true;
                            if (cnpjFormated.length !== 14)
                              return 'CNPJ inválido';
                            return true;
                          }
                        }}
                        name={`defaultValue-${recordTypeId}`}
                      />
                      <ErrorMessage
                        errors={errors}
                        name={`defaultValue-${recordTypeId}`}
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  )}
                </Row>
              )}
              <Row className="pt-0 mt-0">
                <Col md="5" className="ml-4">
                  <Controller
                    name={`isOnlyToShowOnEdit-${recordTypeId}`}
                    control={control}
                    defaultValue={false}
                    render={(props) => {
                      return (
                        <>
                          <label className="form-control-label">
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              disabled={watch(`isRequired-${recordTypeId}`)}
                              checked={props.value}
                            />
                            Mostrar apenas na edição
                          </label>
                        </>
                      );
                    }}
                  />
                </Col>
              </Row>
              {entitiesEdit.includes(entity.entity) ? (
                <Row className="pt-0 mt-0">
                  <Col md="5" className="ml-4">
                    <Controller
                      name={`isNotEditable-${recordTypeId}`}
                      control={control}
                      defaultValue={false}
                      render={(props) => (
                        <>
                          <label className="form-control-label">
                            <Input
                              type="checkbox"
                              onChange={(e) => props.onChange(e.target.checked)}
                              checked={props.value}
                            />
                            Não editável
                          </label>
                        </>
                      )}
                    />
                  </Col>
                </Row>
              ) : null}
            </React.Fragment>
          ))}

        <hr />
        <ReactButton btnColor="confirmation" type="submit">
          {isUpdate ? 'Atualizar' : 'Criar'}
        </ReactButton>

        <ReactButton btnColor="cancelation" onClick={() => cancelAction()}>
          Cancelar
        </ReactButton>
      </Form>

      <ModalAddListOption
        isOpenModal={isModalOpen}
        onModalToggle={setIsModalOpen}
        setterFunction={addListItem}
        recordTypes={recordTypes}
        isOptionEdition={isOptionEdition}
        dataOptionToEdit={dataOptionToEdit}
        handleUpdateItem={handleUpdateItem}
      />
    </>
  );
};

export { ProtocolFieldsForm };
