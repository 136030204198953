import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Modal,
  ModalBody,
  Row
} from 'reactstrap';

import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import FlexSelect from 'components/Inputs/FlexSelect';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';
import { getStateConversation } from 'utils/fuctions/getStateConversation';
import QueueService from '../../../../../../../../Queue/service';
import { removeAnsweredConversation } from 'store/action/answered-conversations-action';
import { removeConversationInAttendance } from 'store/action/conversation-in-attendance-action';
import { useSocketConversationInstagram } from 'hooks/useSocketConversationInstagram';
const TransferConversation = ({
  name,
  tooltipTitle = '',
  id,
  conversation,
  isCurrentAgentsLogged
}) => {
  const { socketInstagramConversation: socketInstagram } =
    useSocketConversationInstagram();

  const { control, handleSubmit, errors, setValue } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [queues, setQueues] = useState([]);
  const dispatch = useDispatch();
  const [isTransferByAgentEspecific, setInsTransferByAgentEspecific] =
    useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [
    isCurrentAgentsLoggedWithoutTheCurrentAgent,
    setIsCurrentAgentsLoggedWithoutTheCurrentAgent
  ] = useState([]);
  const prevConversationRef = useRef(null);
  const prevIdRef = useRef(null);
  const userState = useRef('DDD não definido');

  useEffect(() => {
    if (isOpen) {
      prevConversationRef.current = conversation;
      prevIdRef.current = id;
      userState.current =
        (conversation?.cliente?.telefone &&
          getStateConversation(conversation.cliente?.telefone)) ||
        'DDD não definido';
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      const params = {
        showAllQueues: true,
        showDisable: false
      };
      QueueService.getAll(true)
        .then((response) => {
          //let arrayQueues = [];
          // const attendantQueues = JSON.parse(localStorage.getItem('queues'));
          // response.data.data.forEach((queue) => {
          //   attendantQueues.forEach((attendantQueue) => {
          //     if (queue.id === attendantQueue.id) arrayQueues.push(queue);
          //   });
          // });
          let filteredQueues = response.data.data.filter((fila) => {
            return fila['is_chat'];
          });
          setQueues([...filteredQueues]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isOpen]);

  useEffect(() => {
    if (isTransferByAgentEspecific) {
      if (isCurrentAgentsLogged) {
        let agentsToShow = [];
        if (
          conversation &&
          prevConversationRef.current?.atendente &&
          prevConversationRef.current?.atendente?.id_postgres
        ) {
          agentsToShow = isCurrentAgentsLogged.filter(
            (agent) =>
              agent.login !==
              prevConversationRef.current?.atendente?.id_postgres
          );
        } else {
          agentsToShow = isCurrentAgentsLogged;
        }
        setIsCurrentAgentsLoggedWithoutTheCurrentAgent(agentsToShow);
      }
    }
  }, [isCurrentAgentsLogged, isTransferByAgentEspecific, conversation]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = async (data) => {
    try {
      if (isTransferByAgentEspecific) {
        const agentFilter = isCurrentAgentsLogged.find(
          (agent) => data.user === agent.login
        );
        const atendente = {
          name: agentFilter.name,
          login: agentFilter.login,
          id_socket_atendente: agentFilter.socket_id
        };
        socketInstagram.emit('transferir_conversa_atendente', {
          id: prevIdRef.current,
          //codigo para transferencia de atendente para atendente com escolha de fila
          // fila: data.fila,
          atendente,
          responsavelPelaTransferencia: {
            name: localStorage.getItem('name'),
            login: localStorage.getItem('login')
          }
        });
      } else {
        const formatedData = { ...data };

        socketInstagram.emit('transferir_conversa', {
          id: prevIdRef.current,
          fila: formatedData.fila,
          formulario: {
            ...formatedData
          },
          responsavelPelaTransferencia: {
            name: localStorage.getItem('name'),
            login: localStorage.getItem('login')
          }
        });
      }
      dispatch(removeAnsweredConversation(prevIdRef.current));
      dispatch(removeConversationInAttendance());
      toast.success('Conversa transferida com sucesso!', {
        autoClose: 3000,
        closeOnClick: true
      });
      toggleModal();
    } catch (err) {
      const { data } = err.response;
      toast.error(data.message || 'Erro ao transferir conversa!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <Button
        size="sm"
        color="primary"
        title={tooltipTitle}
        onClick={toggleModal}
      >
        <i className="fas fa-random"></i>
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {name}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <Card style={{ backgroundColor: '#FFFFFF', marginTop: '-35px' }}>
            <CardBody>
              <CardTitle tag="h4">
                Cliente:{' '}
                {prevConversationRef.current?.cliente?.nome || 'Não informado'}
              </CardTitle>
              <CardSubtitle tag="h5" className="mb-3 text-muted">
                <Row>
                  <Col md="6" title="CPF do cliente">
                    <i
                      className="fas fa-id-card"
                      style={{ color: '#5bc0de' }}
                    ></i>{' '}
                    -{' '}
                    {prevConversationRef.current?.cliente?.cpf ||
                      'Não informado'}
                  </Col>

                  <Col md="6" title="Canal de atendimento">
                    <i
                      className="fas fa-users"
                      style={{ color: '#0275d8' }}
                    ></i>{' '}
                    - {prevConversationRef.current?.canal || 'Não reconhecido'}
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Telefone do cliente">
                    <i
                      className="fas fa-phone"
                      style={{ color: '#5cb85c' }}
                    ></i>{' '}
                    -{' '}
                    {prevConversationRef.current?.cliente?.telefone ||
                      'Não informado'}
                  </Col>

                  <Col md="6" title="Estado">
                    <i className="fas fa-city" style={{ color: '#f0ad4e' }}></i>
                    {prevConversationRef.current?.cliente?.telefone &&
                      ` - Estado : ${userState.current}`}
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Email do cliente">
                    <i
                      className="fas fa-envelope-open-text"
                      style={{ color: '#f0ad4e' }}
                    ></i>{' '}
                    -{' '}
                    {prevConversationRef.current?.cliente?.email ||
                      'Não informado'}
                  </Col>
                  <Col md="6" title="Atendente">
                    <i className="fas fa-user" style={{ color: '#f0ad4e' }}></i>
                    {prevConversationRef.current?.atendente?.name
                      ? ` - ${prevConversationRef.current?.atendente?.name}`
                      : ' - Não informado'}
                  </Col>
                </Row>
              </CardSubtitle>
            </CardBody>
          </Card>

          <hr style={{ marginTop: '-20px' }} />
          <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Col md="12">
                <FormGroup check>
                  <Input
                    type="checkbox"
                    checked={isTransferByAgentEspecific}
                    onChange={() =>
                      setInsTransferByAgentEspecific(
                        !isTransferByAgentEspecific
                      )
                    }
                  />
                  <label className="form-control-label">
                    Trocar forma de transferência para atendente específico
                  </label>
                </FormGroup>
              </Col>
            </Row>
            {!isTransferByAgentEspecific ? (
              <Row className="mb-3">
                <Col md="12">
                  <label className="form-control-label">Fila*</label>
                  <Controller
                    render={(props) => (
                      <FlexSelect
                        dataOptions={queues}
                        isMulti={false}
                        isClearable={false}
                        value={props.value}
                        valueController={setValue}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        fieldName="fila"
                        labelName="name"
                        valueName="name"
                      />
                    )}
                    control={control}
                    name="fila"
                    defaultValue=""
                    rules={{ required: 'A fila é obrigatória' }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="fila"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
              </Row>
            ) : (
              <Row className="mb-3">
                <Col md="12">
                  <label className="form-control-label">Usuário*</label>
                  <Controller
                    render={(props) => (
                      <FlexSelect
                        dataOptions={
                          isCurrentAgentsLoggedWithoutTheCurrentAgent
                        }
                        isMulti={false}
                        isClearable={true}
                        value={props.value}
                        valueController={setValue}
                        closeMenuOnSelect={true}
                        isSearchable={true}
                        fieldName="user"
                        labelName="name"
                        valueName="login"
                      />
                    )}
                    control={control}
                    name="user"
                    defaultValue=""
                    rules={{ required: 'Usuário é obrigatório!' }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="user"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
                {/* {isCurrentSelectAgentQueues !== undefined &&
                  isCurrentSelectAgentQueues.length > 0 && (
                    <Col md="12">
                      <label className="form-control-label">
                        Filas do atendente selecionado
                      </label>

                      <Controller
                        render={(props) => (
                          <FlexSelect
                            dataOptions={isCurrentSelectAgentQueues}
                            isMulti={false}
                            isClearable={true}
                            value={props.value}
                            valueController={setValue}
                            closeMenuOnSelect={true}
                            isSearchable={false}
                            fieldName="fila"
                            labelName="name"
                            valueName="value"
                          />
                        )}
                        control={control}
                        name="fila"
                        defaultValue=""
                        // rules={{
                        //   required: 'Filas do atendente é obrigatório!'
                        // }}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="fila"
                        render={({ message }) => errorFormMessage(message)}
                      />
                    </Col>
                  )} */}
              </Row>
            )}

            {/* <Row className="mb-5">
              <Col md="12">
                <label className="form-control-label">Observação</label>
                <Controller
                  as={
                    <Input
                      type="textarea"
                      rows="3"
                      name="observacao"
                      id="observacao"
                    />
                  }
                  control={control}
                  name="observacao"
                  defaultValue=""
                />
              </Col>
            </Row> */}

            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
              style={buttonStyle(systemConfiguration.cancelation_button_color)}
            >
              Fechar
            </Button>

            <Button
              color="primary"
              type="submit"
              style={buttonStyle(systemConfiguration.confirmation_button_color)}
            >
              Transferir
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TransferConversation;
