import { removeMaskSpecialCharacters } from 'views/CRM/shared/utils/phoneNumberHelpers';
import { stringToBoolean } from '../assets/convertDataTypes';
export function toFormatValuesForSending(
  data,
  user,
  selectedQueues,
  showAllConversations,
  isUpdate,
  isDisabledInputLogin,
  isDisabledInput,
  isSupervisor,
  showAllProtocols,
  signatureEmail
) {
  let formatedData = {
    ...data,
    dark_mode: stringToBoolean(data.dark_mode),
    login: data.login.toLowerCase(),
    show_all_conversations: showAllConversations,
    should_sign_in_asterisk: isDisabledInputLogin,
    use_scale: isDisabledInput,
    isSupervisor: isSupervisor,
    show_all_protocols: showAllProtocols,
    telephone: removeMaskSpecialCharacters(data.telephone),
    signatureEmail: signatureEmail
  };
  if (data?.nickname) {
    formatedData.nickname = data?.nickname.toLowerCase();
  }

  //formatting data
  delete formatedData.confirm_password;
  if (data.penalty === '') formatedData = { ...formatedData, penalty: null };
  if (data.teamId === '') formatedData = { ...formatedData, teamId: null };
  if (data.costscenterid === '')
    formatedData = { ...formatedData, costscenterid: [] };
  if (data.recordtypesid === '')
    formatedData = { ...formatedData, recordtypesid: [] };
  if (data.extensionsid === '')
    formatedData = { ...formatedData, extensionsid: [] };
  if (data.teamsIds === '') formatedData = { ...formatedData, teamsIds: [] };
  if (data.profilesid === '')
    formatedData = { ...formatedData, profilesid: [] };
  const queuesid = selectedQueues.map((queue) => queue?.value);
  formatedData = { ...formatedData, queuesid: queuesid };
  const penalties = selectedQueues.map((queue) => {
    const data = {
      queue_id: queue?.value,
      penalty: queue?.penality
    };
    return data;
  });

  formatedData = { ...formatedData, penalties };

  if (isUpdate)
    formatedData = {
      ...formatedData,
      active: user.active
    };

  return formatedData;
}
