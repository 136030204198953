import React, { useEffect } from 'react';
import { Row, Col, Input, Form } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';

import DefaultModal from 'components/Modal/DefaultModal';
import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';

import SurveyService from '../../../service';

const waysOfConductingSurvey = [
  { name: 'Enviar Link', value: 'SEND_LINK' },
  { name: 'Perguntar dentro da conversa', value: 'INSIDE_CHAT_CONVERSATION' }
];

const GeneralConfigurationModal = ({
  isModalOpen,
  onModalToggle,
  ...props
}) => {
  const { errors, control, handleSubmit, setValue, reset } = useForm();

  useEffect(() => {
    if (isModalOpen) {
      SurveyService.getSurveyGeneralConfigurations()
        .then((response) => {
          const { data } = response.data;
          reset(data);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [isModalOpen, reset]);

  const onSubmit = async (data) => {
    try {
      await SurveyService.updateSurveyGeneralConfigurations(data);
      toast.success('Configurações atualizadas');
    } catch (error) {
      toast.error('Ocorreu um erro inesperado ao salvar as configurações');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const closeModal = () => {
    onModalToggle(false);
  };

  return (
    <>
      <DefaultModal
        show={isModalOpen}
        onModalToggle={onModalToggle}
        title="Configurações gerais da pesquisa"
        showCloseButton={false}
        modalContainerClasses="modal-lg"
        {...props}
      >
        <>
          <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Col md="6">
                <label className="form-control-label">
                  Modo de realização da pesquisa*
                </label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      isClearable={false}
                      dataOptions={waysOfConductingSurvey}
                      isMulti={false}
                      closeMenuOnSelect={true}
                      value={props.value}
                      valueController={setValue}
                      fieldName="modoDeRealizarPesquisa"
                      labelName="name"
                      valueName="value"
                    />
                  )}
                  control={control}
                  name="modoDeRealizarPesquisa"
                  rules={{
                    required: 'Campo obrigatório'
                  }}
                  defaultValue=""
                />
                <ErrorMessage
                  errors={errors}
                  name="modoDeRealizarPesquisa"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
              <Col md="6">
                <label className="form-control-label">
                  Tempo de espera de resposta*
                </label>
                <Controller
                  as={<Input type="number" min={1} />}
                  control={control}
                  name="tempoDeEsperaDeResposta"
                  rules={{ required: 'Campo obrigatório!' }}
                  defaultValue=""
                />
                <ErrorMessage
                  errors={errors}
                  name="tempoDeEsperaDeResposta"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>
            <hr />
            <ReactButton btnColor="confirmation" type="submit">
              Salvar
            </ReactButton>

            <ReactButton btnColor="cancelation" onClick={closeModal}>
              Cancelar
            </ReactButton>
          </Form>
        </>
      </DefaultModal>
    </>
  );
};

export default GeneralConfigurationModal;
