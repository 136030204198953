import React, { useEffect, useMemo } from 'react';
import { format, startOfMonth } from 'date-fns';
import { Controller } from 'react-hook-form';
import { Col, Input, Row } from 'reactstrap';
import Datepicker from 'components/Inputs/DatePicker';
import { useUsers } from 'views/Report/Protocol/hooks/useUsers';
import { useListLeadStagesByRecordType } from 'views/CRM/LeadStages/hooks/useListLeadStagesByRecordType';
import FlexSelect from 'components/Inputs/FlexSelect';
import { timeDataNames } from 'views/CRM/shared/utils/leadOpportunityTimeName';

const dateRules = {
  required: 'Data é obrigatória',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};
const getDateBegin = () => {
  const date = new Date();
  const firstDayMonth = new Date(
    date.getFullYear('pt-br'),
    date.getMonth('pt-br'),
    1
  );
  return format(startOfMonth(firstDayMonth), 'dd/LL/yyyy');
};
const toISOFormat = (dateTimeString) => {
  const [date] = dateTimeString.split(' ');
  const [DD, MM, YYYY] = date.split('/');
  return `${DD}/${MM}/${YYYY}`;
};

const DefaultFieldsFilterForm = ({
  control,
  setValue,
  getValues,
  recordTypeId,
  register
}) => {
  const { userList } = useUsers();
  const { leadStages, getLeadStagesByRecordType, isLoadingLeadStages } =
    useListLeadStagesByRecordType();

  const showAllLeads = useMemo(() => {
    const flag = localStorage.getItem('show_all_leads');
    return flag === 'true';
  }, []);

  useEffect(() => {
    if (recordTypeId) getLeadStagesByRecordType(recordTypeId);
  }, [recordTypeId, getLeadStagesByRecordType]);

  return (
    <>
      <Row className="mb-3">
        <Col sm="8">
          <label className="form-control-label">Buscar</label>
          <Controller
            as={
              <Input
                type="text"
                name="textQuery"
                placeholder="Buscar por nome, telefone ou email"
              />
            }
            control={control}
            name="textQuery"
            defaultValue=""
          />
        </Col>
        <Col sm="4">
          <label className="form-control-label">Fase do Lead</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={leadStages ? leadStages : []}
                isMulti={false}
                value={props.value}
                valueController={setValue}
                fieldName="stageId"
                labelName="name"
                valueName="id"
                isClearable={true}
                disabled={isLoadingLeadStages}
                closeMenuOnSelect={true}
              />
            )}
            control={control}
            defaultValue=""
            name="stageId"
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm={showAllLeads ? '3' : '6'}>
          <label className="form-control-label">Data inicial</label>
          <Controller
            render={(props) => (
              <Datepicker
                fieldName="dateBegin"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateBegin"
            rules={dateRules}
            defaultValue={getDateBegin()}
          />
        </Col>
        <Col sm={showAllLeads ? '3' : '6'}>
          <label className="form-control-label">Data final</label>
          <Controller
            render={(props) => (
              <Datepicker
                fieldName="dateEnd"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateEnd"
            defaultValue={new Date().toLocaleDateString('pt-br')}
            rules={{
              ...dateRules,
              validate: async (value) => {
                if (
                  new Date(toISOFormat(value)) <
                  new Date(toISOFormat(getValues()['dateBegin']))
                ) {
                  return 'A data final não pode ser anterior à inicial!';
                } else {
                  return true;
                }
              }
            }}
          />
        </Col>
        {showAllLeads && (
          <Col sm="3">
            <label className="form-control-label">Responsável</label>
            <Controller
              render={(props) => (
                <FlexSelect
                  dataOptions={userList || []}
                  isMulti={false}
                  value={props.value}
                  valueController={setValue}
                  fieldName="responsibleLogin"
                  labelName="name"
                  valueName="login"
                  isClearable={true}
                  closeMenuOnSelect={true}
                />
              )}
              control={control}
              name="responsibleLogin"
              defaultValue=""
            />
          </Col>
        )}
        <Col sm="3">
          <label className="form-control-label">Prazo do Lead</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={timeDataNames || []}
                isMulti={false}
                value={props.value}
                valueController={setValue}
                fieldName="leadTIme"
                labelName="name"
                valueName="value"
                isClearable={true}
                closeMenuOnSelect={true}
              />
            )}
            control={control}
            name="leadTIme"
            defaultValue=""
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="6">
          <label
            className="form-control-label"
            style={{ marginRight: '20px', fontWeight: '400' }}
          >
            <input
              type="radio"
              name="filterDateBy"
              ref={register}
              value="creation"
              defaultChecked={true}
            />{' '}
            Data de criação
          </label>
          <label className="form-control-label" style={{ fontWeight: '400' }}>
            <input
              type="radio"
              name="filterDateBy"
              ref={register}
              value="last_update"
              defaultChecked={false}
            />{' '}
            Data de última alteração
          </label>
        </Col>
      </Row>
    </>
  );
};

export default DefaultFieldsFilterForm;
