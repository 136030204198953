import React, { useEffect, useState } from 'react';
import { differenceInSeconds } from 'date-fns';
import {
  FiPhoneIncoming,
  FiPhoneOutgoing,
  FiPauseCircle
} from 'react-icons/fi';
import { Card } from 'reactstrap';

import { styles } from './styles';

import { statusColors } from '../../../utils/state_colors';
import { queueAgentStatus } from '../../../utils/states';

import '../OneCardPerAgent/styled.css';

export const QueueMemberCardSmall = ({ data }) => {
  const [upTime, setUpTime] = useState('00:00:00');

  useEffect(() => {
    const intervalID = setInterval(() => {
      let differenceFromNow = differenceInSeconds(
        new Date(),
        new Date(data.date)
      );

      const hours = Math.floor(differenceFromNow / (60 * 60));
      differenceFromNow -= hours * 60 * 60;

      const minutes = Math.floor(differenceFromNow / 60);
      differenceFromNow -= minutes * 60;

      const seconds = Math.floor(differenceFromNow);

      function getFomattedDigit(digit) {
        return `${String(digit).padStart(2, '0')}`;
      }

      setUpTime(
        `${getFomattedDigit(hours)}:${getFomattedDigit(
          minutes
        )}:${getFomattedDigit(seconds)}`
      );
    }, 1000);

    return () => clearInterval(intervalID);
  }, [data.date]);

  const getIconByState = (state, isIncomingCall) => {
    if (
      state === queueAgentStatus.UNPLACED ||
      state === queueAgentStatus.AVAILABLE
    ) {
      return;
    }
    if (state === queueAgentStatus.PAUSE) return <FiPauseCircle />;
    if (isIncomingCall) return <FiPhoneIncoming />;
    return <FiPhoneOutgoing />;
  };

  const getContentByStatus = () => {
    if (data.state === queueAgentStatus.PAUSE) {
      return data.agent?.pause?.name || '';
    }
    const isInCall =
      data.state === queueAgentStatus.RING ||
      data.state === queueAgentStatus.RINGING;
    if (isInCall) {
      return data.caller;
    }
    return '';
  };

  return (
    <>
      {data.agent.pause?.show_alert ? (
        <Card
          style={{
            ...styles.container,
            backgroundColor: `purple`
          }}
        >
          <p style={styles.items}>
            <i className="fas fa-user"></i>
            <span className="mx-1">
              {(data.agent && data.agent.usr && data.agent.usr.name) ||
                data.agent.usr.login}
            </span>
          </p>
          <p style={styles.items}>
            {getIconByState(data.state, data.isIncomingCall)}
            <span className="mx-1">{getContentByStatus()}</span>
          </p>
        </Card>
      ) : (
        <Card
          style={{
            ...styles.container,
            backgroundColor: `${statusColors[data.state]}`
          }}
        >
          <p style={styles.items}>
            <i className="fas fa-user"></i>
            <span className="mx-1">
              {(data.agent && data.agent.usr && data.agent.usr.name) ||
                data.agent.usr.login}
            </span>
          </p>
          <p style={styles.items}>
            {getIconByState(data.state, data.isIncomingCall)}
            <span className="mx-1">{getContentByStatus()}</span>
          </p>
        </Card>
      )}
    </>
  );
};
