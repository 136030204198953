import React from 'react';
import { Row } from 'reactstrap';
import Loader from 'react-loader-spinner';

export const Loading = () => {
  return (
    <Row className="justify-content-md-center mt-6">
      <Loader type="TailSpin" color="#35ac50" height={100} width={100} />
    </Row>
  );
};
