/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Card,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';
import { toast } from 'react-toastify';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import Pagination from 'components/Pagination';
import ReactLoader from 'components/Loader';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';

import RecordTypeService from '../service';

const RecordTypeList = () => {
  const [recordTypes, setRecordTypes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [showDisabled, setShowDisabled] = useState(false);

  const getRecordTypes = useCallback(
    (selectedPage = 1, shouldShowDisabled = false) => {
      setLoading(true);
      RecordTypeService.getWithParameters(selectedPage, 10, {
        showDisabled: shouldShowDisabled
      })
        .then((res) => {
          const recordTypes = res.data.recordTypes;
          const quantity = res.data.quantity;

          setRecordTypes(recordTypes);
          setQuantity(quantity);
          setActivePage(selectedPage);
        })
        .catch((error) => {
          const msg = error.response?.data?.message;
          toast.error(msg || 'Erro ao buscar tipos de registro');
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  useEffect(() => {
    getRecordTypes();
  }, []);

  const handlePageChange = (activePage) =>
    getRecordTypes(activePage, showDisabled);

  const handleEnableRecordType = (id) => {
    RecordTypeService.enableRecordType(id)
      .then(() => {
        toast.success('Tipo de registro ativado');
        getRecordTypes(activePage, showDisabled);
      })
      .catch((error) => {
        const msg = error.response?.data?.message;
        toast.error(msg || 'Erro ao habilitar o tipo de registro');
      });
  };

  const handleDisableRecordType = (id) => {
    RecordTypeService.disableRecordType(id)
      .then(() => {
        toast.success('Tipo de registro desativado');
        getRecordTypes(activePage, showDisabled);
      })
      .catch((error) => {
        const msg = error.response?.data?.message;
        toast.error(msg || 'Erro ao desabilitar o tipo de registro');
      });
  };

  const toggleRecordTypeStatus = (isActive, id) => {
    if (isActive) return handleDisableRecordType(id);
    return handleEnableRecordType(id);
  };

  const entities = [
    {
      displayName: 'Oportunidade',
      typeNameUpper: 'OPPORTUNITY'
    },
    {
      displayName: 'Protocolo',
      typeNameUpper: 'PROTOCOL'
    },
    {
      displayName: 'Contato',
      typeNameUpper: 'CONTACT'
    },
    {
      displayName: 'Conta',
      typeNameUpper: 'ACCOUNT'
    },
    {
      displayName: 'Lead',
      typeNameUpper: 'LEAD'
    }
  ];

  const showTypeName = (typeName) => {
    const entries = Object.values(entities);
    const name = entries.map((name) => {
      if (name.typeNameUpper === typeName) {
        return name.displayName;
      } else {
        return '';
      }
    });
    return name;
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/recordType/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Tipos de registro</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col sm="12" className="text-right mt-2">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={showDisabled}
                            onChange={() => {
                              const newPage = !showDisabled ? 1 : activePage;
                              getRecordTypes(newPage, !showDisabled);
                              setShowDisabled(!showDisabled);
                            }}
                          />
                          <span>Mostrar desabilitados</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={recordTypes || []}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'active',
                      text: 'Ativo',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        return row.active ? 'Sim' : 'Não';
                      }
                    },
                    {
                      dataField: 'entity',
                      text: 'Entidade',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        return row.entity
                          ? showTypeName(row.entity)
                          : 'Protocolo';
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link to={'/admin/recordType/form/' + row.id}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },
                    {
                      dataField: 'enableDisable',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Habilitar / Desabilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            checked={row.active}
                            disabled={row.id === 1 || row.name === 'Padrão'}
                            id={row.id}
                            onChange={() => {
                              toggleRecordTypeStatus(row.active, row.id);
                            }}
                          />
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export { RecordTypeList };
