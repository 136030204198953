import React from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import CurrencyUsMask from 'components/Inputs/CurrencyUsMask';
import CurrencyMask from 'components/Inputs/CurrencyMask';

import { Row, Col, Input } from 'reactstrap';

export const WhatsappForm = ({ canal, control, setValue, errors }) => {
  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );
  return (
    <>
      <Row>
        <Col md="6">
          <label className="form-control-label">Nome do canal</label>
          <Controller
            as={Input}
            control={control}
            name="nome_canal"
            defaultValue={canal?.nome_canal ?? ''}
            rules={{
              required: 'nome é um campo obrigatório'
            }}
          />
          <ErrorMessage
            errors={errors}
            name="nome_canal"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6">
          <label className="form-control-label">Descrição</label>
          <Controller
            as={Input}
            control={control}
            name="descricao"
            rules={{
              required: 'descrição é um campo obrigatório'
            }}
            defaultValue={canal?.descricao ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="descricao"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <label className="form-control-label">API Key</label>
          <Controller
            as={Input}
            control={control}
            name="apikey"
            rules={{
              required: 'API KEY é um campo obrigatório'
            }}
            defaultValue={canal?.apikey ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="apikey"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6">
          <label className="form-control-label">Source</label>
          <Controller
            as={Input}
            control={control}
            defaultValue={canal?.source ?? ''}
            name="source"
            rules={{
              required: 'source é um campo obrigatório',
              validate: (value) => {
                if (!value) return true;
                let phoneFormated = value.replace(/\D/g, '');
                phoneFormated = phoneFormated.replace(/^0/, '');

                if (phoneFormated.length < 10 && phoneFormated.length > 0) {
                  return 'Telefone inválido';
                }
                return true;
              }
            }}
          />
          <ErrorMessage
            errors={errors}
            name="source"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <label className="form-control-label">Source Name</label>
          <Controller
            as={Input}
            control={control}
            name="srcName"
            rules={{
              required: 'Source Name é um campo obrigatório'
            }}
            defaultValue={canal?.srcName ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="srcName"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="3">
          <label className="form-control-label">Custo por conversa (US$)</label>
          <Controller
            render={(props) => (
              <CurrencyUsMask
                valueController={setValue}
                value={props.value}
                fieldName="custoPorConversa"
                placeholder="Valor"
              />
            )}
            control={control}
            name="custoPorConversa"
            rules={{
              required: 'Custo por conversa é um campo obrigatório'
            }}
            defaultValue={canal?.custoPorConversa ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="custoPorConversa"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="3">
          <label className="form-control-label">Cotação do dólar</label>
          <Controller
            render={(props) => (
              <CurrencyMask
                valueController={setValue}
                value={props.value}
                fieldName="cotacaoDolar"
                placeholder="Valor"
              />
            )}
            control={control}
            name="cotacaoDolar"
            rules={{
              required: 'Cotação do dólar é um campo obrigatório'
            }}
            defaultValue={canal?.cotacaoDolar ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="cotacaoDolar"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
    </>
  );
};
