import { useState, useEffect } from 'react';

import BlacklistService from '../../../../service';

export const DEFAULT_PAGE_SIZE = 10;

export const useFetch = (id, query, page) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [refetchIndex, setRefetchIndex] = useState(0);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    if (!id) return;

    (async () => {
      setLoading(true);

      try {
        const {
          data: { data, totalRegisters }
        } = await BlacklistService.getAllBlacklistNumbersSearch(id, {
          page,
          size: DEFAULT_PAGE_SIZE,
          query
        });

        setData(data);
        setQuantity(totalRegisters);
      } catch (error) {
        setError(true);
        setErrorMessage('Erro ao listar números da lista de bloqueio!');
      } finally {
        setLoading(false);
      }
    })();
  }, [id, query, page, refetchIndex]);

  const refecth = () =>
    setRefetchIndex((prevRefetchIndex) => prevRefetchIndex + 1);

  return { data, quantity, loading, error, errorMessage, refecth };
};
