import { useState } from 'react';
import React from 'react';
import CreatableSelect from 'react-select/creatable';

export const CreatableFlexSelect = ({
  style,
  controlStyle,
  itemStyle,
  validation,
  defaultValue = [],
  setValue
}) => {
  const styleDefault = {
    container: (provided) => ({
      ...provided,
      width: '100%',
      Height: '100%',
      ...style
    }),
    control: (provided) => ({
      ...provided,
      width: '100%',
      margin: 0,
      height: '100%;',
      ...controlStyle
    }),
    multiValue: (provided) => ({
      ...provided,
      ...itemStyle
    })
  };
  const createOption = (label) => ({
    label,
    value: label
  });

  const defineDefaultValue = (value) => {
    if (value.length === 0) return [];
    return value?.map((item) => createOption(item));
  };

  const [inputValue, setInputValue] = useState('');
  const [valueIntern, setValueIntern] = useState(
    defineDefaultValue(defaultValue)
  );

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (validation && validation(inputValue)) return;
        setValueIntern((prev) => [...prev, createOption(inputValue)]);
        setValue((prev) => [...prev, inputValue]);
        setInputValue('');
        event.preventDefault();
    }
  };

  const components = {
    DropdownIndicator: null
  };
  return (
    <CreatableSelect
      styles={styleDefault}
      components={components}
      options={defaultValue?.map((item) => createOption(item))}
      inputValue={inputValue}
      isClearable
      isMulti
      onChange={(newValue) => {
        setValueIntern(newValue);
        setValue(newValue?.map((item) => item.value));
      }}
      onInputChange={(newValue) => setInputValue(newValue)}
      onKeyDown={handleKeyDown}
      placeholder=""
      value={valueIntern}
    />
  );
};
