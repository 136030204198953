export const arrayTypesOfNotifications = [
  {
    type: 'email_protocol',
    title:
      'Notificação de abertura de protocolo por email, clique aqui para ser redirecionado para a página desse protocolo',
    icon: 'fas fa-envelope',
    color: '#FA8072'
  },
  {
    type: 'timeshift',
    title: 'Notificação de troca de escala',
    icon: 'fas fa-calendar-alt',
    color: 'var(--primary)'
  },
  {
    type: 'overtime',
    title: 'Notificação de hora extra',
    icon: 'fas fa-calendar-alt',
    color: 'var(--primary)'
  },
  {
    type: 'scheduling_protocol',
    title: 'Notificação de agendamento de ligação de protocolo',
    icon: 'fas fa-calendar-alt',
    color: 'var(--primary)'
  }
];
