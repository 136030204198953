import ServiceCRUD from '../../../services/ServiceCRUDFLEXUC';
//import { apiUc } from '../../../http';

class TransshipmentService extends ServiceCRUD {
  constructor() {
    super('/api/toque-de-ramais');
  }

  // recovery = (id) => {
  //   return apiUc.put(`${this.path}/${id}/enable`)
  // }
}

export default new TransshipmentService();
