export const typeOptions = [
  {
    label: 'Telefonia',
    value: 'TELEFONIA'
  }
];

export const statusOptions = [
  {
    label: 'Agendado',
    value: 'AGENDADO'
  },
  {
    label: 'Cancelado',
    value: 'CANCELADO'
  }
];
