import React, { useState } from 'react';
import {
  Modal,
  ModalBody,
  Row,
  CardBody,
  Col,
  Button,
  Input
} from 'reactstrap';

import { Option, StyleOption } from '../../styled';
import { BsFillPersonFill } from 'react-icons/bs';
import ReactButton from 'components/Buttons/ReactButton';
import ContactService from '../../../../QueueContent/components/Contact/service/index';
import { toast } from 'react-toastify';
const AddActiveContact = ({ name, tooltipTitle = '', conversation = {} }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmit = async () => {
    const clientName = document.getElementById('clientName').value;
    if (!document.getElementById('clientName').value) {
      toast.error('O campo de nome é obrigatório');
      return;
    }

    try {
      const usrLogin = localStorage.getItem('login');
      await ContactService.create({
        name: clientName,
        telephone: conversation.cliente.telefone,
        usrLogin
      });

      toast.success('Contato Adicionado ao contatos ativos com sucesso!');
      toggleModal();
    } catch (error) {
      const msg =
        error.response && error.response.data && error.response.data.message;
      toast.error(msg || 'Erro ao criar contato');
    }
  };

  return (
    <>
      <Option title={tooltipTitle}>
        <Button
          onClick={toggleModal}
          color="link"
          size="sm"
          style={StyleOption}
        >
          <BsFillPersonFill size={20} color="#32325d" />
        </Button>
      </Option>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {name}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>

        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />
          <CardBody className="mt--4">
            <Row className="mb-4">
              <Col>
                <label className="form-control-label text-capitalize">
                  Nome*
                </label>
                <Input
                  id="clientName"
                  defaultValue={conversation.cliente.nome ?? ''}
                />
              </Col>
            </Row>

            <ReactButton
              onClick={() => {
                onSubmit();
              }}
              btnColor="confirmation"
              type="submit"
            >
              Salvar
            </ReactButton>

            <ReactButton
              onClick={() => {
                toggleModal();
              }}
              btnColor="cancelation"
            >
              Cancelar
            </ReactButton>
          </CardBody>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddActiveContact;
