/* eslint-disable react/react-in-jsx-scope */

import TimelineHeader from './components/header';
import React, { useEffect, useState } from 'react';
import Loader from 'react-loader-spinner';
import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../assets/styles/table_custom_style';

import { Container, Row, Card, CardHeader } from 'reactstrap';
import axios from 'axios';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import { useHistory } from 'react-router';
import Service from '../../views/ChatConfiguration/service';
import { toast } from 'react-toastify';

const BotFlow = () => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [botFlowText, setBotFlowText] = useState([]);
  const [isLoadFlow, setIsLoadFlow] = useState(true);
  const [bot, setBot] = useState({});
  const history = useHistory();

  useEffect(() => {
    Service.getAll()
      .then((response) => {
        if (response.data.watson) {
          setBot(response.data.watson);
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Erro ao carregar as respostas do bot!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadBuscarFluxo();
  }, [bot]);

  //console.log('bot=======>', bot);
  async function loadBuscarFluxo() {
    setIsLoadFlow(true);
    await axios
      .post(
        //'http://localhost:5002/nodes/list',
        'https://flexucteste.g4flex.com.br/watson-api/nodes/list',

        {
          assistant: {
            //apikey: 'gfgU9QtTK18Ma8WXFl1vmrbEAlaKorOvNUlZdEEfVTGc',
            //somapay
            //apikey: 'VpM1xnw5Xrj3dcgRkyB8M3buL0Zn7W0w7rLmkE4WiQR0',
            apikey: bot.apiKey,
            url: 'https://api.us-south.assistant.watson.cloud.ibm.com'
          },
          //workspaceId: 'c6fea0a9-aa33-44ba-837b-8ae16ea13c25'
          //somapay
          //workspaceId: 'e78aede8-e2b4-4b79-9faf-8cfdd40305b1'
          workspaceId: bot.workspaceId
        },
        {
          headers: {
            'Access-Control-Allow-Methods':
              'GET, POST, OPTIONS, PUT, PATCH, DELETE',
            'Access-Control-Allow-Headers':
              'x-access-token, Origin, X-Requested-With, Content-Type, Accept',
            'Access-Control-Allow-Origin': '*'
          }
        }
      )
      .then((response) => {
        const data = response.data;

        // console.log(response);
        setBotFlowText(data);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoadFlow(false));
  }

  function handleUpdate(id) {
    //console.log(id);
    history.push(`/admin/botFlow/form/${id}`);
  }

  return (
    <>
      <TimelineHeader
        setCreate="/admin/configSurvey/form"
        name="Form validation"
        parentName="Forms"
      ></TimelineHeader>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Respostas do chatbot</h3>
                </CardHeader>
                <ToolkitProvider
                  data={botFlowText}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'label',
                      text: 'Resposta',
                      sort: true,
                      headerStyle: individual_header_style,
                      style: {
                        whiteSpace: 'break-spaces',
                        wordBreak: 'break-all',
                        width: '90%',
                        individual_column_style
                      }
                    },
                    {
                      dataField: 'titleAssunto',
                      text: 'Assunto',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            size="sm"
                            color="info"
                            onClick={() => handleUpdate(row.id)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {isLoadFlow ? (
                        <Row className="justify-content-md-center">
                          <Loader
                            type="TailSpin"
                            color={systemConfiguration.primary_color}
                            height={100}
                            width={100}
                          />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default BotFlow;
