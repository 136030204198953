const INITIAL_STATE = {
  chats: []
};

export default function orderListUser(state = INITIAL_STATE, action) {
  switch (action.type) {
    case '@GET_LIST_USER_CHAT_INTERNAL': {
      let arrayAux = state.chats;
      let dateMessage = new Date();
      arrayAux = action.list;

      arrayAux.map((item) => {
          item.date = dateMessage;
      });

      return {
        chats: [...arrayAux]
      };
    }
    case '@ORDER_CHAT': {
      let arrayAux = state.chats;
      let dateMessage = new Date();

      arrayAux.map((item) => {
        if (item.login === action.from) {
          item.date = dateMessage;
        }
      });

      arrayAux.sort((a, b) => {
        if (!a.date) {
          return -1;
        }
        if (new Date(a.date).getTime() < new Date(b.date).getTime()) {
          return 1;
        } else {
          return -1;
        }
      });

      return {
        chats: [...arrayAux]
      };
    }

    default:
      return state;
  }
}
