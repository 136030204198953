import { useState, useEffect } from 'react';
import { useConectionSocketAdmConversation } from 'hooks/useSocketAdmConversations';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';

export function useSocketLoggedAttendants() {
  const { socketAdm } = useConectionSocketAdmConversation();
  const { socketConversation } = useConversationConnectionSocketForMonitoring();
  const [loggedAttendants, setLoggedAttendants] = useState([]);

  useEffect(() => {
    socketConversation.emit('lista_atendentes_logados');
  }, [socketConversation]);

  useEffect(() => {
    let userTeam = localStorage.getItem('teams');
    if (userTeam) {
      userTeam = JSON.parse(userTeam);
    }
    socketAdm.on('atendentes_logados', (data) => {
      setLoggedAttendants(data.atendentes);
    });
  }, [socketAdm]);

  return {
    loggedAttendants,
    setLoggedAttendants
  };
}
