import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from 'reactstrap';

import ChartLabel from '../ChartLabel';

const CollectiveIndicatorChart = ({
  title,
  labels,
  dataset: { data, percentage, backgroundColor }
}) => {
  return (
    <Card>
      <CardHeader>
        <CardTitle tag="h4" className="mb-0">
          {title}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row className="mb-4">
          <Col xl="12">
            <div className="char">
              <Doughnut
                width={150}
                height={150}
                data={{
                  labels,
                  datasets: [
                    {
                      data,
                      backgroundColor,
                      label: 'Dataset 1'
                    }
                  ]
                }}
                className="chart-canvas"
                id="chart-doughnut"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xl="12">
            <div className="d-flex">
              {labels.map((label, index) => (
                <ChartLabel
                  key={index}
                  label={label}
                  value={data[index]}
                  alternativeValue={percentage[index]}
                  color={backgroundColor[index]}
                />
              ))}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CollectiveIndicatorChart;
