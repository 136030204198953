import styled from 'styled-components';

export const RelationsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  .row {
    width: 100%;
    margin: 0;
  }

  .col {
    padding: 0;
  }
`;

export const ConversationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow: auto;

  p {
    font-size: 0.9rem;
    font-weight: 400;
  }

  span {
    font-size: 0.8rem;
    font-weight: 500;
  }
`;

export const CallContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 1rem;
  overflow: auto;

  p {
    font-size: 0.9rem;
    font-weight: 400;
  }

  span {
    font-size: 0.8rem;
    font-weight: 500;
  }
`;
