import { useState, useCallback, useEffect } from 'react';
import { getClientFields } from '../../../Clients/components/FilterClientScreen/useClientSearch';
import ClientService from '../../../Clients/service';

export const useSearchClients = () => {
  const [fieldsConfig, setFieldsConfig] = useState();
  const [timeoutID, setTimeoutID] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    getClientFields()
      .then((config) => {
        setFieldsConfig(config);
      })
      .catch((error) => {
        console.error(`Erro ao buscar campos customizados ${error}`);
      });
  }, []);

  const findClients = useCallback(
    async (q) => {
      if (!fieldsConfig) return;

      const defaultFieldNames = ['nome', 'email', 'telefone'];
      const customTextFieldNames = fieldsConfig
        .filter((f) => f.type === 'TEXT')
        .map((f) => f.name);
      const customNumberFieldNames = fieldsConfig
        .filter((f) => f.type === 'NUMERIC')
        .map((f) => f.name);

      const textFilter = [...defaultFieldNames, ...customTextFieldNames].map(
        (fieldName) => ({
          [fieldName]: q
        })
      );

      const numberFilter = customNumberFieldNames.map((fieldName) => {
        try {
          const number = Number(q);
          return {
            [fieldName]: q && !Number.isNaN(number) ? number : undefined
          };
        } catch (error) {
          return { [fieldName]: undefined };
        }
      });

      const fieldsToFilter = [...textFilter, ...numberFilter];
      const filters = {};
      fieldsToFilter.forEach((filter) => {
        Object.assign(filters, filter);
      });

      const filtersDTO = {};
      for (const key in filters) {
        const value = filters[key];
        if (value) {
          filtersDTO[key] = value;
        }
      }

      try {
        const response = await ClientService.wideSearch(filtersDTO);

        const clients = response.data.content;
        if (!clients) return null;

        const clientsDataDTO = clients.map((data) => ({
          label: data.nome || '',
          value: data.id,
          telephone: data.telefone || '',
          email: data.email || ''
        }));

        return clientsDataDTO;
      } catch (error) {
        return null;
      }
    },
    [fieldsConfig]
  );

  const handleGetClients = useCallback(
    async (q) => {
      try {
        if (!q) return null;
        setIsSearching(true);
        const clients = await findClients(q);
        return clients;
      } catch (error) {
        return null;
      } finally {
        setIsSearching(false);
      }
    },
    [findClients]
  );

  const filterClients = useCallback(
    async (q) => {
      return new Promise((resolve, reject) => {
        try {
          if (typeof timeoutID === 'number') {
            clearTimeout(timeoutID);
          }

          setTimeoutID(
            setTimeout(async () => {
              const data = await handleGetClients(q);
              resolve(data);
            }, 1000)
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    [handleGetClients, timeoutID]
  );

  return {
    isSearching,
    filterClients
  };
};
