import { useState, useEffect, useCallback } from 'react';
import FieldsConfigService from 'views/fieldConfigs/service/index';

function useOpportunityCustomFields() {
  const [opportunityCustomFields, setOpportunityCustomFields] = useState(null);
  const [
    isLoadingOpportunityCustomFields,
    setIsLoadingOpportunityCustomFields
  ] = useState(false);

  const getOpportunityCustomFields = useCallback(async (params = {}) => {
    setIsLoadingOpportunityCustomFields(true);

    FieldsConfigService.getFields('opportunity', params)
      .then((response) => {
        const fieldsData = response.data.data;
        setOpportunityCustomFields(fieldsData);
      })
      .catch(() => {
        setOpportunityCustomFields(null);
      })
      .finally(() => {
        setIsLoadingOpportunityCustomFields(false);
      });
  }, []);

  useEffect(() => {
    getOpportunityCustomFields();
  }, [getOpportunityCustomFields]);

  return {
    opportunityCustomFields,
    isLoadingOpportunityCustomFields,
    reloadOpportunityCustomFields: getOpportunityCustomFields
  };
}

export { useOpportunityCustomFields };
