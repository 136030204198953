import React from 'react';
import { Row, Col, Button, Table } from 'reactstrap';

export function QueuePenaltyTable({
  selectedQueues,
  handleOpenModalUpdatePenality
}) {
  return (
    <>
      <Row className="mt-5">
        <Col>
          <h4>Definir Penalidades das Filas</h4>
        </Col>
      </Row>

      <Row>
        <Col>
          <Table striped size="sm" hover>
            <thead>
              <tr>
                <th>Fila</th>
                <th>Penalidade</th>
                <th>Alterar penalidade</th>
              </tr>
            </thead>

            {selectedQueues &&
              selectedQueues.map((queue, index) => (
                <tbody key={index}>
                  <tr>
                    <td>{queue.label}</td>
                    <td>{queue.penality}</td>
                    <td>
                      <Button
                        color="primary"
                        size="sm"
                        type="button"
                        onClick={() => handleOpenModalUpdatePenality(queue)}
                      >
                        <li className="fas fa-edit"></li>
                      </Button>
                    </td>
                  </tr>
                </tbody>
              ))}
          </Table>
        </Col>
      </Row>
    </>
  );
}
