import React, { useEffect } from 'react';
import { Table } from 'reactstrap';
import { FiArrowDownCircle, FiArrowUpCircle } from 'react-icons/fi';

export const OpportunityStagesConfig = ({
  opportunityStages = [],
  selectedOpportunityStages = [],
  opportunityStagesConfig,
  setOpportunityStagesConfig
}) => {
  useEffect(() => {
    if (opportunityStages && opportunityStages.length > 0) {
      const config = [];
      for (const stgId of selectedOpportunityStages) {
        config.push(opportunityStages.find((stg) => stg.id === stgId));
      }
      setOpportunityStagesConfig(config);
    }
  }, [
    selectedOpportunityStages,
    opportunityStages,
    setOpportunityStagesConfig
  ]);

  if (
    !opportunityStages ||
    !opportunityStages.length ||
    !selectedOpportunityStages ||
    !selectedOpportunityStages.length
  ) {
    return null;
  }

  const switchItems = (index1, index2) => {
    [opportunityStagesConfig[index1], opportunityStagesConfig[index2]] = [
      opportunityStagesConfig[index2],
      opportunityStagesConfig[index1]
    ];
    setOpportunityStagesConfig([...opportunityStagesConfig]);
  };

  const upItem = (i) => {
    const index = opportunityStagesConfig.indexOf(i);
    if (index > 0) {
      switchItems(index, index - 1);
    }
  };

  const downItem = (i) => {
    const index = opportunityStagesConfig.indexOf(i);
    if (index < opportunityStagesConfig.length - 1) {
      switchItems(index, index + 1);
    }
  };

  const stages = [
    {
      displayName: 'Aberto',
      typeNameUpper: 'OPEN'
    },
    {
      displayName: 'Fechado - Perdido',
      typeNameUpper: 'CLOSED_LOST'
    },
    {
      displayName: 'Fechado - Ganho',
      typeNameUpper: 'CLOSED_WON'
    }
  ];

  const showTypeName = (typeName) => {
    const entries = Object.values(stages);
    const name = entries.map((name) => {
      if (name.typeNameUpper === typeName) {
        return name.displayName;
      } else {
        return '';
      }
    });
    return name;
  };

  return (
    <div>
      <Table bordered={true} striped={true} size="sm" className="text-center">
        <thead>
          <tr>
            <th>Fase</th>
            <th>Tipo</th>
            <th>Ordenar</th>
          </tr>
        </thead>
        <tbody>
          {opportunityStagesConfig &&
            opportunityStagesConfig.map((stage) => (
              <tr key={stage.id}>
                <td className="text-left">{stage.name}</td>
                <td className="text-center">{showTypeName(stage.type)}</td>
                <td>
                  {stage.type === 'OPEN' ? (
                    <div className="">
                      <FiArrowUpCircle
                        size={20}
                        cursor="pointer"
                        onClick={() => upItem(stage)}
                      />
                      <FiArrowDownCircle
                        style={{ marginLeft: '10px' }}
                        size={20}
                        cursor="pointer"
                        onClick={() => downItem(stage)}
                      />
                    </div>
                  ) : null}
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </div>
  );
};
