import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Row, Form, Col, Input } from 'reactstrap';

import optionsOutsideHoursInput from './optionsOutsideHoursInput';
import { inboundRouteTypes } from './inboundRouteTypes';
import { ErrorMessage } from '@hookform/error-message';
import Modal from '../../../components/Modal/DefaultModal';
import ReactButton from 'components/Buttons/ReactButton';

const ModalForm = ({
  isOpenModal,
  onModalToggle,
  IVRs,
  audios,
  callbacks,
  queues,
  extensions,
  timeSchedules,
  setterFunction,
  dataToUpdate
}) => {
  const { reset, control, handleSubmit, errors, watch } = useForm();

  const currentType = watch('outside_hours_input.type') || 'IVR';

  useEffect(() => {
    if (dataToUpdate.outside_hours_input) {
      reset(dataToUpdate);
    } else {
      reset();
    }
  }, [reset, dataToUpdate]);

  const onSubmit = (data) => {
    setterFunction({ ...data });
    onModalToggle(false);
    reset();
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <Modal
      show={isOpenModal}
      onModalToggle={(state) => {
        if (Object.keys(dataToUpdate).length === 0) reset();
        onModalToggle(state);
      }}
      title="Rota Fora do Horário"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
    >
      <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          <Col sm="6">
            <label className="form-control-label">Tipo*</label>
            <Controller
              control={control}
              defaultValue="IVR"
              name="outside_hours_input.type"
              as={
                <Input type="select">
                  {optionsOutsideHoursInput.map((option, index) => (
                    <option key={index} value={option.value}>
                      {option.displayName}
                    </option>
                  ))}
                </Input>
              }
            />
          </Col>

          {currentType === inboundRouteTypes.IVR && (
            <Col md="6">
              <label className="form-control-label">URA*</label>
              <Controller
                defaultValue=""
                control={control}
                name="outside_hours_input.ivr_id"
                rules={{ required: 'Selecione uma URA' }}
                as={
                  <Input type="select">
                    <option value="">Selecione uma URA</option>
                    {IVRs.map((ivr) => (
                      <option key={ivr.id} value={ivr.id}>
                        {ivr.name}
                      </option>
                    ))}
                  </Input>
                }
              />
              <ErrorMessage
                errors={errors}
                name="outside_hours_input.ivr_id"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          )}

          {currentType === inboundRouteTypes.AUDIO && (
            <Col md="6">
              <label className="form-control-label">Áudio*</label>
              <Controller
                defaultValue=""
                control={control}
                name="outside_hours_input.audio_id"
                rules={{ required: 'Campo obrigatório' }}
                as={
                  <Input type="select">
                    <option value="">Selecione um áudio</option>
                    {audios.map((audio) => (
                      <option key={audio.id} value={audio.id}>
                        {audio.name}
                      </option>
                    ))}
                  </Input>
                }
              />
              <ErrorMessage
                errors={errors}
                name="outside_hours_input.audio_id"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          )}

          {currentType === inboundRouteTypes.CALLBACK && (
            <Col md="6">
              <label className="form-control-label">Callback*</label>
              <Controller
                defaultValue=""
                control={control}
                name="outside_hours_input.callback_id"
                rules={{ required: 'Selecione a callback' }}
                as={
                  <Input type="select">
                    <option value="">Selecione uma callback</option>
                    {callbacks.map((callback) => (
                      <option key={callback.id} value={callback.id}>
                        {callback.name}
                      </option>
                    ))}
                  </Input>
                }
              />
              <ErrorMessage
                errors={errors}
                name="outside_hours_input.callback_id"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          )}

          {currentType === inboundRouteTypes.QUEUE && (
            <Col md="6">
              <label className="form-control-label">Fila*</label>
              <Controller
                defaultValue=""
                control={control}
                name="outside_hours_input.queue_id"
                rules={{ required: 'Selecione uma fila' }}
                as={
                  <Input type="select">
                    <option value="">Selecione uma fila</option>
                    {queues.map((queue) => (
                      <option key={queue.id} value={queue.id}>
                        {queue.name}
                      </option>
                    ))}
                  </Input>
                }
              />
              <ErrorMessage
                errors={errors}
                name="outside_hours_input.queue_id"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          )}

          {currentType === inboundRouteTypes.EXTENSION && (
            <Col md="6">
              <label className="form-control-label">Ramal*</label>
              <Controller
                defaultValue=""
                control={control}
                name="outside_hours_input.extension_id"
                rules={{ required: 'Selecione um Ramal' }}
                as={
                  <Input type="select">
                    <option value="">Selecione um Ramal</option>
                    {extensions.map((extension) => (
                      <option
                        key={extension.extension_number}
                        value={extension.extension_number}
                      >
                        {extension.extension_number}
                      </option>
                    ))}
                  </Input>
                }
              />
              <ErrorMessage
                errors={errors}
                name="outside_hours_input.extension_id"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          )}
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <label className="form-control-label">
              Programação de Horário*
            </label>
            <Controller
              defaultValue=""
              control={control}
              name="outside_hours_input.schedule_time_id"
              rules={{ required: 'Selecione uma programação' }}
              as={
                <Input type="select">
                  <option value="">Selecione uma programação</option>
                  {timeSchedules.map((schedule) => (
                    <option key={schedule.id} value={schedule.id}>
                      {schedule.name}
                    </option>
                  ))}
                </Input>
              }
            />
            <ErrorMessage
              errors={errors}
              name="outside_hours_input.schedule_time_id"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        </Row>
        <ReactButton btnColor="confirmation" type="submit">
          Salvar
        </ReactButton>
      </Form>
    </Modal>
  );
};

export default ModalForm;
