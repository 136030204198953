import styled from 'styled-components';

export const Hr = styled.hr`
  margin: 7px 0px;
`;

export const TimelineContainer = styled.div`
  width: 100%;
  display: flex;
`;

export const TimelineDot = styled.div`
  width: 35px;
  height: 35px;
  margin-top: 3%;
  text-align: center;
  color: white;
  border-radius: 50%;
  z-index: 99;

  ${(props) => props.color && `background-color: ${props.color};`}
`;

export const TimelineRow = styled.div`
  width: 300px;
  padding: 0.5em 2em 1.5em 2em;
`;

export const Vr = styled.div`
  margin-left: 1em;
  position: absolute;
  height: 100%;
  border-left: 2px dotted #999999;
  z-index: 98;
`;
