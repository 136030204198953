import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  display: flex;
  border-radius: 5px 5px 0 0;
  height: 80vh;
  padding: 10px;
  min-height: 500px;
  width: 100%;
  align-items: center;
`;

export const ContentInfor = styled.div`
  margin: 0 auto;
  display: flex;
  span {
    color: #5f73e4;
    font-size: 1.5rem;
    font-weight: bold;
    padding-right: 1rem;
  }

  svg {
    margin-top: 5px;
    color: #5f73e4;
    height: 1.5rem;
    width: 1.5rem;
  }
`;
