import { useState, useCallback } from 'react';

import {
  calculateOffsetFromPageAndLimit,
  defaultLimit
} from 'views/CRM/shared/utils/pagination';

import ProtocolProcessService from '../service';

function useListProtocolProcesses() {
  const [protocolProcesses, setProtocolProcesses] = useState(null);
  const [totalProtocolProcesses, setTotalProtocolProcesses] = useState(null);
  const [isLoadingProtocolProcesses, setIsLoadingProtocolProcesses] =
    useState(false);

  const getProtocolProcesses = useCallback(
    async (page = 1, params = {}, limit = defaultLimit) => {
      setIsLoadingProtocolProcesses(true);

      const offset = calculateOffsetFromPageAndLimit(page, limit);

      ProtocolProcessService.list(params, { offset, limit })
        .then((response) => {
          setProtocolProcesses(response.data.data);
          setTotalProtocolProcesses(response.data.totalRegisters);
        })
        .catch(() => {
          setProtocolProcesses(null);
        })
        .finally(() => {
          setIsLoadingProtocolProcesses(false);
        });
    },
    []
  );

  return {
    protocolProcesses,
    isLoadingProtocolProcesses,
    totalProtocolProcesses,
    getProtocolProcesses
  };
}

export { useListProtocolProcesses };
