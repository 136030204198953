import styled from 'styled-components';

export const Content = styled.div`
  background: #fff;
  display: flex;
  border-radius: 5px 5px 0 0;
  height: 87vh;
  padding: 10px;
  min-height: 500px;
  width: 100%;

  .queue {
    background-color: #fff;
    border: 0.5px solid lightgray;
    border-radius: 5px 0 0 0;
    padding: 10px;
    width: 335px;
    min-width: 258px;
  }

  .attendance {
    background-color: #fff;
    border: 0.5px solid lightgray;
    border-radius: 0 5px 0 0;
    text-align: center;
    width: calc(100% - 335px);
    min-width: 258px;
  }

  @media (max-width: 800px) {
    height: 100%;
    flex-direction: column;

    .queue {
      width: 100%;
      height: 350px;
    }

    .attendance {
      width: 100%;
    }
  }
`;
