import React from 'react';
import { format, startOfMonth } from 'date-fns';
import { Controller } from 'react-hook-form';
import { Col, Input, Row } from 'reactstrap';
import Datepicker from 'components/Inputs/DatePicker';
import { useUsers } from 'views/Report/Protocol/hooks/useUsers';
import FlexSelect from 'components/Inputs/FlexSelect';

const dateRules = {
  required: 'Data é obrigatória',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};
const getDateBegin = () => {
  const date = new Date();
  const firstDayMonth = new Date(
    date.getFullYear('pt-br'),
    date.getMonth('pt-br'),
    1
  );
  return format(startOfMonth(firstDayMonth), 'dd/LL/yyyy');
};
const toISOFormat = (dateTimeString) => {
  const [date] = dateTimeString.split(' ');
  const [DD, MM, YYYY] = date.split('/');
  return `${DD}/${MM}/${YYYY}`;
};

const DefaultFieldsFilterForm = ({
  control,
  setValue,
  getValues,
  register
}) => {
  const { userList } = useUsers();

  return (
    <>
      <Row className="mb-3">
        <Col sm="8">
          <label className="form-control-label">Buscar</label>
          <Controller
            as={
              <Input
                type="text"
                name="textQuery"
                placeholder="Buscar por nome ou telefone"
              />
            }
            control={control}
            name="textQuery"
            defaultValue=""
          />
        </Col>
        <Col sm="4">
          <label className="form-control-label">Criado Por</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={userList || []}
                isMulti={false}
                value={props.value}
                valueController={setValue}
                fieldName="createdByLogin"
                labelName="name"
                valueName="login"
                isClearable={true}
                closeMenuOnSelect={true}
              />
            )}
            control={control}
            name="createdByLogin"
            defaultValue=""
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="6">
          <label className="form-control-label">Data inicial</label>
          <Controller
            render={(props) => (
              <Datepicker
                fieldName="dateBegin"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateBegin"
            rules={dateRules}
            defaultValue={getDateBegin()}
          />
        </Col>
        <Col sm="6">
          <label className="form-control-label">Data final</label>
          <Controller
            render={(props) => (
              <Datepicker
                fieldName="dateEnd"
                valueController={setValue}
                defaultValue={props.value}
              />
            )}
            control={control}
            name="dateEnd"
            defaultValue={new Date().toLocaleDateString('pt-br')}
            rules={{
              ...dateRules,
              validate: async (value) => {
                if (
                  new Date(toISOFormat(value)) <
                  new Date(toISOFormat(getValues()['dateBegin']))
                ) {
                  return 'A data final não pode ser anterior à inicial!';
                } else {
                  return true;
                }
              }
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col sm="6">
          <label
            className="form-control-label"
            style={{ marginRight: '20px', fontWeight: '400' }}
          >
            <input
              type="radio"
              name="filterDateBy"
              ref={register}
              value="creation"
              defaultChecked={true}
            />{' '}
            Data de criação
          </label>
          <label className="form-control-label" style={{ fontWeight: '400' }}>
            <input
              type="radio"
              name="filterDateBy"
              ref={register}
              value="last_update"
              defaultChecked={false}
            />{' '}
            Data de última alteração
          </label>
        </Col>
      </Row>
    </>
  );
};

export default DefaultFieldsFilterForm;
