const channels = [
  'facebook',
  'watson',
  'whatsapp',
  'telegram',
  'instagram',
  'sms',
  'webchat',
  'flex4u'
];

export default channels;
