import { getFlexSelectCustomStyle } from 'assets/styles/multiple_select';
import Select from 'react-select';
import React from 'react';

export const ItemPerPageSelect = ({ valueController }) => {
  const width = '5rem';
  return (
    <>
      <Select
        styles={
          getFlexSelectCustomStyle({ width })}
        classNamePrefix="react-select"
        placeholder="Selecione..."
        defaultValue={{ value: 10, label: 10 }}
        closeMenuOnSelect={true}
        noOptionsMessage={'Nenhuma opção'}
        onChange={(e) => {
          valueController(e.value);
        }}
        options={[
          { label: 10, value: 10 },
          { label: 20, value: 20 },
          { label: 50, value: 50 },
          { label: 100, value: 100 }
        ]}
      ></Select>
    </>
  );
};
