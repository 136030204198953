import React, { useState } from 'react';
import DatePicker from 'components/Inputs/DatePicker.js';
import { Button, Col, Input, InputGroup, InputGroupAddon } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';

import FlexSelect from 'components/Inputs/FlexSelect';

import { customFieldTypes } from 'views/fieldConfigs/utils/customFieldTypes';
import CurrencyMask from 'components/Inputs/CurrencyMask';
import TelephoneMask from 'components/Inputs/TelephoneMask';
import CPFMask from 'components/Inputs/CPFMask';
import CNPJMask from 'components/Inputs/CNPJMask';
import { useClickToCallOnPhoneField } from '../../hooks/useClickToCallOnPhoneField';
import { removeMaskSpecialCharacters } from '../../utils/phoneNumberHelpers';
import TemplatesModal from 'views/ServicePanel/components/QueueContent/components/Contact/TemplatesModal';
import { useCustomFields } from 'views/Protocol/form/context/CustomFieldsContext';

const CustomFieldsInputsForm = ({
  fields,
  control,
  errors,
  watch,
  setValue,
  valueFieldsReferenceLoading,
  Controller,
  setChatCreatedOnIsolatedMessageId,
  setCallUniqueId,
  isTryingToCreateAContactAttempt = false,
  isUpdate = false,
  isOnEditPage = false,
  options = {
    showCallIconOnPhoneField: false,
    showMessageIconOnPhoneField: false
  },
  contactMessageClientName,
  draggableOptions = {
    itCanBeDragged: false,
    itIsDraggable: false
  },
  tabIndex = 0
}) => {
  const { changeFieldsReference, getValueFieldsReference } = useCustomFields();

  const { canMakeCall, makeCall, canSendMessage } =
    useClickToCallOnPhoneField();
  const [
    isModalTemplateMessageCustomFieldsOpen,
    setIsisModalTemplateMessageCustomFieldsOpen
  ] = useState(false);
  const [
    whatsAppMessageContactInfoCustomFields,
    setWhatsAppMessageContactInfoCustomFields
  ] = useState({});

  const errorFormMessage = (message) => (
    <p style={{ color: 'red', marginBottom: '0' }}>{message}</p>
  );

  function handleWhatsAppMessage(phone) {
    if (!phone) return;
    const phoneNumberWithoutMask = removeMaskSpecialCharacters(phone);
    let data = {
      nome: contactMessageClientName ?? '',
      telefone: phoneNumberWithoutMask ?? ''
    };

    setWhatsAppMessageContactInfoCustomFields(data);
    setIsisModalTemplateMessageCustomFieldsOpen(
      !isModalTemplateMessageCustomFieldsOpen
    );
    return whatsAppMessageContactInfoCustomFields;
  }

  function handleOpenUrlCustomField(url) {
    if (!url) return;
    const urlToOpen =
      url.startsWith('http://') || url.startsWith('https://')
        ? url.replace('http://', '').replace('https://', '')
        : url.replace(/.*\/\//, '');
    window.open(`https://${urlToOpen}`, '_blank');
  }

  const verifyParentId = (field, value) => {
    if (!field?.isReference && field?.parentName) {
      setValue(String(field.parentName.trim()), null);
      (field?.options ?? []).map((option) => {
        if (option.value === value) {
          changeFieldsReference({
            ...getValueFieldsReference(),
            [field?.parentName]: option.parentsValue.map((value) => ({
              value,
              label: value
            }))
          });
        }
      });

      return;
    }
  };

  const filterFields = (fields) => {
    if (isOnEditPage) return fields;

    const isOnlyToShowOnEdit = fields.filter(
      (field) => !field.recordTypesConfig.isOnlyToShowOnEdit
    );

    return isOnlyToShowOnEdit;
  };

  return (
    <>
      {!valueFieldsReferenceLoading &&
        filterFields(fields)?.map((field) => {
          if (field.type === customFieldTypes.TEXT) {
            return (
              <Col
                className={
                  draggableOptions.itCanBeDragged ? 'mb-0 p-0' : 'mb-3'
                }
                sm={draggableOptions.itCanBeDragged ? '' : '6'}
                key={field.id}
              >
                <label
                  className="form-control-label"
                  style={{
                    cursor: draggableOptions.itIsDraggable ? 'grab' : 'default'
                  }}
                >
                  {field.label}
                  {field.recordTypesConfig.isRequired ? '*' : ''}
                </label>
                <Controller
                  render={(props) => (
                    <Input
                      tabIndex={tabIndex}
                      value={props.value}
                      onChange={props.onChange}
                      type="text"
                      placeholder={field.label}
                      disabled={
                        isUpdate && field.recordTypesConfig.isNotEditable
                      }
                    />
                  )}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: field.recordTypesConfig.isRequired
                      ? 'Campo obrigatório'
                      : false
                  }}
                  name={field.name}
                />
                <ErrorMessage
                  errors={errors}
                  name={field.name}
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            );
          }

          if (field.type === customFieldTypes.DATE) {
            return (
              <Col
                className={
                  draggableOptions.itCanBeDragged ? 'mb-0 p-0' : 'mb-3'
                }
                sm={draggableOptions.itCanBeDragged ? '' : '6'}
                key={field.id}
              >
                <label
                  className="form-control-label"
                  style={{
                    cursor: draggableOptions.itIsDraggable ? 'grab' : 'default'
                  }}
                >
                  {field.label}
                  {field.recordTypesConfig.isRequired ? '*' : ''}
                </label>
                <Controller
                  render={(props) => (
                    <div style={{ position: 'relative', zIndex: '1' }}>
                      <DatePicker
                        tabIndex={tabIndex}
                        fieldName={field.name}
                        valueController={setValue}
                        defaultValue={props.value}
                        shouldDisable={
                          isUpdate && field.recordTypesConfig.isNotEditable
                        }
                      />
                    </div>
                  )}
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      field.recordTypesConfig.isRequired && 'Campo obrigatório'
                  }}
                  name={field.name}
                />
                <ErrorMessage
                  errors={errors}
                  name={field.name}
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            );
          }

          if (field.type === customFieldTypes.LIST) {
            return (
              <Col
                className={
                  draggableOptions.itCanBeDragged ? 'mb-0 p-0' : 'mb-3'
                }
                sm={draggableOptions.itCanBeDragged ? '' : '6'}
                key={field.id}
              >
                <label
                  className="form-control-label"
                  style={{
                    cursor: draggableOptions.itIsDraggable ? 'grab' : 'default'
                  }}
                >
                  {field.label}
                  {field.recordTypesConfig.isRequired ? '*' : ''}
                </label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      tabIndex={tabIndex}
                      dataOptions={
                        !field?.isReference
                          ? field.options.map((option) => ({
                              label: option.value,
                              value: option.value
                            }))
                          : getValueFieldsReference()[field.name] || []
                      }
                      isMulti={false}
                      isClearable={true}
                      value={props.value || ''}
                      valueController={(fieldName, fieldValue) => {
                        setValue(fieldName, fieldValue);
                        verifyParentId(field, fieldValue);
                      }}
                      closeMenuOnSelect={true}
                      fieldName={field.name}
                      labelName="label"
                      valueName="value"
                      isDisabled={
                        (field.isReference && !watch(field.parentName)) ||
                        (isUpdate && field.recordTypesConfig.isNotEditable)
                      }
                      portalTarget={true}
                    />
                  )}
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      field.recordTypesConfig.isRequired && 'Campo obrigatório'
                  }}
                  name={field.name}
                />
                <ErrorMessage
                  errors={errors}
                  name={field.name}
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            );
          }

          if (field.type === customFieldTypes.MULTIPLE) {
            return (
              <Col
                className={
                  draggableOptions.itCanBeDragged ? 'mb-0 p-0' : 'mb-3'
                }
                sm={draggableOptions.itCanBeDragged ? '' : '6'}
                key={field.id}
              >
                <label
                  className="form-control-label"
                  style={{
                    cursor: draggableOptions.itIsDraggable ? 'grab' : 'default'
                  }}
                >
                  {field.label}
                  {field.recordTypesConfig.isRequired ? '*' : ''}
                </label>
                <Controller
                  render={(props) => {
                    return (
                      <FlexSelect
                        tabIndex={tabIndex}
                        dataOptions={field?.options.map((option) => ({
                          label: option.value,
                          value: option.value
                        }))}
                        isMulti={true}
                        isClearable={true}
                        value={props.value || ''}
                        valueController={setValue}
                        defaultValue=""
                        closeMenuOnSelect={false}
                        fieldName={field.name}
                        labelName="label"
                        valueName="value"
                        isDisabled={
                          isUpdate && field.recordTypesConfig.isNotEditable
                        }
                        portalTarget={true}
                      />
                    );
                  }}
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      field.recordTypesConfig.isRequired && 'Campo obrigatório'
                  }}
                  name={field.name}
                />
                <ErrorMessage
                  errors={errors}
                  name={field.name}
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            );
          }

          if (field.type === customFieldTypes.NUMERIC) {
            return (
              <Col
                className={
                  draggableOptions.itCanBeDragged ? 'mb-0 p-0' : 'mb-3'
                }
                sm={draggableOptions.itCanBeDragged ? '' : '6'}
                key={field.id}
              >
                <label
                  className="form-control-label"
                  style={{
                    cursor: draggableOptions.itIsDraggable ? 'grab' : 'default'
                  }}
                >
                  {field.label}
                  {field.recordTypesConfig.isRequired ? '*' : ''}
                </label>
                <Controller
                  render={(props) => (
                    <Input
                      tabIndex={tabIndex}
                      value={props.value}
                      onChange={props.onChange}
                      type="number"
                      placeholder={field.label}
                      disabled={
                        isUpdate && field.recordTypesConfig.isNotEditable
                      }
                    />
                  )}
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      field.recordTypesConfig.isRequired && 'Campo obrigatório'
                  }}
                  name={field.name}
                />
                <ErrorMessage
                  errors={errors}
                  name={field.name}
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            );
          }

          if (field.type === customFieldTypes.CURRENCY) {
            return (
              <Col
                className={
                  draggableOptions.itCanBeDragged ? 'mb-0 p-0' : 'mb-3'
                }
                sm={draggableOptions.itCanBeDragged ? '' : '6'}
                key={field.id}
              >
                <label
                  className="form-control-label"
                  style={{
                    cursor: draggableOptions.itIsDraggable ? 'grab' : 'default'
                  }}
                >
                  {field.label}
                  {field.recordTypesConfig.isRequired ? '*' : ''}
                </label>
                <Controller
                  render={(props) => (
                    <CurrencyMask
                      tabIndex={tabIndex}
                      valueController={setValue}
                      value={props.value}
                      fieldName={field.name}
                      shouldDisable={
                        isUpdate && field.recordTypesConfig.isNotEditable
                      }
                    />
                  )}
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      field.recordTypesConfig.isRequired && 'Campo obrigatório'
                  }}
                  name={field.name}
                />
                <ErrorMessage
                  errors={errors}
                  name={field.name}
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            );
          }

          if (field.type === customFieldTypes.HOUR) {
            return (
              <Col
                className={
                  draggableOptions.itCanBeDragged ? 'mb-0 p-0' : 'mb-3'
                }
                sm={draggableOptions.itCanBeDragged ? '' : '6'}
                key={field.id}
              >
                <label
                  className="form-control-label"
                  style={{
                    cursor: draggableOptions.itIsDraggable ? 'grab' : 'default'
                  }}
                >
                  {field.label}
                  {field.recordTypesConfig.isRequired ? '*' : ''}
                </label>
                <Controller
                  render={(props) => (
                    <Input
                      tabIndex={tabIndex}
                      value={props.value}
                      onChange={props.onChange}
                      type="time"
                      step="2"
                      placeholder={field.label}
                      disabled={
                        isUpdate && field.recordTypesConfig.isNotEditable
                      }
                    />
                  )}
                  control={control}
                  defaultValue=""
                  rules={{
                    required:
                      field.recordTypesConfig.isRequired && 'Campo obrigatório'
                  }}
                  name={field.name}
                />
                <ErrorMessage
                  errors={errors}
                  name={field.name}
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            );
          }

          if (field.type === customFieldTypes.TELEPHONE) {
            return (
              <>
                <Col
                  className={
                    draggableOptions.itCanBeDragged ? 'mb-0 p-0' : 'mb-3'
                  }
                  sm={draggableOptions.itCanBeDragged ? '' : '12'}
                  key={field.id}
                >
                  <label
                    className="form-control-label"
                    style={{
                      cursor: draggableOptions.itIsDraggable
                        ? 'grab'
                        : 'default'
                    }}
                  >
                    {field.label}
                    {field.recordTypesConfig.isRequired ? '*' : ''}
                  </label>
                  <Controller
                    render={(props) => (
                      <InputGroup>
                        <TelephoneMask
                          tabIndex={tabIndex}
                          valueController={setValue}
                          fieldName={field.name}
                          value={props.value}
                          placeholder="(DDD) 0000-0000"
                          shoudlDisable={
                            isUpdate && field.recordTypesConfig.isNotEditable
                          }
                        />
                        {options && options.showMessageIconOnPhoneField && (
                          <InputGroupAddon addonType="append">
                            <Button
                              type="button"
                              color="info"
                              title="Enviar Mensagem"
                              disabled={!canSendMessage(props.value)}
                              onClick={() => handleWhatsAppMessage(props.value)}
                            >
                              <i className="fas fa-comment" />
                            </Button>
                          </InputGroupAddon>
                        )}
                        {options && options.showCallIconOnPhoneField && (
                          <InputGroupAddon addonType="append">
                            <Button
                              type="button"
                              color="success"
                              title="Realizar chamada"
                              disabled={!canMakeCall(props.value)}
                              onClick={() =>
                                makeCall(
                                  props.value,
                                  setCallUniqueId,
                                  isTryingToCreateAContactAttempt
                                )
                              }
                            >
                              <i className="fas fa-phone" />
                            </Button>
                          </InputGroupAddon>
                        )}
                      </InputGroup>
                    )}
                    control={control}
                    defaultValue=""
                    rules={{
                      required: field.recordTypesConfig.isRequired
                        ? 'Campo obrigatório'
                        : false,
                      validate: (value) => {
                        if (!value) return true;
                        let phoneFormated = value.replace(/\D/g, '');
                        phoneFormated = phoneFormated.replace(/^0/, '');

                        if (
                          phoneFormated.length < 10 &&
                          phoneFormated.length > 0
                        ) {
                          return 'Telefone inválido';
                        }
                        return true;
                      }
                    }}
                    name={field.name}
                  />
                  <ErrorMessage
                    errors={errors}
                    name={field.name}
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
                <TemplatesModal
                  isModalOpen={isModalTemplateMessageCustomFieldsOpen}
                  onModalToggle={setIsisModalTemplateMessageCustomFieldsOpen}
                  clientNumber={whatsAppMessageContactInfoCustomFields.telefone}
                  clientName={whatsAppMessageContactInfoCustomFields.nome}
                  clientId={
                    whatsAppMessageContactInfoCustomFields.clientId ?? ''
                  }
                  setChatCreatedOnIsolatedMessageId={
                    setChatCreatedOnIsolatedMessageId
                  }
                  isTryingToCreateAContactAttempt={
                    isTryingToCreateAContactAttempt
                  }
                  isIsolated={true}
                />
              </>
            );
          }

          if (field.type === customFieldTypes.CPF) {
            return (
              <Col
                md={draggableOptions.itCanBeDragged ? '' : '6'}
                className={
                  draggableOptions.itCanBeDragged ? 'mb-0 p-0' : 'mb-3'
                }
                sm={draggableOptions.itCanBeDragged ? '' : '12'}
                key={field.id}
              >
                <label
                  className="form-control-label"
                  style={{
                    cursor: draggableOptions.itIsDraggable ? 'grab' : 'default'
                  }}
                >
                  {field.label}
                  {field.recordTypesConfig.isRequired ? '*' : ''}
                </label>
                <Controller
                  render={(props) => (
                    <CPFMask
                      tabIndex={tabIndex}
                      valueController={setValue}
                      fieldName={field.name}
                      value={props.value}
                      placeholder="000.000.000-00"
                      shouldDisable={
                        isUpdate && field.recordTypesConfig.isNotEditable
                      }
                    />
                  )}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: field.recordTypesConfig.isRequired
                      ? 'Campo obrigatório'
                      : false,
                    validate: (value) => {
                      if (!value) return true;
                      let cpfFormated = value.replace(/\D/g, '');
                      if (!cpfFormated) return true;
                      if (cpfFormated.length !== 11) return 'CPF inválido';
                      return true;
                    }
                  }}
                  name={field.name}
                />
                <ErrorMessage
                  errors={errors}
                  name={field.name}
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            );
          }

          if (field.type === customFieldTypes.CNPJ) {
            return (
              <Col
                md={draggableOptions.itCanBeDragged ? '' : '6'}
                className={
                  draggableOptions.itCanBeDragged ? 'mb-0 p-0' : 'mb-3'
                }
                sm={draggableOptions.itCanBeDragged ? '' : '12'}
                key={field.id}
              >
                <label
                  className="form-control-label"
                  style={{
                    cursor: draggableOptions.itIsDraggable ? 'grab' : 'default'
                  }}
                >
                  {field.label}
                  {field.recordTypesConfig.isRequired ? '*' : ''}
                </label>
                <Controller
                  render={(props) => (
                    <CNPJMask
                      tabIndex={tabIndex}
                      valueController={setValue}
                      fieldName={field.name}
                      value={props.value}
                      placeholder="00.000.000/0001-00"
                      shouldDisable={
                        isUpdate && field.recordTypesConfig.isNotEditable
                      }
                    />
                  )}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: field.recordTypesConfig.isRequired
                      ? 'Campo obrigatório'
                      : false,
                    validate: (value) => {
                      if (!value) return true;
                      let cnpjFormated = value.replace(/\D/g, '');
                      if (!cnpjFormated) return true;
                      if (cnpjFormated.length !== 14) return 'CNPJ inválido';
                      return true;
                    }
                  }}
                  name={field.name}
                />
                <ErrorMessage
                  errors={errors}
                  name={field.name}
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            );
          }

          if (field.type === customFieldTypes.LINK) {
            return (
              <Col
                className={
                  draggableOptions.itCanBeDragged ? 'mb-0 p-0' : 'mb-3'
                }
                sm={draggableOptions.itCanBeDragged ? '' : '6'}
                key={field.id}
              >
                <label
                  className="form-control-label"
                  style={{
                    cursor: draggableOptions.itIsDraggable ? 'grab' : 'default'
                  }}
                >
                  {field.label}
                  {field.recordTypesConfig.isRequired ? '*' : ''}
                </label>
                <Controller
                  render={(props) => (
                    <>
                      <InputGroup>
                        <Input
                          tabIndex={tabIndex}
                          value={props.value}
                          onChange={props.onChange}
                          type="text"
                          placeholder={'https://'}
                          disabled={
                            isUpdate && field.recordTypesConfig.isNotEditable
                          }
                        />
                        <Button
                          type="button"
                          color="info"
                          title="Abrir url em nova aba"
                          onClick={() => handleOpenUrlCustomField(props.value)}
                        >
                          <li className="fas fa-external-link-alt"></li>
                        </Button>
                      </InputGroup>
                    </>
                  )}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: field.recordTypesConfig.isRequired
                      ? 'Campo obrigatório'
                      : false
                  }}
                  name={field.name}
                />
                <ErrorMessage
                  errors={errors}
                  name={field.name}
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            );
          }
        })}
    </>
  );
};
export default CustomFieldsInputsForm;
