/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import SimpleHeaderChangeDayOff from 'components/Headers/SimpleHeaderChangeDayOff';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'components/Pagination';
import TimeShiftService from '../../CalendarScales/service/TimeShift';
import TimeShiftStatusService from 'views/CalendarScales/service/TimeShiftStatus';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Card,
  Button,
  CardHeader,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
  Label
} from 'reactstrap';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';
import { format } from 'date-fns';
import { HeaderCalendar, ButtonLogo } from 'views/CalendarScales/style';
import { BsFillCalendarFill, BsPersonLinesFill } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';
import ModalChangeDayOff from '../components/ModalChangeDayOff';
import TimeShiftAgent from 'views/CalendarScales/service/TimeShiftAgent';
import { getShiftStatus } from '../functions';

export default function ChangeDayOffList() {
  const history = useHistory();
  const login = localStorage.getItem('login');
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [ids, setIds] = useState(0);
  const [typeShift, setTypeShift] = useState('single');
  const [isDayoffShift, setIsDayOffShift] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([]);
  const [showDisabled, setShowDisabled] = useState(false);

  useEffect(() => {
    localStorage.setItem('viewChangeDayOff', true);
  }, []);

  async function loadApi(page = 1, querySearch = '') {
    setLoading(true);
    const size = 10;
    TimeShiftService.getAllSearch(
      showDisabled,
      size,
      page,
      querySearch,
      login
    ).then((response) => {
      setQuantity(response.data.totalRegisters);
      const data = response.data.data;
      setData(data);
      setLoading(false);
    });
  }
  useEffect(() => {
    loadApi();
  }, [showDisabled]);

  const toISOFormat = (dateNotFormmated) => {
    const [date] = dateNotFormmated.split('T');
    const [YYYY, MM, DD] = date.split('-');
    return `${DD}/${MM}/${YYYY}`;
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    loadApi(page);
  };

  const ControlerStatus = (id, status) => {
    try {
      const data = { statusShift: status };

      if (data.statusShift === 'APPROVED') {
        TimeShiftAgent.patchShift(id, 'approve').finally(() => loadApi());
      }
      if (data.statusShift === 'REJECT') {
        TimeShiftAgent.patchShift(id, 'reject').finally(() => loadApi());
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const deleteExtraHour = (id) => {
    TimeShiftService.delete(id)
      .then(() => {
        toast.success('Troca excluída com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
        loadApi();
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Erro ao deletar a Troca!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        loadApi();
      });
  };

  return (
    <>
      <SimpleHeaderChangeDayOff
        setCreate="/admin/ChangeDayOff"
        name="List"
        parentName="Lists"
        loadApi={loadApi}
      />

      <Container fluid className="mt--6">
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <Container fluid style={{ backgroundColor: '#ffffff' }}>
                    <Row>
                      <HeaderCalendar>
                        <h2>Trocas</h2>
                        <ButtonLogo
                          onClick={() => {
                            localStorage.removeItem('viewChangeDayOff');
                            history.push('/admin/Calendar');
                          }}
                        >
                          <BsFillCalendarFill color="#646f85"></BsFillCalendarFill>
                        </ButtonLogo>
                        <ButtonLogo active="true">
                          <BsPersonLinesFill color="#646f85"></BsPersonLinesFill>
                        </ButtonLogo>
                      </HeaderCalendar>
                    </Row>
                  </Container>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Buscar Hora Extra"
                          type="text"
                          onChange={'handleOnSearch'}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="4" className="text-right mt-2 ">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={showDisabled}
                            onChange={() => setShowDisabled(!showDisabled)}
                          />
                          <span>Mostrar desabilitados</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={data}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'userRequestShift',
                      text: 'solicitante',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'userRequestedShift',
                      text: 'solicitado',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'typeShift',
                      text: 'Tipo de troca',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        if (row.typeShift === 'single' && row.isDayoffShift) {
                          return 'Troca de folga';
                        } else if (
                          row.typeShift === 'single' &&
                          !row.isDayoffShift
                        ) {
                          return 'Troca de horário Avulsa';
                        } else if (
                          row.typeShift === 'tiein' &&
                          !row.isDayOffShift
                        ) {
                          return 'Troca de horário Casada';
                        }
                      }
                    },
                    {
                      dataField: 'dateTimeShift',
                      text: 'Data de origem da troca',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        if (row.typeShift === 'single' && row.isDayoffShift) {
                          const response = toISOFormat(row.dateTimeShift);

                          const weekDay = format(
                            new Date(row.dateTimeShift),
                            'EEEE'
                          );

                          const scale = row.requestScale;

                          const beginTime = scale[`beginTime${weekDay}`];
                          const endTime = scale[`endTime${weekDay}`];

                          return response;
                        } else {
                          const response = toISOFormat(row.dateTimeShift);

                          const weekDay = format(
                            new Date(row.dateTimeShift),
                            'EEEE'
                          );

                          const scale = row.requestScale;

                          const beginTime = scale[`beginTime${weekDay}`];
                          const endTime = scale[`endTime${weekDay}`];

                          return response + '  -  ' + beginTime + '-' + endTime;
                        }
                      }
                    },
                    {
                      dataField: 'requestedDateShift',
                      text: 'Data de destino da troca',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        if (row.typeShift === 'single' && row.isDayoffShift) {
                          const response = toISOFormat(row.requestedDateShift);

                          const weekDay = format(
                            new Date(row.requestedDateShift),
                            'EEEE'
                          );

                          const scale = row.requestedScale;

                          const beginTime = scale[`beginTime${weekDay}`];
                          const endTime = scale[`endTime${weekDay}`];

                          return response;
                        } else {
                          const response = toISOFormat(row.requestedDateShift);

                          const weekDay = format(
                            new Date(row.requestedDateShift),
                            'EEEE'
                          );

                          const scale = row.requestedScale;

                          const beginTime = scale[`beginTime${weekDay}`];
                          const endTime = scale[`endTime${weekDay}`];

                          return response + '  -  ' + beginTime + '-' + endTime;
                        }
                      }
                    },
                    {
                      dataField: 'statusShift',
                      text: 'status',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        const objectTest = {
                          PENDING: 'Pendente',
                          APPROVED: 'Aprovado',
                          REJECT: 'Rejeitado'
                        };
                        return <>{objectTest[getShiftStatus(row)]}</>;
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        const editisDisabled = row.statusShift !== 'PENDING';

                        return (
                          <Button
                            color="info"
                            size="sm"
                            title={'Editar Perfil'}
                            disabled={editisDisabled}
                            onClick={() => {
                              setIsOpen(!isOpen);
                              setIds(row.id);
                              setTypeShift(row.typeShift);
                              setIsDayOffShift(row.isDayoffShift);
                            }}
                          >
                            <li className="fas fa-clone"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'remove',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        const editisDisabled =
                          row.userStatusShift !== 'PENDING';
                        return (
                          <Button
                            color="danger"
                            size="sm"
                            title={'Excluir perfil'}
                            disabled={editisDisabled}
                            onClick={() => deleteExtraHour(row.id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'aprove',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Aprovar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        const isAprove =
                          (row.supervisorStatusShift === 'APPROVED' &&
                            row.userStatusShift === 'PENDING') ||
                          row.typeShift !== 'tiein';

                        return (
                          <Button
                            color="success"
                            size="sm"
                            title={'Confirmar hora extra'}
                            disabled={!isAprove}
                            onClick={() => ControlerStatus(row.id, 'APPROVED')}
                          >
                            <li className="fas fa-check-circle"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Rejeitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        const isReject =
                          (row.supervisorStatusShift === 'APPROVED' &&
                            row.userStatusShift === 'PENDING') ||
                          row.typeShift !== 'tiein';

                        return (
                          <Button
                            color="danger"
                            size="sm"
                            title={'Rejeitar hora extra'}
                            disabled={!isReject}
                            onClick={() => ControlerStatus(row.id, 'REJECT')}
                          >
                            <li className="fas fa-times"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalChangeDayOff
        isModalOpen={isOpen}
        onModalToggle={setIsOpen}
        reloadfunction={loadApi}
        id={ids}
        typeShift={typeShift}
        isDayOffShift={isDayoffShift}
      />
    </>
  );
}
