import React, { useCallback, useEffect, useState } from 'react';
import { Modal, ModalBody, Row, Col, Input, Button } from 'reactstrap';
import ReactLoader from 'components/Loader';
import Select from 'react-select/async';
import ReactButton from 'components/Buttons/ReactButton';
import { SpaceItens, Hr } from '../.././../styled';
import ProtocolConfigService from '../../../service';
// react-grid-layout
import { Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { Controller, useForm } from 'react-hook-form';
import { FaHistory, FaRegCalendarAlt, FaSearch } from 'react-icons/fa';
import { custom_select } from 'assets/styles/multiple_select';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import CustomFieldsInputsForm from '../../../../CRM/shared/components/CustomFieldsInputsForm';
import { steps } from '../utils/tourSteps';
import Tour from 'reactour';
import { toast } from 'react-toastify';
import { FileProtocolArea } from 'views/Protocol/form/components/styledComponents/styled';
import { UploadButtonProtocol } from 'views/Protocol/form/components/styledComponents/styled';
import { FiUpload } from 'react-icons/fi';
const ResponsiveGridLayout = WidthProvider(Responsive);

export const AllowsRearrangeInputsModal = ({
  isModalOpen,
  toggleModal,
  allControls,
  recordTypeId
}) => {
  const { control, errors, setValue, watch } = useForm();
  const [protocolFormLayout, setProtocolFormLayout] = useState([]);
  const [tourOpen, setTourOpen] = useState(false);
  const [id, setId] = useState(null);

  const { customFields, getCustomFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType('PROTOCOL', recordTypeId);
  const [loadingForm, setLoadingForm] = useState(false);
  const openTour = () => {
    setTourOpen(true);
  };

  useEffect(() => {
    setLoadingForm(true);
    ProtocolConfigService.getProtocolPosition(recordTypeId, 'PROTOCOL')
      .then((response) => {
        if (response?.data?.positions) {
          setProtocolFormLayout(response.data.positions);
          setId(response.data._id);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao carregar a posição dos campos do protocolo', {
          autoClose: 3000,
          closeOnClick: true
        });
        setId(null);
      })
      .finally(() => {
        setLoadingForm(false);
      });
  }, []);

  const SubmitProtocolFieldsPosition = () => {
    setLoadingForm(true);
    const dataToSend = {
      positions: protocolFormLayout,
      recordType: recordTypeId,
      entity: 'PROTOCOL'
    };

    if (id) {
      ProtocolConfigService.updateProtocolPosition(dataToSend, id)
        .then((response) => {
          toast.success(
            'Posição dos campos do protocolo atualizada com sucesso',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
          closeModal();
        })
        .catch((error) => {
          console.log(error);
          toast.error('Erro ao atualizar a posição dos campos do protocolo', {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .finally(() => {
          closeModal();
          setLoadingForm(false);
        });
      return;
    }

    ProtocolConfigService.setProtocolPosition(dataToSend)
      .then((response) => {
        toast.success('Posição dos campos do protocolo salva com sucesso', {
          autoClose: 3000,
          closeOnClick: true
        });
        closeModal();
      })
      .catch((error) => {
        console.log(error);
        toast.error('Erro ao salvar a posição dos campos do protocolo', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        closeModal();
        setLoadingForm(false);
      });
  };

  const closeTour = () => {
    setTourOpen(false);
  };

  const closeModal = () => {
    toggleModal();
    setProtocolFormLayout([]);
  };

  useEffect(() => {
    setLoadingForm(isLoadingCustomFields);
  }, [isLoadingCustomFields]);

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  const renderCustomFields = useCallback(() => {
    if (!customFields || customFields.length === 0 || !protocolFormLayout) {
      return null;
    }

    return customFields.map((field, index) => {
      const { x, y, w, h, minW, maxW, minH, maxH } = protocolFormLayout.find(
        (item) => item.i === field.id
      ) || {
        x: Math.floor(Math.random() * 2) * 2,
        y: 8 + index,
        w: 2,
        h: 1,
        minW: 2,
        maxW: 4,
        minH: 1,
        maxH: 1
      };

      return (
        <div
          key={field.id}
          className="react-grid-layout-box"
          style={{
            width: '100%',
            marginRight: '0',
            zIndex: field.type === 'DATE' ? '1' : '-1'
          }}
          data-grid={{ x, y, w, h, minW, maxW, minH, maxH }}
        >
          <CustomFieldsInputsForm
            fields={[
              {
                ...field,
                recordTypesConfig: {
                  ...field.recordTypesConfig,
                  isNotEditable: true
                }
              }
            ]}
            watch={watch}
            control={control}
            errors={errors}
            setValue={setValue}
            Controller={Controller}
            isUpdate={true}
            isOnEditPage={true}
            draggableOptions={{
              itCanBeDragged: true,
              itIsDraggable: true
            }}
            itIsNotInteractable={true}
          />
        </div>
      );
    });
  }, [control, customFields, errors, protocolFormLayout, setValue]);

  const [hasMoved, setHasMoved] = useState(false);
  const [hasResized, setHasResized] = useState(false);

  const onDragStop = () => {
    setHasMoved(true);
  };

  const onResizeStop = () => {
    setHasResized(true);
  };

  const onLayoutChange = (newLayout) => {
    if (hasMoved || hasResized) {
      setProtocolFormLayout(newLayout);
    }
  };

  return (
    <>
      <Modal
        className="modal-dialog-centered"
        isOpen={isModalOpen}
        toggle={closeModal}
        style={{ width: '90vw', maxWidth: ' 50rem' }}
      >
        <div className="modal-header">
          <h6
            className="modal-title"
            id="modal-title-default"
            style={{
              width: '100%'
            }}
          >
            Reordenar campos do formulário do protocolo
            <Button
              className="float-right ml-2"
              type="button"
              outline
              color="info"
              onClick={openTour}
              title="Saiba mais sobre a reordenação de campos do protocolo"
            >
              Saiba Mais
            </Button>
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={closeModal}
          >
            <span aria-hidden={true}>x</span>
          </button>
        </div>
        <ModalBody>
          {loadingForm ? (
            <Row className="justify-content-md-center">
              <ReactLoader />
            </Row>
          ) : (
            allControls &&
            customFields && (
              <ResponsiveGridLayout
                className="layout"
                layout={protocolFormLayout}
                breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 4 }}
                rowHeight={84}
                style={{ zIndex: '0' }}
                onLayoutChange={onLayoutChange}
                onDragStop={onDragStop}
                onResizeStop={onResizeStop}
              >
                <div
                  key={'protocolNumber'}
                  className="react-grid-layout-box"
                  data-grid={
                    (protocolFormLayout &&
                      protocolFormLayout.length > 0 &&
                      protocolFormLayout.find(
                        (item) => item.i === 'protocolNumber'
                      )) || {
                      x: 2,
                      y: 0,
                      w: 2,
                      h: 1,
                      minW: 2,
                      maxW: 4,
                      minH: 1,
                      maxH: 1
                    }
                  }
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'space-between'
                    }}
                  >
                    <FaRegCalendarAlt
                      style={{
                        fontSize: '1.4rem',
                        display: 'inline',
                        color: 'rgb(0, 100, 140)',
                        cursor: 'pointer',
                        marginRight: '0.2rem',
                        marginTop: '2rem'
                      }}
                    />
                    <Col>
                      <label
                        className="form-control-label tour1"
                        style={{ cursor: 'grab' }}
                      >
                        Nº. do Protocolo
                      </label>
                      <Input type="text" value="123456" disabled />
                    </Col>
                  </div>
                </div>
                {allControls?.title?.isActive &&
                  !allControls?.title?.isOnlyToShowOnEdit && (
                    <div
                      key={'title'}
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'title'
                          )) || {
                          x: 0,
                          y: 1,
                          w: 4,
                          h: 1,
                          minW: 2,
                          maxW: 4,
                          minH: 1,
                          maxH: 1
                        }
                      }
                    >
                      <Row className="mb-3">
                        <Col>
                          <label
                            className="form-control-label"
                            style={{ cursor: 'grab' }}
                          >
                            Titulo
                          </label>
                          <Input
                            type="text"
                            disabled={true}
                            placeholder="Exemplo de titulo"
                          />
                        </Col>
                      </Row>
                    </div>
                  )}
                {allControls?.client_id?.isActive &&
                  !allControls?.client_id?.isOnlyToShowOnEdit && (
                    <div
                      key={'client_id'}
                      className="react-grid-layout-box"
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'client_id'
                          )) || {
                          x: 0,
                          y: 2,
                          w: 2,
                          h: 1,
                          minW: 2,
                          maxW: 4,
                          minH: 1,
                          maxH: 1
                        }
                      }
                      style={{
                        zIndex: '1'
                      }}
                    >
                      <div className="d-flex">
                        <div style={{ flexGrow: '10' }}>
                          <label
                            className="form-control-label"
                            style={{ cursor: 'grab' }}
                          >
                            Contato
                          </label>

                          <Select
                            styles={custom_select}
                            placeholder="Busca rápida..."
                            isDisabled={true}
                          />
                        </div>

                        <div
                          style={{
                            alignSelf: 'flex-end',
                            justifyContent: 'space-between',
                            padding: '0.6rem 0.6rem'
                          }}
                        >
                          <FaSearch
                            style={{
                              fontSize: '1.4rem',
                              display: 'inline',
                              color: 'rgb(0, 100, 140)',
                              cursor: 'pointer',
                              marginRight: '0.2rem'
                            }}
                          />

                          <FaHistory
                            style={{
                              fontSize: '1.6rem',
                              display: 'inline',
                              color: 'rgb(0, 150, 0)',
                              cursor: 'pointer',
                              paddingLeft: '0.2rem'
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                {allControls?.telephone?.isActive &&
                  !allControls?.telephone?.isOnlyToShowOnEdit && (
                    <div
                      key={'telephone'}
                      className="react-grid-layout-box"
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'telephone'
                          )) || {
                          x: 2,
                          y: 2,
                          w: 2,
                          h: 1,
                          minW: 2,
                          maxW: 4,
                          minH: 1,
                          maxH: 1
                        }
                      }
                    >
                      <label
                        className="form-control-label"
                        style={{ cursor: 'grab' }}
                      >
                        Telefone
                      </label>
                      <Input
                        type="text"
                        disabled={true}
                        placeholder="(00) 00000-0000"
                      />
                    </div>
                  )}
                {allControls?.description?.isActive &&
                  !allControls?.description?.isOnlyToShowOnEdit && (
                    <div
                      key={'description'}
                      className="react-grid-layout-box"
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'description'
                          )) || {
                          x: 0,
                          y: 3,
                          w: 2,
                          h: allControls?.account_id?.isActive ? 3 : 2,
                          minW: 2,
                          maxW: 4,
                          minH: 2,
                          maxH: 6
                        }
                      }
                    >
                      <label
                        className="form-control-label"
                        style={{ cursor: 'grab' }}
                      >
                        Descrição
                      </label>
                      <Input
                        type="textarea"
                        disabled={true}
                        style={{
                          height: '80%',
                          resize: 'none'
                        }}
                      />
                    </div>
                  )}
                {allControls?.reason_id?.isActive &&
                  !allControls?.reason_id?.isOnlyToShowOnEdit && (
                    <div
                      key={'reason_id'}
                      className="react-grid-layout-box"
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'reason_id'
                          )) || {
                          x: 2,
                          y: 3,
                          w: 2,
                          h: 1,
                          minW: 2,
                          maxW: 4,
                          minH: 1,
                          maxH: 1
                        }
                      }
                    >
                      <label
                        className="form-control-label"
                        style={{ cursor: 'grab' }}
                      >
                        Motivo
                      </label>
                      <Input
                        type="select"
                        name="select"
                        disabled={true}
                      ></Input>
                    </div>
                  )}
                {allControls?.subreason_id?.isActive &&
                  !allControls?.subreason_id?.isOnlyToShowOnEdit && (
                    <div
                      key={'subreason_id'}
                      className="react-grid-layout-box"
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'subreason_id'
                          )) || {
                          x: 2,
                          y: 4,
                          w: 2,
                          h: 1,
                          minW: 2,
                          maxW: 4,
                          minH: 1,
                          maxH: 1
                        }
                      }
                    >
                      <label
                        className="form-control-label"
                        style={{ cursor: 'grab' }}
                      >
                        Sub motivo
                      </label>
                      <Input
                        type="select"
                        name="select"
                        disabled={true}
                      ></Input>
                    </div>
                  )}
                {allControls?.account_id?.isActive &&
                  !allControls?.account_id?.isOnlyToShowOnEdit && (
                    <div
                      key={'account_id'}
                      className="react-grid-layout-box"
                      style={{ zIndex: '1' }}
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'account_id'
                          )) || {
                          x: 2,
                          y: 5,
                          w: 2,
                          h: 1,
                          minW: 2,
                          maxW: 4,
                          minH: 1,
                          maxH: 1
                        }
                      }
                    >
                      <label
                        className="form-control-label"
                        style={{ cursor: 'grab' }}
                      >
                        Conta
                      </label>

                      <Select
                        styles={custom_select}
                        placeholder="Buscar Contato"
                        isDisabled={true}
                      />
                    </div>
                  )}
                {allControls?.service_channel?.isActive &&
                  !allControls?.service_channel?.isOnlyToShowOnEdit && (
                    <div
                      key={'service_channel'}
                      className="react-grid-layout-box"
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'service_channel'
                          )) || {
                          x: 0,
                          y: 6,
                          w: 2,
                          h: 1,
                          minW: 2,
                          maxW: 4,
                          minH: 1,
                          maxH: 1
                        }
                      }
                    >
                      <label
                        className="form-control-label"
                        style={{ cursor: 'grab' }}
                      >
                        Canal de Atendimento
                      </label>
                      <Input
                        type="select"
                        name="select"
                        disabled={true}
                      ></Input>
                    </div>
                  )}
                {allControls?.current_status?.isActive &&
                  !allControls?.current_status?.isOnlyToShowOnEdit && (
                    <div
                      key={'current_status'}
                      className="react-grid-layout-box"
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'current_status'
                          )) || {
                          x: 2,
                          y: 6,
                          w: 2,
                          h: 1,
                          minW: 2,
                          maxW: 4,
                          minH: 1,
                          maxH: 1
                        }
                      }
                    >
                      <label
                        className="form-control-label"
                        style={{ cursor: 'grab' }}
                      >
                        Status
                      </label>
                      <Input
                        type="select"
                        name="select"
                        disabled={true}
                      ></Input>
                    </div>
                  )}
                {allControls?.current_responsible?.isActive &&
                  !allControls?.current_responsible?.isOnlyToShowOnEdit && (
                    <div
                      key={'current_responsible'}
                      className="react-grid-layout-box"
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'current_responsible'
                          )) || {
                          x: 0,
                          y: 6,
                          w: 2,
                          h: 1,
                          minW: 2,
                          maxW: 4,
                          minH: 1,
                          maxH: 1
                        }
                      }
                    >
                      <label
                        className="form-control-label"
                        style={{ cursor: 'grab' }}
                      >
                        Responsável atual
                      </label>
                      <Input
                        type="select"
                        name="select"
                        disabled={true}
                      ></Input>
                    </div>
                  )}
                {allControls?.email?.isActive &&
                  !allControls?.email?.isOnlyToShowOnEdit && (
                    <div
                      key={'email'}
                      className="react-grid-layout-box"
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'email'
                          )) || {
                          x: 2,
                          y: 7,
                          w: 2,
                          h: 1,
                          minW: 2,
                          maxW: 4,
                          minH: 1,
                          maxH: 1
                        }
                      }
                    >
                      <label className="form-control-label">Email</label>
                      <Input type="text" disabled={true} />
                    </div>
                  )}

                {(allControls?.files?.isActive || true) &&
                  (!allControls?.files?.isOnlyToShowOnEdit || true) && (
                    <div
                      key={'files'}
                      className="react-grid-layout-box"
                      data-grid={
                        (protocolFormLayout &&
                          protocolFormLayout.length > 0 &&
                          protocolFormLayout.find(
                            (item) => item.i === 'files'
                          )) || {
                          x: 2,
                          y: 7,
                          w: 4,
                          h: 1,
                          minW: 1,
                          maxW: 4,
                          minH: 1,
                          maxH: 1
                        }
                      }
                    >
                      <label className="form-control-label">Arquivos</label>
                      <FileProtocolArea htmlFor="files" disabled={true}>
                        <UploadButtonProtocol>
                          <span>Enviar Arquivo</span>
                          <FiUpload />
                        </UploadButtonProtocol>

                        <Input
                          id="files"
                          style={{ display: 'none' }}
                          type="files"
                          disabled={true}
                          accept="image/png"
                          multiple={true}
                        />
                      </FileProtocolArea>
                    </div>
                  )}

                {customFields && renderCustomFields()}
              </ResponsiveGridLayout>
            )
          )}

          <SpaceItens />
          <Hr />
          <>
            <ReactButton
              btnColor="confirmation"
              type="submit"
              className="tour2"
              onClick={SubmitProtocolFieldsPosition}
            >
              Salvar
            </ReactButton>
            <ReactButton btnColor="cancelation" onClick={closeModal}>
              Cancelar
            </ReactButton>
          </>
        </ModalBody>
      </Modal>
      <Tour steps={steps} isOpen={tourOpen} onRequestClose={closeTour} />
    </>
  );
};
