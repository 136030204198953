import { apiCrm } from '../../../../../http';

class ContactAttemptService {
  async create(data) {
    return apiCrm.post('/contact-attempt', data);
  }

  async list(entityId, entityType) {
    return apiCrm.get(`/contact-attempt?entityId=${entityId}&entityType=${entityType}`);
  }
}

export default new ContactAttemptService();
