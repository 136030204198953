import styled from 'styled-components';

export const Modifier = styled.button`
  padding: 0rem 0.25rem 0rem 0.25rem;
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  width: 3rem;
  height: 1.5625rem;
  text-align: center;
  cursor: pointer;
  margin-right: 0.125rem;
  background-color: white;
  font-weight: bold;
  font-size: 0.9rem;
  color: #525f7f;
  white-space: nowrap;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  label {
    margin-top: 0.5rem;
  }
`;

export const AdicionaveisContainer = styled.div`
  display: flex;
  margin-left: 0.3rem;
`;

export const ModifierContainer = styled.div`
  display: inline-flex;
  /* border: 1px solid black; */
  background-color: #e9ecef;
  padding: 0.3rem;
  border-radius: 0.25rem;
  width: min-content;
`;
