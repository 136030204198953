import React from 'react';

import { Form, Row, Col, Input, Table } from 'reactstrap';

import ReactButton from 'components/Buttons/ReactButton';

import { useForm, Controller } from 'react-hook-form';

import DatePickerIsValidCHA from 'views/CalendarScales/components/ChangeAvulsa/components/DatePickerIsValidCHA';
import FlexSelectCalenderCHA from 'views/CalendarScales/components/ChangeAvulsa/components/FlexSelectCalenderCHA';

import { toISOFormatAvulsa } from 'views/ChangeDayOff/functions';

import { format, addDays } from 'date-fns';

export default function AvulsaComponent({
  closeModal,
  setScaleSelected,
  scalesAvulsaTable,
  controlTable,
  daysOffObject,
  user,
  listFlexSelect,
  onSubmit,
  setControlTable,
  setScalesAvulsaTable,
  scales,
  isUndefined
}) {
  const { control, handleSubmit, setValue, getValues } = useForm();

  const requestTableAvulsa = async () => {
    const tableTiein = getValues(['supervisorShift', 'dateTimeShift']);

    if (
      tableTiein.supervisorShift === '' ||
      tableTiein.supervisorShift === undefined
    ) {
      setControlTable(false);
    }
    if (
      tableTiein.dateTimeShift === '' ||
      tableTiein.dateTimeShift === undefined
    ) {
      setControlTable(false);
    }

    if (
      tableTiein.supervisorShift !== 'undefined' &&
      tableTiein.supervisorShift !== '' &&
      tableTiein.dateTimeShift !== 'undefined' &&
      tableTiein.dateTimeShift !== ''
    ) {
      const weekDay = format(
        addDays(new Date(toISOFormatAvulsa(tableTiein.dateTimeShift)), 1),
        'EEEE'
      );

      const lowerWeekDay = weekDay.toLowerCase();

      const newArray = scales.map((item) => {
        if (
          item.sunday === true &&
          lowerWeekDay === 'sunday' &&
          user.scale.id !== item.id
        ) {
          return item;
        }
        if (
          item.monday === true &&
          lowerWeekDay === 'monday' &&
          user.scale.id !== item.id
        ) {
          return item;
        }
        if (
          item.tuesday === true &&
          lowerWeekDay === 'tuesday' &&
          user.scale.id !== item.id
        ) {
          return item;
        }
        if (
          item.wednesday === true &&
          lowerWeekDay === 'wednesday' &&
          user.scale.id !== item.id
        ) {
          return item;
        }
        if (
          item.thursday === true &&
          lowerWeekDay === 'thursday' &&
          user.scale.id !== item.id
        ) {
          return item;
        }
        if (
          item.friday === true &&
          lowerWeekDay === 'friday' &&
          user.scale.id !== item.id
        ) {
          return item;
        }
        if (
          item.saturday === true &&
          lowerWeekDay === 'saturday' &&
          user.scale.id !== item.id
        ) {
          return item;
        }
      });
      const formatNewArray = newArray.filter(isUndefined);

      const dataScales = formatNewArray.map((item) => {
        const avulsaBeginDate = item[`beginTime${weekDay}`];
        const avulsaEndDate = item[`endTime${weekDay}`];
        const avulsaId = item.id;

        const objectTieinTable = {
          beginDate: avulsaBeginDate,
          endDate: avulsaEndDate,
          id: avulsaId,
          name: item.name
        };
        return objectTieinTable;
      });
      setScalesAvulsaTable(dataScales);
      setControlTable(true);
    }
  };
  return (
    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
      <Row className="mt-4 ml-1">
        <Col>
          <label className="form-control-label">Supervisor</label>
          <Controller
            render={(props) => {
              return (
                <FlexSelectCalenderCHA
                  dataOptions={listFlexSelect}
                  isClearable={true}
                  isMulti={false}
                  value={props.value}
                  valueController={setValue}
                  fieldName="supervisorShift"
                  labelName="label"
                  valueName="value"
                  minWidth="192px"
                  requestTableAvulsa={requestTableAvulsa}
                />
              );
            }}
            control={control}
            name="supervisorShift"
            defaultValue=""
          />
        </Col>
        <Col>
          <label>Data de Troca</label>
          <Controller
            render={(props) => {
              return (
                <DatePickerIsValidCHA
                  fieldName="dateTimeShift"
                  valueController={setValue}
                  defaultValue={props.value}
                  requestTableAvulsa={requestTableAvulsa}
                  user={user}
                  daysOffObject={daysOffObject}
                />
              );
            }}
            control={control}
            name="dateTimeShift"
            defaultValue=""
          />
        </Col>
      </Row>
      {controlTable && (
        <Col className="mt-4">
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Nome da escala</th>
                <th>Hora inicial</th>
                <th>Hora Final</th>
              </tr>
            </thead>
            <tbody>
              {scalesAvulsaTable.map((item, key) => {
                return (
                  <tr key={key}>
                    <th scope="row">
                      <Input
                        type="checkbox"
                        style={{
                          marginTop: '-0.5rem',
                          marginLeft: '0.5rem'
                        }}
                        onClick={() => {
                          setScaleSelected(item.id);
                        }}
                      />
                    </th>
                    <td>{item.name || ''}</td>
                    <td>{item.beginDate}</td>
                    <td>{item.endDate}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      )}

      <Row style={{ marginTop: '3rem', marginLeft: '9rem' }}>
        <ReactButton btnColor="confirmation" type="submit">
          Salvar
        </ReactButton>
        <ReactButton
          btnColor="cancelation"
          type="button"
          onClick={() => closeModal()}
        >
          Cancelar
        </ReactButton>
      </Row>
    </Form>
  );
}
