import React from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';

import { Container } from './style';

export const FlexCollapse = ({ title, stateCollapse, setStateCollapse }) => {
  return (
    <Container onClick={() => setStateCollapse(!stateCollapse)}>
      <h3 className="mb-1">
        {title}
      </h3>
      {stateCollapse ? (
        <MdKeyboardArrowUp size="25" type="button" />
      ) : (
        <MdKeyboardArrowDown size="25" type="button" />
      )}
    </Container>
  );
};
