import ServiceCRUD from '../../../../../services/ServiceCRUDFLEXCHAT';
import { apiChat } from '../../../../../http';

class WordCloudScoreService extends ServiceCRUD {
  constructor() {
    super('relatorio');
  }

  getWordCloudScore(queryParams) {
    return apiChat.get(`${this.path}/nuvem-de-palavras-score`, {
      params: {
        ...queryParams
      }
    });
  }
}

export default new WordCloudScoreService();
