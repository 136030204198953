import React from 'react';
import Tree from 'react-d3-tree';
import NodeTree from './node';
import { useTree } from './context';

const TreeComponent = () => {
  const { treeData } = useTree();

  return (
    <div id="treeWrapper" style={{ width: '100vw', height: '100vh' }}>
      <Tree
        data={treeData}
        nodeSvgShape={{
          shape: 'rect',
          shapeProps: {
            width: 130,
            height: 90,
            x: -65,
            fill: '#fafafa',
            stroke: '#dadada',
            strokeWidth: 1
          }
        }}
        orientation="vertical"
        pathFunc="step"
        collapsible={false}
        translate={{
          x: 500,
          y: 30
        }}
        separation={{
          siblings: 2,
          nonSiblings: 2
        }}
        transitionDuration={0}
        allowForeignObjects={true}
        nodeLabelComponent={{
          render: <NodeTree />,
          foreignObjectWrapper: {
            x: -15,
            y: -20,
            height: 130,
            width: 200
          }
        }}
      />
    </div>
  );
};

export default TreeComponent;
