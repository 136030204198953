export const getHoursFromSeconds = (seconds) => {
  const hours = Math.floor(seconds / (60 * 60));
  const secondsLeftFromHours = seconds - hours * 60 * 60;

  return [hours, secondsLeftFromHours];
};

export const getMinutesFromSeconds = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  const secondsLeftFromMinutes = seconds - minutes * 60;

  return [minutes, secondsLeftFromMinutes];
};

export const formatDigit = (digit) => `${String(digit).padStart(2, '0')}`;
