import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Chart from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import randomColor from 'randomcolor';
import DashboardService from './service';
import 'moment/locale/pt-br';
import './components/assets/style.css';
import { Card, CardHeader, CardBody, Container, Row, Col } from 'reactstrap';
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';

import { ringChart, barChartConfig } from './components/assets';
import { chartOptions, parseOptions } from 'variables/charts.js';
import CardAttendants from './components/CardAttendants';
import CardItem from './components/CardItem';
import ReactLoader from 'components/Loader';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';

if (window.Chart) {
  parseOptions(Chart, chartOptions());
}

const Dashboard = () => {
  const [tmaeData, setTmaeData] = useState({
    tma: '00:00:00',
    tme: '00:00:00',
    tmd: '00:00:00'
  });
  const [rangeDate, setRangeDate] = useState(null);
  const [totalData, setTotalData] = useState(null);
  const [inAttendanceData, setInAttendanceData] = useState(null);
  const [closedData, setClosedData] = useState(null);
  const [notAnsweredData, setNotAnsweredData] = useState(null);
  const [conversationsOnHold, setConversationsOnHold] = useState(0);
  const [conversationsInAttendance, setConversationsInAttendance] = useState(0);
  const [conversationsClosed, setConversationsClosed] = useState(0);
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const primary_color = systemConfiguration.primary_color;

  useEffect(() => {
    const now = new Date();
    const firstDayMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    const range = { start: firstDayMonth, end: now, label: 'Mês' };

    setRangeDate({ ...range });

    const timer = setInterval(() => {
      const now = new Date();
      setRangeDate({ ...range, end: now });
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (firstLoad) {
      setLoading(true);
      setFirstLoad(false);
    }

    rangeDate &&
      FlexChatIntegration.getTotal(rangeDate)
        .then((res) => {
          let channel = [];
          let quantity = [];
          let colors = [];

          const canais = Object.keys(res?.data?.data?.canal) ?? [];

          canais.forEach((canal) => {
            if (canal === 'null') return;
            channel.push(canal);
            quantity.push(res.data.data.canal[canal].total);
            colors.push(randomColor({ luminosity: 'random', hue: 'random' }));
          });

          setTotalData({
            data: {
              labels: [...channel],
              datasets: [
                {
                  data: [...quantity],
                  backgroundColor: [...colors],
                  label: 'Dataset 1'
                }
              ]
            }
          });

          setConversationsOnHold(res.data.data.situacao.emEspera);
          setConversationsInAttendance(res.data.data.situacao.emAtendimento);
          setConversationsClosed(res.data.data.situacao.encerrada);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
        });
  }, [rangeDate, firstLoad]);

  useEffect(() => {
    rangeDate &&
      DashboardService.getInAttendance(rangeDate)
        .then((res) => {
          let attendants = [];
          let quantity = [];
          let colors = [];

          const attendantsWithCalls = res.data.filter(
            (attendant) => attendant.quantidade > 0
          );

          attendantsWithCalls.forEach((el) => {
            attendants.push(el.atendente);
            quantity.push(el.quantidade);
            colors.push(randomColor({ luminosity: 'random', hue: 'random' }));
          });

          setInAttendanceData({
            data: {
              labels: [...attendants],
              datasets: [
                {
                  data: [...quantity],
                  backgroundColor: [...colors],
                  label: 'Dataset 1'
                }
              ]
            }
          });
        })
        .catch((err) => {});
  }, [rangeDate]);

  useEffect(() => {
    rangeDate &&
      DashboardService.getClosed(rangeDate)
        .then((res) => {
          let attendants = [];
          let quantity = [];

          const attendantsWithCalls = res.data.filter(
            (attendant) => attendant.quantidade > 0
          );

          attendantsWithCalls.forEach((el) => {
            attendants.push(el.atendente);
            quantity.push(el.quantidade);
          });

          setClosedData({
            data: {
              labels: [...attendants],
              datasets: [
                {
                  label: 'Sales',
                  data: [...quantity],
                  backgroundColor: primary_color,
                  maxBarThickness: 10
                }
              ]
            }
          });
        })
        .catch((err) => {});
  }, [rangeDate, primary_color]);

  useEffect(() => {
    rangeDate &&
      DashboardService.getNotAnswered(rangeDate)
        .then((res) => {
          let queue = [];
          let quantity = [];

          const queues = res.data.filter((queue) => {
            if (queue.quantidade > 0) {
              return queue;
            } else {
              return null;
            }
          });

          queues.forEach((el) => {
            queue.push(el.fila);
            quantity.push(el.quantidade);
          });

          setNotAnsweredData({
            data: {
              labels: [...queue],
              datasets: [
                {
                  label: 'Sales',
                  data: [...quantity],
                  backgroundColor: primary_color,
                  maxBarThickness: 10
                }
              ]
            }
          });
        })
        .catch((err) => {});
  }, [rangeDate, primary_color]);

  useEffect(() => {
    rangeDate &&
      DashboardService.getTmae(rangeDate)
        .then((res) => {
          setTmaeData(res.data);
        })
        .catch((err) => {});
  }, [rangeDate]);

  // const handleRangeDate = (range) => {
  //   setLoading(true);
  //   const sunday = new Date();
  //   sunday.setDate(now.getDate() - now.getDay());
  //   const firstDayMonth = new Date(now.getFullYear(), now.getMonth(), 1);

  //   switch (range) {
  //     case 'day':
  //       setRangeDate({ start: now, end: now, label: 'Dia' });
  //       break;
  //     case 'week':
  //       setRangeDate({ start: sunday, end: now, label: 'Semana' });
  //       break;
  //     case 'month':
  //       setRangeDate({ start: firstDayMonth, end: now, label: 'Mês' });
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (
    <>
      <AlternativeHeader />

      {loading && (
        <Row className="justify-content-md-center mt-4">
          <ReactLoader />
        </Row>
      )}

      {!loading && (
        <>
          <Container className="mt--6" fluid>
            {/* {rangeDate && (
              <Row className="mb-3 justify-content-end">
                <Col md="3" className="text-md-right">
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={() => handleRangeDate('day')}
                  >
                    Dia
                  </Button>
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={() => handleRangeDate('week')}
                  >
                    Semana
                  </Button>
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={() => handleRangeDate('month')}
                  >
                    Mês
                  </Button>
                </Col>
              </Row>
            )} */}

            <Row>
              <Col md="6" xl="3">
                <CardAttendants
                  title="Usuários Logados"
                  icon="ni ni-circle-08"
                />
              </Col>
              <Col md="6" xl="3">
                <CardItem
                  title="Em espera"
                  text={conversationsOnHold}
                  icon="fas fa-clock"
                />
              </Col>
              <Col md="6" xl="3">
                <CardItem
                  title="Em atendimento"
                  text={conversationsInAttendance}
                  icon="fas fa-comments"
                />
              </Col>
              <Col md="6" xl="3">
                <CardItem
                  title="Encerradas"
                  text={conversationsClosed}
                  icon="fas fa-times-circle"
                />
              </Col>
            </Row>

            <Row>
              <Col md="6" xl="3" className="mt-4">
                <CardItem
                  title="T.M.A"
                  text={tmaeData.tma.substr(0, 8)}
                  icon="fas fa-hourglass-end"
                />

                <CardItem
                  title="T.M.E"
                  text={tmaeData.tme.substr(0, 8)}
                  icon="fas fa-hourglass-start"
                />

                {/* <CardItem
                  title="T.M.D"
                  text={'00:00:00'}
                  icon="far fa-hourglass"
                /> */}
              </Col>
              {notAnsweredData && (
                <Col md="6" xl="9">
                  <Card>
                    <CardHeader>
                      <h5 className="h3 mb-0">Contatos em espera por fila</h5>
                      <h6 className="surtitle">POR: {rangeDate.label}</h6>
                    </CardHeader>
                    <CardBody>
                      <div className="chart">
                        <Bar
                          data={notAnsweredData.data}
                          options={barChartConfig(false).options}
                          className="chart-canvas"
                          id="chart-bars"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
          <Container fluid>
            {closedData && (
              <Row>
                <Col xl="12">
                  <Card>
                    <CardHeader>
                      <h5 className="h3 mb-0">
                        Conversas encerradas por atendente
                      </h5>
                      <h6 className="surtitle">POR: {rangeDate.label}</h6>
                    </CardHeader>
                    <CardBody>
                      <div className="chart">
                        <Bar
                          data={closedData.data}
                          options={barChartConfig(false).options}
                          className="chart-canvas"
                          id="chart-bars"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}

            <Row>
              {inAttendanceData && (
                <Col xl="6">
                  <Card>
                    <CardHeader>
                      <h5 className="h3 mb-0">Conversas em Atendimento</h5>
                      <h6 className="surtitle">
                        <br />
                      </h6>
                    </CardHeader>
                    <CardBody>
                      <div className="chart">
                        <Doughnut
                          data={inAttendanceData.data}
                          options={ringChart.options}
                          className="chart-canvas"
                          id="chart-doughnut"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}

              {totalData && (
                <Col xl="6">
                  <Card>
                    <CardHeader>
                      <h5 className="h3 mb-0">Conversas por Canal</h5>
                      <h6 className="surtitle">POR: {rangeDate.label}</h6>
                    </CardHeader>
                    <CardBody>
                      <div className="chart">
                        <Doughnut
                          data={totalData.data}
                          options={ringChart.options}
                          className="chart-canvas"
                          id="chart-doughnut"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </Container>
        </>
      )}
    </>
  );
};

export default Dashboard;
