import React from 'react';
import { Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import { Line } from 'react-chartjs-2';
import { calculateData } from '../../../../assets/CalculateAndFormatedData';

export const TotalProtocolsPerDay = ({ protocolsData }) => {
  const { calculateTotalProtocolsPerMonth } = calculateData();
  const formattedDataProtocols = calculateTotalProtocolsPerMonth(
    protocolsData.totalProtocolsPerDay
  );

  return (
    <Row>
      <Col xl="12">
        <Card>
          <CardHeader>
            <h6 className="surtitle">Visão geral</h6>
            <h5 className="h3 mb-0">Protocolos por dia</h5>
          </CardHeader>
          <CardBody>
            <div className="chart">
              <Line
                data={formattedDataProtocols.data}
                className="chart-canvas"
                id="chart-bars"
              />
            </div>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};
