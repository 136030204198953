import React from 'react';
import { AttachmentWrapItem, ListFileAttachmentsWrapper } from './styled';

import { MdDeleteForever } from 'react-icons/md';
import { FileAttachments } from './FileAttachments';

export const ListFileAttachments = ({
  width = '80px',
  height = '80px',
  setValue,
  setMessageError = () => {}
}) => {
  const files = new FileAttachments();

  const [loading, setLoading] = React.useState(false);
  return (
    <>
      {!loading && files && files.attachments && files.attachments.length > 0 && (
        <ListFileAttachmentsWrapper>
          {files.getAttachments().map((att, index) => (
            <AttachmentWrapItem
              key={index}
              title={att?.name}
              className="attachment-name-container"
            >
              <img
                src={att.data}
                width={width}
                height={height}
                alt={att?.name}
                style={{
                  display: 'inline-block',
                  marginRight: '0.5rem',
                  borderRadius: '4px'
                }}
              />
              <p style={{ fontSize: '11pt' }}>{att?.name || '(Sem nome)'}</p>
              <MdDeleteForever
                type="button"
                color="#450a0a"
                size={20}
                onClick={() => {
                  setLoading(true);
                  files.removeAttachment(att, () => {
                    if (files.attachmentsSubmited.length === 0)
                      setMessageError();
                  });
                  setValue('files', files.attachmentsSubmited);
                  setLoading(false);
                }}
                className="delete-icon-attachment"
              />
            </AttachmentWrapItem>
          ))}
        </ListFileAttachmentsWrapper>
      )}
    </>
  );
};
