import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, Form, Row, Col, Input } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';

const WhisperModal = ({ name, tooltipTitle = '', id }) => {
  const { socketConversation } = useConversationConnectionSocketForMonitoring();
  const { control, handleSubmit, errors, reset } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const prevIdRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      prevIdRef.current = id;
    }
  }, [isOpen]);

  const onSubmit = async (data) => {
    try {
      const formatData = {
        escrita_por: localStorage.getItem('name'),
        texto: data.observacao,
        cliente_ou_atendente: 'admin',
        response_type: 'whisper'
      };
      toast.success('Sussurro enviado com sucesso!', {
        autoClose: 3000,
        closeOnClick: true
      });
      socketConversation.emit('adm_enviar_sussurro', {
        idDaConversa: prevIdRef.current,
        mensagem: {
          ...formatData
        }
      });
      reset();
      toggleModal();
    } catch (err) {
      console.log('error', err);
      const { data } = err.response;
      toast.error(data.message || 'Erro ao enviar sussuro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <Button
        size="sm"
        color="info"
        title={tooltipTitle}
        onClick={toggleModal}
        // disabled={true}
      >
        <i className="fas fa-user-secret"></i>
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {name}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-20px' }} />
          <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Col sm="12">
                <label className="form-control-label">Observação*</label>
                <Controller
                  as={Input}
                  control={control}
                  name="observacao"
                  type="textarea"
                  rows="5"
                  cols="33"
                  rules={{ required: 'Campo obrigatório' }}
                  defaultValue=""
                />
                <ErrorMessage
                  errors={errors}
                  name="observacao"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>
            <Button
              color="danger"
              data-dismiss="modal"
              type="button"
              onClick={toggleModal}
              style={buttonStyle(systemConfiguration.cancelation_button_color)}
            >
              Fechar
            </Button>

            <Button
              color="primary"
              type="submit"
              style={buttonStyle(systemConfiguration.confirmation_button_color)}
            >
              Enviar
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default WhisperModal;
