import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useSelector } from 'react-redux';
import Chart from 'chart.js';
import ReceivedCallService from '../../Report/Callcenter/ReceivedCall/service';
import IncomingCallService from '../../Report/Callcenter/CallMade/service';
import ProtocoloService from '../../Protocol/service';
import ChatService from '../../Report/Chat/Conversation/service';
import ChatDashboardService from '../Chat/service';
import CloseGraphic from './components/CloseGraphic';
import IncomingGraphic from './components/IncomingGraphic';
import OutgoingGraphic from './components/OutgoingGraphic';
import ProtocolsByAttendantsGraphic from './components/ProtocolsByAttendantsGraphic';
import ConversationsByQueueGraphic from './components/ConversationsByQueueGraphic';
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import { format, endOfMonth, formatISO, parse } from 'date-fns';
import { calculateData } from './components/assets/calculateData';
import { Container, Row, Card, CardHeader, CardBody, Col } from 'reactstrap';
import { chartOptions, parseOptions } from 'variables/charts.js';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import { ORDERBYGRAPHICS, SELECTMONTH } from './components/assets/constants';
import { range, now } from './components/assets';
import ReactLoader from 'components/Loader';

if (window.Chart) {
  parseOptions(Chart, chartOptions());
}

const {
  calculateDataReceivedCall,
  calculateDataIncomingCallService,
  calculateChatDashboardService,
  calculateProtocolByAttendants,
  calculteConversationByQueue
} = calculateData();

const Dashboard = () => {
  const { control, setValue, watch } = useForm();
  const [outgoingData, setOutgoingData] = useState(null);
  const [incomingData, setIncomingData] = useState(null);
  const [rangeDate, setRangeDate] = useState(null);
  const [closedData, setClosedData] = useState(null);
  const [protocolData, setProtocolData] = useState(null);
  const [conversationByQueue, setConversationByQueue] = useState(null);
  const [loading, setLoading] = useState(false);

  const orderByGraphics = watch('orderByGraphics');
  const selectMonth = watch('selectMonth', now.getMonth() + 1);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const primary_color = systemConfiguration.primary_color;

  function formatString(dateString) {
    var parsedDate = parse(dateString, 'MM-dd-yyyy HH:mm:ss', new Date());

    if (!isNaN(parsedDate)) {
      var isoString = formatISO(parsedDate);
      return isoString;
    } else {
      console.error('Invalid date string:', dateString);
      return null;
    }
  }
  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      setRangeDate({ ...range, dateEnd: format(now, 'LL-dd-yyyy HH:mm:ss') });
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    if (rangeDate) {
      ReceivedCallService.getAllReportsType('detailedsynthetic', 1, {
        dateBegin: rangeDate.dateBegin,
        dateEnd: rangeDate.dateEnd,
        disable: false,
        groupby: 'fila'
      })
        .then((response) => {
          const getFormatData = calculateDataReceivedCall(response);
          setOutgoingData(getFormatData);
          setLoading(false);
        })
        .catch((error) => {
          console.log('error receivedCall', error);
        });
    }
  }, [rangeDate]);

  useEffect(() => {
    if (rangeDate) {
      IncomingCallService.getAllReportsType('detailedsynthetic', 1, {
        dateBegin: rangeDate.dateBegin,
        dateEnd: rangeDate.dateEnd,
        disable: false,
        groupby: 'fila'
      })
        .then((response) => {
          const getFormatData = calculateDataIncomingCallService(response);
          setIncomingData(getFormatData);
          setLoading(false);
        })
        .catch((error) => {
          console.log('error IncomingCallService', error);
        });
    }
  }, [rangeDate]);

  useEffect(() => {
    if (rangeDate) {
      ChatDashboardService.getClosed({
        start: new Date(formatString(rangeDate.dateBegin)),
        end: new Date(formatString(rangeDate.dateEnd))
      })
        .then((res) => {
          const getFormatData = calculateChatDashboardService(
            res,
            primary_color
          );
          setClosedData(getFormatData);
          setLoading(false);
        })
        .catch((err) => {
          console.log('error ChatDashboard ', err);
        });
    }
  }, [rangeDate, primary_color]);

  useEffect(() => {
    if (rangeDate) {
      ProtocoloService.getCountProtocolByUser({
        dateBegin: format(
          new Date(formatString(rangeDate.dateBegin)),
          'yyyy-LL-dd'
        ),
        dateEnd: format(new Date(formatString(rangeDate.dateEnd)), 'yyyy-LL-dd')
      })
        .then((response) => {
          const getFormatData = calculateProtocolByAttendants(
            response.data,
            orderByGraphics
          );
          setProtocolData(getFormatData);
          setLoading(false);
        })
        .catch((error) => {
          console.log('error protocol', error);
        });
    }
  }, [rangeDate, orderByGraphics]);

  useEffect(() => {
    if (rangeDate) {
      ChatService.getSynteticReport('filtrado', {
        dataInicial: format(
          new Date(formatString(rangeDate.dateBegin)),
          'yyyy-LL-dd'
        ),
        dataFinal: format(
          new Date(formatString(rangeDate.dateEnd)),
          'yyyy-LL-dd'
        ),
        group: 'fila'
      })
        .then((response) => {
          if (response.data.conversasFiltradas.length > 0) {
            const getFormatData = calculteConversationByQueue(
              response.data,
              orderByGraphics
            );
            setConversationByQueue(getFormatData);
            setLoading(false);
          } else {
            setConversationByQueue([]);
          }
        })
        .catch((error) => {
          console.log('error chat by queue', error);
        });
    }
  }, [rangeDate, orderByGraphics]);

  useEffect(() => {
    if (selectMonth) {
      const firstDayMonthSelected = new Date(
        now.getFullYear(),
        selectMonth - 1,
        1
      );

      if (isNaN(firstDayMonthSelected) === false) {
        const lastDayMonthSelected = endOfMonth(firstDayMonthSelected);

        setRangeDate({
          dateBegin: format(firstDayMonthSelected, 'LL-dd-yyyy HH:mm:ss'),
          dateEnd: format(lastDayMonthSelected, 'LL-dd-yyyy HH:mm:ss')
        });
      }
    }
  }, [selectMonth]);

  return (
    <>
      <AlternativeHeader />
      {verifyRoles(['DASHBOARD']) ? (
        <>
          {loading && (
            <Row className="justify-content-md-center mt-4">
              <ReactLoader />
            </Row>
          )}
          {!loading && (
            <Container className="mt--6" fluid>
              <Card>
                <CardHeader>
                  <h6 className="surtitle">Visão Geral</h6>
                  <h5 className="h3 mb-0">Ordenar gráficos em forma</h5>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="6">
                      <Controller
                        render={(props) => (
                          <FlexSelect
                            isClearable={true}
                            dataOptions={ORDERBYGRAPHICS}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            value={props.value}
                            valueController={setValue}
                            fieldName="orderByGraphics"
                            labelName="label"
                            valueName="value"
                          />
                        )}
                        control={control}
                        name="orderByGraphics"
                        defaultValue={'DESC'}
                      />
                    </Col>
                    <Col md="6">
                      <Controller
                        render={(props) => (
                          <FlexSelect
                            isClearable={false}
                            dataOptions={SELECTMONTH}
                            isMulti={false}
                            closeMenuOnSelect={true}
                            value={props.value}
                            valueController={setValue}
                            fieldName="selectMonth"
                            labelName="label"
                            valueName="value"
                          />
                        )}
                        control={control}
                        name="selectMonth"
                        defaultValue={now.getMonth() + 1}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              {protocolData && (
                <ProtocolsByAttendantsGraphic
                  filteredLabels={protocolData.arrayLabels}
                  filteredData={protocolData.arrayData}
                  filteredColors={protocolData.arrayColors}
                  count={protocolData.totalCount}
                />
              )}
              <Row>
                {outgoingData && (
                  <OutgoingGraphic outgoingData={outgoingData} />
                )}
                {incomingData && (
                  <IncomingGraphic incomingData={incomingData} />
                )}
              </Row>
              {conversationByQueue && (
                <ConversationsByQueueGraphic
                  filteredLabels={conversationByQueue.filteredLabels}
                  filteredData={conversationByQueue.filteredData}
                  filteredColors={conversationByQueue.filteredColors}
                  count={conversationByQueue.totalCount}
                />
              )}
              {closedData && <CloseGraphic closedData={closedData} />}
            </Container>
          )}
        </>
      ) : (
        <Container className="mt--6" fluid>
          <Row className="justify-content-md-center mt-4 mb-3">Bem vindo!</Row>
        </Container>
      )}
    </>
  );
};

export default Dashboard;
