import styled from 'styled-components';
import { Form } from 'reactstrap';
import { Button } from 'reactstrap';
export const EmptyAttendance = styled.div`
  padding: 20px;
  height: 100%;

  @media (max-width: 800px) {
    height: 500px;
  }
`;

export const Attendance = styled.div`
  height: 100%;
  background: #fff;
  border-radius: 5px 5px 0 0;

  @media (max-width: 800px) {
    height: 500px;
  }
`;

export const Header = styled.div`
  margin: 10px;
  width: 100%;
  justify-content: space-between;
  height: 50px;
  display: flex;

  .headerTitle {
    margin: auto 0 auto 20px;
  }

  .typing {
    animation: blinker 1s linear infinite;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
  }

  @media (max-width: 500px) {
    flex-direction: column;
    height: 60px;
  }
`;

export const HeaderOptions = styled.div`
  justify-content: space-between;
  display: flex;
  margin: auto 20px auto 0;

  .headerBtn {
    margin: 0 10px;
    cursor: pointer;
    color: black;
    font-size: 20px;
  }
`;

export const Dialog = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100% - 238px);

  @media (max-width: 800px) {
    max-height: 500px;
  }
`;

export const Option = styled.div`
  width: 35px;
  height: 30px;
  margin: 0 5px;
`;

export const OptionButton = styled(Button)`
  width: ${(props) => (props.isDefaultButtonStyle ? '' : '35px')};
  height: ${(props) => (props.isDefaultButtonStyle ? '' : '30px')};
  margin: 0 ${(props) => (props.isDefaultButtonStyle ? '' : '5px')};
`;

export const StyleOption = {
  width: '100%',
  height: '100%',
  padding: 0
};

export const Hr = styled.hr`
  margin-top: -0.1px;
`;

export const Search = styled.div`
  white-space: pre-wrap;
  height: 59px;
  width: 100%;
`;

export const LeftArea = styled.div`
  width: 170px;
  margin: auto 0;

  @media (max-width: 600px) {
    width: 100%;
    margin: 10px 0;
  }
`;

export const RightArea = styled.div`
  width: 130px;
  margin: auto;

  @media (max-width: 600px) {
    width: 100%;
    margin: 10px 0;
  }
`;

export const Bottom = styled.div`
  pointer-events: ${(props) => !props.clientActiveSession && 'none'};
  width: 100%;
  justify-content: center;
  display: flex;
  min-height: 100px;
  background-color: #e8e8e8;

  .bottomBtn {
    margin: 0;
    font-size: 20px;
    margin: 0 10px;
    width: 25px;
  }

  textarea {
    border-color: transparent;
    width: calc(100% - 300px);
    border-radius: 15px;
    padding: 10px 12px;
    margin: auto 0;
    overflow-y: scroll;
    resize: none;
  }

  textarea:focus {
    border-color: transparent;
    box-shadow: none;
  }

  .pickerZone {
    position: absolute;
    margin-left: 50px;
    margin-top: -345px;
    z-index: 99;
  }

  // .emoji-picker-react {

  // }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    textarea {
      width: 90%;
      margin: 0 5%;
    }
  }
`;

export const FormComponent = styled(Form)`
  margin: -1.5rem 0 2rem 0;
  .historyConversation__formContainer {
    display: grid;
    grid-template-columns: ${(props) =>
      props.isMultipleFilters ? '1fr' : '0.8fr 1fr'};
    gap: 0.5rem;
    @media (max-width: 1180px) {
      grid-template-columns: 1fr;
    }
  }
  .historyConversation__formContainerOneRow {
    display: grid;
    gap: 0.5rem;

    grid-template-columns: 1fr 1fr;
  }
`;

export const ButtonFormContainer = styled.div`
  margin-top: 0.5rem;
  display: grid;
  grid-template-columns: 1fr;
`;
/**
 * {} = o que estiver dentro
 * vazio = atendente
 * [{},{}] = os dois
 * [{}] = o que estiver dentro
 *
 * [] = atendente
 * null = atendente
 */

export const InactiveConversation = styled.div`
  width: 100%;
  background-color: #f3f4f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  height: 163px;

  span {
    margin-bottom: 8px;
  }

  button {
    width: 100%;
    max-width: 300px;
  }
`;
