import React from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { Button, Row, Col, Container } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function HeaderBody({ setOpenModalCreateTag }) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const history = useHistory();
  return (
    <div
      className="header header-dark pb-6 content__title content__title--calendar"
      style={{ background: systemConfiguration.primary_color }}
    >
      <Container fluid>
        <div className="header-body">
          <Row className="align-items-center py-4">
            <Col lg="6" xs="7">
              <Button
                size="sm"
                color={systemConfiguration.primary_color}
                title="Volta para a pagina de configurações"
                onClick={() => history.push(`/admin/configuration`)}
              >
                <BiArrowBack size="35px" color="white" />
              </Button>
            </Col>
            <Col className="mt-3 mt-md-0 text-md-right" lg="6" xs="5">
              <Button onClick={() => setOpenModalCreateTag(true)}>
                {' '}
                Criar{' '}
              </Button>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
}
