export function maskPhone(valuePhone) {
  if (!valuePhone) {
    return '';
  }
  let phoneFormated = valuePhone.replace(/\D/g, '');
  phoneFormated = phoneFormated.replace(/^0/, '');
  if (phoneFormated.length > 10) {
    phoneFormated = phoneFormated.replace(
      /^(\d\d)(\d{2})(\d{4})(\d{4}).*/,
      '+$1 $2 $3-$4'
    );
  } else if (phoneFormated.length > 5) {
    phoneFormated = phoneFormated.replace(
      /^(\d\d)(\d{4})(\d{0,4}).*/,
      '($1)$2-$3'
    );
  } else if (phoneFormated.length > 2) {
    phoneFormated = phoneFormated.replace(/^(\d\d)(\d{0,5})/, '($1) $2');
  } else {
    phoneFormated = phoneFormated.replace(/^(\d*)/, '($1');
  }
  return phoneFormated;
}
