import ServiceCRUD from '../../../../../services/ServiceCRUDFLEXCHAT';
import { apiChat } from '../../../../../http';

class ConversationAnalyticsService extends ServiceCRUD {
  constructor() {
    super('relatorio');
  }

  getConversationAnalytics(queryParams = {}, pagina) {
    return apiChat.get(`${this.path}/feat-conversation-analytics`, {
      params: {
        pagina: pagina,
        ...queryParams
      }
    });
  }

  getIntentions(queryParams) {
    return apiChat.get(`${this.path}/conversation-analytics-intentions`, {
      params: {
        ...queryParams
      }
    });
  }
}

export default new ConversationAnalyticsService();
