import React, { useEffect, useState } from 'react';

import {
  Container,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  InputGroupText
} from 'reactstrap';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from '../../../components/Pagination';
import DdrService from '../service';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import ModalConfirmation from '../../../components/Modal/ConfirmationModal';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const SectorList = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const [ddr, setDdr] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [seachSector, setSeachSector] = useState();
  const [idResource, setidResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);

  useEffect(() => {
    getDdr();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDdr = (selectedPage = 1) => {
    DdrService.getAll(selectedPage, 10)
      .then((res) => {
        const { data, totalRegisters } = res.data;
        const newActivePage = selectedPage || activePage;
        setDdr(data);
        setQuantity(totalRegisters);
        setActivePage(newActivePage);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Não foi possivel listar as DDR', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const handlePageChange = (activePage) => {
    if (seachSector) {
      getDdrSeach(activePage);
    } else {
      getDdr(activePage);
    }
  };

  function getDdrSeach(event, selectedPage = 1) {
    setSeachSector(event.target.value);
    if (seachSector) {
      DdrService.getAllSeach(selectedPage, 10, event.target.value, false)
        .then((res) => {
          const { data, totalRegisters } = res.data;
          const newActivePage = selectedPage || activePage;
          setDdr(data);
          setQuantity(totalRegisters);
          setActivePage(newActivePage);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Não foi possivel listar os setores', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }

  function deleteDdr(id) {
    DdrService.delete(id)
      .then(() => {
        toast.success('Ddr excluido com sucesso', {
          autoClose: 3000,
          closeOnClick: true
        });
        getDdr();
      })
      .catch(() => {
        toast.error('Ocoreu um erro!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        getDdr();
      });
  }

  function handleUpdate(id) {
    history.push(`ddr/form/${id}`);
  }

  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/ddr/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">DDRs</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="11">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="1234"
                          type="text"
                          onChange={(event) => getDdrSeach(event)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={ddr}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'ddr',
                      text: 'Ddr',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'description',
                      text: 'Descrição',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },

                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            size="sm"
                            color="info"
                            onClick={() => handleUpdate(row.id)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    },

                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() => controlDelete(row.id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={deleteDdr}
        message={'Tem certeza que deseja excluir a DDR?'}
      />
    </>
  );
};

export default SectorList;
