Date.prototype.isValid = function () {
  // An invalid date object returns NaN for getTime() and NaN is the only
  // object not strictly equal to itself.
  return this.getTime() === this.getTime();
};

export const formatDateForAudioDownloads = (date) => {
  const dateWithoutFormatting = new Date(date);
  if (dateWithoutFormatting.isValid()) {
    return `${dateWithoutFormatting.toLocaleDateString('pt-br')}`;
  } else {
    return null;
  }
};
