/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'components/Pagination';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  Input,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import ReactLoader from 'components/Loader';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_icon_header_style_callback,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import Service from '../service';
import { Chip } from '@material-ui/core/';
import { ChipContainer } from '../styles';

const WatsonList = () => {
  const history = useHistory();

  const [watsonList, setWatsonList] = useState([]);

  const [loading, setLoading] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [showDisabled, setShowDisable] = useState(false);

  const getData = () => {
    Service.getWhatson()
      .then((response) => {
        console.log('response', response);
        setLoading(false);
        setWatsonList(response?.data.data ?? []);
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Erro ao carregar Watson!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetExtensions();
  }, [showDisabled]);

  const handleGetExtensions = (selectedPage = 1, querySearch = '') => {
    // Service.getAll(false, selectedPage, 10, showDisabled, querySearch)
    //   .then((res) => {
    //     const { data, totalRegisters } = res.data;
    //     const newActivePage = selectedPage || activePage;
    //     setCallbacks(data);
    //     setQuantity(totalRegisters);
    //     setActivePage(newActivePage);
    //     setLoading(false);
    //   })
    //   .catch(() => {
    //     toast.error('Não foi possível listar as callbacks', {
    //       autoClose: 3000,
    //       closeOnClick: true
    //     });
    //   });
  };

  const handlePageChange = (activePage) => handleGetExtensions(activePage);

  const toggleDisable = (row) => {
    console.log(`row`, row);
    const dataToSend = { ...row, ativado: !row.ativado };
    console.log('dataToSend: ', dataToSend);
    Service.putCanal('watson', dataToSend)
      .then(() => {
        getData();
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao atualizar o canal');
      });
  };

  const handleUpdate = ({ id }) => {
    history.push(`watson/form/${id}`);
  };

  const handleOnSearch = (event) => {
    const q = event.target.value;
    setTimeout(() => {
      handleGetExtensions(1, q);
    }, 500);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/watson/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Watson</h3>
                </CardHeader>

                <Row className="mt-3 mx-2">
                  <Col md="8">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Busca canal por nome"
                        type="text"
                        defaultValue=""
                        onChange={handleOnSearch}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="4" className="text-right mt-2 mt-md-1">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={showDisabled}
                          onChange={() => setShowDisable(!showDisabled)}
                        />
                        <span>Mostar desabilitados</span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <ToolkitProvider
                  data={watsonList}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'nome_canal',
                      text: 'Canal',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'descricao',
                      text: 'Descrição',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'ativado',
                      text: 'Ativo',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        return (
                          <ChipContainer>
                            {row.ativado === true ? (
                              <Chip
                                className="chipTrue"
                                size="small"
                                label="sim"
                              />
                            ) : (
                              <Chip
                                className="chipFalse"
                                size="small"
                                label="não"
                              />
                            )}
                          </ChipContainer>
                        );
                      }
                    },

                    // {
                    //   dataField: 'Editar',
                    //   style: icon_column_style,
                    //   headerStyle: individual_icon_header_style,
                    //   text: 'Configurações',
                    //   // eslint-disable-next-line react/display-name
                    //   formatter: (_, row) => {
                    //     return (
                    //       <Modal name={row.name}>
                    //         <p>
                    //           <strong>
                    //             Verificar quantidade de agentes livres:
                    //           </strong>
                    //           {row.check_agent ? 'Sim' : 'Não'}
                    //         </p>
                    //         <p>
                    //           <strong>Quantidade máxima de tentativas: </strong>
                    //           {row.attempts}
                    //         </p>
                    //         <p>
                    //           <strong>Intervalo entre tentativas: </strong>
                    //           {row.attempt_interval}
                    //         </p>
                    //         <p>
                    //           <strong>
                    //             Retorna apenas chamadas perdidas no dia:
                    //           </strong>
                    //           {row.only_day ? 'Sim' : 'Não'}
                    //         </p>
                    //       </Modal>
                    //     );
                    //   }
                    // },
                    {
                      dataField: 'disable',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style_callback,
                      text: 'Desabilitar / Habilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        console.log('row: ', row);
                        return (
                          <div
                            onClick={() => {
                              toggleDisable(row);
                            }}
                          >
                            <ToggleSwitch checked={row.ativado} id={row.name} />
                          </div>
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="info"
                            // outline
                            type="button"
                            size="sm"
                            disabled={row.disable}
                            onClick={() => handleUpdate(row)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default WatsonList;
