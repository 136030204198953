import React, { useContext, useEffect, useRef } from 'react';
import { isEqual } from 'lodash';

import { brazilian } from 'assets/translator/pt-br';
import { statusColors } from 'views/Monitoring/Queue/MonitoringScreen/utils/state_colors';

import { getAgentStatus } from '../../../../services/getAgentStatus';
import { AgentCtx } from '../../../../contexts/AgentCtx';
import { useAgentDashboardEvents } from '../../hooks/useAgentDashboardEvents';
import Timer from './components/Timer';

import { StyledCard, StyledBadge, CardBody, CardText } from './styled';

const AgentCard = ({ agent, extension }) => {
  const { queues, handleQueues, socket } = useContext(AgentCtx);

  const { agentSessions, handleAgentSessions } = useAgentDashboardEvents(
    agent,
    extension,
    socket
  );

  const name = useRef(localStorage.getItem('name'));

  const {
    state = 'UNPLACED',
    date,
    caller,
    currentQueue,
    agentInfo,
    pause
  } = agentSessions[0] || {};


  useEffect(() => {
    (async () => {
      const sessions = await getAgentStatus(agent);
      handleAgentSessions(
        sessions.map((session) => ({
          pause: session.pause,
          state: session.action,
          extension: session.extension,
          caller: null,
          date: session.date,
          is_incoming_call: null,
          queue: session.queue,
          currentQueue: null
        }))
      );
      handleQueues(sessions.map(({ queue }) => queue));
    })();
  }, [agent, handleAgentSessions, handleQueues]);

  useEffect(() => {
    const incomingQueues = agentSessions.map(({ queue }) => queue);

    if (isEqual(incomingQueues, queues)) return;

    handleQueues(incomingQueues);
  }, [agentSessions]);

  return (
    <StyledCard>
      <div className="d-flex">
        <span className="text-truncate h3 mb-0 font-weight-bold">
          {name.current}
        </span>

        <div className="ml-3">
          <StyledBadge backgroundColor={statusColors[state]}>
            {`${brazilian.AGENT_STATUS[state]} ${
              pause ? `(${pause?.name})` : ''
            }`}
          </StyledBadge>
        </div>
      </div>
      <CardBody>
        <div>
          <h5 className="mb-1 font-weight-normal">Ramal</h5>
          <CardText>{extension}</CardText>
        </div>
        <div>
          <h5 className="mb-1 font-weight-normal">Cronômetro</h5>
          <Timer
            date={date}
            render={(upTime) => <CardText>{upTime}</CardText>}
          />
        </div>
        <div>
          <h5 className="mb-1 font-weight-normal">Fila</h5>
          <CardText>{(caller && currentQueue) || ''}</CardText>
        </div>
        <div>
          <h5 className="mb-1 font-weight-normal">Bina</h5>
          <CardText>{caller || ''}</CardText>
        </div>
      </CardBody>
    </StyledCard>
  );
};

export default AgentCard;
