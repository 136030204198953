import React from 'react';
import { Button as ButtonReactstrap } from 'reactstrap';

export function ButtonFilter({ color, title, onClick, label, isActive }) {
  return (
    <ButtonReactstrap
      color={color}
      outline
      size="sm"
      type="button"
      active={isActive}
      title={title}
      onClick={onClick}
      style={{
        margin: '0rem'
      }}
    >
      {label}
    </ButtonReactstrap>
  );
}
