import { startOfToday, endOfToday, format } from 'date-fns';

const dateBegin = format(startOfToday(), 'LL-dd-yyyy HH:mm:ss');
const dateEnd = format(endOfToday(), 'LL-dd-yyyy HH:mm:ss');
const timeStart = '000000';
const timeEnd = '235959';

const queryParamsDefault = {
  disable: false,
  allCalls: false,
  maxWaitTime: 10,
  dateBegin,
  dateEnd
};

export const TIME_INTERVAL = 5 * 60 * 1000;

export const REPORT_TYPE = {
  ANALYTICAL: 'analytical',
  SYNTHETIC: 'synthetic',
  DETAILED_SYNTHETIC: 'detailedsynthetic'
};

export const DEFAULT_PAGE = 1;

export const DEFAULT_PAGE_SIZE = 10;

export const QUERY_PARAMS_ANALYTICAL = {
  ...queryParamsDefault,
  timeStart,
  timeEnd,
  reportType: 1
};

export const QUERY_PARAMS_SYNTHETIC = {
  ...queryParamsDefault,
  timeStart,
  timeEnd,
  reportType: 2
};

export const QUERY_PARAMS_DETAILED_SYNTHETIC = {
  ...queryParamsDefault,
  hourBegin: timeStart,
  hourEnd: timeEnd
};
