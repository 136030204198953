export function chatConfigEmail(email) {
  return {
    type: '@ACTION_CHAT_CONFIG_EMAIL',
    payload: email
  };
}

export function chatConfigEmailEncerramento(encerramento) {
  return {
    type: '@ACTION_CHAT_CONFIG_EMAIL_ENCERRA',
    payload: encerramento
  };
}

export function chatConfigEmailForaDeHora(foraDeHora) {
  return {
    type: '@ACTION_CHAT_CONFIG_EMAIL_FORA_DE_HORA',
    payload: foraDeHora
  };
}
