import { useState, useCallback } from 'react';

import {
  defaultLimit,
  calculateOffsetFromPageAndLimit
} from 'views/CRM/shared/utils/pagination';

import LeadStageService from '../service';

function useListLeadStages() {
  const [leadStages, setLeadStages] = useState(null);
  const [totalLeadStages, setTotalLeadStages] = useState(0);
  const [isLoadingLeadStages, setIsLoadingLeadStages] = useState(false);

  const getLeadStages = useCallback(async (page, params = {}) => {
    setIsLoadingLeadStages(true);

    const offset = page
      ? calculateOffsetFromPageAndLimit(page, defaultLimit)
      : 0;

    LeadStageService.list(params, { offset, limit: page && defaultLimit })
      .then((response) => {
        setLeadStages(response.data.data);
        setTotalLeadStages(response.data.totalRegisters);
      })
      .catch(() => {
        setLeadStages(null);
      })
      .finally(() => {
        setIsLoadingLeadStages(false);
      });
  }, []);

  return {
    leadStages,
    totalLeadStages,
    isLoadingLeadStages,
    getLeadStages
  };
}

export { useListLeadStages };
