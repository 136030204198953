import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Container, Row } from 'reactstrap';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import Loader from 'components/Loader';
import ProtocolList from './components/ProtocolList';
import { useRecordTypesByUser } from '../../RecordType/hooks/useRecordTypesByUser';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';

function ProtocolListIndex() {
  const [selectedRecordType, setSelectedRecordType] = useState();
  const { recordTypes, isLoadingRecordTypes, getRecordTypesByUser } =
    useRecordTypesByUser(entitiesName.PROTOCOL);
  const [hasChangedRecordType, setHasChangedRecordType] = useState(false);

  useEffect(() => {
    getRecordTypesByUser();
  }, [getRecordTypesByUser]);

  useEffect(() => {
    if (recordTypes && recordTypes.length > 0) {
      setSelectedRecordType({
        label: recordTypes[0].name,
        value: recordTypes[0].id
      });
    }
  }, [recordTypes]);

  const handleSelectedRecordTypeChange = (data) => {
    if (!data) {
      setSelectedRecordType(null);
      setHasChangedRecordType(false);
    }
    setSelectedRecordType(data);
    setHasChangedRecordType(true);
  };

  const renderWithBaseLayout = (children) => {
    return (
      <>
        <SimpleHeader
          name="Form validation"
          parentName="Forms"
          showBackArrow="not"
        />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <div className="d-flex flex-row justify-content-between">
                      <h3 className="mb-0">Protocolos</h3>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-2">{children}</CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  };

  const render = () => {
    if (isLoadingRecordTypes) {
      return renderWithBaseLayout(
        <Row className="justify-content-md-center">
          <Loader />
        </Row>
      );
    }

    if (!isLoadingRecordTypes && !recordTypes?.length) {
      return renderWithBaseLayout(
        <div>
          <span className="small">
            Você não tem acesso a nenhum tipo de protocolo.
          </span>
        </div>
      );
    }

    return (
      <ProtocolList
        recordTypes={recordTypes}
        selectedRecordType={selectedRecordType}
        handleSelectedRecordTypeChange={handleSelectedRecordTypeChange}
        hasChangedRecordType={hasChangedRecordType}
        setHasChangedRecordType={setHasChangedRecordType}
      />
    );
  };

  return render();
}

export default ProtocolListIndex;
