import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

const GraphicOpportunitiesByListCustomFields = ({
  opportunitiesCountByListCustomFields
}) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const filteredOptions = opportunitiesCountByListCustomFields.options.filter(
    (item) => item.quantity > 0
  );
  const filteredOpportunitiesCountByListCustomFields = {
    ...opportunitiesCountByListCustomFields,
    options: filteredOptions
  };

  return (
    <div
      className="chart mt-5"
      style={{
        width: '90%'
      }}
    >
      <Row>
        <Col md="12">
          <h2 className="text-muted mt--3">
            {opportunitiesCountByListCustomFields.name}
          </h2>
        </Col>
      </Row>
      <Bar
        data={{
          labels: filteredOpportunitiesCountByListCustomFields.options.map(
            (item) => `${item.value} (${item.quantity})`
          ),
          datasets: [
            {
              data: filteredOpportunitiesCountByListCustomFields.options.map(
                (item) => item.quantity
              ),
              backgroundColor: systemConfiguration?.primary_color,
              maxBarThickness: 10
            }
          ]
        }}
        options={{
          showLines: true,
          scales: {
            yAxes: [
              {
                gridLines: {
                  color: '#e9ecef',
                  zeroLineColor: '#e9ecef'
                },
                ticks: {
                  callback: function (value) {
                    if (!(value % 10)) {
                      return value;
                    }
                  }
                }
              }
            ],
            xAxes: [
              {
                maxBarThickness: 100
              }
            ]
          },
          tooltips: {
            callbacks: {
              label: function (item, data) {
                var label = data.datasets[item.datasetIndex].label || '';
                if (label) {
                  label += ': ';
                }
                label += Math.round(item.yLabel * 100) / 100;
                return label;
              }
            }
          }
        }}
      />
    </div>
  );
};

export default GraphicOpportunitiesByListCustomFields;
