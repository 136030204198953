import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  UncontrolledDropdown
} from 'reactstrap';
import { BiBot, BiUserCircle, BiX, BiTired } from 'react-icons/bi';
import { HiChat, HiChatAlt2, HiXCircle, HiOutlineUsers } from 'react-icons/hi';
import { Title, TotalAttendanceData } from './styled';
const MultiButtonsHeader = (props) => {
  const [dataToDisplayed, setDataToDisplayed] = useState(props.dataToShow);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const primary_color = systemConfiguration.primary_color;

  useEffect(() => {
    const object = { ...props.dataToShow };
    const arrayOfKeys = Object.keys(object);
    arrayOfKeys.forEach((key) => {
      if (object[key] === 0) object[key] = '0';
    });
    setDataToDisplayed(object);
  }, [props.dataToShow]);

  return (
    <>
      <div
        className="header header-dark  pb-6 content__title content__title--calendar"
        style={{ background: primary_color }}
      >
        <Container fluid>
          <div className="header-body">
            <Title>Conversas</Title>
            <Row>
              <Col md="6" xl="3">
                <Card className="border-10" title="Total de Conversas">
                  <CardBody>
                    <Row>
                      <Col>
                        <CardTitle
                          title="teste"
                          tag="h6"
                          className="text-uppercase font-weight-bold mb-0 text-black h6"
                        >
                          em aberto
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataToDisplayed.qtdTotalConversations}
                        </TotalAttendanceData>
                      </Col>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <HiChatAlt2 size={35} color={primary_color} />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card
                  className="border-10"
                  title="Total de conversas não atendidas"
                >
                  <CardBody>
                    <Row>
                      <Col>
                        <CardTitle
                          tag="h6"
                          className="text-uppercase font-weight-bold mb-0 text-black h6"
                        >
                          Não Atendidas
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataToDisplayed.qtdTotalMissed} /{' '}
                          {dataToDisplayed.qtdTotalConversations}
                        </TotalAttendanceData>
                      </Col>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <HiXCircle size={35} color={primary_color} />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card
                  className="border-10"
                  title="Total de conversas em atendimento"
                >
                  <CardBody>
                    <Row>
                      <Col>
                        <CardTitle
                          tag="h6"
                          className="text-uppercase font-weight-bold mb-0 text-black h6"
                        >
                          Em Atendimento
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataToDisplayed.qtdTotalInAttendance} /{' '}
                          {dataToDisplayed.qtdTotalConversations}
                        </TotalAttendanceData>
                      </Col>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <HiChat size={35} color={primary_color} />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card
                  className="border-10"
                  title="Total de conversas Atendimento bot"
                >
                  <CardBody>
                    <Row>
                      <Col>
                        <CardTitle
                          tag="h6"
                          className="text-uppercase font-weight-bold mb-0 text-black h6"
                        >
                          Atendimento Bot
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataToDisplayed.qtdTotalBot} /{' '}
                          {dataToDisplayed.qtdTotalInAttendance}
                        </TotalAttendanceData>
                      </Col>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <BiBot size={35} color={primary_color} />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md="6" xl="3">
                <Card
                  className="border-10"
                  title="Total de conversas encerradas"
                >
                  <CardBody>
                    <Row>
                      <Col>
                        <CardTitle
                          tag="h6"
                          className="text-uppercase font-weight-bold mb-0 text-black h6"
                        >
                          Encerradas Hoje
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataToDisplayed.qtdEnded}
                        </TotalAttendanceData>
                      </Col>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <BiX size={35} color={primary_color} />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card
                  className="border-10"
                  title="Total de conversas abandonadas"
                >
                  <CardBody>
                    <Row>
                      <Col>
                        <CardTitle
                          tag="h6"
                          className="text-uppercase font-weight-bold mb-0 text-black h6"
                        >
                          Abandonadas Hoje
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataToDisplayed.qtdAbandonadas}
                        </TotalAttendanceData>
                      </Col>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <BiTired size={35} color={primary_color} />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card className="border-10" title="Total de usuários logados">
                  <CardBody>
                    <Row>
                      <Col>
                        <CardTitle
                          tag="h6"
                          className="text-uppercase font-weight-bold mb-0 text-black h6"
                        >
                          Usuários Logados
                        </CardTitle>
                        <TotalAttendanceData>
                          {props.qtdLoggedAttendants}
                        </TotalAttendanceData>
                      </Col>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <HiOutlineUsers size={35} color={primary_color} />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md="6" xl="3">
                <Card
                  className="border-10"
                  title="Total de conversas atendimento humano"
                >
                  <CardBody>
                    <Row>
                      <Col>
                        <CardTitle
                          tag="h6"
                          className="text-uppercase font-weight-bold mb-0 text-black h6"
                        >
                          Atendimento Humano
                        </CardTitle>
                        <TotalAttendanceData>
                          {dataToDisplayed.qtdTotalHuman} /{' '}
                          {dataToDisplayed.qtdTotalInAttendance}
                        </TotalAttendanceData>
                      </Col>
                      <Col className="col-auto">
                        <UncontrolledDropdown>
                          <BiUserCircle size={35} color={primary_color} />
                        </UncontrolledDropdown>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default MultiButtonsHeader;
