export const fuctions = () => {
  const formatDataUpdateProtocol = (data) => {
    for (let [key] of Object.entries(data)) {
      if (
        key === 'telephone' ||
        key === 'client_id' ||
        key === 'service_channel' ||
        key === 'reason_id' ||
        key === 'current_responsible' ||
        key === 'current_status' ||
        key === 'subreason_id'
      ) {
        delete data[key];
      }
    }
    return data;
  };

  const formatOptionsToSelect = (options) => {
    const formattedOptions = options.map((option) => {
      const options = {};
      options.label = option;
      options.value = option;
      return options;
    });

    return formattedOptions;
  };

  const dateRules = (validations) => {
    let rules = {
      required: validations ? 'Campo obrigatório' : false,
      pattern: {
        value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
        message: 'Data inválida! (dd/MM/aaaa)'
      }
    };
    return rules;
  };

  return {
    formatDataUpdateProtocol,
    formatOptionsToSelect,
    dateRules
  };
};
