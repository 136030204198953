import ServiceCRUD from '../../../services/ServiceCRUD';
import { apiCase as http } from '../../../http';

class ClientService extends ServiceCRUD {
  constructor() {
    super('cliente');
  }

  getAllFilter(entityName, page, limit, filters = {}) {
    const arrayFilters = Object.entries(filters);

    let query = `/entity/${entityName}/select?page=${page}&size=${limit}`;

    arrayFilters.forEach((el) => {
      query += `&${el[0]}=${el[1]}`;
    });

    return http.get(query);
  }

  wideSearch(params) {
    params = { ...params, requestLimit: 50 };
    return http.get('/client/wideSearch', { params });
  }
}

export default new ClientService();
