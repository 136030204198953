import React from 'react';

import DialogTreeComponent from '../index';
import { useDialogTree } from '../context';

const TreeList = () => {
  const { treeData } = useDialogTree();
  return <DialogTreeComponent data={treeData} />;
};

export default TreeList;
