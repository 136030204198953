import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import React from 'react';
import {
  individual_column_style,
  individual_header_style
} from 'assets/styles/table_custom_style';
import { toBrazilianFullDate } from 'views/CRM/shared/utils/date';

export const Analytical = ({ data, customFields, reportType }) => {
  const createDynamicColumns = () => {
    const columns = [];

    columns.push(
      {
        dataField: 'name',
        text: 'Nome',
        sort: true,
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'phone',
        text: 'Telefone',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'stageName',
        text: 'Fase do Lead',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'createdAt',
        text: 'Criado em',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (row) => {
          if (row) return toBrazilianFullDate(row);
        }
      },
      {
        dataField: 'createdByName',
        text: 'Criado por',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'responsibleName',
        text: 'Responsável',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'lastUpdatedAt',
        text: 'Última atualização',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (row) => {
          if (row) return toBrazilianFullDate(row);
        }
      },
      {
        dataField: 'convertedAt',
        text: 'Data da conversão',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (row) => {
          if (row) return toBrazilianFullDate(row);
        }
      },
      {
        dataField: 'convertedTime',
        text: 'Tempo conversão',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'monthQuantity',
        text: 'Quantidade de meses',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      }
    );

    (customFields || []).forEach((field) => {
      if (field.label === 'Origem do Lead')
        columns.push({
          dataField: field.name,
          text: field.label,
          style: individual_column_style,
          formatter: (_, row) => {
            return row[`${field.name}`];
          }
        });
    });

    columns.push(
      {
        dataField: reportType === '2' ? 'dateClosedWon' : 'dateClosedLost',
        text: 'Data do Fechamento',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (row) => {
          if (row) return toBrazilianFullDate(row);
        }
      },
      {
        dataField: 'opportunityStageName',
        text: 'Fase da Oportunidade',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'closedTime',
        text: 'Tempo Fechamento',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'monthClosedQuantity',
        text: 'Quantidade de Meses Fechamento',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      },
      {
        dataField: 'opportunityResponsible',
        text: 'Responsável da Oportunidade',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' }
      }
    );

    return [...columns];
  };

  const dynamicFunctionColumns = createDynamicColumns();

  return (
    <ToolkitProvider
      data={data || []}
      keyField="id"
      columns={dynamicFunctionColumns}
    >
      {(props) => (
        <div className="py-4 table-responsive">
          <BootstrapTable
            bootstrap4={true}
            bordered={false}
            classes="align-items-center"
            {...props.baseProps}
          />
        </div>
      )}
    </ToolkitProvider>
  );
};
