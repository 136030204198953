import React from 'react';
import DefaultModal from 'components/Modal/DefaultModal';
import { Row, Col, Form, Input } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ReactButton from 'components/Buttons/ReactButton';
import ContactService from '../service/index';
import { toast } from 'react-toastify';
import TelephoneMask from '../../../../../../../components/Inputs/TelephoneMask';

const CreateContactModal = ({ isModalOpen, onModalToggle, ...props }) => {
  const removeMaskSpecialCharacters = (phone) => {
    const regexToRemoveSpecialCharacters = /[^0-9a-zA-Z:,]+/gm;
    const telephoneWithoutSpecialCharacters = phone.replace(
      regexToRemoveSpecialCharacters,
      ''
    );

    return telephoneWithoutSpecialCharacters;
  };

  const onSubmit = async (data) => {
    try {
      const { name, telephone } = data;

      const telephoneWithoutSpecialCharacters =
        removeMaskSpecialCharacters(telephone);

      const usrLogin = localStorage.getItem('login');
      await ContactService.create({
        name,
        telephone: telephoneWithoutSpecialCharacters,
        usrLogin
      });

      onModalToggle(false);
      toast.success('Contato criado');
    } catch (error) {
      const msg =
        error.response && error.response.data && error.response.data.message;
      toast.error(msg || 'Erro ao criar contato');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const { control, handleSubmit, errors, setValue } = useForm();
  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Criar Contato"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <Row>
          <Col md="10">
            <label>Nome do contato:</label>
            <Controller
              as={Input}
              control={control}
              placeholder="Digite o nome contato"
              name="name"
              rules={{
                required: 'Campo obrigatorio'
              }}
              defaultValue=""
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        </Row>
        <Row style={{ marginTop: 10, marginBottom: 20 }}>
          <Col md="10">
            <label>Telefone do contato:</label>
            <Controller
              render={(props) => (
                <TelephoneMask
                  valueController={setValue}
                  fieldName="telephone"
                  value={props.value}
                  placeholder="(88) 99999-9999"
                />
              )}
              control={control}
              name="telephone"
              defaultValue=""
              rules={{
                required: 'Campo obrigatório',
                validate: (value) => {
                  if (!value) return true;
                  let phoneFormated = value.replace(/\D/g, '');
                  phoneFormated = phoneFormated.replace(/^0/, '');
                  if (phoneFormated.length < 10) {
                    return 'Telefone inválido';
                  }
                  return true;
                }
              }}
            />
            <ErrorMessage
              errors={errors}
              name="telephone"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        </Row>
        <Row>
          <div
            style={{
              flex: 1,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <ReactButton btnColor="confirmation" type="submit">
              Criar
            </ReactButton>
          </div>
        </Row>
      </Form>
    </DefaultModal>
  );
};

export default CreateContactModal;
