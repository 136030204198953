import { apiNotifications as http } from '../http';
//
class ServiceCRUD {
  constructor(path) {
    this.path = path;
  }

  // teste

  getAllNotifications = (size, page, querySearch, login) => {
    return http.get(`${this.path}`, {
      headers: {
        limit: size,
        offset: size * (page - 1)
      },
      params: {
        search: querySearch,
        to: login
      }
    });
  };

  getAllNotificationREAD = (size, page, login) => {
    return http.get(`${this.path}`, {
      headers: {
        limit: size,
        offset: size * (page - 1)
      },
      params: {
        to: login,
        isRead: true
      }
    });
  };
  getNotificationUNREAD = (size, page, login) => {
    return http.get(`${this.path}`, {
      headers: {
        limit: size,
        offset: size * (page - 1)
      },
      params: {
        to: login,
        isRead: false
      }
    });
  };

  markNotificationAsSeen = (ids) => {
    return http.put(`${this.path}/seen`, {
      ids: ids
    });
  };

  markNotificationAsUnseen = (ids) => {
    return http.put(`${this.path}/unseen`, {
      ids: ids
    });
  };

  markNotificationAsRead = (ids) => {
    return http.put(`${this.path}/read`, {
      ids: ids
    });
  };

  markNotificationAsUnread = (ids) => {
    return http.put(`${this.path}/unread`, {
      ids: ids
    });
  };

  // teste

  getAllSearch = (showDisabled, size, page, querySearch, login) => {
    return http.get(`${this.path}/?disabled=${showDisabled}`, {
      headers: {
        limit: size,
        offset: size * (page - 1)
      },
      params: {
        name: querySearch,
        user: login
      }
    });
  };
  getAllSearchSupervisor = (showDisabled, size, page, querySearch) => {
    return http.get(`${this.path}/?disabled=${showDisabled}`, {
      headers: {
        limit: size,
        offset: size * (page - 1)
      },
      params: {
        search: querySearch
      }
    });
  };

  getNotification = (login) => {
    return http.get(`${this.path}`, {
      params: {
        to: login
      }
    });
  };
  get = (id) => {
    return http.get(`${this.path}/${id}`);
  };
  getAll = (params = {}) => {
    return http.get(`${this.path}/`, {
      params: params
    });
  };
  update = (id, data) => {
    return http.put(`${this.path}/${id}`, data);
  };
  delete = (id) => {
    return http.delete(`${this.path}/${id}`);
  };
  put = (id, data) => {
    return http.put(`${this.path}/${id}`, data);
  };
  patch = (id, data) => {
    return http.patch(`${this.path}/${id}`, data);
  };
  patchNotification = (data) => {
    return http.patch(`${this.path}`, data);
  };
  patchShift = (id, approved) => {
    return http.patch(`${this.path}/${approved}/${id}`);
  };
  post = (data) => {
    return http.post(`${this.path}`, data);
  };
}

export default ServiceCRUD;
