import React, { useState } from 'react';
import { Modal, ModalBody, Button } from 'reactstrap';
import { MdTimeline } from 'react-icons/md';
import Timeline from 'components/Conversation/ConversationDetails/components/Timeline';
import { Option, StyleOption } from '../../styled';

const TimelineConversation = ({ name, tooltipTitle = '', timeline = [] }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Option title={tooltipTitle}>
        <Button
          onClick={toggleModal}
          color="link"
          size="sm"
          style={StyleOption}
        >
          <MdTimeline size={20} color="#32325d" />
        </Button>
      </Option>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {name}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />
          <Timeline timelineData={timeline} />
        </ModalBody>
      </Modal>
    </>
  );
};

export default TimelineConversation;
