export const steps = [
  {
    selector: '.needs-validation',
    content: `Olá seja bem vindo ao nosso TourGuiado na parte de reordenação dos campos do protocolo.
      Nessa tela, é possível reordenar os campos do protocolo, basta apenas clicar no campo que deseja mover e arrastar para a posição desejada.
      Isso é muito útil para que você possa deixar os campos mais importantes no topo do protocolo, facilitando assim a visualização e preenchimento dos mesmos.
      Esses campos incluem os campos padrões do protocolo escolhidos e os campos customizados desse tipo de registro específico.
      A posição escolhida aqui será a mesma que aparecerá no protocolo, ou seja, se você colocar o campo de descrição em primeiro lugar, ele será o primeiro campo a ser preenchido no protocolo. 
    `
  },
  {
    selector: '.tour1',
    content: `Para começar, basta apenas clicar no titulo do campo que deseja mover e arrastar para a posição desejada.`
  },
  {
    selector: '.tour2',
    content: `Após isso, basta apenas clicar no botão de salvar e pronto, os campos estarão reordenados.
      Lembre-se, caso você feche essa janela ou saia dessa tela sem salvar, as alterações não serão salvas.
    `
  }
];
