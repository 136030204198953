import AutenticationReportService from 'views/Report/Callcenter/Autentication/Services/Index';

import { REPORT_TYPE, QUERY_PARAMS_ANALYTICAL } from '../utils/consts';

export const getAnalyticalAutenticationReport = async (params, page) => {
  const {
    data: { data }
  } = await AutenticationReportService.getAllReportsType(
    REPORT_TYPE.ANALYTICAL,
    page,
    {
      ...QUERY_PARAMS_ANALYTICAL,
      ...params
    }
  );

  return data;
};
