import { useState, useCallback } from 'react';
import ReasonService from '../../service';

function useListReasonByRecordType(recordTypeId) {
  const [reasons, setReasons] = useState(null);
  const [reasonsQuantity, setReasonsQuantity] = useState(0);
  const [isLoadingReason, setIsLoadingReason] = useState(false);

  const getReasonsByRecordType = useCallback(
    async (params = {}) => {
      setIsLoadingReason(true);

      ReasonService.getByRecordType(recordTypeId, params)
        .then((response) => {
          const reasonData = response.data.content;
          const quantity = response.data.quantity;
          const sortedReasonData = reasonData.sort(function (a, b) {
            let nameA = a.name.toUpperCase();
            let nameB = b.name.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
          setReasons(sortedReasonData);
          setReasonsQuantity(quantity);
        })
        .catch(() => {
          setReasons(null);
        })
        .finally(() => {
          setIsLoadingReason(false);
        });
    },
    [recordTypeId]
  );

  return {
    reasons,
    reasonsQuantity,
    isLoadingReason,
    getReasonsByRecordType
  };
}

export { useListReasonByRecordType };
