import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { Container, Row, Card, CardHeader, CardBody } from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { ProtocolFieldsForm } from './components/ProtocolFieldsForm';

const CustomFieldsConfig = () => {
  const history = useHistory();
  const { state, entityName } = useLocation();

  const isUpdate = !!state;
  const entity =
    (state && state.entityName) || (entityName && entityName.entityName);

  if (!entity) {
    history.push('/admin/fieldsConfig');
    return null;
  }

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/fieldsConfig"
        buttonTitle="Voltar para a pagina de configuração de campos"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">{`Campos Customizados da Entidade ${entity}`}</h3>
                </CardHeader>
                <CardBody>
                  <ProtocolFieldsForm
                    entity={entity}
                    isUpdate={isUpdate}
                    fieldData={state}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CustomFieldsConfig;
