import { useFlexCaseBackEndConnectionSocket } from 'hooks/useSocketFlexCaseBackend';
import { useEffect, useState, useCallback } from 'react';

export const useSocketCallAnswered = () => {
  const { socketFlexCaseBackEnd } = useFlexCaseBackEndConnectionSocket();
  const [answeringCall, setAnsweringCall] = useState({});
  const [forceSaveProtocol, setSaveProtocol] = useState(false);
  const [startCounter, setStartCounter] = useState(false);

  useEffect(() => {
    if (forceSaveProtocol) {
      setSaveProtocol(false);
    }
  }, [forceSaveProtocol]);

  useEffect(() => {
    if (setStartCounter) {
      setSaveProtocol(false);
    }
  }, [setStartCounter]);

  useEffect(() => {
    socketFlexCaseBackEnd.on('client_call_answered', (eventData) => {
      const extension = localStorage.getItem('currentLoggedInExtension');

      const hasExtension = !!extension;
      const isAdifferentExtension = eventData.extension !== extension;
      if (!hasExtension || isAdifferentExtension) return;
      setAnsweringCall(() => eventData);
    });

    socketFlexCaseBackEnd.on('save_protocol_timeout', (eventData) => {
      const extension = localStorage.getItem('currentLoggedInExtension');
      const hasExtension = !!extension;
      const isAdifferentExtension = eventData.extension !== extension;
      if (!hasExtension || isAdifferentExtension) return;
      setSaveProtocol(true);
    });

    socketFlexCaseBackEnd.on('start_protocol_timer', (eventData) => {
      console.log('start_protocol_timer: ');
      const extension = localStorage.getItem('currentLoggedInExtension');

      const hasExtension = !!extension;
      const isAdifferentExtension = eventData.extension !== extension;
      if (!hasExtension || isAdifferentExtension) return;
      setStartCounter(true);
    });
  }, [socketFlexCaseBackEnd]);

  const resetAnsweringCall = useCallback(() => {
    setAnsweringCall({});
  }, []);

  return { answeringCall, resetAnsweringCall, forceSaveProtocol, startCounter };
};
