import React, { useState } from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'reactstrap';
import {
  individual_column_style,
  individual_header_style,
  individual_icon_header_style,
  icon_column_style
} from '../../../../../../../assets/styles/table_custom_style';
import ModalConfirmation from 'components/Modal/ConfirmationModal';
import LeadService from '../../../../services/leadService';
import { toast } from 'react-toastify';
import { toBrazilianFullDate } from 'views/CRM/shared/utils/date';

const CommentsList = ({
  listComments,
  reloadComments,
  handleUpdateComment
}) => {
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [leadCommentId, setLeadCommentId] = useState(null);

  const deleteComment = async (id) => {
    try {
      await LeadService.deleteLeadComments(id);
      const message = 'Comentário excluido com sucesso';
      toast.success(message, {
        autoClose: 3000,
        closeOnClick: true
      });
      await reloadComments();
    } catch (err) {
      toast.error(' Erro ao excluir o comentário', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setOpenModalConfirmation(false);
    }
  };

  const controlDeleteLeadComment = (id) => {
    setLeadCommentId(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <label className="form-control-label" style={{ paddingTop: '25px' }}>
        Comentário(s)
      </label>

      <ToolkitProvider
        data={listComments || []}
        keyField="id"
        columns={[
          {
            dataField: 'content',
            text: 'Descrição',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          {
            dataField: 'created_at',
            text: 'Data de criação',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style,
            formatter: (Row) => {
              const date = new Date(Row);
              return toBrazilianFullDate(date);
            }
          },
          {
            dataField: 'edit',
            style: icon_column_style,
            headerStyle: individual_icon_header_style,
            text: 'Editar',
            // eslint-disable-next-line react/display-name
            formatter: (_, row) => {
              return (
                <Button
                  title={'Editar comentário'}
                  size="sm"
                  color="info"
                  onClick={() => handleUpdateComment(row)}
                >
                  <li className="fas fa-user-edit"></li>
                </Button>
              );
            }
          },
          {
            dataField: 'delete',
            style: icon_column_style,
            headerStyle: individual_icon_header_style,
            text: 'deletar',
            // eslint-disable-next-line react/display-name
            formatter: (_, row) => {
              return (
                <Button
                  title={`Apagar comentário`}
                  size="sm"
                  color="danger"
                  onClick={() => controlDeleteLeadComment(row.id)}
                >
                  <li className="fas fa-trash"></li>
                </Button>
              );
            }
          }
        ]}
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
              classes="align-items-center"
            />
          </div>
        )}
      </ToolkitProvider>

      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={leadCommentId}
        deleteResource={deleteComment}
        message={'Tem certeza que deseja excluir o comentário?'}
      />
    </>
  );
};

export default CommentsList;
