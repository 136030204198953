export const verifyRoles = (roles) => {
  const userRoles = JSON.parse(localStorage.getItem('roles'));

  let show = false;

  roles.forEach((pageRole) => {
    userRoles.forEach((userRole) => {
      if (userRole.role.toUpperCase() === pageRole.toUpperCase()) {
        show = true;
      }
    });
  });

  return show;
};
