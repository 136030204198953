export const historyEventsNames = [
  {
    displayName: 'Atualizado',
    upperName: 'UPDATED'
  },
  {
    displayName: 'Criado',
    upperName: 'CREATED'
  },
  {
    displayName: 'Comentário criado',
    upperName: 'COMMENT_ADDED'
  },
  {
    displayName: 'Comentário atualizado',
    upperName: 'COMMENT_UPDATED'
  },
  {
    displayName: 'Comentário deletado',
    upperName: 'COMMENT_DELETED'
  },
  {
    displayName: 'Mudança de fase',
    upperName: 'STAGE_CHANGED'
  },
  {
    displayName: 'Mudança de responsável',
    upperName: 'RESPONSIBLE_CHANGED'
  },
  {
    displayName: 'Lead convertido',
    upperName: 'CONVERTED'
  },
  {
    displayName: 'Oportunidade convertida',
    upperName: 'OPPORTUNITY_CONVERTED'
  }
];
