import React from 'react';

import { Row, Card } from 'reactstrap';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { TotalRegister } from '../../../../../components/TotalRegister';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';

const DetailedSynthetic = (props) => {
  return (
    <>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            {props.totalRegister >= 0 && (
              <TotalRegister totalRegister={props.totalRegister} />
            )}
            <Card>
              <ToolkitProvider
                data={props.showReports}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'nameCostCenter',
                    text: 'Centro de custo',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'answered',
                    text: 'Chamadas Atendidas',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'abandoned',
                    text: 'Chamadas Abandonadas',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'percentageAnswered',
                    text: 'Chamadas Atendidas(%)',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'percentageAbandoned',
                    text: 'Chamadas Abandonadas(%)',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default DetailedSynthetic;
