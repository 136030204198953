import React, { useCallback, useEffect, useState } from 'react';
import { Row } from 'reactstrap';

import Pagination from 'components/Pagination';
import Loader from 'components/Loader';
import FilterOpportunitiesForm from './components/FilterOpportunitiesForm';
import ListOpportunitiesTable from './components/ListOpportunitiesTable';

import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { useListOpportunities } from '../../../hooks/useListOpportunities';

import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { Kanban } from './components/Kanban';
import opportunityService from 'views/CRM/Opportunity/services/opportunityService';
import { toast } from 'react-toastify';

import { useListOpportunityStagesByRecordType } from 'views/CRM/OpportunityStages/hooks/useListOpportunityStagesByRecordType';
import { InfoCard } from './components/Kanban/components/Card';
import ChangeResponsibleModal from './components/ChangeResponsibleModal';

const OpportunityCRM = ({
  selectedRecordTypeId,
  setSelectedRecordType,
  recordTypes
}) => {
  const [activePage, setActivePage] = useState(1);
  const [filters, setFilters] = useState({});
  const [showTable, setShowTable] = useState(true);
  const { opportunityStages, getOpportunityStagesByRecordType } =
    useListOpportunityStagesByRecordType();

  const [selectedRows, setSelectedRows] = useState([]);
  const [transferOpportunities, setTransferOpportunities] = useState(false);
  const [isChangeResponsibleModalOpen, setIsChangeResponsibleModalOpen] =
    useState(false);
  const [transferColumnsSearch, setTransferColumnsSearch] = useState({});

  useEffect(() => {
    if (!showTable) getOpportunityStagesByRecordType(selectedRecordTypeId);
  }, [getOpportunityStagesByRecordType, selectedRecordTypeId, showTable]);

  const {
    getOpportunities,
    opportunities,
    totalOpportunities,
    isLoadingOpportunities,
    clearOpportunities,
    getAllOpportunities
  } = useListOpportunities({
    recordTypeId: selectedRecordTypeId
  });

  const { customFields, getCustomFields } = useEntityCustomFieldsByRecordType(
    entitiesName.OPPORTUNITY,
    selectedRecordTypeId
  );

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    clearOpportunities(clearOpportunities);
    setActivePage(1);
  }, [selectedRecordTypeId, clearOpportunities]);

  const handleSearch = useCallback(
    (searchFilters, getAll = false) => {
      setActivePage(1);
      setFilters(searchFilters);
      if (getAll) {
        getAllOpportunities(searchFilters);
        return;
      }
      getOpportunities(1, searchFilters);
    },
    [getOpportunities, getAllOpportunities]
  );

  const handleTransferSearch = () => {
    handleSearch(transferColumnsSearch);
  };

  const handlePageChange = (newPage) => {
    setActivePage(newPage);
    getOpportunities(newPage, filters);
  };

  const reloadOpportunities = useCallback(() => {
    //console.log('Tá aqui ó');
    getOpportunities(1, {});
  }, [getOpportunities]);

  const stages = [
    'Qualificação',
    'Proposta',
    'Negociação',
    'Fechado Perdido',
    'Fechado Ganho'
  ];


  const onTableChange = (oportunidadeId, faseId) => {
    return opportunityService
      .updateStage(oportunidadeId, { stageId: faseId })
      .then(() => {
        // TODO: Melhorar a performance da busca,
        // acho melhor atualizar apenas o lead que mudou de fase ao invés de refazer a busca pra atualizar o Kanban
        handleSearch({ ...filters }, false);
        return true;
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao atualizar a fase', {
          autoClose: 3000,
          draggable: false
        });
        return false;
      });
  };

  const handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((x) => x.id !== row.id));
    }
  };

  const handleOnSelectAll = (isSelect, rows) => {
    const allRows = rows.map((r) => {
      return r;
    });
    if (isSelect) {
      setSelectedRows(allRows);
    } else {
      setSelectedRows([]);
    }
  };

  return (
    <>
      <FilterOpportunitiesForm
        handleSearch={handleSearch}
        customFields={customFields}
        isLoadingOpportunities={isLoadingOpportunities}
        recordTypeId={selectedRecordTypeId}
        setShowTable={setShowTable}
        showTable={showTable}
        opportunities={opportunities}
        totalOpportunities={totalOpportunities}
        selectedRecordTypeId={selectedRecordTypeId}
        setSelectedRecordType={setSelectedRecordType}
        recordTypes={recordTypes}
        setTransferOpportunities={setTransferOpportunities}
        transferOpportunities={transferOpportunities}
        selectedRows={selectedRows}
        setIsChangeResponsibleModalOpen={setIsChangeResponsibleModalOpen}
        setTransferColumnsSearch={setTransferColumnsSearch}
      />
      <Row
        style={{ overflowX: 'auto', width: '100%', height: '33.75rem' }}
        className="mt-2"
      >
        {isLoadingOpportunities ? (
          <div className="col-12">
            <Row className="justify-content-md-center">
              <Loader />
            </Row>
          </div>
        ) : showTable ? (
          <ListOpportunitiesTable
            opportunities={opportunities}
            reloadOpportunities={reloadOpportunities}
            customFields={customFields}
            transferOpportunities={transferOpportunities}
            handleOnSelect={handleOnSelect}
            handleOnSelectAll={handleOnSelectAll}
            selectedRows={selectedRows}
          />
        ) : (
          <Kanban
            reloadOpportunities={reloadOpportunities}
            onTableChange={onTableChange}
            data={opportunities}
            opportunityStages={opportunityStages ?? []}
            InfoCard={InfoCard}
          />
        )}
      </Row>
      {showTable && (
        <div style={{ marginTop: '3rem' }}>
          <Pagination
            activePage={activePage}
            totalItemsCount={totalOpportunities}
            onChange={handlePageChange.bind(this)}
          />
        </div>
      )}
      <ChangeResponsibleModal
        isModalOpen={isChangeResponsibleModalOpen}
        onModalToggle={setIsChangeResponsibleModalOpen}
        opportunityIds={selectedRows}
        callback={handleTransferSearch}
      />
    </>
  );
};

export default OpportunityCRM;
