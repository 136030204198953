import React, { useMemo, createContext, useContext, useEffect } from 'react';
import io from 'socket.io-client';
import customParser from 'socket.io-msgpack-parser';

import store from '../store';
import {
  addInitUnAnsweredConversation,
  addMessageUnansweredConversation
} from '../store/action/unanswered-convertations-action';
import {
  addInitAnsweredConversation,
  removeAnsweredConversation,
  addMessageAnsweredConversation,
  addAnsweredConversation
} from '../store/action/answered-conversations-action';
import {
  addMessageConversationInAttendance,
  updateStatusMessageConversationInAttendance
} from '../store/action/conversation-in-attendance-action';
import { authActiveLogout } from '../store/action/auth';

const useConversationConnectionSocketForMonitoringContext = createContext();

export function ConversationConnectionSocketForMonitoringProvider({
  children
}) {
  const socketConversation = useMemo(() => {
    return io.connect(process.env.REACT_APP_PROXY_URL, {
      path: process.env.REACT_APP_CHANNEL_SOCKET_PATH,
      parser: customParser,
      transports: ['websocket'],
      upgrade: false
    });
  }, []);

  useEffect(() => {
    return () => {
      socketConversation.close();
    };
  }, [socketConversation]);

  useEffect(() => {
    socketConversation.on('conversas_do_atendente', async (data) => {
      await store.dispatch(
        addInitUnAnsweredConversation(data.conversasDasMinhasFilas)
      );
      await store.dispatch(addInitAnsweredConversation(data.minhasConversas));
    });
  }, [socketConversation]);

  useEffect(() => {
    socketConversation.on(
      'conversa_transferida_pelo_bot_para_atendente',
      async (data) => {
        await store.dispatch(addAnsweredConversation(data.conversa));
      }
    );
  }, [socketConversation]);

  useEffect(() => {
    socketConversation.on(
      'cliente_enviou_mensagem_portal_chat',
      async (data) => {
        // console.log('########## cliente_enviou_mensagem ##########', data);
        data.mensagem = { hora_da_mensagem: new Date(), ...data.mensagem };

        await store.dispatch(
          addMessageConversationInAttendance(data.mensagem, data.idDaConversa)
        );
        //updating list of answeredConversations in store
        await store.dispatch(
          addMessageAnsweredConversation(
            data.mensagem,
            data.idDaConversa,
            data.novas_mensagens
          )
        );

        await store.dispatch(
          addMessageUnansweredConversation(
            data.mensagem,
            data.idDaConversa,
            data.novas_mensagens
          )
        );
      }
    );
  }, [socketConversation]);

  useEffect(() => {
    socketConversation.on('evento_de_mensagem', async (eventMessage) => {
      await store.dispatch(
        updateStatusMessageConversationInAttendance(eventMessage)
      );
    });
  }, [socketConversation]);

  useEffect(() => {
    //Alteração feita para o sussurro
    socketConversation.on('adm_enviou_sussurro', async (data) => {
      data.mensagem = { hora_da_mensagem: new Date(), ...data.mensagem };
      await store.dispatch(
        addMessageConversationInAttendance(data.mensagem, data.idDaConversa)
      );
      //updating list of answeredConversations in store
      await store.dispatch(
        addMessageAnsweredConversation(
          data.mensagem,
          data.idDaConversa,
          data.novas_mensagens
        )
      );
    });
  }, [socketConversation]);

  useEffect(() => {
    socketConversation.on('conversa_transferida', (data) => {
      store.dispatch(removeAnsweredConversation(data));
      //store.dispatch(removeConversationInAttendance());
    });
  }, [socketConversation]);

  useEffect(() => {
    socketConversation.on('conversa_encerrada', async (data) => {
      //console.log('########## conversa_encerrada ##########');
      let idDaConversa = data.id;
      await store.dispatch(removeAnsweredConversation(idDaConversa));
      //store.dispatch(removeConversationInAttendance());
    });
  }, [socketConversation]);

  useEffect(() => {
    socketConversation.on('deslogar_atendente', async () => {
      await store.dispatch(authActiveLogout());
    });
  }, [socketConversation]);

  useEffect(() => {
    socketConversation.on('connect', () => {
      const queuesUser = JSON.parse(localStorage.getItem('queues'));
      let teamUser = [];
      if (
        localStorage.getItem('teams') !== null ||
        localStorage.getItem('teams') !== undefined
      ) {
        teamUser = JSON.parse(localStorage.getItem('teams'));
      }
      const queuesName = queuesUser.map((queue) => queue.name);
      const data = {
        login: localStorage.getItem('login'),
        name: localStorage.getItem('name'),
        socket_id: socketConversation.id,
        nickname: localStorage.getItem('nickname'),
        queues: queuesName,
        teams: teamUser || [],
        pin_code: localStorage.getItem('pin_code')
      };
      socketConversation.emit('atendente_login', { ...data });
    });
  }, [socketConversation]);

  return (
    <>
      <useConversationConnectionSocketForMonitoringContext.Provider
        value={{
          socketConversation: socketConversation
        }}
      >
        {children}
      </useConversationConnectionSocketForMonitoringContext.Provider>
    </>
  );
}

export function useConversationConnectionSocketForMonitoring() {
  const context = useContext(
    useConversationConnectionSocketForMonitoringContext
  );
  return context;
}
