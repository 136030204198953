import { apiCrm } from '../../../../../http';

class LeadService {
  async getReportType1Pdf(params) {
    return apiCrm.get('/report/lead/export1/pdf', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType1Xlsx(params) {
    return apiCrm.get('/report/lead/export1/xlsx', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType1Csv(params) {
    return apiCrm.get('/report/lead/export1/csv', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType2Pdf(params) {
    return apiCrm.get('/report/lead/export2/pdf', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType2Xlsx(params) {
    return apiCrm.get('/report/lead/export2/xlsx', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType2Csv(params) {
    return apiCrm.get('/report/lead/export2/csv', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType3Pdf(params) {
    return apiCrm.get('/report/lead/export3/pdf', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType3Xlsx(params) {
    return apiCrm.get('/report/lead/export3/xlsx', {
      params,
      responseType: 'blob'
    });
  }

  async getReportType3Csv(params) {
    return apiCrm.get('/report/lead/export3/csv', {
      params,
      responseType: 'blob'
    });
  }
}

export default new LeadService();
