import React, { useEffect, useState, useCallback } from 'react';
import Service from '../service';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Pagination from 'components/Pagination';
import ModalConfirmation from '../../../components/Modal/ConfirmationModal';
import {
  Container,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  InputGroupText
} from 'reactstrap';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

export function ListShutdownCause() {
  const [shutdownCause, setShutdownCause] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [idResource, setidResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);
  const [timeoutID, setTimeoutID] = useState(null);

  const getShutdownCause = useCallback(
    (selectedPage = 1) => {
      Service.getAll(selectedPage, 10)
        .then((res) => {
          const { data, totalRegisters } = res.data;
          const newActivePage = selectedPage || activePage;
          setShutdownCause(data);
          setQuantity(totalRegisters);
          setActivePage(newActivePage);
          setLoading(false);
        })
        .catch((error) => {
          const { data } = error.response;
          toast.error(
            data.message || 'Erro ao listar Causas de desligamento!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
        });
    },
    [activePage]
  );

  useEffect(() => {
    getShutdownCause();
  }, [getShutdownCause]);

  const getCauseSeach = (event, selectedPage = 1) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const query_text = event.target.value;
    setTimeoutID(
      setTimeout(() => {
        Service.getAllSeach(selectedPage, 10, query_text, false)
          .then((res) => {
            const { data, totalRegisters } = res.data;
            const newActivePage = selectedPage || activePage;
            setShutdownCause(data);
            setQuantity(totalRegisters);
            setActivePage(newActivePage);
            setLoading(false);
          })
          .catch(() => {
            toast.error('Não foi possivel listar as causas', {
              autoClose: 3000,
              closeOnClick: true
            });
          });
      }, 1000)
    );
  };

  const handlePageChange = (activePage) => getShutdownCause(activePage);

  const deleteShutdownCause = (id) => {
    Service.delete(id)
      .then((res) => {
        console.log('res', res.data);
        const { data } = res;
        getShutdownCause();
        toast.success(
          data.message || 'Causa de desligamento excluido com sucesso!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      })
      .catch((error) => {
        const { data } = error.response;
        toast.error(data.message || 'Erro ao deletar Causa de desligamento!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        getShutdownCause();
      });
  };

  const handleControlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/shutdown/cause/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Causas de desligamento</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="11">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="nome: ERRO DE ROTA, ou código: 123"
                          type="text"
                          onChange={(event) => getCauseSeach(event)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={shutdownCause || []}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'cause_number',
                      text: 'Código',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'cause_title',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'description',
                      text: 'Descrição',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link to={'/admin/shutdown/cause/form/' + row.id}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => handleControlDelete(row.id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={deleteShutdownCause}
        message={'Tem certeza que deseja excluir o Causa de desligamento?'}
      />
    </>
  );
}
