import React, { useCallback, useEffect, useState } from 'react';
import ModalCDO from './ModalCDO/ModalCDO';

//Arquivos de requisições http
import UserService from '../../User/service';
import TimeShiftService from 'views/CalendarScales/service/TimeShift';
import ScaleService from 'views/Scales/service';
import CalendarService from 'views/CalendarScales/service/CalendarService';
import { useRequestUsers } from 'hooks/useUserContext';

//Importação de funções
import {
  toISOFormatSubmit,
  formatSupervisors,
  formatCallers,
  isUndefined,
  toISORender,
  arrayDays,
  Options,
  daysOfWeekObjectDateFilterTiein
} from '../functions/index';
import { brazilian } from 'assets/translator/pt-br/index';

//Importação de Funções de Biblioteca
import { useForm } from 'react-hook-form';

//Componentes
import { Row, Input } from 'reactstrap';
import { toast } from 'react-toastify';

import DayOffComponent from './DayOffComponent/DayOffComponent';
import TieinComponent from './TieinComponent/TieinComponent';
import AvulsaComponent from './AvulsaComponent/AvulsaComponent';

const ModalChangeDayOff = ({
  isModalOpen,
  onModalToggle,
  id,
  reloadfunction,

  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  // eslint-disable-next-line no-unused-vars
  const { users } = useRequestUsers();
  const { reset } = useForm();
  //Estados criados para Buscar informações
  // eslint-disable-next-line no-unused-vars
  const [login, setLogin] = useState(localStorage.getItem('login'));

  const [user, setUser] = useState('');

  //Estados para controle de campos FlexSelect
  const [listFlexSelect, setListFlexSelect] = useState([]);
  const [listSelect, setListSelect] = useState([]);
  const [scalesTieinTable, setScalesTieinTable] = useState([]);
  const [scalesAvulsaTable, setScalesAvulsaTable] = useState([]);
  const [scales, setScales] = useState([]);
  const [daysOffObject, setDaysOffObject] = useState([]);

  //Estados de controle de funções Submit e de controle de tela
  const [optionsUpdate, setOptionsUpdate] = useState();
  const [controlOptions, setControlOptions] = useState('folga');
  const [controlTable, setControlTable] = useState(false);

  //Estados de componentes para montar o objeto de Submit
  const [scaleSelected, setScaleSelected] = useState(0);

  //Estados de animação

  //Hook useEffect

  const loadApiReset = useCallback(async () => {
    try {
      await TimeShiftService.get(id).then((res) => {
        if (res.data.data.isDayoffShift === true) {
          setControlOptions('folga');

          const getTradeDay = res.data.data;

          const formatGetTradeDay = {
            ...getTradeDay,
            beginDateDayOff: toISORender(getTradeDay.dateTimeShift),
            endDateDayOff: toISORender(getTradeDay.requestedDateShift)
          };

          reset(formatGetTradeDay);
        } else if (
          res.data.data.isDayoffShift === false &&
          res.data.data.typeShift === 'single'
        ) {
          setControlOptions('avulsa');

          const getTradeDay = res.data.data;
          const formatGetTradeDay = {
            ...getTradeDay,
            dateTimeShift: toISORender(getTradeDay.requestedDateShift)
          };
          reset(formatGetTradeDay);
          // requestTableAvulsa();
          setControlTable(true);
        } else {
          setOptionsUpdate('tiein');

          const getTradeDay = res.data.data;
          const formatGetTradeDay = {
            ...getTradeDay,
            dateTimeShift: toISORender(getTradeDay.requestedDateShift)
          };
          reset(formatGetTradeDay);
          // requestTableTiein();
          setControlTable(true);
        }
      });
    } catch (error) {
      toast.error(error);
    }
  }, []);

  useEffect(() => {
    if (id) {
      if (props.isDayOffShift) {
        setOptionsUpdate('folga');
        setControlOptions('folga');
        loadApiReset();
      } else if (
        props.isDayOffShift === false &&
        props.typeShift === 'single'
      ) {
        setOptionsUpdate('avulsa');
        setControlOptions('avulsa');
        loadApiReset();
      } else {
        setOptionsUpdate('tiein');
        setControlOptions('tiein');
        loadApiReset();
      }
    }
  }, [id, loadApiReset]);

  useEffect(() => {
    CalendarService.getCalendarDayOffWithoutParams(login)
      .then((res) => {
        setDaysOffObject(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const login = localStorage.getItem('login');

    UserService.get(login)
      .then((res) => setUser(res.data.data))
      .catch((err) => console.log(err));
  }, []);

  async function loadApi(page = 1, querySearch = '') {
    await ScaleService.getAllSearch(true, 10, page, querySearch)
      .then((response) => {
        const FormatedData = response.data.data;
        const { DAYS } = brazilian;
        response.data.data.forEach((scale, index) => {
          let listDays = [];
          arrayDays.forEach((day) => {
            if (scale[day] === true) {
              for (let [key, value] of Object.entries(DAYS)) {
                if (key === day) listDays.push(value);
              }
            }
          });
          const joinDays = listDays.join(' , ');
          FormatedData[index] = { ...scale, joinDays };
        });
        setScales(FormatedData);
      })
      .catch((error) => console.log(error));
  }

  useEffect(() => {
    loadApi();
  }, []);

  const loadApiUsers = useCallback(async () => {
    const Params = {
      page: 0,
      size: 0,
      showDisable: true,
      query: '',
      isSupervisor: true
    };

    if (controlOptions === 'avulsa' || controlOptions === 'folga') {
      await UserService.getAllShowSupervisor(
        Params.page,
        Params.size,
        Params.query,
        Params.showDisable,
        Params.isSupervisor
      )
        .then((response) => {
          formatSupervisors(response.data.data, setListFlexSelect, login);
        })
        .catch((error) => {
          toast.error(error.response?.data.errors[0]);
        });
    } else {
      setListSelect([]);

      formatCallers(users, setListSelect, login);
    }
  }, [controlOptions, login, users]);

  useEffect(() => {
    loadApiUsers();
  }, [controlOptions, loadApiUsers]);

  const onSubmit = (data) => {
    if (id) {
      if (controlOptions === 'avulsa') {
        const newData = {
          dateTimeShift: toISOFormatSubmit(data.dateTimeShift),
          userRequestWorkScale: user.scale?.id,
          userRequestedShift: user.login,
          userRequestedWorkScale: scaleSelected,
          requestedDateShift: toISOFormatSubmit(data.dateTimeShift),
          isDayoffShift: false,
          typeShift: 'single',
          supervisorShift: data.supervisorShift
        };

        TimeShiftService.patch(id, newData)
          .then(() => {
            closeModal();
            reloadfunction();
          })
          .catch((error) => toast.error(error.response?.data.errors[0]));
      } else if (controlOptions === 'tiein') {
        const newData = {
          dateTimeShift: toISOFormatSubmit(data.dateTimeShift),
          userRequestWorkScale: user.scale?.id,
          userRequestedShift: data.atendentShift,
          userRequestedWorkScale: scaleSelected,
          requestedDateShift: toISOFormatSubmit(data.dateTimeShift),
          isDayoffShift: false,
          typeShift: 'tiein',
          supervisorShift: data.supervisorShift
        };

        TimeShiftService.patch(id, newData)
          .then(() => {
            closeModal();
            reloadfunction();
          })
          .catch((error) => toast.error(error.response?.data.errors[0]));
      } else if (controlOptions === 'folga') {
        const newData = {
          dateTimeShift: toISOFormatSubmit(data.beginDateDayOff),
          userRequestWorkScale: user.scale?.id,
          userRequestedShift: user.login,
          userRequestedWorkScale: user.scale?.id,
          requestedDateShift: toISOFormatSubmit(data.endDateDayOff),
          isDayoffShift: true,
          typeShift: 'single',
          supervisorShift: data.supervisorShift
        };

        TimeShiftService.patch(id, newData)
          .then(() => {
            closeModal();
            reloadfunction();
          })
          .catch((error) => toast.error(error.response?.data.errors[0]));
      }
    } else {
      if (controlOptions === 'avulsa') {
        const newData = {
          dateTimeShift: toISOFormatSubmit(data.dateTimeShift),
          userRequestWorkScale: user.scale?.id,
          userRequestedShift: user.login,
          userRequestedWorkScale: scaleSelected,
          requestedDateShift: toISOFormatSubmit(data.dateTimeShift),
          isDayoffShift: false,
          typeShift: 'single',
          supervisorShift: data.supervisorShift
        };

        TimeShiftService.post(newData)
          .then(() => {
            closeModal();
            reloadfunction();
          })
          .catch((error) => toast.error(error.response?.data.errors[0]));
      } else if (controlOptions == 'tiein') {
        const newData = {
          dateTimeShift: toISOFormatSubmit(data.dateTimeShift),
          userRequestWorkScale: user.scale?.id,
          userRequestedShift: data.atendentShift,
          userRequestedWorkScale: scaleSelected,
          requestedDateShift: toISOFormatSubmit(data.dateTimeShift),
          isDayoffShift: false,
          typeShift: 'tiein',
          supervisorShift: data.supervisorShift
        };

        TimeShiftService.post(newData)
          .then(() => {
            closeModal();
            reloadfunction();
          })
          .catch((error) => toast.error(error.response?.data.errors[0]));
      } else if (controlOptions === 'folga') {
        const newData = {
          dateTimeShift: toISOFormatSubmit(data.beginDateDayOff),
          userRequestWorkScale: user.scale?.id,
          userRequestedShift: user.login,
          userRequestedWorkScale: user.scale?.id,
          requestedDateShift: toISOFormatSubmit(data.endDateDayOff),
          isDayoffShift: true,
          typeShift: 'single',
          supervisorShift: data.supervisorShift
        };

        TimeShiftService.post(newData)
          .then(() => {
            closeModal();
            reloadfunction();
          })
          .catch((error) => toast.error(error.response?.data.errors[0]));
      }
    }
  };

  const toggleModal = (value) => {
    onModalToggle(value);
    setControlOptions('folga');
  };

  return (
    <ModalCDO
      show={isModalOpen}
      onModalToggle={toggleModal}
      title="Configurações de troca"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <Row>
        <div
          className="col"
          style={{
            backgroundColor: '#ffffff',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <h4>Tipo de troca</h4>
          {optionsUpdate ? (
            <Input
              type="select"
              name="mes"
              defaultValue={optionsUpdate}
              onChange={(e) => {
                setControlOptions(e.target.value);
                setControlTable(false);
              }}
              style={{ width: '18rem' }}
              className=" mt-2"
            >
              {Options.map((value, index) => {
                return (
                  <option key={index} value={value.value}>
                    {value.label}
                  </option>
                );
              })}
            </Input>
          ) : (
            <Input
              type="select"
              name="mes"
              defaultValue={Options[0].value}
              onChange={(e) => {
                setControlOptions(e.target.value);
                setControlTable(false);
              }}
              style={{ width: '18rem' }}
              className=" mt-2"
            >
              {Options.map((value, index) => {
                return (
                  <option key={index} value={value.value}>
                    {value.label}
                  </option>
                );
              })}
            </Input>
          )}
        </div>
      </Row>
      <Row style={{ display: 'flex', justifyContent: 'center' }}>
        {controlOptions === 'folga' && (
          <DayOffComponent
            closeModal={closeModal}
            onSubmit={onSubmit}
            listFlexSelect={listFlexSelect}
            user={user}
            daysOffObject={daysOffObject}
          />
        )}
        {controlOptions === 'tiein' && (
          <TieinComponent
            closeModal={closeModal}
            setScaleSelected={setScaleSelected}
            scalesTieinTable={scalesTieinTable}
            controlTable={controlTable}
            daysOffObject={daysOffObject}
            user={user}
            listSelect={listSelect}
            onSubmit={onSubmit}
            setControlTable={setControlTable}
            daysOfWeekObjectDateFilterTiein={daysOfWeekObjectDateFilterTiein}
            setScalesTieinTable={setScalesTieinTable}
            listFlexSelect={listFlexSelect}
          />
        )}
        {controlOptions === 'avulsa' && (
          <AvulsaComponent
            closeModal={closeModal}
            setScaleSelected={setScaleSelected}
            scalesAvulsaTable={scalesAvulsaTable}
            controlTable={controlTable}
            daysOffObject={daysOffObject}
            user={user}
            listFlexSelect={listFlexSelect}
            onSubmit={onSubmit}
            setControlTable={setControlTable}
            setScalesAvulsaTable={setScalesAvulsaTable}
            scales={scales}
            isUndefined={isUndefined}
          />
        )}
      </Row>
    </ModalCDO>
  );
};

export default ModalChangeDayOff;
