import React, { useEffect, useState } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Pagination from 'components/Pagination';
import ModalConfirmation from '../../../components/Modal/ConfirmationModal';
import TimeScheduleService from '../service';

import {
  Input,
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../assets/styles/table_custom_style';

import ReactLoader from 'components/Loader';

const TimeScheduleList = () => {
  const [timeSchedules, setTimeSchedules] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [searchTimeSchedules, setSearchTimeSchedules] = useState('');
  const [idResource, setidResource] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const getTimeSchedules = (selectedPage = 1, search = '') => {
    setLoading(true);
    TimeScheduleService.getAllSeach(selectedPage, 10, search, true)
      .then((response) => {
        const timeScheduleList = response.data.data;
        const quantity = response.data.totalRegisters;

        setTimeSchedules(timeScheduleList);
        setQuantity(quantity);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(
          err.response.data.message ||
            'Erro ao listar pragramações de horário!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
        setLoading(false);
      });
  };

  useEffect(getTimeSchedules, []);

  const getTimeSchedulesSearch = (event, selectedPage = 1) => {
    setSearchTimeSchedules(event.target.value);
    if (searchTimeSchedules) {
      getTimeSchedules(selectedPage, event.target.value);
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    getTimeSchedules(page);
  };

  function deleteTimeSchedule(id) {
    TimeScheduleService.delete(id)
      .then(() => {
        toast.success('Programação de horário excluída com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
        getTimeSchedules();
      })
      .catch((err) => {
        toast.error(err.response.data.message || err.response.data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        getTimeSchedules();
      });
  }
  const controlDelete = (id) => {
    setidResource(id);
    setOpenModalConfirmation(true);
  };
  return (
    <>
      <SimpleHeader
        setCreate="/admin/timeschedule/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Programação de horário</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Programação de horário"
                          type="text"
                          onChange={(event) => getTimeSchedulesSearch(event)}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={timeSchedules}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link to={'/admin/timeschedule/form/' + row.id}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => controlDelete(row.id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={deleteTimeSchedule}
        message={'Tem certeza que deseja excluir o horário?'}
      />
    </>
  );
};

export default TimeScheduleList;
