import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  Row,
  Col,
  Form,
  FormGroup,
  ModalBody
} from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import ExtensionService from '../../../../Extension/service';
import FlexSelect from '../../../../../components/Inputs/FlexSelect';
import { ErrorMessage } from '@hookform/error-message';

const AddAgentModal = ({
  allUsers,
  loggedInAgents,
  queues,
  handleAgentLogin,
  ...props
}) => {
  const [extensions, setExtensions] = useState([]);
  const { buttonLabel, className } = props;
  const { control, handleSubmit, setValue, errors } = useForm();

  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [canExtensionBeChanged, setCanExtensionBeChanged] = useState(true);

  useEffect(() => {
    if (!isOpen || extensions.length > 0) return;
    setIsLoading(true);
    ExtensionService.getAllSimple()
      .then((response) => {
        setExtensions(response.data.data);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => setIsLoading(false));
  }, [isOpen]);

  const toggle = () => setIsOpen(!isOpen);

  const handleAgentChange = (agentCode) => {
    const loggedAgent = loggedInAgents.find(
      (agent) => agent.agentCode === agentCode
    );

    const isAgentAlreadyLoggedIn = !!loggedAgent;

    if (isAgentAlreadyLoggedIn) {
      setValue('extension', loggedAgent.extension);
      setCanExtensionBeChanged(false);
    } else {
      setCanExtensionBeChanged(true);
    }
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    await handleAgentLogin(data);
    setIsLoading(false);
    setIsOpen(false);
  };
  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <div>
      <Button
        type="button"
        color="primary"
        size="sm"
        onClick={toggle}
        disabled={props.loading}
      >
        {buttonLabel}
      </Button>
      <Modal isOpen={isOpen} toggle={toggle} className={className}>
        <ModalBody>
          <h3>Logar Agente</h3>
          <hr />
          <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
            <Row className="mb-3">
              <Col>
                <label htmlFor="agentSelection">Agente*</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      closeMenuOnSelect={true}
                      isClearable={true}
                      dataOptions={allUsers}
                      placeholder="Selecione o agente"
                      isMulti={false}
                      value={props.value}
                      valueController={(key, value) => {
                        setValue(key, value);
                        handleAgentChange(value);
                      }}
                      fieldName="agent_code"
                      labelName="name"
                      valueName="agent_code"
                      isDisabled={isLoading}
                    />
                  )}
                  rules={{ required: 'Selecione o agente' }}
                  control={control}
                  name="agent_code"
                  defaultValue=""
                  id="agentSelection"
                />
                <ErrorMessage
                  errors={errors}
                  name="agent_code"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label htmlFor="queueNameChangePenaltyForm">Ramal*</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      closeMenuOnSelect={true}
                      isClearable={false}
                      dataOptions={extensions}
                      placeholder="Selecione o ramal"
                      isMulti={false}
                      value={props.value}
                      valueController={setValue}
                      fieldName="extension"
                      labelName="extension_number"
                      valueName="extension_number"
                      isDisabled={!canExtensionBeChanged || isLoading}
                    />
                  )}
                  rules={{ required: 'Selecione o ramal' }}
                  control={control}
                  name="extension"
                  defaultValue=""
                  id="extensionSelection"
                />
                <ErrorMessage
                  errors={errors}
                  name="extension"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <label htmlFor="queueSelection">Filas*</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      closeMenuOnSelect={false}
                      isClearable={true}
                      dataOptions={queues}
                      placeholder="Selecione a fila"
                      isMulti={true}
                      value={props.value}
                      valueController={setValue}
                      fieldName="queueIds"
                      labelName="name"
                      valueName="id"
                      isDisabled={isLoading}
                    />
                  )}
                  rules={{
                    validate: (values) => {
                      if (!values || !values.length) {
                        return 'Selecione pelo menos uma fila';
                      }
                    }
                  }}
                  control={control}
                  name="queueIds"
                  defaultValue=""
                  id="queueSelection"
                />
                <ErrorMessage
                  errors={errors}
                  name="queueIds"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Row>

            <Row form>
              <Col md={12} className="text-right">
                <FormGroup>
                  <Button type="submit" color="primary" disabled={isLoading}>
                    Logar
                  </Button>
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddAgentModal;
