import React, { useEffect, useState } from 'react';
import RecievedCallReportingService from '../../Report/Callcenter/ReceivedCall/service';
import OutgoingCallReportingService from '../../Report/Callcenter/CallMade/service';
import PbxipCallReceivedService from '../../Report/PBXIP/ReceivedCall/service';
import PbxipCallOutgoingService from '../../Report/PBXIP/CallMade/service';
import { useForm, Controller } from 'react-hook-form';
import QueueService from '../../Queue/service';
import { useSelector } from 'react-redux';

import {
  PAGE,
  QUERY_PARAMS_CARDS,
  QUERY_PARAMS_CALLIN,
  REPORT_TYPE_DETAILEDSYNTHETIC,
  dateBegin,
  dateEnd,
  GROUPBY,
  currentMont,
  TIME_INTERVAL,
  QUERY_PARAMS_PBXIP
} from './assets/consts';

import { calculateServiceLevel } from './assets/calculateServiceLevel';

import { Container, Row, Col } from 'reactstrap';
import AlternativeHeader from 'components/Headers/AlternativeHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import TopCards from './components/CardItem';
import CallCenterCallGraphicsIncomingCalls from './components/CallcenterCallGraphics/IncomingCalls';
import CallCenterCallGraphicsOutgoingCalls from './components/CallcenterCallGraphics/OutgoingCalls';
import PbxipCallGraphicsIncomingCalls from './components/PbxipCallGraphics/IncomingCalls';
import PbxipCallGraphicsOutgoingCalls from './components/PbxipCallGraphics/OutgoingCalls';
import { useCalculateStatisticalData } from '../../Monitoring/Queue/MonitoringScreen/components/AsideCards/AsideCardsList/useCalculateStatisticalData';
import { UserNotLoggedIn } from 'views/Monitoring/SupervisorPanel/components/QueueTable/styled';
import { FiMeh } from 'react-icons/fi';

const styleTime = {
  color: '#fff',
  fontSize: '2.1rem',
  margin: '0',
  padding: '0'
};

const Dashboard = () => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { watch, setValue, control } = useForm();
  const [dataCarts, setDataCarts] = useState({});
  const [dataServiceLevel, setDataServiceLevel] = useState({});
  const [dataForIncomingCallGraph, setDataForIncomingCallGraph] = useState([]);
  const [dataForOutCallGraph, setDataOutCallGraph] = useState([]);
  const [dataForIncomingPbxipGraph, setDataForIncomingPbxipGraph] = useState(
    []
  );
  const [dataForOutPbxipGraph, setDataForOutPbxipGraph] = useState([]);
  const queueValue = watch('queuesid');
  const [queues, setQueues] = useState([]);
  const { calculeConform, calculeNotConfor } = calculateServiceLevel();
  const { getTME, getTMA, getAnsweredCalls, getAbandonedCalls } =
    useCalculateStatisticalData();
  const [isFromPBXIP, setIsFromPBXIP] = useState();

  useEffect(() => {
    if (queueValue) {
      getMontServiceLevel(queueValue);
      getDataDashboard(queueValue);
      getDataForIncomingCallGraph(queueValue);
      getDataForOutgoingCallGraph(queueValue);
      getDataForPbxipIncomingCallGraph();
      getDataForPbxipOutgoingCallGraph();
      const interval = setInterval(() => {
        getMontServiceLevel(queueValue);
        getDataDashboard(queueValue);
        getDataForIncomingCallGraph(queueValue);
        getDataForOutgoingCallGraph(queueValue);
        getDataForPbxipIncomingCallGraph();
        getDataForPbxipOutgoingCallGraph();
      }, TIME_INTERVAL);
      return () => clearInterval(interval);
    }
  }, [queueValue]);

  const getDataDashboard = (queue) => {
    const queryParams = {
      ...QUERY_PARAMS_CARDS,
      queue,
      dateBegin,
      dateEnd
    };
    RecievedCallReportingService.getAllReportsType(
      REPORT_TYPE_DETAILEDSYNTHETIC,
      PAGE,
      queryParams
    )
      .then((response) => {
        const { data } = response.data;
        setDataCarts(data);
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const getMontServiceLevel = (queue) => {
    const queryParams = {
      ...QUERY_PARAMS_CARDS,
      queue,
      dateBegin,
      dateEnd,
      GROUPBY
    };
    RecievedCallReportingService.getAllReportsType(
      REPORT_TYPE_DETAILEDSYNTHETIC,
      PAGE,
      queryParams
    )
      .then((response) => {
        const { listCallDetailedSyntheticCallCenterDTO } = response.data.data;
        setDataServiceLevel(listCallDetailedSyntheticCallCenterDTO);
      })
      .catch((error) => {
        console.log('error na request nivel de serviço', error);
      });
  };

  const getDataForIncomingCallGraph = (queue) => {
    const queryParams = {
      ...QUERY_PARAMS_CALLIN,
      queue,
      dateBegin,
      dateEnd
    };
    RecievedCallReportingService.getAllReportsType(
      REPORT_TYPE_DETAILEDSYNTHETIC,
      PAGE,
      queryParams
    )
      .then((response) => {
        // console.log('response grafico', response);
        const { listCallDetailedSyntheticCallCenterDTO } = response.data.data;
        setDataForIncomingCallGraph(listCallDetailedSyntheticCallCenterDTO);
      })
      .catch((error) => {
        console.log('error na request chamadas recebidas ', error);
      });
  };

  const getDataForOutgoingCallGraph = (queue) => {
    const queryParams = {
      ...QUERY_PARAMS_CALLIN,
      queue,
      dateBegin,
      dateEnd
    };
    OutgoingCallReportingService.getAllReportsType(
      REPORT_TYPE_DETAILEDSYNTHETIC,
      PAGE,
      queryParams
    )
      .then((response) => {
        const { listCallDetailedSyntheticCallCenterDTO } = response.data.data;
        setDataOutCallGraph(listCallDetailedSyntheticCallCenterDTO);
      })
      .catch((error) => {
        console.log('error na request chamadas realizadas ', error);
      });
  };

  const getDataForPbxipIncomingCallGraph = () => {
    const queryParams = {
      ...QUERY_PARAMS_PBXIP,
      dateBegin,
      dateEnd
    };
    PbxipCallReceivedService.getAllReportsType(
      REPORT_TYPE_DETAILEDSYNTHETIC,
      PAGE,
      queryParams
    )
      .then((response) => {
        // console.log('response PBXIP', response);
        const { listCallDetailedSyntheticPbxipDTO } = response.data.data;
        setDataForIncomingPbxipGraph(listCallDetailedSyntheticPbxipDTO);
      })
      .catch((error) => {
        console.log('error na request chamadas recebidas PBXIP', error);
      });
  };

  const getDataForPbxipOutgoingCallGraph = () => {
    const queryParams = {
      ...QUERY_PARAMS_PBXIP,
      dateBegin,
      dateEnd
    };
    PbxipCallOutgoingService.getAllReportsType(
      REPORT_TYPE_DETAILEDSYNTHETIC,
      PAGE,
      queryParams
    )
      .then((response) => {
        const { listCallDetailedSyntheticPbxipDTO } = response.data.data;
        setDataForOutPbxipGraph(listCallDetailedSyntheticPbxipDTO);
      })
      .catch((error) => {
        console.log('error na request chamadas realizadas PBXIP', error);
      });
  };

  useEffect(() => {
    let checkIsFromPBXIP = window.location.href.split('/');
    checkIsFromPBXIP.filter((item) => {
      if (item === 'PBXIP') {
        setIsFromPBXIP(true);
        getDataForPbxipIncomingCallGraph();
        getDataForPbxipOutgoingCallGraph();
      } else if (item === 'callcenter') {
        QueueService.getAll()
          .then((response) => {
            setQueues(response.data.data);
          })
          .catch((err) => {
            console.log('err: ' + err);
          });
        setIsFromPBXIP(false);
      }
    });
  }, []);

  return (
    <>
      <AlternativeHeader />

      <Container className="mt--6">
        <Row
          style={{
            paddingBottom: '1.5rem',
            display: `${isFromPBXIP ? 'none' : 'flex'}`
          }}
        >
          <Col md="12">
            <label className="form-control-label">Fila</label>
            <Controller
              render={(props) => (
                <FlexSelect
                  closeMenuOnSelect={true}
                  isClearable={true}
                  dataOptions={queues}
                  placeholder="Selecione a fila para buscar dados"
                  isMulti={false}
                  value={props.value}
                  valueController={setValue}
                  fieldName="queuesid"
                  labelName="name"
                  valueName="id"
                />
              )}
              control={control}
              name="queuesid"
              defaultValue=""
            />
          </Col>
        </Row>

        <Row
          style={{
            display: `${isFromPBXIP ? 'none' : 'flex'}`
          }}
        >
          <Col md="6" xl="3">
            <TopCards title="T.M.A">
              <span style={styleTime}>{getTMA(dataCarts)}</span>
            </TopCards>
          </Col>
          <Col md="6" xl="3">
            <TopCards title="T.M.E">
              <span style={styleTime}>{getTME(dataCarts)}</span>
            </TopCards>
          </Col>
          <Col md="6" xl="3">
            <TopCards title="Nível de Serviço">
              <div className="d-flex flex-row justify-content-center align-items-start">
                <div className="d-flex flex-column text-center mr-4">
                  <span
                    className="my-0 font-weight-normal"
                    style={{ fontSize: '2.0rem' }}
                  >
                    {(calculeConform && calculeConform(dataServiceLevel)) ||
                      '0'}
                  </span>
                  <span
                    className="my-0 font-weight-normal"
                    style={{ fontSize: '0.8rem' }}
                  >
                    Conforme
                  </span>
                </div>
                <div className="d-flex flex-column text-center">
                  <span
                    className="font-weight-normal"
                    style={{ fontSize: '2.0rem' }}
                  >
                    {(calculeNotConfor && calculeNotConfor(dataServiceLevel)) ||
                      '0'}
                  </span>
                  <span
                    className="font-weight-normal"
                    style={{ fontSize: '0.8rem' }}
                  >
                    Não Conforme
                  </span>
                </div>
              </div>
            </TopCards>
          </Col>
          <Col md="6" xl="3">
            <TopCards title="Status de Chamadas">
              <div className="d-flex flex-row justify-content-center">
                <div className="d-flex flex-column text-center mr-4">
                  <span
                    className="my-0 font-weight-normal"
                    style={{ fontSize: '2.0rem' }}
                  >
                    {getAnsweredCalls(dataCarts) || 0}
                  </span>
                  <span className="my-0" style={{ fontSize: '0.8rem' }}>
                    Atendidas
                  </span>
                </div>
                <div className="d-flex flex-column text-center">
                  <span className="my-0" style={{ fontSize: '2.0rem' }}>
                    {getAbandonedCalls(dataCarts) || 0}
                  </span>
                  <span className="my-0" style={{ fontSize: '0.8rem' }}>
                    Abandonadas
                  </span>
                </div>
              </div>
            </TopCards>
          </Col>
        </Row>
        <Row>
          <Col
            md={
              dataForIncomingCallGraph.length > 15 ? { size: 12 } : { size: 6 }
            }
          >
            {dataForIncomingCallGraph.length > 0 && isFromPBXIP === false ? (
              <CallCenterCallGraphicsIncomingCalls
                month={currentMont()}
                dataGraphics={dataForIncomingCallGraph}
              />
            ) : null}
          </Col>
          <Col
            md={dataForOutCallGraph.length > 15 ? { size: 12 } : { size: 6 }}
          >
            {dataForOutCallGraph.length > 0 && isFromPBXIP === false ? (
              <CallCenterCallGraphicsOutgoingCalls
                month={currentMont()}
                dataGraphics={dataForOutCallGraph}
              />
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col md={{ size: 12 }}>
            {dataForIncomingPbxipGraph.length > 0 && isFromPBXIP === true ? (
              <PbxipCallGraphicsIncomingCalls
                month={currentMont()}
                dataGraphics={dataForIncomingPbxipGraph}
              />
            ) : null}
          </Col>
          <Col md={{ size: 12 }}>
            {dataForOutPbxipGraph.length > 0 && isFromPBXIP === true ? (
              <PbxipCallGraphicsOutgoingCalls
                month={currentMont()}
                dataGraphics={dataForOutPbxipGraph}
              />
            ) : (
              <>
                <UserNotLoggedIn>
                  <p className="mb-1 text-muted">
                    Ops...Não há nenhum dado disponível no momento
                  </p>
                  <FiMeh size={25} color={systemConfiguration.primary_color} />
                </UserNotLoggedIn>
              </>
            )}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Dashboard;
