import React from 'react';

import { ContentContainer } from './styled';
import { useSelector } from 'react-redux';

import { Card, CardBody, CardTitle } from 'reactstrap';

const QueueCardItem = ({ title, children }) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  return (
    <Card
      style={{
        backgroundColor: systemConfiguration.primary_color,
        color: '#fff',
        textAlign: 'center',
        margin: '0 0 7px 0',
        border: '0.5px solid',
        width: 'auto',
        height: '90px'
      }}
    >
      <CardBody style={{ padding: '15px' }}>
        <CardTitle
          tag="h6"
          className="text-uppercase font-weight-bold mb-0 pb-0 text-black text-white"
        >
          {title}
        </CardTitle>
        <ContentContainer>{children}</ContentContainer>
      </CardBody>
    </Card>
  );
};

export default QueueCardItem;
