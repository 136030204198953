import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { toast } from 'react-toastify';
import { Container, Row, Card, CardHeader, Button, Badge } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import ConfigurationModal from './components/GeneralConfigurationModal';
import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style,
  individual_icon_header_style_callback
} from '../../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';
import Service from '../service';

const ListConfigSurvey = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const [configSurvey, setConfigSurvey] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [, setQuantity] = useState(0);
  const [isConfigModalOpen, setIsConfigModalOpen] = useState(false);

  useEffect(() => {
    getConfig();
  }, []);

  const getConfig = (selectedPage = 1) => {
    Service.getAll(selectedPage, 10)
      .then((res) => {
        const data = res.data;
        const newActivePage = selectedPage || activePage;
        setConfigSurvey(data);
        setQuantity(0);
        setActivePage(newActivePage);
        setLoading(false);
      })
      .catch((err) => {
        console.log('erro na listagem', err);
        toast.error('Não foi possivel listar as configurações', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const toggleDisableAndEnable = (id, status) => {
    const newStatus = {
      active: !status
    };
    Service.enableConfig(id, newStatus)
      .then((res) => {
        if (res.data.msg) {
          getConfig();
          const message = res.data.msg;
          toast.warning(`Alteração realizada com sucesso, ${message}`, {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error('Erro ao alterar status da pesquisa ', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  function handleUpdate(id) {
    history.push(`/admin/configSurvey/form/${id}`);
  }

  return (
    <>
      <SimpleHeader
        setCreate="/admin/configSurvey/form"
        name="Form validation"
        parentName="Forms"
      >
        <Button onClick={() => setIsConfigModalOpen(true)}>
          <FiSettings /> Configurações gerais
        </Button>
      </SimpleHeader>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Configurações de pesquisa</h3>
                </CardHeader>
                <ToolkitProvider
                  data={configSurvey}
                  keyField="_id"
                  columns={[
                    {
                      dataField: 'question',
                      text: 'Questão',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'queues',
                      text: 'Fila(s)',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (row) => {
                        return (
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              flexWrap: 'wrap',
                              maxWidth: '250px'
                            }}
                          >
                            {row.map((answer, index) => {
                              return (
                                <div
                                  style={{
                                    margin: '1px'
                                  }}
                                  key={index}
                                >
                                  <Badge color="info" key={index}>
                                    {answer.name}
                                  </Badge>
                                </div>
                              );
                            })}
                          </div>
                        );
                      }
                    },
                    {
                      dataField: 'type_survey',
                      text: 'Tipo',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (type) => {
                        return type === 'nps' ? 'NPS' : 'SATISFAÇÃO';
                      }
                    },
                    {
                      dataField: 'active',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style_callback,
                      text: 'Desabilitar / Habilitar',
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            checked={row.active}
                            id={row._id}
                            onChange={() => {
                              toggleDisableAndEnable(row._id, row.active);
                            }}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      formatter: (_, row) => {
                        return (
                          <Button
                            size="sm"
                            color="info"
                            onClick={() => handleUpdate(row._id)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ConfigurationModal
        isModalOpen={isConfigModalOpen}
        onModalToggle={setIsConfigModalOpen}
        unmountOnClose={true}
      />
    </>
  );
};

export default ListConfigSurvey;
