import { useState, useCallback } from 'react';
import contactService from '../services/contactService';

export const useSearchContacts = () => {
  const [timeoutID, setTimeoutID] = useState(null);
  const [isSearchingContacts, setIsSearchingContacts] = useState(false);

  const findContacts = useCallback(async (q) => {
    const params = { textQuery: q };
    try {
      const response = contactService.searchContacts(params);
      return response;
    } catch (error) {
      return null;
    }
  }, []);

  const handleGetContacts = useCallback(
    async (q) => {
      try {
        if (!q) return [];
        setIsSearchingContacts(true);
        const response = await findContacts(q);
        return response.data.data;
      } catch (error) {
        return [];
      } finally {
        setIsSearchingContacts(false);
      }
    },
    [findContacts]
  );

  const filterContacts = useCallback(
    async (q) => {
      return new Promise((resolve, reject) => {
        try {
          if (typeof timeoutID === 'number') {
            clearTimeout(timeoutID);
          }

          setTimeoutID(
            setTimeout(async () => {
              const data = await handleGetContacts(q);
              resolve(data);
            }, 1000)
          );
        } catch (error) {
          reject(error);
        }
      });
    },
    [handleGetContacts, timeoutID]
  );

  return {
    isSearchingContacts,
    filterContacts
  };
};
