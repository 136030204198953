import { useState, useEffect, useCallback } from 'react';
import Protocol from './Service/protocolservice';

export function useGetProtocolById(login, isOpen) {
  const [protocol, setProtocol] = useState();

  const getProtocol = useCallback(async () => {
    try {
      if (isOpen) {
        const response = await Protocol.get(login);
        setProtocol(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [login, isOpen]);

  useEffect(() => {
    getProtocol();
  }, [getProtocol]);

  return { protocol, setProtocol };
}
