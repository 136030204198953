import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.section`
  height: 25px;
  width: 25px;
  position: sticky;
  margin-top: 100%;
  bottom: 5px;
  left: 50%;
  z-index: 200;
  color: #21c25e;
`;

export const BodyChat = styled.section`
  position: fixed;
  border-radius: 2px;
  z-index: 200;
  bottom: 0px;
  width: 249px;
  left: 0px;
  animation-name: ${(props) => (props.sizeChat ? 'fadeInLeft' : '')};
  animation-duration: 0.3s;

  .icon-internal-chat {
    transition: ease-in-out 0.3s;
  }

  .icon-internal-chat:hover {
    color: ${shade(0.2, '#21c25e')};
    transform: scale(1.3, 1.3);
  }

  @keyframes fadeInLeft {
    0% {
      width: 0px;
      opacity: 0;
    }
    100% {
      opacity: 1;
      width: 249px;
    }
  }
`;

export const ContainerChat = styled.section`
  overflow-y: scroll;
  scroll-behavior: smooth;
  height: ${(props) => (props.sizeChat ? '280px' : '0px')};
  transition: ease-in-out 0.4s;
  background: #fff;

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const Header = styled.header`
  height: 40px;
  background-color: ${(props) => (props.color ? props.color : 'gray')};
  width: 100%;
  display: flex;
  color: ${(props) => (props.fontColor ? props.fontColor : 'gray')};
  align-items: center;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
  padding: 0 8px 0 8px;
  .header {
    border: none;
    background: inherit;
    color: white;
    width: 100%;
  }

  .title-chat-interno {
    color: ${(props) => (props.fontColor ? props.fontColor : 'gray')};
  }
`;

export const UserList = styled.div`
  height: 45px;
  width: 100%;
  display: flex;
  position: relative;
  justify-content: space-around;
  margin-top: 3px;
  align-items: center;
  transition: ease-in-out 0.3s;

  .name-user-chat-internal {
    font-family: 'Roboto', sans-serif;
    color: #383838;
    margin-right: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  cursor: pointer;
  &&:hover {
    -webkit-box-shadow: 0px 1px 13px -1px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 1px 13px -1px rgba(0, 0, 0, 0.24);
  }
`;
export const GroupList = styled.div`
  height: 45px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 3px;
  align-items: center;
  transition: ease-in-out 0.3s;

  .name-group-chat-internal {
    font-family: 'Roboto', sans-serif;
    color: #383838;
    margin-right: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  cursor: pointer;
  &&:hover {
    -webkit-box-shadow: 0px 1px 13px -1px rgba(0, 0, 0, 0.24);
    box-shadow: 0px 1px 13px -1px rgba(0, 0, 0, 0.24);
  }
`;

export const ProfileInternalChat = styled.div`
  height: 38px;
  width: 38px;
  min-width: 38px;
  border-radius: 50%;
  background-color: gray;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: contain;
  margin: 0 15px 0 10px;
`;

export const ContentDate = styled.div`
  height: 38px;
  width: 43px;
  min-width: 43px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  margin-right: 8px;

  .hours-message-internal-chat {
    font-family: 'Roboto', sans-serif;
    font-size: 10px;
    font-weight: bold;
    color: #383838;
  }

  .qauntity-message-internal-chat {
    display: flex;
    font-family: 'Roboto', sans-serif;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 15px;
    background-color: #ca7272;
    font-size: 10px;
    width: 15px;
    border-radius: 50%;
    min-width: 15px;
  }
`;
export const ButtonAddGroup = styled.span`
  width: 8px;
  height: 8px;
  background-color: #47aa4e;
  border: transparent;
  border-radius: 50%;

  position: absolute;
  left: 41px;
  margin-top: 22px;
`;
export const Sinalization = styled.span`
  width: 12px;
  height: 12px;
  background-color: ${(props) => props.color};
  border: transparent;
  border-radius: 50%;
  margin-right: 0.5rem;
`;
export const HeaderGroup = styled.div`
  width: 100%;
  height: 36px;
  background-color: ${(props) => props.src};
  color: ${(props) => props.srcColor};
  font-size: 12;
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
  padding-top: 0.5rem;

  p {
    margin-left: 0.5rem;
    margin-top: -0.2rem;
    cursor: default;
  }
`;
export const MainGroup = styled.div`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;

  & > div {
    margin-bottom: 0.5rem;
    cursor: default;
    color: black;
  }
`;
export const CreateGroup = styled.div`
  width: 100%;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;

  label {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: white;
    border: 5px solid ${(props) => props.children.props.bordertheme};
  }
`;
export const InputGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  gap: 0.5rem;

  .input-name {
    margin-top: -2rem;
  }

  textarea {
    display: block;
    width: 100%;
    height: 80px;
    padding: 0.625rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8898aa;
    resize: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #dee2e6;
    border-radius: 0.25rem;
    box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);

    ::placeholder {
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 1.5;
      color: #8898aa;
    }

    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #c1c1c1;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
`;

export const ButtonGroup = styled.div`

  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;

  button{
    cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: ${(props) => props.buttoncolor};
  border: 1px solid ${(props) => props.buttoncolor};
  transition: 0.2s;
  }

  button:hover{
    filter: brightness(80%);
  }
  ]
`;
