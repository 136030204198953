import React, { useEffect } from 'react';
import Chart from 'chart.js';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import randomColor from 'randomcolor';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';
import { chartOptions, parseOptions } from 'variables/charts.js';
import { TotalRegister } from '../../../../../components/TotalRegister';

const Synthetic = ({ dataReport, totalRegister}) => {
  useEffect(() => {
    if (window.Chart) parseOptions(Chart, chartOptions());
    if (dataReport.listUraSyntheticDTO)
      if (dataReport.listUraSyntheticDTO.length > 0) {
        let arrayLabels = [];
        let arrayData = [];
        let colorsData = [];

        dataReport.listUraSyntheticDTO.forEach((data) => {
          arrayLabels.push(
            data.callDisposition === 'ANSWERED' ? 'Atendida' : 'Não Atendida'
          );
          arrayData.push(data.quantity);
          colorsData.push(randomColor({ hue: 'green' }));
        });
      }
  }, [dataReport]);

  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            <Card>
            {totalRegister >= 0 && (
              <TotalRegister totalRegister={totalRegister} />
              )}
              <ToolkitProvider
                data={dataReport.listUraSyntheticDTO || []}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'uraName',
                    text: 'Status da Chamada',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'lastUraOption',
                    text: 'Opção de destino da URA',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'amount',
                    text: 'Quantidade',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Synthetic;
