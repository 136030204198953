import React, { useState, useEffect, useCallback } from 'react';
import { Tooltip } from '@material-ui/core';
import Service from './service';
import QueueService from '../../../Queue/service';
import TagService from '../../../SatisfactionSurveyLevel/Tags/service';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'react-loader-spinner';
import { dateRules } from './assets/rulesValidation';
import { typesConfig, channels, groups } from './assets/types';
import { notes } from '../assets/notes';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import TableNps from './Tables/Nps';
import { useSelector } from 'react-redux';
import UserService from '../Analytical/service/UserService';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { SatisfactionSurveyPrint } from './styled';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import pdfImage from '../../../../assets/img/pdf.png';
import {
  ImageButtonContainer,
  ImageButton,
  ImageSize,
  ButtonDownloadPdf
} from '../../../../assets/styles/image_style';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';

const Report = () => {
  const { control, handleSubmit, errors, setValue, watch, getValues } =
    useForm();
  const [SatisfactionReport, setSatisfactionReport] = useState([]);
  const [SatisfactionTagsReport, setSatisfactionTagsReport] = useState();
  const [SatisfactionIndicators, setSatisfactionIndicators] = useState();
  // eslint-disable-next-line no-unused-vars
  const [dataToSubmitDownloads, setDataToSubmitDownloads] = useState();
  // eslint-disable-next-line no-unused-vars
  const [queues, setQueues] = useState([]);
  const [tags, setTags] = useState([]);
  const [loadingForm] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const showDisableQueue = watch('disable');
  const [ratesByTypeSurvey, setRatesByTypesSurvey] = useState([]);

  const [isLoadingPdf, setIsLoadingPdf] = useState(false);
  const typeSurvey = watch('type_survey');
  // const componentRef = useRef();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [agent, setAgent] = useState([]);

  useEffect(() => {
    QueueService.getAllShowDisable(showDisableQueue)
      .then((response) => {
        const data = response.data.data;
        const queues = data.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });
        setQueues(queues);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, [showDisableQueue]);

  useEffect(() => {
    TagService.showTags()
      .then((response) => {
        setTags(response.data.list);
      })
      .catch((err) => {
        console.log('erroListTag', err);
      });
  }, []);

  useEffect(() => {
    UserService.getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        setAgent(response.data.data);
      })
      .catch((err) => {
        console.log('error:' + err);
      });
  }, [usersShowDisable]);

  useEffect(() => {
    if (typeSurvey === 'nps') {
      const rates = notes.slice(0, 10);
      setRatesByTypesSurvey(rates);
    } else if (typeSurvey === 'satisfacao') {
      const rates = notes.slice(0, 5);
      setRatesByTypesSurvey(rates);
    } else {
      setRatesByTypesSurvey([]);
    }
  }, [typeSurvey]);

  const toISOFormat = useCallback(async (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${YYYY}-${MM}-${DD}`;
  }, []);

  const getDataDTO = useCallback(
    async (data) => {
      let dataToSend;

      const dataQueues = queues.filter((queue) => data.queue.includes(queue.id));
      
      const objTagsArray = tags.filter((tag) => {
        if (data.tags.includes(tag._id)) {
          return tag;
        }
        return null;
      });

      dataToSend = {
        queue: dataQueues|| '',
        initialDate: `${await toISOFormat(data.dateBegin)}`,
        finalDate: `${await toISOFormat(data.dateEnd)}`,
        type_survey: data.type_survey,
        channel: data.channel,
        tag: objTagsArray[0] || '',
        rates: data.rates || [],
        group: data.group,
        user: data.atendente || ''
      };

      setDataToSubmitDownloads(dataToSend);
      return dataToSend;
    },
    [toISOFormat, queues, tags]
  );

  const getReports = useCallback(
    (dataToSend, selectedPage = 1) => {
      setLoadingTable(true);
      try {
        const newActivePage = selectedPage || activePage;
        //requets general chart
        Service.fetchReports(dataToSend)
          .then((res) => {
            const { list } = res.data;
            setSatisfactionReport(list || []);
            setActivePage(newActivePage);
            if (list.length === 0) {
              toast.info('Nenhum registro foi encontrado!');
            }
          })
          .catch((err) => {
            console.log('erro na request de buscar dados relatorio=>' + err);
          });
        //request tags list
        Service.getTagsListReport(dataToSend)
          .then((res) => {
            setSatisfactionTagsReport(res.data);
          })
          .catch((err) => {
            console.log('erro na request de buscar dados tags=>' + err);
          });
        //request indicators of report
        Service.getIndicatorsOfSynthetic(dataToSend)
          .then((res) => {
            setSatisfactionIndicators(res.data);
          })
          .catch((err) => {
            console.log('Erro ao pega os indicadores da pesquisa' + err);
          });
      } catch (err) {
        console.log('error =>', err);
      } finally {
        setLoadingTable(false);
      }
    },
    [activePage]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        const dataToSend = await getDataDTO(data);
        // return console.log('data', dataToSend);
        getReports(dataToSend);
      } catch (err) {
        console.log(err);
        toast.error('Erro desconhecido por favor, informar o suporte!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [getDataDTO, getReports]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const handleDownloadPdf = async () => {
    setIsLoadingPdf(true);
    const elements = [
      document.getElementById('filteredSearch'),
      document.getElementById('searchIndicators'),
      document.getElementById('tagsContent')
    ];

    const pdf = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4',
      compress: true
    });

    let currentPageHeight = 10;
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margin = 10;
    const availableWidth = pageWidth - 2 * margin;
    const reportName = `satisfaction-report-${new Date().toISOString()}.pdf`;

    const addImageToPDF = async (element) => {
      if (element) {
        const canvas = await html2canvas(element);
        const imgData = canvas.toDataURL('image/png');

        const pixelToMm = 0.264583;
        const elementHeightMm = canvas.height * pixelToMm;
        const elementWidthMm = canvas.width * pixelToMm;

        if (currentPageHeight + elementHeightMm >= pageHeight) {
          pdf.addPage();
          currentPageHeight = margin;
        }

        // Redimensione a imagem proporcionalmente
        const scaleFactor = availableWidth / elementWidthMm;
        const imageWidth = elementWidthMm * scaleFactor;
        const imageHeight = elementHeightMm * scaleFactor;

        pdf.addImage(
          imgData,
          'PNG',
          margin,
          currentPageHeight,
          imageWidth,
          imageHeight
        );
        currentPageHeight += imageHeight;
      }
    };

    for (const element of elements) {
      await addImageToPDF(element);
    }

    pdf.save(reportName);
    setIsLoadingPdf(false);
    toast.info(`Baixando relatório`);
  };


  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatorio sintético de satisfação</h3>
                </CardHeader>
                <CardBody>
                  {loadingForm ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">Fila</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={queues}
                                isMulti={true}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="queue"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="queue"
                            defaultValue=""
                          />
                        </Col>

                        <Col md="3">
                          <label className="form-control-label">Agente</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={agent}
                                isMulti={true}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="atendente"
                                labelName="name"
                                valueName="name"
                              />
                            )}
                            control={control}
                            name="atendente"
                            defaultValue=""
                          />
                        </Col>

                        <Col md="3">
                          <label className="form-control-label">Canal</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={channels}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="channel"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="channel"
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Data inicial*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateBegin"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateBegin"
                            rules={dateRules}
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateBegin"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Data final*
                          </label>
                          <Controller
                            render={(props) => (
                              <DatePicker
                                fieldName="dateEnd"
                                valueController={setValue}
                                defaultValue={props.value}
                              />
                            )}
                            control={control}
                            name="dateEnd"
                            defaultValue={new Date().toLocaleDateString(
                              'pt-br'
                            )}
                            rules={{
                              ...dateRules,
                              validate: async (value) => {
                                if (
                                  new Date(await toISOFormat(value, true)) <
                                  new Date(
                                    await toISOFormat(
                                      getValues()['dateBegin'],
                                      true
                                    )
                                  )
                                ) {
                                  return 'A data final não pode ser anterior à inicial!';
                                } else {
                                  return true;
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="dateEnd"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md="6">
                          <label className="form-control-label">
                            Tipo de pesquisa*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={typesConfig}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                defaultValue="satisfacao"
                                fieldName="type_survey"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            rules={{
                              required: 'Campo obrigatório!'
                            }}
                            control={control}
                            name="type_survey"
                            defaultValue="satisfacao"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="type_survey"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Tag</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={tags}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="tags"
                                labelName="name"
                                valueName="_id"
                              />
                            )}
                            control={control}
                            name="tags"
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <Row className="mb-12">
                        <Col md="6">
                          <label className="form-control-label">
                            Agrupar por*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={groups}
                                isMulti={false}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={true}
                                valueController={setValue}
                                fieldName="group"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            rules={{
                              required: 'Este campo é obrigátorio'
                            }}
                            control={control}
                            name="group"
                            defaultValue="rate"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="group"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Nota da pesquisa
                          </label>

                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={ratesByTypeSurvey}
                                isMulti={true}
                                isClearable={true}
                                value={props.value}
                                closeMenuOnSelect={false}
                                valueController={setValue}
                                fieldName="rates"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="rates"
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="rates"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-12">
                        <Col className="md-5 mx-4">
                          <div style={{ display: 'flex', gap: '20px' }}>
                            <Controller
                              name="disable"
                              control={control}
                              render={(props) => (
                                <>
                                  <label className="form-control-label">
                                    <Input
                                      type="checkbox"
                                      onChange={(e) =>
                                        props.onChange(e.target.checked)
                                      }
                                      checked={props.value}
                                    />
                                    Mostrar desabilitados (Fila)
                                  </label>
                                </>
                              )}
                              defaultValue=""
                            />
                            <UserFlexSelect
                              usersShowDisable={usersShowDisable}
                              setUsersShowDisable={setUsersShowDisable}
                              name={'Agentes'}
                            />
                          </div>
                        </Col>
                      </Row>

                      <Button
                        style={buttonStyle(systemConfiguration.primary_color)}
                        type="submit"
                      >
                        Buscar
                      </Button>
                    </Form>
                  )}
                </CardBody>
                {loadingTable ? (
                  <div className="py-4 table-responsive">
                    <Row className="justify-content-md-center">
                      {loadingTable && (
                        <Loader
                          type="TailSpin"
                          color={systemConfiguration.primary_color}
                          height={100}
                          width={100}
                        />
                      )}
                    </Row>
                  </div>
                ) : (
                  <>
                    {SatisfactionReport.length > 0 &&
                      SatisfactionTagsReport &&
                      SatisfactionIndicators && (
                        <>
                          <Col md="12" style={ImageButtonContainer}>
                            <button
                              style={ImageButton}
                              onClick={handleDownloadPdf}
                            >
                              <Tooltip
                                title={
                                  <span style={{ fontSize: '14px' }}>
                                    {
                                      'Clique para realizar o relatório sintético em PDF'
                                    }
                                  </span>
                                }
                                arrow
                              >
                                <div style={ButtonDownloadPdf}>
                                  {isLoadingPdf ? (
                                    <Loader
                                      type="ThreeDots"
                                      visible={true}
                                      height="40"
                                      width="40"
                                    />
                                  ) : (
                                    <img
                                      src={pdfImage}
                                      alt="pdf"
                                      style={ImageSize}
                                    />
                                  )}
                                </div>
                              </Tooltip>
                            </button>
                          </Col>

                          <SatisfactionSurveyPrint id="print">
                            <TableNps
                              dataReport={SatisfactionReport}
                              dataTagsCloud={SatisfactionTagsReport}
                              dataIndicators={SatisfactionIndicators}
                              typeSurvey={typeSurvey}
                            />
                          </SatisfactionSurveyPrint>
                        </>
                      )}
                  </>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default Report;
