import React, { useState, useCallback, useEffect } from 'react';
import { Row } from 'reactstrap';

import Loader from 'components/Loader';
import { toast } from 'react-toastify';

import Pagination from 'components/Pagination';
import { AnalyticalLeadTime } from '../../../../components/Tables/AnalyticalLeadTime';
import { SyntheticLeadTime } from '../../../../components/Tables/Synthetic';
import { useListLeads } from '../../../hooks/useListLeadTime';
import FilterLeadTimeForm from '../FilterLeadTime';

const ReportLeadTime = ({
  selectedRecordTypeId,
  setSelectedRecordType,
  recordTypes
}) => {
  const [activePage, setActivePage] = useState(1);
  const [reportType, setReportType] = useState(1);
  const [analyticalData, setAnalyticalData] = useState([]);
  const [syntheticalData, setSyntheticalData] = useState([]);
  const [quantity, setQuantity] = useState(0);
  const [dataToSend, setDataToSend] = useState({});

  const {
    getReportLeadTime,
    leads,
    totalLeads,
    getReportSyntheticLeadTime,
    syntheticData,
    isLoadingLeads,
    isLoadingsyntheticLeads
  } = useListLeads({
    recordTypeId: selectedRecordTypeId
  });

  const handleSearch = useCallback(
    (searchFilters, reportTypeSubmit, page = 1) => {
      setActivePage(1);
      setReportType(reportTypeSubmit);
      if (reportTypeSubmit === 1) {
        getReportLeadTime(page, searchFilters);
      }

      if (reportTypeSubmit === 2) {
        getReportSyntheticLeadTime(page, searchFilters);
      }
    },
    [getReportLeadTime, getReportSyntheticLeadTime]
  );

  useEffect(() => {
    if (reportType === 1) {
      if (leads && leads.length) {
        setAnalyticalData(leads);
        setQuantity(totalLeads);
      }

      if (leads && !leads.length) {
        setAnalyticalData(leads);
        setQuantity(totalLeads);
        toast.info('Nenhum registro foi encontrado');
      }
    }

    if (reportType === 2) {
      if (syntheticData) {
        setSyntheticalData(syntheticData);
      }
    }
  }, [leads, totalLeads, reportType, syntheticData]);

  const handlePageChange = (page) => {
    setActivePage(page);
    getReportLeadTime(page, dataToSend);
  };

  return (
    <>
      <FilterLeadTimeForm
        handleSearch={handleSearch}
        recordTypeId={selectedRecordTypeId}
        leads={leads}
        setSelectedRecordType={setSelectedRecordType}
        recordTypes={recordTypes}
        analyticalData={analyticalData}
        setAnalyticalData={setAnalyticalData}
        quantity={quantity}
        setDataToSend={setDataToSend}
        reportType={reportType}
      />

      {reportType === 2 ? <hr /> : null}
      {analyticalData && analyticalData.length > 0 && reportType === 1 ? (
        isLoadingLeads ? (
          <div className="col-12">
            <Row className="justify-content-md-center">
              <Loader />
            </Row>
          </div>
        ) : (
          <div style={{ marginTop: '1rem' }}>
            <AnalyticalLeadTime data={analyticalData} />
            <Pagination
              activePage={activePage}
              totalItemsCount={quantity}
              onChange={handlePageChange.bind(this)}
            />
          </div>
        )
      ) : null}
      {syntheticalData &&
      Object.keys(syntheticalData).length >= 1 &&
      reportType === 2 ? (
        isLoadingsyntheticLeads ? (
          <div className="col-12">
            <Row className="justify-content-md-center">
              <Loader />
            </Row>
          </div>
        ) : (
          <div style={{ marginTop: '1rem' }}>
            <SyntheticLeadTime data={syntheticalData} />
          </div>
        )
      ) : null}
    </>
  );
};

export default ReportLeadTime;
