/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ExtensionService from '../../../../views/Extension/service/';
import QueueService from '../../../../views/Queue/service';
import ContextService from '../../../../views/Context/service';
import ProfileService from '../../../../views/Profile/service';
import SectorService from '../../../../views/Sector/service';
import ScaleService from '../../../Scales/service/';
import UserServiceUpdateAll from '../service/index';
import FlexSelect from 'components/Inputs/FlexSelect';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { toast } from 'react-toastify';
import { brazilian } from 'assets/translator/pt-br';
import { useHistory, useLocation } from 'react-router-dom';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  Button,
  CardBody,
  FormGroup
} from 'reactstrap';

const UserFormMulti = () => {
  const history = useHistory();
  const location = useLocation();
  const { actionType, type, users = [] } = location.state;
  console.log(location.state);

  const { control, handleSubmit, errors, clearErrors, setValue } = useForm();
  const [contexts, setContexts] = useState([]);
  const [isDisabledInput, setisDisabledInput] = useState(false);
  const [isDisabledInputLogin, setisDisabledInputLogin] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [queuesidtosign, setQueuesidtosign] = useState([]);
  const [scaleid, setScaleid] = useState([]);
  const [queuesid, setQueuesid] = useState([]);
  const [workScales, setworkScales] = useState([]);
  const [FormatedUsers, setFormatedUsers] = useState([]);
  const [profilesid, setProfilesid] = useState([]);
  const [extensionsid, setExtensions] = useState([]);
  const [costcenterid, setCostcenterid] = useState([]);
  const [isSupervisor, setisSupervisor] = useState(false);
  const [showAllConversations, setShowAllConversations] = useState(false);
  const [enableControl, setEnableControl] = useState({
    profile: false,
    context: false,
    should_sign_in_asterisk: false,
    queuesidtosign: false,
    usescale: false,
    scaleid: false,
    show_all_conversations: false,
    extensionsid: false,
    queuesid: false,
    profilesid: false,
    costcenterid: false,
    isSupervisor: false
  });
  useEffect(() => {
    ExtensionService.getAll()
      .then((response) => {
        setExtensions(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);
  useEffect(() => {
    QueueService.getAll()
      .then((response) => {
        setQueuesid(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);
  useEffect(() => {
    ProfileService.getAll()
      .then((response) => {
        const profileList = response.data.data;
        const profilesTranslated = toTranslatedProfiles(profileList);
        setProfilesid(profilesTranslated);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);
  useEffect(() => {
    SectorService.getAll()
      .then((response) => {
        setCostcenterid(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);
  useEffect(() => {
    ContextService.getAll()
      .then((response) => {
        setContexts(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);
  async function loadApiScale() {
    const Params = {
      page: 0,
      size: 0,
      showDisable: false,
      querySearch: ''
    };
    ScaleService.getAllSearch(
      Params.showDisabled,
      Params.size,
      Params.page,
      Params.querySearch
    ).then((response) => {
      const getScale = response.data.data.map((scale) => {
        return {
          name: scale.name,
          id: scale.id
        };
      });
      setworkScales(getScale);
    });
  }
  useEffect(() => {
    loadApiScale();
  }, []);

  useEffect(() => {
    let FormatedUsersArray = [];
    const formatedUsers = users.map((user) => {
      FormatedUsersArray.push(user.id);
    });
    setFormatedUsers(FormatedUsersArray);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const onSubmit = async (data) => {
    try {
      const FormatData = {
        ...data,
        should_sign_in_asterisk: isDisabledInputLogin,
        show_all_conversations: showAllConversations,
        logins: FormatedUsers,
        shouldUpdateAll: false,
        isSupervisor
      };
      await UserServiceUpdateAll.updateConfig(FormatData).then(() => {
        toast.success('Usuários cadastrados com sucesso');
        history.push('/admin/User');
      });
    } catch (err) {
      toast.error('Ocorreu uma falha na atualização dos usuários');
    }
  };
  //updateConfig
  function toTranslatedProfiles(listProfiles) {
    const translatedBrazilianProfiles = brazilian.PROFILES;
    const profilesTranslated = listProfiles.map((profile) => {
      if (translatedBrazilianProfiles[profile.name]) {
        profile.name = translatedBrazilianProfiles[profile.name];
      }
      return profile;
    });

    return profilesTranslated;
  }

  const handleToggleField = (fieldName) => {
    clearErrors(fieldName);
    setEnableControl({
      ...enableControl,
      [fieldName]: !enableControl[fieldName]
    });
  };
  const isSomeChecked = () => {
    const values = Object.values(enableControl);
    return values.some((v) => v);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/user');
  };

  const handleWithQueueLogin = () => {
    if (isDisabledInput) {
      setisDisabledInputLogin(true);
    } else {
      setisDisabledInputLogin(!isDisabledInputLogin);
    }
  };
  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">
                    {actionType === 'EDIT_MULTIPLE'
                      ? 'Edição de Múltiplos Usuários'
                      : 'Edição de todos os Usuários'}
                  </h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <>
                      <Row style={{ paddingTop: '15px' }}>
                        <Col md="6">
                          <div className="d-flex flex-row pl-2">
                            <div className="mt-5">
                              <Input
                                type="checkbox"
                                checked={enableControl.profile}
                                onChange={() => handleToggleField('profile')}
                              />
                            </div>
                            <div className="col col-12">
                              <label className="form-control-label">
                                Perfil de acesso
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={profilesid}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="profileId"
                                    labelName="name"
                                    valueName="id"
                                    isDisabled={!enableControl.profile}
                                  />
                                )}
                                control={control}
                                name="profileId"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="profileId"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md="6">
                          <div className="d-flex flex-row pl-2">
                            <div className="mt-5">
                              <Input
                                type="checkbox"
                                checked={enableControl.context}
                                onChange={() => handleToggleField('context')}
                              />
                            </div>
                            <div className="col col-12">
                              <label className="form-control-label">
                                Contexto*
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={true}
                                    dataOptions={contexts}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="context"
                                    labelName="name"
                                    valueName="id"
                                    isDisabled={!enableControl.context}
                                  />
                                )}
                                control={control}
                                name="context"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="context"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '2rem' }}>
                        <Col sm="6">
                          <div className="row ma-0 pl-2">
                            <div className="col col-1 align-middle">
                              <Input
                                type="checkbox"
                                checked={enableControl.should_sign_in_asterisk}
                                onChange={() =>
                                  handleToggleField('should_sign_in_asterisk')
                                }
                              />
                            </div>
                            <div
                              className="col col-11"
                              style={{ marginLeft: '5.5rem' }}
                            >
                              <Controller
                                name="show_all_conversations"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <>
                                    <label
                                      className="form-control-label"
                                      style={{ marginLeft: '-3.1rem' }}
                                    >
                                      <Input
                                        type="checkbox"
                                        checked={isDisabledInputLogin}
                                        onChange={() => handleWithQueueLogin()}
                                        disabled={
                                          !enableControl.should_sign_in_asterisk
                                        }
                                      />
                                      Logar nas filas automaticamente ao logar
                                      no sistema
                                    </label>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="row ma-0 pl-2">
                            <div className="col col-1 align-middle">
                              <Input
                                type="checkbox"
                                checked={enableControl.usescale}
                                onChange={() => handleToggleField('usescale')}
                              />
                            </div>
                            <div
                              className="col col-11"
                              style={{ marginLeft: '5.5rem' }}
                            >
                              <Controller
                                name="show_all_conversations"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <>
                                    <label
                                      className="form-control-label"
                                      style={{ marginLeft: '-3.1rem' }}
                                    >
                                      <Input
                                        type="checkbox"
                                        disabled={!enableControl.usescale}
                                        onChange={() => {
                                          setisDisabledInputLogin(true);
                                          setEnableControl({
                                            ...enableControl,
                                            should_sign_in_asterisk: true
                                          });
                                          setisDisabledInput(!isDisabledInput);
                                        }}
                                        checked={isDisabledInput}
                                      />
                                      Utilizar escala de trabalho ao logar
                                    </label>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '2rem' }}>
                        {isDisabledInputLogin ? (
                          <Col md="6">
                            <div className="d-flex flex-row pl-2">
                              <div className="mt-5">
                                <Input
                                  type="checkbox"
                                  checked={enableControl.queuesidtosign}
                                  onChange={() =>
                                    handleToggleField('queuesidtosign')
                                  }
                                />
                              </div>
                              <div className="col col-12">
                                <label className="form-control-label">
                                  Selecione a(s) Fila(s) para login automático
                                </label>
                                <Controller
                                  render={(props) => (
                                    <FlexSelect
                                      dataOptions={queuesid}
                                      placeholder="Selecione a(s) fila(s)"
                                      isClearable={true}
                                      isMulti={true}
                                      value={props.value}
                                      valueController={setValue}
                                      fieldName="queuesidToSignIn"
                                      labelName="name"
                                      valueName="id"
                                      isDisabled={!enableControl.queuesidtosign}
                                    />
                                  )}
                                  control={control}
                                  name="queuesidToSignIn"
                                  defaultValue=""
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="queuesidToSignIn"
                                  render={({ message }) =>
                                    errorFormMessage(message)
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                        {isDisabledInput ? (
                          <Col md="6">
                            <div className="d-flex flex-row pl-2">
                              <div className="mt-5">
                                <Input
                                  type="checkbox"
                                  checked={enableControl.scaleid}
                                  onChange={() => handleToggleField('scaleid')}
                                />
                              </div>
                              <div className="col col-12">
                                <label className="form-control-label">
                                  Escalas
                                </label>
                                <Controller
                                  render={(props) => (
                                    <FlexSelect
                                      dataOptions={workScales}
                                      placeholder="Selecione a escala"
                                      isClearable={true}
                                      isMulti={false}
                                      value={props.value}
                                      valueController={setValue}
                                      fieldName="scaleId"
                                      labelName="name"
                                      valueName="id"
                                      isDisabled={!enableControl.scaleid}
                                    />
                                  )}
                                  control={control}
                                  name="scaleId"
                                  defaultValue=""
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="scaleId"
                                  render={({ message }) =>
                                    errorFormMessage(message)
                                  }
                                />
                              </div>
                            </div>
                          </Col>
                        ) : (
                          <></>
                        )}
                        <Col className="mt-5">
                          <div className="d-flex flex-row pl-2">
                            <div>
                              <Input
                                type="checkbox"
                                checked={enableControl.isSupervisor}
                                onChange={() =>
                                  handleToggleField('isSupervisor')
                                }
                              />
                            </div>
                            <div className="col col-12">
                              <label className="form-control-label">
                                <input
                                  type="checkbox"
                                  checked={isSupervisor}
                                  onChange={() => {
                                    setisSupervisor(!isSupervisor);
                                  }}
                                  style={{ marginRight: '0.5rem' }}
                                />
                                Supervisor
                              </label>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <hr />
                      <h3>Permissões</h3>
                      <Row>
                        <Col sm="12">
                          <div className="row ma-0 pl-2">
                            <div className="col col-1 align-middle">
                              <Input
                                type="checkbox"
                                checked={enableControl.show_all_conversations}
                                onChange={() =>
                                  handleToggleField('show_all_conversations')
                                }
                              />
                            </div>
                            <div className="col col-11">
                              <Controller
                                name="show_all_conversations"
                                control={control}
                                defaultValue={false}
                                render={(props) => (
                                  <>
                                    <label
                                      className="form-control-label"
                                      style={{ marginLeft: '-3.1rem' }}
                                    >
                                      <Input
                                        type="checkbox"
                                        disabled={
                                          !enableControl.show_all_conversations
                                        }
                                        onChange={() => {
                                          setShowAllConversations(true);
                                        }}
                                        checked={showAllConversations}
                                      />
                                      Relatório de conversas de todos os
                                      usuários
                                    </label>
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '2rem' }}>
                        <Col md="6">
                          <div className="d-flex flex-row pl-2">
                            <div className="mt-3">
                              <Input
                                type="checkbox"
                                checked={enableControl.extensionsid}
                                onChange={() =>
                                  handleToggleField('extensionsid')
                                }
                              />
                            </div>
                            <div
                              className="col col-12"
                              style={{ marginTop: '-1.3rem' }}
                            >
                              <label className="form-control-label">
                                Ramais
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={extensionsid}
                                    placeholder="Selecione os ramais"
                                    isClearable={true}
                                    isMulti={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="extensionsId"
                                    labelName="extension_number"
                                    valueName="extension_number"
                                    isDisabled={!enableControl.extensionsid}
                                  />
                                )}
                                control={control}
                                name="extensionsId"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="extensionsId"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="d-flex flex-row pl-2">
                            <div className="mt-3">
                              <Input
                                type="checkbox"
                                checked={enableControl.queuesid}
                                onChange={() => handleToggleField('queuesid')}
                              />
                            </div>
                            <div
                              className="col col-12"
                              style={{ marginTop: '-1.3rem' }}
                            >
                              <label className="form-control-label">
                                Filas
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={queuesid}
                                    placeholder="Selecione a(s) fila(s)"
                                    isClearable={true}
                                    isMulti={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="queuesid"
                                    labelName="name"
                                    valueName="id"
                                    isDisabled={!enableControl.queuesid}
                                  />
                                )}
                                control={control}
                                name="queuesid"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="queuesid"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '2rem' }}>
                        <Col md="6">
                          <div className="d-flex flex-row pl-2">
                            <div className="mt-3">
                              <Input
                                type="checkbox"
                                checked={enableControl.profilesid}
                                onChange={() => handleToggleField('profilesid')}
                              />
                            </div>
                            <div
                              className="col col-12"
                              style={{ marginTop: '-1.3rem' }}
                            >
                              <label className="form-control-label">
                                Criação de novos usuários
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={profilesid}
                                    placeholder="Selecione os perfis"
                                    isMulti={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="profilesid"
                                    labelName="name"
                                    valueName="id"
                                    isDisabled={!enableControl.profilesid}
                                  />
                                )}
                                control={control}
                                name="profilesid"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="profilesid"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </div>
                          </div>
                        </Col>
                        <Col md="6">
                          <div className="d-flex flex-row pl-2">
                            <div className="mt-3">
                              <Input
                                type="checkbox"
                                checked={enableControl.costcenterid}
                                onChange={() =>
                                  handleToggleField('costcenterid')
                                }
                              />
                            </div>
                            <div
                              className="col col-12"
                              style={{ marginTop: '-1.3rem' }}
                            >
                              <label className="form-control-label">
                                Setor(es)
                              </label>
                              <Controller
                                render={(props) => (
                                  <FlexSelect
                                    dataOptions={costcenterid}
                                    isMulti={true}
                                    value={props.value}
                                    valueController={setValue}
                                    fieldName="costscenterid"
                                    labelName="name"
                                    valueName="id"
                                    isDisabled={!enableControl.costcenterid}
                                  />
                                )}
                                control={control}
                                name="costscenterid"
                                defaultValue=""
                              />
                              <ErrorMessage
                                errors={errors}
                                name="costcenterid"
                                render={({ message }) =>
                                  errorFormMessage(message)
                                }
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="mt-5">
                        <Button
                          color="primary"
                          type="submit"
                          disabled={!isSomeChecked()}
                        >
                          Salvar
                        </Button>
                        <Button color="danger" onClick={() => cancelAction()}>
                          Cancelar
                        </Button>
                      </div>
                    </>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default UserFormMulti;
