const { default: styled } = require('styled-components');

const StyledInput = styled.input`
  ${(props) =>
    props.disabled &&
    props.checked &&
    `
    appearance: none;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    border: 2px solid green;
    background-color: green;
`}
`;

export default StyledInput;
