import React from 'react';
import { Table } from 'reactstrap';

const thStyle = {
  whiteSpace: 'break-spaces',
  textAlign: 'center'
};

const AnsweredCallsTable = ({ data }) => {
  return (
    <Table className="mb-5 overflow-y-visible" bordered={true} striped>
      <thead>
        <tr>
          <th style={thStyle}>Intervalo</th>
          <th style={thStyle}>Chamadas atendidas</th>
          <th style={thStyle}>Chamadas Abandonadas</th>
          <th style={thStyle}>Total</th>
          <th style={thStyle}>Tempo Médio de Espera</th>
          <th style={thStyle}>Tempo Médio de Atendimento</th>
          <th style={thStyle}>Tempo Total de Atendimento</th>
          <th style={thStyle}>Nível de Serviço (%)</th>
          <th style={thStyle}>Chamadas Atendidas (%)</th>
          <th style={thStyle}>Chamadas Abandonadas (%)</th>
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((row, i) => (
            <tr key={i}>
              <td>{row.interval}</td>
              <td>{row.qtdAnswered}</td>
              <td>{row.qtdAbandoned}</td>
              <td>{row.qtdTotal}</td>
              <td>{row.tme || ''}</td>
              <td>{row.tma || ''}</td>
              <td>{row.tta || ''}</td>
              <td>{row.serviceLevel}</td>
              <td>{row.answeredPercentage}</td>
              <td>{row.abandonedPercentage}</td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default AnsweredCallsTable;
