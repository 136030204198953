import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { Row, Col, Input } from 'reactstrap';

export const InstagramForm = ({ canal, control, errors }) => {
  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <Row>
        <Col md="6">
          <label className="form-control-label">Nome do canal</label>
          <Controller
            as={Input}
            control={control}
            name="nome_canal"
            defaultValue={canal?.nome_canal ?? ''}
            rules={{
              required: 'nome é um campo obrigatório'
            }}
          />
          <ErrorMessage
            errors={errors}
            name="nome_canal"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6">
          <label className="form-control-label">Descrição</label>
          <Controller
            as={Input}
            control={control}
            name="descricao"
            rules={{
              required: 'descrição é um campo obrigatório'
            }}
            defaultValue={canal?.descricao ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="descricao"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <label className="form-control-label">Token Instagram</label>
          <Controller
            as={Input}
            control={control}
            name="tokenInstagram"
            rules={{
              required: 'Token Instagram é um campo obrigatório'
            }}
            defaultValue={canal?.tokenInstagram ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="tokenInstagram"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6">
          <label className="form-control-label">Instagram Access Token</label>
          <Controller
            as={Input}
            control={control}
            name="instagram_access_token"
            rules={{
              required: 'Instagram Access Token é um campo obrigatório'
            }}
            defaultValue={canal?.instagram_access_token ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="instagram_access_token"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6">
          <label className="form-control-label">Instagram Page Id</label>
          <Controller
            as={Input}
            control={control}
            name="pageId"
            rules={{
              required: 'Instagram Page Id é um campo obrigatório'
            }}
            defaultValue={canal?.pageId ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="pageId"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        {/* <Col md="3">
          <label className="form-control-label">Custo por conversa (US$)</label>
          <Controller
            as={Input}
            control={control}
            name="custoPorConversa"
            rules={{
              required: 'Custo por conversa é um campo obrigatório'
            }}
            defaultValue={canal?.custoPorConversa ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="custoPorConversa"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="3">
          <label className="form-control-label">Cotação do dólar</label>
          <Controller
            as={Input}
            control={control}
            name="cotacaoDolar"
            rules={{
              required: 'Cotação do dólar é um campo obrigatório'
            }}
            defaultValue={canal?.cotacaoDolar ?? ''}
          />
          <ErrorMessage
            errors={errors}
            name="cotacaoDolar"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col> */}
      </Row>
    </>
  );
};
