import { queueAgentStatus } from './states';

export const statusColors = Object.freeze({
  [queueAgentStatus.AVAILABLE]: 'rgb(19, 133, 77)',
  [queueAgentStatus.RING]: '#e03623',
  [queueAgentStatus.RINGING]: '#DB9513',
  [queueAgentStatus.PAUSE]: '#2365e0',
  [queueAgentStatus.UNPLACED]: 'rgb(101, 112, 104)',
  [queueAgentStatus.TABULATION_PAUSE]: '#f580f4'
});
