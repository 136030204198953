import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Col, Form, Input, Label, Modal, Row } from 'reactstrap';

import DefaultModal from '../../../../components/Modal/DefaultModal';
import ReactButton from '../../../../components/Buttons/ReactButton';
import AssessmentService from '../service';
import { toast } from 'react-toastify';
import { AudioStreamPlayer } from 'components/AudioController/AudioStreamPlayer';
import FlexSelect from 'components/Inputs/FlexSelect';

export default function MonitoringForm({
  isModalOpen,
  onModalToggle,
  onSubmitForm,
  isViewMode = false,
  cdrReport,
  cdrUniqueId,
  dataReport,
  ReportService,
  paper
}) {
  const filterListCall = dataReport?.listCallInAnalyticalDTO?.filter(function (
    value
  ) {
    return value.uniqueid == cdrUniqueId;
  })[0];

  const { control, handleSubmit, watch, setValue } = useForm();

  const [isOpen, setIsOpen] = useState(false);

  async function onSubmit(data) {
    let description = data.description;
    let category = data.category ?? undefined;
    delete data.description;
    delete data.category;

    const scores = Object.keys(data).map((key) => {
      return {
        question: Number(key),
        total: Number(data[key])
      };
    });
    try {
      await AssessmentService.create({
        cdrReport,
        paper: paper.id,
        description,
        category,
        scores: scores.filter(
          (score) => (score.total != undefined) != null && score.total != -1
        )
      });

      toast.success('Monitoria cadastrada com sucesso!');
      onModalToggle(false);
      onSubmitForm();
    } catch (err) {
      toast.error('Ocorreu um erro ao cadastrar monitoria.', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  }

  function onCancel() {
    onModalToggle(false);
  }

  function getAverage() {
    if (!paper) return (0).toFixed(2);
    let sumOfScores = 0;
    let sumOfWeightages = 0;

    const checkedCriticalQuestions = paper.questions.filter(
      (q) => q.enabled && q.type == 'CRITICAL' && watch(`${q.id}`) != 0
    );

    if (checkedCriticalQuestions.length != 0) return (0).toFixed(2);

    paper.questions
      .filter((q) => q.enabled && q.type == 'WEIGHTAGE')
      .forEach((question) => {
        const score = watch(`${question.id}`);
        if (score && score != -1) {
          sumOfScores += score * question.weightage;
          sumOfWeightages += question.weightage;
        }
        return question;
      });

    if (sumOfScores != 0) {
      return (sumOfScores / sumOfWeightages).toFixed(2);
    }
    return sumOfScores.toFixed(2);
  }

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Monitoria de qualidade"
      modalContainerClasses="modal-lg"
      showCloseButton={isViewMode}
    >
      <div>
        <AudioStreamPlayer
          uniqueid={cdrUniqueId}
          nameAgent={filterListCall?.nameAgent || ''}
          calldateStart={filterListCall?.callDateStartFormated || ''}
          ReportService={ReportService}
          Origem={filterListCall?.clidNum || ''}
          Queue={filterListCall?.queue || ''}
          MonitoringForm={true}
        />

        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          {paper?.questions
            .filter((q) => q.enabled)
            .map((q) => (
              <Row className="mb-5" key={q.id}>
                <Col
                  md="12"
                  className="d-flex justify-content-center text-center"
                >
                  <p className="font-weight-400">{`${q.description} ${
                    q.required ? '*' : ''
                  }`}</p>
                </Col>
                <Col md="3" className="d-flex justify-content-center">
                  <strong className="text-uppercase font-weight-600">
                    Não Conforme
                  </strong>
                </Col>
                <Col md="6">
                  <div className="d-flex justify-content-around">
                    {q.type === 'CRITICAL' && (
                      <Controller
                        render={(props) => (
                          <>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_0`}
                                type="radio"
                                value={1}
                              />
                              <Label htmlFor={`${q.id}_0`}>Não</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_1`}
                                type="radio"
                                value={0}
                              />
                              <Label htmlFor={`${q.id}_1`}>Sim</Label>
                            </div>
                          </>
                        )}
                        control={control}
                        name={`${q.id}`}
                        rules={{
                          required: true
                        }}
                      />
                    )}

                    {paper?.type === 'basic' && q.type === 'WEIGHTAGE' && (
                      <Controller
                        render={(props) => (
                          <>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_1`}
                                type="radio"
                                value={1}
                              />
                              <Label htmlFor={`${q.id}_1`}>1</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_2`}
                                type="radio"
                                value={2}
                              />
                              <Label htmlFor={`${q.id}_2`}>2</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_3`}
                                type="radio"
                                value={3}
                              />
                              <Label htmlFor={`${q.id}_3`}>3</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_4`}
                                type="radio"
                                value={4}
                              />
                              <Label htmlFor={`${q.id}_4`}>4</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_5`}
                                type="radio"
                                value={5}
                              />
                              <Label htmlFor={`${q.id}_5`}>5</Label>
                            </div>
                            {q.required ? null : (
                              <div className="d-flex flex-column align-items-center">
                                <input
                                  {...props}
                                  id={`${q.id}_exclude`}
                                  type="radio"
                                  value={-1}
                                />
                                <Label htmlFor={`${q.id}_exclude`}>
                                  Não se aplica
                                </Label>
                              </div>
                            )}
                          </>
                        )}
                        control={control}
                        name={`${q.id}`}
                        rules={{
                          required: true
                        }}
                      />
                    )}

                    {paper?.type === 'advanced' && q.type === 'WEIGHTAGE' && (
                      <Controller
                        render={(props) => (
                          <>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_1`}
                                type="radio"
                                value={1}
                              />
                              <Label htmlFor={`${q.id}_1`}>1</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_2`}
                                type="radio"
                                value={2}
                              />
                              <Label htmlFor={`${q.id}_2`}>2</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_3`}
                                type="radio"
                                value={3}
                              />
                              <Label htmlFor={`${q.id}_3`}>3</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_4`}
                                type="radio"
                                value={4}
                              />
                              <Label htmlFor={`${q.id}_4`}>4</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_5`}
                                type="radio"
                                value={5}
                              />
                              <Label htmlFor={`${q.id}_5`}>5</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_6`}
                                type="radio"
                                value={6}
                              />
                              <Label htmlFor={`${q.id}_6`}>6</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_7`}
                                type="radio"
                                value={7}
                              />
                              <Label htmlFor={`${q.id}_7`}>7</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_8`}
                                type="radio"
                                value={8}
                              />
                              <Label htmlFor={`${q.id}_8`}>8</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_9`}
                                type="radio"
                                value={9}
                              />
                              <Label htmlFor={`${q.id}_9`}>9</Label>
                            </div>
                            <div className="d-flex flex-column align-items-center">
                              <input
                                {...props}
                                id={`${q.id}_10`}
                                type="radio"
                                value={10}
                              />
                              <Label htmlFor={`${q.id}_10`}>10</Label>
                            </div>
                            {q.required ? null : (
                              <div className="d-flex flex-column align-items-center">
                                <input
                                  {...props}
                                  id={`${q.id}_exclude`}
                                  type="radio"
                                  value={-1}
                                />
                                <Label htmlFor={`${q.id}_exclude`}>
                                  Não se aplica
                                </Label>
                              </div>
                            )}
                          </>
                        )}
                        control={control}
                        name={`${q.id}`}
                        rules={{
                          required: true
                        }}
                      />
                    )}
                  </div>
                </Col>
                <Col md="3" className="d-flex justify-content-center">
                  <strong className="text-uppercase font-weight-600">
                    Conforme
                  </strong>
                </Col>
              </Row>
            ))}
          {paper?.categories.filter((category) => category.enabled)?.length !=
          0 ? (
            <div className="mb-2">
              <h4>{`Categoria:`}</h4>
              <Controller
                render={(props) => (
                  <FlexSelect
                    dataOptions={[
                      {
                        label: 'Selecione uma categoria',
                        value: null
                      },
                      ...paper.categories
                        .filter((category) => category.enabled)
                        .map((category) => {
                          return {
                            label: category.name,
                            value: category.id
                          };
                        })
                    ]}
                    closeMenuOnSelect={true}
                    value={props.value}
                    valueController={setValue}
                    fieldName={props.name}
                    placeholder="Selecione uma categoria"
                    labelName="label"
                    valueName="value"
                  />
                )}
                control={control}
                name={`category`}
                rules={{
                  required: false
                }}
                defaultValue={null}
              />
            </div>
          ) : null}
          <h4>{`Descrição:`}</h4>
          <Controller
            as={<Input type="textarea" rows={6} />}
            control={control}
            name={`description`}
            defaultValue={''}
            placeholder=""
          />
          {!isViewMode ? (
            <>
              <hr />
              <h4>{`Nota final: ${getAverage()}`}</h4>
              <hr />
              <ReactButton btnColor="confirmation" type="submit">
                Salvar
              </ReactButton>

              <ReactButton btnColor="cancelation" onClick={onCancel}>
                Cancelar
              </ReactButton>
            </>
          ) : null}
        </Form>
      </div>
    </DefaultModal>
  );
}
