export const opportunityTypes = [
  {
    name: 'Aberto',
    value: 'OPEN'
  },
  {
    name: 'Fechado - Perdido',
    value: 'CLOSED_LOST'
  },
  {
    name: 'Fechado - Ganho',
    value: 'CLOSED_WON'
  }
];
