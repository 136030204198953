export const removeMaskSpecialCharacters = (phone = '') => {
  const regexToRemoveSpecialCharacters = /[^0-9a-zA-Z]+/g;
  return phone.replace(regexToRemoveSpecialCharacters, '');
};

export const maskPhoneNumber = (valuePhone) => {
  if (!valuePhone) {
    return '';
  }
  let phoneFormated = valuePhone.replace(/\D/g, '');
  phoneFormated = phoneFormated.replace(/^0/, '');
  if (phoneFormated.length === 11) {
    phoneFormated = phoneFormated.replace(
      /^(\d\d)(\d{1})(\d{4})(\d{4}).*/,
      '($1) $2$3-$4'
    );
  }
  if (phoneFormated.length === 10) {
    phoneFormated = phoneFormated.replace(
      /^(\d\d)(\d{4})(\d{4}).*/,
      '($1) $2-$3'
    );
  }
  return phoneFormated;
};
