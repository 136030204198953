import React, { useCallback, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { toast } from 'react-toastify';

import ConfirmationModal from 'components/Modal/ConfirmationModal';

import AccountService from '../../../services/accountService';

import './styles.css';
import { useHistory } from 'react-router';

const AccountContacts = ({ contacts, accountId, reloadAccount }) => {
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [idOfContactToRemove, setIdOfContactToRemove] = useState(null);

  const history = useHistory();

  const getName = useCallback((name = '') => {
    if (name.length <= 26) return name;
    return `${name.substring(0, 24).trim()}...`;
  }, []);

  const handleRemoveContact = useCallback(
    (id) => {
      AccountService.removeContacts(accountId, [id])
        .then(() => {
          toast.success('Contato removido');
          reloadAccount();
        })
        .catch((err) => {
          const message =
            err?.response?.data?.message || 'Erro ao remover contato';
          toast.error(message);
        })
        .finally(() => {
          setOpenModalConfirmation(false);
          setIdOfContactToRemove(null);
        });
    },
    [accountId, reloadAccount]
  );

  const controlDelete = (id) => {
    setIdOfContactToRemove(id);
    setOpenModalConfirmation(true);
  };

  const handleOnContactNameClick = useCallback(
    ({ recordTypeId, contactId }) => {
      //console.log(recordTypeId, contactId);
      history.push(`/admin/crm/contactForm/${recordTypeId}/${contactId}`);
    },
    [history]
  );

  if (!contacts || !contacts.length) return null;

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          flexWrap: 'wrap'
        }}
      >
        {contacts.map((contact) => (
          <div
            key={contact.id}
            style={{
              position: 'relative',
              border: '1px solid lightgray',
              borderRadius: '0.1rem',
              borderTop: '0.15rem solid gray',
              padding: '0.8rem',
              width: '24%',
              overflow: 'hidden',
              backgroundColor: '#fff'
            }}
          >
            <div
              style={{
                position: 'absolute',
                right: '0.5rem',
                top: '0.1rem'
              }}
              onClick={() => controlDelete(contact.id)}
            >
              <AiOutlineDelete type="button" size={18} color="#82000b" />
            </div>
            <div>
              <span
                className="link-to-contact-details"
                onClick={() =>
                  handleOnContactNameClick({
                    recordTypeId: contact.recordTypeId,
                    contactId: contact.id
                  })
                }
                title={contact.name}
              >
                {getName(contact.name)}
              </span>
            </div>
            <div className="small">
              <span>
                <span className="font-weight-bold">Email: </span>
                {contact.email || ''}
              </span>
            </div>
            <div className="small">
              <span className="font-weight-bold">Telefone: </span>
              <span>{contact.phone || ''}</span>
            </div>
          </div>
        ))}
      </div>
      <ConfirmationModal
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idOfContactToRemove}
        deleteResource={handleRemoveContact}
        message={'Tem certeza que deseja remover este contato da conta?'}
      />
    </>
  );
};

export default AccountContacts;
