export function SearchTemplateWhatsapp({inputValue,template={},option= {}}) {
    if (!inputValue) {
        return true;
    }

    const nameTemplate = template?.name?.toLowerCase().includes(inputValue.toLowerCase()) || option?.data?.name?.toLowerCase().includes(inputValue.toLowerCase());
    const descriptionTemplate = template?.description?.toLowerCase().includes(inputValue.toLowerCase()) || option?.data?.label?.toLowerCase().includes(inputValue.toLowerCase());

    return nameTemplate || descriptionTemplate;
}

