import { subMonths, format } from 'date-fns';

import DialerReportingService from '../../../Report/Advanced/Dialer/service';

const REPORT_TYPE = 'detailedsynthetic';
const PAGE = 1;
const QUERY_PARAMS = {
  quantity: 10,
  reportType: 3,
  disable: false,
  timeStart: '000000',
  timeEnd: '235959'
};

export const getStatisticalData = async ({ dialerId }) => {
  const currentDate = new Date();

  const dateBegin = format(subMonths(currentDate, 1), 'LL-dd-yyyy HH:mm:ss');
  const dateEnd = format(currentDate, 'LL-dd-yyyy HH:mm:ss');

  const queryParams = {
    ...QUERY_PARAMS,
    dialer: dialerId,
    dateBegin,
    dateEnd
  };

  try {
    const response = await DialerReportingService.getAllReportsType(
      REPORT_TYPE,
      PAGE,
      queryParams
    );
    return response.data;
  } catch (error) {
    console.error(
      'Error getting detailed synthetic report on monitoring panel',
      error.request || error.response
    );
    return false;
  }
};
