import { apiChat } from '../../../../http/index';
import ServiceCRUD from '../../../../services/ServiceCRUDCHANNEL';

class broadcastListService extends ServiceCRUD {
  constructor() {
    super('/report/broadcastlistSMS');
  }

  async getExcel(params) {
    return apiChat.get('/report/broadcastlistSMS/export/xlsx', {
      params,
      responseType: 'arraybuffer'
    });
  }

  async getCsv(params) {
    return apiChat.get('/report/broadcastlistSMS/export/csv', { params });
  }
  async getPdf(params) {
    return apiChat.get('/report/broadcastlistSMS/export/pdf', {
      params,
      responseType: 'blob'
    });
  }
}

export default new broadcastListService();
