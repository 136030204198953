import ReactButton from 'components/Buttons/ReactButton';
import DefaultModal from 'components/Modal/DefaultModal';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import AccountService from 'views/CRM/Account/services/accountService';
import { useClickToCallOnPhoneField } from 'views/CRM/shared/hooks/useClickToCallOnPhoneField';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../../../../../../assets/styles/table_custom_style';
import { getContactsAttempts } from '../../../../../../shared/ContactAttempt/utils';
import { maskPhoneNumber } from '../../../../../../shared/utils/phoneNumberHelpers';

const ContactsModal = ({
  accountId,
  recordTypeId,
  isModalOpen,
  isToGetContacts,
  opportunityData,
  onModalToggle
}) => {
  const [contacts, setContacts] = useState([]);
  const [callUniqueId, setCallUniqueId] = useState(null);
  const { canMakeCall, makeCall } = useClickToCallOnPhoneField();

  useEffect(() => {
    if (isToGetContacts) {
      AccountService.getById(recordTypeId, accountId)
        .then((response) => {
          const contacts = response.data.data.contacts.filter(
            (contact) => contact.phone
          );
          setContacts(contacts);
        })
        .catch((error) => {
          console.log('Erro', error);
        });
    }
  }, [isToGetContacts, recordTypeId, accountId]);

  useEffect(() => {
    if (callUniqueId) {
      getContactsAttempts(
        callUniqueId,
        'TELEFONIA',
        'OPPORTUNITY',
        opportunityData.id
      );
      return;
    }
  }, [callUniqueId, opportunityData.id]);

  const createDynamicColumns = () => {
    const columns = [];

    columns.push(
      {
        dataField: 'name',
        text: 'Nome',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (_, row) => {
          return row.name;
        }
      },
      {
        dataField: 'phone',
        text: 'Telefone',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (_, row) => {
          if (row) return maskPhoneNumber(row.phone);
        }
      },
      {
        dataField: 'action',
        text: 'Ações',
        style: icon_column_style,
        headerStyle: individual_icon_header_style,
        formatter: (_, row) => {
          return (
            <>
              <ReactButton
                btnColor="primary"
                onClick={() => makeCall(row.phone, setCallUniqueId, true)}
                disabled={!canMakeCall(row.phone)}
              >
                <i className="fas fa-phone" style={{ marginRight: '6px' }} />
                Realizar chamada
              </ReactButton>
            </>
          );
        }
      }
    );

    return [...columns];
  };

  const formattedColumns = createDynamicColumns();

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Escolher contato"
      showCloseButton={false}
      modalContainerClasses="modal-md"
    >
      <ToolkitProvider
        data={contacts || []}
        keyField="id"
        columns={formattedColumns}
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
              classes="align-items-center"
            />
          </div>
        )}
      </ToolkitProvider>
    </DefaultModal>
  );
};

export default ContactsModal;
