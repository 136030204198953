import React, { useCallback, useState } from 'react';
import { Row, Card, Button } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style,
  icon_column_style,
  individual_icon_header_style
} from '../../../../../../assets/styles/table_custom_style';
import { useHistory } from 'react-router-dom';
import ProtocolHistoryModal from 'views/Protocol/list/components/ProtocolHistoryModal';
import ListModalProtocols from 'views/Protocol/form/ListModalProtocols';
import { FaCommentDots } from 'react-icons/fa';
export function ClientProtocolTable({
  data,
  selectedType,
  isProtocolScreen = false
}) {

  const history = useHistory();

  const [isModalComents, setIsModalComents] = useState(false);
  const [idComents, setIdComents] = useState('');

  const handleUpdate = useCallback(
    (id) => {
      history.push(`/admin/protocol/${id}/update`, {
        recordTypeId: selectedType
      });
    },
    [history, selectedType]
  );

  const openInModal = (id) => {
    setIdComents(id);
    setIsModalComents(!isModalComents);
  };

  return (
    <>
      <Row className="mt--3">
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <ToolkitProvider
                data={data}
                keyField="_id"
                columns={[
                  {
                    dataField: 'number',
                    text: 'NÚMERO',
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (_, row) => {
                      return <span>{row.number}</span>;
                    }
                  },
                  {
                    dataField: 'title',
                    text: 'TÍTULO',
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    // eslint-disable-next-line react/display-name
                    formatter: (_, row) => {
                      return <span>{row.title}</span>;
                    }
                  },
                  {
                    dataField: 'status',
                    text: 'Status/Fase',
                    sort: true,
                    style: individual_column_style,
                    formatter: (_, row) => {
                      const [protocol_status] = row.protocol_statuses;
                      return (
                        (protocol_status && (
                          <div
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            <>
                              <div
                                style={{
                                  marginRight: '5px',
                                  width: '12px',
                                  height: '12px',
                                  border: '1px solid #CDC9C9',
                                  backgroundColor: `${protocol_status.status.color}`
                                }}
                              ></div>
                              <div>{`${protocol_status.status.name}`}</div>
                            </>
                          </div>
                        )) ||
                        ''
                      );
                    }
                  },
                  {
                    dataField: 'cliente.nome',
                    text: 'Cliente',
                    sort: true,
                    style: individual_column_style
                  },
                  {
                    dataField: 'edit',
                    text: isProtocolScreen ? 'Comentários' : 'Editar',
                    style: icon_column_style,
                    headerStyle: individual_icon_header_style,
                    formatter: (_, row) => {
                      return (
                        <Button
                          title={
                            isProtocolScreen
                              ? 'Ver Comentários'
                              : 'Editar Protocolos'
                          }
                          color="info"
                          size="sm"
                          onClick={() =>
                            isProtocolScreen
                              ? openInModal(row.id)
                              : handleUpdate(row.id)
                          }
                        >
                          {isProtocolScreen ? (
                            <FaCommentDots />
                          ) : (
                            <li className="fas fa-user-edit"></li>
                          )}
                        </Button>
                      );
                    }
                  },
                  !isProtocolScreen
                    ? {
                        dataField: 'history',
                        text: 'Histórico',
                        style: icon_column_style,
                        headerStyle: individual_icon_header_style,
                        formatter: (_, row) => {
                          return <ProtocolHistoryModal protocolId={row.id} />;
                        }
                      }
                    : ''
                ]}
                hora_criacao
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>

      <ListModalProtocols
        show={isModalComents}
        setIsModalComents={setIsModalComents}
        id={idComents}
        isProtocolScreen={isProtocolScreen}
      />
    </>
  );
}
