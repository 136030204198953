export function activeConversation(infos, boll) {
  return {
    type: '@ACTIVE_CONVERSATION',
    infos,
    boll
  };
}

export function handleInfosUser(infos) {
  return {
    type: '@HANDLE_INFOS_USER',
    infos
  };
}

export function addMessage(messages, id, sender, isSocketUpdate) {
  return {
    type: '@ADD_MESSAGE',
    messages,
    id,
    sender,
    isSocketUpdate
  };
}

export function orderChat(from) {
  return {
    type: '@ORDER_CHAT',
    from
  };
}

export function getListuser(list) {
  return {
    type: '@GET_LIST_USER_CHAT_INTERNAL',
    list
  };
}

export function removeChat(data) {
  return {
    type: '@REMOVE_CHAT_SPECIFIC',
    data
  };
}

export function watchNewMessages(data) {
  return {
    type: '@WATCH_NEW_MESSAGE',
    newMessages: data
  };
}
