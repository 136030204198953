import { format, startOfMonth, endOfMonth } from 'date-fns';

const date = new Date();
const firstDayMonth = new Date(date.getFullYear(), date.getMonth(), 1);
const lastDayMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

export const REPORT_TYPE_DETAILEDSYNTHETIC = 'detailedsynthetic';
export const PAGE = 1;
export const QUANTITY = 31;
export const GROUPBYDATA = 'data';
export const GROUPBY = 'fila';
export const TIME_INTERVAL = 10 * 2000 * 10;

export const QUERY_PARAMS_CARDS = {
  disable: false,
  allCalls: false,
  maxWaitTime: 10,
  groupby: 'fila',
  hourBegin: '000000',
  hourEnd: '235959'
};

export const QUERY_PARAMS_CALLIN = {
  disable: false,
  allCalls: false,
  maxWaitTime: 10,
  hourBegin: '000000',
  hourEnd: '235959',
  groupby: GROUPBYDATA,
  quantity: QUANTITY
};

export const QUERY_PARAMS_PBXIP = {
  timeStart: '000000',
  timeEnd: '235959',
  reportType: 3,
  quantity: QUANTITY
};

export const dateBegin = format(
  startOfMonth(firstDayMonth),
  'LL-dd-yyyy HH:mm:ss'
);

export const dateEnd = format(endOfMonth(lastDayMonth), 'LL-dd-yyyy HH:mm:ss');

export const currentMont = () => {
  const month = date.getMonth();
  const arrayMonths = new Array(12);
  arrayMonths[0] = 'Janeiro';
  arrayMonths[1] = 'Fevereiro';
  arrayMonths[2] = 'Março';
  arrayMonths[3] = 'Abril';
  arrayMonths[4] = 'Maio';
  arrayMonths[5] = 'Junho';
  arrayMonths[6] = 'Julho';
  arrayMonths[7] = 'Agosto';
  arrayMonths[8] = 'Setembro';
  arrayMonths[9] = 'Outubro';
  arrayMonths[10] = 'Novembro';
  arrayMonths[11] = 'Dezembro';

  return arrayMonths[month];
};
