import { useCallback } from 'react';

export const possiblePageFilters = [
  'protocol-list-filters',
  'crm-list-filters-account',
  'crm-list-filters-contact',
  'crm-list-filters-opportunity',
  'crm-list-filters-lead'
];

export const useMemorizeFilters = (typeOfFilter = '') => {
  let MEMORIZED_FILTERS_STORAGE_KEY = '';
  switch (typeOfFilter) {
    case 'protocol':
      MEMORIZED_FILTERS_STORAGE_KEY = 'protocol-list-filters';
      break;
    case 'CRM - Account':
      MEMORIZED_FILTERS_STORAGE_KEY = 'crm-list-filters-account';
      break;
    case 'CRM - Contact':
      MEMORIZED_FILTERS_STORAGE_KEY = 'crm-list-filters-contact';
      break;
    case 'CRM - Opportunity':
      MEMORIZED_FILTERS_STORAGE_KEY = 'crm-list-filters-opportunity';
      break;
    case 'CRM - Lead':
      MEMORIZED_FILTERS_STORAGE_KEY = 'crm-list-filters-lead';
      break;
    case 'ALL':
      MEMORIZED_FILTERS_STORAGE_KEY = 'all-list-filters';
      break;
    default:
      break;
  }

  const getMemorizedFilters = useCallback(() => {
    const filters = localStorage.getItem(MEMORIZED_FILTERS_STORAGE_KEY);
    if (!filters) return null;
    return JSON.parse(filters);
  }, [MEMORIZED_FILTERS_STORAGE_KEY]);

  const memorizeFilters = useCallback(
    (filters) => {
      const filtersAsString = JSON.stringify(filters);
      localStorage.setItem(MEMORIZED_FILTERS_STORAGE_KEY, filtersAsString);
    },
    [MEMORIZED_FILTERS_STORAGE_KEY]
  );

  const clearMemorizedFilters = useCallback(() => {
    localStorage.removeItem(MEMORIZED_FILTERS_STORAGE_KEY);
  }, [MEMORIZED_FILTERS_STORAGE_KEY]);

  return {
    getMemorizedFilters,
    memorizeFilters,
    clearMemorizedFilters
  };
};
