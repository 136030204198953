import React, { useState, useEffect } from 'react';

import Chart from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Row, Card, Col, CardBody, CardHeader } from 'reactstrap';
import {
  ColorTitleAnswered,
  ColorTitleAbandoned,
  ContainerTitle
} from '../../../assets/style';
import {
  chartOptions,
  parseOptions,
  chartExample2
} from '../../../../../../variables/charts';

const CallCenterOutGraphics = ({ dataGraphics, month }) => {
  const [barChartData, setBarChartData] = useState(null);
  useEffect(() => {
    if (window.Chart) parseOptions(Chart, chartOptions());

    if (dataGraphics)
      if (dataGraphics.length > 0) {
        let arrayLabels = [];
        let arrayData = [];
        let colorsData = [];

        dataGraphics.forEach((data) => {
          if (data.answered || data.answered === 0) {
            arrayLabels.push(`Dia ${data.dateFormatted.substr(0, 2)}`);
            arrayData.push(data.answered);
            colorsData.push('#00A07C');
          }
          if (data.abandoned || data.abandoned === 0) {
            arrayLabels.push(``);
            arrayData.push(data.abandoned);
            colorsData.push('red');
          }
        });

        setBarChartData({
          labels: [...arrayLabels],
          datasets: [
            {
              data: [...arrayData],
              maxBarThickness: 50,
              backgroundColor: [...colorsData]
            }
          ]
        });
      }
  }, [dataGraphics]);
  return (
    <Card>
      <CardHeader className="introduct">
        <h5 className="h3 mb-0">Chamadas Realizadas Callcenter de {month}</h5>
      </CardHeader>
      <CardBody>
        <Row>
          <Col>
            <div style={ContainerTitle}>
              <div style={ColorTitleAnswered}>Atendidas</div>
              <div style={ColorTitleAbandoned}>Abandonadas</div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="chart">
              <Bar
                data={barChartData}
                options={chartExample2.options}
                className="chart-canvas"
                id="chart-bars"
              />
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default CallCenterOutGraphics;
