import ServiceCRUD from '../../../services/ServiceCRUDNEWCHANNEL';

import { apiInstagramChat as http, apiChat } from '../../../http';

class PredefinedMessageService extends ServiceCRUD {
  constructor() {
    super('msgspredefinidas');
  }

  getAllFilter(page = 1, search = '') {
    return apiChat.get(
      `${this.path}/busca/filtradas?filtro=${search}&pagina=${page}`
    );
  }

  getById(id) {
    return http.get(`${this.path}/${id}`);
  }

  //Como o email não tem id, repassar o channelId como "email"
  getByChannel(channelId) {
    return http.get(`${this.path}/channel/${channelId}`, {
      headers: { 'content-type': 'application/json' }
    });
  }

  createWithMidia(data) {
    return http.post(`${this.path}`, data, {
      headers: { 'content-type': 'multipart/form-data' }
    });
  }

  updateWithMidia(data, id) {
    return http.put(`${this.path}/${id}`, data, {
      headers: {
        'content-type': 'multipart/form-data'
      }
    });
  }
}

export default new PredefinedMessageService();
