import DefaultModal from '../../../../../../../components/Modal/DefaultModal';
import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import LeadService from '../../../../services/leadService';
import { toast } from 'react-toastify';
import { Row, Form, Col, Button, Input } from 'reactstrap';

const CommentModal = ({
  isModalOpen,
  onModalToggle,
  leadId,
  render,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);

  const { control, handleSubmit, errors } = useForm();

  const onSubmit = async (data) => {
    try {
      let message;
      await LeadService.createLeadComments(leadId, data);

      message = 'Comentário criado com sucesso';
      toast.success(message, {
        autoClose: 3000,
        closeOnClick: true
      });
      closeModal();
    } catch (err) {
      toast.error(' Erro ao criar o comentário', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      render();
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Criar um novo comentário"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col md="12">
              <label className="form-control-label">Comentário*</label>

              <Controller
                as={
                  <Input
                    type="textarea"
                    rows="3"
                    name="content"
                    placeholder="Digite aqui o comentário"
                  />
                }
                rules={{
                  required: 'Campo obrigatório',
                  validate: (value) => {
                    return !!value.trim() || 'Campo não pode estar vazio';
                  }
                }}
                control={control}
                name="content"
                defaultValue=""
              />
              <ErrorMessage
                errors={errors}
                name="content"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>
          <hr />
          <Button color="primary" type="submit">
            Salvar
          </Button>
          <Button color="danger" onClick={closeModal}>
            Voltar
          </Button>
        </Form>
      </>
    </DefaultModal>
  );
};

export default CommentModal;
