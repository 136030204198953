import React from 'react';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';
import { TotalRegister } from '../../../../../components/TotalRegister';

const Analytical = ({ showReports, totalRegister }) => {
  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            {totalRegister >= 0 && (
              <TotalRegister totalRegister={totalRegister} />
            )}
            <Card>
              <ToolkitProvider
                data={showReports}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'userName',
                    text: 'Agente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'dateIn',
                    text: 'Data inicial',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      const date = new Date(Row);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'dateOut',
                    text: 'Data Final',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (Row) => {
                      const date = new Date(Row);
                      return `${date.toLocaleDateString(
                        'pt-br'
                      )} ${date.toLocaleTimeString('pt-br')}`;
                    }
                  },
                  {
                    dataField: 'extension',
                    text: 'Ramal',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'fila',
                    text: 'Fila',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'pause',
                    text: 'Pausa',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'time',
                    text: 'Tempo',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Analytical;
