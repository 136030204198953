import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FiChevronDown, FiChevronRight, FiFilter } from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { Button, Form, Col } from 'reactstrap';
import xlsxImage from '../../../../../../../../assets/img/xlsx.png';
import pdfImage from '../../../../../../../../assets/img/pdf.png';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import { Tooltip } from '@material-ui/core';
import {
  ImageButton,
  ImageSize
} from '../../../../../../../../assets/styles/image_style';
import CustomFieldsFilterForm from '../../../../../../shared/components/CustomFieldsFilterForm';
import DefaultFieldsFilterForm from './DefaultFieldsFilterForm';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { customFieldTypes } from 'views/fieldConfigs/utils/customFieldTypes';
import { parseIntFromString } from 'utils/fuctions/parseIntFromString';

import { saveAs } from 'file-saver';

import LeadService from '../../../../../services/leadService';

import { toast } from 'react-toastify';
import { useMemorizeFilters } from '../../../../../../../../hooks/useMemorizeInputsFilters';
import { AiOutlineClear } from 'react-icons/ai';
import { resetToFirstDayOfMonth } from '../../../../../../shared/helpers/getFirstDayOfTheMonth';
import { resetFields } from '../../../../../../shared/helpers/resetInputsFIeldsValues';

const CustomFieldsFilterToggle = styled.div`
  display: inline-block;
  margin-bottom: 1rem;
  user-select: none;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const FilterLeadsForm = ({
  handleSearch,
  customFields,
  isLoadingLeads,
  recordTypeId,
  setShowTable,
  showTable,
  setTransferLeads,
  transferLeads,
  setIsChangeResponsibleModalOpen,
  leads,
  selectedRows,
  totalLeads,
  setSelectedRecordType,
  recordTypes,
  setTransferColumnsSearch
}) => {
  const [showCustomFieldsFilters, setShowCustomFieldsFilters] = useState(false);
  const { setValue, control, getValues, handleSubmit, register, reset } =
    useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [analyticalData, setAnalyticalData] = useState([]);
  const [loadingCSV, setLoadingCSV] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [shouldTransfer, setShouldTransfer] = useState(true);
  const limitRows = 40000;
  const { memorizeFilters, getMemorizedFilters, clearMemorizedFilters } =
    useMemorizeFilters('CRM - Lead');

  useEffect(() => {
    if (leads) {
      setAnalyticalData(leads);
    }
  }, [leads]);

  useEffect(() => {
    const filters = getMemorizedFilters();
    if (filters) {
      reset(filters);
    } else if (customFields) {
      customFields.forEach((field) => {
        if (field) {
          setValue(field.name, '');
        }
      });
    }
  }, [
    customFields,
    getMemorizedFilters,
    reset,
    resetConfig,
    setValue,
    showCustomFieldsFilters
  ]);

  const saveFiltersWithRecordType = (filters) => {
    const filtersWithRecordType = {
      ...filters,
      recordTypeId: recordTypeId
    };
    memorizeFilters(filtersWithRecordType);
  };

  const onSubmit = async (data) => {
    if (customFields && showCustomFieldsFilters) {
      customFields.forEach((field) => {
        if (field.type === customFieldTypes.LIST) {
          const possibleValues = field.options.map((value) => value.value);
          if (!possibleValues.includes(data[field.name])) {
            data[field.name] = '';
          }
        }
      });
    }
    saveFiltersWithRecordType(data);
    const currencyFieldsName =
      customFields &&
      customFields
        .filter((field) => field.type === customFieldTypes.CURRENCY)
        .map((field) => field.name);

    const formattedData = { ...data };

    Object.keys(data).forEach((key) => {
      if (currencyFieldsName.includes(key)) {
        if (data[key]) {
          formattedData[key] = parseIntFromString(data[key]);
        }
      }
    });

    await handleSearch({ ...formattedData }, !showTable);
  };

  const handleChangeToggle = async () => {
    setShouldTransfer(!shouldTransfer);
    let data = getValues();

    const currencyFieldsName =
      customFields &&
      customFields
        .filter((field) => field.type === customFieldTypes.CURRENCY)
        .map((field) => field.name);

    const formattedData = { ...data };

    Object.keys(data).forEach((key) => {
      if (currencyFieldsName.includes(key)) {
        if (data[key]) {
          formattedData[key] = parseIntFromString(data[key]);
        }
      }
    });

    setShowTable((prev) => {
      handleSearch({ ...formattedData }, prev);
      return !prev;
    });
  };

  const toISOFormat = useCallback((dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${DD}/${MM}/${YYYY}`;
  }, []);

  const handleTransferLeads = async () => {
    let data = getValues();

    const currencyFieldsName =
      customFields &&
      customFields
        .filter((field) => field.type === customFieldTypes.CURRENCY)
        .map((field) => field.name);

    const formattedData = { ...data };

    Object.keys(data).forEach((key) => {
      if (currencyFieldsName.includes(key)) {
        if (data[key]) {
          formattedData[key] = parseIntFromString(data[key]);
        }
      }
    });

    handleSearch({ ...formattedData }, !showTable);
    setTransferColumnsSearch({ ...formattedData });
    setTransferLeads(!transferLeads);
  };

  const toggleModalTransfer = () => {
    setIsChangeResponsibleModalOpen(true);
  };

  const handleReportExport = (typeExport, e) => {
    e.preventDefault();

    const params = getValues();

    const dto = {
      ...params,
      dateStart: toISOFormat(params.dateBegin),
      dateEnd: toISOFormat(params.dateEnd),
      recordTypeId
    };

    if (analyticalData.length < limitRows && typeExport == 'XLSX') {
      setLoadingCSV(true);

      LeadService.getExcel(dto)
        .then((res) => {
          if (res.data.size === 0) {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          } else {
            saveAs(
              new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel'
              }),
              `relatorio-leads.xlsx`
            );

            setLoadingCSV(false);
          }
        })
        .catch(() => {
          setLoadingCSV(false);
          toast.info('Nenhum registro foi encontrado!');
        });
    } else if (typeExport == 'XLSX' && analyticalData.length > limitRows) {
      setLoadingCSV(true);

      LeadService.getCsv(dto)
        .then((res) => {
          if (res.data.size === 0) {
            setLoadingCSV(false);
            toast.info('Nenhum registro foi encontrado!');
          } else {
            saveAs(
              new Blob([res.data], { type: res.headers['content-type'] }),
              `realatorio-leads${new Date()}.csv`
            );

            setLoadingCSV(false);
          }
        })
        .catch(() => {
          setLoadingCSV(false);
          toast.info('Nenhum registro foi encontrado!');
        });
    } else if (typeExport == 'PDF') {
      setLoadingPdf(true);
      LeadService.getPdf(dto)
        .then((res) => {
          if (res.data.size === 0) {
            toast.info('Nenhum registro foi encontrado!');
            setLoadingPdf(false);
          } else {
            saveAs(
              new Blob([res.data], { type: 'application/pdf' }),
              `realatorio-leads-${new Date()}.pdf`
            );
          }
          setLoadingPdf(false);
        })
        .catch(() => {
          setLoadingPdf(false);
          toast.info('Nenhum registro foi encontrado!');
        });
    }
  };

  const resetConfig = useMemo(
    () => ({
      dateBegin: resetToFirstDayOfMonth,
      dateEnd: () => new Date().toLocaleDateString('pt-br').toString(),
      filterDateBy: () => 'creation',
      stageId: () => '',
      textQuery: () => '',
      responsibleLogin: () => ''
    }),
    []
  );

  const handleReset = () => {
    if (recordTypeId && recordTypes && recordTypes.length) {
      setSelectedRecordType(recordTypes[0]);
    }
    resetFields(resetConfig, customFields, control);
    clearMemorizedFilters();
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div
        style={{
          position: 'absolute',
          right: '1rem',
          top: '2rem'
        }}
      >
        <span
          onClick={() => {
            handleReset();
          }}
          className="small"
          style={{ cursor: 'pointer' }}
        >
          <AiOutlineClear /> Limpar filtros
        </span>
      </div>
      <DefaultFieldsFilterForm
        handleSearch={handleSearch}
        setValue={setValue}
        control={control}
        getValues={getValues}
        recordTypeId={recordTypeId}
        register={register}
      />

      {customFields && customFields.length > 0 && (
        <>
          <CustomFieldsFilterToggle
            className="small"
            onClick={() => setShowCustomFieldsFilters(!showCustomFieldsFilters)}
          >
            Filtrar por campos customizados{' '}
            {showCustomFieldsFilters ? (
              <FiChevronDown size={18} />
            ) : (
              <FiChevronRight size={18} />
            )}
          </CustomFieldsFilterToggle>

          {showCustomFieldsFilters && (
            <CustomFieldsFilterForm
              toISOFormat={toISOFormat}
              getValues={getValues}
              customFields={customFields}
              setValue={setValue}
              control={control}
            />
          )}
        </>
      )}

      <div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Button
            style={buttonStyle(systemConfiguration.primary_color)}
            type="submit"
            size="md"
            disabled={isLoadingLeads}
          >
            <FiFilter />
            <span className="ml-1">Filtrar Leads</span>
          </Button>
          <Col
            md="6"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end'
            }}
          >
            <button
              id="button_xlsx"
              style={ImageButton}
              onClick={(e) => {
                handleReportExport('XLSX', e);
              }}
              disabled={analyticalData.length && !loadingCSV ? false : true}
            >
              <Tooltip
                title={
                  <p style={{ fontSize: '14px' }}>
                    {analyticalData.length
                      ? `Fazer download do relatório de Leads. Arquivos menores que ${limitRows} registros serão baixados em XLSX, caso contrário serão em CSV`
                      : 'Nenhum registro para realizar download'}
                  </p>
                }
                arrow
              >
                {loadingCSV ? (
                  <Loader
                    type="ThreeDots"
                    visible={true}
                    height="40"
                    width="40"
                  />
                ) : (
                  <img src={xlsxImage} alt="xlsx" style={ImageSize} />
                )}
              </Tooltip>
            </button>
            <button
              style={ImageButton}
              name="pdf"
              onClick={(e) => {
                handleReportExport('PDF', e);
              }}
              disabled={analyticalData.length && !loadingPdf ? false : true}
            >
              <Tooltip
                title={
                  <p style={{ fontSize: '14px' }}>
                    {analyticalData.length
                      ? 'Fazer download do relatório de Leads.'
                      : 'Nenhum registro para realizar download, faça uma filtragem para realizar esta ação'}
                  </p>
                }
                arrow
              >
                {loadingPdf ? (
                  <Loader
                    type="ThreeDots"
                    visible={true}
                    height="40"
                    width="40"
                  />
                ) : (
                  <img src={pdfImage} alt="pdf" style={ImageSize} />
                )}
              </Tooltip>
            </button>
          </Col>
        </div>
        <div>
          <label
            style={{ fontWeight: '400', marginRight: '1rem' }}
            className="form-control-label"
          >
            <input
              onChange={handleChangeToggle}
              defaultValue={false}
              type="checkbox"
            ></input>{' '}
            Kanban
          </label>
          {shouldTransfer ? (
            <label style={{ fontWeight: '400' }} className="form-control-label">
              <input
                onChange={handleTransferLeads}
                defaultValue={false}
                type="checkbox"
              ></input>{' '}
              Transferir vários
            </label>
          ) : null}
          {shouldTransfer && transferLeads && selectedRows.length ? (
            <Button
              size="sm"
              color="primary"
              title="Transferir leads para um responsável"
              onClick={toggleModalTransfer}
              style={{ marginLeft: '.5rem' }}
            >
              <i className="fas fa-random"></i>
            </Button>
          ) : null}
          <div style={{ fontSize: 'small', float: 'right' }}>
            {totalLeads} resultado(s) encontrado(s)
          </div>
        </div>
      </div>
    </Form>
  );
};

export default FilterLeadsForm;
