import { apiCrm } from 'http/index';

class OpportunityStageService {
  async create(data) {
    return apiCrm.post('/opportunity-stage', data);
  }

  async list(params, headers) {
    return apiCrm.get('/opportunity-stage', { params, headers });
  }

  async listOrdenade(recordTypeId) {
    return apiCrm.get(`/opportunity-stage/by-record-type/${recordTypeId}`);
  }

  async getById(opportunityStageId) {
    return apiCrm.get(`/opportunity-stage/get-by-id/${opportunityStageId}`);
  }

  async enable(opportunityStageId) {
    return apiCrm.put(`/opportunity-stage/${opportunityStageId}/enable`);
  }

  async disable(opportunityStageId) {
    return apiCrm.put(`/opportunity-stage/${opportunityStageId}/disable`);
  }

  async update(opportunityStageId, data) {
    return apiCrm.put(`/opportunity-stage/${opportunityStageId}`, data);
  }
}

export default new OpportunityStageService();
