import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';

const Graphic = ({ data }) => {
  const [graphicData, setGraphicData] = useState({});

  useEffect(() => {
    if (!data || !data.length) return setGraphicData({});

    const labels = data.map((item) => item.interval);
    const datasets = [
      {
        label: 'Atendidas',
        data: data.map((item) => item.qtdAnswered),
        borderColor: '#5cb85c',
        pointBackgroundColor: '#5cb85c'
      },
      {
        label: 'Não Atendidas',
        data: data.map((item) => item.qtdAbandoned),
        borderColor: '#d9534f',
        pointBackgroundColor: '#d9534f'
      }
    ];

    setGraphicData({
      labels,
      datasets
    });
  }, [data]);

  return (
    <div className="chart">
      <Line
        data={graphicData}
        height={70}
        legend={{ display: true }}
        options={{
          layout: {
            padding: 10
          },
          elements: {
            point: {
              pointStyle: 'star'
            }
          }
        }}
        id="chart-sales"
        className="chart-canvas"
      />
    </div>
  );
};

export default Graphic;
