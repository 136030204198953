import React, { useEffect, useState } from 'react';
import D3Funnel from 'd3-funnel';
import randomColor from 'randomcolor';
import { Col, Row } from 'reactstrap';
import { ColorArea } from './assets/styleds';

const options = {
  block: {
    dynamicHeight: true,
    minHeight: 25,
    fill: {
      type: 'gradient'
    }
  },
  label: {
    fill: '#fff'
  },
  chart: {
    curve: {
      enabled: true
    }
  }
};

const OpportunityFunnelGraphic = ({ data, total }) => {
  const [colors, setColors] = useState([]);

  useEffect(() => {
    const chart = new D3Funnel('#funnel');

    const funnelData = data.map((row) => {
      const color = randomColor();
      setColors((prevColors) => [...prevColors, color]);
      return [row.name, row.quantity, color];
    });

    chart.draw(funnelData, options);
  }, [data]);

  return (
    <>
      <Row className="mr-0">
        <Col sm="4">
          <div id="funnel" />
        </Col>

        <Col sm="8" className="mt-4">
          <Row>
            {data.map((item, i) => {
              return (
                <Col
                  key={item.name}
                  md="2"
                  className="mt--3 mb--3"
                  style={{
                    minWidth: '177px',
                    minHeight: '130px'
                  }}
                >
                  <Row
                    className="row flex-nowrap"
                    style={{
                      minHeight: '100px'
                    }}
                  >
                    <Col md="1">
                      <ColorArea color={colors[i]} />
                    </Col>
                    <Col md="11">
                      <h2>{item?.amount ?? 0}</h2>
                      <h3 className="text-muted font-weight-light mt--3">
                        {item.name}
                      </h3>
                      <h2
                        className="mt--1 font-weight-light"
                        style={{
                          paddingBottom: '1.5625rem'
                        }}
                      >
                        {item.percentage ? item.percentage + '%' : '0%'}
                      </h2>
                    </Col>
                  </Row>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default OpportunityFunnelGraphic;
