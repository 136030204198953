import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { Row, Form, Col, Input, Button } from 'reactstrap';

import Modal from 'components/Modal/DefaultModal';
import FlexSelect from 'components/Inputs/FlexSelect';

const ModalAddListOption = ({
  isOpenModal,
  onModalToggle,
  setterFunction,
  recordTypes,
  isOptionEdition = false,
  dataOptionToEdit,
  handleUpdateItem
}) => {
  const { reset, control, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    if (!isOpenModal) {
      reset({});
      return;
    }
    if (!!dataOptionToEdit) {
      reset(dataOptionToEdit);
    }
  }, [isOpenModal]);

  const handleUpdateOption = (data) => {
    handleUpdateItem(dataOptionToEdit.name, data);
    onModalToggle(false);
  };

  const onSubmit = (data) => {
    if (isOptionEdition) return handleUpdateOption(data);
    setterFunction({ ...data });
    reset();
    onModalToggle(false);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <Modal
      show={isOpenModal}
      onModalToggle={(state) => onModalToggle(state)}
      title="Adicionar opção à lista"
      showCloseButton={false}
    >
      <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        <Row className="mb-3">
          {!isOptionEdition && (
            <Col sm="12">
              <label className="form-control-label">Nome da opção</label>
              <Controller
                as={Input}
                control={control}
                name="name"
                rules={{ required: 'O nome é obrigatório' }}
                defaultValue=""
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          )}
          <Col sm="12" className="mt-2">
            <label className="form-control-label">Tipos de registro</label>
            <Controller
              render={(props) => (
                <FlexSelect
                  dataOptions={recordTypes || []}
                  isMulti={true}
                  value={props.value}
                  valueController={setValue}
                  fieldName="recordTypeIds"
                  labelName="name"
                  valueName="id"
                  isClearable={true}
                />
              )}
              control={control}
              name="recordTypeIds"
              defaultValue=""
              rules={{ required: 'Selecione pelo menos um tipo de registro' }}
            />
            <ErrorMessage
              errors={errors}
              name="recordTypeIds"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        </Row>
        <Button color="primary" type="submit">
          Salvar
        </Button>
      </Form>
    </Modal>
  );
};

export default ModalAddListOption;
