import DefaultModal from '../../../../components/Modal/DefaultModal';
import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import ProtocolService from '../../service';
import { toast } from 'react-toastify';
import { Row, Form, Col, Button } from 'reactstrap';
import UserService from '../../../User/service';
import FlexSelect from '../../../../components/Inputs/FlexSelect';

const ModalStatus = ({
  isModalOpen,
  onModalToggle,
  idProtocol,
  idUserProtocol,
  render,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  const [userList, setUserList] = useState([]);
  const { control, handleSubmit, errors, setValue, reset } = useForm();

  useEffect(() => {
    if (idUserProtocol && idProtocol) {
      UserService.get(idUserProtocol)
        .then((response) => {
          const res = response.data.data;
          reset(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [idUserProtocol, reset, idProtocol]);

  useEffect(() => {
    UserService.getAll()
      .then((response) => {
        const res = response.data.data;
        const usersSort = res.sort((a, b) => {
          if (a.name.toLowerCase() < b.name.toLowerCase()) return -1;
          if (a.name.toLowerCase() > b.name.toLowerCase()) return 1;
          return 0;
        });

        setUserList(usersSort);
      })
      .catch((err) => {
        console.log(err);
        const message = 'Erro ao carregar usuários';
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
        closeModal();
      });
  }, [closeModal]);

  const onSubmit = async (data) => {
    try {
      let message;
      await ProtocolService.protocolUpdateData(
        idProtocol,
        data,
        'change_responsible'
      );

      message = 'Responsável do protocolo atualizado com sucesso';
      toast.success(message, {
        autoClose: 3000,
        closeOnClick: true
      });
      closeModal();
      render(userList.find((user) => user.login === data.responsible_id));
    } catch (err) {
      console.log(err);
      toast.error(' Erro ao atualizar o protocolo', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Alterar responsável do protocolo"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col md="12">
              <label className="form-control-label">Usuários*</label>
              <Controller
                render={(props) => (
                  <FlexSelect
                    isClearable={true}
                    dataOptions={userList}
                    isMulti={false}
                    closeMenuOnSelect={true}
                    value={props.value}
                    valueController={setValue}
                    fieldName="responsible_id"
                    labelName="name"
                    valueName="login"
                  />
                )}
                control={control}
                name="responsible_id"
                rules={{ required: 'Status é obrigatório!' }}
                defaultValue=""
              />
              <ErrorMessage
                errors={errors}
                name="responsible_id"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>
          <hr />
          <Button color="primary" type="submit">
            Salvar
          </Button>
          <Button color="danger" onClick={closeModal}>
            Voltar
          </Button>
        </Form>
      </>
    </DefaultModal>
  );
};

export default ModalStatus;
