import { useState, useRef } from 'react';
import { useSelector } from 'react-redux';

export const useAnalyticalReportService = (reportService) => {
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [error, setError] = useState(null);
  const quantity = useRef(0);
  const telephonyServicePanelAgentCode = useSelector(
    (state) => state.telephonyServicePanel.agentCode
  );

  const handlePageChange = (page) => {
    setActivePage(page);
  };

  const fetchData = async (params) => {
    if (!telephonyServicePanelAgentCode) return;

    setLoading(true);

    try {
      const res = await reportService(
        {
          agent: telephonyServicePanelAgentCode,
          ...params
        },
        activePage
      );

      quantity.current = res.quantity || res.quantityCall || 0;

      setData(res);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return {
    data,
    quantity: quantity.current,
    loading,
    activePage,
    error,
    handlePageChange,
    fetchData
  };
};
