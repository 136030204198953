export const organizeConversationsOptions = [
  { label: 'Maior tempo na fila', value: 1 },
  { label: 'Última mensagem recebida', value: 2 },
  { label: 'Maior tempo em espera', value: 3 }
];

export const whichConversationTypeToShowOptions = [
  { label: 'Todas', value: 1 },
  { label: 'Respondidas', value: 2 },
  { label: 'Não respondidas', value: 3 }
];
