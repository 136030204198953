import React from 'react';
import { FormGroup, Form } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import MaskedInput from 'react-text-mask';

import DefaultModal from 'components/Modal/DefaultModal';
import ReactButton from 'components/Buttons/ReactButton';
import BlacklistService from '../../../service';

const FormModal = ({
  isModalOpen,
  onModalToggle,
  onFormSubmit,
  blacklistId,
  ...props
}) => {
  const { control, handleSubmit, errors, setValue } = useForm();

  const onSubmit = async (data) => {
    try {
      await BlacklistService.createBlacklistNumber(blacklistId, data);

      onModalToggle(!isModalOpen);
      onFormSubmit();
      toast.success('O número foi cadastrada com sucesso!');
    } catch (error) {
      toast.error('Não foi possível cadastrar o número!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Adicionar contatos à campanha"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <label className="form-control-label">Origem*</label>
          <Controller
            render={(props) => (
              <MaskedInput
                mask={[
                  '(',
                  /[0-9]/,
                  /[0-9]/,
                  ')',
                  ' ',
                  /[0-9]/,
                  ' ',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  '-',
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/,
                  /[0-9]/
                ]}
                fieldName="origin"
                value={props.value}
                onChange={(event) => {
                  setValue(props.name, event.target.value);
                }}
                placeholder="(99) 9 9999-9999"
                className="form-control"
              />
            )}
            control={control}
            name="origin"
            type="text"
            rules={{
              required: 'Origem é obrigatório!',
              pattern: {
                value: /^\((\d{2})\)\s(\d)\s(\d{4})-(\d{4})$/,
                message: 'Origem inválida!'
              }
            }}
            defaultValue=""
          />
          <ErrorMessage
            errors={errors}
            name="origin"
            render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
          />
        </FormGroup>

        <hr />

        <ReactButton btnColor="confirmation" type="submit">
          Salvar
        </ReactButton>

        <ReactButton
          btnColor="cancelation"
          onClick={() => onModalToggle(!isModalOpen)}
        >
          Cancelar
        </ReactButton>
      </Form>
    </DefaultModal>
  );
};

export default FormModal;
