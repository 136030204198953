import React, { useCallback, useEffect, useState } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import Loader from 'components/Loader';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router';
import { Container, Row, Card, CardHeader, CardBody } from 'reactstrap';

import { useRecordTypesByUser } from 'views/RecordType/hooks/useRecordTypesByUser';
import RecordTypeSelection from 'views/RecordType/components/RecordTypeSelection';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import FormInputs from './FormInputs';

const ContactForm = () => {
  const [selectedRecordTypeId, setSelectedRecordTypeId] = useState(null);
  const [shouldShowContactForm, setShouldShowContactForm] = useState(false);
  const { recordTypes, isLoadingRecordTypes, getRecordTypesByUser } =
    useRecordTypesByUser(entitiesName.CONTACT);
  const { state } = useLocation();
  const { contactId } = useParams();

  useEffect(() => {
    getRecordTypesByUser();
  }, [getRecordTypesByUser]);

  useEffect(() => {
    if (recordTypes && recordTypes.length === 1) {
      setSelectedRecordTypeId(recordTypes[0].id);
      setShouldShowContactForm(true);
    }
  }, [recordTypes]);

  /**
   * Quando a rota é chamada e deve-se abrir diretamente a listagem, sem
   * selecionar manualmente o tipo de registro.
   */
  useEffect(() => {
    if (state && state.recordTypeId) {
      setSelectedRecordTypeId(state.recordTypeId);
      setShouldShowContactForm(true);
    }
  }, [state]);

  const shouldShowRecordTypeSelection = useCallback(() => {
    return isLoadingRecordTypes || !shouldShowContactForm;
  }, [isLoadingRecordTypes, shouldShowContactForm]);

  const handleOnSelectedRecordType = useCallback((recordTypeId) => {
    setSelectedRecordTypeId(recordTypeId);
    setShouldShowContactForm(true);
  }, []);

  const renderWithBaseLayout = (children) => {
    return (
      <>
        <SimpleHeader showBackArrow="not" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <div className="card-wrapper">
                <Card>
                  <CardHeader>
                    <div className="d-flex flex-row justify-content-between">
                      <h3 className="mb-0">
                        {' '}
                        {contactId
                          ? 'Atualizar contato'
                          : 'Adicionar contato'}{' '}
                      </h3>
                    </div>
                  </CardHeader>
                  <CardBody className="pt-2">{children}</CardBody>
                </Card>
              </div>
            </div>
          </Row>
        </Container>
      </>
    );
  };

  const render = () => {
    if (!recordTypes || !recordTypes.length)
      return renderWithBaseLayout(
        <Row className="justify-content-md-center">
          <Loader />
        </Row>
      );

    return shouldShowRecordTypeSelection()
      ? renderWithBaseLayout(
          <RecordTypeSelection
            recordTypes={recordTypes}
            handleOnSelectedRecordType={handleOnSelectedRecordType}
          />
        )
      : renderWithBaseLayout(
          <FormInputs recordTypeId={selectedRecordTypeId} />
        );
  };

  return render();
};

export default ContactForm;
