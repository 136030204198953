import React, { useState, useEffect } from 'react';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import './style.css';

import bootstrapPlugin from '@fullcalendar/bootstrap';
import { Card, CardBody, Container, Row, CardHeader } from 'reactstrap';

import { BsFillCalendarFill, BsPersonLinesFill } from 'react-icons/bs';

import { addDays, getDate } from 'date-fns';

import UserService from '../User/service';
import CalendarService from './service/CalendarService';

import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

import { ButtonLogo, HeaderCalendar } from './style/index';

import { useHistory } from 'react-router';

import SimpleHeaderOffButton from 'components/Headers/SimpleHeaderOffButton';

const daysOfWeekMap = new Map([
  [0, 'sunday'],
  [1, 'monday'],
  [2, 'tuesday'],
  [3, 'wednesday'],
  [4, 'thursday'],
  [5, 'friday'],
  [6, 'saturday']
]);

export default function CalendarViewScales() {
  const history = useHistory();

  // eslint-disable-next-line no-unused-vars
  const [login, setLogin] = useState(localStorage.getItem('login'));

  // eslint-disable-next-line no-unused-vars
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const [user, setUser] = useState('');

  useEffect(() => {
    viewChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const viewChange = async () => {
    const view = localStorage.getItem('viewChangeDayOff');
    let userLocal = {};

    await UserService.get(login).then((res) => {
      userLocal = res.data.data;
    });

    if (view && !userLocal.isSupervisor) {
      history.push('/admin/ChangeDayOff');
    } else if (view && userLocal.isSupervisor) {
      history.push('/admin/ChangeDayOffSupervisor');
    }
  };

  useEffect(() => {
    UserService.get(login)
      .then((res) => setUser(res.data.data))
      .catch((err) => console.log(err));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getCalendarData(fetchInfo, successCallback) {
    try {
      const startMonthTest = fetchInfo.start;

      let currentDay = startMonthTest.getDate();
      let currentMonth = startMonthTest.getMonth();

      if (currentDay === 1) {
        currentMonth += 1;
      } else {
        currentMonth = currentMonth + 2;
      }

      if (currentMonth > 12) {
        currentMonth = 1;
      }

      let scaleData = [];
      let folgaData = [];
      let shouldStop = false;

      await UserService.get(login).then((res) => {
        const scaleObject = res.data.data.scale;
        scaleData = scaleObject;
      });
      await CalendarService.getCalendarDayOff(
        login,
        fetchInfo.start,
        fetchInfo.end
      ).then((res) => {
        const datesDaysOff = res.data.data.daysOff;
        const datesTimeShift = res.data.data.timeShifts;

        const newDatesTimeShift = datesTimeShift.map((item) => {
          return { ...item, date: item.requestedDateShift, isTimeShift: true };
        });

        const newArray = [];

        // datesDaysOff.map((item) => {
        //   newDatesTimeShift.map((itemTimeShift) => {
        //     if (itemTimeShift.dateTimeShift === item.date) {
        //       newArray.push(itemTimeShift);
        //     } else {
        //       newArray.push(item);
        //     }
        //   });
        // });

        datesDaysOff.map((item) => {
          const isTimeShift = newDatesTimeShift.find((itemTimeShift) => {
            return itemTimeShift.dateTimeShift === item.date;
          });

          if (isTimeShift) {
            item.wasChanged = true;
            item.shiftedFrom = isTimeShift.date;
            newArray.push(item);
            newArray.push(isTimeShift);
          } else {
            newArray.push(item);
          }
        });

        folgaData = newArray;
      });

      let nextDate = fetchInfo.start;

      const data = [];

      while (!shouldStop) {
        const dates = getNextWeekDates(nextDate, scaleData, folgaData);

        if (dates.length > 0) {
          data.push(...dates);
          nextDate = addDays(nextDate, 7);
          const nextMonth = nextDate.getMonth() + 1;
          shouldStop = currentMonth !== nextMonth;
        } else {
          shouldStop = true;
        }
      }

      successCallback(
        data.map((event) => {
          if (event.wasChanged) {
            return {
              title: 'Dia de Trabalho Alterado',
              date: toISOFormat(event.date),
              className: 'bg-green',
              allday: true,
              shiftedFrom: event.shiftedFrom
            };
          } else if (event.isDayOff) {
            return {
              title: 'Folga',
              date: toISOFormat(event.date),
              className: 'bg-yellow',
              textColor: 'black',
              allday: true
            };
          } else if (event.isTimeShift) {
            return {
              title: 'Folga Alterada',
              date: toISOFormat(event.date),
              className: 'bg-green',
              allday: true,
              shiftedFrom: event.shiftedFrom
            };
          } else {
            return {
              title: 'Dia de Trabalho',
              date: toISOFormat(event.date),
              className: 'bg-blue',
              allday: true
            };
          }
        })
      );
    } catch (error) {
      toast.error(error);
    }
  }

  function getNextWeekDates(startDate, scaleObject, daysOffArray) {
    const dates = [];
    Array.from(daysOfWeekMap.keys()).forEach((dayOfWeekNumber) => {
      const nextDayResult = addDays(startDate, dayOfWeekNumber);

      const dayOff = daysOffArray.find(
        (d) => d.date === nextDayResult.toISOString() && !d.isTimeShift
      );
      const timeShift = daysOffArray.find(
        (i) => i.date === nextDayResult.toISOString() && i.isTimeShift
      );

      if (dayOff) {
        dates.push({
          isDayOff: true,
          date: dayOff.date,
          wasChanged: dayOff.wasChanged ?? false,
          shiftedFrom: dayOff.shiftedFrom ?? undefined
        });
      } else if (timeShift) {
        dates.push({
          isTimeShift: true,
          date: timeShift.date,
          shiftedFrom: timeShift.dateTimeShift
        });
      } else if (
        scaleObject &&
        scaleObject[daysOfWeekMap.get(nextDayResult.getDay())]
      ) {
        dates.push({
          isDayOff: false,
          date: nextDayResult.toISOString()
        });
      }
    });
    return dates;
  }

  const toISOFormat = (dateNotFormmated) => {
    if (dateNotFormmated === undefined) {
      console.log('A data está vazia');
    } else {
      const [date] = dateNotFormmated.split('T');
      const [YYYY, MM, DD] = date.split('-');

      return `${YYYY}-${MM}-${DD}`;
    }
  };

  const toReadableFormat = (dateNotFormmated) => {
    if (dateNotFormmated === undefined) {
      console.log('A data está vazia');
    } else {
      const [date] = dateNotFormmated.split('T');
      const [YYYY, MM, DD] = date.split('-');

      return `${DD}-${MM}-${YYYY}`;
    }
  };

  return (
    <>
      <SimpleHeaderOffButton
        setCreate="/admin/Calender"
        name="List"
        parentName="Lists"
      />

      <Container fluid style={{ backgroundColor: '#ffffff' }} className="mt--6">
        <Card className="card-calendar">
          <CardHeader>
            <Container fluid style={{ backgroundColor: '#ffffff' }}>
              <Row>
                <HeaderCalendar>
                  <h2>Calendário</h2>
                  <ButtonLogo active="true">
                    <BsFillCalendarFill color="#646f85"></BsFillCalendarFill>
                  </ButtonLogo>
                  <ButtonLogo
                    onClick={() => {
                      if (user.isSupervisor) {
                        history.push('/admin/ChangeDayOffSupervisor');
                      } else {
                        history.push('/admin/ChangeDayOff');
                      }
                    }}
                  >
                    <BsPersonLinesFill color="#646f85"></BsPersonLinesFill>
                  </ButtonLogo>
                </HeaderCalendar>
              </Row>
            </Container>
          </CardHeader>
          <CardBody className="p-3">
            <FullCalendar
              plugins={[dayGridPlugin, bootstrapPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              events={(fetchInfo, successCallback, testeIcon) =>
                getCalendarData(fetchInfo, successCallback, testeIcon)
              }
              eventContent={(eventInfo) => {
                return (
                  <>
                    <div
                      title={
                        eventInfo.event._def.extendedProps.shiftedFrom
                          ? `Alterado do dia ${toReadableFormat(
                              eventInfo.event._def.extendedProps.shiftedFrom
                            )}`
                          : ''
                      }
                    >
                      {eventInfo.event.title}
                    </div>
                  </>
                );
              }}
              themeSystem="bootstrap"
              locale="pt-BR"
              buttonText={{
                today: 'Hoje',
                month: 'Mês',
                week: 'Semana',
                day: 'Dia',
                list: 'Lista'
              }}
            />
          </CardBody>
        </Card>
      </Container>
    </>
  );
}
