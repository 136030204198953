import { displayLabelOnTopOfTheBar } from './pluginsConfigurations/pluginChartBarDefaultConfiguration';

export const chartBarWithTheLabelsOptions = {
  layout: {
    padding: {
      top: 30
    }
  },
  plugins: {
    datalabels: {
      ...displayLabelOnTopOfTheBar,
      formatter: (value) => {
        return ` ${value.y}(${value.percentage.toFixed(2)}%)`;
      }
    }
  }
};
