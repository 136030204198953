import React from 'react';

import { MessageContainer } from './styles';

const NothingToShow = () => (
  <MessageContainer>
    <p>Nenhuma Fila Selecionada Para Monitoramento</p>
  </MessageContainer>
);

export default NothingToShow;
