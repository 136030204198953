import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { ImageButtonConteinerProtocol } from '../../../assets/styles/image_style';
import { functionsUseFul } from 'views/Protocol/list/assets/functionsUseFul';
import { dateRules } from 'views/Protocol/form/assets/functions';
import { ErrorFormMessage } from 'components/ErrorFormMessage';
import SimpleHeader from 'components/Headers/SimpleHeader';
import { ErrorMessage } from '@hookform/error-message';
import { Controller, useForm } from 'react-hook-form';
import DatePicker from 'components/Inputs/DatePicker';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { individual_column_style } from 'assets/styles/table_custom_style';
import Pagination from 'components/Pagination';
import Loader from 'react-loader-spinner';
import ScalesService from './service';
import { useGetTeams } from '../../User/form/hooks/useGetTeams';
import { useGetScales } from '../../../hooks/useGetScales';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col,
  Form,
  Input
} from 'reactstrap';
import FlexSelect from 'components/Inputs/FlexSelect';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';

export const ScalesReportTeams = () => {
  const { getDateBegin } = functionsUseFul();

  const [data, setData] = useState([]);

  const [beginDate, setBeginDate] = useState(
    new Date(getDateBegin(true)).toISOString()
  );
  const [showDisabled, setShowDisabled] = useState(false);
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [dataToSend, setDataToSend] = useState(null);
  const { teams } = useGetTeams();
  const { scales } = useGetScales();

  const toISOFormat = useCallback((dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${YYYY}/${MM}/${DD}`;
    return `${YYYY}-${MM}-${DD}`;
  }, []);

  const formatDate = (oldDate) => {
    const [DD, MM, YYYY] = oldDate.split('/');
    return `${MM}-${DD}-${YYYY}`;
  };

  const getReports = () => {
    setIsLoading(true);
    ScalesService.getAllSearchScale(false, 10, activePage, dataToSend)
      .then((res) => {
        if (res.data.totalRegisters === 0) {
          toast.error('Nenhum registro encontrado');
        }
        setData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onSubmit = (data) => {
    const formatedData = formatData(data);
    setDataToSend(formatedData);
    setIsLoading(true);
    setActivePage(1);
    const dataToSend = formatData(data);
    setDataToSend(dataToSend);
    ScalesService.getAllSearchScale(false, 10, 1, formatedData)
      .then((res) => {
        if (res.data.totalRegisters === 0) {
          toast.info('Nenhum registro encontrado');
        }
        setQuantity(res.data.totalRegisters);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log('err', err);
        toast.error(
          err || 'Ocorreu um erro por favor entre em contato com o suporte'
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
    return;
  };

  const formatData = (data) => {
    data.beginDate = beginDate;
    data.endDate = endDate;

    if (data.teams && data.teams.length > 0) {
      data.teams = JSON.stringify(data.teams);
    }
    if (data.scales && data.scales.length > 0) {
      data.scales = JSON.stringify(data.scales);
    }

    if (!data.teams) data.teams = '[]';
    if (!data.scales) data.scales = '[]';

    delete data['beginDate '];
    delete data.dateEnd;

    return data;
  };

  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { control, handleSubmit, getValues, setValue, errors } = useForm();

  const handlePageChange = (page) => {
    setActivePage(page);
    getReports();
  };

  const formatPercentage = (number) => {
    return number || number === 0 ? number + '%' : '-';
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <div className="d-flex flex-row justify-content-between">
                    <h3 className="mb-0">Relatório de Escalas por Equipe</h3>
                  </div>
                </CardHeader>
                <CardBody className="pt-2">
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mb-3">
                      <Col md={{ size: 6 }}>
                        <label className="form-control-label">
                          Data inicial
                        </label>
                        <Controller
                          render={(props) => (
                            <DatePicker
                              fieldName="beginDate"
                              valueController={(fieldName, value) => {
                                setValue(fieldName, value);
                                const newDate = new Date(
                                  formatDate(value)
                                ).toISOString();
                                setBeginDate(newDate);
                              }}
                              defaultValue={props.value}
                            />
                          )}
                          control={control}
                          name="beginDate"
                          defaultValue={getDateBegin()}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="beginDate"
                          render={({ message }) => (
                            <ErrorFormMessage message={message} />
                          )}
                        />
                      </Col>
                      <Col md={{ size: 6 }}>
                        <label className="form-control-label">Data final</label>
                        <Controller
                          render={(props) => (
                            <DatePicker
                              fieldName="dateEnd"
                              valueController={(fieldName, value) => {
                                setValue(fieldName, value);
                                const newDate = new Date(
                                  formatDate(value)
                                ).toISOString();
                                setEndDate(newDate);
                              }}
                              defaultValue={props.value}
                            />
                          )}
                          control={control}
                          name="dateEnd"
                          defaultValue={new Date().toLocaleDateString('pt-br')}
                          rules={{
                            ...dateRules,
                            validate: async (value) => {
                              if (
                                new Date(await toISOFormat(value, true)) <
                                new Date(
                                  await toISOFormat(
                                    getValues()['beginDate'],
                                    true
                                  )
                                )
                              ) {
                                return 'A data final não pode ser anterior à inicial!';
                              } else {
                                return true;
                              }
                            }
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="dateEnd"
                          render={({ message }) => (
                            <ErrorFormMessage message={message} />
                          )}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">Equipes</label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              isClearable={true}
                              dataOptions={teams}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              value={props.value}
                              valueController={setValue}
                              fieldName="teams"
                              labelName="name"
                              valueName="id"
                            />
                          )}
                          control={control}
                          name="teams"
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="teams"
                          render={({ message }) => (
                            <ErrorFormMessage message={message} />
                          )}
                        />
                      </Col>

                      <Col md="6">
                        <label className="form-control-label">Escala</label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              isClearable={true}
                              dataOptions={scales}
                              isMulti={true}
                              closeMenuOnSelect={false}
                              value={props.value}
                              valueController={setValue}
                              fieldName="scales"
                              labelName="name"
                              valueName="id"
                            />
                          )}
                          control={control}
                          name="scales"
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="scales"
                          render={({ message }) => (
                            <ErrorFormMessage message={message} />
                          )}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col className="md-6 mx-4">
                        <label className="form-control-label">
                          <Input
                            type="checkbox"
                            onChange={(e) => {
                              setShowDisabled(!showDisabled);
                            }}
                            checked={showDisabled}
                          />
                          Mostrar Filas desabilitadas
                        </label>
                      </Col>
                    </Row>

                    <div className="mt-3" style={ImageButtonConteinerProtocol}>
                      <Button
                        style={buttonStyle(systemConfiguration.primary_color)}
                        type="submit"
                        size="sm"
                      >
                        Filtrar protocolos
                      </Button>
                    </div>
                  </Form>
                </CardBody>
                {data && data.length > 0 ? (
                  isLoading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      <ToolkitProvider
                        data={data}
                        keyField="id"
                        columns={[
                          {
                            dataField: 'name',
                            text: 'Equipe',
                            sort: true,
                            style: individual_column_style
                          },
                          {
                            dataField: 'totalHired',
                            text: 'Tempo total contratado',
                            sort: true,
                            style: individual_column_style
                          },
                          {
                            dataField: 'overtimeTotalHired',
                            text: 'Tempo de hora extra solicitado',
                            sort: true,
                            style: individual_column_style
                          },
                          {
                            dataField: 'totalHiredWithOvertime',
                            text: 'Tempo total contratado com hora extra',
                            sort: true,
                            style: individual_column_style
                          },
                          {
                            dataField: 'overtimeTotal',
                            text: 'Tempo de hora extra efetivo',
                            sort: true,
                            style: individual_column_style
                          },
                          {
                            dataField: 'automaticPauseTotalHired',
                            text: 'Tempo total de pausa automática',
                            sort: true,
                            style: individual_column_style
                          },
                          {
                            dataField: 'loggedTotal',
                            text: 'Tempo total logado',
                            sort: true,
                            style: individual_column_style
                          },
                          {
                            dataField: 'loggedWithoutOvertimeTotal',
                            text: 'Tempo total logado sem hora extra',
                            sort: true,
                            style: individual_column_style
                          },
                          {
                            dataField: 'pausedTotal',
                            text: 'Tempo total pausado',
                            sort: true,
                            style: individual_column_style
                          },
                          {
                            dataField: 'automaticPauseTotal',
                            text: 'Tempo total pausado por pausa automática',
                            sort: true,
                            style: individual_column_style
                          },
                          {
                            dataField: 'loggedPercentage',
                            text: 'percentual logado',
                            sort: true,
                            style: individual_column_style,
                            formatter: (_, row) => {
                              return formatPercentage(row.loggedPercentage);
                            }
                          },
                          {
                            dataField: 'loggedWithoutOvertimePercentage',
                            text: 'percentual logado sem hora extra',
                            sort: true,
                            style: individual_column_style,
                            formatter: (_, row) => {
                              return formatPercentage(
                                row.loggedWithoutOvertimePercentage
                              );
                            }
                          },
                          {
                            dataField: 'absenceTotalPercentage',
                            text: 'percentual de absenteísmo',
                            sort: true,
                            style: individual_column_style,
                            formatter: (_, row) => {
                              return formatPercentage(
                                row.absenceTotalPercentage
                              );
                            }
                          },
                          {
                            dataField: 'absenceWithoutPauseTotalPercentage',
                            text: 'percentual de absenteísmo sem tempo de pausa',
                            sort: true,
                            style: individual_column_style,
                            formatter: (_, row) => {
                              return formatPercentage(
                                row.absenceWithoutPauseTotalPercentage
                              );
                            }
                          },
                          {
                            dataField: 'conversationPercentage',
                            text: 'percentual de conversação em relação ao tempo contratado',
                            sort: true,
                            style: individual_column_style,
                            formatter: (_, row) => {
                              return formatPercentage(
                                row.conversationPercentage
                              );
                            }
                          },
                          {
                            dataField: 'conversationPercentageWithoutPause',
                            text: 'percentual de conversação sem pausa',
                            sort: true,
                            style: individual_column_style,
                            formatter: (_, row) => {
                              return formatPercentage(
                                row.conversationPercentageWithoutPause
                              );
                            }
                          },
                          {
                            dataField: 'nonAdherentTime',
                            text: 'Tempo inaderente',
                            sort: true,
                            style: individual_column_style
                          },
                          {
                            dataField: 'adherentTimePercentage',
                            text: 'Percentual Tempo Inaderente',
                            sort: true,
                            style: individual_column_style,
                            formatter: (_, row) => {
                              return formatPercentage(
                                row.adherentTimePercentage
                              );
                            }
                          }
                        ]}
                      >
                        {(props) => (
                          <div style={{ overflow: 'auto' }}>
                            <BootstrapTable
                              {...props.baseProps}
                              bootstrap4={true}
                              bordered={false}
                            />
                          </div>
                        )}
                      </ToolkitProvider>
                      <Pagination
                        activePage={activePage}
                        totalItemsCount={quantity}
                        onChange={handlePageChange.bind(this)}
                      />
                    </>
                  )
                ) : null}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
