import React from 'react';

import { ChatDialogTreeContainer } from './styled';
import NodeTree from './node';

const DialogTreeComponent = ({ data = {} }) => {
  return (
    <ChatDialogTreeContainer>
      {data && (
        <ul>
          {Object.keys(data).map((key) => (
            <NodeTree key={key} nodeData={data[key]} />
          ))}
        </ul>
      )}
    </ChatDialogTreeContainer>
  );
};

export default DialogTreeComponent;
