import { useState, useEffect } from 'react';
import TeamService from '../../../Team/service';

export function useGetTeams() {
  const [teams, setTeams] = useState([]);

  useEffect(() => {
    TeamService.getAll()
      .then((response) => {
        setTeams(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);

  return {
    teams,
    setTeams
  };
}
