import React from 'react';

const Success = ({ fontColor }) => {
  return (
    <div className="text-center mb-8 mt-6">
      <i
        style={{ fontSize: '80px', color: fontColor }} //99b543
        className="ni ni-check-bold"
      ></i>
      <h3 style={{ paddingBottom: '30px', color: fontColor }}>
        Registramos sua satisfação no nosso sistema
      </h3>
      <h3 style={{ paddingBottom: '30px', color: fontColor }}> Obrigado!</h3>
    </div>
  );
};

export default Success;
