import React from 'react';
import QueueContent from './components/QueueContent';
import AttendanceContent from './components/AttendanceContent';
import PauseServicePainel from './components/PauseServicePainel';
import { Content } from './components/styled';
import { verifyRoles } from 'components/Authentication/VerifyRoles';
import { useServiceBlockServicePainelContext } from '../../hooks/useBlockAcessServicePainel';

const ServicePanel = () => {
  const { isBlockServicePainel } = useServiceBlockServicePainelContext();

  return (
    <>
      {verifyRoles(['ATTENDANT', 'TRAINEE', 'ATTENDANCE_PANEL']) &&
      !isBlockServicePainel ? (
        <Content>
          <div className="queue">
            <QueueContent />
          </div>
          <div className="attendance">
            <AttendanceContent />
          </div>
        </Content>
      ) : (
        <PauseServicePainel />
      )}
    </>
  );
};

export default ServicePanel;
