import React, { useCallback, useEffect, useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import {
  toggleSideDrawer,
  closeSideDrawer
} from 'store/action/sideDrawerActions';
import DialerVisualizationForm from '../../components/Navbars/ServicePanel/DialerCallInformation/form';
import ThirdSystem from './ThirdSystem';

import {
  SideDrawerContainer,
  ButtonToggleContainer,
  ToggleButton,
  ToggleButtonText,
  ScrollWrapper
} from './styled';

const defaultTabs = [
  {
    id: 1,
    title: 'Dados do Atendimento',
    content: null,
    isOpen: false,
    isSelected: false
  },
  {
    id: 2,
    title: 'Tela Sistema',
    content: null,
    isOpen: false,
    isSelected: false
  }
];

const SideDrawer = () => {
  const sideDrawer = useSelector((state) => state.sideDrawer);

  const [tabs, setTabs] = useState(defaultTabs);
  const dispatch = useDispatch();

  useEffect(() => {
    setTabs((prev) =>
      prev.map((tab) => {
        return {
          ...tab,
          isOpen: tab.isOpen || tab.id === sideDrawer.data.tabId,
          isSelected: tab.id === sideDrawer.data.tabId,
          content: sideDrawer?.data?.answeringDialerCallData
        };
      })
    );
  }, [sideDrawer, sideDrawer?.data?.answeringDialerCallData]);

  const handleTabClick = (tabId) => {
    const [tabAlreadySelected] = tabs.filter((tab) => tab.isSelected);

    if (sideDrawer.isDrawerCollapsed || tabAlreadySelected.id === tabId) {
      dispatch(toggleSideDrawer());
    }

    const updatedTabs = tabs.map((tab) => ({
      ...tab,
      isSelected: tab.id === tabId
    }));
    setTabs(updatedTabs);
  };

  const closeDrawer = useCallback(
    (tabId) => {
      return () => {
        const updatedTabs = tabs.map((tab) => {
          if (tab.id === tabId) {
            return {
              ...tab,
              isOpen: false,
              isSelected: false
            };
          }

          return tab;
        });
        setTabs(updatedTabs);
        dispatch(closeSideDrawer());
      };
    },
    [dispatch, tabs]
  );

  return (
    <SideDrawerContainer>
      <ButtonToggleContainer>
        {tabs.map(
          (tab) =>
            tab.isOpen && (
              <ToggleButton key={tab.id} onClick={() => handleTabClick(tab.id)}>
                <FiArrowLeft
                  className="mb-2"
                  size="12"
                  style={{
                    transform: `rotate(${
                      !sideDrawer.isDrawerCollapsed ? '180deg' : '0deg'
                    })`,
                    transition: 'transform 0.6s'
                  }}
                />
                <ToggleButtonText>{tab.title}</ToggleButtonText>
              </ToggleButton>
            )
        )}
      </ButtonToggleContainer>

      <ScrollWrapper collapsed={sideDrawer.isDrawerCollapsed}>
        {tabs
          .filter((tab) => tab.isSelected)
          .map((tab) => {
            if (tab.id === 1) {
              return (
                <DialerVisualizationForm
                  closeDrawer={closeDrawer(tab.id)}
                  data={tab.content}
                  key={tab.id}
                />
              );
            } else if (tab.id === 2) {
              return (
                <ThirdSystem closeDrawer={closeDrawer(tab.id)} key={tab.id} />
              );
            }
          })}
      </ScrollWrapper>
    </SideDrawerContainer>
  );
};

export default SideDrawer;
