import styled from 'styled-components';

export const Container = styled.section`
  background: transparent;
  position: fixed;
  border-radius: 2px;
  z-index: 100;
  bottom: 0px;
  right: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  @media (max-width: 800px) {
    display: none;
  }
`;
