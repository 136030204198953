import React from 'react';
import { Container, AreaLogin } from './style';
import { FaUserAlt, FaLock } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { UserAgent, Registerer } from 'sip.js';

import { nextPage } from '../../../../store/action/softphonePages/navigationPages';

const Login = () => {
  const dispatch = useDispatch();

  //onde ele se conectará usando websockets transport
  const transportOptions = {
    server: 'wss://aws.g4flex.com.br:8089/ws'
  };

  //criando a uri
  const uri = UserAgent.makeURI('sip:3002@aws.g4flex.com.br:5162');

  //objeto com as informações de acesso de agente do usuário altenticado
  const userAgentOptions = {
    authorizationPassword: 'testeaa3002',
    // authorizationUsername: '3002',
    authorizationUser: '3002',
    transportOptions,
    hackWssInTransport: true,
    uri
  };

  //posibilita fazer a chamada (metodo construtor);
  const userAgent = new UserAgent(userAgentOptions);

  const registerer = new Registerer(userAgent);

  function registerSoftphone() {
    userAgent
      .start()
      .then((response) => {
        console.log('registrado', response);
        registerer.register();
        dispatch(nextPage('dashboard'));
      })
      .catch((error) => {
        console.log('erro ao conectar', error);
      });
  }

  // function ligar() {
  //   const target = UserAgent.makeURI('sip:bob@example.com');

  //   const inviter = new Inviter(userAgent, target);
  //   inviter.invite();
  // }

  return (
    <Container>
      <div className="ContentLogin">
        <AreaLogin>
          <FaUserAlt size="18" />
          <input type="text" placeholder="ramal" />
        </AreaLogin>
        <AreaLogin>
          <FaLock size="18" />
          <input type="password" placeholder="senha" />
        </AreaLogin>
        <button onClick={registerSoftphone} className="softphone-btn">
          Registrar
        </button>
      </div>
    </Container>
  );
};

export { Login };
