import React from 'react';
import ReactDatetime from 'react-datetime';
import 'moment/locale/pt-br';

import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from 'reactstrap';

const Datepicker = ({
  showIconCalendar = true,
  flexWrap = true,
  tabIndex = 0,
  ...props
}) => {
  const inputPropsCustom = {
    style: {
      border: '1px solid #c4c4c4'
    },
    disabled: props.shouldDisable ? true : false,
    tabIndex: tabIndex
  };
  const controlChange = (values) => {
    let date = values;
    if (values._d) {
      date = values._d.toLocaleDateString('pt-br');

      return props.valueController(props.fieldName, date);
    }

    if (values) {
      return props.valueController(props.fieldName, date);
    }

    return props.valueController(props.fieldName, '');
  };

  return (
    <>
      <FormGroup style={{ marginBottom: '0px' }}>
        <InputGroup
          style={{ flexWrap: flexWrap ? 'wrap' : 'nowrap' }}
          className="input-group-alternative"
        >
          {showIconCalendar && (
            <InputGroupAddon addonType="prepend">
              <InputGroupText style={{ border: '1px solid #c4c4c4' }}>
                <i className="ni ni-calendar-grid-58" />
              </InputGroupText>
            </InputGroupAddon>
          )}
          <ReactDatetime
            closeOnSelect={true}
            closeOnTab={true}
            name={props.fieldName}
            className="reactDate"
            inputProps={inputPropsCustom}
            onChange={controlChange}
            timeFormat={false}
            locale="pt-br"
            value={props.defaultValue}
          />
        </InputGroup>
      </FormGroup>
    </>
  );
};

export default Datepicker;
