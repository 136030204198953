import React, { useState, useEffect, useCallback } from 'react';
import { Button, Row, Col, Form, FormGroup, Label } from 'reactstrap';
import { FiSettings } from 'react-icons/fi';
import { Modal, ModalBody } from 'reactstrap';
import { useForm, Controller } from 'react-hook-form';
import axios from 'axios';
import { toast } from 'react-toastify';

import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import ReactLoader from 'components/Loader';
import { defaultFields } from '../../../utils/defaultFields';
import { defaultOptionalFields } from '../../../utils/defaultOptionalFields';

import DialerService from '../../../service';

const InteractiveDialerSettingsModal = ({
  disabled,
  integrationUrl,
  dialerId
}) => {
  const { control, handleSubmit, setValue, watch, reset } = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [fields, setFields] = useState(defaultFields);
  const [optionalFields, setOptionalFields] = useState(defaultOptionalFields);
  const [integrationFields, setIntegrationFields] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const formValues = watch();

  useEffect(() => {
    if (isOpen) {
      const fetchIntegrationUrlData = async () => {
        const { url, method, headers: headersRaw } = integrationUrl;
        const headers = headersRaw
          .split(';')
          .map((e) => e.split(': '))
          .reduce((prev, [k, v]) => {
            prev[k] = v;
            return prev;
          }, {});
        const res = await axios.request({ url, method, headers });
        return res;
      };
      fetchIntegrationUrlData()
        .then((res) => {
          const [integrationData] = res.data.data || res.data;
          const fieldsOfTheIntegration = Object.keys(integrationData);
          setIntegrationFields(fieldsOfTheIntegration);
        })
        .then(async () => {
          const response = await DialerService.getIntegrationDialerSettings(
            dialerId
          );
          const data = response.data.data;

          const formDataDTO = {
            nome: data.name,
            identificador: data.identification,
            whatsappNumber: data.whatsappNumber,
            numero: data.phoneNumbers && data.phoneNumbers.shift(),
            visualizationFields: data.visualizationFields || []
          };

          const phoneNumberFieldsDTO = [];
          if (data.phoneNumbers) {
            data.phoneNumbers.forEach((field, index) => {
              const keyName = `numero${index + 2}`;

              formDataDTO[keyName] = field;
              phoneNumberFieldsDTO.push({
                name: keyName,
                label: `Número ${index + 2}`
              });
            });
          }

          const remainOptionalFields = [...optionalFields].slice(
            phoneNumberFieldsDTO.length
          );

          setFields([...fields, ...phoneNumberFieldsDTO]);
          setOptionalFields(remainOptionalFields);
          reset(formDataDTO);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoading(false));
    }
  }, [isOpen, integrationUrl]);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const resetFieldsState = () => {
    setFields(defaultFields);
    setOptionalFields(defaultOptionalFields);
    setLoading(true);
  };

  const getOptionsDTO = useCallback(() => {
    if (!integrationFields) return [];
    return integrationFields.map((value) => ({ value }));
  }, [integrationFields]);

  const getVisualizationFields = useCallback(() => {
    if (!integrationFields) return [];
    const selectedFields = Object.values(formValues);
    return integrationFields
      .filter((field) => !selectedFields.includes(field))
      .map((value) => ({ value }));
  }, [formValues, integrationFields]);

  const onSubmit = (data) => {
    const requiredFields = ['nome', 'identificador'];
    for (const field of requiredFields) {
      if (!data[field]) return toast.error(`O campo ${field} é obrigatório`);
    }

    const phoneNumberFields = Object.keys(data)
      .filter((key) => {
        return data[key] && /numero.?/g.test(key);
      })
      .map((key) => data[key]);

    const requestDataDTO = {
      dialer_id: dialerId,
      name: data.nome,
      identification: data.identificador,
      whatsappNumber: data.whatsappNumber,
      phoneNumberFields,
      visualizationFields: data.visualizationFields || null
    };

    setIsSaving(true);
    DialerService.createIntegrationDialerSettings(requestDataDTO)
      .then(() => {
        toast.success('Configurações salvas com sucesso');
        setIsOpen(false);
        resetFieldsState();
      })
      .catch((error) => {
        const msg =
          error.response?.data?.message || 'Erro ao salvar configuração';
        toast.error(msg);
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  return (
    <>
      <Button
        size="sm"
        color="success"
        title="Definir Configurações"
        onClick={toggleModal}
        disabled={disabled}
      >
        <FiSettings />
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        unmountOnClose={true}
        toggle={() => {
          toggleModal();
          resetFieldsState();
        }}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Definir configurações
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>&times;</span>
          </button>
        </div>
        <ModalBody>
          <Form onSubmit={handleSubmit(onSubmit)}>
            {loading ? (
              <Row className="justify-content-md-center">
                <ReactLoader />
              </Row>
            ) : (
              <>
                {fields.map((e) => (
                  <Controller
                    key={e.name}
                    control={control}
                    name={e.name}
                    defaultValue=""
                    render={(props) => (
                      <FormGroup>
                        <Label className="form-control-label">{e.label}</Label>
                        <FlexSelect
                          isClearable={true}
                          dataOptions={getOptionsDTO()}
                          isMulti={false}
                          closeMenuOnSelect={true}
                          value={props.value}
                          valueController={setValue}
                          fieldName={e.name}
                          labelName="value"
                          valueName="value"
                        />
                      </FormGroup>
                    )}
                  />
                ))}
                <div>
                  <span style={{ paddingRight: '5px' }}>
                    Associar mais um número
                  </span>
                  <Button
                    type="button"
                    size="sm"
                    color="default"
                    onClick={() => {
                      if (optionalFields.length > 0) {
                        const optionalFieldsCopy = [...optionalFields];
                        const field = optionalFieldsCopy.shift();
                        setOptionalFields(optionalFieldsCopy);
                        setFields([...fields, field]);
                      }
                    }}
                  >
                    <li className="fas fa-plus"></li>
                  </Button>
                </div>
                <Row className="mt-4">
                  <Col>
                    <Controller
                      key="visualizationFields"
                      control={control}
                      name="visualizationFields"
                      defaultValue=""
                      render={(props) => (
                        <FormGroup>
                          <Label className="form-control-label">
                            Campos para visualização
                          </Label>
                          <FlexSelect
                            isClearable={true}
                            dataOptions={getVisualizationFields()}
                            isMulti={true}
                            closeMenuOnSelect={false}
                            value={props.value}
                            valueController={setValue}
                            fieldName="visualizationFields"
                            labelName="value"
                            valueName="value"
                          />
                        </FormGroup>
                      )}
                    />
                  </Col>
                </Row>
              </>
            )}
            <Row className="mt-5">
              <Col>
                <ReactButton
                  btnColor="confirmation"
                  type="submit"
                  disabled={isSaving || loading}
                >
                  Salvar
                </ReactButton>
                <ReactButton
                  btnColor="cancelation"
                  onClick={() => {
                    toggleModal();
                    resetFieldsState();
                  }}
                >
                  Cancelar
                </ReactButton>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default InteractiveDialerSettingsModal;
