import styled from 'styled-components';

export const Container = styled.section`
  display: flex;
  height: 50px;
  align-items: center;
  padding: 0 10px 0 10px;
  justify-content: space-between;
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
`;
