import React, { useEffect, useState } from 'react';
import { sortData } from '../../../../../utils/fuctions/sortData';
import Chart from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';
import { Row, Card, Col, CardBody, CardHeader, Container } from 'reactstrap';
import randomColor from 'randomcolor';
import CountUp from 'react-countup';
import {
  chartExample2,
  chartExample5,
  chartOptions,
  parseOptions
} from 'variables/charts.js';
import { ColorArea } from '../assets/components/styleds';

const Synthetic = ({ dataReport }) => {
  const [filteredLabels, setFilteredLabels] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [filteredColors, setFilteredColors] = useState(null);
  const [userDatasets, setUserDatasets] = useState(null);
  const [channelDatasets, setChannelDatasets] = useState(null);
  const [queueDatasets, setQueueDatasets] = useState(null);
  const [count, setCount] = useState(null);

  useEffect(() => {
    if (window.Chart) parseOptions(Chart, chartOptions());
    if (dataReport.filtrado.conversasFiltradas) {
      const isOrderedConversations = sortData(
        dataReport.filtrado.conversasFiltradas,
        'count'
      );

      let arrayLabels = [];
      let arrayData = [];
      let arrayColors = [];
      let totalCount = 0;

      isOrderedConversations.forEach((el) => {
        if (typeof el._id === 'boolean') {
          if (el._id) {
            dataReport.group === 'atendimentoBot'
              ? arrayLabels.push('Atendimento Bot')
              : arrayLabels.push('Sim');
          } else {
            dataReport.group === 'atendimentoBot'
              ? arrayLabels.push('Atendimento Humano')
              : arrayLabels.push('Não');
          }
        } else {
          if (dataReport.group === 'motivo') {
            el._id === ''
              ? arrayLabels.push('SEM MOTIVO')
              : arrayLabels.push(el._id);
          } else {
            arrayLabels.push(el._id);
          }
        }
        arrayData.push(el.count);
        arrayColors.push(randomColor({ luminosity: 'random', hue: 'random' }));
        totalCount += el.count;
      });

      setFilteredLabels([...arrayLabels]);
      setFilteredData([...arrayData]);
      setFilteredColors([...arrayColors]);
      setCount(totalCount);
    }

    //Atendentes charts
    let arrayUsers = [];
    //Geeting all users
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < Object.values(dataReport.atendente)[i].length; j++) {
        if (
          !arrayUsers.includes(Object.values(dataReport.atendente)[i][j]._id)
        ) {
          arrayUsers.push(Object.values(dataReport.atendente)[i][j]._id);
        }
      }
    }

    arrayUsers = arrayUsers.sort();
    let arrayUserDatasets = [];

    for (let i = 0; i < arrayUsers.length; i++) {
      arrayUserDatasets.push({
        label: `${arrayUsers[i]}  `,
        backgroundColor: randomColor({ luminosity: 'random', hue: 'random' }),
        borderColor: 'transparent',
        borderWidth: 1,
        data: [
          dataReport.atendente.conversasFinalizadas.find(
            (el) => el._id === arrayUsers[i]
          )
            ? dataReport.atendente.conversasFinalizadas.find(
                (el) => el._id === arrayUsers[i]
              ).count
            : 0,
          dataReport.atendente.conversasAbandonadas.find(
            (el) => el._id === arrayUsers[i]
          )
            ? dataReport.atendente.conversasAbandonadas.find(
                (el) => el._id === arrayUsers[i]
              ).count
            : 0,
          dataReport.atendente.conversasNaoAtendidas.find(
            (el) => el._id === arrayUsers[i]
          )
            ? dataReport.atendente.conversasNaoAtendidas.find(
                (el) => el._id === arrayUsers[i]
              ).count
            : 0
        ]
      });
    }
    setUserDatasets([...arrayUserDatasets]);

    //Canal chart
    let arrayChannel = [];
    //getting all channels
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < Object.values(dataReport.canal)[i].length; j++) {
        if (!arrayChannel.includes(Object.values(dataReport.canal)[i][j]._id)) {
          arrayChannel.push(Object.values(dataReport.canal)[i][j]._id);
        }
      }
    }

    arrayChannel = arrayChannel.sort();
    let arrayChannelDatasets = [];

    for (let i = 0; i < arrayChannel.length; i++) {
      arrayChannelDatasets.push({
        label: `${arrayChannel[i]}  `,
        backgroundColor: randomColor({ luminosity: 'random', hue: 'random' }),
        borderColor: 'transparent',
        borderWidth: 1,
        data: [
          dataReport.canal.conversasFinalizadas.find(
            (el) => el._id === arrayChannel[i]
          )
            ? dataReport.canal.conversasFinalizadas.find(
                (el) => el._id === arrayChannel[i]
              ).count
            : 0,
          dataReport.canal.conversasAbandonadas.find(
            (el) => el._id === arrayChannel[i]
          )
            ? dataReport.canal.conversasAbandonadas.find(
                (el) => el._id === arrayChannel[i]
              ).count
            : 0,
          dataReport.canal.conversasNaoAtendidas.find(
            (el) => el._id === arrayChannel[i]
          )
            ? dataReport.canal.conversasNaoAtendidas.find(
                (el) => el._id === arrayChannel[i]
              ).count
            : 0
        ]
      });
    }
    setChannelDatasets([...arrayChannelDatasets]);

    //Fila chart
    let arrayQueue = [];
    //getting all queues
    for (let i = 0; i < 3; i++) {
      for (let j = 0; j < Object.values(dataReport.fila)[i].length; j++) {
        if (!arrayQueue.includes(Object.values(dataReport.fila)[i][j]._id)) {
          arrayQueue.push(Object.values(dataReport.fila)[i][j]._id);
        }
      }
    }

    arrayQueue = arrayQueue.sort();
    let arrayQueueDatasets = [];

    for (let i = 0; i < arrayQueue.length; i++) {
      arrayQueueDatasets.push({
        label: `${arrayQueue[i]}  `,
        backgroundColor: randomColor({ luminosity: 'random', hue: 'random' }),
        borderColor: 'transparent',
        borderWidth: 1,
        data: [
          dataReport.fila.conversasFinalizadas.find(
            (el) => el._id === arrayQueue[i]
          )
            ? dataReport.fila.conversasFinalizadas.find(
                (el) => el._id === arrayQueue[i]
              ).count
            : 0,
          dataReport.fila.conversasAbandonadas.find(
            (el) => el._id === arrayQueue[i]
          )
            ? dataReport.fila.conversasAbandonadas.find(
                (el) => el._id === arrayQueue[i]
              ).count
            : 0,
          dataReport.fila.conversasNaoAtendidas.find(
            (el) => el._id === arrayQueue[i]
          )
            ? dataReport.fila.conversasNaoAtendidas.find(
                (el) => el._id === arrayQueue[i]
              ).count
            : 0
        ]
      });
    }
    setQueueDatasets([...arrayQueueDatasets]);
  }, [dataReport]);

  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            <Card>
              <Container fluid>
                {filteredData && (
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardHeader>
                          <h6 className="surtitle">Visão Geral</h6>
                          <h5 className="h3 mb-0">Conversas Filtradas</h5>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md="4" style={{ alignSelf: 'center' }}>
                              <div className="chart">
                                <Doughnut
                                  data={{
                                    labels: [...filteredLabels],
                                    datasets: [
                                      {
                                        data: [...filteredData],
                                        backgroundColor: [...filteredColors],
                                        label: 'Conversas filtradas'
                                      }
                                    ]
                                  }}
                                  options={chartExample5.options}
                                  className="chart-canvas"
                                  id="chart-doughnut"
                                />
                              </div>
                            </Col>
                            <Col md="8" style={{ alignSelf: 'center' }}>
                              <Row>
                                <Col md="12">
                                  <h1 className="display-1">
                                    <CountUp end={count} />
                                  </h1>
                                  <h2 className="text-muted font-weight-light mt--3">
                                    Conversas filtradas
                                  </h2>
                                </Col>
                              </Row>
                              <hr />
                              <Row>
                                {filteredData.map((data, index) => {
                                  return (
                                    <Col
                                      md="2"
                                      key={index}
                                      className="mt--3 mb--3"
                                      style={{
                                        minWidth: '177px',
                                        minHeight: '130px'
                                      }}
                                    >
                                      <Row
                                        className="row flex-nowrap"
                                        style={{
                                          minHeight: '100px'
                                        }}
                                      >
                                        <Col
                                          md="1"
                                          style={{
                                            padding: 0
                                          }}
                                        >
                                          <ColorArea
                                            color={filteredColors[index]}
                                          />
                                        </Col>
                                        <Col md="11">
                                          <h2>{filteredData[index]}</h2>
                                          <h3 className="text-muted font-weight-light mt--3">
                                            {filteredLabels[index] === '' ||
                                            filteredLabels[index] === null
                                              ? '-'
                                              : filteredLabels[index]}
                                          </h3>
                                          <h2
                                            className="mt--1 font-weight-light"
                                            style={{
                                              paddingBottom: '1.5625rem'
                                            }}
                                          >
                                            {`${parseFloat(
                                              (100 * filteredData[index]) /
                                                count
                                            ).toFixed(2)}%`}
                                          </h2>
                                        </Col>
                                      </Row>
                                    </Col>
                                  );
                                })}
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {userDatasets && (
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardHeader>
                          <h6 className="surtitle">Visão Geral</h6>
                          <h5 className="h3 mb-0">Conversas por atendente</h5>
                        </CardHeader>
                        <CardBody>
                          <div className="chart">
                            <Bar
                              data={{
                                labels: [
                                  'Finalizadas',
                                  'Abandonadas',
                                  'Não Atendidas'
                                ],
                                datasets: [...userDatasets]
                              }}
                              options={{
                                legend: { display: true, position: 'right' },
                                ...chartExample2.options
                              }}
                              className="chart-canvas"
                              id="chart-bars1"
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {channelDatasets && (
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardHeader>
                          <h6 className="surtitle">Visão Geral</h6>
                          <h5 className="h3 mb-0">Conversas por canal</h5>
                        </CardHeader>
                        <CardBody>
                          <div className="chart">
                            <Bar
                              data={{
                                labels: [
                                  'Finalizadas',
                                  'Abandonadas',
                                  'Não Atendidas'
                                ],
                                datasets: [...channelDatasets]
                              }}
                              options={{
                                legend: { display: true, position: 'right' },
                                ...chartExample2.options
                              }}
                              className="chart-canvas"
                              id="chart-bars1"
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
                {queueDatasets && (
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardHeader>
                          <h6 className="surtitle">Visão Geral</h6>
                          <h5 className="h3 mb-0">Conversas por fila</h5>
                        </CardHeader>
                        <CardBody>
                          <div className="chart">
                            <Bar
                              data={{
                                labels: [
                                  'Finalizadas',
                                  'Abandonadas',
                                  'Não Atendidas'
                                ],
                                datasets: [...queueDatasets]
                              }}
                              options={{
                                legend: { display: true, position: 'right' },
                                ...chartExample2.options
                              }}
                              className="chart-canvas"
                              id="chart-bars1"
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}
              </Container>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Synthetic;
