import React, { useContext, useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';

import { useSocketWatingCalls } from 'views/Monitoring/Queue/MonitoringScreen/hooks/useSocketWatingCalls';

import { AgentCtx } from '../../contexts/AgentCtx';
import CollectiveIndicatorCard from './components/CollectiveIndicatorCard';
import ServiceLevelChart from './components/ServiceLevelChart';
import ReceivedCallsChart from './components/ReceivedCallsChart';
import AgentsStatusChart from './components/AgentsStatusChart';

const CollectiveIndicators = () => {
  const { queues, collectiveIndicators, socket } = useContext(AgentCtx);

  const queuesToMonit = useMemo(() => {
    return queues.map(({ id }) => id);
  }, [queues]);
  const nameQueuesToMonit = useMemo(() => {
    return queues.map(({ name }) => name);
  }, [queues]);

  const { watingCalls } = useSocketWatingCalls(socket, nameQueuesToMonit);

  return (
    <Container className="p-0">
      <Row>
        <Col xl="3">
          <CollectiveIndicatorCard
            title="Fila de espera"
            body={watingCalls.length}
          />
        </Col>
        <Col xl="3">
          <CollectiveIndicatorCard
            title="T.M.E"
            body={collectiveIndicators.TME}
          />
        </Col>
        <Col xl="3">
          <CollectiveIndicatorCard
            title="Maior tempo de espera"
            body={collectiveIndicators.longestWaitTime}
          />
        </Col>
        <Col xl="3">
          <CollectiveIndicatorCard
            title="T.M.A"
            body={collectiveIndicators.TMA}
          />
        </Col>
      </Row>
      <Row>
        <Col xl="6">
          <ServiceLevelChart data={collectiveIndicators.serviceLevel} />
        </Col>
        <Col xl="6">
          <ReceivedCallsChart
            qtdAnswered={collectiveIndicators.qtdAnswered}
            qtdAbandoned={collectiveIndicators.qtdAbandoned}
            answeredPercentage={collectiveIndicators.answeredPercentage}
            abandonedPercentage={collectiveIndicators.abandonedPercentage}
          />
        </Col>
      </Row>
      <Row>
        <Col xl="12">
          <AgentsStatusChart
            title="Visão geral do sistema"
            queuesToMonit={queuesToMonit}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default CollectiveIndicators;
