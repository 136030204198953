import ServiceCRUD from '../../../../../services/ServiceAUTH';
import { apiAuth } from '../../../../../http';
class AuditAuthenticationService extends ServiceCRUD {
  constructor() {
    super('/authentication-report');
  }

  getDownloadsPdfAndCsv(exportType, reportType, filterParams) {
    return apiAuth.get(`/authentication-report/${reportType}/${exportType}`, {
      params: filterParams,
      responseType: 'blob'
    });
  }
}

export default new AuditAuthenticationService();
