import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import SpyService from '../service';
import ExtensionService from '../service/extensionService';
import { toast } from 'react-toastify';
// import { regex } from './regexs';
import FlexSelect from 'components/Inputs/FlexSelect';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';

const GroupSpyForm = (props) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { reset, control, handleSubmit, errors, setValue, formState } =
    useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [Extensions, setExtensions] = useState([]);

  const { id } = props.match.params;
  // Get group to extensions
  useEffect(() => {
    ExtensionService.getAllSimple()
      .then((response) => {
        setExtensions(response.data.data);
      })
      .catch(() => {
        console.log('Erro ao ler os ramais');
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      SpyService.get(id)
        .then((res) => {
          let getGroupSpy = res.data.data;

          let arrayExtensionId = [];
          getGroupSpy.extensions.forEach((extension) => {
            arrayExtensionId.push(extension.extension_number);
          });
          getGroupSpy = { ...getGroupSpy, extensionsid: arrayExtensionId };
          reset(getGroupSpy);
          setIsUpdate(true);
          setLoading(false);
        })
        .catch(() => {
          toast.error('Erro ao tentar carregar Grupo Espião', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  }, [id, reset]);

  const onSubmit = useCallback(
    async (data) => {
      try {
        setLoading(true);
        isUpdate
          ? await SpyService.update(id, data)
          : await SpyService.create(data);
        history.push('/admin/SpyGroup');

        if (isUpdate) {
          // setLoading(false);
          toast.info('Grupo espião foi atualizada com sucesso!');
        } else {
          //setLoading(false);
          toast.success('Grupo espião foi cadastrada com sucesso!');
        }
      } catch (err) {
        toast.error(err.response.data.message, {
          autoClose: 3000,
          closeOnClick: true
        });
        history.push('/admin/SpyGroup');
      }
    },
    [history, isUpdate, id]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/SpyGroup');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/spyGroup"
        buttonTitle="Voltar para a pagina de grupos de espião"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Grupos de Espião</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row>
                        <Col md={{ size: 6 }}>
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{
                              required: 'Nome é um campo obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Ramais*</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                dataOptions={Extensions}
                                isMulti={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="extensionsid"
                                labelName="extension_number"
                                valueName="extension_number"
                              />
                            )}
                            control={control}
                            name="extensionsid"
                            defaultValue=""
                            rules={{
                              validate: (value) => {
                                let valuesExtensionId = value.length;

                                if (valuesExtensionId === 0) {
                                  return 'Ramais é um campo obrigatório';
                                } else {
                                  return true;
                                }
                              }
                            }}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="extensionsid"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default GroupSpyForm;
