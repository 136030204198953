import React from 'react';
import { Modal, Button, ListGroup, ListGroupItem } from 'reactstrap';

export const PredefinedMessageModal = ({
  handle = () => {},
  setPredefinedMessage,
  predefinedMessages = [],
  toggleModal,
  open
}) => {
  return (
    <>
      <Modal
        className={`modal-dialog-centered`}
        isOpen={open}
        toggle={() => toggleModal()}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Mensagens Predefinidas
            {/* <br />
            <p>{subtitle}</p> */}
          </h6>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => toggleModal()}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body" style={{ padding: '0rem' }}>
          {predefinedMessages.length ? (
            <ListGroup>
              {predefinedMessages.map((msgCurrent, index) => {
                if (msgCurrent?.messageHtml)
                  return (
                    <ListGroupItem
                      key={index}
                      style={{ cursor: 'pointer' }}
                      action
                      onClick={() => {
                        setPredefinedMessage(msgCurrent?.messageHtml);
                        handle(msgCurrent.messageHtml);
                        toggleModal();
                      }}
                    >
                      {msgCurrent.title}
                      <br />
                    </ListGroupItem>
                  );
              })}
            </ListGroup>
          ) : (
            <div className="text-center">
              Nenhuma mensagem encontrada para o canal de email
            </div>
          )}

          <div className="modal-footer">
            <Button
              className="ml-auto"
              data-dismiss="modal"
              color="danger"
              type="button"
              onClick={() => toggleModal()}
            >
              Fechar
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
