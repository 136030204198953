import QueueHeader from '../../../AsideCards/QueueHeader';
import { QueueMemberCardSmall } from '../../QueueMemberCardSmall';
import {
  CardContainer,
  GridContainer,
  GridItem,
  SmallCardContainer
} from '../styles';
import React from 'react';
import QueueCardItem from './QueueCardItem';
const QueueList = ({
  nameQueuesToMonit,
  queueMembers,
  queueMonitoringDataByQueue,
  watingCalls
}) => {
  const styleNumber = {
    color: '#fff',
    fontSize: '1.8rem',
    margin: '0',
    padding: '0'
  };
  const styleTime = {
    color: '#fff',
    fontSize: '1.8rem',
    margin: '0',
    padding: '0'
  };

  return (
    <GridContainer>
      {nameQueuesToMonit.map((nome) => {
        const queueInfo = queueMonitoringDataByQueue ?? {};
        const queueData = queueInfo[nome] ?? null;

        const agentes = queueMembers.filter((agent) => {
          if (agent?.sessions?.some((session) => session.name === nome)) {
            return true;
          }
          return agent?.agent?.queue?.name === nome;
        });
        const calls = watingCalls ?? [];
        const filteredCalls = calls.filter((queue) => queue.queueName === nome);

        return (
          <GridItem key={nome}>
            <QueueHeader>{nome.replace(/%20/g, ' ')}</QueueHeader>
            <CardContainer>
              <QueueCardItem className="my-2" title="Fila de espera" key="0">
                <span style={styleNumber}>
                  {Array.isArray(filteredCalls) && filteredCalls.length}
                </span>
              </QueueCardItem>
              <QueueCardItem className="my-2" title="T.M.A" key="1">
                <span style={styleTime}>{queueData?.TMA || '00:00:00'}</span>
              </QueueCardItem>
              <QueueCardItem className="my-2" title="T.M.E" key="2">
                <span style={styleTime}>{queueData?.TME || '00:00:00'}</span>
              </QueueCardItem>
              <QueueCardItem
                className="my-2"
                title="Status de chamadas"
                key="7"
              >
                <div className="d-flex flex-row justify-content-center">
                  <div className="d-flex flex-column text-center mr-2">
                    <span
                      className="my-0 font-weight-normal"
                      style={{ fontSize: '1.25rem' }}
                    >
                      {queueData?.qtdAnswered || '0'}
                    </span>
                    <span className="my-0" style={{ fontSize: '0.8rem' }}>
                      Atendidas
                    </span>
                  </div>
                  <div className="d-flex flex-column text-center">
                    <span className="my-0" style={{ fontSize: '1.25rem' }}>
                      {queueData?.qtdAbandoned || '0'}
                    </span>
                    <span className="my-0" style={{ fontSize: '0.8rem' }}>
                      Abandonadas
                    </span>
                  </div>
                </div>
              </QueueCardItem>
            </CardContainer>

            <SmallCardContainer className="essaqui">
              {agentes.map((_agent, key) => (
                <QueueMemberCardSmall key={key} data={_agent} />
              ))}
            </SmallCardContainer>
          </GridItem>
        );
      })}
    </GridContainer>
  );
};

export default QueueList;
