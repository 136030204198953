import DefaultModal from '../../../../../components/Modal/DefaultModal';
import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col, CardTitle } from 'reactstrap';
import ConversationDetails from 'components/Conversation/ConversationDetails';
import ConversaService from '../../../../Conversation/service';
import { toast } from 'react-toastify';
import ReportService from '../../../../Report/Callcenter/ReceivedCall/service';

import {
  RelationsWrapper,
  ConversationContainer,
  CallContainer
} from './styled';
import { AudioStreamReportTelephony as AudioStream } from '../../../../../components/AudioController/AudioStream';

export function handleFormatConversationData(hora_criacao) {
  const data = new Date(hora_criacao);
  return (
    <p>
      {`${data.toLocaleDateString('pt-br')} - ${data.toLocaleTimeString(
        'pt-br'
      )}`}
    </p>
  );
}

export function handleCpfCnpj(cpf_cnpj) {
  if (cpf_cnpj) {
    const resumos = cpf_cnpj;
    const KEYCPF = 'CPF';
    const KEYCPFANDCNPF = 'CPF/CNPJ';
    let cpfCnpj = '';

    for (const resumo of resumos) {
      if (resumo.id === KEYCPF || resumo.id === KEYCPFANDCNPF) {
        cpfCnpj = resumo.value;
      }
    }

    return <p>{cpfCnpj}</p>;
  } else {
    return '';
  }
}

export function handleTimeCall(info) {
  if (info?.disposition === 'ANSWERED') {
    const timeAnswer = new Date(info?.calldate_answer);
    const timeEnd = new Date(info?.calldate_end);
    const timeDiff = timeEnd.getTime() - timeAnswer.getTime();
    const timeDiffFormat = new Date(timeDiff).toLocaleTimeString('pt-br', {
      timeZone: 'UTC'
    });
    if (timeDiffFormat !== 'Invalid Date') {
      return <p>{timeDiffFormat}</p>;
    } else {
      return <p>Não Informado</p>;
    }
  }
}

export function handleTimeWaitingCall(info) {
  if (info?.disposition === 'ANSWERED') {
    const timeStart = new Date(info?.calldate_start);
    const timeAnswer = new Date(info?.calldate_answer);
    const timeDiff = timeAnswer.getTime() - timeStart.getTime();
    const timeDiffFormat = new Date(timeDiff).toLocaleTimeString('pt-br', {
      timeZone: 'UTC'
    });
    if (timeDiffFormat !== 'Invalid Date') {
      return <p>{timeDiffFormat}</p>;
    } else {
      return <p>Não Informado</p>;
    }
  } else {
    const timeStart = new Date(info?.calldate_start);
    const timeEnd = new Date(info?.calldate_end);
    const timeDiff = timeEnd.getTime() - timeStart.getTime();
    const timeDiffFormat = new Date(timeDiff).toLocaleTimeString('pt-br', {
      timeZone: 'UTC'
    });
    if (timeDiffFormat !== 'Invalid Date') {
      return <p>{timeDiffFormat}</p>;
    } else {
      return <p>Não Informado</p>;
    }
  }
}

const ModalRelations = ({
  isModalOpen,
  onModalToggle,
  protocolRelation,
  setLoading,
  ...props
}) => {
  const [conversation, setConversation] = useState(null);

  useEffect(() => {
    if (protocolRelation) {
      if (protocolRelation?.conversa !== null) {
        getConversationById(protocolRelation?.conversa?.id);
      }
    }
  }, [getConversationById, protocolRelation, isModalOpen]);

  const getConversationById = useCallback(
    (conversationId) => {
      if (conversationId && isModalOpen) {
        ConversaService.getClosed(conversationId)
          .then((res) => {
            setConversation(res.data.conversa);
            setLoading(false);
          })
          .catch(() => {
            toast.error('Erro ao carregar a conversa', {
              autoClose: 3000,
              closeOnClick: true
            });
          });
      }
    },
    [isModalOpen, setLoading]
  );

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title={
        protocolRelation?.conversa === null &&
        protocolRelation?.cdr_report === null
          ? 'Relacionamento'
          : protocolRelation?.conversa
          ? 'Conversa Relacionada'
          : 'Chamada Relacionada'
      }
      showCloseButton={false}
      modalContainerClasses="modal-md"
      {...props}
    >
      <>
        {protocolRelation?.conversa === null &&
        protocolRelation?.cdr_report === null ? (
          <p>O Protocolo Não possui Relações</p>
        ) : (
          <RelationsWrapper>
            <CardTitle tag="h4">
              {conversation !== null ? (
                <>Cliente: {conversation?.cliente?.nome || 'Não informado'}</>
              ) : (
                <>
                  Atendente:{' '}
                  {protocolRelation?.cdr_report?.usr?.name || 'Não informado'}
                </>
              )}
            </CardTitle>
            {conversation !== null ? (
              <ConversationContainer>
                <Row>
                  <Col md="6" title="Usuário">
                    <span>Atendente: </span>
                    <p>{conversation?.atendente?.name || 'Não informado'}</p>
                  </Col>
                  <Col md="6" title="Cliente">
                    <span>Cliente: </span>
                    <p>{conversation?.cliente?.nome || 'Não informado'}</p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Telefone do cliente">
                    <span>Telefone: </span>
                    <p>{conversation?.cliente?.telefone || 'Não informado'}</p>
                  </Col>
                  <Col md="6" title="CPF/CNPJ">
                    <span>CPF/CNPJ: </span>
                    <br />
                    {handleCpfCnpj(conversation?.resumoBot)}
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Fila">
                    <span>Fila: </span>
                    <p>{conversation?.fila || 'Não informado'}</p>
                  </Col>
                  <Col md="6" title="Início da Conversa">
                    <span>Início da Conversa: </span>
                    {handleFormatConversationData(conversation?.hora_criacao)}
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Canal">
                    <span>Canal: </span>
                    <p>{conversation?.canal || 'Não informado'}</p>
                  </Col>
                  <Col md="6" title="Detalhes da Conversa">
                    <span>Detalhes da Conversa: </span>
                    <br />
                    <ConversationDetails conversationData={conversation} />
                  </Col>
                </Row>
              </ConversationContainer>
            ) : (
              <CallContainer>
                <Row>
                  <Col md="6" title="Usuário">
                    <span>Atendente: </span>
                    <p>
                      {protocolRelation?.cdr_report?.usr?.name ||
                        'Não informado'}
                    </p>
                  </Col>
                  <Col md="6" title="Fila">
                    <span>Fila: </span>
                    <p>
                      {protocolRelation?.cdr_report?.queue?.name ||
                        'Não informado'}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Tempo em ligação">
                    <span>Tempo em ligação: </span>
                    {handleTimeCall(protocolRelation?.cdr_report)}
                  </Col>
                  <Col md="6" title="Tempo em espera">
                    <span>Tempo em espera: </span>
                    <br />
                    {handleTimeWaitingCall(protocolRelation?.cdr_report)}
                  </Col>
                </Row>

                <Row>
                  <Col md="6" title="Data de Início">
                    <span>Data de Início: </span>
                    {handleFormatConversationData(
                      protocolRelation?.cdr_report?.calldate_start
                    )}
                  </Col>
                  <Col md="6" title="Origem">
                    <span>Origem: </span>
                    <p>
                      {protocolRelation?.cdr_report?.clid_num ||
                        'Não informado'}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md="6" title="Destino">
                    <span>Destino: </span>
                    <p>
                      {protocolRelation?.cdr_report?.dst || 'Não informado'}
                    </p>
                  </Col>
                  <Col md="6" title="Audio">
                    <span>Audio: </span>
                    <br />
                    {protocolRelation?.cdr_report !== null ? (
                      <AudioStream
                        ReportService={ReportService}
                        uniqueid={protocolRelation?.cdr_report?.uniqueid}
                        disabled={
                          protocolRelation?.cdr_report?.disposition ===
                          'ANSWERED'
                            ? false
                            : true
                        }
                        reasonDisabled={
                          protocolRelation?.cdr_report?.disposition ===
                          'ANSWERED'
                            ? ''
                            : 'Não atendida'
                        }
                      />
                    ) : (
                      <p>Não possui audio</p>
                    )}
                  </Col>
                </Row>
              </CallContainer>
            )}
          </RelationsWrapper>
        )}
      </>
    </DefaultModal>
  );
};

export default ModalRelations;
