import React from 'react';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';
import { TotalRegister } from '../../../../../components/TotalRegister';

const DetailedSynthetic = ({ dataReport, totalRegister }) => {
  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            <Card>
            {totalRegister >= 0 && (
              <TotalRegister totalRegister={totalRegister} />
              )}
              <ToolkitProvider
                data={dataReport.listCallbackDetailedSyntheticDTO || []}
                keyField="uuid"
                columns={[
                  {
                    text: 'Nome do Callback',
                    dataField: 'callback',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Quantidade',
                    dataField: 'amount',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Retorno com Sucesso',
                    dataField: 'returnSuccess',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Retorno Sem Sucesso',
                    dataField: 'returnWithoutSuccess',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Quantidade de Atendidas ',
                    dataField: 'numberAnswered',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Média de Tentativas',
                    dataField: 'avgAttempts',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Tempo Médio Retorno',
                    dataField: 'avgToReturnFormatted',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Tempo Mínimo Retorno',
                    dataField: 'minReturnFormatted',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Tempo Máximo Retorno',
                    dataField: 'maxReturnFormatted',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Tempo Médio Duração',
                    dataField: 'avgBillsecFormatted',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Tempo Mínimo Duração',
                    dataField: 'minBillsecFormatted',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    text: 'Tempo Máximo Duração ',
                    dataField: 'maxBillsecFormatted',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default DetailedSynthetic;
