import React from 'react';

const Fail = ({ fontColor }) => {
  return (
    <div className="text-center mb-8 mt-6">
      <i
        style={{ fontSize: '80px', color: fontColor }} //99b543
        className="fas fa-exclamation-circle mb-4"
      ></i>
      <h3 style={{ paddingBottom: '30px', color: fontColor }}>
        Um erro aconteceu
      </h3>
      <h3 style={{ paddingBottom: '30px', color: fontColor }}>
        Por favor, verifique o link e tente novamente mais tarde!
      </h3>
    </div>
  );
};

export default Fail;
