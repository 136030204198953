import ServiceCRUD from '../../../services/ServiceCRUDFLEXCHAT';
import { apiChat, apiCase } from '../../../http';

class ChatBroadcastListSMSService extends ServiceCRUD {
  constructor() {
    super('/broadcast-list-sms');
  }

  getContactsFromBroadcastList(broadcastListId, queryParams) {
    return apiChat.get(`/broadcast-list-sms/${broadcastListId}/contacts`, {
      params: queryParams
    });
  }

  updateBroadcastWithMidia(data, id) {
    return apiChat.put(`broadcast-list-sms/midia/${id}`, data, {
      headers: { 'content-type': 'multipart/form-data' }
    });
  }

  createBroadcastWithMidia(data) {
    return apiChat.post('broadcast-list-sms/midia', data, {
      headers: { 'content-type': 'multipart/form-data' }
    });
  }

  getContacts(queryParams) {
    return apiChat.get(`/broadcast-list-sms/contacts`, {
      params: queryParams
    });
  }

  addContactsToBroadcastList(broadcastListId, clients) {
    return apiChat.post('/broadcast-list-sms/contacts', {
      broadcastListId,
      clients
    });
  }

  getAllWithoutPagination() {
    return apiChat.get('/broadcast-list-sms/all');
  }

  removeContactsFromBroadcastList(broadcastListId, clientIds = []) {
    return apiChat.put('/broadcast-list-sms/contacts', {
      broadcastListId,
      clientIds
    });
  }

  executeBroadcastList(data) {
    return apiChat.post('/broadcast-list-sms/execute', data);
  }

  resumeBroadcastList(data) {
    return apiChat.post(`/broadcast-list-sms/resume/${data.broadcastListId}`);
  }

  pauseBroadcastList(data) {
    return apiChat.post(`/broadcast-list-sms/pause/${data.broadcastListId}`);
  }

  syncBroadcastList(id) {
    return apiChat.get(`/broadcast-list-sms/synchronize/${id}`);
  }

  importList(entityName, data, broadcastListId) {
    return apiCase.post(
      `/entity/${entityName}/import/broadcast-list-sms/${broadcastListId}`,
      data
    );
  }

  getAllSearch(page, size, title) {
    return apiChat.get(`${this.path}`, {
      headers: {
        limit: size,
        offset: size * (page - 1)
      },
      params: {
        title: title
      }
    });
  }
}

export default new ChatBroadcastListSMSService();
