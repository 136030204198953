import React from 'react';
import { Controller } from 'react-hook-form';
import { Form, Row, Col, Input } from 'reactstrap';
import { ErrorMessage } from '@hookform/error-message';
import { LabelInput } from '../atonsComponents/LabelInput';
import FlexSelect from 'components/Inputs/FlexSelect';
import { errorFormMessage } from '../atonsComponents/ErrorFormMessage';
import { ButtonClose } from '../atonsComponents/ButtonClose';
import { ButtonAction } from '../atonsComponents/ButtonAction';

export const FormDefaultProtocol = ({
  protocolEndOfConversation,
  handleGetSubReasons,
  reasons,
  displayDefaultClosure,
  useFormProtocol,
  onSubmit,
  closingFields,
  subReasons,
  products,
  issues,
  displayEndButton,
  protolEndOfConversation,
  idProtocol,
  setShowSaveProtocol,
  setActiveFormCreatedProtocol,
  toggleModal,
  sectors,
  status
}) => {
  const { handleSubmit, setValue, control, errors } = useFormProtocol;
  return (
    <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
      {displayDefaultClosure && (
        <Row className="mb-3">
          <Col md="6">
            <LabelInput value="Status" />
            <Controller
              render={(props) => (
                <FlexSelect
                  dataOptions={status}
                  isMulti={false}
                  isClearable={false}
                  value={props.value}
                  valueController={setValue}
                  closeMenuOnSelect={true}
                  isSearchable={false}
                  fieldName="status_id"
                  labelName="name"
                  valueName="id"
                />
              )}
              control={control}
              name="status_id"
              defaultValue=""
              disabled={!protocolEndOfConversation}
            />
            <ErrorMessage
              errors={errors}
              name="status_id"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          <Col md="6">
            <LabelInput value="Motivo*" />
            <Controller
              as={
                <Input
                  type="select"
                  name="select"
                  onChangeCapture={handleGetSubReasons}
                >
                  <option value="">Selecione um motivo</option>
                  {reasons.map(({ name, id }, index) => (
                    <option key={`selectreasons+${name}+${index}`} value={id}>
                      {name}
                    </option>
                  ))}
                </Input>
              }
              control={control}
              name="reason_id"
              rules={{ required: 'Campo obrigatório' }}
              defaultValue=""
            />
            <ErrorMessage
              errors={errors}
              name="reason_id"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        </Row>
      )}
      {closingFields && displayDefaultClosure && (
        <Row>
          <Col
            md={closingFields.setor ? { size: 6 } : { size: 12 }}
            className="mb-3"
          >
            <LabelInput value="Submotivo*" />
            <Controller
              as={
                <Input type="select" name="select">
                  <option value="">Selecione um sub motivo</option>
                  {subReasons.map(({ name, id }, index) => (
                    <option
                      key={`selectsubreasons+${name}+${index}`}
                      value={id}
                    >
                      {name}
                    </option>
                  ))}
                </Input>
              }
              control={control}
              name="subreason_id"
              rules={{ required: 'Campo obrigatório' }}
              defaultValue={''}
            />
            <ErrorMessage
              errors={errors}
              name="subreason_id"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          {closingFields.setor && (
            <Col md="6">
              <LabelInput value="Setor" />
              <Controller
                render={(props) => (
                  <FlexSelect
                    dataOptions={sectors}
                    isMulti={false}
                    isClearable={false}
                    value={props.value}
                    valueController={setValue}
                    closeMenuOnSelect={true}
                    isSearchable={false}
                    fieldName="setor"
                    labelName="name"
                    valueName="name"
                  />
                )}
                control={control}
                name="setor"
                defaultValue=""
              />
            </Col>
          )}
        </Row>
      )}
      {closingFields && displayDefaultClosure && (
        <>
          <Row className="mb-3">
            {closingFields.produto && (
              <Col md="6">
                <LabelInput value="Produto" />
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={products}
                      isMulti={false}
                      isClearable={false}
                      value={props.value}
                      valueController={setValue}
                      closeMenuOnSelect={true}
                      isSearchable={false}
                      fieldName="produto"
                      labelName="nome"
                      valueName="nome"
                    />
                  )}
                  control={control}
                  name="produto"
                  defaultValue=""
                />
              </Col>
            )}
            {closingFields.assunto && displayDefaultClosure && (
              <Col md="6">
                <LabelInput value="Assunto" />
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={issues}
                      isMulti={false}
                      isClearable={false}
                      value={props.value}
                      valueController={setValue}
                      closeMenuOnSelect={true}
                      isSearchable={false}
                      fieldName="assunto"
                      labelName="nome"
                      valueName="nome"
                    />
                  )}
                  control={control}
                  name="assunto"
                  defaultValue=""
                />
              </Col>
            )}
          </Row>

          {closingFields.observacao && displayDefaultClosure && (
            <Row className="mb-5">
              <Col md="12">
                <LabelInput value="Observação" />
                <Controller
                  as={
                    <Input
                      type="textarea"
                      rows="3"
                      name="observacao"
                      id="observacao"
                    />
                  }
                  control={control}
                  name="observacao"
                  defaultValue=""
                />
              </Col>
            </Row>
          )}
        </>
      )}
      {displayEndButton && !protolEndOfConversation && !idProtocol && (
        <>
          <ButtonClose text="Fechar" onClick={toggleModal} />
          <ButtonAction text="Encerrar" />
        </>
      )}
      {displayEndButton && protolEndOfConversation && !idProtocol && (
        <>
          <ButtonClose text="Fechar" onClick={toggleModal} />
          <ButtonAction
            type="button"
            text="Criar e Encerrar"
            onClick={() => setActiveFormCreatedProtocol(true)}
          />
        </>
      )}

      {displayEndButton && idProtocol && (
        <>
          <ButtonClose text="Fechar" onClick={toggleModal} />
          <ButtonAction
            text="Salvar e Encerrar"
            onClick={() => setShowSaveProtocol(true)}
          />
        </>
      )}
    </Form>
  );
};
