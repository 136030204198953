import { apiNpsMidia } from 'http/index';
import ServiceCRUD from '../../../../../services/ServiceNPS';

class ConfigSurveyService extends ServiceCRUD {
  constructor() {
    super('reports/survey/synthetic');
  }

  getDownloadsPdfAndCsv = (data, media) => {
    return apiNpsMidia.post(`${this.path}/${media}`, data, {
      responseType: 'blob'
    });
  };
}

export default new ConfigSurveyService();
