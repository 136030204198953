import { useState, useCallback } from 'react';
import ProtocolFieldsService from '../../service';
import { toast } from 'react-toastify';

function useHandleProtocolListing(recordTypeId) {
  const [protocols, setProtocols] = useState(null);
  const [protocolsQuantity, setProtocolQuantity] = useState(0);
  const [isLoadingProtocols, setIsLoadingProtocols] = useState(false);

  const getProtocols = useCallback(
    async (params = {}, showToastMessage = true) => {
      setIsLoadingProtocols(true);

      ProtocolFieldsService.getProtocolsByRecordType(recordTypeId, params)
        .then((response) => {
          const protocolsData = response.data.content;
          const quantity = response.data.quantity;
          setProtocols(protocolsData);
          setProtocolQuantity(quantity);
          if (quantity === 0 && showToastMessage) {
            toast.error('Nenhum registro foi encontrado', {
              autoClose: 3000,
              closeOnClick: true
            });
          }
        })
        .catch(() => {
          setProtocols(null);
        })
        .finally(() => {
          setIsLoadingProtocols(false);
        });
    },
    [recordTypeId]
  );

  return {
    protocols,
    protocolsQuantity,
    isLoadingProtocols,
    getProtocols
  };
}

export { useHandleProtocolListing };
