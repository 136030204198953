import React, { useCallback, useEffect, useState } from 'react';
import OpportunityService from '../../../services/opportunityService';
import { useParams, useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import DatePicker from 'components/Inputs/DatePicker.js';
import {
  Row,
  Form,
  Card,
  CardBody,
  CardHeader,
  Col,
  Input,
  Container,
  Button,
  ButtonGroup
} from 'reactstrap';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import OpportunityStageService from '../../../../OpportunityStages/service';
import Loader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import CustomFieldsInputsForm from '../../../../shared/components/CustomFieldsInputsForm';
import Select from 'react-select/async';
import { custom_select } from '../../../../../../assets/styles/multiple_select';
import { useSearchAccounts } from '../../../hooks/useSearchAccounts';

import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { toBrazilianFullDate } from 'views/CRM/shared/utils/date';
import { maskPhoneNumber } from 'views/CRM/shared/utils/phoneNumberHelpers';
import ChangeResponsibleModal from '../ChangeResponsibleModal';

import './styles.css';
import { FiEdit, FiPlus } from 'react-icons/fi';
import ListComments from '../Comments/CommentsList';
import CommentModal from '../Comments/CommentsForm';
import ModalComment from '../Comments/ModalUpdateComment';
import ConversionModal from '../ConversionOpportunityModal';
import { formatCustomFieldsToDisplay } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { OpportunityAttachments } from '../Attachments/';
import CurrencyMask from 'components/Inputs/CurrencyMask';
import ContactAttemptTimelineModal from 'views/CRM/shared/ContactAttempt/components/ContactTimeLineModal';
import { getContactsAttempts } from 'views/CRM/shared/ContactAttempt/utils';
import OpportunityTimeModal from '../OpportunityTime';
import { toBrazilianDate } from 'views/CRM/shared/utils/date';

const OpportunityEdit = () => {
  const { control, reset, setValue, handleSubmit, errors, watch } = useForm();
  const { recordTypeId, id } = useParams();
  const history = useHistory();
  const [opportunity, setOpportunity] = useState();
  const [activeStage, setActiveStage] = useState();
  const [opportunityStages, setOpportunityStages] = useState();
  const [changedStage, setChangedStage] = useState(false);
  const { isSearching, filterAccounts } = useSearchAccounts();
  const [account, setAccount] = useState();
  const [isChangeReponsibleModalOpen, setIsChangeReponsibleModalOpen] =
    useState(false);
  const [listComments, setListComments] = useState([]);
  const [openConversionOpportunityModal, setOpenConversionOpportunityModal] =
    useState(false);
  const [openModalCreateComment, setOpenModalCreateComment] = useState(false);
  const [openModalOpportunityTime, setOpenModalOpportunityTime] =
    useState(false);
  const [dataCommentUpdate, setDataCommentUpdate] = useState([]);
  const [openModalUpdateComment, setOpenModalUpdateComment] = useState(false);
  const [chatCreatedOnIsolatedMessageId, setChatCreatedOnIsolatedMessageId] =
    useState(null);

  const [callUniqueId, setCallUniqueId] = useState(null);
  const [
    isContactAttemptTimelineModalOpen,
    setIsContactAttemptTimelineModalOpen
  ] = useState(false);

  const { getCustomFields, customFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType(entitiesName.OPPORTUNITY, recordTypeId);
  useEffect(() => {
    if (chatCreatedOnIsolatedMessageId) {
      getContactsAttempts(
        chatCreatedOnIsolatedMessageId,
        'CHAT',
        'OPPORTUNITY',
        id
      );
    }

    if (callUniqueId) {
      getContactsAttempts(callUniqueId, 'TELEFONIA', 'OPPORTUNITY', id);
      return;
    }
  }, [chatCreatedOnIsolatedMessageId, id]);

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    if (id && !isLoadingCustomFields) {
      getOpportunityById();
    }
  }, [id, getOpportunityById, isLoadingCustomFields]);

  const getCommnents = useCallback(() => {
    if (id) {
      OpportunityService.getOpportunityComments(id)
        .then((res) => {
          setListComments(res.data.data);
        })
        .catch((err) => {
          toast.error(
            'Houve um erro ao buscar os comentários dessa oportunidade',
            {
              closeOnClick: true,
              autoClose: 3000
            }
          );
          console.log(err);
        });
    }
  }, [id]);

  useEffect(getCommnents, [getCommnents]);

  const updateStage = () => {
    const data = {
      stageId: activeStage
    };
    OpportunityService.updateStage(opportunity.id, data)
      .then(() => {
        setChangedStage(true);
        toast.success('Fase atualizada com sucesso.', {
          closeOnClick: true,
          autoClose: 3000
        });
      })
      .catch(() =>
        toast.error('Erro ao atualizar a fase.', {
          autoClose: 3000,
          closeOnClick: true
        })
      );
  };

  const getOpportunityById = useCallback(() => {
    OpportunityService.getById(recordTypeId, id)
      .then(({ data }) => {
        setAccount({
          label: data.data?.accountName,
          value: data.data?.accountId
        });

        const reqData = {
          ...data.data,
          phone: maskPhoneNumber(data.data.phone),
          expectedClosure: data.data.expectedClosure
            ? toBrazilianDate(data.data.expectedClosure)
            : null
        };

        const formattedData = formatCustomFieldsToDisplay(
          reqData,
          customFields
        );
        if (
          formattedData.amount !== undefined &&
          formattedData.amount !== null
        ) {
          formattedData.amount = parseFloat(formattedData.amount);
          formattedData.amount = formattedData.amount.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          });
        }
        setOpportunity(formattedData);
        reset(formattedData);
        setActiveStage(formattedData.stageId);
      })
      .catch(() => {
        toast.error('Erro ao buscar dados da oportunidade');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, recordTypeId, customFields]);

  const changeStage = (stage) => {
    setActiveStage(stage);
  };

  const changeResponsible = async () => {
    setIsChangeReponsibleModalOpen(true);
  };

  useEffect(() => {
    OpportunityStageService.listOrdenade(recordTypeId)
      .then((res) => {
        setOpportunityStages(res.data.data);
      })
      .catch(() => {
        toast.error('Erro ao carregar as fases.', {
          closeOnClick: true,
          autoClose: 3000
        });
      });
  }, [recordTypeId]);

  if (isLoadingCustomFields) {
    return (
      <Row className="justify-content-md-center">
        <Loader />
      </Row>
    );
  }

  const onSubmit = async (data) => {
    if (data.amount) {
      data.amount = data.amount
        .replace('R$', '')
        .replace(/\./g, '')
        .replace(',', '.')
        .trim();
    }

    data.accountId = account && account.value ? Number(account.value) : null;

    const dataDTO = formatCustomFieldsToSubmit(data, customFields);

    OpportunityService.updateOpportunity(recordTypeId, id, dataDTO)
      .then(() => {
        toast.success('Oportunidade atualizada com sucesso.', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .catch(() => {
        toast.error('Não foi possível atualizar a Oportunidade.', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const cancelAction = () => {
    history.push('/admin/crm/opportunity', { recordTypeId });
  };

  const handleUpdateComment = (dataComment) => {
    setOpenModalUpdateComment(true);
    setDataCommentUpdate(dataComment);
  };

  const handleOpenOpportunityTime = () => {
    setOpenModalOpportunityTime(true);
  };

  const handleCreateComment = () => {
    setOpenModalCreateComment(true);
  };

  const openContactAttemptTimeline = async () => {
    setIsContactAttemptTimelineModalOpen(true);
  };

  const reloadComments = () => {
    OpportunityService.getOpportunityComments(id).then((res) => {
      setListComments(res.data.data);
    });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <SimpleHeader
        name="Opportunity edition"
        parentName="Opportunity"
        returnPath="/admin/crm/opportunity"
        navigatorState={{ recordTypeId }}
      />
      <Container className="configuration-container mt--6" fluid>
        <Card>
          <CardHeader
            style={{
              display: 'flex'
            }}
          >
            {opportunityStages &&
              opportunityStages.map((stage) => (
                <ButtonGroup key={stage.id} style={{ flexGrow: 1 }}>
                  <Button
                    color={
                      activeStage === stage.id
                        ? 'primary'
                        : activeStage > stage.id
                        ? 'success'
                        : 'dark'
                    }
                    outline
                    active={activeStage >= stage.id}
                    size="md"
                    type="button"
                    title="Fases da Oportunidade"
                    onClick={() => {
                      changeStage(stage.id);
                    }}
                  >
                    {stage.name}
                    <li className="fas fa-arrow-right ml-2"></li>
                  </Button>
                </ButtonGroup>
              ))}
            <Button
              color="primary"
              size="sm"
              onClick={() => {
                const filtro = opportunityStages.filter(
                  (stage) =>
                    stage.id === activeStage && stage.type === 'CLOSED_WON'
                );
                if (filtro.length > 0) {
                  setOpenConversionOpportunityModal(true);
                } else {
                  updateStage();
                }
              }}
              style={{ marginLeft: '3px' }}
            >
              <li className="fas fa-check"></li>
              Atualizar fase
            </Button>
          </CardHeader>
          <CardBody>
            {opportunity && (
              <Row className="mb-3">
                <Col sm="2">
                  <h3 className="font-weight-bold small mb-0">Responsável:</h3>
                  <span className="d-flex align-items-center">
                    <span
                      id="responsible-edit-label"
                      onClick={changeResponsible}
                    >
                      {opportunity?.responsibleName}{' '}
                    </span>
                    <FiEdit
                      onClick={changeResponsible}
                      className="ml-2"
                      cursor="pointer"
                      textDecoration="underline"
                    />
                  </span>
                </Col>
                <Col sm="3">
                  <h3 className="font-weight-bold small mb-0">Criado em:</h3>
                  <span>{toBrazilianFullDate(opportunity?.createdAt)}</span>
                </Col>
                <Col sm="3">
                  <h3 className="font-weight-bold small mb-0">
                    Última modificação por:
                  </h3>
                  <span>{opportunity?.lastUpdatedByName}</span>
                </Col>
                <Col sm="2">
                  <h3 className="font-weight-bold small mb-0">
                    Última modificação em:
                  </h3>
                  <span>{toBrazilianFullDate(opportunity?.lastUpdatedAt)}</span>
                </Col>
                <Col
                  sm="2"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}
                >
                  <h3 className="font-weight-bold small mb-0">
                    Prazos da Oportunidade:
                  </h3>
                  <Button
                    color="info"
                    size="sm"
                    onClick={() => handleOpenOpportunityTime()}
                  >
                    <li className="fas fa-clock"></li>
                  </Button>
                </Col>
              </Row>
            )}
            <hr />
            <Form
              className="needs-validation"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row className="mb-3">
                <Col sm="3">
                  <label className="form-control-label text-capitalize">
                    Título*
                  </label>
                  <Controller
                    render={(props) => (
                      <Input
                        value={props.value}
                        onChange={props.onChange}
                        type="text"
                        placeholder="Título"
                      />
                    )}
                    control={control}
                    defaultValue=""
                    name="title"
                    rules={{
                      required: 'Campo obrigatório'
                    }}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="title"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
                <Col sm="3">
                  <label className="form-control-label text-capitalize">
                    Conta
                  </label>
                  <Select
                    styles={custom_select}
                    placeholder="Busca rápida"
                    isClearable={true}
                    name="accountId"
                    isLoading={isSearching}
                    loadingMessage={() => 'Buscando...'}
                    noOptionsMessage={({ inputValue }) =>
                      !inputValue
                        ? 'Digite para pesquisar'
                        : 'Nenhum resultado encontrado'
                    }
                    loadOptions={async (inputValue) => {
                      const res = await filterAccounts(inputValue);
                      return (
                        res &&
                        res.map((account) => ({
                          label: account.name,
                          value: account.id
                        }))
                      );
                    }}
                    onChange={(data) => {
                      if (data) {
                        const { value, label } = data;
                        setAccount({
                          label,
                          value
                        });
                      } else {
                        setAccount(null);
                      }
                    }}
                    value={
                      account
                        ? {
                            label: account.label,
                            value: account.value
                          }
                        : ''
                    }
                    defaultValue={
                      account
                        ? {
                            label: account.label,
                            value: account.value
                          }
                        : ''
                    }
                  />
                </Col>

                <Col sm="3">
                  <label className="form-control-label">Valor</label>
                  <Controller
                    render={(props) => (
                      <CurrencyMask
                        valueController={setValue}
                        value={props.value}
                        fieldName="amount"
                        placeholder="Valor"
                      />
                    )}
                    control={control}
                    defaultValue={0}
                    rules={{ required: 'Campo obrigatório' }}
                    name="amount"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="amount"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
                <Col sm="3">
                  <label className="form-control-label">
                    Previsão de encerramento
                  </label>
                  <Controller
                    render={(props) => (
                      <div>
                        <DatePicker
                          fieldName="expectedClosure"
                          valueController={setValue}
                          defaultValue={props.value}
                        />
                      </div>
                    )}
                    control={control}
                    defaultValue=""
                    name="expectedClosure"
                  />
                  <ErrorMessage
                    errors={errors}
                    name="expectedClosure"
                    render={({ message }) => errorFormMessage(message)}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <CustomFieldsInputsForm
                  fields={customFields}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                  watch={watch}
                  Controller={Controller}
                  setChatCreatedOnIsolatedMessageId={
                    setChatCreatedOnIsolatedMessageId
                  }
                  setCallUniqueId={setCallUniqueId}
                  isTryingToCreateAContactAttempt={true}
                  isOnEditPage={true}
                  isUpdate={true}
                  options={{
                    showCallIconOnPhoneField: true,
                    showMessageIconOnPhoneField: true
                  }}
                  contactMessageClientName={opportunity?.title}
                />
              </Row>
              {listComments && (
                <ListComments
                  listComments={listComments}
                  reloadComments={reloadComments}
                  handleUpdateComment={handleUpdateComment}
                />
              )}
              <Row style={{ paddingTop: '20px' }}>
                <Col className="text-right">
                  <Button
                    title="Adicionar novo comentário"
                    color="success"
                    type="button"
                    onClick={() => handleCreateComment()}
                  >
                    <FiPlus size={16} /> Adiciona Comentário
                  </Button>
                </Col>
              </Row>
              <Row style={{ paddingTop: '20px', paddingBottom: '40px' }}>
                <Col>
                  <h3 className="form-control-label mt-2">Anexo(s)</h3>
                  <OpportunityAttachments
                    opportunityId={opportunity?.id}
                    attachments={opportunity?.attachments}
                    onAdded={(addedAttachments) => {
                      setOpportunity((prev) => ({
                        ...prev,
                        attachments: [...prev.attachments, ...addedAttachments]
                      }));
                    }}
                    onDeleted={(attachmentId) => {
                      const remainAttachments = opportunity.attachments.filter(
                        (att) => att.id !== attachmentId
                      );
                      setOpportunity((prev) => ({
                        ...prev,
                        attachments: remainAttachments
                      }));
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col className="text-left">
                  <ReactButton btnColor="confirmation" type="submit">
                    Salvar
                  </ReactButton>

                  <ReactButton
                    btnColor="cancelation"
                    onClick={() => {
                      return cancelAction();
                    }}
                  >
                    Cancelar
                  </ReactButton>
                </Col>
                <Col className="text-right">
                  <Button
                    color="primary"
                    type="button"
                    onClick={() => openContactAttemptTimeline()}
                  >
                    <i className="fas fa-history" /> Histórico de Tentativas de
                    Contato
                  </Button>
                </Col>
              </Row>
            </Form>
          </CardBody>
        </Card>
      </Container>

      <ChangeResponsibleModal
        isModalOpen={isChangeReponsibleModalOpen}
        onModalToggle={setIsChangeReponsibleModalOpen}
        opportunityId={opportunity?.id}
        callback={getOpportunityById}
      />
      <CommentModal
        render={getCommnents}
        opportunityId={id}
        isModalOpen={openModalCreateComment}
        onModalToggle={setOpenModalCreateComment}
        unmountOnClose={true}
      />

      <ConversionModal
        opportunityId={id}
        isModalOpen={openConversionOpportunityModal}
        onModalToggle={setOpenConversionOpportunityModal}
        unmountOnClose={true}
        activeStage={activeStage}
        opportunity={opportunity}
        recordTypeId={recordTypeId}
      />

      <ModalComment
        render={getCommnents}
        dataComment={dataCommentUpdate}
        isModalOpen={openModalUpdateComment}
        onModalToggle={setOpenModalUpdateComment}
        unmountOnClose={true}
      />
      {id && (
        <ContactAttemptTimelineModal
          entityId={id}
          entityType="OPPORTUNITY"
          isModalOpen={isContactAttemptTimelineModalOpen}
          onModalToggle={setIsContactAttemptTimelineModalOpen}
        />
      )}
      <OpportunityTimeModal
        opportunityId={id}
        isModalOpen={openModalOpportunityTime}
        onModalToggle={setOpenModalOpportunityTime}
        unmountOnClose={true}
        changedStage={changedStage}
        setChangedStage={setChangedStage}
      />
    </>
  );
};

export default OpportunityEdit;
