import styled from 'styled-components';

export const Content = styled.div`
  background: #fff;
  border-radius: 5px 5px 0 0;
  height: 100%;

  .DropdownMenu {
    height: auto;
    max-height: 400px;
    width: 280px;
    overflow-y: scroll;
  }

  @media screen and (min-width: 767px) {
    .DropdownMenu {
      margin-right: 250px;
    }
  }

  .MainLink {
    white-space: nowrap;
    border: transparent;
  }

  .nav-link {
    cursor: pointer;
    border: transparent;
  }

  .nav-link.active {
    ${(props) =>
      props.primaryColor && `border-bottom: 2px solid ${props.primaryColor};`}
  }

  .nav-link:hover {
    ${(props) =>
      props.primaryColor && `border-bottom: 2px solid ${props.primaryColor};`}
  }

  ul.scroll-menu {
    background-color: 'black';
  }

  .tab-content {
    height: calc(100% - 100px);
  }
`;

export const TimeZone = styled.h4`
  font-size: 14px;
`;

export const Header = styled.div`
  height: 100px;

  @media (max-width: 304px) {
    height: 130px;
  }
`;

export const TopHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const IconCount = styled.div`
  margin-left: 10px;
  ${(props) =>
    props.primaryColor && `border: 4.5px solid ${props.primaryColor};`}
  border-radius: 50%;
  height: 3.125rem;
  width: 3.125rem;
  min-width: 3.125rem;
  display: flex;
  justify-content: center;
  align-items: center;

  & > h3 {
    font-size: ${(props) => props.count};
    font-weight: 500;
    margin-bottom: 0;
  }
`;

export const SubHeader = styled.div`
  justify-content: center;
  display: flex;
`;

export const Hr = styled.hr`
  margin-top: -0.1px;
`;

export const IconName = styled.div`
  width: 55px;
  height: 55px;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  padding-top: 2px;
  color: white;
  border-radius: 50%;
  z-index: 99;

  ${(props) => props.color && `background-color: ${props.color};`}
  ${(props) => props.borderColor && `border-color: ${props.borderColor};`}
`;

export const MessageReceived = styled.div`
  width: 21px;
  height: 21px;
  margin-left: auto;
`;

export const MessageCount = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  background-color: #d9534f;
  border-radius: 50%;
  color: white;
  z-index: 100;
`;

export const TagStyle = (color) => {
  return {
    width: '100%',
    minWidth: '308px',
    height: '112px',
    display: 'flex',
    borderLeft: `4.5px solid ${color}`,
    margin: 0
  };
};

export const TagBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const ClientName = styled.h4`
  white-space: nowrap;
  width: 140px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const QueueName = styled.h6`
  white-space: nowrap;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ChannelName = styled.h6`
  white-space: nowrap;
  width: 80px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const LeftZone = styled.div`
  text-align: left;
  padding: 10px;
`;

export const RightZone = styled.div`
  text-align: right;
  padding: 10px;
`;
