import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { ClientProtocolModal } from './components/ClientProtocolModal';

export const ClientProtocol = ({ clientInfo, recordTypes, clientId }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <Button
        onClick={() => {
          toggleModal();
        }}
        color="success"
        size="sm"
      >
        <li className="fas fa-clock"></li>
      </Button>
      {isModalOpen ? (
        <ClientProtocolModal
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          clientInfo={clientInfo}
          recordTypes={recordTypes}
          isProtocolScreen={false}
          clientId={clientId}
        />
      ) : null}
    </>
  );
};
