/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import ConversationReportService from '../../../Report/Chat/Conversation/service/index';
import { format } from 'date-fns';

export function useGetChatById(login, isOpen) {
  const [chat, setChat] = useState();
  const date = new Date();
  const dateBeginNotFormmated = new Date('2010-01-02');
  const dateBegin = format(dateBeginNotFormmated, 'yyyy-LL-dd');
  const dateEnd = format(date, 'yyyy-LL-dd');

  const PARAMS = {
    dataInicial: dateBegin,
    dataFinal: dateEnd
  };

  const getChat = useCallback(async () => {
    try {
      if (isOpen) {
        const response = await ConversationReportService.getSynteticReport(
          'atendente',
          { ...PARAMS, group: 'fila', atendente: login, loginAtendente: login }
        );
        setChat(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  }, [login, isOpen]);

  useEffect(() => {
    getChat();
  }, [getChat]);

  return { chat, setChat };
}
