import styled from 'styled-components';
import { Card } from 'reactstrap';

export const StyledCard = styled(Card)`
  padding: 16px;
  height: 185px;
`;

export const List = styled.ul`
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin: 0;
  padding: 0;
  font-size: 1rem;
  height: 100%;
  overflow-y: auto;
`;
