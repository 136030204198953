import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import Loader from 'components/Loader';

import AccountService from '../../services/accountService';
import { toBrazilianFullDate } from 'views/CRM/shared/utils/date';
import AccountInputs from '../FormInputs';
import AccountContacts from './AccountContacts';
import AccountOpportunities from './AccountOpportunities';
import AddContactModal from './AddContactModal';
import AddOpportunityModal from './AddOpportunityModal';
import AccountProtocols from './AccountProtocols';

const sectionStyle = {
  backgroundColor: '#f7f7f7',
  padding: '0.8rem 1rem',
  borderRadius: '0.1rem'
};

const AccountDetails = () => {
  const history = useHistory();
  const { recordTypeId, id } = useParams();

  const [account, setAccount] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const getAccount = useCallback(() => {
    setIsLoading(true);
    AccountService.getById(recordTypeId, id)
      .then((response) => {
        setAccount(response.data.data);
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao buscar informações da conta');
        backToAccountListing();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [backToAccountListing, id, recordTypeId]);

  useEffect(() => {
    getAccount();
  }, [getAccount]);

  const reloadAccount = useCallback(() => {
    getAccount();
  }, [getAccount]);

  const backToAccountListing = useCallback(() => {
    history.push('/admin/crm/accounts', { recordTypeId });
  }, [recordTypeId, history]);

  if (isLoading) {
    return (
      <Row className="justify-content-md-center">
        <Loader />
      </Row>
    );
  }

  if (!account) return null;

  return (
    <>
      <SimpleHeader
        name="Account edit"
        parentName="Account"
        returnPath="/admin/crm/accounts"
        navigatorState={{ recordTypeId }}
      />
      <Container className="configuration-container mt--6" fluid>
        <Card>
          <CardBody>
            <Row className="mb-3">
              <Col sm="3">
                <h3 className="font-weight-bold small mb-0">Criado por:</h3>
                <span>{account.createdByName}</span>
              </Col>
              <Col sm="3">
                <h3 className="font-weight-bold small mb-0">Criado em:</h3>
                <span>{toBrazilianFullDate(account.createdAt)}</span>
              </Col>
              <Col sm="3">
                <h3 className="font-weight-bold small mb-0">
                  Última modificação por:
                </h3>
                <span>{account.lastUpdatedByName}</span>
              </Col>
              <Col sm="3">
                <h3 className="font-weight-bold small mb-0">
                  Última modificação em:
                </h3>
                <span>{toBrazilianFullDate(account.lastUpdatedAt)}</span>
              </Col>
            </Row>

            <hr style={{ margin: '0', paddingTop: '0' }} />

            <div className="mt-3" style={sectionStyle}>
              <h2>Dados da conta</h2>
              <AccountInputs
                recordTypeId={recordTypeId}
                account={account}
                showCallIconOnPhoneField={true}
                showMessageIconOnPhoneField={true}
              />
            </div>

            <div className="mt-3" style={sectionStyle}>
              <div className="d-flex justify-content-between mb-2">
                <h2>Contatos ({account?.contacts?.length || 0})</h2>
                <AddContactModal
                  accountId={id}
                  onAddedContact={reloadAccount}
                  buttonOpenModal={({ onClick }) => (
                    <Button size="sm" onClick={onClick}>
                      Adicionar
                    </Button>
                  )}
                />
              </div>

              <AccountContacts
                contacts={account.contacts}
                accountId={id}
                reloadAccount={reloadAccount}
              />
            </div>
            <div className="mt-3" style={sectionStyle}>
              <div className="d-flex justify-content-between mb-2">
                <h2>Oportunidades ({account?.opportunities?.length || 0})</h2>
                <AddOpportunityModal
                  accountId={id}
                  onAddedOpportunity={reloadAccount}
                  buttonOpenModal={({ onClick }) => (
                    <Button size="sm" onClick={onClick}>
                      Adicionar
                    </Button>
                  )}
                />
              </div>

              <AccountOpportunities
                opportunities={account.opportunities}
                reloadAccount={reloadAccount}
              />
            </div>

            <div className="mt-3" style={sectionStyle}>
              <h2>{`Protocolos (${account?.protocols?.length || 0})`}</h2>
              <AccountProtocols protocols={account?.protocols} />
            </div>
          </CardBody>
        </Card>
      </Container>
    </>
  );
};

export default AccountDetails;
