import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ModalIVR from './modalIVR';
import { useTree } from './tree/context';
import TreeComponent from './tree';
import IVRService from '../service';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';

const IVRForm = () => {
  const history = useHistory();
  const { id } = useParams();
  const {
    isModalOpen,
    setIsModalOpen,
    selectedNode,
    onSubmitModalData,
    submitDataToBackend,
    resetTree
  } = useTree();
  const { reset, control, handleSubmit, errors, formState } = useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const buildTreeStructureFromBackendData = useCallback(
    (backendData) => {
      const nodes = [...backendData.nodes];
      const rootNodeData = nodes.shift();
      rootNodeData.schedule_time_id = String(backendData.schedule_time_id);

      const nodesOnFlatTreeFormmat = nodes.map((node) => ({
        child: node.guid,
        parent: node.parentId,
        attributes: { ...node }
      }));

      reset({
        name: backendData.name,
        customized: backendData.customized
      });
      resetTree(nodesOnFlatTreeFormmat, rootNodeData);
    },
    [reset, resetTree]
  );

  useEffect(() => {
    if (id) {
      setIsUpdate(true);
      setLoading(true);
      IVRService.get(id)
        .then((response) => {
          buildTreeStructureFromBackendData(response.data.data);
        })
        .catch((err) => {
          console.log('Erro', err);
          history.push('/admin/ivr');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, reset, buildTreeStructureFromBackendData, history]);

  const onSubmit = async (data) => {
    submitDataToBackend(data, isUpdate, id);
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/ivr');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/ivr"
        buttonTitle="Voltar para a pagina de URA"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>{isUpdate ? 'Atualizar URA' : 'Criar URA'}</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col sm="12">
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{ required: 'Nome é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-3">
                        <Col className="md-6 mx-4">
                          <Controller
                            name="customized"
                            control={control}
                            render={(props) => (
                              <>
                                <label className="form-control-label">
                                  <Input
                                    type="checkbox"
                                    onChange={(e) => {
                                      props.onChange(e.target.checked);
                                    }}
                                    checked={props.value}
                                  />
                                  URA Customizada
                                </label>
                              </>
                            )}
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                      <hr />

                      <Row>
                        <TreeComponent />
                      </Row>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalIVR
        isOpenModal={isModalOpen}
        onModalToggle={setIsModalOpen}
        setterFunction={onSubmitModalData}
        selectedNode={selectedNode}
      />
    </>
  );
};

export default IVRForm;
