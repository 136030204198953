import React, { useEffect, useState } from 'react';
import AudioService from '../service';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ModalConfirmation from '../../../components/Modal/ConfirmationModal';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import Pagination from 'components/Pagination';

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_header_style
} from '../../../assets/styles/table_custom_style';
import { brazilian } from '../../../assets/translator/pt-br';
import ReactLoader from 'components/Loader';
import { AudioPlayer } from '../components/AudioPlayer/AudioPlayer';

const AudiosList = () => {
  const [audios, setAudios] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const [timeoutID, setTimeoutID] = useState(null); // Timeout da busca por texto
  const [idAudio, setIdAudio] = useState();
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  useEffect(() => {
    handleGetAudios();
  }, []);

  const handleGetAudios = (selectedPage = 1, querySearch = '') => {
    setLoading(true);
    AudioService.getAll(false, selectedPage, 10, false, querySearch)
      .then((res) => {
        const configs = res.data.data.map((item) => {
          return {
            ...item,
            type:
              (item.type === 'sound' && brazilian.SOUND) ||
              (item.type === 'musicHold' && brazilian.MUSIC_ON_HOLD) ||
              item.type
          };
        });
        const quantity = res.data.totalRegisters;
        const newActivePage = selectedPage;

        setAudios(configs);
        setQuantity(quantity);
        setActivePage(newActivePage);
      })
      .catch((error) => {
        const { message } = error.response.data;
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePageChange = (activePage) => handleGetAudios(activePage);

  const handleOnSearch = (event) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const q = event.target.value;
    setTimeoutID(
      setTimeout(() => {
        handleGetAudios(1, q);
      }, 1000)
    );
  };

  const controlDeleteAudio = (idAudio) => {
    setIdAudio(idAudio);
    setOpenModalConfirmation(true);
  };

  const handleDeleteAudio = (id) => {
    AudioService.delete(id)
      .then(() => {
        handleGetAudios();
        toast.success('Áudio excluído!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .catch((err) => {
        const { message } = err.response.data;
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        handleGetAudios();
      });
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/audio/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Áudio</h3>
                </CardHeader>

                <Row className="mt-3 mx-2">
                  <Col md="8">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Pesquisar por nome"
                        type="text"
                        defaultValue=""
                        onChange={handleOnSearch}
                      />
                    </InputGroup>
                  </Col>
                </Row>

                <ToolkitProvider
                  data={audios}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'description',
                      text: 'Descrição',
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'type',
                      text: 'Tipo',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'tocar',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Ouvir',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return <AudioPlayer id={row.id} />;
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link to={'/admin/audio/form/' + row.id}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },

                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            onClick={() => controlDeleteAudio(row.id)}
                            size="sm"
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idAudio}
        deleteResource={handleDeleteAudio}
        message={'Tem certeza que deseja excluir o áudio?'}
      />
    </>
  );
};

export default AudiosList;
