import { apiCrm } from 'http/index';

class CRMDashboardService {
  getLeadsCountByStageWithDate(queryParams) {
    const { recordType } = queryParams;
    return apiCrm.get(`/lead/bff/dashboard/${recordType}`, {
      params: {
        ...queryParams
      }
    });
  }

  getOpportunitiesCountByStageWithDate(queryParams) {
    const { recordType } = queryParams;
    return apiCrm.get(`/opportunity/bff/dashboard/${recordType}`, {
      params: {
        ...queryParams
      }
    });
  }
}

export default new CRMDashboardService();
