import { ErrorMessage } from '@hookform/error-message';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { Col, Form, Input, Row } from 'reactstrap';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';

import { ChatMessageAudioSelectorWrapper } from './styled';
import FormTooltip from 'components/Inputs/FormTooltip';

import { MdClear } from 'react-icons/md';

function ChatMessageAudioSelector({
  sound,
  setSound,
  tooltipText,
  setIsValidSound,
  base64Sound,
  setBase64Sound
}) {
  const { errors, setError, clearErrors } = useForm();
  const [isAudioValid, setIsAudioValid] = useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  useEffect(() => {
    if (base64Sound === null) return;
    setIsAudioValid(true);
  }, [base64Sound]);

  const isFileValid = (file) => {
    if (!file) {
      setError('sound', {
        type: 'required',
        message: 'Selecione um arquivo de áudio'
      });
      return false;
    }
    if (file.size > 50000) {
      setError('sound', {
        type: 'maxLength',
        message: 'Arquivo maior do que 50 Kb'
      });
      return false;
    }
    if (
      ![
        'audio/mpeg',
        'audio/mp3',
        'audio/wav',
        'audio/x-wav',
        'audio/x-gsm',
        'audio/ogg'
      ].includes(file.type)
    ) {
      setError('sound', {
        type: 'pattern',
        message: 'Formato de áudio inválido'
      });
      return false;
    }

    clearErrors('sound');
    return true;
  };

  const changeFile = (event) => {
    const file = event.target.files[0];
    setBase64Sound(null);
    isFileValid(file);
    setIsAudioValid(isFileValid(file));
    setIsValidSound(isFileValid(file));
    setSound(file);
  };

  const handleClearAudio = () => {
    setSound(null);
    setBase64Sound(null);
    setIsAudioValid(false);
    setIsValidSound(false);
    clearErrors('sound');
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <ChatMessageAudioSelectorWrapper>
      <Form className="needs-validation" encType="multipart/form-data">
        <Row className="mb-3">
          <Col
            sm="12"
            style={{
              display: 'flex',
              alignItems: 'center'
            }}
          >
            <strong>Áudio do chat</strong>
            <FormTooltip text={tooltipText} />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm="12">
            <label className="form-control-label d-block">
              Áudio ( mp3, wav, gsm, ogg, limite de 50 Kb )*
            </label>
            <label
              htmlFor="sound"
              className="audio-button-select-style"
              style={buttonStyle(systemConfiguration.primary_color)}
            >
              Selecionar um arquivo
            </label>
            <Input
              style={{ display: 'none' }}
              type="file"
              id="sound"
              name="sound"
              onChange={changeFile}
              accept="audio/mp3, audio/mpeg, audio/wav, audio/x-wav, audio/x-gsm, audio/ogg"
            />
            <ErrorMessage
              errors={errors}
              name="sound"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          {isAudioValid === true && (base64Sound !== null || sound !== null) ? (
            <>
              <Col
                sm="9"
                style={{
                  display: 'flex',
                  alignItems: 'center'
                }}
              >
                <audio
                  src={
                    base64Sound !== null
                      ? base64Sound
                      : window.URL.createObjectURL(sound)
                  }
                  controls
                  style={{ width: '100%', maxWidth: '20rem' }}
                ></audio>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleClearAudio}
                  style={{
                    marginLeft: '1rem',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <MdClear title="Remover arquivo selecionado" />
                </button>
              </Col>
            </>
          ) : null}
        </Row>
      </Form>
    </ChatMessageAudioSelectorWrapper>
  );
}

export default ChatMessageAudioSelector;
