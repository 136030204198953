import styled from 'styled-components';

export const ColorArea = styled.div`
  width: 10px;
  height: 60px;
  ${(props) => props.color && `background-color: ${props.color};`}
`;
export const TotalAttendanceData = styled.span`
  display: flex;
  font-size: 1.1em;
  padding-top: 2%;
  font-weight: bold;
  color: white;
`;

export const chartStyle = {
  height: '105px',
  width: '100%',
  alignItems: 'center'
};
