import React, { useEffect, useState } from 'react';
import Chart from 'chart.js';

import { Row, Col, Container } from 'reactstrap';
import randomColor from 'randomcolor';
import { chartOptions, parseOptions } from 'variables/charts.js';

import ContentTags from '../../components/tags';
import ContentSeachs from '../../components/searchs';
import ContentIndicators from '../../components/indicators';

// eslint-disable-next-line react/display-name
const NpsTable = React.forwardRef((props) => {
  const [filteredLabels, setFilteredLabels] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [filteredColors, setFilteredColors] = useState(null);
  const [count, setCount] = useState(null);
  const [dataTagsCloud, setDataTagsCloud] = useState([]);
  const [arrayTags1, setArrayTags1] = useState([]);
  const [arrayTags2, setArrayTags2] = useState([]);
  const [dataIndicators, setDataIndicators] = useState();

  useEffect(() => {
    if (window.Chart) parseOptions(Chart, chartOptions());

    if (props.dataReport) {
      let arrayLabels = [];
      let arrayData = [];
      let arrayColors = [];
      let totalCount = 0;

      props.dataReport.forEach((el) => {
        if (el._id) {
          if (el._id.name !== '') {
            arrayLabels.push(el._id.name || el._id);
          } else {
            arrayLabels.push('Não definido');
          }
        } else {
          arrayLabels.push('Não definido');
        }

        arrayData.push(el.count);
        arrayColors.push(randomColor({ luminosity: 'random', hue: 'random' }));
        totalCount += el.count;
      });

      setFilteredLabels([...arrayLabels]);
      setFilteredData([...arrayData]);
      setFilteredColors([...arrayColors]);
      setCount(totalCount);
    }
  }, [props.dataReport]);

  useEffect(() => {
    if (
      typeof props.dataTagsCloud !== 'undefined' &&
      props.dataTagsCloud.list.length > 0
    ) {
      const dataCloudTag = props.dataTagsCloud.list.map((tagCloud) => {
        return {
          value: tagCloud._id,
          count: tagCloud.count
        };
      });
      setDataTagsCloud(dataCloudTag);
      const sizeControlerArrayTags = dataCloudTag.length;
      const arrayTag1 = [];
      const arrayTag2 = [];
      if (sizeControlerArrayTags % 2 === 0) {
        const sizeArrayTagsPar = dataCloudTag.length / 2;
        for (let index = 0; index < sizeArrayTagsPar; index++) {
          arrayTag1.push(dataCloudTag[index]);
        }
        for (
          let index = sizeArrayTagsPar;
          index < dataCloudTag.length;
          index++
        ) {
          arrayTag2.push(dataCloudTag[index]);
        }
      } else {
        const sizeArrayTagsImpar = Math.round(dataCloudTag.length / 2);
        for (let index = 0; index < sizeArrayTagsImpar; index++) {
          arrayTag1.push(dataCloudTag[index]);
        }
        for (
          let index = sizeArrayTagsImpar;
          index < dataCloudTag.length;
          index++
        ) {
          arrayTag2.push(dataCloudTag[index]);
        }
      }
      setArrayTags1(arrayTag1);
      setArrayTags2(arrayTag2);
    }
  }, [props.dataTagsCloud]);

  useEffect(() => {
    if (
      props.dataIndicators.indicadores.resultado === 0 ||
      props.dataIndicators.indicadores.resultado
    ) {
      props.dataIndicators.type = 'NPS';
    }
    setDataIndicators({ ...props.dataIndicators });
  }, [props.dataIndicators]);

  return (
    <div>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            <Container fluid>
              {filteredData && (
                <Row>
                  <Col xl="12">
                    <ContentSeachs
                      filteredLabels={filteredLabels}
                      filteredData={filteredData}
                      filteredColors={filteredColors}
                      count={count}
                    />

                    {dataIndicators.type === 'NPS' && (
                      <ContentIndicators
                        dataIndicators={dataIndicators}
                        typeSurvey={props.typeSurvey}
                      />
                    )}
                    {dataTagsCloud.length > 0 && (
                      <ContentTags
                        dataTagsCloud={dataTagsCloud}
                        arrayTags1={arrayTags1}
                        arrayTags2={arrayTags2}
                        count={props.dataTagsCloud.count}
                      />
                    )}
                  </Col>
                </Row>
              )}
            </Container>
          </div>
        </div>
      </Row>
    </div>
  );
});
export default NpsTable;
