import { apiCase } from '../../../http';

const basePath = '/protocol/fields';

class ProtocolFieldsService {
  createField(data) {
    return apiCase.post(`${basePath}/create`, data);
  }

  getFields(params) {
    return apiCase.get(`${basePath}/list`, { params });
  }

  getFieldsByRecordType(recordTypeId) {
    return apiCase.get(`${basePath}/list/${recordTypeId}`);
  }

  addOptionsToListField(fieldId, data) {
    return apiCase.put(`${basePath}/addOptions/${fieldId}`, data);
  }

  getFieldById(fieldId) {
    return apiCase.get(`${basePath}/findById/${fieldId}`);
  }

  updateField(fieldId, data) {
    return apiCase.put(`${basePath}/update/${fieldId}`, data);
  }

  getDefaultFieldsDisplaySettings(recordTypeId) {
    return apiCase.get(
      `${basePath}/default-fields-display-config/${recordTypeId}`
    );
  }

  setDefaultFieldsDisplaySettings(recordTypeId, data) {
    return apiCase.put(
      `${basePath}/default-fields-display-config/${recordTypeId}`,
      data
    );
  }
}

export default new ProtocolFieldsService();
