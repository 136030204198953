export function addInitConversationInAttendance(conversation) {
  return {
    type: '@CONVERSATION_IN_ATTENDANCE/ADD_INIT_CONVERSATION',
    payload: {
      conversation
    }
  };
}

export function removeConversationInAttendance() {
  return {
    type: '@CONVERSATION_IN_ATTENDANCE/REMOVE_CONVERSATION_IN_ATTENDANCE'
  };
}

export function checkRemoveConversationInAttendance(id) {
  return {
    type: '@CONVERSATION_IN_ATTENDANCE/CHECK_REMOVE_CONVERSATION_IN_ATTENDANCE',
    payload: {
      id
    }
  };
}

export function addMessageConversationInAttendance(message, idDaConversa) {
  return {
    type: '@CONVERSATION_IN_ATTENDANCE/ADD_MESSAGE_CONVERSATION_IN_ATTENDANCE',
    payload: {
      message,
      idDaConversa
    }
  };
}

export function updateStatusMessageConversationInAttendance(eventMessage) {
  return {
    type: '@CONVERSATION_IN_ATTENDANCE/UPDATE_STATUS_MESSAGE_CONVERSATION_IN_ATTENDANCE',
    payload: {
      eventMessage
    }
  };
}

export function updateWhatsappMessageIdConversationInAttendance(eventPayload) {
  return {
    type: '@CONVERSATION_IN_ATTENDANCE/UPDATE_WHATSAPP_MESSAGE_ID_CONVERSATION_IN_ATTENDANCE',
    payload: {
      eventPayload
    }
  };
}

export function deleteMessageConversationInAttendance(message) {
  return {
    type: '@CONVERSATION_IN_ATTENDANCE/DELETE_MESSAGE_CONVERSATION_IN_ATTENDANCE',
    payload: {
      message
    }
  };
}

export function addWhatsappMessageIdConversationInAttendance(message) {
  return {
    type: '@CONVERSATION_IN_ATTENDANCE/ADD_WHATSAPP_MESSAGE_ID_CONVERSATION_IN_ATTENDANCE',
    payload: {
      message
    }
  };
}
