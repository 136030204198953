import ServiceCRUD from '../../../services/ServiceCRUDFLEXUC';
import { apiUc } from '../../../http';

class QueueService extends ServiceCRUD {
  constructor() {
    super(`/api/filas`);
  }

  recovery = (id) => {
    return apiUc.put(`${this.path}/${id}/enable`);
  };
}

export default new QueueService();
