import { useState } from 'react';

export const useModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const handleModalToggle = (value) => setIsModalOpen(value);

  return {
    isModalOpen,
    toggleModal,
    handleModalToggle
  };
};
