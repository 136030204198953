import styled from 'styled-components';

export const ColorArea = styled.div`
  width: 10px;
  height: 60px;
  ${(props) => props.color && `background-color: ${props.color};`}
`;

export const QuantitativeContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
`;

export const ItemQuantitative = styled.div`
  display: flex;
  text-align: left;
`;

export const ColorAreaIcon = styled.div`
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-right: 0.4rem;
  margin-top: 0.5rem;
  ${(props) => (props?.color ? `background-color: ${props.color}` : 'orange')}
`;
