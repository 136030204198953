import { apiCase as http } from '../http';

class ServiceCRUD {
  constructor(path) {
    this.path = path;
  }

  protocolUpdateData = (id, data, resource) => {
    return http.put(`${this.path}/${id}/${resource}`, data);
  };

  createCommentProtocol = (id, data) => {
    return http.post(`${this.path}/${id}/comments`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  deleteComment = (idProtocol, idComment) => {
    return http.delete(`${this.path}/${idProtocol}/comments/${idComment}`);
  };

  updateComment = (idProtocol, idComment, data) => {
    return http.put(`${this.path}/${idProtocol}/comments/${idComment}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  };

  getAllComments = (id) => {
    return http.get(`${this.path}/comments/list/${id}`);
  };

  generateNumberProtocol = () => {
    return http.post(`${this.path}/generate_protocol_number`);
  };

  getAll = (page = 1, query,size = 10, headers = {}) => {
    return http.get(`${this.path}?page=${page}&size=${size}&q=${query}`, {
      headers
    });
  };



  get = (id, queryParams = {}) => {
    return http.get(`${this.path}/${id}`, {
      params: {
        ...queryParams
      }
    });
  };

  create = (data) => {
    return http.post(this.path, data);
  };

  update = (id, data) => {
    return http.put(`${this.path}/${id}`, data);
  };

  delete = (id) => {
    return http.delete(`${this.path}/${id}`);
  };

  getWithParameters(page, size, queryParams = {}, headers = {}) {
    return http.get(`${this.path}/?page=${page}&size=${size}`, {
      params: {
        ...queryParams
      },
      headers
    });
  }

  updateToSelectBox = (fieldId, data, entityName) => {
    return http.put(
      `${this.path}/${entityName}/addOptionsToselectBox/${fieldId}`,
      data
    );
  };

  updateField = (id, data, type) => {
    return http.put(`${this.path}/${type}/updateField/${id}`, data);
  };

  getProtocolHistory(protocolId) {
    return http.get(`${this.path}/history/${protocolId}`);
  }

  getCountProtocolByUser(queryParams = {}) {
    return http.get(`${this.path}/report/count_protocol_by_user`, {
      params: {
        ...queryParams
      }
    });
  }

  getReportProtocol(queryParams = {}, type) {
    return http.get(`${this.path}/report/${type}`, {
      params: {
        ...queryParams
      }
    });
  }

  getConfigProtocol() {
    return http.get(`${this.path}`);
  }

  updateConfigProtocol(data) {
    return http.put(`${this.path}`, data);
  }
}
export default ServiceCRUD;
