import styled from 'styled-components';

export const Img = styled.img`
  height: 7rem;
  width: 7rem;
  margin-top: 2rem;
`;

export const Input = styled.input`
  width: 100%;
  height: 100%;
  opacity: 0;
`;
