import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import TagClientService from '../services/TagClientService';
import { toast } from 'react-toastify';
import { Row, Form, Col, Input } from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import DefaultModal from 'components/Modal/DefaultModal';
import { ButtonContainer } from 'views/SystemParams/form/components/styles';
import { PreviewColor } from 'views/SystemParams/form/components/styles';

const CreateAndEditTagClient = ({
  isModalOpen,
  onModalToggle,
  tagFromUpdate,
  getTagsList = () => {},
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  const { control, errors, watch, setValue } = useForm();

  const onSubmit = async () => {
    try {
      const dto = {
        name: watch('name'),
        color: watch('color')
      };
      if (!Object.keys(tagFromUpdate || {}).length)
        return await handlerCreateTag(dto);
      await updateTag(dto);
    } catch (err) {
      console.log(err);
      toast.error(' Erro ao criar tag', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const handlerTitle = () => {
    return tagFromUpdate?.id ? 'Editar tag' : 'Criar nova tag';
  };

  const updateTag = async ({ name, color }) => {
    if (!tagFromUpdate?.id) throw new Error('Id não informado');

    await TagClientService.update(tagFromUpdate?.id, { name, color });
    getTagsList();
    toast.success('Tag atualizada com sucesso', {
      autoClose: 3000,
      closeOnClick: true
    });
  };

  const handlerCreateTag = async ({ name, color }) => {
    await TagClientService.create({ name, color });
    getTagsList();

    toast.success('Tag criada com sucesso', {
      autoClose: 3000,
      closeOnClick: true
    });
    closeModal();
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title={handlerTitle()}
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <Form className="needs-validation">
          <Row className="mb-3">
            <Col className="px-0">
              <Col sm="12" className="mb-3">
                <label className="form-control-label">Nome*</label>
                <Controller
                  as={Input}
                  control={control}
                  name="name"
                  rules={{
                    required: 'Campo obrigatório'
                  }}
                  defaultValue={tagFromUpdate?.name || ''}
                />
                <ErrorMessage
                  errors={errors}
                  name="name"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col className="px-0">
              <Col sm="12" className="mb-3">
                <label className="form-control-label">Cor*</label>
                <Controller
                  render={(event) => (
                    <ButtonContainer>
                      <PreviewColor
                        color={event.value}
                        htmlFor="color_tag_update"
                      />

                      <input
                        type="color"
                        id="color_tag_update"
                        value={event.value}
                        onChange={(e) => setValue('color', e.target.value)}
                      />
                    </ButtonContainer>
                  )}
                  control={control}
                  name="color"
                  rules={{
                    required: 'Campo obrigatório'
                  }}
                  defaultValue={tagFromUpdate?.color || '#000'}
                />
                <ErrorMessage
                  errors={errors}
                  name="color"
                  render={({ message }) => errorFormMessage(message)}
                />
              </Col>
            </Col>
          </Row>
          <hr />
          <ReactButton btnColor="confirmation" onClick={onSubmit}>
            Salvar
          </ReactButton>
          <ReactButton btnColor="cancelation" onClick={closeModal}>
            Voltar
          </ReactButton>
        </Form>
      </>
    </DefaultModal>
  );
};

export default CreateAndEditTagClient;
