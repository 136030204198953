import ServiceCRUD from '../../../services/ServiceCRUDFLEXUC';
import { apiUc as http } from '../../../http';

class IntegrationUrlService extends ServiceCRUD {
  constructor() {
    super('/api/integration-url');
  }

  request(data) {
    return http.post('/api/integration-url/request', data);
  }

  thirdSystem() {
    return http.get('/api/integration-url/third-system');
  }
}

export default new IntegrationUrlService();
