import { apiCrm } from '../../../../../http';
import { calculateOffsetFromPageAndLimit } from 'views/CRM/shared/utils/pagination';

class OpportunityTimeService {
  async getReportOpportunityTime(
    recordTypeId,
    params = {},
    headers = { page: 1, limit: 10 }
  ) {
    const offset = calculateOffsetFromPageAndLimit(headers.page, headers.limit);
    return apiCrm.get(`opportunity-time-report/${recordTypeId}`, {
      params,
      headers: { ...headers, offset }
    });
  }

  async getReportSyntheticOpportunityTime(
    recordTypeId,
    params = {},
    headers = { page: 1, limit: 10 }
  ) {
    const offset = calculateOffsetFromPageAndLimit(headers.page, headers.limit);
    return apiCrm.get(`opportunity-time-report-synthetic/${recordTypeId}`, {
      params,
      headers: { ...headers, offset }
    });
  }

  async getOpportunityTimeByOpportunityId(opportunityId) {
    return apiCrm.get(`opportunity-time/${opportunityId}`);
  }

  async getPdf(params) {
    return apiCrm.get('/report/opportunity-time/export/pdf', {
      params,
      responseType: 'blob'
    });
  }

  async getXlsx(params) {
    return apiCrm.get('/report/opportunity-time/export/xlsx', {
      params,
      responseType: 'blob'
    });
  }

  async getCsv(params) {
    return apiCrm.get('/report/opportunity-time/export/csv', {
      params,
      responseType: 'blob'
    });
  }
}

export default new OpportunityTimeService();
