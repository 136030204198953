import React from 'react';

import {
  ContainerChat,
  ProfileInternalChat,
  UserList,
  ButtonAddGroup,
  Sinalization
} from '../../style';

import LogoG4 from '../../../../assets/img/theme/iconG4.png';

export function UserListContainer({
  sizeChat,
  handleInfosUserFunction,
  updateConversation,
  users,
  color,
  userConversation,
  senders
}) {
  const loginUser = localStorage.getItem('login');
  const originUrl = window.location.origin.includes('localhost')
    ? process.env.REACT_APP_PROXY_URL
    : window.location.origin + '/';

  return (
    <ContainerChat sizeChat={sizeChat}>
      {userConversation.map((conversation) => {
        return users.map((user) => {
          if (user.login !== loginUser && conversation.sender === user.login) {
            return (
              <UserList
                key={conversation.sender}
                onClick={() => {
                  handleInfosUserFunction(user);
                  updateConversation(user);
                }}
              >
                {user.profile_pic ? (
                  <ProfileInternalChat
                    src={originUrl + user.profile_pic}
                    alt="Profile picture"
                  />
                ) : (
                  <ProfileInternalChat src={LogoG4} alt="Profile picture" />
                )}
                <div className="name-user-chat-internal">{user.name}</div>
                {user.isLogged && <ButtonAddGroup />}
                {user.haveMessages && <Sinalization color={color} />}
              </UserList>
            );
          }
        });
      })}
      {users &&
        users.map((user) => {
          if (user.login !== loginUser && !senders.includes(user.login)) {
            return (
              <UserList
                key={user.login}
                onClick={() => {
                  handleInfosUserFunction(user);
                  updateConversation(user);
                }}
              >
                {user.profile_pic ? (
                  <ProfileInternalChat
                    src={originUrl + user.profile_pic}
                    alt="Profile picture"
                  />
                ) : (
                  <ProfileInternalChat src={LogoG4} alt="Profile picture" />
                )}
                <div className="name-user-chat-internal">{user.name}</div>
                {user.haveMessages && <Sinalization color={color} />}
                {user.isLogged ? <ButtonAddGroup /> : <></>}
              </UserList>
            );
          }
        })}
    </ContainerChat>
  );
}
