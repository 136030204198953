import React, { useEffect, useState } from 'react';
import {
  ContainerItems,
  TittleModalNotification,
  ButtonsFunc,
  TittleSeeAll
} from './styled';
import { Button, Row } from 'reactstrap';
// import { useSelector } from 'react-redux';
// import { CardStyle } from './utils/utils';
import ReactLoader from 'components/Loader';
// import CalendarService from './service/index';
import AllNotificationService from '../../../../views/AllNotification/service/AllNotificationService';
import NotificationItem from './components/NotificationItem';
import { Link } from 'react-router-dom';

import { useRequestUsers } from 'hooks/useUserContext';

const NotificationForm = ({
  isOpen,
  setSchedulingId,
  setIsSchedulingModalOpen
}) => {
  const { users } = useRequestUsers();

  const login = localStorage.getItem('login');

  const [loading, setLoading] = useState(false);
  const [totalRegister, setTotalRegister] = useState(0);
  const [isUpdate, setIsUpdate] = useState(false);
  const [page, setPage] = useState(1);
  const [typeOfNotifications, setTypeOfNotifications] = useState('ALL');
  const [dataReadNotifications, setDataReadNotifications] = useState([]);
  const [dataUnreadNotifications, setDataUnreadNotifications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);

  const removeDuplicatedNotifications = (notifications) => {
    return notifications.reduce((acc, current) => {
      const item = acc.find((item) => item.id === current.id);
      if (!item) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
  };

  const loadNotificationsRead = async (isUpdate) => {
    const size = 10;
    await AllNotificationService.getAllNotificationREAD(size, page, login)
      .then((response) => {
        if (isUpdate) {
          const notificationsReads = response.data.data;
          const orderByDateReadNotifications = notificationsReads.sort(
            function (date1, date2) {
              return new Date(date2.createdAt) - new Date(date1.createdAt);
            }
          );
          const AllReadNotifications = orderByDateReadNotifications.map(
            (notificationItem) => {
              let newObject = {};
              users.map((userInfo) => {
                if (
                  userInfo.login === notificationItem.from ||
                  userInfo.login === notificationItem.attendantLogin
                ) {
                  newObject = { ...notificationItem, user: userInfo };
                }
              });
              return newObject;
            }
          );
          setDataReadNotifications(AllReadNotifications);
          setLoading(false);
        } else {
          setTotalRegister(response.data.totalRegisters);
          const responseWithNotificationsData = response.data.data;
          const previousReadNotifications = [
            ...dataReadNotifications,
            ...responseWithNotificationsData
          ];
          const removedDuplicatedReadNotifications =
            removeDuplicatedNotifications(previousReadNotifications);
          const orderByDateReadNotifications =
            removedDuplicatedReadNotifications.sort(function (date1, date2) {
              return new Date(date2.createdAt) - new Date(date1.createdAt);
            });
          const AllReadNotifications = orderByDateReadNotifications.map(
            (notificationItem) => {
              let newObject = {};
              users.map((userInfo) => {
                if (
                  userInfo.login === notificationItem.from ||
                  userInfo.login === notificationItem.attendantLogin
                ) {
                  newObject = { ...notificationItem, user: userInfo };
                }
              });
              return newObject;
            }
          );
          setDataReadNotifications(AllReadNotifications);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadNotificationsUnread = async (
    isUpdate,
    unreadPreviousNotifications
  ) => {
    const size = 10;
    await AllNotificationService.getNotificationUNREAD(size, page, login)
      .then((response) => {
        if (isUpdate) {
          if (
            unreadPreviousNotifications &&
            unreadPreviousNotifications?.length > 0
          ) {
            const unreadNotifications = response.data.data;
            const previous = unreadPreviousNotifications;
            const previousUNREAD = [...previous, ...unreadNotifications];
            const removedDuplicatedUnreadNotifications =
              removeDuplicatedNotifications(previousUNREAD);
            const orderByDateUnreadNotifications =
              removedDuplicatedUnreadNotifications.sort(function (
                date1,
                date2
              ) {
                return new Date(date2.createdAt) - new Date(date1.createdAt);
              });
            setAllNotifications(orderByDateUnreadNotifications);
            setLoading(false);
            return;
          }
          const notificationsUnread = response.data.data;
          const orderByDateUnreadNotifications = notificationsUnread.sort(
            function (date1, date2) {
              return new Date(date2.createdAt) - new Date(date1.createdAt);
            }
          );
          const AllUnreadNotifications = orderByDateUnreadNotifications.map(
            (notificationItem) => {
              let newObject = {};
              users.map((userInfo) => {
                if (
                  userInfo.login === notificationItem.from ||
                  userInfo.login === notificationItem.attendantLogin
                ) {
                  newObject = { ...notificationItem, user: userInfo };
                }
              });
              return newObject;
            }
          );
          setDataUnreadNotifications(AllUnreadNotifications);
          setLoading(false);
        } else {
          setTotalRegister(response.data.totalRegisters);
          const responseWithNotificationsData = response.data.data;
          const previousUnreadNotifications = [
            ...dataUnreadNotifications,
            ...responseWithNotificationsData
          ];
          const removedDuplicatedUnreadNotifications =
            removeDuplicatedNotifications(previousUnreadNotifications);
          const orderByDateUnreadNotifications =
            removedDuplicatedUnreadNotifications.sort(function (date1, date2) {
              return new Date(date2.createdAt) - new Date(date1.createdAt);
            });
          const AllUnreadNotifications = orderByDateUnreadNotifications.map(
            (notificationItem) => {
              let newObject = {};
              users.map((userInfo) => {
                if (
                  userInfo.login === notificationItem.from ||
                  userInfo.login === notificationItem.attendantLogin
                ) {
                  newObject = { ...notificationItem, user: userInfo };
                }
              });
              return newObject;
            }
          );
          setDataUnreadNotifications(AllUnreadNotifications);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const loadAllNotifications = async (
    querySearch = '',
    isUpdate,
    allPreviousNotifications
  ) => {
    const size = 10;
    await AllNotificationService.getAllNotifications(
      size,
      page,
      querySearch,
      login
    )
      .then((response) => {
        if (isUpdate) {
          if (
            allPreviousNotifications &&
            allPreviousNotifications?.length > 0
          ) {
            const allNotifications = response.data.data;
            const previous = allPreviousNotifications;
            const previousALL = [...previous, ...allNotifications];
            const removedDuplicatedAllNotifications =
              removeDuplicatedNotifications(previousALL);
            const orderByDateAllNotifications =
              removedDuplicatedAllNotifications.sort(function (date1, date2) {
                return new Date(date2.createdAt) - new Date(date1.createdAt);
              });
            setAllNotifications(orderByDateAllNotifications);
            setLoading(false);
            return;
          }
          const allNotifications = response.data.data;
          const orderByDateAllNotifications = allNotifications.sort(function (
            date1,
            date2
          ) {
            return new Date(date2.createdAt) - new Date(date1.createdAt);
          });
          const allNotificationsOrdered = orderByDateAllNotifications.map(
            (notificationItem) => {
              let newObject = {};
              users.map((userInfo) => {
                if (
                  userInfo.login === notificationItem.from ||
                  userInfo.login === notificationItem.attendantLogin
                ) {
                  newObject = { ...notificationItem, user: userInfo };
                }
              });
              return newObject;
            }
          );
          setAllNotifications(allNotificationsOrdered);
        } else {
          setTotalRegister(response.data.totalRegisters);
          const responseWithNotificationsData = response.data.data;
          const previousAllNotifications = [
            ...allNotifications,
            ...responseWithNotificationsData
          ];
          const removedDuplicatedAllNotifications =
            removeDuplicatedNotifications(previousAllNotifications);
          const orderByDateAllNotifications =
            removedDuplicatedAllNotifications.sort(function (date1, date2) {
              return new Date(date2.createdAt) - new Date(date1.createdAt);
            });
          const allNotificationsData = orderByDateAllNotifications.map(
            (notificationItem) => {
              let newObject = {};
              users.map((userInfo) => {
                if (
                  userInfo.login === notificationItem.from ||
                  userInfo.login === notificationItem.attendantLogin
                ) {
                  newObject = { ...notificationItem, user: userInfo };
                }
              });
              return newObject;
            }
          );
          setAllNotifications(allNotificationsData);
          setLoading(false);
        }
      })
      .catch((err) => console.log(err));
    setLoading(false);
  };

  const handleAllNotificationsAction = () => {
    setPage(1);
    loadAllNotifications('', true, []);
    setTypeOfNotifications('ALL');
    setLoading(true);
    setAllNotifications([]);
  };

  const handleCleanNotifications = () => {
    setPage(1);
    setAllNotifications([]);
    setDataReadNotifications([]);
    setDataUnreadNotifications([]);
  };

  const handleNotificationsReadAndUnread = (typeNotification) => {
    typeNotification === 'READ'
      ? loadNotificationsRead(true)
      : loadNotificationsUnread(true);
    setTypeOfNotifications(typeNotification);
    setLoading(true);
  };

  useEffect(() => {
    if (isOpen === false) return;
    if (typeOfNotifications === 'ALL') {
      loadAllNotifications('', false);
    } else if (typeOfNotifications === 'READ') {
      loadNotificationsRead(false);
    } else {
      loadNotificationsUnread(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (page === 1) return;
    if (typeOfNotifications === 'ALL') {
      loadAllNotifications('', false, []);
    } else if (typeOfNotifications === 'READ') {
      loadNotificationsRead(false);
    } else {
      loadNotificationsUnread(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <>
      <ContainerItems id="notifications-wrapper">
        <TittleModalNotification title="Notificações do sistema">
          Notificações
        </TittleModalNotification>
        <Link to={`/admin/AllNotification`}>
          <TittleSeeAll>Ver tudo</TittleSeeAll>
        </Link>

        <ButtonsFunc>
          <Button
            className="btn btn-outline-secondary"
            style={{ maxWidth: '7rem', width: '100%' }}
            onClick={handleAllNotificationsAction}
          >
            Todas
          </Button>
          <Button
            className="btn btn-outline-secondary"
            style={{ maxWidth: '7rem', width: '100%' }}
            onClick={() => handleNotificationsReadAndUnread('READ')}
          >
            Lidas
          </Button>
          <Button
            className="btn btn-outline-secondary"
            style={{ maxWidth: '7rem', width: '100%' }}
            onClick={() => handleNotificationsReadAndUnread('UNREAD')}
          >
            Não lidas
          </Button>
        </ButtonsFunc>
        <hr />
        {loading ? (
          <Row className="justify-content-md-center">
            <ReactLoader />
          </Row>
        ) : (
          <NotificationItem
            data={
              typeOfNotifications === 'ALL'
                ? allNotifications
                : typeOfNotifications === 'READ'
                ? dataReadNotifications
                : dataUnreadNotifications
            }
            loadApi={
              typeOfNotifications === 'ALL'
                ? loadAllNotifications
                : typeOfNotifications === 'READ'
                ? loadNotificationsRead
                : loadNotificationsUnread
            }
            typeOfNotifications={typeOfNotifications}
            setPage={setPage}
            page={page}
            totalRegister={totalRegister}
            setIsUpdate={setIsUpdate}
            setSchedulingId={setSchedulingId}
            setIsSchedulingModalOpen={setIsSchedulingModalOpen}
            cleanNotifications={handleCleanNotifications}
            setLoading={setLoading}
          />
        )}
      </ContainerItems>
    </>
  );
};

export default NotificationForm;
