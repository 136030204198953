import React, { useState, useEffect } from 'react';
import { Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { endOfDay, format, startOfDay } from 'date-fns';

import Timer from '../../../../../../Dashboard/Agent/components/AgentIndicators/components/AgentCard/components/Timer';
import ReceivedCallReportService from '../../../../../../Report/Callcenter/ReceivedCall/service';
import CallMadeReportService from '../../../../../../Report/Callcenter/CallMade/service';

import {
  individual_column_style,
  individual_header_style
} from '../../../../../../../assets/styles/table_custom_style';
import { queueAgentStatus } from '../../../utils/states';
import { BadgeStatus } from './styles';

const AgentStatistics = ({ queueMembers = [] }) => {
  const [receivedCallReport, setReceivedCallReport] = useState([]);
  const [callMadeCallReport, setCallMadeReport] = useState([]);
  const FETCH_RECEIVED_CALL_REPORT_INTERVAL = 300000;

  const fetchReceivedCallReportData = async () => {
    const res = await ReceivedCallReportService.getAllReportsType(
      'detailedsynthetic',
      1,
      {
        dateBegin: format(startOfDay(new Date()), 'LL-dd-yyyy HH:mm:ss'),
        dateEnd: format(endOfDay(new Date()), 'LL-dd-yyyy HH:mm:ss'),
        timeStart: '00:00:00'.replace(/:/g, ''),
        timeEnd: '23:59:59'.replace(/:/g, ''),
        page: 1,
        agent: queueMembers.map(({ agent }) => agent.agent_code).join(', '),
        groupby: 'agente',
        reportType: 3
      },
      queueMembers.length
    );
    const { listCallDetailedSyntheticCallCenterDTO } = res.data.data;

    setReceivedCallReport(listCallDetailedSyntheticCallCenterDTO);
  };

  const fetchCallMadeReportData = async () => {
    const res = await CallMadeReportService.getAllReportsType(
      'detailedsynthetic',
      1,
      {
        dateBegin: format(startOfDay(new Date()), 'LL-dd-yyyy HH:mm:ss'),
        dateEnd: format(endOfDay(new Date()), 'LL-dd-yyyy HH:mm:ss'),
        timeStart: '00:00:00'.replace(/:/g, ''),
        timeEnd: '23:59:59'.replace(/:/g, ''),
        page: 1,
        agent: queueMembers.map(({ agent }) => agent.agent_code).join(', '),
        groupby: 'agente',
        reportType: 3
      },
      queueMembers.length
    );
    const { listCallDetailedSyntheticCallCenterDTO } = res.data.data;

    setCallMadeReport(listCallDetailedSyntheticCallCenterDTO);
  };

  const getContentByStatus = (data) => {
    if (data.state === queueAgentStatus.PAUSE) {
      return data.agent?.pause?.name || '';
    }
    const isInCall =
      data.state === queueAgentStatus.RING ||
      data.state === queueAgentStatus.RINGING;
    if (isInCall) {
      return data.caller;
    }
    return '';
  };

  const findReportDataByQueueMember = (queueMember) => {
    return receivedCallReport.find(
      ({ agentCode }) => agentCode === queueMember.agent.agent_code
    );
  };

  const findCallMadeReportDataByQueueMember = (queueMember) => {
    return callMadeCallReport.find(
      ({ agentCode }) => agentCode === queueMember.agent.agent_code
    );
  };

  const mergeQueueMembersWithReportData = (queueMember) => {
    const reportData = findReportDataByQueueMember(queueMember);
    const callMadeReportData = findCallMadeReportDataByQueueMember(queueMember);

    const queueMemberWithCallMadeInfo = {
      ...queueMember,
      callMadeAnswered: callMadeReportData?.answered
    };

    if (!reportData) {
      return {
        ...queueMemberWithCallMadeInfo,
        answered: 0,
        tma: '00:00:00',
        tta: '00:00:00'
      };
    }

    const { answered, tma, tta } = reportData;

    return {
      ...queueMemberWithCallMadeInfo,
      answered,
      tma,
      tta
    };
  };

  useEffect(() => {
    fetchReceivedCallReportData();
    fetchCallMadeReportData();

    const intervalId = setInterval(() => {
      fetchReceivedCallReportData();
      fetchCallMadeReportData();
    }, FETCH_RECEIVED_CALL_REPORT_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  const tableData = queueMembers.map(mergeQueueMembersWithReportData);

  return (
    <Card>
      <ToolkitProvider
        data={tableData}
        keyField="uuid"
        columns={[
          {
            dataField: 'state',
            text: 'Status',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style,
            formatter: (_, row) => (
              <BadgeStatus
                status={row.state}
                showPauseAlert={row.agent.pause?.show_alert}
              />
            )
          },
          {
            dataField: 'agent.usr.name',
            text: 'Agente',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          {
            dataField: 'extension',
            text: 'Ramal',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          {
            dataField: 'extension',
            text: 'Cronômetro',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style,
            formatter: (_, row) => (
              <Timer date={row.date} render={(upTime) => upTime} />
            )
          },
          {
            dataField: 'state',
            text: 'Fila',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style,
            formatter: (_, row) => {
              return row.caller ? row.agent.queue.name : '';
            }
          },
          {
            dataField: 'caller',
            text: 'Bina/Pausa',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style,
            formatter: (_, row) => getContentByStatus(row)
          },
          {
            dataField: 'answered',
            text: 'Chamadas atendidas (recebidas)',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          {
            dataField: 'callMadeAnswered',
            text: 'Chamadas atendidas (realizadas)',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          {
            dataField: 'tma',
            text: 'Tempo médio de atendimento',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          },
          {
            dataField: 'tta',
            text: 'Tempo total de atendimento',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style
          }
        ]}
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
              classes="align-items-center"
            />
          </div>
        )}
      </ToolkitProvider>
    </Card>
  );
};

export default AgentStatistics;
