import ReactButton from 'components/Buttons/ReactButton';
import DefaultModal from 'components/Modal/DefaultModal';
import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import AccountService from 'views/CRM/Account/services/accountService';
import { useClickToCallOnPhoneField } from 'views/CRM/shared/hooks/useClickToCallOnPhoneField';
import { removeMaskSpecialCharacters } from 'views/CRM/shared/utils/phoneNumberHelpers';
import TemplatesModal from 'views/ServicePanel/components/QueueContent/components/Contact/TemplatesModal';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../../../../../../assets/styles/table_custom_style';
import { getContactsAttempts } from '../../../../../../shared/ContactAttempt/utils';
import { maskPhoneNumber } from '../../../../../../shared/utils/phoneNumberHelpers';

const ContactsModal = ({
  accountId,
  recordTypeId,
  isModalOpen,
  isToGetContacts,
  opportunityData,
  onModalToggle
}) => {
  const [contacts, setContacts] = useState([]);
  const { canSendMessage } = useClickToCallOnPhoneField();
  const [
    whatsAppMessageContactInfoOpportunity,
    setWhatsAppMessageContactInfoOpportunity
  ] = useState({});
  const [
    isModalTemplateMessageOpportunityOpen,
    setIsModalTemplateMessageOpportunityOpen
  ] = useState(false);
  const [chatCreatedOnIsolatedMessageId, setChatCreatedOnIsolatedMessageId] =
    useState(null);

  useEffect(() => {
    if (isToGetContacts) {
      AccountService.getById(recordTypeId, accountId)
        .then((response) => {
          const contacts = response.data.data.contacts.filter(
            (contact) => contact.phone
          );
          setContacts(contacts);
        })
        .catch((error) => {
          console.log('Erro', error);
        });
    }
  }, [isToGetContacts, recordTypeId, accountId]);

  useEffect(() => {
    if (chatCreatedOnIsolatedMessageId) {
      getContactsAttempts(
        chatCreatedOnIsolatedMessageId,
        'CHAT',
        'OPPORTUNITY',
        opportunityData.id
      );
      return;
    }
  }, [chatCreatedOnIsolatedMessageId, opportunityData.id]);

  function handleWhatsAppMessage(phone) {
    if (!phone) return;
    const phoneNumberWithoutMask = removeMaskSpecialCharacters(phone);
    let data = {
      nome: opportunityData.title,
      telefone: phoneNumberWithoutMask !== '' ? phoneNumberWithoutMask : ''
    };

    setWhatsAppMessageContactInfoOpportunity(data);
    setIsModalTemplateMessageOpportunityOpen(
      !isModalTemplateMessageOpportunityOpen
    );
    return whatsAppMessageContactInfoOpportunity;
  }

  const createDynamicColumns = () => {
    const columns = [];

    columns.push(
      {
        dataField: 'name',
        text: 'Nome',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (_, row) => {
          return row.name;
        }
      },
      {
        dataField: 'phone',
        text: 'Telefone',
        style: individual_column_style,
        headerStyle: { ...individual_header_style, cursor: 'pointer' },
        formatter: (_, row) => {
          if (row) return maskPhoneNumber(row.phone);
        }
      },
      {
        dataField: 'action',
        text: 'Ações',
        style: icon_column_style,
        headerStyle: individual_icon_header_style,
        formatter: (_, row) => {
          return (
            <>
              <ReactButton
                btnColor="primary"
                onClick={() => handleWhatsAppMessage(row.phone)}
                disabled={!canSendMessage(row.phone)}
              >
                <i className="fas fa-comment" style={{ marginRight: '6px' }} />
                Enviar mensagem
              </ReactButton>
            </>
          );
        }
      }
    );

    return [...columns];
  };

  const formattedColumns = createDynamicColumns();

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Escolher contato"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
    >
      <ToolkitProvider
        data={contacts || []}
        keyField="id"
        columns={formattedColumns}
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
              classes="align-items-center"
            />
          </div>
        )}
      </ToolkitProvider>
      <TemplatesModal
        isModalOpen={isModalTemplateMessageOpportunityOpen}
        onModalToggle={setIsModalTemplateMessageOpportunityOpen}
        clientNumber={whatsAppMessageContactInfoOpportunity.telefone}
        clientName={whatsAppMessageContactInfoOpportunity.nome}
        clientId={whatsAppMessageContactInfoOpportunity.clientId ?? ''}
        setChatCreatedOnIsolatedMessageId={setChatCreatedOnIsolatedMessageId}
        isTryingToCreateAContactAttempt={true}
        isIsolated={true}
      />
    </DefaultModal>
  );
};

export default ContactsModal;
