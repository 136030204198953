import React from 'react';

import { Row, Card } from 'reactstrap';
import { TotalRegister } from '../../../../../components/TotalRegister';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';

import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';

const synthetic = (props) => {
  return (
    <>
      <Row>
        <div className="col">
          <div className="card-wrapper">
            {props.totalRecords >= 0 && (
              <TotalRegister totalRegister={props.totalRecords} />
            )}
            <Card>
              <ToolkitProvider
                data={props.showReports}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'agent',
                    text: 'Agente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeInQueue',
                    text: 'Tempo na Fila',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeWithoutPause',
                    text: 'Tempo sem pausa',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default synthetic;
