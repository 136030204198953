import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ReactButton from 'components/Buttons/ReactButton';

import ProtocolProcessService from '../service';
import { ProtocolStatusConfig } from './components/ProtocolStatusConfig';
import FlexSelect from 'components/Inputs/FlexSelect';

import StatusService from 'views/status/service';
import { ScreenPrompt } from 'components/ScreenPrompt';

const ProtocolProcessForm = () => {
  const [loading, setLoading] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);
  const [statues, setStatues] = useState([]);
  const [protocolStatusConfig, setProtocolStatusConfig] = useState([]);

  const history = useHistory();
  const { id } = useParams();
  const { control, handleSubmit, errors, setValue, reset, watch, formState } =
    useForm();
  const { isDirty } = formState;

  const selectedProtocolStatus = watch('protocolStatus');

  useEffect(() => {
    setIsLoadingStatus(true);
    StatusService.getWithoutPagination()
      .then((response) => {
        setStatues(response.data.content);
      })
      .catch((error) => {
        const msg =
          error.response?.data?.message || 'Erro ao buscar status/fases';
        toast.error(msg);
      })
      .finally(() => {
        setIsLoadingStatus(false);
      });
  }, []);

  useEffect(() => {
    if (id) {
      setLoading(true);
      ProtocolProcessService.getById(id)
        .then((response) => {
          const data = response.data.data;
          reset({
            name: data.name,
            protocolStatus: data?.protocolStatusConfig?.map(
              (config) => config.protocolStatusId
            )
          });
        })
        .catch((error) => {
          const msg =
            error.response?.data?.message ||
            'Erro ao buscar processo de atendimento';
          toast.error(msg);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    const config = protocolStatusConfig
      .map(({ id }) => id)
      .map((protocolStatusId) => ({
        protocolStatusId,
        type: protocolStatusId === data.type
      }));

    const requestDataDTO = {
      name: data.name,
      protocolStatusConfig: config
    };

    const savePromise = id
      ? ProtocolProcessService.update(id, requestDataDTO)
      : ProtocolProcessService.create(requestDataDTO);

    setLoading(true);

    savePromise
      .then(() => {
        if (id) {
          toast.success('Sucesso ao atualizar o processo de atendimento', {
            autoClose: 3000,
            closeOnClick: true
          });
        } else {
          toast.success('Sucesso ao criar o processo de atendimento', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
        backToListing();
      })
      .catch((error) => {
        const msg =
          error.response?.data?.message ||
          'Erro ao criar processo de atendimento';
        toast.error(msg);
        setLoading(false);
      });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const backToListing = () => {
    history.push('/admin/crm/protocolProcess');
  };

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/crm/protocolProcess"
      />
      <Container className="mt--6" fluid>
        <Row className="mb-3">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Processo de Atendimento</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">Nome*</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="name"
                          rules={{
                            required: 'Nome é obrigatório',
                            maxLength: {
                              value: 50,
                              message: 'Nome deve ter no máximo 50 caracteres'
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="name"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">
                          Status/Fase*
                        </label>
                        <Controller
                          render={(props) => (
                            <FlexSelect
                              dataOptions={statues || []}
                              isMulti={true}
                              value={props.value}
                              valueController={setValue}
                              fieldName="protocolStatus"
                              labelName="name"
                              valueName="id"
                              isClearable={true}
                              disabled={isLoadingStatus}
                              noOptionsMessage={() =>
                                'Nenhum status/fase para ser exibida'
                              }
                            />
                          )}
                          control={control}
                          name="protocolStatus"
                          defaultValue=""
                          rules={{
                            required: 'Selecione pelo menos um status/fase',
                            validate: (value) => {
                              if (!value || !value.length)
                                return 'Selecione pelo menos um status/fase';
                              return true;
                            }
                          }}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="protocolStatus"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col sm="12" style={{ overflowX: 'auto' }}>
                        <ProtocolStatusConfig
                          protocolStatus={statues}
                          selectedProtocolStatus={selectedProtocolStatus}
                          protocolStatusConfig={protocolStatusConfig}
                          setProtocolStatusConfig={setProtocolStatusConfig}
                        />
                      </Col>
                    </Row>
                    <hr />
                    <ReactButton
                      btnColor="confirmation"
                      type="submit"
                      disabled={loading}
                    >
                      Salvar
                    </ReactButton>

                    <ReactButton
                      btnColor="cancelation"
                      onClick={backToListing}
                      disabled={loading}
                    >
                      Cancelar
                    </ReactButton>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ProtocolProcessForm;
