import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import serviceConfig from './service';
import { toast } from 'react-toastify';
import ReactLoader from 'components/Loader';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';

import SimpleHeader from 'components/Headers/SimpleHeader.js';

const LevelServiceConfiguration = () => {
  const [loading, setLoading] = useState(true);
  const { reset, control, handleSubmit, errors } = useForm();
  const history = useHistory();
  const [config, setConfig] = useState(null);

  const getConfig = useCallback(async () => {
    try {
      const { data } = await serviceConfig.getConfig();
      const config = data.data;
      setConfig(config);
      reset(config);
      setLoading(false);
    } catch (err) {
      console.log('error', err);
      toast.error('Não foi possível exibir a configuração', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      setLoading(false);
    }
  }, [reset]);

  useEffect(() => {
    getConfig();
  }, [getConfig]);

  const cancelAction = () => {
    history.push('/admin/configuration');
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const formatedDataToSubmit = (data) => {
    let formatedData = {
      levelOfService: Number(data.levelOfService)
    };

    return formatedData;
  };

  const onSubmit = async (data) => {
    try {
      const newDateFormated = formatedDataToSubmit(data);

      if (config) {
        const { data } = await serviceConfig.updateConfig(newDateFormated);
        if (data.status === 'ok'.toLocaleUpperCase()) {
          toast.info('Atualização realizada com sucesso');
        }
      } else {
        const { data } = await serviceConfig.create(newDateFormated);
        if (data.status === 'ok'.toLocaleUpperCase()) {
          toast.success('Nível de serviço cadastrado com sucesso');
        }
      }
    } catch (err) {
      console.log('error', err);
      toast.error('Erro ao realizar operação, por favor contate o suporte');
    }
  };

  return (
    <>
      <SimpleHeader name="Form validation" parentName="Forms" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3> Configuração do tempo de nível de serviço geral</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col>
                          <label className="form-control-label">
                            Tempo de nível de serviço (segundos) *
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            type="number"
                            defaultValue=""
                            rules={{
                              required: 'Campo obrigatório'
                            }}
                            name="levelOfService"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="levelOfService"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default LevelServiceConfiguration;
