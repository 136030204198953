import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import routes from 'routes';

const ProtocolLayout = () => {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/protocol') {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
            exact={prop.exact}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <div>
      <Switch>
        {getRoutes(routes)}
        <Redirect from="*" to="/admin/dashboard" />
      </Switch>
    </div>
  );
};

export default ProtocolLayout;
