export const hourRules = {
  // required: 'Campo obrigatório!',
  pattern: {
    value: /^[0-2][0-9]:[0-5][0-9]:[0-5][0-9]$/,
    message: 'Hora inválida!'
  }
};

export const dateRules = {
  required: 'Data é obrigatório!',
  pattern: {
    value: /^[0-3][0-9]\/[0-1][0-9]\/[0-9][0-9][0-9][0-9]$/,
    message: 'Data inválida! (dd/MM/aaaa)'
  }
};

export const cpfRules = {
  pattern: {
    value: /^[0-9][0-9][0-9].[0-9][0-9][0-9].[0-9][0-9][0-9]-[0-9][0-9]$/,
    message: 'CPF inválido! (000.000.000-00)'
  }
};

export const emailRules = {
  pattern: {
    value: /^[a-z0-9.]+@[a-z0-9]+.[a-z]+(.[a-z]+)?$/i,
    message: 'Email no formato inválido!'
  }
};
