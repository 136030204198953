import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { getStatisticalData } from './useReportData';
import MainContainer from './MainContainer';
import useAgentEvents from '../../../Monitoring/Queue/MonitoringScreen/hooks/useAgentEvents';

const DialerDashboard = () => {
  const dialerName = window.location.search.split('?')[1];
  const NewdialerName = dialerName.split('=')[1];
  const dialerQueueName = window.location.search.split('=')[2];
  const { id } = useParams();
  const { agents } = useAgentEvents();
  const [dialerStatistics, setDialerStatistics] = useState({});
  //console.log(dialerQueueName);
  const orderByExtension = (items) => {
    return [...items].sort((a, b) => {
      return a.extension < b.extension ? -1 : 1;
    });
  };

  const orderByQueue = (items) => {
    return [...items].sort((a, b) => {
      return a.queueName < b.queueName ? -1 : 1;
    });
  };
  const filterAgentsByQueue = (items) => {
    const listFilterAgents = items.filter(
      (item) => item.queueName === dialerQueueName
    );
    return listFilterAgents;
  };

  useEffect(() => {
    async function getReportData() {
      const data = await getStatisticalData({ dialerId: id });
      setDialerStatistics(data.data.listDiscadorDetailedSyntheticDTO[0]);
    }

    getReportData();

    const interval = setInterval(() => {
      getReportData();
    }, 1000 * 20);

    return () => clearInterval(interval);
  }, [id]);

  return (
    <MainContainer
      data={{
        ...dialerStatistics,
        NewdialerName,
        agents: orderByExtension(orderByQueue(filterAgentsByQueue(agents)))
      }}
    />
  );
};

export default DialerDashboard;
