import React from 'react';
import CustomTooltip from './style';

export function OutlineTooltip({
  placement,
  target,
  autohide = true,
  children
}) {
  return (
    <CustomTooltip placement={placement} target={target} autohide={autohide}>
      {children}
    </CustomTooltip>
  );
}
