import { apiNps as http } from '../http';
class ServiceCRUD {
  constructor(path) {
    this.path = path;
  }

  getAll = (page = 1) => {
    return http.get(`${this.path}?pagina=${page}`);
  };

  get = async (id) => {
    return http.get(`${this.path}/${id}`);
  };

  create = (data) => {
    return http.post(this.path, data);
  };

  update = (id, data) => {
    return http.put(`${this.path}/${id}`, data);
  };

  delete = (id) => {
    return http.delete(`${this.path}/${id}`);
  };

  enable = (id, data) => {
    return http.put(`${this.path}/${id}`, data);
  };

  enableConfig = (id, data) => {
    return http.put(`${this.path}/enable/${id}`, data);
  };

  showTags = () => {
    return http.get(`${this.path}/list/enabled`);
  };

  fetchReports = (data) => {
    return http.post(`${this.path}`, data);
  };

  getTagsListReport = (data) => {
    return http.post(`${this.path}/tags`, data);
  };

  getDownloadsPdfAndCsv = (data, media) => {
    return http.post(`${this.path}/${media}`, data, {
      responseType: 'blob'
    });
  };

  getIndicatorsOfSynthetic = (data) => {
    return http.post(`${this.path}/indicadores`, data);
  };
}

export default ServiceCRUD;
