import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import QueueService from '../service';
import AudiosService from '../service/Audios';
import CallbacksService from '../service/Callbacks';
import CostCentersService from '../service/CostCenters';
import ScheduleTimeService from '../service/ScheduleTime';
import FlexSelect from 'components/Inputs/FlexSelect';
import FormTooltip from 'components/Inputs/FormTooltip';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import { useHistory } from 'react-router-dom';
import { strategyOptions, booleanOptions, strategyTitle } from './assets';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import { ScreenPrompt } from 'components/ScreenPrompt';
import { Chip } from '@material-ui/core';
import FormToolView from 'components/Inputs/FormToolView';

const QueueForm = (props) => {
  const history = useHistory();
  const { id } = props.match.params;

  const { reset, control, handleSubmit, errors, setValue, formState, watch } =
    useForm();
  const { isDirty } = formState;
  const [isUpdate, setIsUpdate] = useState(false);
  const [sounds, setSounds] = useState([]);
  const [musicHolds, setMusicHolds] = useState([]);
  const [callbacks, setCallbacks] = useState([]);
  const [costCenters, setCostCenters] = useState([]);
  const [scheduleTimes, setScheduleTimes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasCallback, setHasCallback] = useState(false);
  const [hasPositionAdTime, setHasPositionAdTime] = useState(false);
  const [greetingMessageEnabled, setGreetingMessageEnabled] = useState(false);
  const [transferMessageEnabled, setTransferMessageEnabled] = useState(false);
  const [closingMessageEnabled, setClosingMessageEnabled] = useState(false);
  useEffect(() => {
    setLoading(true);
    AudiosService.getWithType('sound')
      .then((response) => {
        setSounds(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    AudiosService.getWithType('musicHold')
      .then((response) => {
        setMusicHolds(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    CallbacksService.getAll()
      .then((response) => {
        setCallbacks(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    CallbacksService.getAll()
      .then((response) => {
        setCallbacks(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    ScheduleTimeService.getAll()
      .then((response) => {
        setScheduleTimes(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    CostCentersService.getAll()
      .then((response) => {
        setCostCenters(response.data.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        setLoading(false);
      });
  }, []);

  const booleanString = (string) => {
    if (string === true) return 'true';
    if (string === false) return 'false';
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      QueueService.get(id)
        .then((response) => {
          const data = response.data.data;
          let getQueue = {
            ...response.data.data,
            autopause: booleanString(data.autopause),
            is_chat: booleanString(data.is_chat),
            is_telephony: booleanString(data.is_telephony),
            ringinuse: booleanString(data.ringinuse)
          };

          reset(getQueue);
          setClosingMessageEnabled(getQueue.closing_message_enabled);
          setTransferMessageEnabled(getQueue.transfer_message_enabled);
          setGreetingMessageEnabled(getQueue.greeting_message_enabled);
          setIsUpdate(true);

          if (response.data.data.callback_id !== null) setHasCallback(true);
          if (response.data.data.announcePosition === true)
            setHasPositionAdTime(true);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(err.response.data.message || 'Erro ao carregar fila!', {
            autoClose: 3000,
            closeOnClick: true
          });
          setLoading(false);
        });
    }
  }, [id, reset]);

  const stringBoolean = (string) => {
    if (string === 'true') return true;
    if (string === 'false') return false;
  };

  const onSubmit = async (data) => {
    try {
      let formatedData = {
        ...data,
        autopause: stringBoolean(data.autopause),
        is_chat: stringBoolean(data.is_chat),
        is_telephony: stringBoolean(data.is_telephony),
        ringinuse: stringBoolean(data.ringinuse),
        send_attendant_name: data.send_attendant_name,
        greeting_message_enabled: greetingMessageEnabled,
        transfer_message_enabled: transferMessageEnabled,
        closing_message_enabled: closingMessageEnabled
      };

      if (data.fila_personalizada === '')
        formatedData = { ...formatedData, fila_personalizada: false };

      if (isUpdate) {
        if (hasCallback === false)
          formatedData = { ...formatedData, callback_id: null };
        if (hasPositionAdTime === false)
          formatedData = {
            ...formatedData,
            announceFrequency: null
          };
        await QueueService.update(id, formatedData);
      } else {
        await QueueService.create(formatedData);
      }

      history.push('/admin/queue');
      toast.success(
        isUpdate
          ? 'Fila alterada com sucesso!'
          : 'Fila cadastrada com sucesso!',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    } catch (err) {
      toast.error(err.response?.data?.message || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/queue');
  };

  const viewMessage = () => {
    const message = watch('greeting_message');

    if (
      message &&
      message !== '' &&
      message.toLowerCase().match(/{{atendente}}/g)
    )
      return message?.replace(
        /{{atendente}}/g,
        localStorage.getItem('nameUser') || 'Adm'
      );

    return message;
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/queue"
        buttonTitle="Voltar para a pagina de filas"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Fila</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color="#35ac50"
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col>
                          <h3 className="mb-1">Principal</h3>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="8">
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{
                              pattern: {
                                value: /^[a-zA-Z0-9-_]+$/,
                                message:
                                  'O nome da fila não pode conter espaços ou caracteres especiais!'
                              },
                              required: 'Nome é obrigatório!'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="1">
                          <label className="form-control-label">Cor</label>
                          <Controller
                            as={<Input type="color" name="color" id="color" />}
                            control={control}
                            name="color"
                            defaultValue="#58DE6E"
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Estratégia*
                            <FormTooltip text={strategyTitle} />
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={strategyOptions}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="strategy"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="strategy"
                            rules={{ required: 'Estratégia é obrigatório!' }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="strategy"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Setor*</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={costCenters}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="centrodecustoId"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="centrodecustoId"
                            rules={{
                              required: 'Setor é obrigatório!'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="centrodecustoId"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-5">
                        <Col md="6">
                          <label className="form-control-label">
                            Telefonia*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={booleanOptions}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="is_telephony"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="is_telephony"
                            rules={{
                              required: 'Telefonia é obrigatório!'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="is_telephony"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">Chat*</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={booleanOptions}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="is_chat"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="is_chat"
                            rules={{
                              required: 'Chat é obrigatório!'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="is_chat"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <h3 className="mb-1">Configurações</h3>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="4">
                          <label className="form-control-label">
                            Tempo de Toque*
                          </label>
                          <Controller
                            placeholder="Segundos"
                            as={Input}
                            control={control}
                            name="timeout"
                            rules={{
                              required: 'Tempo de toque é obrigatório!',
                              pattern: {
                                value: /^\d+$/,
                                message: 'Tempo de toque é um campo numérico!'
                              }
                            }}
                            defaultValue="0"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="timeout"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">
                            Tempo entre Chamadas*
                          </label>
                          <Controller
                            placeholder="Segundos"
                            as={Input}
                            control={control}
                            name="wrapuptime"
                            rules={{
                              required: 'Tempo entre chamadas é obrigatório!',
                              pattern: {
                                value: /^\d+$/,
                                message:
                                  'Tempo entre chamadas é um campo numérico!'
                              }
                            }}
                            defaultValue="0"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="wrapuptime"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">
                            Pausa Automática*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={booleanOptions}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="autopause"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="autopause"
                            rules={{
                              required: 'Pausa Automática é obrigatório!'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="autopause"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="4">
                          <label className="form-control-label">
                            Tocar quando ocupado*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={booleanOptions}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="ringinuse"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="ringinuse"
                            rules={{
                              required: 'Tocar quando ocupado é obrigatório!'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="ringinuse"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">
                            Música de Espera
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={musicHolds}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="musiconhold"
                                labelName="name"
                                valueName="name"
                              />
                            )}
                            control={control}
                            name="musiconhold"
                            defaultValue=""
                          />
                        </Col>
                        <Col md="4">
                          <label className="form-control-label">
                            Tempo de nível de serviço (segundos)
                          </label>

                          <Controller
                            as={Input}
                            control={control}
                            type="number"
                            defaultValue="15"
                            min="1"
                            name="levelOfService"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="levelOfService"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>

                      <Row className="mb-5">
                        <Col className="md-11 mx-4">
                          <Controller
                            name="fila_personalizada"
                            control={control}
                            render={(props) => (
                              <>
                                <label className="form-control-label">
                                  <Input
                                    type="checkbox"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                  />
                                  Fila Personalizada
                                  <FormTooltip text="Selecione esta opção caso a fila possua regras de horário complexas." />
                                </label>
                              </>
                            )}
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <hr />
                      <Row className="mb-3">
                        <Col>
                          <h3 className="mb-1">Programação de Horário</h3>
                        </Col>
                      </Row>
                      <Row className="mb-5">
                        <Col md="6">
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={scheduleTimes}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="scheduleTime_id"
                                labelName="name"
                                valueName="id"
                              />
                            )}
                            control={control}
                            name="scheduleTime_id"
                            rules={{
                              required: 'Programação de horário é obrigatório!'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="scheduleTime_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Música fora de horário
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={sounds}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="foraHorario"
                                labelName="name"
                                valueName="name"
                              />
                            )}
                            control={control}
                            name="foraHorario"
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <h3 className="mb-1">Facilidade</h3>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">
                            Anúncio Periódico
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={true}
                                dataOptions={sounds}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="periodic_announce"
                                labelName="name"
                                valueName="name"
                              />
                            )}
                            control={control}
                            name="periodic_announce"
                            defaultValue=""
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Frequência Anúncio Periódico
                          </label>
                          <Controller
                            placeholder="Segundos"
                            as={Input}
                            control={control}
                            name="periodic_announce_frequency"
                            rules={{
                              required:
                                'Frequência anúncio periódico é obrigatório!'
                            }}
                            defaultValue="0"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="periodic_announce_frequency"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        {hasCallback && (
                          <Col md="6">
                            <label className="form-control-label">
                              CallBack
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={true}
                                  dataOptions={callbacks}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="callback_id"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="callback_id"
                              rules={{
                                required:
                                  'A opção Habilitar Callback está ativa, portanto selecione!'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="callback_id"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        )}
                        {hasPositionAdTime && (
                          <Col md="6">
                            <label className="form-control-label">
                              Tempo Anúncio de Posição
                            </label>
                            <Controller
                              placeholder="Segundos"
                              as={Input}
                              control={control}
                              name="announceFrequency"
                              rules={{
                                required:
                                  'A opção Anúncio de Posição está ativa, portanto selecione!',
                                pattern: {
                                  value: /^(|\d)+$/,
                                  message:
                                    'Tempo anúncio de posição é um campo numérico!'
                                }
                              }}
                              defaultValue="0"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="announceFrequency"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className="mb-3">
                        <Col className="md-6 mx-4">
                          <label className="form-control-label">
                            <Input
                              type="checkbox"
                              onChange={() => setHasCallback(!hasCallback)}
                              checked={hasCallback}
                            />
                            Habilitar Callback
                          </label>
                        </Col>
                        <Col className="md-6 mx-4">
                          <Controller
                            name="announcePosition"
                            control={control}
                            render={(props) => (
                              <>
                                <label className="form-control-label">
                                  <Input
                                    type="checkbox"
                                    onChange={(e) => {
                                      props.onChange(e.target.checked);
                                      setHasPositionAdTime(!hasPositionAdTime);
                                    }}
                                    checked={props.value}
                                  />
                                  Anúncio de Posição
                                </label>
                              </>
                            )}
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col>
                          <h3 className="mb-1">Chat</h3>
                        </Col>
                      </Row>
                      <Row className="mb-5">
                        <Col md="4">
                          <label
                            className="form-control-label"
                            style={{ marginLeft: '20px' }}
                          >
                            <Input
                              type="checkbox"
                              checked={greetingMessageEnabled}
                              onClick={() =>
                                setGreetingMessageEnabled(
                                  !greetingMessageEnabled
                                )
                              }
                            />
                            Mensagem de Saudação
                            {watch('greeting_message') !== '' && (
                              <FormToolView text={viewMessage()} />
                            )}
                            <FormTooltip text="Ao adicionar {{atendente}} na mensagem, será automaticamente substituido pelo nome do atendente" />
                            <Chip
                              size="small"
                              style={{
                                padding: '0px',
                                backgroundColor: `${
                                  greetingMessageEnabled ? '#00a17c' : '#f5365c'
                                }`,
                                color: '#fff',
                                marginLeft: '10px'
                              }}
                              label={`${
                                greetingMessageEnabled ? 'Ativo' : 'Desativado'
                              }`}
                            />
                          </label>
                          <Controller
                            render={(props) => (
                              <Input
                                type="textarea"
                                defaultValue={
                                  control.defaultValuesRef.current
                                    .greeting_message || ''
                                }
                                name="greeting_message"
                                onChange={(e) => {
                                  props.onChange(e.target.value);
                                }}
                                disabled={!greetingMessageEnabled}
                              />
                            )}
                            control={control}
                            name="greeting_message"
                            defaultValue={
                              control.defaultValuesRef.current
                                .greeting_message || ''
                            }
                          />
                        </Col>
                        <Col md="4">
                          <label
                            className="form-control-label"
                            style={{ marginLeft: '20px' }}
                          >
                            <Input
                              type="checkbox"
                              onChange={() =>
                                setTransferMessageEnabled(
                                  !transferMessageEnabled
                                )
                              }
                              checked={transferMessageEnabled}
                            />
                            Mensagem de Transferência
                            <Chip
                              size="small"
                              style={{
                                padding: '0px',
                                backgroundColor: `${
                                  transferMessageEnabled ? '#00a17c' : '#f5365c'
                                }`,
                                color: '#fff',
                                marginLeft: '10px'
                              }}
                              label={`${
                                transferMessageEnabled ? 'Ativo' : 'Desativado'
                              }`}
                            />
                          </label>
                          <Controller
                            render={(props) => (
                              <Input
                                type="textarea"
                                onChange={(e) => props.onChange(e.target.value)}
                                name="transfer_message"
                                disabled={!transferMessageEnabled}
                                defaultValue={
                                  control.defaultValuesRef.current
                                    .transfer_message || ''
                                }
                              />
                            )}
                            control={control}
                            name="transfer_message"
                            defaultValue={
                              control.defaultValuesRef.current
                                .transfer_message || ''
                            }
                          />
                        </Col>
                        <Col md="4">
                          <label
                            className="form-control-label"
                            style={{ marginLeft: '20px' }}
                          >
                            <Input
                              type="checkbox"
                              onChange={() =>
                                setClosingMessageEnabled(!closingMessageEnabled)
                              }
                              checked={closingMessageEnabled}
                            />
                            Mensagem de Encerramento
                            <Chip
                              size="small"
                              style={{
                                padding: '0px',
                                backgroundColor: `${
                                  closingMessageEnabled ? '#00a17c' : '#f5365c'
                                }`,
                                color: '#fff',
                                marginLeft: '10px'
                              }}
                              label={`${
                                closingMessageEnabled ? 'Ativo' : 'Desativado'
                              }`}
                            />
                          </label>
                          <Controller
                            render={(props) => (
                              <Input
                                type="textarea"
                                onChange={(e) => props.onChange(e.target.value)}
                                name="closing_message"
                                disabled={!closingMessageEnabled}
                                defaultValue={
                                  control.defaultValuesRef.current
                                    .closing_message || ''
                                }
                              />
                            )}
                            control={control}
                            name="closing_message"
                            defaultValue={
                              control.defaultValuesRef.current
                                .closing_message || ''
                            }
                          />
                        </Col>
                      </Row>
                      <Row className="mb-5">
                        <Col className="md-11 mx-4">
                          <Controller
                            name="send_attendant_name"
                            control={control}
                            render={(props) => (
                              <>
                                <label className="form-control-label">
                                  <Input
                                    type="checkbox"
                                    onChange={(e) =>
                                      props.onChange(e.target.checked)
                                    }
                                    checked={props.value}
                                  />
                                  Exibir nome do atendente sobre a mensagem
                                  <FormTooltip text="Ao selecionar esse opcão o nome do atendente irá aparecer sobre a mensagem" />
                                </label>
                              </>
                            )}
                            defaultValue=""
                          />
                        </Col>
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default QueueForm;
