import React from 'react';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button } from 'reactstrap';
import {
  individual_column_style,
  individual_header_style,
  individual_icon_header_style,
  icon_column_style
} from '../../../../../../assets/styles/table_custom_style';

const CommentsList = ({
  listComments,
  handleUpdateComment,
  deleteComent,
  isProtocolScreen
}) => {
  return (
    <>
      <label className="form-control-label" style={{ paddingTop: '25px' }}>
        Comentário(s)
      </label>

      <ToolkitProvider
        data={listComments || []}
        keyField="id"
        columns={[
          {
            dataField: 'description',
            text: 'Descrição',
            sort: true,
            style: individual_column_style
          },
          {
            dataField: 'creation_date',
            text: 'Data de criação',
            sort: true,
            style: individual_column_style,
            headerStyle: individual_header_style,
            formatter: (Row) => {
              const date = new Date(Row);
              return date.toLocaleDateString('pt-br');
            }
          },
          !isProtocolScreen
            ? {
                dataField: 'edit',
                style: icon_column_style,
                headerStyle: individual_icon_header_style,
                text: 'Editar',
                // eslint-disable-next-line react/display-name
                formatter: (_, row) => {
                  return (
                    <Button
                      title={'Editar comentário'}
                      size="sm"
                      color="info"
                      onClick={() => handleUpdateComment(row)}
                    >
                      <li className="fas fa-user-edit"></li>
                    </Button>
                  );
                }
              }
            : '',
          {
            dataField: 'delete',
            style: icon_column_style,
            headerStyle: individual_icon_header_style,
            text: 'deletar',
            // eslint-disable-next-line react/display-name
            formatter: (_, row) => {
              return (
                <Button
                  title={`Apagar comentário`}
                  size="sm"
                  color="danger"
                  onClick={() => deleteComent(row.id)}
                >
                  <li className="fas fa-trash"></li>
                </Button>
              );
            }
          }
        ]}
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
              classes="align-items-center"
            />
          </div>
        )}
      </ToolkitProvider>
    </>
  );
};

export default CommentsList;
