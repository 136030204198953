import { useState, useEffect, useCallback } from 'react';
import RecordTypeService from '../service';

function useRecordTypes(entity) {
  const [recordTypes, setRecordTypes] = useState(null);
  const [isLoadingRecordTypes, setIsLoadingRecordTypes] = useState(false);

  const getRecordTypes = useCallback(
    async (params = {}) => {
      setIsLoadingRecordTypes(true);
      params.entity = entity;

      RecordTypeService.list(params)
        .then((response) => {
          const recordTypeData = response.data.recordTypes;
          setRecordTypes(recordTypeData);
        })
        .catch(() => {
          setRecordTypes(null);
        })
        .finally(() => {
          setIsLoadingRecordTypes(false);
        });
    },
    [entity]
  );

  useEffect(() => {
    getRecordTypes();
  }, [getRecordTypes]);

  return { recordTypes, isLoadingRecordTypes };
}

export { useRecordTypes };
