import styled from 'styled-components';

export const AudioInput = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;

  .controlBtn {
    font-size: 30px;
    margin: 0;
  }

  .blinker {
    width: 8px;
    margin: 30px 2px 0 -5px;
    height: 8px;
    background-color: #d9534f;
    border-radius: 50%;
    animation: blinker 1s linear infinite;
  }

  @keyframes blinker {
    50% {
      opacity: 0;
    }
`;

export const AudioTime = styled.div`
  font-size: 12px;
  margin-top: 25px;
`;
