import {
  CONFIG_INIT,
  CONFIG_UPDATE
} from '../action/systemConfiguration/actionTypes';
import { updateObject } from '../utility';

const initialState = {
  loading: true,
  _id: '',
  primary_color: '#9ab744',
  secondary_color: '#9ab744',
  confirmation_button_color: '#9ab744',
  cancelation_button_color: '#f5365c',
  welcome_msg: 'Seja bem-vindo ao FlexUc'
};

const SystemConfigurationReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONFIG_INIT:
      return updateObject(state, { loading: false, ...action.data });
    case CONFIG_UPDATE:
      return updateObject(state, { ...action.data });
    default:
      return state;
  }
};

export default SystemConfigurationReducer;
