import { CONFIG_INIT, CONFIG_UPDATE } from './actionTypes';
import { apiConfiguration } from '../../../http';

const load = (data) => {
  return {
    type: CONFIG_INIT,
    data: data
  };
};

const update = (data) => {
  return {
    type: CONFIG_UPDATE,
    data: data
  };
};

export const loadConfiguration = () => {
  return (dispath) => {
    let data = {};

    apiConfiguration
      .get('configurations')
      .then((res) => {
        data = res.data;
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        dispath(load(data));
      });
  };
};

export const updateConfiguration = (data) => {
  return (dispath) => {
    dispath(update(data));
  };
};
