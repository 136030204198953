import React from 'react';
import ContentLoader from 'react-content-loader';

const TableLoader = (props) => (
  <ContentLoader
    // style={{ width: '100%' }}
    speed={2}
    width={1801}
    height={609}
    viewBox="0 0 1801 609"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="0" rx="2" ry="2" width="1801" height="57" />
    <rect x="0" y="53" rx="2" ry="2" width="5" height="556" />
    <rect x="1796" y="41" rx="2" ry="2" width="5" height="568" />
    <rect x="1407" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="72" rx="2" ry="2" width="31" height="28" />
    <path d="M 5 112 h 1791 v 2 H 5 z M 5 57 h 1791 v 2 H 5 z" />
    <rect x="1407" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="81" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="72" rx="2" ry="2" width="31" height="28" />
    <rect x="19" y="79" rx="2" ry="2" width="15" height="15" />
    <rect x="1407" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="127" rx="2" ry="2" width="31" height="28" />
    <path d="M 5 167 h 1791 v 2 H 5 z M 5 112 h 1791 v 2 H 5 z" />
    <rect x="1407" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="136" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="127" rx="2" ry="2" width="31" height="28" />
    <rect x="19" y="134" rx="2" ry="2" width="15" height="15" />
    <rect x="1407" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="182" rx="2" ry="2" width="31" height="28" />
    <path d="M 5 222 h 1791 v 2 H 5 z M 5 167 h 1791 v 2 H 5 z" />
    <rect x="1407" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="191" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="182" rx="2" ry="2" width="31" height="28" />
    <rect x="19" y="189" rx="2" ry="2" width="15" height="15" />
    <rect x="1407" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="237" rx="2" ry="2" width="31" height="28" />
    <path d="M 5 277 h 1791 v 2 H 5 z M 5 222 h 1791 v 2 H 5 z" />
    <rect x="1407" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="246" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="237" rx="2" ry="2" width="31" height="28" />
    <rect x="19" y="244" rx="2" ry="2" width="15" height="15" />
    <rect x="1407" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="292" rx="2" ry="2" width="31" height="28" />
    <path d="M 5 332 h 1791 v 2 H 5 z M 5 277 h 1791 v 2 H 5 z" />
    <rect x="1407" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="301" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="292" rx="2" ry="2" width="31" height="28" />
    <rect x="19" y="299" rx="2" ry="2" width="15" height="15" />
    <rect x="1407" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="347" rx="2" ry="2" width="31" height="28" />
    <path d="M 5 387 h 1791 v 2 H 5 z M 5 332 h 1791 v 2 H 5 z" />
    <rect x="1407" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="356" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="347" rx="2" ry="2" width="31" height="28" />
    <rect x="19" y="354" rx="2" ry="2" width="15" height="15" />
    <rect x="1407" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="402" rx="2" ry="2" width="31" height="28" />
    <path d="M 5 442 h 1791 v 2 H 5 z M 5 387 h 1791 v 2 H 5 z" />
    <rect x="1407" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="411" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="402" rx="2" ry="2" width="31" height="28" />
    <rect x="19" y="409" rx="2" ry="2" width="15" height="15" />
    <rect x="1407" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="457" rx="2" ry="2" width="31" height="28" />
    <path d="M 5 497 h 1791 v 2 H 5 z M 5 442 h 1791 v 2 H 5 z" />
    <rect x="1407" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="466" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="457" rx="2" ry="2" width="31" height="28" />
    <rect x="19" y="464" rx="2" ry="2" width="15" height="15" />
    <rect x="1407" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="512" rx="2" ry="2" width="31" height="28" />
    <path d="M 5 552 h 1791 v 2 H 5 z M 5 497 h 1791 v 2 H 5 z" />
    <rect x="1407" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="749" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="927" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="1207" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="1076" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="599" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="459" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="521" rx="5" ry="5" width="115" height="11" />
    <rect x="1581" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1581" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1707" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1666" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="1624" y="512" rx="2" ry="2" width="31" height="28" />
    <rect x="19" y="519" rx="2" ry="2" width="15" height="15" />
    <rect x="1409" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="750" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="928" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="1208" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="1077" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="600" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="460" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="1583" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1583" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1583" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1709" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1709" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1709" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1668" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1668" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1668" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1626" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1626" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1626" y="567" rx="2" ry="2" width="31" height="28" />
    <path d="M 2 607 h 1796 v 2 H 2 z M 5 552 h 1793 v 2 H 5 z" />
    <rect x="1409" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="750" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="928" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="1208" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="1077" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="600" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="99" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="460" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="288" y="576" rx="5" ry="5" width="115" height="11" />
    <rect x="1583" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1583" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1583" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1709" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1709" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1709" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1668" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1668" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1668" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1626" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1626" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="1626" y="567" rx="2" ry="2" width="31" height="28" />
    <rect x="19" y="574" rx="2" ry="2" width="15" height="15" />
  </ContentLoader>
);

export default TableLoader;
