import React from 'react';
const stepsStyle = {
  textAlign: 'justify',
  fontSize: '1.2rem'
};

export const steps = [
  {
    selector: '.needs-validation',
    content: `Olá seja bem vindo ao nosso Tour Guiado, aqui você vai aprender como funciona a criação de um nó na árvore de diálogo.`,
    style: stepsStyle
  },
  {
    selector: '.tour1-dt',
    content: `Identificador numérico, esse número deve ser único e é opcional, serve para identificar o nó, ele será exibido na forma de uma opção que o usuário pode selecionar/digitar, ele é recomendado para ser utilizado em um nó submenu. `,
    style: stepsStyle
  },
  {
    selector: '.tour2-dt',
    content: `
      Identificador de texto, esse é o texto que será exibido ao usuário, ele é obrigatório e vai servir como identificador do nó no fluxo da árvore.
    `,
    style: stepsStyle
  },
  {
    selector: '.tour3-dt',
    content: `
      Respostas possíveis, aqui você pode adicionar as respostas que o usuário pode dar para o bot, essas respostas podem ser tanto em texto quanto em número, podendo ter espaços, símbolos e acentos, para adicionar uma nova resposta basta digitar o texto nesse campo, para remover uma resposta basta clicar no botão "x".
    `,
    style: stepsStyle
  },
  {
    selector: '.tour4-dt',
    content: () => (
      <div>
        <p>
          Tipo da ação, aqui você pode escolher qual ação o bot deve tomar
          quando o usuário selecionar essa opção, as opções são:
        </p>
        <ul
          style={{
            listStyle: 'none',
            textAlign: 'left',
            fontSize: '1rem'
          }}
        >
          <li>
            <b>Exibe as opções de um nível especifico:</b> O bot vai exibir as
            opções do nó que você selecionar;
          </li>
          <li>
            <b>Envia menagem e encerra o fluxo:</b> O bot vai enviar a mensagem
            que você digitar e encerrar o fluxo;
          </li>
          <li>
            <b>Envia mensagem e transfere para uma fila:</b> O bot vai enviar a
            mensagem que você digitar e transferir o usuário para a fila que
            você selecionar;
          </li>
          <li>
            <b>Repete as últimas opções:</b> O bot vai repetir as últimas opções
            que o usuário selecionou;
          </li>
          <li>
            <b>Cria um submenu de botões:</b> O bot vai criar um submenu de
            botões com as opções que você selecionar;
          </li>
          <li>
            <b>Cria um submenu de opções:</b> O bot vai criar um submenu de
            opções com as opções, essas opções podem ser criadas por você;
          </li>
        </ul>
      </div>
    ),
    style: stepsStyle
  },
  {
    selector: '.tour5-dt',
    content: `
      Mensagem, aqui você pode digitar a mensagem que o bot vai enviar para o usuário, essa mensagem pode ser tanto em texto quanto em número, podendo ter espaços, símbolos e acentos.
    `,
    style: stepsStyle
  }
];
