import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { ErrorMessage } from '@hookform/error-message';
import { Row, Form, Col, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import Select from 'react-select/async';

import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import Loader from 'components/Loader';
import TelephoneMask from 'components/Inputs/TelephoneMask';
import CustomFieldsInputsForm from '../../../shared/components/CustomFieldsInputsForm';

import LeadService from '../../services/leadService';
import { useListLeadStagesByRecordType } from '../../../LeadStages/hooks/useListLeadStagesByRecordType';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { custom_select } from 'assets/styles/multiple_select';
import { useSearchUsers } from 'views/User/hooks/useSearchUsers';
import { formatCustomFieldsToSubmit } from 'views/CRM/shared/helpers/customFieldsHelpers';
import { AiOutlineClear } from 'react-icons/ai';
import { ScreenPrompt } from 'components/ScreenPrompt';
import ReactLoader from 'components/Loader';

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

const LeadInputs = ({
  recordTypeId,
  isModal = false,
  callbackFunc = () => {}
}) => {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    errors,
    setValue,
    register,
    setError,
    formState,
    reset,
    watch
  } = useForm();
  const { isDirty } = formState;

  const [isSubmiting, setIsSubmiting] = useState(false);
  const [responsible, setResponsible] = useState({
    label: localStorage.getItem('name'),
    value: localStorage.getItem('login')
  });

  useEffect(() => {
    const lead = localStorage.getItem('leadValue');
    if (lead) {
      reset(JSON.parse(lead));
    }
  }, []);

  const { isSearchingUsers, searchUsers } = useSearchUsers();

  const { getCustomFields, customFields, isLoadingCustomFields } =
    useEntityCustomFieldsByRecordType(entitiesName.LEAD, recordTypeId);

  const { getLeadStagesByRecordType, isLoadingLeadStages, leadStages } =
    useListLeadStagesByRecordType();

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    getLeadStagesByRecordType(recordTypeId);
  }, [recordTypeId, getLeadStagesByRecordType]);

  useEffect(() => {
    register('responsibleLogin');
  }, [register]);

  const removeMaskSpecialCharacters = (phone) => {
    const regexToRemoveSpecialCharacters = /[^0-9a-zA-Z]+/g;
    return phone.replace(regexToRemoveSpecialCharacters, '');
  };

  const onSubmit = async (data) => {
    const originalData = { ...data };
    if (!responsible || !responsible.value) {
      setError('responsibleLogin', { message: 'Campo obrigatório' });
      return;
    }

    setIsSubmiting(true);

    data.phone = removeMaskSpecialCharacters(data.phone);
    data.responsibleLogin = responsible.value;

    const dataDTO = formatCustomFieldsToSubmit(data, customFields);

    LeadService.createLead(recordTypeId, dataDTO)
      .then((response) => {
        const message = response.data.message;
        toast.success(message ?? 'Lead criado com sucesso');
        if (!isModal) {
          history.push(
            `/admin/crm/leadEdit/${recordTypeId}/${response?.data?.data?.id}`
          );
        }
      })
      .catch((err) => {
        toast.error(
          err.response?.data?.message || err.message || 'Erro ao criar o lead',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      })
      .finally(() => {
        setIsSubmiting(false);
        localStorage.setItem('leadValue', JSON.stringify(originalData));
        if (isModal) {
          callbackFunc();
        }
      });
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    if (isModal) {
      callbackFunc();
      return;
    }

    history.push('/admin/crm/lead', { recordTypeId });
  };

  const defaultStage = leadStages?.filter(
    (leadStage) => leadStage.isDefault
  )[0];

  if (isLoadingLeadStages) {
    return (
      <Row className="justify-content-md-center">
        <ReactLoader />
      </Row>
    );
  }

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <button      
            type="button"
            style={{
              display: isModal ? 'block' : 'none',
              border: 'none',
              background: 'none'
            }}
            onClick={() => {
              localStorage.removeItem('leadValue');
              reset({ stageId: null });
              setValue('responsibleLogin', null);
              setResponsible(null);
            }}
            id="buttonInChild"
          >
            <span
              onClick={() => {
                document.getElementById('buttonInChild').click();
              }}
              className="small"
              style={{ cursor: 'pointer' }}
            >
              <AiOutlineClear /> Limpar campos
            </span>
          </button>
        </div>
        <Row className="mb-3">
          <Col sm="6">
            <label className="form-control-label">Nome*</label>
            <Controller
              render={(props) => (
                <Input
                  value={props.value}
                  onChange={props.onChange}
                  type="text"
                  placeholder="Nome"
                />
              )}
              control={control}
              defaultValue=""
              rules={{ required: 'Campo obrigatório' }}
              name="name"
            />
            <ErrorMessage
              errors={errors}
              name="name"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          <Col sm="6">
            <label className="form-control-label">Email</label>
            <Controller
              render={(props) => (
                <Input
                  value={props.value}
                  onChange={props.onChange}
                  type="text"
                  placeholder="Email"
                />
              )}
              control={control}
              defaultValue=""
              rules={{
                validate: (value) => {
                  if (!value) return true;
                  return (
                    emailRegex.test(value) || 'Email com formato incorreto'
                  );
                }
              }}
              name="email"
            />
            <ErrorMessage
              errors={errors}
              name="email"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col sm="12" md="6">
            <label className="form-control-label">Telefone</label>
            <Controller
              render={(props) => (
                <TelephoneMask
                  valueController={setValue}
                  fieldName="phone"
                  value={props.value}
                  placeholder="(99) 91234-5678"
                />
              )}
              control={control}
              defaultValue=""
              rules={{
                validate: (value) => {
                  if (!value) return true;
                  let phoneFormated = value.replace(/\D/g, '');
                  phoneFormated = phoneFormated.replace(/^0/, '');

                  if (phoneFormated.length < 10 && phoneFormated.length > 0) {
                    return 'Telefone inválido';
                  }
                  return true;
                }
              }}
              name="phone"
            />
            <ErrorMessage
              errors={errors}
              name="phone"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
          <Col sm="12" md="6">
            <label className="form-control-label">Fase do lead*</label>
            <Controller
              render={(props) => (
                <FlexSelect
                  dataOptions={leadStages || []}
                  isMulti={false}
                  value={props.value}
                  valueController={setValue}
                  fieldName="stageId"
                  labelName="name"
                  valueName="id"
                  isClearable={true}
                  disabled={isLoadingLeadStages}
                  closeMenuOnSelect={true}
                />
              )}
              control={control}
              defaultValue={defaultStage?.id || ''}
              rules={{
                required: 'Campo obrigatório'
              }}
              name="stageId"
            />
            <ErrorMessage
              errors={errors}
              name="stageId"
              render={({ message }) => errorFormMessage(message)}
            />
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md="6">
            <div className="d-flex">
              <div style={{ flexGrow: '10' }}>
                <label className="form-control-label">Responsável*</label>
                <Select
                  styles={custom_select}
                  placeholder="Busca rápida"
                  isClearable={true}
                  name="responsibleLogin"
                  isLoading={isSearchingUsers}
                  loadingMessage={() => 'Buscando...'}
                  noOptionsMessage={({ inputValue }) =>
                    !inputValue
                      ? 'Digite para pesquisar'
                      : 'Nenhum resultado encontrado'
                  }
                  loadOptions={async (inputValue) => {
                    const data = await searchUsers(inputValue);
                    return (
                      data &&
                      data.map((user) => ({
                        label: user.name,
                        value: user.login
                      }))
                    );
                  }}
                  onChange={(data) => {
                    if (data) {
                      const { value, label } = data;
                      setResponsible({
                        label,
                        value
                      });
                    } else {
                      setResponsible(null);
                    }
                  }}
                  value={
                    responsible
                      ? {
                          label: responsible.label,
                          value: responsible.value
                        }
                      : ''
                  }
                  defaultValue={
                    responsible
                      ? {
                          label: responsible.label,
                          value: responsible.value
                        }
                      : ''
                  }
                />
                <ErrorMessage
                  errors={errors}
                  name="responsibleLogin"
                  render={({ message }) => errorFormMessage(message)}
                />
              </div>
            </div>
          </Col>

          <CustomFieldsInputsForm
            fields={customFields}
            control={control}
            errors={errors}
            setValue={setValue}
            Controller={Controller}
            watch={watch}
          />
        </Row>

        <ReactButton
          btnColor="confirmation"
          type="submit"
          disabled={isSubmiting}
        >
          Salvar
        </ReactButton>

        <ReactButton
          btnColor="cancelation"
          onClick={() => {
            return cancelAction();
          }}
        >
          Cancelar
        </ReactButton>
      </Form>
    </>
  );
};

export default LeadInputs;
