import styled from 'styled-components';

//any-message
//any-message:after
export const ClientContent = styled.div`
  position: relative;
  margin-left: 20px;
  margin-bottom: 20px;
  padding: 10px 10px 30px 10px;
  background-color: #dbdbdb;

  width: fit-content;
  min-width: 200px;
  white-space: pre-wrap;
  max-width: 60%;
  /* min-height: 100px; */
  font-weight: 400;
  text-align: left;
  border-radius: 10px;

  &:after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-top: 15px solid #dbdbdb;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    top: 0;
    left: -15px;
  }

  .g4-link {
    word-wrap: break-word;
  }
`;

//.any-timestamp
export const ClientTimestamp = styled.div`
  position: absolute;
  font-size: 0.85em;
  font-weight: 400;
  bottom: 5px;
  right: 10px;
  white-space: normal;
`;

//.any-name
export const ClientName = styled.div`
  margin-bottom: 5px;
  font-weight: 600;
`;

//.user-timestamp
export const UserTimestamp = styled.div`
  position: absolute;
  font-size: 0.85em;
  font-weight: 400;
  bottom: 5px;
  right: 10px;
  white-space: normal;
`;

//.user-name
export const UserName = styled.div`
  margin-bottom: 5px;
  font-weight: 600;
`;

//.image-content
export const ImageContent = styled.div`
  text-align: center;
  justify-content: center;

  & + div {
    margin-top: 10px;
  }
`;

//.user-message
//.user-message:after
export const UserContent = styled.div`
  ${(props) => props.color && `background-color: ${props.color};`}

  position: relative;
  margin-bottom: 20px;
  margin-left: calc(100% - 62%);
  margin-right: 15px;
  align-self: flex-end;

  padding: 10px 10px 30px 10px;
  color: white;
  white-space: pre-wrap;
  width: fit-content;
  min-width: 200px;

  font-weight: 400;
  /* min-height: 100px; */
  text-align: left;
  border-radius: 10px;

  ${ImageContent} {
    margin-left: auto;
    margin-right: 20px;
  }

  &:after {
    ${(props) => props.color && `border-bottom: 15px solid ${props.color};`}
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    color: white;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    bottom: 0;
    right: -15px;
  }

  .g4-link {
    word-wrap: break-word;
  }
`;

//  ${(props) => (props.resizable ? console.log('true') : console.log('false'))}

//.message-content
export const MessageContent = styled.div`
  width: 100%;
  word-break: break-word;
`;
export const ContextMessageContent = styled.div`
  width: 100%;
  background-color: #d1d1d1;
  /* background-color: red; */
  word-break: break-word;
  margin-bottom: 4px;
  border-radius: 5px;
  padding: 10px 10px 10px 10px;
  display: flex;

  /* &:before {
    content: '';
    position: absolute;
    background-color: green;
    border-radius: 50%;
    width: 2px;
    height: 100%;
    padding: 1rem 0;
    bottom: 0;
    left: 0;
  } */
`;

export const ContextImageContent = styled.div`
  width: 100%;
  text-align: start;
  justify-content: center;
`;
export const Bar = styled.div`
  margin: -10px 10px -10px -10px;
  border-radius: 5px;
  min-height: 100%;
  width: 0.3rem;
  background-color: #9747ff;
`;

//.image
export const Image = styled.img`
  text-align: center;
  justify-content: center;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 400px;
  cursor: pointer;
`;
export const ContextImage = styled.img`
  text-align: center;
  justify-content: center;
  max-width: 10%;
  max-height: 10%;
  cursor: pointer;
`;

//.message-text
export const MessageText = styled.div`
  cursor: pointer;

  a {
    text-decoration: underline;
  }

  .messagePdf {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

export const ContentContact = styled.div`
  //cursor: pointer;
  //background-color: #c0c0c0;
  //border-radius: 6px;
  padding: 6px;

  > button {
    width: 100%;
    margin-top: 1rem;
    padding: 4px;
    //border: 2px solid #fcfcfc;
    ${(props) => props.color && `border: 1px solid ${props.color};`}

    ${(props) => props.color && `color: ${props.color};`}
    background-color: transparent;
    //color: #fcfcfc;
    border-radius: 4px;

    font-size: 10px;
    font-weight: bold;
    transition: background-color, color, 0.2s;
  }

  > button:hover {
    ${(props) => props.color && `background-color: ${props.color};`}
    color: #fcfcfc;
  }
`;

export const InfoContact = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 14px;

  > div {
    display: flex;
    flex-direction: column;
  }
`;

export const ContentModalInfoContato = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-size: 18px;
  font-style: italic;

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    border-bottom: 1px solid #ccc;
    padding: 12px 0;
  }

  .contact-name {
    font-weight: bold;
  }
`;
