import { useCallback, useState } from 'react';

import { defaultLimit } from 'views/CRM/shared/utils/pagination';

import LeadService from '../services/leadService';

export const useListLeads = ({ recordTypeId }) => {
  const [leads, setLeads] = useState(null);
  const [totalLeads, setTotalLeads] = useState(0);
  const [isLoadingLeads, setIsLoadingLeads] = useState(false);

  const getLeads = useCallback(
    (page = 1, params = {}) => {
      setIsLoadingLeads(true);

      LeadService.getLeads(recordTypeId, params, { page, limit: defaultLimit })
        .then((response) => {
          setLeads(response.data.data);
          setTotalLeads(response.data.totalRegisters);
        })
        .finally(() => setIsLoadingLeads(false));
    },
    [recordTypeId]
  );

  const getReportLeads = useCallback(
    (page = 1, params = {}) => {
      setIsLoadingLeads(true);

      LeadService.getReportLeads(recordTypeId, params, {
        page,
        limit: defaultLimit
      })
        .then((response) => {
          setLeads(response.data.data);
          setTotalLeads(response.data.totalRegisters);
        })
        .finally(() => setIsLoadingLeads(false));
    },
    [recordTypeId]
  );

  const getAllLeads = useCallback(
    (params = {}) => {
      setIsLoadingLeads(true);

      LeadService.getAllLeads(recordTypeId, params)
        .then((response) => {
          setLeads(response.data.data);
          setTotalLeads(response.data.totalRegisters);
        })
        .finally(() => setIsLoadingLeads(false));
    },
    [recordTypeId]
  );

  const clearLeads = useCallback(() => {
    setLeads(null);
    setTotalLeads(0);
    setIsLoadingLeads(false);
  }, []);

  return {
    leads,
    totalLeads,
    isLoadingLeads,
    getLeads,
    getAllLeads,
    clearLeads,
    getReportLeads
  };
};
