import React from 'react';
import { Row, Card } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';
import { TotalRegister } from '../../../../../components/TotalRegister';
import { groupByColumnsOptions } from '../utils/variables';

const defaultColumns = {
  quantity: {
    dataField: 'amount',
    text: 'Quantidade',
    sort: true,
    style: individual_column_style,
    headerStyle: individual_header_style
  }
};

const variableColumns = {
  [groupByColumnsOptions.DIALER]: {
    dataField: 'dialerName',
    text: 'Nome do discador',
    sort: true,
    style: individual_column_style,
    headerStyle: individual_header_style
  },
  [groupByColumnsOptions.STATUS]: {
    dataField: 'status',
    text: 'Status',
    sort: true,
    style: individual_column_style,
    headerStyle: individual_header_style
  },
  [groupByColumnsOptions.QUEUE]: {
    dataField: 'queue',
    text: 'Fila',
    sort: true,
    style: individual_column_style,
    headerStyle: individual_header_style
  },
  [groupByColumnsOptions.CLIENT]: {
    dataField: 'client',
    text: 'Cliente',
    sort: true,
    style: individual_column_style,
    headerStyle: individual_header_style
  },
  [groupByColumnsOptions.AGENT]: {
    dataField: 'agent',
    text: 'Agente',
    sort: true,
    style: individual_column_style,
    headerStyle: individual_header_style
  }
};

const Synthetic = ({ dataReport, totalRegister, groupBy }) => {
  const mountColmuns = () => {
    if (!groupBy) {
      return Object.values({ ...variableColumns, ...defaultColumns });
    }

    const pickedColumns = groupBy.reduce((acc, curr) => {
      const variableColumnsName = Object.keys(variableColumns);
      const shouldBePicked = variableColumnsName.some((col) => col === curr);

      if (shouldBePicked) {
        acc.push(variableColumns[curr]);
      }

      return acc;
    }, []);

    return pickedColumns.concat(Object.values(defaultColumns));
  };

  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            {totalRegister >= 0 && (
              <TotalRegister totalRegister={totalRegister} />
            )}
            <Card>
              <ToolkitProvider
                data={dataReport.listDiscadorSyntheticDTO || []}
                keyField="uuid"
                columns={mountColmuns()}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Synthetic;
