import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../../store/action/index';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';

const Logout = () => {
  const notifySucessLogout = () => toast.info('Até logo, nos vemos em breve!');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(logout());
    // channelSocket.emit('atendente_logoff', {
    //   login: localStorage.getItem('login')
    // });
  });

  notifySucessLogout();
  return <Redirect to="/" />;
};

export default Logout;
