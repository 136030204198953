/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { toast } from 'react-toastify';
import ModalConfirmation from 'views/ExtraHour/components/ModalConfirmation';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row
} from 'reactstrap';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import ReactLoader from '../../../components/Loader';
import Pagination from '../../../components/Pagination';
import MonitoringForm from '../../Report/QualityMonitoring/components/MonitoringForm';
import PaperService from '../service/index';

import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../assets/styles/table_custom_style';

export default function Paper() {
  const [papers, setPapers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openDeletePaperConfirmation, setOpenDeletePaperConfirmation] =
    useState(false);
  const [paperToBeDeletedId, setPaperToBeDeletedId] = useState();

  const [currentPaper, setCurrentPaper] = useState(undefined);

  function controlDeletePaper(paperId) {
    console.log('clicked');
    setPaperToBeDeletedId(paperId);
    setOpenDeletePaperConfirmation(true);
  }

  async function getPapers(selectedPage = 1, search = '') {
    try {
      const { data } = await PaperService.getAllSeach(
        selectedPage,
        10,
        search,
        false
      );
      setPapers(data.data);
      setQuantity(data.totalRegisters);
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao listar questionários!', {
        autoClose: 3000,
        closeOnClick: true
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    getPapers();
  }, []);

  const handleOnSearch = (event) => {
    getPapers(1, event.target.value);
  };

  const handleOnPageChange = (page) => {
    setActivePage(page);
    getPapers(page);
  };

  async function handleDeletePaper(id) {
    PaperService.delete(id)
      .then(() => {
        getPapers();
        toast.success('Questionário deletado com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .catch(() => {
        toast.error('Ocorreu um erro ao deletar questionário!', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
    setOpenDeletePaperConfirmation(false);
  }

  return (
    <>
      <SimpleHeader
        setCreate="/admin/paper/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Questionários de monitoria</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="12">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Papers"
                          type="text"
                          onChange={handleOnSearch}
                        />
                      </InputGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={papers}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'description',
                      text: 'Descrição',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'visualizer',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Visualizar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="success"
                            size="sm"
                            onClick={() => {
                              setCurrentPaper(row);
                              setOpen(true);
                            }}
                          >
                            <i className="fas fa-eye"></i>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link to={`/admin/paper/form/${row.id}`}>
                            <Button color="info" size="sm">
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => controlDeletePaper(row.id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handleOnPageChange}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <MonitoringForm
        isViewMode
        isModalOpen={open}
        onModalToggle={setOpen}
        paper={currentPaper}
      />
      <ModalConfirmation
        isModalOpen={openDeletePaperConfirmation}
        onModalToggle={setOpenDeletePaperConfirmation}
        unmountOnClose={true}
        idResource={paperToBeDeletedId}
        deleteResource={handleDeletePaper}
        message={'Tem certeza que deseja excluir o questionário?'}
      />
    </>
  );
}
