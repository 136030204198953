import React from 'react';

const MultipleRecordTypesListContainer = (props) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex'
      }}
    >
      <div
        style={{
          width: '350px',
          display: 'flex',
          flexWrap: 'wrap'
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default MultipleRecordTypesListContainer;
