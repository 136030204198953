import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { consfigFieldsClosing } from '../../../../store/action/configurationsChat/configFields';
import { Row, Col } from 'reactstrap';
import { Chip } from '@material-ui/core';
import { Container, ContentFields } from './style';
import { ButtonChat } from '../../components/buttons/index';
import { toast } from 'react-toastify';

import Service from '../../service/index';

const ClosingFields = (props) => {
  const fields = useSelector((state) => state.createObject.data);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [stateFields, setStateFields] = useState(null);
  const [Config, setConfig] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setConfig([fields]);
    setStateFields(fields.camposEncerramento);
  }, [fields]);

  function modifyFields(event) {
    setStateFields({
      ...stateFields,
      [event.target.name]: event.target.checked
    });
  }

  function modifyAccordion(event) {
    event.preventDefault();
    props.controledPanel5(false);
  }

  function sendModifications(event) {
    event.preventDefault();

    Config[0].camposEncerramento = stateFields;

    dispatch(consfigFieldsClosing(stateFields));

    Service.update(`${fields._id}`, Config[0])
      .then((response) => {
        if (response.data) {
          toast.success('Alterações feitas com sucesso', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Ops, ocorreu um erro!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
  }
  return (
    <Container>
      {stateFields && (
        <Row xs="1">
          <Col md="3" className="contentCheckbox">
            <ContentFields>
              <label>Produto</label>
              {stateFields.produto === false ? (
                <Chip size="small" className="chipFalse" label="DESABILITADO" />
              ) : (
                <Chip size="small" className="chipTrue" label="HABILITADO" />
              )}
            </ContentFields>
            <input
              type="checkbox"
              className="checkbox"
              name="produto"
              defaultChecked={stateFields.produto}
              onChange={modifyFields}
            ></input>
          </Col>

          <Col md="3" className="contentCheckbox">
            <ContentFields>
              <label>Assunto</label>
              {stateFields.assunto === false ? (
                <Chip size="small" className="chipFalse" label="DESABILITADO" />
              ) : (
                <Chip size="small" className="chipTrue" label="HABILITADO" />
              )}
            </ContentFields>
            <input
              type="checkbox"
              className="checkbox"
              name="assunto"
              defaultChecked={stateFields.assunto}
              onChange={modifyFields}
            ></input>
          </Col>

          <Col md="3" className="contentCheckbox">
            <ContentFields>
              <label>Setor</label>
              {stateFields.setor === false ? (
                <Chip size="small" className="chipFalse" label="DESABILITADO" />
              ) : (
                <Chip size="small" className="chipTrue" label="HABILITADO" />
              )}
            </ContentFields>
            <input
              type="checkbox"
              className="checkbox"
              name="setor"
              defaultChecked={stateFields.setor}
              onChange={modifyFields}
            ></input>
          </Col>

          <Col md="3" className="contentCheckbox">
            <ContentFields>
              <label>Observação</label>
              {stateFields.observacao === false ? (
                <Chip size="small" className="chipFalse" label="DESABILITADO" />
              ) : (
                <Chip size="small" className="chipTrue" label="HABILITADO" />
              )}
            </ContentFields>
            <input
              type="checkbox"
              className="checkbox"
              name="observacao"
              defaultChecked={stateFields.observacao}
              onChange={modifyFields}
            ></input>
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <ButtonChat
            action={sendModifications}
            name={'Salvar'}
            color={systemConfiguration.confirmation_button_color}
          />
          <ButtonChat
            action={(event) => modifyAccordion(event)}
            name={'Fechar'}
            color={systemConfiguration.cancelation_button_color}
          />
        </Col>
      </Row>
    </Container>
  );
};

export { ClosingFields };
