import { useState, useEffect, useCallback } from 'react';
import FieldsConfigService from 'views/fieldConfigs/service/index';

function useLeadCustomFields() {
  const [leadCustomFields, setLeadCustomFields] = useState(null);
  const [isLoadingLeadCustomFields, setIsLoadingLeadCustomFields] =
    useState(false);

  const getLeadCustomFields = useCallback(async (params = {}) => {
    setIsLoadingLeadCustomFields(true);

    FieldsConfigService.getFields('lead', params)
      .then((response) => {
        const fieldsData = response.data.data;
        setLeadCustomFields(fieldsData);
      })
      .catch(() => {
        setLeadCustomFields(null);
      })
      .finally(() => {
        setIsLoadingLeadCustomFields(false);
      });
  }, []);

  useEffect(() => {
    getLeadCustomFields();
  }, [getLeadCustomFields]);

  return {
    leadCustomFields,
    isLoadingLeadCustomFields,
    reloadLeadCustomFields: getLeadCustomFields
  };
}

export { useLeadCustomFields };
