import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

const GraphicLeadsByListCustomFields = ({ leadsCountByListCustomFields }) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const filteredOptions = leadsCountByListCustomFields.options.filter(
    (item) => item.quantity > 0
  );

  const graphicData = {
    labels: filteredOptions.map((item) => `${item.value} (${item.quantity})`),
    datasets: [
      {
        data: filteredOptions.map((item) => item.quantity),
        backgroundColor: systemConfiguration?.primary_color,
        maxBarThickness: 10
      }
    ]
  };

  return (
    <div
      className="chart mt-5"
      style={{
        width: '90%'
      }}
    >
      <Row>
        <Col md="12">
          <h2 className="text-muted mt--3">
            {leadsCountByListCustomFields.name}
          </h2>
        </Col>
      </Row>
      <Bar data={graphicData} />
    </div>
  );
};

export default GraphicLeadsByListCustomFields;
