export const container_style = {
  display: 'flex',
  height: '100%'
  // position: 'relative'
};

export const button_style = {
  border: 0,
  ouline: 'none',
  backgroundColor: 'transparent',
  fontSize: 22,
  color: '#6c7177'
};

export const icon_style = {
  fontSize: 18,
  position: 'fixed',
  left: 70
};

export const delete_icon_style = {
  ...icon_style,
  color: '#ff6666',
  bottom: 40
};

export const add_icon_style = {
  ...icon_style,
  color: '#00aa00',
  bottom: 12
};

export const display_name_style = {
  fontSize: '0.8rem'
};
