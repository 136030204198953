import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Col, Form, Input, Label, Row } from 'reactstrap';
import Loader from 'react-loader-spinner';

import DefaultModal from '../../../../components/Modal/DefaultModal';
import AssessmentService from '../service';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { FaReceipt } from 'react-icons/fa';
import StyledInput from './style';
import { AudioStreamPlayer } from 'components/AudioController/AudioStreamPlayer';
import FlexSelect from 'components/Inputs/FlexSelect';

const loaderContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

export default function AnswersModal({ assessmentId, cdrUniqueId, MonitoringForm, nameAgent,calldateStartAnswers,Origem,Queue }) {
  const { control } = useForm();
  const [assessmentData, setAssessmentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const loadScores = () => {
    if (!assessmentId) return;

    setLoading(true);

    AssessmentService.getAssessmentAnswers(assessmentId)
      .then((response) => {
        console.log(response);
        setAssessmentData(response.data.data);
      })
      .catch((error) => {
        const msg =
          (error &&
            error.response &&
            error.response.data &&
            error.response.data.message) ||
          'Erro ao recuperar respostas';

        toast.error(msg, {
          autoClose: 3000,
          closeOnClick: true
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Button color="success" onClick={toggle} size="sm">
        <FaReceipt size={17} />
      </Button>
      <DefaultModal
        show={modal}
        onModalToggle={() => {
          if (modal && !assessmentData) {
            loadScores();
          }
        }}
        title={
          assessmentData ? assessmentData.paper.name : 'Monitoria de qualidade'
        }
        modalContainerClasses="modal-lg"
        showCloseButton={true}
      >
        {loading ? (
          <div style={loaderContainerStyle}>
            <Loader type="TailSpin" color="#9ab744" height={50} width={50} />
          </div>
        ) : (
          <div>
            <AudioStreamPlayer uniqueid={cdrUniqueId} MonitoringForm={MonitoringForm} nameAgent={nameAgent} calldateStartAnswers={calldateStartAnswers} Origem={Origem} Queue= {Queue}  />
            <Form className="needs-validation">
              {assessmentData.category ? (
                <div className="mb-2">
                  <h4>{`Categoria:`}</h4>
                  <Controller
                    render={(props) => (
                      <FlexSelect
                        dataOptions={[
                          {
                            value: assessmentData.category.name
                          }
                        ]}
                        closeMenuOnSelect={true}
                        value={props.value}
                        fieldName={props.name}
                        placeholder="Categoria"
                        labelName="value"
                        valueName="value"
                        isDisabled={true}
                      />
                    )}
                    control={control}
                    name={`category`}
                    rules={{
                      required: false
                    }}
                    disabled={true}
                    defaultValue={assessmentData.category.name}
                  />
                </div>
              ) : null}
              {assessmentData.description ? (
                <div>
                  <h4>{`Descrição:`}</h4>
                  <Controller
                    as={
                      <Input
                        type="textarea"
                        rows={6}
                        style={{ margin: '0px 0px 30px' }}
                      />
                    }
                    control={control}
                    name={`description`}
                    disabled={true}
                    defaultValue={assessmentData.description}
                    placeholder="Descrição"
                  />
                </div>
              ) : null}
              {assessmentData.paper.questions
                ?.filter((q) => q.enabled)
                .map((question) => {
                  const scoresFiltered = assessmentData.scores.filter(
                    (score) => score.question.id == question.id
                  );

                  let score = null;

                  if (scoresFiltered.length == 1) {
                    score = scoresFiltered[0];
                  }

                  return (
                    <Row className="mb-5" key={question.id}>
                      <Col
                        md="12"
                        className="d-flex justify-content-center text-center"
                      >
                        <p className="font-weight-400">
                          {`${question.description} (Peso ${question.weightage})`}
                        </p>
                      </Col>
                      <Col md="3" className="d-flex justify-content-center">
                        <strong className="text-uppercase font-weight-600">
                          Não Conforme
                        </strong>
                      </Col>
                      <Col md="6">
                        <div className="d-flex justify-content-around">
                          {question.type === 'CRITICAL' && (
                            <Controller
                              render={(props) => (
                                <>
                                  <div className="d-flex flex-column align-items-center">
                                    <StyledInput
                                      {...props}
                                      id={`${question.id}_0`}
                                      type="radio"
                                      value={1}
                                      checked={score?.total == 1}
                                      disabled={true}
                                    />
                                    <Label htmlFor={`${question.id}_0`}>
                                      Não
                                    </Label>
                                  </div>
                                  <div className="d-flex flex-column align-items-center">
                                    <StyledInput
                                      {...props}
                                      id={`${question.id}_1`}
                                      type="radio"
                                      value={0}
                                      checked={score?.total == 0}
                                      disabled={true}
                                    />
                                    <Label htmlFor={`${question.id}_1`}>
                                      Sim
                                    </Label>
                                  </div>
                                </>
                              )}
                              control={control}
                              name={`${question.id}`}
                              rules={{
                                required: true
                              }}
                            />
                          )}

                          {assessmentData.paper?.type === 'basic' &&
                            question.type === 'WEIGHTAGE' && (
                              <Controller
                                render={(props) => (
                                  <>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_1`}
                                        type="radio"
                                        value={1}
                                        checked={score?.total == 1}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_1`}>
                                        1
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_2`}
                                        type="radio"
                                        value={2}
                                        checked={score?.total == 2}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_2`}>
                                        2
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_3`}
                                        type="radio"
                                        value={3}
                                        checked={score?.total == 3}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_3`}>
                                        3
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_4`}
                                        type="radio"
                                        value={4}
                                        checked={score?.total == 4}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_4`}>
                                        4
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_5`}
                                        type="radio"
                                        value={5}
                                        checked={score?.total == 5}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_5`}>
                                        5
                                      </Label>
                                    </div>
                                    {question.required ? null : (
                                      <div className="d-flex flex-column align-items-center">
                                        <StyledInput
                                          {...props}
                                          id={`${question.id}_exclude`}
                                          type="radio"
                                          value={-1}
                                          checked={
                                            score?.total == null &&
                                            !question.required
                                          }
                                          disabled={true}
                                        />
                                        <Label
                                          htmlFor={`${question.id}_exclude`}
                                        >
                                          Não se aplica
                                        </Label>
                                      </div>
                                    )}
                                  </>
                                )}
                                control={control}
                                name={`${question.id}`}
                                rules={{
                                  required: true
                                }}
                              />
                            )}

                          {assessmentData.paper?.type === 'advanced' &&
                            question.type === 'WEIGHTAGE' && (
                              <Controller
                                render={(props) => (
                                  <>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_1`}
                                        type="radio"
                                        value={1}
                                        checked={score?.total == 1}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_1`}>
                                        1
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_2`}
                                        type="radio"
                                        value={2}
                                        checked={score?.total == 2}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_2`}>
                                        2
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_3`}
                                        type="radio"
                                        value={3}
                                        checked={score?.total == 3}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_3`}>
                                        3
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_4`}
                                        type="radio"
                                        value={4}
                                        checked={score?.total == 4}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_4`}>
                                        4
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_5`}
                                        type="radio"
                                        value={5}
                                        checked={score?.total == 5}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_5`}>
                                        5
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_6`}
                                        type="radio"
                                        value={6}
                                        checked={score?.total == 6}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_6`}>
                                        6
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_7`}
                                        type="radio"
                                        value={7}
                                        checked={score?.total == 7}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_7`}>
                                        7
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_8`}
                                        type="radio"
                                        value={8}
                                        checked={score?.total == 8}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_8`}>
                                        8
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_9`}
                                        type="radio"
                                        value={9}
                                        checked={score?.total == 9}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_9`}>
                                        9
                                      </Label>
                                    </div>
                                    <div className="d-flex flex-column align-items-center">
                                      <StyledInput
                                        {...props}
                                        id={`${question.id}_10`}
                                        type="radio"
                                        value={10}
                                        checked={score?.total == 10}
                                        disabled={true}
                                      />
                                      <Label htmlFor={`${question.id}_10`}>
                                        10
                                      </Label>
                                    </div>
                                    {question.required ? null : (
                                      <div className="d-flex flex-column align-items-center">
                                        <StyledInput
                                          {...props}
                                          id={`${question.id}_exclude`}
                                          type="radio"
                                          value={-1}
                                          checked={
                                            score?.total == null &&
                                            !question.required
                                          }
                                          disabled={true}
                                        />
                                        <Label
                                          htmlFor={`${question.id}_exclude`}
                                        >
                                          Não se aplica
                                        </Label>
                                      </div>
                                    )}
                                  </>
                                )}
                                control={control}
                                name={`${question.id}`}
                                rules={{
                                  required: true
                                }}
                              />
                            )}
                        </div>
                      </Col>
                      <Col md="3" className="d-flex justify-content-center">
                        <strong className="text-uppercase font-weight-600">
                          Conforme
                        </strong>
                      </Col>
                    </Row>
                  );
                })}
            </Form>
          </div>
        )}
      </DefaultModal>
    </div>
  );
}
