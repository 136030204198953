import React, { useCallback } from 'react';
import DefaultModal from '../../../../components/Modal/DefaultModal';
import Service from '../../service';
import { toast } from 'react-toastify';
import { Row, Col } from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';

const ConfirmModal = ({
  isModalOpen,
  onModalToggle,
  render,
  idTag,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);

  const deleteTag = async () => {
    try {
      const deleteTag = await Service.delete(idTag);
      if (deleteTag.data === 'success') {
        toast.success('Tag excluida com sucesso ', {
          autoClose: 3000,
          closeOnClick: true
        });
        closeModal();
      }
    } catch (err) {
      console.log(err);
      toast.error('Erro ao excluir tag ', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      render();
    }
  };

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Confirmação"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <h3 className="mb-0 align-text-center" style={{ textAlign: 'center' }}>
          Você tem certeza que deseja realizar esta ação?
        </h3>
        <hr />
        <Row>
          <Col md="8">
            <ReactButton btnColor="confirmation" onClick={deleteTag}>
              Confirma
            </ReactButton>
          </Col>
          <Col md="4">
            <ReactButton btnColor="cancelation" onClick={closeModal}>
              Voltar
            </ReactButton>
          </Col>
        </Row>
      </>
    </DefaultModal>
  );
};

export default ConfirmModal;
