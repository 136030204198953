import styled from 'styled-components';

export const SideDrawerContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: fixed;
  transition: margin 0.6s;
  background-color: #eee;
  border-left: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1;
`;

export const ButtonToggleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background-color: transparent;
  margin-left: -1.6rem;
  padding-left: 0rem;
`;

export const ToggleButton = styled.div`
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e1e1e1;
  border-right: 0;
  color: #222;
  padding: 1rem 0.2rem;
  border-radius: 0.5rem 0 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const ToggleButtonText = styled.span`
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  font-size: 0.85rem;
`;

export const ScrollWrapper = styled.div`
  padding: ${(props) => (props.collapsed ? '0' : '1rem 1rem 1rem 1rem')};
  background-color: #fff;
  height: 100%;
  overflow-y: auto;
  box-sizing: border-box;

  ::-webkit-scrollbar {
    width: 0.7rem;
  }
  ::-webkit-scrollbar-track {
    background: unset;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }

  &:hover {
    ::-webkit-scrollbar {
      width: 0.7rem;
    }
    ::-webkit-scrollbar-thumb {
      background: #ccc;
    }
    ::-webkit-scrollbar-track {
      background: #f5f5f5;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: #aaa;
    }
  }
`;
