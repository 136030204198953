import { useState, useCallback } from 'react';
import RecordTypeService from '../service';

function useRecordTypesByUser(entity) {
  const [recordTypes, setRecordTypes] = useState(null);
  const [isLoadingRecordTypes, setIsLoadingRecordTypes] = useState(false);

  const getRecordTypesByUser = useCallback(
    async (params = {}) => {
      setIsLoadingRecordTypes(true);

      RecordTypeService.listByUser(entity, params)
        .then((response) => {
          const recordTypeData = response.data.content;
          setRecordTypes(recordTypeData);
        })
        .catch(() => {
          setRecordTypes(null);
        })
        .finally(() => {
          setIsLoadingRecordTypes(false);
        });
    },
    [entity]
  );

  return { recordTypes, isLoadingRecordTypes, getRecordTypesByUser };
}

export { useRecordTypesByUser };
