import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form, CardBody, Button } from 'reactstrap';

import * as S from './styles';
import FlexSelect from '../../../Inputs/FlexSelect';
import { ErrorMessage } from '@hookform/error-message';

const schema = Yup.object().shape({
  queueIds: Yup.array().nullable().required('Selecione pelo menos uma fila')
});

const AddQueueForm = ({
  queues,
  handleAddQueues,
  handleCancel,
  agentSessions
}) => {
  const { control, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema)
  });

  const getQueueNotLoggedYet = () => {
    const currentSessionQueuesId = agentSessions.map(
      (session) => session.queue.id
    );

    return queues.filter((q) => !currentSessionQueuesId.includes(q.id));
  };

  const onSubmit = (data) => {
    handleAddQueues({
      queueIds: data.queueIds,
      extension: agentSessions[0].extension
    });
  };

  return (
    <CardBody>
      <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <Controller
            render={(props) => (
              <FlexSelect
                isClearable={true}
                dataOptions={getQueueNotLoggedYet()}
                isMulti={true}
                closeMenuOnSelect={false}
                value={props.value}
                valueController={setValue}
                fieldName="queueIds"
                labelName="name"
                valueName="id"
                minHeight="1rem"
              />
            )}
            control={control}
            name="queueIds"
            defaultValue=""
          />

          <ErrorMessage
            errors={errors}
            name="queueIds"
            render={({ message }) => <p style={{ color: 'red' }}>{message}</p>}
          />
        </div>

        <S.ButtonsWrapper>
          <Button color="danger" type="button" onClick={handleCancel}>
            Cancelar
          </Button>
          <Button color="primary" type="submit">
            Logar
          </Button>
        </S.ButtonsWrapper>
      </Form>
    </CardBody>
  );
};

export default AddQueueForm;
