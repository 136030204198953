import React, { useEffect, useState } from 'react';
import { Card, CardBody, Container, Row, Col } from 'reactstrap';
import Loader from 'react-loader-spinner';
import { toast } from 'react-toastify';
import { Comment, ReadMore } from './styled';
import { historyEventsNames } from '../../../../../../shared/utils/historyEventsNames';

import OpportunityService from '../../../../../services/opportunityService';

const loaderContainerStyle = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const TimelineOpportunityHistory = ({
  opportunityId,
  convertOpportunityId
}) => {
  const [opportunityData, setOpportunityData] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!opportunityId) return;

    setLoading(true);

    if (convertOpportunityId) {
      OpportunityService.getConvertOpportunityHistory(opportunityId)
        .then((response) => {
          setOpportunityData(response.data.data);
        })
        .catch((error) => {
          const msg =
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.message) ||
            'Erro ao recuperar histórico da oportunidade';

          toast.error(msg, {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      OpportunityService.getOpportunityHistory(opportunityId)
        .then((response) => {
          setOpportunityData(response.data.data);
        })
        .catch((error) => {
          const msg =
            (error &&
              error.response &&
              error.response.data &&
              error.response.data.message) ||
            'Erro ao recuperar histórico da oportunidade';

          toast.error(msg, {
            autoClose: 3000,
            closeOnClick: true
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [opportunityId, convertOpportunityId]);

  const getFormattedDateAndHour = (stringDate) => {
    const date = new Date(stringDate);
    const formattedDate = date.toLocaleDateString('pt-br');
    const formattedHour = date.toLocaleTimeString('pt-br');

    return `${formattedDate} às ${formattedHour}`;
  };

  return (
    <>
      {loading ? (
        <div style={loaderContainerStyle}>
          <Loader type="TailSpin" color="#9ab744" height={50} width={50} />
        </div>
      ) : (
        <Container fluid>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  {opportunityData.length >= 1 ? (
                    opportunityData.map((historyItem) => (
                      <div
                        key={historyItem.id}
                        className="timeline timeline-one-side mx-0"
                        data-timeline-axis-style="dashed"
                        data-timeline-content="axis"
                      >
                        <div className="timeline-block pb-5">
                          <span className="timeline-step badge-success">
                            <i className="ni ni-calendar-grid-58" />
                          </span>

                          <div
                            className="timeline-content"
                            style={{ maxWidth: '50rem' }}
                          >
                            <small className="text-muted font-weight-bold">
                              {getFormattedDateAndHour(historyItem.action_at)}
                            </small>

                            {historyEventsNames.map((type) => {
                              if (type.upperName === historyItem.type) {
                                return (
                                  <h5 className="mt-3 mb-0">
                                    {type.displayName}
                                  </h5>
                                );
                              }
                              return null;
                            })}

                            {historyItem.action_by && (
                              <p className="text-sm mt-1 mb-0">
                                Feito por: {historyItem.action_by}
                              </p>
                            )}

                            {historyItem.previous_stage && (
                              <>
                                <p className="text-sm mt-1 mb-0 text-danger">
                                  Fase antiga: {historyItem.previous_stage}
                                </p>
                                <p className="text-sm mt-1 mb-0 text-success">
                                  Fase nova: {historyItem.current_stage}
                                </p>
                              </>
                            )}

                            {historyItem.previous_responsible && (
                              <>
                                <p className="text-sm mt-1 mb-0 text-danger">
                                  Responsável antigo:{' '}
                                  {historyItem.previous_responsible}
                                </p>
                                <p className="text-sm mt-1 mb-0 text-success">
                                  Responsável novo:{' '}
                                  {historyItem.current_responsible}
                                </p>
                              </>
                            )}
                            {historyItem.comment_content && (
                              <div className="text-sm mt-1 mb-0">
                                {historyItem.action_by}:
                                {historyItem.comment_content.length > 500 ? (
                                  <>
                                    <Comment>
                                      {historyItem.comment_content.slice(
                                        0,
                                        500
                                      )}
                                      ...
                                    </Comment>
                                    <ReadMore
                                      onClick={(e) => {
                                        e.currentTarget.parentNode.querySelector(
                                          'p'
                                        ).innerText =
                                          historyItem.comment_content;
                                        e.currentTarget.remove();
                                      }}
                                    >
                                      <span>Mostrar mais</span>
                                    </ReadMore>
                                  </>
                                ) : (
                                  <Comment>
                                    {historyItem.comment_content}
                                  </Comment>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-sm mt-1 mb-0 text-center">
                      Não há nenhuma informação no histórico a ser mostrada.
                    </p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default TimelineOpportunityHistory;
