import React from 'react';
import PaginationReact from 'react-js-pagination';

import PaginationContainer from '../Containers/PaginationContainer';

const Pagination = (props) => {
  const activePage = props.activePage || 1;
  const itemsCountPerPage = props.itemsCountPerPage || 10;
  const totalItemsCount = props.totalItemsCount || 10;
  const pageRangeDisplayed = 10;
  const onChange = props.onChange;

  return (
    <PaginationContainer>
      <PaginationReact
        activePage={activePage}
        itemClass="page-item"
        linkClass="page-link"
        itemsCountPerPage={itemsCountPerPage}
        totalItemsCount={totalItemsCount}
        pageRangeDisplayed={pageRangeDisplayed}
        onChange={onChange}
      />
    </PaginationContainer>
  );
};
export default Pagination;
