//http://34.220.71.166/pbxip/api/dashboard-configs
import ServiceCRUD from '../../../services/ServiceCRUDFLEXUC';

class Service extends ServiceCRUD {
  constructor() {
    super('/api/system-configs');
  }
}

export default new Service();
