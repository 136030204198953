import React from 'react';
import { Row, Col, FormGroup, Label, Input } from 'reactstrap';
import Select from 'react-select';

const customStyles = {
  control: (styles) => ({
    ...styles,
    height: '38px',
    fontSize: '0.875rem'
  })
};

const ExtensionMonitoring = ({
  textQuery,
  setTextQuery,
  hideInactive,
  setHideInactive,
  costsCenters,
  setSelectedCostCenters
}) => {
  const onChangeCheckbox = (event) => {
    const value = event.target.checked;
    setHideInactive(value);
  };

  return (
    <>
      <Row form>
        <Col md={8}>
          <FormGroup>
            <Label htmlFor="searchExtensions" className="small">
              Buscar ramais
            </Label>
            <Input
              type="search"
              name="searchExtensions"
              id="searchExtensions"
              placeholder="Busca pelo número do ramal"
              style={{ height: '38px' }}
              value={textQuery}
              onChange={(e) => setTextQuery(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label htmlFor="costCenter" className="small">
              Centro de custo
            </Label>
            <Select
              isClearable={true}
              options={costsCenters}
              isMulti
              closeMenuOnSelect={false}
              onChange={setSelectedCostCenters}
              styles={customStyles}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row form className="mt--2">
        <Col>
          <label className="small">
            <input
              type="checkbox"
              checked={hideInactive}
              onChange={onChangeCheckbox}
            />{' '}
            Ocultar Ramais Inativos
          </label>
        </Col>
      </Row>
    </>
  );
};

export default ExtensionMonitoring;
