import { useEffect, useState } from 'react';

const useSocketWatingCalls = (socket, nameQueuesToMonit = []) => {
  const [watingCalls, setWatingCalls] = useState([]);

  const isSameCaller = (caller, receivedCaller) => {
    return (
      caller.caller === receivedCaller.caller &&
      caller.queueName === receivedCaller.queueName
    );
  };

  const callerIsOnList = (caller, list) => {
    return list.some((member) => isSameCaller(caller, member));
  };

  useEffect(() => {
    const resetWaitList = () => {
      setWatingCalls([]);
      socket.emit('get_wait_list');
    };

    socket.on('connect', resetWaitList);

    return () => socket.off('connect', resetWaitList);
  }, [socket]);

  useEffect(() => {
    if (nameQueuesToMonit.length === 0) return;

    const addCallerToWaitList = (caller) => {
      const isBeingMonitored = nameQueuesToMonit.includes(caller.queueName);

      if (!isBeingMonitored) return;

      setWatingCalls((currentList) => {
        const alreadyOnList = callerIsOnList(caller, currentList);

        if (alreadyOnList) return currentList;

        return [...currentList, caller];
      });
    };

    socket.on('queue_caller_join', addCallerToWaitList);

    return () => socket.off('queue_caller_join', addCallerToWaitList);
  }, [socket, nameQueuesToMonit]);

  useEffect(() => {
    const removeCallerFromWaitList = (caller) => {
      setWatingCalls((currentList) => {
        if (callerIsOnList(caller, currentList)) {
          return currentList.filter((item) => !isSameCaller(caller, item));
        }

        return currentList;
      });
    };

    socket.on('queue_caller_leave', removeCallerFromWaitList);

    return () => socket.off('queue_caller_leave', removeCallerFromWaitList);
  }, [socket]);

  return { watingCalls };
};

export { useSocketWatingCalls };
