import React from 'react';
import { generateFormattedDate } from './assets/generateFormattedDate';
import { Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import {
  Image,
  ImageContent,
  MessageContent,
  MessageText,
  UserName,
  UserTimestamp
} from './components/styled';
import './style.css';

import { AdminContent } from './components/styledUser';

const SpyMessage = ({
  message = '',
  title = '',
  time = '',
  name = '',
  type = 'text',
  options = '',
  source = ''
}) => {
  const copyToClipBoard = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      toast.info(`Mensagem copiada!`, { autoClose: 1500 });
    } catch (err) {
      toast.error('Erro ao copiar mensagem!', { autoClose: 1500 });
    }
  };

  return (
    <AdminContent>
      <UserName>{name}:</UserName>
      <MessageContent>
        <MessageText
        // onClick={() => copyToClipBoard(title || message || options)}
        >
          {title ||
            (message &&
              (type === 'link' ? (
                <u onClick={() => window.open(message, '_blank')}>{message}</u>
              ) : (
                message
              ))) ||
            options}
          {type === 'file' && (
            <u
              className="g4-link"
              style={{ cursor: 'pointer' }}
              onClick={() => window.open(source, '_blank')}
            >
              {source}
            </u>
          )}
        </MessageText>
        {options && (
          <Badge
            className="user-option"
            color="light"
            title={`Opção da ${name}`}
          >
            Opção
          </Badge>
        )}
        {type === 'image' && (
          <ImageContent>
            <Image
              src={source}
              alt={`imagem de ${name}`}
              onClick={() => window.open(source, '_blank')}
            />
          </ImageContent>
        )}
      </MessageContent>

      <UserTimestamp>{time && generateFormattedDate(time)}</UserTimestamp>
    </AdminContent>
  );
};

export default SpyMessage;
