import ImageService from './service';

const MD5 = require('crypto-js/md5');

export const getProfileImage = async (
  setProfileImage,
  userLogin,
  setIsLoading
) => {
  await ImageService.get(`${MD5(userLogin)}.png`)
    .then((res) => {
      var reader = new window.FileReader();

      reader.readAsDataURL(res.data);
      reader.onload = function () {
        var imageDataUrl = reader.result;
        setProfileImage(imageDataUrl);
        setIsLoading(false);
      };
    })
    .catch(() => {
      setProfileImage(require('assets/img/theme/iconG4.png'));
      setIsLoading(false);
    });
};
