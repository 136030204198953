import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Table,
  FormGroup,
  Label
} from 'reactstrap';
import UserService from '../../User/service';

import ButtonHeader from '../../../components/Headers/MultiButtons';
import QueueService from '../../Queue/service';

const ReasonList = () => {
  const [queues, setQueues] = useState([]);
  const [queuesToMonitor, setQueuesToMonitor] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  useEffect(() => {
    QueueService.getAll()
      .then((res) => {
        const login = localStorage.getItem('login');
        UserService.get(login)
          .then((response) => {
            const getUserQueues = response.data.data.queues;
            const filteredQueues = res.data.data.filter(
              (queue) =>
                getUserQueues.some(
                  (localQueue) => localQueue.id === queue.id
                ) && queue.is_telephony
            );
            setQueues(filteredQueues || []);
          })
          .catch((err) => {
            const message =
              (err.response && err.response.data) || 'Erro ao carregar filas';
            toast.error(message, {
              autoClose: 3000,
              closeOnClick: true
            });
          });
      })
      .catch((err) => {
        const message =
          (err.response && err.response.data) || 'Erro ao carregar filas';
        toast.error(message, {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  }, []);

  const toggleQueueSelection = (queueID) => {
    const alreadySelected = queuesToMonitor.includes(queueID);
    if (!alreadySelected)
      return setQueuesToMonitor([...queuesToMonitor, queueID]);

    const newQueues = queuesToMonitor.filter((id) => id !== queueID);
    return setQueuesToMonitor(newQueues);
  };

  const areAllQueuesSelected = () => {
    return queues.every((queue) => queuesToMonitor.includes(queue.id));
  };

  const toggleSelectAll = () => {
    if (areAllQueuesSelected()) return setQueuesToMonitor([]);

    const idOfAllqueues = queues.map((queue) => queue.id);
    setQueuesToMonitor(idOfAllqueues);
  };

  const getQueuesIdAndName = () => {
    const queuesId = queuesToMonitor.join(',');
    const queueNames = queues
      .filter((queue) => queuesToMonitor.includes(queue.id))
      .map((queue) => queue.name);
    return { queuesId, queueNames };
  };

  const handleOpenOnNewWindow = () => {
    const { queuesId, queueNames } = getQueuesIdAndName();
    window.open(
      `${window.location.origin}/monitoring/queues?queuesId=${queuesId}&queueNames=${queueNames}`,
      '_blank',
      'width=' +
        window.screen.availWidth +
        ',height=' +
        window.screen.availHeight
    );
  };

  const handleOpenOnNewTab = () => {
    const { queuesId, queueNames } = getQueuesIdAndName();
    window.open(
      `${window.location.origin}/monitoring/queues?queuesId=${queuesId}&queueNames=${queueNames}`,
      '_blank'
    );
  };

  return (
    <>
      <ButtonHeader
        size="md"
        buttons={[
          {
            label: 'Monitorar',
            customNavigator: handleOpenOnNewWindow,
            type: 'dropdown',
            title: 'Abre o monitoramento em uma nova janela',
            dropdown: {
              isDropdownOpen,
              setIsDropdownOpen,
              items: [
                {
                  label: 'Abrir em uma nova aba',
                  onClick: handleOpenOnNewTab,
                  title: 'Abre o monitaramento em uma outra aba'
                }
              ]
            }
          }
        ]}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Filas</h3>
                </CardHeader>

                <Row className="text-right mr-2 mt-2">
                  <Col>
                    <FormGroup check>
                      <Label check>
                        <input
                          type="checkbox"
                          onChange={toggleSelectAll}
                          checked={areAllQueuesSelected()}
                        />
                        <span className="small"> Selecionar todas</span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <CardBody>
                  <Table bordered={false} borderless={true} striped>
                    <thead style={{ backgroundColor: '#F6F9FC' }}>
                      <tr>
                        <th>Fila</th>
                        <th>Monitorar</th>
                      </tr>
                    </thead>
                    <tbody>
                      {queues.map((queue) => (
                        <tr key={queue.id}>
                          <td>{queue.name}</td>
                          <td>
                            <FormGroup check>
                              <Label check>
                                <input
                                  type="checkbox"
                                  onChange={() =>
                                    toggleQueueSelection(queue.id)
                                  }
                                  checked={queuesToMonitor.includes(queue.id)}
                                />
                              </Label>
                            </FormGroup>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ReasonList;
