import React, { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import DefaultModal from '../../../../../components/Modal/DefaultModal';
import ProfileService from '../../../service';
import { ErrorMessage } from '@hookform/error-message';

import { Row, Form, Col, Input, Button } from 'reactstrap';

const ConfirmModal = ({
  isModalOpen,
  onModalToggle,
  ProfileCopy,
  getProfiles,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  const onSubmit = async (data) => {
    try {
      const { roles } = ProfileCopy;
      const roleIds = roles.map((rl) => rl.id);
      const dataToSubmit = {
        name: data.name,
        description: ProfileCopy.description,
        roleIds: roleIds
      };
      await ProfileService.create(dataToSubmit);
      getProfiles();
    } catch (err) {
      console.log('err', err);
    } finally {
      closeModal();
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );
  const { control, handleSubmit, errors } = useForm();
  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Confirmação de cópia"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <Row className="mb-3">
            <Col md="12">
              <label className="form-control-label">Novo nome do perfil*</label>
              <Controller
                as={Input}
                control={control}
                name="name"
                rules={{ required: 'Nome é obrigatório!' }}
                defaultValue=""
              />
              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) => errorFormMessage(message)}
              />
            </Col>
          </Row>

          <hr />
          <Button color="primary" type="submit">
            Confirmar cópia
          </Button>
          <Button color="danger" onClick={() => closeModal()}>
            Cancelar
          </Button>
        </Form>
      </>
    </DefaultModal>
  );
};

export default ConfirmModal;
