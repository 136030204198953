import { useCallback, useState } from 'react';

import { defaultLimit } from 'views/CRM/shared/utils/pagination';

import contactService from '../services/contactService';

export const useListContacts = ({ recordTypeId }) => {
  const [contacts, setContacts] = useState();
  const [totalContacts, setTotalContacts] = useState();
  const [isLoadingContacts, setIsLoadingContacts] = useState(false);

  const getContacts = useCallback(
    (page = 1, params = {}) => {
      setIsLoadingContacts(true);

      contactService
        .getContacts(recordTypeId, params, {
          page,
          limit: defaultLimit
        })
        .then((response) => {
          setContacts(response.data.data);
          setTotalContacts(response.data.totalRegisters);
        })
        .finally(() => setIsLoadingContacts(false));
    },
    [recordTypeId]
  );

  const clearContacts = useCallback(() => {
    setContacts(null);
    setTotalContacts(0);
    setIsLoadingContacts(false);
  }, []);

  return {
    contacts,
    totalContacts,
    isLoadingContacts,
    getContacts,
    clearContacts
  };
};
