import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'reactstrap';
import { useSelector } from 'react-redux';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
// core components
const DefaultModal = ({
  show,
  children,
  onModalToggle,
  title = '',
  subtitle = '',
  showCloseButton = true,
  modalContainerClasses = '',
  modalHeaderClasses = '',
  modalBodyClasses = '',
  centered = true,
  backdrop = true,
  closeModal = null,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    onModalToggle(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const toggleModal = () => setOpen(!open);

  return (
    <>
      <Modal
        backdrop={backdrop}
        className={modalContainerClasses}
        isOpen={open}
        toggle={() => toggleModal()}
        centered={centered}
        {...props}
      >
        <div className={`modal-header ${modalHeaderClasses}`}>
          <h6 className="modal-title" id="modal-title-default">
            {title}
            <br />
            {subtitle && <p>{subtitle}</p>}
          </h6>

          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={() => (closeModal ? closeModal() : setOpen(false))}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className={`modal-body ${modalBodyClasses}`}>
          {children}

          {showCloseButton && (
            <div className="modal-footer">
              <Button
                style={buttonStyle(
                  systemConfiguration.cancelation_button_color
                )}
                className="ml-auto"
                data-dismiss="modal"
                type="button"
                onClick={() => toggleModal()}
              >
                Fechar
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default DefaultModal;
