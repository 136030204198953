export const reportOptions = [
  { label: 'Analítico', value: 1 },
  { label: 'Sintético', value: 2 },
  { label: 'Sintético detalhado', value: 3 }
];

export const statusCall = [
  { label: 'Selecione o status das chamadas', value: '' },
  { label: 'Não atendida', value: 'NO ANSWER' },
  { label: 'Atendida', value: 'ANSWERED' }
];

export const groupByOptions = [
  { label: 'Fila', value: 'fila' },
  { label: 'Agente', value: 'agente' },
  { label: 'Data', value: 'data' },
  { label: 'Intervalo', value: 'intervalo' },
  { label: 'Dia da semana', value: 'diaDaSemana' },
  { label: 'Equipe', value: 'nomeEquipe' }
];

export const daysOfWeek = [
  { label: 'Domingo', value: 1 },
  { label: 'Segunda', value: 2 },
  { label: 'Terça', value: 3 },
  { label: 'Quarta', value: 4 },
  { label: 'Quinta', value: 5 },
  { label: 'Sexta', value: 6 },
  { label: 'Sábado', value: 7 }
];
