import React from 'react';
import styled from 'styled-components';
//import { colors } from '../utils';
import { useSelector } from 'react-redux';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid white;
  margin: 0.5rem 0.5rem;
  min-height: 7rem;
  width: 100%;
  ${(props) => props.color && `background-color: ${props.color};`}
  color: #fff;
  border-radius: 0.4rem;
  padding: 0.2rem;
`;

const Title = styled.div`
  text-align: center;
`;

const Value = styled.div`
  font-size: 1.7rem;
`;

const ReportCard = ({ data }) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  return (
    <Container color={systemConfiguration.primary_color}>
      <Title>{data.title}</Title>
      <Value>{data.value}</Value>
    </Container>
  );
};

export default ReportCard;
