import styled from 'styled-components';
import MaskedInput from 'react-text-mask';

export const TelephoneMaskNewStyle = styled(MaskedInput)`
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 5px);
  padding: 0.625rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #8898aa;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  box-shadow: 0 3px 2px rgba(233, 236, 239, 0.05);
  transition: all 0.15s cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &:focus {
    color: #8898aa;
    background-color: #fff;
    border-color: #5e72e4;
    outline: 0;
    box-shadow: 0 3px 9px rgba(50, 50, 9, 0),
      3px 4px 8px rgba(94, 114, 228, 0.1);
  }
`;
