import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback
} from 'react';
import { treeUtil } from 'react-d3-tree';
import { v4 as uuid } from 'uuid';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import IVRService from '../../../service';
import { useNameRederer } from '../util/buildNodeContentAttribute';
import isNodeRoot from '../util/isNodeRoot';

const context_format = {
  treeData: {},
  addNode: () => {},
  removeNode: () => {},
  isModalOpen: false,
  setIsModalOpen: () => {},
  openModal: () => {},
  selectedNode: {},
  onSubmitModalData: () => {},
  rootNodeData: {},
  submitDataToBackend: () => {},
  resetTree: () => {}
};

const TreeContext = createContext(context_format);

export function TreeProvider({ children }) {
  const { buildNodeContentAttribute } = useNameRederer();
  const history = useHistory();
  const [flatDataTree, setFlatDataTree] = useState([]);

  const [treeData, setTreeData] = useState({ name: 'root' });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState({}); // Dados do node a ser alterado no modal
  const [rootNodeData, setRootNodeData] = useState({
    guid: 'root',
    uraOption: '0',
    description: 'Root'
  });

  useEffect(() => {
    if (flatDataTree.length === 0) return setTreeData({ name: 'root' });
    setTreeData(treeUtil.generateHierarchy([...flatDataTree]));
  }, [flatDataTree]);

  useEffect(() => {
    if (!isModalOpen) setSelectedNode({});
  }, [isModalOpen]);

  const addNode = (nodeData) => {
    const new_node_name = uuid();
    const newNode = {
      parent: nodeData.name,
      child: new_node_name,
      attributes: {
        guid: new_node_name,
        parentId: nodeData.name
      }
    };
    const flat_nodes = flatDataTree.map((node) => ({
      parent: node.parent,
      child: node.child,
      attributes: node.attributes
    }));
    return setFlatDataTree([...flat_nodes, newNode]);
  };

  const removeNode = (nodeData) => {
    const node_name = nodeData.name;
    const node_parent_name = nodeData.parent.name;

    const flat_nodes = flatDataTree.map((node) => {
      return {
        parent: node.parent,
        child: node.child,
        attributes: node.attributes
      };
    });

    const flatNodesAfterRemoval = flat_nodes.filter(
      (node) => node.child !== node_name || node.parent !== node_parent_name
    );

    setFlatDataTree(flatNodesAfterRemoval);
  };

  const openModal = (nodeData) => {
    setSelectedNode(nodeData);
    setIsModalOpen(true);
  };

  const saveDataOfRootNode = (data) => {
    setRootNodeData({
      ...rootNodeData,
      ...data,
      contentId: Number(data.contentId),
      content: buildNodeContentAttribute(data.type, data.contentId)
    });
  };

  const getChildren = (nodeName) => {
    const flat_nodes = flatDataTree.map((item) => ({
      attributes: item.attributes,
      child: item.child,
      parent: item.parent
    }));

    return flat_nodes
      .filter((node) => node.parent === nodeName)
      .map((node) => node.child);
  };

  const onSubmitModalData = (data) => {
    if (isNodeRoot(selectedNode)) return saveDataOfRootNode(data);

    const new_flat_nodes = flatDataTree.map((item) => ({
      attributes: item.attributes,
      child: item.child,
      parent: item.parent
    }));

    const [item_to_be_replaced] = new_flat_nodes.filter(
      (node) =>
        node.child === selectedNode.name &&
        node.parent === selectedNode.parent.name
    );
    const item_to_be_replaced_index =
      new_flat_nodes.indexOf(item_to_be_replaced);

    new_flat_nodes[item_to_be_replaced_index].attributes = {
      ...selectedNode.attributes,
      ...data,
      contentId: Number(data.contentId),
      content: buildNodeContentAttribute(data.type, data.contentId)
    };
    setFlatDataTree(new_flat_nodes);
  };

  const isTreeDataValid = (data) => {
    const allNodesFilled = data.nodes.every((node) => node.content);
    return allNodesFilled || 'Não pode haver nós vazios';
  };

  const showToastMessage = (type, message) => {
    return toast[type](message);
  };

  const submitDataToBackend = async (data, isUpdate, id) => {
    const flat_nodes = flatDataTree.map((item) => ({
      attributes: item.attributes || {},
      child: item.child,
      parent: item.parent
    }));

    // Preenche o atributo children[] de cada nó
    flatDataTree.forEach((node, index) => {
      const children = getChildren(node.child);
      flat_nodes[index].attributes.children = children;
    });

    const dataToBackend = {};
    dataToBackend.nodes = flat_nodes.map((node) => node.attributes);

    dataToBackend.name = data.name;
    dataToBackend.schedule_time_id = rootNodeData.schedule_time_id;
    dataToBackend.customized = data.customized || false;
    dataToBackend.disable = false;
    rootNodeData.children = getChildren('root'); // Pega todos os filos do primeiro nó
    dataToBackend.nodes.unshift(rootNodeData);

    const isValid = isTreeDataValid(dataToBackend);

    if (typeof isValid === 'string') return showToastMessage('error', isValid);

    try {
      isUpdate
        ? await IVRService.update(id, dataToBackend)
        : await IVRService.create(dataToBackend);

      showToastMessage('success', 'URA salva com sucesso!');
      history.push('/admin/ivr');
    } catch (err) {
      showToastMessage(
        'error',
        (err.response && err.response.data.message) || 'Erro ao salvar URA'
      );
    }
  };

  const resetTree = useCallback((dataToFillFlatTree, rootNodeData) => {
    setFlatDataTree(dataToFillFlatTree);
    setRootNodeData(rootNodeData);
  }, []);

  return (
    <TreeContext.Provider
      value={{
        treeData,
        addNode,
        removeNode,
        isModalOpen,
        setIsModalOpen,
        openModal,
        selectedNode,
        onSubmitModalData,
        rootNodeData,
        submitDataToBackend,
        resetTree
      }}
    >
      {children}
    </TreeContext.Provider>
  );
}

export function useTree() {
  const context = useContext(TreeContext);
  return context;
}
