import { apiCrm } from '../../../../../http';
import { calculateOffsetFromPageAndLimit } from 'views/CRM/shared/utils/pagination';

class LeadTimeService {
  async getReportLeadTime(
    recordTypeId,
    params = {},
    headers = { page: 1, limit: 10 }
  ) {
    const offset = calculateOffsetFromPageAndLimit(headers.page, headers.limit);
    return apiCrm.get(`lead-time-report/${recordTypeId}`, {
      params,
      headers: { ...headers, offset }
    });
  }

  async getReportSyntheticLeadTime(
    recordTypeId,
    params = {},
    headers = { page: 1, limit: 10 }
  ) {
    const offset = calculateOffsetFromPageAndLimit(headers.page, headers.limit);
    return apiCrm.get(`lead-time-report-synthetic/${recordTypeId}`, {
      params,
      headers: { ...headers, offset }
    });
  }

  async getLeadTimeByLeadId(leadId) {
    return apiCrm.get(`lead-time/${leadId}`);
  }

  async getPdf(params) {
    return apiCrm.get('/report/lead-time/export/pdf', {
      params,
      responseType: 'blob'
    });
  }

  async getXlsx(params) {
    return apiCrm.get('/report/lead-time/export/xlsx', {
      params,
      responseType: 'blob'
    });
  }

  async getCsv(params) {
    return apiCrm.get('/report/lead-time/export/csv', {
      params,
      responseType: 'blob'
    });
  }
}

export default new LeadTimeService();
