import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

const AudioStream = ({
  ReportService,
  uniqueid,
  disabled = false,
  reasonDisabled = '',
  origin = 'telephony',
  stopAudioModal = false
}) => {
  const [playing, setPlaying] = useState(false);
  const [audio, setAudio] = useState(new Audio());
  const [disable, setDisable] = useState(false);
  const [reasonDisable, setReasonDisable] = useState('');

  useEffect(() => {
    if (playing && stopAudioModal) {
      setPlaying(!playing);
      audio.pause();
      audio.currentTime = 0;
    }
  }, [audio, playing, stopAudioModal]);

  useEffect(() => {
    if (uniqueid) {
      setAudio(new Audio(ReportService.streamAudio(uniqueid)));
      setDisable(disabled);
      setReasonDisable(reasonDisabled);
    } else {
      setDisable(true);
      setReasonDisable('Não possui registro');
    }
  }, [uniqueid, disabled, ReportService, reasonDisabled]);

  audio.onended = () => {
    setPlaying(false);
  };

  const playAudio = () => {
    if (audio.readyState !== 0) {
      setPlaying(!playing);
      audio.play();
    } else {
      toast.error('Gravação não encontrada', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const stopAudio = () => {
    if (audio.readyState !== 0) {
      setPlaying(!playing);
      audio.pause();
      audio.currentTime = 0;
    } else {
      toast.error('Gravação não encontrada', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  return (
    <>
      {origin === 'telephony' && (
        <Button
          disabled={disable}
          color={playing ? 'danger' : 'success'}
          size="sm"
          dataplacement="top"
          title={reasonDisable}
          datacontainer="body"
          dataanimation="true"
          onClick={() => (playing ? stopAudio(uniqueid) : playAudio(uniqueid))}
        >
          <i
            className={playing ? 'fas fa-stop-circle' : 'fas fa-play-circle'}
          ></i>
        </Button>
      )}
      {origin !== 'telephony' && (
        <Button
          disabled={disable}
          color={playing ? 'danger' : 'success'}
          size="sm"
          outline
          dataplacement="top"
          title={reasonDisable}
          datacontainer="body"
          dataanimation="true"
          onClick={() => (playing ? stopAudio(uniqueid) : playAudio(uniqueid))}
        >
          <i
            className={playing ? 'fas fa-stop-circle' : 'fas fa-play-circle'}
          ></i>{' '}
          Reproduzir atendimento
        </Button>
      )}
    </>
  );
};

export default AudioStream;
