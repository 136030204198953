import XLSX from 'xlsx';

export function sheetReader(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const { SheetNames, Sheets } = XLSX.read(reader.result, {
        type: 'array',
        codepage: 1200
      });
      const data = XLSX.utils.sheet_to_txt(Sheets[SheetNames[0]], {
        type: 'string'
      });

      return resolve(data);
    };
    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  });
}
