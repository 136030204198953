import { useReducer } from 'react';

const SELECT = 'select';
const SELECT_ALL_ROWS = 'selectAllRows';
const SELECT_ALL_ITEMS = 'selectAllItems';
const UNSELECT_ALL_ITEMS = 'unselectAllItems';

const reducer = (state, { type, payload }) => {
  switch (type) {
    case SELECT: {
      if (!state.isAllItemsSelected) {
        return {
          ...state,
          selectedRows: payload.isSelected
            ? [...state.selectedRows, payload.row]
            : state.selectedRows.filter(({ id }) => id !== payload.row.id),
          isAllRowsSelected: false
        };
      }

      return {
        ...state,
        selectedRows: payload.rows.filter(({ id }) => id !== payload.row.id),
        isAllRowsSelected: false,
        isAllItemsSelected: false
      };
    }

    case SELECT_ALL_ROWS: {
      if (payload.isSelect) {
        return {
          ...state,
          selectedRows: [...state.selectedRows, ...payload.rows],
          isAllRowsSelected: true
        };
      } else {
        return {
          ...state,
          selectedRows: [],
          isAllRowsSelected: false,
          isAllItemsSelected: false
        };
      }
    }

    case SELECT_ALL_ITEMS:
      return {
        ...state,
        selectedRows: payload,
        isAllItemsSelected: true
      };

    case UNSELECT_ALL_ITEMS:
      return {
        ...state,
        selectedRows: [],
        isAllRowsSelected: false,
        isAllItemsSelected: false
      };

    default:
      return;
  }
};

export const useSelect = () => {
  const initialState = {
    selectedRows: [],
    isAllRowsSelected: false,
    isAllItemsSelected: false
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const select = (row, isSelected, rows) => {
    dispatch({
      type: SELECT,
      payload: { isSelected, row, rows }
    });
  };

  const selectAllRows = (isSelect, rows) => {
    dispatch({
      type: SELECT_ALL_ROWS,
      payload: { isSelect, rows }
    });
  };

  const selectAllItems = (items) => {
    dispatch({
      type: SELECT_ALL_ITEMS,
      payload: items
    });
  };

  const unselectAllItems = () => {
    dispatch({ type: UNSELECT_ALL_ITEMS });
  };

  return {
    state,
    select,
    selectAllRows,
    selectAllItems,
    unselectAllItems
  };
};
