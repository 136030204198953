import React, { useState, useEffect } from 'react';
import { Col, Input, Row } from 'reactstrap';
import {
  BsFillImageFill,
  BsCameraVideoFill,
  BsChatDotsFill
} from 'react-icons/bs';
import { AiFillFilePdf } from 'react-icons/ai';

import DefaultModal from 'components/Modal/DefaultModal';
import ReactLoader from 'components/Loader';
import { SingleTemplateModal } from '../SingleTemplateModal';

import { ColTemplate } from './style';
import FlexChatIntegration from 'views/ServicePanel/components/QueueContent/components/Contact/service/FlexChatIntegration';
import { SearchTemplateWhatsapp } from 'views/ServicePanel/components/QueueContent/components/Contact/Function';

const TemplatesModal = ({
  isModalOpen,
  onModalToggle,
  conversation,
  contactId,
  clientId,
  queue,
  ...props
}) => {
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtertemplate, setfiltertemplate] = useState('');

  const [isTemplateModalOpen, setIsTemplateModalOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(undefined);

  const filterarray = templates.filter((template) =>
    SearchTemplateWhatsapp({ inputValue: filtertemplate, template })
  );

  async function getTemplates() {
    try {
      if (!conversation.whatsappOrigem) return setLoading(false);
      const response = await FlexChatIntegration.getContatoAtivoByPhone(
        conversation.whatsappOrigem
      );
      // const response = await ContactService.getTemplates(login);
      const templatesText = response.data.data;

      setTemplates(templatesText);
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    // setLoading(true);
    getTemplates();
  }, []);

  const handleOnClick = (value) => {
    setSelectedTemplate(value);
    setIsTemplateModalOpen(true);
  };

  const Icons = {
    IMAGE: <BsFillImageFill />,
    VIDEO: <BsCameraVideoFill />,
    DOCUMENT: <AiFillFilePdf />,
    TEXT: <BsChatDotsFill />
  };

  const Colors = {
    DOCUMENT: '#d92222d1',
    IMAGE: '#2dce89',
    VIDEO: '#11cdef',
    TEXT: 'rgb(54 54 96)'
  };

  const hasTemplates = !!templates.length;

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Mensagens predefinidas"
      showCloseButton={false}
      modalContainerClasses="modal-lg"
      {...props}
    >
      <Col>
        <Input
          onChange={(event) => {
            setfiltertemplate(event.target.value);
          }}
          placeholder="Buscar Template..."
          style={{ height: '40px' }}
        />
      </Col>
      {loading ? (
        <Row className="justify-content-md-center py-3">
          <ReactLoader />
        </Row>
      ) : (
        <>
          <Row>
            {hasTemplates &&
              filterarray.map((template, templateIndex) => {
                return (
                  <ColTemplate
                    sm="12"
                    onClick={() => {
                      handleOnClick(template);
                    }}
                    color={Colors[template.type]}
                    style={{ cursor: 'pointer' }}
                    key={templateIndex}
                    className={`colTemplateMessageHover${templateIndex}`}
                    title={template.description}
                  >
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'space-between'
                      }}
                    >
                      <h4>{template.name}</h4>

                      {Icons[template.type] ?? '!'}
                    </div>
                  </ColTemplate>
                );
              })}
          </Row>
        </>
      )}

      <SingleTemplateModal
        isModalOpen={isTemplateModalOpen}
        setIsModalOpen={setIsTemplateModalOpen}
        onSendMessage={() => onModalToggle(false)}
        template={selectedTemplate}
        conversation={conversation}
        queue={queue}
        contactId={contactId}
        clientId={clientId}
        clientNumber={conversation.cliente.telefone}
        channelId={conversation.idCanal}
      />
    </DefaultModal>
  );
};

export default TemplatesModal;
