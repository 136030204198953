import React from 'react';
import styled from 'styled-components';
export const ListColorDisplay = ({ color }) => {
  return <ExternalContainer color={color} />;
};

const ExternalContainer = styled.div`
  background-color: ${(props) => props.color};
  width: 0.938rem;
  height: 0.938rem;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;

  &:hover {
    transform: scale(1.1);
  }
`;
