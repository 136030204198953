import React, { useCallback, useEffect, useState } from 'react';
import ConversationService from '../../../../../service';
import { Modal, ModalBody, Button } from 'reactstrap';
import { toISOFormat } from '../../../../../../../utils/fuctions/formattedToISOFormatofdate';
import { TYPES_FILTERS } from './utils/constants';
import { ImHistory } from 'react-icons/im';
import { Form } from './components/Form';
import { Table } from './components/Table';
import { HistoryNotFound } from './components/HistoryNotFound';
import { Option, StyleOption } from '../../styled';
import { useRequestUsers } from 'hooks/useUserContext';

const HistoryConversation = ({
  name,
  tooltipTitle = '',
  id,
  newIcon = false
}) => {
  const { users } = useRequestUsers();
  const [isOpen, setIsOpen] = useState(false);
  const [history, setHistory] = useState([]);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [attendants_with_conversations, set_attendants_with_conversations] =
    useState([]);
  const [selected_search_type, set_selected_search_type] = useState([
    TYPES_FILTERS[0]
  ]);
  const [
    field_value_already_selected_date,
    set_field_value_already_selected_date
  ] = useState(null);
  const [
    field_value_already_selected_attendent,
    set_field_value_already_selected_attendent
  ] = useState();

  const get_history_conversation = useCallback(
    (id, atendente = '', hora_fim_conversa = '') => {
      setIsLoadingRegister(true);
      let formattedDate = '';
      if (hora_fim_conversa !== '') {
        formattedDate = toISOFormat(hora_fim_conversa);

        set_field_value_already_selected_date(
          toISOFormat(hora_fim_conversa, true)
        );
      } else {
        set_field_value_already_selected_attendent(atendente);
      }

      ConversationService.getHistory(id, atendente, formattedDate)
        .then((res) => {
          setHistory(res.data);
          setIsLoadingRegister(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    []
  );

  useEffect(() => {
    if (isOpen) {
      get_history_conversation(id);
    }
  }, [id, isOpen, get_history_conversation]);

  useEffect(() => {
    if (isOpen) {
      const userList = users;
      set_attendants_with_conversations(userList);
    }
  }, [isOpen, users]);

  const onSubmit = async (data) => {
    const attendant = data.atendente;
    const date = data.hora_fim_conversa;
    get_history_conversation(id, attendant, date);
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
    set_selected_search_type(TYPES_FILTERS[0]);
    set_field_value_already_selected_date(null);
    set_field_value_already_selected_attendent();
  };

  return (
    <>
      <Option title={tooltipTitle}>
        {newIcon ? (
          <Button
            onClick={toggleModal}
            style={{ width: '33px', height: '28px' }}
            color="success"
            size="sm"
          >
            <li className="fas fa-comment"></li>
          </Button>
        ) : (
          <Button
            onClick={toggleModal}
            color="link"
            size="sm"
            style={StyleOption}
          >
            <ImHistory size={20} color="#32325d" />
          </Button>
        )}
      </Option>

      <Modal
        className="modal-dialog-centered"
        isOpen={isOpen}
        toggle={toggleModal}
        style={{ width: '80vw', maxWidth: ' 40rem' }}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            {name}
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />
          <Form
            selected_search_type={selected_search_type}
            attendants_with_conversations={attendants_with_conversations}
            set_selected_search_type={set_selected_search_type}
            onSubmit={onSubmit}
            field_value_already_selected_date={
              field_value_already_selected_date
            }
            field_value_already_selected_attendent={
              field_value_already_selected_attendent
            }
          />
          {history.length > 0 && !isLoadingRegister ? (
            <Table history={history} />
          ) : (
            <HistoryNotFound />
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default HistoryConversation;
