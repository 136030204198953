import { useEffect, useState, useMemo, useCallback } from 'react';
import io from 'socket.io-client';

import { extensionEvents } from '../utils/events';

export const useSocketEvents = () => {
  const socket = useMemo(
    () =>
      io(process.env.REACT_APP_PROXY_URL, {
        path: process.env.REACT_APP_AMI_SOCKET_PATH,
        transports: ['websocket'],
        upgrade: false,
        query: { token: localStorage.getItem('token') }
      }),
    []
  );

  const [extensions, setExtensions] = useState([]);

  const extensionAlreadyExists = useCallback(
    (extensionList, extensionNumber) => {
      return extensionList
        .map((ext) => ext.extension)
        .includes(extensionNumber);
    },
    []
  );

  const updateExtensionState = useCallback(
    (newData) => {
      const extentionItemDTO = {
        costCenter:
          newData.agent &&
          newData.agent.centrodecusto &&
          newData.agent.centrodecusto.name,
        date: newData.date,
        extension: newData.extension,
        identifier: newData.agent && newData.agent.identifier,
        isIncomingCall: newData.isIncomingCall || null,
        phoneNumber: newData.caller || '',
        state: newData.state
      };

      setExtensions((prev) => {
        const alreadyExists = extensionAlreadyExists(prev, newData.extension);
        if (!alreadyExists) return prev;

        const newItems = (prev || []).filter(
          (item) => item.extension !== extentionItemDTO.extension
        );

        return [...newItems, extentionItemDTO];
      });
    },
    [extensionAlreadyExists]
  );

  useEffect(() => {
    socket.on('connect', () => {
      console.info('Extension Monitoring Socket Connected!');
    });

    socket.emit(extensionEvents.GET_EXTENSIONS);

    socket.on(extensionEvents.EXTENSIONS, ({ extensions: allExtensions }) => {
      const filterRepeatedExtensions = allExtensions.filter(
        (ext, index, self) =>
          index === self.findIndex((t) => t.extension === ext.extension)
      );
      setExtensions(() => filterRepeatedExtensions);
    });

    return () => {
      if (socket) socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    socket.on(extensionEvents.EXTENSION_UNREGISTERED, (eventData) => {
      updateExtensionState(eventData);
    });
  }, [socket, updateExtensionState]);

  useEffect(() => {
    socket.on(extensionEvents.EXTENSION_AVAILABLE, (eventData) => {
      //console.log('### AVAILABLE ###', eventData);
      updateExtensionState(eventData);
    });
  }, [socket, updateExtensionState]);

  useEffect(() => {
    socket.on(extensionEvents.EXTENSION_RING, (eventData) => {
      //console.log('### RING ###', eventData);
      updateExtensionState(eventData);
    });
  }, [socket, updateExtensionState]);

  useEffect(() => {
    socket.on(extensionEvents.EXTENSION_RINGING, (eventData) => {
      //console.log('### RINGING ###', eventData);
      updateExtensionState(eventData);
    });
  }, [socket, updateExtensionState]);

  const sortExtensions = (extensionList) => {
    return (
      extensionList && extensionList.sort((a, b) => a.extension - b.extension)
    );
  };

  return {
    extensions: sortExtensions(extensions) || []
  };
};
