import { useCallback } from 'react';

export const MEMORIZED_FILTERS_STORAGE_KEY = 'protocol-list-filters';

export const useMemorizeProtocolsFilters = () => {
  const getMemorizedFilters = useCallback(() => {
    const filters = localStorage.getItem(MEMORIZED_FILTERS_STORAGE_KEY);
    if (!filters) return null;
    return JSON.parse(filters);
  }, []);

  const memorizeFilters = useCallback((filters) => {
    const filtersAsString = JSON.stringify(filters);
    localStorage.setItem(MEMORIZED_FILTERS_STORAGE_KEY, filtersAsString);
  }, []);

  const clearMemorizedFilters = useCallback(() => {
    localStorage.removeItem(MEMORIZED_FILTERS_STORAGE_KEY);
  }, []);

  return {
    getMemorizedFilters,
    memorizeFilters,
    clearMemorizedFilters
  };
};
