import ServiceCRUD from '../../../services/ServiceCRUDFLEXCHAT';
import { apiChat as http } from '../../../http';

class ConversaService extends ServiceCRUD {
  constructor() {
    super('conversa');
  }

  getClosed = (id) => {
    return http.get(`${this.path}/encerrada/${id}`);
  };

  getClosedOrInAttendance = (id) => {
    return http.get(`${this.path}/encerrada-ou-atendimento/${id}`);
  }
}

export default new ConversaService();
