import React, { useState, useEffect } from 'react';
import { Card as CardReactStrap } from 'reactstrap';
import { CardHeader } from './CardHeader';
import { CardBody } from './CardBody';
import { getProfileImage } from '../../../../../../../components/Navbars/getProfileImage';

export function Card({ protocol, user, chat, calls, toggleModal, loadImage }) {
  const [image, setImage] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [previewImage, setPreviewImage] = useState(
    require('assets/img/theme/iconG4.png')
  );

  useEffect(() => {
    getProfileImage(setPreviewImage, userLogin, setIsLoading);
  }, []);

  const userLogin = localStorage.getItem('login');

  return (
    <CardReactStrap className="card-profile">
      <CardHeader
        image={image}
        setImage={setImage}
        previewImage={previewImage}
        setPreviewImage={setPreviewImage}
        isLoading={isLoading}
      />
      <CardBody
        protocol={protocol}
        user={user}
        calls={calls}
        chat={chat}
        toggleModal={toggleModal}
        image={image}
        loadImage={loadImage}
      />
    </CardReactStrap>
  );
}
