import React from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';

export const SearchContactButton = ({ onClick, style }) => {
  return (
    <ButtonWrapper onClick={onClick}>
      <FaSearch
        style={{
          fontSize: '1.4rem',
          display: 'inline',
          color: 'rgb(0, 100, 140)',
          cursor: 'pointer',
          marginRight: '0.2rem',
          ...style
        }}
        id="tour4"
        onClick={() => onClick(true)}
      />
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: inline;

  &:hover > svg {
    transform: scale(1.1);
  }
`;
