import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;

  .contentCheckBox {
    display: flex;
    margin-top: 40px;
    ${(props) => props.color && `border-left: 2px solid ${props.color};`}
    align-items: center;
  }

  .chipTime {
    background: #4682b4;
    color: #fff;
    border-radius: 7px;
  }

  .cardTime {
    margin-top: 40px;
    display: flex;
    align-items: center;

    input {
      max-width: 90px;
    }

    p {
      font-size: 12px;
      font-family: sans-serif;
    }
  }

  .contentTime {
    ${(props) => props.color && `border: 1px solid ${props.color};`}
    border-radius: 7px;
    margin-top: 20px;
    padding: 10px;
    font-size: 12px;
  }

  .contentCheckBox p {
    margin-bottom: 1px;
    font-family: sans-serif;
    font-size: 12px;
  }

  .iconTime {
    max-width: 100px;
  }

  .chipTrue {
    background: #00a17c;
    color: #fff;
    border-radius: 7px;
    font-size: 12px;
  }

  .chipFalse {
    background: #ee6363;
    color: #fff;
    border-radius: 7px;
    font-size: 12px;
  }

  .checkbox {
    height: 16px;
    width: 16px;
  }

  .contentMessages {
    font-size: 14px;

    .titleMsg {
      margin-top: 20px;
    }

    label {
      margin-top: 40px;
      margin-bottom: 1px;
    }
  }
`;

export const ChipTime = styled.div`
  //width: 18.75rem;
  display: flex;

  input {
    max-width: 90px;
  }

  p {
    font-size: 12px;
    font-family: sans-serif;
  }

  .chipTime {
    background: #4682b4;
    color: #fff;
    border-radius: 7px;
    margin-bottom: 10px;
  }
`;
