import React, { useState } from 'react';
import {
  CardHeader,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from 'reactstrap';

import './styles.css';

const SelectRecordTypeDropDown = ({
  recordTypes = [],
  selectedRecordTypeName = '',
  handleSelectRecordType
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle
        tag="span"
        className="selected-record-type-name"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        {selectedRecordTypeName}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Selecione</DropdownItem>
        {recordTypes.map((rt) => (
          <DropdownItem
            key={rt.id}
            onClick={() => handleSelectRecordType(rt.id)}
          >
            {rt.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

const ListAccountsHeader = ({
  title = '',
  recordTypes,
  selectedRecordType,
  handleSelectRecordType
}) => {
  const getRecordTypeLabel = () => {
    if (!selectedRecordType) return null;
    return (
      <div className="small">
        <span className="font-weight-bold">Tipo de registro: </span>
        <SelectRecordTypeDropDown
          recordTypes={recordTypes}
          selectedRecordTypeName={selectedRecordType.name || ''}
          handleSelectRecordType={handleSelectRecordType}
        />
      </div>
    );
  };

  return (
    <CardHeader>
      <h3 className="mb-0">{title}</h3>
      {getRecordTypeLabel()}
    </CardHeader>
  );
};

export default ListAccountsHeader;
