export const currentMont = (index) => {
  const arrayMonths = new Array(12);
  arrayMonths[0] = 'Janeiro';
  arrayMonths[1] = 'Fevereiro';
  arrayMonths[2] = 'Março';
  arrayMonths[3] = 'Abril';
  arrayMonths[4] = 'Maio';
  arrayMonths[5] = 'Junho';
  arrayMonths[6] = 'Julho';
  arrayMonths[7] = 'Agosto';
  arrayMonths[8] = 'Setembro';
  arrayMonths[9] = 'Outubro';
  arrayMonths[10] = 'Novembro';
  arrayMonths[11] = 'Dezembro';

  return arrayMonths[index];
};

export const getLabel = (day, month, year) => {
  return `${day}`.padStart(2, '0') + ` ${currentMont(month - 1)}` + ` /${year}`;
};

export const options = {
  indexAxis: 'x',
  responsive: true,
  elements: {
    bar: {
      borderWidth: 1
    }
  },
  legend: {
    display: false
  },
  title: {
    display: true
  }
};
