import React from 'react';
import { Row, Col, Button } from 'reactstrap';

export function UpdatePasswordButton({ toggleModal }) {
  return (
    <Row>
      <Col md="12" className="d-flex justify-content-end">
        <Button color="success" size="sm" onClick={toggleModal}>
          Alterar senha
        </Button>
      </Col>
    </Row>
  );
}
