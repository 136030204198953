import React from 'react';
import { generateFormattedDate } from './assets/generateFormattedDate';
import { Badge } from 'reactstrap';
import { toast } from 'react-toastify';
import { FaFileAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import {
  Image,
  ImageContent,
  MessageContent,
  MessageText,
  UserContent,
  UserName,
  UserTimestamp
} from './components/styled';
import './style.css';

const UserMessage = ({
  message = '',
  title = '',
  time = '',
  name = '',
  type = 'text',
  options = '',
  source = ''
}) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const copyToClipBoard = async (content) => {
    try {
      await navigator.clipboard.writeText(content);
      toast.info(`Mensagem copiada!`, { autoClose: 1500 });
    } catch (err) {
      console.log('error copy => ', err);
      toast.error('Erro ao copiar mensagem!', { autoClose: 1500 });
    }
  };
  return (
    <UserContent color={systemConfiguration.primary_color}>
      <UserName>{name}:</UserName>
      <MessageContent>
        <MessageText
        // onClick={() => copyToClipBoard(title || message || options)}
        >
          {(message && options) ||
            (message &&
              (type === 'link' ? (
                <u onClick={() => window.open(message, '_blank')}>{message}</u>
              ) : (
                message
              ))) ||
            options}

          {type === 'file' && (
            <div
              className="messagePdf"
              onClick={() => window.open(source, '_blank')}
            >
              <FaFileAlt size="24" color="#d9534f" />
              <label style={{ cursor: 'pointer' }}>Clique aqui</label>
            </div>
          )}
        </MessageText>
        {options && (
          <Badge
            className="user-option"
            color="light"
            title={`Opção da ${name}`}
          >
            Opção
          </Badge>
        )}
        {type === 'image' && (
          <ImageContent>
            <Image
              src={source}
              alt={`imagem de ${name}`}
              onClick={() => window.open(source, '_blank')}
            />
          </ImageContent>
        )}
        {type === 'audio' && (
          <ImageContent>
            <audio src={source} controls={true} autoPlay={false} />
          </ImageContent>
        )}
        {type === 'video' && (
          <ImageContent>
            <video
              src={source}
              autoPlay={false}
              controls={true}
              width="100%"
              height="300"
            />
          </ImageContent>
        )}
      </MessageContent>

      <UserTimestamp>{time && generateFormattedDate(time)}</UserTimestamp>
    </UserContent>
  );
};

export default UserMessage;
