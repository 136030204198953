import React, { useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useHistory } from 'react-router-dom';
import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  Input,
  Row
} from 'reactstrap';
import Markdown from 'react-markdown';
import { toast } from 'react-toastify';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';
import ReactLoader from 'components/Loader';
import IntegrationUrlService from '../service';
import jsonToMarkdown from '../utils/jsonToMarkdown';
import { encode, decode } from '../utils/b64';

import {
  StyledCol,
  InputWrapper,
  SendRequestButton,
  ResponseContainer
} from './style';
import { ScreenPrompt } from 'components/ScreenPrompt';

const INTEGRATION_TYPES = {
  THIRD_SYSTEM: 'externo',
  OTHERS: 'outro'
};
const REQUEST_METHODS = {
  GET: 'get'
};
const AUTH_SCHEMES = {
  BASIC: 'Basic',
  BEARER: 'Bearer'
};
const USER_COLS = {
  LOGIN: 'login',
  NAME: 'name',
  PASSWORD: 'password',
  PIN_CODE: 'pin_code',
  AGENT_CODE: 'agent_code',
  NICKNAME: 'nickname',
  INTEGRATION_LOGIN: 'integration_login',
  INTEGRATION_PASSWORD: 'integration_password'
};
const INTEGRATION_TYPE_OPTIONS = [
  { label: 'Tela de Sistemas', value: INTEGRATION_TYPES.THIRD_SYSTEM },
  { label: 'Outras integrações', value: INTEGRATION_TYPES.OTHERS }
];
const REQUEST_METHOD_OPTIONS = [{ label: 'GET', value: REQUEST_METHODS.GET }];
const AUTH_SCHEME_OPTIONS = [
  { label: 'Nenhuma', value: '' },
  { label: 'Básica', value: AUTH_SCHEMES.BASIC },
  { label: 'Token', value: AUTH_SCHEMES.BEARER }
];
const USER_COLS_OPTIONS = [
  { label: 'Usuário do Flexuc', value: USER_COLS.LOGIN },
  { label: 'Nome do usuário no Flexuc', value: USER_COLS.NAME },
  { label: 'Senha do Flexuc', value: USER_COLS.PASSWORD },
  { label: 'Código PIN', value: USER_COLS.PIN_CODE },
  { label: 'Código do agente', value: USER_COLS.AGENT_CODE },
  { label: 'Nickname', value: USER_COLS.NICKNAME },
  { label: 'Usuário de Integração', value: USER_COLS.INTEGRATION_LOGIN },
  { label: 'Senha de Integração', value: USER_COLS.INTEGRATION_PASSWORD }
];

export const IntegrationUrlForm = ({
  match: {
    params: { id }
  }
}) => {
  const [requestConfig, setRequestConfig] = useState({
    url: '',
    method: REQUEST_METHODS.GET,
    headers: {
      accept: 'application/json'
    }
  });
  const [authentication, setAuthentication] = useState('');
  const [authFields, setAuthFields] = useState({});
  const [response, setResponse] = useState(undefined);
  const [requestLoading, setRequestLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const { reset, control, handleSubmit, errors, setValue, watch, formState } =
    useForm();
  const { isDirty } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'params'
  });
  const history = useHistory();
  const integrationType = watch('type');
  const allParamsGlobal = requestConfig.url.match(/({{\d+}})/g) || [];
  const toRemove = fields.reduce((acc, curr, i) => {
    if (allParamsGlobal.some((param) => param === curr.identifier)) return acc;

    return [...acc, i];
  }, []);

  useEffect(() => {
    if (!toRemove.length) return;

    remove(toRemove);
  }, [toRemove]);

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/integrationUrl');
  };

  const sendRequest = async () => {
    setRequestLoading(true);

    try {
      const response = await IntegrationUrlService.request(requestConfig);

      setResponse(response.data);
    } catch (error) {
      const { response } = error;

      setResponse(response.data['config'] ? response.data : response);
    } finally {
      setRequestLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const { name, method, url, type, params } = data;
    const headers = Object.entries(requestConfig.headers).map((e) =>
      e.join(': ')
    );

    try {
      if (isUpdate) {
        await IntegrationUrlService.update(id, {
          name,
          method,
          url,
          headers,
          type,
          params
        });

        toast.success('URL de integração atualizada com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
      } else {
        await IntegrationUrlService.create({
          name,
          method,
          url,
          headers,
          type,
          params
        });

        toast.success('URL de integração cadastrada com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }

      history.push('/admin/integrationUrl');
    } catch (error) {
      toast.error(error.response.data.message || 'Ops, ocorreu um erro!', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  useEffect(() => {
    switch (authentication) {
      case AUTH_SCHEMES.BASIC: {
        const { username, password } = authFields;
        const scheme = AUTH_SCHEMES.BASIC;
        const credentials = encode(`${username}:${password}`);

        setRequestConfig((prev) => ({
          ...prev,
          headers: {
            ...prev.headers,
            authorization: `${scheme} ${credentials}`
          }
        }));

        break;
      }
      case AUTH_SCHEMES.BEARER: {
        const { token } = authFields;
        const scheme = AUTH_SCHEMES.BEARER;

        setRequestConfig((prev) => ({
          ...prev,
          headers: {
            ...prev.headers,
            authorization: `${scheme} ${token}`
          }
        }));

        break;
      }
      default:
        setRequestConfig((prev) => {
          // eslint-disable-next-line no-unused-vars
          const { authorization, ...headers } = prev.headers;

          return {
            ...prev,
            headers
          };
        });

        break;
    }
  }, [authentication, authFields]);

  useEffect(() => {
    const fetchData = async (IntegrationUrlId) => {
      try {
        const {
          data: {
            data: { name, method, url, headers, type, params }
          }
        } = await IntegrationUrlService.get(IntegrationUrlId);
        const serializedParams = params.map(({ identifier, value }) => ({
          identifier,
          value
        }));

        const resetData = { name, method, url, type, params: serializedParams };

        const headersList = headers
          .split(';')
          .map((header) => header.split(': '));
        const [authorizationHeader] = headersList.filter(
          ([header]) => header === 'authorization'
        );

        if (authorizationHeader) {
          const [scheme, parameters] = authorizationHeader[1].split(' ');

          if (scheme === AUTH_SCHEMES.BASIC) {
            const base64DecodedCredentials = decode(parameters);
            const [username, password] = base64DecodedCredentials.split(':');

            resetData['authentication'] = AUTH_SCHEMES.BASIC;
            resetData['username'] = username;
            resetData['password'] = password;

            setAuthentication(AUTH_SCHEMES.BASIC);
            setAuthFields({ username, password });
          }

          if (scheme === AUTH_SCHEMES.BEARER) {
            resetData['authentication'] = AUTH_SCHEMES.BEARER;
            resetData['token'] = parameters;

            setAuthentication(AUTH_SCHEMES.BEARER);
            setAuthFields({ token: parameters });
          }
        }

        setIsUpdate(true);
        setRequestConfig({
          name,
          method,
          url,
          headers: Object.fromEntries(headersList)
        });
        reset(resetData);
      } catch (error) {
        toast.error('Erro ao tentar carregar URL de integração', {
          autoClose: 3000,
          closeOnClick: true
        });
      } finally {
        setLoading(false);
      }
    };

    if (id) {
      setLoading(true);
      fetchData(id);
    }
  }, [id]);

  return (
    <>
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <SimpleHeader
        returnPath="/admin/integrationUrl"
        buttonTitle="Voltar para a página de URLs de integração"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>
                    {isUpdate ? 'Atualização' : 'Cadastro'} de URL de integração
                  </h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col sm="6">
                          <label className="form-control-label">Nome</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{
                              required: 'Nome é obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col sm="6">
                          <label className="form-control-label">Tipo</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={false}
                                dataOptions={INTEGRATION_TYPE_OPTIONS}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={(field, value) => {
                                  setValue(field, value);
                                }}
                                fieldName="type"
                                labelName="label"
                                valueName="value"
                              />
                            )}
                            control={control}
                            name="type"
                            defaultValue={INTEGRATION_TYPES.THIRD_SYSTEM}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col sm="2">
                          <label className="form-control-label">Método</label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={false}
                                dataOptions={REQUEST_METHOD_OPTIONS}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={(field, value) => {
                                  setValue(field, value);
                                  setRequestConfig({
                                    ...requestConfig,
                                    method: value
                                  });
                                }}
                                fieldName="method"
                                labelName="label"
                                valueName="value"
                                isDisabled={
                                  integrationType ===
                                  INTEGRATION_TYPES.THIRD_SYSTEM
                                }
                              />
                            )}
                            control={control}
                            name="method"
                            defaultValue={requestConfig.method}
                          />
                        </Col>
                        <StyledCol sm="10">
                          <InputWrapper style={{ width: '100%' }}>
                            <label className="form-control-label">
                              URL de requisição
                            </label>
                            <Controller
                              control={control}
                              render={({ onChange, value }) => (
                                <Input
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                    setRequestConfig({
                                      ...requestConfig,
                                      url: e.target.value
                                    });

                                    const allParams =
                                      e.target.value.match(/({{\d+}})/g) || [];

                                    allParams.reduce((acc, curr) => {
                                      const isParamAlreadyDeclared =
                                        fields.some(
                                          (field) => field.identifier === curr
                                        );

                                      if (isParamAlreadyDeclared) return;

                                      // console.log({
                                      //   identifier: curr,
                                      //   value: ''
                                      // });
                                      append({
                                        identifier: curr,
                                        value: ''
                                      });
                                    }, []);
                                  }}
                                />
                              )}
                              name="url"
                              rules={{
                                required: 'URL de requisição é obrigatório'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="url"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </InputWrapper>
                          <SendRequestButton
                            style={{
                              backgroundColor: '#2dce89',
                              border: '1px solid #2dce89',
                              color: 'white'
                            }}
                            onClick={() => sendRequest()}
                            disabled={
                              integrationType === INTEGRATION_TYPES.THIRD_SYSTEM
                            }
                          >
                            Enviar
                          </SendRequestButton>
                        </StyledCol>
                      </Row>
                      {integrationType === INTEGRATION_TYPES.THIRD_SYSTEM ? (
                        <Row>
                          <Col md="12">
                            <label className="form-control-label mb-0">
                              Parâmetros
                            </label>
                          </Col>
                          <Col md="12">
                            {fields.map((field, i) => (
                              <Row className="my-2" key={field.id}>
                                <Col
                                  md="1"
                                  className="d-flex flex-column justify-content-center text-center pl-3"
                                >
                                  {field.identifier}
                                  <Controller
                                    control={control}
                                    render={(props) => (
                                      <Input {...props} hidden />
                                    )}
                                    name={`params.${i}.identifier`}
                                    defaultValue={field.identifier}
                                  />
                                </Col>
                                <Col md="5">
                                  <Controller
                                    control={control}
                                    render={(props) => (
                                      <FlexSelect
                                        isClearable={false}
                                        dataOptions={USER_COLS_OPTIONS}
                                        isMulti={false}
                                        closeMenuOnSelect={true}
                                        value={props.value}
                                        valueController={(field, value) => {
                                          setValue(field, value);
                                        }}
                                        fieldName={props.name}
                                        labelName="label"
                                        valueName="value"
                                        placeholder="Selecione um campo"
                                      />
                                    )}
                                    name={`params.${i}.value`}
                                    defaultValue={field.value}
                                    rules={{
                                      required:
                                        'Escolha um campo para o parâmetro'
                                    }}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name={`params.${i}.value`}
                                    render={({ message }) =>
                                      errorFormMessage(message)
                                    }
                                  />
                                </Col>
                              </Row>
                            ))}
                          </Col>
                        </Row>
                      ) : (
                        <>
                          <Row className="mb-3">
                            <Col sm="12">
                              <label className="form-control-label">
                                Autenticação
                              </label>
                              <Controller
                                control={control}
                                render={(props) => (
                                  <FlexSelect
                                    isClearable={false}
                                    dataOptions={AUTH_SCHEME_OPTIONS}
                                    isMulti={false}
                                    closeMenuOnSelect={true}
                                    value={props.value}
                                    valueController={(field, value) => {
                                      setValue(field, value);
                                      setAuthentication(value);
                                      setAuthFields({});
                                    }}
                                    fieldName="authentication"
                                    labelName="label"
                                    valueName="value"
                                  />
                                )}
                                name="authentication"
                                defaultValue={authentication}
                              />
                            </Col>
                          </Row>
                          {(() => {
                            switch (authentication) {
                              case AUTH_SCHEMES.BASIC:
                                return (
                                  <Row className="mb-3">
                                    <Col sm="6">
                                      <label className="form-control-label">
                                        Usuário
                                      </label>
                                      <Controller
                                        control={control}
                                        render={({ onChange, value }) => (
                                          <Input
                                            value={value}
                                            onChange={(e) => {
                                              onChange(e);
                                              setAuthFields({
                                                ...authFields,
                                                username: e.target.value
                                              });
                                            }}
                                          />
                                        )}
                                        name="username"
                                        rules={{
                                          required: 'Usuário é obrigatório'
                                        }}
                                        defaultValue=""
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        name="username"
                                        render={({ message }) =>
                                          errorFormMessage(message)
                                        }
                                      />
                                    </Col>
                                    <Col sm="6">
                                      <label className="form-control-label">
                                        Senha
                                      </label>
                                      <Controller
                                        control={control}
                                        render={({ onChange, value }) => (
                                          <Input
                                            value={value}
                                            onChange={(e) => {
                                              onChange(e);
                                              setAuthFields({
                                                ...authFields,
                                                password: e.target.value
                                              });
                                            }}
                                          />
                                        )}
                                        name="password"
                                        rules={{
                                          required: 'Usuário é obrigatório'
                                        }}
                                        defaultValue=""
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        name="password"
                                        render={({ message }) =>
                                          errorFormMessage(message)
                                        }
                                      />
                                    </Col>
                                  </Row>
                                );

                              case AUTH_SCHEMES.BEARER:
                                return (
                                  <Row className="mb-3">
                                    <Col sm="12">
                                      <label className="form-control-label">
                                        Token de acesso
                                      </label>
                                      <Controller
                                        control={control}
                                        render={({ onChange, value }) => (
                                          <Input
                                            value={value}
                                            onChange={(e) => {
                                              onChange(e);
                                              setAuthFields({
                                                ...authFields,
                                                token: e.target.value
                                              });
                                            }}
                                          />
                                        )}
                                        name="token"
                                        rules={{
                                          required: 'Token é obrigatório'
                                        }}
                                        defaultValue=""
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        name="token"
                                        render={({ message }) =>
                                          errorFormMessage(message)
                                        }
                                      />
                                    </Col>
                                  </Row>
                                );

                              default:
                                return null;
                            }
                          })()}
                          <Row className="mb-3">
                            <Col sm="12">
                              <div>
                                <label className="form-control-label">
                                  Resposta
                                </label>
                                {response && (
                                  <Badge
                                    style={{ marginLeft: '0.5rem' }}
                                    color={(() => {
                                      const responseStatusCode =
                                        response.status;
                                      const isSucess =
                                        responseStatusCode >= 200 &&
                                        responseStatusCode <= 299;
                                      const isClientError =
                                        responseStatusCode >= 400 &&
                                        responseStatusCode <= 499;

                                      if (isSucess) {
                                        return 'success';
                                      }

                                      if (isClientError) {
                                        return 'warning';
                                      }

                                      return 'danger';
                                    })()}
                                  >
                                    {response.status} {response.statusText}
                                  </Badge>
                                )}
                              </div>
                              <ResponseContainer
                                center={!response || requestLoading}
                              >
                                {(() => {
                                  if (requestLoading) {
                                    return (
                                      <Row className="justify-content-md-center">
                                        <ReactLoader />
                                      </Row>
                                    );
                                  }

                                  if (!response) {
                                    return <p>Envie uma requisição</p>;
                                  }

                                  return (
                                    <Markdown>
                                      {jsonToMarkdown(response.data)}
                                    </Markdown>
                                  );
                                })()}
                              </ResponseContainer>
                            </Col>
                          </Row>
                        </>
                      )}
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>
                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};
