import React, { useState, useEffect } from 'react';
import SimpleHeaderExtraHour from 'components/Headers/SimpleHeaderExtraHour';
//import { useHistory } from 'react-router-dom';
//import { brazilian } from '../../../assets/translator/pt-br';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'components/Pagination';
import { toast } from 'react-toastify';
import OverTimesService from '../service';
import {
  Container,
  Row,
  Card,
  Button,
  CardHeader,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  FormGroup,
  Label
} from 'reactstrap';
import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../assets/styles/table_custom_style';
import ModalExtraHour from '../components/ModalExtraHour';
import ReactLoader from 'components/Loader';
import ModalConfirmation from '../components/ModalConfirmation/';
import { useSelector } from 'react-redux';
import { IconLogoHeader } from '../../Scales/style/style';
import { IoIosHourglass } from 'react-icons/io';

export default function ExtraHourList() {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const login = localStorage.getItem('login');
  const [extraHour, setExtraHour] = useState([]);
  const [id, setIds] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [timeoutID, setTimeoutID] = useState(null); // Timeout da busco por texto
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showDisabled, setShowDisable] = useState(false);
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [idResource, setIdResource] = useState();

  async function loadApi(page = 1, querySearch = '') {
    setLoading(true);
    const size = 10;
    OverTimesService.getAllSearch(
      showDisabled,
      size,
      page,
      querySearch,
      login
    ).then((response) => {
      setQuantity(response.data.totalRegisters);
      const data = response.data.data;
      setExtraHour(data);
      setLoading(false);
    });
  }
  useEffect(() => {
    loadApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showDisabled]);

  const handleOnSearch = (event) => {
    if (typeof timeoutID === 'number') {
      clearTimeout(timeoutID);
    }
    const q = event.target.value;
    setTimeoutID(
      setTimeout(() => {
        loadApi(1, q);
      }, 1000)
    );
  };
  const handlePageChange = (page) => {
    setActivePage(page);
    loadApi(page);
  };
  const toISOFormat = (dateNotFormmated) => {
    if (dateNotFormmated === undefined) {
      console.log('A data está vazia');
    } else {
      const [date] = dateNotFormmated.split('T');
      const [YYYY, MM, DD] = date.split('-');
      return `${DD}/${MM}/${YYYY}`;
    }
  };

  function controlDeleteResource(idResouce) {
    setIdResource(idResouce);
    setOpenModalConfirmation(true);
  }
  function deleteExtraHour(id) {
    OverTimesService.delete(id)
      .then(() => {
        toast.success('Hora extra excluída com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
        loadApi();
      })
      .catch((err) => {
        toast.error(
          err.response.data.message || 'Erro ao deletar a hora extra!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      })
      .finally(() => {
        setOpenModalConfirmation(false);
        loadApi();
      });
  }

  return (
    <>
      <SimpleHeaderExtraHour
        setCreate="/admin/Scales/form"
        name="Form validation"
        parentName="Forms"
        loadApi={loadApi}
      />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '1rem',
                    alignItems: 'center'
                  }}
                >
                  <IconLogoHeader
                    backgroundColor={systemConfiguration.primary_color}
                  >
                    <IoIosHourglass color="white" size={23} />
                  </IconLogoHeader>
                  <h3>Hora Extra</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Buscar Hora Extra"
                          type="text"
                          onChange={handleOnSearch}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="4" className="text-right mt-2 ">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={showDisabled}
                            onChange={() => setShowDisable(!showDisabled)}
                          />
                          <span>Mostrar desabilitados</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={extraHour}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'date',
                      text: 'Data',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        const response = toISOFormat(row.date);
                        return response;
                      }
                    },
                    {
                      dataField: 'timeInMinutes',
                      text: 'Tempo(Minutos)',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'status',
                      text: 'Status',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (_, row) => {
                        const objectTest = {
                          PENDING: 'Pendente',
                          APPROVED: 'Aprovado',
                          REJECTED: 'Rejeitado',
                          EXECUTED: 'Executado',
                          FINISHED: 'Finalizado',
                          CANCELED: 'Cancelado'
                        };
                        return <>{objectTest[row.status]}</>;
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        const editisDisabled = row.status !== 'PENDING';
                        return (
                          <Button
                            color="info"
                            size="sm"
                            title={'Editar Perfil'}
                            disabled={editisDisabled}
                            onClick={() => {
                              setIsOpen(!isOpen);
                              setIds(row.id);
                            }}
                          >
                            <li className="fas fa-clone"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'delete',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'deletar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        const editisDisabled = row.status !== 'PENDING';
                        return (
                          <Button
                            color="danger"
                            size="sm"
                            title={'Excluir perfil'}
                            disabled={editisDisabled}
                            onClick={() => controlDeleteResource(row.id)}
                          >
                            <li className="fas fa-trash"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
      <ModalExtraHour
        isModalOpen={isOpen}
        onModalToggle={setIsOpen}
        reloadfunction={loadApi}
        id={id}
      />
      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idResource}
        deleteResource={deleteExtraHour}
        message={'Tem certeza que deseja excluir a hora extra?'}
      />
    </>
  );
}
