import React, { useCallback } from 'react';
import { useHistory } from 'react-router';

import { toBrazilianFullDate } from 'views/CRM/shared/utils/date';

import './styles.css';

const AccountProtocols = ({ protocols }) => {
  const history = useHistory();

  const handleOnProtocolNameClick = useCallback(
    ({ protocolId, recordTypeId }) => {
      history.push(`/admin/protocol/${protocolId}/update`, { recordTypeId });
    },
    [history]
  );

  if (!protocols || !protocols.length) return null;

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          flexWrap: 'wrap'
        }}
      >
        {protocols.map((protocol) => (
          <div
            key={protocol.id}
            style={{
              position: 'relative',
              border: '1px solid lightgray',
              borderRadius: '0.1rem',
              borderTop: '0.15rem solid gray',
              padding: '0.8rem',
              width: '24%',
              overflow: 'hidden',
              backgroundColor: '#fff'
            }}
          >
            <div>
              <span
                className="link-to-protocol-details"
                onClick={() =>
                  handleOnProtocolNameClick({
                    recordTypeId: protocol.recordTypeId,
                    protocolId: protocol.id
                  })
                }
                title={protocol.title || ''}
              >
                {protocol.number}
              </span>
            </div>
            <div className="small">
              <span>
                <span className="font-weight-bold">Criado em: </span>
                {toBrazilianFullDate(protocol.creationDate)}
              </span>
            </div>
            <div className="small">
              <span className="font-weight-bold">Responsável: </span>
              <span>{protocol.responsibleName || ''}</span>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default AccountProtocols;
