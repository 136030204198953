export const reportOptions = [
  { label: 'Analítico', value: 1 },
  { label: 'Sintético', value: 2 },
  { label: 'Sintético detalhado', value: 3 }
];

export const StatusCall = [
  { label: 'Selecione o status das chamadas', value: '' },
  { label: 'Não atendida', value: 'NO ANSWER' },
  { label: 'Atendida', value: 'ANSWERED' }
];

export const Groupings = [
  { label: 'Fila', value: 'fila' },
  { label: 'Agente', value: 'agente' },
  { label: 'Data', value: 'data' },
  { label: 'Intervalo', value: 'intervalo' }
];
