import React from 'react';
import { TelephoneMaskNewStyle } from './customInputStyles/telephoneStyled';

const TelephoneMask = (props) => {
  const onChange = (event) => {
    props.valueController(props.fieldName, event.target.value);
    telephoneMask(event.target.value);
  };

  const getInputValue = (value) => {
    return value;
  };

  const telephoneMask = (event) => {
    let phoneFormated = event.replace(/\D/g, '');
    phoneFormated = phoneFormated.replace(/^0/, '');
    if (phoneFormated.length < 11) {
      return [
        '(',
        /[0-9]/,
        /[0-9]/,
        ')',
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/
      ];
    } else {
      return [
        '(',
        /[0-9]/,
        /[0-9]/,
        ')',
        ' ',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        '-',
        /[0-9]/,
        /[0-9]/,
        /[0-9]/,
        /[0-9]/
      ];
    }
  };

  return (
    <TelephoneMaskNewStyle
      mask={props.mask ? props.mask : telephoneMask}
      value={getInputValue(props.value)}
      onChange={(event) => onChange(event)}
      onInput={(event) => onChange(event)}
      placeholder={props.placeholder}
      tabIndex={props.tabIndex ? props.tabIndex : 0}
      className="form-control"
      disabled={props.shoudlDisable}
      style={{
        width: '100%',
        height: '46px',
        fontWeight: '500',
        color: 'gray'
      }}
    />
  );
};

export default TelephoneMask;
