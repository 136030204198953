import React, { useState, useEffect, useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import InboundRouteService from '../service';
import ScheduleTimeTable from './outOfHoursScheduleTable';
import ModalForm from './modal';
import { Regex } from './utils/regex';

import {
  fetchContexts,
  fetchAudios,
  fetchCallbacks,
  fetchQueues,
  fetchExtensions,
  fetchIVR,
  fetchSchedules
} from './asyncDataGetters';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  Button,
  CardBody
} from 'reactstrap';

import { inboundRouteTypes } from './inboundRouteTypes';
import optionsWorkHoursInput from './optionsWorkHoursInput';
import ReactButton from 'components/Buttons/ReactButton';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { ScreenPrompt } from 'components/ScreenPrompt';
import extensionService from 'views/ChiefSecretary/service/extensionService';

const InboundRouteForm = (props) => {
  const history = useHistory();

  const { reset, control, handleSubmit, errors, watch, formState } = useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const [outsideHours, setOutsideHours] = useState({}); // Dados do modal

  const [contexts, setContexts] = useState([]);
  const [audios, setAudios] = useState([]);
  const [callbacks, setCallbacks] = useState([]);
  const [queues, setQueues] = useState([]);
  const [extensions, setExtensions] = useState([]);
  const [IVRs, setIVRs] = useState([]);
  const [timeSchedules, setTimeSchedules] = useState([]);
  const [loadingRamal, setLoadingRamal] = useState(false);

  const typeOnForm = watch('work_hours_input.type');

  const [currentType, setCurrentType] = useState(inboundRouteTypes.AUDIO);

  useEffect(() => {
    if (
      !(currentType === inboundRouteTypes.EXTENSION) ||
      extensions.length > 0
    ) {
      return;
    }
    setLoadingRamal(true);
    extensionService
      .getAllSimple()
      .then((response) => {
        setExtensions(response.data.data);
        setLoadingRamal(false);
      })
      .catch((err) => {
        console.log('err: ' + err);
        toast.error('Erro ao listar ramais');
        setLoadingRamal(false);
      });
  }, [currentType]);

  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const { id } = props.match.params;

  useEffect(() => {
    Promise.all([
      fetchContexts(),
      fetchAudios(),
      fetchCallbacks(),
      fetchQueues(),
      fetchIVR(),
      fetchSchedules()
    ])
      .then((results) => {
        setContexts(results[0]);
        setAudios(results[1]);
        setCallbacks(results[2]);
        setQueues(results[3]);
        setIVRs(results[4]);
        setTimeSchedules(results[5]);
      })
      .catch((err) => {
        console.log('Erro on Promise.all', err);
      });
  }, []);

  const formatterForUpdate = useCallback(
    (data) => {
      const [inbound_route] = data.inputs.filter((item) => !item.schedule);
      reset({
        context_id: data.context_id,
        route: String(data.route),
        work_hours_input: inbound_route
      });
      const [outside_route] = data.inputs.filter((item) => item.schedule);
      if (outside_route) {
        setOutsideHours({
          outside_hours_input: outside_route
        });
      }
    },
    [reset]
  );

  useEffect(() => {
    if (id) {
      InboundRouteService.get(id)
        .then((res) => {
          formatterForUpdate(res.data.data);

          setIsUpdate(true);
        })
        .catch(() => {
          toast.error('Erro ao carregar rota de entrada', {
            autoClose: 3000,
            closeOnClick: true
          });
          history.replace('/admin/inboundRoute');
        });
    }
  }, [id, reset, history, formatterForUpdate]);

  useEffect(() => {
    setCurrentType(typeOnForm);
  }, [typeOnForm]);

  const handleRemoveOutsideHours = useCallback(() => {
    setOutsideHours({});
  }, []);

  const onSubmit = async (data) => {
    try {
      const formatedData = { ...data, ...outsideHours };
      isUpdate
        ? await InboundRouteService.update(id, formatedData)
        : await InboundRouteService.create(formatedData);
      toast.success('Rota de entrada salva!', {
        autoClose: 3000,
        closeOnClick: true
      });
    } catch (err) {
      console.log('err', err);
      const { message } = err.response.data;
      toast.error(message, {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      history.push('/admin/inboundRoute/');
    }
  };

  const renderOutsideHoursTableData = (data) => {
    console.log(data);
    if (!data) return '';
    const key = `${data.type.toLowerCase()}_id`;

    if (data.type === 'IVR')
      return IVRs.length === 0
        ? ''
        : IVRs.find((el) => el.id === Number(data[key])).name;

    if (data.type === 'AUDIO')
      return audios.length === 0
        ? ''
        : audios.find((el) => el.id === Number(data[key])).name;

    if (data.type === 'CALLBACK')
      return callbacks.length === 0
        ? ''
        : callbacks.find((el) => el.id === Number(data[key])).name;

    if (data.type === 'QUEUE')
      return queues.length === 0
        ? ''
        : queues.find((el) => el.id === Number(data[key])).name;

    if (data.type === 'EXTENSION') {
      return extensions.length === 0
        ? ''
        : extensions.find((el) => el.extension_number === data[key])
            .extension_number;
    }
  };

  const renderScheduleTimeName = () => {
    if (Object.keys(outsideHours).length === 0) return '';
    return timeSchedules.length === 0
      ? ''
      : timeSchedules.find(
          (el) =>
            el.id === Number(outsideHours.outside_hours_input.schedule_time_id)
        ).name;
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/inboundRoute');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/inboundRoute"
        buttonTitle="Voltar para a pagina de rotas de entrada"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting}
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">{`${
                    id ? 'Editar' : 'Adicionar'
                  } Rota de Entrada`}</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">Rota*</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="route"
                          rules={{
                            required: 'Campo obrigatório',
                            pattern: {
                              value: Regex,
                              message: 'Formato inválido de rota'
                            }
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="route"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col md="6">
                        <label className="form-control-label">Contexto*</label>
                        <Controller
                          name="context_id"
                          defaultValue=""
                          control={control}
                          rules={{ required: 'Selecione um contexto' }}
                          as={
                            <Input type="select">
                              <option value="">Selecione um contexto</option>
                              {contexts &&
                                contexts.map((context, index) => (
                                  <option key={index} value={context.id}>
                                    {context.name}
                                  </option>
                                ))}
                            </Input>
                          }
                        />
                        <ErrorMessage
                          errors={errors}
                          name="context_id"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col md="6">
                        <label className="form-control-label">Tipo*</label>
                        <Controller
                          control={control}
                          defaultValue="AUDIO"
                          name="work_hours_input.type"
                          rules={{ required: 'Campo obrigatório' }}
                          as={
                            <Input type="select">
                              {optionsWorkHoursInput.map((option, index) => (
                                <option key={index} value={option.value}>
                                  {option.displayName}
                                </option>
                              ))}
                            </Input>
                          }
                        />
                        <ErrorMessage
                          errors={errors}
                          name="work_hours_input.type"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>

                      {currentType === inboundRouteTypes.IVR && (
                        <Col md="6">
                          <label className="form-control-label">URA*</label>
                          <Controller
                            defaultValue=""
                            control={control}
                            name="work_hours_input.ivr_id"
                            rules={{ required: 'Selecione uma URA' }}
                            as={
                              <Input type="select">
                                <option value="">Selecione uma URA</option>
                                {IVRs &&
                                  IVRs.map((ivr) => (
                                    <option key={ivr.id} value={ivr.id}>
                                      {ivr.name}
                                    </option>
                                  ))}
                              </Input>
                            }
                          />
                          <ErrorMessage
                            errors={errors}
                            name="work_hours_input.ivr_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      )}

                      {currentType === inboundRouteTypes.AUDIO && (
                        <Col md="6">
                          <label className="form-control-label">Áudio*</label>
                          <Controller
                            defaultValue=""
                            control={control}
                            name="work_hours_input.audio_id"
                            rules={{ required: 'Campo obrigatório' }}
                            as={
                              <Input type="select">
                                <option value="">Selecione um áudio</option>
                                {audios &&
                                  audios.map((audio) => (
                                    <option key={audio.id} value={audio.id}>
                                      {audio.name}
                                    </option>
                                  ))}
                              </Input>
                            }
                          />
                          <ErrorMessage
                            errors={errors}
                            name="work_hours_input.audio_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      )}

                      {currentType === inboundRouteTypes.CALLBACK && (
                        <Col md="6">
                          <label className="form-control-label">
                            Callback*
                          </label>
                          <Controller
                            defaultValue=""
                            control={control}
                            name="work_hours_input.callback_id"
                            rules={{ required: 'Selecione a callback' }}
                            as={
                              <Input type="select">
                                <option value="">Selecione uma callback</option>
                                {callbacks &&
                                  callbacks.map((callback) => (
                                    <option
                                      key={callback.id}
                                      value={callback.id}
                                    >
                                      {callback.name}
                                    </option>
                                  ))}
                              </Input>
                            }
                          />
                          <ErrorMessage
                            errors={errors}
                            name="work_hours_input.callback_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      )}

                      {currentType === inboundRouteTypes.QUEUE && (
                        <Col md="6">
                          <label className="form-control-label">Fila*</label>
                          <Controller
                            defaultValue=""
                            control={control}
                            name="work_hours_input.queue_id"
                            rules={{ required: 'Selecione uma fila' }}
                            as={
                              <Input type="select">
                                <option value="">Selecione uma fila</option>
                                {queues &&
                                  queues.map((queue) => (
                                    <option key={queue.id} value={queue.id}>
                                      {queue.name}
                                    </option>
                                  ))}
                              </Input>
                            }
                          />
                          <ErrorMessage
                            errors={errors}
                            name="work_hours_input.queue_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      )}

                      {currentType === inboundRouteTypes.EXTENSION && (
                        <Col md="6">
                          <label className="form-control-label">Ramal*</label>
                          <Controller
                            defaultValue=""
                            control={control}
                            name="work_hours_input.extension_id"
                            rules={{ required: 'Selecione um Ramal' }}
                            as={
                              <Input disabled={loadingRamal} type="select">
                                <option value="">
                                  {' '}
                                  {loadingRamal
                                    ? 'Carregando opções...'
                                    : 'Selecione um Ramal'}
                                </option>
                                {extensions &&
                                  extensions.map((extension) => (
                                    <option
                                      key={extension.extension_number}
                                      value={extension.extension_number}
                                    >
                                      {extension.extension_number}
                                    </option>
                                  ))}
                              </Input>
                            }
                          />
                          <ErrorMessage
                            errors={errors}
                            name="work_hours_input.extension_id"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      )}
                    </Row>

                    <Row className="mb-3">
                      <Col md="6">
                        <Button
                          style={buttonStyle(systemConfiguration.primary_color)}
                          onClick={() => setIsOpenModal(true)}
                        >
                          {Object.keys(outsideHours).length === 0
                            ? 'Adicionar'
                            : 'Editar'}{' '}
                          Programação de Horário
                        </Button>
                      </Col>
                    </Row>

                    {/* Tabela de programação de horário */}
                    {Object.keys(outsideHours).length > 0 &&
                      outsideHours.outside_hours_input && (
                        <Row className="mb-3 mt-5">
                          <Col sm="12">
                            <ScheduleTimeTable
                              item={{
                                type: outsideHours.outside_hours_input
                                  ? outsideHours.outside_hours_input.type
                                  : '',
                                input: renderOutsideHoursTableData(
                                  outsideHours.outside_hours_input
                                ),
                                schedule: renderScheduleTimeName()
                              }}
                              handleRemoveItem={handleRemoveOutsideHours}
                            />
                          </Col>
                        </Row>
                      )}

                    <hr />
                    <ReactButton btnColor="confirmation" type="submit">
                      Salvar
                    </ReactButton>
                    <ReactButton
                      btnColor="cancelation"
                      onClick={() => cancelAction()}
                    >
                      Cancelar
                    </ReactButton>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>

      <ModalForm
        isOpenModal={isOpenModal}
        onModalToggle={setIsOpenModal}
        IVRs={IVRs}
        audios={audios}
        callbacks={callbacks}
        queues={queues}
        extensions={extensions}
        timeSchedules={timeSchedules}
        setterFunction={setOutsideHours}
        isUpdate={isUpdate}
        dataToUpdate={outsideHours}
      />
    </>
  );
};

export default InboundRouteForm;
