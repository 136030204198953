import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getFlexSelectCustomStyle } from '../../../../../assets/styles/multiple_select';

const FlexSelectCalenderCHA = ({
  dataOptions,
  value,
  fieldName,
  placeholder = 'Selecione...',
  valueController,
  isMulti = false,
  labelName,
  valueName,
  closeMenuOnSelect = false,
  isClearable = false,
  isSearchable = true,
  isDisabled = false,
  minHeight = '',
  minWidth = '',
  requestTableAvulsa = () => {},
  // eslint-disable-next-line no-unused-vars
  ...props
}) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    let arrayOptions = [];
    dataOptions.forEach((option) => {
      arrayOptions.push({
        label: option[labelName],
        value: option[valueName]
      });
    });
    setOptions([...arrayOptions]);
  }, [dataOptions, labelName, valueName]);

  const getSelectValues = (values) => {
    // console.log('erererr', values);
    if (isMulti) {
      let capsule = [];
      values.length > 0 &&
        values.forEach((el) => {
          dataOptions.forEach((option) => {
            if (option[valueName] === el)
              capsule.push({
                label: option[labelName],
                value: option[valueName]
              });
          });
        });
      return capsule;
    } else {
      let capsule = [];
      dataOptions.forEach((option) => {
        if (option[valueName] === values)
          capsule.push({
            label: option[labelName],
            value: option[valueName]
          });
      });
      return capsule;
    }
  };

  const controlChange = (values) => {
    if (isMulti) {
      if (values && values.length > 0) {
        const a = values.map((l) => l.value);
        return valueController(fieldName, a);
      }
      return valueController(fieldName, []);
    } else {
      if (values) {
        return valueController(fieldName, values.value);
      }
      return valueController(fieldName, '');
    }
  };
  const controlFunction = () => {
    requestTableAvulsa();
  };

  return (
    <>
      <Select
        isSearchable={isSearchable}
        isClearable={isClearable}
        isMulti={isMulti}
        name={fieldName}
        placeholder={placeholder}
        styles={getFlexSelectCustomStyle({ minHeight, minWidth })}
        value={getSelectValues(value)}
        onChange={(e) => {
          controlChange(e);
          controlFunction(e);
        }}
        closeMenuOnSelect={closeMenuOnSelect}
        classNamePrefix="react-select"
        fluid
        options={options}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default FlexSelectCalenderCHA;
