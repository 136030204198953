import React from 'react';
import ReactDatetime from 'react-datetime';
import 'moment/locale/pt-br';
import moment from 'moment';

import {
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  InputGroup
} from 'reactstrap';

const DatepickerIsValid = (props) => {
  const controlChange = (values) => {
    let date = values;
    if (values._d) {
      date = values._d.toLocaleDateString('pt-br');

      return props.valueController(props.fieldName, date);
    }

    if (values) {
      return props.valueController(props.fieldName, date);
    }

    return props.valueController(props.fieldName, '');
  };

  const valid = (current) => {
    const yesterday = moment().subtract(1, 'day');

    return current.isAfter(yesterday);
  };

  return (
    <>
      <FormGroup style={{ marginBottom: '0px' }}>
        <InputGroup className="input-group-alternative">
          <InputGroupAddon addonType="prepend">
            <InputGroupText style={{ border: '1px solid #c4c4c4' }}>
              <i className="ni ni-calendar-grid-58" />
            </InputGroupText>
          </InputGroupAddon>
          <ReactDatetime
            isValidDate={valid}
            closeOnSelect={true}
            closeOnTab={true}
            name={props.fieldName}
            className="reactDate"
            inputProps={{
              style: {
                border: '1px solid #c4c4c4'
              }
            }}
            onChange={controlChange}
            timeFormat={false}
            locale="pt-br"
            value={props.defaultValue}
          />
        </InputGroup>
      </FormGroup>
    </>
  );
};

export default DatepickerIsValid;
