import React, { useEffect, useState } from 'react';
import Chart from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Row, Card, Col, Container, CardBody, CardHeader } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  individual_column_style,
  individual_header_style
} from '../../../../../assets/styles/table_custom_style';
import { chartOptions, parseOptions, chartExample2 } from 'variables/charts.js';
import { TotalRegister } from '../../../../../components/TotalRegister';
const Synthetic = (props) => {
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    if (window.Chart) parseOptions(Chart, chartOptions());

    if (props.showReports.length > 0) {
      let arrayLabels = [];
      let arrayData = [];

      props.showReports.forEach((data) => {
        if (data.agentName && data.agentName.includes('Subtotal')) {
          arrayLabels.push(data.agentName);
          const HM = data.timeInPause.split(':');
          arrayData.push(HM[0] + '.' + HM[1]);
        }
      });

      setChartData({
        labels: [...arrayLabels],
        data: [...arrayData]
      });
    }
  }, [props]);

  return (
    <>
      <Row className="mt-3">
        <div className="col">
          <div className="card-wrapper">
            {props.totalRegister >= 0 && (
              <TotalRegister totalRegister={props.totalRegister} />
            )}
            <Card>
              <ToolkitProvider
                data={props.showReports}
                keyField="uuid"
                columns={[
                  {
                    dataField: 'agentName',
                    text: 'Agente',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'pauseName',
                    text: 'Nome da pausa',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'queueName',
                    text: 'Nome da fila',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'timeInPause',
                    text: 'Tempo de pausa',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>

              {chartData && (
                <Container fluid>
                  <Row>
                    <Col xl="12">
                      <Card>
                        <CardHeader>
                          <h6 className="surtitle">Visão Geral</h6>
                          <h5 className="h3 mb-0">Tempo de pausa</h5>
                        </CardHeader>
                        <CardBody>
                          <div className="chart">
                            <Bar
                              data={{
                                labels: chartData.labels,
                                datasets: [
                                  {
                                    data: chartData.data,
                                    maxBarThickness: 50
                                  }
                                ]
                              }}
                              options={chartExample2.options}
                              className="chart-canvas"
                              id="chart-bars"
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              )}
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default Synthetic;
