import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Container } from './style';
import { ChatIndice } from '../components/chat';

export const Chat = () => {
  const data = useSelector((state) => state.activeChatInternal.chats);

  return (
    <Container>
      {data.map((item, index) => {
        return <ChatIndice data={item} key={index} />;
      })}
    </Container>
  );
};
