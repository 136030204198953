import React, { createContext, useState, useContext, useEffect } from 'react';

const ControlAudioContext = createContext();

export function ControlAudioProvider({ children }) {
  const [stopPlayingAudio, setStopPlayingAudio] = useState(false);

  useEffect(() => {
    if (stopPlayingAudio) {
      setStopPlayingAudio(false);
    }
  }, [stopPlayingAudio]);

  function stopPlay() {
    setStopPlayingAudio(true);
  }

  return (
    <ControlAudioContext.Provider value={{ stopPlayingAudio, stopPlay }}>
      {children}
    </ControlAudioContext.Provider>
  );
}

export function useControlAudioState() {
  const context = useContext(ControlAudioContext);
  return context;
}
