import React, { useCallback, useState } from 'react';
import { Button, Row, Col, Form } from 'reactstrap';
import { useSelector } from 'react-redux';
import Select from 'react-select/async';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';

import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import DefaultModal from 'components/Modal/DefaultModal';

import { custom_select } from 'assets/styles/multiple_select';
import { useSearchUsers } from 'views/User/hooks/useSearchUsers';

import LeadService from 'views/CRM/Lead/services/leadService';

const ChangeResponsibleModal = ({
  isModalOpen,
  onModalToggle,
  leadId,
  callback,
  ...props
}) => {
  const [newResponsible, setNewResponsible] = useState(null);

  const { isSearchingUsers, searchUsers } = useSearchUsers();

  const { handleSubmit, errors } = useForm();
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const onSubmit = async () => {
    if (!newResponsible) {
      return toast.error('Selecione um novo responsável');
    }

    try {
      await LeadService.changeResponsible(leadId, newResponsible.value);
      toast.success('Responsável alterado com sucesso');
      setNewResponsible(null);
      if (callback) callback();
      closeModal();
    } catch (error) {
      const errMsg =
        error.response?.data?.message || 'Erro ao alterar responsável';
      toast.error(errMsg);
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Alterar responsável pelo Lead"
      showCloseButton={false}
      modalContainerClasses="modal-md"
      {...props}
    >
      <Form
        className="mt--3"
        onSubmit={(event) => {
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
      >
        <Row className="mb-3">
          <Col>
            <div className="d-flex">
              <div style={{ flexGrow: '10' }}>
                <label className="form-control-label">Novo Responsável*</label>
                <Select
                  styles={custom_select}
                  placeholder="Digite para buscar..."
                  isClearable={true}
                  name="responsibleLogin"
                  isLoading={isSearchingUsers}
                  loadingMessage={() => 'Buscando...'}
                  noOptionsMessage={({ inputValue }) =>
                    !inputValue
                      ? 'Digite para pesquisar'
                      : 'Nenhum resultado encontrado'
                  }
                  loadOptions={async (inputValue) => {
                    const data = await searchUsers(inputValue);
                    return (
                      data &&
                      data.map((user) => ({
                        label: user.name,
                        value: user.login
                      }))
                    );
                  }}
                  onChange={(data) => {
                    if (data) {
                      const { value, label } = data;
                      setNewResponsible({
                        label,
                        value
                      });
                    } else {
                      setNewResponsible(null);
                    }
                  }}
                  value={
                    newResponsible
                      ? {
                          label: newResponsible.label,
                          value: newResponsible.value
                        }
                      : ''
                  }
                  defaultValue=""
                />
                <ErrorMessage
                  errors={errors}
                  name="responsibleLogin"
                  render={({ message }) => errorFormMessage(message)}
                />
              </div>
            </div>
          </Col>
        </Row>

        <Row className="mt-1">
          <Col>
            <Button
              style={buttonStyle(systemConfiguration.confirmation_button_color)}
              type="submit"
            >
              Alterar
            </Button>
            <Button
              style={buttonStyle(systemConfiguration.cancelation_button_color)}
              onClick={closeModal}
            >
              Cancelar
            </Button>
          </Col>
        </Row>
      </Form>
    </DefaultModal>
  );
};

export default ChangeResponsibleModal;
