import { apiCase } from '../../../http';
import ServiceCRUD from '../../../services/ServiceCRUD';

class EmailSettingsService extends ServiceCRUD {
  constructor() {
    super('emails');
  }

  getConfig() {
    return apiCase.get('emails');
  }
}

export default new EmailSettingsService();
