import { apiCrm } from '../../../../http';
class TagClientService {
  async create({ name, color }) {
    return apiCrm.post('/tag-client', {
      name,
      color
    });
  }

  async getById(id) {
    return apiCrm.get(`/tag-client/${id}`);
  }

  async getAll({ showDisabled = false }) {
    return apiCrm.get('/tags-client/all', {
      params: {
        showDisabled
      }
    });
  }

  async getWithPagination({
    page = 1,
    limit = 10,
    showDisabled = false,
    name = ''
  }) {
    return apiCrm.get('/tags-client', {
      params: {
        page,
        limit,
        showDisabled,
        name
      }
    });
  }

  async getWithPaginationAndSearch(page = 1, name) {
    return apiCrm.get('/tags-client', {
      params: {
        page,
        limit: 30,
        name
      }
    });
  }

  async update(id, { name, color }) {
    return apiCrm.put(`/tags-client/${id}`, {
      name,
      color
    });
  }

  async changeStatus(id) {
    return apiCrm.put(`/tag-client/status/${id}`);
  }
}

export default new TagClientService();
