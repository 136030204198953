export const steps = [
  {
    selector: '.needs-validation',
    content: `Olá seja bem vindo ao nosso TourGuiado na parte de protocolo. 
    Protocolo de atendimento é uma sequência numérica criada para a identificação 
    do atendimento e acompanhamento do cliente junto à empresa sobre
     uma solicitação e/ou reclamação`
  },
  {
    selector: '#tour1',
    content: `Vamos começar falando primeiramente sobre o número do protocolo 
    ele é usado exclusivamente para identificar o protocolo de forma única e deve ser repassado ao 
    cliente em alguma hora do atendimento. Ele é formado dinamicamente com base no dia, mes, ano, hora e segundo da realização do atendimento`
  },
  {
    selector: '.tour2',
    content:
      'Aqui por sua vez é o titulo principal do protocolo, ele server pra descrever de forma resumida o motivo pelo qual o cliente entrou em contato '
  },
  {
    selector: '.tour3',
    content: `Aqui é onde você deve escolher/vincular o cliente ao protocolo. Sendo assim, basta apenas clicar no campo que automaticamente aparecera um pequeno modal onde sera solicitado o preenchimento de dois campos
    o primeiro um campo de seleção para escolher por qual campo deseja filtrar o cliente o segundo campo é onde você deverá preencher a informação para realizar a busca, feito isso basta apenas
    escolher o cliente entre os listados que aparecera. Feito isto, o cliente vai esta vinculado ao protocolo 🙂😉`
  },
  {
    selector: '#tour4',
    content: `Caso não encontre o cliente como visto no passo anterior basta apenas clicar aqui, preencher os campos de nome, email e telefone e logo após clicar
      no botão de salvar, que teremos um novo cliente cadastrado, bastando apenas selecionar ele, como visto no passo anterior`
  },
  {
    selector: '.tour5',
    content: `Chegamos agora na descrição do protocolo, é nele onde você descrever de forma detalhada a ocorrência que gerou a criação do protocolo`
  },
  {
    selector: '#tour6',
    content: `Este é o campo de motivo nele você deve especificar de forma mais macro a causa/razão da abertura deste protocolo `
  },
  {
    selector: '#tour7',
    content: `Sempre que for escolhido um motivo, para especificar de forma mais micro deve-se também selecionar um sub-motivo sendo aqui o local para fazer isso`
  },
  {
    selector: '.tour8',
    content: `Este é um campo bem interessante, pois a função dele é indicar qual o canal que o cliente usou para entrar contato, ocasionado assim à abertura deste protocolo. 
    Os canais possiveis são: Email, Facebook, Telegram, Telefonia e Whatsapp ;)`
  },
  {
    selector: '.tour9',
    content: `Chegamos a parte final, com o campo de status/fase ele é onde definimos a situação atual deste protocolo, podendo ser: Em Aberto, Padrão e entre outros... Era isso
    que tinha a dizer espero que tenha gostado, até a próxima`
  }
];
