import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import AudioService from '../service';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';
import ReactButton from 'components/Buttons/ReactButton';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { ScreenPrompt } from 'components/ScreenPrompt';

const Status = (props) => {
  const history = useHistory();
  const { id } = props.match.params;
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const {
    reset,
    control,
    handleSubmit,
    errors,
    setError,
    clearErrors,
    register,
    formState
  } = useForm();
  const { isDirty } = formState;

  const [isUpdate, setIsUpdate] = useState(false);
  const [sound, setSound] = useState(null);

  useEffect(() => {
    if (id) {
      AudioService.get(id)
        .then((res) => {
          reset(res.data.data);
          setIsUpdate(true);
        })
        .catch((err) => {
          const { message } = err.response.data;
          toast.error(message, {
            autoClose: 3000,
            closeOnClick: true
          });
          history.replace('/admin/audio');
        });
    }
  }, [id, reset, history]);

  const changeFile = (event) => {
    const file = event.target.files[0];
    isFileValid(file);
    setSound(file);
  };

  const isFileValid = (file) => {
    if (!file) {
      setError('sound', {
        type: 'required',
        message: 'Selecione um arquivo de áudio'
      });
      return false;
    }
    if (file.size > 5 * 1024 * 1024) {
      setError('sound', {
        type: 'maxLength',
        message: 'Arquivo maior do que 5M'
      });
      return false;
    }
    clearErrors('sound');
    return true;
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve) => {
      var reader = new FileReader();
      // Ler o conteudo do arquivo
      reader.onload = function (event) {
        resolve(event.target.result);
      };

      // Converte dado para base64
      reader.readAsDataURL(file);
    });
  };

  const onSubmit = async (data) => {
    if (!isFileValid(sound)) return;

    try {
      const formatedData = { ...data };
      // Transforma sound para base 64
      formatedData.sound = await fileToBase64(sound);

      isUpdate
        ? await AudioService.update(id, formatedData)
        : await AudioService.create(formatedData);

      toast.success('Áudio salvo!', {
        autoClose: 3000,
        closeOnClick: true
      });
    } catch (err) {
      console.log('response err', err.response);
      // const { message } = err.response.data;
      toast.error('Erro ao salvar áudio', {
        autoClose: 3000,
        closeOnClick: true
      });
    } finally {
      history.push('/admin/audio/');
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/audio');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/audio/"
        buttonTitle="Voltar para a pagina de audio"
      />
      <ScreenPrompt
        when={isDirty && !formState.isSubmitting }
        message={`Está certo de que deseja sair? Terá que preencher os dados novamente quando retornar`}
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Adicionar Áudio</h3>
                </CardHeader>
                <CardBody>
                  <Form
                    className="needs-validation"
                    onSubmit={handleSubmit(onSubmit)}
                    encType="multipart/form-data"
                  >
                    <Row className="mb-3">
                      <Col sm="12">
                        <label className="form-control-label">Nome*</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="name"
                          rules={{
                            pattern: {
                              value: /^[a-zA-Z0-9-_]+$/,
                              message:
                                'O nome não pode conter espaços ou caracteres especiais!'
                            },
                            required: 'Campo obrigatório'
                          }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="name"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col sm="12">
                        <label className="form-control-label">Descrição*</label>
                        <Controller
                          as={Input}
                          control={control}
                          name="description"
                          rules={{ required: 'Campo obrigatório' }}
                          defaultValue=""
                        />
                        <ErrorMessage
                          errors={errors}
                          name="description"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col sm="12">
                        <label className="form-control-label">
                          <input
                            type="radio"
                            name="type"
                            ref={register}
                            value="sound"
                            defaultChecked={true}
                            disabled={isUpdate}
                          />{' '}
                          Áudio
                        </label>
                      </Col>
                      <Col sm="12">
                        <label className="form-control-label">
                          <input
                            type="radio"
                            name="type"
                            ref={register}
                            value="musicHold"
                            disabled={isUpdate}
                          />{' '}
                          Música de espera
                        </label>
                      </Col>
                      <Col sm="12">
                        <label className="form-control-label">
                          <input
                            type="radio"
                            name="type"
                            ref={register}
                            value="ura"
                            disabled={isUpdate}
                          />{' '}
                          URA
                        </label>
                      </Col>
                    </Row>

                    <Row className="mb-3">
                      <Col sm="12">
                        <label className="form-control-label d-block">
                          Áudio ( mp3, mp4, wav, gsm, ogg, limite de 5 Mb )*
                        </label>
                        <label
                          htmlFor="sound"
                          className="btn btn-primary btn-sm"
                          style={buttonStyle(systemConfiguration.primary_color)}
                        >
                          Selecionar um arquivo
                        </label>
                        {sound && sound.name}
                        <Input
                          style={{ display: 'none' }}
                          type="file"
                          id="sound"
                          name="sound"
                          onChange={changeFile}
                          accept="audio/mp3, audio/mp4, audio/wav, audio/gsm, audio/ogg"
                        />
                        <ErrorMessage
                          errors={errors}
                          name="sound"
                          render={({ message }) => errorFormMessage(message)}
                        />
                      </Col>
                      <Col sm="12">
                        {isFileValid && sound ? (
                          <audio
                            src={window.URL.createObjectURL(sound)}
                            controls
                            style={{ width: '100%', maxWidth: '20rem' }}
                          ></audio>
                        ) : null}
                      </Col>
                    </Row>

                    <hr />
                    <ReactButton btnColor="confirmation" type="submit">
                      Salvar
                    </ReactButton>

                    <ReactButton
                      btnColor="cancelation"
                      onClick={() => cancelAction()}
                    >
                      Cancelar
                    </ReactButton>
                  </Form>
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Status;
