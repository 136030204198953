import {
  refusedExtensions,
  imageExtensions,
  fileExtensions,
  audioExtensions,
  videoExtensions
} from '../components/assets';
export function validationFiles() {
  const VIDEOTYPE = 'video';
  const AUDIOTYPE = 'audio';
  const FILETYPEPDFANDXLSX = 'application';
  const FILETYPECSV = 'text';
  const IMAGETYPE = 'image';

  function CheckedRefusedExtensionsFiles(filesToBeSent) {
    const status = filesToBeSent.some((file) => {
      const fileExtension = file.name.split('.').pop();
      return refusedExtensions.includes(fileExtension);
    });

    return status;
  }

  function isAllFilesWithApprovedExtension(filesToBeSend) {
    const MESSAGERETURNINVALIDTYPE = 'Essa extensão de arquivo é inválida';
    let invalidFile = {};

    filesToBeSend.forEach((file) => {
      const fileType = file.type.split('/').shift();
      const fileExtension = file.name.split('.').pop();

      if (fileType === VIDEOTYPE && !videoExtensions.includes(fileExtension)) {
        invalidFile = {
          extension: fileExtension,
          message: MESSAGERETURNINVALIDTYPE
        };
      }

      if (fileType === AUDIOTYPE && !audioExtensions.includes(fileExtension)) {
        invalidFile = {
          extension: fileExtension,
          message: MESSAGERETURNINVALIDTYPE
        };
      }

      if (
        (fileType === FILETYPEPDFANDXLSX || fileType === FILETYPECSV) &&
        !fileExtensions.includes(fileExtension)
      ) {
        invalidFile = {
          extension: fileExtension,
          message: MESSAGERETURNINVALIDTYPE
        };
      }

      if (fileType === IMAGETYPE && !imageExtensions.includes(fileExtension)) {
        invalidFile = {
          extension: fileExtension,
          message: MESSAGERETURNINVALIDTYPE
        };
      }
    });

    return invalidFile;
  }

  function sizeInvalidFileValidation(filesToBeSend, to) {
    let sizes = {};

    let invalidSizeFile = {};
    if (to === 'WHATSAPP') {
      sizes = {
        maximumFileSizeVideo: {
          size: 16000000,
          message:
            'Erro ao enviar arquivo(s). Tamanho máximo deve ser de 16MB para: '
        },
        maximumFileSizeAudio: {
          size: 16000000,
          message:
            'Erro ao enviar arquivo(s). Tamanho máximo deve ser de 16MB para: '
        },
        maximumFileSizeImage: {
          size: 2000000,
          message:
            'Erro ao enviar arquivo(s). Tamanho máximo deve ser de 2MB para: '
        },
        maximumFileSizePDF: {
          size: 2000000,
          message:
            'Erro ao enviar arquivo(s). Tamanho máximo deve ser de 2MB para: '
        },
        maximumFileSizeImageAnyValidMimeType: {
          size: 50000000,
          message:
            'Erro ao enviar arquivo(s). Tamanho máximo deve ser de 50MB para: '
        }
      };
    } else {
      sizes = {
        maximumFileSizeVideo: {
          size: 16000000,
          message:
            'Erro ao enviar arquivo(s). Tamanho máximo deve ser de 16MB para: '
        },
        maximumFileSizeAudio: {
          size: 16000000,
          message:
            'Erro ao enviar arquivo(s). Tamanho máximo deve ser de 16MB para: '
        },
        maximumFileSizeImage: {
          size: 50000000,
          message:
            'Erro ao enviar arquivo(s). Tamanho máximo deve ser de 5MB para: '
        },
        maximumFileSizeImageAnyValidMimeType: {
          size: 100000000,
          message:
            'Erro ao enviar arquivo(s). Tamanho máximo deve ser de 100MB para: '
        }
      };
    }

    filesToBeSend.forEach((file) => {
      const fileType = file.type.split('/').shift();

      if (
        fileType === VIDEOTYPE &&
        file.size > sizes.maximumFileSizeVideo.size
      ) {
        invalidSizeFile = {
          extension: fileType,
          message: sizes.maximumFileSizeVideo.message
        };
      }

      if (
        fileType === AUDIOTYPE &&
        file.size > sizes.maximumFileSizeAudio.size
      ) {
        invalidSizeFile = {
          extension: fileType,
          message: sizes.maximumFileSizeAudio.message
        };
      }

      if (
        fileType === IMAGETYPE &&
        file.size > sizes.maximumFileSizeImage.size
      ) {
        invalidSizeFile = {
          extension: fileType,
          message: sizes.maximumFileSizeImage.message
        };
      }

      if (
        fileType !== IMAGETYPE &&
        fileType !== AUDIOTYPE &&
        fileType !== VIDEOTYPE &&
        file.size > sizes.maximumFileSizeImageAnyValidMimeType.size
      ) {
        invalidSizeFile = {
          extension: fileType,
          message:
            'Erro ao enviar arquivo(s). Tamanho máximo deve ser de 100MB para: '
        };
      }
    });

    return invalidSizeFile;
  }

  function isFilesWithApprovedExtension(filesToBeSent) {
    const EXTENSIONVALID = 'pdf';
    const files = filesToBeSent.filter((file) => {
      const fileExtension = file.name.split('.').pop();
      return fileExtension === EXTENSIONVALID;
    });

    return files;
  }

  function isImagesWithApprovedExtension(filesToBeSent) {
    const images = filesToBeSent.filter((image) => {
      const imageExtension = image.name.split('.').pop();
      return imageExtensions.includes(imageExtension);
    });

    return images;
  }

  return {
    CheckedRefusedExtensionsFiles,
    isAllFilesWithApprovedExtension,
    isFilesWithApprovedExtension,
    isImagesWithApprovedExtension,
    sizeInvalidFileValidation
  };
}
