import React, { useEffect } from 'react';
import { Row } from 'reactstrap';
import { toast } from 'react-toastify';

import DefaultModal from 'components/Modal/DefaultModal';
import ReactLoader from 'components/Loader';
import Pagination from 'components/Pagination';
import AutenticationAnalyticalTable from 'views/Report/Callcenter/Autentication/tables/analytical';

import { getAnalyticalAutenticationReport } from 'views/Dashboard/Agent/services/getAnalyticalAutenticationReport';
import { DEFAULT_PAGE_SIZE } from 'views/Dashboard/Agent/utils/consts';

import { useAnalyticalReportService } from '../../hooks/useAnalyticalReportService';

const AutenticationReportModal = ({ isModalOpen, onModalToggle }) => {
  const {
    data,
    quantity,
    loading,
    activePage,
    error,
    handlePageChange,
    fetchData
  } = useAnalyticalReportService(getAnalyticalAutenticationReport);

  useEffect(() => {
    if (!isModalOpen) return;

    fetchData();
  }, [isModalOpen, activePage]);

  useEffect(() => {
    if (!error) return;

    onModalToggle(false);
    toast.error('Erro ao carregar relatório de autenticação.');
  }, [error]);

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Relatório de Autenticação"
      modalContainerClasses="modal-xl"
      showCloseButton={false}
      centered={false}
    >
      {loading ? (
        <Row className="justify-content-md-center">
          <ReactLoader />
        </Row>
      ) : (
        <AutenticationAnalyticalTable
          totalRecords={quantity}
          showReports={data.listAuthenticationDTO || []}
        />
      )}

      {quantity > DEFAULT_PAGE_SIZE && (
        <Pagination
          activePage={activePage}
          totalItemsCount={quantity}
          onChange={handlePageChange}
        />
      )}
    </DefaultModal>
  );
};

export default AutenticationReportModal;
