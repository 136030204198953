export function definedMessageGeneratedInPdfCsvDownloads(
  quantity,
  limitGenerationPDF
) {
  const message = {
    csvMessage: '',
    pdfMessage: ''
  };

  if (quantity > 0 && quantity <= limitGenerationPDF) {
    message.pdfMessage =
      'Clique aqui para realizar o Download do relatório em PDF';
    message.csvMessage =
      'Clique aqui para realizar o Downloads do relatório em CSV';
  } else if (quantity > 0 && quantity > limitGenerationPDF) {
    message.pdfMessage = `Infelizmente essa quantidade de ${quantity}, não é suportando para download em PDF`;
    message.csvMessage =
      'Clique aqui para realizar o Downloads do relatório em CSV';
  } else if (quantity <= 0) {
    message.pdfMessage =
      'Por favor verifique a quantide de registro, ou realize uma filtragem';

    message.csvMessage =
      'Por favor verifique a quantide de registro, ou realize uma filtragem';
  }

  return message;
}
