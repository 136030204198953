import React, { useState } from 'react';
import { Button, Container, Row, Col } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { BiArrowBack } from 'react-icons/bi';
import { useSelector } from 'react-redux';

const TimelineHeader = (props) => {
  const [state, setState] = useState({
    path: '',
    return: props.returnPath || '/admin/configuration',
    controlReturn: false
  });
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  function handleReturn() {
    localStorage.removeItem('viewChangeDayOff');
    setState({ ...state, controlReturn: true });
  }

  if (state.path && props.entityName) {
    return (
      <Redirect
        to={{
          pathname: state.path,
          entityName: { entityName: props.entityName }
        }}
      />
    );
  } else if (state.path) {
    return (
      <Redirect
        to={{
          pathname: state.path
        }}
      />
    );
  }
  if (state.controlReturn) return <Redirect to={state.return} />;
  return (
    <>
      <div
        className="header header-dark pb-6 content__title content__title--calendar"
        style={{ background: systemConfiguration.primary_color }}
      >
        <Container fluid>
          {/* {console.log('chegou aqui', this.props)} */}
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col lg="6" xs="7">
                {props.showBackArrow !== 'not' && (
                  <Button
                    size="sm"
                    color={systemConfiguration.primary_color}
                    title={
                      props.buttonTitle ||
                      'Volta para a pagina de configurações'
                    }
                    onClick={handleReturn}
                  >
                    <BiArrowBack size="35px" color="white" />
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

// TimelineHeader.propTypes = {
//   name: PropTypes.string,
//   parentName: PropTypes.string
// };

export default TimelineHeader;
