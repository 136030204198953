import React, { useState, useEffect, useCallback } from 'react';
import Service from './service';
import SectorService from '../../../Sector/service';
import UserService from '../../../User/service';
import TrunkService from '../../../Trunk/service';
import ExtensionService from '../../../Extension/service';
import { useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import FlexSelect from 'components/Inputs/FlexSelect';
import Pagination from 'components/Pagination';
import Loader from 'react-loader-spinner';
import HourMaskMMHHSS from '../../../../components/Inputs/HourMaskMMHHSS.js';
import DatePicker from '../../../../components/Inputs/DatePicker.js';
import ReportAnalythical from './tables/Analytical';
import ReportSynthetical from './tables/Synthetic';
import ReportDetailedSynthetic from './tables/DetailedSynthetic';
import { reportOptions, StatusCall } from './types';
import { dateRules, hourRules } from './rulesValidation';
import { definedMessageGeneratedInPdfCsvDownloads } from '../../utils/definedMessageGeneratePdfCsv';
import { disabledDownloadButtonCsvAndPdf } from '../../utils/disabledDownloadButtonCsvAndPdf';
import pdfImage from '../../../../assets/img/pdf.png';
import csvImage from '../../../../assets/img/csv.png';
import * as fileSaver from 'file-saver';
import { v4 as uuid } from 'uuid';
import { format, startOfMonth } from 'date-fns';
import { Tooltip } from '@material-ui/core';

import {
  Container,
  Row,
  Card,
  Form,
  Col,
  CardHeader,
  Button,
  CardBody,
  Input
} from 'reactstrap';

import {
  ImageButtonContainer,
  ImageButton,
  ImageSize
} from '../../../../assets/styles/image_style';
import { reports } from 'http';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { UserFlexSelect } from '../../../../components/UserFlexSelect';
import { ItemPerPageSelect } from 'components/Inputs/ItemPerPageSelect';

const CallMadeReport = () => {
  const { control, handleSubmit, errors, setValue, watch, getValues } =
    useForm();
  const [ReportTypeAnalytical, setReportTypeAnalytical] = useState([]);
  const [ReportTypeSynthetical, setReportTypeSynthetical] = useState([]);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const [ReportTypeDetailedSynthetic, setReportTypeDetailedSynthetic] =
    useState([]);
  const [dataToSubmitDownloads, setDataToSubmitDownloads] = useState();
  const [usersShowDisable, setUsersShowDisable] = useState(true);
  const [agents, setAgents] = useState([]);
  const [sector, SetSector] = useState([]);
  const [trunks, setTrunks] = useState([]);
  const [extensions, setExtensions] = useState([]);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingTable, setLoadingTable] = useState(false);
  const reportType = watch('reportType');
  const showDisableQueueAndExtension = !!watch('disable');
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [extraDataFromAnalyticalReport, setExtraDataFromAnalyticalReport] =
    useState();
  const [chartDataList, setCharDataList] = useState([]);
  const limitGenerationPDF = parseInt(
    process.env.REACT_APP_LIMIT_GENERATION_PDF || 25000
  );
  const [messagemGeneratePdf, setMessagemGeneratePdf] = useState();
  const [messagemGenerateCsv, setMessagemGenerateCsv] = useState();
  const [itemsPerPage, setItemsPerPage] = useState(10);

  //trunk to select
  useEffect(() => {
    TrunkService.getAll()
      .then((response) => {
        setTrunks(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, []);

  //sector to select
  useEffect(() => {
    SectorService.getAll()
      .then((response) => {
        SetSector(response.data.data);
      })
      .catch((err) => {
        console.log('Erro: ' + err);
      });
  }, []);

  //show extensions enabled and disabled for select
  useEffect(() => {
    ExtensionService.getAllShowDisableNew(showDisableQueueAndExtension)
      .then((response) => {
        setExtensions(response.data.data);
      })
      .catch((err) => {
        console.log('err: ' + err);
      });
  }, [showDisableQueueAndExtension]);

  //clear reporting statuses and pagination
  useEffect(() => {
    //data clear for analytical report
    if (reportType === 1) {
      setQuantity(0);
      setActivePage(1);
      setReportTypeSynthetical([]);
      setReportTypeDetailedSynthetic([]);
    }
    //data clear for synthetic report
    if (reportType === 2) {
      setQuantity(0);
      setActivePage(1);
      setReportTypeAnalytical([]);
      setCharDataList([]);
      setReportTypeDetailedSynthetic([]);
    }
    //data clear for detailedSynthetic
    if (reports === 3) {
      setQuantity(0);
      setActivePage(1);
      setReportTypeAnalytical([]);
      setReportTypeSynthetical([]);
    }
  }, [reportType]);

  //agent||users to select
  useEffect(() => {
    getUser(true);
  }, [usersShowDisable]);

  const getUser = (showDisable = false) => {
    if (!showDisable) {
      setLoadingForm(true);
    }
    UserService.getAll(false, undefined, undefined, usersShowDisable)
      .then((response) => {
        setAgents(response.data.data);
        if (!showDisable) {
          setLoadingForm(false);
        }
      })
      .catch((err) => {
        console.log('err: ' + err);
        if (!showDisable) {
          setLoadingForm(false);
        }
      });
  };

  //messagemGeneratePdfCsv
  useEffect(() => {
    const message = definedMessageGeneratedInPdfCsvDownloads(
      quantity,
      limitGenerationPDF
    );

    setMessagemGeneratePdf(message.pdfMessage);
    setMessagemGenerateCsv(message.csvMessage);
  }, [quantity, limitGenerationPDF]);

  const downloadMedia = useCallback(
    async (media, type, fileName) => {
      try {
        let typeReport;
        if (dataToSubmitDownloads.reportType === 1) typeReport = 'analytical';
        if (dataToSubmitDownloads.reportType === 2) typeReport = 'synthetic';
        if (dataToSubmitDownloads.reportType === 3)
          typeReport = 'detailedsynthetic';
        //type, page, format, queryParams
        const response = await Service.getDownloads(
          typeReport,
          1,
          media,
          dataToSubmitDownloads
        );
        fileSaver.saveAs(new Blob([response.data], { type }), fileName);
      } catch (err) {
        toast.error(err || 'Ops, ocorreu um erro!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [dataToSubmitDownloads]
  );

  const toISOFormat = useCallback(async (dateTimeString, bar = false) => {
    const [date] = dateTimeString.split(' ');
    const [DD, MM, YYYY] = date.split('/');
    if (bar) return `${MM}/${DD}/${YYYY}`;
    return `${MM}-${DD}-${YYYY}`;
  }, []);

  const getDataDTO = useCallback(
    async (data) => {
      let dataToSend = null;
      dataToSend = {
        ...data,
        dateBegin: `${await toISOFormat(data.dateBegin)} ${data.timeStart}`,
        dateEnd: `${await toISOFormat(data.dateEnd)} ${data.timeEnd}`,
        timeStart: data.timeStart.replace(/:/g, ''),
        timeEnd: data.timeEnd.replace(/:/g, ''),
        disable: data.disable || false
      };
      const arrayOfKeys = Object.keys(dataToSend);
      arrayOfKeys.forEach((key) => {
        if (String(dataToSend[key]).length === 0) delete dataToSend[key];
      });
      setDataToSubmitDownloads(dataToSend);
      return dataToSend;
    },
    [toISOFormat]
  );

  const defineTypeReport = async (type) => {
    switch (type) {
      case 1:
        return 'analytical';
      case 2:
        return 'synthetic';
      case 3:
        return 'detailedsynthetic';
      default:
        console.log(`Sorry, error!`);
        break;
    }
  };

  const getReports = useCallback(
    (typeReport, selectedPage = 1, dataToSend) => {
      //definir tipo = analytical, synthetic,pdf, excel, detailedsynthetic
      const newActivePage = selectedPage || activePage;
      if (typeReport === 'analytical') {
        setLoadingTable(true);
        Service.getAllReportsType(
          typeReport,
          selectedPage,
          dataToSend,
          itemsPerPage
        )
          .then((res) => {
            const {
              listCallAnalytical,
              quantityCall,
              quantityCallAnswer,
              quantityCallNoAnswer,
              averageWaintingTimeFormated,
              averageServiceTimeFormated
            } = res.data.data;
            listCallAnalytical.forEach((el, index) => {
              listCallAnalytical[index].uuid = uuid();
            });
            setReportTypeAnalytical(listCallAnalytical);
            setQuantity(quantityCall);
            setExtraDataFromAnalyticalReport({
              quantityCallAnswer: quantityCallAnswer,
              quantityCallNoAnswer: quantityCallNoAnswer,
              waitingServiceTimeFormated: averageWaintingTimeFormated,
              averageServiceTimeFormated: averageServiceTimeFormated
            });
            setActivePage(newActivePage);
            if (listCallAnalytical.length === 0) {
              toast.info('Nenhum registro foi encontrado!');
            }
            setLoadingTable(false);
          })
          .catch((err) => {
            setLoadingTable(false);
            console.log('err' + err);
          });
      }

      if (typeReport === 'synthetic') {
        setLoadingTable(true);
        Service.getAllReportsType(typeReport, selectedPage, dataToSend)
          .then((res) => {
            const { listCallForNotQueuedDTO, listCallSyntheticDTO } =
              res.data.data;
            listCallForNotQueuedDTO.forEach((el, index) => {
              listCallForNotQueuedDTO[index].uuid = uuid();
            });
            setReportTypeSynthetical(listCallForNotQueuedDTO);
            setCharDataList(listCallSyntheticDTO);
            setActivePage(newActivePage);

            if (listCallForNotQueuedDTO.length === 0) {
              toast.info('Nenhum registro foi encontrado!');
            }
            setQuantity(listCallForNotQueuedDTO.length);
            setLoadingTable(false);
          })
          .catch((err) => {
            setLoadingTable(false);
            console.log(err);
          });
      }

      if (typeReport === 'detailedsynthetic') {
        setLoadingTable(true);
        Service.getAllReportsType(
          typeReport,
          selectedPage,
          dataToSend,
          itemsPerPage
        )
          .then((res) => {
            const { listCallDetailedSyntheticPbxipDTO, quantity } =
              res.data.data;
            listCallDetailedSyntheticPbxipDTO.forEach((el, index) => {
              listCallDetailedSyntheticPbxipDTO[index].uuid = uuid();
            });
            setReportTypeDetailedSynthetic(listCallDetailedSyntheticPbxipDTO);
            setQuantity(quantity);
            setActivePage(newActivePage);
            if (listCallDetailedSyntheticPbxipDTO.length === 0) {
              toast.info('Nenhum registro foi encontrado!');
            }
            setLoadingTable(false);
          })
          .catch((err) => {
            setLoadingTable(false);
            console.log('err' + err);
          });
      }
    },
    [activePage, itemsPerPage]
  );

  const onSubmit = useCallback(
    async (data) => {
      try {
        const dataToSend = await getDataDTO(data);
        const typeReport = await defineTypeReport(reportType);
        getReports(typeReport, 1, dataToSend);
      } catch (err) {
        toast.error(err || 'Erro desconhecido por favor, informar o suporte!', {
          autoClose: 3000,
          closeOnClick: true
        });
      }
    },
    [getDataDTO, reportType, getReports]
  );

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const handlePageChange = (activePage) => {
    let reportType = null;
    if (dataToSubmitDownloads.reportType === 1) reportType = 'analytical';
    if (dataToSubmitDownloads.reportType === 2) reportType = 'synthetic';
    if (dataToSubmitDownloads.reportType === 3)
      reportType = 'detailedsynthetic';
    getReports(reportType, activePage, dataToSubmitDownloads);
  };

  const getDateBegin = () => {
    const date = new Date();
    const firstDayMonth = new Date(
      date.getFullYear('pt-br'),
      date.getMonth('pt-br'),
      1
    );
    const dateBegin = format(startOfMonth(firstDayMonth), 'dd/LL/yyyy');
    return dateBegin;
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        showBackArrow="not"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Relatório de Chamadas Realizadas - PBXIP</h3>
                </CardHeader>
                <CardBody>
                  {loadingForm ? (
                    <Row className="justify-content-md-center">
                      <Loader
                        type="TailSpin"
                        color={systemConfiguration.primary_color}
                        height={100}
                        width={100}
                      />
                    </Row>
                  ) : (
                    <>
                      <Form
                        className="needs-validation"
                        onSubmit={handleSubmit(onSubmit)}
                      >
                        <Row>
                          <Col md="6">
                            <label className="form-control-label">
                              Operadora
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={trunks}
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="carrier"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="carrier"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Usuário
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={agents}
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  isClearable={true}
                                  closeMenuOnSelect={true}
                                  fieldName="agent"
                                  labelName="name"
                                  valueName="agent_code"
                                />
                              )}
                              control={control}
                              name="agent"
                              defaultValue=""
                            />
                          </Col>
                        </Row>
                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">Origem</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={extensions}
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="origin"
                                  labelName="extension_number"
                                  valueName="extension_number"
                                />
                              )}
                              control={control}
                              name="origin"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">
                              Destino
                            </label>

                            <Controller
                              as={Input}
                              control={control}
                              name="dst"
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dst"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dateBegin"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dateBegin"
                              rules={dateRules}
                              defaultValue={getDateBegin()}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dateBegin"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>

                          <Col md="6">
                            <label className="form-control-label">
                              Hora inicial*
                            </label>
                            <Controller
                              render={(props) => (
                                <HourMaskMMHHSS
                                  valueController={setValue}
                                  fieldName="timeStart"
                                  value={props.value}
                                />
                              )}
                              control={control}
                              name="timeStart"
                              rules={hourRules}
                              defaultValue="00:00:00"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="timeStart"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>

                        <Row className="mb-3">
                          <Col md="6">
                            <label className="form-control-label">
                              Data final*
                            </label>
                            <Controller
                              render={(props) => (
                                <DatePicker
                                  fieldName="dateEnd"
                                  valueController={setValue}
                                  defaultValue={props.value}
                                />
                              )}
                              control={control}
                              name="dateEnd"
                              defaultValue={new Date().toLocaleDateString(
                                'pt-br'
                              )}
                              rules={{
                                ...dateRules,
                                validate: async (value) => {
                                  if (
                                    new Date(await toISOFormat(value, true)) <
                                    new Date(
                                      await toISOFormat(
                                        getValues()['dateBegin'],
                                        true
                                      )
                                    )
                                  ) {
                                    return 'A data final não pode ser anterior à inicial!';
                                  } else {
                                    return true;
                                  }
                                }
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="dateEnd"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>

                          <Col md="6">
                            <label className="form-control-label">
                              Hora final*
                            </label>
                            <Controller
                              render={(props) => (
                                <HourMaskMMHHSS
                                  valueController={setValue}
                                  fieldName="timeEnd"
                                  value={props.value}
                                />
                              )}
                              control={control}
                              name="timeEnd"
                              rules={hourRules}
                              defaultValue="23:59:59"
                            />
                            <ErrorMessage
                              errors={errors}
                              name="timeEnd"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        </Row>
                        <Row className="mb-12">
                          <Col md="6">
                            <label className="form-control-label">
                              Status da chamada
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={StatusCall}
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="disposition"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="disposition"
                              defaultValue=""
                            />
                          </Col>
                          <Col md="6">
                            <label className="form-control-label">Setor</label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={sector}
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="cost_center"
                                  labelName="name"
                                  valueName="id"
                                />
                              )}
                              control={control}
                              name="cost_center"
                              defaultValue=""
                            />
                          </Col>
                        </Row>

                        <Row className="mb-12">
                          <Col md="6">
                            <label className="form-control-label">
                              Tipo de Relatório
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  dataOptions={reportOptions}
                                  isMulti={false}
                                  value={props.value}
                                  valueController={setValue}
                                  closeMenuOnSelect={true}
                                  fieldName="reportType"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="reportType"
                              defaultValue={1}
                            />
                          </Col>
                        </Row>
                        <Row className="mb-12">
                          <Col className="md-6 mx-4">
                            <div style={{ display: 'flex', gap: '20px' }}>
                              <Controller
                                name="disable"
                                control={control}
                                render={(props) => (
                                  <>
                                    <label className="form-control-label">
                                      <Input
                                        type="checkbox"
                                        onChange={(e) =>
                                          props.onChange(e.target.checked)
                                        }
                                        checked={props.value}
                                      />
                                      Mostrar desabilitados (Ramal)
                                    </label>
                                  </>
                                )}
                                defaultValue=""
                              />
                              <UserFlexSelect
                                usersShowDisable={usersShowDisable}
                                setUsersShowDisable={setUsersShowDisable}
                                name={'Usuário'}
                              />
                            </div>
                          </Col>
                          <Col md="4" style={ImageButtonContainer}>
                            <div id="button_pdf">
                              {reportType !== 1 && (
                                <button
                                  style={ImageButton}
                                  name="pdf"
                                  onClick={() =>
                                    downloadMedia(
                                      'pdf',
                                      'application/pdf',
                                      'Relatório_de_Chamada_Realizada.pdf'
                                    )
                                  }
                                  disabled={disabledDownloadButtonCsvAndPdf(
                                    'pdf',
                                    quantity,
                                    limitGenerationPDF
                                  )}
                                >
                                  <Tooltip
                                    title={
                                      <p style={{ fontSize: '14px' }}>
                                        {messagemGeneratePdf}
                                      </p>
                                    }
                                    arrow
                                  >
                                    <img
                                      src={pdfImage}
                                      alt="pdf"
                                      style={ImageSize}
                                    />
                                  </Tooltip>
                                </button>
                              )}
                            </div>

                            <button
                              style={ImageButton}
                              onClick={() =>
                                downloadMedia(
                                  'csv',
                                  'application/csv',
                                  'Relatório_de_Chamada_Realizada.csv'
                                )
                              }
                              disabled={disabledDownloadButtonCsvAndPdf(
                                'csv',
                                quantity,
                                limitGenerationPDF
                              )}
                            >
                              <Tooltip
                                title={
                                  <p style={{ fontSize: '14px' }}>
                                    {messagemGenerateCsv}
                                  </p>
                                }
                                arrow
                              >
                                <img
                                  src={csvImage}
                                  alt="csv"
                                  style={ImageSize}
                                />
                              </Tooltip>
                            </button>
                          </Col>
                        </Row>

                        <Button
                          style={buttonStyle(systemConfiguration.primary_color)}
                          type="submit"
                        >
                          Buscar
                        </Button>
                        <Row className="mb-3">
                          <Col
                            md="3"
                            style={{ marginTop: '-45px', marginLeft: '110px' }}
                          >
                            <ItemPerPageSelect
                              valueController={setItemsPerPage}
                            ></ItemPerPageSelect>
                          </Col>
                        </Row>
                      </Form>
                    </>
                  )}
                </CardBody>
                {loadingTable ? (
                  <div className="py-4 table-responsive">
                    <Row className="justify-content-md-center">
                      {loadingTable && (
                        <Loader
                          type="TailSpin"
                          color={systemConfiguration.primary_color}
                          height={100}
                          width={100}
                        />
                      )}
                    </Row>
                  </div>
                ) : (
                  <>
                    {reportType === 1 && dataToSubmitDownloads && (
                      <ReportAnalythical
                        showReports={ReportTypeAnalytical}
                        quantity={quantity}
                        extraDataFromAnalyticalReport={
                          extraDataFromAnalyticalReport
                        }
                      />
                    )}
                    {reportType === 2 && dataToSubmitDownloads && (
                      <ReportSynthetical
                        showReports={ReportTypeSynthetical}
                        list={chartDataList}
                        typeReport={dataToSubmitDownloads.reportType}
                      />
                    )}
                    {reportType === 3 && dataToSubmitDownloads && (
                      <ReportDetailedSynthetic
                        dataToSend={dataToSubmitDownloads}
                        showReports={ReportTypeDetailedSynthetic}
                        totalRegister={quantity}
                      />
                    )}
                    {dataToSubmitDownloads && (
                      <Pagination
                        itemsCountPerPage={itemsPerPage}
                        activePage={activePage}
                        totalItemsCount={quantity}
                        onChange={handlePageChange.bind(this)}
                      />
                    )}
                  </>
                )}
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CallMadeReport;
