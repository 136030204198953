import React, { useCallback } from 'react';

import TemplateMessageFillingItem from '../TemplateMessageFillingItem';
import { templateParamsRegex } from '../../../utils';
import { Row } from 'reactstrap';

const TemplateMessageFilling = ({
  templateMessage,
  templateParams,
  setTemplateParams,
  clientFields,
  isUpdate,
  templateType = `${templateType}`
}) => {
  const onChangeParam = useCallback(
    (paramIdentifier, { type, value }) => {
      if (templateParams !== {}) {
        const newParams = { ...templateParams };
        newParams[paramIdentifier] = { type, value };
        setTemplateParams(newParams);
      }
    },
    [templateParams, setTemplateParams]
  );

  const extractParams = (message = '') => {
    return message.match(templateParamsRegex);
  };

  const params = extractParams(templateMessage);

  return (
    <>
      <div className="my-3">
        {params &&
          params.map((param) => (
            <Row key={param} className="my-1">
              <TemplateMessageFillingItem
                paramIdendifier={param}
                clientFields={clientFields}
                templateParams={templateParams}
                onChangeParam={onChangeParam}
                isUpdate={isUpdate}
                templateType={templateType}
              />
            </Row>
          ))}
      </div>
    </>
  );
};

export default TemplateMessageFilling;
