import styled from 'styled-components';

export const ChipContainer = styled.div`
  .chipTrue {
    background: #00a17c;
    color: #fff;
  }
  .chipFalse {
    background: #ee6363;
    color: #fff;
  }
`;
