import React from 'react';
import { useSelector } from 'react-redux';

import { CompDate } from './style';

export const FormatDate = ({ date }) => {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  let dateAux = `${new Date(date).getDate()}/${new Date(
    date
  ).getMonth()}/${new Date(date).getFullYear()}`;
  let dateNow = `${new Date().getDate()}/${new Date().getMonth()}/${new Date().getFullYear()}`;

  return (
    <CompDate
      fontColor={systemConfiguration.internal_chat_font_color}
      color={systemConfiguration.primary_color}
    >
      {dateAux !== dateNow ? (
        <div className="content-date-message-internal-chat">{dateAux}</div>
      ) : (
        <div className="content-date-message-internal-chat">hoje</div>
      )}
    </CompDate>
  );
};
