import React, { useCallback, useState } from 'react';
import { Button } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {
  individual_column_style,
  individual_header_style,
  icon_column_style,
  individual_icon_header_style
} from '../../../../../../../../assets/styles/table_custom_style';
import BootstrapTable from 'react-bootstrap-table-next';
import { useHistory } from 'react-router-dom';
import { toBrazilianFullDate } from 'views/CRM/shared/utils/date';
import AccountService from '../../../../../services/accountService';
import { toast } from 'react-toastify';
import ModalConfirmation from 'components/Modal/ConfirmationModal';

import './styles.css';

const ListAccountsTable = ({ accounts, reloadAccounts }) => {
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [accountId, setAccountId] = useState(null);
  const history = useHistory();

  const handleDeleteAccount = useCallback(
    (id) => {
      AccountService.deleteAccount(id)
        .catch((err) => {
          const message =
            err?.response?.data?.message || 'Contato deletado com sucesso';
          toast.error(message);
        })
        .finally(() => {
          setOpenModalConfirmation(false);
          reloadAccounts();
        });
    },
    [reloadAccounts]
  );

  const navigateToEditAccountPage = (account) => {
    history.push(`/admin/crm/accounts/${account.recordTypeId}/${account.id}`);
  };

  const controlDeleteAccount = (id) => {
    setAccountId(id);
    setOpenModalConfirmation(true);
  };

  return (
    <>
      <ToolkitProvider
        data={accounts || []}
        keyField="id"
        columns={[
          {
            dataField: 'name',
            text: 'Nome',
            sort: true,
            style: individual_column_style,
            headerStyle: { ...individual_header_style, cursor: 'pointer' },
            formatter: (_, row) => {
              return (
                <span
                  className="account-name"
                  onClick={() => {
                    navigateToEditAccountPage(row);
                  }}
                >
                  {row.name}
                </span>
              );
            }
          },
          {
            dataField: 'phone',
            text: 'Telefone',
            style: individual_column_style
          },
          {
            dataField: 'createdAt',
            text: 'Criado em',
            style: individual_column_style,
            headerStyle: { ...individual_header_style, cursor: 'pointer' },
            formatter: (row) => {
              if (row) return toBrazilianFullDate(row);
            }
          },
          {
            dataField: 'createdByName',
            text: 'Criado por',
            style: individual_column_style,
            headerStyle: { ...individual_header_style, cursor: 'pointer' }
          },
          {
            dataField: 'lastUpdatedAt',
            text: 'Última atualização',
            style: individual_column_style,
            headerStyle: { ...individual_header_style, cursor: 'pointer' },
            formatter: (row) => {
              if (row) return toBrazilianFullDate(row);
            }
          },
          {
            dataField: 'edit',
            text: 'Editar',
            style: icon_column_style,
            headerStyle: individual_icon_header_style,
            formatter: (_, row) => {
              return (
                <Button
                  size="sm"
                  color="info"
                  onClick={() => navigateToEditAccountPage(row)}
                >
                  <li className="fas fa-user-edit"></li>
                </Button>
              );
            }
          },
          {
            dataField: 'delete',
            text: 'Excluir',
            style: icon_column_style,
            headerStyle: individual_icon_header_style,
            formatter: (_, row) => {
              return (
                <Button
                  size="sm"
                  color="danger"
                  onClick={() => controlDeleteAccount(row.id)}
                >
                  <li className="fas fa-trash"></li>
                </Button>
              );
            }
          }
        ]}
      >
        {(props) => (
          <div className="py-4 table-responsive">
            <BootstrapTable
              {...props.baseProps}
              bootstrap4={true}
              bordered={false}
              classes="align-items-center"
            />
          </div>
        )}
      </ToolkitProvider>

      <ModalConfirmation
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={accountId}
        deleteResource={handleDeleteAccount}
        message={
          'Tem certeza que deseja excluir esta conta? Todas as oportunidades e contatos relacionados a ela serão juntamente excluídas.'
        }
      />
    </>
  );
};

export default ListAccountsTable;
