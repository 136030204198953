import React from 'react';
import { useSelector } from 'react-redux';
import { FaCopy } from 'react-icons/fa';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';

export function CopyButton({ copyToClipBoard }) {
  const systemConfiguration = useSelector((state) => state.systemConfiguration);
  const MESSAGECOPYLINK = 'Clique aqui para copiar o link';

  return (
    <button
      style={buttonStyle(systemConfiguration.primary_color)}
      onClick={copyToClipBoard}
      title={MESSAGECOPYLINK}
    >
      <FaCopy />
    </button>
  );
}
