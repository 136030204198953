import ServiceCRUD from '../../../../../services/ServiceCRUDFLEXCHAT';
import { apiChat } from '../../../../../http';

class WordCloudService extends ServiceCRUD {
  constructor() {
    super('relatorio');
  }

  getWordCloud(queryParams) {
    return apiChat.get(`${this.path}/nuvem-de-palavras`, {
      params: {
        ...queryParams
      }
    });
  }
}

export default new WordCloudService();
