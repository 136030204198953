import React, { useCallback } from 'react';
import PausaAutomaticModal from '../../../../Modal/PausaAutomaticModal';
import { Row, Button } from 'reactstrap';

const ModalPauseConfirmation = ({ isModalOpen, onModalToggle, ...props }) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  return (
    <PausaAutomaticModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Confirmação de pausa automática"
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <Row className="mb-1">
          <h6
            className="modal-title"
            id="modal-title-default"
            style={{ fontSize: '1rem', marginLeft: '1.5rem' }}
          >
            O usuário entrou em pausa automática
          </h6>
          <Button
            color="danger"
            onClick={closeModal}
            style={{ marginLeft: '7rem', marginTop: '2rem' }}
          >
            Confirmar
          </Button>
        </Row>
      </>
    </PausaAutomaticModal>
  );
};

export default ModalPauseConfirmation;
