import { apiCrm } from '../../../../../http';

class OpportunityRevenueService {
  async getReportSyntheticOpportunityRevenue(recordTypeId, params = {}) {
    return apiCrm.get(`opportunity-revenue-report-synthetic/${recordTypeId}`, {
      params
    });
  }

  async getReportPdfOpportunityRevenue(recordTypeId, params = {}) {
    return apiCrm.get(`opportunity-revenue-report/pdf/${recordTypeId}`, {
      params,
      responseType: 'arraybuffer'
    });
  }
  async getReportXlsxOpportunityRevenue(recordTypeId, params = {}) {
    return apiCrm.get(`opportunity-revenue-report/xlsx/${recordTypeId}`, {
      params,
      responseType: 'arraybuffer'
    });
  }
  async getReportCsvOpportunityRevenue(recordTypeId, params = {}) {
    return apiCrm.get(`opportunity-revenue-report/csv/${recordTypeId}`, {
      params,
      responseType: 'arraybuffer'
    });
  }
}

export default new OpportunityRevenueService();
