import React from 'react';
//import { useSelector } from 'react-redux';
import { Card, CardBody, CardTitle, Row } from 'reactstrap';

const CardItem = ({ title, text, subText, tooltip, icon }) => {
  //const systemConfiguration = useSelector((state) => state.systemConfiguration);
  return (
    <Card
      className="card-stats"
      style={{
        fontSize: '3rem',
        height: '10rem',
        marginTop: '1rem',
        marginBottom: '1rem'
      }}
    >
      <CardBody>
        <Row>
          <div className="col mt-3 mb-3">
            <CardTitle tag="h3" className=" text-dark mb-0">
              {title}
              <i
                className={icon}
                //icon="fa-light fa-circle-info"
                size="sm"
                datatoggle="tooltip"
                dataplacement="top"
                title={tooltip}
                datacontainer="body"
                dataanimation="true"
                style={{
                  position: 'absolute',
                  top: '6px',
                  right: '20px',
                  marginLeft: '15px',
                  cursor: 'pointer',
                  fontSize: '16px',
                  color: '#707a95'
                }}
              />
            </CardTitle>
            <span className="h1 font-weight-bold text-dark mb-0">{text}</span>
            <p>{subText}</p>
          </div>
          {/* <Col className="col-auto mt-3 mb-3">
            <div className="icon icon-shape bg-white rounded-circle shadow">
              <i className={icon} />
            </div>
          </Col> */}
        </Row>
      </CardBody>
    </Card>
  );
};

export default CardItem;
