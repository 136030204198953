export const receiveCasts = (getTimeSchedule) => {
  if (getTimeSchedule.segunda_inicial === null)
    getTimeSchedule.segunda_inicial = '';
  if (getTimeSchedule.segunda_final === null)
    getTimeSchedule.segunda_final = '';
  if (getTimeSchedule.terca_inicial === null)
    getTimeSchedule.terca_inicial = '';
  if (getTimeSchedule.terca_final === null) getTimeSchedule.terca_final = '';
  if (getTimeSchedule.quarta_inicial === null)
    getTimeSchedule.quarta_inicial = '';
  if (getTimeSchedule.quarta_final === null) getTimeSchedule.quarta_final = '';
  if (getTimeSchedule.quinta_inicial === null)
    getTimeSchedule.quinta_inicial = '';
  if (getTimeSchedule.quinta_final === null) getTimeSchedule.quinta_final = '';
  if (getTimeSchedule.sexta_inicial === null)
    getTimeSchedule.sexta_inicial = '';
  if (getTimeSchedule.sexta_final === null) getTimeSchedule.sexta_final = '';
  if (getTimeSchedule.sabado_inicial === null)
    getTimeSchedule.sabado_inicial = '';
  if (getTimeSchedule.sabado_final === null) getTimeSchedule.sabado_final = '';
  if (getTimeSchedule.domingo_inicial === null)
    getTimeSchedule.domingo_inicial = '';
  if (getTimeSchedule.domingo_final === null)
    getTimeSchedule.domingo_final = '';

  return getTimeSchedule;
};

export const sendCasts = (formatedData) => {
  if (formatedData.segunda_inicial === '') formatedData.segunda_inicial = null;
  if (formatedData.segunda_final === '') formatedData.segunda_final = null;
  if (formatedData.terca_inicial === '') formatedData.terca_inicial = null;
  if (formatedData.terca_final === '') formatedData.terca_final = null;
  if (formatedData.quarta_inicial === '') formatedData.quarta_inicial = null;
  if (formatedData.quarta_final === '') formatedData.quarta_final = null;
  if (formatedData.quinta_inicial === '') formatedData.quinta_inicial = null;
  if (formatedData.quinta_final === '') formatedData.quinta_final = null;
  if (formatedData.sexta_inicial === '') formatedData.sexta_inicial = null;
  if (formatedData.sexta_final === '') formatedData.sexta_final = null;
  if (formatedData.sabado_inicial === '') formatedData.sabado_inicial = null;
  if (formatedData.sabado_final === '') formatedData.sabado_final = null;
  if (formatedData.domingo_inicial === '') formatedData.domingo_inicial = null;
  if (formatedData.domingo_final === '') formatedData.domingo_final = null;

  return formatedData;
};
