import styled from 'styled-components';

export const SvgContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  path {
    stroke: ${(props) => props.color};
  }
`;
