import React from 'react';
import HeaderBar from '../HeaderBar';
import { Col, Container, Row } from 'reactstrap';
import AgentCard from '../../../Monitoring/Queue/MonitoringScreen/components/QueueMembers/QueueMemberCard';

const AgentCardsContainer = ({ callBackName, agents }) => {
  return (
    <Container>
      <Row>
        <Col>
          <HeaderBar
            title={`Monitoramento Callback - ${callBackName}`}
            classname="mb-2"
          />
          <div
            id="agents"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fill,minmax(11rem,2fr))',
              gap: '1rem'
            }}
          >
            {agents &&
              agents.map((agent, index) => (
                <AgentCard data={agent} key={index} />
              ))}
          </div>
        </Col>
      </Row>
    </Container>

    // <div>
    //   <div id="header">
    //     <HeaderBar title={`Discador: ${dialerName}`} />
    //   </div>
    //   <div id="agents" style={{ display: 'flex' }}>
    //     {agents &&
    //       agents.map((agent, index) => <AgentCard data={agent} key={index} />)}
    //   </div>
    // </div>
  );
};

export default AgentCardsContainer;
