import React from 'react';

import { Row, Card } from 'reactstrap';

import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { TotalRegister } from '../../../../components/TotalRegister';

import {
  individual_column_style,
  individual_header_style,
  sortingHeaderStyle
} from '../../../../assets/styles/table_custom_style';

//dado para exibir devem vir, via props
const performace = ({ showReports, totalRegister }) => {
  return (
    <>
      <Row>
        <div className="col">
          <div className="card-wrapper">
          {totalRegister >= 0 && (
              <TotalRegister totalRegister={totalRegister} />
            )}
            <Card>
              <ToolkitProvider
                data={showReports}
                keyField="id"
                columns={[
                  {
                    dataField: 'user',
                    text: 'Usuário',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    sortingHeaderStyle
                  },
                  {
                    dataField: 'callReceive',
                    text: 'Chamadas Recebidas',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'attendanceChat',
                    text: 'Atendimento Chat',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'protocolOpen',
                    text: 'Protocolos Abertos',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'madeCall',
                    text: 'Chamada Realizadas',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'average',
                    text: 'Média chat',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style,
                    formatter: (_, { average }) => {
                      return average.toFixed(2);
                    }
                  },
                  {
                    dataField: 'tmaChat',
                    text: 'TMA chat',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tmeChat',
                    text: 'TME chat',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'ttaChat',
                    text: 'TTA chat',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'tmaCalls',
                    text: 'TMA chaMADAS',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  },
                  {
                    dataField: 'ttaCalls',
                    text: 'TTA CHAMADAS',
                    sort: true,
                    style: individual_column_style,
                    headerStyle: individual_header_style
                  }
                ]}
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                      classes="align-items-center"
                    />
                  </div>
                )}
              </ToolkitProvider>
            </Card>
          </div>
        </div>
      </Row>
    </>
  );
};
export default performace;
