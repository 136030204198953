import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import authReduce from './store/reduce/auth';
import answeredConversationReduce from './store/reduce/answered-conversations';
import unAnsweredConversationReduce from './store/reduce/unanswered-conversations';
import conversationInAttendanceReduce from './store/reduce/conversation-in-attendance';
import createObject from './store/reduce/reducerChatConfig';
import telephonyServicePanelReducer from './store/reduce/telephonyServicePanelReducer';
import navigationSoftphone from './store/reduce/nextNavigation';
import sidebarReducer from './store/reduce/sidebarReducer';
import systemConfiguration from './store/reduce/systemConfiguration';
import activeChatInternal from './store/reduce/reducerInternalChat';
import orderListUser from './store/reduce/orderInternalChat';
import sideDrawerReducer from './store/reduce/sideDrawerReducer';
import contactEventsReducer from './store/reduce/contactEventsReducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
  auth: authReduce,
  answeredConversation: answeredConversationReduce,
  unAnsweredConversation: unAnsweredConversationReduce,
  conversationInAttendance: conversationInAttendanceReduce,
  telephonyServicePanel: telephonyServicePanelReducer,
  createObject,
  navigationSoftphone,
  sidebarReducer,
  systemConfiguration,
  activeChatInternal,
  orderListUser,
  sideDrawer: sideDrawerReducer,
  contactEvents: contactEventsReducer
});

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunk))
);

export default store;
