import React from 'react';
import styled from 'styled-components';
import { FaPlusCircle } from 'react-icons/fa';

export const AddNewContactButton = ({ onClick }) => {
  return (
    <ButtonWrapper onClick={onClick}>
      <FaPlusCircle
        style={{
          fontSize: '1.6rem',
          color: 'rgb(0, 140, 0)',
          cursor: 'pointer',
          paddingLeft: '0.2rem'
        }}
        id="tour4"
      />
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  display: inline;

  &:hover > svg {
    transform: scale(1.1);
  }
`;
