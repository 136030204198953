/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Service from '../service';
import { toast } from 'react-toastify';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from 'components/Pagination';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  Input,
  Col,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from 'reactstrap';

import SimpleHeader from '../../../components/Headers/SimpleHeader';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import Modal from '../../../components/Modal/NotificationModal';
import ReactLoader from 'components/Loader';

import {
  icon_column_style,
  individual_column_style,
  individual_icon_header_style,
  individual_icon_header_style_callback,
  individual_header_style
} from '../../../assets/styles/table_custom_style';

const ExtensionList = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [callbacks, setCallbacks] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [showDisabled, setShowDisable] = useState(false);

  useEffect(() => {
    handleGetExtensions();
  }, [showDisabled]);

  const handleGetExtensions = (selectedPage = 1, querySearch = '') => {
    Service.getAll(false, selectedPage, 10, showDisabled, querySearch)
      .then((res) => {
        const { data, totalRegisters } = res.data;
        const newActivePage = selectedPage || activePage;

        setCallbacks(data);
        setQuantity(totalRegisters);
        setActivePage(newActivePage);
        setLoading(false);
      })
      .catch(() => {
        toast.error('Não foi possível listar as callbacks', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const handlePageChange = (activePage) => handleGetExtensions(activePage);

  const toggleDisable = (id, disable) => {
    if (!disable) {
      handleDisable(id);
    } else {
      handleRecovery(id);
    }
  };

  const handleDisable = (id) => {
    if (!window.confirm('Deseja desabilitar Callback?')) {
      handleGetExtensions();
    } else {
      Service.delete(id)
        .then(() => {
          toast.success('Callback desabilitada com sucesso', {
            autoClose: 3000,
            closeOnClick: true
          });
          handleGetExtensions();
        })
        .catch((err) => {
          console.log(err.response);
          toast.error('Ocorreu um erro!', {
            autoClose: 3000,
            closeOnClick: true
          });
        });
    }
  };

  const handleRecovery = (id) => {
    Service.recovery(id)
      .then(() => {
        toast.success('Callback habilitada com sucesso', {
          autoClose: 3000,
          closeOnClick: true
        });
        setShowDisable(false);
        handleGetExtensions();
      })
      .catch((err) => {
        console.log(err.response.data);
        toast.error('Ocorreu um erro!', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const handleUpdate = ({ id }) => {
    history.push(`callback/form/${id}`);
  };

  const handleOnSearch = (event) => {
    const q = event.target.value;
    setTimeout(() => {
      handleGetExtensions(1, q);
    }, 500);
  };

  const handleOpenDashboard = (queueId, queueName) => {
    //console.log(queueId, queueName);
    window.open(
      `${window.location.origin}/callback/${queueId}/dashboard?name=${queueName}?queuename=${queueName}`,
      '_blank',
      'width=' +
        window.screen.availWidth +
        ',height=' +
        window.screen.availHeight
    );
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/callback/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Callbacks</h3>
                </CardHeader>

                <Row className="mt-3 mx-2">
                  <Col md="8">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fas fa-search" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder="Busca callback por nome"
                        type="text"
                        defaultValue=""
                        onChange={handleOnSearch}
                      />
                    </InputGroup>
                  </Col>
                  <Col md="4" className="text-right mt-2 mt-md-1">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={showDisabled}
                          onChange={() => setShowDisable(!showDisabled)}
                        />
                        <span>Mostrar desabilitados</span>
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <ToolkitProvider
                  data={callbacks}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'queue.name',
                      text: 'Fila',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'type',
                      text: 'estratégia',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'dashboad',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Dashboard',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            disabled={false}
                            color="success"
                            size="sm"
                            onClick={() => {
                              handleOpenDashboard(row.id, row.queue.name);
                            }}
                          >
                            <li className="fas fa-chart-pie"></li>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'clone',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Configurações',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Modal name={row.name}>
                            <p>
                              <strong>
                                Verificar quantidade de agentes livres:
                              </strong>
                              {row.check_agent ? 'Sim' : 'Não'}
                            </p>
                            <p>
                              <strong>Quantidade máxima de tentativas: </strong>
                              {row.attempts}
                            </p>
                            <p>
                              <strong>Intervalo entre tentativas: </strong>
                              {row.attempt_interval}
                            </p>
                            <p>
                              <strong>
                                Retorna apenas chamadas perdidas no dia:
                              </strong>
                              {row.only_day ? 'Sim' : 'Não'}
                            </p>
                          </Modal>
                        );
                      }
                    },
                    {
                      dataField: 'disable',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style_callback,
                      text: 'Desabilitar / Habilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            checked={!row.disable}
                            id={row.name}
                            onChange={() => {
                              // console.log('aqui');
                              toggleDisable(row.id, row.disable);
                            }}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="info"
                            // outline
                            type="button"
                            size="sm"
                            disabled={row.disable}
                            onClick={() => handleUpdate(row)}
                          >
                            <li className="fas fa-user-edit"></li>
                          </Button>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ExtensionList;
