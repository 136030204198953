import { useEffect } from 'react';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';
import { useConectionSocketAdmConversation } from '../../hooks/useSocketAdmConversations';
import { removeUnAnsweredConversation } from 'store/action/unanswered-convertations-action';
import { removeConversationInAttendance } from 'store/action/conversation-in-attendance-action';
import { PlayNewAlert } from './components/Alerts';
import { useSelector } from 'react-redux';
import store from 'store';
import ConfigurationService from '../../views/SystemParams/services';
import { useSocketConversationInstagram } from 'hooks/useSocketConversationInstagram';

const Notification = () => {
  const { socketAdm } = useConectionSocketAdmConversation();
  const { socketConversation } = useConversationConnectionSocketForMonitoring();
  const { socketInstagramConversation: socketInstagram } =
    useSocketConversationInstagram();
  const conversationInAttendance = useSelector(
    (state) => state.conversationInAttendance
  );
  useEffect(() => {
    if (!window.Notification) {
      return;
    }
    window.Notification.requestPermission().then((permission) => {
      return permission;
    });
    // "denied" (o usuário se recusa a ter notificações exibidas),
    // "granted" (o usuário aceita ter notificações exibidas) ou
    // "default" (a escolha do usuário é desconhecido e, portanto, o navegador irá agir como se o valor foram negados).
  }, []);

  useEffect(() => {
    ConfigurationService.getAllConfigs()
      .then((response) => {
        const audioNotification = response.data.audio_chat;
        socketConversation.on('cliente_enviou_mensagem_portal_chat', (data) => {
          let permission = window.Notification.permission;
          let type = 'new menssage';
          PlayNewAlert(data, permission, type, audioNotification);
        });
        socketInstagram.on('cliente_enviou_mensagem', (data) => {
          let permission = window.Notification.permission;
          let type = 'new menssage';
          PlayNewAlert(data, permission, type, audioNotification);
        });
        socketAdm.on('entrou_na_fila', (data) => {
          const attendantQueues = JSON.parse(localStorage.getItem('queues'));
          if (attendantQueues) {
            attendantQueues.forEach(async (attendantQueue) => {
              if (
                data.conversa?.fila &&
                data.conversa.fila === attendantQueue.name
              ) {
                let permission = window.Notification.permission;
                let type = 'client in queue';
                PlayNewAlert(
                  data.conversa,
                  permission,
                  type,
                  audioNotification
                );
              }
            });
          }
        });
        socketConversation.on(
          'conversa_transferida_pelo_bot_para_atendente',
          (data) => {
            const attendantLogin = localStorage.getItem('login');
            const { conversa } = data;
            if (
              conversa.atendente.id_postgres === attendantLogin &&
              conversa.atendida
            ) {
              let permission = window.Notification.permission;
              let type = 'exclusive conversation';
              PlayNewAlert(conversa, permission, type, audioNotification);
            }
          }
        );

        socketInstagram.on(
          'conversa_transferida_pelo_bot_para_atendente',
          (data) => {
            const attendantLogin = localStorage.getItem('login');
            const { conversa } = data;
            if (
              conversa.atendente.id_postgres === attendantLogin &&
              conversa.atendida
            ) {
              let permission = window.Notification.permission;
              let type = 'exclusive conversation';
              PlayNewAlert(conversa, permission, type, audioNotification);
            }
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, [socketConversation, socketAdm]);

  useEffect(() => {
    socketAdm.on('conversa_abandonada_cliente', async (data) => {
      if (data.id === conversationInAttendance.conversation._id) {
        await store.dispatch(removeConversationInAttendance());
      }

      await store.dispatch(removeUnAnsweredConversation(data.id));
    });

    return () => socketAdm.off('conversa_abandonada_cliente');
  }, [socketAdm, conversationInAttendance]);

  return null;
};

export default Notification;
