export const sortData = (data) => {
  const dataSort = data.sort((a, b) => {
    if (a.cause_title) {
      if (a.cause_title < b.cause_title) return -1;
      if (a.cause_title > b.cause_title) return 1;
      return 0;
    } else {
      if (a.cause_title < b.cause_title) return -1;
      if (a.cause_title > b.cause_title) return 1;
      return 0;
    }
  });

  return dataSort;
};
