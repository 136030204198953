import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import { toast } from 'react-toastify';
import { ErrorMessage } from '@hookform/error-message';
import {
  Container,
  Row,
  Card,
  Form,
  Col,
  Input,
  CardHeader,
  CardBody
} from 'reactstrap';

import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ReactLoader from 'components/Loader';
import ReactButton from 'components/Buttons/ReactButton';
import FlexSelect from 'components/Inputs/FlexSelect';

import { useListLeadProcesses } from 'views/CRM/LeadProcess/hooks/useListLeadProcesses';
import { useListSalesProcesses } from 'views/CRM/SalesProcess/hooks/useListSalesProcesses';
import { useListProtocolProcesses } from 'views/CRM/ProtocolProcess/hooks/useListProtocolProcesses';

import RecordTypeService from '../service';

const RecordTypeForm = () => {
  const history = useHistory();
  const [isUpdate, setIsUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [recordType, setRecordType] = useState(null);

  const { id } = useParams();
  const { reset, control, handleSubmit, errors, watch, setValue } = useForm();
  const { leadProcesses, getLeadProcesses } = useListLeadProcesses();
  const { salesProcesses, getSalesProcesses } = useListSalesProcesses();
  const { protocolProcesses, getProtocolProcesses } =
    useListProtocolProcesses();

  const selectedEntity = watch('entity');
  const options = useMemo(
    () => [
      { value: 'PROTOCOL', label: 'Protocolo' },
      { value: 'CONTACT', label: 'Contato' },
      { value: 'ACCOUNT', label: 'Conta' },
      { value: 'OPPORTUNITY', label: 'Oportunidade' },
      { value: 'LEAD', label: 'Lead' }
    ],
    []
  );

  const getLeadStageProcessesOptions = useCallback(() => {
    if (!leadProcesses) return [];
    return leadProcesses.map((leadProcess) => ({
      value: leadProcess.id,
      label: leadProcess.name
    }));
  }, [leadProcesses]);

  const getSalesStageProcessesOptions = useCallback(() => {
    if (!salesProcesses) return [];
    return salesProcesses.map((salesProcess) => ({
      value: salesProcess.id,
      label: salesProcess.name
    }));
  }, [salesProcesses]);

  const getProtocolProcessesOptions = useCallback(() => {
    if (!protocolProcesses) return [];
    return protocolProcesses.map((protocolProcess) => ({
      value: protocolProcess.id,
      label: protocolProcess.name
    }));
  }, [protocolProcesses]);

  useEffect(() => {
    getLeadProcesses(1, {}, 500);
    getSalesProcesses(1, {}, 500);
    getProtocolProcesses(1, {}, 500);
  }, [getLeadProcesses, getSalesProcesses, getProtocolProcesses]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      RecordTypeService.get(id)
        .then((res) => {
          const recordTypeData = res.data.content;
          reset(recordTypeData);
          setRecordType(recordTypeData);
          setIsUpdate(true);
        })
        .catch((error) => {
          const errorMsg = error.response?.data?.message;
          toast.error(
            errorMsg || 'Erro ao buscar tipo de registro para edição'
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id, reset]);

  const onSubmit = async (data) => {
    try {
      const formatedData = { ...data };

      isUpdate
        ? await RecordTypeService.update(id, formatedData)
        : await RecordTypeService.create(formatedData);

      const msg = isUpdate
        ? 'Tipo de registro alterado com sucesso'
        : 'Tipo de registro criado com sucesso';

      toast.success(msg);

      history.push('/admin/recordType');
    } catch (err) {
      const msg = err.response?.data?.message;
      toast.error(msg || 'Erro ao cadastrar tipo de registro', {
        autoClose: 3000,
        closeOnClick: true
      });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  const cancelAction = () => {
    history.push('/admin/recordType');
  };

  return (
    <>
      <SimpleHeader
        name="Form validation"
        parentName="Forms"
        returnPath="/admin/recordType"
        buttonTitle="Voltar para a pagina de tipos de registro"
      />
      <Container className="mt--6" fluid>
        <Row className="mb-3">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3 className="mb-0">Tipo de registro</h3>
                </CardHeader>
                <CardBody>
                  {loading ? (
                    <Row className="justify-content-md-center">
                      <ReactLoader />
                    </Row>
                  ) : (
                    <Form
                      className="needs-validation"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <Row className="mb-3">
                        <Col md="6">
                          <label className="form-control-label">Nome*</label>
                          <Controller
                            as={Input}
                            control={control}
                            name="name"
                            rules={{
                              required: 'Campo obrigatório',
                              maxLength: 255
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="name"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                        <Col md="6">
                          <label className="form-control-label">
                            Entidade*
                          </label>
                          <Controller
                            render={(props) => (
                              <FlexSelect
                                isClearable={false}
                                dataOptions={options}
                                isMulti={false}
                                closeMenuOnSelect={true}
                                value={props.value}
                                valueController={setValue}
                                fieldName="entity"
                                labelName="label"
                                valueName="value"
                                isDisabled={isUpdate}
                              />
                            )}
                            control={control}
                            name="entity"
                            rules={{
                              required: 'Campo obrigatório'
                            }}
                            defaultValue=""
                          />
                          <ErrorMessage
                            errors={errors}
                            name="entity"
                            render={({ message }) => errorFormMessage(message)}
                          />
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        {((selectedEntity && selectedEntity === 'LEAD') ||
                          (recordType && recordType.entity === 'LEAD')) && (
                          <Col md="6">
                            <label className="form-control-label">
                              Processo*
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={false}
                                  dataOptions={getLeadStageProcessesOptions()}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="lead_process_id"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="lead_process_id"
                              rules={{
                                required: 'Campo obrigatório'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="lead_process_id"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className="mb-3">
                        {((selectedEntity &&
                          selectedEntity === 'OPPORTUNITY') ||
                          (recordType &&
                            recordType.entity === 'OPPORTUNITY')) && (
                          <Col md="6">
                            <label className="form-control-label">
                              Processo*
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={false}
                                  dataOptions={getSalesStageProcessesOptions()}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="sales_process_id"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="sales_process_id"
                              rules={{
                                required: 'Campo obrigatório'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="sales_process_id"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        )}
                      </Row>
                      <Row className="mb-3">
                        {((selectedEntity && selectedEntity === 'PROTOCOL') ||
                          (recordType && recordType.entity === 'PROTOCOL')) && (
                          <Col md="6">
                            <label className="form-control-label">
                              Processo de Atendimento*
                            </label>
                            <Controller
                              render={(props) => (
                                <FlexSelect
                                  isClearable={false}
                                  dataOptions={getProtocolProcessesOptions()}
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  value={props.value}
                                  valueController={setValue}
                                  fieldName="protocol_process_id"
                                  labelName="label"
                                  valueName="value"
                                />
                              )}
                              control={control}
                              name="protocol_process_id"
                              rules={{
                                required: 'Campo obrigatório'
                              }}
                              defaultValue=""
                            />
                            <ErrorMessage
                              errors={errors}
                              name="protocol_process_id"
                              render={({ message }) =>
                                errorFormMessage(message)
                              }
                            />
                          </Col>
                        )}
                      </Row>
                      <hr />
                      <ReactButton btnColor="confirmation" type="submit">
                        Salvar
                      </ReactButton>

                      <ReactButton
                        btnColor="cancelation"
                        onClick={() => cancelAction()}
                      >
                        Cancelar
                      </ReactButton>
                    </Form>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export { RecordTypeForm };
