import DefaultModal from '../../../../components/Modal/DefaultModal';
import React, { useCallback } from 'react';
import { Button, Row, Col } from 'reactstrap';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import { useSelector } from 'react-redux';

const ModalConfirmation = ({
  isModalOpen,
  onModalToggle,
  idResource,
  deleteResource,
  message,
  ...props
}) => {
  const closeModal = useCallback(() => onModalToggle(false), [onModalToggle]);
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Confirmação"
      showCloseButton={false}
      modalContainerClasses="modal-sm"
      {...props}
    >
      <>
        <h3 className="mb-0 align-text-center" style={{ textAlign: 'center' }}>
          {message}
        </h3>
        <hr />
        <Row>
          <Col md="8">
            <Button
              style={buttonStyle(systemConfiguration.confirmation_button_color)}
              onClick={() => deleteResource(idResource)}
            >
              Confirmar
            </Button>
          </Col>
          <Col md="4">
            <Button
              style={buttonStyle(systemConfiguration.cancelation_button_color)}
              onClick={closeModal}
            >
              Voltar
            </Button>
          </Col>
        </Row>
      </>
    </DefaultModal>
  );
};

export default ModalConfirmation;
