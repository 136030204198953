export function addInitAnsweredConversation(conversations) {
  return {
    type: '@ANSWERED_CONVERSATION/ADD_INIT_CONVERSATIONS',
    payload: {
      conversations
    }
  };
}

export function addAnsweredConversation(conversation) {
  return {
    type: '@ANSWERED_CONVERSATION/ADD_CONVERSATION',
    payload: {
      conversation
    }
  };
}

export function addMessageAnsweredConversation(
  message,
  idDaConversa,
  novas_mensagens
) {
  return {
    type: '@ANSWERED_CONVERSATION/ADD_MESSAGE_ANSWERED_CONVERSATION',
    payload: {
      message,
      idDaConversa,
      novas_mensagens
    }
  };
}

export function removeAnsweredConversation(id) {
  return {
    type: '@ANSWERED_CONVERSATION/REMOVE_CONVERSATION',
    payload: {
      id
    }
  };
}
