import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Row } from 'reactstrap';
import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import { handleObject } from '../../store/action/configurationsChat/loadConfigChat';

import Service from './service/index';

import { Form } from './style';

import { EmailTelephonyModule } from './modules/emailTelephony/index';
import { ClosingFields } from './modules/closingFields/index';
import { Timeout } from './modules/timeout/index';
import { TimeEndConversation } from './modules/timeEndConversation';
import ReactLoader from 'components/Loader';

// import PermissionsModule from './modules/permissions/index';

const ConfigurationsSystem = () => {
  const [loading, setLoading] = useState(true);

  const [control1, setControl1] = useState(false);
  const [control2, setControl2] = useState(false);
  const [control3, setControl3] = useState(false);
  const [control4, setControl4] = useState(false);
  const [control5, setControl5] = useState(false);
  const [control6, setControl6] = useState(false);
  const [control7, setControl7] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    Service.getAll()
      .then((response) => {
        //console.log('response', response);
        setLoading(false);
        dispatch(handleObject(response.data));
      })
      .catch((error) => {
        if (error.response) {
          toast.error('Erro ao carregar usuários!', {
            autoClose: 3000,
            closeOnClick: true
          });
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function controledPanel1(boll) {
    setControl1(boll);
  }

  function controledPanel2(boll) {
    setControl2(boll);
  }
  function controledPanel3(boll) {
    setControl3(boll);
  }
  function controledPanel4(boll) {
    setControl4(boll);
  }
  function controledPanel5(boll) {
    setControl5(boll);
  }
  function controledPanel6(boll) {
    setControl6(boll);
  }

  function controledPanel7(boll) {
    setControl7(boll);
  }

  return (
    <>
      {loading ? (
        <div className="py-4 responsive">
          <Row className="justify-content-md-center">
            <ReactLoader />
          </Row>
        </div>
      ) : (
        <Form>
          {/* <Accordion
            expanded={control1}
            onChange={() => setControl1(!control1)}
            className="accodion"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <strong>Webchat</strong>
            </AccordionSummary>
            <AccordionDetails>
              <ChatModule controledPanel1={controledPanel1} />
            </AccordionDetails>
          </Accordion> */}

          {/* <Accordion className="accodion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
          >
            <strong>Logos</strong>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>logos</Typography>
          </AccordionDetails>
        </Accordion> */}
          {/* <Accordion
            className="accodion"
            expanded={control3}
            onChange={() => setControl3(!control3)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel5a-content"
              id="panel5a-header"
            >
              <strong>Canais</strong>
            </AccordionSummary>
            <AccordionDetails>
              <ChannelModule controledPanel3={controledPanel3} />
            </AccordionDetails>
          </Accordion> */}
          <Accordion
            className="accodionEnd"
            expanded={control4}
            onChange={() => setControl4(!control4)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <strong>Email & Telefonia</strong>
            </AccordionSummary>
            <AccordionDetails>
              <EmailTelephonyModule controledPanel4={controledPanel4} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            className="accodionEnd"
            expanded={control5}
            onChange={() => setControl5(!control5)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <strong>Campos de encerramento</strong>
            </AccordionSummary>
            <AccordionDetails>
              <ClosingFields controledPanel5={controledPanel5} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            className="accodionEnd"
            expanded={control6}
            onChange={() => setControl6(!control6)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel6a-content"
              id="panel6a-header"
            >
              <strong>Inatividade</strong>
            </AccordionSummary>
            <AccordionDetails>
              <Timeout controledPanel6={controledPanel6} />
            </AccordionDetails>
          </Accordion>

          <Accordion
            className="accodionEnd"
            expanded={control7}
            onChange={() => setControl7(!control7)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel7a-content"
              id="panel7a-header"
            >
              <strong>Tempo de encerramento de conversa</strong>
            </AccordionSummary>

            <AccordionDetails>
              <TimeEndConversation controledPanel7={controledPanel7} />
            </AccordionDetails>
          </Accordion>
          {/* <Accordion className="accodion">
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
          >
            <strong>Permisões</strong>
          </AccordionSummary>
          <AccordionDetails>
            <PermissionsModule permissions={teste.alerta} />
          </AccordionDetails>
        </Accordion> */}
          {/* <input type="submit" /> */}
        </Form>
      )}
    </>
  );
};

export default ConfigurationsSystem;
