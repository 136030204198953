import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal, ModalBody, ModalFooter, Card, Row } from 'reactstrap';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { useConversationConnectionSocketForMonitoring } from 'hooks/useConversationConnectionSocketForMonitoring';
import { buttonStyle } from 'components/Containers/ConfigurationContainer/components/utils';
import {
  individual_column_style,
  individual_header_style,
  icon_column_style,
  individual_icon_header_style
} from 'assets/styles/table_custom_style';
import { toast } from 'react-toastify';
import UserService from '../../../../../../User/service';

const NotificationModal = ({ users }) => {
  const { socketConversation } = useConversationConnectionSocketForMonitoring();
  const [isOpen, setIsOpen] = useState(false);

  const userLogin = localStorage.getItem('login');
  const systemConfiguration = useSelector((state) => state.systemConfiguration);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const disconnectUser = (user) => {
    if (
      !window.confirm(
        'Tem certeza que deseja desconectar o atendente do sistema? Será necessário realizar o login novamente!'
      )
    )
      return;
    socketConversation.emit('atendente_logoff_ativo', { login: user });
  };

  const disableUser = (user) => {
    if (
      !window.confirm(
        'Tem certeza que deseja bloquear o atendente do sistema? Será necessário reativar o acesso!'
      )
    )
      return;
    UserService.changeActive(user, {
      active: false
    })
      .then(() => {
        toast.info('Usuário desabilitado com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
        socketConversation.emit('atendente_logoff_ativo', { login: user });
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Ops algo errado aconteceu!', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  return (
    <>
      <Button
        color="success"
        title="Visualizar atendentes logados no sistema"
        outline
        size="sm"
        type="button"
        style={{
          ...buttonStyle(systemConfiguration.primary_color),
          width: '100%',
          height: '100%'
        }}
        onClick={toggleModal}
      >
        Usuários Logados
      </Button>

      <Modal
        className="modal-dialog-centered"
        size="lg"
        isOpen={isOpen}
        toggle={toggleModal}
      >
        <div className="modal-header">
          <h6 className="modal-title" id="modal-title-default">
            Usuários Logados
          </h6>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <ModalBody>
          <hr style={{ marginTop: '-30px' }} />

          {users.length > 0 && (
            <Row className="mt-3">
              <div className="col">
                <div className="card-wrapper">
                  <Card>
                    <ToolkitProvider
                      data={users}
                      keyField="login"
                      columns={[
                        {
                          dataField: 'name',
                          text: 'Nome',
                          sort: true,
                          style: individual_column_style,
                          headerStyle: individual_header_style
                        },
                        {
                          dataField: 'login',
                          text: 'Login',
                          sort: true,
                          style: individual_column_style,
                          headerStyle: individual_header_style
                        },
                        {
                          dataField: 'disconnect',
                          style: icon_column_style,
                          headerStyle: individual_icon_header_style,
                          text: 'Desconectar',
                          // eslint-disable-next-line react/display-name
                          formatter: (_, row) => {
                            return (
                              <Button
                                size="sm"
                                color="warning"
                                disabled={
                                  userLogin === row.login || row.login === 'adm'
                                    ? true
                                    : false
                                }
                                title="Desconectar usuário do sistema"
                                onClick={() => disconnectUser(row.login)}
                              >
                                <li className="fas fa-user-times"></li>
                              </Button>
                            );
                          }
                        },
                        {
                          dataField: 'block',
                          style: icon_column_style,
                          headerStyle: individual_icon_header_style,
                          text: 'Bloquear',
                          // eslint-disable-next-line react/display-name
                          formatter: (_, row) => {
                            return (
                              <Button
                                size="sm"
                                color="danger"
                                title="Bloquear usuário do sistema"
                                disabled={
                                  userLogin === row.login || row.login === 'adm'
                                    ? true
                                    : false
                                }
                                onClick={() => disableUser(row.login)}
                              >
                                <li className="fas fa-user-lock"></li>
                              </Button>
                            );
                          }
                        }
                      ]}
                    >
                      {(props) => (
                        <div className="py-4 table-responsive">
                          <BootstrapTable
                            {...props.baseProps}
                            bootstrap4={true}
                            bordered={false}
                            classes="align-items-center"
                          />
                        </div>
                      )}
                    </ToolkitProvider>
                  </Card>
                </div>
              </div>
            </Row>
          )}
        </ModalBody>

        <ModalFooter>
          <Button
            color="danger"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
            style={buttonStyle(systemConfiguration.cancelation_button_color)}
          >
            Fechar
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NotificationModal;
