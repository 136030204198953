import React from 'react';
import { Col } from 'reactstrap';
import Modal from './Modal';

const ShowUsers = ({ loggedAttendants = [] }) => {
  return (
    <Col
      style={{
        padding: 0
      }}
    >
      <Modal users={loggedAttendants} />
    </Col>
  );
};

export default ShowUsers;
