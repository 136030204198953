import ServiceCRUD from '../../../services/ServiceCRUD';
import { apiCase as http } from '../../../http';

class StatusService extends ServiceCRUD {
  constructor() {
    super('status');
  }

  getWithoutPagination() {
    return http.get('status/all');
  }

  getByRecordType(recordTypeId, params) {
    return http.get(`/status/by-record-type/${recordTypeId}`, { params });
  }

  getByRecordTypes(params) {
    return http.get(`/status/record-types`, { params });
  }
}

export default new StatusService();
