import React, { useState } from 'react';
import MaskedInput from 'react-text-mask';

const HourMaskMMHHSS = (props) => {
  const [mask, setMask] = useState([
    /[0-2]/,
    /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/,
    ':',
    /[0-5]/,
    /[0-9]/
  ]);

  const onChange = (event) => {
    const time = event.target.value;
    if (time[0] === '2') {
      setMask([/[0-2]/, /[0-3]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]);
    } else {
      setMask([/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]);
    }
    props.valueController(props.fieldName, time);
  };

  const getInputValue = (value) => {
    return value;
  };

  return (
    <MaskedInput
      mask={mask}
      value={getInputValue(props.value)}
      onChange={(event) => onChange(event)}
      placeholder={props.placeholder}
      className="form-control"
    />
  );
};

export default HourMaskMMHHSS;
