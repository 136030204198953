import ServiceUC from '../../../../services/ServiceCRUDFLEXUC';

class Service extends ServiceUC {
  constructor() {
    super('/api/cdr-report');
  }

  async getReportById(id) {
    return this.get(`/${id}`);
  }
}

export default new Service();
