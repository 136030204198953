import { useState, useCallback } from 'react';

import {
  defaultLimit,
  calculateOffsetFromPageAndLimit
} from 'views/CRM/shared/utils/pagination';

import OpportunityStageService from '../service';

function useListOpportunityStages() {
  const [opportunityStages, setOpportunityStages] = useState(null);
  const [totalOpportunityStages, setTotalOpportunityStages] = useState(0);
  const [isLoadingOpportunityStages, setIsLoadingOpportunityStages] = useState(
    false
  );

  const getOpportunityStages = useCallback(async (page, params = {}) => {
    setIsLoadingOpportunityStages(true);

    const offset = page
      ? calculateOffsetFromPageAndLimit(page, defaultLimit)
      : 0;

    OpportunityStageService.list(params, {
      offset,
      limit: page && defaultLimit
    })
      .then((response) => {
        setOpportunityStages(response.data.data);
        setTotalOpportunityStages(response.data.totalRegisters);
      })
      .catch(() => {
        setOpportunityStages(null);
      })
      .finally(() => {
        setIsLoadingOpportunityStages(false);
      });
  }, []);

  return {
    opportunityStages,
    totalOpportunityStages,
    isLoadingOpportunityStages,
    getOpportunityStages
  };
}

export { useListOpportunityStages };
