import React from 'react';

import { Button } from './style';

const ButtonChat = ({ name, color, action }) => {
  return (
    <>
      <Button onClick={action} type="submit" color={color}>
        {name}
      </Button>
    </>
  );
};

export { ButtonChat };
