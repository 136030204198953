import { useCallback, useState } from 'react';

import GetConversationService from '../services/apiConversation';

export const useChatMonitoring = () => {
  const [monitoringData, setMonitoringData] = useState(null);
  const [isLoadingMonitoringData, setIsLoadingMonitoringData] = useState(false);

  const getMonitoringData = useCallback(async (params = {}) => {
    setIsLoadingMonitoringData(true);
    try {
      const response = await GetConversationService.getMonitoringData({
        queryParams: params
      });
      setMonitoringData(response.data);
      return response.data;
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoadingMonitoringData(false);
    }
  }, []);

  return {
    monitoringData,
    getMonitoringData,
    isLoadingMonitoringData,
    setIsLoadingMonitoringData,
    setMonitoringData
  };
};
