import React, { useCallback, useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { toast } from 'react-toastify';

import ConfirmationModal from 'components/Modal/ConfirmationModal';

import OpportunityService from '../../../../Opportunity/services/opportunityService';

import './styles.css';
import { useHistory } from 'react-router';

const AccountOpportunities = ({ opportunities, reloadAccount }) => {
  const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
  const [idOfOpportunityToRemove, setIdOfOpportunityToRemove] = useState(null);

  const history = useHistory();

  const getName = useCallback((name = '') => {
    if (name.length <= 26) return name;
    return `${name.substring(0, 24).trim()}...`;
  }, []);

  const handleRemoveOpportunity = useCallback(
    (id) => {
      OpportunityService.delete(id)
        .then(() => {
          toast.success('Oportunidade removida');
          reloadAccount();
        })
        .catch((err) => {
          const message =
            err?.response?.data?.message || 'Erro ao remover oportunidade';
          toast.error(message);
        })
        .finally(() => {
          setOpenModalConfirmation(false);
          setIdOfOpportunityToRemove(null);
        });
    },
    [reloadAccount]
  );

  const controlDelete = (id) => {
    setIdOfOpportunityToRemove(id);
    setOpenModalConfirmation(true);
  };

  const handleOnOpportunityNameClick = useCallback(
    ({ recordTypeId, opportunityId }) => {
      history.push(
        `/admin/crm/opportunityEdit/${recordTypeId}/${opportunityId}`
      );
    },
    [history]
  );

  if (!opportunities || !opportunities.length) return null;

  return (
    <>
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          flexWrap: 'wrap'
        }}
      >
        {opportunities.map((opportunity) => (
          <div
            key={opportunity.id}
            style={{
              position: 'relative',
              border: '1px solid lightgray',
              borderRadius: '0.1rem',
              borderTop: '0.15rem solid gray',
              padding: '0.8rem',
              width: '24%',
              overflow: 'hidden',
              backgroundColor: '#fff'
            }}
          >
            <div
              style={{
                position: 'absolute',
                right: '0.5rem',
                top: '0.1rem'
              }}
              onClick={() => controlDelete(opportunity.id)}
            >
              <AiOutlineDelete type="button" size={18} color="#82000b" />
            </div>
            <div>
              <span
                className="link-to-contact-details"
                onClick={() =>
                  handleOnOpportunityNameClick({
                    recordTypeId: opportunity.recordTypeId,
                    opportunityId: opportunity.id
                  })
                }
                title={opportunity.title}
              >
                {getName(opportunity.title)}
              </span>
            </div>
          </div>
        ))}
      </div>
      <ConfirmationModal
        isModalOpen={openModalConfirmation}
        onModalToggle={setOpenModalConfirmation}
        unmountOnClose={true}
        idResource={idOfOpportunityToRemove}
        deleteResource={handleRemoveOpportunity}
        message={'Tem certeza que deseja remover esta oportunidade da conta?'}
      />
    </>
  );
};

export default AccountOpportunities;
