import styled from 'styled-components';

export const Container = styled.section`
  width: 18rem;
  background: #fff;
  border-radius: 5px 5px 0 5px;
  display: flex;
  flex-direction: column;
  align-items: center;

  -webkit-box-shadow: 2px 2px 9px -4px #000000;
  box-shadow: 2px 2px 9px -4px #000000;

  & + & {
    margin-left: 1rem;
  }

  @media (max-width: 1200px) {
    width: 16rem;
  }
`;

export const Header = styled.header`
  height: 40px;
  background-color: ${(props) => (props.color ? props.color : 'gray')};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 5px 5px 0 0;
  padding: 0 8px 0 8px;
  color: ${(props) => (props.fontColor ? props.fontColor : '#000')};
  z-index: 20;

  .header {
    border: none;
    background: inherit;
    color: white;
    width: 100%;
  }

  .header > p {
    float: left;
    margin: 0;
    padding: 0;
    font-weight: 400;
  }
`;

export const BodyChat = styled.section`
  height: ${(props) => (props.size ? '320px' : '0px')};
  transition: ease-in-out 0.4s;
  overflow-y: scroll;
  width: 100%;
  scroll-behavior: smooth;
  background: #eceff1;
  display: flex;
  flex-direction: column;

  .area-messages {
    scroll-behavior: smooth;
    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
  }
`;

export const BalloonRight = styled.div`
  border-radius: 4px 0px 4px 4px;
  background-color: #d6d2d2;
  word-wrap: break-word;
  color: #000;
  padding: 0.5rem 0.6rem;
  margin: 0 0 15px auto;
  max-width: 80%;
  font-size: medium;
  font-family: sans-serif;
  position: relative;
  z-index: 102;
`;

export const BalloonLeft = styled.div`
  border-radius: 0px 4px 4px 4px;
  background-color: ${(props) => (props.color ? props.color : 'gray')};
  margin: 0 auto 15px 0;
  color: ${(props) => (props.fontColor ? props.fontColor : '#000')};
  padding: 0.5rem 0.6rem;
  word-wrap: break-word;
  max-width: 80%;
  font-size: medium;
  font-family: sans-serif;
  position: relative;
`;

export const InputArea = styled.form`
  display: flex;
  justify-content: center;
  width: 100%;
  height: ${(props) => (props.size ? '42px' : '0px')};
  background: #fff;
  font-family: sans-serif;
  box-shadow: 0px -2px 10px 0px rgb(195, 195, 195);

  .input-chat-internal-write {
    outline-style: none;
    border: 0;
    width: 100%;
    background: #fff;
    padding: 5px 10px 5px 10px;
  }
`;

export const Arrow = styled.div`
  height: 0px;
  width: 0px;
  margin-right: 5px;
  border-top: 10px solid transparent;
  border-bottom: -10px solid transparent;
  border-left: 10px solid ${(props) => (props.color ? props.color : '#d6d2d2')};
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
`;

export const ArrowLeft = styled.div`
  height: 0px;
  width: 0px;
  margin-left: 5px;
  border-top: -10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid ${(props) => (props.color ? props.color : '#d6d2d2')};
  transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
`;

export const ContentMessages = styled.div`
  width: 100%;
  display: flex;
  margin-top: 5px;
`;
