import React, { useState } from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from 'reactstrap';

const DropdownQueueList = ({ nameQueuesToMonit = [] }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <Dropdown
      direction="left"
      isOpen={dropdownOpen}
      toggle={toggle}
      className="mr-2"
    >
      <DropdownToggle
        tag="span"
        data-toggle="dropdown"
        aria-expanded={dropdownOpen}
      >
        <span style={{ padding: '10px', cursor: 'pointer' }}>
          <i className="fas fa-desktop"></i>
        </span>
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem header>Filas Monitoradas</DropdownItem>
        {nameQueuesToMonit.length > 0 &&
          nameQueuesToMonit.map((queue) => (
            <DropdownItem key={queue} toggle={false} tag="span" text>
              {queue}
            </DropdownItem>
          ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default DropdownQueueList;
