import React, { useCallback, useState } from 'react';
import { CardProtocol } from '../../../../CRM/Opportunity/list/components/OpportunitiesList/components/Kanban/styles';
import { Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ProtocolHistoryModal from '../ProtocolHistoryModal';
import { MdPhoneForwarded } from 'react-icons/md';
import ClickToCall from 'views/ServicePanel/components/AttendanceContent/components/Modal/ClickToCall';

import { IoLogoWhatsapp } from 'react-icons/io';
import { BsFillChatDotsFill } from 'react-icons/bs';
import { FaFacebook, FaTelegram } from 'react-icons/fa';
import { LiaSmsSolid } from 'react-icons/lia';
import { AiFillInstagram } from 'react-icons/ai';
import { IoIosMail } from 'react-icons/io';

export const InfoCardProtocol = ({
  data: info,
  dragStart,
  dragEnter,
  handleCall,
  disableClickToCall,
  isProtocol
}) => {
  const cardProps = info.data.array[info.index];
  const history = useHistory();

  const getAnimatedIcon = () => {
    switch (cardProps?.service_channel?.toUpperCase()) {
      case 'TELEGRAM':
        return <FaTelegram size="20" color="#0088cc" />;
      case 'WHATSAPP':
        return <IoLogoWhatsapp size="20" color="#25D366" />;
      case 'CHAT':
        return <BsFillChatDotsFill size="18" color="#fbad50" />;
      case 'SMS':
        return <LiaSmsSolid size="18" color="#000" />;
      case 'INSTAGRAM':
        return <AiFillInstagram size="20" color="#bc2a8d" />;
      case 'FACEBOOK':
        return <FaFacebook size="20" color="#3B5998" />;
      case 'TELEFONIA':
        return <MdPhoneForwarded size="20" color="#d9534f" />;
      case 'EMAIL':
        return <IoIosMail size="20" color="#FF0000" />;

      default:
        return <> {cardProps?.service_channel ?? ''}</>;
    }
  };

  const telegramBorder = { borderLeft: '3px solid blue' };
  const whatsappBorder = { borderLeft: '3px solid green' };
  const chatBorder = { borderLeft: '3px solid orange' };
  const smsBorder = { borderLeft: '3px solid back' };
  const instagramBorder = { borderLeft: '3px solid purple' };
  const facebookBorder = { borderLeft: '3px solid blue' };
  const emailborder = { borderLeft: '3px solid red' };
  const telefoniaborder = { borderLeft: '3px solid red' };

  const getBorderStyle = () => {
    switch (cardProps?.service_channel?.toUpperCase()) {
      case 'TELEGRAM':
        return telegramBorder;
      case 'WHATSAPP':
        return whatsappBorder;
      case 'CHAT':
        return chatBorder;
      case 'SMS':
        return smsBorder;
      case 'INSTAGRAM':
        return instagramBorder;
      case 'FACEBOOK':
        return facebookBorder;
      case 'EMAIL':
        return emailborder;
      case 'TELEFONIA':
        return telefoniaborder;
      default:
        return null;
    }
  };
  return (
    <CardProtocol
      style={{
        ...info.style,
        color: 'black',
        justifyContent: 'center',
        ...getBorderStyle(cardProps?.service_channel)
      }}
      onDragStart={(e) => dragStart(e, info.index, info.data.list)}
      onDragEnter={(e) => dragEnter(e, info.index)}
      draggable={true}
      key={cardProps?.id}
      id={'draggable' + cardProps?.id}
      isProtocol={isProtocol}
    >
      <div className="card-padding-before">
        <div className="CardContent">
          <div style={{ marginTop: '-15px' }}>
            <p title={cardProps?.title}>{cardProps?.title}</p>
          </div>
          <div>
            <p title={cardProps?.description}>
              {' '}
              <label>Número</label> : {cardProps?.number}
            </p>
          </div>
          <div>
            <p title={cardProps?.description}>
              {' '}
              <label>Canal</label> : {getAnimatedIcon()}
            </p>
          </div>
          <div>
            <p title={cardProps?.description}>
              {' '}
              <label>Atendente: </label>{' '}
              {cardProps?.protocol_responsibles[0]?.usr?.name}
            </p>
          </div>
          <div>
            <p title={cardProps?.description}>
              {' '}
              <label>Cliente: </label> {cardProps?.cliente?.nome}
            </p>
          </div>
        </div>

        <div className="button-container">
          <>
            <Button
              title="Editar"
              onClick={() => {
                history.push(`/admin/protocol/${cardProps.id}/update`, {
                  recordTypeId: cardProps.record_type_id,
                  returnPath: '/admin/protocol'
                });
              }}
              size="sm"
              color="info"
            >
              <i className="fas fa-user-edit"></i>
            </Button>

            <ProtocolHistoryModal protocolId={cardProps.id} />

            <ClickToCall
              title={
                cardProps?.cliente?.telefone !== '' ||
                cardProps.telephone !== ''
                  ? `Chamar cliente ${cardProps?.cliente?.nome}\nÉ necessário estar logado em um ramal para realizar a chamada!`
                  : `O cliente ${cardProps?.cliente?.nome} não possui telefone cadastrado`
              }
              callBack={() => handleCall(cardProps)}
              disableClickToCall={
                (cardProps?.cliente?.telefone !== '' ||
                  cardProps?.telephone !== '') &&
                !disableClickToCall
                  ? false
                  : true
              }
              icon={<MdPhoneForwarded size={17} color="#fff" />}
              color="success"
              isDefaultButtonStyle={true}
            />
          </>
        </div>
      </div>
    </CardProtocol>
  );
};
