import React, { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import { Row, Col, Input } from 'reactstrap';
import FlexSelect from 'components/Inputs/FlexSelect';

const ExitEmailForm = (props) => {
  const {
    control,
    errors,
    setValue,
    isUpdate,
    recordTypes = [],
    isLoadingRecordTypes = true
  } = props;

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <>
      <Row className="mb-3">
        <Col md="6">
          <label className="form-control-label">Nome*</label>
          <Controller
            as={Input}
            control={control}
            type="text"
            name="name"
            rules={{ required: 'O nome é obrigatório' }}
            defaultValue=""
            autocomplete="off"
          />
          <ErrorMessage
            errors={errors}
            name="name"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6">
          <label className="form-control-label">Email*</label>
          <Controller
            as={Input}
            control={control}
            type="email"
            name="address"
            rules={{ required: 'Endereço é obrigatório' }}
            defaultValue=""
            autocomplete="off"
          />
          <ErrorMessage
            errors={errors}
            name="address"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="6">
          <label className="form-control-label">
            Senha{isUpdate ? '' : '*'}
          </label>
          <Controller
            as={Input}
            type="password"
            control={control}
            name="password"
            placeholder=""
            rules={{
              required: isUpdate ? false : 'Campo obrigatório'
            }}
            defaultValue=""
            autocomplete="new-password"
          />
          <ErrorMessage
            errors={errors}
            name="password"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6">
          <label className="form-control-label">Host SMTP*</label>
          <Controller
            as={Input}
            control={control}
            type="text"
            name="smtpHost"
            rules={{ required: 'Campo obrigatório' }}
            defaultValue=""
            autocomplete="off"
          />
          <ErrorMessage
            errors={errors}
            name="smtpHost"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md="6">
          <label className="form-control-label">Porta SMTP*</label>
          <Controller
            as={Input}
            control={control}
            type="number"
            name="smtpPort"
            rules={{ required: 'Campo obrigatório' }}
            defaultValue=""
            autocomplete="off"
          />
          <ErrorMessage
            errors={errors}
            name="smtpPort"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
        <Col md="6" className="mt-2">
          <label className="form-control-label">Tipos de registro*</label>
          <Controller
            render={(props) => (
              <FlexSelect
                dataOptions={recordTypes || []}
                isMulti={true}
                value={props.value}
                valueController={setValue}
                fieldName="recordTypeIds"
                labelName="name"
                valueName="id"
                isClearable={true}
                disabled={isLoadingRecordTypes}
                noOptionsMessage={() => 'Nenhum tipo de registro encontrado'}
              />
            )}
            control={control}
            name="recordTypeIds"
            defaultValue=""
            rules={{
              required: 'Selecione pelo menos um tipo de registro',
              validate: (value) => {
                if (!value || !value.length)
                  return 'Selecione pelo menos um tipo de registro';
                return true;
              }
            }}
          />
          <ErrorMessage
            errors={errors}
            name="recordTypeIds"
            render={({ message }) => errorFormMessage(message)}
          />
        </Col>
      </Row>
    </>
  );
};

export default ExitEmailForm;
