import { ErrorMessage } from '@hookform/error-message';
import FlexSelect from 'components/Inputs/FlexSelect';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Button, Form, Input, Table } from 'reactstrap';
import { entitiesName } from 'views/CRM/shared/utils/entitiesName';
import { useEntityCustomFieldsByRecordType } from 'views/fieldConfigs/hooks/useCustomFieldsByRecordType';
import { useRecordTypesByUser } from 'views/RecordType/hooks/useRecordTypesByUser';
import DefaultModal from '../../../../../../components/Modal/DefaultModal';
import OpportunityService from '../../../../../CRM/Opportunity/services/opportunityService';

const ConversionModal = ({
  isModalOpen,
  onModalToggle,
  activeStage,
  opportunity,
  recordTypeId,
  ...props
}) => {
  const [recordTypeIdHook, setRecordTypeIdHook] = useState(recordTypeId);
  const { recordTypes, getRecordTypesByUser } = useRecordTypesByUser(
    entitiesName.OPPORTUNITY
  );
  const closeModal = useCallback(() => {
    onModalToggle(false);
    setSelectedOption(true);
  }, [onModalToggle]);
  const { getCustomFields, customFields } = useEntityCustomFieldsByRecordType(
    entitiesName.OPPORTUNITY,
    recordTypeId
  );
  const { control, handleSubmit, errors, setValue, reset } = useForm();
  const [selectedOption, setSelectedOption] = useState(true);
  const {
    getCustomFields: getOpportunityCustomFields,
    customFields: opportunityCustomFields,
    isLoadingCustomFields: isLoadingOpportunityCustomFields
  } = useEntityCustomFieldsByRecordType(
    entitiesName.OPPORTUNITY,
    recordTypeIdHook
  );

  const userLogin = localStorage.getItem('login');
  const history = useHistory();

  useEffect(() => {
    getCustomFields();
  }, [getCustomFields]);

  useEffect(() => {
    if (recordTypeIdHook) {
      getOpportunityCustomFields();
    }
  }, [getOpportunityCustomFields, recordTypeIdHook]);

  useEffect(() => {
    reset({
      recordTypeId: Number(recordTypeId)
    });
  }, [reset, recordTypeId]);

  useEffect(() => {
    getRecordTypesByUser();
  }, [getRecordTypesByUser]);

  const updateStage = () => {
    const data = {
      stageId: activeStage
    };
    OpportunityService.updateStage(opportunity.id, data)
      .then(() => {
        toast.success('Fase atualizada com sucesso.', {
          closeOnClick: true,
          autoClose: 3000
        });
      })
      .catch(() =>
        toast.error('Erro ao atualizar a fase.', {
          autoClose: 3000,
          closeOnClick: true
        })
      );
  };

  const removeBlankProperties = (obj) => {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ''
      ) {
        delete obj[propName];
      }
    }
    return obj;
  };

  const onSubmit = async (data) => {
    if (selectedOption) {
      updateStage();
      closeModal();
    } else {
      const filterObject = removeBlankProperties(data);

      const dataDto = {
        login: userLogin,
        recordTypeIdToConvert: data.recordTypeId,
        opportunityTitle: data.title,
        fields: {
          ...filterObject
        }
      };

      delete dataDto.fields.recordTypeId;
      delete dataDto.fields.title;

      OpportunityService.convertOpportunity(
        opportunity.id,
        recordTypeId,
        dataDto
      )
        .then((res) => {
          const { recordTypeId, id } = res.data.data;
          toast.success('Oportunidade convertida com sucesso');
          history.push(`/admin/crm/opportunityEdit/${recordTypeId}/${id}`);
          return;
        })
        .catch(() => {
          toast.error('Houve um erro ao converter a oportunidade');
        })
        .finally(() => {
          closeModal();
        });
    }
  };

  const errorFormMessage = (message) => (
    <p style={{ color: 'red' }}>{message}</p>
  );

  return (
    <DefaultModal
      show={isModalOpen}
      onModalToggle={onModalToggle}
      title="Converter Oportunidade"
      showCloseButton={false}
      modalContainerClasses="modal-xl"
      closeModal={closeModal}
      {...props}
    >
      <>
        <Form className="needs-validation" onSubmit={handleSubmit(onSubmit)}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: -20
            }}
          >
            <label>
              <input
                type="radio"
                name="myRadio"
                value="finalizar"
                defaultChecked={true}
                onChange={() => setSelectedOption(true)}
              />{' '}
              Finalizar
            </label>
            <label>
              <input
                type="radio"
                name="myRadio"
                value="converter"
                onChange={() => setSelectedOption(false)}
              />{' '}
              Converter em nova Oportunidade
            </label>
            {selectedOption ? (
              <>
                <hr />
              </>
            ) : (
              <>
                <label className="form-control-label text-capitalize">
                  Título*
                </label>
                <Controller
                  render={(props) => (
                    <Input
                      valueController={setValue}
                      value={props.value}
                      onChange={props.onChange}
                      type="text"
                      placeholder="Título"
                    />
                  )}
                  rules={{ required: 'Campo obrigatório' }}
                  control={control}
                  defaultValue=""
                  name="title"
                />
                <ErrorMessage
                  errors={errors}
                  name="title"
                  render={({ message }) => errorFormMessage(message)}
                />

                <label> Tipo de Registro*</label>
                <Controller
                  render={(props) => (
                    <FlexSelect
                      dataOptions={recordTypes || []}
                      isMulti={false}
                      value={props.value}
                      onChange={setRecordTypeIdHook(props.value)}
                      valueController={setValue}
                      fieldName="recordTypeId"
                      labelName="name"
                      valueName="id"
                      isClearable={true}
                      closeMenuOnSelect={true}
                      disabled={isLoadingOpportunityCustomFields}
                    />
                  )}
                  control={control}
                  defaultValue={recordTypeId}
                  rules={{
                    required: 'Campo obrigatório'
                  }}
                  name="recordTypeId"
                />
                <ErrorMessage
                  errors={errors}
                  name="recordTypeId"
                  render={({ message }) => errorFormMessage(message)}
                />

                <Table hover={true} striped>
                  <thead>
                    <tr>
                      <th className="text-center">Campo Oportunidade</th>
                      <th className="text-center">
                        Campo Oportunidade convertida
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {customFields &&
                      customFields.map((customFields, i) => {
                        return (
                          <>
                            <tr key={i}>
                              <td>{customFields.label}</td>
                              <td>
                                <Controller
                                  render={(props) => (
                                    <FlexSelect
                                      dataOptions={
                                        (opportunityCustomFields &&
                                          opportunityCustomFields.filter(
                                            (field) =>
                                              field.type === customFields.type
                                          )) ||
                                        []
                                      }
                                      isMulti={false}
                                      value={props.value}
                                      valueController={setValue}
                                      fieldName={customFields.name}
                                      labelName="label"
                                      valueName="name"
                                      closeMenuOnSelect={true}
                                      isClearable={true}
                                      disabled={
                                        isLoadingOpportunityCustomFields
                                      }
                                      noOptionsMessage={() =>
                                        'Não há campos customizados na oportunidade'
                                      }
                                    />
                                  )}
                                  control={control}
                                  name={customFields.name}
                                  defaultValue=""
                                />
                              </td>
                            </tr>
                          </>
                        );
                      })}
                    <hr />
                  </tbody>
                </Table>
              </>
            )}
          </div>
          <hr />
          <Button color="primary" type="submit">
            Salvar
          </Button>
          <Button color="danger" onClick={closeModal}>
            Voltar
          </Button>
        </Form>
      </>
    </DefaultModal>
  );
};

export default ConversionModal;
