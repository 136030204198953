import React, { useCallback, useEffect, useState } from 'react';
import SimpleHeader from 'components/Headers/SimpleHeader.js';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import Pagination from 'components/Pagination';
import ToggleSwitch from '../../../components/ToggleSwitch/ToggleSwitch';
import Modal from '../../../components/Modal/NotificationModal';
import DialerService from '../service';
import MailingModal from './MailingModal';
import InteractiveDialerSettingsModal from './components/InteractiveDialerSettingsModal';
import { strategies, strategyOptions } from '../utils/strategy';

import {
  Input,
  Container,
  Row,
  Card,
  CardHeader,
  Button,
  CardBody,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
  Label
} from 'reactstrap';

import {
  icon_column_style,
  individual_column_style,
  individual_header_style,
  individual_icon_header_style
} from '../../../assets/styles/table_custom_style';
import ReactLoader from 'components/Loader';

const DialerList = () => {
  const [dialers, setDialers] = useState([]);
  const [activePage, setActivePage] = useState(1);
  const [quantity, setQuantity] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showDisable, setShowDisable] = useState(false);
  const [searchDialers, setSearchDialers] = useState();
  const [changingDialerStatus, setChangingDialerStatus] = useState(false);

  const getDialers = useCallback(
    (selectedPage, search = '') => {
      DialerService.getAllSeach(
        selectedPage || activePage,
        10,
        search || searchDialers || '',
        showDisable
      )
        .then((response) => {
          const dialerList = response.data.data;
          const quantity = response.data.totalRegisters;
          setDialers(dialerList);
          setQuantity(quantity);
          setLoading(false);
        })
        .catch((err) => {
          toast.error(
            err.response.data.message || 'Erro ao listar discadores!',
            {
              autoClose: 3000,
              closeOnClick: true
            }
          );
        });
    },
    [showDisable, activePage, searchDialers]
  );

  useEffect(() => {
    getDialers();

    const intervalId = setInterval(() => {
      getDialers();
    }, 20000);

    return () => clearInterval(intervalId);
  }, [showDisable, getDialers]);

  const toggleDisable = (id, disable) => {
    if (!disable) {
      disableDialer(id);
    } else {
      recoveryDialer(id);
    }
  };

  const disableDialer = (id) => {
    if (!window.confirm('Tem certeza que deseja desabilitar a discador?'))
      return;
    DialerService.delete(id)
      .then(() => {
        toast.info('Discador desabilitado com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });

        getDialers();
      })
      .catch((err) => {
        toast.error(
          err.response.data.message || 'Ops, algo errado aconteceu!',
          {
            autoClose: 3000,
            closeOnClick: true
          }
        );
      });
  };

  const recoveryDialer = (id) => {
    DialerService.recovery(id)
      .then(() => {
        toast.info('Discador recuperado com sucesso!', {
          autoClose: 3000,
          closeOnClick: true
        });
        getDialers(activePage);
      })
      .catch((err) => {
        toast.error(err.response.data.message || 'Ops algo errado aconteceu!', {
          autoClose: 3000,
          closeOnClick: true
        });
      });
  };

  const getDialersSearch = (event, selectedPage = 1) => {
    setSearchDialers(event.target.value);
    if (searchDialers) {
      getDialers(selectedPage, event.target.value);
    }
  };

  const handlePageChange = (page) => {
    setActivePage(page);
    getDialers(page);
  };

  const handleShowDisableds = (value) => {
    setShowDisable(value);
    if (value === false) setActivePage(1);
  };

  const toggleDialerStatus = async ({ dialerId, currentStatus }) => {
    setChangingDialerStatus(true);

    try {
      if (currentStatus === false) {
        await startDialer(dialerId);
      } else {
        await stopDialer(dialerId);
      }

      setChangingDialerStatus(false);

      const newDialers = dialers.map((dialer) => {
        if (dialer.id === dialerId) {
          dialer.status_process = !dialer.status_process;
        }
        return dialer;
      });

      setDialers([...newDialers]);
    } catch (error) {
      setChangingDialerStatus(false);
      toast.error(
        error.response?.data?.message || 'Erro no processo do discador',
        {
          autoClose: 3000,
          closeOnClick: true
        }
      );
    }
  };

  const startDialer = async (dialerId) => {
    await DialerService.startDialer(dialerId);

    toast.success('Discador iniciado', {
      autoClose: 3000,
      closeOnClick: true
    });
  };

  const stopDialer = async (dialerId) => {
    await DialerService.stopDialer(dialerId);

    toast.success('Discador interrompido', {
      autoClose: 3000,
      closeOnClick: true
    });
  };

  const handleOpenDashboard = (dialerId, dialerName, queueName) => {
    window.open(
      `${window.location.origin}/dialer/${dialerId}/dashboard?name=${dialerName}?queuename=${queueName}`,
      '_blank',
      'width=' +
        window.screen.availWidth +
        ',height=' +
        window.screen.availHeight
    );
  };

  return (
    <>
      <SimpleHeader
        setCreate="/admin/dialer/form"
        name="Form validation"
        parentName="Forms"
      />
      <Container className="mt--6" fluid>
        <Row className="mt-3 mx-2">
          <div className="col">
            <div className="card-wrapper">
              <Card>
                <CardHeader>
                  <h3>Discador</h3>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md="8">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="fas fa-search" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Discador"
                          type="text"
                          onChange={(event) => getDialersSearch(event)}
                        />
                      </InputGroup>
                    </Col>
                    <Col md="4" className="text-right mt-2">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={showDisable}
                            onChange={() => handleShowDisableds(!showDisable)}
                          />
                          <span>Mostrar desabilitados</span>
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                </CardBody>
                <ToolkitProvider
                  data={dialers}
                  keyField="id"
                  columns={[
                    {
                      dataField: 'name',
                      text: 'Nome',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style
                    },
                    {
                      dataField: 'queue.name',
                      text: 'Fila',
                      sort: true,
                      style: individual_column_style,
                      headerStyle: individual_header_style,
                      formatter: (rowContent, row) => {
                        if (row.queue !== null) return row.queue.name;
                        else return 'Sem fila';
                      }
                    },
                    {
                      dataField: 'status_process',
                      text: 'Status',
                      sort: true,
                      style: icon_column_style,
                      headerStyle: individual_header_style,
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        if (row.pid_process === 0) {
                          return 'Finalizado';
                        }

                        return (
                          <Button
                            color={row.status_process ? 'danger' : 'primary'}
                            size="sm"
                            disabled={row.disable || changingDialerStatus}
                            onClick={() =>
                              toggleDialerStatus({
                                dialerId: row.id,
                                currentStatus: row.status_process
                              })
                            }
                          >
                            <i
                              className={
                                row.status_process
                                  ? 'fas fa-stop-circle'
                                  : 'fas fa-play-circle'
                              }
                            ></i>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'pid_process',
                      text: 'Dashboard',
                      sort: true,
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Button
                            color="success"
                            size="sm"
                            onClick={() =>
                              handleOpenDashboard(
                                row.id,
                                row.name,
                                row.queue?.name
                              )
                            }
                          >
                            <i className="fas fa-chart-pie"></i>
                          </Button>
                        );
                      }
                    },
                    {
                      dataField: 'configs',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Configurações',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Modal name={row.name}>
                            <p>
                              <strong>Estratégia: </strong>
                              {
                                strategyOptions.find(
                                  (element) => element.value === row.type
                                ).label
                              }
                            </p>
                            <p>
                              <strong>Intervalo entre chamadas: </strong>
                              {row.attempt_interval}
                            </p>
                            <p>
                              <strong>
                                Intervalo entre tentativas (minutos):
                              </strong>
                              {row.repeat_interval}
                            </p>
                            <p>
                              <strong>Quantidade máxima de tentativas: </strong>
                              {row.attempts}
                            </p>
                            <p>
                              <strong>Hora Inicial: </strong>
                              {row.start_hour}
                            </p>
                            <p>
                              <strong>Hora Final: </strong>
                              {row.end_hour}
                            </p>
                            <p>
                              <strong>Rota de saida: </strong>
                              {row.outboundRouteGroup !== null
                                ? row.outboundRouteGroup.name
                                : 'Sem rota'}
                            </p>
                          </Modal>
                        );
                      }
                    },
                    {
                      dataField: 'import_mailing',
                      text: 'Importar Lista',
                      sort: true,
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        const isInteractiveDialer =
                          row.type === strategies.INTERATIVO;
                        return isInteractiveDialer ? (
                          <InteractiveDialerSettingsModal
                            dialerId={row.id}
                            integrationUrl={row.integrationUrl}
                            disabled={row.pid_process === 0}
                          />
                        ) : (
                          <MailingModal
                            dialerId={row.id}
                            queueId={row.queue_id}
                            importType={row.mailing_import_type}
                            integrationUrl={row.integrationUrl}
                            disabled={row.pid_process === 0}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'disable',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Habilitar / Desabilitar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <ToggleSwitch
                            checked={!row.disable}
                            id={row.name}
                            onChange={() => toggleDisable(row.id, row.disable)}
                          />
                        );
                      }
                    },
                    {
                      dataField: 'edit',
                      style: icon_column_style,
                      headerStyle: individual_icon_header_style,
                      text: 'Editar',
                      // eslint-disable-next-line react/display-name
                      formatter: (_, row) => {
                        return (
                          <Link to={'/admin/dialer/form/' + row.id}>
                            <Button
                              color="info"
                              size="sm"
                              disabled={row.pid_process === 0}
                            >
                              <li className="fas fa-user-edit"></li>
                            </Button>
                          </Link>
                        );
                      }
                    }
                  ]}
                >
                  {(props) => (
                    <div className="py-4 table-responsive">
                      {loading ? (
                        <Row className="justify-content-md-center">
                          <ReactLoader />
                        </Row>
                      ) : (
                        <BootstrapTable
                          {...props.baseProps}
                          bootstrap4={true}
                          bordered={false}
                          classes="align-items-center"
                        />
                      )}
                    </div>
                  )}
                </ToolkitProvider>
                <Pagination
                  activePage={activePage}
                  totalItemsCount={quantity}
                  onChange={handlePageChange.bind(this)}
                />
              </Card>
            </div>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DialerList;
